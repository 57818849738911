import moment from "moment";
import { config } from "../config/config";
import {
  masterMakeChartObject,
  getUniqueArrayByKey,
  objectFlip,
  capitalize,
  masterMakeChartObjectReport,
} from "./utils";
import { getTimeZoneObj } from "./timeFiltersUtils";
import {
  getDimensionObjByOriginalID,
  getMeasureObjByOriginalID,
} from "./plotlyUtils";
import { getSigviewUserType } from "./utils";
import {
  transformFiltersBackendToUi,
  transformMetricFiltersBackendToUi,
} from "./filtersUtils";
import { unwrapperTimeFilters } from "../utils/chartObjectUtils";
import { updateTimeFiltersWithDefaultDates } from "../redux/actions";
import timeFiltersReducer from "../redux/reducers/timeFilters";

import allTimezones from "../../assets/data/allTimezones.json";
import allDatePresets from "../../assets/data/allDatePresets.json";
import reportCountFileTypeAws from "../../assets/data/reportCountFileType";
import reportCountFileTypeOpenx from "../../assets/data/reportCountFileTypeOpenx";
import triggerFrequencyArr from "../../assets/data/triggerFrequencyArr.json";

const sigviewUserType = getSigviewUserType();

const reportCountFileType =
  sigviewUserType === "sigview"
    ? reportCountFileTypeAws
    : reportCountFileTypeOpenx;

const operationTypeIdMapping = {
  SendLinks: "all_records",
  SendEmails: "fifty_thousand_records",
  DownloadRecords: "five_thousand_records",
};

const operationTypeIdMappingInverse = objectFlip(operationTypeIdMapping);

const scheduledReportTypeIdMapping = {
  "scheduled-full-report": "all_records",
  scheduleRecords: "fifty_thousand_records",
};

const scheduledReportTypeIdMappingInverse = objectFlip(
  scheduledReportTypeIdMapping
);

const orderByTypeMapping = {
  id_only: "",
  id_trueDelta: "_Absolute_Change",
  id_deltaPercentage: "_Percentage_Change",
};

const getFrequencyObjectFromId = (id) =>
  triggerFrequencyArr.find((row) => row.id === id);

//Function to remove instance from the user email for displaying in reports
export const unwrapperEmail = (email, instance) => {
  //Eg:
  // Non-Sigview
  // EMAIL: john.hopkins+this-is-my-instance@xyz.com
  // INSTANCE: this-is-my-instance (note: + sign is not a part of instance)
  // OUTPUT: john.hopkins@xyz.com

  // Sigview
  // EMAIL: john.hopkins@abc.com
  // INSTANCE: ""
  // OUTPUT: john.hopkins@abc.com
  return email.replace(`+${instance}`, ""); // (note: + sign is not a part of instance)
};

export const getMetricTypeFromMetricId = (id) =>
  id.startsWith("C") ? "custom" : "basic";

export const getOrderByDetailsFromOrderBy = (orderBy) => {
  const orderByArr = [
    ...(orderBy.dimOrdByList || []),
    ...(orderBy.metricOrdByList || []),
    ...(orderBy.customMetricOrdByList || []),
  ];
  const orderByObj = orderByArr[0];
  const orderByIdTemp = orderByObj.id;
  const orderBySort = orderByObj.desc ? "desc" : "asc";
  const orderByType = orderByIdTemp.includes("trueDelta")
    ? "id_trueDelta"
    : orderByIdTemp.includes("deltaPercentage")
    ? "id_deltaPercentage"
    : "id_only";
  const orderById = orderByIdTemp
    .replace("_trueDelta", "")
    .replace("_deltaPercentage", "");
  return {
    orderById,
    orderBy: orderBySort,
    orderByType,
  };
};

const unwrapperAllRecordsComparisionOff = (reportFormData) => {
  if (reportFormData.reportRowCount === "all_records") {
    return {
      ...reportFormData,
      isComparisonOn: false,
      timeFilters: {
        ...reportFormData.timeFilters,
        isComparisonOn: false,
      },
    };
  } else {
    return reportFormData;
  }
};

const mapping = {
  Custom: "custom",
  Yesterday: "yesterday",
  "This Week": "this_week",
  "Last Week": "last_week",
  "Last 7 Days": "last_7_days",
  "Last 14 Days": "last_14_days",
  "Last 30 Days": "last_30_days",
  "Last 60 Days": "last_60_days",
  "This Month": "this_month",
  "Last Month": "last_month",
  "This Quarter": "this_quarter",
};

export const unwrapperSavedReport = (props) => {
  const { payload, allData, user, activeReport, variant } = props;
  const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarReports;
  const userEmail = user?.reqMetadata?.email;
  const userInstance = user?.auth?.instances;
  const formObject =
    activeReport.type === "saved"
      ? payload.recentDownloadRecord
      : activeReport.type === "download"
      ? payload
      : null;
  const chartObject = formObject.payload;

  // * The change in structure is for consistency
  // * This is done because our unwrapperTimeFilters requires data in a certain format
  const unwrapperTimeFiltersProps = {
    chartObject: {
      ...chartObject,
      metadata: {
        ...chartObject.metadata,
        comparisonMode: chartObject.requestParam.hasOwnProperty("compareDate"),
      },
      requestParam: {
        ...chartObject.requestParam,
        dateRangeType: mapping[chartObject.requestParam.dateRangeType],
        progressiveDate: "false", // defaulting to false to load correct time filters
        ...(chartObject.requestParam.hasOwnProperty("compareDate")
          ? { compareDateRange: chartObject.requestParam.compareDate }
          : {}),
      },
    },
    calendarDaysLimits,
    user,
    allData,
  };
  const timeFilters = unwrapperTimeFilters(unwrapperTimeFiltersProps);
  // ! OLD IMPLEMENTATION
  // let timeFiltersAction;
  // if (activeReport.type === "download") {
  //   let compareSelectedStartDate;
  //   //Only if
  //   if (formObject.isComparison) {
  //     const compareSelectedStartDateEpoch = parseInt(
  //       chartObject.requestParam.compareDate.startDate
  //     );
  //     let selectedTimezone = getTimeZoneObj(
  //       chartObject.requestParam.timeZone.name,
  //       allTimezones
  //     );
  //     let compareSelectedStartDateEpochInUTC =
  //       compareSelectedStartDateEpoch +
  //       selectedTimezone.minutesOffset * 60 * 1000;
  //     let compareSelectedStartDateEpochInUserSystemTimezone =
  //       compareSelectedStartDateEpochInUTC +
  //       new Date().getTimezoneOffset() * 60 * 1000;
  //     compareSelectedStartDate = new Date(
  //       compareSelectedStartDateEpochInUserSystemTimezone
  //     );
  //   }
  //   timeFiltersAction = updateTimeFilters({
  //     selectedTimezone: getTimeZoneObj(
  //       chartObject.requestParam.timeZone.name,
  //       allTimezones
  //     ),
  //     startDateEpoch: allData.dateRange.data.startDate,
  //     endDateEpoch: allData.dateRange.data.endDate,
  //     allDatePresets,
  //     format: config.hardCoded.datetimeFormat,
  //     selectedStartDateEpoch: chartObject.requestParam.dateRange.startDate,
  //     selectedEndDateEpoch: chartObject.requestParam.dateRange.endDate,
  //     daysLimit: calendarDaysLimits,
  //     isComparisonOn: formObject.isComparison,
  //     ...(compareSelectedStartDate ? { compareSelectedStartDate } : {}),
  //   });
  // } else {
  //   if (chartObject.requestParam.dateRangeType !== "Custom") {
  //     timeFiltersAction = updateTimeFilters({
  //       selectedTimezone: getTimeZoneObj(
  //         chartObject.requestParam.timeZone.name,
  //         allTimezones
  //       ),
  //       startDateEpoch: allData.dateRange.data.startDate,
  //       endDateEpoch: allData.dateRange.data.endDate,
  //       allDatePresets,
  //       format: config.hardCoded.datetimeFormat,
  //       selectedDatePreset: getDatePresetObj(
  //         chartObject.requestParam.dateRangeType,
  //         allDatePresets,
  //         "name"
  //       ).id,
  //       daysLimit: calendarDaysLimits,
  //       isComparisonOn: formObject.isComparison,
  //     });
  //   } else {
  //     timeFiltersAction = updateTimeFilters({
  //       selectedTimezone: getTimeZoneObj(
  //         chartObject.requestParam.timeZone.name,
  //         allTimezones
  //       ),
  //       startDateEpoch: allData.dateRange.data.startDate,
  //       endDateEpoch: allData.dateRange.data.endDate,
  //       allDatePresets,
  //       format: config.hardCoded.datetimeFormat,
  //       selectedDatePreset: "last_2_days",
  //       daysLimit: calendarDaysLimits,
  //       isComparisonOn: formObject.isComparison,
  //     });
  //   }
  // }
  // let timeFilters = timeFiltersReducer({}, timeFiltersAction);
  // const areSelectedDatesValidFlag = areSelectedDatesValid({
  //   selectedDates: {
  //     startDate: timeFilters.selectedDates.startDate.epoch,
  //     endDate: timeFilters.selectedDates.endDate.epoch,
  //   },
  //   validDates: {
  //     startDate: allData.dateRange.data.startDate,
  //     endDate: allData.dateRange.data.endDate,
  //   },
  // });
  // if (!areSelectedDatesValidFlag) {
  //   timeFiltersAction = updateTimeFiltersWithDefaultDates({
  //     selectedTimezone: getTimeZoneObj(
  //       chartObject.requestParam.timeZone.name,
  //       allTimezones
  //     ),
  //     startDateEpoch: allData.dateRange.data.startDate,
  //     endDateEpoch: allData.dateRange.data.endDate,
  //     allDatePresets,
  //     format: config.hardCoded.datetimeFormat,
  //     selectedDatePreset: "custom",
  //     daysLimit: calendarDaysLimits,
  //     isComparisonOn: formObject.isComparison,
  //   });
  //   timeFilters = timeFiltersReducer({}, timeFiltersAction);
  // }
  let dimensionFilters = transformFiltersBackendToUi(
    chartObject.requestParam.filter || [],
    allData.plotlyDimensions
  );
  //** Backward Compatibility Code Start Here **//
  const {
    globalFiltersFileUpload = false,
    globalFiltersStringMatch = false,
    globalFiltersTimestamp = false,
  } = user.uiFeatureList;

  if (
    !globalFiltersStringMatch &&
    !globalFiltersFileUpload &&
    !globalFiltersTimestamp &&
    dimensionFilters.length > 0
  ) {
    var validityFlags = dimensionFilters.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            ![
              "startsWith",
              "exactlyMatches",
              "endsWith",
              "containsString",
              "containsWholeWord",
              "from",
              "on",
              "between",
              "before",
            ].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    !globalFiltersStringMatch &&
    globalFiltersFileUpload &&
    !globalFiltersTimestamp &&
    dimensionFilters.length > 0
  ) {
    var validityFlags = dimensionFilters.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            ![
              "startsWith",
              "endsWith",
              "containsString",
              "containsWholeWord",
              "from",
              "on",
              "between",
              "before",
            ].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    globalFiltersStringMatch &&
    !globalFiltersFileUpload &&
    !globalFiltersTimestamp &&
    dimensionFilters.length > 0
  ) {
    var validityFlags = dimensionFilters.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            !["exactlyMatches", "from", "on", "between", "before"].includes(
              item.type
            )
        ),
      };
      return newRow;
    });
  } else if (
    !globalFiltersStringMatch &&
    !globalFiltersFileUpload &&
    globalFiltersTimestamp &&
    dimensionFilters.length > 0
  ) {
    var validityFlags = dimensionFilters.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            ![
              "startsWith",
              "exactlyMatches",
              "endsWith",
              "containsString",
              "containsWholeWord",
            ].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    globalFiltersFileUpload &&
    !globalFiltersStringMatch &&
    globalFiltersTimestamp &&
    dimensionFilters.length > 0
  ) {
    var validityFlags = dimensionFilters.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            ![
              "startsWith",
              "endsWith",
              "containsString",
              "containsWholeWord",
            ].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    !globalFiltersFileUpload &&
    globalFiltersStringMatch &&
    globalFiltersTimestamp &&
    dimensionFilters.length > 0
  ) {
    var validityFlags = dimensionFilters.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) => !["exactlyMatches"].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    globalFiltersStringMatch &&
    globalFiltersFileUpload &&
    !globalFiltersTimestamp &&
    dimensionFilters.length > 0
  ) {
    var validityFlags = dimensionFilters.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) => !["from", "on", "between", "before"].includes(item.type)
        ),
      };
      return newRow;
    });
  }
  if (validityFlags) {
    var finalValidateFilterArray = validityFlags.filter((row) => {
      if (
        (row.values.length !== 0 && row.advancedFilters.length === 0) ||
        (row.values.length === 0 && row.advancedFilters.length !== 0) ||
        (row.values.length !== 0 && row.advancedFilters.length !== 0)
      ) {
        return row;
      }
    });
    dimensionFilters = finalValidateFilterArray;
  }
  //** Backward Compatibility Code Ends Here **//

  const metricFilters = transformMetricFiltersBackendToUi(
    chartObject.requestParam.metricFilter || [],
    allData.plotlyDimensions
  );
  const orderByDetails = getOrderByDetailsFromOrderBy(
    chartObject.requestParam.orderBy
  );
  const dimensionsList = chartObject.requestParam.xAxis.map((originalId) =>
    getDimensionObjByOriginalID(originalId, allData.plotlyDimensions)
  );
  // const allMetrics = [
  //   ...chartObject.requestParam.yAxis,
  //   ...chartObject.requestParam.specialCalculation,
  //   ...chartObject.requestParam.approxCountDistinct,
  // ];
  const metricsList = formObject.metricOrder.map((row) =>
    getMeasureObjByOriginalID(row.id, allData.plotlyMetrics)
  );
  const recipientsListFiltered = formObject.recipientsList.filter(
    (row) => row !== userEmail
  );
  const recipientsList = [
    ...(variant === "adminReport" &&
    formObject.recipientsList.find((row) => row === userEmail)
      ? [...formObject.recipientsList]
      : variant === "adminReport" &&
        formObject.recipientsList.find((row) => row !== userEmail)
      ? [...recipientsListFiltered]
      : [unwrapperEmail(userEmail, userInstance), ...recipientsListFiltered]),
  ].map((email, index) => ({ id: email, name: email, deletable: index !== 0 }));
  const reportRowCount = operationTypeIdMapping[formObject.operationType];
  const reportCountFileTypeFinal = reportCountFileType.map((row) =>
    row.id === reportRowCount
      ? { ...row, selectedDropdown: formObject.fileFormat }
      : row
  );
  const initialReportFormData = {
    reportId: formObject.id,
    reportName: formObject.name,
    scheduleFlag: "no",
    timeFilters,
    triggerTimeRangeNumber: 1,
    triggerType: "daily",
    triggerDay: "monday",
    triggerTime: "09:00",
    triggerTimeZone: "UTC (+00:00)",
    triggerTimeRangeTimeZone: "UTC (+00:00)",
    isComparisonOn: formObject.isComparison,
    dimensionsList,
    metricsList,
    ...orderByDetails,
    preferredLanguage: formObject.userPreferredLanguage || "en",
    recipientsList,
    reportStatus: "active",
    reportCountFileType: reportCountFileTypeFinal,
    reportRowCount,
    duplicateReportNameFlag: false,
    dimensionFilters,
    metricFilters,
  };
  return initialReportFormData;
};

export const unwrapperScheduledReport = (props) => {
  const { payload, allData, user, activeReport, variant = "report" } = props;
  const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarReports;
  const userEmail = user?.reqMetadata?.email;
  const userInstance = user?.auth?.instances;
  const formObject = { ...payload };
  const chartObject = formObject.payload;
  const timeFiltersAction = updateTimeFiltersWithDefaultDates({
    selectedTimezone: getTimeZoneObj(
      chartObject.requestParam.timeZone.name,
      allTimezones
    ),
    startDateEpoch: allData.dateRange.data.startDate,
    endDateEpoch: allData.dateRange.data.endDate,
    allDatePresets,
    format: config.hardCoded.datetimeFormat,
    selectedDatePreset: "custom",
    daysLimit: calendarDaysLimits,
    isComparisonOn: formObject.isComparison,
  });
  const timeFilters = timeFiltersReducer({}, timeFiltersAction);
  let dimensionFilters = transformFiltersBackendToUi(
    chartObject.requestParam.filter || [],
    allData.plotlyDimensions
  );
  //** Backward Compatibility Code Start Here **//
  const {
    globalFiltersFileUpload = false,
    globalFiltersStringMatch = false,
    globalFiltersTimestamp = false,
  } = user.uiFeatureList;

  if (
    !globalFiltersStringMatch &&
    !globalFiltersFileUpload &&
    !globalFiltersTimestamp &&
    dimensionFilters.length > 0
  ) {
    var validityFlags = dimensionFilters.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            ![
              "startsWith",
              "exactlyMatches",
              "endsWith",
              "containsString",
              "containsWholeWord",
              "from",
              "on",
              "between",
              "before",
            ].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    !globalFiltersStringMatch &&
    globalFiltersFileUpload &&
    !globalFiltersTimestamp &&
    dimensionFilters.length > 0
  ) {
    var validityFlags = dimensionFilters.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            ![
              "startsWith",
              "endsWith",
              "containsString",
              "containsWholeWord",
              "from",
              "on",
              "between",
              "before",
            ].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    globalFiltersStringMatch &&
    !globalFiltersFileUpload &&
    !globalFiltersTimestamp &&
    dimensionFilters.length > 0
  ) {
    var validityFlags = dimensionFilters.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            !["exactlyMatches", "from", "on", "between", "before"].includes(
              item.type
            )
        ),
      };
      return newRow;
    });
  } else if (
    !globalFiltersStringMatch &&
    !globalFiltersFileUpload &&
    globalFiltersTimestamp &&
    dimensionFilters.length > 0
  ) {
    var validityFlags = dimensionFilters.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            ![
              "startsWith",
              "exactlyMatches",
              "endsWith",
              "containsString",
              "containsWholeWord",
            ].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    globalFiltersFileUpload &&
    !globalFiltersStringMatch &&
    globalFiltersTimestamp &&
    dimensionFilters.length > 0
  ) {
    var validityFlags = dimensionFilters.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) =>
            ![
              "startsWith",
              "endsWith",
              "containsString",
              "containsWholeWord",
            ].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    !globalFiltersFileUpload &&
    globalFiltersStringMatch &&
    globalFiltersTimestamp &&
    dimensionFilters.length > 0
  ) {
    var validityFlags = dimensionFilters.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) => !["exactlyMatches"].includes(item.type)
        ),
      };
      return newRow;
    });
  } else if (
    globalFiltersStringMatch &&
    globalFiltersFileUpload &&
    !globalFiltersTimestamp &&
    dimensionFilters.length > 0
  ) {
    var validityFlags = dimensionFilters.map((row) => {
      const newRow = {
        ...row,
        advancedFilters: row.advancedFilters.filter(
          (item) => !["from", "on", "between", "before"].includes(item.type)
        ),
      };
      return newRow;
    });
  }
  if (validityFlags) {
    var finalValidateFilterArray = validityFlags.filter((row) => {
      if (
        (row.values.length !== 0 && row.advancedFilters.length === 0) ||
        (row.values.length === 0 && row.advancedFilters.length !== 0) ||
        (row.values.length !== 0 && row.advancedFilters.length !== 0)
      ) {
        return row;
      }
    });
    dimensionFilters = finalValidateFilterArray;
  }
  //** Backward Compatibility Code Ends Here **//

  const metricFilters = transformMetricFiltersBackendToUi(
    chartObject.requestParam.metricFilter || [],
    allData.plotlyDimensions
  );
  const orderByDetails = getOrderByDetailsFromOrderBy(
    chartObject.requestParam.orderBy
  );
  const dimensionsList = chartObject.requestParam.xAxis.map((originalId) =>
    getDimensionObjByOriginalID(originalId, allData.plotlyDimensions)
  );
  // const allMetrics = [
  //   ...chartObject.requestParam.yAxis,
  //   ...chartObject.requestParam.specialCalculation,
  //   ...chartObject.requestParam.approxCountDistinct,
  // ];
  const metricsList = formObject.metricOrder.map((row) =>
    getMeasureObjByOriginalID(row.id, allData.plotlyMetrics)
  );
  const recipientsListFiltered = formObject.recipients.filter(
    (row) => row !== userEmail
  );

  const recipientsList = [
    ...(variant === "adminReport" &&
    formObject.recipients.find((row) => row === userEmail)
      ? [...formObject.recipients]
      : variant === "adminReport" &&
        formObject.recipients.find((row) => row !== userEmail)
      ? [...recipientsListFiltered]
      : [unwrapperEmail(userEmail, userInstance), ...recipientsListFiltered]),
  ].map((email, index) => ({ id: email, name: email, deletable: index !== 0 }));
  const reportRowCount = formObject.miscellaneous
    ? scheduledReportTypeIdMapping[formObject.miscellaneous.reportType]
    : scheduledReportTypeIdMapping["scheduleRecords"]; //hard coded due to bad api design
  let reportCountFileTypeFinal = reportCountFileType.map((row) =>
    row.id === reportRowCount
      ? { ...row, selectedDropdown: formObject.fileFormat, bucketId: "initial" }
      : { ...row, bucketId: "initial" }
  );

  // Hack
  if (formObject.miscellaneous?.writeToBucket) {
    if (
      formObject.miscellaneous.bucketId === undefined ||
      formObject.miscellaneous.bucketId === null
    ) {
      reportCountFileTypeFinal = reportCountFileTypeFinal.map((el) =>
        el.id === "fifty_thousand_records"
          ? {
              ...el,
              selectedDropdown: "sendLink",
              bucketId: formObject.miscellaneous?.bucketId || "initial",
            }
          : { ...el }
      );
    } else if (formObject.miscellaneous.bucketId !== null) {
      reportCountFileTypeFinal = reportCountFileTypeFinal.map((el) =>
        el.id === "fifty_thousand_records"
          ? {
              ...el,
              selectedDropdown: "writeToBucket",
              bucketId: formObject.miscellaneous?.bucketId || "initial",
            }
          : { ...el }
      );
    }
  }

  const triggerTime = makeTriggerTimeStringFromObject(formObject.triggerTime);
  const initialReportFormData = {
    reportId: formObject._id,
    reportName: formObject.reportName,
    scheduleFlag: "yes",
    timeFilters,
    triggerTimeRangeNumber: formObject.timeRange,
    triggerType: formObject.triggerType,
    triggerDay:
      formObject.triggerType === "weekly" ? formObject.triggerDay : "monday",
    triggerTime,
    triggerTimeZone: formObject.triggerTimeZone.name,
    triggerTimeRangeTimeZone: chartObject.requestParam.timeZone.name,
    isComparisonOn: formObject.isComparison,
    dimensionsList,
    metricsList,
    ...orderByDetails,
    preferredLanguage: formObject.userPreferredLanguage || "en",
    recipientsList,
    reportStatus: formObject.reportStatus,
    reportCountFileType: reportCountFileTypeFinal,
    reportRowCount,
    duplicateReportNameFlag: false,
    dimensionFilters,
    metricFilters,
  };
  return initialReportFormData;
};

export const wrapperSavedReport = (props) => {
  const {
    activeReportFormData,
    user,
    globalFilters,
    activeReport,
    addSavedReportFlag = false,
    variant,
  } = props;

  const reportFormData = activeReportFormData.reportFormData;
  const reportPayload = activeReportFormData.reportPayload; //if this has any key, it means it's on edit stage else add stage
  const metricOrder = reportFormData.metricsList.map((row) => ({
    id: row._id,
    metricType: getMetricTypeFromMetricId(row._id),
  }));
  const recipientsList =
    variant === "adminReport"
      ? [...reportFormData.recipientsList.map((row) => row.name)]
      : [
          user?.reqMetadata?.email,
          ...reportFormData.recipientsList.slice(1).map((row) => row.name),
        ];
  const reportCountFileTypeSelectedObj =
    reportFormData.reportCountFileType.find(
      (row) => row.id === reportFormData.reportRowCount
    );
  const savedReportTimeZoneObj = getTimeZoneObj(
    reportFormData.triggerTimeRangeTimeZone,
    allTimezones
  );
  const savedReportTimeZone = {
    name: savedReportTimeZoneObj.name,
    location: savedReportTimeZoneObj.location,
  };
  const fileFormat = reportCountFileTypeSelectedObj.selectedDropdown;
  const operationType =
    operationTypeIdMappingInverse[reportCountFileTypeSelectedObj.id];
  const chartObject = masterMakeChartObject({
    metadataParams: {
      dataLimit: reportCountFileTypeSelectedObj.limit,
      title: reportFormData.reportId,
    },
    filters: {
      timeFilters: reportFormData.timeFilters,
      metricFilters: reportFormData.metricFilters,
      dimensionFilters: reportFormData.dimensionFilters,
    },
    orderByDetails: {
      orderById: reportFormData.orderById,
      orderByType: reportFormData.orderByType,
      orderBy: reportFormData.orderBy,
    },
    dimensionsList: reportFormData.dimensionsList,
    metricsList: reportFormData.metricsList,
    granularity: globalFilters?.metricChartGranularity?.value,
  });
  const userPreferredLanguage =
    sigviewUserType === "sigview"
      ? {}
      : { userPreferredLanguage: reportFormData.preferredLanguage || "en" }; //hard coded
  let payload = {
    email:
      variant === "adminReport"
        ? reportFormData.recipientsList[0].name
        : user?.reqMetadata?.email,
    orgViewReq:
      variant === "adminReport"
        ? reportPayload.orgViewReq
        : {
            organization: user?.reqMetadata?.organization,
            view: user?.reqMetadata?.view,
          },
    recentDownloadRecord: {
      id: reportFormData.reportId,
      name: reportFormData.reportName,
      payload: chartObject,
      metricOrder,
      createdOn:
        reportPayload?.recentDownloadRecord?.createdOn ||
        new Date().valueOf().toString(),
      isComparison: reportFormData.isComparisonOn,
      recipientsList,
      fileFormat,
      operationType,
      ...userPreferredLanguage,
    },
  };
  if (addSavedReportFlag && activeReport.type !== "download") {
    payload = {
      ...payload,
      recentDownloadRecord: {
        ...payload.recentDownloadRecord,
        payload: {
          ...payload.recentDownloadRecord.payload,
          requestParam: {
            ...payload.recentDownloadRecord.payload.requestParam,
            timeZone: savedReportTimeZone,
          },
        },
      },
    };
  }
  return payload;
};

export const wrapperDownloadReport = (props) => {
  const { activeReportFormData, user, globalFilters } = props;
  const sigviewUserType = getSigviewUserType();
  const reportFormData = activeReportFormData.reportFormData;
  // const reportPayload = activeReportFormData.reportPayload; //if this has any key, it means it's on edit stage else add stage
  const metricOrder = reportFormData.metricsList.map((row) => ({
    id: row._id,
    metricType: getMetricTypeFromMetricId(row._id),
  }));
  const recipientsList = [
    user?.reqMetadata?.email,
    ...reportFormData.recipientsList.slice(1).map((row) => row.name),
  ];
  const reportCountFileTypeSelectedObj =
    reportFormData.reportCountFileType.find(
      (row) => row.id === reportFormData.reportRowCount
    );
  const fileFormat = reportCountFileTypeSelectedObj.selectedDropdown;
  const downloadType =
    operationTypeIdMappingInverse[reportCountFileTypeSelectedObj.id];
  const chartObject = masterMakeChartObjectReport({
    metadataParams: {
      dataLimit: reportCountFileTypeSelectedObj.limit,
      title: reportFormData.reportId,
    },
    filters: {
      timeFilters: reportFormData.timeFilters,
      metricFilters: reportFormData.metricFilters,
      dimensionFilters: reportFormData.dimensionFilters,
    },
    orderByDetails: {
      orderById: reportFormData.orderById,
      orderByType: reportFormData.orderByType,
      orderBy: reportFormData.orderBy,
    },
    dimensionsList: reportFormData.dimensionsList,
    metricsList: reportFormData.metricsList,
    granularity: globalFilters?.metricChartGranularity?.value,
  });

  const userPreferredLanguage =
    sigviewUserType === "sigview"
      ? {}
      : { userPreferredLanguage: reportFormData.preferredLanguage || "en" }; //hard coded
  const payload = {
    requestId: new Date().valueOf().toString(),
    feature: "download-email", //hard coded
    name: reportFormData.reportName,
    payload: chartObject,
    metricOrder,
    orgViewReq: {
      organization: user?.reqMetadata?.organization,
      view: user?.reqMetadata?.view,
    },
    isComparisonOn: reportFormData.isComparisonOn,
    isSave: true,
    recipients: recipientsList,
    fileFormat,
    downloadType,
    ...userPreferredLanguage,
  };
  return payload;
};

export const wrapperReportOverEmail = (props) => {
  const { activeReportFormData, user, globalFilters } = props;
  const sigviewUserType = getSigviewUserType();
  const reportFormData = activeReportFormData.reportFormData;
  // const reportPayload = activeReportFormData.reportPayload; //if this has any key, it means it's on edit stage else add stage
  const metricOrder = reportFormData.metricsList.map((row) => ({
    id: row._id,
    metricType: getMetricTypeFromMetricId(row._id),
  }));
  const recipientsList = [
    user?.reqMetadata?.email,
    ...reportFormData.recipientsList.slice(1).map((row) => row.name),
  ];
  const reportCountFileTypeSelectedObj =
    reportFormData.reportCountFileType.find(
      (row) => row.id === reportFormData.reportRowCount
    );
  const fileFormat = reportCountFileTypeSelectedObj.selectedDropdown;
  const chartObject = masterMakeChartObject({
    metadataParams: {
      dataLimit: reportCountFileTypeSelectedObj.limit,
      title: reportFormData.reportId,
    },
    filters: {
      timeFilters: reportFormData.timeFilters,
      metricFilters: reportFormData.metricFilters,
      dimensionFilters: reportFormData.dimensionFilters,
    },
    orderByDetails: {
      orderById: reportFormData.orderById,
      orderByType: reportFormData.orderByType,
      orderBy: reportFormData.orderBy,
    },
    dimensionsList: reportFormData.dimensionsList,
    metricsList: reportFormData.metricsList,
    granularity: globalFilters?.metricChartGranularity?.value,
  });
  const userPreferredLanguage =
    sigviewUserType === "sigview"
      ? {}
      : { userPreferredLanguage: reportFormData.preferredLanguage || "en" }; //hard coded
  const payload = {
    downloadType: "SendEmails", //hard coded
    feature: "download-email", //hard coded
    isSave: true, //hard coded
    fileFormat,
    isComparisonOn: reportFormData.isComparisonOn,
    name: reportFormData.reportName,
    payload: chartObject,
    orgViewReq: {
      organization: user?.reqMetadata?.organization,
      view: user?.reqMetadata?.view,
    },
    metricOrder,
    requestId: new Date().valueOf().toString(),
    recipients: recipientsList,
    ...userPreferredLanguage,
  };
  return payload;
};

export const wrapperScheduledReport = (props) => {
  const {
    activeReportFormData,
    user,
    allData,
    globalFilters,
    addScheduleReportFlag = false,
    updateScheduleReportBtnFlag = false,
    variant,
  } = props;
  console.log("wrapperScheduledReport", props);
  const sigviewUserType = getSigviewUserType();
  const reportFormData = activeReportFormData.reportFormData;
  const reportPayload = activeReportFormData.reportPayload; //if this has any key, it means it's on edit stage else add stage
  const metricOrder = reportFormData.metricsList.map((row) => ({
    id: row._id,
    metricType: getMetricTypeFromMetricId(row._id),
  }));
  const recipientsList =
    variant === "adminReport"
      ? [...reportFormData.recipientsList.map((row) => row.name)]
      : [
          user?.reqMetadata?.email,
          ...reportFormData.recipientsList.slice(1).map((row) => row.name),
        ];
  const reportCountFileTypeSelectedObj =
    reportFormData.reportCountFileType.find(
      (row) => row.id === reportFormData.reportRowCount
    );
  const fileFormat =
    reportCountFileTypeSelectedObj.selectedDropdown === "sendLink" ||
    reportCountFileTypeSelectedObj.selectedDropdown === "writeToBucket"
      ? "CSV"
      : reportCountFileTypeSelectedObj.selectedDropdown;
  const reportType =
    scheduledReportTypeIdMappingInverse[reportCountFileTypeSelectedObj.id];
  const chartObject = masterMakeChartObject({
    metadataParams: {
      dataLimit: reportCountFileTypeSelectedObj.limit,
      title: reportFormData.reportId,
    },
    filters: {
      timeFilters: reportFormData.timeFilters,
      metricFilters: reportFormData.metricFilters,
      dimensionFilters: reportFormData.dimensionFilters,
    },
    orderByDetails: {
      orderById: reportFormData.orderById,
      orderByType: reportFormData.orderByType,
      orderBy: reportFormData.orderBy,
    },
    dimensionsList: reportFormData.dimensionsList,
    metricsList: reportFormData.metricsList,
    settings: { compareFlag: false },
    granularity: globalFilters?.metricChartGranularity?.value,
  });
  const triggerTimeZoneObj = getTimeZoneObj(
    reportFormData.triggerTimeZone,
    allTimezones
  );
  const triggerTimeZone = {
    name: triggerTimeZoneObj.name,
    location: triggerTimeZoneObj.location,
  };
  const scheduleReportTimeZoneObj = getTimeZoneObj(
    reportFormData.triggerTimeRangeTimeZone,
    allTimezones
  );
  const scheduleReportTimeZone = {
    name: scheduleReportTimeZoneObj.name,
    location: scheduleReportTimeZoneObj.location,
  };
  const allSelectedItems = [
    ...reportFormData.metricFilters.map((row) => ({
      id: row.metricId,
      title: getMeasureObjByOriginalID(row.metricId, allData.plotlyMetrics)
        .measureTitle,
    })),
    ...reportFormData.dimensionFilters.map((row) => ({
      id: row.metadata._id,
      title: row.metadata.dimTitle,
    })),
    ...reportFormData.dimensionsList.map((row) => ({
      id: row._id,
      title: row.dimTitle,
    })),
    ...reportFormData.metricsList.map((row) => ({
      id: row._id,
      title: row.measureTitle,
    })),
  ];
  const uniqueSelectedItems = getUniqueArrayByKey(allSelectedItems, "id");

  let bucketIdValue = null;
  if (
    reportFormData.reportRowCount === "fifty_thousand_records" &&
    reportCountFileTypeSelectedObj.selectedDropdown === "writeToBucket"
  ) {
    bucketIdValue = reportCountFileTypeSelectedObj.bucketId;

    if ([undefined, "initial"].includes(bucketIdValue)) {
      bucketIdValue = null;
    }
  }

  const miscellaneous =
    sigviewUserType === "sigview"
      ? {}
      : {
          miscellaneous: {
            userType: user?.reqMetadata?.userType,
            reportType,
            alias: uniqueSelectedItems,
            writeToBucket:
              (reportFormData.reportRowCount === "fifty_thousand_records" &&
                reportCountFileTypeSelectedObj.selectedDropdown ===
                  "sendLink") ||
              (reportFormData.reportRowCount === "fifty_thousand_records" &&
                reportCountFileTypeSelectedObj.selectedDropdown ===
                  "writeToBucket"),

            bucketId: bucketIdValue,
          },
        };
  const triggerDay =
    reportFormData.triggerType === "weekly" //hard coded
      ? reportFormData.triggerDay
      : getFrequencyObjectFromId(reportFormData.triggerType).helperText;
  const lastJobSubmissionTime =
    reportPayload?.lastJobSubmissionTime || String(0);
  const userPreferredLanguage =
    sigviewUserType === "sigview"
      ? {}
      : { userPreferredLanguage: reportFormData.preferredLanguage || "en" }; //hard coded
  let payload = {
    _id: reportFormData.reportId,
    reportName: reportFormData.reportName,
    reportStatus: reportFormData.reportStatus,
    loginInfo:
      variant === "adminReport"
        ? {
            ...reportPayload.loginInfo,
            providerKey: reportFormData.recipientsList[0].name,
          }
        : {
            providerID: "credentials",
            providerKey: user?.reqMetadata?.email,
          },
    orgViewReq:
      variant === "adminReport"
        ? reportPayload.orgViewReq
        : {
            organization: user?.reqMetadata?.organization,
            view: user?.reqMetadata?.view,
          },
    payload: chartObject,
    metricOrder,
    createdOn: reportPayload?.createdOn || new Date().valueOf().toString(),
    isComparison: reportFormData.isComparisonOn,
    limit: reportCountFileTypeSelectedObj.limit,
    recipients: recipientsList,
    fileFormat,
    ...userPreferredLanguage,
    timeRange: reportFormData.triggerTimeRangeNumber,
    //triggerTimeRangeTimeZone: scheduleReportTimeZone,
    modifiedOn: reportPayload?.modifiedOn || new Date().valueOf().toString(),
    triggerType: reportFormData.triggerType,
    triggerDay,
    triggerTime: {
      hours: parseInt(reportFormData.triggerTime.slice(0, 2)),
      minutes: 0,
    },
    statusToggleTime:
      reportPayload?.statusToggleTime || new Date().valueOf().toString(),
    triggerTimeZone,
    ...miscellaneous,
    lastJobSubmissionTime,
  };
  if (addScheduleReportFlag || updateScheduleReportBtnFlag) {
    payload = {
      ...payload,
      payload: {
        ...payload.payload,
        requestParam: {
          ...payload.payload.requestParam,
          timeZone: scheduleReportTimeZone,
        },
      },
    };
  }
  return payload;
};

export const wrapperReportLinkOverEmail = (props) => {
  const { activeReportFormData, user, allData, globalFilters } = props;
  const sigviewUserType = getSigviewUserType();
  const reportFormData = activeReportFormData.reportFormData;
  // const reportPayload = activeReportFormData.reportPayload; //if this has any key, it means it's on edit stage else add stage
  const metricOrder = reportFormData.metricsList.map((row) => ({
    id: row._id,
    metricType: getMetricTypeFromMetricId(row._id),
  }));
  const recipientsList = [
    user?.reqMetadata?.email,
    ...reportFormData.recipientsList.slice(1).map((row) => row.name),
  ];
  const reportCountFileTypeSelectedObj =
    reportFormData.reportCountFileType.find(
      (row) => row.id === reportFormData.reportRowCount
    );
  const fileFormat = reportCountFileTypeSelectedObj.selectedDropdown;
  const chartObject = masterMakeChartObject({
    metadataParams: {
      dataLimit: reportCountFileTypeSelectedObj.limit,
      title: reportFormData.reportId,
    },
    filters: {
      timeFilters: reportFormData.timeFilters,
      metricFilters: reportFormData.metricFilters,
      dimensionFilters: reportFormData.dimensionFilters,
    },
    orderByDetails: {
      orderById: reportFormData.orderById,
      orderByType: reportFormData.orderByType,
      orderBy: reportFormData.orderBy,
    },
    dimensionsList: reportFormData.dimensionsList,
    metricsList: reportFormData.metricsList,
    granularity: globalFilters?.metricChartGranularity?.value,
  });
  const allSelectedItems = [
    ...reportFormData.metricFilters.map((row) => ({
      id: row.metricId,
      title: getMeasureObjByOriginalID(row.metricId, allData.plotlyMetrics)
        .measureTitle,
    })),
    ...reportFormData.dimensionFilters.map((row) => ({
      id: row.metadata._id,
      title: row.metadata.dimTitle,
    })),
    ...reportFormData.dimensionsList.map((row) => ({
      id: row._id,
      title: row.dimTitle,
    })),
    ...reportFormData.metricsList.map((row) => ({
      id: row._id,
      title: row.measureTitle,
    })),
  ];
  const uniqueSelectedItems = getUniqueArrayByKey(allSelectedItems, "id");
  const userPreferredLanguage =
    sigviewUserType === "sigview"
      ? {}
      : { userPreferredLanguage: reportFormData.preferredLanguage || "en" }; //hard coded
  const endpoint =
    sigviewUserType === "sigview"
      ? {
          endpoint: {
            host: "localhost",
            port: 1000,
            protocol: "http",
            url: "api/v1/successMockEndpoint",
          },
        }
      : {}; //hard coded
  const payload = {
    _id: reportFormData.reportId,
    chartObject,
    downloadType: "SendLinks", //hard coded
    feature: "FullReport-email", //hard coded
    emailId: user?.reqMetadata?.email,
    isSave: true, //hard coded
    metricOrder,
    name: reportFormData.reportName,
    orgViewReq: {
      organization: user?.reqMetadata?.organization,
      view: user?.reqMetadata?.view,
    },
    ...userPreferredLanguage,
    reportObject: {
      fileFormat,
      recipients: recipientsList,
      timeToLive: 604800000,
      alias: uniqueSelectedItems,
      ...endpoint,
    },
  };
  return payload;
};

const getDefaultNewName = (text, length) => `${text} ${length + 1}`;

export const getSelectedReportPayload = (props) => {
  const {
    activeReport,
    allReports,
    allData,
    user,
    globalFilters,
    variant = "report",
  } = props;
  const activeReportType = activeReport.type;
  let reportPayload = {};
  let reportFormData = {};
  try {
    if (activeReport.variant === "gotoReport") {
      reportFormData = unwrapperGotoReport({
        user,
        activeReport,
        selections: activeReport.selections,
        // newReportName: getDefaultNewName(
        //   "Report",
        //   allReports.savedReports.length
        // ),
        newReportName: activeReport.selections.title,
      });
      return { reportPayload: undefined, reportFormData, valid: true };
    } else if (activeReportType === "saved") {
      reportPayload = allReports.savedReports.find(
        (row) => row.recentDownloadRecord.id === activeReport.id
      );
      if (reportPayload) {
        //HARD CODED
        //For urgent release of feature, if bulkFilters is present in the object, don't show the report
        const isBulkFieldsFieldPresent =
          reportPayload.recentDownloadRecord.payload.requestParam.bulkFilters;
        const isBulkFiltersApplied = isBulkFieldsFieldPresent
          ? isBulkFieldsFieldPresent.length > 0
            ? true
            : false
          : false;
        if (isBulkFiltersApplied) {
          console.groupCollapsed("CORRUPT REPORT");
          console.log("PROPS", props);
          console.groupEnd();
          return { reportPayload, reportFormData, valid: false };
        }
        reportFormData = unwrapperSavedReport({
          payload: reportPayload,
          allData,
          user,
          activeReport,
          variant,
        });
      } else {
        reportFormData = getNewReportFormData({
          user,
          activeReport,
          newReportName: getDefaultNewName(
            "Report",
            allReports.savedReports.length
          ),
        });
        reportFormData = {
          ...reportFormData,
          dimensionFilters: globalFilters.dimensionFilters,
          timeFilters: globalFilters.timeFilters,
          triggerTimeRangeTimeZone:
            globalFilters.timeFilters.selectedTimezone.name,
          triggerTimeZone: globalFilters.timeFilters.selectedTimezone.name,
          isComparisonOn: globalFilters.timeFilters.isComparisonOn,
        };
      }
    } else if (activeReportType === "scheduled") {
      reportPayload = allReports.scheduledReports.find(
        (row) => row._id === activeReport.id
      );
      if (reportPayload) {
        reportFormData = unwrapperScheduledReport({
          payload: reportPayload,
          allData,
          user,
          activeReport,
          variant,
        });
        //HARD CODED
        //For urgent release of feature, if bulkFilters is present in the object, don't show the report
        const isBulkFieldsFieldPresent =
          reportPayload.payload.requestParam.bulkFilters;
        const isBulkFiltersApplied = isBulkFieldsFieldPresent
          ? isBulkFieldsFieldPresent.length > 0
            ? true
            : false
          : false;
        if (isBulkFiltersApplied) {
          console.groupCollapsed("CORRUPT REPORT");
          console.log("PROPS", props);
          console.groupEnd();
          return { reportPayload, reportFormData, valid: false };
        }
      } else {
        reportFormData = getNewReportFormData({
          user,
          activeReport,
          newReportName: getDefaultNewName(
            "Report",
            allReports.scheduledReports.length
          ),
        });
        reportFormData = {
          ...reportFormData,
          dimensionFilters: globalFilters.dimensionFilters,
          timeFilters: globalFilters.timeFilters,
          triggerTimeRangeTimeZone:
            globalFilters.timeFilters.selectedTimezone.name,
          triggerTimeZone: globalFilters.timeFilters.selectedTimezone.name,
          isComparisonOn: globalFilters.timeFilters.isComparisonOn,
        };
      }
    } else if (activeReportType === "download") {
      reportPayload = allReports.reportsDownloadHistory.find(
        (row) => row.id === activeReport.id
      );
      if (reportPayload) {
        const porpsUnwrapperSavedReport = {
          payload: reportPayload,
          allData,
          user,
          activeReport,
        };
        reportFormData = unwrapperSavedReport(porpsUnwrapperSavedReport);

        // ! To cater the Bussiness requirement ot off the comparision in all_records
        // ! To solve the infinite loop Bug because there is difference in isComparisonOn in timeFilter and
        reportFormData = unwrapperAllRecordsComparisionOff(reportFormData);

        //HARD CODED
        //For urgent release of feature, if bulkFilters is present in the object, don't show the report
        const isBulkFieldsFieldPresent =
          reportPayload.payload.requestParam.bulkFilters;
        const isBulkFiltersApplied = isBulkFieldsFieldPresent
          ? isBulkFieldsFieldPresent.length > 0
            ? true
            : false
          : false;
        if (isBulkFiltersApplied) {
          console.groupCollapsed("CORRUPT REPORT");
          console.log("PROPS", props);
          console.groupEnd();
          return { reportPayload, reportFormData, valid: false };
        }
      } else {
        reportFormData = getNewReportFormData({
          user,
          activeReport,
          newReportName: getDefaultNewName(
            "Report",
            allReports.reportsDownloadHistory.length
          ),
        });
      }
    } else {
      //Shouldn't come here
      // console.log("Shouldn't come here.");
      // console.log("activeReport", activeReport);
      // console.log("allReports", allReports);
    }
    return { reportPayload, reportFormData, valid: true };
  } catch (error) {
    console.groupCollapsed("UI ERROR");
    console.log(error);
    console.groupEnd();
    return { reportPayload, reportFormData, valid: false, error: error };
  }
};

const getNewReportFormData = (props) => {
  const { user, activeReport, newReportName } = props;

  const userEmail = user?.reqMetadata?.email;
  const userInstance = user?.auth?.data?.instances;
  const recipientsList = [unwrapperEmail(userEmail, userInstance)].map(
    (email, index) => ({
      id: email,
      name: email,
      deletable: index !== 0,
    })
  );

  const newInitialFormData = {
    reportId: activeReport.id,
    reportName: newReportName,
    scheduleFlag: activeReport.type === "saved" ? "no" : "yes",
    timeFilters: user.timeFilters,
    triggerTimeRangeNumber: 1,
    triggerType: "daily",
    triggerDay: "monday",
    triggerTime: "09:00",
    triggerTimeZone: "UTC (+00:00)",
    triggerTimeRangeTimeZone: "UTC (+00:00)",
    isComparisonOn: false,
    dimensionsList: [],
    metricsList: [],
    orderById: "",
    orderBy: "desc",
    orderByType: "id_only",
    preferredLanguage: "en",
    recipientsList,
    reportStatus: "active",
    reportCountFileType,
    reportRowCount: "five_thousand_records",
    duplicateReportNameFlag: false,
    dimensionFilters: [],
    metricFilters: [],
  };

  return newInitialFormData;
};

const unwrapperGotoReport = (props) => {
  const { user, activeReport, newReportName, selections = {} } = props;
  const {
    timeFilters = {},
    dimensionsList = [],
    metricsList = [],
    dimensionFilters = [],
  } = selections;

  const userEmail = user?.reqMetadata?.email;
  const userInstance = user?.auth?.instances;
  const recipientsList = [unwrapperEmail(userEmail, userInstance)].map(
    (email, index) => ({
      id: email,
      name: email,
      deletable: index !== 0,
    })
  );

  const newInitialFormData = {
    reportId: activeReport.id,
    reportName: newReportName,
    scheduleFlag: "yes",
    timeFilters: timeFilters,
    isComparisonOn: timeFilters.isComparisonOn,
    triggerTimeRangeNumber: 1,
    triggerType: "daily",
    triggerDay: "monday",
    triggerTime: "09:00",
    triggerTimeZone: timeFilters.selectedTimezone.name,
    triggerTimeRangeTimeZone: timeFilters.selectedTimezone.name,
    dimensionsList,
    metricsList,
    orderById: "",
    orderBy: "desc",
    orderByType: "id_only",
    preferredLanguage: "en",
    recipientsList,
    reportStatus: "active",
    reportCountFileType,
    reportRowCount: "five_thousand_records",
    duplicateReportNameFlag: false,
    dimensionFilters,
    metricFilters: [],
  };

  return newInitialFormData;
};

const makeTriggerTimeStringFromObject = (obj) => {
  const hours = obj.hours < 10 ? `0${obj.hours}` : `${obj.hours}`;
  const time = `${hours}:00`;
  return time;
};

export const makeSubtitleForScheduledReport = (
  reportRow,
  triggerFrequencyArr
) => {
  const currFreqObject = triggerFrequencyArr.find(
    (row) => row.id === reportRow.triggerType
  );
  const time = makeTriggerTimeStringFromObject(reportRow.triggerTime);
  const timezone = reportRow.triggerTimeZone.name;
  const subtitle =
    (reportRow.triggerType === "weekly"
      ? `${currFreqObject.helperText} ${capitalize(reportRow.triggerDay)}`
      : reportRow.triggerType === "daily"
      ? `${capitalize(currFreqObject.helperText)}`
      : currFreqObject.helperText) + ` at ${time} ${timezone}`;
  return subtitle;
};

export const isOrderByTypeValid = (orderById, orderByType) => {
  //for metrics and custom metrics, all values are valid
  if (orderById.startsWith("C") || orderById.startsWith("M")) return true;
  //for dimensions, only id_only is valid
  if (orderById.startsWith("D") && orderByType !== "id_only") return false;
  return true;
};

export const validateReportName = (props) => {
  const {
    reportName = "",
    // reportType = "saved/scheduled",
    // allSavedReportNames = [],
    // allScheduledReportNames = [],
    // originalName,
  } = props;
  //Common Validations
  const sigviewUserType = getSigviewUserType();
  const runCheckFlag =
    sigviewUserType === "sigview"
      ? !/^[A-z0-9-_ ]*$/.test(reportName)
      : !/^[a-zA-Z0-9-_!@#$%^&*()+=~|:;',./<>? \u3040-\u309F\u30A0-\u30FF-\u31F0-\u31FF-\u2E80-\u2EFF-\u2F00-\u2FDF-\u3000-\u303F-\u31C0-\u31EF-\u3200-\u32FF-\u3300-\u33FF-\u3400-\u3FFF-\u4000-\u4DBF-\u4E00-\u4FFF]*$/.test(
          reportName
        );
  if (runCheckFlag)
    return {
      flag: true,
      message: "Report name cannot have special characters",
    };

  if (reportName.length < 1)
    return {
      flag: true,
      message: "Report name must have at least 1 character",
    };

  if (reportName.length > 100)
    return {
      flag: true,
      message: "Report name cannot exceed 100 characters",
    };

  //Name duplication in Saved Reports
  //Not to show it in case the same report is open
  //In case user opens Report 1, in that case we won't show the error that same name exists
  //We will get the originalName from props
  //If originalName is same as reportName and originalName.length >0, in that case, error is not valid
  // const sameReportNameFlag =
  //   originalName.length > 0 && originalName === reportName;
  // if (
  //   allSavedReportNames.includes(reportName.trim()) &&
  //   reportType === "saved" &&
  //   !sameReportNameFlag
  // )
  //   return {
  //     flag: true,
  //     message: "Report name already exists in saved reports",
  //   };

  //Name duplication in Scheduled Reports
  //Not to show it in case the same report is open
  // if (
  //   allScheduledReportNames.includes(reportName.trim()) &&
  //   reportType === "scheduled" &&
  //   !sameReportNameFlag
  // )
  //   return {
  //     flag: true,
  //     message: "Report name already exists in scheduled reports",
  //   };

  return { flag: false, message: "" };
};

const validateMetricsList = (metricsList) => {
  if (metricsList.length === 0)
    return { flag: true, message: "Atleast one metric should be selected" };

  return { flag: false, message: "" };
};

const validateDimensionsList = (reportFormData) => {
  const { timeFilters, dimensionsList, reportRowCount } = reportFormData;
  if (dimensionsList.length === 0)
    return { flag: true, message: "Atleast one dimension should be selected" };

  const durationInDays =
    (timeFilters.selectedDatesQE.endDate -
      timeFilters.selectedDatesQE.startDate) /
    (1000 * 60 * 60 * 24);
  const allDimensionTitles = dimensionsList.map((row) =>
    row.dimTitle.toLowerCase()
  );
  const isDaySelected = allDimensionTitles.includes("day");
  const isHourSelected = allDimensionTitles.includes("hour");
  const isFullReport = reportRowCount === "all_records";
  //If duration is greater than 7 days and hour is selected and it's a full report
  if (durationInDays > 7 && isHourSelected && isFullReport)
    return {
      flag: true,
      message:
        "Hour is not permitted for full data reports when more than 7 days are selected",
    };

  //If duration is greater than 3 months and day is selected and it's a full report
  if (durationInDays > 90 && isDaySelected && isFullReport)
    return {
      flag: true,
      message:
        "Day is not permitted for full data reports when more than 3 months are selected",
    };

  return { flag: false, message: "" };
};

export const validateReportFormData = (props) => {
  const {
    reportFormData = {},
    // allSavedReportNames = [],
    // allScheduledReportNames = [],
    // originalName = "",
  } = props;

  //Validating report name
  const reportName = validateReportName({
    reportName: reportFormData.reportName,
    // reportType: reportFormData.scheduleFlag === "yes" ? "scheduled" : "saved",
    // allSavedReportNames,
    // allScheduledReportNames,
    // originalName,
  });
  const dimensionsList = validateDimensionsList(reportFormData);
  const metricsList = validateMetricsList(reportFormData.metricsList);

  //If anyone of them is true, reportFormData is invalid
  const flag = reportName.flag || dimensionsList.flag || metricsList.flag;
  const response = {
    flag,
    message: {
      reportName,
      dimensionsList,
      metricsList,
    },
  };
  return response;
};

const formatDateForReportManagerQuery = (epoch, selectedTimezone) =>
  moment(epoch)
    .utcOffset(selectedTimezone.minutesOffset)
    .format(config.hardCoded.reportManagerQueryFormat);

const replaceLastOccurenceOf = (text, trimText) =>
  text.replace(new RegExp(`${trimText}$`, "g"), "");

const makeReportManagerQueryForDimensionFilters = (dimensionFilters) => {
  let text = "";
  const selectedTimezone = {
    name: "UTC (+00:00)",
    location: "UTC",
    value: {
      hours: 9,
      minutes: 0,
    },
    minutesOffset: 0,
  };
  for (let row of dimensionFilters) {
    const rowIndex = dimensionFilters.indexOf(row);
    let filterTypeText = row.filterType === "exclude" ? "NOT IN" : "IN";
    let valuesText = replaceLastOccurenceOf(
      row.values.reduce((finalText, value) => `'${value}', ${finalText}`, ""),
      ", "
    );
    let basicFiltersText =
      row.values.length > 0
        ? `<span class="quantity-dimension">${row.metadata.dimTitle}</span> <span class="query-keyword">${filterTypeText}</span> (${valuesText})`
        : "";
    let adFiltersText = "";
    for (let adRow of row.advancedFilters) {
      // "startsWith": "stringMatch",
      // "endsWith": "stringMatch",
      // "containsString": "stringMatch",
      // "containsWholeWord": "stringMatch",
      // "exactlyMatches": "fileUpload",
      // "from": "dateSingleSelect",
      // "before": "dateSingleSelect",
      // "on": "dateSingleSelect",
      // "between": "dateRangeSelect"
      switch (adRow.uiCompType) {
        case "dateRangeSelect":
          var startDate = formatDateForReportManagerQuery(
            adRow.value.startDate.epoch,
            selectedTimezone
          );
          var endDate = formatDateForReportManagerQuery(
            adRow.value.endDate.epoch,
            selectedTimezone
          );
          adFiltersText = `${adFiltersText}<span class="quantity-dimension">${row.metadata.dimTitle}</span> <span class="query-keyword">BETWEEN</span> ${startDate} <span class="query-keyword">AND</span> ${endDate} <span class="query-keyword">OR</span> `;
          break;
        case "dateSingleSelect":
          var date = formatDateForReportManagerQuery(
            adRow.value.startDate.epoch,
            selectedTimezone
          );
          adFiltersText = `${adFiltersText}<span class="quantity-dimension">${
            row.metadata.dimTitle
          }</span> <span class="query-keyword">${adRow.type.toUpperCase()}</span> ${date} <span class="query-keyword">OR</span> `;
          break;
        case "stringMatch":
          switch (adRow.type) {
            case "startsWith":
              adFiltersText = `${adFiltersText}<span class="quantity-dimension">${row.metadata.dimTitle}</span> <span class="query-keyword">LIKE</span> '${adRow.value}%' <span class="query-keyword">OR</span> `;
              break;
            case "endsWith":
              adFiltersText = `${adFiltersText}<span class="quantity-dimension">${row.metadata.dimTitle}</span> <span class="query-keyword">LIKE</span> '%${adRow.value}' <span class="query-keyword">OR</span> `;
              break;
            case "containsString":
              adFiltersText = `${adFiltersText}<span class="quantity-dimension">${row.metadata.dimTitle}</span> <span class="query-keyword">LIKE</span> '%${adRow.value}%' <span class="query-keyword">OR</span> `;
              break;
            case "containsWholeWord":
              adFiltersText = `${adFiltersText}<span class="quantity-dimension">${row.metadata.dimTitle}</span> <span class="query-keyword">LIKE</span> '% ${adRow.value} %' <span class="query-keyword">OR</span> `;
              break;
          }
          break;
        case "fileUpload":
          adFiltersText = `${adFiltersText}<span class="quantity-dimension">${row.metadata.dimTitle}</span> <span class="query-keyword">=</span> '${adRow.extraData.fileName}' <span class="query-keyword">OR</span> `;
          break;
        default:
          break;
      }
    }
    //replace last OR in adFiltersText
    adFiltersText = adFiltersText.replace(
      / <span class=\"query\-keyword\">OR<\/span> $/g,
      ""
    );
    let textType =
      basicFiltersText.length > 0 && adFiltersText.length > 0
        ? "both"
        : basicFiltersText.length > 0 && adFiltersText.length === 0
        ? "basicFiltersOnly"
        : basicFiltersText.length === 0 && adFiltersText.length > 0
        ? "adFiltersOnly"
        : "none";
    switch (textType) {
      case "both":
        text = `${text}${
          rowIndex === 0 ? "" : "\n"
        }${basicFiltersText} <span class="query-keyword">AND</span>\n<span class="ad-filters">(${adFiltersText}) </span><span class="query-keyword">AND</span>`;
        break;
      case "basicFiltersOnly":
        text = `${text}${
          rowIndex === 0 ? "" : "\n"
        }${basicFiltersText} <span class="query-keyword">AND</span>`;
        break;
      case "adFiltersOnly":
        text = `${text}${
          rowIndex === 0 ? "" : "\n"
        }<span class="ad-filters">(${adFiltersText}) </span><span class="query-keyword">AND</span>`;
        break;
      case "none":
        text = `${text}`;
        break;
    }
  }
  // //replace last AND in text
  // text = text.replace(
  //   /<span class=\"query\-keyword\">AND<\/span>$/g,
  //   ""
  // );
  return text;
};

const makeReportManagerQueryForMetricFilters = (metricFilters, allData) => {
  let text = "";
  // "<": "singleValueInput",
  // "<=": "singleValueInput",
  // ">": "singleValueInput",
  // ">=": "singleValueInput",
  // "!=": "singleValueInput",
  // "=": "singleValueInput",
  // "between": "multiValueInput"
  for (let row of metricFilters) {
    const rowIndex = metricFilters.indexOf(row);
    const metricObject = getMeasureObjByOriginalID(
      row.metricId,
      allData.plotlyMetrics
    );
    let rowText = "";
    switch (row.type) {
      case "between":
        let minVal = Math.min(parseInt(row.value1), parseInt(row.value2));
        let maxVal = Math.max(parseInt(row.value1), parseInt(row.value2));
        rowText = `<span class="quantity-metric">${
          metricObject.measureTitle
        }</span> <span class="query-keyword">${">="}</span> ${minVal} <span class="query-keyword">AND</span> <span class="quantity-metric">${
          metricObject.measureTitle
        }</span> <span class="query-keyword">${"<="}</span> ${maxVal}`;
        break;
      default:
        rowText = `<span class="quantity-metric">${metricObject.measureTitle}</span> <span class="query-keyword">${row.type}</span> ${row.value1}`;
    }
    // text =
    //   metricFiltersLen === rowIndex + 1
    //     ? `${text}${rowIndex===0?"":"\n"}\n${rowText}`
    //     : `${text}\n${rowText} <span class="query-keyword">AND</span>`;
    text = `${text}${
      rowIndex === 0 ? "" : "\n"
    }${rowText} <span class="query-keyword">AND</span>`;
  }
  return text;
};

const makeReportManagerQueryForTimeFilters = (timeFilters) => {
  // const startDateFormatted = timeFilters.selectedDates.startDate.formattedDate;
  // const endDateFormatted = timeFilters.selectedDates.endDate.formattedDate;
  // const startDate = startDateFormatted.slice(0, startDateFormatted.length - 6); //remove timezone
  // const endDate = endDateFormatted.slice(0, endDateFormatted.length - 6); //remove timezone
  const startDate = formatDateForReportManagerQuery(
    timeFilters.selectedDates.startDate.epoch,
    timeFilters.selectedTimezone
  );
  const endDate = formatDateForReportManagerQuery(
    timeFilters.selectedDates.endDate.epoch,
    timeFilters.selectedTimezone
  );
  let text = `DATE RANGE <span class="query-keyword">BETWEEN</span> ${startDate} <span class="query-keyword">AND</span> ${endDate} <span class="query-keyword">IN</span> ${timeFilters.selectedTimezone.name}`;
  return text;
};

const makeReportManagerQueryForTimeFiltersCompare = (
  timeFiltersCompare,
  selectedTimezone
) => {
  // const startDateFormatted = timeFilters.selectedDates.startDate.formattedDate;
  // const endDateFormatted = timeFilters.selectedDates.endDate.formattedDate;
  // const startDate = startDateFormatted.slice(0, startDateFormatted.length - 6); //remove timezone
  // const endDate = endDateFormatted.slice(0, endDateFormatted.length - 6); //remove timezone
  const startDate = formatDateForReportManagerQuery(
    timeFiltersCompare.compareSelectedDates.startDate.epoch,
    selectedTimezone
  );
  const endDate = formatDateForReportManagerQuery(
    timeFiltersCompare.compareSelectedDates.endDate.epoch,
    selectedTimezone
  );
  let text = `<span class="query-keyword">COMPARED WITH</span> DATE RANGE <span class="query-keyword">BETWEEN</span> ${startDate} <span class="query-keyword">AND</span> ${endDate} <span class="query-keyword">IN</span> ${selectedTimezone.name}`;
  return text;
};

export const makeReportManagerQuery = (reportFormData, allData) => {
  const dimText = `<span class="dimensions-list">${replaceLastOccurenceOf(
    reportFormData.dimensionsList.reduce(
      (finalText, row) =>
        `<span class="quantity-dimension">${row.dimTitle}</span>, ${finalText}`,
      ""
    ),
    ", "
  )}</span>`;
  const metricText = `<span class="metrics-list">${replaceLastOccurenceOf(
    reportFormData.metricsList.reduce(
      (finalText, row) =>
        `<span class="quantity-metric"><span class="query-keyword">SUM</span>(${row.measureTitle})</span>, ${finalText}`,
      ""
    ),
    ", "
  )}</span>`;
  const filtersPreText = `<span class="filters-pre"><span class="query-keyword">WHERE</span></span>`;
  const dimFiltersText = `<span class="dimensions-filters">${makeReportManagerQueryForDimensionFilters(
    reportFormData.dimensionFilters
  )}</span>`;
  const metricFiltersText = `<span class="metric-filters">${makeReportManagerQueryForMetricFilters(
    reportFormData.metricFilters,
    allData
  )}</span>`;
  const timeFiltersText = `<span class="time-filters">${makeReportManagerQueryForTimeFilters(
    reportFormData.timeFilters
  )}</span>`;
  const timeFiltersCompareText = `<span class="time-filters-compare">${makeReportManagerQueryForTimeFiltersCompare(
    reportFormData.timeFilters.compareDates[0],
    reportFormData.timeFilters.selectedTimezone
  )}</span>`;
  const groupByText = `<span class="groupby"><span class="query-keyword">GROUP BY</span> ${replaceLastOccurenceOf(
    reportFormData.dimensionsList.reduce(
      (finalText, row) =>
        `<span class="quantity-dimension">${row.dimTitle}</span>, ${finalText}`,
      ""
    ),
    ", "
  )}</span>`;
  const orderByTypeText = orderByTypeMapping[reportFormData.orderByType];
  const orderByObject = reportFormData.orderById.startsWith("D")
    ? getDimensionObjByOriginalID(
        reportFormData.orderById,
        allData.plotlyDimensions
      )
    : getMeasureObjByOriginalID(
        reportFormData.orderById,
        allData.plotlyMetrics
      );
  const orderByObjectTitle = reportFormData.orderById.startsWith("D")
    ? `<span class="quantity-dimension">${orderByObject.dimTitle}${orderByTypeText}</span>`
    : `<span class="quantity-metric">${orderByObject.measureTitle}${orderByTypeText}</span>`;
  const orderByText = `<span class="orderby"><span class="query-keyword">ORDER BY</span> ${orderByObjectTitle} <span class="query-keyword">${reportFormData.orderBy.toUpperCase()}</span> </span>`;
  //OPTION 1
  const query = `<span class="query-keyword">SELECT</span> \n${dimText},\n${metricText}\n${filtersPreText}${
    reportFormData.dimensionFilters.length ? `\n${dimFiltersText}` : ""
  }${
    reportFormData.metricFilters.length ? `\n${metricFiltersText}` : ""
  }\n${timeFiltersText}${
    reportFormData.isComparisonOn &&
    reportFormData.reportRowCount !== "all_records"
      ? `\n${timeFiltersCompareText}`
      : ""
  }\n${groupByText}\n${orderByText}`;
  return query;
};

export const getAllColumnTitlesFromReportFormData = (reportFormData) => {
  const allDimensions = reportFormData.dimensionsList.map((row) => ({
    id: row.dimTitle,
    name: row.dimTitle,
  }));
  let allMetrics = reportFormData.metricsList.map((row) => row.measureTitle);
  if (
    reportFormData.isComparisonOn &&
    reportFormData.reportRowCount !== "all_records"
  ) {
    //Adding 3 rows for each metric (value, change, %change)
    allMetrics = allMetrics
      .map((row) => [
        {
          id: row,
          name: row,
        },
        {
          id: `${row}_change`,
          name: "Change",
        },
        {
          id: `${row}_per_change`,
          name: "%Change",
        },
      ])
      .flat();
  } else {
    allMetrics = allMetrics.map((row) => ({
      id: row,
      name: row,
    }));
  }
  const allColumnTitles = [...allDimensions, ...allMetrics];
  return allColumnTitles;
};
export const unwrapperBucketListInReport = (data) => {
  return data.map((el) => ({
    id: el._id,
    name: el.storageName,
    disabled: false,
  }));
};

export const isBucketAssociatedReport = (reportFormData) => {
  if (reportFormData.reportRowCount === "fifty_thousand_records") {
    if (
      ["sendLink", "writeToBucket"].includes(
        reportFormData.reportCountFileType.filter(
          (el) => el.id === "fifty_thousand_records"
        )[0].selectedDropdown
      )
    )
      return true;
    else return false;
  } else return false;
};

export const realTimeCprFrequencyFilter = ["daily", "weekly"];
export const realTimeCprTimeRange = [1];
