// * Import required libraies
import React, { useState, useEffect, useRef, useContext } from "react";
import { v4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

// * Import lib components
import { Box } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";

// * Import custom components
import VizItem from "../VizItem/VizItem";
import SigviewTooltip from "../Common/SigviewTooltip";
import TimeFilters from "../TimeFilters/TimeFilters";
import DimensionFilters from "../DimensionFilters";
import SigviewIcon from "../Common/SigviewIcon";
import SigviewTypography from "../Common/SigviewTypography";

// * Import utils/data
import { formatTimeFilters } from "../../utils/dsUtils";
import { wrapperChartObject } from "../../utils/chartObjectUtils";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Import styles
import "./DsWidgetItem.scss";

// * Import actions
import { updateUserScreen } from "../../redux/actions";

// * Import services
import { masterTrackGaEvent } from "../../services/ga";

function DsWidgetHeader(props) {
  // * Destructure props
  const {
    // REDUX PROPS
    user = {},
    allData = {},
    ReduxDispatcher,
    // PARENT PROPS
    widgetMetadata = {},
    eventHandlers = {},
    selectionsObject = {},
    isEditableOn = false,
    dsForm = {},
    mouseIn = false,
  } = props;
  const { widgetItemId: id = "" } = widgetMetadata;
  const {
    onDelete = () => {},
    onEditChart = () => {},
    onRename = () => {},
    onDimensionFiltersChange = () => {},
    onTimeFiltersChange = () => {},
  } = eventHandlers;
  const { title = "", metadata = {} } = selectionsObject;
  const { displayFormula: infoTitle = "" } = metadata;

  // * Define requried states
  const [name, setName] = useState({
    status: "success",
    value: title,
    originalValue: title,
    message: "",
  });

  // * Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define required side effects
  // Update local state if title from props changes
  useEffect(() => {
    setName({ ...name, value: title, originalValue: title });
  }, [title]);

  // * Define required event handlers
  const handleDelete = (event) => onDelete(event, id);
  const handleEdit = (event) => onEditChart(event, id);
  const handleRename = (event) => onRename(event, id, name.value);
  const handleNameChange = (event, value) =>
    setName((prevState) => ({ ...prevState, value: value }));
  const handleDimFiltersChange = (value) => {
    onDimensionFiltersChange(value);
  };

  // * Define required variables
  let vizType = metadata._id
    ? metadata._id?.startsWith("M")
      ? "metric"
      : "customMetric"
    : "chart";
  let rootCss = {
    padding: "5px 10px",
  };
  let titleRowCss = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  let titleContainerCss = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
    flexBasis: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  let titleCss = {
    width: "100%",
  };
  let customMetricTitleCss = {
    width: "100%",
  };
  let customMetricInfoCss = {
    width: "100%",
  };
  let menuContainerCss = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexShrink: 0,
    width: "max-content",
  };
  let subtitleRowCss = {
    display: "flex",
    alignItems: "center",
  };
  const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarDashboard;
  const timeFiltersSettings = {
    compareCalendarRowLimit: 1, //hard coded; it will come from /getUserAccessList
    isMultiCompareRowAllowed: false, //hard coded; it will come from /getUserAccessList
    isComparisonAvailable: false, //hard coded; it should come from where this component is being called
    isChangeTypeDropdownAvailable: true,
    isApplyButtonAvailable: false,
  };
  const formattedDateRange = formatTimeFilters(selectionsObject.timeFilters);
  const isTimeFiltersAtWidget = dsForm.timeFilterType.value === "widget";
  const isDimFiltersAtWidget = dsForm.dimensionFilterType.value === "widget";
  const isTimeFiltersEditVisible = isEditableOn && isTimeFiltersAtWidget;
  const isDimFiltersEditVisible = isEditableOn && isDimFiltersAtWidget;
  const isDimFiltersReadOnlyVisible = !isEditableOn && isDimFiltersAtWidget;
  const isEditVisible = isEditableOn;
  const isDeleteVisible = isEditableOn;
  const dimFiltersCount = selectionsObject.dimensionFilters.length;
  const filterIconMuiClassName =
    dimFiltersCount > 0 ? "material-icons-round" : "material-icons-outlined";

  // * Define required components
  const WidgetDimFiltersReadOnly = (props = {}) => {
    // * Define required states
    const [anchorEl, setAnchorEl] = useState(null);

    // * Define required event handlers
    const handleClick = (event) => {
      // Google Analytics Event - Master
      masterTrackGaEvent({
        category: "DatastoryDashboard",
        action: "Widget",
        label: "ReadDimFilters",
      });
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    // * Define required variables
    const open = Boolean(anchorEl);
    const id = open ? "widgetDimFiltersPopover" : undefined;
    const useStyles = makeStyles({
      root: {
        "& .MuiPaper-root ": {
          backgroundColor: "transparent !important",
        },
      },
    });
    const classes = useStyles();
    const containerCss = {
      backgroundColor: themeColors["mainContentBgColor"],
      padding: "10px 15px",
    };

    return (
      <>
        <SigviewTooltip title="Applied Filters" placement="bottom">
          <Box>
            <SigviewIcon
              className={filterIconMuiClassName}
              iconName="filter_alt"
              style={{
                fontSize: "16px !important",
                padding: "0px 5px 0px 0px",
                color: themeColors["secondaryColorLight"],
                hoverColor: themeColors["primaryColor"],
                cursor: "pointer",
              }}
              onClick={handleClick}
            />
          </Box>
        </SigviewTooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.root}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box css={containerCss}>
            {dimFiltersCount > 0 && (
              <DimensionFilters
                initialDimensionFilters={selectionsObject.dimensionFilters}
                initialTimeFilters={selectionsObject.timeFilters}
                onDimensionFiltersChange={handleDimFiltersChange}
                settings={{ isReadOnly: true }}
              />
            )}
            {dimFiltersCount === 0 && (
              <SigviewTypography
                variant="pMedium"
                style={{
                  color: themeColors["secondaryColorLight"],
                  height: "max-content",
                  // width: "300px",
                  padding: "0px 25px",
                }}
              >
                No filters applied
              </SigviewTypography>
            )}
          </Box>
        </Popover>
      </>
    );
  };

  const WidgetDimFilters = (props = {}) => {
    // * Define required static variables
    const commonGlobalFilterProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: false,
      showMetricFilters: false,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      timeFilters: selectionsObject.timeFilters, //for initializing filters
      dimensionFilters: selectionsObject.dimensionFilters, //for initializing filters
      metricFilters: [], //for initializing filters
    };

    // * Define required event handlers
    const handleEditWidgetFilters = () => {
      // Google Analytics Event - Master
      masterTrackGaEvent({
        category: "DatastoryDashboard",
        action: "Widget",
        label: "EditDimFilters",
      });

      const handleApplyFilters = (payload, filterType) => {
        switch (filterType) {
          case "dimensions":
            onDimensionFiltersChange(payload.newDimensionFilters);
            break;
          case "metrics":
            break;
          case "time":
            break;
        }
      };

      //Open Global Filters
      const newGlobalFiltersProps = {
        ...commonGlobalFilterProps,
        activeDimensionFilter: {},
        handleApplyFilters, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
      };

      const action = updateUserScreen("globalFilters", newGlobalFiltersProps);
      ReduxDispatcher(action);
    };

    return (
      <>
        <SigviewTooltip title="Edit Dimension Filters" placement="bottom">
          <Box>
            <SigviewIcon
              className={filterIconMuiClassName}
              iconName="filter_alt"
              style={{
                fontSize: "16px !important",
                padding: "0px 5px 0px 0px",
                color: themeColors["secondaryColorLight"],
                hoverColor: themeColors["primaryColor"],
                cursor: "pointer",
              }}
              onClick={handleEditWidgetFilters}
            />
          </Box>
        </SigviewTooltip>
        {/* <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          // onClose={handleClose}
          className={classes.root}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box css={containerCss}>
            <DimensionFilters
              initialDimensionFilters={selectionsObject.dimensionFilters}
              initialTimeFilters={selectionsObject.timeFilters}
              onDimensionFiltersChange={handleDimFiltersChange}
              settings={{}}
            />
          </Box>
        </Popover> */}
      </>
    );
  };

  const WidgetTimeFilters = (props = {}) => {
    // * Define required states
    const [anchorEl, setAnchorEl] = useState(null);

    // * Define required event handlers
    const handleClick = (event) => {
      // Google Analytics Event - Master
      masterTrackGaEvent({
        category: "DatastoryDashboard",
        action: "Widget",
        label: "EditTimeFilters",
      });
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    // * Define required variables
    const open = Boolean(anchorEl);
    const id = open ? "widgetTimeFiltersPopover" : undefined;
    const useStyles = makeStyles({
      root: {
        "& .MuiPaper-root ": {
          backgroundColor: "transparent !important",
        },
      },
    });
    const classes = useStyles();
    const containerCss = {
      backgroundColor: themeColors["mainContentBgColor"],
      padding: "5px",
    };

    return (
      <>
        <SigviewTooltip title="Edit Time Filters" placement="bottom">
          <Box>
            <SigviewIcon
              className="material-icons-round"
              iconName="schedule"
              style={{
                fontSize: "16px !important",
                padding: "0px 5px 0px 0px",
                color: themeColors["secondaryColorLight"],
                hoverColor: themeColors["primaryColor"],
                cursor: "pointer",
              }}
              onClick={handleClick}
            />
          </Box>
        </SigviewTooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.root}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box css={containerCss}>
            <TimeFilters
              user={user}
              allData={allData}
              initialTimeFilters={selectionsObject.timeFilters}
              onChange={onTimeFiltersChange}
              calendarDaysLimits={calendarDaysLimits}
              settings={timeFiltersSettings}
              // googleAnalytics={{ category: "DatastoryDashboardPage" }}
            />
          </Box>
        </Popover>
      </>
    );
  };

  const WidgetEditButton = (props = {}) => {
    return (
      <SigviewTooltip title="Edit Chart" placement="bottom">
        <Box>
          <SigviewIcon
            className="material-icons-round datastoryEditCharts-GA"
            iconName="edit"
            style={{
              fontSize: "16px !important",
              padding: "0px 5px 0px 0px",
              color: themeColors["secondaryColorLight"],
              hoverColor: themeColors["primaryColor"],
              cursor: "pointer",
            }}
            onClick={handleEdit}
          />
        </Box>
      </SigviewTooltip>
    );
  };

  const WidgetDeleteButton = (props = {}) => {
    return (
      <SigviewTooltip title="Delete Widget" placement="bottom">
        <Box>
          <SigviewIcon
            className="material-icons-round datastoryDeleteWidget-GA"
            iconName="delete"
            style={{
              fontSize: "16px !important",
              padding: "0px 0px 0px 0px",
              color: themeColors["secondaryColorLight"],
              hoverColor: themeColors["failureColor"],
              cursor: "pointer",
            }}
            onClick={handleDelete}
          />
        </Box>
      </SigviewTooltip>
    );
  };

  const WidgetCustomMetricInfo = (props = {}) => {
    return (
      <Box css={customMetricInfoCss}>
        <SigviewTooltip title={infoTitle} top="-12px">
          <Box>
            <SigviewIcon
              className="material-icons-round"
              iconName="info"
              style={{
                fontSize: "16px !important",
                padding: "0px 5px 0px 0px",
                color: themeColors["secondaryColorLight"],
                hoverColor: themeColors["primaryColor"],
                cursor: "pointer",
              }}
            />
          </Box>
        </SigviewTooltip>
      </Box>
    );
  };

  let Component;
  switch (vizType) {
    case "metric":
      Component = (
        <Box css={rootCss}>
          <Box css={titleRowCss}>
            <Box css={titleContainerCss}>
              <Box css={titleCss}>
                <SigviewTooltip title={name.value} placement="bottom-start">
                  <Box>
                    <SigviewTypography
                      variant="pMediumLarge"
                      style={{
                        height: "max-content",
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {name.value}
                    </SigviewTypography>
                  </Box>
                </SigviewTooltip>
              </Box>
            </Box>
            <Box css={menuContainerCss}>
              {isDimFiltersReadOnlyVisible && <WidgetDimFiltersReadOnly />}
              {isDimFiltersEditVisible && <WidgetDimFilters />}
              {isTimeFiltersEditVisible && <WidgetTimeFilters />}
              {isDeleteVisible && <WidgetDeleteButton />}
            </Box>
          </Box>
          {isTimeFiltersAtWidget && (
            <Box css={subtitleRowCss}>
              <SigviewIcon
                className="material-icons-round"
                iconName="schedule"
                style={{
                  fontSize: "10px !important",
                  padding: "0px 3px 0px 0px",
                  color: themeColors["secondaryColorLight"],
                }}
              />
              <SigviewTypography
                variant="pSmallest"
                style={{
                  height: "max-content",
                  width: "100%",
                  color: themeColors["secondaryColorLight"],
                }}
              >
                {formattedDateRange}
              </SigviewTypography>
            </Box>
          )}
        </Box>
      );
      break;

    case "customMetric":
      Component = (
        // <div className="datastory-widget-title-container custom-metric">
        //   <SigviewTooltip title={name.value} placement="bottom">
        //     <p className="datastory-widget-title counter-custom-metric">
        //       {name.value}
        //     </p>
        //   </SigviewTooltip>
        //   <SigviewTooltip title={metricObject?.displayFormula} top="-12px">
        //     <i className="material-icons-round info-icon">info</i>
        //   </SigviewTooltip>
        //   <button onClick={handleDelete}>Delete</button>
        // </div>
        <Box css={rootCss}>
          <Box css={titleRowCss}>
            <Box css={titleContainerCss}>
              <Box css={customMetricTitleCss}>
                <SigviewTooltip title={name.value} placement="bottom-start">
                  <Box>
                    <SigviewTypography
                      variant="pMediumLarge"
                      style={{
                        height: "max-content",
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {name.value}
                    </SigviewTypography>
                  </Box>
                </SigviewTooltip>
              </Box>
            </Box>
            <Box css={menuContainerCss}>
              {mouseIn && <WidgetCustomMetricInfo />}
              {isDimFiltersReadOnlyVisible && <WidgetDimFiltersReadOnly />}
              {isDimFiltersEditVisible && <WidgetDimFilters />}
              {isTimeFiltersEditVisible && <WidgetTimeFilters />}
              {isDeleteVisible && <WidgetDeleteButton />}
            </Box>
          </Box>
          {isTimeFiltersAtWidget && (
            <Box css={subtitleRowCss}>
              <SigviewIcon
                className="material-icons-round"
                iconName="schedule"
                style={{
                  fontSize: "10px !important",
                  padding: "0px 3px 0px 0px",
                  color: themeColors["secondaryColorLight"],
                }}
              />
              <SigviewTypography
                variant="pSmallest"
                style={{
                  height: "max-content",
                  width: "100%",
                  color: themeColors["secondaryColorLight"],
                }}
              >
                {formattedDateRange}
              </SigviewTypography>
            </Box>
          )}
        </Box>
      );

      break;

    case "chart":
      Component = (
        <Box css={rootCss}>
          <Box css={titleRowCss}>
            <Box css={titleContainerCss}>
              <Box css={titleCss}>
                <SigviewTooltip title={name.value} placement="bottom-start">
                  <Box>
                    <SigviewTypography
                      variant="pMediumLarge"
                      style={{
                        height: "max-content",
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {name.value}
                    </SigviewTypography>
                  </Box>
                </SigviewTooltip>
              </Box>
            </Box>
            <Box css={menuContainerCss}>
              {isDimFiltersReadOnlyVisible && <WidgetDimFiltersReadOnly />}
              {isDimFiltersEditVisible && <WidgetDimFilters />}
              {isTimeFiltersEditVisible && <WidgetTimeFilters />}
              {isEditVisible && <WidgetEditButton />}
              {isDeleteVisible && <WidgetDeleteButton />}
            </Box>
          </Box>
          {isTimeFiltersAtWidget && (
            <Box css={subtitleRowCss}>
              <SigviewIcon
                className="material-icons-round"
                iconName="schedule"
                style={{
                  fontSize: "10px !important",
                  padding: "0px 3px 0px 0px",
                  color: themeColors["secondaryColorLight"],
                }}
              />
              <SigviewTypography
                variant="pSmallest"
                style={{
                  height: "max-content",
                  width: "100%",
                  color: themeColors["secondaryColorLight"],
                }}
              >
                {formattedDateRange}
              </SigviewTypography>
            </Box>
          )}
        </Box>
      );
      break;

    default:
      Component = <p>Unsupported Chart Type</p>;
  }
  return Component;
}

function DsWidgetItem(props) {
  // * Destructure props
  const {
    // REDUX PROPS
    user,
    allData,
    ReduxDispatcher,
    // PARENT PROPS
    widgetMetadata,
    displayLayout,
    isEditableOn = false,
    dsForm,
    globalFilters,
    onChange = () => {},
    onWidgetChange = () => {},
    onMetricsDataReload = () => {},
    onChartDataReload = () => {},
    eventHandlers = {},
  } = props;
  const chartContainerId = useRef(`chartHolder-${v4()}`);
  const { chartType = "counter", widgetItemId = "" } = widgetMetadata;
  const widgetItemType = chartType === "counter" ? "counter" : "chart";

  // * Define required states
  const [mouseIn, setMouseIn] = useState(false);

  // * Define required event handlers
  const handleWidgetDimensionFiltersChange = (value) =>
    onWidgetChange(widgetItemId, "dimensionFilters", value, widgetItemType);

  const handleWidgetTimeFiltersChange = (value) => {
    onWidgetChange(widgetItemId, "timeFilters", value, widgetItemType);
  };

  const handleMouseEnter = () => setMouseIn(true);
  const handleMouseLeave = () => setMouseIn(false);

  // * Define required static variables
  const dataQEAll = dsForm.dataQE.value;
  const chartData = dataQEAll[widgetMetadata.widgetItemId];
  const allSelectedCharts = dsForm.selectedCharts.value;
  const allSelectedKpis = dsForm.selectedKpis.value;
  const selectionsObject =
    chartType === "counter"
      ? allSelectedKpis[widgetItemId]
      : allSelectedCharts[widgetItemId];
  let selections = {
    chartType: selectionsObject.chartType,
    metricFilters: selectionsObject.metricFilters || [],
    dimensionFilters: dsForm.dimensionFilters.value || [],
    timeFilters: dsForm.timeFilters.value || {},
    dimensionsList: selectionsObject.dimensionsList || [],
    metricsList: selectionsObject.metricsList || [],
    orderById: selectionsObject.orderById || "",
    orderBy: selectionsObject.orderBy || "",
    timeFiltersAppliedAt: dsForm.timeFilterType.value,
    dimensionFiltersAppliedAt: dsForm.dimensionFilterType.value,
    granularity: globalFilters?.metricChartGranularity?.value,
  };
  const reloadEpoch = dsForm.reloadEpochs.value[widgetItemId];
  // To implement widget level filters, selections will change
  if (dsForm.timeFilterType.value === "widget") {
    selections = {
      ...selections,
      timeFilters: selectionsObject.timeFilters || {},
    };
  }
  if (dsForm.dimensionFilterType.value === "widget") {
    selections = {
      ...selections,
      dimensionFilters: selectionsObject.dimensionFilters || {},
    };
  }
  const payload = wrapperChartObject({ selections, user });
  const isCounterAtWidgetLevel =
    dsForm.timeFilterType.value === "widget" ||
    dsForm.dimensionFilterType.value === "widget";
  const widgetEventHandlers = {
    ...eventHandlers,
    onDimensionFiltersChange: handleWidgetDimensionFiltersChange,
    onTimeFiltersChange: handleWidgetTimeFiltersChange,
  };

  let rootClassName = "datastory-widget";
  rootClassName += ` ${chartType}`;
  const isTimeFiltersAtWidget = dsForm.timeFilterType.value === "widget";
  const widgetCss = {
    height: isTimeFiltersAtWidget ? "calc(100% - 45px)" : "calc(100% - 30px)",
  };
  if (isEditableOn) rootClassName += " editable-on";

  return (
    <div
      className={rootClassName}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <section className="datastory-widget-header-container">
        <DsWidgetHeader
          user={user}
          allData={allData}
          widgetMetadata={widgetMetadata}
          selectionsObject={selectionsObject}
          eventHandlers={widgetEventHandlers}
          isEditableOn={isEditableOn}
          dsForm={dsForm}
          mouseIn={mouseIn}
          ReduxDispatcher={ReduxDispatcher}
        />
      </section>

      <div
        className={`widget ${chartType}`}
        id={chartContainerId.current}
        style={widgetCss}
      >
        <VizItem
          id={widgetItemId}
          selections={selections}
          data={chartData}
          payload={payload}
          chartContainerId={chartContainerId.current}
          reloadEpoch={reloadEpoch}
          onChartDataReload={onChartDataReload}
          onChange={onChange}
          isCounterAtWidgetLevel={isCounterAtWidgetLevel}
          onMetricsDataReload={onMetricsDataReload}
          displayLayout={displayLayout}
        />
      </div>
      {/* <i>
        This widget has dimension filters applied. Please hover to check them
        out
      </i> */}
    </div>
  );
}

export default DsWidgetItem;
