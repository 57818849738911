// * Import required libraries
import React, { useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// * Import custom components
import SigviewTooltip from "../Common/SigviewTooltip";

// * Import utils, config & static data
import { masterTrackGaEvent } from "../../services/ga/index";

// * Import styles
import "./SidenavAdmin.scss";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";
import { useHistory, useLocation } from "react-router";

function SidenavAdmin(props) {
  const { t } = useTranslation();
  const {
    dispatch: ReduxDispatcher,
    user,
    allData,
    handleActiveTab = () => {},
    variant = "AdminGcp",
  } = props;
  const activeTab = user.screen.activeTab;
  const { state: themeState } = useContext(ThemeContext);

  const handleTab = (e, id) => {
    handleActiveTab(e, id);
  };

  // * Define contexts
  const history = useHistory();
  const location = useLocation();

  //* Define Event Handlers
  const handleGroupsOpen = (e) => {
    // Google Analytics Event - Master Admin
    masterTrackGaEvent({
      category: "Admin",
      action: "GroupsOpen",
      label: "Sidenav",
    });
    handleTab(e, "groups");

    const pathname = "/admin/groups";
    history.push(pathname);
  };
  const handleUsersOpen = (e) => {
    // Google Analytics Event - Master Admin
    masterTrackGaEvent({
      category: "Admin",
      action: "UsersOpen",
      label: "Sidenav",
    });
    const pathname = "/admin/users";
    // `${location.pathname}${location.hash}${location.search}`;
    // pathname = pathname.replace("/app/#", "");
    history.push(pathname);
  };

  let activeTabHome = activeTab === "home";
  let activeTabGroups = activeTab === "groups";
  let activeTabUsers = activeTab === "users";
  let activeTabAttributes = activeTab === "attributes";

  return (
    <>
      <aside className={`sidenav-container `}>
        <SigviewTooltip title={t("Go back to Home")} placement="right">
          <div
            onClick={(e) => {
              var pathname = "/admin";
              history.push(pathname);
            }}
            className={`sidenav-item AdminHomeOpen-GA`}
          >
            <div
              className={`admin-custom-home-icon ${
                ((variant === "AdminGcp" && location.pathname === "/admin") ||
                  (variant === "AdminAws" && location.pathname === "/admin")) &&
                "sidenav-active"
              }`}
            ></div>
            <span
              className={`sidenav-item-title  ${
                ((variant === "AdminGcp" && location.pathname === "/admin") ||
                  (variant === "AdminAws" && location.pathname === "/admin")) &&
                "sidenav-active"
              }`}
            >
              {t("Home")}
            </span>
          </div>
        </SigviewTooltip>

        {variant === "AdminGcp" && (
          <SigviewTooltip title={t("Go to Groups")} placement="right">
            <div
              onClick={handleGroupsOpen}
              className={`sidenav-item AdminGroupsOpen-GA`}
            >
              <div
                className={`admin-custom-group-icon ${
                  variant === "AdminGcp" &&
                  location.pathname === "/admin/groups" &&
                  "sidenav-active"
                }`}
              >
                <span className="material-icons-outlined ">group</span>
              </div>

              <span
                className={`sidenav-item-title  ${
                  variant === "AdminGcp" &&
                  location.pathname === "/admin/groups" &&
                  "sidenav-active"
                }`}
              >
                {t("Groups")}
              </span>
            </div>
          </SigviewTooltip>
        )}

        <SigviewTooltip title={t("Go to Users")} placement="right">
          <div
            onClick={handleUsersOpen}
            className={`sidenav-item AdminUsersOpen-GA`}
          >
            <div
              className={`admin-custom-group-icon ${
                ((variant === "AdminGcp" &&
                  location.pathname === "/admin/users") ||
                  (variant === "AdminAws" &&
                    location.pathname === "/admin/users")) &&
                "sidenav-active"
              }`}
            >
              <span className="material-icons-outlined">person</span>
            </div>

            <span
              className={`sidenav-item-title ${
                ((variant === "AdminGcp" &&
                  location.pathname === "/admin/users") ||
                  (variant === "AdminAws" &&
                    location.pathname === "/admin/users")) &&
                "sidenav-active"
              }`}
            >
              {t("Users")}
            </span>
          </div>
        </SigviewTooltip>

        <SigviewTooltip title={t("Go to Attributes")} placement="right">
          <div
            onClick={(e) => {
              var pathname = "/admin/attributes";
              history.push(pathname);
            }}
            className={`sidenav-item AdminAttributeOpen-GA`}
            id="dsBackToHome"
          >
            {/* <div className="admin-custom-home-icon"></div> */}
            <div
              className={`admin-custom-group-icon ${
                ((variant === "AdminGcp" &&
                  location.pathname === "/admin/attributes") ||
                  (variant === "AdminAws" &&
                    location.pathname === "/admin/attributes")) &&
                "sidenav-active"
              }`}
            >
              <span className="material-icons-outlined">
                <span className="material-icons-outlined">trending_up</span>
              </span>
            </div>

            <span
              className={`sidenav-item-title ${
                ((variant === "AdminGcp" &&
                  location.pathname === "/admin/attributes") ||
                  (variant === "AdminAws" &&
                    location.pathname === "/admin/attributes")) &&
                "sidenav-active"
              }`}
            >
              {t("Attributes")}
            </span>
          </div>
        </SigviewTooltip>

        {variant === "AdminGcp" && (
          <SigviewTooltip title="Go to Reports" placement="right">
            <div
              onClick={(e) => {
                var pathname = "/admin/adminReport";
                history.push(pathname);
              }}
              className={`sidenav-item AdminReportOpen-GA`}
            >
              <div
                className={`admin-custom-group-icon ${
                  ((variant === "AdminGcp" &&
                    location.pathname === "/admin/adminReport") ||
                    (variant === "AdminAws" &&
                      location.pathname === "/admin/adminReport")) &&
                  "sidenav-active"
                }`}
              >
                <span className="material-icons-outlined ">description</span>
              </div>

              <span
                className={`sidenav-item-title  ${
                  ((variant === "AdminGcp" &&
                    location.pathname === "/admin/adminReport") ||
                    (variant === "AdminAws" &&
                      location.pathname === "/admin/adminReport")) &&
                  "sidenav-active"
                }`}
              >
                Reports
              </span>
            </div>
          </SigviewTooltip>
        )}
      </aside>
    </>
  );
}

SidenavAdmin.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(SidenavAdmin);
