//Import required libraies
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

// Import Custom Component

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import action creators

// Import utils

// Import styles

function SigviewTextField(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    value,
    height = "24px",
    onChange,
    width = "100%",
    fontSize = "10px",
    fontSizeHelperText = "12px",
    minWidth,
    maxWidth,
    margin,
    borderRadius,
    border,
    borderHover,
    backgroundColor,
    error,
    helperText,
    placeholder,
    readOnly,
    onFocus = () => {},
    onBlur = () => {},
    type = "text",
    disabled = false,
    customClassName,
  } = props;

  //Required Functions
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  //Styling
  const useStyles = makeStyles({
    formControl: {
      minWidth: minWidth || "120px",
      maxWidth: maxWidth || "400px",
      width,
      marginLeft: margin?.left || "0px",
      marginRight: margin?.right || "0px",
      marginBottom: margin?.bottom || "0px",
    },
    textField: {
      pointerEvents: readOnly ? "none" : "auto",
      fontSize: fontSize,
      fontFamily: "inherit",
      minWidth: minWidth || "120px",
      maxWidth: maxWidth || "350px",
      width,
      boxSizing: "border-box",
      height: height,
      borderRadius: borderRadius || "3px",
      backgroundColor: backgroundColor || "transparent",
      border:
        border ||
        `1px solid ${
          error
            ? themeColors["failureColor"]
            : themeColors["secondaryColorLighter"]
        }`,
      "& .MuiInput-underline:before": {
        display: "none !important",
      },
      "& .MuiInput-underline:after": {
        display: "none !important",
      },
      "& .MuiInputBase-root": {
        fontSize: "inherit",
        fontFamily: "inherit !important",
        height: height,
      },
      "& .MuiInputBase-input": {
        paddingLeft: "6px !important",
        paddingRight: "6px !important",
        color: themeColors["secondaryColor"],
      },
    },
    formHelperText: {
      fontSize: fontSizeHelperText,
      fontFamily: "inherit",
      minWidth: minWidth || "120px",
      maxWidth: maxWidth || "350px",
      width,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: `${themeColors["failureColor"]} !important`,
    },
  });
  const classes = useStyles();

  return (
    <FormControl
      className={classes.formControl}
      error={error}
      disabled={disabled}
    >
      <TextField
        id={`sigviewTextField-${v4()}`}
        label=""
        value={value}
        onChange={handleChange}
        className={`${classes.textField} ${customClassName}`}
        placeholder={t(placeholder) || t("Enter text")}
        onFocus={onFocus}
        onBlur={onBlur}
        type={type}
        disabled={disabled}
      />
      {error && (
        <FormHelperText className={classes.formHelperText} title={helperText}>
          {t(helperText)}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default SigviewTextField;
