// Import utils
import { config } from "../config/config";
import {
  getTimeZoneObj,
  getDatePresetObj,
  areSelectedDatesValid,
} from "./timeFiltersUtils";
import {
  getDimensionObjByOriginalID,
  getMeasureObjByOriginalID,
  getMetricTypeForChartObject,
  getDimensionObjByID,
  getMeasureObjByID,
} from "./plotlyUtils";
import { getOrderByDetailsFromOrderBy } from "./reportUtils";
import {
  transformFiltersBackendToUi,
  transformMetricFiltersBackendToUi,
  transformFiltersUiToBackend,
} from "./filtersUtils";
import {
  updateStandaloneChartForm,
  updateTimeFilters,
  updateTimeFiltersWithDefaultDates,
} from "../redux/actions";
import standaloneChartReducer from "../redux/reducers/standaloneChart";
import timeFiltersReducer from "../redux/reducers/timeFilters";
import allTimezones from "../../assets/data/allTimezones.json";
import allDatePresets from "../../assets/data/allDatePresets.json";
import chartList from "../../assets/data/chartTitle.json";
import { v4 } from "uuid";
import { masterMakeChartObject } from "./utils";
import { getSigviewUserType } from "../utils/utils";
import globalFilters from "../redux/reducers/globalFilters";

export const unwrapperTimeFilters = (props) => {
  const {
    allData,
    chartObject,
    calendarDaysLimits,
    user,
    variant = "",
  } = props;
  const { metadata = {}, requestParam = {} } = chartObject;
  const { comparisonMode: isComparisonOn = false } = metadata;
  const {
    dateRangeType = "Custom",
    dateRange = {},
    timeZone = {},
    progressiveDate = "true",
    // compareDateRange = {},
    secondaryComparisonDates = [],
  } = requestParam;

  let { compareDate = {}, compareDateRange = {} } = requestParam;

  if (
    variant === "share" ||
    (user.screen?.activePivot?.type === undefined &&
      window.location.pathname === "/pivotx")
  ) {
    compareDateRange = compareDate;
  }

  let timeFiltersAction;
  let compareSelectedStartDateArr = [];
  if (isComparisonOn) {
    let compareSelectedStartDatesEpochs = [compareDateRange.startDate];
    for (const compareDateRange of secondaryComparisonDates) {
      compareSelectedStartDatesEpochs.push(compareDateRange.startDate);
    }
    for (const startEpoch of compareSelectedStartDatesEpochs) {
      const compareSelectedStartDateEpoch = parseInt(startEpoch);
      let selectedTimezone = getTimeZoneObj(timeZone.name, allTimezones);
      let compareSelectedStartDateEpochInUTC =
        compareSelectedStartDateEpoch +
        selectedTimezone.minutesOffset * 60 * 1000;
      let compareSelectedStartDateEpochInUserSystemTimezone =
        compareSelectedStartDateEpochInUTC +
        new Date().getTimezoneOffset() * 60 * 1000;
      let compareSelectedStartDate = new Date(
        compareSelectedStartDateEpochInUserSystemTimezone
      );
      compareSelectedStartDateArr.push(compareSelectedStartDate);
    }
  }

  if (progressiveDate === "true") {
    if (dateRangeType !== "Custom") {
      timeFiltersAction = updateTimeFilters({
        selectedTimezone: getTimeZoneObj(timeZone.name, allTimezones),
        initialStartDateEpoch: dateRange.startDate,
        initialEndDateEpoch: dateRange.endDate,
        startDateEpoch: allData.dateRange.data.startDate || dateRange.startDate,
        endDateEpoch: allData.dateRange.data.endDate || dateRange.endDate,
        allDatePresets,
        format: config.hardCoded.datetimeFormat,
        selectedDatePreset: getDatePresetObj(
          dateRangeType,
          allDatePresets,
          "name"
        ).id,
        daysLimit: calendarDaysLimits,
        isComparisonOn: isComparisonOn,
        ...(isComparisonOn
          ? { compareSelectedStartDate: compareSelectedStartDateArr }
          : {}),
      });
    } else {
      timeFiltersAction = updateTimeFilters({
        selectedTimezone: getTimeZoneObj(timeZone.name, allTimezones),
        initialStartDateEpoch: dateRange.startDate,
        initialEndDateEpoch: dateRange.endDate,
        startDateEpoch: allData.dateRange.data.startDate || dateRange.startDate,
        endDateEpoch: allData.dateRange.data.endDate || dateRange.endDate,
        allDatePresets,
        format: config.hardCoded.datetimeFormat,
        selectedDatePreset: "last_2_days",
        daysLimit: calendarDaysLimits,
        isComparisonOn: isComparisonOn,
        ...(isComparisonOn
          ? { compareSelectedStartDate: compareSelectedStartDateArr }
          : {}),
      });
    }
  } else {
    timeFiltersAction = updateTimeFilters({
      selectedTimezone: getTimeZoneObj(timeZone.name, allTimezones),
      startDateEpoch: allData.dateRange.data.startDate || dateRange.startDate,
      endDateEpoch: allData.dateRange.data.endDate || dateRange.endDate,
      allDatePresets,
      format: config.hardCoded.datetimeFormat,
      selectedStartDateEpoch: dateRange.startDate,
      selectedEndDateEpoch: dateRange.endDate,
      daysLimit: calendarDaysLimits,
      selectedDatePreset: dateRangeType,
      isComparisonOn: isComparisonOn,
      ...(isComparisonOn
        ? { compareSelectedStartDate: compareSelectedStartDateArr }
        : {}),
    });
  }

  let timeFilters = timeFiltersReducer({}, { ...timeFiltersAction, variant });
  var areSelectedDatesValidFlag = areSelectedDatesValid({
    selectedDates: {
      startDate: timeFilters.selectedDates.startDate.epoch,
      endDate: timeFilters.selectedDates.endDate.epoch,
    },
    validDates: {
      startDate: allData.dateRange.data.startDate || dateRange.startDate,
      endDate: allData.dateRange.data.endDate || dateRange.endDate,
    },
  });
  if (!areSelectedDatesValidFlag) {
    timeFiltersAction = updateTimeFiltersWithDefaultDates({
      selectedTimezone: getTimeZoneObj(timeZone.name, allTimezones),
      startDateEpoch: allData.dateRange.data.startDate || dateRange.startDate,
      endDateEpoch: allData.dateRange.data.endDate || dateRange.endDate,
      allDatePresets,
      format: config.hardCoded.datetimeFormat,
      selectedDatePreset: "last_2_days",
      daysLimit: calendarDaysLimits,
      isComparisonOn: isComparisonOn,
    });
    timeFilters = timeFiltersReducer({}, timeFiltersAction);
  }
  // ! Add comparisonType: "abs_change": HARD CODED
  timeFilters = { ...timeFilters, comparisonType: "abs_change" };
  return timeFilters;
};

const unwrapperPivotXAdvancedSort = (props = {}) => {
  const { pivotXAdvancedSort = [], allData = {} } = props;
  const advanceSort = pivotXAdvancedSort.map((row) => {
    const { sortedOn = "", sortType = "" } = row;
    const cmPattern = /CM[0-9]{3}/g;
    const isSortedOnCm = cmPattern.test(sortedOn);
    let newRow = { ...row };
    if (sortType === "percentageDelta") {
      if (isSortedOnCm) {
        newRow = {
          ...row,
          sortType: "metric",
          sortedOn: `${sortedOn}_deltaPercentage`,
        };
      } else {
        let currMetricObj = getMeasureObjByID(sortedOn, allData.plotlyMetrics);
        newRow = {
          ...row,
          sortType: "metric",
          sortedOn: `${currMetricObj._id}_deltaPercentage`,
        };
      }
    } else if (sortType === "trueDelta") {
      if (isSortedOnCm) {
        newRow = {
          ...row,
          sortType: "metric",
          sortedOn: `${sortedOn}_trueDelta`,
        };
      } else {
        let currMetricObj = getMeasureObjByID(sortedOn, allData.plotlyMetrics);
        newRow = {
          ...row,
          sortType: "metric",
          sortedOn: `${currMetricObj._id}_trueDelta`,
        };
      }
    } else {
      if (isSortedOnCm) {
        return row;
      } else if (sortType === "metric") {
        let currMetricObj = getMeasureObjByID(sortedOn, allData.plotlyMetrics);
        newRow = { ...row, sortedOn: currMetricObj._id };
      } else {
        let currMetricObj = getDimensionObjByID(
          sortedOn,
          allData.plotlyDimensions
        );
        newRow = { ...row, sortedOn: currMetricObj._id };
      }
    }
    return newRow;
  });
  return advanceSort;
};

export const wrapperPivotXAdvancedSort = (props = {}) => {
  const { pivotXAdvancedSort = [], allData = {} } = props;
  const advanceSort = pivotXAdvancedSort.map((row) => {
    const cmPattern = /CM[0-9]{3}/g;
    const isSortedOnCm = cmPattern.test(row.sortedOn);
    let newRow = { ...row };
    let sortedOnPostText = "";
    let sortType = row.sortType;
    if (row.sortedOn.includes("deltaPercentage")) {
      sortedOnPostText = "_deltaPercentage";
      sortType = "percentageDelta";
    } else if (row.sortedOn.includes("trueDelta")) {
      sortedOnPostText = "_trueDelta";
      sortType = "trueDelta";
    }
    if (isSortedOnCm) {
      newRow = { ...row };
    } else if (row.sortType === "metric") {
      let currMetricObj = getMeasureObjByOriginalID(
        row.sortedOn.replace("_deltaPercentage", "").replace("_trueDelta", ""),
        allData.plotlyMetrics
      );
      newRow = { ...row, sortedOn: currMetricObj.measureID };
    } else {
      let currDimensionObj = getDimensionObjByOriginalID(
        row.sortedOn,
        allData.plotlyDimensions
      );
      newRow = { ...row, sortedOn: currDimensionObj.dimID };
    }
    newRow = {
      ...newRow,
      sortedOn: `${newRow.sortedOn}`,
      sortType: sortType,
    };
    return newRow;
  });
  return advanceSort;
};

export const unwrapperChartObject = (props) => {
  const { allData, user, payload, variant = "" } = props;
  try {
    const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarReports;
    const chartObject = payload.chartObject;
    const unwrapperPivotXAdvancedSortProps = {
      pivotXAdvancedSort: chartObject.requestParam.pivotXAdvancedSort || [],
      allData,
    };
    const advanceSort = unwrapperPivotXAdvancedSort(
      unwrapperPivotXAdvancedSortProps
    );
    const isComparisonOn = chartObject.metadata.comparisonMode || false;
    const id = payload._id;
    const chartType = chartObject.metadata.chartType;
    const title = chartObject.metadata.title;
    const dimensionFilters = transformFiltersBackendToUi(
      chartObject.requestParam.filter || [],
      allData.plotlyDimensions
    );
    const metricFilters = transformMetricFiltersBackendToUi(
      chartObject.requestParam.metricFilter || [],
      allData.plotlyMetrics
    );
    const progressiveDate =
      chartObject?.requestParam?.progressiveDate || "true";
    const orderByDetails = getOrderByDetailsFromOrderBy(
      chartObject.requestParam.orderBy
    );
    const dimensionsList = chartObject.requestParam.xAxis.map((originalId) =>
      getDimensionObjByOriginalID(originalId, allData.plotlyDimensions)
    );
    // const metricsList = formObject.metricOrder.map((row) =>
    const allMetrics = [
      ...chartObject.requestParam.yAxis,
      ...chartObject.requestParam.specialCalculation,
      ...chartObject.requestParam.approxCountDistinct,
    ];
    const metricsList = allMetrics.map((row) =>
      getMeasureObjByOriginalID(row, allData.plotlyMetrics)
    );
    // ! TODO: Get Clear about the percentCalList arr is only contains row or row.id
    const percentCalListMod = chartObject.requestParam.percentCalList.map(
      (row) => {
        if (row.id) return row.id;
        else {
          return row;
        }
      }
    );
    const percentCalList = percentCalListMod.map((row) =>
      getMeasureObjByOriginalID(row, allData.plotlyMetrics)
    );
    const timeFilters = unwrapperTimeFilters({
      chartObject,
      calendarDaysLimits,
      user,
      allData,
      isComparisonOn,
      variant,
    });
    let standaloneChartInitialState = {
      id,
      title,
      dimensionsList,
      metricsList,
      metricFilters,
      dimensionFilters,
      timeFilters,
      orderById: orderByDetails.orderById,
      orderBy: orderByDetails.orderBy,
      chartType,
      chartList,
      valid: true,
      progressiveDateFlag: progressiveDate === "true",
      advanceSort,
      percentCalList,
    };
    // Updating plot by calling the reducer
    // Adding required business logic
    const actionPayload = { key: "chartType", value: chartType };
    const reduxAction = updateStandaloneChartForm(actionPayload);
    standaloneChartInitialState = standaloneChartReducer(
      standaloneChartInitialState,
      reduxAction
    );
    // If chartType is pivotx, keep it pivotx
    if (chartType === "pivotx")
      return { ...standaloneChartInitialState, chartType: "pivotx" };
    return standaloneChartInitialState;
  } catch (error) {
    console.groupCollapsed("UI ERROR -> CORRUPT CHART OBJECT");
    console.log("ERROR ->", error);
    console.log("PROPS", props);
    console.groupEnd();
    let initialStandaloneChartState = {
      id: `chart-object-${v4()}`,
      title: "Untitled - nth",
      dimensionsList: [],
      metricsList: [],
      orderById: "",
      orderBy: "asc",
      chartType: "bar",
      metricFilters: [],
      dimensionFilters: [],
      timeFilters: user.timeFilters,
      chartList,
      valid: false,
      advanceSort: [],
      percentCalList: [],
    };
    return initialStandaloneChartState;
  }
};

export const validateChartName = (name) => {
  const sigviewUserType = getSigviewUserType();
  const runCheckFlag =
    sigviewUserType === "sigview"
      ? !/^[A-z0-9-_ ]*$/.test(name)
      : !/^[a-zA-Z0-9-_!@#$%^&*()+=~|:;',./<>? \u3040-\u309F\u30A0-\u30FF-\u31F0-\u31FF-\u2E80-\u2EFF-\u2F00-\u2FDF-\u3000-\u303F-\u31C0-\u31EF-\u3200-\u32FF-\u3300-\u33FF-\u3400-\u3FFF-\u4000-\u4DBF-\u4E00-\u4FFF]*$/.test(
          name
        );
  //Common Validations
  if (runCheckFlag)
    return {
      status: "invalid",
      message: "Name cannot have special characters",
    };

  if (name.length < 1)
    return {
      status: "invalid",
      message: "Name must have at least 1 character",
    };

  if (name.replace(/ /g, "").length === 0)
    return {
      status: "invalid",
      message: "Name cannot have only spaces",
    };

  if (name.length > 50)
    return {
      status: "invalid",
      message: "Name cannot exceed 50 characters",
    };

  return { status: "valid", message: "" };
};

const unwrapperChartObjectForMetrics = (props) => {
  const {
    dsForm = {},
    user = {},
    allData = {},
    timeFilters = {},
    dimensionFilters = [],
  } = props;
  const { selectedKpis = {} } = dsForm;
  const selectedKpisIds = Object.keys(selectedKpis.value) || [];
  let yAxis = [],
    specialCalculation = [],
    approxCountDistinct = [];
  selectedKpisIds.forEach((kpiId) => {
    const metricObj = getMeasureObjByOriginalID(kpiId, allData.plotlyMetrics);
    const metricType = getMetricTypeForChartObject(metricObj);
    if (metricType === "base_sum") {
      yAxis.push(kpiId);
    } else if (metricType === "custom") {
      specialCalculation.push(kpiId);
    } else if (metricType === "base_approxCountDistinct") {
      approxCountDistinct.push(kpiId);
    }
  });
  const payload = {
    _id: v4(),
    emailId: user?.reqMetadata?.email,
    orgViewReq: {
      organization: user?.reqMetadata?.organization,
      view: user?.reqMetadata?.view,
    },
    chartObject: {
      metadata: {
        ...config.hardCoded.payload.getDataMetricMetadata,
        dataLimit: 500,
      },
      requestParam: {
        xAxis: [],
        yAxis,
        specialCalculation,
        approxCountDistinct,
        filter: transformFiltersUiToBackend([...dimensionFilters]),
        timeZone: {
          name: timeFilters.selectedTimezone.name,
          location: timeFilters.selectedTimezone.location,
          value: timeFilters.selectedTimezone.value,
        },
        dateRange: {
          startDate: timeFilters.selectedDatesQE.startDate.toString(),
          endDate: timeFilters.selectedDatesQE.endDate.toString(),
        },
        orderBy: {},
      },
    },
  };
  return payload;
};

export const wrapperOrgViewReq = (user = {}) => ({
  organization: user?.reqMetadata?.organization,
  view: user?.reqMetadata?.view,
});

const wrapperChartObject = (props = {}) => {
  const { selections = {}, user = {} } = props;
  const { title = v4() } = selections;
  const {
    progressiveDateFlag = false,
    feature = "",
    timeFilters = {},
    orderByType = "id_only",
  } = selections;
  const { isComparisonOn: comparisonMode = false } = timeFilters;
  const chartObject = masterMakeChartObject({
    metadataParams: {
      title,
      chartType: selections.chartType,
      dataLimit: selections.dataLimit || 5000,
      comparisonMode: comparisonMode,
    },
    filters: {
      timeFilters: selections.timeFilters,
      metricFilters: selections.metricFilters,
      dimensionFilters: selections.dimensionFilters,
    },
    orderByDetails: {
      orderById: selections.orderById,
      orderBy: selections.orderBy,
      orderByType: orderByType,
    },
    dimensionsList: selections.dimensionsList,
    metricsList: selections.metricsList,
    percentCalList: selections.percentCalList,
    settings: {
      compareFlag: selections.timeFilters.isComparisonOn,
      progressiveDateFlag: progressiveDateFlag,
    },
    granularity: selections.granularity,
  });
  const payload = {
    _id: title,
    emailId: user?.reqMetadata?.email,
    orgViewReq: wrapperOrgViewReq(user),
    chartObject,
    feature,
  };
  return payload;
};

const wrapperChart = (props = {}) => {
  const { user = {}, chartName = "", standaloneChart } = props;
  const chartObject = masterMakeChartObject({
    metadataParams: {
      title: chartName.value,
      chartType: standaloneChart.chartType,
      comparisonMode: false,
    },
    filters: {
      timeFilters: standaloneChart.timeFilters,
      metricFilters: standaloneChart.metricFilters,
      dimensionFilters: standaloneChart.dimensionFilters,
    },
    orderByDetails: {
      orderById: standaloneChart.orderById,
      orderBy: standaloneChart.orderBy,
      orderByType: "id_only",
    },
    dimensionsList: standaloneChart.dimensionsList,
    metricsList: standaloneChart.metricsList,
    settings: { compareFlag: false, progressiveDateFlag: true },
  });
  const payload = {
    _id: standaloneChart.id,
    emailId: user?.reqMetadata?.email,
    orgViewReq: wrapperOrgViewReq(user),
    chartObject,
  };
  return payload;
};

// This function doesn't give a valid UI chart object, it just gives an object with place holder values
const getChartObjectUiPlaceHolder = () => ({
  id: "", // "D011",
  title: "", //"App/Site Domain",
  dimensionsList: [],
  metricsList: [],
  metricFilters: [],
  dimensionFilters: [],
  timeFilters: {}, // ! invalid time filters; it can break the UI; please update time filters before using
  orderById: "", // "M002",
  orderBy: "desc",
  orderByType: "id_only",
  chartType: "multitable",
  chartList: chartList,
  valid: false, // ! change it to true
  progressiveDateFlag: true,
  advanceSort: [],
  percentCalList: [],
  renderFlag: false,
});

export {
  unwrapperChartObjectForMetrics,
  wrapperChartObject,
  wrapperChart,
  getChartObjectUiPlaceHolder,
};
