import React, { useContext } from "react";

import { Grid } from "@material-ui/core";
// import { height, padding } from "@mui/system";

// Import contexts
import { ThemeContext } from "../../../js/contexts/ThemeContext";
import { UserConsumer } from "../../contexts/AdminContext";

function LayoutAdminContent(props) {
  // contentHeight = "calc(100% - 35px)"
  // const { contentHeight = "calc(100% - 35px)" } = props;

  const {
    children,
    fullHeight = "calc(100% - 200px)",
    padding = "25px",
  } = props;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  return (
    <UserConsumer>
      {(props) => {
        return (
          <div
            style={{
              padding: padding,
              height: fullHeight,
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                border: `1px solid ${themeColors["secondaryColorLighter"]}`,
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  // justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "250px",
                  }}
                >
                  {children[0]}
                </div>

                <div
                  style={{
                    height: "100%",
                    width: "calc(100% - 250px)",
                    minWidth: "100px",
                    borderLeft: `1px solid ${themeColors["secondaryColorLighter"]}`,
                  }}
                >
                  {/* <div
            style={{
              display: "flex",
              // flexDirection: "column",
              height: "100%",
              // justifyContent: "space-between",
              flexDirection: "column",
              borderLeft: `1px solid ${themeColors["secondaryColorLighter"]}`,
            }}
          > */}
                  <div
                    style={{
                      // display: "flex",
                      height: "35px",
                      // justifyContent: "space-between",
                      // flexDirection: "column",
                    }}
                  >
                    {children[1]}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: props,
                      justifyContent: "space-evenly",
                      flexDirection: "row",
                      // background: "red",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        // background: "blue",
                        width: "calc(100% / 3)",
                        padding: "10px",
                        boxSizing: "border-box",
                        // height: "100%",
                      }}
                    >
                      {children[2]}
                    </div>
                    <div
                      style={{
                        // background: "blue",
                        width: "calc(100% / 3)",
                        padding: "10px",
                        boxSizing: "border-box",
                        // height: "100%",
                      }}
                    >
                      {children[3]}
                    </div>
                    <div
                      style={{
                        // background: "blue",
                        width: "calc(100% / 3)",
                        padding: "10px",
                        boxSizing: "border-box",
                        // height: "100%",
                      }}
                    >
                      {children[4]}
                    </div>
                  </div>
                  {/* </div> */}
                  {children[5] && (
                    <div
                      style={{
                        height: "50px",
                      }}
                    >
                      {children[5]}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </UserConsumer>
  );
}

export default LayoutAdminContent;
