// Import required libraries
import React, {
  useState,
  useReducer,
  useEffect,
  useContext,
  memo,
} from "react";
import isEqual from "lodash.isequal";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useTranslation } from "react-i18next";

// Import custom components
import SigviewDateRangeSelect from "../Common/SigviewDateRangeSelect";
import SigviewDateSingleSelect from "../Common/SigviewDateSingleSelect";
import SigviewSingleSelect from "../Common/SigviewSingleSelect";
import SigviewCheckboxGroup from "../Common/SigviewCheckboxGroup";
import SigviewSimpleText from "../Common/SigviewSimpleText";
import SigviewButton from "../Common/SigviewButton";
import SigviewRadioGroup from "../Common/SigviewRadioGroup";
import SigviewVR from "../Common/SigviewVR";
import SigviewIcon from "../Common/SigviewIcon";

// Import styles
import "./TimeFilters.scss";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import config
import { config } from "../../config/config";

// Import data/utils
import allTimezones from "../../../assets/data/allTimezones.json";
import allDatePresets from "../../../assets/data/allDatePresets.json";
import allCompareDatePresets from "../../../assets/data/allCompareDatePresets.json";
import calendarComparisonType from "../../../assets/data/calendarComparisonType.json";
import {
  getTimeZoneObj,
  getDatePresetObj,
  subtractOneDayMinus1SecondMilliseconds,
  getCompareNewRow,
  getObjectByKey,
  getPrimaryCompareDateObject,
} from "../../utils/timeFiltersUtils";
import useReducerLogger from "../../utils/useReducerLogger";
import { masterTrackGaEvent } from "../../services/ga";

// Import timeFilters reducer and initialState and constants
import timeFiltersReducer from "../../redux/reducers/timeFilters";

// Import action creators
import {
  timezoneChanged,
  datePresetChanged,
  dateSelectedChanged,
  replaceTimeFilters,
  compareDateSelectedChanged,
  toggleCompare,
  updateCalendarComparisonType,
  updateActiveCompareDateRow,
  addCompareRow,
  removeCompareRow,
  compareDatePresetChanged,
} from "../../redux/actions";

function areEqual(prevProps, nextProps) {
  let prev = {
    ...prevProps,
    allData: { ...prevProps.allData, banner: {} },
    onChange: null,
    rollingDatesProps: {
      ...prevProps.rollingDatesProps,
      onChange: null,
    },
  };
  let next = {
    ...nextProps,
    allData: {
      ...nextProps.allData,
      banner: {},
    },
    onChange: null,
    rollingDatesProps: {
      ...nextProps.rollingDatesProps,
      onChange: null,
    },
  };

  return isEqual(prev, next);
}
function TimeFilters(props) {
  const { t } = useTranslation();

  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    user,
    allData,
    initialTimeFilters,
    onChange,
    googleAnalytics,
    calendarDaysLimits,
    rollingDatesProps = {},
    settings = {},
  } = props;
  const {
    compareCalendarRowLimit = 1, //hard coded; it will come from /getUserAccessList
    isMultiCompareRowAllowed = false, //hard coded; it will come from /getUserAccessList
    isComparisonAvailable = false, //hard coded; it should come from where this component is being called
    isChangeTypeDropdownAvailable = true,
    isApplyButtonAvailable = false,
    isRollingDatesAvailable = false,
  } = settings;
  const {
    value: rollingDatesValue = "yes",
    onChange: rollingDatesOnChange = () => {},
  } = rollingDatesProps;

  const { disabled = false } = settings;
  //Defining the main reducer
  const [state, dispatch] = useReducer(
    useReducerLogger(timeFiltersReducer),
    initialTimeFilters
  );

  const pathname = window.location.pathname;
  const rootPage = pathname.split("/")[1];
  //Defining required states
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateParentTimeFilters, setUpdateParentTimeFilters] = useState(true);
  // const [activated, setActivated] = useState(false);

  // Define styles
  const useStyles = makeStyles(() => ({
    root: {
      zIndex: "999999999 !important",
      backgroundColor: themeColors["overlayBgColor"],
      "& .MuiPaper-root": {
        backgroundColor: themeColors["popoverBgColor"],
      },
    },
    dateRangeContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
  }));

  //Defining required hooks
  const classes = useStyles();

  //ALL USE EFFECTS
  //Update local timeFilters state if props change
  useEffect(() => {
    //Perform it only when they are not equal to avoid infinite loop
    if (!isEqual(initialTimeFilters, state)) {
      const action = replaceTimeFilters(initialTimeFilters);
      dispatch(action);
    }
  }, [initialTimeFilters]);
  //OnChange handler for timeFilters (update parent state everytime state changes)
  useEffect(() => {
    //Perform it only when they are not equal to avoid infinite loop
    //Update only when updateParentTimeFilters is true
    //It's to cater to the case when user is making changes in compare calendar
    if (!isEqual(initialTimeFilters, state) && updateParentTimeFilters) {
      if (!isApplyButtonAvailable) {
        onChange(state);
      }
    }
  }, [state]);

  //Defining required functions
  //Update timezone
  const updateTimezone = (selectedTimezoneName) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "TimeFilters",
      action: "TimeZone",
      label: selectedTimezoneName,
    });
    const action = timezoneChanged({
      selectedTimezone: getTimeZoneObj(selectedTimezoneName, allTimezones),
      startDateEpoch: allData.dateRange.data.startDate,
      selectedDatePreset: state.selectedDatePreset,
      endDateEpoch: allData.dateRange.data.endDate,
      allDatePresets,
      format: config.hardCoded.datetimeFormat,
      selectedStartDate: state.selectedDates.startDate.nativeDateObj,
      selectedEndDate: new Date(
        subtractOneDayMinus1SecondMilliseconds(
          state.selectedDates.endDate.nativeDateObj.valueOf()
        )
      ),
      daysLimit: calendarDaysLimits,
    });
    dispatch(action);
  };

  //Update datePreset
  const updateDatePreset = (selectedDatePreset) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "TimeFilters",
      action: "Preset",
      label: getDatePresetObj(selectedDatePreset, allDatePresets, "id").name,
    });
    const action = datePresetChanged({
      selectedDatePreset,
      selectedTimezone: state.selectedTimezone,
      startDateEpoch: allData.dateRange.data.startDate,
      endDateEpoch: allData.dateRange.data.endDate,
      format: config.hardCoded.datetimeFormat,
      daysLimit: calendarDaysLimits,
    });
    dispatch(action);
  };

  //Apply selected dates to dashboard
  const applySelectedDates = (dateSelection) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "TimeFilters",
      action: "Calendar",
      label: "Calendar",
    });
    const action = dateSelectedChanged({
      selectedStartDate: dateSelection.startDate,
      selectedEndDate: dateSelection.endDate,
      selectedTimezone: state.selectedTimezone,
      startDateEpoch: allData.dateRange.data.startDate,
      endDateEpoch: allData.dateRange.data.endDate,
      selectedDatePreset: state.selectedDatePreset,
      allDatePresets,
      format: config.hardCoded.datetimeFormat,
      daysLimit: calendarDaysLimits,
    });
    dispatch(action);
  };

  //Update compare dates
  const applyCompareDates = (newDate, compareRowId) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "TimeFilters",
      action: "Compare",
      label: "Calendar",
    });
    const action = compareDateSelectedChanged({
      compareRowId,
      compareSelectedStartDate: newDate,
    });
    dispatch(action);
  };

  const handleAddCompareRow = () => {
    const addedCompareRow = getCompareNewRow();
    const payload = {
      addedCompareRow,
    };
    const action = addCompareRow(payload);
    dispatch(action);
  };

  const handleRemoveCompareRow = (compareRowId) => {
    const payload = {
      compareRowId,
    };
    const action = removeCompareRow(payload);
    dispatch(action);
  };

  const handleClick = (event) => {
    setUpdateParentTimeFilters(false);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    //Close the popover
    setAnchorEl(null);

    //Update state so that other changes in time filters can update parent state
    setUpdateParentTimeFilters(true);

    //Reset child state to initial parent state
    if (!isEqual(initialTimeFilters, state)) {
      const action = replaceTimeFilters(initialTimeFilters);
      dispatch(action);
    }
  };

  const updateCompareCalendarInParent = () => {
    //Close the popover
    setAnchorEl(null);

    //Update state so that other changes in time filters can update parent state
    setUpdateParentTimeFilters(true);

    //Update parent state to the new state
    if (!isEqual(initialTimeFilters, state)) {
      onChange(state);
    }
  };

  const handleCompareDatePresetChange = (
    compareRowId,
    newCompareDatePreset
  ) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "TimeFilters",
      action: "ComparePreset",
      label: getDatePresetObj(newCompareDatePreset, allCompareDatePresets, "id")
        .name,
    });
    const payload = { compareRowId, newCompareDatePreset };
    const action = compareDatePresetChanged(payload);
    dispatch(action);
  };

  const handleCompareTypeChange = (newValue) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "TimeFilters",
      action: "CompareType",
      label: getObjectByKey({ data: calendarComparisonType, value: newValue })
        .name,
    });
    const payload = { newComparisonType: newValue };
    const action = updateCalendarComparisonType(payload);
    dispatch(action);
  };

  const handleToggleCompare = (event, id, checked) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "TimeFilters",
      action: "Compare",
      label: checked ? "On" : "Off",
    });
    dispatch(toggleCompare());
  };

  const handleToggleRollingDates = (event, id, checked) => {
    // Google Analytics Event - Master
    // masterTrackGaEvent({
    //   category: "TimeFilters",
    //   action: "Compare",
    //   label: checked ? "On" : "Off",
    // });
    const newValue = checked ? "yes" : "no";
    rollingDatesOnChange(newValue);
  };

  const handleApply = () => {
    onChange(state);
    // setActivated(false);
  };

  const handleCancel = () => {
    //Update local state to initial state only when it's different; otherwise it logs at every click
    if (!isEqual(initialTimeFilters, state)) {
      const action = replaceTimeFilters(initialTimeFilters);
      dispatch(action);
    }
  };

  // const handleTimeFiltersDivClick = () => {
  //   console.log("CLICKED");
  //   if (!activated) {
  //     setActivated(true);
  //   }
  // };

  // //NOT WORKING PROPERLY
  // const handleOverlayClick = () => {
  //   console.log("OVERLAY WAS CLICKED");
  //   if (activated) {
  //     handleCancel();
  //   }
  // };

  //Defining required variables
  const open = Boolean(anchorEl);
  const id = open ? "sigviewCompareCalendarPopover" : undefined;
  const calendarComparisonData = [
    { id: "compareWith", name: "Compare", checked: state.isComparisonOn },
  ];
  const rollingDatesData = [
    {
      id: "rollingDates",
      name: "Rolling Dates",
      checked: rollingDatesValue === "yes",
    },
  ];
  const primaryCompareDateObject = getPrimaryCompareDateObject(state);
  const timeFiltersCompareText = `${moment(
    primaryCompareDateObject.compareSelectedDates.startDate.nativeDateObj
  ).format(config.hardCoded.dateFormatFilterMenu)} - ${moment(
    primaryCompareDateObject.compareSelectedDates.endDate.nativeDateObj
  ).format(config.hardCoded.dateFormatFilterMenu)}`;
  const calendarRadioData = [
    { id: "showInTable", name: "Show in table", disabled: false },
  ];
  const showInTableStyle = {
    display: compareCalendarRowLimit === 1 ? "none" : "auto",
  };
  const showAddNewCompareRowIcon =
    isMultiCompareRowAllowed &&
    state.compareDates.length < compareCalendarRowLimit;
  const addNewCompareRowStyle = {
    visibility: showAddNewCompareRowIcon ? "visible" : "hidden",
  };
  const buttonsOptions = [
    {
      id: "apply",
      label: "Apply",
      onClick: handleApply,
      disabled: isEqual(initialTimeFilters, state),
    },
    { id: "cancel", label: "Cancel", onClick: handleCancel, disabled: false },
  ];
  // const timeFiltersOuterContainer = document.getElementById(
  //   "menuBarTimeFiltersContainer"
  // );
  // const timeFiltersTop = timeFiltersOuterContainer?.offsetTop || "0px";
  // const timeFiltersLeft = timeFiltersOuterContainer?.offsetLeft || "0px";
  // const timeFiltersContainerStyle = activated
  //   ? { top: timeFiltersTop + 28, left: timeFiltersLeft } //added header height
  //   : {};

  //Debugging
  // console.groupCollapsed("TIME FILTERS");
  // console.log("timeFiltersOuterContainer", timeFiltersOuterContainer);
  // console.log("timeFiltersCompareText", timeFiltersCompareText);
  // console.log("timeFiltersTop", timeFiltersTop);
  // console.log("timeFiltersLeft", timeFiltersLeft);
  // console.log("timeFiltersContainerStyle", timeFiltersContainerStyle);
  // console.log("activated", activated);
  // console.log("state", state);
  // console.groupEnd();

  return (
    // <section
    //   className={`time-filters-overlay ${activated ? "activated" : ""}`}
    //   onClick={handleOverlayClick}
    // >
    //   <section
    //     className={`time-filters-outer-container ${
    //       activated ? "activated" : ""
    //     }`}
    //   ></section>
    // ! Removing ClickAway Listener because creating bug - Refer TimeFilter bug sheet - BUG#5
    // <ClickAwayListener onClickAway={handleCancel}>
    <section
      className="time-filters-container"
      // style={timeFiltersContainerStyle}
      // className={`time-filters-container ${activated ? "activated" : ""}`}
      // onClick={handleTimeFiltersDivClick}
    >
      {/* <SigviewTooltip title="Timezone" top="-12px"> */}
      <div className="time-filter-timezone-container">
        <SigviewSingleSelect
          value={state.selectedTimezone.name}
          data={allTimezones.map((row) => ({
            ...row,
            id: row.name,
            name: row.name,
            disabled: false,
          }))}
          onChange={(newValue) => {
            updateTimezone(newValue);
          }}
          minWidth="10px"
          border="none"
          theme={user.theme}
          themeColors={user.themeColors}
          fontSize="12px"
          disabled={disabled}
          customID={state.selectedTimezone.name}
          customClassName={`${rootPage}selectedTimezone-GA`}
        />
      </div>
      {/* </SigviewTooltip> */}

      <SigviewVR />

      {/* <SigviewTooltip title="Date Range" top="-12px"> */}
      <div className="time-filter-date-preset-container">
        <SigviewSingleSelect
          value={state.selectedDatePreset}
          data={state.validDatePresets.map((row) => ({
            ...row,
            disabled: false,
          }))}
          onChange={(newValue) => {
            updateDatePreset(newValue);
          }}
          minWidth="10px"
          border="none"
          theme={user.theme}
          themeColors={user.themeColors}
          fontSize="12px"
          disabled={disabled}
          customClassName={`${rootPage}selectedDatePreset-GA`}
        />
      </div>
      {/* </SigviewTooltip> */}

      <SigviewVR />

      {/* <SigviewTooltip title="Select Dates" top="-12px"> */}
      <div className="time-filters-calendar-picker">
        <SigviewDateRangeSelect
          value={{
            startDate: state.selectedDates.startDate.nativeDateObj,
            endDate: state.selectedDates.endDate.nativeDateObj,
          }}
          onChange={applySelectedDates}
          minMaxDates={{
            minDate: state.validDates.startDate.nativeDateObj,
            maxDate: state.validDates.endDate.nativeDateObj,
          }}
          format={config.hardCoded.dateFormatFilterMenu}
          daysLimit={calendarDaysLimits}
          isMinMaxDateVisible={true}
          timezone={state.selectedTimezone.name}
          disabled={disabled}
          user={user}
        />
      </div>
      {/* </SigviewTooltip> */}

      {isRollingDatesAvailable && (
        <>
          <SigviewVR />
          <div className="time-filters-comparison-checkbox-container">
            <SigviewCheckboxGroup
              data={rollingDatesData}
              onChange={handleToggleRollingDates}
              disabled={disabled}
              customID="rollingDateStatus-GA"
            />
          </div>
        </>
      )}

      {isComparisonAvailable && (
        <>
          <SigviewVR />
          <div className="time-filters-comparison-checkbox-container">
            <SigviewCheckboxGroup
              data={calendarComparisonData}
              onChange={handleToggleCompare}
              disabled={disabled}
              customID="compareCalendarStatus-GA"
              customClassName={`${rootPage}compareCalendarStatus-GA`}
            />
          </div>
        </>
      )}

      {isComparisonAvailable && state.isComparisonOn && (
        <>
          <SigviewVR />
          <div className="time-filters-comparison-text" onClick={handleClick}>
            <SigviewSimpleText
              value={timeFiltersCompareText}
              style={{
                backgroundColor: "transparent",
                border: "none",
                fontSize: "12px",
              }}
            >
              {/* <span className="material-icons selected-date-formatted-icon">
                  arrow_drop_down
                </span> */}
              <SigviewIcon
                iconName="expand_more"
                style={{
                  fontSize: "14px !important",
                  padding: "0 0 0 6px !important",
                  color: themeColors["secondaryColor"],
                  hoverColor: themeColors["secondaryColor"],
                  cursor: "default",
                }}
              />
            </SigviewSimpleText>
          </div>
        </>
      )}

      {/* {isComparisonAvailable &&
        state.isComparisonOn &&
        state.compareDates.length > 1 && (
          <div className="time-filters-comparison-adhoc" onClick={handleClick}>
            <SigviewSimpleText
              value={`${state.compareDates.length - 1} More`}
              style={{ margin: "0px 5px 0px 0px" }}
            />
          </div>
        )} */}

      {isComparisonAvailable &&
        isChangeTypeDropdownAvailable &&
        state.isComparisonOn && (
          <>
            <SigviewVR />
            <div className="time-filter-date-preset-container">
              <SigviewSingleSelect
                value={state.comparisonType}
                data={calendarComparisonType}
                onChange={handleCompareTypeChange}
                minWidth="10px"
                margin={{ right: "3px" }}
                border="none"
                theme={user.theme}
                themeColors={user.themeColors}
                fontSize="12px"
                disabled={disabled}
                customClassName="compareChangePercentChange-GA"
              />
            </div>
          </>
        )}

      {isApplyButtonAvailable && !isEqual(initialTimeFilters, state) && (
        // <SigviewButtonSplit
        //   options={buttonsOptions}
        //   customStyle={{ margin: "0px 0px 0px 10px" }}
        // />
        <SigviewButton
          variant="contained"
          onClick={handleApply}
          title="Apply"
          disabled={isEqual(initialTimeFilters, state)}
          style={{ width: "50px", margin: { left: "5px", right: "5px" } }}
          customClassName={`${rootPage}submitCalendarDates-GA`}
        />
      )}

      {isComparisonAvailable && open && (
        <Popover
          id={id}
          open={open && !disabled}
          anchorEl={anchorEl}
          // onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          className={classes.root}
        >
          <div className="time-filters-comparison-calendar-container">
            <div className="time-filters-comparison-calendar-title-container">
              <p className="time-filters-comparison-calendar-title">
                {t("With Selected Date Range")}
              </p>
              <i
                className="material-icons time-filters-comparison-close-icon"
                onClick={handleClose}
              >
                close
              </i>
            </div>
            {state.compareDates
              .slice(0, compareCalendarRowLimit)
              .map((compareRow, index) => {
                const showDeleteIcon = !(
                  state.compareDates.length === 1 && index === 0
                );
                const deleteIconStyle = {
                  visibility: showDeleteIcon ? "visible" : "hidden",
                };
                return (
                  <div
                    className="time-filters-comparison-calendar-row"
                    key={compareRow.id}
                  >
                    <div className="time-filter-date-preset-container">
                      <SigviewSingleSelect
                        value={compareRow.compareSelectedDatePreset}
                        data={compareRow.compareValidDatePresets.map((row) => ({
                          ...row,
                          disabled: false,
                        }))}
                        onChange={(newValue) => {
                          handleCompareDatePresetChange(
                            compareRow.id,
                            newValue
                          );
                        }}
                        minWidth="105px"
                        margin={{ right: "5px" }}
                        theme={user.theme}
                        themeColors={user.themeColors}
                        fontSize="12px"
                        customClassName="comparePreset-GA"
                      />
                    </div>
                    <div className="time-filters-comparison-date-picker">
                      <SigviewDateSingleSelect
                        value={{
                          startDate:
                            compareRow.compareSelectedDates.startDate
                              .nativeDateObj,
                          endDate:
                            compareRow.compareSelectedDates.endDate
                              .nativeDateObj,
                        }}
                        onChange={(newDate) =>
                          applyCompareDates(newDate, compareRow.id)
                        }
                        showEndDate={true}
                        minMaxDates={{
                          minDate:
                            compareRow.compareValidDates.startDate
                              .nativeDateObj,
                          maxDate:
                            compareRow.compareValidDates.endDate.nativeDateObj,
                        }}
                        format={config.hardCoded.dateFormatFilterMenu}
                        daysLimit={calendarDaysLimits}
                        user={user}
                      />
                    </div>
                    <div
                      className="time-filters-comparison-row-menu"
                      style={showInTableStyle}
                    >
                      <SigviewRadioGroup
                        data={calendarRadioData}
                        value={compareRow.isSelected ? "showInTable" : ""}
                        onChange={() => {
                          const payload = { compareRowId: compareRow.id };
                          const action = updateActiveCompareDateRow(payload);
                          dispatch(action);
                        }}
                        style={{ rootFlexDirection: "row" }}
                      />
                      <i
                        className="material-icons-outlined time-filters-comparison-row-delete-icon"
                        onClick={() => {
                          handleRemoveCompareRow(compareRow.id);
                        }}
                        style={deleteIconStyle}
                      >
                        cancel
                      </i>
                    </div>
                  </div>
                );
              })}
            <div className="time-filters-comparison-calendar-menu-container">
              <div className="left-panel" style={addNewCompareRowStyle}>
                <i
                  className="material-icons-outlined time-filters-comparison-add-more-icon"
                  onClick={handleAddCompareRow}
                >
                  add_circle_outline
                </i>
                <p className="time-filters-comparison-calendar-compare-more-title">
                  {t("Compare more dates")}
                </p>
              </div>
              <SigviewButton
                variant="contained"
                onClick={() => {
                  updateCompareCalendarInParent();
                }}
                title="Done"
                style={{ margin: { left: "10px" }, width: "50px" }}
                customClassName="ApplyCompareCalendarChanges-GA"
              />
            </div>
          </div>
        </Popover>
      )}
    </section>
    // {/* </ClickAwayListener> */}
    // </section>
  );
}

TimeFilters.propTypes = {};

export default memo(TimeFilters, areEqual);
