// Import required libraries
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

function SigviewProgressBar() {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
      "& .MuiLinearProgress-colorPrimary": {
        backgroundColor: themeColors["secondaryColorLighter"],
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: themeColors["secondaryColor"],
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress />
    </div>
  );
}

export default SigviewProgressBar;
