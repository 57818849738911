// * Import utils
import C from "../constants";

export default function globalFilters(state, action) {
  const { type = "", payload = {} } = action;
  const { key = "", value = "" } = payload;

  let newState = {};
  switch (type) {
    case C.UPDATE_GLOBALFILTERS_DIMENSIONFILTERS:
      newState = {
        ...state,
        dimensionFilters: value,
      };
      break;
    case C.UPDATE_GLOBALFILTERS_TIMEFILTERS:
      newState = {
        ...state,
        timeFilters: value,
      };
      break;
    case C.UPDATE_GLOBALFILTERS_ROLLINGDATETYPE:
      newState = {
        ...state,
        rollingDateType: {
          ...state.rollingDateType,
          value: value,
        },
      };
      break;
    case C.UPDATE_MERTRIC_CHART_GRANULARITY:
      newState = {
        ...state,
        metricChartGranularity: {
          ...state.metricChartGranularity,
          value: value,
        },
      };
      break;
    case C.UPDATE_ANALYZE_FILTERS_READONLY_FROM_GLOBALFILTERS:
      newState = {
        ...state,
        analyzeFiltersReadOnly: {
          ...state.analyzeFiltersReadOnly,
          dimensionFilters: state.dimensionFilters,
          timeFilters: state.timeFilters,
          rollingDateType: state.rollingDateType,
        },
      };
      break;
    case C.UPDATE_GLOBALFILTERS_FORM_ANALYZEFILTERSREADONLY:
      newState = {
        ...state,
        dimensionFilters: state.analyzeFiltersReadOnly.dimensionFilters,
        timeFilters: state.analyzeFiltersReadOnly.timeFilters,
        rollingDateType: state.analyzeFiltersReadOnly.rollingDateType,
      };
      break;
    case C.UPDATE_GLOBALFILTERS_TIMEFILTERS_WITH_DEFAULT_VALUE:
      newState = {
        ...state,
        timeFilters: value,
        analyzeFiltersReadOnly: {
          ...state.analyzeFiltersReadOnly,
          timeFilters: state.timeFilters,
        },
      };
      break;
    default:
      newState = { ...state };
      break;
  }
  return newState;
}
