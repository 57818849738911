// Import React Components
import React, { useRef, useContext } from "react";

// Import library components
import { makeStyles } from "@material-ui/core/styles";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";
import moment from "moment";

function SigviewAdminReportTab(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // Destructure props
  const {
    handleActiveView = () => {},
    handleSelectedView = () => {},
    activeView = [],
    selectedValue = "",
    data = [],
    type = "",
    disabled = false,
    isEditable = false,
    onClick,
  } = props;

  const useStyles = makeStyles(() => ({
    root: {
      // width: "240px",
      display: "flex",
      flexDirection: "column",
      // height: "100%",
      overflow: "auto",
    },
    tabRowContainer: {
      display: "flex",
      alignItems: "center",
      color: themeColors["secondaryColor"],
      // backgroundColor: themeColors["sidenavBgColor"],
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#ddeeff",
      },
      width: "inherit",
      height: "35px",

      justifyContent: "space-between",
      padding: "5px 0px 5px 10px",
      // paddingRight: "8.33px",
      boxShadow: `0 2px 3px -1px ${themeColors["secondaryColorLighter"]} !important`,
    },
    materialIconsChecked: {
      opacity: disabled ? 0.5 : 1,
      color: themeColors["primaryColor"],
      fontSize: "15px !important",
      width: "calc(100% - 205px)",
    },
    materialIconsUnchecked: {
      opacity: disabled ? 0.5 : 1,
      color: themeColors["secondaryColor"],
      fontSize: "15px !important",
      width: "calc(100% - 205px)",
    },
    selectedClass: {
      backgroundColor: "#ddeeff",
    },
    SideNavItem: {
      width: "250px",
      height: "inherit",
      display: "block",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: "400",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis !important",
      paddingBottom: "8px",
    },
    materialIcons: {
      fontSize: "small",
      padding: "0px 5px 0px 5px !important",
    },
  }));
  const classes = useStyles();

  return (
    <>
      {data.length === 0 && (
        <div className="no-data-container">
          <div className="no-data-image"></div>
          <p className="no-data">No user available</p>
        </div>
      )}
      {data.length > 0 && (
        <div className={classes.root}>
          {data.map((item, index) => {
            // let active = selectedView.find((row) => row.id == item.id);

            return (
              <div
                className={`${
                  selectedValue === item.email ? classes.selectedClass : null
                } ${classes.tabRowContainer}`}
                onClick={() => onClick(item)}
                key={index}
              >
                {/* <div className={classes.tabRowNameContainer}> */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    className={classes.SideNavItem}
                    onClick={(e) => handleActiveView(e, item)}
                    title={item.email}
                  >
                    {item.email}
                  </span>
                  <span style={{ fontSize: 10 }} title={item.email}>
                    Last Sigview Login -{" "}
                    {moment(Number(item.lastSuccessLoginTime)).format(
                      "MMMM Do YYYY"
                    )}
                  </span>
                </div>

                <span
                  style={{
                    padding: "2.5px 5px",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    style={{
                      // padding: "2.5px 10px",
                      boxSizing: "border-box",
                      background: themeColors["primaryColor"],
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "25px",
                      width: "25px",
                      borderRadius: "100%",
                      overflow: "hidden",
                      color: "white",
                    }}
                  >
                    {item.totalReport}
                  </div>
                </span>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default SigviewAdminReportTab;
