// * Import required libraries
import React, { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import orderBy from "lodash.orderby";

// * Import custom components
import SigviewTooltip from "../Common/SigviewTooltip";

// * Import styles
import "./Sidenav.scss";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Import action creators
import { updateUserScreen } from "../../redux/actions";

// * Import utils & data
import { masterTrackGaEvent } from "../../services/ga";

// * Import config
import { config } from "../../config/config";

function Sidenav(props = {}) {
  const { t } = useTranslation();
  // * Destructure props
  const {
    dispatch: ReduxDispatcher,
    user = {},
    tabName = "",
    additionalSidenavItems = [], // Refer to commonSidenavData in this file for correct data structure
    sidenavItemsOrderInfo = config.hardCoded.defaultSidenavItemsOrderInfo, // If this is passed as props, append your order with config.hardCoded.defaultSidenavItemsOrderInfo order
  } = props;
  const { state: themeState } = useContext(ThemeContext);

  // * Define required event handlers
  const handleGoHome = (user) => {
    masterTrackGaEvent({
      category: "Navigation",
      action: "Sidenav",
      label: "Home",
    });

    //Return to home (will be controlled by router later)
    const activeNav = user.screen.activeNav;
    if (activeNav === "pivot") {
      const value = { activeNav: "pivot", activeTab: "home" };
      const action = updateUserScreen(null, value);
      ReduxDispatcher(action);
    } else if (activeNav === "datastory") {
      const value = { activeNav: "datastory", activeTab: "home" };
      const action = updateUserScreen(null, value);
      ReduxDispatcher(action);
    }
  };

  const toggleReportManager = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ReportManager",
      action: "OpenReportManager",
      label: "Sidenav",
    });
    ReduxDispatcher(
      updateUserScreen("reportManager", {
        isOpen: true,
        metadata: { type: "saved", selections: {} },
      })
    );
  };

  const toggleAlertManager = () => {
    const value = {
      isOpen: true,
    };
    ReduxDispatcher(updateUserScreen("alertManager", value));
  };

  // * Defining required variables
  const isVisible = {
    home: true, //hard coded
    reports: user.uiFeatureList.reportManager && !user.timeFilters.isLoading, //Only when time-filters have been loaded; otherwise the calendar malfunctions (doesn't show all presets)
    alert: user.uiFeatureList.alertManager && !user.timeFilters.isLoading, //Only when time-filters have been loaded; otherwise the calendar malfunctions (doesn't show all presets),
  };
  const commonSidenavData = [
    // {
    //   id: "home",
    //   name: "Home",
    //   tooltip: "Go back to home",
    //   elementId: "dsBackToHome",
    //   iconId: "custom-home-icon",
    //   onClick: (user) => handleGoHome(user),
    //   isVisible: isVisible.home,
    // },
    {
      id: "reports",
      name: "Reports",
      tooltip: "Open Reports",
      elementId: "reportToggle",
      iconId: "custom-report-icon",
      customClass: "sidenav-reports",
      onClick: (user) => toggleReportManager(user),
      isVisible: isVisible.reports,
    },
    {
      id: "alerts",
      name: "Alerts",
      tooltip: "Open Alerts",
      elementId: "alertsToggler",
      iconId: "custom-alert-icon",
      customClass: "sidenav-alerts",
      onClick: (user) => toggleAlertManager(user),
      isVisible: isVisible.alert,
    },
  ];
  var finalSidenavData = [...commonSidenavData, ...additionalSidenavItems];
  // Remove unwanted items
  finalSidenavData = finalSidenavData.filter((row) => row.isVisible);
  // Re-order based on sidenavItemsOrderInfo
  finalSidenavData = orderBy(
    finalSidenavData,
    (item) => sidenavItemsOrderInfo[item.id],
    ["asc"]
  );

  return (
    <>
      <aside className={`sidenav-container ${themeState.activeTheme}`}>
        {finalSidenavData.map((row) => (
          <Fragment key={row.id}>
            <SigviewTooltip title={t(row.tooltip)} placement="right">
              <div
                onClick={() => row.onClick(user)}
                className={`sidenav-item ${row.id} ${
                  tabName + "-" + row.customClass + "-GA"
                }`}
                id={row.elementId}
              >
                <div
                  className={`${row.iconId} ${row.id} ${
                    tabName + "-" + row.customClass + "-GA"
                  }`}
                ></div>
                <span
                  className={`sidenav-item-title ${row.id} ${
                    tabName + "-" + row.customClass + "-GA"
                  }`}
                >
                  {t(row.name)}
                </span>
              </div>
            </SigviewTooltip>
          </Fragment>
        ))}
      </aside>
    </>
  );
}

Sidenav.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(Sidenav);
