import rootReducer from "./reducers/index";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import { config } from "../config/config";
import { stateData } from "./stateData";

const initialState = localStorage[config.localStorageReduxStoreName]
  ? JSON.parse(localStorage[config.localStorageReduxStoreName])
  : stateData;

// const saver = (store) => (next) => (action) => {
//   let result = next(action);
//   localStorage[config.localStorageReduxStoreName] = JSON.stringify(
//     store.getState()
//   );
//   return result;
// };

// for disable log redux-logger on production
let middleware = [];
if (process.env.NODE_ENV === "production") {
  middleware = [...middleware];
} else {
  middleware = [...middleware, logger];
}

const storeFactory = () =>
  applyMiddleware(...middleware)(createStore)(
    rootReducer,
    initialState,
    composeWithDevTools()
  );
// applyMiddleware()(createStore)(rootReducer, initialState);

export default storeFactory;
