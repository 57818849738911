import React, { useState, useEffect, useReducer, useContext } from "react";
import axios from "axios";
import { connect } from "react-redux";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";

// * Import lib components
import { Box } from "@material-ui/core";

// Import Components
import LayoutTopSideBottomAdmin from "../../../layouts/LayoutTopSideBottomAdmin/LayoutTopSideBottomAdmin";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import SigviewTab from "../../../components/SigviewTab";
import SigviewSingleSelect from "../../../components/Common/SigviewSingleSelect";
import SigviewCheckList from "../../../components/SigviewChecklist/SigviewChecklist";
import SigviewTextField from "../../../components/Common/SigviewTextField";
import SigviewNav from "../../../components/Common/SigviewNav";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SigviewButton from "../../../components/Common/SigviewButton";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import { config } from "../../../config/config";
import DimensionFilters from "../../../components/DimensionFilters";
import { UserClone } from "./userClone";

// Import Layouts
import LayoutAdminContent from "../../../layouts/LayoutAdminContent";
import LayoutRow from "../../../layouts/LayoutRow";

// Import Context
import { ThemeContext } from "../../../contexts/ThemeContext";
import { UserProvider } from "../../../contexts/AdminContext";

// Import utils/data
import { updateUserScreen } from "../../../redux/actions";
import {
  transformDimensionsForPlotly,
  transformMetricsForPlotly,
  capitalize,
  getBreadcrumbsDataFromRoute,
} from "../../../utils/utils";

import useReducerLogger from "../../../utils/useReducerLogger";
import { updateAdminForm, replaceAdminForm } from "../../../redux/actions";
import { adminUser } from "../../../redux/reducers/admin";
import {
  unWrapperUser,
  wrapperUser,
  unWrapperGroup,
  unWrapperUserGroup,
  unWrapperviews,
  userData as initialUserSelection,
  viewsDataFromApiSample,
  userSelection,
  validateUser,
  isSelectionsInvalid,
  validateEmail,
} from "../../../utils/adminUtils";
import { masterTrackGaEvent } from "../../../../js/services/ga/index";

// * Import APIs
import {
  readUserById,
  readGroupsByOrg,
  readGroupById,
  updateUser,
  createUser,
  emailCheckUser,
} from "../../../services/api";
import { useHistory } from "react-router";

const views = (view, id) => {
  view.map((row) => {
    if ((row.id = id)) {
      return row.dimensions.value;
    }
  });
};

const sigviewNavData = [
  { id: "accessList", name: "Access List", disabled: false },
  { id: "featureList", name: "Feature List", disabled: true },
  { id: "limitList", name: "Limit List", disabled: true },
];
const defaultActiveView = {
  id: "view1",
  name: "View 1",
  subTitle: "",
  disabled: false,
  metricData: [],
  DimensionData: [],
  customMetricData: [],
};

function User(props) {
  const { t } = useTranslation();
  // * Destructure props
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const { dispatch: ReduxDispatcher, user, allData = {} } = props;
  const { views: viewsData } = allData;

  // * Define contexts
  const history = useHistory();

  if (viewsData.status !== "success") {
    history.push("/admin");
  }

  // ! Fuc on the basics of curd type we will make the selection state
  // * Define required states
  const [selections, dispatchSelections] = useReducer(
    useReducerLogger(adminUser),
    initialUserSelection
  );
  console.log("selections", selections);
  const [groupsData, setGroupsData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });

  const [groupData, setGroupData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });
  const [userData, setUserData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });
  const [emailValidation, setEmailValidation] = useState(false);

  // Define Validity of form
  const [isValid, setIsValid] = useState(false);
  const [isUnique, setIsUnique] = useState(false);
  const [validationMsg, setValidationMsg] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState("");

  const temp = groupsData.result?.map((el) => ({ id: el._id, name: el.name }));
  // console.log("temp", temp);

  // * Define required side effects
  // ! Fetch user Data
  useEffect(() => {
    // console.log("USER", user.screen?.activeAdminUser?.metadata.crudType);
    if (user.screen?.activeAdminUser?.metadata?.crudType === "edit") {
      // Make fetch call using axios
      const source = axios.CancelToken.source();
      // console.log("active", user.screen.activeAdminUser.payload.email);
      const fetchProps = {
        email: user.screen.activeAdminUser.payload.email,
        cancelToken: source.token,
      };
      const readUserId = readUserById(fetchProps);
      readUserId
        .then((data) => {
          // console.log("API call", data);
          setUserData({
            // result: viewsDataFromApiSample,
            result: data?.result?.data,
            status: "success",
            messsage: "",
          });
        })
        .catch((json) => {
          setUserData({
            result: [],
            status: "error",
            messsage: "API failed",
          });
        });
    }
    if (user.screen?.activeAdminUser?.metadata?.crudType === "create") {
      setUserData({
        // result: viewsDataFromApiSample,
        result: selections,
        status: "success",
        messsage: "",
      });
    }
  }, []);

  // ! Fetch  groupsData and set groupsData
  useEffect(() => {
    // ! api call for getting all the groups
    // Make fetch call using axios
    const readAllGroupsViews = readGroupsByOrg();
    readAllGroupsViews
      .then((data) => {
        setGroupsData({
          result: data?.result?.data,
          status: "success",
          messsage: "",
        });
      })
      .catch((json) => {
        setGroupsData({
          result: [],
          status: "error",
          messsage: "API failed",
        });
        // console.log(json);
      });
  }, []);

  // ! useEffect for getting the groupby id
  useEffect(() => {
    if (
      selections.groupName.value !== "" &&
      typeof selections.groupName.value !== "undefined"
    ) {
      // console.log("selections.groupName.value", selections.groupName.value);
      const fetchProps = {
        // id: selections.groupName.value,
        // id: groupsData.result[0],
        id: selections.groupName.value,
      };
      // console.log("fetchProps", fetchProps);
      const readGroupId = readGroupById(fetchProps);
      readGroupId
        .then((data) => {
          // console.log("api success", data);
          setGroupData({
            result: data?.result?.data,
            status: "success",
            messsage: "",
          });
        })
        .catch((json) => {
          // console.log("api fail", json);
          setGroupData({
            result: [],
            status: "error",
            messsage: "API failed",
          });
        });
    }
  }, [selections.groupName.value]);

  // ! Replace selectiom when user data is success
  useEffect(() => {
    if (
      userData.status === "success" &&
      user.screen?.activeAdminUser?.metadata?.crudType === "edit"
    ) {
      const payload = {
        value: unWrapperUser(userData.result, allData),
      };
      console.log("payload", payload);
      setDefaultEmail(payload?.value?.email?.value);
      const action = replaceAdminForm(payload);
      // console.log("action", action);
      dispatchSelections(action);
    }
    if (
      userData.status === "success" &&
      user.screen?.activeAdminUser?.metadata?.crudType === "create"
    ) {
      // console.log("userData dependency", userData);

      if (userData.result.views instanceof Array) {
        const payload = {
          value: unWrapperUser(userData.result, allData),
        };
        const action = replaceAdminForm(payload);
        dispatchSelections(action);
      }
    }
  }, [userData]);

  // ! Replacing the selection when user is create and groupsData is success
  useEffect(() => {
    if (
      groupsData.status === "success" &&
      user.screen?.activeAdminUser?.metadata?.crudType === "create"
    ) {
      const newState = {
        ...selections,
        firstName: {
          message: "",
          value: user.screen.activeAdminUser.metadata.name,
          status: "valid",
        },
        lastName: {
          message: "",
          value: "",
          status: "valid",
        },
        email: {
          message: "",
          value: "",
          status: "valid",
        },
        role: {
          message: "",
          value: "user",
          status: "valid",
        },
        groupName: {
          message: "",
          value: groupsData.result[0]._id,
          status: "valid",
        },
      };

      const payload = { value: newState };
      const action = replaceAdminForm(payload);
      dispatchSelections(action);
    }
  }, [groupsData]);

  // ! Fetch Views Data for any selections
  useEffect(() => {
    const runFlag =
      groupData.status === "success" &&
      userData.status === "success" &&
      viewsData.status === "success";
    if (runFlag) {
      let unWrappedGroupData = unWrapperUserGroup(
        groupData.result,
        viewsData,
        allData
      );

      let uiUserData = unWrapperUser(userData.result, allData);

      for (const [key1, value1] of Object.entries(
        unWrappedGroupData.views.value
      )) {
        for (const [key2, value2] of Object.entries(uiUserData.views.value)) {
          if (key1 === key2) {
            Object.assign(value1, value2);

            unWrappedGroupData.views.value[key1] = value1;
          }
        }
      }

      const payload = {
        key: "modifyUserViews",
        value: unWrappedGroupData,
      };
      const action = updateAdminForm(payload);
      dispatchSelections(action);
    }
  }, [groupData, userData, allData]);

  // ! for validation
  useEffect(() => {
    let validatedSelections = validateUser(selections);

    // console.log("validatedSelections", validatedSelections);

    if (!isEqual(selections, validatedSelections)) {
      // Dispatch ReplaceForm

      let selectionsValidity = isSelectionsInvalid(validatedSelections);

      // console.log("validdddddd", selectionsValidity);

      selectionsValidity.status === "valid"
        ? setIsValid(true)
        : setIsValid(false);

      const payload = { value: validatedSelections };

      const action = replaceAdminForm(payload);
      dispatchSelections(action);
    }
  }, [selections]);

  const handleClone = () => {
    // Google Analytics Event - Master Admin
    masterTrackGaEvent({
      category: "Admin",
      action: "UserClone",
      label: selections.email.value,
    });

    const payload = {
      open: true,
      children: (
        <UserClone
          ReduxDispatcher={ReduxDispatcher}
          name={""}
          email={""}
          user={user}
          selections={selections}
          // dsForm={dsForm}
          // isEdit={isEdit}
        />
      ),
    };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };
  const handleCancel = () => {
    // update screen
    const activeTab = "users";
    const value = { activeNav: "admin", activeTab };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);

    const pathname = "/admin/users";
    history.push(pathname);
  };
  const handleSave = () => {
    // wrapper user will come here
    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: "Requesting to saved user...",
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    console.log("user.reqMetadata", user.reqMetadata);
    console.log("selection on save", selections);

    const payload = wrapperUser({ ...selections }, user.reqMetadata);

    console.log("payload", payload);

    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
      payload,
    };
    const createUserPromise = createUser(fetchProps);
    createUserPromise
      .then(() => {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: false,
          message: "",
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        // update screen
        const activeTab = "users";
        const value = { activeNav: "admin", activeTab };
        var action = updateUserScreen(null, value);
        ReduxDispatcher(action);

        const pathname = "/admin/users";
        history.push(pathname);
      })
      .catch((json) => {
        // console.log("API FAILED", json);
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Updating User Failed",
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };
  const handleUpdate = () => {
    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: "Requesting to update user...",
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    const payload = wrapperUser({ ...selections }, user.reqMetadata);
    // console.log("payload(user)", payload);
    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
      payload,
    };

    const updateUserPromise = updateUser(fetchProps);
    updateUserPromise
      .then(() => {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: false,
          message: "",
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        // update screen
        const activeTab = "users";
        const value = { activeNav: "admin", activeTab };
        var action = updateUserScreen(null, value);
        ReduxDispatcher(action);

        const pathname = "/admin/users";
        history.push(pathname);
      })
      .catch((json) => {
        // console.log("API FAILED", json);
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Updating User Failed",
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };

  const handleActiveTab = (e, id) => {
    //Return to home (will be controlled by router later)

    // Google Analytics Event - Master Admin
    masterTrackGaEvent({
      category: "Admin",
      action: `${capitalize(id)}Open`,
      label: "Breadcrumb",
    });

    const activeTab = id;
    const value = { activeNav: "admin", activeTab };
    const action = updateUserScreen(null, value);
    ReduxDispatcher(action);
  };
  // * Define required static variables for props
  const activeTab = user.screen.activeTab;

  const handleBreadcrumbChange = (event, value) => {
    history.push(value.path);
  };

  const breadcrumbsData = getBreadcrumbsDataFromRoute(user, history);
  const layoutTopSideBottomAdminProps = {
    activeTab,
    handleActiveTab,
  };
  const handleGroupChange = (value) => {
    // console.log(value);

    const fetchProps = {
      id: value,
    };
    const readGroupId = readGroupById(fetchProps);
    readGroupId
      .then((data) => {
        // console.log("API call");
        setGroupData({
          // result: viewsDataFromApiSample,
          result: data?.result?.data,
          status: "success",
          messsage: "",
        });
        // console.log("group response", data);
        // viewData = data.result.data;
        // let abc = unWrapperviews(viewData);
        // console.log("abc", abc);
      })
      .catch((json) => {
        // console.log("API failed");
        setGroupData({
          result: [],
          status: "error",
          messsage: "API failed",
        });
        // console.log(json);
      });

    const payload = {
      key: "groupName",
      value,
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };

  const handleRoleChange = (value) => {
    // console.log(value);

    const payload = {
      key: "role",
      value,
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleFirstNameChange = (value) => {
    // console.log(value);

    const payload = {
      key: "firstName",
      value,
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleLastNameChange = (value) => {
    // console.log(value);

    const payload = {
      key: "lastName",
      value,
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleEmailChange = (value) => {
    // console.log(value);

    const payload = {
      key: "email",
      value,
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };

  const handleClickAway = () => {
    // Make fetch call using axios
    const fetchProps = {
      title: selections.email.value,
      // ! organization is hard code for now can be change further
      organization: "OpenX",
    };
    const emailCheckUserPromise = emailCheckUser(fetchProps);
    emailCheckUserPromise
      .then((data) => {
        data.status.statusMessage === "Success"
          ? setEmailValidation(true)
          : setEmailValidation(false);

        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: `Email: ${data.status?.statusMessage}`,
        };

        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      })
      .catch((json) => {
        setEmailValidation(false);
        //Update snackbar

        // console.log("API FAILED", json);
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };

  const handleViewChange = (e, value) => {
    const payload = {
      key: "activeView",
      value,
      value: { id: value.id },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleDimensionFiltersChange = (value) => {
    // console.log("NEW DIMENSION FILTERS", value);
    // TODO
    const payload = {
      key: "userSecurityFilter",
      value: {
        newdata: value,
        activeViewId: selections.activeView.value.id,
      },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };

  const viewsDataUi = unWrapperviews(viewsData.result);

  const activeViewId = selections.activeView.value.id;
  const activeView =
    viewsDataUi.find((row) => row.id === activeViewId) || defaultActiveView;

  const getAllDataStatus = (allData = []) => {
    const areAllSuccess = allData.every((data) => data.status === "success");
    const isAnyError = allData.some((data) => data.status === "error");
    let allVendorDataStatus = "loading";
    if (areAllSuccess) allVendorDataStatus = "success";
    if (isAnyError) allVendorDataStatus = "error";
    return allVendorDataStatus; //loading, success, error
  };

  const finalStatus = getAllDataStatus([
    userData,
    groupData,
    groupsData,
    viewsData,
  ]);
  // console.groupCollapsed("UI ERROR");
  // console.log(finalStatus);
  // console.log("userData", userData.status);
  // console.log("groupData", groupData.status);
  // console.log("groupsData", groupsData.status);
  // console.groupEnd();

  // console.log("emailValidation", emailValidation, isValid);

  // Making Dimension Filters Data
  const activeViewData = allData.views.result.find(
    (row) => row.id === selections.activeView.value.id
  );
  var filtersDimData = [...allData.plotlyDimensions];
  if (activeViewData)
    filtersDimData = transformDimensionsForPlotly(activeViewData.dimensions);
  var filtersMetricsData = [...allData.plotlyMetrics];
  if (activeViewData)
    filtersMetricsData = transformMetricsForPlotly(
      activeViewData.metrics,
      activeViewData.customMetrics
    );

  // Styles
  const formTitleStyling = {
    height: "max-content",
    padding: "0px 10px 0px 0px",
  };
  const attributeTitleStyling = {
    height: "max-content",
    padding: "7px 0px 7px 15px",
  };
  const box1Style = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  };
  const jpStyle1 = {
    fontSize: "12px",
    color: "#46596a",
    height: "25px",
    fontWeight: "400",
    letterSpacing: "0.0125em",
    lineHeight: "2",
    padding: "7px 0px 7px 15px",
    height: "max-content",
  };
  const jpStyle2 = {
    fontSize: "12px",
    color: "#46596a",
    height: "25px",
    fontWeight: "400",
    letterSpacing: "0.0125em",
    lineHeight: "2",
    padding: "7px 0px 7px 7px",
    height: "max-content",
  };

  const groupInitialDimensionFilters =
    selections.activeView.value.id === "view1"
      ? []
      : selections.views.value[selections.activeView.value.id]
          ?.groupSecurityFilter || [];

  const groupFiltersSettings = {
    isReadOnly: true,
    isAdvancedFilterVisible: false,
    isExcludeAllowed: false,
    rowTitle:
      groupInitialDimensionFilters.length === 0
        ? "No Group Security Filters Applied"
        : "Group Security Filters:",
  };

  const userInitialDimensionFilters =
    selections.activeView.value.id === "view1"
      ? []
      : selections.views.value[selections.activeView.value.id]
          ?.userSecurityFilter || [];

  const userFiltersSettings = {
    isReadOnly: true,
    isAdvancedFilterVisible: false,
    isExcludeAllowed: false,
    addFilterTitle: "Click to Add User Filters",
    rowTitle:
      userInitialDimensionFilters.length === 0
        ? "No User Security Filters Applied"
        : "User Security Filters:",
  };

  return (
    <LayoutTopSideBottomAdmin {...layoutTopSideBottomAdminProps}>
      <SigviewBreadcrumb
        data={breadcrumbsData} //data
        onClick={handleBreadcrumbChange} //onClick
      />
      {finalStatus === "loading" && <Loader />}
      {finalStatus === "error" && (
        <ErrorHandler
          message={config.hardCoded.uiErrorMessage}
          reloadFlag={true}
          onReload={() => {}}
        />
      )}
      {finalStatus === "success" && (
        <>
          <LayoutRow
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "50px",
              padding: "0px 25px",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "20%",
              }}
            >
              <SigviewTypography
                style={{
                  ...formTitleStyling,
                  width: "30%",
                }}
              >
                {t("First Name:")}
              </SigviewTypography>
              <span style={{ color: "red", transform: "translateX(-10px)" }}>
                *
              </span>
              <SigviewTextField
                placeholder="First Name"
                value={selections.firstName.value}
                onChange={handleFirstNameChange}
                maxWidth="70%"
                minWidth="20px"
              />
            </div>
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "20%",
                }}
              >
                <SigviewTypography
                  style={{
                    ...formTitleStyling,
                    width: "30%",
                  }}
                >
                  {t("Last Name:")}
                </SigviewTypography>
                <SigviewTextField
                  placeholder="Last Name"
                  value={selections.lastName.value}
                  onChange={handleLastNameChange}
                  maxWidth="70%"
                  minWidth="20px"
                />
              </div>
            </>
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "30%",
                }}
              >
                <SigviewTypography
                  style={{
                    ...formTitleStyling,
                  }}
                >
                  {t("Email:")}
                </SigviewTypography>
                <span style={{ color: "red", transform: "translateX(-10px)" }}>
                  *
                </span>
                <SigviewTextField
                  placeholder="Email"
                  value={selections.email.value}
                  readOnly={
                    user.screen?.activeAdminUser?.metadata?.crudType ===
                      "edit" || false
                  }
                  onChange={handleEmailChange}
                  onBlur={handleClickAway}
                  tooltipTitle="Type you email to check if exists"
                  maxWidth="270px"
                  minWidth="20px"
                />
              </div>
            </>
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "15%",
                }}
              >
                <SigviewTypography style={formTitleStyling}>
                  {t("Role:")}
                </SigviewTypography>{" "}
                <span style={{ color: "red", transform: "translateX(-10px)" }}>
                  *
                </span>
                <SigviewSingleSelect
                  data={[
                    {
                      id: "admin",
                      name: "Admin",
                    },
                    { id: "user", name: "User", disabled: false },
                    {
                      id: "partnerAdmin",
                      name: "Partner Admin",
                      disabled: false,
                    },
                  ]}
                  value={selections.role.value}
                  onChange={handleRoleChange}
                  disabled={false}
                  maxWidth="100px"
                  minWidth="20px"
                />
              </div>
            </>
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "15%",
                }}
              >
                <SigviewTypography style={formTitleStyling}>
                  {t("Assigned Group:")}
                </SigviewTypography>{" "}
                <span style={{ color: "red", transform: "translateX(-10px)" }}>
                  *
                </span>
                <SigviewSingleSelect
                  data={temp}
                  value={selections.groupName.value}
                  onChange={handleGroupChange}
                  disabled={
                    user.screen?.activeAdminUser?.metadata?.crudType === "edit"
                  }
                  maxWidth="100px"
                  minWidth="20px"
                />
              </div>
            </>
          </LayoutRow>
          <UserProvider value={"calc(100% - 135px)"}>
            <LayoutAdminContent
              fullHeight={"calc(100% - 134px)"}
              padding="0px 25px"
            >
              <Box css={box1Style}>
                <SigviewTypography
                  style={{
                    padding: "10px",
                    height: "45px !important",
                    boxSizing: "border-box !important",
                    color: themeColors["primaryColor"],
                  }}
                >
                  {t("Views")}
                </SigviewTypography>
                <SigviewTab
                  disabled={true}
                  data={viewsDataUi}
                  selectedView={selections.selectedView.value}
                  activeView={selections.activeView.value}
                  handleActiveView={handleViewChange}
                  // handleSelectedView={handleSelectedView}
                />
              </Box>

              <SigviewNav
                data={sigviewNavData}
                value={selections.activeTab.value.id}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    backgroundColor: `${themeColors["secondaryColorLightest"]} `,
                    borderRadius: "3px 3px 0px 0px",
                    // height: "100%",
                    width: "100%",
                  }}
                >
                  <span style={jpStyle1}>{t("Dimensions")}</span>
                  <span style={jpStyle2}>
                    {selections.views.value[selections.activeView.value.id]
                      ?.dimensionValue?.length || 0}
                    /{activeView.DimensionData?.length}
                  </span>
                </div>
                <SigviewCheckList
                  disabled={true}
                  data={activeView.DimensionData}
                  selections={{
                    userSelection:
                      selections.views.value[selections.activeView.value.id]
                        ?.dimensionValue || [],
                    activeItem: selections.activeView.value,

                    filter:
                      selections.views.value[
                        selections.activeView.value.id
                      ]?.groupSecurityFilter
                        ?.map((row) => row.id)
                        .concat(
                          selections.views.value[
                            selections.activeView.value.id
                          ]?.userSecurityFilter?.map((row) => row.id)
                        ) || [],
                  }}
                  // onChange={handleDimensionChange}
                  // handleClearAll={handleClearDimension}
                  // handleSelectAll={handleSelectAllDimension}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    backgroundColor: `${themeColors["secondaryColorLightest"]} `,
                    borderRadius: "3px 3px 0px 0px",
                    // height: "100%",
                    width: "100%",
                  }}
                >
                  <span style={jpStyle1}>{t("Metrics")}</span>
                  <span style={jpStyle2}>
                    {selections.views.value[selections.activeView.value.id]
                      ?.metricValue?.length || 0}
                    /{activeView.metricData?.length}
                  </span>
                </div>
                <SigviewCheckList
                  disabled={true}
                  data={activeView.metricData}
                  selections={{
                    userSelection:
                      selections.views.value[selections.activeView.value.id]
                        ?.metricValue || [],
                    activeItem: selections.activeView.value,
                  }}
                  // onChange={handleMetricChange}
                  // handleClearAll={handleClearMetric}
                  // handleSelectAll={handleSelectAllMetric}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    backgroundColor: `${themeColors["secondaryColorLightest"]} `,
                    borderRadius: "3px 3px 0px 0px",
                    // height: "100%",
                    width: "100%",
                  }}
                >
                  <span style={jpStyle1}>{t("Custom Metrics")}</span>
                  <span style={jpStyle2}>
                    {selections.views.value[selections.activeView.value.id]
                      ?.customMetricValue?.length || 0}
                    /{activeView.customMetricData?.length}
                  </span>
                </div>
                <SigviewCheckList
                  disabled={true}
                  data={activeView.customMetricData}
                  selections={{
                    userSelection:
                      selections.views.value[selections.activeView.value.id]
                        ?.customMetricValue || [],
                    activeItem: selections.activeView.value,
                  }}
                  // onChange={handleCustomMetricChange}
                  // handleClearAll={handleClearCustomMetric}
                  // handleSelectAll={handleSelectAllCustomMetric}
                />
              </div>
              <div>
                <div
                  className="sigview-styled-scroller-thin"
                  style={{
                    paddingTop: "10px",
                    borderBottom: "1px dotted #e0e0e0",
                    height: "50px",
                    overflow: "auto",
                    boxSizing: "border-box !important",
                  }}
                >
                  <DimensionFilters
                    initialDimensionFilters={groupInitialDimensionFilters}
                    initialTimeFilters={user.timeFilters}
                    settings={groupFiltersSettings}
                    filtersData={{
                      filtersDimData: filtersDimData,
                      filtersMetricsData: filtersMetricsData,
                    }}
                    selections={{
                      activeView: selections.activeView.value.id,
                      activeOrg: user?.reqMetadata?.organization,
                    }}
                  />
                </div>
                <div
                  className="sigview-styled-scroller-thin"
                  style={{
                    paddingTop: "10px",
                    height: "50px",
                    overflow: "auto",
                    boxSizing: "border-box !important",
                  }}
                >
                  <DimensionFilters
                    initialDimensionFilters={userInitialDimensionFilters}
                    initialTimeFilters={user.timeFilters}
                    onDimensionFiltersChange={handleDimensionFiltersChange}
                    settings={userFiltersSettings}
                    filtersData={{
                      filtersDimData: filtersDimData,
                      filtersMetricsData: filtersMetricsData,
                    }}
                    selections={{
                      activeView: selections.activeView.value.id,
                      activeOrg: user?.reqMetadata?.organization,
                    }}
                  />
                </div>
              </div>
            </LayoutAdminContent>
          </UserProvider>

          <LayoutRow
            style={{
              display: "flex",
              height: "50px",

              padding: "0px 25px",
              justifyContent: "flex-end",
              alignItems: "center",
              " & > div:first-child": { paddng: "100px" },
            }}
          >
            <SigviewButton
              title="Cancel"
              variant="outlined"
              onClick={handleCancel}
              style={{ margin: { left: "10px" } }}
            />
            {/* {user.screen?.activeAdminUser?.metadata?.crudType === "edit" && (
              <SigviewButton
                title="Clone"
                variant="contained"
                style={{ margin: { left: "10px" } }}
                disabled={!isValid}
                onClick={handleClone}
              />
            )} */}
            {user.screen?.activeAdminUser?.metadata?.crudType === "create" && (
              <SigviewButton
                title="Save"
                variant="contained"
                onClick={handleSave}
                disabled={!isValid || (!emailValidation && !isUnique)}
                style={{ margin: { left: "10px" } }}
                customClassName="saveNewUser-GA"
              />
            )}
            {user.screen?.activeAdminUser?.metadata?.crudType === "edit" && (
              <SigviewButton
                title="Update"
                variant="contained"
                onClick={handleUpdate}
                disabled={!isValid}
                style={{ margin: { left: "10px" } }}
                customClassName="updateUser-GA"
              />
            )}
          </LayoutRow>
        </>
      )}
    </LayoutTopSideBottomAdmin>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(User);
