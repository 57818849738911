import { config } from "../config/config";
import moment from "moment";

function getSelectedDatesDurationInDays(first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  let selectedDatesDurationInDays;
  // If selected dates is exactly 86400000 milli seconds, it means 2 dates are selected
  // Eg: selectedDates are 1675814400000 & 1675728000000
  if (second - first === 1000 * 60 * 60 * 24) {
    selectedDatesDurationInDays = 2;
  } else {
    selectedDatesDurationInDays = Math.ceil(
      (second - first) / (1000 * 60 * 60 * 24)
    );
  }
  return selectedDatesDurationInDays;
}

const getValidMetricGranDataBasedOnDimListAndAccessList = (props = {}) => {
  const { plotlyDimensions = [], uiFeatureList = {} } = props;
  const {
    hourGranularity = true,
    dayGranularity = true,
    monthGranularity = true,
  } = uiFeatureList;

  // * Define required variables
  const validDatetimeDimIds = config.hardCoded.validDatetimeDimIds;
  const defaultMetricGranData = config.hardCoded.defaultMetricGranData;
  const granularityIdMappingInverse = config.hardCoded.granularityIdMapping;

  // 1. Pluck dateTime dimensions from plotlyDimensions
  // This is the first point of source of truth for available granularity
  var datetimeDimensions = plotlyDimensions.filter(
    (row) => row.dataType === "dateTime"
  );
  // 2. Remove those rows which are not in our validDatetimeDimIds and
  // Removing those dimension whose dimension Ids are not mathing with granularityIdMappingInverse
  datetimeDimensions = datetimeDimensions.filter((row) => {
    return (
      validDatetimeDimIds.includes(row.dimID) &&
      granularityIdMappingInverse[row._id]
    );
  });

  // ! This can be removed if we finalize on keeping dimension list as the single source of truth
  // 3. Remove rows if feature access list doesn't allow
  if (!hourGranularity) {
    datetimeDimensions = datetimeDimensions.filter((row) => {
      var id = row._id;
      var dimId = granularityIdMappingInverse[id];
      var flag = dimId !== "hour";
      return flag;
    });
  }
  if (!dayGranularity) {
    datetimeDimensions = datetimeDimensions.filter((row) => {
      var id = row._id;
      var dimId = granularityIdMappingInverse[id];
      var flag = dimId !== "day";
      return flag;
    });
  }
  if (!monthGranularity) {
    datetimeDimensions = datetimeDimensions.filter((row) => {
      var id = row._id;
      var dimId = granularityIdMappingInverse[id];
      var flag = dimId !== "month";
      return flag;
    });
  }

  let metricGranData = [];
  if (datetimeDimensions.length === 0) {
    metricGranData = [...defaultMetricGranData];
  } else {
    metricGranData = datetimeDimensions.map((row) => ({
      id: row.dimID,
      name: row.dimTitle,
      disabled: false,
    }));
  }
  return metricGranData;
};

const manipulateAvailableMetricGranDataBasedOnSelectedDatesAndLimitList = (
  props = {}
) => {
  const {
    metricGranData = [],
    uiLimitsList = {},
    selectedDates = {},
    selectedTimezone = {},
  } = props;
  const {
    minHourGranDurationInDays = 1,
    maxHourGranDurationInDays = 92,
    minDayGranDurationInDays = 2,
    maxDayGranDurationInDays = 9999,
    minMonthGranDurationInDays = 32, //! Month granularity is coming when user select more than 31 days
    maxMonthGranDurationInDays = 99999,
  } = uiLimitsList;

  // * Define required variables
  const defaultMetricGranData = config.hardCoded.defaultMetricGranData;
  const selectedDatesDurationInDays = getSelectedDatesDurationInDays(
    selectedDates.startDate,
    selectedDates.endDate
  );
  var startDayMonth = moment
    .tz(selectedDates.startDate, selectedTimezone.location)
    .format("M");

  var endDayMonth = moment
    .tz(selectedDates.endDate, selectedTimezone.location)
    .format("M");

  var metricGranDataFinal = [];
  // console.log("selectedDatesDurationInDays", selectedDatesDurationInDays);

  for (const row of metricGranData) {
    var validFlag = true;
    switch (row.id) {
      case "hour":
        validFlag =
          selectedDatesDurationInDays >= minHourGranDurationInDays &&
          selectedDatesDurationInDays <= maxHourGranDurationInDays;
        break;
      case "day":
        validFlag =
          selectedDatesDurationInDays >= minDayGranDurationInDays &&
          selectedDatesDurationInDays <= maxDayGranDurationInDays;
        break;
      case "month":
        validFlag =
          startDayMonth !== endDayMonth ||
          (selectedDatesDurationInDays >= minMonthGranDurationInDays &&
            selectedDatesDurationInDays <= maxMonthGranDurationInDays);
        break;
      default:
        console.error("UI ERROR");
        console.groupCollapsed("DETAILS");
        console.log(
          "UNCATERED CASE in manipulateAvailableMetricGranDataBasedOnSelectedDatesAndLimitList.js"
        );
        console.log("props", props);
        console.groupEnd("metricGranData row", row);
        validFlag = false;
    }

    // * We have 2 options here:
    // OPTION 1 : Straight-forward remove the row
    // OPTION 2 : Disable the row
    // * We are going with option 1
    if (validFlag) {
      metricGranDataFinal.push(row);
    }

    // ! ALL CUSTOM ORG RELATED LOGIC CAN COME HERE
    // ! BEWARE NOT TO DO IT. TRY TO FIGURE OUT A WAY TO MAKE IT CONFIGURABLE
  }

  if (metricGranDataFinal.length === 0) {
    let finalmetricGranData = [];
    for (const row of defaultMetricGranData) {
      finalmetricGranData = metricGranData.filter((el) => el.id === row.id);
    }
    metricGranDataFinal = [...finalmetricGranData];
  }
  return metricGranDataFinal;
};

const getValidMetricChartGranularityBasedOnValidMetricGranData = (
  props = {}
) => {
  const { metricChartGranularity = "hour", metricGranData = [] } = props;

  // * Define required variables
  const validMetricGranIds = metricGranData.map((row) => row.id);
  var metricChartGranularityFinal = metricChartGranularity;
  if (!validMetricGranIds.includes(metricChartGranularity)) {
    metricChartGranularityFinal = validMetricGranIds[0];
  }

  return metricChartGranularityFinal;
};

export {
  getValidMetricGranDataBasedOnDimListAndAccessList,
  manipulateAvailableMetricGranDataBasedOnSelectedDatesAndLimitList,
  getValidMetricChartGranularityBasedOnValidMetricGranData,
  getSelectedDatesDurationInDays,
};
