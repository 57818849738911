//Import required libraies
import React, { useState, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

// Import Custom Component

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import action creators

// Import config
import { config } from "../../config/config";

// Import utils

// Import styles
import "./SigviewChartTypes.scss";

const chartTypesBaseUrl = config.hardCoded.chartTypesBaseUrl;

function SigviewChartTypes(props) {
  const { state: themeState } = useContext(ThemeContext);
  const { data = [], value = "", onChange = () => {}, format = "grid" } = props;

  //Defining requried variables
  const wrapperClassName = `wrapper-container ${format}`;
  const sectionClassName = `chart-filter-container ${format}`;

  return (
    <div className={wrapperClassName}>
      <section className={sectionClassName}>
        {data.map((row) => (
          <SigviewChartItem
            key={row.id}
            {...row}
            value={value}
            onChange={onChange}
          />
        ))}
      </section>
    </div>
  );
}

function SigviewChartItem(props) {
  const { t } = useTranslation();
  const {
    id = "",
    name = "",
    disabled = false,
    value,
    onChange = () => {},
    validity = [],
  } = props;

  //Defining required state
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const [anchorEl, setAnchorEl] = useState(null);
  const originalPopoverId = useRef(v4());
  const open = Boolean(anchorEl);
  const popoverId = open ? originalPopoverId.current : undefined;

  //Define styles
  const useStyles = makeStyles(() => ({
    root: {
      pointerEvents: "none",
      zIndex: "999999999 !important",
      "& .MuiPaper-root": {
        backgroundColor: themeColors["popoverBgColor"],
        maxHeight: "200px !important",
        height: "max-content !imporant",
        overflowY: "hidden",
      },
    },
  }));

  //Defining required change handlers
  const handleChange = () => {
    if (!disabled) onChange(id);
  };
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Images hosted on GCP
  // Defining required variables
  const classes = useStyles();
  let className = "chart-icons-container";
  if (value === id && !disabled) className += " " + "icon-active";
  let imgUrl = chartTypesBaseUrl;
  if (disabled) {
    imgUrl += `/${id}.svg`;
    className += " filter-icon-disabled";
  } else {
    imgUrl += `/${id}Hovered.svg`;
  }
  let style = {
    backgroundImage: `url(${imgUrl})`,
  };

  //Debugging
  // console.groupCollapsed("SigviewChartTypes.js");
  // console.log(anchorEl);
  // console.log(popoverId);
  // console.log("props", props);
  // console.groupEnd();

  return (
    <>
      <div
        className={className}
        id={id}
        style={style}
        name={name}
        onClick={handleChange}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      ></div>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className={classes.root}
      >
        <section className="chart-type-popover-container">
          <p className="chart-type-popover-title">{t(name)}</p>
          {validity.map((row, index) => {
            const labels = row.labels;
            const showOR = validity.length > 0 && index !== validity.length - 1;
            return (
              <React.Fragment key={index}>
                <section className="chart-type-popover-criteria-container">
                  <article className="chart-type-popover-criteria-row">
                    <span
                      className={`criteria-circle ${labels.dim.classNames}`}
                    ></span>
                    <label className="criteria-label">
                      {t(labels.dim.label)}
                    </label>
                  </article>

                  <article className="chart-type-popover-criteria-row">
                    <span
                      className={`criteria-circle ${labels.metric.classNames}`}
                    ></span>
                    <label className="criteria-label">
                      {t(labels.metric.label)}
                    </label>
                  </article>
                </section>
                {showOR && <p className="criteria-row-or-label">{t("OR")}</p>}
              </React.Fragment>
            );
          })}
        </section>
      </Popover>
    </>
  );
}

export default SigviewChartTypes;
