// Import required libraries
import moment from "moment";
import { v4 } from "uuid";
import groupBy from "lodash.groupby";
import orderBy from "lodash/orderBy";

// Import required utils
import { config } from "../config/config";
import {
  getDateAllVariations,
  getDateAllVariationsFromEpoch,
} from "./timeFiltersUtils";
import globalFiltersTypeUiCompTypeMapping from "../../assets/data/globalFiltersTypeUiCompTypeMapping.json";
import metricFiltersDropdownTypes from "../../assets/data/metricFiltersDropdownTypes.json";
import viewApiMapping from "../../assets/data/viewApiMapping.json";
import { getMeasureObjByOriginalID } from "./plotlyUtils";
import { getSigviewUserType } from "./utils";

export const validateAdFilterString = (string) => {
  if (string.length < 2 || string.length > 50)
    return { flag: true, message: "Length between 2 to 50 characters" };
  return { flag: false, message: "" };
};

export const validateAdFilterExtraData = (extraData) => {
  if (!extraData.fileName || !extraData.fileLocation)
    return { flag: true, message: "Please upload a file" };
  return { flag: false, message: "" };
};

export const validateAdFilterType = (type) => {
  if (type === "chooseCondition")
    return { flag: true, message: "Please select an operator" };
  return { flag: false, message: "" };
};

export const validateMetricId = (value) => {
  if (value === "selectMetric")
    return { flag: true, message: "Please select a metric" };
  return { flag: false, message: "" };
};

export const validateNumericValue = (value) => {
  if (isNaN(value) || value === "")
    return { flag: true, message: "Please enter a number" };
  return { flag: false, message: "" };
};

export const validateNumberLength = (value, length) => {
  if (value.toString().length > length)
    return { flag: true, message: "Please enter less than 20 digits" };
  return { flag: false, message: "" };
};

export const validateMetricFilterValue = (value) => {
  var { flag, message } = validateNumericValue(value);
  if (flag) return { flag, message };
  var { flag, message } = validateNumberLength(value, 20);
  if (flag) return { flag, message };
  return { flag: false, message: "" };
};

const getMetricFilterTypeMetadata = (type) =>
  metricFiltersDropdownTypes.find((row) => row.id === type);

const modifyStartAndEndDatesForOverlappingCheck = (
  copyAdNewFiltersStore,
  minMaxDates
) => {
  //Making required variables
  const selectedTimezone = {
    name: "UTC (+00:00)",
    location: "UTC",
    value: {
      hours: 9,
      minutes: 0,
    },
    minutesOffset: 0,
  };
  const format = "ddd MMMM DD, YYYY HH:mm:ss ZZ";

  copyAdNewFiltersStore = copyAdNewFiltersStore.map((row) => {
    //Hard coded
    let newValue;
    switch (row.type) {
      case "before":
        const newStartDateProps = {
          selectedDate: minMaxDates.minDate,
          selectedTimezone,
          format,
        };
        const newStartDate = getDateAllVariations(newStartDateProps);
        newValue = {
          startDate: newStartDate,
          endDate: row.value.startDate,
        };
        break;
      case "from":
        const newEndDateProps = {
          selectedDate: minMaxDates.maxDate,
          selectedTimezone,
          format,
        };
        const newEndDate = getDateAllVariations(newEndDateProps);
        newValue = {
          startDate: row.value.startDate,
          endDate: newEndDate,
        };
        break;
      default:
        newValue = row.value;
        break;
    }
    return {
      ...row,
      value: newValue,
    };
  });
  return copyAdNewFiltersStore;
};

export function addErrorInfoToTimestampAdFiltersStore(
  adFiltersStore,
  minMaxDates
) {
  let overlappingIds = [];
  if (!adFiltersStore.length) {
    return adFiltersStore;
  }

  //Make a copy to not hamper original
  let copyAdNewFiltersStore = [...adFiltersStore];

  //Modify copyAdNewFilterStores to change start and end date based on type
  copyAdNewFiltersStore = modifyStartAndEndDatesForOverlappingCheck(
    copyAdNewFiltersStore,
    minMaxDates
  );

  // Sorting timeranges array based on first value in ascending order
  copyAdNewFiltersStore.sort(function (obj1, obj2) {
    return obj1.value.startDate.epoch - obj2.value.startDate.epoch;
  });
  var startDate = copyAdNewFiltersStore[0].value.startDate.epoch;
  var endDate = copyAdNewFiltersStore[0].value.endDate.epoch;

  for (let index = 1; index < copyAdNewFiltersStore.length; index++) {
    if (endDate < copyAdNewFiltersStore[index].value.startDate.epoch) {
      startDate = copyAdNewFiltersStore[index].value.startDate.epoch;
      endDate = copyAdNewFiltersStore[index].value.endDate.epoch;
    } else {
      overlappingIds.push(copyAdNewFiltersStore[index - 1].id);
      overlappingIds.push(copyAdNewFiltersStore[index].id);
    }
  }

  //Updating adFiltersStore with valueError
  let newAdFiltersStore = adFiltersStore.map((row) => ({
    ...row,
    valueError: overlappingIds.includes(row.id)
      ? { flag: true, message: "Overlapping dates" }
      : { flag: false, message: "" },
  }));
  return newAdFiltersStore;
}

const addErrorInfoToStringAdFiltersStore = (adFiltersStore) => {
  let overlappingIds = [];

  if (!adFiltersStore.length) {
    return adFiltersStore;
  }

  //Code to add error if same type has same text
  const groupedAdFiltersData = groupBy(adFiltersStore, (row) => row.type);
  //This will make a groupedObj
  //{startsWith: [...values], endsWith: [...values]}
  //For each group, check if it has duplicate values
  Object.keys(groupedAdFiltersData).forEach((key) => {
    const currGroupedDataArr = groupedAdFiltersData[key];
    const valuesArr = currGroupedDataArr.map((row) => row.value);
    //This will make another grouped object based on values
    //{word1: ["word1"], repeatedWord: ["repeatedWord, "repeatedWord]}
    const valuesGroupedData = groupBy(valuesArr, (value) => value);
    Object.keys(valuesGroupedData).forEach((key2) => {
      let currValuesGroupedDataArr = valuesGroupedData[key2];
      //remove empty string
      currValuesGroupedDataArr = currValuesGroupedDataArr.filter(
        (value) => value.length
      );
      //if even then the length is greater than 1, it means it's a duplicate value
      if (currValuesGroupedDataArr.length > 1) {
        //find all ids with the repeated word within the same time
        let repeatedWord = currValuesGroupedDataArr[0];
        let repeatedAdStore = currGroupedDataArr.filter(
          (adFilterRow) => adFilterRow.value === repeatedWord
        );
        let repeatedWordIds = repeatedAdStore.map(
          (adFilterRow) => adFilterRow.id
        );
        overlappingIds = [...overlappingIds, ...repeatedWordIds];
      }
    });
  });

  //Updating adFiltersStore with valueError
  let newAdFiltersStore = adFiltersStore.map((row) => ({
    ...row,
    valueError: overlappingIds.includes(row.id)
      ? { flag: true, message: "Please remove duplicate entries" }
      : row.valueError,
  }));

  return newAdFiltersStore;
};

export const addErrorInfoToAdvancedFilters = (
  adFiltersStore,
  activeDimDatatype,
  minMaxDates
) => {
  //Check if type chosen is not chooseCondition
  var newAdFiltersStore = adFiltersStore.map((adFilterRow) => ({
    ...adFilterRow,
    typeError: validateAdFilterType(adFilterRow.type),
  }));
  switch (activeDimDatatype) {
    case "string":
      //Validate ad filter string entered by the user
      newAdFiltersStore = newAdFiltersStore.map((adFilterRow) => ({
        ...adFilterRow,
        valueError:
          adFilterRow.type === config.hardCoded.exactlyMatchesObject.id //Hard coded
            ? validateAdFilterExtraData(adFilterRow.extraData)
            : validateAdFilterString(adFilterRow.value),
      }));
      //Validate duplicate entries in ad filter string entered by the user
      newAdFiltersStore = addErrorInfoToStringAdFiltersStore(newAdFiltersStore);
      break;
    case "timestamp":
      newAdFiltersStore = addErrorInfoToTimestampAdFiltersStore(
        newAdFiltersStore,
        minMaxDates
      );
      break;
    default:
      break;
  }

  return newAdFiltersStore;
};

export const validateAdvancedFilters = (adFiltersStore) => {
  //Making valid flag
  //Grabbing all typeFlags
  //Eg: [true, true, false, true]
  //true means it's an error
  const allTypeFlags = [...adFiltersStore.map((row) => row.typeError.flag)];
  //Grabbing all valueFlags
  const allValueFlags = [...adFiltersStore.map((row) => row.valueError.flag)];
  //Concatenating both the arrays
  const allFlags = [...allTypeFlags, ...allValueFlags];
  //If each item is not false, validFlag is false
  const validFlag = allFlags.every((flag) => !flag);

  return validFlag;
};

export const getNewAdFilterRow = (activeDimDatatype = "string") => {
  switch (activeDimDatatype) {
    case "string":
      return {
        id: v4(),
        uiCompType: "stringMatch",
        type: "chooseCondition",
        value: "",
        typeError: { flag: false, message: "" },
        valueError: { flag: false, message: "" },
        extraData: {},
      };

    case "timestamp":
      const newDate = moment().startOf("date")._d; //should be 0th hour of current date in epoch
      const selectedTimezone = {
        name: "UTC (+00:00)",
        location: "UTC",
        value: {
          hours: 9,
          minutes: 0,
        },
        minutesOffset: 0,
      };
      const format = "ddd MMMM DD, YYYY HH:mm:ss ZZ";
      const startDateProps = {
        selectedDate: newDate,
        selectedTimezone,
        format,
      };
      const startDate = getDateAllVariations(startDateProps);
      const endDateProps = {
        selectedDate: new Date(newDate.valueOf() + 24 * 60 * 60 * 1000), //adding one day to start date to match angular structure
        selectedTimezone,
        format,
      };
      const endDate = getDateAllVariations(endDateProps);
      const newValue = {
        startDate,
        endDate,
      };
      return {
        id: v4(),
        uiCompType: "dateSingleSelect",
        type: "chooseCondition",
        value: newValue,
        typeError: { flag: false, message: "" },
        valueError: { flag: false, message: "" },
        extraData: {},
      };
  }
};

export const transformFiltersUiToBackend = (filters) => {
  let backendFilters = [];
  filters.forEach((filterRow) => {
    //Getting required variables
    const filterDatatype = filterRow?.metadata?.dataType?.toLowerCase();

    //Adding basic filters info
    let newFilterRow = {
      id: filterRow.id,
      filterType: filterRow.filterType,
      values: filterRow.values,
    };

    //Adding advanced filters info
    //Grouping by first
    let adFilterObj = {};
    const groupedAdFiltersData = groupBy(
      filterRow.advancedFilters,
      (row) => row.type
    );
    //Hard coded
    Object.keys(groupedAdFiltersData).forEach((key) => {
      const currGroupedDataArr = groupedAdFiltersData[key];
      switch (filterDatatype) {
        case "string":
          switch (key) {
            case "exactlyMatches":
              adFilterObj["exactlyMatches"] = {
                filterFile:
                  currGroupedDataArr[0]?.extraData === undefined ||
                  currGroupedDataArr[0]?.extraData?.length === 0
                    ? []
                    : [currGroupedDataArr[0]?.extraData],
              };
              break;
            default:
              adFilterObj[key] = {
                filterFile: [],
                filterString: currGroupedDataArr.map((row) => row.value),
              };
              break;
          }
          break;
        case "timestamp":
          //If timeFilterRange key exists, append it, else make it
          if (adFilterObj["timeFilterRange"]) {
            adFilterObj["timeFilterRange"] = {
              ...adFilterObj["timeFilterRange"],
              [key]: {
                filterString: currGroupedDataArr.map((row) => ({
                  firstValue: row?.value?.startDate?.epoch?.toString(),
                  secondValue: row?.value?.endDate?.epoch?.toString(),
                })),
              },
            };
          } else {
            adFilterObj["timeFilterRange"] = {
              [key]: {
                filterString: currGroupedDataArr.map((row) => ({
                  firstValue: row?.value?.startDate?.epoch?.toString(),
                  secondValue: row?.value?.endDate?.epoch?.toString(),
                })),
              },
            };
          }

          break;
      }
    });

    //Concatenating both basic and advanced filter info into one object
    newFilterRow = { ...newFilterRow, ...adFilterObj };
    backendFilters.push(newFilterRow);
  });
  return backendFilters;
};

export const transformFiltersBackendToUi = (filters, plotlyDimensions) => {
  let uiFilters = [];
  const selectedTimezone = {
    name: "UTC (+00:00)",
    location: "UTC",
    value: {
      hours: 9,
      minutes: 0,
    },
    minutesOffset: 0,
  };
  const format = "ddd MMMM DD, YYYY HH:mm:ss ZZ";
  filters.forEach((filterRow) => {
    //Getting required variables
    const id = filterRow.id;
    const values = filterRow.values;
    const filterType =
      filterRow.filterType === "exclude" ? "exclude" : "include"; //Hard Coded (backlog in backend)
    const metadata = plotlyDimensions.find(
      (dimRow) => dimRow._id === filterRow.id
    );
    const filterDatatype = metadata?.dataType?.toLowerCase();
    const valid = true;
    let advancedFilters = [];
    let newAdFilterObj = {};

    //Hard coded
    const basicStringTypes = [
      "startsWith",
      "endsWith",
      "containsString",
      "containsWholeWord",
    ];
    const basicTimestampTypes = ["from", "before", "on", "between"];

    //Making advanced filters
    switch (filterDatatype) {
      case "string":
        //Doing for all string types except exactly matches
        basicStringTypes.forEach((stringType) => {
          //If the key exists, add it to adFilters
          if (filterRow[stringType]) {
            //If the filterString is not an empty array
            if (filterRow[stringType].filterString.length) {
              filterRow[stringType].filterString.forEach((row) => {
                newAdFilterObj = getNewAdFilterRow("string");
                newAdFilterObj = {
                  ...newAdFilterObj,
                  value: row,
                  type: stringType,
                };
                advancedFilters.push(newAdFilterObj);
              });
            }
          }
        });

        //Doing for exactlyMatches
        if (filterRow["exactlyMatches"]) {
          newAdFilterObj = getNewAdFilterRow("string");
          newAdFilterObj = {
            ...newAdFilterObj,
            value: "",
            type: "exactlyMatches",
            extraData:
              filterRow["exactlyMatches"]?.filterFile.length > 0
                ? filterRow["exactlyMatches"]?.filterFile[0]
                : [],
            uiCompType: "fileUpload",
          };
          advancedFilters.push(newAdFilterObj);
        }

        break;
      case "timestamp":
        const timeFilterRangeObj = filterRow["timeFilterRange"];
        //Doing for all string types except exactly matches
        if (timeFilterRangeObj) {
          basicTimestampTypes.forEach((timestampType) => {
            //If the key exists, add it to adFilters
            if (timeFilterRangeObj[timestampType]) {
              //If the filterString is not an empty array
              if (timeFilterRangeObj[timestampType].filterString.length) {
                timeFilterRangeObj[timestampType].filterString.forEach(
                  (row) => {
                    newAdFilterObj = getNewAdFilterRow("timestamp");
                    newAdFilterObj = {
                      ...newAdFilterObj,
                      value: {
                        startDate: getDateAllVariationsFromEpoch({
                          selectedDateEpoch: parseInt(row.firstValue),
                          selectedTimezone,
                          format,
                        }),
                        endDate: getDateAllVariationsFromEpoch({
                          selectedDateEpoch: parseInt(row.secondValue),
                          selectedTimezone,
                          format,
                        }),
                      }, // Need to make a function which converts epoch to allDateVariations
                      type: timestampType,
                      uiCompType:
                        globalFiltersTypeUiCompTypeMapping[timestampType],
                    };
                    advancedFilters.push(newAdFilterObj);
                  }
                );
              }
            }
          });
        }

        break;
    }

    //Concatenating both basic and advanced filter info into one object
    let newFilterRow = {
      id,
      values,
      metadata,
      filterType,
      advancedFilters,
      valid,
    };
    uiFilters.push(newFilterRow);
  });
  return uiFilters;
};

//If the filterRow's values and advancedFilters length both are zero, it's an invalid filter
export const validateFilterRow = (filterRow) =>
  !(filterRow.values.length === 0 && filterRow.advancedFilters.length === 0);

//Function to filter only those dimensions whose dataType equal to string or timestamp
export const getValidDimensionsList = (allDimensions) =>
  allDimensions?.filter((row) =>
    config.hardCoded.filterDimTypeList.includes(row?.dataType?.toLowerCase())
  );

export const removeIdFromAllRows = (array) => {
  let newArray = [];
  array.forEach((row) => {
    let { id, ...everyThingApartFromId } = row;
    newArray.push(everyThingApartFromId);
  });
  return newArray;
};

export function isFileValid(
  file,
  maxFileUploadSizeInMB = config.hardCoded.defaultLimitList
    .maxFileUploadSizeInMB
) {
  const maxFileSize = maxFileUploadSizeInMB * 1024 * 1024;
  const supportedFileTypes =
    config.hardCoded.adFiltersFileUpload.adFiltersSupportedFileTypes;
  if (file.size === 0)
    return { status: false, message: "Upload Failed as the file is empty" };
  if (file.size > maxFileSize)
    return {
      status: false,
      message: `Exceeded file size. File should be below ${
        maxFileSize / (1024 * 1024)
      } MB`,
    };
  if (!supportedFileTypes.includes(file.type))
    return {
      status: false,
      message: "Invalid format. File type must be '.xlsx' or '.csv'.",
    };
  return { status: true, message: "Success" };
}

export function getGCSFileName(fileName) {
  var extension = fileName.split(".").pop();
  var fileNameWithoutExtension = fileName.split(".").slice(0, -1).join(".");
  fileNameWithoutExtension = fileNameWithoutExtension.replace(
    /[^a-zA-Z0-9]/g,
    ""
  );
  var fileNameTimeStamp = fileNameWithoutExtension + "_" + Date.now();
  return fileNameTimeStamp + "." + extension;
}

export const getMaxLimitForAdFiltersDropDownTypes = (uiFeatureList) => {
  if (
    uiFeatureList.globalFiltersFileUpload &&
    uiFeatureList.globalFiltersStringMatch
  )
    return 10; //hard coded
  if (uiFeatureList.globalFiltersFileUpload) return 1; //hard coded
  if (uiFeatureList.globalFiltersTimestamp) return 10; //hard coded
  return 10; //hard coded
};

export const subsetAdFilterDropdownTypesBasedOnFeatureAvailability = (
  props
) => {
  let { allFilterTypes, availableAdFiltersFeatureIdList } = props;

  //Filter allFilterTypes based on availableFeatureList
  allFilterTypes = allFilterTypes.filter((row) =>
    row.featureList.some((rowFeatureId) =>
      availableAdFiltersFeatureIdList.includes(rowFeatureId)
    )
  );
  return allFilterTypes;
};

export const getNewMetricFilterRow = () => {
  return {
    id: v4(),
    uiCompType: "singleValueInput",
    absoluteChange: "id_only",
    type: "chooseCondition",
    value1: "0",
    value2: "0",
    metricId: "selectMetric",
    metricIdError: {
      flag: false,
      message: "",
    },
    typeError: {
      flag: false,
      message: "",
    },
    value1Error: {
      flag: false,
      message: "",
    },
    value2Error: {
      flag: false,
      message: "",
    },
  };
};

export const addErrorInfoToMetricFilterForDuplicateMetricIds = (
  metricFiltersStore
) => {
  let overlappingIds = [];

  if (!metricFiltersStore.length) {
    return metricFiltersStore;
  }

  //Code to add error if same metric id appears more than once
  const groupedMetricFiltersDataByMetricId = groupBy(
    metricFiltersStore,
    (row) =>
      `${row.metricId}_${
        row.absoluteChange === "" ? "id_only" : row.absoluteChange
      }`
  );
  //This will make a groupedObj
  //{M001: [...values], M002: [...values]}
  //For each group, check if it has duplicate values
  Object.keys(groupedMetricFiltersDataByMetricId).forEach((key) => {
    const currGroupedDataArr = groupedMetricFiltersDataByMetricId[key];
    if (currGroupedDataArr.length > 1) {
      currGroupedDataArr.forEach((metricFilterRow) => {
        overlappingIds.push(metricFilterRow.id);
      });
    }
  });

  //Updating adFiltersStore with valueError
  let newMetricFiltersStore = metricFiltersStore.map((row) => ({
    ...row,
    metricIdError: overlappingIds.includes(row.id)
      ? { flag: true, message: "Please remove duplicate entries" }
      : row.metricIdError,
  }));

  return newMetricFiltersStore;
};

export const addErrorInfoToMetricFilters = (metricFilters) => {
  //Check if type chosen is not chooseCondition
  var newMetricFilters = metricFilters.map((metricFilterRow) => ({
    ...metricFilterRow,
    typeError: validateAdFilterType(metricFilterRow.type),
  }));

  //Check if metric id chosen is not selectMetric
  newMetricFilters = newMetricFilters.map((metricFilterRow) => ({
    ...metricFilterRow,
    metricIdError: validateMetricId(metricFilterRow.metricId),
  }));

  //VALIDATE DUPLICATE METRIC IDS
  newMetricFilters =
    addErrorInfoToMetricFilterForDuplicateMetricIds(newMetricFilters);

  //Check if value1 is a number
  newMetricFilters = newMetricFilters.map((metricFilterRow) => ({
    ...metricFilterRow,
    value1Error: validateMetricFilterValue(metricFilterRow.value1),
  }));

  //Check if value2 is a number
  newMetricFilters = newMetricFilters.map((metricFilterRow) => ({
    ...metricFilterRow,
    value2Error: validateMetricFilterValue(metricFilterRow.value2),
  }));

  return newMetricFilters;
};

export const validateMetricFilters = (metricFilters) => {
  //Making valid flag
  //Grabbing all typeFlags
  //Eg: [true, true, false, true]
  //true means it's an error
  const allTypeFlags = [...metricFilters.map((row) => row.typeError.flag)];
  //Grabbing all valueFlags
  const allMetricIdFlags = [
    ...metricFilters.map((row) => row.metricIdError.flag),
  ];
  //Grabbing all valueFlags
  const allValue1Flags = [...metricFilters.map((row) => row.value1Error.flag)];
  //Grabbing all valueFlags
  const allValue2Flags = [...metricFilters.map((row) => row.value2Error.flag)];
  //Concatenating both the arrays
  const allFlags = [
    ...allTypeFlags,
    ...allMetricIdFlags,
    ...allValue1Flags,
    ...allValue2Flags,
  ];
  //If each item is not false, validFlag is false
  const validFlag = allFlags.every((flag) => !flag);

  return validFlag;
};

export const handleApplyFiltersGlobally = ({
  ReduxDispatcher,
  replaceAllDimensionFilters,
  replaceTimeFilters,
  replaceAllMetricFilters,
}) => {
  return (payload, filterType) => {
    switch (filterType) {
      case "dimensions":
        ReduxDispatcher(replaceAllDimensionFilters(payload));
        break;
      case "metrics":
        ReduxDispatcher(replaceAllMetricFilters(payload));
        break;
      case "time":
        ReduxDispatcher(replaceTimeFilters(payload));
        break;
    }
  };
};

export const addMetadataToMetricFilters = ({
  selectedMetricFilters,
  plotlyMetrics,
}) =>
  selectedMetricFilters.map((metricRow) => ({
    ...metricRow,
    metricIdMetadata: getMeasureObjByOriginalID(
      metricRow.metricId,
      plotlyMetrics
    ),
    typeMetadata: getMetricFilterTypeMetadata(metricRow.type),
  }));

export const transformMetricFiltersBackendToUi = (metricFiltersBackend) => {
  let metricFiltersUi = [];

  // for (let metricRow of metricFiltersBackend) {
  metricFiltersBackend.forEach((metricRow) => {
    let newRow = getNewMetricFilterRow();
    //Add the row only when it's id is not present in metricFiltersUi to avoid adding 2 rows for between case
    // console.log("metricFiltersUi", JSON.stringify(metricFiltersUi));
    const metricFiltersUiIds = metricFiltersUi.map((row) => row.metricId);
    if (!metricFiltersUiIds.includes(metricRow.id)) {
      //Check length of metricFiltersBackend for current row
      //If length is greater than 1, it is a between condition
      const metricFiltersBackendFiltered = metricFiltersBackend.filter(
        (row) => row.id === metricRow.id
      );
      //Not between case
      if (metricFiltersBackendFiltered.length === 1) {
        newRow = {
          ...newRow,
          metricId: metricRow.id,
          type: metricRow.filterValues[0].operation,
          value1: metricRow.filterValues[0].value,
        };
        metricFiltersUi.push(newRow);
      } else {
        //TO DO: orderBy based on row.filterValues.value
        const orderedMetricFiltersBackendFiltered = orderBy(
          metricFiltersBackendFiltered,
          (row) => row.filterValues[0].value,
          ["asc"]
        );
        //Adding metricId
        newRow = { ...newRow, metricId: metricRow.id };
        orderedMetricFiltersBackendFiltered.forEach((currMetricRow, index) => {
          //Adding value
          newRow = {
            ...newRow,
            type: "between", //hard coded
            uiCompType: "multiValueInput", //hard coded
            [`value${index + 1}`]: currMetricRow.filterValues[0].value,
          };
        });
        metricFiltersUi.push(newRow);
      }
    }
  });

  return metricFiltersUi;
};

export const transformMetricFiltersUiToBackend = (metricFiltersUi) => {
  let metricFiltersBackend = [];

  metricFiltersUi.forEach((metricRow) => {
    let newRow = {};
    //Based on the type, append metricFiltersBackend
    //If length is greater than 1, it is a between condition
    switch (metricRow.type) {
      case "between": //hard coded
        newRow = {
          id: metricRow.metricId,
          filterValues: [
            {
              value: Math.min(metricRow.value1, metricRow.value1).toString(),
              operation: ">=",
            },
          ],
        };
        metricFiltersBackend.push(newRow);
        newRow = {
          id: metricRow.metricId,
          filterValues: [
            {
              value: Math.max(metricRow.value2, metricRow.value2).toString(),
              operation: "<=",
            },
          ],
        };
        metricFiltersBackend.push(newRow);
        break;
      default:
        newRow = {
          id: metricRow.metricId,
          filterValues: [
            { value: metricRow.value1.toString(), operation: metricRow.type },
          ],
        };
        metricFiltersBackend.push(newRow);
        break;
    }
  });
  return metricFiltersBackend;
};

export const getFinalDimensionValuesData = (dimensionData, activeFilterObj) => {
  let allValues = [];
  let selectedValues = [];
  let nonSelectedValues = [];
  if (dimensionData.data) {
    selectedValues = activeFilterObj?.values || [];
    nonSelectedValues = dimensionData.data.filter(
      (value) => !selectedValues.includes(value)
    );
    allValues = [...selectedValues, ...nonSelectedValues];
  }
  const finalDimensionData = {
    selectedValues,
    nonSelectedValues,
    allValues,
  };
  return finalDimensionData;
};

export const isApiGetDimensionValues = (props) => {
  // For this function, if true is returned, it means it's getDimensionValue
  const { activeView = "CPR", activeDimension = {} } = props;
  const { dimID = "day" } = activeDimension;

  // ! HARD CODED
  // ! Elastic search was too costly for this dimension, hence using getData instead of getDimensionValues
  const sigviewUserType = getSigviewUserType();

  // If user is nonSigview, all calls will be getData
  if (sigviewUserType === "sigview") return false;

  // If mapping is getData, return false
  if (viewApiMapping[activeView] === "getData") {
    return false;
  } else {
    // Else if mapping is getDimensionValues
    if (activeView === config.hardCoded.kvrId) {
      // Case 1: If view is KeyValueReport
      // EXEMPTION 1: If view is KeyValueReport and dimId is not CustomValue (dimID for dimension "Key Value"), return true
      if (dimID !== config.hardCoded.kvrKeyValueDimId) {
        return true;
      } else {
        return false;
      }
    } else if (activeView === config.hardCoded.prebidId) {
      // Case 2: If view is PrebidAnalytics
      // EXEMPTION 2: If view is PrebidAnalytics and dimId is not AdUnitCode (dimID for dimension "AdUnitCode"), return true
      if (dimID !== config.hardCoded.prebidAdUnitCodeId) {
        return true;
      } else {
        return false;
      }
    } else {
      // Case 3: For all other cases return true since viewMapping is getDimensionValues in our viewApiMapping
      return true;
    }
  }
};
