// Import required libraries
import React, { useContext } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

function Alert(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  //Define styles
  const useStyles = makeStyles(() => ({
    typographyStyle: {
      fontFamily: "Fira Sans! important",
      fontSize: 14,
      backgroundColor: themeColors["secondaryColorLightest"],
      color: themeColors["secondaryColor"],
      width: 400,
    },
    root: { "&.MuiSnackbar-root": { zIndex: 100000000 } },
  }));

  const alertTheme = createMuiTheme({
    // palette: {
    //   success: {
    //     main: "#f0f2f4",
    //     contrastText: "#415a6c",
    //   },
    //   info: {
    //     main: "#f0f2f4",
    //     contrastText: "#415a6c",
    //   },
    //   error: {
    //     main: "#f0f2f4",
    //     contrastText: "#415a6c",
    //   },
    // },
    // "& .MuiAlert-filledSuccess": {
    //   color: "red",
    //   backgroundColor: "blue",
    //   width: "800px",
    // },
  });

  //Define hooks
  const classes = useStyles(themeColors);

  return (
    <ThemeProvider theme={alertTheme}>
      <MuiAlert
        className={classes.typographyStyle}
        elevation={6}
        variant="filled"
        {...props}
      />
    </ThemeProvider>
  );
}

function SigviewSnackbar(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    open = false,
    message = "This is a sample text",
    autoHideDuration = 5000,
    anchorOrigin = { vertical: "top", horizontal: "center" },
    onClose = () => {},
    severity = "success",
    iconFlag = false,
  } = props;

  //Define styles
  const useStyles = makeStyles(() => ({
    root: { "&.MuiSnackbar-root": { zIndex: 100000000 } },
  }));

  const alertTheme = createMuiTheme({});

  //Define hooks
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <ThemeProvider theme={alertTheme}>
      <Snackbar
        open={open}
        anchorOrigin={anchorOrigin}
        autoHideDuration={autoHideDuration}
        className={classes.root}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} icon={iconFlag} severity={severity}>
          {t(message)}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default SigviewSnackbar;
