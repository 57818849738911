// Import required libraries
import React, { useState, useContext } from "react";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import groupBy from "lodash.groupby";
import moment from "moment";
import { v4 } from "uuid";
import orderBy from "lodash.orderby";

// Import custom components
import SigviewButton from "../Common/SigviewButton";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import styles

// Import config
import { config } from "../../config/config";

// Import action creators

// Import reducers and loggers

// Import utils & data
import globalFiltersAdFilterDropdownTypes from "../../../assets/data/globalFiltersAdFilterDropdownTypes.json";
import {
  getSigviewUserType,
  formatValueForMetricFilter,
} from "../../utils/utils";
import { addMetadataToMetricFilters } from "../../utils/filtersUtils";
import { useTranslation } from "react-i18next";

function SelectedDimensionFilterItem(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    filterRow,
    onClick,
    onDelete,
    rowIndex,
    isReadOnly = false,
    disabledItemsList = [],
  } = props;

  //Defining required states
  const [anchorEl, setAnchorEl] = useState(null);

  //Defining Styles
  const useStyles = makeStyles(() => ({
    popover: {
      pointerEvents: "none",
      zIndex: "99999999999 !important",
      "& .MuiPaper-root": {
        backgroundColor: themeColors["popoverBgColor"],
      },
    },
  }));

  //Defining required functions
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  //Defining required variables
  const open = Boolean(anchorEl);
  const popoverId = open ? `selectedFilter_${rowIndex}` : undefined;
  // const filterTypeIconName =
  //   filterRow.filterType === "include" ? "done" : "remove";
  const sigviewUserType = getSigviewUserType();
  const filterTypeIconName =
    filterRow.filterType === "include" ? "check_circle" : "remove_circle";
  const filterTypeTitle =
    config.hardCoded.incExcTitleMapping[filterRow.filterType];
  const filterTitle = filterRow?.metadata?.dimTitle;
  const filterDatatype = filterRow?.metadata?.dataType?.toLowerCase();
  const filterAllAdFilDropdowns =
    globalFiltersAdFilterDropdownTypes[sigviewUserType][filterDatatype];
  const classes = useStyles();

  //Transform advanced filters data for UI
  //HARD CODED
  const groupedAdFiltersData = groupBy(
    filterRow.advancedFilters,
    (row) => row.type
  );
  var adFiltersArrData = Object.keys(groupedAdFiltersData).map((key) => {
    const dropdownObject = filterAllAdFilDropdowns.find(
      (row) => row.id === key
    );
    const title = dropdownObject?.name || "No title";
    var data = [];
    const currGroupedDataArr = groupedAdFiltersData[key];
    currGroupedDataArr.forEach((adFilterRow) => {
      switch (filterDatatype) {
        case "string":
          switch (key) {
            case "exactlyMatches":
              var name = adFilterRow?.extraData?.fileName || "No value";
              data.push(name);
              break;
            default:
              var name = adFilterRow?.value || "No value";
              data.push(name);
              break;
          }
          break;
        case "timestamp":
          switch (key) {
            case "between":
              var format = config.hardCoded.dateFormatFilterMenu;
              var name = `${moment(adFilterRow.value.startDate.epoch).format(
                format
              )} - ${moment(adFilterRow.value.endDate.epoch).format(format)}`;
              data.push(name);
              break;
            default:
              var format = config.hardCoded.dateFormatFilterMenu;
              var name = moment(adFilterRow.value.startDate.epoch).format(
                format
              );
              data.push(name);
              break;
          }
          break;
      }
    });

    return {
      title,
      data,
    };
  });
  //Adding selections row at the start if it exists
  if (filterRow.values.length) {
    let selectionsRow = { title: "Selections", data: filterRow.values };
    adFiltersArrData = [selectionsRow, ...adFiltersArrData];
  }
  //Modifying adFiltersArrData to have just title and subtitle for UI
  adFiltersArrData = adFiltersArrData.map((row) => ({
    id: v4(),
    title: `${row.title}: `,
    subTitle: `${row.data[0]}${
      row.data.slice(1).length ? ` +${row.data.slice(1).length} More` : ""
    }`,
  }));
  const isReadOnlyFinal =
    disabledItemsList.includes(filterRow.id) || isReadOnly;
  const isReadOnlyHelperText = isReadOnlyFinal ? "(Read Only)" : "";

  return (
    <>
      <div
        className={`selected-filter-item-container ${filterRow.filterType} OpenFilterSelectedDimension-GA`}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={() => {
          if (!isReadOnlyFinal) onClick(filterRow, "dimension");
        }}
      >
        <i
          className={`material-icons selected-filter-item-type-icon ${filterRow.filterType}`}
        >
          {filterTypeIconName}
        </i>
        <div className="selected-filter-item-title" title={filterTitle}>
          {filterTitle}
        </div>
        {!isReadOnlyFinal && (
          <div
            className="material-icons-outlined selected-filter-item-delete-icon WorkspaceRemoveFilters-GA"
            onClick={(e) => {
              e.stopPropagation(); //To prevent the onclick function of the parent div
              onDelete(filterRow, "dimension");
            }}
          >
            close
          </div>
        )}
      </div>
      <Popover
        id={popoverId}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="selected-filter-item-popover-container">
          <p className="selected-filter-item-popover-filter-type">
            {t("Filters:")} {t(filterTypeTitle)} {t(isReadOnlyHelperText)}
          </p>
          {adFiltersArrData.map((row) => (
            <div className="selected-filter-item-popover-row" key={row.id}>
              <p className="selected-filter-item-popover-row-title">
                {row.title}
              </p>
              <p className="selected-filter-item-popover-row-value">
                {row.subTitle}
              </p>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
}

function SelectedMetricFilterItem(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    metricRow,
    onClick,
    onDelete,
    rowIndex,
    isReadOnly = false,
    disabledItemsList = [],
  } = props;

  //Defining required states
  const [anchorEl, setAnchorEl] = useState(null);

  //Defining Styles
  const useStyles = makeStyles(() => ({
    popover: {
      pointerEvents: "none",
      zIndex: "99999999999 !important",
      "& .MuiPaper-root": {
        backgroundColor: themeColors["popoverBgColor"],
      },
    },
  }));

  //Defining required functions
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  //Defining required variables
  const open = Boolean(anchorEl);
  const popoverId = open ? `selectedFilter_${rowIndex}` : undefined;
  const filterOperationType = metricRow?.typeMetadata?.title;
  const filterValue =
    metricRow?.type === "between"
      ? `${Math.min(metricRow?.value1, metricRow?.value2)} & ${Math.max(
          metricRow?.value1,
          metricRow?.value2
        )}`
      : metricRow?.value1;
  const filterTitle = metricRow?.metricIdMetadata?.measureTitle;
  const filterSubtitle = `${filterOperationType} ${formatValueForMetricFilter(
    filterValue
  )}`;
  const filterSubtitleActual = `${filterOperationType} ${filterValue}`;
  const classes = useStyles();
  // TODO : Just added these for parity with SelectedDimensionFilterItem
  // TODO : Need to add in the JSX as well (if required)
  const isReadOnlyFinal =
    disabledItemsList.includes(metricRow.id) || isReadOnly;
  const isReadOnlyHelperText = isReadOnlyFinal ? "(Read Only)" : "";

  return (
    <>
      <div
        className="selected-filter-item-container metric-item"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={() => {
          onClick(metricRow, "metric");
        }}
      >
        {/* <i
          className={`material-icons selected-filter-item-type-icon ${filterRow.filterType}`}
        >
          {filterTypeIconName}
        </i> */}
        <div className="selected-filter-item-title">{filterTitle}</div>
        <div className="selected-filter-item-subtitle">{filterSubtitle}</div>
        <div
          className="material-icons-outlined selected-filter-item-delete-icon"
          onClick={(e) => {
            e.stopPropagation(); //To prevent the onclick function of the parent div
            onDelete(metricRow, "metric");
          }}
        >
          close
        </div>
      </div>
      <Popover
        id={popoverId}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="selected-filter-item-popover-container">
          <div className="selected-filter-item-popover-row">
            <div className="selected-filter-item-title">{filterTitle}</div>
            <div className="selected-filter-item-subtitle">
              {filterSubtitleActual}
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
}

function SelectedFilters(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    selectedDimensionFilters = [],
    selectedMetricFilters = [],
    onClick,
    onDelete,
    rowHeader,
    children,
    style,
    divId,
    allData,
    childrenPlacement,
    showClearAllButton = false,
    onClearAll = () => {},
    isReadOnly = false,
    disabledItemsList = [], // ["D044"] --> This needs to be a array of ids
  } = props;

  const orderedSelectedDimensionFilters = orderBy(
    selectedDimensionFilters,
    (item) => item?.metadata?.dimTitle,
    ["asc"]
  );
  const metricFiltersWithMetadata = addMetadataToMetricFilters({
    selectedMetricFilters,
    plotlyMetrics: allData.plotlyMetrics,
  });
  const orderedMetricFiltersWithMetadata = orderBy(
    metricFiltersWithMetadata,
    (item) => item?.metricIdMetadata?.measureTitle,
    ["asc"]
  );
  const showClearAll =
    showClearAllButton &&
    (selectedDimensionFilters.length > 0 || selectedMetricFilters.length > 0);

  const useStylesForSelectedFilters = makeStyles(() => ({
    root: {
      maxHeight: style?.maxHeight || "35px",
      width: "100%",
      overflow: "auto",
      "&::-webkit-scrollbar-track": {
        borderRadius: "0px",
        backgroundColor: themeColors["secondaryColorLightest"],
      },
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
        backgroundColor: `transparent !important`,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: `${themeColors["secondaryColorLighter"]} !important`,
        borderRadius: "5px",
      },
    },
  }));
  const classes = useStylesForSelectedFilters();

  return (
    <div className={classes.root} id={divId || v4()}>
      <div className="selected-filters-container">
        {childrenPlacement === "start" && !isReadOnly && children}
        {rowHeader && (
          <div className={`selected-filter-item-container header-container`}>
            <div
              className="selected-filter-item-title1 header-container"
              title={rowHeader}
            >
              {t(rowHeader)}
            </div>
          </div>
        )}
        {orderedSelectedDimensionFilters.map((filterRow, rowIndex) => (
          <SelectedDimensionFilterItem
            key={filterRow.id}
            rowIndex={rowIndex}
            filterRow={filterRow}
            onClick={onClick}
            onDelete={onDelete}
            isReadOnly={isReadOnly}
            disabledItemsList={disabledItemsList}
          />
        ))}
        {orderedMetricFiltersWithMetadata.map((metricRow, rowIndex) => (
          <SelectedMetricFilterItem
            key={metricRow.id}
            rowIndex={rowIndex}
            metricRow={metricRow}
            onClick={onClick}
            onDelete={onDelete}
            isReadOnly={isReadOnly}
            disabledItemsList={disabledItemsList}
          />
        ))}
        {childrenPlacement === "end" && !isReadOnly && children}
        {showClearAll && !isReadOnly && (
          <SigviewButton
            variant="containedWhiter"
            onClick={onClearAll}
            title="Clear All"
            iconFlag={false}
            iconProps={{ className: "material-icons", iconName: "delete" }}
            customClassName="WorkspaceClearAll-GA"
            style={{
              padding: "4px 8px !important",
              height: "22px !important",
              fontSize: "11px !important",
            }}
            iconStyle={{
              fontSize: "16px !important",
            }}
          />
        )}
      </div>
    </div>
  );
}

export default SelectedFilters;
