// * Import required libraies
import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";

// * Import lib comp
import { Box } from "@material-ui/core";

// * Import Custom Component
import LayoutTopSideBottomAdmin from "../../../layouts/LayoutTopSideBottomAdmin/LayoutTopSideBottomAdmin";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import SigviewAdminTable from "../../../components/SigviewAdminTable/SigviewAdminTable";
import AppMenuBar from "../../../components/AppMenuBar";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import { config } from "../../../config/config";

// * Import utils, config & static data
import { getBreadcrumbsDataFromRoute } from "../../../utils/utils";
import { unWrapperGroups } from "../../../utils/adminUtils";
import { masterTrackGaEvent } from "../../../../js/services/ga/index";

// * Import action creators
import { updateUserScreen } from "../../../redux/actions";

// * Import APIs
import { readGroupsByOrg } from "../../../services/api";
import { useHistory } from "react-router";

function Groups(props) {
  // * Destructure props
  const { dispatch: ReduxDispatcher, user, allData = {} } = props;
  const { views: viewsData } = allData;

  // * Define contexts
  const history = useHistory();

  if (viewsData.status !== "success") {
    history.push("/admin");
  }

  // * Define required states
  const [groupsData, setGroupsData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });
  const [searchField, setSearchField] = useState("");

  // * Define required side effects
  // Fetch groups Data
  useEffect(() => {
    // Make fetch call using axios
    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
    };
    const readAllGroupsViewsPromise = readGroupsByOrg(fetchProps);
    readAllGroupsViewsPromise
      .then((data) => {
        setGroupsData({
          result: data?.result?.data,
          status: "success",
          messsage: "",
        });
      })
      .catch((json) => {
        setGroupsData({
          result: [],
          status: "error",
          messsage: "API failed",
        });
      });

    return () => {
      setGroupsData({
        result: [],
        status: "loading",
        messsage: "",
      });
    };
  }, []);

  // * Define required event handlers

  const handleBreadcrumbChange = (event, value) => {
    history.push(value.path);
  };

  // For active row
  const handleClickRow = (row) => {
    // Google Analytics Event - Master Admin
    masterTrackGaEvent({
      category: "Admin",
      action: "GroupOpen",
      label: row._id,
    });

    //update screen
    const activeTab = "group";
    const newActiveAdminGroup = {
      payload: {
        ...row,
      },
      metadata: { crudType: "edit", name: row.name },
    };

    const value = { activeNav: "admin", activeTab };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);

    var action = updateUserScreen("activeAdminGroup", newActiveAdminGroup);
    ReduxDispatcher(action);

    const pathname = "/admin/group";
    history.push(pathname);
  };

  // For activenab and activetab
  const handleActiveTab = (e, id) => {
    //Return to home (will be controlled by router later)
    const activeTab = id;
    const value = { activeNav: "admin", activeTab };
    const action = updateUserScreen(null, value);
    ReduxDispatcher(action);
  };

  // For new group
  const handleCreateNewGroup = () => {
    // Google Analytics Event - Master Admin
    masterTrackGaEvent({
      category: "Admin",
      action: "GroupOpen",
      label: "CreateNew",
    });

    const newActiveAdminGroup = {
      payload: {},
      metadata: {
        crudType: "create",
        name: `UntitledGroup${groupsData.result.length + 1}`,
      },
    };
    // update screen
    const activeTab = "group";
    const value = { activeNav: "admin", activeTab };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);

    var action = updateUserScreen("activeAdminGroup", newActiveAdminGroup);
    ReduxDispatcher(action);

    const pathname = "/admin/group";
    history.push(pathname);
  };

  // Event handlers for search feild
  const handleSearchFieldChange = (event, value) => setSearchField(value);

  // * Define required static variables for props
  const activeTab = user.screen.activeTab;
  const breadcrumbsData = getBreadcrumbsDataFromRoute(user, history);
  const layoutTopSideBottomAdminProps = {
    activeTab,
    handleActiveTab,
  };
  const buttonProps = {
    handleClick: handleCreateNewGroup,
    title: "Create New Group",
  };
  const appMenuBarProps = {
    buttonProps,
    onSearchFieldChange: handleSearchFieldChange,
  };
  // For filtering the search results
  const modifiedUsersData = groupsData.result.map((row) => {
    return {
      ...row,
      createdOn: moment(Number(row.createdOn)).format("MMMM Do YYYY"),
      lastModifiedOn:
        row.lastModifiedOn === "0"
          ? "Inactive User"
          : moment(Number(row.lastModifiedOn)).format("MMMM Do YYYY"),
    };
  });
  const filteredData = modifiedUsersData.filter((el) =>
    el.name.toLowerCase().includes(searchField.toLowerCase())
  );

  return (
    <>
      <LayoutTopSideBottomAdmin {...layoutTopSideBottomAdminProps}>
        <SigviewBreadcrumb
          data={breadcrumbsData}
          onClick={handleBreadcrumbChange}
        />
        <AppMenuBar {...appMenuBarProps} />
        {groupsData.status === "loading" && <Loader />}
        {groupsData.status === "error" && (
          <ErrorHandler
            message={config.hardCoded.uiErrorMessage}
            reloadFlag={true}
            onReload={() => {}}
          />
        )}
        {groupsData.status === "success" && filteredData.length > 0 && (
          <>
            <Box
              css={{
                padding: "5px 25px 0px 25px",
              }}
            >
              <SigviewAdminTable
                handleClickRow={handleClickRow}
                headCells={unWrapperGroups(modifiedUsersData)}
                rows={filteredData}
              />
            </Box>
          </>
        )}
        {groupsData.status === "success" && filteredData.length === 0 && (
          <div className="no-data-container">
            <div className="no-data-image"></div>
            <p className="no-data">{config.messages.noGroups}</p>
          </div>
        )}
      </LayoutTopSideBottomAdmin>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(Groups);
