// * Import required libraries
import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// * Import library components
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";

import TablePagination from "@material-ui/core/TablePagination";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Define requires styling functions
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles({
    root: {
      // border: `1px solid ${themeColors["secondaryColorLight"]}`,
      "& .MuiTableHead-root": {
        color: "white",
        backgroundColor: themeColors["secondaryColor"],
      },
      "& .MuiButtonBase-root": {
        color: "white !important",
        fontFamily: "Fira Sans !important",
        fontSize: "13px",
      },
      "& .MuiSvgIcon-root": {
        color: "white !important",
      },
      "& .MuiTableBody-root .MuiTableRow-root": {
        backgroundColor: themeColors["secondaryColorLightest"] + "!important",
        [`&:nth-child(${2}n)`]: {
          backgroundColor: "rgba(255, 255, 255, 1)" + "!important",
        },
        borderTop: `1px solid ${themeColors["secondaryColorLight"]}`,
        "&:hover": {
          background: `${themeColors["secondaryColorLighter"]} !important`,
          cursor: "pointer",
        },
      },
      "& .MuiTableRow-head": { height: "30px !important" },
      "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:not(:last-child)":
        {
          borderRight: `1px solid ${themeColors["secondaryColorLight"]}`,
          padding: "15px",
          height: "20px !important",
          boxSizing: "border-box !important",
        },
      "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root": {
        textAlign: "left",
        fontFamily: "Fira Sans !important",
        width: "200px",
      },
      "& .MuiTableCell-root": {
        textAlign: "left",
        color: `${themeColors["secondaryColor"]} `,
        fontFamily: "Fira Sans !important",
        fontSize: "12px",
        width: "200px",
        maxWidth: "100px",
      },
      "& .MuiTablePagination-root": {
        "& .MuiTypography-root MuiTablePagination-caption": {
          fontFamily: "Fira Sans !important",
          fontSize: "10px",
        },
      },
    },
    cell: {
      margin: "0",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: " hidden",
      width: `100%`,
    },
    tablePagination: {
      backgroundColor: themeColors["secondaryColorLightest"],
      "& .MuiTablePagination-root": {
        "& .MuiTablePagination-caption": {
          fontFamily: "Fira Sans !important",
          fontSize: "12px",
          color: `${themeColors["secondaryColor"]} `,
        },
      },
    },
  });
  return useStyles;
};

function SigviewAdminTable(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const { headCells = [], rows = [], handleClickRow = () => {} } = props;

  // * Define required states
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // * Define requried utility functions
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  // * Define required util components
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells } = props;

    const useStyles = makeStyles({
      root: {
        "& .activeSortIconClass .MuiTableSortLabel-icon": {
          color: "white !important",
        },
        "& 	.MuiTableSortLabel-icon": {
          color: `${themeColors["secondaryColorLight"]}!important`,
        },
      },
    });

    const classes = useStyles();

    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    if (rows.length <= 10) {
      setPage(0);
    }

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              className={classes.root}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={true}
                className={orderBy === headCell.id ? "activeSortIconClass" : ""}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {t(headCell.label)}
                {orderBy === headCell.id ? <Box component="span"></Box> : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  // * Define required event handlers
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // * Define required static variables
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const tableData = stableSort(rows, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box>
      <Paper className="sigview-styled-scroller">
        <Box className={classes.root}>
          <TableContainer>
            <Table aria-labelledby="tableTitle" size="medium">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                headCells={headCells}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {tableData.map((row, index1) => (
                  <TableRow
                    hover
                    onClick={() => handleClickRow(row)}
                    tabIndex={-1}
                    key={index1}
                    className="UserClickEmailRow-GA"
                  >
                    {headCells.map((accessor, index) => (
                      <TableCell key={index} component="th" scope="row">
                        <p
                          title={row[accessor.id]}
                          className={`${classes.cell} UserClickEmailRow-GA`}
                          id={`${row[accessor.id]}-GA`}
                        >
                          {row[accessor.id]}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box className={classes.tablePagination}>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            // onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}

            // Depricated
            // If not working use the above two lines instead
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default SigviewAdminTable;
