// Import required libraries
import React, { useState, useReducer, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";

// Import custom components
import SigviewButton from "../Common/SigviewButton";
import MetricFiltersRow from "./MetricFiltersRow";

// Import styles

// Import config
import { config } from "../../config/config";

// Import action creators
import {
  updateUserScreen,
  updateDialogInfo,
  replaceAllMetricFilters,
} from "../../redux/actions";

// Import reducers
import metricFiltersReducer from "../../redux/reducers/metricFilters";

// Import utils & data
import {
  getNewMetricFilterRow,
  removeIdFromAllRows,
  addErrorInfoToMetricFilters,
  validateMetricFilters,
} from "../../utils/filtersUtils";
import useReducerLogger from "../../utils/useReducerLogger";
import metricFiltersDropdownTypes from "../../../assets/data/metricFiltersDropdownTypes.json";
import { masterTrackGaEvent } from "../../services/ga";

// const metricFilters = [
//   {
//     id: "f5b879be-d832-46c5-bef6-405b36ec12f2",
//     uiCompType: "singleValueInput",
//     type: "<",
//     value1: "1",
//     value2: "0",
//     metricId: "M001",
//     metricIdError: {
//       flag: false,
//       message: "",
//     },
//     typeError: {
//       flag: false,
//       message: "",
//     },
//     value1Error: {
//       flag: false,
//       message: "",
//     },
//     value2Error: {
//       flag: false,
//       message: "",
//     },
//   },
//   {
//     id: "89759872-5b55-4dd4-9c7e-d1246eac6dd4",
//     uiCompType: "multiValueInput",
//     type: "between",
//     value1: "1",
//     value2: "0",
//     metricId: "M002",
//     metricIdError: {
//       flag: false,
//       message: "",
//     },
//     typeError: {
//       flag: false,
//       message: "",
//     },
//     value1Error: {
//       flag: false,
//       message: "",
//     },
//     value2Error: {
//       flag: false,
//       message: "",
//     },
//   },
// ];

function MetricFiltersContainer(props) {
  const { t } = useTranslation();
  const {
    dispatch: ReduxDispatcher,
    user,
    allData,
    metricFilters,
    setMetricFilters,
  } = props;

  //Definint required reducers
  const [metricFiltersStore, dispatchMetricFiltersStore] = useReducer(
    useReducerLogger(metricFiltersReducer),
    []
  );
  //Making a copy to compare with the updated one to check if the toasts need to be shown or not
  const metricFiltersOriginal = useRef([]);

  //Defining required state
  const [showError, setShowError] = useState(false);

  //Defining required variables
  const newMetricFilters = addErrorInfoToMetricFilters(metricFiltersStore);
  const oldNewMetricFiltersEqualFlag = isEqual(
    removeIdFromAllRows(newMetricFilters),
    removeIdFromAllRows(metricFiltersOriginal.current)
  );
  //If there's no change in the filters, DONE button will act like cancel or close
  //NOT THE BEST UX in my opinion
  const applyButtonText = oldNewMetricFiltersEqualFlag ? "Done" : "Add Filters";
  const maxLimit = allData.plotlyMetrics.length; //maxLimit is equal to total metrics because each metric can have one row

  //update metricFiltersStore everytime metricFilters changes
  useEffect(() => {
    let newMetricFilters = addErrorInfoToMetricFilters([
      getNewMetricFilterRow(),
    ]);
    if (metricFilters.length > 0) {
      newMetricFilters = addErrorInfoToMetricFilters(metricFilters);
    }
    const payload = { newMetricFilters };
    dispatchMetricFiltersStore(replaceAllMetricFilters(payload));
    metricFiltersOriginal.current = newMetricFilters;
  }, [metricFilters]);

  //Add errorInfo everytime metricFiltersStore changes
  useEffect(() => {
    const newMetricFilters = addErrorInfoToMetricFilters(metricFiltersStore);
    const payload = {
      newMetricFilters,
    };
    const equalFlag = isEqual(metricFiltersStore, newMetricFilters);
    // Update only when the old and new are not equal otherwise it will result in an infinte loop
    if (!equalFlag) {
      dispatchMetricFiltersStore(replaceAllMetricFilters(payload));
    }
  }, [metricFiltersStore]);

  const closeMetricFilters = () => {
    //Close the metric filters
    let newGlobalFiltersProps = {
      ...user.screen.globalFilters,
      activeFilterType: "dimensions", //metrics filters close
    };
    ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
  };

  const handleMetricFiltersClose = () => {
    //Check if there's a change in advanced filter values
    //If yes, show the dialog, else close the filters
    const flag = !isEqual(
      removeIdFromAllRows(metricFiltersStore),
      removeIdFromAllRows(metricFiltersOriginal.current)
    );
    if (flag) {
      const message = {
        title: config.dialogInfo.filtersClose.title,
        subtitle: config.dialogInfo.filtersClose.subtitle,
      };
      const handleNo = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
          })
        );
      };
      const handleYes = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
            message: { title: "", subtitle: "" },
          })
        );
        //Close metricFilters
        closeMetricFilters();
      };
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: true,
          message,
          handleYes,
          handleNo,
        })
      );
    } else {
      //Close metricFilters
      closeMetricFilters();
    }
  };

  const handleApplyMetricFilters = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "MetricFilters",
      label: "AddFilters",
    });

    //Check if the user has clicked Clear All which means he wants to remove all the filters
    //In that case we will have newMetricFilters similar to [getNewMetricFilterRow()]
    //If that's the case, pass empty array as the advanced filers and close the adFilters
    const typicalNewMetricFilters = addErrorInfoToMetricFilters([
      getNewMetricFilterRow(),
    ]);
    const metricFiltersStoreErrorInfoAdded =
      addErrorInfoToMetricFilters(metricFiltersStore);
    const hasUserClickedClearAll = isEqual(
      removeIdFromAllRows(metricFiltersStoreErrorInfoAdded),
      removeIdFromAllRows(typicalNewMetricFilters)
    );
    const oldNewAdFiltersEqualFlag = isEqual(
      removeIdFromAllRows(metricFiltersStoreErrorInfoAdded),
      removeIdFromAllRows(metricFiltersOriginal.current)
    );
    const validFlag = validateMetricFilters(metricFiltersStoreErrorInfoAdded);

    if (hasUserClickedClearAll) {
      setMetricFilters([]);
      //Close metricFilters
      closeMetricFilters();
    } else if (oldNewAdFiltersEqualFlag) {
      //Close metricFilters
      closeMetricFilters();
    } else if (validFlag) {
      setMetricFilters(metricFiltersStore);
      //Close metricFilters
      closeMetricFilters();
    } else {
      setShowError(true);
    }
  };

  const handleClearAll = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "MetricFilters",
      label: "ClearAll",
    });

    const payload = {
      newMetricFilters: [getNewMetricFilterRow()],
    };
    dispatchMetricFiltersStore(replaceAllMetricFilters(payload));
    setShowError(false);
  };

  //Add event listener on keydown
  useEffect(() => {
    let keysPressed = {};

    const keydownFunc = (event) => {
      keysPressed[event.key] = true;
      //Prompt filters close if escape is clicked
      if (keysPressed["Escape"]) {
        handleMetricFiltersClose();
      }
    };

    const keyupFunc = (event) => {
      delete keysPressed[event.key];
    };

    window.addEventListener("keydown", keydownFunc);
    window.addEventListener("keyup", keyupFunc);
    return () => {
      window.removeEventListener("keydown", keydownFunc);
      window.removeEventListener("keyup", keyupFunc);
    };
  });

  return (
    <div className="advanced-filters-container">
      <div className="advanced-filters-title-container">
        <div className="left-panel">
          {/* <i
            className="material-icons-round advanced-filters-go-back-icon"
            onClick={handleMetricFiltersClose}
          >
            arrow_back
          </i> */}
          {/* <i
            className="material-icons-round advanced-filter-go-back-icon"
            onClick={handleMetricFiltersClose}
          >
            fast_rewind
          </i> */}
          <i
            className="material-icons-round advanced-filters-go-back-icon part-1"
            onClick={handleMetricFiltersClose}
          >
            arrow_back_ios
          </i>
          <i
            className="material-icons-round advanced-filters-go-back-icon part-2"
            onClick={handleMetricFiltersClose}
          >
            arrow_back_ios
          </i>

          <p className="advanced-filters-title">{t("Metric Filters")}</p>
        </div>
        <div className="right-panel">
          <i
            className="material-icons-round advanced-filter-close-icon"
            onClick={handleMetricFiltersClose}
          >
            close
          </i>
        </div>
      </div>
      <div className="advanced-filters-row-container">
        {/* <div className="advanced-filters-clear-all-container">
          <p
            className="advanced-filters-clear-all-title"
            onClick={() => {
              handleClearAll();
            }}
          >
            clear all
          </p>
        </div> */}
        {metricFiltersStore.map((metricFilterRow, rowIndex) => (
          <MetricFiltersRow
            key={metricFilterRow.id}
            metricFilterRow={metricFilterRow}
            rowIndex={rowIndex}
            maxLimit={maxLimit}
            totalLength={metricFiltersStore.length}
            dispatchMetricFiltersStore={dispatchMetricFiltersStore}
            showError={showError}
            allFilterTypes={metricFiltersDropdownTypes}
            allData={allData}
            user={user}
          />
        ))}
      </div>
      <div className="advanced-filters-actions-bar-container">
        <section className="left-panel">
          <SigviewButton
            variant="outlined"
            onClick={handleClearAll}
            title="Clear All"
          />
        </section>
        <section className="right-panel">
          <SigviewButton
            variant="outlined"
            onClick={handleMetricFiltersClose}
            title="Cancel"
            style={{ width: "50px" }}
          />
          <SigviewButton
            variant="contained"
            onClick={handleApplyMetricFilters}
            title={applyButtonText}
            style={{ margin: { left: "10px" }, width: "95px" }}
          />
        </section>
      </div>
    </div>
  );
}

MetricFiltersContainer.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
  allData: state.data,
});

export default connect(mapStateToProps)(MetricFiltersContainer);
