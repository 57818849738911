// * Import lib
import isEqual from "lodash.isequal";
import { orderBy } from "lodash";

// * Import config
import { config } from "../config/config";

// * Import utils
import { areMultiItemsSelectedInMsv } from "./analyzeUtils";
import SIGVIEW_CONTANTS from "../constants/sigviewConstants";

// * Define required variables
const defaultQeData = SIGVIEW_CONTANTS.defaultQeData;
const defaultKpiData = SIGVIEW_CONTANTS.defaultKpiData;

const manipulateOrderByDetailsBasedOnBusinessLogic = (state) => {
  const selectedDimTableMetricIds = state.selectedDimTableMetrics.value.map(
    (row) => row._id
  );
  const finalOrderById =
    state.orderById.value === "_dimension"
      ? "_dimension"
      : selectedDimTableMetricIds.includes(state.orderById.value)
      ? state.orderById.value
      : selectedDimTableMetricIds[0];

  const validatedState = {
    ...state,
    orderById: {
      ...state.orderById,
      value: finalOrderById,
    },
  };

  return validatedState;
};

const manipulateOrderByDetailsForDimensionTables = (state) => {
  var selectedDimensionsVal = {};
  const selectedDimensionsValArr = Object.values(
    state.selectedDimensions.value
  );
  const isComparisonOn = state.timeFilters.value.isComparisonOn;
  for (const selectedDimension of selectedDimensionsValArr) {
    const metricsListIds = selectedDimension.metricsList.map((row) => row._id);
    const isOrderByOnDim = selectedDimension["orderById"] === "_dimension"; // hard coded
    const isOrderByIdValid = metricsListIds.includes(
      selectedDimension.orderById
    );
    var newSelectedDimension = { ...selectedDimension };
    if (!isOrderByOnDim && !isOrderByIdValid) {
      newSelectedDimension["orderById"] = metricsListIds[0];
      newSelectedDimension["orderBy"] = "desc";
    }

    // orderByType
    if (!isComparisonOn) {
      newSelectedDimension["orderByType"] = "id_only";
    }
    selectedDimensionsVal[selectedDimension.id] = { ...newSelectedDimension };
  }
  const validatedState = {
    ...state,
    selectedDimensions: {
      ...state.selectedDimensions,
      value: selectedDimensionsVal,
    },
  };
  return validatedState;
};

const makeAllValidMetricCalendarKeys = (state) => {
  let allValidMetricKeys = {};

  // * MSV Case
  if (state.isItMsvForm.value) {
    const areMultiItemsSelectedInMsvFlag = areMultiItemsSelectedInMsv(state);

    // * Case: Show primary calendar line
    if (areMultiItemsSelectedInMsvFlag) {
      // * Case: Show Multiple Lines Selected in MSV Table
      let selectedTableItemInChart = state.selectedTableItemInChart.value;

      // * Define required variables
      let allValidPrimaryMetricKeys = [];
      let allValidSecondaryMetricKeys = [];
      let allMetricIds = Object.keys(state.selectedKpis.value);

      // Iterate through allMetricIds
      for (const metricId of allMetricIds) {
        // Iterate through multiple selected dimension values in MSV Table
        for (const selectedDimValue of selectedTableItemInChart) {
          const validMetricKey = `${metricId}_chart_${selectedDimValue}`;
          allValidPrimaryMetricKeys.push(validMetricKey);
        }
      }

      // allValidSecondaryMetricKeys
      // case not needed for MSV table

      allValidMetricKeys = {
        allValidPrimaryMetricKeys,
        allValidSecondaryMetricKeys,
        allValidMetricKeys: [
          ...allValidPrimaryMetricKeys,
          ...allValidSecondaryMetricKeys,
        ],
      };
    } else {
      // * Case: Primary Calendar Only
      let timeFiltersVal = state.timeFilters.value;
      // let allSelectedSecondaryEpochsKeys = [];
      let allMetricIds = Object.keys(state.selectedKpis.value);
      let allValidPrimaryMetricKeys = [];
      let allValidSecondaryMetricKeys = [];

      // * Define required variables
      // This is to cater to primary calendar
      let startEpoch = timeFiltersVal.selectedDatesQE.startDate;
      let endEpoch = timeFiltersVal.selectedDatesQE.endDate;
      let normalCalendarKey = `chart_${startEpoch}_${endEpoch}`;

      // allValidPrimaryMetricKeys
      for (const metricId of allMetricIds) {
        const validMetricKey = `${metricId}_${normalCalendarKey}`;
        allValidPrimaryMetricKeys.push(validMetricKey);
      }

      // allValidSecondaryMetricKeys
      // case not needed for MSV table

      allValidMetricKeys = {
        allValidPrimaryMetricKeys,
        allValidSecondaryMetricKeys,
        allValidMetricKeys: [
          ...allValidPrimaryMetricKeys,
          ...allValidSecondaryMetricKeys,
        ],
      };
    }
  } else {
    // * Workspace Case
    let timeFiltersVal = state.timeFilters.value;
    let allSelectedSecondaryEpochsKeys = [];
    let allMetricIds = Object.keys(state.selectedKpis.value);
    let allValidPrimaryMetricKeys = [];
    let allValidSecondaryMetricKeys = [];

    // * Define required variables
    // This is to cater to primary calendar
    let startEpoch = timeFiltersVal.selectedDatesQE.startDate;
    let endEpoch = timeFiltersVal.selectedDatesQE.endDate;
    let normalCalendarKey = `chart_${startEpoch}_${endEpoch}`;

    // This is to cater to secondary calendar (all date ranges)
    // ! Added logic to keep only n rows (n = limitList)
    if (timeFiltersVal.isComparisonOn) {
      const maxCompareCalendarInDashboard =
        state.utils.value.uiLimitsList.maxCompareCalendarInDashboard;
      timeFiltersVal.compareDates
        .slice(0, maxCompareCalendarInDashboard) // ! Added logic to keep only n rows (n = limitList)
        .forEach((row) => {
          let startEpoch = row.compareSelectedDatesQE.startDate;
          let endEpoch = row.compareSelectedDatesQE.endDate;
          let compareCalendarKey = `chart_${startEpoch}_${endEpoch}`;
          allSelectedSecondaryEpochsKeys.push(compareCalendarKey);
        });
    }

    // allValidPrimaryMetricKeys
    for (const metricId of allMetricIds) {
      const validMetricKey = `${metricId}_${normalCalendarKey}`;
      allValidPrimaryMetricKeys.push(validMetricKey);
    }

    // allValidSecondaryMetricKeys
    for (const selectedEpochKey of allSelectedSecondaryEpochsKeys) {
      for (const metricId of allMetricIds) {
        const validMetricKey = `${metricId}_${selectedEpochKey}`;
        allValidSecondaryMetricKeys.push(validMetricKey);
      }
    }

    allValidMetricKeys = {
      allValidPrimaryMetricKeys,
      allValidSecondaryMetricKeys,
      allValidMetricKeys: [
        ...allValidPrimaryMetricKeys,
        ...allValidSecondaryMetricKeys,
      ],
    };
  }

  return allValidMetricKeys;
};

const manipulateDataQEBasedOnTimeFilters = (state) => {
  if (state.isItMsvForm.value === false) {
    var timeFiltersVal = state.timeFilters.value;
    var allSelectedEpochsKeys = [];
    var allMetricIds = Object.keys(state.selectedKpis.value);
    var allValidMetricKeys = [];

    // * Define required variables
    // This is to cater to primary calendar
    let startEpoch = timeFiltersVal.selectedDatesQE.startDate;
    let endEpoch = timeFiltersVal.selectedDatesQE.endDate;
    let normalCalendarKey = `chart_${startEpoch}_${endEpoch}`;
    allSelectedEpochsKeys.push(normalCalendarKey);

    // This is to cater to secondary calendar (all date ranges)
    // ! Added logic to keep only n rows (n = limitList)
    if (timeFiltersVal.isComparisonOn) {
      const maxCompareCalendarInDashboard =
        state.utils.value.uiLimitsList.maxCompareCalendarInDashboard;
      timeFiltersVal.compareDates
        .slice(0, maxCompareCalendarInDashboard) // ! Added logic to keep only n rows (n = limitList)
        .forEach((row) => {
          let startEpoch = row.compareSelectedDatesQE.startDate;
          let endEpoch = row.compareSelectedDatesQE.endDate;
          let compareCalendarKey = `chart_${startEpoch}_${endEpoch}`;
          allSelectedEpochsKeys.push(compareCalendarKey);
        });
    }

    // allValidMetricKeys
    for (const selectedEpochKey of allSelectedEpochsKeys) {
      for (const metricId of allMetricIds) {
        const validMetricKey = `${metricId}_${selectedEpochKey}`;
        allValidMetricKeys.push(validMetricKey);
      }
    }

    // ** Update dataQE, reloadEpochs
    // * dataQE
    var newDataQEVal = { ...state.dataQE.value };
    var oldDataQEValKeys = Object.keys(state.dataQE.value);
    var newMetricKeysToBeAdded = allValidMetricKeys.filter(
      (newId) => !oldDataQEValKeys.includes(newId)
    );
    // Remove old values
    for (const key of Object.keys(newDataQEVal)) {
      // Doing only for metrics and custom metrics; Not dimension
      if (key.includes("_chart_")) {
        const isKeyValid = allValidMetricKeys.includes(key);

        // Remove the key if it's not valid based on new selected time filters
        if (!isKeyValid) {
          delete newDataQEVal[key];
        }
      }
    }
    // Add new values
    for (const newKey of newMetricKeysToBeAdded) {
      newDataQEVal[newKey] = {
        status: "loading",
        message: "",
        result: defaultQeData,
      };
    }

    // * reloadEpochs
    var newReloadEpochsVal = { ...state.reloadEpochs.value };
    for (const key of Object.keys(newReloadEpochsVal)) {
      // Doing only for metrics and custom metrics; Not dimension
      if (key.includes("_chart_")) {
        const isKeyValid = allValidMetricKeys.includes(key);

        // Remove the key if it's not valid based on new selected time filters
        if (!isKeyValid) {
          delete newReloadEpochsVal[key];
        }
      }
    }
    // Add new values
    for (const newKey of newMetricKeysToBeAdded) {
      newReloadEpochsVal[newKey] = Date.now();
    }

    const validatedState = {
      ...state,
      dataQE: { ...state.dataQE, value: newDataQEVal },
      reloadEpochs: { ...state.reloadEpochs, value: newReloadEpochsVal },
    };
    return validatedState;
  } else if (state.isItMsvForm.value === true) {
    return state;
    // var timeFiltersVal = state.timeFilters.value;
    // // If filter selected is same as msv table value
    // var dimensionFiltersVal =
    //   state.dimensionFilters.value.find(
    //     (el) => el.id === state.activeMsvTableId.value
    //   )?.values || [];
    // var allSelectedEpochsKeys = [];
    // var allMetricIds = Object.keys(state.selectedKpis.value);
    // var allValidMetricKeys = [];
    // // * Define required variables
    // // This is to cater to primary calendar
    // let startEpoch = timeFiltersVal.selectedDatesQE.startDate;
    // let endEpoch = timeFiltersVal.selectedDatesQE.endDate;
    // let normalCalendarKey = `chart_${startEpoch}_${endEpoch}`;
    // allSelectedEpochsKeys.push(normalCalendarKey);
    // // This is to cater to filtered value (all date ranges)
    // // var newDataQEVal = { ...state.dataQE.value };
    // var newDataQEVal = {};
    // var newReloadEpochsVal = {};
    // if (dimensionFiltersVal.length > 0) {
    //   const startEpoch = state.timeFilters.value.selectedDatesQE.startDate;
    //   const endEpoch = state.timeFilters.value.selectedDatesQE.endDate;
    //   const exludeMainCalendarKey = `_chart_${startEpoch}_${endEpoch}`;
    //   for (let key of Object.keys(state.dataQE.value)) {
    //     if (
    //       (key.includes("chart") &&
    //         dimensionFiltersVal.includes(key.split("_")[2])) ||
    //       !key.includes("chart")
    //     ) {
    //       newDataQEVal[key] = state.dataQE.value[key];
    //     }
    //   }
    //   for (let key of Object.keys(state.reloadEpochs.value)) {
    //     if (
    //       (key.includes("chart") && dimensionFiltersVal.includes(key)) ||
    //       !key.includes("chart")
    //     ) {
    //       newReloadEpochsVal[key] = state.reloadEpochs.value[key];
    //     }
    //   }
    //   // newReloadEpochsVal = Object.fromEntries(
    //   //   Object.entries(newReloadEpochsVal).filter(
    //   //     ([key]) => !key.includes(exludeMainCalendarKey)
    //   //   )
    //   // );
    // } else {
    //   newDataQEVal = { ...state.dataQE.value };
    //   newReloadEpochsVal = { ...state.reloadEpochs.value };
    // }
    // const validatedState = {
    //   ...state,
    //   dataQE: { ...state.dataFromQE, value: newDataQEVal },
    //   reloadEpochs: { ...state.reloadEpochs, value: newReloadEpochsVal },
    // };
    // return validatedState;
  }
};

const manipulateDataQEBasedOnMsvTableRowClick = (state) => {
  const { allValidMetricKeys } = makeAllValidMetricCalendarKeys(state);

  var newState = { ...state };
  var newDataQeVal = { ...newState.dataQE.value };
  var newReloadEpochsVal = { ...newState.reloadEpochs.value };

  // Add new ones
  for (const msvKey of allValidMetricKeys) {
    const isKeyAbsentInDataQe = newDataQeVal[msvKey] === undefined;
    const isKeyAbsentInReloadEpochs = newReloadEpochsVal[msvKey] === undefined;
    if (isKeyAbsentInDataQe) {
      newDataQeVal[msvKey] = defaultQeData;
    }
    if (isKeyAbsentInReloadEpochs) {
      newReloadEpochsVal[msvKey] = Date.now();
    }
  }

  // Remove obsolute ones
  for (const dataQeKey of Object.keys(newDataQeVal)) {
    const isChartKey = dataQeKey.includes("_chart_");
    const isInvalidKey = !allValidMetricKeys.includes(dataQeKey);
    if (isChartKey && isInvalidKey) {
      delete newDataQeVal[dataQeKey];
      delete newReloadEpochsVal[dataQeKey];
    }
  }

  // Update newState
  newState = {
    ...newState,
    dataQE: { ...newState.dataQE, value: newDataQeVal },
    reloadEpochs: { ...newState.reloadEpochs, value: newReloadEpochsVal },
  };
  return newState;
};

const manipulateMetricChartTypeBasedOnBusinessLogic = (state) => {
  const isItMsvForm = state.isItMsvForm.value;
  let newState = { ...state };
  if (isItMsvForm) {
    const areMultiItemsSelectedInMsvFlag = areMultiItemsSelectedInMsv(state);
    const metricChartType = state.metricChartType.value;
    let newMetricChartTypeVal = state.metricChartType.value;

    if (areMultiItemsSelectedInMsvFlag) {
      let validChartTypes = ["multiline", "groupedbar", "verticalstackedbar"];
      if (!validChartTypes.includes(metricChartType)) {
        newMetricChartTypeVal = "multiline";
      }
    } else {
      let validChartTypes = ["line", "bar", "area"];
      if (!validChartTypes.includes(metricChartType)) {
        newMetricChartTypeVal = "line";
      }
    }

    newState = {
      ...state,
      metricChartType: {
        ...state.metricChartType,
        value: newMetricChartTypeVal,
      },
    };
  } else {
    const isComparisonOn = state.timeFilters.value.isComparisonOn;
    const metricChartType = state.metricChartType.value;
    let newMetricChartTypeVal = state.metricChartType.value;
    if (isComparisonOn) {
      let validChartTypes = ["multiline", "groupedbar"];
      if (!validChartTypes.includes(metricChartType)) {
        newMetricChartTypeVal = "multiline";
      }
    } else {
      let validChartTypes = ["line", "bar", "area"];
      if (!validChartTypes.includes(metricChartType)) {
        newMetricChartTypeVal = "line";
      }
    }

    newState = {
      ...state,
      metricChartType: {
        ...state.metricChartType,
        value: newMetricChartTypeVal,
      },
    };
  }

  return newState;
};

const resetAllDataQE = (state) => {
  let finalState = { ...state };

  let dataQEVal = {};
  let reloadEpochsVal = {};
  reloadEpochsVal["allMetricCharts"] = Date.now();
  reloadEpochsVal["allMetrics"] = Date.now();

  // Add dimension keys
  for (const key of Object.keys(finalState.selectedDimensions.value)) {
    dataQEVal[key] = defaultQeData;
    reloadEpochsVal[key] = Date.now();
  }

  // Add metric keys
  for (const key of Object.keys(finalState.selectedKpis.value)) {
    dataQEVal[key] = defaultKpiData;
    reloadEpochsVal[key] = Date.now();
  }

  // Add metric trueDelta and percentageDelta keys
  for (const key of Object.keys(finalState.selectedKpis.value)) {
    const newKey1 = `${key}_trueDelta`;
    const newKey2 = `${key}_deltaPercentage`;
    const newKey3 = `${key}_percentageTotalValue`;
    dataQEVal[newKey1] = defaultKpiData;
    reloadEpochsVal[newKey1] = Date.now();
    dataQEVal[newKey2] = defaultKpiData;
    reloadEpochsVal[newKey2] = Date.now();
    dataQEVal[newKey3] = defaultKpiData;
    reloadEpochsVal[newKey3] = Date.now();
  }

  // Add metric calendar keys
  const { allValidMetricKeys } = makeAllValidMetricCalendarKeys(finalState);
  for (const key of allValidMetricKeys) {
    dataQEVal[key] = defaultQeData;
    reloadEpochsVal[key] = Date.now();
  }

  finalState = {
    ...finalState,
    dataQE: { ...finalState.dataQE, value: dataQEVal },
    reloadEpochs: { ...finalState.reloadEpochs, value: reloadEpochsVal },
  };

  return finalState;
};

const resetAllMetricChartsDataQE = (state) => {
  let finalState = { ...state };

  let dataQEVal = { ...finalState.dataQE.value };
  let reloadEpochsVal = { ...finalState.reloadEpochs.value };
  reloadEpochsVal["allMetricCharts"] = Date.now();

  // Add metric calendar keys
  const { allValidMetricKeys } = makeAllValidMetricCalendarKeys(finalState);
  for (const key of allValidMetricKeys) {
    dataQEVal[key] = defaultQeData;
    reloadEpochsVal[key] = Date.now();
  }

  finalState = {
    ...finalState,
    dataQE: { ...finalState.dataQE, value: dataQEVal },
    reloadEpochs: { ...finalState.reloadEpochs, value: reloadEpochsVal },
  };

  return finalState;
};

const resetAllMetricsAndMetricChartsDataQE = (state) => {
  let finalState = { ...state };

  let dataQEVal = { ...finalState.dataQE.value };
  let reloadEpochsVal = { ...finalState.reloadEpochs.value };
  reloadEpochsVal["allMetrics"] = Date.now();
  reloadEpochsVal["allMetricCharts"] = Date.now();

  // Add metric calendar keys
  const { allValidMetricKeys } = makeAllValidMetricCalendarKeys(finalState);
  for (const key of allValidMetricKeys) {
    dataQEVal[key] = defaultQeData;
    reloadEpochsVal[key] = Date.now();
  }
  for (const metricId of Object.keys(state.selectedKpis.value)) {
    dataQEVal[metricId] = defaultKpiData;
    reloadEpochsVal[metricId] = Date.now();
    const newKey1 = `${metricId}_trueDelta`;
    const newKey2 = `${metricId}_deltaPercentage`;
    const newKey3 = `${metricId}_percentageTotalValue`;
    dataQEVal[newKey1] = defaultKpiData;
    reloadEpochsVal[newKey1] = Date.now();
    dataQEVal[newKey2] = defaultKpiData;
    reloadEpochsVal[newKey2] = Date.now();
    dataQEVal[newKey3] = defaultKpiData;
    reloadEpochsVal[newKey3] = Date.now();
  }

  finalState = {
    ...finalState,
    dataQE: { ...finalState.dataQE, value: dataQEVal },
    reloadEpochs: { ...finalState.reloadEpochs, value: reloadEpochsVal },
  };

  return finalState;
};

const resetAllMetricsOnlyDataQE = (state) => {
  let finalState = { ...state };

  let dataQEVal = { ...finalState.dataQE.value };
  let reloadEpochsVal = { ...finalState.reloadEpochs.value };
  reloadEpochsVal["allMetrics"] = Date.now();

  for (const metricId of Object.keys(finalState.selectedKpis.value)) {
    dataQEVal[metricId] = defaultKpiData;
    reloadEpochsVal[metricId] = Date.now();
    const newKey1 = `${metricId}_trueDelta`;
    const newKey2 = `${metricId}_deltaPercentage`;
    const newKey3 = `${metricId}_percentageTotalValue`;
    dataQEVal[newKey1] = defaultKpiData;
    reloadEpochsVal[newKey1] = Date.now();
    dataQEVal[newKey2] = defaultKpiData;
    reloadEpochsVal[newKey2] = Date.now();
    dataQEVal[newKey3] = defaultKpiData;
    reloadEpochsVal[newKey3] = Date.now();
  }

  finalState = {
    ...finalState,
    dataQE: { ...finalState.dataQE, value: dataQEVal },
    reloadEpochs: { ...finalState.reloadEpochs, value: reloadEpochsVal },
  };

  return finalState;
};

const resetAllDimensionsDataQE = (state) => {
  let finalState = { ...state };

  let dataQEVal = { ...finalState.dataQE.value };
  let reloadEpochsVal = { ...finalState.reloadEpochs.value };

  // Add dimension keys
  for (const key of Object.keys(finalState.selectedDimensions.value)) {
    dataQEVal[key] = defaultQeData;
    reloadEpochsVal[key] = Date.now();
  }

  finalState = {
    ...finalState,
    dataQE: { ...finalState.dataQE, value: dataQEVal },
    reloadEpochs: { ...finalState.reloadEpochs, value: reloadEpochsVal },
  };

  return finalState;
};

const manipulateStateBasedOnTimeFiltersChange = (newState, oldState) => {
  let finalState = { ...newState };

  // * What all can be changed in time filters
  // 1. selectedDatesQE --> didSelectedDatesQEChange
  // 2. compareDates --> didCompareDatesChange
  // 3. compareDates.isSelectedRow --> didCompareDatesSelectedRowChange
  // 4. comparisonChangeDropdown --> didComparisonChangeDropdownChange
  // 5. isComparisonOn --> didIsComparisonOnChange
  // 6. selectedRowCompareDates --> didSelectedRowCompareDatesChange
  // 7. orderByType ---> wasOldOrderByTypeNotOnIdOnly
  // 8. globalSort ---> wasOldGlobalSortOn

  // * 1. didSelectedDatesQEChange
  const didSelectedDatesQEChange = !isEqual(
    newState.timeFilters.value.selectedDatesQE,
    oldState.timeFilters.value.selectedDatesQE
  );

  // * 2. compareDates
  const newStateCompareDatesList = newState.timeFilters.value.compareDates.map(
    (row) => {
      let startEpoch = row.compareSelectedDatesQE.startDate;
      let endEpoch = row.compareSelectedDatesQE.endDate;
      let compareCalendarKey = `chart_${startEpoch}_${endEpoch}`;
      return compareCalendarKey;
    }
  );
  const oldStateCompareDatesList = oldState.timeFilters.value.compareDates.map(
    (row) => {
      let startEpoch = row.compareSelectedDatesQE.startDate;
      let endEpoch = row.compareSelectedDatesQE.endDate;
      let compareCalendarKey = `chart_${startEpoch}_${endEpoch}`;
      return compareCalendarKey;
    }
  );
  // New added keys
  const newCompareCalendarKeysAdded = newStateCompareDatesList.filter(
    (key) => !oldStateCompareDatesList.includes(key)
  );
  // Obsolute keys
  const obsoluteCompareCalendarKeysAdded = oldStateCompareDatesList.filter(
    (key) => !newStateCompareDatesList.includes(key)
  );
  const didCompareDatesChange = newCompareCalendarKeysAdded.length > 0;

  // * 3. didCompareDatesSelectedRowChange
  const newStateCompareDatesSelectedRow =
    newState.timeFilters.value.compareDates.find((row) => row.isSelected);
  const oldStateCompareDatesSelectedRow =
    oldState.timeFilters.value.compareDates.find((row) => row.isSelected);
  const didCompareDatesSelectedRowChange =
    newStateCompareDatesSelectedRow?.id !== oldStateCompareDatesSelectedRow?.id;

  // * 4. comparisonChangeDropdown
  const didComparisonChangeDropdownChange =
    newState.timeFilters.value.comparisonType !==
    oldState.timeFilters.value.comparisonType;

  // * 5. isComparisonOn
  const didIsComparisonOnChange =
    newState.timeFilters.value.isComparisonOn !==
    oldState.timeFilters.value.isComparisonOn;

  // * 6. selectedRowCompareDates
  // This is for the dimension table
  var didSelectedRowCompareDatesChange = false;
  if (!didCompareDatesSelectedRowChange) {
    // New state
    let newStateSelectedCompareRowStartEpoch =
      newStateCompareDatesSelectedRow?.compareSelectedDatesQE.startDate;
    let newStateSelectedCompareRowEndEpoch =
      newStateCompareDatesSelectedRow?.compareSelectedDatesQE.endDate;
    let newStateSelectedCompareRowKey = `chart_${newStateSelectedCompareRowStartEpoch}_${newStateSelectedCompareRowEndEpoch}`;

    // Old state
    let oldStateSelectedCompareRowStartEpoch =
      oldStateCompareDatesSelectedRow?.compareSelectedDatesQE.startDate;
    let oldStateSelectedCompareRowEndEpoch =
      oldStateCompareDatesSelectedRow?.compareSelectedDatesQE.endDate;
    let oldStateSelectedCompareRowKey = `chart_${oldStateSelectedCompareRowStartEpoch}_${oldStateSelectedCompareRowEndEpoch}`;
    if (newStateSelectedCompareRowKey !== oldStateSelectedCompareRowKey) {
      didSelectedRowCompareDatesChange = true;
    }
  }

  // * 7. wasOldOrderByTypeNotOnIdOnly
  const wasOldOrderByTypeNotOnIdOnly = oldState.orderByType.value !== "id_only";

  // * 8. globalSort ---> wasOldGlobalSortOn
  const wasOldGlobalSortOn = oldState.globalSort.value;

  // * Define required variables
  const isItMsvForm = newState.isItMsvForm.value;

  // Reset every metric, metric chart, trueDelta and percentageDelta
  // It's like creating a fresh dataQE for metrics
  if (didSelectedDatesQEChange) {
    finalState = resetAllDataQE(newState);
  }

  if (didIsComparisonOnChange) {
    // If the form is MSV, no change is required for metric charts, only dimension table needs to be changed
    let dataQEVal = finalState.dataQE.value;
    let reloadEpochsVal = finalState.reloadEpochs.value;
    if (isItMsvForm) {
      // * If comparison was turned on
      if (finalState.timeFilters.value.isComparisonOn) {
        // 1. Modify all dimensions (which includes our MSV table dim id as well)
        for (const key of Object.keys(finalState.selectedDimensions.value)) {
          dataQEVal[key] = defaultQeData;
          reloadEpochsVal[key] = Date.now();
        }
      } else {
        // No change required
      }
    } else {
      // * If comparison was turned on
      if (finalState.timeFilters.value.isComparisonOn) {
        // 1. Modify all dimensions
        for (const key of Object.keys(finalState.selectedDimensions.value)) {
          dataQEVal[key] = defaultQeData;
          reloadEpochsVal[key] = Date.now();
        }

        // 2. Retrigger allMetricCharts and allMetrics API
        reloadEpochsVal["allMetricCharts"] = Date.now();
        reloadEpochsVal["allMetrics"] = Date.now();

        // 3. Add all the compare metric calendar keys
        const { allValidSecondaryMetricKeys } =
          makeAllValidMetricCalendarKeys(finalState);
        for (const key of allValidSecondaryMetricKeys) {
          dataQEVal[key] = defaultQeData;
          reloadEpochsVal[key] = Date.now();
        }
      } else {
        // * If comparison was turned off
        // 1. Just remove all the compare metric calendar keys
        const { allValidSecondaryMetricKeys } =
          makeAllValidMetricCalendarKeys(finalState);
        for (const key of allValidSecondaryMetricKeys) {
          delete dataQEVal[key];
          delete reloadEpochsVal[key];
        }

        // 2. Add metric trueDelta and percentageDelta keys
        for (const key of Object.keys(finalState.selectedKpis.value)) {
          const newKey1 = `${key}_trueDelta`;
          const newKey2 = `${key}_deltaPercentage`;
          const newKey3 = `${key}_percentageTotalValue`;
          dataQEVal[newKey1] = defaultKpiData;
          reloadEpochsVal[newKey1] = Date.now();
          dataQEVal[newKey2] = defaultKpiData;
          reloadEpochsVal[newKey2] = Date.now();
          dataQEVal[newKey3] = defaultKpiData;
          reloadEpochsVal[newKey3] = Date.now();
        }
      }
    }

    finalState = {
      ...finalState,
      dataQE: { ...finalState.dataQE, value: dataQEVal },
      reloadEpochs: { ...finalState.reloadEpochs, value: reloadEpochsVal },
    };
  }

  if (didSelectedRowCompareDatesChange || didCompareDatesSelectedRowChange) {
    finalState = resetAllDimensionsDataQE(finalState);
  }

  if (didCompareDatesChange) {
    let dataQEVal = finalState.dataQE.value;
    let reloadEpochsVal = finalState.reloadEpochs.value;

    if (isItMsvForm) {
      // No change required in metric charts
    } else {
      // 1. Remove obsolute metric keys
      for (const metricId of Object.keys(finalState.selectedKpis.value)) {
        for (const key of obsoluteCompareCalendarKeysAdded) {
          const keyToBeRemoved = `${metricId}_${key}`;
          delete dataQEVal[keyToBeRemoved];
          delete reloadEpochsVal[keyToBeRemoved];
        }
      }

      // 2. Add new ones
      for (const metricId of Object.keys(finalState.selectedKpis.value)) {
        for (const key of newCompareCalendarKeysAdded) {
          const keyTobeAdded = `${metricId}_${key}`;
          dataQEVal[keyTobeAdded] = defaultQeData;
          reloadEpochsVal[keyTobeAdded] = Date.now();
        }
      }

      // 3. Add metric trueDelta and percentageDelta keys
      for (const key of Object.keys(finalState.selectedKpis.value)) {
        const newKey1 = `${key}_trueDelta`;
        const newKey2 = `${key}_deltaPercentage`;
        const newKey3 = `${key}_percentageTotalValue`;
        dataQEVal[newKey1] = defaultKpiData;
        reloadEpochsVal[newKey1] = Date.now();
        dataQEVal[newKey2] = defaultKpiData;
        reloadEpochsVal[newKey2] = Date.now();
        dataQEVal[newKey3] = defaultKpiData;
        reloadEpochsVal[newKey3] = Date.now();
      }

      // 4. Retrigger allMetricCharts and allMetrics API
      reloadEpochsVal["allMetricCharts"] = Date.now();
      reloadEpochsVal["allMetrics"] = Date.now();
    }

    finalState = {
      ...finalState,
      dataQE: { ...finalState.dataQE, value: dataQEVal },
      reloadEpochs: { ...finalState.reloadEpochs, value: reloadEpochsVal },
    };
  }

  if (wasOldOrderByTypeNotOnIdOnly && wasOldGlobalSortOn) {
    finalState = resetAllDimensionsDataQE(finalState);
  }

  // * DEBUGGER
  // console.group("CUSTOM DEPENDENCY CHANGE");
  // console.log("didSelectedDatesQEChange", didSelectedDatesQEChange);
  // console.log("didCompareDatesChange", didCompareDatesChange);
  // console.log(
  //   "didCompareDatesSelectedRowChange",
  //   didCompareDatesSelectedRowChange
  // );
  // console.log(
  //   "didComparisonChangeDropdownChange",
  //   didComparisonChangeDropdownChange
  // );
  // console.log("didIsComparisonOnChange", didIsComparisonOnChange);
  // console.groupEnd();

  return finalState;
};

const manipulateMetricFiltersBasedOnTimeFiltersChange = (state) => {
  // If time filters is turned off
  const isComparisonOn = state.timeFilters.value.isComparisonOn;
  const isItMsvForm = state.isItMsvForm.value;
  if (!isComparisonOn && isItMsvForm) {
    const activeMsvTableId = state.activeMsvTableId.value;
    var newMetricFilters =
      state.selectedDimensions.value[activeMsvTableId].metricFilters;
    newMetricFilters = newMetricFilters.filter(
      (row) => row.absoluteChange === "id_only"
    );
    var newSelectedDimensionsVal = {
      ...state.selectedDimensions.value,
      [activeMsvTableId]: {
        ...state.selectedDimensions.value[activeMsvTableId],
        metricFilters: newMetricFilters,
      },
    };
    var newState = {
      ...state,
      selectedDimensions: {
        ...state.selectedDimensions,
        value: newSelectedDimensionsVal,
      },
    };
    return newState;
  } else {
    return state;
  }
};

const manipulateMsvColorMapping = (state) => {
  var newState = { ...state };
  var newMsvColorMappingVal = { ...newState.msvColorMapping.value };
  var selectedTableItemInChart = [...newState.selectedTableItemInChart.value];

  // Remove old ones
  for (const dimValue of Object.keys(newMsvColorMappingVal)) {
    const isDimValueAbsentInSelectedTableItemInChart =
      !selectedTableItemInChart.includes(dimValue);
    if (isDimValueAbsentInSelectedTableItemInChart) {
      delete newMsvColorMappingVal[dimValue];
    }
  }

  // Already Used Colors
  const msvColorArr = config.hardCoded.msvColorArr["light"]; // ! HARD CODED as it's not present in the state; it can be added in utils but it has to be updated whenever theme changes; hence not included
  const alreadyUsedColors = Object.values(newMsvColorMappingVal);
  var remainingColors = msvColorArr.filter(
    (color) => !alreadyUsedColors.includes(color)
  );

  // Add new ones
  for (const dimValue of selectedTableItemInChart) {
    const isDimValueAbsentInColorMapping =
      newMsvColorMappingVal[dimValue] === undefined;
    if (isDimValueAbsentInColorMapping) {
      newMsvColorMappingVal[dimValue] = remainingColors[0];

      // Update remaining colors arr
      remainingColors = remainingColors.slice(1);
    }
  }

  newState = {
    ...newState,
    msvColorMapping: {
      ...newState.msvColorMapping,
      value: newMsvColorMappingVal,
    },
  };

  return newState;
};

const manipulateRegexCheckAllBasedOnDataQe = (state) => {
  const runFlag =
    state.isItMsvForm.value &&
    state.regexSearchField.value.length > 0 &&
    areMultiItemsSelectedInMsv(state);
  if (runFlag) {
    const allSelectedTableItem = state.allSelectedTableItem.value;
    var activeMsvTableId = state.activeMsvTableId.value;
    var activeMsvTableIdObj =
      state.selectedDimensions.value[activeMsvTableId].dimensionsList[0];
    var accessor = activeMsvTableIdObj["dimID"];
    const allDimValuesInDataFromQe =
      state.dataQE.value[activeMsvTableId].result.dataFromQE;
    var allDimValues = allDimValuesInDataFromQe.map((row) => row[accessor]);
    const areEqual = isEqual(
      orderBy(allSelectedTableItem),
      orderBy(allDimValues)
    );

    const manipulateRegexSearchFlag =
      allSelectedTableItem.length > 0 &&
      allDimValuesInDataFromQe.length > 0 &&
      areEqual;

    if (manipulateRegexSearchFlag) {
      var newState = {
        ...state,
        regexSearchCheckAll: { ...state.regexSearchCheckAll, value: true },
      };
      return newState;
    }
  }

  return state;
};

export {
  manipulateOrderByDetailsBasedOnBusinessLogic,
  manipulateOrderByDetailsForDimensionTables,
  makeAllValidMetricCalendarKeys,
  manipulateDataQEBasedOnTimeFilters,
  manipulateDataQEBasedOnMsvTableRowClick,
  manipulateMetricChartTypeBasedOnBusinessLogic,
  resetAllDataQE,
  resetAllMetricChartsDataQE,
  resetAllMetricsAndMetricChartsDataQE,
  resetAllMetricsOnlyDataQE,
  resetAllDimensionsDataQE,
  manipulateStateBasedOnTimeFiltersChange,
  manipulateMetricFiltersBasedOnTimeFiltersChange,
  manipulateMsvColorMapping,
  manipulateRegexCheckAllBasedOnDataQe,
};
