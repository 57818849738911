// Import required libraies
import React, { useReducer, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEqual from "lodash.isequal";
import { useHistory } from "react-router";

// * Import 3rd party lib
import { Box } from "@material-ui/core";

// Import Custom Component
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import StandaloneChart from "../../../components/StandaloneChart";
import { PlotMenuBar, SigviewDialogChartClone } from "./ChartsCreateHelpers";
import SigviewBreadcrumbs from "../../../components/Common/SigviewBreadcrumbs";
import DimensionFilters from "../../../components/DimensionFilters/DimensionFilters";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";

// Import Context

// Import action creators
import {
  updateUserScreen,
  updateDialogInfo,
  replaceStandaloneChartForm,
  updateStandaloneChartForm,
  replaceAllDimensionFilters,
  updateGlobalFiltersDimensionFilters,
  updateGlobalFiltersTimeFilters,
  updateGlobalFiltersFormAnalyzeFiltersReadOnly,
} from "../../../redux/actions";
import standaloneChartReducer from "../../../redux/reducers/standaloneChart";
import dimensionFiltersReducer from "../../../redux/reducers/dimensionFilters";

// Import utils/data
import { config } from "../../../config/config";
import {
  isEqualWithoutCertainKeys,
  masterMakeChartObject,
  getBreadcrumbsDataFromRoute,
} from "../../../utils/utils";
import { masterTrackGaEvent } from "../../../services/ga";
import useReducerLogger from "../../../utils/useReducerLogger";

// Import API functions
import { saveChart, updateChart, deleteChart } from "../../../services/api";
import useUpdateEffect from "../../../hooks/useUpdateEffect";

//Defining static variables

// Defining required utility functions

function ChartsCreate(props) {
  const { dispatch: ReduxDispatcher, user, allData, standaloneWs } = props;
  const {
    globalFiltersFileUpload = false,
    globalFiltersStringMatch = false,
    globalFiltersTimestamp = false,
  } = user.uiFeatureList;
  //Lifting state up so that it can be passed to PlotCreate for
  //updating the plot object
  const [chartName, setChartName] = useState({
    status: "success",
    value: user.screen.activeChart.title,
    originalValue: user.screen.activeChart.title,
    message: "",
  });
  const [standaloneChart, dispatchStandaloneChart] = useReducer(
    useReducerLogger(standaloneChartReducer),
    user.screen.activeChart
  );
  const standaloneChartOriginal = useRef(user.screen.activeChart);

  // * Define required hooks
  const history = useHistory();

  // * Define required variables
  const isEdit = standaloneChart.elementType === "update";
  const gaCategory = isEdit ? "EditChart" : "CreateChart";

  useEffect(() => {
    return () => {
      // Revert to inital state in redux when component unmounts
      // This is to cater to case when user switches between tabs
      ReduxDispatcher(updateGlobalFiltersFormAnalyzeFiltersReadOnly());
    };
  }, []);

  // * Define required side effects
  // Reset to default activeChart whenever this component unmounts
  useEffect(() => {
    return () => {
      // Change tab to home page
      const value = {
        activeChart: {},
      };
      var action = updateUserScreen(null, value);
      ReduxDispatcher(action);
    };
  }, []);

  //Update chartTitle in the object everytime chartName (parent state) changes
  //Do this only when chartName.originalValue changes because chartName.value is
  //real-time state and chartName.originalValue refers to the renamed state
  useEffect(() => {
    // Update state only when they are different
    if (standaloneChart.title !== chartName.originalValue) {
      const payload = { key: "title", value: chartName.originalValue };
      const action = updateStandaloneChartForm(payload);
      dispatchStandaloneChart(action);
      // Rename chart is a separate API
      standaloneChartOriginal.current = {
        ...standaloneChartOriginal.current,
        title: chartName.originalValue,
      };
    }
  }, [chartName.originalValue]);

  //Update local state of standaloneChart if user.screen.activeChart changes
  //Update local chartName if user.screen.activeChart changes
  useEffect(() => {
    //Perform it only when they are not equal to avoid infinite loop
    if (!isEqual(user.screen.activeChart, standaloneChart)) {
      const value = user.screen.activeChart;
      const action = replaceStandaloneChartForm(value);
      dispatchStandaloneChart(action);
      // Can come here (Not needed)
      // standaloneChartOriginal.current = { ...user.screen.activeChart };
      setChartName(() => ({
        status: "success",
        value: user.screen.activeChart.title,
        originalValue: user.screen.activeChart.title,
        message: "",
      }));
    }
  }, [user.screen.activeChart]);
  //** Backward Compatibility Code Start Here**//
  useEffect(() => {
    if (
      !globalFiltersStringMatch &&
      !globalFiltersFileUpload &&
      !globalFiltersTimestamp &&
      standaloneChart.dimensionFilters.length > 0
    ) {
      var validityFlags = standaloneChart.dimensionFilters.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              ![
                "startsWith",
                "exactlyMatches",
                "endsWith",
                "containsString",
                "containsWholeWord",
                "from",
                "on",
                "between",
                "before",
              ].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      !globalFiltersStringMatch &&
      globalFiltersFileUpload &&
      !globalFiltersTimestamp &&
      standaloneChart.dimensionFilters.length > 0
    ) {
      var validityFlags = standaloneChart.dimensionFilters.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              ![
                "startsWith",
                "endsWith",
                "containsString",
                "containsWholeWord",
                "from",
                "on",
                "between",
                "before",
              ].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      globalFiltersStringMatch &&
      !globalFiltersFileUpload &&
      !globalFiltersTimestamp &&
      standaloneChart.dimensionFilters.length > 0
    ) {
      var validityFlags = standaloneChart.dimensionFilters.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              !["exactlyMatches", "from", "on", "between", "before"].includes(
                item.type
              )
          ),
        };
        return newRow;
      });
    } else if (
      !globalFiltersStringMatch &&
      !globalFiltersFileUpload &&
      globalFiltersTimestamp &&
      standaloneChart.dimensionFilters.length > 0
    ) {
      var validityFlags = standaloneChart.dimensionFilters.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              ![
                "startsWith",
                "exactlyMatches",
                "endsWith",
                "containsString",
                "containsWholeWord",
              ].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      globalFiltersFileUpload &&
      !globalFiltersStringMatch &&
      globalFiltersTimestamp &&
      standaloneChart.dimensionFilters.length > 0
    ) {
      var validityFlags = standaloneChart.dimensionFilters.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              ![
                "startsWith",
                "endsWith",
                "containsString",
                "containsWholeWord",
              ].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      !globalFiltersFileUpload &&
      globalFiltersStringMatch &&
      globalFiltersTimestamp &&
      standaloneChart.dimensionFilters.length > 0
    ) {
      var validityFlags = standaloneChart.dimensionFilters.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) => !["exactlyMatches"].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      globalFiltersFileUpload &&
      globalFiltersStringMatch &&
      !globalFiltersTimestamp &&
      standaloneChart.dimensionFilters.length > 0
    ) {
      var validityFlags = standaloneChart.dimensionFilters.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) => !["from", "on", "between", "before"].includes(item.type)
          ),
        };
        return newRow;
      });
    }
    if (validityFlags) {
      var finalValidateFilterArray = validityFlags.filter((row) => {
        if (
          (row.values.length !== 0 && row.advancedFilters.length === 0) ||
          (row.values.length === 0 && row.advancedFilters.length !== 0) ||
          (row.values.length !== 0 && row.advancedFilters.length !== 0)
        ) {
          return row;
        }
      });
      const payload = {
        key: "dimensionFilters",
        value: finalValidateFilterArray,
      };
      var action = updateStandaloneChartForm(payload);
      dispatchStandaloneChart(action);
    }
  }, []);
  //** Backward Compatibility Code Ends Here **//
  // Defining required event handlers
  const trackCrudGA = (label) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: gaCategory,
      action: "CRUD",
      label,
    });
  };
  const handleStandaloneChartChange = (value) => {
    const action = replaceStandaloneChartForm(value);
    dispatchStandaloneChart(action);
  };

  useUpdateEffect(() => {
    if (
      standaloneChart.dimensionsList.find((el) => el.dataType === "dateTime")
    ) {
      const action = replaceStandaloneChartForm({
        ...standaloneChart,
        orderBy: "asc",
      });
      dispatchStandaloneChart(action);
    } else if (
      standaloneChart.dimensionsList.find((el) => el.dataType !== "dateTime")
    ) {
      const action = replaceStandaloneChartForm({
        ...standaloneChart,
        orderBy: "desc",
      });
      dispatchStandaloneChart(action);
    }
  }, [standaloneChart.dimensionsList]);

  const handleChartSave = () => {
    trackCrudGA("Save");
    const chartObject = masterMakeChartObject({
      metadataParams: {
        title: chartName.value,
        chartType: standaloneChart.chartType,
        comparisonMode: false,
      },
      filters: {
        timeFilters: standaloneChart.timeFilters,
        metricFilters: standaloneChart.metricFilters,
        dimensionFilters: standaloneChart.dimensionFilters,
      },
      orderByDetails: {
        orderById: standaloneChart.orderById,
        orderBy: standaloneChart.orderBy,
        orderByType: "id_only",
      },
      dimensionsList: standaloneChart.dimensionsList,
      metricsList: standaloneChart.metricsList,
      settings: { compareFlag: false, progressiveDateFlag: true },
      granularity: standaloneWs.metricChartGranularity.value,
    });
    const payload = {
      _id: standaloneChart.id,
      emailId: user?.reqMetadata?.email,
      orgViewReq: {
        organization: user?.reqMetadata?.organization,
        view: user?.reqMetadata?.view,
      },
      chartObject,
    };
    const fetchProps = {
      payload: { ...payload },
    };
    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    const saveChartPromise = saveChart(fetchProps);
    saveChartPromise
      .then((responseData) => {
        // update standaloneRef as well
        standaloneChartOriginal.current = {
          ...standaloneChart,
          elementType: "update",
        };

        // To be done at the last (callback queue)

        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Chart saved successfully!",
        };
        // Update snackbar
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        // Remove dashboard loader
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);

        // TODO: Find a better way for breadcrumb to avoid this dispatch
        // Update user.screen.activeChart.elementType to "update"
        var action = updateUserScreen("activeChart", {
          ...standaloneChart,
          elementType: "update",
        });
        ReduxDispatcher(action);

        var payload = { key: "elementType", value: "update" };
        var action = updateStandaloneChartForm(payload);
        dispatchStandaloneChart(action);
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Saving chart failed",
        };
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);
      });
  };

  const handleChartUpdate = () => {
    trackCrudGA("Update");
    const chartObject = masterMakeChartObject({
      metadataParams: {
        title: chartName.value,
        chartType: standaloneChart.chartType,
        comparisonMode: false,
      },
      filters: {
        timeFilters: standaloneChart.timeFilters,
        metricFilters: standaloneChart.metricFilters,
        dimensionFilters: standaloneChart.dimensionFilters,
      },
      orderByDetails: {
        orderById: standaloneChart.orderById,
        orderBy: standaloneChart.orderBy,
        orderByType: "id_only",
      },
      dimensionsList: standaloneChart.dimensionsList,
      metricsList: standaloneChart.metricsList,
      settings: { compareFlag: false, progressiveDateFlag: true },
      granularity: standaloneWs.metricChartGranularity.value,
    });
    const payload = {
      _id: standaloneChart.id,
      emailId: user?.reqMetadata?.email,
      orgViewReq: {
        organization: user?.reqMetadata?.organization,
        view: user?.reqMetadata?.view,
      },
      chartObject,
    };
    const fetchProps = {
      payload: { ...payload },
    };
    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    const updateChartPromise = updateChart(fetchProps);
    updateChartPromise
      .then((responseData) => {
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Chart updated successfully!",
        };
        // update standaloneRef as well
        standaloneChartOriginal.current = standaloneChart;
        // Update snackbar
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        // Remove dashboard loader
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Updating chart failed",
        };
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);
      });
  };

  const handleChartDelete = () => {
    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    const fetchProps = {
      url: `${user.apiEndpoints.baseUrl}${config.apiEndpoints.deleteChart}?id=${standaloneChart.id}`,
    };
    const deleteChartPromise = deleteChart(fetchProps);
    deleteChartPromise
      .then((responseData) => {
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Chart deleted successfully!",
        };
        // Update snackbar
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        // Remove dashboard loader
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);
        // Change tab to home page
        const value = {
          activeNav: "datastory",
          activeTab: "chartsHome",
          activeChart: {},
        };
        var action = updateUserScreen(null, value);
        ReduxDispatcher(action);
        history.push("/datastory/chart");
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Deleting chart failed",
        };
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);
      });
  };

  const handleDeleteDialog = () => {
    trackCrudGA("Delete");
    const message = {
      title: "Are you sure you want to delete?",
      subtitle: "This action cannot be undone",
    };
    const handleNo = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
        })
      );
      trackCrudGA("DeleteNo");
    };
    const handleYes = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
          message: { title: "", subtitle: "" },
        })
      );
      handleChartDelete();
      trackCrudGA("DeleteYes");
    };
    ReduxDispatcher(
      updateDialogInfo({
        ...user.dialogInfo,
        open: true,
        message,
        handleYes,
        handleNo,
      })
    );
  };

  const handleCloneDialog = () => {
    trackCrudGA("Clone");
    const initialName = `${config.hardCoded.cloneOfPreText} ${standaloneChart.title}`;
    const payload = {
      open: true,
      children: (
        <SigviewDialogChartClone
          ReduxDispatcher={ReduxDispatcher}
          initialName={initialName}
          user={user}
          standaloneChart={standaloneChart}
          isEdit={isEdit}
        />
      ),
    };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleTimeFiltersChange = (value) => {
    var payload = { key: "renderFlag", value: false }; // so that getData call is not made until user clicks apply
    var action = updateStandaloneChartForm(payload);
    dispatchStandaloneChart(action);
    var payload = { key: "timeFilters", value };
    var action = updateStandaloneChartForm(payload);
    dispatchStandaloneChart(action);
    var payload = { value };
    var actionTimeFilters = updateGlobalFiltersTimeFilters(payload);
    ReduxDispatcher(actionTimeFilters);
  };

  const handleDimensionFiltersChange = (value) => {
    var payload = { key: "renderFlag", value: false }; // so that getData call is not made until user clicks apply
    var action = updateStandaloneChartForm(payload);
    dispatchStandaloneChart(action);
    var payload = { key: "dimensionFilters", value };
    var action = updateStandaloneChartForm(payload);
    dispatchStandaloneChart(action);
    var actionDimensionFilters = updateGlobalFiltersDimensionFilters(payload);
    ReduxDispatcher(actionDimensionFilters);
  };

  const handleBreadcrumbChange = (event, value) => {
    const data = { activeNav: "datastory", activeTab: value };
    const action = updateUserScreen(null, data);
    ReduxDispatcher(action);
    history.push(value.path);
  };

  const handleAddFilters = () => {
    const handleApplyFilters = (payload, filterType) => {
      switch (filterType) {
        case "dimensions":
          var action = replaceAllDimensionFilters(payload);
          var newDimensionFilters = dimensionFiltersReducer(
            standaloneChart.dimensionFilters,
            action
          );
          handleDimensionFiltersChange(newDimensionFilters);
          break;
        // case "metrics":
        //   var action = replaceAllMetricFilters(payload);
        //   dispatchMetricFilters(action);
        //   break;
        // case "time":
        //   onTimeFiltersChange(payload);
        //   break;
      }
    };

    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Open Filters",
      label: "Sidenav",
    });
    const commonGlobalFilterProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: false,
      showMetricFilters: false,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      timeFilters: standaloneChart.timeFilters, //for initializing filters
      dimensionFilters: standaloneChart.dimensionFilters, //for initializing filters
      metricFilters: [], //for initializing filters
      // settings: dimensionFiltersSettings,
      filtersDimData: allData.plotlyDimensions,
      filtersMetricsData: allData.plotlyMetrics,
      selections: {},
    };
    //Open Global Filters
    const newGlobalFiltersProps = {
      ...commonGlobalFilterProps,
      activeDimensionFilter: {},
      handleApplyFilters, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };

    const action = updateUserScreen("globalFilters", newGlobalFiltersProps);
    ReduxDispatcher(action);
  };
  const handleGoHome = (user) => {
    history.push("/datastory");
  };

  //Defining required variables
  const areSelectionsSameAsOriginal = isEqualWithoutCertainKeys(
    standaloneChartOriginal.current,
    standaloneChart,
    "renderFlag"
  ); //Only for update
  const isSaveCloneButtonDisabled =
    standaloneChart.dimensionsList.length === 0 ||
    standaloneChart.metricsList.length === 0;
  const isUpdateDisabled =
    isSaveCloneButtonDisabled || areSelectionsSameAsOriginal;
  let menuBarSplitButtonOptions = [];
  if (standaloneChart.elementType !== "update")
    menuBarSplitButtonOptions = [
      ...menuBarSplitButtonOptions,
      {
        id: "save",
        label: "Save",
        onClick: handleChartSave,
        disabled: isSaveCloneButtonDisabled,
      },
    ];

  if (standaloneChart.elementType === "update")
    menuBarSplitButtonOptions = [
      ...menuBarSplitButtonOptions,
      {
        id: "update",
        label: "Update",
        onClick: handleChartUpdate,
        disabled: isUpdateDisabled,
      },
      {
        id: "clone",
        label: "Clone",
        onClick: handleCloneDialog,
        disabled: isSaveCloneButtonDisabled,
      },
      {
        id: "delete",
        label: "Delete",
        onClick: handleDeleteDialog,
        disabled: false,
      },
    ];

  const renderMenuBarProps = {
    ReduxDispatcher,
    user,
    allData,
    chartName,
    setChartName,
    menuBarSplitButtonOptions,
    standaloneChart,
    isEdit,
    onTimeFiltersChange: handleTimeFiltersChange,
  };

  // DEBUGGING
  // console.groupCollapsed("PlotCreate.js");
  // console.log("standaloneChart", standaloneChart);
  // console.groupEnd();

  const isAnyDimFiltersApplied = standaloneChart.dimensionFilters.length > 0;
  const TEMP_CSS = { height: "25px" };
  const menuCss = { padding: "15px 22px 10px 22px" };
  var standaloneChartWrapperCss = { height: "calc(100% - 80px)" };
  if (isAnyDimFiltersApplied)
    standaloneChartWrapperCss = { height: "calc(100% - 110px)" };
  const breadcrumbData = getBreadcrumbsDataFromRoute(user, history);

  const additionalSidenavItems = [
    {
      id: "home",
      name: "Home",
      tooltip: "Go back to Datastory home",
      elementId: "dsBackToHome",
      iconId: "custom-home-icon",
      customClass: "sidenav-home",
      onClick: (user) => handleGoHome(user),
      isVisible: true,
    },
    {
      id: "filters",
      name: "Filters",
      tooltip: "Open filters",
      elementId: "filtersInSidenav",
      iconId: "custom-filter-icon",
      customClass: "sidenav-filters",
      onClick: (user) => handleAddFilters(),
      isVisible: true,
    },
  ];
  const sidenavItemsOrderInfo = {
    home: 1,
    filters: 2,
    reports: 3,
    alerts: 4,
  };
  const tabName = "charts-create"
  const sidenavProps = { additionalSidenavItems, sidenavItemsOrderInfo, tabName };

  return (
    <>
      <LayoutTopSideBottom sidenavProps={sidenavProps}>
        <SigviewBreadcrumb
          data={breadcrumbData}
          onClick={handleBreadcrumbChange}
        />
        <Box css={menuCss}>
          <Box css={TEMP_CSS}>
            <PlotMenuBar {...renderMenuBarProps} />
          </Box>
          {isAnyDimFiltersApplied ? (
            <DimensionFilters
              initialDimensionFilters={standaloneChart.dimensionFilters}
              initialTimeFilters={standaloneChart.timeFilters}
              onDimensionFiltersChange={handleDimensionFiltersChange}
              // settings={dimensionFiltersSettings}
            />
          ) : (
            <></> // ADDING THIS BECAUSE LAYOUT NEEDS SOMETHING IN THAT INDEX
          )}
        </Box>
        <Box css={standaloneChartWrapperCss}>
          <StandaloneChart
            initialState={standaloneChart}
            onChange={handleStandaloneChartChange}
            updateParentState={true}
          />
        </Box>
      </LayoutTopSideBottom>
      {/* <SigviewCommon /> */}
    </>
  );
}

ChartsCreate.propTypes = {
  location: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  currTab: state.user.screen.activeTab,
  allData: state.data,
  globalFilters: state.globalFilters,
  standaloneWs: state.standaloneWs,
});

export default connect(mapStateToProps)(ChartsCreate);
