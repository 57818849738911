// * Import required libraries
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SigviewBanner from "../SigviewBanner";
import { updateUserBannerStatus } from "../../redux/actions";
import { masterTrackGaEvent } from "../../services/ga";

// * Define styles
const midPanelStyle = {
  height: "100%",
  width: "50%",
  boxSizing: "border-box",
};

function BannerDisplay(props) {
  const { dispatch: ReduxDispatcher, user, allData } = props;
  const handleCloseBanner = () => {
    const payload = {
      status: false,
      originalMessage: allData.banner.message,
    };
    ReduxDispatcher(updateUserBannerStatus(payload));
    masterTrackGaEvent({
      category: "Menubar",
      action: "BannerNotificationHide",
      label: "1",
    });
  };

  return (
    <>
      {allData.banner.status &&
        (user.bannerStatus.status ||
          user.bannerStatus.originalMessage !== allData.banner.message) && (
          <div className={`mid-panel`} style={midPanelStyle}>
            <SigviewBanner
              title={allData.banner.message}
              handleCloseBanner={handleCloseBanner}
            />
          </div>
        )}
    </>
  );
}

BannerDisplay.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  changeFlag: state.changeFlag,
});

export default connect(mapStateToProps)(BannerDisplay);
