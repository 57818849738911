// ! IMPORTANT NOTES
// Caution:
// 1. This is a fast fix to solve website performance
// 2. This may not adhere to our kit guidelines

// * Import 3rd party
import { makeStyles } from "@material-ui/core";

// * Import kit
import SigviewTooltip from "./SigviewTooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: (style) => style.padding || "0px",
    cursor: (style) => style.cursor || "initial",
    pointerEvents: (style) => style.border || "initial",
    border: (style) =>
      style.border || "0px solid" + theme.palette.misc["secondaryColorLighter"],
    borderRadius: (style) => style.borderRadius || "0px solid",
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: (style) => style?.fontSize || "14px",
    fontWeight: (style) => style.fontWeight || "initial",
    color: (style) =>
      style.color || theme.palette.misc["secondaryColorLighter"],
    "&:hover": {
      color: (style) =>
        style.hoverColor || theme.palette.misc["secondaryColor"],
    },
  },
  disabledIcon: {
    pointerEvents: "none",
    opacity: 0.5,
  },
}));

function SigviewIcon(props) {
  const {
    className = "material-icons",
    iconName = "home",
    style = {},
    disabled = false,
    onClick = () => {},
    tooltipProps = {},
  } = props;
  const {
    flag: tooltipFlag = false,
    title: tooltipTitle = "",
    placement: tooltipPlacement = "bottom",
  } = tooltipProps;

  const classes = useStyles(style);
  const { root, icon, disabledIcon } = classes;
  const rootClassName =
    disabled === true ? `${disabledIcon} ${root}` : `${root}`;

  return (
    <>
      {tooltipFlag && (
        <SigviewTooltip title={tooltipTitle} placement={tooltipPlacement}>
          <span
            className={rootClassName}
            onClick={disabled ? () => {} : onClick}
          >
            <span className={`${icon} ${className}`}>{iconName}</span>
          </span>
        </SigviewTooltip>
      )}
      {!tooltipFlag && (
        <span className={rootClassName} onClick={disabled ? () => {} : onClick}>
          <span className={`${icon} ${className}`}>{iconName}</span>
        </span>
      )}
    </>
  );
}

export default SigviewIcon;
