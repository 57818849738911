// * Import required libraries
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router";
import axios from "axios";
import i18n from "./i18n";

// * Import custom components
import AppError from "./screens/AppError/AppError";
import AppLoader from "./screens/AppLoader/AppLoader";
import SigviewCommon from "./screens/SigviewCommon/SigviewCommon";

// * Import reducers & action creators
// Reducers
import timeFiltersReducer from "./redux/reducers/timeFilters";
// Actiom creators
import {
  updateData,
  updateAuth,
  updateAuthError,
  logout,
  updateUserMetadata,
  updatePlotlyMetricsDimensionsList,
  updateUserScreen,
  emptyAllData,
  updateUserApiEndpoints,
  updateUserType,
  replaceTimeFilters,
  updateTimeFiltersWithDefaultDates,
  updateVersion,
  updateGlobalFiltersTimeFiltersWithDefaultValue,
} from "./redux/actions";
import { redirectToSignInPage } from "./utils/authUtils";
// * Import utils, config & static data
import { config } from "./config/config";
import allDatePresets from "../assets/data/allDatePresets.json";
import allTimezones from "../assets/data/allTimezones.json";
import {
  getSigviewUserType,
  getBaseUrlEndpoints,
  checkAppError,
  getEmbedInfo,
} from "./utils/utils";
import { initialFeatureAccessList } from "./redux/stateData";

// * Import API functions
import {
  getUser,
  getMetricList,
  getDimensionList,
  getCustomMetricList,
  getDateRange,
  getUserAccessList,
  getUserEmbedded,
  getFeatureInfo,
  refreshToken,
  get,
} from "./services/api";

// * Import styling files
// Main styles
import "./App.scss";
import "./AppTheme.scss";

// 3rd party library styles
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import "react-toastify/dist/ReactToastify.css";

// * APP LOGIC HIERARCHY
// 1) Whenever this component mounts, update isDashboardLoading to true and setPageLoading to false
//    because we want that other useEffects to NOT run until isDashboardLoading is false
// 2) Update auth after fetching it from local storage based on user type
// 3) Set authResolved local state to true if auth is resolved from local storage
// 4) If auth is resolved and page is not loading, fetch /getUser;
// 5) Once userMetadata is resolved, fetch /getUserAccessList, /getMetric, /getDimension, /getCustomMetri, /getDateRange
// 6) Once we get all metrics and dimensions, we update plotlyMetrics and dimensions
// 7) Once everything is resolved, we set isDashboardLoading to false
// 8) Some additional useEffects have been put in place like show/hide filters/time bar

function App(props) {
  // * Destructure props
  const { dispatch: ReduxDispatcher, user, changeFlag, allData } = props;

  // * Define required hooks
  // * Define contexts
  const history = useHistory();
  const location = useLocation();

  // * Define required states
  const [authResolved, setAuthResolved] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [userMetadataResolved, setUserMetadataResolved] = useState(false);

  // * Define required side effects
  //Update isDashboarLoading to true everytime this component mounts
  useEffect(() => {
    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    setPageLoading(false);
  }, []);

  //Update redux with auth present in local storage
  useEffect(() => {
    const runFlag = !authResolved && user.auth.status !== "success";
    if (runFlag) {
      //Identify if the application is sigview or non-sigview
      const sigviewUserType = getSigviewUserType();
      if (sigviewUserType === "sigview") {
        const { xAuthToken } = getEmbedInfo(user);
        let authData = {
          status: "success",
          message: "",
          data: {
            "X-Auth-Token": xAuthToken,
            instances: "",
          },
        };
        let newApiEndPoints = getBaseUrlEndpoints();
        let newUserType = { newUserType: sigviewUserType };
        ReduxDispatcher(updateAuth(authData));
        ReduxDispatcher(updateUserApiEndpoints({ newApiEndPoints }));
        ReduxDispatcher(updateUserType({ newUserType }));
      } else {
        const openxTokenLocalObject = JSON.parse(
          localStorage.getItem("openxUserData")
        );

        const openxToken = openxTokenLocalObject[0]?.token;
        const openxUserType = openxTokenLocalObject[0]?.userType;
        const instances = openxTokenLocalObject[0]?.instances;
        const xAuthToken = localStorage.getItem("satellizer_token");
        const openxEmail = localStorage.getItem("user_email");
        let authData = {
          status: "success",
          message: "",
          data: {
            "openx-email": openxEmail,
            "openx-token": openxToken,
            "X-Auth-Token": xAuthToken,
            "openx-userType": openxUserType,
            instances: instances,
          },
        };
        let newApiEndPoints = getBaseUrlEndpoints();
        let newUserType = { newUserType: sigviewUserType };
        ReduxDispatcher(updateAuth(authData));
        ReduxDispatcher(updateUserApiEndpoints({ newApiEndPoints }));
        ReduxDispatcher(updateUserType({ newUserType }));
      }
    }
  }, []);

  //Set authResolved to true if auth is resolved from local storage
  useEffect(() => {
    if (!authResolved) {
      if (
        user.auth.status === "success" &&
        user.apiEndpoints.baseUrl.length &&
        user.apiEndpoints.baseUrlOld.length
      ) {
        setAuthResolved(true);
      }
    }
  }, [user.auth, user.apiEndpoints]);

  //Fetch Version (SAME AS METADATA)
  useEffect(() => {
    const runFlag = authResolved && !pageLoading;
    if (runFlag) {
      var getPromise = get({});
      getPromise
        .then((responseData) => {
          if (responseData.status.statusCode === "200") {
            const payload = {
              status: "success",
              message: "",
              data: responseData,
            };
            ReduxDispatcher(updateVersion("version", payload));
          }
        })
        .catch(() => {
          console.error("GET API CALL FAILED FROM APP.JS");
          const payload = {
            status: "error",
            message: "",
            data: {},
          };
          ReduxDispatcher(updateVersion("version", payload));
        });
    }
  }, [authResolved, pageLoading]);

  //Fetch user metadata
  useEffect(() => {
    const runFlag = authResolved && !pageLoading;
    if (runFlag) {
      //Based on if the application is embedded, API calls will differ
      //This is decided based on the presence of sigviewApp.embedInfo in local storage
      const { embeddedFlag, clientId } = getEmbedInfo(user);
      if (embeddedFlag) {
        //Make fetch call using axios
        var fetchProps = {
          url: `${user.apiEndpoints.baseUrl}${config.apiEndpoints.getUserEmbedded}/${clientId}`,
          headers: user.auth,
        };
        var getUserEmbeddedPromise = getUserEmbedded(fetchProps);
        getUserEmbeddedPromise
          .then((responseData) => {
            const sigviewUserType = getSigviewUserType();
            var newUserMetadataData = {};
            if (sigviewUserType === "sigview") {
              // Standardization not happening for certain APIs which are exposed to the client
              // Doc Link: https://docs.google.com/spreadsheets/d/1J-3hpvuLsl4u2Umbp5eGMNpl6FcRcYLXgEZ7Zare3qQ/edit#gid=1972283651
              newUserMetadataData = responseData;
            } else {
              // New App Server for OpenX
              newUserMetadataData = responseData;
            }
            // * NEW IMPLEMENTATION
            const newUserMetadata = {
              status: "success",
              message: "",
              data: newUserMetadataData,
            };
            //Empty allData as the data depends on view selected by user and the view has been changed
            ReduxDispatcher(emptyAllData());
            ReduxDispatcher(updateUserMetadata(newUserMetadata));
            setUserMetadataResolved(true);
            // ! OLD IMPLEMENTATION (ANGULAR REACT HYBRID)
            // //Get activeOrg
            // const activeOrg = activeOrgObject.name;
            // //Get active view from active org object
            // const activeView = getActiveViewObjFromViewsArr(
            //   activeOrgObject.viewInfoList
            // );
            // //If user navigates from angular to react
            // //And activeOrg and the view is same, don't reload the entire data
            // if (
            //   user?.reqMetadata?.view === activeView.name &&
            //   user?.metadata?.data?.activeOrg === activeOrg
            // ) {
            //   //Update dashboard loading to false
            //   ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
            //   setUserMetadataResolved(true);
            // } else {
            //   const newUserMetadata = {
            //     status: "success",
            //     message: "",
            //     data: responseData,
            //   };
            //   //Empty allData as the data depends on view selected by user and the view has been changed
            //   ReduxDispatcher(emptyAllData());
            //   ReduxDispatcher(updateUserMetadata(newUserMetadata));
            //   setUserMetadataResolved(true);
            // }
          })
          .catch((error) => {
            //Update dashboard loading to false
            updateUserScreen("isDashboardLoading", false);
          });
      } else {
        //Make fetch call using axios
        const getUserPromise = getUser({});
        getUserPromise
          .then((responseData) => {
            // * NEW IMPLEMENTATION (post standardization)
            const sigviewUserType = getSigviewUserType();
            const newUserMetadata = {
              status: "success",
              message: "",
              data:
                sigviewUserType === "sigview"
                  ? responseData
                  : responseData.result.data,
            };

            // This is to cater the User Language we are fetching the user language form the getUser api
            const userPreferredLanguage =
              sigviewUserType === "sigview"
                ? "en"
                : responseData.result.data.userPreferredLanguage;
            i18n.changeLanguage(userPreferredLanguage);

            //Empty allData as the data depends on view selected by user and the view has been changed
            ReduxDispatcher(emptyAllData());
            ReduxDispatcher(updateUserMetadata(newUserMetadata));
            setUserMetadataResolved(true);

            // ! OLD IMPLEMENTATION (before standardization)
            // const sigviewUserType = getSigviewUserType();
            // //Get activeOrg
            // var activeOrg;
            // var activeOrgObject;
            // if (sigviewUserType === "sigview") {
            //   activeOrg = responseData.result.data.activeOrg;
            //   activeOrgObject = getActiveOrgObject(responseData.result.data);
            // } else {
            //   // New App Server for OpenX
            //   activeOrg = responseData.result.data.activeOrg;
            //   activeOrgObject = getActiveOrgObject(responseData.result.data);
            // }
            // // * NEW IMPLEMENTATION
            // const newUserMetadata = {
            //   status: "success",
            //   message: "",
            //   data:
            //     sigviewUserType === "sigview"
            //       ? responseData.result.data
            //       : responseData.result.data,
            // };
            // //Empty allData as the data depends on view selected by user and the view has been changed
            // ReduxDispatcher(emptyAllData());
            // ReduxDispatcher(updateUserMetadata(newUserMetadata));
            // setUserMetadataResolved(true);

            // ! OLD IMPLEMENTATION (ANGULAR REACT HYBRID)
            //If user navigates from angular to react
            //And activeOrg and the view is same, don't reload the entire data
            // if (
            //   user?.reqMetadata?.view === activeView.name &&
            //   user?.metadata?.data?.activeOrg === activeOrg
            // ) {
            //   //Update dashboard loading to false
            //   ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
            //   setUserMetadataResolved(true);
            // } else {
            // const newUserMetadata = {
            //   status: "success",
            //   message: "",
            //   data:
            //     sigviewUserType === "sigview"
            //       ? responseData
            //       : responseData.result.data,
            // };
            // //Empty allData as the data depends on view selected by user and the view has been changed
            // ReduxDispatcher(emptyAllData());
            // ReduxDispatcher(updateUserMetadata(newUserMetadata));
            // setUserMetadataResolved(true);
            // }
          })
          .catch((error) => {
            console.groupCollapsed("UI ERROR");
            console.log("ERROR -> ", error);
            console.groupEnd();
            const newUserMetadata = {
              status: "error",
              message: config.messages.getUserFailed,
              data: {},
            };
            ReduxDispatcher(updateUserMetadata(newUserMetadata));
            //Update dashboard loading to false
            ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
          });
      }
    }

    return () => {
      //Update dashboard loading to true
      ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    };
  }, [authResolved, changeFlag.view, pageLoading]);

  //Fetch featureAccessList
  useEffect(() => {
    const runFlag =
      authResolved &&
      user.metadata.status === "success" &&
      user.screen.isDashboardLoading &&
      user.reqMetadata &&
      allData.featureAccessList.status !== "success" &&
      userMetadataResolved &&
      !pageLoading;
    if (runFlag) {
      ReduxDispatcher(
        updateData("featureAccessList", initialFeatureAccessList)
      );
      //Make fetch call using axios
      const getUserAccessListPromise = getUserAccessList({});
      getUserAccessListPromise
        .then((responseDate) => {
          const payload = {
            status: "success",
            message: "",
            data: responseDate?.result?.data || {
              accessList: [],
              limitsList: [],
              paramsList: [],
            },
          };
          ReduxDispatcher(updateData("featureAccessList", payload));
        })
        .catch((error) => {
          console.groupCollapsed("UI ERROR");
          console.log("ERROR -> ", error);
          console.groupEnd();
          const payload = {
            ...initialFeatureAccessList,
            status: "error",
            message: config.messages.getUserAccessListFailed,
          };
          ReduxDispatcher(updateData("featureAccessList", payload));
        });
    }
  }, [
    authResolved,
    user.metadata,
    user.reqMetadata,
    user.apiEndpoints,
    userMetadataResolved,
  ]);

  //Get Metrics List
  useEffect(() => {
    const runFlag =
      authResolved &&
      user.screen.isDashboardLoading &&
      user.reqMetadata &&
      allData.metricList.status !== "success" &&
      userMetadataResolved &&
      !pageLoading;
    if (runFlag) {
      //Make fetch call using axios
      const getMetricListPromise = getMetricList({});
      getMetricListPromise
        .then((responseDate) => {
          const sigviewUserType = getSigviewUserType();
          const newData = {
            status: "success",
            message: "",
            data:
              sigviewUserType === "sigview"
                ? responseDate.result.metricList
                : responseDate.result.data,
          };
          ReduxDispatcher(updateData("metricList", newData));
        })
        .catch((error) => {
          console.groupCollapsed("UI ERROR");
          console.log("ERROR -> ", error);
          console.groupEnd();
          const newData = {
            status: "error",
            message: config.messages.getMetricsListFailed,
            data: {},
          };
          ReduxDispatcher(updateData("metricList", newData));
        });
    }
  }, [authResolved, user.reqMetadata, pageLoading, userMetadataResolved]);

  //Get Dimension List
  useEffect(() => {
    const runFlag =
      authResolved &&
      user.screen.isDashboardLoading &&
      user.reqMetadata &&
      allData.dimensionList.status !== "success" &&
      userMetadataResolved &&
      !pageLoading;
    if (runFlag) {
      //Make fetch call using axios
      const getDimensionListPromise = getDimensionList({});
      getDimensionListPromise
        .then((responseData) => {
          const sigviewUserType = getSigviewUserType();
          const newData = {
            status: "success",
            message: "",
            data:
              sigviewUserType === "sigview"
                ? responseData.result.dimensionList
                : responseData.result.data,
          };
          ReduxDispatcher(updateData("dimensionList", newData));
        })
        .catch((error) => {
          console.groupCollapsed("UI ERROR");
          console.log("ERROR -> ", error);
          console.groupEnd();
          const newData = {
            status: "error",
            message: config.messages.getDimensionsListFailed,
            data: {},
          };
          ReduxDispatcher(updateData("dimensionList", newData));
        });
    }
  }, [authResolved, user.reqMetadata, pageLoading, userMetadataResolved]);

  //Get CustomMetric List
  useEffect(() => {
    const runFlag =
      authResolved &&
      user.screen.isDashboardLoading &&
      user.reqMetadata &&
      allData.customMetricList.status !== "success" &&
      userMetadataResolved &&
      !pageLoading;
    if (runFlag) {
      //Make fetch call using axios
      const getCustomMetricListPromise = getCustomMetricList({});
      getCustomMetricListPromise
        .then((responseData) => {
          const sigviewUserType = getSigviewUserType();
          const newData = {
            status: "success",
            message: "",
            data:
              sigviewUserType === "sigview"
                ? responseData.result.customMetricList
                : responseData.result.data,
          };
          ReduxDispatcher(updateData("customMetricList", newData));
        })
        .catch((error) => {
          console.groupCollapsed("UI ERROR");
          console.log("ERROR -> ", error);
          console.groupEnd();
          const newData = {
            status: "error",
            message: config.messages.getCustomMetricsListFailed,
            data: {},
          };
          ReduxDispatcher(updateData("customMetricList", newData));
        });
    }
  }, [authResolved, user.reqMetadata, pageLoading, userMetadataResolved]);

  //Get Date Range
  useEffect(() => {
    const runFlag =
      authResolved &&
      user.screen.isDashboardLoading &&
      user.reqMetadata &&
      allData.dateRange.status !== "success" &&
      userMetadataResolved &&
      !pageLoading;
    if (runFlag) {
      //Make fetch call using axios
      const getDateRangePromise = getDateRange({});
      getDateRangePromise
        .then((responseDate) => {
          const newData = {
            status: "success",
            message: "",
            data: responseDate.result,
          };
          ReduxDispatcher(updateData("dateRange", newData));
        })
        .catch((error) => {
          console.groupCollapsed("UI ERROR");
          console.log("ERROR -> ", error);
          console.groupEnd();
          const newData = {
            status: "error",
            message: config.messages.getDateRangeFailed,
            data: {},
          };
          ReduxDispatcher(updateData("dateRange", newData));
        });
    }
  }, [authResolved, user.reqMetadata, pageLoading, userMetadataResolved]);

  //Get Date Range
  useEffect(() => {
    //Make fetch call using axios
    const getLatesetDaterange = () => {
      const getDateRangePromise = getDateRange({});
      getDateRangePromise
        .then((responseData) => {
          const newData = {
            status: "success",
            message: "",
            data: responseData.result,
          };
          ReduxDispatcher(updateData("dateRangeLatest", newData));
        })
        .catch((error) => {
          console.groupCollapsed("UI ERROR");
          console.log("ERROR -> ", error);
          console.groupEnd();
          const newData = {
            status: "error",
            message: config.messages.getDateRangeFailed,
            data: {},
          };
          ReduxDispatcher(updateData("dateRangeLatest", newData));
        });
    };
    const intervalID = setInterval(
      getLatesetDaterange,
      config.hardCoded.getDateRangeRelaodInterval
    );
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  //Transform dimensions and metric lists for plotly when dim and metric api are complete
  useEffect(() => {
    if (
      allData.dimensionList.status === "success" &&
      allData.metricList.status === "success" &&
      allData.customMetricList.status === "success" &&
      !allData.plotlyMetrics &&
      !allData.plotlyDimensions &&
      !pageLoading
    ) {
      ReduxDispatcher(updatePlotlyMetricsDimensionsList());
    }
  }, [
    allData.dimensionList,
    allData.metricList,
    allData.customMetricList,
    allData.plotlyMetrics,
    allData.plotlyDimensions,
  ]);

  //Update time filters if dateRange is resolved
  useEffect(() => {
    const runFlag =
      allData.dateRange.status === "success" &&
      !pageLoading &&
      user.metadata.status === "success" &&
      allData.featureAccessList.status === "success";
    if (runFlag) {
      // * Initialize calendar with last_2_days (which is last_2_valid available dates to cater to DemoTest)
      const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarDashboard;
      const timeFiltersAction = updateTimeFiltersWithDefaultDates({
        selectedTimezone: allTimezones[1],
        startDateEpoch: allData.dateRange.data.startDate,
        endDateEpoch: allData.dateRange.data.endDate,
        allDatePresets,
        format: config.hardCoded.datetimeFormat,
        selectedDatePreset: "last_2_days",
        daysLimit: calendarDaysLimits,
        isComparisonOn: false,
      });
      const newTimeFilters = timeFiltersReducer({}, timeFiltersAction);
      const action = replaceTimeFilters(newTimeFilters);
      ReduxDispatcher(action);

      //* update GlobalFilter with new time filters
      let payload = { value: newTimeFilters };
      let actionTimeFilter =
        updateGlobalFiltersTimeFiltersWithDefaultValue(payload);
      ReduxDispatcher(actionTimeFilter);
    }
  }, [allData.dateRange, allData.featureAccessList, pageLoading]);

  //Update user screen loading if all required data has been fetched
  useEffect(() => {
    const runFlag =
      user.screen.isDashboardLoading && !pageLoading && userMetadataResolved;
    if (runFlag) {
      if (
        allData.metricList.status === "success" &&
        allData.customMetricList.status === "success" &&
        allData.dimensionList.status === "success" &&
        allData.dateRange.status === "success" &&
        user.reqMetadata &&
        allData.plotlyMetrics &&
        allData.plotlyDimensions &&
        allData.featureAccessList.status === "success"
      ) {
        ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
      }
    }
  }, [
    allData.metricList,
    allData.customMetricList,
    allData.dimensionList,
    allData.dateRange,
    user.reqMetadata,
    allData.plotlyMetrics,
    allData.plotlyDimensions,
    allData.featureAccessList.status,
    pageLoading,
    userMetadataResolved,
  ]);

  // useEffect for the  banner api
  useEffect(() => {
    const sigviewUserType = getSigviewUserType();
    const runFlag =
      authResolved &&
      user.reqMetadata &&
      userMetadataResolved &&
      sigviewUserType === "sigview";

    var source = axios.CancelToken.source();
    const bannerApi = () => {
      const fetchProps = { cancelToken: source.token };
      const getFeatureInfoPromise = getFeatureInfo(fetchProps);
      getFeatureInfoPromise
        .then((data) => {
          if (data.status.statusCode === "400") {
            console.error("Bad Request in bannerApi");
          }
          if (data.status.statusCode === "200") {
            let title =
              data.result.data.paramsList.find(
                (el) => el.paramName === "bannerNotification"
              )?.paramValue || "";
            const payload = {
              status: title !== "" && title !== undefined ? true : false,
              message: title,
            };
            ReduxDispatcher(updateData("banner", payload));
          }
        })
        .catch((json) => {
          const payload = {
            status: false,
            message: "API failed",
          };
          ReduxDispatcher(updateData("banner", payload));
        });
    };
    let intervalId;
    if (runFlag) {
      //Make fetch call using axios
      bannerApi();

      intervalId = setInterval(() => {
        bannerApi();
      }, config.hardCoded.bannerNotificationInetrval);
    }
    return () => {
      //Cancel prev fetch
      clearInterval(intervalId);
      if (source) source.cancel();
    };
  }, [authResolved, user.reqMetadata, pageLoading, userMetadataResolved]);

  // CODE ADDED TO POLL OPENX SSO EVERY 50 MINUTES TO CHECK IF SECURITY FILTERS OF A USER ARE CHANGED
  useEffect(() => {
    const sigviewUserType = getSigviewUserType();
    if (sigviewUserType === "nonSigview") {
      const runFlag = authResolved && user.reqMetadata && userMetadataResolved;
      const openxTokenLocalObject = JSON.parse(
        localStorage.getItem("openxUserData")
      );
      var source = axios.CancelToken.source();
      var token = openxTokenLocalObject[0]["token"];

      const CheckFiltersInRegularIntervals = () => {
        const fetchProps = {
          headers: {
            token: token,
          },
        };
        // if usertype is not OAA
        if (openxTokenLocalObject[0]["userType"] !== "OAA") {
          const refreshTokenPromise = refreshToken(fetchProps);
          refreshTokenPromise
            .then((data) => {
              if (data.status.statusCode == "500") {
                // Logout user
                ReduxDispatcher(logout());

                const payload = {
                  status: "error",
                  isTokenValid: false,
                };
                const action = updateAuthError(payload);
                ReduxDispatcher(action);
                //history.push("/openxSignIn");
                redirectToSignInPage({ history, location });
                // location.path("/openxSignIn");
                // localStorage.removeItem("openxUserData");
                // localStorage.removeItem("satellizer_token");
                // localStorage.removeItem("user_email");
              }
            })
            .catch((json) => {
              console.log("Could not fetch userinfo", json);
            });
        }
      };
      let intervalId;
      if (runFlag) {
        //Make fetch call using axios
        // Commenting this because we need to call after 50 minutes
        // CheckFiltersInRegularIntervals();

        intervalId = setInterval(() => {
          CheckFiltersInRegularIntervals();
        }, config.hardCoded.CheckFiltersNotificationIntervals);
      }
      return () => {
        //Cancel prev fetch
        clearInterval(intervalId);
        if (source) source.cancel();
      };
    }
  }, [authResolved, user.reqMetadata, pageLoading, userMetadataResolved]);

  // //DEBUGGING
  // useEffect(() => {
  //   console.groupCollapsed("APP.js");
  //   console.log("isUserAuthenticated", isUserAuthenticated);
  //   console.log("ERROR", { errorFlag, errorMessage });
  //   console.groupEnd();
  // });

  // * Define required variables
  // Check if the url that the user is on is valid
  // If not, show error message
  const checkAppErrorProps = {
    mandatoryApiListData: [
      user.metadata,
      allData.featureAccessList,
      allData.dateRange,
      allData.metricList,
      allData.dimensionList,
      allData.customMetricList,
    ],
    location,
    uiFeatureList: user.uiFeatureList,
  };
  const { errorFlag, errorMessage } = checkAppError(checkAppErrorProps);

  return (
    <>
      {errorFlag && (
        <>
          <AppError
            errorHandlerProps={{
              message: errorMessage,
              reloadFlag: true,
              onReload: () => {
                window.location.reload();
              },
            }}
          />
          <SigviewCommon />
        </>
      )}
      {!errorFlag && (
        <>
          <AppLoader />
          <SigviewCommon />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(App);
