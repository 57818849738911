// * Import required libraies
import React, {
  useEffect,
  useState,
  useReducer,
  useRef,
  useContext,
} from "react";
import { connect } from "react-redux";
import axios from "axios";
import isEqual from "lodash.isequal";
import GridLayout, { WidthProvider } from "react-grid-layout";
import { v4 } from "uuid";
import { orderBy } from "lodash";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

// * Import 3rd party lib comp
import { Box } from "@material-ui/core";

// * Import Custom Component
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import {
  SigviewDialogClone,
  DsSettingsIcon,
} from "./DatastoryDashboardHelpers";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import DsWidgetItem from "../../../components/DsWidgetItem";
import DimensionFilters from "../../../components/DimensionFilters";
import ChartPopover from "../../../components/ChartPopover";
import { KpisPopover } from "../DatastoryCreate/DatastoryCreateHelpers";
import SigviewButton from "../../../components/Common/SigviewButton";
import TimeFilters from "../../../components/TimeFilters/TimeFilters";
import SigviewButtonSplit from "../../../components/Common/SigviewButtonSplit";
import SigviewTextFieldAsync from "../../../components/Common/SigviewTextFieldAsync";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SigviewIcon from "../../../components/Common/SigviewIcon";
import SigviewTooltip from "../../../components/Common/SigviewTooltip";

// * Import actions
import {
  updateUserScreen,
  replaceStandaloneDsForm,
  updateStandaloneDsForm,
  updateDialogInfo,
  updateStandaloneDsFormChartData,
  updateStandaloneDsFormWidget,
  removeDsWidget,
  replaceStandaloneDsFormWidget,
  reloadDsChartData,
  updateStandaloneDsFormMetricsData,
  updateStandaloneDsFormMultipleKpis,
  updateStandaloneDsFormMultipleCharts,
  updateTimeFiltersWithDefaultDates,
  replaceAllDimensionFilters,
  updateGlobalFiltersDimensionFilters,
  updateGlobalFiltersTimeFilters,
  updateGlobalFiltersFormAnalyzeFiltersReadOnly,
} from "../../../redux/actions";
import dimensionFiltersReducer from "../../../redux/reducers/dimensionFilters";
import timeFiltersReducer from "../../../redux/reducers/timeFilters";
import allDatePresets from "../../../../assets/data/allDatePresets.json";
import allTimezones from "../../../../assets/data/allTimezones.json";

// * Import data/utils
import { config } from "../../../config/config";
import {
  unwrapperDatastory,
  wrapperDatastory,
  getInitialDsForm,
  updateGridLayout,
  makeMetricsDataConsistent,
  isAnyWidgetPresent,
  formatTimeFilters,
  getAllDataStatus,
  isDatastoryValid,
} from "../../../utils/dsUtils";
import {
  unwrapperChartObjectForMetrics,
  validateChartName,
} from "../../../utils/chartObjectUtils";
import useReducerLogger from "../../../utils/useReducerLogger";
import standaloneDsReducer from "../../../redux/reducers/standaloneDs";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { masterTrackGaEvent } from "../../../services/ga";
import staticChartJson from "../../../../assets/data/chartTitle.json";
import {
  getBreadcrumbsDataFromRoute,
  makeAttributeMissingErrorMessageMaster,
} from "../../../utils/utils";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Import API functions
import {
  getDatastoryById,
  getData,
  deleteDs,
  updateDs,
  renameDs,
} from "../../../services/api";

// * Define required variables
const initialState = { status: "loading", message: "", result: {} };
const defaultQeData = {
  status: "loading",
  message: "",
  result: { dataFromQE: [], extraData: {} },
};
const ResponsiveGridLayout = WidthProvider(GridLayout);

//Hardcoded/config
let rowHeight = config.hardCoded.datastoryConfig.rowHeight;
let margin = config.hardCoded.datastoryConfig.margin;

function DatastoryDashboard(props) {
  const { t } = useTranslation();
  // * Destructure props
  const {
    dispatch: ReduxDispatcher,
    user,
    allData,
    globalFilters = {},
  } = props;
  const {
    globalFiltersFileUpload = false,
    globalFiltersStringMatch = false,
    globalFiltersTimestamp = false,
  } = user.uiFeatureList;
  // * Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const history = useHistory();

  // * Define required state
  // Metrics Data (backend)
  // Active DS Object (backend)
  const [ds, setDs] = useState(initialState);
  const [dsReload, setDsReload] = useState(initialState);
  // DS FORM (UI)
  const getInitialDsFormProps = { user, allData, title: "", defaultFlag: true };
  const initialDsForm = getInitialDsForm(getInitialDsFormProps);
  const [dsForm, dispatchDsForm] = useReducer(
    useReducerLogger(standaloneDsReducer),
    initialDsForm
  );
  const dsFormOriginal = useRef(initialDsForm);
  // DS Name (UI)
  const [chartName, setChartName] = useState({
    status: "success",
    value: user.screen.activeDatastory.title,
    originalValue: user.screen.activeDatastory.title,
    message: "",
  });

  // * Define required variables
  const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarDashboard;
  const activeDatastory = user.screen.activeDatastory;
  const isEdit = dsForm?.type?.value === "edit";
  const gaCategory = isEdit ? "EditDatastory" : "CreateDatastory";
  const isSampleDs = activeDatastory.dsCategory.toLowerCase() === "sample";
  const reloadEpochMetric = dsForm.reloadEpochs.value["metrics"];

  // * Define required side effects

  // Empty activeDatastory whenever this component unmounts
  // useEffect(() => {
  //   return () => {
  //     // Change tab to home page
  //     const value = {
  //       activeDsCategory: "saved",
  //       activeDatastory: {},
  //     };
  //     var action = updateUserScreen(null, value);
  //     ReduxDispatcher(action);
  //   };
  // }, []);

  useEffect(() => {
    return () => {
      // Revert to inital state in redux when component unmounts
      // This is to cater to case when user switches between tabs
      ReduxDispatcher(updateGlobalFiltersFormAnalyzeFiltersReadOnly());
    };
  }, []);

  useUpdateEffect(() => {
    if (dsForm.dimensionFilterType.value === "widget") {
      let payload = { value: [] };
      let action = updateGlobalFiltersDimensionFilters(payload);
      ReduxDispatcher(action);
    } else if (dsForm.dimensionFilterType.value === "dashboard") {
      let payload = { value: dsForm.dimensionFilters.value };
      let action = updateGlobalFiltersDimensionFilters(payload);
      ReduxDispatcher(action);
    }
  }, [dsForm.dimensionFilterType.value, ds]);

  useUpdateEffect(() => {
    if (dsForm.timeFilterType.value === "widget") {
      const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarDashboard;
      const timeFiltersAction = updateTimeFiltersWithDefaultDates({
        selectedTimezone: allTimezones[1],
        startDateEpoch: allData.dateRange.data.startDate,
        endDateEpoch: allData.dateRange.data.endDate,
        allDatePresets,
        format: config.hardCoded.datetimeFormat,
        selectedDatePreset: "last_2_days",
        daysLimit: calendarDaysLimits,
        isComparisonOn: false,
      });
      const defaultTimeFilters = timeFiltersReducer({}, timeFiltersAction);
      let payload = { value: defaultTimeFilters };
      var action = updateGlobalFiltersTimeFilters(payload);
      ReduxDispatcher(action);
    } else if (dsForm.timeFilterType.value === "dashboard") {
      let payload = { value: dsForm.timeFilters.value };
      var action = updateGlobalFiltersTimeFilters(payload);
      ReduxDispatcher(action);
    }
  }, [dsForm.timeFilterType.value, ds]);

  // Fetch ds data from API
  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();
    if (activeDatastory) {
      const urlEndpoint =
        config.apiEndpoints[`datastory${activeDatastory.dsCategory}ById`];
      const fetchProps = {
        url: `${user.apiEndpoints.baseUrl}${urlEndpoint}/${activeDatastory._id}`,
        cancelToken: source.token,
      };
      const getDatastoryByIdPromise = getDatastoryById(fetchProps);
      getDatastoryByIdPromise
        .then((responseData) => {
          let result = responseData?.result.data;
          let newData = { status: "success", message: "", result };

          const unwrapperDatastoryProps = {
            backendDs: result,
            user,
            allData,
            isLayoutEditableFlag: !isSampleDs,
          };

          const isDatastoryValidResponse = isDatastoryValid(
            unwrapperDatastoryProps
          );

          if (isDatastoryValidResponse.status === "valid") {
            // * IMPORTANT
            // 1) First update the dsForm
            // 2) Update original ref
            // 3) Async update ds so that it happens at the last (call stack | callback queue)

            // 1) First update the dsForm
            const newDsForm = unwrapperDatastory(unwrapperDatastoryProps);
            var payload = { value: newDsForm };
            //** Backward Compatibility Code Start Here**//
            if (
              !globalFiltersStringMatch &&
              !globalFiltersFileUpload &&
              !globalFiltersTimestamp &&
              payload.value.dimensionFilters.value.length > 0
            ) {
              var validityFlags = payload.value.dimensionFilters.value.map(
                (row) => {
                  const newRow = {
                    ...row,
                    advancedFilters: row.advancedFilters.filter(
                      (item) =>
                        ![
                          "startsWith",
                          "exactlyMatches",
                          "endsWith",
                          "containsString",
                          "containsWholeWord",
                          "from",
                          "on",
                          "between",
                          "before",
                        ].includes(item.type)
                    ),
                  };
                  return newRow;
                }
              );
            } else if (
              globalFiltersStringMatch &&
              !globalFiltersFileUpload &&
              !globalFiltersTimestamp &&
              payload.value.dimensionFilters.value.length > 0
            ) {
              var validityFlags = payload.value.dimensionFilters.value.map(
                (row) => {
                  const newRow = {
                    ...row,
                    advancedFilters: row.advancedFilters.filter(
                      (item) =>
                        ![
                          "exactlyMatches",
                          "from",
                          "on",
                          "between",
                          "before",
                        ].includes(item.type)
                    ),
                  };
                  return newRow;
                }
              );
            } else if (
              !globalFiltersStringMatch &&
              globalFiltersFileUpload &&
              !globalFiltersTimestamp &&
              payload.value.dimensionFilters.value.length > 0
            ) {
              var validityFlags = payload.value.dimensionFilters.value.map(
                (row) => {
                  const newRow = {
                    ...row,
                    advancedFilters: row.advancedFilters.filter(
                      (item) =>
                        ![
                          "startsWith",
                          "endsWith",
                          "containsString",
                          "containsWholeWord",
                          "from",
                          "on",
                          "between",
                          "before",
                        ].includes(item.type)
                    ),
                  };
                  return newRow;
                }
              );
            } else if (
              !globalFiltersStringMatch &&
              !globalFiltersFileUpload &&
              globalFiltersTimestamp &&
              payload.value.dimensionFilters.value.length > 0
            ) {
              var validityFlags = payload.value.dimensionFilters.value.map(
                (row) => {
                  const newRow = {
                    ...row,
                    advancedFilters: row.advancedFilters.filter(
                      (item) =>
                        ![
                          "startsWith",
                          "endsWith",
                          "containsString",
                          "containsWholeWord",
                          "exactlyMatches",
                        ].includes(item.type)
                    ),
                  };
                  return newRow;
                }
              );
            } else if (
              globalFiltersStringMatch &&
              globalFiltersFileUpload &&
              !globalFiltersTimestamp &&
              payload.value.dimensionFilters.value.length > 0
            ) {
              var validityFlags = payload.value.dimensionFilters.value.map(
                (row) => {
                  const newRow = {
                    ...row,
                    advancedFilters: row.advancedFilters.filter(
                      (item) =>
                        !["from", "on", "between", "before"].includes(item.type)
                    ),
                  };
                  return newRow;
                }
              );
            } else if (
              globalFiltersFileUpload &&
              !globalFiltersStringMatch &&
              globalFiltersTimestamp &&
              payload.value.dimensionFilters.value.length > 0
            ) {
              var validityFlags = payload.value.dimensionFilters.value.map(
                (row) => {
                  const newRow = {
                    ...row,
                    advancedFilters: row.advancedFilters.filter(
                      (item) =>
                        ![
                          "startsWith",
                          "endsWith",
                          "containsString",
                          "containsWholeWord",
                        ].includes(item.type)
                    ),
                  };
                  return newRow;
                }
              );
            } else if (
              !globalFiltersFileUpload &&
              globalFiltersStringMatch &&
              globalFiltersTimestamp &&
              payload.value.dimensionFilters.value.length > 0
            ) {
              var validityFlags = payload.value.dimensionFilters.value.map(
                (row) => {
                  const newRow = {
                    ...row,
                    advancedFilters: row.advancedFilters.filter(
                      (item) => !["exactlyMatches"].includes(item.type)
                    ),
                  };
                  return newRow;
                }
              );
            }
            if (validityFlags) {
              var finalValidateFilterArray = validityFlags.filter((row) => {
                if (
                  (row.values.length !== 0 &&
                    row.advancedFilters.length === 0) ||
                  (row.values.length === 0 &&
                    row.advancedFilters.length !== 0) ||
                  (row.values.length !== 0 && row.advancedFilters.length !== 0)
                ) {
                  return row;
                }
              });

              var payload = {
                ...payload,
                value: {
                  ...payload.value,
                  dimensionFilters: {
                    ...payload.value.dimensionFilters,
                    value: finalValidateFilterArray,
                  },
                },
              };
            }
            var action = replaceStandaloneDsForm(payload);
            dispatchDsForm(action);
            //** Backward Compatibility Code Ends Here **//

            // 2) Update original ref
            dsFormOriginal.current = { ...newDsForm };

            // 3) Async update ds so that it happens at the last (call stack | callback queue)

            setDs(newData);
          } else {
            let message = makeAttributeMissingErrorMessageMaster(
              isDatastoryValidResponse
            );
            let newData = {
              status: "accessError",
              message,
              result: {},
            };
            setDs(newData);
          }
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            const message = json.error || config.messages.uiErrorMessage;
            let newData = {
              status: "error",
              message,
              result: {},
            };
            setDs(newData);
            console.groupCollapsed("UI ERROR");
            console.log("JSON", json);
            console.log("ERROR ->", message);
            console.groupEnd();
          }
        });
    }
    return () => {
      //Cancel prev fetch
      if (source) source.cancel();
      //Revert to loading state
      setDs(initialState);
    };
  }, [dsReload]);

  // Fetch metrics data from API (all at once to optimize the number of fetch calls)
  // Only when filters are at dashboard level
  useEffect(() => {
    // Make fetch call using axios
    const source = axios.CancelToken.source();
    // This will run only when both timeFilterType and dimensionFilterType are equal to dashboard
    // Otherwise, metric level /getData calls will be made
    // ! This function should run only when there's a chn
    const runFlag =
      ds.status === "success" &&
      Object.keys(dsForm.selectedKpis.value).length > 0;
    if (runFlag) {
      // * If filter type changes from both dashboard to one widget
      // * We need to reload the metrics data which is taken care in the else case
      const fetchDataAtDashboardLevelRunFlag =
        dsForm.dimensionFilterType.value === "dashboard" &&
        dsForm.timeFilterType.value === "dashboard";
      if (fetchDataAtDashboardLevelRunFlag) {
        // Update dsForm.dataQE to show loader
        var payload = {
          metricsDataFromQE: {},
          metricsDataFromQEStatus: "loading",
        };
        var action = updateStandaloneDsFormMetricsData(payload);
        dispatchDsForm(action);

        try {
          const unwrapperChartObjectForMetricsProps = {
            dsForm,
            user,
            allData,
            timeFilters: dsForm.timeFilters.value,
            dimensionFilters: dsForm.dimensionFilters.value,
          };
          const payload = unwrapperChartObjectForMetrics(
            unwrapperChartObjectForMetricsProps
          );
          //Make fetch call using axios
          const fetchProps = {
            payload,
            cancelToken: source.token,
          };
          const getDataPromise = getData(fetchProps);
          getDataPromise
            .then((responseData) => {
              const { response, status } = makeMetricsDataConsistent(
                responseData?.result?.data || [],
                allData.plotlyMetrics
              );
              // Update dsForm
              let payload = {
                metricsDataFromQE: response,
                metricsDataFromQEStatus: status,
              };
              let action = updateStandaloneDsFormMetricsData(payload);
              dispatchDsForm(action);
            })
            .catch((json) => {
              if (json.error !== config.hardCoded.queryCancelled) {
                console.error("API FAILED");
                console.groupCollapsed("DETAILS");
                console.log(json);
                console.groupEnd();
                // Update dsForm.dataQE to show error
                var payload = {
                  metricsDataFromQE: {},
                  metricsDataFromQEStatus: "error",
                };
                var action = updateStandaloneDsFormMetricsData(payload);
                dispatchDsForm(action);
              }
            });
        } catch (err) {
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log(err);
          console.groupEnd();
          // Update dsForm.dataQE to show error
          var payload = {
            metricsDataFromQE: {},
            metricsDataFromQEStatus: "error",
          };
          var action = updateStandaloneDsFormMetricsData(payload);
          dispatchDsForm(action);
        }
      } else {
        // Update dsForm.dataQE to show loader
        var payload = {
          metricsDataFromQE: {},
          metricsDataFromQEStatus: "loading",
        };
        var action = updateStandaloneDsFormMetricsData(payload);
        dispatchDsForm(action);
      }
    }
    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
    };
  }, [
    ds,
    dsForm.timeFilters.value,
    dsForm.dimensionFilters.value,
    dsForm.timeFilterType.value,
    dsForm.dimensionFilterType.value,
    // dsForm.selectedKpis.value, // ! Triggering an unnecessary reload when a metric is deleted
    reloadEpochMetric,
  ]);

  //Everytime dsForm everytime activeDatastory changes
  useUpdateEffect(() => {
    // Update DS Form
    const newDsForm = unwrapperDatastory({
      backendDs: user.screen.activeDatastory,
      user,
      allData,
      isLayoutEditableFlag: !isSampleDs,
    });
    var payload = { value: newDsForm };
    var action = replaceStandaloneDsForm(payload);
    dispatchDsForm(action);

    // Update original ref
    dsFormOriginal.current = newDsForm;

    // Update Chart Name
    const newTitle = user.screen.activeDatastory.title;
    setChartName((prevState) => ({
      ...prevState,
      value: newTitle,
      originalValue: newTitle,
    }));
  }, [user.screen.activeDatastory]);

  //Update dsForm.name in the state everytime chartName (parent state) changes
  //Do this only when chartName.originalValue changes because chartName.value is
  //real-time state and chartName.originalValue refers to the renamed state
  useEffect(() => {
    // Update state only when they are different
    if (dsForm.datastoryName.value !== chartName.originalValue) {
      handleDsFormChange("datastoryName", chartName.originalValue);
      // Update ref so that update button doesn't get enabled
      dsFormOriginal.current = {
        ...dsFormOriginal.current,
        datastoryName: {
          ...dsFormOriginal.current.datastoryName,
          value: chartName.originalValue,
        },
      };
    }
  }, [chartName.originalValue]);

  // * Define required event handlers
  const trackCrudGA = (label) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryDashboard",
      action: "CRUD",
      label,
    });
  };

  const handleDsFormChange = (key, value) => {
    const payload = { key, value };
    const action = updateStandaloneDsForm(payload);
    dispatchDsForm(action);
  };

  const handleClone = () => {
    trackCrudGA("Clone");
    const initialName = `${config.hardCoded.cloneOfPreText} ${dsForm.datastoryName.value}`;
    const payload = {
      open: true,
      children: (
        <SigviewDialogClone
          ReduxDispatcher={ReduxDispatcher}
          initialName={initialName}
          user={user}
          dsForm={dsForm}
          isEdit={isEdit}
        />
      ),
    };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleDsDelete = () => {
    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    const fetchProps = {
      url: `${user.apiEndpoints.baseUrl}${config.apiEndpoints.deleteDs}/${dsForm.id.value}`,
    };
    const deleteDsPromise = deleteDs(fetchProps);
    deleteDsPromise
      .then(() => {
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Datastory deleted successfully!",
        };
        // Update snackbar
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        // Remove dashboard loader
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);
        history.push("/datastory/saved");
        // Change tab to home page
        const value = {
          activeTab: "category",
          activeNav: "datastory",
          activeDsCategory: "saved",
          activeDatastory: {},
        };
        var action = updateUserScreen(null, value);
        ReduxDispatcher(action);
        history.push("/datastory/saved");

        // // Empty Filters
        // // Update global dimension filters
        // var payload = { newDimensionFilters: [] };
        // var action = replaceAllDimensionFilters(payload);
        // ReduxDispatcher(action);
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Deleting datastory failed",
        };
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);
      });
  };

  const handleDeleteDialog = () => {
    trackCrudGA("Delete");
    const message = {
      title: "Are you sure you want to delete?",
      subtitle: "This action cannot be undone",
    };
    const handleNo = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
        })
      );
      trackCrudGA("DeleteNo");
    };
    const handleYes = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
          message: { title: "", subtitle: "" },
        })
      );
      handleDsDelete();
      trackCrudGA("DeleteYes");
    };
    ReduxDispatcher(
      updateDialogInfo({
        ...user.dialogInfo,
        open: true,
        message,
        handleYes,
        handleNo,
      })
    );
  };

  const handleEditDialog = () => {
    trackCrudGA("Bulk Edit");
    // Do this only when unsaved changes are present
    const hasFormChanged = !isEqual(dsFormOriginal.current, dsForm);
    if (hasFormChanged) {
      const message = {
        title: "Discard unsaved changes?",
        subtitle: "This action cannot be undone",
      };
      const handleNo = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
          })
        );
        trackCrudGA("EditNo");
      };
      const handleYes = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
            message: { title: "", subtitle: "" },
          })
        );
        handleEdit("dontSave");
        trackCrudGA("EditYes");
        history.push("/datastory/dashboard/bulk-edit");
      };
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: true,
          message,
          handleYes,
          handleNo,
        })
      );
    } else {
      handleEdit("dontSave");
    }
  };

  const handleEdit = (userOption) => {
    // // Remove all dimension filters
    // var payload = {
    //   newDimensionFilters: [],
    // };
    // ReduxDispatcher(replaceAllDimensionFilters(payload));

    if (userOption === "save") {
      var activeDatastory = wrapperDatastory({ uiDs: dsForm, user });
      activeDatastory = { ...activeDatastory, elementType: "edit" };
      var value = {
        activeNav: "datastory",
        activeTab: "create",
        activeDatastory,
      };
      var action = updateUserScreen(null, value);
      ReduxDispatcher(action);
      history.push("/datastory/dashboard/create");
    } else {
      var activeDatastory = wrapperDatastory({
        uiDs: dsFormOriginal.current,
        user,
      });
      activeDatastory = { ...activeDatastory, elementType: "edit" };
      var value = {
        activeNav: "datastory",
        activeTab: "create",
        activeDatastory,
      };
      var action = updateUserScreen(null, value);
      ReduxDispatcher(action);
      history.push("/datastory/dashboard/create");
    }
  };

  const handleRearrange = () => {
    trackCrudGA("Edit");
    handleDsFormChange("layoutEditable", true);
  };

  const handleCancel = () => {
    trackCrudGA("DiscardChanges");

    const payload = {
      value: {
        ...dsFormOriginal.current,
        // dataQE: dsForm.dataQE, // ! This creates a bug
        layoutEditable: {
          ...dsFormOriginal.current.layoutEditable,
          value: false,
        },
        gridLayout: {
          ...dsFormOriginal.current.gridLayout,
          value: dsFormOriginal.current.gridLayout.value.map((row) => ({
            ...row,
            static: true,
          })),
        },
      },
    };
    const action = replaceStandaloneDsForm(payload);
    dispatchDsForm(action);

    handleWidgetChartReload(null, "metrics");
  };

  const handleUpdate = () => {
    trackCrudGA("Update");
    let backendDs = wrapperDatastory({ uiDs: dsForm, user });
    const fetchProps = {
      payload: { ...backendDs },
    };
    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    const updateDsPromise = updateDs(fetchProps);
    updateDsPromise
      .then(() => {
        handleDsFormChange("layoutEditable", false);

        // ! dsFormOriginal.current = { ...dsForm }; //If we do this, dsForm has static flag as true which makes the update button enabled
        // * Hence, we will first get the nextState from the reducer and then set the current value
        var payload = { key: "layoutEditable", value: false };
        var action = updateStandaloneDsForm(payload);
        var nextState = standaloneDsReducer(dsForm, action);
        dsFormOriginal.current = { ...nextState };

        // Update snackbar
        const snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Datastory updated successfully!",
        };
        var payload = { isDashboardLoading: false, snackbar: snackbarPayload };
        var action = updateUserScreen(null, payload);
        ReduxDispatcher(action);
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        const message = json.error || "Updating datastory failed!";
        // Update snackbar
        const snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: message,
        };
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
        ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
      });
  };

  const handleLayoutChange = (layout) => {
    const value = updateGridLayout(dsForm.gridLayout.value, layout);
    handleDsFormChange("gridLayout", value);
  };

  const handleChartDataChange = (event, widgetItemId, widgetItemValue) => {
    let payload = { widgetItemId, widgetItemValue };
    let action = updateStandaloneDsFormChartData(payload);
    dispatchDsForm(action);
  };

  const handleDimensionFiltersChange = (value) => {
    handleDsFormChange("dimensionFilters", value);
    const payload = { key: "dimensionFilters", value };
    const action = updateGlobalFiltersDimensionFilters(payload);
    ReduxDispatcher(action);
  };

  const handleTimeFiltersChange = (value) => {
    handleDsFormChange("timeFilters", value);
    const payload = { value };
    const action = updateGlobalFiltersTimeFilters(payload);
    ReduxDispatcher(action);
  };

  const handleWidgetChange = (
    widgetItemId,
    key,
    value,
    widgetItemType = "chart"
  ) => {
    const payload = { widgetItemId, key, value, widgetItemType };
    const action = updateStandaloneDsFormWidget(payload);
    dispatchDsForm(action);
  };

  const onDSWidgetDelete = (event, widgetItemId) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryDashboard",
      action: "Widget",
      label: "DeleteWidget",
    });
    let payload = { widgetItemId };
    let action = removeDsWidget(payload);
    dispatchDsForm(action);
  };

  const onDSWidgetDeleteDialog = (event, widgetItemId) => {
    const message = {
      title: "Are you sure you want to delete?",
      subtitle: "",
    };
    const handleNo = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
        })
      );
    };
    const handleYes = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
          message: { title: "", subtitle: "" },
        })
      );
      onDSWidgetDelete(event, widgetItemId);
    };
    ReduxDispatcher(
      updateDialogInfo({
        ...user.dialogInfo,
        open: true,
        message,
        handleYes,
        handleNo,
      })
    );
  };

  const handleWidgetChartReload = (event, widgetItemId) => {
    const payload = { widgetItemId };
    const action = reloadDsChartData(payload);
    dispatchDsForm(action);
  };

  const handleEditChart = (e, widgetItemId) => {
    console.log("here", widgetItemId);
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryDashboard",
      action: "Widget",
      label: "EditChart",
    });

    const handleClose = () => {
      // Close the dialog
      const value = {
        open: false,
        title: "",
        onClose: () => {},
        children: null,
      };
      var action = updateUserScreen("sigviewBigDialog", value);
      ReduxDispatcher(action);
    };

    const handleCloseDialog = (type = "showDialog") => {
      //Type will decide if we need to show the dialog or not
      // justClose -> Don't show dialog
      // showDialog -> Show dialog
      if (type === "justClose") {
        handleClose();
      } else {
        const message = {
          title: "Are you sure you want to cancel?",
          subtitle: "This action cannot be undone",
        };
        const handleNo = () => {
          //Close the dialog box
          ReduxDispatcher(
            updateDialogInfo({
              ...user.dialogInfo,
              open: false,
            })
          );
        };
        const handleYes = () => {
          //Close the dialog box
          ReduxDispatcher(
            updateDialogInfo({
              ...user.dialogInfo,
              open: false,
              message: { title: "", subtitle: "" },
            })
          );
          handleClose();
        };
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: true,
            message,
            handleYes,
            handleNo,
          })
        );
      }
    };

    const handleDsWidgetChartUpdate = (props = {}) => {
      const { uiPayload = {} } = props;
      const {
        standaloneChart: modifiedStandaloneChart = {},
        chartData: finalChartData = defaultQeData,
      } = uiPayload;

      // Since the choose to update the card:
      // 1. Reset dataQE for this chart to loading (NOT DOING: handled by 3)
      // 2. Update the selections
      // 3. Reload the charts

      // * UPDATE: We can now use handleWidgetChartReload because we have one data component for plotly and chart now
      // Q) Why can't we just call handleWidgetChartReload?
      // A) It will work when all same data component for plotly and table.
      //    Currently, these differ, hence it doesn't work when chart type changes from chart to table.
      //    The useUpdateEffect doesn't run because it's the first mount for table and it receives data as success

      // 1. Reset dataQE for this chart to loading
      // var widgetItemValue = { ...defaultQeData };
      // if (finalChartData.status === "success")
      //   widgetItemValue = { ...finalChartData };
      // var payload = { widgetItemId, widgetItemValue };
      // var action = updateStandaloneDsFormChartData(payload);
      // dispatchDsForm(action);

      // 2. Update the selections (Replace dsForm.selectedCharts)
      var payload = { widgetItemId, value: modifiedStandaloneChart };
      var action = replaceStandaloneDsFormWidget(payload);
      dispatchDsForm(action);

      // Close the popover
      handleClose();

      // Re-fetch charts data
      handleWidgetChartReload(null, widgetItemId);
    };

    const chartPopoverProps = {
      // ! Need to remove it as it creates a bug
      // ! BUG: If user changes filters in the dashboard, the loaded data will go to the chart which has chart level filters
      // initialChartData: dsForm.dataQE.value[widgetItemId],
      initialStandaloneChartData: {
        ...dsForm.selectedCharts.value[widgetItemId],
        renderFlag: true,
      },
      eventHandlers: {
        onYes: handleDsWidgetChartUpdate,
        onNo: handleCloseDialog,
      },
      settings: { yesTitle: "Save & Continue", noTitle: "Discard" },
    };

    // Update activeChart in redux
    const sigviewBigDialogVal = {
      open: true,
      props: {
        title: "Edit Chart",
        onClose: handleCloseDialog,
        children: <ChartPopover {...chartPopoverProps} />,
      },
    };
    var value = {
      sigviewBigDialog: sigviewBigDialogVal,
    };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);
  };

  const handleAddNewChart = (e, widgetItemId) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryDashboard",
      action: "MenuBar",
      label: "AddChart",
    });

    const handleClose = () => {
      // Close the dialog
      const value = {
        open: false,
        title: "",
        onClose: () => {},
        children: null,
      };
      var action = updateUserScreen("sigviewBigDialog", value);
      ReduxDispatcher(action);
    };

    const handleCloseDialog = (type = "showDialog") => {
      //Type will decide if we need to show the dialog or not
      // justClose -> Don't show dialog
      // showDialog -> Show dialog
      if (type === "justClose") {
        handleClose();
      } else {
        const message = {
          title: "Are you sure you want to cancel?",
          subtitle: "This action cannot be undone",
        };
        const handleNo = () => {
          //Close the dialog box
          ReduxDispatcher(
            updateDialogInfo({
              ...user.dialogInfo,
              open: false,
            })
          );
        };
        const handleYes = () => {
          //Close the dialog box
          ReduxDispatcher(
            updateDialogInfo({
              ...user.dialogInfo,
              open: false,
              message: { title: "", subtitle: "" },
            })
          );
          handleClose();
        };
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: true,
            message,
            handleYes,
            handleNo,
          })
        );
      }
    };

    const handleDsWidgetChartAdd = (props = {}) => {
      const { uiPayload = {} } = props;
      const {
        standaloneChart: modifiedStandaloneChart = {},
        chartData: finalChartData = defaultQeData,
      } = uiPayload;

      // Since the choose to update the card:
      // 1. Reset dataQE for this chart to loading (NOT DOING: handled by 3)
      // 2. Update the selections
      // 3. Reload the charts

      // * UPDATE: We can now use handleWidgetChartReload because we have one data component for plotly and chart now
      // Q) Why can't we just call handleWidgetChartReload?
      // A) It will work when all same data component for plotly and table.
      //    Currently, these differ, hence it doesn't work when chart type changes from chart to table.
      //    The useUpdateEffect doesn't run because it's the first mount for table and it receives data as success

      // 1. Reset dataQE for this chart to loading
      // var widgetItemValue = { ...defaultQeData };
      // if (finalChartData.status === "success")
      //   widgetItemValue = { ...finalChartData };
      // var payload = { widgetItemId, widgetItemValue };
      // var action = updateStandaloneDsFormChartData(payload);
      // dispatchDsForm(action);

      // 2. Update the selections (Replace dsForm.selectedCharts)

      const modifiedCharts = [
        ...Object.values(dsForm.selectedCharts.value),
        modifiedStandaloneChart,
      ];

      // * This reducer case is using allData and utility functions which might throw an error
      // * Hence this catch block
      try {
        const payload = { modifiedCharts, allData };
        const action = updateStandaloneDsFormMultipleCharts(payload);
        dispatchDsForm(action);
      } catch (error) {
        console.error("UI ERROR");
        console.groupCollapsed("DETAILS");
        console.log(error);
        console.groupEnd();
      }

      // Close the popover
      handleClose();
    };
    let newTitle = `Untitled Chart - ${
      Object.keys(dsForm.selectedCharts.value).length + 1
    }`;
    let initialStandaloneChartData = {
      id: `chart-object-${v4()}`,
      title: newTitle,
      dimensionsList: [],
      metricsList: [],
      orderById: "",
      orderBy: "desc",
      chartType: "bar",
      metricFilters: [],
      dimensionFilters: [],
      timeFilters: dsForm.timeFilters.value,
      chartList: staticChartJson,
      elementType: "create", // create or update to help differentiate in PlotCreate,
      renderFlag: false, // to control rendering of the chart in the component
    };

    const chartPopoverProps = {
      initialStandaloneChartData,
      eventHandlers: {
        onYes: handleDsWidgetChartAdd,
        onNo: handleCloseDialog,
      },
      settings: { yesTitle: "Add & Close", noTitle: "Discard" },
    };

    // Update activeChart in redux
    const sigviewBigDialogVal = {
      open: true,
      props: {
        title: "Edit Chart",
        onClose: handleCloseDialog,
        children: <ChartPopover {...chartPopoverProps} />,
      },
    };
    var value = {
      sigviewBigDialog: sigviewBigDialogVal,
    };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);
  };

  const handleEditKpis = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryDashboard",
      action: "MenuBar",
      label: "EditKpis",
    });

    const handleClose = () => {
      // Close the dialog
      const value = {
        open: false,
        title: "",
        onClose: () => {},
        children: null,
      };
      var action = updateUserScreen("sigviewBigDialog", value);
      ReduxDispatcher(action);
    };

    const handleCloseDialog = (type = "showDialog") => {
      //Type will decide if we need to show the dialog or not
      // justClose -> Don't show dialog
      // showDialog -> Show dialog
      if (type === "justClose") {
        handleClose();
      } else {
        const message = {
          title: "Are you sure you want to cancel?",
          subtitle: "This action cannot be undone",
        };
        const handleNo = () => {
          //Close the dialog box
          ReduxDispatcher(
            updateDialogInfo({
              ...user.dialogInfo,
              open: false,
            })
          );
        };
        const handleYes = () => {
          //Close the dialog box
          ReduxDispatcher(
            updateDialogInfo({
              ...user.dialogInfo,
              open: false,
              message: { title: "", subtitle: "" },
            })
          );
          handleClose();
        };
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: true,
            message,
            handleYes,
            handleNo,
          })
        );
      }
    };

    const handleSave = (modifiedKpis) => {
      // * This reducer case is using allData and utility functions which might throw an error
      // * Hence this catch block
      try {
        // Update dsForm.selectedKpis
        const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarDashboard;
        const timeFiltersAction = updateTimeFiltersWithDefaultDates({
          selectedTimezone: allTimezones[1],
          startDateEpoch: allData.dateRange.data.startDate,
          endDateEpoch: allData.dateRange.data.endDate,
          allDatePresets,
          format: config.hardCoded.datetimeFormat,
          selectedDatePreset: "last_2_days",
          daysLimit: calendarDaysLimits,
          isComparisonOn: false,
        });
        const defaultTimeFilters = timeFiltersReducer({}, timeFiltersAction);
        const payload = { modifiedKpis, allData, defaultTimeFilters };
        const action = updateStandaloneDsFormMultipleKpis(payload);
        dispatchDsForm(action);

        // Update reload epochs to trigger reload
        handleWidgetChartReload(null, "metrics");

        // Close the dialog
        handleClose();
      } catch (error) {
        console.error("UI ERROR");
        console.groupCollapsed("DETAILS");
        console.log(error);
        console.groupEnd();
      }
    };

    const plotlyMetricsMod = allData.plotlyMetrics.map((row) => ({
      id: row._id,
      name: row._title,
      disabled: false,
      checked: false,
    }));
    // Order By Data
    const orderedArray = orderBy(plotlyMetricsMod, "name");

    // Update activeChart in redux
    const sigviewBigDialogVal = {
      open: true,
      props: {
        title: "Edit KPIs",
        onClose: handleCloseDialog,
        children: (
          <KpisPopover
            user={user}
            allData={allData}
            ReduxDispatcher={ReduxDispatcher}
            initialData={orderedArray}
            initialSelections={Object.keys(dsForm.selectedKpis.value)}
            onSave={handleSave}
            settings={{
              maxLimit: config.hardCoded.kpisMaxLimitInDs,
              isOnSaveVisible: true,
            }}
          />
        ),
      },
    };
    var value = {
      sigviewBigDialog: sigviewBigDialogVal,
    };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);
  };

  const handleBreadcrumbChange = (event, value) => {
    // TODO: Find a better way to handle this
    var activeNav = "datastory";
    var activeTab = "category";
    var activeDsCategory = "saved";
    if (value === "home") activeTab = "home";
    const data = { activeNav, activeTab, activeDsCategory };
    const action = updateUserScreen(null, data);
    ReduxDispatcher(action);
    history.push(value.path);
  };

  const handleChartNameChange = (event, value) => {
    setChartName((prevState) => ({ ...prevState, value }));
  };

  const handleClickAway = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryDashboard",
      action: "Rename",
      label: "DS",
    });
    const activeItem = { ...dsForm };
    // Making required active variables
    //If entered chartName is invalid
    const { status, message } = validateChartName(chartName.value);
    if (status === "invalid") {
      setChartName((prevState) => ({
        ...prevState,
        status: "success",
        value: prevState.originalValue,
      }));
      let snackbarPayload = {
        ...user.screen.snackbar,
        open: true,
        message: message,
      };
      const action = updateUserScreen("snackbar", snackbarPayload);
      ReduxDispatcher(action);
    } else {
      // Do this only when the names are different otherwise
      // it will run for every click on the screen
      if (chartName.originalValue !== chartName.value) {
        // Make an API call only when the element type is update
        if (activeItem.type.value === "edit") {
          setChartName((prevState) => ({ ...prevState, status: "loading" }));
          const fetchPayload = {
            id: activeItem.id.value,
            title: chartName.value,
            emailId: user.reqMetadata.email,
            orgViewReq: {
              organization: user?.reqMetadata?.organization,
              view: user?.reqMetadata?.view,
            },
          };
          const fetchProps = {
            payload: fetchPayload,
          };
          const renameDsPromise = renameDs(fetchProps);
          renameDsPromise
            .then(() => {
              setChartName((prevState) => ({
                ...prevState,
                status: "success",
                originalValue: prevState.value,
              }));
              handleDsFormChange("datastoryName", chartName.value);
              // Commenting it out as it's distracting
              // let snackbarPayload = {
              //   ...user.screen.snackbar,
              //   open: true,
              //   message: "Chart renamed successfully!",
              // };
              // const action = updateUserScreen("snackbar", snackbarPayload);
              // ReduxDispatcher(action);
            })
            .catch((json) => {
              console.groupCollapsed("API FAILED");
              console.log("Error JSON -> ", json);
              console.groupEnd();
              setChartName((prevState) => ({
                ...prevState,
                status: "success",
                value: prevState.originalValue,
              }));
              let snackbarPayload = {
                ...user.screen.snackbar,
                open: true,
                message: json.error || "Rename Failed",
              };
              const action = updateUserScreen("snackbar", snackbarPayload);
              ReduxDispatcher(action);
            });
        } else {
          // Don't make the API call if the element type is not edit
          setChartName((prevState) => ({
            ...prevState,
            status: "success",
            originalValue: prevState.value,
          }));
        }
      }
    }
  };
  const handleDownloadAsPDF = (event, dsForm, enableDownloadAsPDF) => {
    // * Wrapping it in in setTimeout as it was breaking the downloaded report CSS
    if (enableDownloadAsPDF) {
      let snackbarPayload = {
        ...user.screen.snackbar,
        open: true,
        message: "Download is in progress...",
      };
      const action = updateUserScreen("snackbar", snackbarPayload);
      ReduxDispatcher(action);

      const htmlElementId = "dsDashboard";
      const dsName = dsForm.datastoryName.value;
      const fileName = `${dsName}_${new Date().valueOf()}`;
      const formattedDateRange = formatTimeFilters(dsForm.timeFilters.value);
      const dsDateRangeText = formattedDateRange.replace("in", "for Timezone");
      const isAnyDimensionFiltersApplied =
        dsForm.dimensionFilters.value.length > 0;
      const isTimeFiltersAtWidget = dsForm.timeFilterType.value === "widget";
      const isDimFiltersAtWidget =
        dsForm.dimensionFilterType.value === "widget";
      var filtersText = "No filters applied";
      if (isTimeFiltersAtWidget || isDimFiltersAtWidget)
        filtersText = "This datastory has widget level filters applied";
      if (isAnyDimensionFiltersApplied)
        filtersText = "This datastory has advanced filters applied";
      convertSVG(htmlElementId);
      function convertSVG(id) {
        let dsDashboardCloned = document.getElementById(id).cloneNode(true);

        // Creating header and its children
        const header = document.createElement("div").cloneNode(true);
        const dsNameTag = document.createElement("p").cloneNode(true);
        const dsReportIcon = document.createElement("div").cloneNode(true);
        const dsNameText = document.createTextNode(dsName).cloneNode(true);

        // Creating subheader and its children
        const subHeader = document.createElement("div").cloneNode(true);
        const dsFilterDescriptionTag = document
          .createElement("em")
          .cloneNode(true);
        const dsFilterDescriptionText = document
          .createTextNode(filtersText)
          .cloneNode(true);
        const dsDateRangeTag1 = document.createElement("p").cloneNode(true);
        const dsDateRangeTag2 = document
          .createElement("strong")
          .cloneNode(true);
        const dsDateRangeText1 = document
          .createTextNode("Date Range for the Report is ")
          .cloneNode(true);
        const dsDateRangeText2 = document
          .createTextNode(`${dsDateRangeText}`)
          .cloneNode(true);

        // Cloning footer element
        const footer = document
          .getElementById("sigview-footer")
          .cloneNode(true);

        // Adding classes to created elements
        dsNameTag.classList.add("ds-header-name");
        dsReportIcon.classList.add("ds-header-report-icon");
        header.classList.add("ds-download-as-pdf-header");
        subHeader.classList.add("ds-download-as-pdf-subheader");
        dsFilterDescriptionTag.classList.add("ds-subheader-filter-description");
        dsDateRangeTag2.classList.add("ds-download-as-pdf-date-range");

        // Appending children to thein respective parent elements
        dsNameTag.appendChild(dsNameText);
        header.appendChild(dsNameTag);
        header.appendChild(dsReportIcon);
        dsFilterDescriptionTag.appendChild(dsFilterDescriptionText);
        if (!isTimeFiltersAtWidget) {
          dsDateRangeTag2.appendChild(dsDateRangeText2);
          dsDateRangeTag1.appendChild(dsDateRangeText1);
          dsDateRangeTag1.appendChild(dsDateRangeTag2);
        }

        subHeader.appendChild(dsFilterDescriptionTag);
        subHeader.appendChild(dsDateRangeTag1);

        dsDashboardCloned.appendChild(footer);

        // Making a placeholder div
        const placeholderDiv = document.getElementById(
          "placeholderDivToDownloadPdf"
        );

        // Hiding placeholder element from the user's screen
        placeholderDiv.style.zIndex = -1;
        placeholderDiv.style.position = "fixed";
        placeholderDiv.style.top = "0px";
        placeholderDiv.style.bottom = "0px";
        placeholderDiv.style.left = "0px";
        placeholderDiv.style.width = "calc(100vw - 50px)";
        placeholderDiv.style.height = "max-content";

        // Appending children to placeholderDiv
        placeholderDiv.appendChild(header);
        placeholderDiv.appendChild(subHeader);
        placeholderDiv.appendChild(dsDashboardCloned);

        htmlToImage
          .toPng(placeholderDiv)
          .then((dataUrl) => {
            placeholderDiv.innerHTML = "";
            var img = new Image();
            img.src = dataUrl;
            img.onload = function () {
              let orientation = "p";
              if (img.height < img.width) orientation = "l";
              var doc = new jsPDF({
                orientation: `${orientation}`,
                unit: "pt",
                format: [img.width, img.height],
              });
              var width = doc.internal.pageSize.getWidth();
              var height = (img.height * width) / img.width;
              doc.addImage(img, "PNG", 0, 0, width, height);
              doc.save(fileName);
            };
          })
          .catch((error) => {
            console.error(
              "Error occurred while downloading datastory as pdf!",
              error
            );
            const snackbarPayload = {
              ...user.screen.snackbar,
              open: true,
              message: "Error occurred while downloading datastory as pdf!",
            };
            const action = updateUserScreen("snackbar", snackbarPayload);
            ReduxDispatcher(action);
          });
      }
    }
  };

  // * Define required static variables
  const isAnyWidgetPresentFlag = isAnyWidgetPresent(ds, dsForm);
  let menuBarSplitButtonOptions = [];
  // const hasFormChanged = !isEqualWithoutCertainKeys(
  //   dsFormOriginal.current,
  //   dsForm,
  //   "gridLayout"
  // );
  const isEditableOn = dsForm.layoutEditable.value;
  const isTimeFiltersEditable = isSampleDs ? true : isEditableOn;
  const isDimFiltersEditable = isSampleDs ? true : isEditableOn;
  const hasFormChanged = !isEqual(dsFormOriginal.current, dsForm);
  const isUpdateDisabled = ds.status !== "success" || !hasFormChanged;
  const isEditDisabled = ds.status !== "success";
  const isCloneDisabled = ds.status !== "success";
  const isRearrangeDisabled = ds.status !== "success";
  const isDeleteDisabled = ds.status !== "success";
  if (isSampleDs)
    menuBarSplitButtonOptions.push({
      id: "addToSaved",
      label: "Clone",
      onClick: handleClone,
      disabled: isCloneDisabled,
    });
  if (!isSampleDs && !isEditableOn)
    menuBarSplitButtonOptions = [
      {
        id: "edit",
        label: "Edit",
        onClick: handleRearrange,
        disabled: false,
      },
      {
        id: "clone",
        label: "Clone",
        onClick: handleClone,
        disabled: isCloneDisabled,
      },
      {
        id: "delete",
        label: "Delete",
        onClick: handleDeleteDialog,
        disabled: isDeleteDisabled,
      },
      {
        id: "bulkEdit",
        label: "Bulk Edit",
        onClick: handleEditDialog,
        disabled: isEditDisabled,
      },
    ];
  if (!isSampleDs && isEditableOn)
    menuBarSplitButtonOptions = [
      {
        id: "save",
        label: "Save",
        onClick: handleUpdate,
        disabled: false,
      },
      {
        id: "cancel",
        label: "Discard",
        onClick: handleCancel,
        disabled: false,
      },
    ];
  if (!isSampleDs && !isAnyWidgetPresentFlag && !isEditableOn) {
    menuBarSplitButtonOptions = [
      {
        id: "delete",
        label: "Delete",
        onClick: handleDeleteDialog,
        disabled: isDeleteDisabled,
      },
      {
        id: "bulkEdit",
        label: "Bulk Edit",
        onClick: handleEditDialog,
        disabled: isEditDisabled,
      },
    ];
  }
  // if (dsForm.layoutEditable.value) {
  //   menuBarSplitButtonOptions = [
  //     {
  //       id: "update",
  //       label: "Update",
  //       onClick: handleUpdate,
  //       disabled: isUpdateDisabled,
  //     },
  //     {
  //       id: "cancel",
  //       label: "Cancel",
  //       onClick: handleCancelRearrange,
  //       disabled: false,
  //     },
  //   ];
  // }
  let renameTooltip = "Type and press ENTER to rename";
  if (isSampleDs) renameTooltip = "Sample DS cannot be edited";
  const renderMenuBarProps = {
    ReduxDispatcher,
    user,
    allData,
    chartName,
    setChartName,
    menuBarSplitButtonOptions,
    renameDisabled: isSampleDs,
    renameTooltip,
    dsForm: dsForm,
    handleDsFormChange: handleDsFormChange,
    isEdit,
    onTimeFiltersChange: handleTimeFiltersChange,
  };
  const stickyStyles = {
    position: "sticky",
    top: "0px",
    zIndex: 1,
    backgroundColor: themeColors["mainContentBgColor"],
    paddingTop: "5px",
  };
  const layout = dsForm.gridLayout.value;
  const eventHandlers = {
    // onDelete: onDSWidgetDeleteDialog,
    onDelete: onDSWidgetDelete,
    // onAddChart: handleCreateChart,
    onEditChart: handleEditChart,
    // onRename: handleChartRename,
  };

  const handleGoHome = (user) => {
    history.push("/datastory");
  };

  const timeFiltersSettings = {
    compareCalendarRowLimit: 1, //hard coded; it will come from /getUserAccessList
    isMultiCompareRowAllowed: false, //hard coded; it will come from /getUserAccessList
    isComparisonAvailable: false, //hard coded; it should come from where this component is being called
    isChangeTypeDropdownAvailable: true,
    isApplyButtonAvailable: false,
  };
  const textFieldCustomStyle = {
    wrapperWidth: "100%",
  };
  const breadcrumbData = getBreadcrumbsDataFromRoute(user, history);
  const formattedDateRange = formatTimeFilters(dsForm.timeFilters.value);
  const formattedDateContainerCss = {
    backgroundColor: "white",
    boxShadow: themeColors["timeFiltersBoxShadow"],
    color: themeColors["secondaryColor"],
    // fontSize: "",
    height: "30px",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
    borderRadius: "5px",
  };
  const isAnyDimensionFiltersApplied = dsForm.dimensionFilters.value.length > 0;
  const isTimeFiltersAtWidget = dsForm.timeFilterType.value === "widget";
  const isDimFiltersAtWidget = dsForm.dimensionFilterType.value === "widget";
  const noFiltersContainerCss = {
    display: "flex",
    alignItems: "center",
    height: "30px",
  };
  // DsMenuBar props
  const chartsCountInDs = Object.keys(dsForm.selectedCharts.value).length;
  const isAddChartEnabled =
    chartsCountInDs < config.hardCoded.chartsMaxLimitInDs;
  const addChartTooltip = isAddChartEnabled
    ? "Add New Chart"
    : `Only ${config.hardCoded.chartsMaxLimitInDs} charts allowed`;
  //
  const onTimeFiltersChange = handleTimeFiltersChange;
  const renameDisabled = renderMenuBarProps.renameDisabled;
  // Add min-max height for ds widgets
  const layoutWithMinMaxAdded = layout.map((row) => {
    if (row.chartType === "counter") {
      return { ...row, minW: 2, minH: 3 };
    } else {
      return { ...row, minW: 3, minH: 4 };
    }
  });
  const allDataStatus = getAllDataStatus(
    Object.values(dsForm["dataQE"]["value"])
  );
  const enableDownloadAsPDF = allDataStatus === "success" && !isEditableOn;
  const downloadAsPDFTooltipText = isEditableOn
    ? "Please save to download as PDF"
    : allDataStatus === "error"
    ? "Error occured in fetching data"
    : allDataStatus === "loading"
    ? "Loading data..."
    : "Download as PDF";
  const dsDashboardStyle = {
    backgroundColor: themeColors["mainContentBgColor"],
  };
  let dsPreviewBoxClass = "datastory-preview-box";
  if (!isEditableOn) dsPreviewBoxClass += " published";

  const handleAddFilters = () => {
    const handleApplyFilters = (payload, filterType) => {
      switch (filterType) {
        case "dimensions":
          var action = replaceAllDimensionFilters(payload);
          var newDimensionFilters = dimensionFiltersReducer(
            dsForm.dimensionFilters.value,
            action
          );
          handleDimensionFiltersChange(newDimensionFilters);
          break;
        // case "metrics":
        //   var action = replaceAllMetricFilters(payload);
        //   dispatchMetricFilters(action);
        //   break;
        // case "time":
        //   onTimeFiltersChange(payload);
        //   break;
      }
    };

    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Open Filters",
      label: "Sidenav",
    });
    const commonGlobalFilterProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: false,
      showMetricFilters: false,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      timeFilters: dsForm.timeFilters.value, //for initializing filters
      dimensionFilters: dsForm.dimensionFilters.value, //for initializing filters
      metricFilters: [], //for initializing filters
      // settings: dimensionFiltersSettings,
      filtersDimData: allData.plotlyDimensions,
      filtersMetricsData: allData.plotlyMetrics,
      selections: {},
    };
    //Open Global Filters
    const newGlobalFiltersProps = {
      ...commonGlobalFilterProps,
      activeDimensionFilter: {},
      handleApplyFilters, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };

    const action = updateUserScreen("globalFilters", newGlobalFiltersProps);
    ReduxDispatcher(action);
  };

  const pivotNoImagePosition = {
    height: "calc(100vh - 123px)",
  };

  const additionalSidenavItems = [
    {
      id: "home",
      name: "Home",
      tooltip: "Go back to Datastory home",
      elementId: "dsBackToHome",
      iconId: "custom-home-icon",
      customClass: "sidenav-home",
      onClick: (user) => handleGoHome(user),
      isVisible: true,
    },
    {
      id: "filters",
      name: "Filters",
      tooltip: "Open filters",
      elementId: "filtersInSidenav",
      iconId: "custom-filter-icon",
      customClass: "sidenav-filters",
      onClick: (user) => handleAddFilters(),
      isVisible: true,
    },
  ];
  const sidenavItemsOrderInfo = {
    home: 1,
    filters: 2,
    reports: 3,
    alerts: 4,
  };
  const tabName="datastory-dashboard";
  const sidenavProps = { additionalSidenavItems, sidenavItemsOrderInfo, tabName };
  const dimFiltersContainerCss = { padding: "0px 22px" };

  return (
    <>
      <LayoutTopSideBottom sidenavProps={sidenavProps}>
        <SigviewBreadcrumb
          data={breadcrumbData}
          onClick={handleBreadcrumbChange}
        />
        {ds.status === "error" && (
          <ErrorHandler
            message={ds.message}
            reloadFlag={true}
            onReload={() => {
              setDsReload(!dsReload);
            }}
          />
        )}
        {ds.status === "accessError" && (
          <ErrorHandler
            message={ds.message}
            reloadFlag={true}
            onReload={() => history.push("/datastory")}
            reloadButtonProps={{
              flag: true,
              // variant: "contained",
              title: "Redirect to Home",
            }}
          />
        )}
        {ds.status === "loading" && <Loader />}
        {/* THIS WRAPPER BOX IS FOR STICKY PURPOSES ONLY */}
        {ds.status === "success" && (
          <Box id="dsDashboardForPdf" css={pivotNoImagePosition}>
            <Box css={stickyStyles}>
              {/* <DsMenuBar {...renderMenuBarProps} /> */}
              <div className={`menu-bar-container`}>
                <div className={`menu-bar-left-panel`}>
                  {/* Wrapping in a div only for tour */}
                  <div className="menu-bar-basics">
                    {/* <SigviewTooltip title={chartTitle} top="-12px">
                      <span className={`menu-bar-title`}>{chartTitle}</span>
                    </SigviewTooltip> */}
                    <SigviewTextFieldAsync
                      value={chartName.value}
                      onChange={handleChartNameChange}
                      status={chartName.status}
                      onClickAway={handleClickAway}
                      customStyle={textFieldCustomStyle}
                      tooltipTitle={renameTooltip}
                      readOnly={renameDisabled}
                      readOnly={!isEditableOn}
                      inputClassName="rename-datastory-GA"
                    />
                  </div>
                </div>
                <div className={`menu-bar-center-panel`}>
                  <div id="dsMenuBar">
                    {!isTimeFiltersAtWidget && (
                      <>
                        {dsForm.timeFilters.value.isLoading && <Loader />}
                        {!dsForm.timeFilters.value.isLoading && (
                          <>
                            {!isTimeFiltersEditable && (
                              <Box css={formattedDateContainerCss}>
                                <SigviewTypography
                                  variant="pSmallMedium"
                                  style={{
                                    height: "max-content",
                                    color: themeColors["primaryColor"],
                                  }}
                                >
                                  {formattedDateRange}
                                </SigviewTypography>
                              </Box>
                            )}
                            {isTimeFiltersEditable && (
                              <div id="menuBarTimeFiltersContainer">
                                <TimeFilters
                                  user={user}
                                  allData={allData}
                                  initialTimeFilters={dsForm.timeFilters.value}
                                  onChange={onTimeFiltersChange}
                                  googleAnalytics={{
                                    category: "DatastoryDashboardPage",
                                  }}
                                  calendarDaysLimits={calendarDaysLimits}
                                  settings={timeFiltersSettings}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className={`menu-bar-right-panel`}>
                  <div className="ds-menu-button-container">
                    {isAnyWidgetPresentFlag && (
                      <SigviewTooltip
                        title={downloadAsPDFTooltipText}
                        placement="bottom"
                      >
                        <Box>
                          <SigviewIcon
                            className={`${
                              !enableDownloadAsPDF
                                ? `material-icons-round`
                                : "material-icons-round datastoryClick-Download-GA"
                            }`}
                            iconName="download"
                            style={{
                              fontSize: "22px !important",
                              padding: "0px 10px 0px 0px",
                              color: themeColors["secondaryColorLight"],
                              hoverColor: themeColors["primaryColor"],
                              cursor: "pointer",
                            }}
                            disabled={!enableDownloadAsPDF}
                            onClick={(e) =>
                              handleDownloadAsPDF(
                                e,
                                dsForm,
                                enableDownloadAsPDF
                              )
                            }
                          />
                        </Box>
                      </SigviewTooltip>
                    )}

                    {isEditableOn && (
                      <SigviewTooltip title="Edit KPIs" placement="bottom">
                        <Box style={{ padding: "0px 10px 0px 0px" }}>
                          <span
                            className="sigview-icon-editKpiSecondaryColorLight datastoryClick-Edit-KPIs-GA"
                            // iconName="pin"
                            style={
                              {
                                // fontSize: "22px !important",
                                // padding: "0px 0px 0px 10px",
                                // color: themeColors["secondaryColorLight"],
                                // hoverColor: themeColors["primaryColor"],
                                // cursor: "pointer",
                              }
                            }
                            onClick={handleEditKpis}
                          ></span>
                        </Box>
                      </SigviewTooltip>
                    )}
                    {isEditableOn && (
                      <SigviewTooltip
                        title={addChartTooltip}
                        placement="bottom"
                      >
                        <Box>
                          <SigviewIcon
                            className="material-icons-round datastory-createNewChart-GA"
                            iconName="add_chart"
                            style={{
                              fontSize: "22px !important",
                              padding: "0px 10px 0px 0px",
                              color: isAddChartEnabled
                                ? themeColors["secondaryColorLight"]
                                : themeColors["secondaryColorLighter"],
                              hoverColor: isAddChartEnabled
                                ? themeColors["primaryColor"]
                                : themeColors["secondaryColorLighter"],
                              cursor: isAddChartEnabled
                                ? "pointer"
                                : "not-allowed",
                              pointerEvents: isAddChartEnabled
                                ? "initial"
                                : "none",
                            }}
                            onClick={handleAddNewChart}
                          />
                        </Box>
                      </SigviewTooltip>
                    )}
                    {isEditableOn && (
                      <DsSettingsIcon
                        dsForm={dsForm}
                        dispatchDsForm={dispatchDsForm}
                        themeColors={themeColors}
                      />
                    )}
                    {menuBarSplitButtonOptions.length > 1 && (
                      <SigviewButtonSplit
                        options={menuBarSplitButtonOptions}
                        customClassName="Datastory-GA"
                      />
                    )}
                    {menuBarSplitButtonOptions.length === 1 && (
                      <SigviewButton
                        id={menuBarSplitButtonOptions[0]["id"]}
                        title={menuBarSplitButtonOptions[0]["label"]}
                        disabled={menuBarSplitButtonOptions[0]["disabled"]}
                        onClick={menuBarSplitButtonOptions[0]["onClick"]}
                        customClassName="Datastory-GA"
                      />
                    )}
                    {/* <Icons data={menuBarSplitButtonOptions} /> */}
                  </div>
                </div>
              </div>
              {/* OLD IMPLEMENTATION OF DIMENSION FILTER */}
              {/* {!isDimFiltersAtWidget && (
                <>
                  {(isDimFiltersEditable || isAnyDimensionFiltersApplied) && (
                    <DimensionFilters
                      initialDimensionFilters={dsForm.dimensionFilters.value}
                      initialTimeFilters={dsForm.timeFilters.value}
                      onDimensionFiltersChange={handleDimensionFiltersChange}
                      settings={{ isReadOnly: !isDimFiltersEditable }}
                    />
                  )}
                  {!isAnyDimensionFiltersApplied && !isDimFiltersEditable && (
                    <Box css={noFiltersContainerCss}>
                      <SigviewTypography
                        variant="pMedium"
                        style={{
                          color: themeColors["secondaryColorLight"],
                          height: "max-content",
                          width: "300px",
                          padding: "0px 25px",
                        }}
                      >
                        No filters applied
                      </SigviewTypography>
                    </Box>
                  )}
                </>
              )} */}

              {isAnyDimensionFiltersApplied && !isDimFiltersAtWidget ? (
                <Box css={dimFiltersContainerCss}>
                  <DimensionFilters
                    initialDimensionFilters={dsForm.dimensionFilters.value}
                    initialTimeFilters={dsForm.timeFilters.value}
                    onDimensionFiltersChange={handleDimensionFiltersChange}
                    settings={{ isReadOnly: !isDimFiltersEditable }}
                  />
                </Box>
              ) : (
                <></> // ADDING THIS BECAUSE LAYOUT NEEDS SOMETHING IN THAT INDEX
              )}
            </Box>
            <>
              {isAnyWidgetPresentFlag && (
                <div id="dsDashboard" style={dsDashboardStyle}>
                  <div className="datastory-preview-parent">
                    <div className="datastory-preview-container">
                      <div className="datastory-preview">
                        <ResponsiveGridLayout
                          className="layout"
                          layout={layoutWithMinMaxAdded}
                          cols={12}
                          rowHeight={rowHeight}
                          margin={margin}
                          onLayoutChange={(layout) =>
                            handleLayoutChange(layout)
                          }
                        >
                          {layoutWithMinMaxAdded.map((widget) => (
                            <div key={widget.i} className={dsPreviewBoxClass}>
                              <DsWidgetItem
                                widgetMetadata={widget}
                                user={user}
                                allData={allData}
                                ReduxDispatcher={ReduxDispatcher}
                                dsForm={dsForm}
                                globalFilters={globalFilters}
                                // metricsData={metricsData}
                                // metricsDataReload={metricsDataReload}
                                // setMetricsDataReload={setMetricsDataReload}
                                displayLayout={layout}
                                isEditableOn={isEditableOn}
                                onChange={handleChartDataChange}
                                onWidgetChange={handleWidgetChange}
                                eventHandlers={eventHandlers}
                                onChartDataReload={handleWidgetChartReload}
                              />
                            </div>
                          ))}
                        </ResponsiveGridLayout>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!isAnyWidgetPresentFlag && (
                <>
                  <div className="chart-placeholder-container">
                    <div className="chart-placeholder-image"></div>
                    <p className="chart-placeholder-title">
                      {t(config.messages.noWidgets)}
                    </p>
                    {!isEditableOn && (
                      <SigviewButton
                        variant="contained"
                        onClick={handleRearrange}
                        title="Edit Datastory"
                        style={{ margin: { top: "15px" } }}
                        customClassName="EditDatastory-GA"
                      />
                    )}
                  </div>
                  {/* <div className="no-data-container">
                  <div className="chart-placeholder-image"></div>
                  <p className="no-data">{config.messages.noWidgets}</p>
                </div> */}
                </>
              )}
            </>
          </Box>
        )}
        <div id="placeholderDivToDownloadPdf"></div>
      </LayoutTopSideBottom>
      {/* <SigviewCommon /> */}
    </>
  );
}

DatastoryDashboard.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  activeDatastory: state.user.screen.activeDatastory,
  globalFilters: state.globalFilters,
  timeFilters: state.user.timeFilters,
  dimensionFilters: state.user.dimensionFilters, //Can't use transformFiltersUiToBackend(state.user.dimensionFilters) here because it re-renders useEffects even when there's no change in dimensionFilters
});

export default connect(mapStateToProps)(DatastoryDashboard);
