import { Box } from "@material-ui/core";
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import themeColors from "../../../styles/theme/themeColors";
import SigviewButton from "../../components/Common/SigviewButton";
import SigviewSingleSelect from "../../components/Common/SigviewSingleSelect";
import SigviewTextField from "../../components/Common/SigviewTextField";
import SigviewTypography from "../../components/Common/SigviewTypography";
import LayoutRegisterBucket from "../../layouts/LayoutRegisterBucket";
import {
  replaceRegisterBucketForm,
  updateRegisterBucketForm,
  updateUserScreen,
} from "../../redux/actions";
import { registerBucket } from "../../redux/reducers/registerBucket";
import {
  areSelectionsEqual,
  initialRegisterBucketState,
  isSelectionInvalid,
  unwrapperBucketState,
  wrapperBucketState,
} from "../../utils/bucketUtils";
import useReducerLogger from "../../utils/useReducerLogger";
// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";
import {
  saveBucketDetails,
  testBucketConnection,
  updateBucketDetails,
} from "../../services/api";
import SigviewIcon from "../../components/Common/SigviewIcon";
import Loader from "../../components/Loader/Loader";
import SigviewTooltip from "../../components/Common/SigviewTooltip";

const asteriskStyle = { color: "red", transform: "translateX(-10px)" };

function RegisterBucket(props) {
  const { setReloadBucketData = () => {} } = props;
  const { state: themeState } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const themeColors = themeState.themes[themeState.activeTheme];

  const [selection, dispatchSelections] = useReducer(
    useReducerLogger(registerBucket),
    initialRegisterBucketState
  );

  const [connectionStatus, setConnectionStatus] = useState({
    result: {},
    status: "initial",
    messsage: "",
  });

  const user = useSelector((state) => state.user);

  let finalValidation = {};
  const originalSelection = useRef({});

  useEffect(() => {
    if (user.screen.bucketScreen.type === "updateBucket") {
      const payload = {
        value: user.screen.bucketScreen.payload,
      };

      originalSelection.current = { ...payload.value };

      const action = replaceRegisterBucketForm(payload);
      dispatchSelections(action);
    }
  }, [user.screen.bucketScreen]);

  useEffect(() => {
    if (
      connectionStatus.status === "success" ||
      connectionStatus.status === "error"
    )
      setConnectionStatus({
        result: {},
        status: "initial",
        messsage: "",
      });
  }, [selection]);

  const handleFormChange = (value, key) => {
    const payload = {
      key,
      value,
    };
    const action = updateRegisterBucketForm(payload);
    dispatchSelections(action);
  };

  const testConnection = () => {
    setConnectionStatus({ result: {}, status: "loading", messsage: "" });
    const payload = unwrapperBucketState(selection);

    const testBucketConnectionPromise = testBucketConnection({
      payload,
    });
    testBucketConnectionPromise
      .then((data) => {
        let newData = {
          result: data.result.data,
          status: "success",
          messsage: "",
        };
        setConnectionStatus(newData);
      })
      .catch((json) => {
        console.log("API FAILED");

        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Test connection check failed",
        };
        dispatch(updateUserScreen("snackbar", snackbarPayload));

        let newData = {
          result: [],
          status: "error",
          messsage: "Fetch bucketList API failed",
        };
        setConnectionStatus(newData);
      });
  };
  const saveBucket = () => {
    const payload = wrapperBucketState(selection, user);

    console.log("payload", payload);
    if (user.screen.bucketScreen.type === "updateBucket") {
      const updateBucketDetailsPromise = updateBucketDetails({
        payload,
      });
      updateBucketDetailsPromise
        .then((data) => {
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: "Storage updated successfully...",
          };
          dispatch(updateUserScreen("snackbar", snackbarPayload));

          var action = updateUserScreen("bucketScreen", {
            type: "manageBucket",
            payload: {},
          });

          console.log("action", action);
          dispatch(action);

          setReloadBucketData(Date.now());
        })
        .catch((json) => {
          console.log("API FAILED");

          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: json.error || "Storage failed to update",
          };
          dispatch(updateUserScreen("snackbar", snackbarPayload));
        });
    }

    if (user.screen.bucketScreen.type === "registerBucket") {
      const saveBucketDetailsPromise = saveBucketDetails({
        payload,
      });
      saveBucketDetailsPromise
        .then((data) => {
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: "Storage registered successfully...",
          };
          dispatch(updateUserScreen("snackbar", snackbarPayload));

          var action = updateUserScreen("bucketScreen", {
            type: "manageBucket",
            payload: {},
          });

          console.log("action", action);
          dispatch(action);
          setReloadBucketData(Date.now());
        })
        .catch((json) => {
          console.log("API FAILED");

          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: json.error || "Storage failed to register",
          };
          dispatch(updateUserScreen("snackbar", snackbarPayload));
        });
    }
  };

  // Validation Checker
  finalValidation = isSelectionInvalid(selection).status === "valid";

  let selectionsComparator = areSelectionsEqual(
    selection,
    originalSelection.current
  );

  return (
    <>
      <Box
        css={{
          padding: "30px 200px",
          height: "calc(100% - 150px)",
        }}
      >
        <Box
          css={{
            border: `1.5px solid ${themeColors["secondaryColorLighter"]}`,

            borderRadius: "5px",
            // margin: "30px 200px",
            height: "100%",
            boxSizing: "border-box",
            background: "white",
          }}
        >
          <Box css={{ padding: "30px 50px" }}>
            <SigviewTypography variant="pLarge">
              Please enter the storage details below:
            </SigviewTypography>
          </Box>
          <LayoutRegisterBucket type={selection.type.value}>
            <Box
              css={{
                display: "flex",
                // alignItems: "center",
              }}
            >
              <SigviewTypography
                style={{
                  width: "100px",
                  height: "max-content",
                  padding: "6px 0px 0px 0px",
                }}
              >
                Storage Name:
              </SigviewTypography>
              <span style={{ ...asteriskStyle }}>*</span>
              <Box css={{ width: "70%" }}>
                <SigviewTextField
                  placeholder="Enter storage name"
                  width="100%"
                  maxWidth="100%"
                  value={selection.storageName.value}
                  onChange={(value) => handleFormChange(value, "storageName")}
                  error={
                    (user.screen.bucketScreen.type === "updateBucket" &&
                      selection.storageName.status === "invalid") ||
                    (user.screen.bucketScreen.type === "registerBucket" &&
                      selection.storageName.message ===
                        "Storage name should not be more than 50 characters" &&
                      selection.storageName.status === "invalid")
                  }
                  helperText={selection.storageName.message}
                  fontSizeHelperText="9px"
                />
              </Box>

              <p
                style={{
                  padding: "8px 0px 0px 5px",
                  height: "max-content",
                  fontSize: "9px",
                  fontWeight: "500",
                  color: themeColors["secondaryColorLight"],
                }}
              >
                {selection.storageName.value.length}/50
              </p>
            </Box>
            <Box
              css={{
                display: "flex",
                // alignItems: "center",
              }}
            >
              <SigviewTypography
                style={{
                  width: "100px",
                  height: "max-content",
                  padding: "6px 0px 0px 0px",
                }}
              >
                Storage Type:
              </SigviewTypography>
              <span style={{ ...asteriskStyle }}>*</span>
              <Box css={{ width: "70%" }}>
                <SigviewSingleSelect
                  placeholder="Select bucket type"
                  width="100%"
                  maxWidth="100%"
                  minWidth="100%"
                  data={[
                    {
                      id: "selectBucket",
                      name: "Select storage type",
                      disabled: true,
                    },
                    { id: "s3", name: "S3" },
                    { id: "gcs", name: "GCS" },
                  ]}
                  value={
                    selection.type.value === ""
                      ? "selectBucket"
                      : selection.type.value
                  }
                  onChange={(value) => handleFormChange(value, "type")}
                />
              </Box>
            </Box>

            {selection.type.value && (
              <>
                <Box
                  css={{
                    display: "flex",
                    // alignItems: "center",
                  }}
                >
                  <SigviewTypography
                    style={{
                      width: "100px",
                      height: "max-content",
                      padding: "6px 0px 0px 0px",
                    }}
                  >
                    Bucket Name:
                  </SigviewTypography>
                  <span style={{ ...asteriskStyle }}>*</span>
                  <Box css={{ width: "70%" }}>
                    <SigviewTextField
                      placeholder="Enter bucket name"
                      width="100%"
                      maxWidth="100%"
                      value={selection.name.value}
                      onChange={(value) => handleFormChange(value, "name")}
                      error={
                        user.screen.bucketScreen.type === "updateBucket" &&
                        selection.name.status === "invalid"
                      }
                      helperText={selection.name.message}
                      fontSizeHelperText="9px"
                    />
                  </Box>
                </Box>
                <Box
                  css={{
                    display: "flex",
                    // alignItems: "center",
                  }}
                >
                  <SigviewTypography
                    style={{
                      width: "100px",
                      height: "max-content",
                      padding: "6px 0px 0px 0px",
                    }}
                  >
                    Folder path:
                  </SigviewTypography>
                  <span style={{ ...asteriskStyle }}>*</span>
                  <Box css={{ width: "70%" }}>
                    <SigviewTextField
                      placeholder="Enter folder path"
                      width="100%"
                      maxWidth="100%"
                      value={selection.path.value}
                      onChange={(value) => handleFormChange(value, "path")}
                      error={
                        user.screen.bucketScreen.type === "updateBucket" &&
                        selection.path.status === "invalid"
                      }
                      helperText={selection.path.message}
                      fontSizeHelperText="9px"
                    />
                  </Box>
                </Box>
              </>
            )}
            {selection.type.value === "s3" ? (
              <>
                <Box
                  css={{
                    display: "flex",
                    // alignItems: "center",
                  }}
                >
                  <SigviewTypography
                    style={{
                      width: "100px",
                      height: "max-content",
                      padding: "6px 0px 0px 0px",
                    }}
                  >
                    Access Key:
                  </SigviewTypography>
                  <span style={{ ...asteriskStyle }}>*</span>
                  <Box css={{ width: "70%" }}>
                    <SigviewTextField
                      placeholder="Enter Access Key"
                      width="100%"
                      maxWidth="100%"
                      value={selection.accessKey.value}
                      onChange={(value) => handleFormChange(value, "accessKey")}
                      error={
                        user.screen.bucketScreen.type === "updateBucket" &&
                        selection.accessKey.status === "invalid"
                      }
                      helperText={selection.accessKey.message}
                      fontSizeHelperText="9px"
                      type="password"
                    />
                  </Box>
                </Box>
                <Box
                  css={{
                    display: "flex",
                    // alignItems: "center",
                  }}
                >
                  <SigviewTypography
                    style={{
                      width: "100px",
                      height: "max-content",
                      padding: "6px 0px 0px 0px",
                    }}
                  >
                    Secret Key:
                  </SigviewTypography>
                  <span style={{ ...asteriskStyle }}>*</span>
                  <Box css={{ width: "70%" }}>
                    <SigviewTextField
                      placeholder="Enter Secret Key"
                      width="100%"
                      maxWidth="100%"
                      value={selection.secretKey.value}
                      onChange={(value) => handleFormChange(value, "secretKey")}
                      error={
                        user.screen.bucketScreen.type === "updateBucket" &&
                        selection.secretKey.status === "invalid"
                      }
                      helperText={selection.secretKey.message}
                      fontSizeHelperText="9px"
                      type="password"
                    />
                  </Box>
                </Box>
                <Box
                  css={{
                    display: "flex",
                    // alignItems: "center",
                  }}
                >
                  <SigviewTypography
                    style={{
                      width: "100px",
                      height: "max-content",
                      padding: "6px 0px 0px 0px",
                    }}
                  >
                    Region:
                  </SigviewTypography>
                  <span style={{ ...asteriskStyle }}>*</span>
                  <Box css={{ width: "70%" }}>
                    <SigviewTextField
                      placeholder="Enter Region"
                      width="100%"
                      maxWidth="100%"
                      value={selection.region.value}
                      onChange={(value) => handleFormChange(value, "region")}
                      error={
                        user.screen.bucketScreen.type === "updateBucket" &&
                        selection.region.status === "invalid"
                      }
                      helperText={selection.region.message}
                      fontSizeHelperText="9px"
                    />
                  </Box>
                </Box>
                <></>
              </>
            ) : selection.type.value === "gcs" ? (
              <>
                <Box
                  css={{
                    display: "flex",
                    // alignItems: "center",
                  }}
                >
                  <SigviewTypography
                    style={{
                      width: "100px",
                      height: "max-content",
                      padding: "6px 0px 0px 0px",
                    }}
                  >
                    Email ID :
                  </SigviewTypography>
                  <span style={{ ...asteriskStyle }}>*</span>
                  <Box css={{ width: "70%" }}>
                    <SigviewTextField
                      placeholder="Enter Email ID"
                      width="100%"
                      maxWidth="100%"
                      value={selection.emailId?.value}
                      onChange={(value) => handleFormChange(value, "emailId")}
                      error={
                        user.screen.bucketScreen.type === "updateBucket" &&
                        selection.emailId.status === "invalid"
                      }
                      helperText={selection.emailId.message}
                      fontSizeHelperText="9px"
                      type="password"
                    />
                  </Box>
                </Box>
                <Box
                  css={{
                    display: "flex",
                    // alignItems: "center",
                  }}
                >
                  <SigviewTypography
                    style={{
                      width: "100px",
                      height: "max-content",
                      padding: "6px 0px 0px 0px",
                    }}
                  >
                    Private ID:
                  </SigviewTypography>
                  <Box css={{ width: "70%", paddingLeft: "7px" }}>
                    <SigviewTextField
                      placeholder="Enter Private ID"
                      width="100%"
                      maxWidth="100%"
                      value={selection.privateId?.value}
                      onChange={(value) => handleFormChange(value, "privateId")}
                      type="password"
                    />
                  </Box>
                </Box>
                <Box
                  css={{
                    display: "flex",
                    // alignItems: "center",
                  }}
                >
                  <SigviewTypography
                    style={{
                      width: "100px",
                      height: "max-content",
                      padding: "6px 0px 0px 0px",
                    }}
                  >
                    Private Key :
                  </SigviewTypography>
                  <span style={{ ...asteriskStyle }}>*</span>
                  <Box css={{ width: "70%" }}>
                    <SigviewTextField
                      placeholder="Enter Private Key"
                      width="100%"
                      maxWidth="100%"
                      value={selection.privateKey?.value}
                      onChange={(value) =>
                        handleFormChange(value, "privateKey")
                      }
                      error={
                        user.screen.bucketScreen.type === "updateBucket" &&
                        selection.privateKey.status === "invalid"
                      }
                      helperText={selection.privateKey.message}
                      fontSizeHelperText="9px"
                      type="password"
                    />
                  </Box>
                </Box>
                <Box
                  css={{
                    display: "flex",
                    // alignItems: "center",
                  }}
                >
                  <SigviewTypography
                    style={{
                      width: "100px",
                      height: "max-content",
                      padding: "6px 0px 0px 0px",
                    }}
                  >
                    Client ID:
                  </SigviewTypography>
                  <Box css={{ width: "70%", paddingLeft: "7px" }}>
                    <SigviewTextField
                      placeholder="Enter Client ID"
                      width="100%"
                      maxWidth="100%"
                      value={selection.clientId?.value}
                      onChange={(value) => handleFormChange(value, "clientId")}
                      type="password"
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <></>
            )}

            <Box
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <SigviewButton
                onClick={testConnection}
                title="Test Connection"
                style={{
                  btnWidth: "150px",
                  btnHeight: "30px",
                }}
                disabled={!finalValidation}
                customClassName="TestConnection-GA"
              />

              <Box
              // css={{ transform: " translate(-25px, 0)" }}
              >
                {(connectionStatus.status === "initial" ||
                  connectionStatus.status === "error") && (
                  <Box css={{ height: "25px", width: "25px" }} />
                )}

                {connectionStatus.status === "loading" && (
                  <Box css={{ height: "25px", width: "25px" }}>
                    <Loader />
                  </Box>
                )}

                {connectionStatus.result.write &&
                  connectionStatus.status === "success" && (
                    <SigviewTooltip
                      title="Connection is valid"
                      placement="bottom"
                    >
                      <span>
                        <SigviewIcon
                          iconName="check_circle"
                          style={{
                            fontSize: "25px !important",
                            // padding: "0 0 0 10px !important",
                            color: "var(--posChangeColor)",
                            hoverColor: "var(--posChangeColor)",
                            cursor: "default",
                          }}
                        />
                      </span>
                    </SigviewTooltip>
                  )}

                {!connectionStatus.result.write &&
                  connectionStatus.status === "success" && (
                    <SigviewTooltip
                      title="Connection is invalid"
                      placement="bottom"
                    >
                      <span>
                        <SigviewIcon
                          iconName="cancel"
                          style={{
                            fontSize: "25px !important",
                            // padding: "0 0 0 10px !important",
                            color: "var(--negChangeColor)",
                            hoverColor: "var(--negChangeColor)",
                            cursor: "default",
                          }}
                        />
                      </span>
                    </SigviewTooltip>
                  )}
              </Box>
            </Box>
            <Box>
              <SigviewButton
                title={
                  user.screen.bucketScreen.type === "registerBucket"
                    ? "Register Storage"
                    : user.screen.bucketScreen.type === "updateBucket"
                    ? "Update Storage"
                    : ""
                }
                onClick={saveBucket}
                style={{
                  btnWidth: "150px",
                  btnHeight: "30px",
                }}
                disabled={
                  !finalValidation ||
                  !selectionsComparator ||
                  !(
                    connectionStatus.result.write &&
                    connectionStatus.status === "success"
                  )
                }
                customClassName={user.screen.bucketScreen.type === "registerBucket"
                ? "RegisterNewStorage-GA"
                : user.screen.bucketScreen.type === "updateBucket"
                ? "UpdateStorage-GA"
                : ""}
              />
              <Box css={{ color: "red", paddingTop: "5px" }}>
                <span style={{ ...asteriskStyle, fontSize: "12px" }}>*</span>

                <span style={{ fontSize: "9px" }}>Mandatory fields</span>
              </Box>
            </Box>
          </LayoutRegisterBucket>
        </Box>
      </Box>
    </>
  );
}

export default RegisterBucket;
