// Import required libraries
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Import custom components
import SigviewSingleSelect from "../../components/Common/SigviewSingleSelect";
import SigviewButton from "../Common/SigviewButton";
import SigviewTextField from "../Common/SigviewTextField";
import SigviewFileInput from "../Common/SigviewFileInput";
import SigviewProgressBar from "../Common/SigviewProgressBar";

// Import styles

// Import config
import { config } from "../../config/config";

// Import action creators

// Import utils
import {
  getFileUploadLocation,
  uploadToAWSBucket,
  uploadFileMetadataToServer,
} from "../../services/api";
import { isFileValid, getGCSFileName } from "../../utils/filtersUtils";

//STEPS
//1) Let the user upload only 1 file (not multiple)
//2) Allowed file formats are csv and excel (format differs in windows and mac)
//3) Once the user uploads the file, check if it is valid

//Making required variables
const initialFileData = config.hardCoded.initialFileData;

function AdvancedFiltersFileUploadSigview(props) {
  const {
    user,
    itemTitle,
    type,
    newDropDownData,
    handleTypeChange,
    extraData,
    handleExtraDataChange,
    showDeleteIconFlag,
    handleRowDelete,
    handleAddRow,
    showAddIconFlag,
    showError,
    activeDimension,
  } = props;
  const [fileData, setFileData] = useState(initialFileData);

  //Defining required variables
  const displayFileName = fileData.isLoading
    ? "Uploading..."
    : extraData?.fileName || "No File Chosen";
  const inputFileLabel = fileData.error
    ? "Retry"
    : extraData?.fileName
    ? "Replace"
    : "Select File";
  const errorFlag = fileData.error
    ? true
    : !extraData?.fileName
    ? showError
      ? true
      : false
    : false;
  const errorMessage = fileData.error
    ? fileData.error
    : !extraData?.fileName
    ? "Please upload a file"
    : "";
  const dimId = activeDimension?._id;

  const handleFileRemove = () => {
    handleExtraDataChange({});
    setFileData(initialFileData);
  };

  const handleFileChange = (e) => {
    //Grab the file and store it in var "file"
    const files = e.target.files;
    const file = files[0];
    console.log("FILES", files);

    //Check if the file is valid
    const maxFileUploadSizeInMB = user.uiLimitsList.maxFileUploadSizeInMB;
    const { status, message } = isFileValid(file, maxFileUploadSizeInMB);
    console.log("MESSAGE", message);

    //Check if file is valid
    if (status) {
      const gcsFileName = getGCSFileName(file.name);
      //File path is a concatenation of org, view and gcsFileName (different from non-sigview)
      const filePath =
        user?.reqMetadata?.organization +
        "/" +
        user?.reqMetadata?.view +
        "/" +
        gcsFileName;
      let newFileData = {
        error: null,
        isLoading: true,
        data: {
          filePath,
          file,
        },
      };
      setFileData(newFileData);
    } else {
      let newFileData = {
        error: message,
        isLoading: false,
        data: null,
      };
      setFileData(newFileData);
    }
  };

  //Upload file to AWS (s3 bucket) everytime fileData changes
  //Uploading the file requires 4 steps (3 API calls):
  // Step 1) Get an upload location & key from BACKEND to upload the file in remote bucket (GCP/AWS)
  // Step 2) With the upload location & key (only in GCP), upload the file to GCP which won't return anything except success or failure
  // Step 3) On successful upload of file to GCP, send file metadata to BACKEND to get validate the file;
  //         if valid file, it will return the table_name
  //         if invalid file, it will return the error
  // Step 4) Set this table name and file name as extraData (file info) in advanced filters
  useEffect(() => {
    //Upload file only when it's a valid file
    if (fileData.data) {
      const fetchProps = {
        headers: {
          expirationTime: 3600000, //HARD CODED
          objectKey: fileData.data.filePath,
        },
      };
      // Step 1) Get an upload location & key from BACKEND to upload the file in remote bucket (GCP/AWS)
      const getFileUploadLocationPromise = getFileUploadLocation(fetchProps);
      getFileUploadLocationPromise
        .then((responseData) => {
          const url = responseData;

          const fetchProps = {
            url,
            payload: fileData.data.file,
          };
          // Step 2) With the upload location & key (only in GCP), upload the file to GCP which won't return anything except success or failure
          const uploadToAWSBucketPromise = uploadToAWSBucket(fetchProps);
          uploadToAWSBucketPromise
            .then(() => {
              const payload = {
                id: dimId,
                emailId: user?.reqMetadata?.email,
                fileType: fileData.data.file.type,
                location: url.split("?")[0],
                s3Key: fileData.data.filePath, //since filePath is exactly what API requires and for small files, S3 does not return S3key
                orgViewReq: {
                  organization: user?.reqMetadata?.organization,
                  view: user?.reqMetadata?.view,
                },
              };
              const fetchProps = {
                payload,
              };
              // Step 3) On successful upload of file to GCP, send file metadata to BACKEND to get validate the file;
              //         if valid file, it will return the table_name
              //         if invalid file, it will return the error
              const uploadFileMetadataToServerPromise =
                uploadFileMetadataToServer(fetchProps);
              uploadFileMetadataToServerPromise
                .then((responseData) => {
                  const fileName = fileData.data.file.name;
                  const responseDataResult = responseData?.result.data || {};
                  const fileLocation = responseDataResult?.s3Key;
                  const extraData = {
                    fileName,
                    fileLocation,
                  };
                  // ! BECAUSE OF POOR AND INCONSISTENT API DESIGN
                  const fileValidFlagFromServer =
                    responseDataResult?.s3Key?.length > 0;
                  if (fileValidFlagFromServer) {
                    handleExtraDataChange(extraData);
                    setFileData({
                      ...fileData,
                      isLoading: false,
                      error: null,
                    });
                  } else {
                    setFileData({
                      isLoading: false,
                      error:
                        responseDataResult?.response ||
                        config.hardCoded.uiErrorMessage,
                      data: null,
                    });
                  }
                })
                .catch((error) => {
                  console.error("error", error);
                  if (error.error !== config.hardCoded.queryCancelled) {
                    setFileData({
                      isLoading: false,
                      error: error.error,
                      data: null,
                    });
                  }
                });
            })
            .catch((error) => {
              console.error("error", error);
              if (error.error !== config.hardCoded.queryCancelled) {
                setFileData({
                  isLoading: false,
                  error: error.error,
                  data: null,
                });
              }
            });
        })
        .catch((error) => {
          console.error("error", error);
          if (error.error !== config.hardCoded.queryCancelled) {
            setFileData({
              isLoading: false,
              error: error.error,
              data: null,
            });
          }
        });
    }
  }, [fileData.data]);

  return (
    <div className="advanced-filters-item-container">
      <div className="advanced-filters-item-title-container">
        <p className="advanced-filters-item-title">{itemTitle}</p>
      </div>
      <SigviewSingleSelect
        value={type}
        data={newDropDownData}
        onChange={handleTypeChange}
        minWidth="170px"
        margin={{ right: "5px" }}
      />
      <SigviewTextField
        value={displayFileName}
        onChange={() => {}}
        error={errorFlag}
        helperText={errorMessage}
        readOnly={true}
        minWidth="210px"
        maxWidth="210px"
        margin={{ right: "5px" }}
      />
      {extraData?.fileName && !fileData.isLoading && (
        <SigviewButton
          variant="outlined"
          onClick={() => handleFileRemove()}
          title="Remove"
          style={{ margin: { right: "5px" }, width: "50px", fontSize: "10px" }}
        />
      )}
      {fileData.isLoading && (
        <div className="advanced-filters-item-progress-bar-container">
          <div className="advanced-filters-item-progress-bar">
            <SigviewProgressBar />
          </div>
        </div>
      )}
      {!fileData.isLoading && (
        <SigviewFileInput
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
          onChange={(e) => handleFileChange(e)}
          label={inputFileLabel}
          style={{ margin: { right: "5px" } }}
        />
      )}
      <div
        className={`advanced-filters-item-icon-container ${
          showDeleteIconFlag ? "visible" : "hidden"
        }`}
      >
        <i
          className="material-icons-outlined advanced-filters-item-icon af-delete-icon"
          onClick={handleRowDelete}
        >
          cancel
        </i>
      </div>
      {showAddIconFlag && (
        <div className="advanced-filters-item-icon-container">
          <i
            className="material-icons advanced-filters-item-icon af-add-icon"
            onClick={handleAddRow}
          >
            add
          </i>
        </div>
      )}
    </div>
  );
}

AdvancedFiltersFileUploadSigview.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(AdvancedFiltersFileUploadSigview);
