// TODO Same code changes as attributeDimension

// Import Constants
import C from "../constants";
import { validateAttributeMetrics } from "../../utils/attributeUtils";

const attributeMetric = (state, action) => {
  const { type = "", payload = {} } = action;
  const { key = "", value = "" } = payload;

  let newState = {};
  switch (type) {
    case C.UPDATE_ATTRIBUTE_METRIC_FORM:
      switch (key) {
        case "name":
        case "dataUnitType":
        case "dataUnitSymbol":
        case "measureType":
        case "backendName":
        case "groups":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          break;

        default:
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log("UNIDENTIFIED KEY in UPDATE_ATTRIBUTE_METRIC_FORM");
          console.log("REDUCER -> ", "AttributeDimension Reducer.js");
          console.log("state -> ", state);
          console.log("action -> ", action);
          console.groupEnd();
          newState = { ...state };
      }

      break;

    case C.UPDATE_ATTRIBUTE_METRIC_FORM_WHOLE_KEY:
      switch (key) {
        case "name":
        case "backendName":
        case "groups":
        case "dataType":
          newState = {
            ...state,
            [key]: value,
          };
          console.log("newState", newState);
          break;
        default:
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log("UNIDENTIFIED KEY in UPDATE_ATTRIBUTE_METRIC_FORM");
          console.log("REDUCER -> ", "AttributeDimension Reducer.js");
          console.log("state -> ", state);
          console.log("action -> ", action);
          console.groupEnd();
          newState = { ...state };
      }
      break;

    case C.REPLACE_ATTRIBUTE_METRIC_FORM:
      newState = { ...payload };
      break;

    default:
      console.error("UI ERROR");
      console.groupCollapsed("DETAILS");
      console.log("UNIDENTIFIED TYPE");
      console.log("REDUCER -> ", "AttributeDimension Reducer.js");
      console.log("state -> ", state);
      console.log("action -> ", action);
      console.groupEnd();
      newState = { ...state };
  }

  const validatedNewState = validateAttributeMetrics({ ...newState });

  return validatedNewState;
};

export default attributeMetric;
