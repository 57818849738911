// Import required libraies
import React, { useContext } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Import styles
import "./Loader.scss";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

function Loader(props) {
  const { size = "20px" } = props;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      color: themeColors["primaryColor"],
      margin: theme.spacing(1),
      "& > * + *": {
        margin: theme.spacing(5),
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className="loader-outer-container">
      <CircularProgress className={classes.root} size={size} />
    </div>
  );
}

Loader.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
