// * Import required libraies
import React, {
  useState,
  useEffect,
  memo,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { connect, useSelector } from "react-redux";
import _ from "underscore";
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  withStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";

// * Import Custom Component
import SigviewIcon from "../../Common/SigviewIcon";
import SigviewTooltip from "../../Common/SigviewTooltip";

// * Import utils
import { config } from "../../../config/config";
import { createResizableTable } from "../../../utils/msvUtils";

import {
  makeUiRowDataFriendlyForWsDimTable,
  getWsDimTableColumns,
  formatDimValueWsDimTable,
} from "../../../utils/analyzeUtils";

// * Import services
import { masterTrackGaEvent } from "../../../services/ga";

// * Import redux utils
// Actions
import {
  updateStandaloneMsvFormDataSort,
  updateStandaloneMsvFormDimensionTableData,
  updateStandaloneMsvMsvTableRowClicked,
  updateStandaloneWsFormDataSort,
  updateStandaloneWsTogglePercentCalList,
} from "../../../redux/actions";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./MsvDimTableUI.css";
import useUpdateEffect from "../../../hooks/useUpdateEffect";

// * Define required static styled components
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

// * Making styles
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    boxSizing: "border-box",
    height: "calc(100% - 5px)",
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent !important",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: `${theme.palette.misc["secondaryColorLighter"]} !important`,
      },
    },
  },
  header1: {
    width: "5px",
  },
  header2: {
    width: "calc(100% - 25px)",
    textOverflow: "ellipsis",
  },
  header3: {
    width: "10px",
  },
  header4: {
    width: "10px",
  },

  mainContainer: {
    padding: "0px 22px",
    height: "30px",
    display: "flex",
    alignItems: "center",
  },
  table: {
    width: "0px !important",
    backgroundColor: "white",
    // height: "100%",
  },
  tableMain: {
    // margin: " 50px 0px 20px 20px",
  },
  tableHead: {
    width: "100%",
  },
  tableHeadTitle: {
    // width: `calc(100% - 50px)`,
    boxSizing: "border-box",
    // maxWidth: "60px",
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginRight: "3px",
    textTransform: "uppercase",
  },
  tableHeadIcon: {
    // width: "10px !important",
  },

  tableRowHeader: {
    // paddingTop: "500px !important",
  },
  firstColumnTanuja: {
    backgroundColor: "inherit",
  },

  tableRowBody: {
    height: "25px",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: `${theme.palette.misc["primaryColorLightest"]} !important`,
    },
  },

  tableCellHeader: {
    // color: theme.palette.misc["secondaryColor"],
    // backgroundColor: "#CCD9E0",
    // borderRightStyle: "solid",
    // borderRightColor: "#dfdfdf",
    fontFamily: "Fira Sans",
    fontWeight: "500",
    fontSize: "12px !important",
    textAlign: "left",
    padding: "5px 11px 5px 0px",
    maxWidth: "200px !important",
    minWidth: "70px",
    // width: "70px !important",
    overflow: "hidden",
    whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    borderBottom: "white",
    backgroundColor: "white !important",
    color: theme.palette.misc["secondaryColor"],
  },

  firstHeader: {
    // display: "flex",
    minWidth: "180px",
    // alignItems: "center",
    // justifyContent: "space-between",
  },
  sticky: {
    position: "sticky",
    left: 0,
  },

  tableCell: {
    // width: "70px !important",
    maxWidth: "139px !important",
    minWidth: "70px !important",
    // borderRightStyle: "solid",
    // borderRightColor: "#dfdfdf",
    borderBottom: "white",
    fontFamily: "Fira Sans",
    fontSize: "11px !important",
    textAlign: "left",
    padding: "3px 11px 3px 11px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "pointer",
    color: theme.palette.misc["secondaryColorLight"],
  },
  rowSelectedHighlightedClassName: {
    backgroundColor: `${theme.palette.misc["primaryColorLightest"]} !important`,
  },
  tableCellFirst: {
    // width: "70px !important",
    // backgroundColor: "white",

    maxWidth: "100% !important",
    minWidth: "180px !important",
    // borderRightStyle: "solid",
    // borderRightColor: "#dfdfdf",
    borderBottom: "white",
    fontFamily: "Fira Sans",
    fontSize: "11px !important",
    textAlign: "left",
    // padding: "3px 11px 3px 11px",
    padding: "0px",
    height: "inherit",
    whiteSpace: "nowrap",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "pointer",
    color: theme.palette.misc["secondaryColorLight"],
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: `${theme.palette.misc["primaryColorLightest"]} !important`,
    },
  },
  tableData: {},
  elementActive: {
    // color: theme.palette.misc["primaryColor"],
    cursor: "pointer",
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  elementDisabled: {
    // color: theme.palette.misc["secondaryColor"],
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  tableColumns: {
    // border: "10px solid white",
    // background: "grey",
  },
  totalRow: {
    backgroundColor: "white !important",
  },
  popoverContainer: {
    fontFamily: "Fira Sans",
    color: theme.palette.misc["secondaryColorLight"],
    hoverColor: "blue",
    textAlign: "left",
    padding: "3px 3px 3px 3px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "pointer",
    fontSize: "12px !important",
    "&:hover": {
      backgroundColor: theme.palette.misc["secondaryColorLighter"],
    },
  },
}));

function MsvDimTableUI(props) {
  // * Destructure Props
  const {
    // REDUX PROPS
    dispatch: ReduxDispatcher,
    // PARENT PROPS
    memoizedStringedChildrenProps = "",
  } = props;
  const childrenProps = JSON.parse(memoizedStringedChildrenProps);
  const {
    id = "",
    data,
    selections = {},
    themeColors,
    allSelectedTableItem = [],
    selectedTableItemInChart = [],
    msvColorMapping = {},
  } = childrenProps;
  const { result = { dataFromQE: [], extraData: {} } } = data;
  const { dataFromQE = [] } = result;
  const { timeFilters, dimensionsList, metricsList } = selections;
  console.log("%c MsvDimTableUI - Re-Rendered", "color:pink; font-size: 20px");
  const [anchorEl, setAnchorEl] = useState(null);

  const standaloneMsv = useSelector((state) => state.standaloneMsv);

  // * Defining required state
  const [tableData, setTableData] = useState({ headers: [], data: [] }); //{headers: [], rows: []}
  const [tableRowData, setTableRowData] = useState({ data: [] });

  // * Defining required event handlers/util functions
  const handleError = (message) => {
    const dimData = {
      status: "error",
      message: message || config.hardCoded.uiErrorMessage,
      result: { dataFromQE: [], extraData: {} },
    };
    let payload = { dimId: id, dimData };
    let action = updateStandaloneMsvFormDimensionTableData(payload);
    ReduxDispatcher(action);
  };

  // const handleSelectedDimensionsInTable = (value) => {
  //   // Google Analytics Event - Master
  //   masterTrackGaEvent({
  //     category: "ZoomedView",
  //     action: "FiltersCount",
  //     label: "1",
  //   });

  //   let payload = {
  //     value,
  //   };
  //   let action = updateStandaloneMsvMsvTableRowClicked(payload);
  //   ReduxDispatcher(action);
  // };

  // const handleDataRowClick = (...args) => {
  //   const [event, copyValue = null, copyText = null] = args;
  //   if (event.type === "contextmenu") {
  //     event.preventDefault();
  //     setAnchorEl({
  //       currentTarget: event.currentTarget,
  //       copyText: copyText,
  //       copyValue: copyValue,
  //     });
  //   }
  // };

  // * Defining required side effects
  useEffect(() => {
    try {
      const newColumn = getWsDimTableColumns(
        dimensionsList,
        metricsList,
        timeFilters,
        [] // ! Hard coding percentCalList as empty array as it's not needed in MSV
      );

      const makeUiRowDataFriendlyForWsDimTableProps = {
        getDataResponse: dataFromQE,
        columns: newColumn[0],
      };
      const data = makeUiRowDataFriendlyForWsDimTable(
        makeUiRowDataFriendlyForWsDimTableProps
      );
      setTableData({ headers: newColumn[0], data: data });
      setTableRowData({ data });
    } catch (err) {
      console.error("UI ERROR in Table.js", err);
      handleError(config.hardCoded.uiErrorMessage);
    }
  }, [
    data.status,
    selections.timeFilters.comparisonType, // This will reload the table UI; no API call needed for this as data is already present in dataQE
    selections.timeFilters.isComparisonOn, // This will reload the table UI in case ONLY the comparison is turned off; in case it's turned on, API will be triggered
  ]);

  // Resizable Table first column
  // useEffect(() => {
  //   let table = document.querySelector(".MuiTable-root");
  //   let resizerCount = table.querySelectorAll(".resizer");

  //   if (resizerCount.length === 0) {
  //     createResizableTable({
  //       table,
  //       themeColors,
  //     });
  //   }
  // });

  // * Define required static variables
  let commomAccessor =
    tableData.headers.find((el) => el.id === id)?.accessor || "";
  const classes = useStyles();
  const tableHeaderProps = {
    classes,
    selections,
    id,
    themeColors,
  };
  const metricFiltersMetricIdsList = selections.metricFilters.map(
    (row) => row.metricId
  );
  const tableBodyClassName = "sigview-styled-scroller-thin";

  // * DEBUGGER
  // console.groupCollapsed(
  //   "DIM TABLE UI",
  //   props.selections.dimensionsList[0].dimTitle
  // );
  // console.log("tableData", tableData);
  // console.log("metricFiltersMetricIdsList", metricFiltersMetricIdsList);
  // console.log("tableRowData", tableRowData);
  // console.log("props", props);
  // console.groupEnd();

  // AgGrid column renderer
  const columnDefs = useMemo(() => {
    // Styles
    const box1 = { cursor: "pointer" };
    const box2 = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      paddingLeft: "20px",
      cursor: "pointer",
    };
    const box3 = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      color: "var(--negChangeColor)",
      cursor: "pointer",
      justifyContent: "center",
    };
    const box4 = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      color: "var(--posChangeColor)",
      cursor: "pointer",
      justifyContent: "center",
    };
    const box5 = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      paddingLeft: "20px",
      cursor: "pointer",
    };
    const box6 = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      color: "var(--negChangeColor)",
      cursor: "pointer",
    };
    const box7 = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      color: "var(--posChangeColor)",
      cursor: "pointer",
    };
    const box8 = { cursor: "pointer" };

    const cellStyle = {
      display: "block",
      //width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textAlign: "center",
      //textOverflow: "ellipsis",
    };
    const cellDimStyle = {
      display: "block",
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    };

    return tableData.headers.map((el, index) => {
      if (index === 0) {
        return {
          field: el.accessor,
          pinned: "left",
          width: 130,
          headerName: el.name,
          minWidth: 85,
          maxWidth: 250,
          resizable: true,
          lockPosition: true,
          suppressMovable: true,

          headerComponentParams: {
            // keyHeader: `${el.id}_${index}`,
            header: el,
            index: index,
            selections: selections,
            themeColors: themeColors,
            id: id,
            classes: classes,
            ReduxDispatcher: ReduxDispatcher,
          },

          // tooltipField: el.accessor,
          // tooltipComponentParams: { color: "#ececec" },
          cellRenderer: (p) => {
            return (
              <div
                onContextMenu={(event) =>
                  handleDataRowContextMenu(
                    event,
                    p.value.actualValue,
                    p.value.displayValue
                  )
                }
                style={box1}
              >
                <span style={cellDimStyle} title={p.value.actualValue}>
                  {p.value.displayValue}
                </span>
              </div>
            );
          },
        };
      }

      if (el.name === "Change" || el.name === "% Change") {
        return {
          field: el.accessor,
          headerName: el.name,
          width: 100,
          minWidth: 85,
          maxWidth: 250,
          resizable: true,
          lockPosition: true,
          suppressMovable: true,

          headerComponentParams: {
            // keyHeader: `${el.id}_${index}`,
            header: el,
            index: index,
            selections: selections,
            themeColors: themeColors,
            id: id,
            classes: classes,
            ReduxDispatcher: ReduxDispatcher,
          },

          cellRenderer: (p) => {
            if (p.value === undefined) {
              return <></>;
            } else {
              let slicedValue = p.value.actualValue?.slice("")[0];

              if (["0", "0.0", "0.00"].includes(p.value.actualValue)) {
                return (
                  <div style={box2}>
                    <span
                      style={cellStyle}
                      title={p.value.actualValue}
                      onContextMenu={(event) =>
                        handleDataRowContextMenu(
                          event,
                          p.value.actualValue,
                          p.value.displayValue
                        )
                      }
                    >
                      {p.value.displayValue}
                    </span>
                  </div>
                );
              }

              if (slicedValue === "-") {
                return (
                  <div style={box3}>
                    <span
                      class="material-icons-round"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>{" "}
                    <span
                      style={cellStyle}
                      title={p.value.actualValue}
                      onContextMenu={(event) =>
                        handleDataRowContextMenu(
                          event,
                          p.value.actualValue,
                          p.value.displayValue
                        )
                      }
                    >
                      {p.value.displayValue}
                    </span>
                  </div>
                );
              } else {
                return (
                  <div style={box4}>
                    <span
                      class="material-icons-round"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_up
                    </span>{" "}
                    <span
                      style={cellStyle}
                      title={p.value.actualValue}
                      onContextMenu={(event) =>
                        handleDataRowContextMenu(
                          event,
                          p.value.actualValue,
                          p.value.displayValue
                        )
                      }
                    >
                      {p.value.displayValue}
                    </span>
                  </div>
                );
              }
            }
          },
        };
      }

      return {
        field: el.accessor,
        headerName: el.name,
        width: 100,
        minWidth: 85,
        maxWidth: 250,
        resizable: true,
        lockPosition: true,
        suppressMovable: true,

        headerComponentParams: {
          // keyHeader: `${el.id}_${index}`,
          header: el,
          index: index,
          selections: selections,
          themeColors: themeColors,
          id: id,
          classes: classes,
          ReduxDispatcher: ReduxDispatcher,
        },

        cellRenderer: (p) => {
          return (
            <div
              onContextMenu={(event) =>
                handleDataRowContextMenu(
                  event,
                  p.value.actualValue,
                  p.value.displayValue
                )
              }
              style={box8}
            >
              <span style={cellStyle} title={p.value.actualValue}>
                {p.value.displayValue}
              </span>
            </div>
          );
        },
      };
    });
  }, [tableData]);
  // * Define required helper components
  const MsvDimTableHeader = (props = {}) => {
    // * Destructure props
    const {
      header = {},
      index = 0,
      selections = {},
      themeColors = {},
      id = "",
      classes = {},
      ReduxDispatcher,
    } = props;
    const { percentCalList } = selections;

    // * Define required states
    const [mouseIn, setMouseIn] = useState(false);

    // * Define event handlers
    const handleMouseEnter = () => setMouseIn(true);
    const handleMouseLeave = () => setMouseIn(false);
    const handleDimTableHeaderSort = (header, index) => {
      const sortType = index === 0 ? "_dimension" : header.id;
      const sortTypeId = header.accessor.includes("_trueDelta")
        ? "id_trueDelta"
        : header.accessor.includes("_deltaPercentage")
        ? "id_deltaPercentage"
        : "id_only";
      const value = {
        sortType: {
          id: sortType,
        },
        sortTypeId: {
          id: sortTypeId,
        },
        sortTypeValue: selections.orderBy === "asc" ? "desc" : "asc",
        dimId: id,
      };

      // Google Analytics Event - Master
      masterTrackGaEvent({
        category: "ZoomedView",
        action: "TableSort",
        label: value.sortTypeValue,
      });
      const payload = { value };
      const action = updateStandaloneMsvFormDataSort(payload);
      ReduxDispatcher(action);
    };
    const handlePercentageTotal = (header, flag) => {
      // Google Analytics Event - Master
      masterTrackGaEvent({
        category: "Workspace",
        action: "Table%ofTotal",
        label: flag ? "Show" : "Hide",
      });

      const payload = { metricId: header.id, flag, dimId: id };
      const action = updateStandaloneWsTogglePercentCalList(payload);
      ReduxDispatcher(action);
    };

    // * Define required variables
    let className = classes.tableCellHeader;
    if (index === 0) className += ` ${classes.sticky}`;
    const style = {
      zIndex: index === 0 ? 10 : 0,
    };
    var isSortedOnThisColumn = false;
    if (selections.orderById === "_dimension" && index === 0) {
      isSortedOnThisColumn = true;
    } else {
      if (selections.orderByType === "id_trueDelta") {
        if (
          selections.orderById === header.id &&
          header.accessor.endsWith("_trueDelta")
        ) {
          isSortedOnThisColumn = true;
        }
      } else if (selections.orderByType === "id_deltaPercentage") {
        if (
          selections.orderById === header.id &&
          header.accessor.endsWith("_deltaPercentage")
        ) {
          isSortedOnThisColumn = true;
        }
      } else {
        if (
          selections.orderById === header.id &&
          !header.accessor.includes("_trueDelta") &&
          !header.accessor.includes("_deltaPercentage") &&
          !header.accessor.includes("_percent")
        ) {
          isSortedOnThisColumn = true;
        }
      }
    }
    const arrowType =
      selections.orderById === header.id && selections.orderBy === "asc"
        ? "arrow_drop_up"
        : "arrow_drop_down";
    let displayValue = index === 0 ? "Name" : header.name;
    let percentCalListIds = percentCalList.map((row) => row._id);
    const expandTotalIconFlag =
      header.type === "metric" &&
      mouseIn &&
      !percentCalListIds.includes(header.id);
    const collapseTotalIconFlag = header.type === "percentTotal" && mouseIn;

    return (
      <Box
        className={`${className} tableHeader`}
        style={style}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span
          style={{
            display: "flex",
            justifyContent:
              displayValue === "Name" ? "space-between" : "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <span
            className={classes.tableHeadTitle}
            title={displayValue}
            style={{
              // width: "600px",
              // display: "inline-block",
              // width: true ? "max-content !important" : "60px",
              textAlign: "left",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              marginRight: "3px",
              fontSize: "11px",
              color: isSortedOnThisColumn
                ? themeColors["primaryColor"]
                : themeColors["secondaryColor"],
              paddingLeft: displayValue === "Change" ? "6px" : "12",
              fontWeight: "500",
            }}
          >
            {displayValue}
          </span>

          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>
              {isSortedOnThisColumn && header.type !== "percentTotal" && (
                <SigviewTooltip title="Toggle Sort" placement="bottom">
                  <Box>
                    <SigviewIcon
                      iconName={arrowType}
                      style={{
                        fontSize: "20px !important",
                        color: themeColors["primaryColor"],
                        hoverColor: themeColors["primaryColor"],
                        cursor: "pointer",
                      }}
                      onClick={() => handleDimTableHeaderSort(header, index)}
                      className={`material-icons-round MSVTable-${selections.orderBy}-GA`}
                    />
                  </Box>
                </SigviewTooltip>
              )}
              {!isSortedOnThisColumn &&
                mouseIn &&
                header.type !== "percentTotal" && (
                  <SigviewTooltip title="Toggle Sort" placement="bottom">
                    <Box>
                      <SigviewIcon
                        iconName={arrowType}
                        style={{
                          fontSize: "20px !important",
                          color: themeColors["primaryColor"],
                          hoverColor: themeColors["primaryColor"],
                          cursor: "pointer",
                        }}
                        onClick={() => handleDimTableHeaderSort(header, index)}
                        className={`material-icons-round MSVTable-${selections.orderBy}-GA`}
                      />
                    </Box>
                  </SigviewTooltip>
                )}
            </span>
          </span>
        </span>
      </Box>
    );
  };

  // Passing refereance to Header Component
  const components = useMemo(() => {
    return {
      agColumnHeader: MsvDimTableHeader,
    };
  }, []);
  const [rowData, setRowData] = useState();

  useEffect(() => {
    let mappedFilteredData = tableData.data.map((el) => {
      let mappedData = {};

      tableData.headers.map((cell, index) => {
        const formatDimValueWsDimTableProps = {
          row: el,
          selections,
          cell,
        };
        let dimValueResponse = formatDimValueWsDimTable(
          formatDimValueWsDimTableProps
        );

        mappedData[cell.accessor] = dimValueResponse;
      });

      return mappedData;
    });

    setRowData(mappedFilteredData);
  }, [tableData.data]);

  const handleOnRowClicked = (e) => {
    const payload = {
      dimId: id,
      value: e.data[selections.dimensionsList[0].dimID].actualValue,
    };
    const action = updateStandaloneMsvMsvTableRowClicked(payload);
    ReduxDispatcher(action);
  };

  const handleDataRowContextMenu = (...args) => {
    const [event, copyValue = null, copyText = null] = args;
    if (event.type === "contextmenu") {
      event.preventDefault();
      setAnchorEl({
        currentTarget: event.currentTarget,
        copyText: copyText,
        copyValue: copyValue,
      });
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCopyText = (event, condition) => {
    // ! Navigator is available only on a secure connection
    // ! It won't work locally
    // ! To test locally, use ngrok which creates a secure tunnel between local and internet
    if (window.navigator.clipboard) {
      navigator.clipboard.writeText(anchorEl[condition]);
    } else {
      console.error("UI ERROR");
      console.error("window.navigator.clipboard not available");
    }

    setAnchorEl(null);
  };

  let activeDimTitle = standaloneMsv.msvTable.value.title;
  let activeMsvTableId = standaloneMsv.msvTable.value.id;
  let activeDimId = standaloneMsv.msvTable.value.dimensionsList[0].dimID;
  const dimensionFiltersValue =
    useSelector((state) => state.standaloneMsv.dimensionFilters?.value).filter(
      (el) => el.id === activeMsvTableId
    )[0]?.values || [];

  const rowStyle = { background: "#FFFFFF" };

  const getRowStyle = (params) => {
    // if (params.data.country.actualValue === "USA") {
    //   return { "background-color": "yellow" };
    // }
    if (
      dimensionFiltersValue.length > 0 &&
      dimensionFiltersValue.includes(params.data[activeDimId].displayValue)
    ) {
      return {
        background: ` linear-gradient(to right,  ${
          standaloneMsv.msvColorMapping.value[
            params.data[activeDimId].displayValue
          ] || themeColors["primaryColorLightest"]
        } 0%,${
          standaloneMsv.msvColorMapping.value[
            params.data[activeDimId].displayValue
          ] || themeColors["primaryColorLightest"]
        } 3px,${themeColors["primaryColorLightest"]} 3px,${
          themeColors["primaryColorLightest"]
        } 100%)`,
      };
    }
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#fafafa" };
    }
  };

  const gridRef = useRef();

  const redrawAllRows = useCallback(() => {
    gridRef.current.api.redrawRows();
  }, []);

  useUpdateEffect(() => {
    redrawAllRows();
  }, [standaloneMsv.dimensionFilters.value]);

  return (
    <Box className={`${classes.root} ${tableBodyClassName}`}>
      <AgGridReact
        ref={gridRef}
        className="ag-theme-alpine"
        animateRows="true"
        rowBuffer={1}
        // suppressDragLeaveHidesColumns={true}
        columnDefs={columnDefs}
        components={components}
        suppressRowHoverHighlight={false}
        // defaultColDef={defaultColDef}
        // enableRangeSelection="true"
        rowData={rowData}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        rowSelection="multiple"
        suppressRowClickSelection="true"
        rowHeight={25}
        headerHeight={30}
        onRowClicked={(e) => handleOnRowClicked(e)}
        // onClick={() => {
        //   handleSelectedDimensionsInTable();
        // }} //accessor = publisherId
        // onGridReady={onGridReady}
      />
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl?.currentTarget}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.popoverContainer}
          onClick={(event) => {
            handleCopyText(event, "copyText");
          }}
        >
          Copy text
        </MenuItem>
        <MenuItem
          className={classes.popoverContainer}
          onClick={(event) => {
            handleCopyText(event, "copyValue");
          }}
        >
          Copy value
        </MenuItem>
      </StyledMenu>
    </Box>
  );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(memo(MsvDimTableUI));
