// Import Constants
import { validateBucketForm } from "../../utils/bucketUtils";
import C from "../constants";

// import { validateGroup } from "../../utils/adminUtils";

const registerBucket = (state, action) => {
  const { type = "", payload = {} } = action;
  const { key = "", value = "" } = payload;

  let newState = {};
  switch (type) {
    case C.UPDATE_REGISTER_BUCKET_FORM:
      switch (key) {
        case "name":
        case "storageName":
        case "type":
        case "path":
        case "accessKey":
        case "secretKey":
        case "region":
        case "emailId":
        case "privateId":
        case "privateKey":
        case "clientId":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };

          break;

        default:
          console.log("Something Went From Reducer");
      }
      break;

    case C.REPLACE_REGISTER_BUCKET_FORM:
      newState = { ...value };
      break;

    case C.VALIDATE_REGISTER_BUCKET_FORM:
      newState = { ...value };
      break;
  }

  // TODO Add validation function

  const validatedState = validateBucketForm(newState);

  return validatedState;
};

export { registerBucket };
