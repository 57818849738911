// Import required libraries
import { useEffect, useReducer, useContext } from "react";
import { useTranslation } from "react-i18next";

// Import library components
import { Stepper, Step, StepButton } from "@material-ui/core";

//import Sigview custom components
import SigviewButton from "../../components/Common/SigviewButton";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import utils
import {
  defaultFormValidator,
  getCurrentStepIndex,
  stepperStyles,
} from "../../utils/stepperUtils";
import { initialStepperState as defaultState } from "../../redux/stateData";
import stepperReducer from "../../redux/reducers/stepper";
import useReducerLogger from "../../utils/useReducerLogger";
import { masterTrackGaEvent } from "../../services/ga";

// Import action creators
import { changeStep } from "../../redux/actions";

function SigviewStepper(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    form = {},
    formValidator = defaultFormValidator,
    initialState = defaultState,
    children,
    onChange = () => {},
    renderButton,
    googleAnalytics = {},
    user = {},
  } = props;
  const {
    category: gaCat,
    action: gaAction = "",
    label: gaLabel = "",
  } = googleAnalytics;

  // Defining required state using reducer
  const [state, dispatch] = useReducer(useReducerLogger(stepperReducer), {
    ...initialState,
  });

  // Defining required side effects
  // Update Parent State
  useEffect(() => {
    onChange(state);
  }, [state]);

  // Defining required change handlers
  const handleNext = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "CreateDatastory",
      action: "StepChange",
      label: "Next",
    });
    const currStepIndex = getCurrentStepIndex(state);
    const stepsLen = state.steps.length; //> 3
    const nextStepIndex = (currStepIndex + 1) % stepsLen;
    const nextStepObject = steps[nextStepIndex];
    const payload = {
      id: nextStepObject.id,
      form,
      formValidator,
    };
    const action = changeStep(payload);
    dispatch(action);
  };

  const handleBack = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "CreateDatastory",
      action: "StepChange",
      label: "Previous",
    });
    const currStepIndex = getCurrentStepIndex(state);
    const nextStepIndex = currStepIndex - 1;
    const nextStepObject = steps[nextStepIndex];
    const payload = {
      id: nextStepObject.id,
      form,
      formValidator,
    };
    const action = changeStep(payload);
    dispatch(action);
  };

  const handleStep = (step) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "CreateDatastory",
      action: "StepChange",
      label: "Step",
    });
    const isSameStep = state.activeStep === step.id;
    const clickedStepIndex = state.steps.indexOf(step);
    const clickedStepMinusOne =
      clickedStepIndex - 1 < 0 ? 0 : clickedStepIndex - 1;
    const prevStep = state.steps[clickedStepMinusOne];
    const isPrevStepVisited = prevStep.visited.flag;
    const runFlag = !isSameStep && isPrevStepVisited;
    if (runFlag) {
      const payload = {
        id: step.id,
        form,
        formValidator,
      };

      const action = changeStep(payload);
      dispatch(action);
    }
  };

  // Defining required static variables
  const { steps, activeStep: activeStepId } = state;
  const activeStepIndex = getCurrentStepIndex(state);
  const backDisabled = activeStepIndex === 0;
  const nextDisabled = activeStepIndex === steps.length - 1;
  const Component = children[activeStepIndex];

  // Using Defined Styles
  const {
    makeSigviewStepperStyles,
    makeSigviewStepperMainContainerStyles,
    makeSigviewStepperBottomStyles,
  } = stepperStyles;
  const useSigviewStepperStyles = makeSigviewStepperStyles(themeColors);
  const stepperClasses = useSigviewStepperStyles();
  const {
    stepperRootContainer,
    stepButtonActive,
    stepButtonVisited,
    stepConnectorBase,
    stepConnectorVisited,
    stepButtonBase,
    stepperRoot,
  } = stepperClasses;
  const useSigviewMainContainerStyles =
    makeSigviewStepperMainContainerStyles(themeColors);
  const mainContainerClasses = useSigviewMainContainerStyles();
  const { mainContainer, mainContentRootContainer } = mainContainerClasses;
  const useSigviewBottomStyles = makeSigviewStepperBottomStyles(themeColors);
  const stepperBottomClasses = useSigviewBottomStyles();
  const { bottomRootContainer, bottomRightContainer } = stepperBottomClasses;

  return (
    <div className={mainContainer}>
      <section className={stepperRootContainer}>
        <Stepper
          className={stepperRoot}
          nonLinear
          alternativeLabel
          activeStep={activeStepIndex}
        >
          {steps.map((step, index) => {
            let stepButtonClass = stepButtonBase;
            let stepConnectorClass = stepConnectorBase;
            if (step.visited.flag === true) {
              stepButtonClass += " " + stepButtonVisited;
              stepConnectorClass += " " + stepConnectorVisited;
            }
            if (step.id === activeStepId) {
              stepButtonClass += " " + stepButtonActive;
            }
            return (
              <Step key={step.id} className={stepConnectorClass}>
                <StepButton
                  className={stepButtonClass}
                  onClick={() => handleStep(step, index)}
                  disableRipple={true}
                  id={`${step.name}-datastoryStepper-GA`}
                >
                  {t(step.name)}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </section>
      <section
        className={`${mainContentRootContainer} sigview-styled-scroller`}
      >
        {Component}
      </section>
      <section className={bottomRootContainer}>
        <div></div>
        <div className={bottomRightContainer}>
          {!backDisabled && (
            <SigviewButton
              disabled={backDisabled}
              onClick={handleBack}
              title="Previous"
              variant="outlined"
              customClassName="datastory-PreviousClick-GA"
            />
          )}
          {!nextDisabled && (
            <SigviewButton
              disabled={nextDisabled}
              variant="contained"
              onClick={handleNext}
              title="Next"
              customClassName="datastory-NextClick-GA"
              // style={{margin:{right:"15px"}}}
            />
          )}
          {renderButton()}
        </div>
      </section>
    </div>
  );
}

export default SigviewStepper;
