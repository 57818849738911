// Import required libraries
import React, { useState, useEffect } from "react";

// Import custom components
import AdvancedFiltersRow from "./AdvancedFiltersRow";

// Import styles

// Import config
import { config } from "../../config/config";

// Import action creators

// Import reducers and loggers

// Import utils & data
import { getMaxLimitForAdFiltersDropDownTypes } from "../../utils/filtersUtils";

function AdvancedFilters(props) {
  const {
    user,
    adFiltersStore,
    dispatchAdFiltersStore,
    showError,
    filterType,
    allFilterTypes,
    activeDimDatatype,
    allData,
    activeDimension,
  } = props;

  const [adFilterTypes, setAdFilterTypes] = useState(allFilterTypes);

  //Making required variables
  //CONFIGURABLE FEATURE
  const maxLimit = getMaxLimitForAdFiltersDropDownTypes(user.uiFeatureList);

  //Update adFilterTypes
  //remove elements from list whose maxLimit has exceeded
  useEffect(() => {
    //Making a dictionary of the current count (selectedStringTypesCount)
    //{ exactlyMatches: 1, startsWith: 8 }
    const selectedTypesCount = {};
    adFiltersStore.forEach((element) => {
      if (selectedTypesCount[element.type]) {
        selectedTypesCount[element.type] += 1;
      } else {
        selectedTypesCount[element.type] = 1;
      }
    });

    //Keeping only those entries whose count is less than maxLimit
    let newAdFilterTypes = [];
    allFilterTypes.forEach((element) => {
      //if the element exists in the dictionary
      //meaning if it has atleast one entry
      if (selectedTypesCount[element.id]) {
        //checking if the limit is less than the maxLimit
        if (selectedTypesCount[element.id] < element.maxLimit) {
          newAdFilterTypes.push(element);
        }
      } else {
        newAdFilterTypes.push(element);
      }
    });

    //Remove those IDs which are present in the overlapping IDs
    let removalIdList = [];
    Object.keys(selectedTypesCount).forEach((key) => {
      const elementObj = allFilterTypes.find(
        (adFilterTypeRow) => adFilterTypeRow.id === key
      );
      removalIdList = [...removalIdList, ...elementObj.partnerIds];
    });
    newAdFilterTypes = newAdFilterTypes.filter(
      (adFilterTypeRow) => !removalIdList.includes(adFilterTypeRow.id)
    );

    setAdFilterTypes(newAdFilterTypes);
  }, [adFiltersStore]);

  return (
    <>
      {adFiltersStore.map((adFilterRow, rowIndex) => (
        <AdvancedFiltersRow
          key={adFilterRow.id}
          {...adFilterRow}
          adFilterTypes={adFilterTypes}
          rowIndex={rowIndex}
          maxLimit={maxLimit}
          totalLength={adFiltersStore.length}
          filterType={filterType}
          dispatchAdFiltersStore={dispatchAdFiltersStore}
          showError={showError}
          allFilterTypes={allFilterTypes}
          activeDimDatatype={activeDimDatatype}
          allData={allData}
          user={user}
          activeDimension={activeDimension}
        />
      ))}
    </>
  );
}

export default AdvancedFilters;
