// Import required libraries
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import {
  createMuiTheme,
  ThemeProvider as MUIThemeProvider,
} from "@material-ui/core/styles";

// Import custom components
import AppAuth from "./AppAuth";

// Import required contexts
import { ThemeProvider } from "./contexts/ThemeContext";

// Import styles
import "./App.scss";

// Import store
import { store } from "./redux/storeInitializer";

// Import Config
import { config } from "./config/config";

// Import theme
import sigviewTheme from "./theme";

window.React = React;
window.store = store;

// Define theme
const theme = createMuiTheme(sigviewTheme);

function AppContainer() {
  console.log("STORE -> ", store.getState());
  useEffect(() => {
    // Get the subdomain name
    // const subdomain = window.location.hostname.split(".")[0];
    // const domain =
    //   window.location.hostname.split(".")[
    //     window.location.hostname.split(".").length - 2
    //   ];
    // const sub =
    //   domain === "sigview"
    //     ? subdomain === "local-openx"
    //       ? "openx"
    //       : "sigview"
    //     : subdomain;

    // ! new code for checking sigview and openx
    const hostname = window.location.hostname;
    const sigviewUserType =
      config.hardCoded.sigviewUrlUserTypeMapping[hostname] || "sigview";
    const gaSubdomain = sigviewUserType === "nonSigview" ? "openx" : "sigview";
    // Push the event and subdomain name to the dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "load_url_domain",
      subdomain: gaSubdomain, // Add the subdomain name to the dataLayer
    });
  }, []);

  return (
    <BrowserRouter>
      <MUIThemeProvider theme={theme}>
        {/* WE CAN REMOVE ThemeProvider ONCE ALL COMPONENTS START USING KIT */}
        <ThemeProvider>
          <Provider store={store}>
            <AppAuth />
          </Provider>
        </ThemeProvider>
      </MUIThemeProvider>
    </BrowserRouter>
  );
}
export default AppContainer;
