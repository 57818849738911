// Import required libraies
import React, { useEffect, useState, useContext } from "react";
import cloneDeep from "lodash/cloneDeep";
import Plotly from "plotly.js";
import _ from "underscore";

// Import Custom Component

// Import Context
import { ThemeContext } from "../../../contexts/ThemeContext";

// Import utils/data
import { config } from "../../../config/config";

// Import plotly utils
import {
  getPlotlyParams,
  buildXvalFromChartObject,
  buildYvalFromChartObject,
  buildSortbyFromChartObject,
  extractChartTypeFromChartObject,
} from "../../../utils/plotlyUtils";
import { transformFiltersUiToBackend } from "../../../utils/filtersUtils";

// Import API functions

function ChartUI(props) {
  // * Destructure Props
  const {
    data,
    onChange = () => {},
    user,
    allData,
    payload,
    chartContainerId,
    displayLayout,
    resizeFlag,
    selections = {},
  } = props;
  const {
    // status = "loading",
    // message = "",
    result = { dataFromQE: [], extraData: {} },
    // reload = false,
  } = data;
  const { dataFromQE = [], extraData = {} } = result;
  const { timeFilters = [], dimensionFilters = [] } = selections;

  // * Defining required hooks/contexts
  const { state: themeState } = useContext(ThemeContext);

  // * Defining required state
  const [windowResize, setWindowResized] = useState(false);

  // * Defining required event handlers/util functions
  const handleError = (message) => {
    onChange({
      status: "error",
      message: message || config.hardCoded.uiErrorMessage,
      result: { dataFromQE: [], extraData: {} },
    });
  };

  // * Defining required side effects
  // Plot the charts
  useEffect(() => {
    //Remove child element (plotly container) if it exists
    var plotlyChartContainer = document
      .getElementById(chartContainerId)
      .getElementsByClassName("plot-container");
    if (plotlyChartContainer.length) {
      plotlyChartContainer[0].remove();
    }

    const myDiv = document.getElementById(chartContainerId);
    if (myDiv && dataFromQE && extraData) {
      // Add style of height 100% just before plotting
      // Hack to bring the loader in the center
      if (resizeFlag !== undefined) myDiv.style.height = "100%";
      //We have data.reqpayloadCopy
      //We need to fetch dataFromQE
      //We need to extract xval //buildXvalFromChartObject
      //We need to extract yval //buildYvalFromChartObject
      //We need to extract chartType //extractChartTypeFromChartObject
      //We need to build sortBy from chartObject.requestParam.orderBy

      try {
        // Make a copy of the payload
        let payloadCopy = cloneDeep(payload);

        // Update time filters based on user selections
        payloadCopy = {
          ...payloadCopy,
          chartObject: {
            ...payloadCopy.chartObject,
            requestParam: {
              ...payloadCopy.chartObject.requestParam,
              timeZone: {
                name: timeFilters.selectedTimezone.name,
                location: timeFilters.selectedTimezone.location,
                value: timeFilters.selectedTimezone.value,
              },
              dateRange: {
                startDate: timeFilters.selectedDatesQE.startDate.toString(),
                endDate: timeFilters.selectedDatesQE.endDate.toString(),
              },
            },
            filter: transformFiltersUiToBackend([...dimensionFilters]),
          },
        };

        //Remove child element (plotly container) if it exists
        var plotlyChartContainer = document
          .getElementById(chartContainerId)
          .getElementsByClassName("plot-container");
        if (plotlyChartContainer.length) {
          plotlyChartContainer[0].remove();
        }
        var chartObject = payloadCopy.chartObject;
        var xval = buildXvalFromChartObject({
          chartObject,
          plotlyDimensions: allData.plotlyDimensions,
        });
        var yval = buildYvalFromChartObject({
          chartObject,
          plotlyMetrics: allData.plotlyMetrics,
        });
        var chartType = extractChartTypeFromChartObject(chartObject);
        var sortBy = buildSortbyFromChartObject({
          chartObject,
          plotlyMetrics: allData.plotlyMetrics,
          plotlyDimensions: allData.plotlyDimensions,
        });

        var plotlyParams = getPlotlyParams({
          dataFromQE: dataFromQE,
          xval,
          yval,
          extraData: extraData,
          chartType,
          chartDivHolderId: chartContainerId,
          sortBy,
          plotlyMetrics: allData.plotlyMetrics,
          plotlyDimensions: allData.plotlyDimensions,
          reqPayload: payloadCopy,
          user: user,
          selectedTimezone: user.timeFilters.selectedTimezone,
          themeState,
        });
        var layout = {
          ...plotlyParams.layout,
          margin: { l: 60, b: 60, r: 30, t: 30 },
        };
        // console.log(
        //   "FILTER ###################################################"
        // );
        // console.log(
        //   "MATCH THIS plotly title",
        //   payloadCopy?.chartObject?.metadata?.title
        // );
        // console.log("MATCH THIS plotly chartObject", chartObject);
        // console.log("MATCH THIS plotly xval", xval);
        // console.log("MATCH THIS plotly yval", yval);
        // console.log("MATCH THIS plotly chartType", chartType);
        // console.log("MATCH THIS plotly sortBy", sortBy);
        // console.log("MATCH THIS plotly extraData", extraData);
        // console.log("MATCH THIS plotly dataFromQE", dataFromQE);
        // console.log("MATCH THIS plotly plotlyParams data", plotlyParams.data);
        // console.log(
        //   "MATCH THIS plotly plotlyParams layout",
        //   plotlyParams.layout
        // );
        // console.log("MATCH THIS plotly layout", layout);
        // console.log(
        //   "MATCH THIS plotly plotlyParams configurations",
        //   plotlyParams.configurations
        // );
        Plotly.newPlot(
          myDiv,
          plotlyParams.data,
          layout,
          plotlyParams.configurations
        ).then(() => {
          plotlyParams.attach({ xval, data: plotlyParams.data, chartType });
        });
        myDiv.on("plotly_afterplot", () => {
          plotlyParams.attach({ xval, data: plotlyParams.data, chartType });
        });
      } catch (err) {
        console.groupCollapsed("UI ERROR");
        console.error(err);
        console.groupEnd();
        handleError(config.hardCoded.uiErrorMessage);
      }
    }
  }, [dataFromQE, extraData, windowResize, themeState]);

  // Remove the chart container when the component unmounts
  useEffect(() => {
    return () => {
      //Remove child element (plotly container) if it exists
      var chartContainer = document.getElementById(chartContainerId);
      if (chartContainer) {
        var plotlyChartContainer =
          chartContainer.getElementsByClassName("plot-container");
        if (plotlyChartContainer.length) {
          plotlyChartContainer[0].remove();
        }
      }
    };
  }, []);

  //Re-plot chart only when resizeFlag changes
  useEffect(() => {
    // For smooth transition of chart in plot page
    // What we are doing is we are replotting the chart every 1ms for 300ms
    // Post 300 ms, we are clearing that interval using setTimeout
    // We will also clear that interval if any of the depen changes (in clean-up function)
    const interval = setInterval(() => {
      const myDiv = document.getElementById(chartContainerId);
      if (myDiv && dataFromQE && extraData) {
        // Add style of height 100% just before plotting
        // Hack to bring the loader in the center
        if (resizeFlag !== undefined) myDiv.style.height = "100%";
        //We have data.reqpayloadCopy
        //We need to fetch dataFromQE
        //We need to extract xval //buildXvalFromChartObject
        //We need to extract yval //buildYvalFromChartObject
        //We need to extract chartType //extractChartTypeFromChartObject
        //We need to build sortBy from chartObject.requestParam.orderBy

        try {
          // Make a copy of the payload
          let payloadCopy = cloneDeep(payload);

          // Update time filters based on user selections
          payloadCopy = {
            ...payloadCopy,
            chartObject: {
              ...payloadCopy.chartObject,
              requestParam: {
                ...payloadCopy.chartObject.requestParam,
                timeZone: {
                  name: timeFilters.selectedTimezone.name,
                  location: timeFilters.selectedTimezone.location,
                  value: timeFilters.selectedTimezone.value,
                },
                dateRange: {
                  startDate: timeFilters.selectedDatesQE.startDate.toString(),
                  endDate: timeFilters.selectedDatesQE.endDate.toString(),
                },
              },
            },
          };

          //Remove child element (plotly container) if it exists
          var plotlyChartContainer = document
            .getElementById(chartContainerId)
            .getElementsByClassName("plot-container");
          if (plotlyChartContainer.length) {
            plotlyChartContainer[0].remove();
          }
          var chartObject = payloadCopy.chartObject;
          var xval = buildXvalFromChartObject({
            chartObject,
            plotlyDimensions: allData.plotlyDimensions,
          });
          var yval = buildYvalFromChartObject({
            chartObject,
            plotlyMetrics: allData.plotlyMetrics,
          });
          var chartType = extractChartTypeFromChartObject(chartObject);
          var sortBy = buildSortbyFromChartObject({
            chartObject,
            plotlyMetrics: allData.plotlyMetrics,
            plotlyDimensions: allData.plotlyDimensions,
          });

          var plotlyParams = getPlotlyParams({
            dataFromQE: dataFromQE,
            xval,
            yval,
            extraData: extraData,
            chartType,
            chartDivHolderId: chartContainerId,
            sortBy,
            plotlyMetrics: allData.plotlyMetrics,
            plotlyDimensions: allData.plotlyDimensions,
            reqPayload: payloadCopy,
            user: user,
            selectedTimezone: user.timeFilters.selectedTimezone,
            themeState,
          });
          var layout = {
            ...plotlyParams.layout,
            margin: { l: 60, b: 60, r: 30, t: 30 },
          };
          // console.log(
          //   "FILTER ###################################################"
          // );
          // console.log(
          //   "MATCH THIS plotly title",
          //   payloadCopy?.chartObject?.metadata?.title
          // );
          // console.log("MATCH THIS plotly chartObject", chartObject);
          // console.log("MATCH THIS plotly xval", xval);
          // console.log("MATCH THIS plotly yval", yval);
          // console.log("MATCH THIS plotly chartType", chartType);
          // console.log("MATCH THIS plotly sortBy", sortBy);
          // console.log("MATCH THIS plotly extraData", extraData);
          // console.log("MATCH THIS plotly dataFromQE", dataFromQE);
          // console.log("MATCH THIS plotly plotlyParams data", plotlyParams.data);
          // console.log(
          //   "MATCH THIS plotly plotlyParams layout",
          //   plotlyParams.layout
          // );
          // console.log("MATCH THIS plotly layout", layout);
          // console.log(
          //   "MATCH THIS plotly plotlyParams configurations",
          //   plotlyParams.configurations
          // );
          Plotly.newPlot(
            myDiv,
            plotlyParams.data,
            layout,
            plotlyParams.configurations
          ).then(() => {
            plotlyParams.attach({ xval, data: plotlyParams.data, chartType });
          });
          myDiv.on("plotly_afterplot", () => {
            plotlyParams.attach({ xval, data: plotlyParams.data, chartType });
          });
        } catch (err) {
          console.groupCollapsed("UI ERROR");
          console.error(err);
          console.groupEnd();
          handleError(config.hardCoded.uiErrorMessage);
        }
      }
    }, 1);

    clearInterval(interval);

    return () => {
      clearInterval(interval);
    };
  }, [resizeFlag, selections.chartType]);

  //Add resize event listener
  useEffect(() => {
    function handlePlotResize() {
      setWindowResized(new Date().valueOf());
    }
    window.addEventListener("resize", handlePlotResize);

    return function cleanupListener() {
      window.removeEventListener("resize", handlePlotResize);
    };
  }, []);

  //On change of display layout, replot the chart
  useEffect(() => {
    setWindowResized(new Date().valueOf());
  }, [displayLayout]);

  // Debugging
  // console.groupCollapsed("PlotlyChart.js");
  // console.log("resizeFlag", resizeFlag);
  // console.groupEnd();

  return (
    <>
      {/* {error ? (
        <ErrorHandler
          message={error}
          reloadFlag={true}
          onReload={() => {
            setReload(!reload);
          }}
        />
      ) : (
        !dataFromQE && !extraData && <Loader />
      )} */}
    </>
  );
}

export default ChartUI;
