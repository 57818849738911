// * Import required libraies
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Define required styles
const useStyles = makeStyles(() => ({
  root: {
    height: "30px",
    backgroundColor: "gray",
  },
}));

function SigviewBreadcrumbs(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const { data = [], onClick = () => {} } = props;

  // * Destructure style
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span>SigviewBreadcrumbs</span>
    </div>
  );
}

export default SigviewBreadcrumbs;
