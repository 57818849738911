// * Import required libraries
import React, { useContext, useEffect, useState, useReducer } from "react";

// * Import library components
import { Box, makeStyles } from "@material-ui/core";
import { Drawer } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

// * Import custom components
import SigviewButton from "../../../components/Common/SigviewButton";
import SigviewTooltip from "../../../components/Common/SigviewTooltip";
import SigviewIcon from "../../../components/Common/SigviewIcon";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import Loader from "../../../components/Loader/Loader";
// import SigviewSearchField from "../../../components/Common/SigviewSearchField";

// * Import action creators

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Import utils
import { config } from "../../../config/config";

// * Making styles for all variations
const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    height: "100vh",
    boxSizing: "border-box",
    width: "350px",
    backgroundColor: theme.palette.misc["sidenavBgColor"],
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 24px",
    boxSizing: "border-box",
    boxShadow: `0px 1px 1px -1px ${theme.palette.misc["secondaryColorLight"]}`,
    "&>.drawer-close-icon": {
      cursor: "pointer",
    },
    flexShrink: 0,
  },
  searchContainer: { padding: "12px 29px 12px 24px" },
  body: {
    padding: "15px 0px 15px 24px",
    flexGrow: 1,
    flexBasis: 0,
    display: "flex",
    flexDirection: "column",
  },
  bodyHead: { flexShrink: 0, paddingRight: "24px" },
  bodyContent: {
    flexGrow: 1,
    flexBasis: 0,
    overflowY: "overlay",
    paddingRight: "24px",
  },
  worksbookRow: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    padding: "10px",
    color: theme.palette.misc["primaryColor"],
  },
  worksheetRow: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    padding: "10px 20px",
    boxSizing: "border-box",
    marginBottom: "3px",
    height: "30px",

    "&:hover": {
      backgroundColor: theme.palette.misc["secondaryColorLightest"],
      cursor: "pointer !important",
      "& $worksheetActions": { display: "flex" },
      "& $worksheetTitle": { width: "80%" },
      // "& $worksheetActions": { visibility: "visible" }, // ! So that it doesn't get repainted; but not needed as it's not impacting the performance a lot
    },
  },
  activeWorksheetRow: {
    backgroundColor: theme.palette.misc["secondaryColorLightest"],
  },
  worksheetTitle: {
    cursor: "pointer !important",
    width: "100%",
    flexGrow: 1,
    flexBasis: 0,
    padding: "10px 0px",
  },
  worksheetActions: {
    // display: "flex",
    display: "none",
    alignItems: "center",
    justifyContent: "space-between",
    flexShrink: 1,
    // visibility: "hidden", // ! So that it doesn't get repainted; but not needed as it's not impacting the performance a lot
  },
  disabledItem: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  accordionRoot: {
    "& .MuiAccordionDetails-root": { padding: "0px" },
    "&.MuiAccordion-rounded": {
      borderRight: `1px solid ${theme.palette.misc["secondaryColorLighter"]}`,
      borderLeft: `1px solid ${theme.palette.misc["secondaryColorLighter"]}`,
      borderBottom: `1px solid ${theme.palette.misc["secondaryColorLighter"]}`,
      boxShadow: "none",
    },
    "&.MuiAccordion-rounded:first-child": {
      borderTop: `1px solid ${theme.palette.misc["secondaryColorLighter"]}`,
    },
    "&.Mui-expanded": {
      margin: "0px !important",
    },
    "& .MuiAccordionSummary-root": {
      padding: "0px 7px !important",
    },
    "& .MuiButtonBase-root": {
      minHeight: "25px !important",
    },
    "& .MuiAccordionSummary-content": {
      margin: "5px 0px !important",
    },
  },
}));

const WorksheetRow = (props = {}) => {
  // * Destructure props
  const {
    classes,
    workbook = {},
    worksheet = {},
    themeColors,
    eventHandlers = {},
    worksheetLength = 1,
    activeWorkspace = {},
  } = props;
  const {
    handleWorkspaceOpen: onWorkspaceOpen = () => {},
    handleWorkspaceRename: onWorkspaceRename = () => {},
    handleWorkspaceDelete: onWorkspaceDelete = () => {},
  } = eventHandlers;
  // ! OLD IMPLEMENTATION WHICH CREATED A BUG:
  // ! This was happening because isActive is driven by mongo
  // 1. OPEN 2 TABS SIMULTANEOUSLY WITH 2 DIFFERENT WORKSPACES
  // 2. In one of the tabs, delete a third one
  // 3. On successful delete, getAllWorkbooks is called again and 2 worksheets become active
  // const { isActive = false, sheetName = "" } = worksheet;
  // * NEW IMPLEMENTATION
  // isActive is driven by UI
  const { sheetName = "", sheetId = "" } = worksheet;
  const isActive = activeWorkspace?.payload?.worksheet?.sheetId === sheetId;

  // * Define required event handlers
  const handleWorkspaceOpen = () => {
    onWorkspaceOpen(workbook, worksheet);
  };
  const handleWorkspaceRename = () => {
    onWorkspaceRename(workbook, worksheet);
  };
  const handleWorkspaceDelete = () => {
    if (isActive) onWorkspaceDelete(workbook, worksheet, "mainPage");
    else onWorkspaceDelete(workbook, worksheet, "sidenav");
  };

  // * Define required static variables
  let rootClassName = classes.worksheetRow;
  if (isActive) rootClassName += ` ${classes.activeWorksheetRow}`;
  const titleStyle = {
    color: isActive
      ? themeColors["primaryColor"]
      : themeColors["secondaryColor"],
    height: "max-content",
    cursor: "pointer",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  var worksheetTitle = sheetName;
  if (isActive) worksheetTitle += ` (active)`;

  return (
    <Box className={rootClassName}>
      <Box className={classes.worksheetTitle} onClick={handleWorkspaceOpen}>
        <SigviewTypography
          variant="pMedium"
          style={titleStyle}
          title={worksheetTitle}
        >
          {worksheetTitle}
        </SigviewTypography>
      </Box>
      <Box className={classes.worksheetActions}>
        <SigviewIcon
          className={`material-icons-outlined workspaceRenameClicked-GA`}
          iconName="edit"
          style={{
            fontSize: "18px !important",
            padding: "0px 0px 0px 0px",
            color: themeColors["secondaryColorLight"],
            hoverColor: themeColors["primaryColor"],
            cursor: "pointer",
          }}
          tooltipProps={{ flag: true, title: "Rename" }}
          onClick={handleWorkspaceRename}
        />
        {worksheetLength > 1 && (
          <SigviewIcon
            className={`material-icons-outlined workspaceDeletdClicked-GA`}
            iconName="delete"
            style={{
              fontSize: "18px !important",
              padding: "0px 0px 0px 0px",
              color: themeColors["secondaryColorLight"],
              hoverColor: themeColors["failureColor"],
              cursor: "pointer",
            }}
            tooltipProps={{ flag: true, title: "Delete" }}
            onClick={handleWorkspaceDelete}
          />
        )}
        {!isActive && (
          <SigviewIcon
            className={`material-icons-outlined workspaceOpenInNewClicked-GA`}
            iconName="open_in_new"
            style={{
              fontSize: "18px !important",
              padding: "0px 0px 0px 0px",
              color: themeColors["secondaryColorLight"],
              hoverColor: themeColors["primaryColor"],
              cursor: "pointer",
            }}
            tooltipProps={{ flag: true, title: "Open" }}
            onClick={handleWorkspaceOpen}
          />
        )}
      </Box>
    </Box>
  );
};

const WorkbookRow = (props = {}) => {
  // * Destructure props
  const {
    classes,
    workbook = {},
    themeColors,
    eventHandlers = {},
    activeWorkspace = {},
  } = props;
  const { workbookName = "", worksheetList = [], isActive = false } = workbook;

  // * Define required states
  const [expanded, setExpanded] = useState(isActive);

  // * Define required event handlers
  const handleAccChange = () => setExpanded(!expanded);

  return (
    <Accordion expanded={expanded} className={classes.accordionRoot}>
      <AccordionSummary>
        <div className={classes.worksbookRow}>
          <SigviewTypography
            variant="pLarger"
            style={{
              height: "max-content",
              fontWeight: 500,
            }}
          >
            {workbookName}
          </SigviewTypography>

          <SigviewTooltip
            title={expanded ? "Click To Hide" : "Click To Show"}
            placement="bottom"
          >
            <Box>
              <SigviewIcon
                iconName={expanded ? "expand_less" : "expand_more"}
                onClick={handleAccChange}
                style={{
                  fontSize: "25px !important",
                  padding: "0 0 0 10px !important",
                  color: themeColors["secondaryColor"],
                  hoverColor: themeColors["secondaryColor"],
                  cursor: "pointer",
                }}
              />
            </Box>
          </SigviewTooltip>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {expanded && (
          <Box css={{ width: "100%" }}>
            {worksheetList.length === 0 && (
              <SigviewTypography
                variant="pLarge"
                style={{ height: "max-content", padding: "0px 0px 10px 15px" }}
              >
                No Worksheets Available
              </SigviewTypography>
            )}
            {worksheetList.length > 0 && (
              <>
                {worksheetList.map((row) => (
                  <WorksheetRow
                    key={row.sheetId}
                    workbook={workbook}
                    worksheet={row}
                    classes={classes}
                    eventHandlers={eventHandlers}
                    worksheetLength={worksheetList.length}
                    themeColors={themeColors}
                    activeWorkspace={activeWorkspace}
                  />
                ))}
              </>
            )}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

function AllWorkbooksDrawer(props = {}) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    workbooks = {},
    setReloadWorkbooks = () => {},
    isOpen = false,
    onCloseHandler = () => {},
    onChange = () => {},
    settings = {},
    eventHandlers = {},
    activeWorkspace = {},
  } = props;
  const { handleCreateNew = () => {} } = eventHandlers;

  // * Define required states
  const [metricSearch, setMetricSearch] = useState("");

  // * Define required side effects

  // * Define required event handlers
  const handleSearchFieldChange = (event, value) => setMetricSearch(value);

  const handleOnClose = (event) => {
    onCloseHandler(false);
  };

  const handleWorkbooksReload = () => setReloadWorkbooks(Date.now());

  // * Define required static variables
  const classes = useStyles();
  const leftDrawerClasses = { className: classes.drawerRoot };

  // * DEBUGGER
  // console.groupCollapsed("AllWorkbooksDrawer.js");
  // console.log("workbooks", workbooks);
  // console.groupEnd();

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={handleOnClose}
      PaperProps={{ ...leftDrawerClasses }}
    >
      <Box className={classes.header}>
        <SigviewTypography variant="pLarger" style={{ height: "max-content" }}>
          My Workspaces
        </SigviewTypography>
        <span
          className="material-icons sigview-close-icon"
          onClick={handleOnClose}
        >
          close
        </span>
      </Box>
      {/* <Box className={searchContainer}>
        <SigviewSearchField
          onChange={handleSearchFieldChange}
          placeholder="Search a Metric"
        />
      </Box> */}
      <Box className={`${classes.body}`}>
        <Box className={classes.bodyHead}>
          <SigviewButton
            //   variant="containedPrimary"
            customClassName="createNewWorkspace-GA"
            style={{
              btnWidth: "100%",
              btnHeight: "40px",
              fontSize: "14px !important",
              margin: { bottom: "15px" },
            }}
            onClick={handleCreateNew}
            title="Create New Workspace"
          />
        </Box>
        <Box className={`${classes.bodyContent} sigview-styled-scroller-thin`}>
          {workbooks.status === "success" &&
            workbooks.result.workbookList.length > 0 && (
              <>
                {workbooks.result.workbookList.map((row) => (
                  <WorkbookRow
                    key={row._id}
                    workbook={row}
                    classes={classes}
                    themeColors={themeColors}
                    eventHandlers={eventHandlers}
                    activeWorkspace={activeWorkspace}
                  />
                ))}
              </>
            )}
          {workbooks.status === "success" &&
            workbooks.result.workbookList.length === 0 && (
              <div className="no-data-container">
                <div className="no-data-image"></div>
                <p className="no-data">{config.messages.noWs}</p>
              </div>
            )}
          {workbooks.status === "loading" && <Loader />}
          {workbooks.status === "error" && (
            <ErrorHandler
              message={workbooks.message}
              reloadFlag={true}
              onReload={handleWorkbooksReload}
            />
          )}
        </Box>
      </Box>
    </Drawer>
  );
}

export default AllWorkbooksDrawer;
