// Import required libraies
import React, { useState } from "react";
import { v4 } from "uuid";

// Import Custom Component
import Loader from "../../../components/Loader/Loader";
import TimeFilters from "../../../components/TimeFilters/TimeFilters";
import SigviewButtonSplit from "../../../components/Common/SigviewButtonSplit";
import SigviewTextFieldAsync from "../../../components/Common/SigviewTextFieldAsync";
import SigviewTextField from "../../../components/Common/SigviewTextField";
import SigviewButton from "../../../components/Common/SigviewButton";
import SigviewSimpleText from "../../../components/Common/SigviewSimpleText";
import LayoutMenuBar from "../../../layouts/LayoutMenuBar/LayoutMenuBar";

// Import action creators
import { updateUserScreen } from "../../../redux/actions";

// Import utils/data
import { masterMakeChartObject } from "../../../utils/utils";
import { validateChartName } from "../../../utils/chartObjectUtils";
import { masterTrackGaEvent } from "../../../services/ga";

// Import API functions
import { renameChart, saveChart, chartNameCheck } from "../../../services/api";

const PlotMenuBar = (props) => {
  const {
    ReduxDispatcher,
    user,
    allData,
    chartName,
    setChartName,
    menuBarSplitButtonOptions,
    standaloneChart,
    isEdit = false,
    onTimeFiltersChange = () => {},
  } = props;

  // Making required variables
  const gaCategory = isEdit ? "EditChart" : "CreateChart";

  //Making requried event handlers
  const handleChartNameChange = (event, value) => {
    setChartName((prevState) => ({ ...prevState, value }));
  };

  const handleClickAway = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: gaCategory,
      action: "Rename",
      label: "Chart",
    });

    const activeChart = { ...standaloneChart };
    // Making required active variables
    //If entered chartName is invalid
    const { status, message } = validateChartName(chartName.value);
    if (status === "invalid") {
      setChartName((prevState) => ({
        ...prevState,
        status: "success",
        value: prevState.originalValue,
      }));
      let snackbarPayload = {
        ...user.screen.snackbar,
        open: true,
        message: message,
      };
      const action = updateUserScreen("snackbar", snackbarPayload);
      ReduxDispatcher(action);
    } else {
      // Do this only when the names are different otherwise
      // it will run for every click on the screen
      if (chartName.originalValue !== chartName.value) {
        // Make an API call only when the element type is update
        if (activeChart.elementType === "update") {
          setChartName((prevState) => ({ ...prevState, status: "loading" }));
          const fetchPayload = {
            orgViewReq: {
              organization: user?.reqMetadata?.organization,
              view: user?.reqMetadata?.view,
            },
            id: activeChart.id,
            title: chartName.value,
          };
          const fetchProps = {
            payload: fetchPayload,
          };
          const renameChartPromise = renameChart(fetchProps);
          renameChartPromise
            .then((responseData) => {
              setChartName((prevState) => ({
                ...prevState,
                status: "success",
                originalValue: prevState.value,
              }));
              // Commenting it out as it's distracting
              // let snackbarPayload = {
              //   ...user.screen.snackbar,
              //   open: true,
              //   message: "Chart renamed successfully!",
              // };
              // const action = updateUserScreen("snackbar", snackbarPayload);
              // ReduxDispatcher(action);
            })
            .catch((json) => {
              console.groupCollapsed("API FAILED");
              console.log("Error JSON -> ", json);
              console.groupEnd();
              setChartName((prevState) => ({
                ...prevState,
                status: "success",
                value: prevState.originalValue,
              }));
              let snackbarPayload = {
                ...user.screen.snackbar,
                open: true,
                message: json.error || "Rename Failed",
              };
              const action = updateUserScreen("snackbar", snackbarPayload);
              ReduxDispatcher(action);
            });
        } else if (activeChart.elementType === "create") {
          // Call name-check API if the element type is create
          setChartName((prevState) => ({ ...prevState, status: "loading" }));
          const fetchPayload = {
            orgViewReq: {
              organization: user?.reqMetadata?.organization,
              view: user?.reqMetadata?.view,
            },
            name: chartName.value,
            emailId: user?.reqMetadata?.email,
          };
          const fetchProps = {
            payload: fetchPayload,
          };
          const chartNameCheckPromise = chartNameCheck(fetchProps);
          chartNameCheckPromise
            .then((responseData) => {
              const isChartTitleExist = responseData.result.data;
              if (isChartTitleExist) {
                throw new Error();
              } else {
                setChartName((prevState) => ({
                  ...prevState,
                  status: "success",
                  originalValue: prevState.value,
                }));
              }
            })
            .catch((json) => {
              console.groupCollapsed("API FAILED");
              console.log("Error JSON -> ", json);
              console.groupEnd();
              setChartName((prevState) => ({
                ...prevState,
                status: "success",
                value: prevState.originalValue,
              }));
              let snackbarPayload = {
                ...user.screen.snackbar,
                open: true,
                message: json.error || "Chart name already exists",
              };
              const action = updateUserScreen("snackbar", snackbarPayload);
              ReduxDispatcher(action);
            });
        } else {
          // Don't make the API call if the element type is not update
          setChartName((prevState) => ({
            ...prevState,
            status: "success",
            originalValue: prevState.value,
          }));
        }
      }
    }
  };

  //Making required variables
  const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarDashboard;
  const timeFiltersSettings = {
    compareCalendarRowLimit: 1, //hard coded; it will come from /getUserAccessList
    isMultiCompareRowAllowed: false, //hard coded; it will come from /getUserAccessList
    isComparisonAvailable: false, //hard coded; it should come from where this component is being called
    isChangeTypeDropdownAvailable: true,
    isApplyButtonAvailable: false,
  };
  const textFieldCustomStyle = {
    wrapperWidth: "100%",
  };

  return (
    <LayoutMenuBar>
      <SigviewTextFieldAsync
        value={chartName.value}
        onChange={handleChartNameChange}
        status={chartName.status}
        onClickAway={handleClickAway}
        customStyle={textFieldCustomStyle}
        tooltipTitle="Type and press ENTER to rename"
      />
      {standaloneChart.timeFilters.isLoading ? (
        <Loader />
      ) : (
        <div id="menuBarTimeFiltersContainer">
          <TimeFilters
            user={user}
            allData={allData}
            initialTimeFilters={standaloneChart.timeFilters}
            onChange={onTimeFiltersChange}
            googleAnalytics={{ category: gaCategory }}
            calendarDaysLimits={calendarDaysLimits}
            settings={timeFiltersSettings}
          />
        </div>
      )}
      <>
        {menuBarSplitButtonOptions.length > 1 && (
          <SigviewButtonSplit
            options={menuBarSplitButtonOptions}
            customClassName="Chart-GA"
          />
        )}
        {menuBarSplitButtonOptions.length === 1 && (
          <SigviewButton
            id={menuBarSplitButtonOptions[0]["id"]}
            title={menuBarSplitButtonOptions[0]["label"]}
            disabled={menuBarSplitButtonOptions[0]["disabled"]}
            onClick={menuBarSplitButtonOptions[0]["onClick"]}
            customClassName="Chart-Saved-GA"
          />
        )}
      </>
    </LayoutMenuBar>
  );
};

const SigviewDialogChartClone = (props) => {
  const {
    user,
    ReduxDispatcher,
    initialName,
    standaloneChart,
    isEdit = false,
  } = props;
  // Defining required state
  const [name, setName] = useState({
    value: initialName,
    status: "success", // success/error/loading
    message: "",
  });

  // Making required variables
  const gaCategory = isEdit ? "EditChart" : "CreateChart";

  // Defining required event handlers
  const handleNameChange = (newValue) => {
    setName(() => {
      const { status, message } = validateChartName(newValue);
      let finalStatus = "success";
      if (status === "invalid") finalStatus = "error";
      const newState = { status: finalStatus, message, value: newValue };
      return newState;
    });
  };

  const handleCancel = () => {
    const payload = { open: false, children: null };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleClone = (type = "clone") => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: gaCategory,
      action: "CloneMenu",
      label: type,
    });

    const newChartId = `chart-object-${v4()}`;
    const activeChart = { ...standaloneChart };
    const chartObject = masterMakeChartObject({
      metadataParams: {
        title: name.value,
        chartType: activeChart.chartType,
        comparisonMode: false,
      },
      filters: {
        timeFilters: activeChart.timeFilters,
        metricFilters: activeChart.metricFilters,
        dimensionFilters: activeChart.dimensionFilters,
      },
      orderByDetails: {
        orderById: activeChart.orderById,
        orderBy: activeChart.orderBy,
        orderByType: "id_only",
      },
      dimensionsList: activeChart.dimensionsList,
      metricsList: activeChart.metricsList,
      settings: { compareFlag: false, progressiveDateFlag: true },
    });
    const payload = {
      _id: newChartId,
      emailId: user?.reqMetadata?.email,
      orgViewReq: {
        organization: user?.reqMetadata?.organization,
        view: user?.reqMetadata?.view,
      },
      chartObject,
    };
    const fetchProps = {
      payload: { ...payload },
    };
    setName((prevState) => ({ ...prevState, status: "loading" }));
    const saveChartPromise = saveChart(fetchProps);
    saveChartPromise
      .then(() => {
        // Update local status to success
        setName((prevState) => ({ ...prevState, status: "success" }));
        // Close the dialog
        handleCancel();
        // Update snackbar
        const snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Chart cloned successfully!",
        };
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);

        // If type is cloneLaunch, update activeChart
        if (type === "cloneLaunch") {
          const newActiveChart = {
            ...activeChart,
            id: newChartId,
            title: name.value,
          };
          var action = updateUserScreen("activeChart", newActiveChart);
          ReduxDispatcher(action);
        }
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        const message = json.error || "Cloning chart failed";
        // Update local status to error
        setName((prevState) => ({ ...prevState, status: "error", message }));
      });
  };

  // Defining required variables
  const errorFlag = name.status === "error";
  const helperText = name.message;
  const cloneDisabled = ["error", "loading"].includes(name.status);
  const cloneTitle = name.status === "loading" ? <Loader /> : "Clone";
  const cloneLaunchTitle =
    name.status === "loading" ? <Loader /> : "Clone & Launch";

  return (
    <section className="sigview-dialog-chart-clone">
      <SigviewSimpleText
        value="Enter chart name"
        style={{
          border: "none",
          fontSize: "16px",
          margin: "0px 0px 15px 0px",
          padding: "0px",
        }}
      />
      <SigviewTextField
        placeholder="Please enter a valid chart name"
        value={name.value}
        onChange={(newValue) => handleNameChange(newValue)}
        error={errorFlag}
        helperText={helperText}
        minWidth="400px"
        maxWidth="400px"
        height="40px"
        fontSize="12px"
        margin={{ right: "5px", bottom: "10px" }}
      />
      <article className="sigview-dialog-chart-clone-menu-bar">
        <SigviewButton
          variant="outlined"
          onClick={handleCancel}
          title="Cancel"
          style={{ width: "auto", margin: { left: "3px", right: "3px" } }}
        />
        <SigviewButton
          variant="contained"
          onClick={() => handleClone("clone")}
          disabled={cloneDisabled}
          customClassName="ChartClone-GA"
          title={cloneTitle}
          style={{ width: "auto", margin: { left: "3px", right: "3px" } }}
        />
        <SigviewButton
          variant="contained"
          onClick={() => handleClone("cloneLaunch")}
          disabled={cloneDisabled}
          customClassName="ChartCloneLaunch-GA"
          title={cloneLaunchTitle}
          style={{
            width: "max-content",
            margin: { left: "3px", right: "3px" },
          }}
        />
      </article>
    </section>
  );
};

export { PlotMenuBar, SigviewDialogChartClone };
