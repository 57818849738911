// Import required libraies
import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import orderBy from "lodash.orderby";
import Popover from "@material-ui/core/Popover";
import { useTranslation } from "react-i18next";

// Import Custom Component
import SigviewDnD from "./SigviewDnD";
import SigviewAutoCompleteMulti from "./SigviewAutoCompleteMulti";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import utils/data
import { masterTrackGaEvent } from "../../services/ga";

// Import styles
import "./SigviewMultiSelectDnD.scss";

// Import action creators

const renderOptionAutoCompleteDefault = (props) => {
  const { option, allSelectedIds, idAccessor, labelAccessor } = props;
  const selectedFlag = allSelectedIds.includes(option[idAccessor]);
  return (
    <>
      <i
        className={`material-icons auto-complete-option-icon ${
          selectedFlag ? "" : "invisible"
        } `}
      >
        check_circle
      </i>
      <span
        className="quantity-selector-option-title"
        title={option[labelAccessor]}
      >
        {option[labelAccessor]}
      </span>
    </>
  );
};

function SigviewMultiSelectDnD(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    data = [],
    value = [],
    onChange = () => {},
    title = "Metrics",
    bgColor = "#eff2f5",
    borderColor = "1px solid #c5cfd9",
    customPadding,
    idAccessor = "_id",
    labelAccessor = "measureTitle",
    variant = "horizontal",
    googleAnalytics = { category: "ReportManager" },
    user = {},
    renderOptionAutoComplete = renderOptionAutoCompleteDefault,
    disabled = false,
    customClassName,
  } = props;

  //Defining required states
  const [anchorEl, setAnchorEl] = useState(null);

  // Define styles
  const useStyles = makeStyles(() => ({
    root: {
      zIndex: "999999999 !important",
      "& .MuiPaper-root": {
        backgroundColor: `${themeColors["popoverBgColor"]} !important`,
      },
    },
    dateRangeContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
  }));

  //Defining required hooks
  const classes = useStyles();

  //Defining required variables
  const open = Boolean(anchorEl);
  const id = open ? "sigviewMultiSelectDnd" : undefined;
  const customStyleSigviewDnd = {
    droppable: {
      padding: "0px",
      backgroundColorDuringDragging: "transparent",
      backgroundColor: "transparent",
    },
    draggable: {
      backgroundColorDuringDragging:
        bgColor || themeColors["secondaryColorLighter"],
      backgroundColor: bgColor || themeColors["secondaryColorLightest"],
      marginRight: variant === "horizontal" ? "5px" : "0px", //for horizontal
      marginBottom: variant === "vertical" ? "5px" : "0px", //for vertical
      borderRadius: "3px",
      border:
        borderColor || `1px solid ${themeColors["secondaryColorLighter"]}`,
    },
  };
  const customStyleAutoComplete = {
    popOver: { maxHeight: "200px" },
    popupIndicatorOpen: { display: "none" },
    popupIndicator: { display: "none" },
  };

  // const valueIds = value.map((row) => row[idAccessor]);
  // const dataFilteredOptions = data.filter(
  //   (row) => !valueIds.includes(row[idAccessor])
  // );
  // const reorderedDataOptions = [
  //   ...orderBy(value, [labelAccessor], ["asc"]),
  //   ...orderBy(dataFilteredOptions, [labelAccessor], ["asc"]),
  // ];
  const clearAllClassName = value.length > 0 ? "visible" : "react-invisible";
  const titleClass = title.length === 0 ? "no-title" : "";

  //Defining required handle functions
  const handleDnDChange = (newData) => onChange(newData);

  const handleDnDItemDelete = (id) => {
    const newData = value.filter((row) => row[idAccessor] !== id);
    onChange(newData);
  };

  const handleAutoCompleteChange = (newValue) => {
    onChange(newValue);
  };

  const handlePopoverOpen = (event) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: googleAnalytics.category,
      action: "AddValue",
      label: title,
    });
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClearAll = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: googleAnalytics.category,
      action: "RemoveAllValues",
      label: title,
    });
    onChange([]);
  };

  //Defining required render props
  const RenderCompDnD = (item) => {
    const useStyles = makeStyles(() => ({
      root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: customPadding || "3px",
        borderRadius: "50px",
        fontFamily: "Fira Sans !important",
      },
      titleContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      title: {
        margin: "0px 3px 0px 0px !important",
        width: "calc(100% - 16px)",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: "11px",
        color: themeColors["secondaryColor"],
        textTransform: "capitalize",
        // marginRight: "5px !important", // Doesn't work here because of App.scss
      },
      hamburgerIcon: {
        // cursor: "pointer",
        fontSize: "14px !important",
        color: themeColors["secondaryColor"],
        marginRight: "5px",
      },
      deleteIcon: {
        cursor: "pointer",
        fontSize: "14px !important",
        color: themeColors["secondaryColorLight"],
        marginLeft: "5px !important",
        "&:hover": {
          color: themeColors["failureColor"],
        },
      },
    }));

    const classes = useStyles();

    return (
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <i
            // onClick={() => {
            //   handleDnDItemDelete(item[idAccessor]);
            // }}
            className={`${classes.hamburgerIcon} material-icons-outlined`}
          >
            menu
          </i>
          <p className={classes.title} title={item[labelAccessor]}>
            {item[labelAccessor]}
          </p>
        </div>
        {!disabled && (
          <i
            onClick={() => {
              handleDnDItemDelete(item[idAccessor]);
            }}
            className={`${classes.deleteIcon} material-icons-outlined`}
            title="Remove"
          >
            remove_circle_outline
          </i>
        )}
      </div>
    );
  };

  const renderOptionSigviewAutoComplete = (option) =>
    renderOptionAutoComplete({
      option,
      allSelectedIds: value.map((row) => row[idAccessor]),
      idAccessor,
      labelAccessor,
    });

  //Debugging
  // console.groupCollapsed("SigviewMultiSelectDnD");
  // console.log("data", data);
  // console.log("value", value);
  // console.log("autoCompleteValues", autoCompleteValues);
  // console.groupEnd();

  return (
    <div
      //className={`${variant === vertical ? sigview - multi - select - dnd - container - report : sigview - multi - select - dnd - container ${variant}`}
      className={`${
        variant === "vertical"
          ? `sigview-multi-select-dnd-container-report ${variant}`
          : `sigview-multi-select-dnd-container ${variant}`
      }`}
    >
      {variant === "horizontal" && (
        <>
          <div
            className={`sigview-multi-select-dnd-title-container ${titleClass} ${customClassName}`}
          >
            <div
              className={`sigview-multi-select-dnd-title ${titleClass} ${customClassName}`}
            >
              {t(title)}
            </div>
            {!disabled && (
              <i
                className={`material-icons sigview-multi-select-dnd-values-popover-icon ${customClassName}`}
                onClick={handlePopoverOpen}
              >
                add_circle
              </i>
            )}
          </div>
          <div
            className={`sigview-multi-select-dnd-dnd-container ${titleClass} ${customClassName}`}
          >
            <SigviewDnD
              data={value}
              idAccessor={idAccessor}
              onChange={handleDnDChange}
              render={RenderCompDnD}
              direction={variant}
              customStyle={customStyleSigviewDnd}
              disabled={disabled}
            />
          </div>
          {!disabled && (
            <i
              className={`material-icons-outlined sigview-multi-select-dnd-values-clear-all-icon ${clearAllClassName}`}
              onClick={handleClearAll}
              title="Clear All"
            >
              cancel
            </i>
          )}
        </>
      )}
      {variant === "vertical" && (
        <>
          <div className="sigview-multi-select-dnd-title-container">
            <div className="sigview-multi-select-dnd-title-popovericon-container">
              {!disabled && (
                <i
                  className={`material-icons sigview-multi-select-dnd-values-popover-icon ${customClassName}`}
                  onClick={handlePopoverOpen}
                >
                  add_circle
                </i>
              )}
              <div className="sigview-multi-select-dnd-title">{title}</div>
            </div>{" "}
            {!disabled && (
              <i
                className={`material-icons-outlined sigview-multi-select-dnd-values-clear-all-icon ${clearAllClassName}`}
                onClick={handleClearAll}
                title="Clear All"
              >
                cancel
              </i>
            )}
          </div>
          <div
            className={`sigview-multi-select-dnd-dnd-container ${titleClass}`}
          >
            <SigviewDnD
              data={value}
              idAccessor={idAccessor}
              onChange={handleDnDChange}
              render={RenderCompDnD}
              direction={variant}
              customStyle={customStyleSigviewDnd}
              disabled={disabled}
            />
          </div>
        </>
      )}

      {open && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className={classes.root}
        >
          <div className="sigview-multi-select-dnd-values-popup-container">
            <p className="sigview-multi-select-dnd-values-popup-container-title">
              {t(title)}
            </p>
            <div className="sigview-multi-select-dnd-autocomplete-container">
              <SigviewAutoCompleteMulti
                data={data}
                value={value}
                keepAlwaysOpen={true}
                renderOption={renderOptionSigviewAutoComplete}
                onChange={handleAutoCompleteChange}
                renderTags={() => {}}
                idAccessor={idAccessor}
                labelAccessor={labelAccessor}
                customStyle={customStyleAutoComplete}
                multiple={true}
                clearInputOnSelection={false}
                disableClearable={true}
                disabled={disabled}
              />
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
}

export default SigviewMultiSelectDnD;
