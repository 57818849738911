// * Import required libraies
import React, { useState, useContext, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

// * Import Custom Component
import SigviewTooltip from "../SigviewTooltip";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";
import { FormHelperText } from "@material-ui/core";

// * Define required styling utils
const makeSigviewStyle = (themeColors, customStyle) => {
  const {
    // wrapperMinWidth = "200px",
    wrapperHeight = "30px",
    wrapperBgColor = themeColors["secondaryColorLightest"],
    wrapperMargin = "0px 10px 0px 0px",
    textFieldFontSize = "14px",
    iconSize = "15px",
    placeholderFontSize = "12px",
    wrapperBorderHover = `1px solid ${themeColors["secondaryColorLighter"]}`,
    wrapperBorderFocus = `1px solid ${themeColors["secondaryColorLight"]}`,
    wrapperBorderColor = `${themeColors["headerBgColor"]}`,
    wrapperPadding = "0px 10px",
  } = customStyle;

  let textFieldWidth = "100%";
  let textFieldPadding = "0px";

  //Define styles
  const useSigviewStyles = makeStyles({
    root: {
      width: "100%",
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      border: `1px solid ${wrapperBorderColor}`,
      boxSizing: "border-box",
      // width: wrapperWidth,
      width: "100%",
      height: wrapperHeight,
      // height: "30px",
      borderRadius: "3px",
      padding: wrapperPadding,
      // border: `1px solid ${themeColors["secondaryColorLightest"]}`,
      boxSizing: "border-box",
      "&:hover": { border: wrapperBorderHover },
      "&:focus-within": { border: wrapperBorderFocus },
      backgroundColor: wrapperBgColor,
      margin: wrapperMargin,
      //   minWidth: "120px",
      //   maxWidth: "400px",
      //   height: "32px",
      //   marginLeft: "0px",
      //   marginRight: "0px",
    },
    iconWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "20px",
      height: "20px",
      boxSizing: "border-box",
      "& span": {
        color: themeColors["secondaryColorLighter"],
        fontSize: `${iconSize} !important`,
      },
    },
    postIconWrapper: { cursor: "pointer" },
    textField: {
      pointerEvents: "auto",
      height: "100%",
      width: textFieldWidth,
      boxSizing: "border-box",
      fontSize: textFieldFontSize,
      fontFamily: "inherit",
      backgroundColor: "transparent",
      border: "none",
      outline: "none !important",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      padding: textFieldPadding,
      color: themeColors["secondaryColor"],
      "&::placeholder": {
        color: themeColors["secondaryColorLighter"],
        fontSize: placeholderFontSize,
      },
    },
    formHelperText: {
      fontSize: "9px",
      fontFamily: "inherit",
      minWidth: "120px",
      maxWidth: "350px",
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: `${themeColors["failureColor"]} !important`,
    },
  });

  return useSigviewStyles;
};

function SigviewSearchField(props) {
  const { t } = useTranslation();
  // * Destructure props
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const elementId = useRef(v4());
  const {
    initialValue = "",
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    placeholder = "Search...",
    type = "text",
    customStyle = {},
    tooltipTitle = "",
    onSubmit = () => {},
    errorFlag = false,
    helperText = "",
    customClassName,
    validationfunction = () => {},
  } = props;

  // * Define required states
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(false);

  // * Define required side effects
  useEffect(() => {
    onChange(null, value); // (event, value) (following mui convention)

    //! If validation is required in realtime then uncomment below line
    // setError(!validationfunction(value));
  }, [value]);

  // * Define required event handlers
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (validationfunction(value)) {
      setError(false);
      onSubmit(event, value, true);
    } else setError(true);

    //Remove focus from text field
    const textField = document.getElementById(
      `sigviewTextField-${elementId.current}`
    );
    textField.blur();
  };
  const handleClear = (event) => {
    setValue("");
    setError(false);
    onSubmit(event, "", false);
  };

  // * Define required variables
  const showPostIconFlag = value.length > 0;
  const useSigviewStyles = makeSigviewStyle(themeColors, customStyle);
  const classes = useSigviewStyles();

  return (
    <div className={classes.root}>
      {/* Need to wrap it in a fragment to because ClickAwayListener requires elements/fragments as children */}
      <SigviewTooltip title={tooltipTitle} placement="bottom">
        <form className={classes.wrapper} onSubmit={handleSubmit}>
          <span className={classes.iconWrapper}>
            <span className="material-icons">search</span>
          </span>
          <input
            className={`${classes.textField} ${customClassName}`}
            id={`sigviewTextField-${elementId.current}`}
            value={value}
            onChange={handleChange}
            placeholder={t(placeholder)}
            onFocus={onFocus}
            onBlur={onBlur}
            type={type}
          />
          {showPostIconFlag && (
            <span
              className={`${classes.iconWrapper} ${classes.postIconWrapper} ${customClassName}`}
              onClick={handleClear}
            >
              <span className="material-icons">clear</span>
            </span>
          )}
        </form>
      </SigviewTooltip>
      {errorFlag && error && (
        <FormHelperText
          className={classes.formHelperText}
          title={t(helperText)}
        >
          {t(helperText)}
        </FormHelperText>
      )}
    </div>
  );
}

export default SigviewSearchField;
