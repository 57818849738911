// * Import required libraies
import React from "react";
import { connect } from "react-redux";

// * Import Custom Component
import Loader from "../../components/Loader/Loader";
import Footer from "../../components/Footer/Footer";

function LayoutBottom(props = {}) {
  const { user = {}, children = <></>, settings = {} } = props;
  const { considerIsDashboardLoading = false } = settings;
  return (
    <>
      {user.screen.isDashboardLoading && considerIsDashboardLoading && (
        <section className={`dashboard-loader-container`}>
          <Loader />
        </section>
      )}
      <div className={`page layout-bottom`}>
        <main>
          <section className={`content`}>
            <section className={`main-content-data`}>{children}</section>
          </section>
        </main>
        {/* <Footer /> */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(LayoutBottom);
