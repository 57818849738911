// Import required libraries
import React, { useState, useContext, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

function SigviewAutoCompleteMulti(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    data,
    value,
    onChange = () => {},
    renderOption,
    keepAlwaysOpen = false,
    limitTags = 2,
    idAccessor = "id",
    labelAccessor = "name",
    placeholder = "Select values...",
    renderTags,
    customStyle,
    multiple = false,
    clearInputOnSelection = true,
    disableClearable = false,
  } = props;
  const uniqueId = useRef(v4());

  //Defining required functions
  //Styling
  const useStyles = makeStyles({
    root: {
      //   height: "100%",
      height: "25px",
      "& .MuiAutocomplete-root ": {
        width: "100% !important",
        height: "100%",
        backgroundColor: `${themeColors["secondaryColorLightest"]} !important`,
      },
      "& .MuiPaper-root.MuiAutocomplete-paper.MuiPaper-elevation1.MuiPaper-rounded":
        {
          margin: "0px !important",
          boxShadow: "none !important",
          borderRadius: "0px 0px 0px 0px !important",
          border: `1px solid ${themeColors["secondaryColorLightest"]}`,
          borderTop: "0px solid transparent",
          "& .MuiAutocomplete-listbox": {
            height: "200px",
            padding: "2px 0px !important",
          },
        },
      "& .MuiFormControl-root": {
        height: "100%",
      },
      "& .MuiInputBase-root": {
        padding: "0px !important",
        height: "100%",
      },
      "& .MuiInputBase-input": {
        padding: "0px 10px !important",
        height: "100%",
        fontSize: "11px",
        fontFamily: "Fira Sans !important",
        color: themeColors["secondaryColor"],
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none!important",
      },
      "& .MuiAutocomplete-option": {
        fontFamily: "Fira Sans !important",
        fontSize: "11px",
        padding: "5px 10px 5px 5px !important",
        "& .auto-complete-option-icon": {
          fontSize: "16px !important",
          marginRight: "5px",
          color: themeColors["primaryColor"],
        },
        "& .auto-complete-option-icon.invisible": {
          visibility: "hidden",
        },
      },
      "& .MuiAutocomplete-listbox": {
        padding: "2px 0px !important",
        backgroundColor: `${themeColors["popoverBgColor"]} !important`,
        "&::-webkit-scrollbar": {
          width: "5px",
          height: "5px",
          backgroundColor: `transparent !important`,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: `${themeColors["secondaryColorLighter"]} !important`,
          borderRadius: "5px",
        },
      },
      "& .MuiAutocomplete-noOptions": {
        fontFamily: "Fira Sans !important",
        fontSize: "11px",
        padding: "5px 10px !important",
        color: themeColors["secondaryColorLight"],
      },
      "& .MuiAutocomplete-clearIndicator": {
        "& .MuiIconButton-label": {
          "& .MuiSvgIcon-root": { fontSize: "16px !important" },
        },
      },
      "& .MuiAutocomplete-popupIndicatorOpen": {
        display: customStyle?.popupIndicatorOpen?.display || "auto",
        "& .MuiIconButton-label": {
          "& .MuiSvgIcon-root": { fontSize: "20px !important" },
        },
      },
      "& .MuiAutocomplete-popupIndicator": {
        display: customStyle?.popupIndicator?.display || "auto",
        "& .MuiIconButton-label": {
          "& .MuiSvgIcon-root": { fontSize: "20px !important" },
        },
      },
      "& .MuiAutocomplete-endAdornment": {
        right: "4px !important",
        top: "0px !important",
        height: "100%",
      },
    },
  });

  //Defining required states
  const [inputValue, setInputValue] = useState("");

  //Using required hooks
  const classes = useStyles();

  //Defining required change handlers
  const handleChange = (e, newValue, reason) => {
    // * If handleChange is getting triggered from input, don't call onChange
    // * It then starts removing values if user hits backspace
    if (e.target.id !== uniqueId.current) onChange(newValue);
  };
  const handleInputValueChange = (e, value, reason) => {
    if (reason === "reset") {
      if (!clearInputOnSelection) {
        setInputValue(inputValue);
      } else {
        setInputValue("");
      }
      return;
    } else {
      setInputValue(value);
    }
  };

  // Debugging
  // console.group("SigviewAutoCompleteMulti");
  // console.log("props", props);
  // console.log("inputValue", inputValue);
  // console.groupEnd();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple={multiple}
        disablePortal //to let dropdown menu render in the same zIndex as the parent
        disableClearable={disableClearable}
        id={uniqueId.current}
        options={data}
        value={value}
        limitTags={limitTags}
        getOptionLabel={(option) => option[labelAccessor]}
        getOptionSelected={(option, value) =>
          option[idAccessor] === value[idAccessor]
        }
        onChange={handleChange}
        onInputChange={handleInputValueChange}
        inputValue={inputValue}
        {...(renderOption ? { renderOption } : {})}
        {...(keepAlwaysOpen ? { open: true } : {})}
        {...(renderTags ? { renderTags: () => {} } : {})}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={t(placeholder)}
          />
        )}
      />
    </div>
  );
}

export default SigviewAutoCompleteMulti;
