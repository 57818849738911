function validateOldPassword(oldPassword, newPassword) {
  if (oldPassword === "") {
    return {
      status: "invalid",
      message: "Old password should not empty",
    };
  } else if (oldPassword.length > 0 && oldPassword === newPassword) {
    return {
      status: "invalid",
      message: "Should not match the old password",
    };
  } else {
    return {
      status: "valid",
      message: "",
    };
  }
}

function validateNewPassword(newPassword, confirmPassword) {
  if (newPassword.length === 0) {
    return {
      status: "invalid",
      message: "Please enter your new password",
    };
  } else if (newPassword.length > 0 && newPassword.match(/[a-z]/) === null) {
    return {
      status: "invalid",
      message: "Should contain 1 lower case and 1 upper case character",
    };
  } else if (newPassword.length > 0 && newPassword.match(/[A-Z]/) === null) {
    return {
      status: "invalid",
      message: "Should contain 1 lower case and 1 upper case character",
    };
  } else if (newPassword.length > 0 && newPassword.match(/\d+/g) === null) {
    return {
      status: "invalid",
      message: "Should contain minimum one number",
    };
  } else if (
    newPassword.length > 0 &&
    newPassword.match(/[-_!@#$%^&*()+=~|:;'',./<>?]/g) === null
  ) {
    return {
      status: "invalid",
      message: "Should contain minimum one symbol",
    };
  } else if (newPassword.length === 0 && confirmPassword.length === 0) {
    return {
      status: "invalid",
      message: "Please enter your new password",
    };
  } else if (
    newPassword.length !== 0 &&
    newPassword.length < 8 &&
    newPassword.length > 32
  ) {
    return {
      status: "invalid",
      message: "Should be between 8 to 32 characters",
    };
  } else {
    return {
      status: "valid",
      message: "",
    };
  }
}

function validateConfirmPassword(newPassword, confirmPassword) {
  if (
    newPassword.length > 0 &&
    confirmPassword.length > 0 &&
    newPassword !== confirmPassword
  ) {
    return {
      status: "invalid",
      message: "Passwords do not match",
    };
  }
  if (confirmPassword.length === 0) {
    return {
      status: "invalid",
      message: "Please confirm your new password.",
    };
  }
  return {
    status: "valid",
    message: "",
  };
}

function validateChangePassword(selections) {
  console.log("selections", selections);
  const validatedSelections = {
    ...selections,
    oldPassword: {
      value: selections.oldPassword.value,
      ...validateOldPassword(
        selections.oldPassword.value,
        selections.newPassword.value
      ),
    },
    newPassword: {
      value: selections.newPassword.value,
      ...validateNewPassword(
        selections.newPassword.value,
        selections.confirmPassword.value
      ),
    },
    confirmPassword: {
      value: selections.confirmPassword.value,
      ...validateConfirmPassword(
        selections.newPassword.value,
        selections.confirmPassword.value
      ),
    },
  };

  return validatedSelections;
}

const isSelectionsInvalid = (selections) => {
  if (
    selections.oldPassword.status === "invalid" ||
    selections.newPassword.status === "invalid" ||
    selections.confirmPassword.status === "invalid"
  ) {
    return true;
  } else {
    return false;
  }
};

export { validateChangePassword, isSelectionsInvalid };
