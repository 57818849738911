// * Import required libraies
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// * import lib component
import { Box } from "@material-ui/core";

// * Import Custom Component
import LayoutNone from "../../layouts/LayoutNone/LayoutNone";
import Loader from "../../components/Loader/Loader";
import { config } from "../../config/config";

const useStyles = makeStyles((theme) => ({
  rootCss: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    position: "fixed",
    backgroundColor: "transparent",
    opacity: "1",
    height: "100vh",
    width: "100vw",
    top: "0",
    left: "0",
    zIndex: "1",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  loaderImage: {
    width: "30%",
    marginBottom: "0%",
  },
  subtitleCss: {
    color: theme.palette.misc["secondaryColor"],
    margin: "1rem !important",
    fontSize: "16px",
  },
}));

function AuthLoader(props = {}) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <LayoutNone>
      <Box className={classes.rootCss}>
        <Box className={classes.loader}>
          <Box>
            <img
              className={classes.loaderImage}
              src={config.hardCoded.appBuilderUrl}
              alt="loading"
            />
            <p className={classes.subtitleCss}>
              {t("Please wait while we create your dashboard")}
            </p>
          </Box>
        </Box>
        <Loader />
      </Box>
    </LayoutNone>
  );
}

AuthLoader.propTypes = {
  location: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(AuthLoader);
