// * Import required libraries
import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";

// * Import action creators
import { updateUserScreen } from "../../redux/actions";

// * Import utils, config & static data
import overlayList from "../../../assets/data/overlayList.json";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Define style functions
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    "& .css-ohwg9z": {
      width: "calc(85vw - 100px)",
      height: "calc(85vh - -30px)",
      position: "relative",
      top: "0px",
      left: "0px",
      padding: "0px",
      boxSizing: "border - box",
      backgroundColor: "#267ee6",
    },
    "& .css-1abc02a": {
      right: "26px",
      width: "50px",
    },
    "& .css-hn784z": {
      left: "26px",
      width: "50px",
    },
    "& .css-1m9128y": {
      height: "15px",
      position: "absolute",
      bottom: "4px",
      zIndex: "10",
    },
  },
  overlay: {
    width: "calc(85vw - 100px)",
    height: "calc(85vh - -30px)",
    position: "fixed",
    top: "0px",
    left: "0px",
    zIndex: "0",
    padding: "50px",
    boxSizing: "border - box",
    "& .css-1f8sh1y": {
      height: "600px",
      "& .css-1l7c0cy": {},
    },
  },
  mainContainer: {
    width: "100%",
    height: "100%",
    boxSizing: "content-box",
    position: "relative",
  },
  imageContainer: {
    height: "83%",
    width: "100%",
    boxSizing: "content-box",
  },
  footer: {
    height: "17%",
    width: "100%",
    boxSizing: "content-box",
    backgroundColor: "#267ee6",
    color: "#fff",
    height: "17%",
    paddingTop: "10px",
    "& h2": {
      textAlign: "center",
      fontSize: "18px",
      fontWeight: "500",
      margin: "0px",
    },
    "& p": {
      textAlign: "center",
      fontSize: "12px",
      lineHeight: "17px",
      padding: "0px 30px",
    },
  },
}));
function OverlayContainer(props) {
  const { dispatch: ReduxDispatcher, user, allData } = props;
  const { uiFeatureList } = user;
  const { state: themeState } = useContext(ThemeContext);

  const themeColors = themeState.themes[themeState.activeTheme];

  const [handleOpen, setHandleOpen] = useState({
    open: user.screen.overlay.isOpen,
  });

  const overlayFeatureAccessList = (overlayList, uiFeatureList) => {
    let finalOverlayFeatureList = [];
    overlayList.map((el) => {
      if (el.isFeatureRelated === false) {
        finalOverlayFeatureList.push(el);
      } else {
        for (const item in uiFeatureList) {
          if (el.feature === item && uiFeatureList[item]) {
            finalOverlayFeatureList.push(el);
          }
        }
      }
    });
    return finalOverlayFeatureList;
  };
  overlayFeatureAccessList(overlayList, uiFeatureList);

  const handleCarouselClose = () => {
    setHandleOpen({ open: false });
    const payload = {
      isOpen: false,
    };
    const action = updateUserScreen("overlay", payload);
    ReduxDispatcher(action);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Carousel>
        {overlayList.map((item, i) => (
          <OverlayItem
            key={i}
            item={item}
            handleCarouselClose={handleCarouselClose}
            className="hello"
          />
        ))}
      </Carousel>
    </div>
  );
}
function OverlayItem(props) {
  const { handleCarouselClose } = props;

  const handleclose = () => {
    handleCarouselClose();
  };
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.imageContainer}>
        {/* <img src={props.item.sourceFile} /> */}
        <div
          style={{
            backgroundImage: `url(${props.item.sourceFile})`,
            height: "100%",
            width: "100%",
            // backgroundPosition: "center",
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </Box>
      <Box className={classes.footer} style={{}}>
        <h2>
          {props.item.title}
          <sup>NEW</sup>
        </h2>
        <p>{props.item.content}</p>
        <div
          className="material-icons sigview-close-icon"
          onClick={handleCarouselClose}
          style={{
            position: "absolute",
            right: "0px",
            top: "0px",
            backgroundColor: "#e6f0fc",
            zIndex: "2",
          }}
        >
          close
        </div>
      </Box>
    </Box>
  );
}

OverlayContainer.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
});

export default connect(mapStateToProps)(OverlayContainer);
