// Import constants
import C from "../constants";

// Import utils
import {
  checkChartTypeValidity,
  manipulatePlotStateBasedOnBusinessLogic,
} from "../../utils/standaloneChartUtils";

export default function standaloneChart(state = {}, payload) {
  let { type, key, value } = payload;

  let newChartList = [];
  let newState = {};
  switch (type) {
    case C.UPDATE_STANDALONE_CHART_FORM:
      switch (key) {
        case "dimensionsList":
        case "metricsList":
        case "orderById":
        case "orderBy":
        case "chartType":
          newState = {
            ...state,
            [key]: value,
          };
          newChartList = checkChartTypeValidity({
            dimensionsList: newState.dimensionsList,
            metricsList: newState.metricsList,
            chartList: newState.chartList,
          });
          newState = {
            ...newState,
            chartList: newChartList,
            renderFlag: false,
          };
          return manipulatePlotStateBasedOnBusinessLogic(newState, payload);
        // ! Comment out above case "chartType" and uncomment below to achieve smooth transition of charts (no reload); can be done only when all charts have the same call
        // case "chartType":
        //   newState = {
        //     ...state,
        //     [key]: value,
        //   };
        //   newChartList = checkChartTypeValidity({
        //     dimensionsList: newState.dimensionsList,
        //     metricsList: newState.metricsList,
        //     chartList: newState.chartList,
        //   });
        //   newState = {
        //     ...newState,
        //     chartList: newChartList,
        //   };
        //   return manipulatePlotStateBasedOnBusinessLogic(newState, payload);
        case "title":
        case "timeFilters":
        case "dimensionFilters":
        case "elementType":
          newState = {
            ...state,
            [key]: value,
          };
          return newState;
        case "renderFlag":
          newState = {
            ...state,
            [key]: value,
          };
          return newState;
        default:
          console.error("UI ERROR occured in standaloneChart.js");
          console.groupCollapsed("Details Below");
          console.log("UNIDENTIFIED TYPE");
          console.log("REDUCER -> ", "standaloneChart.js");
          console.log("state -> ", state);
          console.log("payload -> ", payload);
          console.groupEnd();
          return state;
      }
    case C.REPLACE_STANDALONE_CHART_FORM:
      return value;

    default:
      console.groupCollapsed("UI ERROR");
      console.log("UNIDENTIFIED TYPE");
      console.log("REDUCER -> ", "plot.js");
      console.log("state -> ", state);
      console.log("payload -> ", payload);
      console.groupEnd();
  }
}
