// ! IMPORTANT NOTES
// Caution:
// 1. This is a fast fix to solve website performance
// 2. This may not adhere to our kit guidelines

//Import required libraies
import React, { useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// Import Custom Component
import SigviewTooltip from "../../components/Common/SigviewTooltip";
// Performant Custom Components
import SigviewIcon from "./SigviewIcon";

// Import action creators

// Import utils

// Import styles
//Styling
const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    height: "24px",
    boxShadow: "none",
    margin: (props) => props?.customStyle?.margin || "0px",
    "& .MuiButtonGroup-groupedContainedHorizontal:not(:last-child)": {
      borderRight: `1px solid ${theme.palette.misc["buttonBgColorLighter"]}`,
      transition: "none !important",
      borderRadius: "2px 0px 0px 2px",
    },
    "& .MuiButtonGroup-groupedHorizontal": {
      minWidth: "45px",
      backgroundColor: (props) =>
        props?.variant === "contained"
          ? theme.palette.misc["primaryColor"]
          : props?.variant === "containedLighter"
          ? theme.palette.misc["buttonBgColorLightest"]
          : theme.palette.misc["primaryColor"],
      color: (props) =>
        props?.variant === "contained"
          ? theme.palette.misc["buttonColor"]
          : props?.variant === "containedLighter"
          ? theme.palette.misc["buttonBgColor"]
          : theme.palette.misc["buttonColor"],
      "& .MuiButton-label": {
        fontFamily: "Fira Sans !important",
        fontSize: "12px",
        textTransform: "capitalize",
        fontWeight: "400 !important",
      },
      "&:hover": {
        boxShadow: "none",
        color: (props) =>
          props?.variant === "containedLighter"
            ? theme.palette.misc["buttonColorLighter"]
            : theme.palette.misc["buttonColor"],
        backgroundColor: (props) =>
          props?.variant === "containedLighter"
            ? theme.palette.misc["buttonBgColorLighter"]
            : theme.palette.misc["primaryColor"],
      },
      "&.Mui-disabled": {
        //   opacity: 0.5,
        backgroundColor: theme.palette.misc["buttonDisabledBgColor"],
        color: theme.palette.misc["buttonDisabledColor"],
      },
    },
    "& .MuiButtonGroup-groupedHorizontal:not(:first-child)": {
      width: "25px !important",
      padding: "0px",
      minWidth: "25px",
      transition: "none !important",
      borderRadius: "0px 2px 2px 0px",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  mainButton: {
    height: "24px",
    boxShadow: "none",
  },
  toggleButton: {
    "& .MuiTouchRipple-root": { display: "none" },
    "& .MuiButton-label": {
      width: "25px",
      fontFamily: "Fira Sans !important",
    },
  },
  toggleButtonIcon: { fontSize: "24px !important" },
  popper: {
    zIndex: 1,
    // minWidth: "65px",
    // width: "inherit",
    // width: "84px",
    "& .MuiList-root": {
      backgroundColor: theme.palette.misc["popoverBgColor"],
    },
    "& .MuiListItem-root": {
      fontFamily: "Fira Sans !important",
      fontSize: "12px",
      padding: "3px 18px",
      color: theme.palette.misc["buttonBgColor"],
      "&:hover": {
        // backgroundColor: theme.palette.misc["buttonBgColor"],
        // color: theme.palette.misc["buttonColor"],
        backgroundColor: (props) =>
          props?.variant === "contained"
            ? theme.palette.misc["buttonBgColor"]
            : props?.variant === "containedLighter"
            ? theme.palette.misc["buttonBgColorLightest"]
            : theme.palette.misc["primaryColor"],
        color: (props) =>
          props?.variant === "contained"
            ? theme.palette.misc["buttonColor"]
            : props?.variant === "containedLighter"
            ? theme.palette.misc["buttonColorDarkest"]
            : theme.palette.misc["buttonColor"],
      },
    },
  },
}));

const defaultOptions = [
  {
    id: "save",
    label: "Save",
    onClick: () => {},
    disabled: true,
    tooltipProps: {},
  },
  {
    id: "clone",
    label: "Clone",
    onClick: () => {},
    disabled: true,
    tooltipProps: {},
  },
  {
    id: "delete",
    label: "Delete",
    onClick: () => {},
    disabled: true,
    tooltipProps: {},
  },
];

function SigviewButtonSplit(props) {
  const { t } = useTranslation();
  const { options = defaultOptions, customClassName } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const theme = useTheme();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // Defining static variables
  const classes = useStyles(props);
  const finalOptions = options.length === 1 ? options : options.slice(1);

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        className={classes.buttonGroup}
      >
        <Button
          onClick={options[0]["onClick"]}
          disabled={options[0].disabled}
          disableRipple={true}
          //className={`${options[0].label}${customClassName}`}
          className={
            options[0]?.disabled ? "" : `${options[0].label.replace(/ +|&+/g,"")}${customClassName}`
          }
        >
          <div
            className={
              options[0]?.disabled
                ? ""
                : `${options[0].label.replace(/ +|&+/g,"")}${customClassName}`
            }
          >
            {t(options[0].label)}
          </div>
        </Button>
        <Button
          className={classes.toggleButton}
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          disableRipple={true}
        >
          {/* <span className={`${classes.toggleButtonIcon} material-icons`}>
            expand_more
          </span> */}
          {/* <SigviewIcon></sigviewIcon> */}
          <SigviewIcon
            iconName="expand_more"
            style={{
              fontSize: "20px !important",
              padding: "0 !important",
              color:
                props?.iconProps?.style.color ||
                theme.palette.misc["popoverBgColor"],
              hoverColor:
                props?.iconProps?.style.color ||
                theme.palette.misc["popoverBgColor"],
              cursor: "pointer",
            }}
          />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={`${classes.popper} ${customClassName}`}
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              zIndex: 1,
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {finalOptions.map((option, index) => {
                    const { tooltipProps = {} } = option;
                    const {
                      flag: tooltipFlag = false,
                      title: tooltipTitle = "",
                      placement: tooltipPlacement = "bottom",
                    } = tooltipProps;

                    return (
                      <>
                        {tooltipFlag && (
                          <SigviewTooltip
                            title={tooltipTitle}
                            placement={tooltipPlacement}
                          >
                            <Box css={{ cursor: "pointer" }}>
                              <MenuItem
                                key={option.id}
                                id={`${option.label.replace(
                                  / +/g,
                                  ""
                                )}${customClassName}`}
                                disabled={option.disabled}
                                selected={false}
                                onClick={(event) => {
                                  option.onClick(event);
                                  handleClose(event);
                                }}
                              >
                                {t(option.label)}
                              </MenuItem>
                            </Box>
                          </SigviewTooltip>
                        )}
                        {!tooltipFlag && (
                          <MenuItem
                            key={option.id}
                            id={`${option.label.replace(
                              / +/g,
                              ""
                            )}${customClassName}`}
                            disabled={option.disabled}
                            selected={false}
                            onClick={(event) => {
                              option.onClick(event);
                              handleClose(event);
                            }}
                          >
                            {t(option.label)}
                          </MenuItem>
                        )}
                      </>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default SigviewButtonSplit;
