// * Import required libraies
import React from "react";

// * Import 3rd party lib comp
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";

// * Import custom components
import SigviewTypography from "../../components/Common/SigviewTypography";

// * Define required functions
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "0px 22px 10px 22px",
  },
  leftPanel: { display: "flex", alignItems: "center" },
  rightPanel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "flex-end",
  },
  itemTitle: {},
  item: {
    backgroundColor: "white",
    marginRight: "10px",
    padding: "0px 7px",
    boxShadow: theme.palette.misc["dsItemBoxShadow"],
    borderRadius: "5px",
  },
}));

function LayoutMetricChartsMenu(props = {}) {
  // * Destructure props
  const { children = <></>, style = {}, showTitle = false } = props;

  // * Define requires static variables
  const classes = useStyles();
  const { root, leftPanel, rightPanel, itemContainer, item, itemTitle } =
    classes;

  return (
    <Grid container className={root}>
      <Grid item xs={8} className={leftPanel}>
        <Box className={itemContainer}>
          {showTitle && (
            <Box className={itemTitle}>
              <SigviewTypography
                style={{
                  height: "max-content",
                  margin: "0px 0px 5px 3px !important",
                }}
                variant="pSmallMedium"
              >
                Chart Type
              </SigviewTypography>
            </Box>
          )}

          <Box className={item}>{children[0]}</Box>
        </Box>

        {children[1]}
      </Grid>
      <Grid item xs={4} className={rightPanel}>
        <Box className={itemContainer}>
          {showTitle && (
            <Box className={itemTitle}>
              <SigviewTypography
                style={{
                  height: "max-content",
                  margin: "0px 0px 5px 3px !important",
                }}
                variant="pSmaller"
              >
                Granularity
              </SigviewTypography>
            </Box>
          )}

          <Box className={item}>{children[2]}</Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LayoutMetricChartsMenu;
