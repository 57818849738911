import C from "../constants";

import {
  transformMetricsForPlotly,
  transformDimensionsForPlotly,
} from "../../utils/utils";

import { initialAllData } from "../stateData";

export default function data(state = {}, payload) {
  let { type, key, data, viewId, viewData, viewsLength } = payload;
  switch (type) {
    case C.LOGOUT:
      return { ...initialAllData };

    case C.UPDATE_DATA:
      return { ...state, [key]: data };

    case C.UPDATE_ALL_DATE_RANGE:
      let newGetAllDaterangeData = {
        ...state.allDateRange.data,
        [viewId]: viewData,
      };

      return {
        ...state,
        allDateRange: {
          ...state.allDateRange,
          data: newGetAllDaterangeData,
          status:
            Object.keys(newGetAllDaterangeData).length === viewsLength
              ? "success"
              : "loading",
        },
      };

    case C.UPDATE_PLOTLY_METRICS_DIMENSIONS_LIST:
      return {
        ...state,
        plotlyMetrics: transformMetricsForPlotly(
          state.metricList.data,
          state.customMetricList.data
        ),
        plotlyDimensions: transformDimensionsForPlotly(
          state.dimensionList.data
        ),
      };

    case C.EMPTY_ALL_DATA:
      return { ...initialAllData };

    default:
      return state;
  }
}
