// Import required libraries
import React, { useContext } from "react";
import Joyride from "react-joyride";
import { useTranslation } from "react-i18next";

// Import custom components
import SigviewButton from "../Common/SigviewButton";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import styles
import "./Tour.scss";

// Import config
import { config } from "../../config/config";

// Import action creators

// Import data/utils

const JoyrideCustomTooltip = (props) => {
  const { t } = useTranslation();
  const {
    continuous,
    index,
    step,
    backProps,
    closeProps,
    skipProps,
    primaryProps,
    tooltipProps,
  } = props;
  return (
    <div className="joyride-custom-tooltip-body" {...tooltipProps}>
      {step.title && (
        <p className="joyride-custom-tooltip-title">{t(step.title)}</p>
      )}
      <p className="joyride-custom-tooltip-content">{t(step.content)}</p>
      <div className="joyride-custom-tooltip-footer">
        <div className="joyride-custom-tooltip-left-panel">
          {skipProps && <SigviewButton {...skipProps} variant="outlined" />}
        </div>
        <div className="joyride-custom-tooltip-right-panel">
          {index > 0 && (
            <SigviewButton
              {...backProps}
              variant="outlined"
              style={{ margin: { right: "5px" } }}
            />
          )}
          {continuous && (
            <SigviewButton {...primaryProps} variant="contained" />
          )}
          {!continuous && <SigviewButton {...closeProps} variant="contained" />}
        </div>
      </div>
    </div>
  );
};

//Defining utils

function Tour(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const { steps, tourRun, setTourRun, autoStart } = props;

  //if autostart is true, add disableBeacon as true in the first step object
  const finalSteps = [
    { ...steps[0], disableBeacon: autoStart },
    ...steps.slice(1),
  ];

  //Override colors in settings based on theme
  const joyrideTourSettings = config.hardCoded.joyrideTourSettings;
  const finalSettings = {
    ...joyrideTourSettings,
    styles: {
      ...joyrideTourSettings.styles,
      options: {
        ...joyrideTourSettings?.styles?.options,
        arrowColor: themeColors["popoverBgColor"],
        primaryColor: themeColors["primaryColor"],
      },
    },
  };

  return (
    <div className="joyride-tour-container">
      <Joyride
        steps={finalSteps}
        run={tourRun}
        tooltipComponent={JoyrideCustomTooltip}
        {...finalSettings}
        callback={(callbackProps) => {
          // Close tour if the tour ends
          const resetArr = ["reset"];
          if (resetArr.includes(callbackProps.action)) {
            setTourRun(false);
          }
        }}
      />
    </div>
  );
}

export default Tour;
