// Import Constants
import C from "../constants";

// Import validation function
import { validateSignInForm } from "../../utils/signInUtils";
import { initialSignInSelections } from "../../redux/stateData";

export default function signInFormStatus(
  state = initialSignInSelections,
  action = {}
) {
  const { type = "", payload = {} } = action;
  const { key = "", value = "" } = payload;

  let newState = {};
  switch (type) {
    case C.UPDATE_SIGNIN_FORM:
      switch (key) {
        case "password":
        case "tab":
        case "signInStatus":
        case "resetStatus":
        case "postResetStatus":
        case "showSignInUiError":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          break;
        case "email":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
            signInStatus: {
              message: "",
              value: "initial", // loading/error/success/initial
              status: "",
            },
            resetStatus: {
              message: "",
              value: "initial", // loading/error/success/initial
              status: "",
            },
          };
          break;
        default:
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log("UNIDENTIFIED KEY in UPDATE_ATTRIBUTE_DIMENSION_FORM");
          console.log("REDUCER -> ", "AttributeDimension Reducer.js");
          console.log("state -> ", state);
          console.log("action -> ", action);
          console.groupEnd();
          newState = { ...state };
      }

      break;

    case C.UPDATE_SIGNIN_FORM_WHOLE_KEY:
      switch (key) {
        case "email":
        case "password":
        case "tab":
        case "signInStatus":
        case "resetStatus":
        case "postResetStatus":
        case "showSignInUiError":
          newState = {
            ...state,
            [key]: { ...value },
          };
          break;
        default:
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log("UNIDENTIFIED KEY in UPDATE_ATTRIBUTE_DIMENSION_FORM");
          console.log("REDUCER -> ", "AttributeDimension Reducer.js");
          console.log("state -> ", state);
          console.log("action -> ", action);
          console.groupEnd();
          newState = { ...state };
      }
      break;

    case C.REPLACE_SIGNIN_FORM:
      newState = { ...payload };
      break;

    default:
      console.error("UI ERROR");
      console.groupCollapsed("DETAILS");
      console.log("UNIDENTIFIED TYPE");
      console.log("REDUCER -> ", "AttributeDimension Reducer.js");
      console.log("state -> ", state);
      console.log("action -> ", action);
      console.groupEnd();
      newState = { ...state };
      break;
  }
  // return newState;

  // TODO Add validation

  return validateSignInForm({ ...newState });
}
