// Import required libraries
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

// Import custom components
import SigviewSingleSelect from "../../components/Common/SigviewSingleSelect";
import SigviewButton from "../Common/SigviewButton";
import SigviewTextField from "../Common/SigviewTextField";
import SigviewFileInput from "../Common/SigviewFileInput";
import SigviewProgressBar from "../Common/SigviewProgressBar";

// Import styles

// Import config
import { config } from "../../config/config";

// Import action creators

// Import utils
import {
  getFileUploadLocation,
  uploadToGCPBucket,
  uploadFileMetadataToServer,
} from "../../services/api";
import { isFileValid, getGCSFileName } from "../../utils/filtersUtils";

//STEPS
//1) Let the user upload only 1 file (not multiple)
//2) Allowed file formats are csv and excel (format differs in windows and mac)
//3) Once the user uploads the file, check if it is valid

//Making required variables
const initialFileData = config.hardCoded.initialFileData;

function AdvancedFiltersFileUpload(props) {
  const { t } = useTranslation();
  const {
    user,
    itemTitle,
    type,
    newDropDownData,
    handleTypeChange,
    extraData,
    handleExtraDataChange,
    showDeleteIconFlag,
    handleRowDelete,
    handleAddRow,
    showAddIconFlag,
    showError,
    activeDimension,
  } = props;
  const [fileData, setFileData] = useState(initialFileData);

  //Defining required variables
  const displayFileName = fileData.isLoading
    ? t("Uploading...")
    : extraData?.fileName || t("No File Chosen");
  const inputFileLabel = fileData.error
    ? t("Retry")
    : extraData?.fileName
    ? t("Replace")
    : t("Select File");
  const errorFlag = fileData.error
    ? true
    : !extraData?.fileName
    ? showError
      ? true
      : false
    : false;
  const errorMessage = fileData.error
    ? fileData.error
    : !extraData?.fileName
    ? "Please upload a file"
    : "";
  const dimId = activeDimension?._id;

  const handleFileRemove = () => {
    handleExtraDataChange({});
    setFileData(initialFileData);
  };

  const handleFileChange = (e) => {
    //Remove extraData and setFileData to initialState
    handleFileRemove();

    //Grab the file and store it in var "file"
    const files = e.target.files;
    const file = files[0];
    console.log("FILES", files);

    //Check if the file is valid
    const maxFileUploadSizeInMB = user.uiLimitsList.maxFileUploadSizeInMB;
    const { status, message } = isFileValid(file, maxFileUploadSizeInMB);
    console.log("MESSAGE", message);

    //Check if file is valid
    if (status) {
      //File path is a concatenation of org, view and fileName
      const filePath =
        user?.reqMetadata?.organization +
        "/" +
        user?.reqMetadata?.view +
        "/" +
        file.name;
      let newFileData = {
        error: null,
        isLoading: true,
        data: {
          filePath,
          file,
        },
      };
      setFileData(newFileData);
    } else {
      let newFileData = {
        error: message,
        isLoading: false,
        data: null,
      };
      setFileData(newFileData);
    }
  };

  //Upload file to GCP everytime fileData changes
  //Uploading the file requires 4 steps (3 API calls):
  // Step 1) Get an upload location & key from BACKEND to upload the file in remote bucket (GCP/AWS)
  // Step 2) With the upload location & key (only in GCP), upload the file to GCP which won't return anything except success or failure
  // Step 3) On successful upload of file to GCP, send file metadata to BACKEND to get validate the file;
  //         if valid file, it will return the table_name
  //         if invalid file, it will return the error
  // Step 4) Set this table name and file name as extraData (file info) in advanced filters
  useEffect(() => {
    //Upload file only when it's a valid file
    if (fileData.data) {
      const fileName = fileData.data.file.name.replace(/\s/g, "");
      // Step 1) Get an upload location & key from BACKEND to upload the file in remote bucket (GCP/AWS)
      const getFileUploadLocationPromise = getFileUploadLocation({});
      getFileUploadLocationPromise
        .then((responseData) => {
          const key = responseData?.result?.key;
          const bucket = responseData?.result?.bucketName;
          const gcsFileName = getGCSFileName(fileName);
          const url =
            "https://storage.googleapis.com/upload/storage/v1/b/" +
            bucket +
            "/o?uploadType=media&name=" +
            gcsFileName;

          const fetchProps = {
            url,
            headers: { Authorization: key },
            payload: fileData.data.file,
          };
          // Step 2) With the upload location & key (only in GCP), upload the file to GCP which won't return anything except success or failure
          const uploadToGCPBucketPromise = uploadToGCPBucket(fetchProps);
          uploadToGCPBucketPromise
            .then(() => {
              const payload = {
                id: dimId,
                path: gcsFileName,
                emailId: user?.reqMetadata?.email,
                orgViewReq: {
                  organization: user?.reqMetadata?.organization,
                  view: user?.reqMetadata?.view,
                },
              };
              const fetchProps = {
                payload,
              };
              // Step 3) On successful upload of file to GCP, send file metadata to BACKEND to get validate the file;
              //         if valid file, it will return the table_name
              //         if invalid file, it will return the error
              const uploadFileMetadataToServerPromise =
                uploadFileMetadataToServer(fetchProps);
              uploadFileMetadataToServerPromise
                .then((responseData) => {
                  const fileName = fileData.data.file.name;
                  const fileLocation = responseData?.result?.data?.tableName;
                  const extraData = {
                    fileName,
                    fileLocation,
                  };
                  handleExtraDataChange(extraData);
                  setFileData({
                    ...fileData,
                    isLoading: false,
                    error: null,
                  });
                })
                .catch((error) => {
                  if (error.error !== config.hardCoded.queryCancelled) {
                    setFileData({
                      isLoading: false,
                      error: error.error,
                      data: null,
                    });
                  }
                });
            })
            .catch((error) => {
              if (error.error !== config.hardCoded.queryCancelled) {
                setFileData({
                  isLoading: false,
                  error: error.error,
                  data: null,
                });
              }
            });
        })
        .catch((error) => {
          if (error.error !== config.hardCoded.queryCancelled) {
            setFileData({
              isLoading: false,
              error: error.error,
              data: null,
            });
          }
        });
    }
  }, [fileData.data]);

  return (
    <div className="advanced-filters-item-container">
      <div className="advanced-filters-item-title-container">
        <p className="advanced-filters-item-title">{t(itemTitle)}</p>
      </div>
      <SigviewSingleSelect
        value={type}
        data={newDropDownData}
        onChange={handleTypeChange}
        minWidth="170px"
        margin={{ right: "5px" }}
      />
      <SigviewTextField
        value={displayFileName}
        onChange={() => {}}
        error={errorFlag}
        helperText={errorMessage}
        readOnly={true}
        minWidth="210px"
        maxWidth="210px"
        margin={{ right: "5px" }}
      />
      {extraData?.fileName && !fileData.isLoading && (
        <SigviewButton
          variant="outlined"
          onClick={() => handleFileRemove()}
          title="Remove"
          style={{ margin: { right: "5px" }, width: "50px", fontSize: "10px" }}
        />
      )}
      {fileData.isLoading && (
        <div className="advanced-filters-item-progress-bar-container">
          <div className="advanced-filters-item-progress-bar">
            <SigviewProgressBar />
          </div>
        </div>
      )}
      {!fileData.isLoading && (
        <SigviewFileInput
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
          onChange={(e) => handleFileChange(e)}
          label={inputFileLabel}
          style={{ margin: { right: "5px" } }}
        />
      )}
      <div
        className={`advanced-filters-item-icon-container ${
          showDeleteIconFlag ? "visible" : "hidden"
        }`}
      >
        <i
          className="material-icons-outlined advanced-filters-item-icon af-delete-icon"
          onClick={handleRowDelete}
        >
          cancel
        </i>
      </div>
      {showAddIconFlag && (
        <div className="advanced-filters-item-icon-container">
          <i
            className="material-icons advanced-filters-item-icon af-add-icon"
            onClick={handleAddRow}
          >
            add
          </i>
        </div>
      )}
    </div>
  );
}

AdvancedFiltersFileUpload.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(AdvancedFiltersFileUpload);
