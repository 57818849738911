import C from "../constants";
import {
  extractReqMetadataFromMetadata,
  getUiFeatureList,
  getUiLimitList,
} from "../../utils/utils";
import {
  initialDimensionFilters,
  initialMetricFilters,
  initialUserData,
  initialGlobalFilters,
  initialUiFeatureList,
  initialUiLimitsList,
  initialUserScreen,
  initialActiveWorkspace,
} from "../stateData";

// Import reducers
import timeFilters from "./timeFilters";
import dimensionFilters from "./dimensionFilters";
import metricFilters from "./metricFilters";
import { config } from "../../config/config";

export default function user(state = {}, payload) {
  let { type, key, data, newApiEndPoints, newUserType } = payload;

  let newDimensionFilters, newMetricFilters, newScreen;
  switch (type) {
    case C.LOGOUT:
      return {
        ...initialUserData,
        isAuth: false,
      };

    case C.UPDATE_USER_INFO:
      return {
        ...state,
        ...data,
      };

    // case C.UPDATE_USER_DATA:
    //   return {
    //     ...state,
    //     data: { ...state.data, [key]: data },
    //     screen: { ...state.screen, activeTab: "dashboard" },
    //   };
    case C.UPDATE_USER_BANNER_STATUS:
      return {
        ...state,
        bannerStatus: { ...data },
      };

    case C.UPDATE_AUTH:
      return {
        ...state,
        auth: { ...data },
      };

    case C.UPDATE_USER_API_ENDPOINTS:
      return {
        ...state,
        apiEndpoints: { ...newApiEndPoints },
      };

    case C.UPDATE_USER_TYPE:
      return {
        ...state,
        type: { ...newUserType },
      };

    case C.UPDATE_DATA:
      switch (key) {
        case "featureAccessList":
          let newUiFeatureList = initialUiFeatureList;
          let newUiLimitsList = initialUiLimitsList;
          if (data.status === "success") {
            newUiFeatureList = getUiFeatureList(data.data);
            newUiLimitsList = getUiLimitList(data.data);
          }
          return {
            ...state,
            uiFeatureList: newUiFeatureList,
            uiLimitsList: newUiLimitsList,
          };
        default:
          return state;
      }

    case C.UPDATE_USER_METADATA:
      return {
        ...state,
        metadata: data,
        reqMetadata: extractReqMetadataFromMetadata(data, state.auth),
        screen: {
          ...state.screen,
          workspaceAutoSaveStatus:
            data?.data?.workspaceAutoSaveStatus ||
            config.hardCoded.defaultWorkspaceAutoSaveStatus,
        },
      };

    case C.UPDATE_USER_SCREEN:
      //In case key is undefined, it means there are multiple key-values that need to be updated
      //Using spread operator here
      newScreen = key
        ? { ...state.screen, [key]: data }
        : { ...state.screen, ...data };
      return {
        ...state,
        screen: newScreen,
      };

    case C.EMPTY_ALL_DATA:
      return {
        ...state,
        screen: {
          ...state.screen,
          isDashboardLoading: true,
          globalFilters: initialGlobalFilters,
          activeWorkspace: initialActiveWorkspace,
        },
        dimensionFilters: initialDimensionFilters,
        metricFilters: initialMetricFilters,
        uiFeatureList: initialUiFeatureList,
      };

    case C.CHANGE_THEME:
      return {
        ...state,
        theme: data,
      };

    case C.UPDATE_THEME_COLORS:
      return {
        ...state,
        themeColors: data,
      };

    case C.REDIRECT_TO_ANGULAR:
      newDimensionFilters = dimensionFilters(state.dimensionFilters, {
        type: C.REPLACE_ALL_DIMENSION_FILTERS,
        newDimensionFilters: [],
      });
      return {
        ...state,
        screen: { ...initialUserScreen },
        dimensionFilters: newDimensionFilters,
      };

    case C.UPDATE_TIME_FILTERS:
      return {
        ...state,
        timeFilters: timeFilters(
          (state = state.timeFilters),
          (payload = payload)
        ),
      };

    case C.UPDATE_TIME_FILTERS_WITH_DEFAULT_DATES:
      return {
        ...state,
        timeFilters: timeFilters(
          (state = state.timeFilters),
          (payload = payload)
        ),
      };

    case C.TIMEZONE_CHANGED:
      return {
        ...state,
        timeFilters: timeFilters(
          (state = state.timeFilters),
          (payload = payload)
        ),
      };

    case C.DATE_PRESET_CHANGED:
      return {
        ...state,
        timeFilters: timeFilters(
          (state = state.timeFilters),
          (payload = payload)
        ),
      };

    case C.DATE_SELECTED_CHANGED:
      return {
        ...state,
        timeFilters: timeFilters(
          (state = state.timeFilters),
          (payload = payload)
        ),
      };

    case C.REPLACE_TIME_FILTERS:
      return {
        ...state,
        timeFilters: timeFilters(
          (state = state.timeFilters),
          (payload = payload)
        ),
        // ! BELOW CODE WAS FAILING, HENCE DIDN'T INCLUDE
        // * Updating activeChart.timeFilters so that direct load of /datastory/chart/create works
        // screen: {
        //   ...state.screen,
        //   activeChart: {
        //     ...state.screen.activeChart,
        //     timeFilters: timeFilters(
        //       (state = state.timeFilters),
        //       (payload = payload)
        //     ),
        //   },
        // },
      };

    case C.UPDATE_DIALOG_INFO:
      return {
        ...state,
        dialogInfo: { ...data },
      };

    case C.REPLACE_ALL_DIMENSION_FILTERS:
      newDimensionFilters = dimensionFilters(state.dimensionFilters, payload);
      return {
        ...state,
        dimensionFilters: newDimensionFilters,
      };

    case C.REMOVE_FILTER:
      newDimensionFilters = dimensionFilters(state.dimensionFilters, payload);
      return {
        ...state,
        dimensionFilters: newDimensionFilters,
      };

    case C.REPLACE_ALL_METRIC_FILTERS:
      newMetricFilters = metricFilters(state.metricFilters, payload);
      return {
        ...state,
        metricFilters: newMetricFilters,
      };

    case C.REMOVE_METRIC_FILTER:
      newMetricFilters = metricFilters(state.metricFilters, payload);
      return {
        ...state,
        metricFilters: newMetricFilters,
      };

    case C.UPDATE_VERSION:
      let newVersion = {
        status: "loading",
        message: "",
        data: {},
      };

      if (data.status === "success") {
        newVersion = payload.data;
      }
      return {
        ...state,
        version: newVersion,
      };

    default:
      return state;
  }
}
