//Import required libraies
import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// Import Custom Component

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import action creators

// Import utils

// Import styles

function SigviewButton(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    title = "",
    onClick = () => {},
    variant = "contained",
    style = {
      padding: "6px 16px",
      fontSize: "12px !important",
    },
    disabled = false,
    iconProps = {
      className: "material-icons",
      iconName: "add",
    },
    iconFlag = false,
    iconStyle = { fontSize: "18px !important" },
    type = "button",
    customClassName,
  } = props;
  //Defining styling variables
  let buttonHeight = "22px";
  if (style.buttonHeight) {
    buttonHeight = style.buttonHeight;
  }
  let wrapperHeight = "max-content";
  if (style.wrapperHeight) wrapperHeight = style.wrapperHeight;

  //Styling
  const commonStyle = {
    height: buttonHeight,
    fontFamily: "inherit",
    fontSize: style?.fontSize ? `${style.fontSize}` : "12px !important",
    fontWeight: "400 !important",
    textTransform: "none",
    minWidth: "30px !important",
    borderRadius: "3px",
    boxSizing: "border-box !important",
    "&.MuiButton-text": {
      padding: "6px 12px",
      width: style.btnWidth,
      height: style.btnHeight,
      "& .MuiButton-label": { width: "max-content" },
    },
  };
  const useStyles = makeStyles(() => ({
    root: {
      marginLeft: style?.margin?.left || "0px",
      marginRight: style?.margin?.right || "0px",
      marginTop: style?.margin?.top || "0px",
      marginBottom: style?.margin?.bottom || "0px",
      fontSize: 11,
      width: style?.width,
      "& .MuiButton-startIcon": {
        marginRight: "3px !important",
        lineHeight: 1,
        display: "inline-block",
      },
      "& .MuiButton-iconSizeMedium > *:first-child": {
        fontSize: iconStyle.fontSize,
      },
      height: wrapperHeight,
    },
    containedLight: {
      ...commonStyle,
      color: style?.color || themeColors["buttonColor"],
      backgroundColor: style?.backgroundColor || themeColors["buttonBgColor"],
      boxShadow: "none !important",
      "&:hover": {
        boxShadow: "none !important",
        color: style?.color || themeColors["buttonColor"],
        backgroundColor: style?.backgroundColor || themeColors["buttonBgColor"],
      },
      width: style?.width,
      padding: style.padding,
      "&.Mui-disabled": {
        color: themeColors["buttonDisabledColor"],
        backgroundColor: themeColors["buttonDisabledBgColor"],
        // color: themeColors["buttonColor"],
        // backgroundColor: themeColors["buttonBgColor"],
        // boxShadow: "none !important",
        // opacity: 0.3,
      },
    },
    contained: {
      ...commonStyle,
      color: themeColors["buttonColor"],
      backgroundColor: themeColors["buttonBgColor"],
      boxShadow: "none !important",
      "&:hover": {
        boxShadow: "none !important",
        color: themeColors["buttonColor"],
        backgroundColor: themeColors["buttonBgColor"],
      },
      width: style?.width,
      padding: style.padding,
      "&.Mui-disabled": {
        color: themeColors["buttonDisabledColor"],
        backgroundColor: themeColors["buttonDisabledBgColor"],
        // color: themeColors["buttonColor"],
        // backgroundColor: themeColors["buttonBgColor"],
        // boxShadow: "none !important",
        // opacity: 0.3,
      },
    },
    containedPrimary: {
      ...commonStyle,
      color: themeColors["buttonColor"],
      backgroundColor: themeColors["primaryColor"],
      boxShadow: "none !important",
      "&:hover": {
        boxShadow: "none !important",
        color: themeColors["buttonColor"],
        backgroundColor: themeColors["primaryColor"],
      },
      width: style?.width,
      padding: style.padding,
      "&.Mui-disabled": {
        color: themeColors["buttonDisabledColor"],
        backgroundColor: themeColors["buttonDisabledBgColor"],
        // color: themeColors["buttonColor"],
        // backgroundColor: themeColors["buttonBgColor"],
        // boxShadow: "none !important",
        // opacity: 0.3,
      },
    },
    containedWhiter: {
      ...commonStyle,
      color: themeColors["buttonColorLightest"],
      backgroundColor: themeColors["headerBgColor"],
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
        color: themeColors["buttonColorLightest"],
        backgroundColor: themeColors["headerBgColor"],
      },
      width: style?.width,
      padding: style.padding,
    },
    containedLighter: {
      ...commonStyle,
      color: style?.color || themeColors["buttonColorLightest"],
      backgroundColor: style?.bgColor || themeColors["buttonBgColorLightest"],
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
        color: themeColors["buttonColorLightest"],
        backgroundColor: themeColors["buttonBgColorLighter"],
      },
      width: style?.width,
      padding: style.padding,
    },
    containedLighterNewBlue: {
      ...commonStyle,
      color: style?.color || themeColors["buttonColorLightest"],
      backgroundColor: style?.bgColor || themeColors["buttonBgColorLightest"],
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
        color: style?.bgHoverColor || themeColors["buttonColorLightest"],
        backgroundColor:
          style?.backgroundColor || themeColors["buttonBgColorLighter"],
      },
      width: style?.width,
      padding: style.padding,
      "&.MuiButton-root.Mui-disabled": {
        //color: themeColors["secondaryColor"],
        backgroundColor: "#e0e0e0",
        color: "#8b8b8b",
        //border: `1px solid ${themeColors["buttonBgColor"]}`,
        //opacity: 0.3,
      },
    },

    containedBlueLighter: {
      ...commonStyle,
      color: themeColors["primaryColor"],
      border: "1px solid #edf1ff",
      backgroundColor: "#fff",
      boxShadow: "none",
      height: "28px",
      "&:hover": {
        boxShadow: "none",
        color: themeColors["primaryColor"],
        backgroundColor: "#fff",
      },
      width: style?.width,
      padding: style.padding,
    },
    outlined: {
      ...commonStyle,
      // borderColor: themeColors["buttonBgColor"],
      color: themeColors["secondaryColor"],
      backgroundColor: "transparent",
      border: `1px solid ${themeColors["buttonBgColor"]}`,
      "&:hover": {
        boxShadow: "none",
        borderColor: themeColors["buttonBgColor"],
        color: themeColors["secondaryColor"],
        backgroundColor: "transparent",
      },
      width: style?.width,
      padding: style.padding,
      "&.MuiButton-root.Mui-disabled": {
        color: themeColors["secondaryColor"],
        backgroundColor: "transparent",
        border: `1px solid ${themeColors["buttonBgColor"]}`,
        opacity: 0.3,
      },
    },
    outlinedPrimary: {
      ...commonStyle,
      // borderColor: themeColors["buttonBgColor"],
      color: themeColors["primaryColor"],
      backgroundColor: "transparent",
      border: `1px solid ${themeColors["primaryColor"]}`,
      "&:hover": {
        boxShadow: "none",
        borderColor: themeColors["primaryColor"],
        color: themeColors["primaryColor"],
        backgroundColor: "transparent",
      },
      width: style?.width,
      padding: style.padding,
      "&.MuiButton-root.Mui-disabled": {
        color: themeColors["secondaryColor"],
        backgroundColor: "transparent",
        border: `1px solid ${themeColors["buttonBgColor"]}`,
        opacity: 0.3,
      },
    },
    outlinedLighter: {
      ...commonStyle,
      color: themeColors["buttonColorLighter"],
      backgroundColor: "transparent",
      border: `1px solid ${themeColors["buttonBgColorLighter"]}`,
      "&:hover": {
        boxShadow: "none",
        borderColor: themeColors["buttonBgColorLighter"],
        color: themeColors["buttonColorLighter"],
        backgroundColor: themeColors["buttonBgColorLightest"],
      },
      width: style?.width,
      padding: style.padding,
    },
    text: {
      ...commonStyle,
      color: themeColors["buttonColorLighter"],
      backgroundColor: "transparent",
      border: "none",
      "&:hover": {
        boxShadow: "none",
        borderColor: themeColors["buttonBgColorLighter"],
        color: themeColors["buttonColorLighter"],
        backgroundColor: themeColors["buttonBgColorLightest"],
      },
      width: style?.width,
      padding: style.padding,
    },
  }));
  const classes = useStyles();

  const startIcon = () => {
    return iconFlag ? (
      <span className={`${iconProps.className} ${customClassName}`}>{iconProps.iconName}</span>
    ) : null;
  };

  return (
    <div className={classes.root}>
      {/* This logic is used for the Loader component it cann't be translated  */}
      {typeof title === "object" ? (
        <Button
          // variant="contained" //to avoid the MUI warning; styling is controlled by our component
          className={`${classes[variant]} ${customClassName}`}
          onClick={onClick}
          disabled={disabled}
          startIcon={startIcon()}
          type={type}
        >
          <div className={`${customClassName}`}>{title}</div>
        </Button>
      ) : (
        <Button
          // variant="contained" //to avoid the MUI warning; styling is controlled by our component
          onClick={onClick}
          disabled={disabled}
          startIcon={startIcon()}
          type={type}
          className={`${classes[variant]} ${customClassName}`}
        >
          <div className={`${customClassName}`}> {t(title)}</div>
        </Button>
      )}
      {/* <Button
        // variant="contained" //to avoid the MUI warning; styling is controlled by our component
        className={classes[variant]}
        onClick={onClick}
        disabled={disabled}
        startIcon={startIcon()}
        type={type}
      >
        {t(title)}
      </Button> */}
    </div>
  );
}

export default SigviewButton;
