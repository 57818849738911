// Import constanst
import C from "../constants";

// Import Utils
import orderBy from "lodash/orderBy";
import {
  getValidDates,
  getSelectedDates,
  getStartEndByDatePreset,
  getDatesSelectedQE,
  getPresetBasedOnSelectedDates,
  getStartEndBySelectedDatesInEpoch,
  getDefaultCompareDates,
  getCompareValidDates,
  getDateAllVariationsFromNativeObject,
  getStartEndByDatePresetForCompare,
  getCompareNewRow,
  getDefaultStartAndEndDateEpochBasedOnValidDates,
} from "../../utils/timeFiltersUtils";
import allCompareDatePresets from "../../../assets/data/allCompareDatePresets.json";

export default function timeFilters(state = {}, action) {
  let {
    type,
    selectedTimezone,
    startDateEpoch,
    endDateEpoch,
    selectedStartDate,
    selectedEndDate,
    selectedDatePreset,
    allDatePresets,
    selectedStartDateEpoch,
    selectedEndDateEpoch,
    format,
    isComparisonOn,
    newTimeFilters,
    daysLimit,
    compareRowId,
    compareSelectedStartDate,
    newComparisonType,
    addedCompareRow,
    newCompareDatePreset,
    initialStartDateEpoch,
    initialEndDateEpoch,
  } = action;
  let newTimezone,
    newValidDates,
    newValidDatePresets,
    newSelectedDates,
    newSelectedDatesQE,
    newSelectedDatePreset,
    isSelectedDatePresetValid,
    newValidDatePresetsIdOnly,
    newCompareDates;

  //Changing type of epoch to integer
  startDateEpoch = parseInt(startDateEpoch);
  endDateEpoch = parseInt(endDateEpoch);

  switch (type) {
    case C.UPDATE_TIME_FILTERS:
      //Update validDates
      newValidDates = getValidDates({
        startDateEpoch,
        endDateEpoch,
        selectedTimezone,
        format,
      });

      //Update validDatePresets
      newValidDatePresets = allDatePresets.filter(
        (datePresetRow) =>
          getStartEndByDatePreset({
            startDateEpoch,
            endDateEpoch,
            selectedDatePreset: datePresetRow.id,
            selectedTimezone,
            format,
            daysLimit,
            validDates: newValidDates,
          }).valid
      );

      //If progressiveDates is false, make time filters based on selectedStartDateEpoch and selectedEndDateEpoch
      if (selectedStartDateEpoch && selectedEndDateEpoch) {
        //Update selectedDates
        newSelectedDates = getStartEndBySelectedDatesInEpoch({
          startDateEpoch,
          endDateEpoch,
          selectedStartDateEpoch,
          selectedEndDateEpoch: selectedEndDateEpoch - 1, //subtracting 1 from the value to cater to UI
          selectedTimezone,
          format,
        });
      } else {
        //Update selectedDates
        newSelectedDates = getStartEndByDatePreset({
          startDateEpoch,
          endDateEpoch,
          selectedDatePreset,
          selectedTimezone,
          format,
          daysLimit,
          validDates: newValidDates,
        });
      }

      //Update selectedDatesQE
      newSelectedDatesQE = getDatesSelectedQE(newSelectedDates);

      //Check if the selected date preset is valid
      //Update validDatePresets
      newValidDatePresets = allDatePresets.filter(
        (datePresetRow) =>
          getStartEndByDatePreset({
            startDateEpoch,
            endDateEpoch,
            selectedDatePreset: datePresetRow.id,
            selectedTimezone,
            format,
            daysLimit,
            validDates: newValidDates,
          }).valid
      );
      newValidDatePresetsIdOnly = newValidDatePresets.map(
        (datePresetRow) => datePresetRow.id
      );
      isSelectedDatePresetValid =
        newValidDatePresetsIdOnly.includes(selectedDatePreset);

      newSelectedDatePreset = isSelectedDatePresetValid
        ? selectedDatePreset
        : "custom";

      //Update all compare data
      let compareSelectedStartDateLength = compareSelectedStartDate
        ? compareSelectedStartDate.length
        : 1;
      newCompareDates = [...Array(compareSelectedStartDateLength).keys()]
        .map((row) => getCompareNewRow())
        .map((compareRow, index) => {
          let newCompareSelectedDates, newCompareSelectedDatesSameDuration;

          //If compare SelectedStartDate is present
          //It means the user selected a date in comparison calendar which was different from default
          if (compareSelectedStartDate) {
            //Getting all variations of compareSelectedStartDate

            let validComparisonStartDate = compareSelectedStartDate[index];

            let timezoneCorrectionFactor =
              validComparisonStartDate.getTimezoneOffset() * 60 * 1000;

            let validepoch =
              validComparisonStartDate.valueOf() - timezoneCorrectionFactor;

            if (selectedStartDateEpoch && selectedEndDateEpoch) {
            } else {
              let diff =
                newSelectedDates.startDate.epoch - initialStartDateEpoch;

              let modCompEpoch = validepoch + diff + timezoneCorrectionFactor;

              var d = new Date(modCompEpoch);

              validComparisonStartDate = d;
            }

            let compareSelectedStartDateAllVariations =
              getDateAllVariationsFromNativeObject({
                selectedDateNativeObject: validComparisonStartDate,
                selectedTimezone,
                format: state.format,
              });

            //Update compareSelectedDates
            newCompareSelectedDates = getDefaultCompareDates({
              compareSelectedStartDate: compareSelectedStartDateAllVariations,
              selectedDates: newSelectedDates,
              selectedTimezone,
              format,
              startDateEpoch,
              endDateEpoch,
              sameDurationFlag: false,
            });

            newCompareSelectedDatesSameDuration = getDefaultCompareDates({
              compareSelectedStartDate: compareSelectedStartDateAllVariations,
              selectedDates: newSelectedDates,
              selectedTimezone,
              format,
              startDateEpoch,
              endDateEpoch,
              sameDurationFlag: true,
            });
          } else {
            //Update compareSelectedDates
            newCompareSelectedDates = getDefaultCompareDates({
              selectedDates: newSelectedDates,
              selectedTimezone,
              format: format,
              startDateEpoch,
              endDateEpoch,
              sameDurationFlag: false,
            });

            newCompareSelectedDatesSameDuration = getDefaultCompareDates({
              selectedDates: newSelectedDates,
              selectedTimezone,
              format: format,
              startDateEpoch,
              endDateEpoch,
              sameDurationFlag: true,
            });
          }

          //Update compareSelectedDates
          let newCompareSelectedDatesQE = getDatesSelectedQE(
            newCompareSelectedDates
          );

          let newCompareSelectedDatesQESameDuration = getDatesSelectedQE(
            newCompareSelectedDatesSameDuration
          );

          //Update compare valid dates
          let newCompareValidDates = getCompareValidDates({
            startDateEpoch,
            endDateEpoch,
            selectedTimezone,
            format,
            selectedDates: newSelectedDates,
          });

          // Update valid presets
          let newCompareValidDatePresets = allCompareDatePresets.filter(
            (datePresetRow) =>
              getStartEndByDatePresetForCompare({
                selectedDates: newSelectedDates,
                mainSelectedDatePreset: newSelectedDatePreset,
                currDatePreset: datePresetRow.id,
                selectedTimezone,
                format,
                startDateEpoch,
                endDateEpoch,
              }).valid
          );

          //Make new row
          let newCompareRow = {
            ...compareRow,
            compareSelectedDates: newCompareSelectedDates,
            compareValidDates: newCompareValidDates,
            compareSelectedDatesQE: newCompareSelectedDatesQE,
            compareValidDatePresets: newCompareValidDatePresets,
            compareSelectedDatePreset: "custom", //hard coded
            compareSelectedDatesSameDuration:
              newCompareSelectedDatesSameDuration,
            compareSelectedDatesQESameDuration:
              newCompareSelectedDatesQESameDuration,
            isSelected: index === 0,
          };
          return newCompareRow;
        });

      return {
        ...state,
        isLoading: false,
        selectedTimezone,
        validDates: newValidDates,
        selectedDates: newSelectedDates,
        validDatePresets: newValidDatePresets,
        selectedDatePreset: newSelectedDatePreset,
        selectedDatesQE: newSelectedDatesQE,
        compareDates: newCompareDates,
        isComparisonOn: isComparisonOn || false,
        format: format,
      };

    //Default dates means last_2_days wrt valid endDate epoch
    case C.UPDATE_TIME_FILTERS_WITH_DEFAULT_DATES:
      //Update validDates
      newValidDates = getValidDates({
        startDateEpoch,
        endDateEpoch,
        selectedTimezone,
        format,
      });

      //Updating selectedStartDates
      let defaultDates = getDefaultStartAndEndDateEpochBasedOnValidDates({
        validDates: newValidDates,
        selectedTimezone,
      });
      selectedStartDateEpoch = defaultDates.startDateEpoch;
      selectedEndDateEpoch = defaultDates.endDateEpoch;

      //Update validDates
      newValidDates = getValidDates({
        startDateEpoch,
        endDateEpoch,
        selectedTimezone,
        format,
      });

      //Update validDatePresets
      newValidDatePresets = allDatePresets.filter(
        (datePresetRow) =>
          getStartEndByDatePreset({
            startDateEpoch,
            endDateEpoch,
            selectedDatePreset: datePresetRow.id,
            selectedTimezone,
            format,
            daysLimit,
            validDates: newValidDates,
          }).valid
      );

      //Update selectedDates
      newSelectedDates = getStartEndBySelectedDatesInEpoch({
        startDateEpoch,
        endDateEpoch,
        selectedStartDateEpoch,
        selectedEndDateEpoch: selectedEndDateEpoch,
        selectedTimezone,
        format,
      });

      //Update selectedDatesQE
      newSelectedDatesQE = getDatesSelectedQE(newSelectedDates);

      //Check if the selected date preset is valid
      //Update validDatePresets
      newValidDatePresets = allDatePresets.filter(
        (datePresetRow) =>
          getStartEndByDatePreset({
            startDateEpoch,
            endDateEpoch,
            selectedDatePreset: datePresetRow.id,
            selectedTimezone,
            format,
            daysLimit,
            validDates: newValidDates,
          }).valid
      );
      newSelectedDatePreset = "custom";

      //Update all compare data
      newCompareDates = [getCompareNewRow()].map((compareRow) => {
        let newCompareSelectedDates, newCompareSelectedDatesSameDuration;

        //If compare SelectedStartDate is present
        //It means the user selected a date in comparison calendar which was different from default
        if (compareSelectedStartDate) {
          //Getting all variations of compareSelectedStartDate
          let compareSelectedStartDateAllVariations =
            getDateAllVariationsFromNativeObject({
              selectedDateNativeObject: compareSelectedStartDate,
              selectedTimezone,
              format: format,
            });

          //Update compareSelectedDates
          newCompareSelectedDates = getDefaultCompareDates({
            compareSelectedStartDate: compareSelectedStartDateAllVariations,
            selectedDates: newSelectedDates,
            selectedTimezone,
            format,
            startDateEpoch,
            endDateEpoch,
            sameDurationFlag: false,
          });

          newCompareSelectedDatesSameDuration = getDefaultCompareDates({
            compareSelectedStartDate: compareSelectedStartDateAllVariations,
            selectedDates: newSelectedDates,
            selectedTimezone,
            format,
            startDateEpoch,
            endDateEpoch,
            sameDurationFlag: true,
          });
        } else {
          //Update compareSelectedDates
          newCompareSelectedDates = getDefaultCompareDates({
            selectedDates: newSelectedDates,
            selectedTimezone,
            format: format,
            startDateEpoch,
            endDateEpoch,
            sameDurationFlag: false,
          });

          newCompareSelectedDatesSameDuration = getDefaultCompareDates({
            selectedDates: newSelectedDates,
            selectedTimezone,
            format: format,
            startDateEpoch,
            endDateEpoch,
            sameDurationFlag: true,
          });
        }

        //Update compareSelectedDates
        let newCompareSelectedDatesQE = getDatesSelectedQE(
          newCompareSelectedDates
        );

        let newCompareSelectedDatesQESameDuration = getDatesSelectedQE(
          newCompareSelectedDatesSameDuration
        );

        //Update compare valid dates
        let newCompareValidDates = getCompareValidDates({
          startDateEpoch,
          endDateEpoch,
          selectedTimezone,
          format,
          selectedDates: newSelectedDates,
        });

        // Update valid presets
        let newCompareValidDatePresets = allCompareDatePresets.filter(
          (datePresetRow) =>
            getStartEndByDatePresetForCompare({
              selectedDates: newSelectedDates,
              mainSelectedDatePreset: newSelectedDatePreset,
              currDatePreset: datePresetRow.id,
              selectedTimezone,
              format,
              startDateEpoch,
              endDateEpoch,
            }).valid
        );

        //Make new row
        let newCompareRow = {
          ...compareRow,
          compareSelectedDates: newCompareSelectedDates,
          compareValidDates: newCompareValidDates,
          compareSelectedDatesQE: newCompareSelectedDatesQE,
          compareValidDatePresets: newCompareValidDatePresets,
          compareSelectedDatePreset: "custom", //hard coded
          compareSelectedDatesSameDuration: newCompareSelectedDatesSameDuration,
          compareSelectedDatesQESameDuration:
            newCompareSelectedDatesQESameDuration,
        };
        return newCompareRow;
      });

      return {
        ...state,
        isLoading: false,
        selectedTimezone,
        validDates: newValidDates,
        selectedDates: newSelectedDates,
        validDatePresets: newValidDatePresets,
        selectedDatePreset: newSelectedDatePreset,
        selectedDatesQE: newSelectedDatesQE,
        compareDates: newCompareDates,
        isComparisonOn: isComparisonOn || false,
        format: format,
      };

    case C.TIMEZONE_CHANGED:
      //Update selectedTimezone
      newTimezone = { ...selectedTimezone };

      //Update validDates
      newValidDates = getValidDates({
        startDateEpoch,
        endDateEpoch,
        selectedTimezone,
        format,
      });

      //Update validDatePresets
      newValidDatePresets = allDatePresets.filter(
        (datePresetRow) =>
          getStartEndByDatePreset({
            startDateEpoch,
            endDateEpoch,
            selectedDatePreset: datePresetRow.id,
            selectedTimezone,
            format,
            daysLimit,
            validDates: newValidDates,
          }).valid
      );
      newValidDatePresetsIdOnly = newValidDatePresets.map(
        (datePresetRow) => datePresetRow.id
      );

      //The order of preference is below:
      //If selectedDatePreset in present:
      //And it is custom, do this
      //And it is not custom but is valid, do this
      //And it is not custom and is not valid, pick the first available date preset and get newSelectedDates based on that
      //If not present, pick the first available date preset and get newSelectedDates based on that
      if (selectedDatePreset) {
        if (selectedDatePreset === "custom") {
          newSelectedDatePreset = selectedDatePreset.toString();

          if (selectedStartDate && selectedEndDate) {
            //Update selectedDates
            newSelectedDates = getSelectedDates({
              selectedStartDate,
              selectedEndDate,
              selectedTimezone,
              startDateEpoch,
              endDateEpoch,
              format,
            });

            //Get preset based on selectedDates
            newSelectedDatePreset = getPresetBasedOnSelectedDates({
              startDateEpoch,
              endDateEpoch,
              selectedTimezone,
              allDatePresets,
              format,
              newSelectedDates,
              daysLimit,
            });
          } else {
            newSelectedDatePreset = orderBy(
              newValidDatePresets,
              ["priority"],
              ["asc"]
            )[0].id;
            newSelectedDates = getStartEndByDatePreset({
              startDateEpoch,
              endDateEpoch,
              selectedDatePreset: newSelectedDatePreset,
              selectedTimezone,
              format,
              daysLimit,
              validDates: newValidDates,
            });
            newSelectedDatePreset = "custom";
          }
        } else if (newValidDatePresetsIdOnly.includes(selectedDatePreset)) {
          newSelectedDatePreset = selectedDatePreset.toString();

          //Update selectedDates
          newSelectedDates = getStartEndByDatePreset({
            startDateEpoch,
            endDateEpoch,
            selectedDatePreset,
            selectedTimezone,
            format,
            daysLimit,
            validDates: newValidDates,
          });
        } else if (!newValidDatePresetsIdOnly.includes(selectedDatePreset)) {
          newSelectedDatePreset = orderBy(
            newValidDatePresets,
            ["priority"],
            ["asc"]
          )[0].id;
          newSelectedDates = getStartEndByDatePreset({
            startDateEpoch,
            endDateEpoch,
            selectedDatePreset: newSelectedDatePreset,
            selectedTimezone,
            format,
            daysLimit,
            validDates: newValidDates,
          });
        }
      } else {
        //This is for the case when timezoneChanged is called from App.js for initial initialization
        newSelectedDatePreset = orderBy(
          newValidDatePresets,
          ["priority"],
          ["asc"]
        )[0].id;
        newSelectedDates = getStartEndByDatePreset({
          startDateEpoch,
          endDateEpoch,
          selectedDatePreset: newSelectedDatePreset,
          selectedTimezone,
          format,
          daysLimit,
          validDates: newValidDates,
        });
      }

      //Update selectedDatesQE
      newSelectedDatesQE = getDatesSelectedQE(newSelectedDates);

      //Update all compare data
      newCompareDates = state.compareDates.map((compareRow) => {
        //Update compareSelectedDates
        let newCompareSelectedDates = getDefaultCompareDates({
          selectedDates: newSelectedDates,
          selectedTimezone: newTimezone,
          format: format,
          startDateEpoch,
          endDateEpoch,
          sameDurationFlag: false,
        });

        let newCompareSelectedDatesSameDuration = getDefaultCompareDates({
          selectedDates: newSelectedDates,
          selectedTimezone: newTimezone,
          format: format,
          startDateEpoch,
          endDateEpoch,
          sameDurationFlag: true,
        });

        //Update compareSelectedDates
        let newCompareSelectedDatesQE = getDatesSelectedQE(
          newCompareSelectedDates
        );

        let newCompareSelectedDatesQESameDuration = getDatesSelectedQE(
          newCompareSelectedDatesSameDuration
        );

        //Update compare valid dates
        let newCompareValidDates = getCompareValidDates({
          startDateEpoch,
          endDateEpoch,
          selectedTimezone,
          format,
          selectedDates: newSelectedDates,
        });

        // Update valid presets
        let newCompareValidDatePresets = allCompareDatePresets.filter(
          (datePresetRow) =>
            getStartEndByDatePresetForCompare({
              selectedDates: newSelectedDates,
              mainSelectedDatePreset: newSelectedDatePreset,
              currDatePreset: datePresetRow.id,
              selectedTimezone: newTimezone,
              format: state.format,
              startDateEpoch,
              endDateEpoch,
            }).valid
        );

        //Make new row
        let newCompareRow = {
          ...compareRow,
          compareSelectedDates: newCompareSelectedDates,
          compareValidDates: newCompareValidDates,
          compareSelectedDatesQE: newCompareSelectedDatesQE,
          compareValidDatePresets: newCompareValidDatePresets,
          compareSelectedDatePreset: "custom", //hard coded
          compareSelectedDatesSameDuration: newCompareSelectedDatesSameDuration,
          compareSelectedDatesQESameDuration:
            newCompareSelectedDatesQESameDuration,
        };
        return newCompareRow;
      });

      return {
        ...state,
        isLoading: false,
        selectedTimezone: newTimezone,
        validDates: newValidDates,
        selectedDates: newSelectedDates,
        validDatePresets: newValidDatePresets,
        selectedDatePreset: newSelectedDatePreset,
        selectedDatesQE: newSelectedDatesQE,
        compareDates: newCompareDates,
      };

    case C.DATE_PRESET_CHANGED:
      //Don't do anything if selectedDatePreset is custom
      if (selectedDatePreset === "custom")
        return { ...state, selectedDatePreset };

      //Update selectedDates
      newSelectedDates = getStartEndByDatePreset({
        startDateEpoch,
        endDateEpoch,
        selectedDatePreset,
        selectedTimezone,
        format,
        daysLimit,
        validDates: newValidDates,
      });

      //Update selectedDatesQE
      newSelectedDatesQE = getDatesSelectedQE(newSelectedDates);

      //Update all compare data
      newCompareDates = state.compareDates.map((compareRow) => {
        //Update compareSelectedDates
        let newCompareSelectedDates = getDefaultCompareDates({
          selectedDates: newSelectedDates,
          selectedTimezone,
          format: format,
          startDateEpoch,
          endDateEpoch,
          sameDurationFlag: false,
        });

        let newCompareSelectedDatesSameDuration = getDefaultCompareDates({
          selectedDates: newSelectedDates,
          selectedTimezone,
          format: format,
          startDateEpoch,
          endDateEpoch,
          sameDurationFlag: true,
        });

        //Update compareSelectedDates
        let newCompareSelectedDatesQE = getDatesSelectedQE(
          newCompareSelectedDates
        );

        let newCompareSelectedDatesQESameDuration = getDatesSelectedQE(
          newCompareSelectedDatesSameDuration
        );

        //Update compare valid dates
        let newCompareValidDates = getCompareValidDates({
          startDateEpoch,
          endDateEpoch,
          selectedTimezone,
          format,
          selectedDates: newSelectedDates,
        });

        // Update valid presets
        let newCompareValidDatePresets = allCompareDatePresets.filter(
          (datePresetRow) =>
            getStartEndByDatePresetForCompare({
              selectedDates: newSelectedDates,
              mainSelectedDatePreset: selectedDatePreset,
              currDatePreset: datePresetRow.id,
              selectedTimezone: state.selectedTimezone,
              format: state.format,
              startDateEpoch,
              endDateEpoch,
            }).valid
        );

        let newCompareRow = {
          ...compareRow,
          compareSelectedDates: newCompareSelectedDates,
          compareValidDates: newCompareValidDates,
          compareSelectedDatesQE: newCompareSelectedDatesQE,
          compareValidDatePresets: newCompareValidDatePresets,
          compareSelectedDatePreset: "custom", //hard coded
          compareSelectedDatesSameDuration: newCompareSelectedDatesSameDuration,
          compareSelectedDatesQESameDuration:
            newCompareSelectedDatesQESameDuration,
        };
        return newCompareRow;
      });

      return {
        ...state,
        selectedDatePreset,
        selectedDates: newSelectedDates,
        selectedDatesQE: newSelectedDatesQE,
        compareDates: newCompareDates,
      };

    case C.DATE_SELECTED_CHANGED:
      //Update selectedDates
      newSelectedDates = getSelectedDates({
        selectedStartDate,
        selectedEndDate,
        selectedTimezone,
        startDateEpoch,
        endDateEpoch,
        format,
      });

      //Get preset based on selectedDates
      newSelectedDatePreset = getPresetBasedOnSelectedDates({
        startDateEpoch,
        endDateEpoch,
        selectedTimezone,
        allDatePresets,
        format,
        newSelectedDates,
        daysLimit,
      });

      //Update selectedDatesQE
      newSelectedDatesQE = getDatesSelectedQE(newSelectedDates);

      //Update all compare data
      newCompareDates = state.compareDates.map((compareRow) => {
        //Update compareSelectedDates
        let newCompareSelectedDates = getDefaultCompareDates({
          selectedDates: newSelectedDates,
          selectedTimezone,
          format: format,
          startDateEpoch,
          endDateEpoch,
          sameDurationFlag: false,
        });

        let newCompareSelectedDatesSameDuration = getDefaultCompareDates({
          selectedDates: newSelectedDates,
          selectedTimezone,
          format: format,
          startDateEpoch,
          endDateEpoch,
          sameDurationFlag: true,
        });

        //Update compareSelectedDates
        let newCompareSelectedDatesQE = getDatesSelectedQE(
          newCompareSelectedDates
        );

        let newCompareSelectedDatesQESameDuration = getDatesSelectedQE(
          newCompareSelectedDatesSameDuration
        );

        //Update compare valid dates
        let newCompareValidDates = getCompareValidDates({
          startDateEpoch,
          endDateEpoch,
          selectedTimezone,
          format,
          selectedDates: newSelectedDates,
        });

        // Update valid presets
        let newCompareValidDatePresets = allCompareDatePresets.filter(
          (datePresetRow) =>
            getStartEndByDatePresetForCompare({
              selectedDates: newSelectedDates,
              mainSelectedDatePreset: newSelectedDatePreset,
              currDatePreset: datePresetRow.id,
              selectedTimezone: state.selectedTimezone,
              format: state.format,
              startDateEpoch,
              endDateEpoch,
            }).valid
        );

        let newCompareRow = {
          ...compareRow,
          compareSelectedDates: newCompareSelectedDates,
          compareValidDates: newCompareValidDates,
          compareSelectedDatesQE: newCompareSelectedDatesQE,
          compareValidDatePresets: newCompareValidDatePresets,
          compareSelectedDatePreset: "custom", //hard coded
          compareSelectedDatesSameDuration: newCompareSelectedDatesSameDuration,
          compareSelectedDatesQESameDuration:
            newCompareSelectedDatesQESameDuration,
        };
        return newCompareRow;
      });

      return {
        ...state,
        selectedDates: newSelectedDates,
        selectedDatesQE: newSelectedDatesQE,
        selectedDatePreset: newSelectedDatePreset,
        compareDates: newCompareDates,
        selectedDatesQE: newSelectedDatesQE,
      };

    case C.COMPARE_DATE_SELECTED_CHANGED:
      //todo
      //Get preset based on selectedDates
      // newSelectedDatePreset = getPresetBasedOnSelectedDates({
      //   startDateEpoch,
      //   endDateEpoch,
      //   selectedTimezone,
      //   allDatePresets,
      //   format,
      //   newSelectedDates,
      //   daysLimit,
      // });

      //Update all compare data
      newCompareDates = state.compareDates.map((compareRow) => {
        if (compareRow.id !== compareRowId) {
          return compareRow;
        } else {
          //Getting all variations of compareSelectedStartDate
          let compareSelectedStartDateAllVariations =
            getDateAllVariationsFromNativeObject({
              selectedDateNativeObject: compareSelectedStartDate,
              selectedTimezone: state.selectedTimezone,
              format: state.format,
            });

          //Update compareSelectedDates
          let newCompareSelectedDates = getDefaultCompareDates({
            compareSelectedStartDate: compareSelectedStartDateAllVariations,
            selectedDates: state.selectedDates,
            selectedTimezone: state.selectedTimezone,
            format: state.format,
            startDateEpoch: state.validDates.startDate.epoch,
            endDateEpoch: state.validDates.endDate.epoch,
            sameDurationFlag: false,
          });

          let newCompareSelectedDatesSameDuration = getDefaultCompareDates({
            compareSelectedStartDate: compareSelectedStartDateAllVariations,
            selectedDates: state.selectedDates,
            selectedTimezone: state.selectedTimezone,
            format: state.format,
            startDateEpoch: state.validDates.startDate.epoch,
            endDateEpoch: state.validDates.endDate.epoch,
            sameDurationFlag: true,
          });

          //Update compareSelectedDates
          let newCompareSelectedDatesQE = getDatesSelectedQE(
            newCompareSelectedDates
          );

          let newCompareSelectedDatesQESameDuration = getDatesSelectedQE(
            newCompareSelectedDatesSameDuration
          );

          //Update compare valid dates
          let newCompareValidDates = getCompareValidDates({
            startDateEpoch: state.validDates.startDate.epoch,
            endDateEpoch: state.validDates.endDate.epoch,
            selectedTimezone: state.selectedTimezone,
            format: state.format,
            selectedDates: state.selectedDates,
          });
          let newCompareRow = {
            ...compareRow,
            compareSelectedDates: newCompareSelectedDates,
            compareValidDates: newCompareValidDates,
            compareSelectedDatesQE: newCompareSelectedDatesQE,
            compareSelectedDatePreset: "custom", //hard coded
            compareSelectedDatesSameDuration:
              newCompareSelectedDatesSameDuration,
            compareSelectedDatesQESameDuration:
              newCompareSelectedDatesQESameDuration,
          };
          return newCompareRow;
        }
      });

      return {
        ...state,
        compareDates: newCompareDates,
      };

    case C.COMPARE_DATE_PRESET_CHANGE:
      //Update all compare data
      newCompareDates = state.compareDates.map((compareRow) => {
        if (compareRow.id !== compareRowId) {
          return compareRow;
        } else {
          if (newCompareDatePreset === "custom")
            return { ...compareRow, compareSelectedDatePreset: "custom" };
          //Update compareSelectedDates
          let newCompareSelectedDates = getStartEndByDatePresetForCompare({
            selectedDates: state.selectedDates,
            mainSelectedDatePreset: state.selectedDatePreset,
            currDatePreset: newCompareDatePreset,
            selectedTimezone: state.selectedTimezone,
            format: state.format,
            sameDurationFlag: false,
            startDateEpoch: state.validDates.startDate.epoch,
            endDateEpoch: state.validDates.endDate.epoch,
          });

          let newCompareSelectedDatesSameDuration =
            getStartEndByDatePresetForCompare({
              selectedDates: state.selectedDates,
              selectedTimezone: state.selectedTimezone,
              format: state.format,
              mainSelectedDatePreset: state.selectedDatePreset,
              currDatePreset: newCompareDatePreset,
              sameDurationFlag: true,
              startDateEpoch: state.validDates.startDate.epoch,
              endDateEpoch: state.validDates.endDate.epoch,
            });

          //Update compareSelectedDates
          let newCompareSelectedDatesQE = getDatesSelectedQE(
            newCompareSelectedDates
          );

          let newCompareSelectedDatesQESameDuration = getDatesSelectedQE(
            newCompareSelectedDatesSameDuration
          );

          //Make new row
          let newCompareRow = {
            ...compareRow,
            compareSelectedDates: newCompareSelectedDates,
            compareSelectedDatesQE: newCompareSelectedDatesQE,
            compareSelectedDatePreset: newCompareDatePreset,
            compareSelectedDatesSameDuration:
              newCompareSelectedDatesSameDuration,
            compareSelectedDatesQESameDuration:
              newCompareSelectedDatesQESameDuration,
          };
          return newCompareRow;
        }
      });

      return { ...state, compareDates: newCompareDates };

    case C.TOGGLE_COMPARE:
      return {
        ...state,
        isComparisonOn: !state.isComparisonOn,
      };

    case C.UPDATE_CALENDAR_COMPARISON_TYPE:
      return {
        ...state,
        comparisonType: newComparisonType,
      };

    case C.UPDATE_ACTIVE_COMPARE_DATE_ROW:
      newCompareDates = state.compareDates.map((compareRow) =>
        compareRow.id !== compareRowId
          ? { ...compareRow, isSelected: false }
          : { ...compareRow, isSelected: true }
      );
      return {
        ...state,
        compareDates: newCompareDates,
      };

    case C.ADD_COMPARE_ROW:
      let newCompareSelectedDates = getDefaultCompareDates({
        selectedDates: state.selectedDates,
        selectedTimezone: state.selectedTimezone,
        format: state.format,
        startDateEpoch: state.validDates.startDate.epoch,
        endDateEpoch: state.validDates.endDate.epoch,
      });

      //Update compareSelectedDates
      let newCompareSelectedDatesQE = getDatesSelectedQE(
        newCompareSelectedDates
      );

      //Update compare valid dates
      let newCompareValidDates = getCompareValidDates({
        selectedDates: state.selectedDates,
        selectedTimezone: state.selectedTimezone,
        format: state.format,
        startDateEpoch: state.validDates.startDate.epoch,
        endDateEpoch: state.validDates.endDate.epoch,
      });

      let newCompareSelectedDatesSameDuration = getDefaultCompareDates({
        selectedDates: newCompareSelectedDates,
        selectedTimezone: state.selectedTimezone,
        format: state.format,
        startDateEpoch: state.validDates.startDate.epoch,
        endDateEpoch: state.validDates.endDate.epoch,
        sameDurationFlag: true,
      });

      let newCompareSelectedDatesQESameDuration = getDatesSelectedQE(
        newCompareSelectedDatesSameDuration
      );

      let newCompareRow = {
        ...addedCompareRow,
        compareSelectedDates: newCompareSelectedDates,
        compareValidDates: newCompareValidDates,
        compareSelectedDatesQE: newCompareSelectedDatesQE,
        newCompareSelectedDatesSameDuration:
          newCompareSelectedDatesSameDuration,
        compareSelectedDatesQESameDuration:
          newCompareSelectedDatesQESameDuration,
        compareSelectedDatePreset: "custom", //hard coded
      };

      //Update all compare data
      newCompareDates = [...state.compareDates, newCompareRow];

      return {
        ...state,
        compareDates: newCompareDates,
      };

    case C.REMOVE_COMPARE_ROW:
      newCompareDates = state.compareDates.filter(
        (compareRow) => compareRow.id !== compareRowId
      );

      //If user deletes a row which was selected, make first row selected as default
      let isAnyRowSelected = newCompareDates.find((row) => row.isSelected);
      if (!isAnyRowSelected) {
        newCompareDates = [
          { ...newCompareDates[0], isSelected: true },
          ...newCompareDates.slice(1),
        ];
      }

      return {
        ...state,
        compareDates: newCompareDates,
      };

    case C.REPLACE_TIME_FILTERS:
      return {
        ...newTimeFilters,
      };

    default:
      return state;
  }
}
