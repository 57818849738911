// * Import required libraies
import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";

// * Import lib comp
import { Box } from "@material-ui/core";

// * Import Custom Component
import LayoutTopSideBottomAdmin from "../../../layouts/LayoutTopSideBottomAdmin/LayoutTopSideBottomAdmin";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import SigviewAdminTable from "../../../components/SigviewAdminTable/SigviewAdminTable";
import AppMenuBar from "../../../components/AppMenuBar";
import Loader from "../../../components/Loader/Loader";
import { config } from "../../../config/config";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";

// * Import utils, config & static data
import { updateUserScreen, updateData } from "../../../redux/actions";
import {
  getBreadcrumbsData,
  getBreadcrumbsDataAdminAws,
  getBreadcrumbsDataFromRoute,
} from "../../../utils/utils";
import { unWrapperUsers } from "../../../utils/adminUtils";
import { masterTrackGaEvent } from "../../../../js/services/ga/index";

// * Import APIs
import { readUsersByOrg } from "../../../services/api";
import { useHistory } from "react-router";

function Users(props) {
  // * Destructure props
  const { dispatch: ReduxDispatcher, user, allData = {} } = props;
  const { views: viewsData } = allData;
  const { adminUser } = allData;

  // * Define contexts
  const history = useHistory();

  if (viewsData.status !== "success") {
    history.push("/admin");
  }

  // * Define required states
  const [usersData, setUsersData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });
  const [searchField, setSearchField] = useState("");

  // * Define required side effects
  // Fetch groups Data
  useEffect(() => {
    // Make fetch call using axios
    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
    };
    const readAllUsersViews = readUsersByOrg(fetchProps);
    readAllUsersViews
      .then((data) => {
        setUsersData({
          result: data?.result?.data,
          status: "success",
          messsage: "",
        });
        let newData = {
          result: data.result.data,
          status: "success",
          messsage: "",
        };
        ReduxDispatcher(updateData("adminUser", newData));
      })
      .catch((json) => {
        setUsersData({
          result: [],
          status: "error",
          messsage: "API failed",
        });
        // console.log(json);
      });

    return () => {
      setUsersData({
        result: [],
        status: "loading",
        messsage: "",
      });
    };
  }, []);

  // * Define required event handlers
  const handleActiveTab = (e, id) => {
    //Return to home (will be controlled by router later)
    const activeTab = id;
    const value = { activeNav: "admin", activeTab };
    const action = updateUserScreen(null, value);
    ReduxDispatcher(action);
  };

  const handleBreadcrumbChange = (event, value) => {
    history.push(value.path);
  };

  const handleClickRow = (row) => {
    // Google Analytics Event - Master Admin
    masterTrackGaEvent({
      category: "Admin",
      action: "UserOpen",
      label: row._id,
    });

    //update screen
    // Both the action can be dispatch into one reducers
    //console.log("row", row);
    const activeTab = "user";
    const newActiveAdminUser = {
      payload: {
        ...row,
      },
      metadata: { crudType: "edit", name: row.email },
    };

    const value = { activeNav: "admin", activeTab };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);

    var action = updateUserScreen("activeAdminUser", newActiveAdminUser);
    ReduxDispatcher(action);

    const pathname = "/admin/user";
    history.push(pathname);
  };
  const handleSearchFieldChange = (event, value) => setSearchField(value);
  const handleCreateNewUser = () => {
    // ! metadata name must be replaces with no of users +1
    // ! action dispatch to the user selection state
    // ! for the untitled 1 we have to dispatch the action on selection (state)
    // ! Assigned group bhi dispatch karna hai
    // ! role must be user

    // Google Analytics Event - Master Admin
    masterTrackGaEvent({
      category: "Admin",
      action: "UserOpen",
      label: "CreateNew",
    });

    const newActiveAdminUser = {
      payload: {},
      metadata: {
        crudType: "create",
        name: `Untitled-${usersData?.result.length + 1}`,
      },
    };

    // update screen
    const activeTab = "user";
    const value = { activeNav: "admin", activeTab };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);

    var action = updateUserScreen("activeAdminUser", newActiveAdminUser);
    ReduxDispatcher(action);
  };

  // * Define required static variables for props
  const activeTab = user.screen.activeTab;
  const breadcrumbsData = getBreadcrumbsDataFromRoute(user, history);
  const layoutTopSideBottomAdminProps = {
    activeTab,
    handleActiveTab,
  };
  const buttonProps = {
    handleClick: handleCreateNewUser,
    title: "Create New User",
    flag: false,
  };
  const appMenuBarProps = {
    buttonProps,
    onSearchFieldChange: handleSearchFieldChange,
  };
  const modifiedUsersData = usersData.result.map((row) => {
    return {
      userName: row.firstName + " " + row.lastName,
      ...row,
      createdOn: moment(Number(row.createdOn)).format("MMMM Do YYYY"),
      lastSuccessLoginTime:
        row.lastSuccessLoginTime === "0"
          ? "Inactive User"
          : moment(Number(row.lastSuccessLoginTime)).format("MMMM Do YYYY"),
    };
  });
  // const headCells = unWrapperUsers(modifiedUsersData);
  const filteredData = modifiedUsersData.filter((el) =>
    el.userName.toLowerCase().includes(searchField.toLowerCase())
  );

  return (
    <LayoutTopSideBottomAdmin {...layoutTopSideBottomAdminProps}>
      <SigviewBreadcrumb
        data={breadcrumbsData} //data
        onClick={handleBreadcrumbChange} //onClick
      />
      <AppMenuBar {...appMenuBarProps} />
      {usersData.status === "loading" && <Loader />}
      {usersData.status === "error" && (
        <ErrorHandler
          message={config.hardCoded.uiErrorMessage}
          reloadFlag={true}
          onReload={() => {}}
        />
      )}
      {usersData.status === "success" && filteredData.length > 0 && (
        <>
          <Box
            css={{
              padding: "5px 25px 0px 25px",
            }}
          >
            <SigviewAdminTable
              headCells={unWrapperUsers(modifiedUsersData)}
              handleClickRow={handleClickRow}
              rows={filteredData}
            />
          </Box>
        </>
      )}
      {usersData.status === "success" && filteredData.length === 0 && (
        <div className="no-data-container">
          <div className="no-data-image"></div>
          <p className="no-data">{config.messages.noGroups}</p>
        </div>
      )}
    </LayoutTopSideBottomAdmin>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(Users);
