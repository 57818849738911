import { config } from "../config/config";
import { getSigviewUserType } from "./utils";

const authFormDefault = {
  status: "initial",
  message: "",
  isTokenPresent: false,
  isTokenValid: false,
  authData: {},
  sigviewUserType: "sigview",
  apiEndpoints: {},
};

const isSsoUser = (location) =>
  `${location.pathname}${location.hash}${location.search}`.includes(
    "/sso/v1/login"
  );

const isEmbeddedUser = () => window.self !== window.top;

const isUserOpenx = () => {
  const sigviewUserType = getSigviewUserType();
  return sigviewUserType === "nonSigview";
};

const removeAngularHashFromUrl = (location) => {
  var pathname = `${location.pathname}${location.hash}${location.search}`;
  pathname = pathname.replace("/app/#", "");
  return pathname;
};

const redirectToSignInPage = (props = {}) => {
  const { history = () => {}, location = () => {}, authForm = {} } = props;

  // * NEW IMPLEMENTATION
  var pathname = removeAngularHashFromUrl(location);
  const url = window.location.origin + pathname;
  const params = new URLSearchParams(url.split('?')[1]);

  // Fetch userType parameter
  const userType = params.get('userType');
  localStorage.setItem("userType",userType)

  if (isEmbeddedUser()) {
    history.push(pathname);
  } else if (isUserOpenx()) {
    // * Make pathname based on realm and instance_uid
    // 1. If URL contains ?realm=<id>
    // 2. If URL contains ?instanceId=<id>
    // 3. If 1 & 2 don't satisfy, redirect /openxSignIn

    var pathnameId = "";
    var openxPathName = "/openxSignIn";
    if (pathname.indexOf("realm") > -1) {
      pathnameId = pathname.split("realm=").pop();
      openxPathName = `${openxPathName}?realm=${pathnameId}`;
    } else if (pathname.indexOf("instance_uid") > -1) {
      pathnameId = pathname.split("instance_uid=").pop();
      openxPathName = `${openxPathName}?instance_uid=${pathnameId}`;
    }
    history.push(openxPathName);
  } else {
    history.push("/sign-in");
  }

  // ! OLD IMPLEMENTATION
  // var pathname = removeAngularHashFromUrl(location);
  // if (isSsoUser(location)) {
  //   history.push(pathname);
  // } else if (isUserOpenx()) {
  //   // * Make pathname based on realm and instance_uid
  //   // 1. If URL contains ?realm=<id>
  //   // 2. If URL contains ?instanceId=<id>
  //   // 3. If 1 & 2 don't satisfy, redirect /openxSignIn

  //   var pathnameId = "";
  //   var openxPathName = "/openxSignIn";
  //   if (pathname.indexOf("realm") > -1) {
  //     pathnameId = pathname.split("realm=").pop();
  //     openxPathName = `${openxPathName}?realm=${pathnameId}`;
  //   } else if (pathname.indexOf("instance_uid") > -1) {
  //     pathnameId = pathname.split("instance_uid=").pop();
  //     openxPathName = `${openxPathName}?instance_uid=${pathnameId}`;
  //   }
  //   history.push(openxPathName);
  // } else {
  //   history.push("/sign-in");
  // }
};

// * This function is needed because Angular URL doesn't give search params in the location
// Angular URL = /app/#/reset/token?846a9432-cd10-4bae-b384-ab02c71e61a0
// const location = {
//   pathname: "/app/",
//   search: "",
//   hash: "#/reset/token?846a9432-cd10-4bae-b384-ab02c71e61a0",
// };
const removeAllTextAfterQuestionMarkInPathname = (pathname) =>
  pathname.replace(/\?.+/, "");

const isUrlPublic = (location) => {
  var pathname = removeAngularHashFromUrl(location);
  pathname = removeAllTextAfterQuestionMarkInPathname(pathname);
  const isUrlPublicFlag = config.hardCoded.publicUrls.includes(pathname);
  return isUrlPublicFlag;
};

export {
  authFormDefault,
  isSsoUser,
  redirectToSignInPage,
  isUrlPublic,
  removeAngularHashFromUrl,
  isEmbeddedUser,
};
