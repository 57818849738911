// Import required libraies
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const SigviewStyledSwitch = withStyles((theme) => ({
  root: {
    "& .MuiIconButton-root:hover": { backgroundColor: "transparent" },
  },
  switchBase: {
    color: theme.palette.misc["secondaryColorLighter"],
    "&$checked": {
      color: theme.palette.misc["primaryColor"],
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.misc["primaryColor"],
    },
  },
  checked: {},
  track: { backgroundColor: theme.palette.misc["secondaryColorLighter"] },
}))(Switch);

function SigviewSwitch(props) {
  const {
    name = "",
    checked = true,
    onChange = () => {},
    size = "small",
    disableRipple = true,
    disabled = false,
    customClassName,
  } = props;

  const handleChange = (event) => {
    onChange(event, !checked);
  };

  return (
    <div onClick={handleChange}>
      <SigviewStyledSwitch
        checked={checked}
        id={customClassName}
        // onChange={handleChange} //Some CSS in angular input[type="checkbox"] is hindering the onChange trigger (https://stackoverflow.com/questions/54245962/material-uis-switch-component-onchange-handler-is-not-firing)
        // onClick={handleChange}
        name={name}
        size={size}
        disableRipple={disableRipple}
        disabled={disabled}
      />
    </div>
  );
}

export default SigviewSwitch;
