//Import required libraies
import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// Import Custom Component

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import action creators

// Import utils

// Import styles

function SigviewNav(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    value = "",
    data = [],
    onChange = () => {},
    style = {},
    customClassName,
  } = props;

  //Styling

  const useStyles = makeStyles(() => ({
    root: { padding: "0px !important", height: "100%" },
    navItem: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: style?.padding || "0px 12px",
      height: "100%",
      cursor: "pointer",
      color: themeColors["secondaryColor"],
      boxSizing: "border-box",
      //   "&:first-child": { paddingLeft: "0px !important" },
      "&:hover": {},
      "&.disabled": {
        pointerEvents: "none",
        opacity: 0.5,
      },
    },
    navItemTopBar: { height: "3px" },
    navItemBottomBar: {
      height: "3px",
      width: "75%",
      borderRadius: "10px",
      "&.active": { backgroundColor: themeColors["primaryColor"] },
    },
    navItemTitle: {
      display: "flex",
      alignItems: "center",
      height: "calc(100% - 6px)",
      fontSize: style?.fontSize || "14px !important",
      textTransform: style?.textTransform || "initial",
      "&.active": { color: themeColors["primaryColor"] },
    },
  }));
  const classes = useStyles();

  const NavItem = (props) => {
    const { id, name, disabled } = props;

    const navItemClassName = `${classes.navItem} ${
      id === value ? "active" : ""
    } ${disabled ? "disabled" : ""}`;
    const navItemTitleClassName = `${classes.navItemTitle} ${
      id === value ? "active" : ""
    } ${disabled ? "disabled" : ""}`;
    const navItemBottomBarClassName = `${classes.navItemBottomBar} ${
      id === value ? "active" : ""
    } ${disabled ? "disabled" : ""}`;

    const handleClick = (event) => onChange(event, id);

    return (
      <div
        key={id}
        className={`${navItemClassName} ${customClassName}${name}-GA`}
        onClick={handleClick}
      >
        <section className={classes.navItemTopBar}></section>
        <p className={`${navItemTitleClassName} ${customClassName}${name}-GA`}>
          {t(name)}
        </p>
        <section className={navItemBottomBarClassName}></section>
      </div>
    );
  };

  return <nav className={classes.root}>{data.map(NavItem)}</nav>;
}

export default SigviewNav;
