// * Import required libraies
import React, { useContext, useRef } from "react";
import { toast } from "react-toastify";
import { connect, useSelector } from "react-redux";
import _ from "underscore";
import { makeStyles, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// * Import Custom Component
import SigviewIcon from "../../Common/SigviewIcon";
import SigviewTooltip from "../../Common/SigviewTooltip";
import SigviewTypography from "../../Common/SigviewTypography";
import SigviewButtonMaker from "../../Common/SigviewButtonMaker";
import SigviewSearchField from "../../Common/SigviewSearchField";
import SigviewCheckboxGroup from "../../Common/SigviewCheckboxGroup";
import SigviewButton from "../../Common/SigviewButton";
import MetricFiltersContainerMsv from "../../../components/GlobalFilters/MetricFiltersContainerMsv";
import SigviewIconButton from "../../Common/SigviewIconButton";

// * Import redux utils
// Selectors
import { selectMsvTableSelections } from "../../../redux/selectors/standaloneMsvSelectors";
// Actions
import {
  updateUserScreen,
  updateStandaloneMsvMetricFiltersForMsvTable,
  updateStandaloneMsvForm,
  updateStandaloneMsvFormCheckAll,
} from "../../../redux/actions";

// * Import utils
import {
  wrapperDimDownloadRequest,
  initialWsFormScreenValue,
} from "../../../utils/analyzeUtils";
import {
  regexSearchFieldValidation,
  unwrapperMsvFilters,
} from "../../../utils/msvUtils";
import { masterTrackGaEvent } from "../../../services/ga";

// * Import API functions
import { dimDownloadRequest, downloadReportFile } from "../../../services/api";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Making styles

const useStyles = makeStyles((theme) => ({
  root: { flexShrink: 0 },
  headerContainer: {
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    borderBottom: " 1px solid #F5F5F5 !important",
    color: theme.palette.misc["secondaryColor"],
  },
  leftHeader: {
    width: "100%",
    //padding: "7px 10px 7px 5px",
    display: "flex",
    fontFamily: "Fira Sans",
    fontWeight: "600",
    fontSize: "13px !important",
    alignItems: "center",
  },
  leftFilterIcon: {
    height: "16px",
    width: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rightHeader: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-end",
  },
  msvTableOptionsContainer: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 10px",
    width: "100%",
  },
  msvTableOptionsLeftPanel: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  checkAllContainer: { paddingRight: "10px" },
  regexSearchContainer: {
    width: "98%",
  },
  msvTableOptionsRightPanel: {
    display: "flex",
    alignItems: "center",
  },
}));

function MsvDimHeader(props) {
  const { t } = useTranslation();
  // * Destructure Props
  const {
    // REDUX PROPS
    dispatch: ReduxDispatcher,
    user,
    standaloneMsv: msvForm,
    // PARENT PROPS
    dimensionTableDate,
    id,
  } = props;

  // * Define states/hooks
  const toastId = useRef(null);

  // * Destructure REDUX props
  const selections = useSelector((state) =>
    selectMsvTableSelections(state, id)
  );

  // * Define required event handlers
  const handleDownload = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ZoomedView",
      action: "TableDownload",
      label: "Download",
    });
    if (dimensionTableDate.status === "success") {
      // Show info toaster
      toastId.current = toast.info(
        <p>
          {t("Download initiated for")} {selections.dimensionsList[0].dimTitle}
        </p>,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );

      const fetchPayload = wrapperDimDownloadRequest(selections, user);
      const fetchProps = {
        payload: fetchPayload,
      };
      const dimDownloadRequestPromise = dimDownloadRequest(fetchProps);
      dimDownloadRequestPromise
        .then((responseDate) => {
          //Initiate download file
          const fileName = responseDate.result.data.downloadFileName;
          const fileID = responseDate.result.data.file;
          const downloadUrl = `${user.apiEndpoints.baseUrl}/downloadFile?fid=${fileID}&fname=${fileName}`;

          // * Old implementation
          // This stopped working in new app server as the opened URL required authentication token
          // window.open doesn't allow sending headers (https://stackoverflow.com/questions/4325968/window-open-with-headers)
          // window.open(downloadUrl);

          // * New implementation
          // Fetching the required data from the download file url and then downloading via blob
          const fetchProps = {
            url: downloadUrl,
          };
          const downloadReportFilePromise = downloadReportFile(fetchProps);
          downloadReportFilePromise
            .then((blob) => {
              // Show success toaster
              toast.dismiss(toastId.current);
              toastId.current = toast.success(
                <p>
                  {t("Download completed for")}{" "}
                  {selections.dimensionsList[0].dimTitle}
                </p>,
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                }
              );
              setTimeout(() => {
                toast.dismiss(toastId.current);
              }, 5000);
              // Create blob link to download
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", fileName);

              // Append to html link element page
              document.body.appendChild(link);

              // Start download
              link.click();

              // Clean up and remove the link
              link.parentNode.removeChild(link);
            })
            .catch((json) => {
              console.log("API FAILED", json);
              // Show failure toaster
              toast.dismiss(toastId.current);
              toastId.current = toast.error(
                <p>
                  {t("Download failed for")}{" "}
                  {selections.dimensionsList[0].dimTitle} {json.error}
                </p>,
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                }
              );
              setTimeout(() => {
                toast.dismiss(toastId.current);
              }, 5000);

              //Reload data even if it fails so that the user can see the refreshed history
              // reloadData("download");
            });

          //Reload data
          // reloadData("download");
        })
        .catch((json) => {
          // Show failure toaster
          toast.dismiss(toastId.current);
          toastId.current = toast.error(
            <p>
              {t("Download failed for")} {selections.dimensionsList[0].dimTitle}{" "}
              {json.error}
            </p>,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 5000);
          console.log("API FAILED", json);
        });
    }
  };

  const handleAddMetrics = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ZoomedView",
      action: "TableAddDimensions",
      label: "AddDimensions",
    });
    const newScreen = { ...initialWsFormScreenValue, dimensionDrawer: true };
    const payload = { key: "screen", value: newScreen };
    const action = updateStandaloneMsvForm(payload);
    ReduxDispatcher(action);
  };

  const handleCloseMetricFilters = () => {
    const payload = {
      open: false,
      children: <></>,
    };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleMetricFiltersChange = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ZoomedView",
      action: "Metric Filters",
      label: `${value.length} Rows Applied`,
    });

    let payload = {
      value,
    };
    let action = updateStandaloneMsvMetricFiltersForMsvTable(payload);
    ReduxDispatcher(action);
  };

  const handleOpenMetricFilters = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ZoomedView",
      action: "Metric Filters",
      label: "MetricFiltersOpen",
    });

    const activeMsvTableId = msvForm.activeMsvTableId.value;
    const metricFilters = unwrapperMsvFilters(
      msvForm.selectedDimensions.value[activeMsvTableId].metricFilters
    );
    const metricFiltersData =
      msvForm.selectedDimensions.value[activeMsvTableId].metricsList;
    const payload = {
      open: true,
      children: (
        <MetricFiltersContainerMsv
          metricFilters={metricFilters}
          setMetricFilters={handleMetricFiltersChange}
          onClose={handleCloseMetricFilters}
          ReduxDispatcher={ReduxDispatcher}
          user={user}
          showAbsoluteChange={msvForm.timeFilters.value.isComparisonOn}
          data={metricFiltersData}
        />
      ),
    };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleSubmitSearch = (event, value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ZoomedView",
      action: "TableSearch",
      label: "Regex",
    });

    let payload = {
      key: "regexSearchField",
      value,
    };
    let action = updateStandaloneMsvForm(payload);
    ReduxDispatcher(action);
  };

  const handleCheckAll = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ZoomedView",
      action: "Select All",
      label: "All",
    });

    let payload = {
      value: !msvForm.regexSearchCheckAll.value,
    };
    let action = updateStandaloneMsvFormCheckAll(payload);
    ReduxDispatcher(action);
  };

  // * Define required static variables
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  const classes = useStyles();
  const checkAllData = [
    {
      id: "all",
      name: "All",
      checked: msvForm.regexSearchCheckAll.value,
    },
  ];
  const isDownloadAvailable = user.uiFeatureList.download;
  const isMetricFiltersAvailable = user.uiFeatureList.metricFilters;
  console.log("dimensionTableDate", dimensionTableDate);
  return (
    <Box className={classes.root}>
      <div className={classes.headerContainer}>
        <div className={classes.leftHeader}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Box className={classes.msvTableOptionsContainer}>
              <Box className={classes.msvTableOptionsLeftPanel}>
                {msvForm.regexSearchField.value.length >= 2 && (
                  <Box className={classes.checkAllContainer}>
                    <SigviewCheckboxGroup
                      data={checkAllData}
                      onChange={handleCheckAll}
                      customID="MSVSelectAll-GA"
                    />
                  </Box>
                )}
                <Box className={classes.regexSearchContainer}>
                  <SigviewSearchField
                    placeholder="Type and press enter to search"
                    onSubmit={handleSubmitSearch}
                    helperText="Enter minimum 2 characters to search"
                    errorFlag={true}
                    customClassName="MSVTableSearch-GA"
                    validationfunction={regexSearchFieldValidation}
                    customStyle={{
                      wrapperHeight: "25px",
                      iconSize: "12px",
                      textFieldFontSize: "10px",
                      placeholderFontSize: "11px",
                      width: "170px",
                      wrapperBgColor: "#fff",
                      iconSize: "18px",
                      wrapperBorderHover: "1px solid #fff",
                      wrapperBorderFocus: "1px solid #fff",
                      wrapperPadding: "0px",
                    }}
                  />
                </Box>
              </Box>
            </Box>
            {/* <Box style={{ marginLeft: "7px" }}>
              <Box
                style={{
                  height: "max-content",
                  width: "max-content",
                  display: "inline-block",
                }}
              >
                <SigviewTypography
                  variant="pLarge"
                  title={selections.dimensionsList[0].dimTitle}
                  // style={{ height: "max-content" }}
                  style={{
                    height: "max-content",
                    width: "max-content",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "move",
                  }}
                >
                  {selections.dimensionsList[0].dimTitle}
                </SigviewTypography>
              </Box>
              <Box
                style={{
                  height: "max-content",
                  width: "max-content",
                  display: "inline-block",
                }}
              >
                <SigviewTooltip
                  title="Selected and non-selected values are sorted separately"
                  placement="bottom"
                >
                  <span>
                    <SigviewIcon
                      className="material-icons"
                      iconName="info"
                      style={{ padding: "0px 10px", fontSize: "14px" }}
                    />
                  </span>
                </SigviewTooltip>
              </Box>
            </Box> */}
          </div>
          <div className={classes.rightHeader}>
            <Box className={classes.msvTableOptionsRightPanel}>
              <SigviewIconButton
                onClick={handleOpenMetricFilters}
                title="Metric Filter"
                iconName="filter_alt"
                customClassName="MSVMetricFilter-GA"
                style={{
                  textTransform: "uppercase !important",
                  fontSize: "11px",
                  eventPointer:
                    dimensionTableDate.status === "error" ||
                    dimensionTableDate.status === "loading"
                      ? "none"
                      : "pointer",
                }}
              ></SigviewIconButton>
              {isMetricFiltersAvailable && (
                <SigviewIconButton
                  onClick={handleAddMetrics}
                  title="Add Metric"
                  customClassName="MSVAddMetric-GA"
                  iconName="add_circle"
                ></SigviewIconButton>
              )}
              {isDownloadAvailable && (
                <SigviewIconButton
                  onClick={handleDownload}
                  title="Download"
                  customClassName="MSVDownload-GA"
                  iconName="download"
                  style={{
                    color:
                      dimensionTableDate.status === "error" ||
                      dimensionTableDate.status === "loading"
                        ? "#c5cfd9"
                        : themeColors["secondaryColor"],
                  }}
                ></SigviewIconButton>
              )}
            </Box>
          </div>
        </div>
      </div>

      {/* <Box className={classes.msvTableOptionsContainer}>
        <Box className={classes.msvTableOptionsLeftPanel}>
          {msvForm.regexSearchField.value.length >= 2 && (
            <Box className={classes.checkAllContainer}>
              <SigviewCheckboxGroup
                data={checkAllData}
                onChange={handleCheckAll}
              />
            </Box>
          )}
          <Box className={classes.regexSearchContainer}>
            <SigviewSearchField
              placeholder="Type and press Enter to search"
              onSubmit={handleSubmitSearch}
              helperText="Enter minimum 2 characters to search"
              errorFlag={true}
              validationfunction={regexSearchFieldValidation}
              customStyle={{
                wrapperHeight: "25px",
                iconSize: "12px",
                textFieldFontSize: "10px",
                placeholderFontSize: "9px",
                width: "170px",
              }}
            />
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  allData: state.data,
  user: state.user,
  standaloneMsv: state.standaloneMsv,
});

export default connect(mapStateToProps)(MsvDimHeader);
