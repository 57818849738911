// * Import required libraries
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";

// * Import custom components
import Loader from "../Loader/Loader";
import SigviewSingleSelect from "../Common/SigviewSingleSelect";

// * Import action creators
import { updateUserScreen, updateChangeFlag } from "../../redux/actions";
import {
  getActiveViewObjFromViewsArr,
  getActiveOrgObject,
} from "../../utils/utils";

// * Import API functions
import { updateUserView } from "../../services/api";

function OrgSelection(props) {
  const { dispatch: ReduxDispatcher, user, changeFlag } = props;
  const [error, setError] = useState(false);

  const handleOrgChange = (newOrgId) => {
    //Return to home (will be controlled by router later)
    const data = { activeTab: "home", isDashboardLoading: true };
    ReduxDispatcher(updateUserScreen(null, data));

    //Make fetch call using axios
    const activeOrgObject = getActiveOrgObject(user.metadata.data);
    const activeView = getActiveViewObjFromViewsArr(
      activeOrgObject?.viewInfoList
    );
    const newOrgObject = user?.metadata?.data?.orgInfoList?.find(
      (row) => row.name === newOrgId
    );
    const newActiveView = getActiveViewObjFromViewsArr(
      newOrgObject?.viewInfoList
    );
    const fetchProps = {
      payload: {
        loginInfo: {
          providerID: "credentials",
          providerKey: user.reqMetadata.email,
        },
        currentActiveOrgViewReq: {
          organization: user.reqMetadata.organization,
          view: activeView.name,
        },
        newActiveOrgViewReq: {
          organization: newOrgId,
          view: newActiveView.name,
        },
        isEmbedded: false,
      },
    };
    const updateUserViewPromise = updateUserView(fetchProps);
    updateUserViewPromise
      .then(() => {
        //Change viewFlag to trigger useEffect to refetch all data
        ReduxDispatcher(updateChangeFlag("view", !changeFlag.view));
      })
      .catch((error) => {
        console.log("error", error);
        setError(error.error);
      });
  };

  // * Define required variables
  // const activeOrgObject = getActiveOrgObject(user.metadata.data);
  const activeOrgId = user?.metadata?.data?.activeOrg;
  let allOrgs = user?.metadata?.data?.orgInfoList || [];
  allOrgs = orderBy(allOrgs, ["name"], ["asc"]);
  const allOrgsModified = allOrgs.map((row) => ({
    ...row,
    id: row.name,
    name: row.name,
    disabled: false,
  }));

  return (
    <section className={`view-container`}>
      {error ? (
        <p>{error}</p>
      ) : activeOrgId ? (
        <>
          <SigviewSingleSelect
            value={activeOrgId}
            data={allOrgsModified}
            onChange={handleOrgChange}
            minWidth="10px"
            // margin={{ left: "5px", right: "5px" }}
            border="none"
            theme={user.theme}
            themeColors={user.themeColors}
          />
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
}

OrgSelection.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
});

export default connect(mapStateToProps)(OrgSelection);
