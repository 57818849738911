import { namespace } from "d3";
import orderBy from "lodash.orderby";

const defaultDimensionState = {
  id: {
    message: "",
    value: "",
    status: "invalid",
  },
  name: {
    message: "",
    value: "",
    status: "invalid",
  },
  groups: {
    message: "",
    value: [],
    status: "invalid",
  },
  activeView: {
    message: "",
    value: "",
    status: "invalid",
  },
  backendName: {
    message: "",
    value: "",
    status: "invalid",
  },
  dataType: {
    message: "",
    value: "String",
    status: "valid",
  },
  // TODO Change data structure
  crudType: "",
  actualPayload: {
    message: "",
    value: "",
    status: "invalid",
  },
};

const defaultMetricState = {
  id: {
    message: "",
    value: "",
    status: "invalid",
  },
  name: {
    message: "",
    value: "",
    status: "invalid",
  },
  groups: {
    message: "",
    value: [],
    status: "invalid",
  },
  activeView: {
    message: "",
    value: "",
    status: "invalid",
  },
  backendName: {
    message: "",
    value: "",
    status: "invalid",
  },
  metricType: {
    message: "",
    value: "",
    status: "invalid",
  },
  dataUnitType: {
    message: "",
    value: "String",
    status: "valid",
  },
  dataUnitSymbol: {
    message: "",
    value: "String",
    status: "invalid",
  },
  measureType: {
    message: "",
    value: "sum",
    status: "valid",
  },
  // TODO: Change to same data structure
  isAdditive: true,
  crudType: "",
  actualPayload: {
    message: "",
    value: "",
    status: "valid",
  },
};

const defaultCustomMetricState = {
  id: {
    message: "",
    value: "",
    status: "invalid",
  },
  name: {
    message: "",
    value: "",
    status: "invalid",
  },
  groups: {
    message: "",
    value: [],
    status: "invalid",
  },
  formulaType: {
    message: "",
    value: "A/B",
    status: "invalid",
  },
  dataUnitType: {
    message: "",
    value: "String",
    status: "valid",
  },
  dataUnitSymbol: {
    message: "",
    value: "String",
    status: "invalid",
  },
  formulaEntity: {
    A: {
      message: "",
      value: {
        id: "",
        num: 0,
      },
      status: "invalid",
    },
    B: {
      message: "",
      value: {
        id: "",
        num: 0,
      },
      status: "invalid",
    },
  },
  activeView: {
    message: "",
    value: "",
    status: "invalid",
  },
  enteredFormula: {
    message: "",
    value: "",
    status: "invalid",
  },
  isAdditive: true,
  crudType: "",
  actualPayload: {
    message: "",
    value: "",
    status: "valid",
  },
};

const wrapperDimForm = (data) => {
  let wrappedData = {
    orgViewReq: {
      // HARDCODED ORG NAME
      organization: "OpenX",
      view: data.activeView.value,
    },
    dimension: {
      _id: data.id.value,
      dimId: data.backendName.value,
      title: data.name.value,
      dimType: "basic",
      dataType: data.dataType.value,
      status: true,
      language: {
        ja: "en",
      },
    },
  };

  return { ...wrappedData };
};
const wrapperGroup = (data) => {
  let wrappedData = {
    orgViewReq: {
      // HARDCODED ORG NAME
      organization: "OpenX",
      view: data.activeView.value,
    },
    id: data.id.value,
    groups: data.groups.value.map((item) => item._id),
  };

  return { ...wrappedData };
};

const unwrapperDimForm = (data, activeView) => {
  let unwrappedData = {
    id: {
      message: "",
      value: data.id,
      status: "valid",
    },
    name: {
      message: "",
      value: data.name,
      status: "valid",
    },
    groups: {
      message: "",
      value: [],
      status: "valid",
    },
    activeView: {
      message: "",
      value: activeView,
      status: "valid",
    },
    backendName: {
      message: "",
      value: data.dimId,
      status: "valid",
    },
    dataType: {
      message: "",
      value: data.dataType,
      status: "valid",
    },
    // TODO Change data structure
    crudType: "update",
    actualPayload: {
      message: "",
      value: data,
      status: "valid",
    },
  };

  return { ...unwrappedData };
};

const wrapperMetricForm = (data) => {
  let wrappedData = {
    orgViewReq: {
      organization: "OpenX",
      view: data.activeView.value,
    },
    metric: {
      _id: data.id.value,
      status: true,
      measureType: data.measureType.value,
      title: data.name.value,
      metricId: data.backendName.value,
      metricType: "basic",
      dataUnit: {
        dType: data.dataUnitType.value,
        value: data.dataUnitSymbol.value,
      },
      aggregationList: [data.measureType.value],
    },
  };

  return { ...wrappedData };
};

const unwrapperMetricForm = (data, activeView) => {
  let unwrappedData = {
    id: {
      message: "",
      value: data.id,
      status: "valid",
    },
    name: {
      message: "",
      value: data.name,
      status: "valid",
    },
    groups: {
      message: "",
      value: [],
      status: "valid",
    },
    activeView: {
      message: "",
      value: activeView,
      status: "valid",
    },
    backendName: {
      message: "",
      value: data.metricId,
      status: "valid",
    },
    metricType: {
      message: "",
      value: data.metricType,
      status: "valid",
    },
    dataUnitType: {
      message: "",
      value: data.dataUnit.dType,
      status: "valid",
    },
    dataUnitSymbol: {
      message: "",
      value: data.dataUnit.value,
      status: "valid",
    },
    measureType: {
      message: "",
      value: data.measureType,
      status: "valid",
    },
    // TODO: Change to same data structure
    isAdditive: true,
    crudType: "update",
    actualPayload: {
      message: "",
      value: data,
      status: "valid",
    },
  };

  return { ...unwrappedData };
};

const wrapperCustomMetricForm = (data) => {
  let idsMap = {};

  let metricList = [];

  for (const i in data.formulaEntity) {
    if (data.formulaEntity[i].value.id !== "numeric") {
      metricList.push(data.formulaEntity[i].value.id);
    }

    idsMap[i] =
      data.formulaEntity[i].value.id === "numeric"
        ? {
            id: "const",
            function: `${data.formulaEntity[i].value.num}`,
          }
        : {
            id: data.formulaEntity[i].value.id,
            function: "sum",
          };
  }

  let wrappedData = {
    orgViewReq: {
      organization: "OpenX",
      view: data.activeView.value,
    },

    customMetric: {
      title: data.name.value,
      metricType: "custom",
      dummyFormula: data.formulaType.value,
      idsMap: idsMap,
      metricLevel: "view",
      referenceId: "view",
      status: true,
      metricList: metricList,
      dataUnit: {
        dType: data.dataUnitType.value,
        value: data.dataUnitSymbol.value,
      },
      isPercentageOn: data.isAdditive.value,
      _id: data.id.value,
    },
  };

  return { ...wrappedData };
};

const unwrapperCustomMetricForm = (data, activeView) => {
  // const arrayofIds = Object.entries(data.idsMap).map((e) => ({
  //   [e[0]]: {
  //     id: `${[e[0]]}`,
  //     message: "",
  //     value: e[1].id,
  //     status: "valid",
  //   },
  // }));

  let asciiValOfA = 65;
  const formulaEntity = {};

  for (const [key, value] of Object.entries(data.idsMap)) {
    let exactValue = data.idsMap[String.fromCharCode(asciiValOfA)];

    formulaEntity[String.fromCharCode(asciiValOfA)] = {
      message: "",
      value: {
        id: exactValue.id === "const" ? "numeric" : exactValue.id,
        num:
          exactValue.id === "const"
            ? Number(exactValue.function)
            : exactValue.function,
      },
      status: "valid",
    };
    asciiValOfA++;
  }

  let unwrappedData = {
    id: {
      message: "",
      value: data.id,
      status: "valid",
    },
    name: {
      message: "",
      value: data.name,
      status: "valid",
    },
    groups: {
      message: "",
      value: [],
      status: "valid",
    },
    formulaType: {
      message: "",
      value: data.dummyFormula,
      status: "valid",
    },
    metricType: {
      message: "",
      value: data.metricType,
      status: "valid",
    },
    dataUnitType: {
      message: "",
      value: data.dataUnit.dType,
      status: "valid",
    },
    dataUnitSymbol: {
      message: "",
      value: data.dataUnit.value,
      status: "valid",
    },
    formulaEntity: formulaEntity,

    activeView: {
      message: "",
      value: activeView,
      status: "valid",
    },
    enteredFormula: {
      message: "",
      value: data.displayFormula,
      status: "valid",
    },
    isAdditive: {
      message: "",
      value: data.isPercentageOn,
      status: "valid",
    },

    crudType: "update",
    actualPayload: {
      message: "",
      value: data,
      status: "valid",
    },
  };

  return { ...unwrappedData };
};

const unWrapperFormula = (data) => {
  let unwrappedData = data.map((row) => {
    return { id: row.formula, name: row.formula };
  });

  return unwrappedData;
};

const unwrapperGroupDnd = (data) => {
  const unwrappedData = data.map((item) => ({
    measureTitle: item.name,
    _id: item._id,
    _title: item._id,
  }));
  return unwrappedData;
};

const unwrapperGroupDataDnd = (data) => {
  const unwrappedData = data.map((item) => ({
    measureTitle: item.split("_").slice(1).join(" "),
    _id: item,
    _title: item,
  }));

  return unwrappedData;
};

function validateNotEmptyString(name) {
  if (name === "") {
    return {
      status: "invalid",
      message: "field can not be an empty",
    };
  }

  return {
    status: "valid",
    message: "",
  };
}

function validateName(name) {
  if (name === "") {
    return {
      status: "invalid",
      message: "name can not be an empty",
    };
  }
  if (name.length < 2) {
    return {
      status: "invalid",
      message: "name should be of two letters or more",
    };
  }

  return {
    status: "valid",
    message: "",
  };
}
function validateBackendName(name) {
  if (name === "") {
    return {
      status: "invalid",
      message: "Backend name can not be an empty",
    };
  }

  if (name.length < 2) {
    return {
      status: "invalid",
      message: "Backend name can not be less than two characters",
    };
  }
  if (name.split(/[^a-zA-Z0-9_]/g).length > 1) {
    return {
      status: "invalid",
      message:
        "Backend name can only have alphanumeric characters and underscore",
    };
  }

  return {
    status: "valid",
    message: "",
  };
}

function validateFormulaEntity(obj) {
  // for (const i of obj) {
  //   console.log("i", i);
  // }
  // if (name === "") {
  //   return {
  //     status: "invalid",
  //     message: "name can not be an empty",
  //   };
  // }
  // return {
  //   status: "valid",
  //   message: "",
  // };
  return { ...obj };
}
function validateNotEmptyArray(array) {
  if (array.length === 0) {
    return {
      status: "invalid",
      message: "field can not be an empty array",
    };
  }

  return {
    status: "valid",
    message: "",
  };
}
function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  let isValid = re.test(email);

  if (isValid) {
    return {
      status: "valid",
      message: "",
    };
  } else {
    return {
      status: "invalid",
      message: "email is not valid",
    };
  }
}

function validateViews(props) {
  if (props.length === 0) {
    return {
      status: "invalid",
      message: "There should be atleast one value selected",
    };
  }
  return {
    status: "valid",
  };
}

const validateAttributeName = (name) => {
  //Common Validations
  if (name === "")
    return {
      status: "invalid",
      message: "Name can not be empty",
    };

  if (name.length < 2)
    return {
      status: "invalid",
      message: "Name must have at least 2 character",
    };

  return { status: "valid", message: "" };
};

function validatedDimension(selectedDimension, allDimensionData) {
  let constantDimId = allDimensionData
    .filter(
      (row) =>
        row.dimId === "day" || row.dimId === "hour" || row.dimId === "month"
    )
    .map((row) => row._id);

  let validateDim = constantDimId.map((row) => selectedDimension.includes(row));

  let flag =
    validateDim.every((row) => !row) ||
    selectedDimension.length <= constantDimId.length;
  let finalValidateDim = {
    status: flag ? "invalid" : "valid",
    message: flag ? "select required Dimension" : "",
  };

  return finalValidateDim;
}

const isSelectionsInvalid = (selections) => {
  for (const [key, value] of Object.entries(selections)) {
    if (value.status === "invalid") {
      return {
        status: value.status,
        message: value.message,
      };
    }
  }
  return {
    status: "valid",
    message: "",
  };
};

const validateAttributeDimension = (state) => {
  let validatedState = {
    ...state,

    // name: {
    //   value: state.name.value,
    //   ...validateName(state.name.value),
    // },
    // backendName: {
    //   value: state.backendName.value,
    //   ...validateBackendName(state.backendName.value),
    // },
    activeView: {
      value: state.activeView.value,
      ...validateNotEmptyString(state.activeView.value),
    },
  };

  return validatedState;
};
const validateAttributeMetrics = (state) => {
  let validatedState = {
    ...state,

    // name: {
    //   value: state.name.value,
    //   ...validateName(state.name.value),
    // },
    // backendName: {
    //   value: state.backendName.value,
    //   ...validateBackendName(state.backendName.value),
    // },
    activeView: {
      value: state.activeView.value,
      ...validateNotEmptyString(state.activeView.value),
    },
  };

  return validatedState;
};

const validateNum = (num) => {
  if (num >= 0.1 && num <= 0.99 && /^\d+(?:\.\d{1,2})?$/.test(num)) {
    return {
      status: "valid",
      message: "",
    };
  } else {
    return {
      status: "invalid",
      message: "Please enter a number in between 0.1 to 0.99",
    };
  }
};

const validateformulaEntityValueNum = (state) => {
  let validatedformulaEntityState;
  if (state.dataUnitType.value === "percentile") {
    Object.keys(state.formulaEntity).map((row) => {
      if (row === "B") {
        validatedformulaEntityState = {
          ...state,
          formulaEntity: {
            ...state.formulaEntity,
            [row]: {
              ...state.formulaEntity[row],
              ...validateNum(state.formulaEntity[row].value.num),
              value: {
                ...state.formulaEntity[row].value,
                id: "numeric",
                // num: "0.1",
              },
            },
          },
        };
      }
    });
    return validatedformulaEntityState;
  }
  return state;
};
const validateAttributeCustomMetrics = (state) => {
  const validateformulaEntity = validateformulaEntityValueNum(state);
  let validatedState = {
    ...validateformulaEntity,

    enteredFormula: {
      value: validateformulaEntity.enteredFormula.value,
      ...validateNotEmptyString(validateformulaEntity.enteredFormula.value),
    },
    dataUnitType: {
      value: validateformulaEntity.dataUnitType.value,
      ...validateNotEmptyString(validateformulaEntity.dataUnitType.value),
    },

    activeView: {
      value: validateformulaEntity.activeView.value,
      ...validateNotEmptyString(validateformulaEntity.activeView.value),
    },
  };
  return validatedState;
};

export {
  defaultDimensionState,
  defaultMetricState,
  defaultCustomMetricState,
  unwrapperDimForm,
  unwrapperMetricForm,
  unwrapperCustomMetricForm,
  unwrapperGroupDnd,
  unwrapperGroupDataDnd,
  validateAttributeDimension,
  isSelectionsInvalid,
  validateAttributeMetrics,
  validateAttributeCustomMetrics,
  wrapperGroup,
  wrapperDimForm,
  wrapperMetricForm,
  wrapperCustomMetricForm,
  unWrapperFormula,
  validateAttributeName,
  validateBackendName,
  validateformulaEntityValueNum,
};
