// Import React Components
import React, { useRef, useContext } from "react";

// Import library components
import { makeStyles } from "@material-ui/core/styles";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";
import SigviewSingleSelect from "../Common/SigviewSingleSelect";

function SigviewTabAws(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // Destructure props
  const {
    handleActiveView = () => {},
    handleSelectedView = () => {},
    handleSelectedGroup = () => {},
    activeView = [],
    selectedView = [],
    selectedGroup = {},
    data = [],
    type = "",
    disabled = false,
    isEditable = false,
  } = props;

  const useStyles = makeStyles(() => ({
    root: {
      // width: "240px",
      display: "flex",
      flexDirection: "column",
      // height: "100%",
      // overflow: "auto",
    },
    tabRowContainer: {
      display: "flex",
      padding: "5px",
      alignItems: "center",
      justifyContent: "flex-start",
      color: themeColors["secondaryColor"],
      backgroundColor: themeColors["sidenavBgColor"],
      "&:hover": {
        cursor: "pointer",
        backgroundColor: themeColors["secondaryColorLightest"],
      },
      width: "inherit",
      height: "30px",
      // paddingLeft: "10px",
      // paddingRight: "8.33px",
    },
    materialIconsChecked: {
      opacity: disabled ? 0.5 : 1,
      color: themeColors["primaryColor"],
      fontSize: "15px !important",
      width: "max-content",
      paddingRight: "5px",
    },
    materialIconsUnchecked: {
      opacity: disabled ? 0.5 : 1,
      color: themeColors["secondaryColor"],
      fontSize: "15px !important",
      width: "max-content",
      paddingRight: "5px",
    },
    selecteClass: {
      backgroundColor: themeColors["secondaryColorLightest"],
    },
    SideNavItem: {
      width: "205px",
      height: "inherit",
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: "400",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis !important",
    },
    materialIcons: {
      fontSize: "small",
      padding: "0px 5px 0px 5px !important",
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {data.map((item, index) => {
        let active = selectedView.find((row) => row.id == item.id);
        const value = selectedGroup[item.id] || "";

        return (
          <div
            className={`${
              activeView.id === item.id ? classes.selecteClass : null
            } ${classes.tabRowContainer}`}
          >
            {type !== "dataOnly" &&
              ((active && (
                <span
                  className={`material-icons ${classes.materialIconsChecked}`}
                  onClick={(e) => {
                    handleSelectedView(e, item);
                  }}
                >
                  check_circle
                </span>
              )) || (
                <span
                  className={`material-icons-outlined ${classes.materialIconsUnchecked}`}
                  onClick={(e) => {
                    handleSelectedView(e, item);
                  }}
                >
                  radio_button_unchecked
                </span>
              ))}
            {isEditable && (
              <span
                className={`material-icons-outlined ${classes.materialIcons}`}
                onClick={(e) => handleActiveView(e, item)}
              >
                edit
              </span>
            )}

            {/* <div className={classes.tabRowNameContainer}> */}
            <span
              className={classes.SideNavItem}
              onClick={(e) => handleActiveView(e, item)}
              title={item.name}
            >
              {item.name}
            </span>
            {/* </div> */}
            <SigviewSingleSelect
              disabled={!active}
              data={item.views}
              value={value}
              onChange={(value) => handleSelectedGroup(item.id, value)}
            />
          </div>
        );
      })}
    </div>
  );
}

export default SigviewTabAws;
