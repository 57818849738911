// * Import required libraries
import { useState, useEffect, useReducer, useContext, useRef } from "react";
import { connect } from "react-redux";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * import lib component
import { Box } from "@material-ui/core";

// * Import custom components
import SigviewTypography from "../../../components/Common/SigviewTypography";

// * Import utils, config & static data
import {
  unWrapperDimViewsDetails,
  unWrapperMetricViewsDetails,
} from "../../../utils/adminUtils";

const FormatFormula = (props) => {
  const { formula } = props;
  const formatFormulaText = formula.split("\n");
  return formatFormulaText.map((item) => <div key={item}>{item}</div>);
};
const DimensionViewDetails = (props) => {
  const {
    dimViewsDataUi,
    activeAttributeType,
    textDetailsBox,
    textDetailsKey,
    textFieldCustomStyle,
  } = props;

  return (
    <>
      {dimViewsDataUi && activeAttributeType === "dimensions" && (
        <Box css={{ ...textFieldCustomStyle }}>
          {dimViewsDataUi?.physicalName && (
            <Box css={{ ...textDetailsBox }}>
              <Box css={{ ...textDetailsKey }}>Physical Name:</Box>
              <Box>{dimViewsDataUi.physicalName}</Box>
            </Box>
          )}
          {dimViewsDataUi?.mapper && (
            <Box css={{ ...textDetailsBox }}>
              <Box css={{ ...textDetailsKey }}>Mapper:</Box>
              <Box>{dimViewsDataUi.mapper}</Box>
            </Box>
          )}
          {dimViewsDataUi?.mapperType && (
            <Box css={{ ...textDetailsBox }}>
              <Box css={{ ...textDetailsKey }}>Mapper Type:</Box>
              <Box>{dimViewsDataUi.mapperType}</Box>
            </Box>
          )}
          {dimViewsDataUi?.formula && (
            <Box css={{ ...textDetailsBox }}>
              <Box css={{ ...textDetailsKey }}>Formula:</Box>
              <Box>
                <FormatFormula formula={dimViewsDataUi.formula}></FormatFormula>
              </Box>
            </Box>
          )}
        </Box>
      )}
      {!dimViewsDataUi && activeAttributeType === "dimensions" && (
        <Box css={{ ...textFieldCustomStyle }}>Data Not available</Box>
      )}
    </>
  );
};

const MetricViewDetails = (props) => {
  const {
    metricViewsDataUi,
    activeAttributeType,
    textDetailsBox,
    textDetailsKey,
    textFieldCustomStyle,
  } = props;
  return (
    <>
      {metricViewsDataUi && activeAttributeType === "metrics" && (
        <Box css={{ ...textFieldCustomStyle }}>
          {metricViewsDataUi?.physicalName && (
            <Box css={{ ...textDetailsBox }}>
              <Box css={{ ...textDetailsKey }}>Physical Name:</Box>
              <Box>{metricViewsDataUi.physicalName}</Box>
            </Box>
          )}
          {metricViewsDataUi?.formula && (
            <Box css={{ ...textDetailsBox }}>
              <Box css={{ ...textDetailsKey }}>Formula:</Box>
              <Box>
                <FormatFormula
                  formula={metricViewsDataUi.formula}
                ></FormatFormula>
              </Box>
            </Box>
          )}
        </Box>
      )}
      {!metricViewsDataUi && activeAttributeType === "metrics" && (
        <Box css={{ ...textFieldCustomStyle }}>Data Not available</Box>
      )}
    </>
  );
};

function DimensionMetricViewDetails(props = {}) {
  const {
    dispatch: ReduxDispatcher,
    user = {},
    activeAttributeType,
    allData = {},
    selections,
  } = props;

  const { viewsDetails: viewDetailData } = allData;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  const dimViewsDataUi = unWrapperDimViewsDetails(
    viewDetailData.result,
    selections.activeView.value,
    selections.id.value
  );
  const metricViewsDataUi = unWrapperMetricViewsDetails(
    viewDetailData.result,
    selections.activeView.value,
    selections.id.value
  );

  // * Define required static variables
  // Styles

  const textDetailsBox = {
    display: "flex",
    lineHeight: "16px",
  };
  const textDetailsKey = {
    paddingRight: "5px",
  };

  const fieldBox2 = {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    //width: "913px",
    boxSizing: "border-box",
    justifyContent: "flex-start",
  };
  const textFieldCustomStyle = {
    border: "1px solid #c5cfd9",
    height: "auto",
    maxHeight: "300px",
    fontSize: "10px",
    color: "#46596A",
    padding: "7px 5px",
    fontFamily: "inherit",
    borderRadius: "3px",
    marginTop: "5px",
    overflow: "auto",
    maxWidth: "750px",
    minWidth: "700px",
  };
  const typographyStyle = {
    padding: "5px 119px 0px 0px",
    color: "#45596a",
    fontSize: "12px",
  };

  const customObject = {
    viewDetailData,
    activeAttributeType,
    dimViewsDataUi,
    metricViewsDataUi,
    textDetailsBox,
    textDetailsKey,
    textFieldCustomStyle,
  };

  return (
    <Box css={{ ...fieldBox2 }}>
      <div style={typographyStyle}>Details:</div>
      <DimensionViewDetails {...customObject}></DimensionViewDetails>
      <MetricViewDetails {...customObject}></MetricViewDetails>
    </Box>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(DimensionMetricViewDetails);
