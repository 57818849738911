//Import required libraies
import React, { useContext } from "react";
import Radio from "@material-ui/core/Radio";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// Import Custom Component
import SigviewSingleSelect from "./SigviewSingleSelect";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import action creators

// Import utils

// Import styles

function SigviewRadioDropdownCombo(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    data,
    secondDropdownData = [],
    onRadioChange = () => {},
    onDropdownChange = () => {},
    selectedRadio,
    style,
    disabled = false,
  } = props;

  //Required Functions
  const handleRadioChange = (event) => {
    onRadioChange(event.target.value);
  };

  const handleDropdownChange = (rowId, newSelectedDropdown) => {
    const newData = data.map((row) =>
      row.id === rowId ? { ...row, selectedDropdown: newSelectedDropdown } : row
    );
    onDropdownChange(newData);
  };
  const handleSecondDropdownChange = (rowId, newSelectedDropdown) => {
    const newData = data.map((row) =>
      row.id === rowId ? { ...row, bucketId: newSelectedDropdown } : row
    );
    onDropdownChange(newData);
  };

  //Styling
  const SigviewRadio = withStyles({
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      color: themeColors["radioColor"],
      padding: "0px !important",
      "&$checked": {
        color: themeColors["radioColor"],
      },
      "&$disabled": {
        color: themeColors["radioDisabledColor"],
        opacity: 0.5,
      },
      "& .MuiSvgIcon-root": {
        height: 15,
        weight: 15,
      },
    },
    checked: {},
    disabled: {
      color: themeColors["radioDisabledColor"],
      opacity: 0.5,
    },
  })((props) => <Radio color="default" {...props} />);
  const useStyles = makeStyles({
    root: {
      display: "flex",
      flexDirection: style?.rootFlexDirection || "column",
    },
    radioIconContainer: {
      height: "28px",
      display: "flex",
      alignItems: "center",
      marginRight: "8px",
      padding: "1px 5px",
      //marginBottom: "5px",
    },
    radioLabel: {
      marginLeft: "5px",
      fontSize: "12px",
      color: themeColors["secondaryColor"],
      marginRight: "10px",
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {data.map((row) => {
        const checked = row.id === selectedRadio;
        return (
          <div className={classes.radioIconContainer} key={row.id}>
            <SigviewRadio
              value={row.id}
              checked={checked}
              onChange={handleRadioChange}
              name={"name"}
              size="small"
              disabled={row.disabled || disabled}
              disableRipple
            />
            <label className={classes.radioLabel}>{t(row.preText)}</label>
            <SigviewSingleSelect
              value={row.selectedDropdown}
              data={row.dropDownData}
              disabled={!checked || disabled}
              onChange={(newValue) => {
                handleDropdownChange(row.id, newValue);
              }}
              minWidth="60px"
              margin={{ right: "5px" }}
              border="none"
            />
            {/*  HACK APPLIED HERE DUE TO RIGID ARCHITECTURE OF COMPONENT */}
            {row.id === "fifty_thousand_records" &&
              row.selectedDropdown === "writeToBucket" && (
                <SigviewSingleSelect
                  value={row.bucketId || secondDropdownData[0]?.id}
                  data={secondDropdownData}
                  disabled={!checked}
                  onChange={(newValue) => {
                    handleSecondDropdownChange(row.id, newValue);
                  }}
                  minWidth="60px"
                  margin={{ right: "5px" }}
                  border="none"
                />
              )}

            {row.selectedDropdown !== "writeToBucket" && (
              <label className={classes.radioLabel}>{t(row.postText)}</label>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default SigviewRadioDropdownCombo;
