// Import required libraries
import React, { useState, useReducer, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// Import custom components
import AlertManagerForm from "./AlertManagerForm";
import SigviewButton from "../../components/Common/SigviewButton";
import SigviewTypography from "../../components/Common/SigviewTypography";
import AlertRow from "./AlertManagerComponents/AlertRow";
import Loader from "../../components/Loader/Loader";
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler";
import SigviewTooltip from "../Common/SigviewTooltip";

// Import action creators
import { updateUserScreen } from "../../redux/actions";

// Import utils/data
import { masterTrackGaEvent } from "../../services/ga";
import useReducerLogger from "../../utils/useReducerLogger";
import {
  unWrapperFunc,
  userSelection,
  unWrapperFuncSideNav,
  wrapper,
  getDimensionObjFromId,
  getMetricObjFromId,
  getMetricObjByFromId,
} from "../../utils/alertUtils";
import { config } from "../../config/config";

// Import reducer
import alertFormReducer from "../../redux/reducers/alertForm";
import { replaceAlertForm, updateAlertForm } from "../../redux/actions";

// Import Api
import { readAlerts, updateAlert } from "../../services/api";

// Import styles
import "./AlertManager.scss";

// Defining required variables
// console.log("userSelection", userSelection);
const defaultState = { ...userSelection };
const initialDataState = { status: "loading", message: "", result: [] };
const initialAlert = { status: "success", message: "", result: [] };

function AlertManager(props) {
  const { t } = useTranslation();
  // * Destructure props
  const {
    dispatch: ReduxDispatcher,
    user,
    allData,
    initialState = defaultState,
    globalFilters,
  } = props;
  // console.log("alertmanager props", props);

  // * Defining required states
  // mount and unmount alert manager everytime user click on add new alert
  const [showAlertManager, setShowAlertManager] = useState(false);
  const [alerts, setAlerts] = useState(initialDataState);
  const [reloadData, setReloadData] = useState(false);
  const [activeAlertNav, setActiveAlertNav] = useState("details");
  const [alertListData, setAlertListData] = useState(initialAlert);
  initialState.dimensionFilters = {
    ...initialState.dimensionFilters,
    value: globalFilters.dimensionFilters,
  };
  initialState.timeZone = {
    ...initialState.timeFilters,
    value: globalFilters.timeFilters.selectedTimezone.name,
  };
  //initialState.dimensionFilters.value = globalFilters.dimensionFilters;
  const alertFormDataOriginal = useRef(initialState);
  // Alert list state for the selected id
  const [selectedAlertId, setSelectedAlertId] = useState(null);
  // Defining the main reducer
  const [state, dispatch] = useReducer(
    useReducerLogger(alertFormReducer),
    initialState
  );
  // To get the maxSavedAlerts
  const maxSavedAlerts = user.uiLimitsList.maxSavedAlerts;

  // * Defining requires variables
  // FUNC: For unWrapperData
  const unWrapperData = unWrapperFunc(alerts.result, allData, user);

  // console.groupCollapsed("AlertManager Check");
  // console.log("alertListData", alertListData);
  // console.log("unWrapperData", unWrapperData);
  // console.log("alerts", alerts);
  // console.groupEnd();

  // * Defining required event handlers
  // FUNC: For every actions
  const handleReloadAlerts = () => {
    setReloadData(!reloadData);
  };

  // Opening the selected alerts from sideNav to the alertForm
  const handleActive = (e, id) => {
    // Update state to show the alert form for the selected alert
    setSelectedAlertId(id);
    setShowAlertManager(true);

    // Updation of the state with new selected alert
    const selectedAlert = unWrapperData.find((elem) => elem.id.value === id);
    // reducer dispatch action with value
    const payload = { value: selectedAlert };
    const action = replaceAlertForm(payload);
    dispatch(action);
    alertFormDataOriginal.current = { ...selectedAlert };
  };

  // FUNC: For toggling every alert from sideNav
  const handleToggle = (e, id) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "AlertManager",
      action: "AlertStatus",
      label: "SideNav",
    });

    // Toggle the only one alert
    //! For the progressbar
    const updatedAlertListData = alertListData.result.map((el) => {
      if (el.id === id) {
        const elMod = {
          ...el,
          isActiveData: { message: "", status: "loading" },
        };
        return elMod;
      }
      return el;
    });
    setAlertListData({
      status: "success",
      message: "",
      result: updatedAlertListData,
    });
    let toggleAlert = unWrapperData.find((el) => el.id.value === id);
    toggleAlert = {
      ...toggleAlert,
      active: {
        ...toggleAlert.active,
        value: !toggleAlert.active.value,
      },
    };

    const payload = wrapper(
      { ...toggleAlert },
      user?.reqMetadata?.email,
      allData,
      globalFilters
    );
    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
      payload,
    };

    const updateAlertPromise = updateAlert(fetchProps);
    updateAlertPromise
      .then((data) => {
        if (data.status.statusCode === "200") {
          const toggleAlert = alerts.result.map((el) => {
            if (el._id === id) {
              const elMod = {
                ...el,
                patternStatus:
                  el.patternStatus === "active" ? "inactive" : "active",
              };
              return elMod;
            }
            return el;
          });
          // console.log("alertListData toggle handleToggle", toggleAlert);
          const newData = {
            status: "success",
            message: "",
            result: toggleAlert,
          };
          setAlerts(newData);

          const updatedAlertListData = alertListData.result.map((el) => {
            if (el.id === id) {
              const elMod = {
                ...el,
                isActive: !el.isActive,
              };
              return elMod;
            }
            return el;
          });
          setAlertListData({
            status: "success",
            message: "",
            result: updatedAlertListData,
          });
        } else {
          // In case the api fails then we have to reload the alerts
          setReloadData(!reloadData);
        }
      })
      .catch((json) => {
        // In case the api fails then we have to reload the alerts
        setReloadData(!reloadData);
        console.groupCollapsed("API ERROR");
        console.error("ERROR -> ", json.error);
        console.groupEnd();
      });
  };

  const alertOutsideClickClose = (event) => {
    if (event?.target?.className?.includes("alert-manager-container-overlay")) {
      const value = {
        isOpen: false,
      };
      ReduxDispatcher(updateUserScreen("alertManager", value));
    }
  };

  // To close the alertManager from the close icon of the alertManager
  const handleClose = () => {
    // TODO : Hide alert manager;
    // ! Later, when integrated with sigview-ui-react repo
    const value = {
      isOpen: false,
    };
    ReduxDispatcher(updateUserScreen("alertManager", value));
  };

  // To add new alert from the Add New Alert button
  const handleAddNewAlert = (label) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "AlertManager",
      action: "AddNewAlert",
      label,
    });

    // state of the opeaning the alertMangerForm
    setShowAlertManager(true);

    // reducer dispatch action with value
    // Replace the email with the current user
    const finalInitialState = {
      ...initialState,
      id: {
        ...initialState.id,
        value: Date.now().toString(),
      },
      emailRecipients: {
        ...initialState.emailRecipients,
        value: [
          {
            id: user?.reqMetadata?.email,
            name: user?.reqMetadata?.email,
            deletable: false,
          },
        ],
      },
    };

    let payload = { value: finalInitialState };
    let action = replaceAlertForm(payload);
    dispatch(action);

    const value = `Pattern ${alerts.result.length + 1}`;
    payload = { key: "name", value };
    action = updateAlertForm(payload);
    dispatch(action);

    // Opeaning the default deatils tab on opeaning the alertMangerForm
    setActiveAlertNav("details");
    // resetting the selected alert id
    setSelectedAlertId(null);

    // Update alert form original
    alertFormDataOriginal.current = { ...initialState };
  };

  const getDimObjXAxisFromId = (dimId) => {
    var dimObj = allData.plotlyDimensions.find((el) => el._id === dimId);
    if (dimObj === undefined || dimObj === null) {
      return null;
    } else {
      return dimObj._id;
    }
  };

  // * Defining Required Side Effects
  // API call to get all alerts
  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
    };
    const readAlertsPromise = readAlerts(fetchProps);
    readAlertsPromise
      .then((responseData) => {
        if (responseData.status.statusCode === "200") {
          let reverseAlerts = [...responseData.result.data].reverse();

          const newData = {
            status: "success",
            message: "",
            result: reverseAlerts,
          };
          var dataRemovedFromGetDimList = reverseAlerts.filter(
            (row) =>
              row.expression.dimNodeList?.dimensionId !==
              getDimensionObjFromId(
                row.expression.dimNodeList?.dimensionId,
                row.alertType,
                allData
              )
          );
          var dataRemovedFromGetMtericList = dataRemovedFromGetDimList.filter(
            (row) =>
              row.expression.dimNodeList?.metricId !==
              getMetricObjFromId(
                row.expression.dimNodeList?.metricId,
                row.alertType,
                allData
              )
          );
          let yAxisList = [];
          dataRemovedFromGetMtericList.forEach((row) => {
            if (row.payload.requestParam.yAxis.length > 0) {
              row.payload.requestParam.yAxis.forEach((item) => {
                if (getMetricObjByFromId(item, allData) === null) {
                  yAxisList.push(row.patternName);
                }
              });
            }
          });
          var finaldataRemovedFromYaxis = dataRemovedFromGetMtericList.filter(
            (row) => row.patternName !== yAxisList[0]
          );

          let xAxisList = [];
          finaldataRemovedFromYaxis.forEach((row) => {
            if (row.payload.requestParam.xAxis.length > 0) {
              row.payload.requestParam.xAxis.forEach((item) => {
                if (getDimObjXAxisFromId(item) === null) {
                  xAxisList.push(row.patternName);
                }
              });
            }
          });
          var finaldataRemovedFromXaxis = finaldataRemovedFromYaxis.filter(
            (row) => row.patternName !== xAxisList[0]
          );

          let specialCalculationList = [];
          finaldataRemovedFromXaxis.forEach((row) => {
            if (row.payload.requestParam.specialCalculation.length > 0) {
              row.payload.requestParam.specialCalculation.forEach((item) => {
                if (getMetricObjByFromId(item, allData) === null) {
                  specialCalculationList.push(row.patternName);
                }
              });
            }
          });
          var finaldataRemovedFromSpecialCalculation =
            finaldataRemovedFromXaxis.filter(
              (row) => row.patternName !== specialCalculationList[0]
            );

          let approxCountDistinctList = [];
          finaldataRemovedFromSpecialCalculation.forEach((row) => {
            if (row.payload.requestParam.approxCountDistinct.length > 0) {
              row.payload.requestParam.approxCountDistinct.forEach((item) => {
                if (getMetricObjByFromId(item, allData) === null) {
                  approxCountDistinctList.push(row.patternName);
                }
              });
            }
          });
          var finaldataRemovedFromApproxCountDistinctList =
            finaldataRemovedFromSpecialCalculation.filter(
              (row) => row.patternName !== approxCountDistinctList[0]
            );

          reverseAlerts = finaldataRemovedFromApproxCountDistinctList;

          var sideNavUnWrappedData = unWrapperFuncSideNav(reverseAlerts);
          setAlertListData({
            status: "success",
            message: "",
            result: sideNavUnWrappedData.data,
          });
          setAlerts(newData);
        } else {
          const newData = {
            status: "error",
            message:
              responseData.status?.statusMessage ||
              config.hardCoded.uiErrorMessage,
            result: [],
          };
          setAlerts(newData);
        }
      })
      .catch((json) => {
        console.groupCollapsed("API ERROR");
        console.error("ERROR -> ", json);
        console.groupEnd();
        const newData = {
          status: "error",
          message: json.error || config.hardCoded.uiErrorMessage,
          result: [],
        };
        setAlerts(newData);
      });
    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
      // Reset alerts to initial state
      setAlerts(initialDataState);
    };
  }, [reloadData]); // To refresh the alertList to get all alerts

  //DEBUGGER
  useEffect(() => {
    console.groupCollapsed("RENDERED | AlertManager.js");
    console.log("STATE->", state);
    console.log("showAlertManager->", showAlertManager);
    console.log("alerts->", alerts);
    console.log("alertListData", alertListData);
    console.groupEnd();
  });

  // Defining required variables for the alert manager form props
  const alertManagerFormProps = {
    state,
    dispatch,
    user,
    globalFilters,
    allData,
    ReduxDispatcher,
    setShowAlertManager,
    reloadData,
    setReloadData,
    alertFormDataOriginal,
    activeAlertNav,
    setActiveAlertNav,
    setSelectedAlertId,
  };

  const disableAddNewAlertButton =
    alerts.status === "loading"
      ? true
      : alerts.result.length >= maxSavedAlerts
      ? true
      : false;
  const disabledAddNewAlertMessage =
    alerts.status === "loading"
      ? "Loading"
      : alerts.result.length >= maxSavedAlerts
      ? "Max Limit Reached"
      : "";

  return (
    <div
      className="alert-manager-container-overlay"
      onClick={alertOutsideClickClose}
    >
      <div className="alert-manager-form">
        <div className="title-row-container">
          <div className="left-panel">
            <SigviewTypography
              variant="pLarger"
              style={{ padding: "20px 0px 0px 0px" }}
            >
              Alerts
            </SigviewTypography>
          </div>
          <div className="right-panel">
            <i
              className="material-icons-round close-icon"
              onClick={handleClose}
            >
              close
            </i>
          </div>
        </div>
        <div className="content-container">
          <div className="left-panel">
            <div className="add-alert-button-container">
              <SigviewTooltip
                title={disabledAddNewAlertMessage}
                placement="bottom"
              >
                <Box>
                  <SigviewButton
                    variant="containedPrimary"
                    onClick={() => {
                      handleAddNewAlert("SideNav");
                    }}
                    title="Add New Alert"
                    iconFlag={true}
                    style={{
                      width: "210px",
                      btnHeight: "26px",
                      backgroundColor: "#f5f7ff",
                      color: "#267ee6",
                    }}
                    iconProps={{
                      className: "material-icons",
                      iconName: "add",
                    }}
                    disabled={disableAddNewAlertButton}
                    customClassName="AddNewAlert-GA"
                  />
                </Box>
              </SigviewTooltip>
            </div>
            {/* AlertList jsx */}
            <div className="alert-sidenav">
              <div className="alert-sidenav-list">
                {alerts.status === "loading" && <Loader />}
                {alerts.status === "error" && (
                  <ErrorHandler
                    message={alerts.message}
                    reloadFlag={true}
                    handleReloadAlerts
                    onReload={handleReloadAlerts}
                  />
                )}
                {alerts.status === "success" && alerts.result.length === 0 && (
                  <SigviewTypography variant="pLarge">
                    No Alerts
                  </SigviewTypography>
                )}
                {alerts.status === "success" && alerts.result.length !== 0 && (
                  <>
                    {alertListData.result.map((row) => (
                      <AlertRow
                        key={row.id}
                        {...row}
                        handleActive={handleActive}
                        handleToggle={handleToggle}
                        selectedAlertId={selectedAlertId}
                        showAlertManager={showAlertManager}
                        setShowAlertManager={setShowAlertManager}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className="right-panel"
            style={{
              borderTop: "1px solid #edf1ff",
              borderTopLeftRadius: "25px",
              borderLeft: "1px solid #edf1ff",
              background: "#f4f7ff",
            }}
          >
            {!showAlertManager && (
              <div className="right-panel no-alert-selected-container">
                <section className="add-or-open-alert-container">
                  <SigviewTooltip
                    title={disabledAddNewAlertMessage}
                    placement="bottom"
                  >
                    <Box>
                      <SigviewButton
                        variant="containedPrimary"
                        onClick={() => {
                          handleAddNewAlert("Form");
                        }}
                        title="Add New Alert"
                        iconFlag={true}
                        style={{
                          width: "210px",
                          btnHeight: "26px",
                          backgroundColor: "#f5f7ff",
                          color: "#267ee6",
                        }}
                        iconProps={{
                          className: "material-icons",
                          iconName: "add",
                        }}
                        disabled={disableAddNewAlertButton}
                        customClassName="AddNewAlert-GA"
                      />
                    </Box>
                  </SigviewTooltip>
                  <p className="no-alert-selected-text">{t("Or")}</p>
                  <p className="no-alert-selected-text lower">
                    {t("Select a saved Alert")}
                  </p>
                </section>
              </div>
            )}
            {/* AlertManagerForm jsx */}
            {showAlertManager && (
              <AlertManagerForm
                {...alertManagerFormProps}
                alertsName={`Pattern + ${alerts.result.length + 1}`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(AlertManager);
