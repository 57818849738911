// * Import required libraries
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

// * Import lib components

// * Import custom components
import AuthLoader from "../AuthLoader";

// * Import utils
import { config } from "../../config/config";

// * Import required contexts

// * Import hooks

// * Import APIs
import { sigviewSignInSso } from "../../services/api";

// * Import action creators
import { updateUserInfo, updateAuthLogin, logout } from "../../redux/actions";

function SignInSSO(props = {}) {
  const { dispatch: ReduxDispatcher = () => {} } = props;
  const history = useHistory();
  const params = new URL(window.location.href).searchParams;

  const handleUpdateUserInfo = (data) => {
    // Update embeddedInfo in redux
    const action = updateUserInfo(data);
    ReduxDispatcher(action);
  };

  useEffect(() => {
    // Update embeddedInfo in redux
    const data = {
      embeddedInfo: {
        isEmbeddedUser: true,
        email: params.get("email"),
        clientId: params.get("clientId"),
        cryptoKey: params.get("cryptoKey"),
        status: "loading",
        message: "",
      },
    };
    handleUpdateUserInfo(data);

    const fetchProps = {
      payload: {
        email: params.get("email"),
        clientId: params.get("clientId"),
      },
      headers: { Authorization: params.get("cryptoKey") },
    };
    const sigviewSignInSsoPromise = sigviewSignInSso(fetchProps);
    sigviewSignInSsoPromise
      .then((response) => {
        if (response.status !== 200) throw new Error();
        return response.json();
      })
      .then((responseData) => {
        if (responseData.statusCode === "200") {
          // Update embeddedInfo in redux
          var data = {
            embeddedInfo: {
              isEmbeddedUser: true,
              email: params.get("email"),
              clientId: params.get("clientId"),
              cryptoKey: params.get("cryptoKey"),
              status: "success",
              message: "",
            },
          };
          handleUpdateUserInfo(data);

          // Update local storage
          localStorage.setItem(
            "embedInfo",
            JSON.stringify({ clientId: params.get("clientId") })
          );
          localStorage.setItem(
            `${params.get("clientId")}_token`,
            responseData.token
          );

          // Update routes and re-load data
          const payload = {
            status: "success",
            xAuthToken: { "X-Auth-Token": responseData.token, instances: "" },
            isTokenPresent: true,
            isTokenValid: true,
          };

          // Update auth and history
          const action = updateAuthLogin(payload);
          ReduxDispatcher(action);
          history.push(config.hardCoded.rootUrlOnLogin);
        } else {
          throw new Error();
        }
      })
      .catch((json) => {
        ReduxDispatcher(logout());
        // Update embeddedInfo in redux
        var data = {
          embeddedInfo: {
            isEmbeddedUser: true,
            email: params.get("email"),
            clientId: params.get("clientId"),
            cryptoKey: params.get("cryptoKey"),
            status: "error",
            message: "",
          },
        };
        handleUpdateUserInfo(data);
      });
  }, []);

  return (
    <>
      <AuthLoader />
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
  allData: state.data,
});

export default connect(mapStateToProps)(SignInSSO);
