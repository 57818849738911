// * Import required libraies
import React, { useState, useEffect, useReducer, useContext } from "react";
import axios from "axios";
import { connect } from "react-redux";

// * Import lib components
import { Box } from "@material-ui/core";

// * Import Custom Component
import LayoutTopSideBottomAdmin from "../../../layouts/LayoutTopSideBottomAdmin/LayoutTopSideBottomAdmin";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import SigviewCheckList from "../../../components/SigviewChecklistAws";
import SigviewTab from "../../../components/SigviewTab";
import LayoutAdminContent from "../../../layouts/LayoutAdminContent/LayoutAdminContent";
import LayoutRow from "../../../layouts/LayoutRow/LayoutRow";
import SigviewTextField from "../../../components/Common/SigviewTextField";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";

// * Import Context
import { ThemeContext } from "../../../contexts/ThemeContext";
import { UserProvider } from "../../../contexts/AdminContext";

// * Import utils, config & static data
import { unWrapperviews, defaultActiveView } from "../../../utils/adminUtils";
import { updateAllDateRange, updateUserScreen } from "../../../redux/actions";
import useReducerLogger from "../../../utils/useReducerLogger";
import { userSelection as initialUserSelection } from "../../../utils/adminUtils";
import { config } from "../../../config/config";
import { getBreadcrumbsDataAdminAws } from "../../../utils/utils";
import { masterTrackGaEvent } from "../../../../js/services/ga/index";

// * Import action creators
// Reducers
import {
  updateAdminForm,
  replaceAdminForm,
  updateData,
} from "../../../redux/actions";
import { adminHome } from "../../../redux/reducers/admin";

// * Import APIs
import {
  getDateRange,
  readAdminAwsByOrg,
  readViewGroupListAwsByOrg,
} from "../../../services/api";
import { useHistory, useLocation } from "react-router";

function Home(props) {
  // * Destructure props
  const { dispatch: ReduxDispatcher, user = {}, allData = {} } = props;
  const { views: viewsData } = allData;

  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define contexts
  const history = useHistory();
  const location = useLocation();

  if (user?.reqMetadata?.jobRole !== "admin") {
    history.push("/");
  }
  // * Define required states
  const [selections, dispatchSelections] = useReducer(
    useReducerLogger(adminHome),
    initialUserSelection
  );
  const [reloadViewsData, setReloadViewsData] = useState(Date.now());

  // * Define required side effects
  //Get Views Data (for admin)

  //fetch getDateRange for all views (for admin)

  useEffect(() => {
    if (viewsData.status === "success") {
      let viewsLength = viewsData.result.length;
      //Make fetch call using axios

      for (const i of viewsData.result) {
        let source = axios.CancelToken.source();
        let fetchProps = {
          cancelToken: source.token,
          payload: {
            organization: user.reqMetadata.organization,
            view: i.id,
          },
        };

        const getDateRangePromise = getDateRange(fetchProps);
        getDateRangePromise
          .then((data) => {
            let viewData = {
              message: "",
              value: data.result,
              status: "success",
            };

            let viewId = i.id;

            let action = updateAllDateRange(viewId, viewData, viewsLength);
            ReduxDispatcher(action);
          })
          .catch((json) => {
            if (json.error !== config.hardCoded.queryCancelled) {
              const message = json.error || config.messages.uiErrorMessage;
              let viewData = {
                messsage: "Views API failed",
                status: "error",
                value: {
                  startDate: "0",
                  endDate: "0",
                },
              };

              let viewId = i.id;

              let action = updateAllDateRange(viewId, viewData, viewsLength);
              ReduxDispatcher(action);

              console.groupCollapsed("UI ERROR");
              console.log("JSON", json);
              console.log("ERROR ->", message);
              console.groupEnd();
            }
          });
      }
    }
  }, [viewsData]);

  useEffect(() => {
    const runFlag = user.reqMetadata && allData.views.status !== "success";
    if (runFlag) {
      //Make fetch call using axios
      const readAllAdminViewsPromise = readAdminAwsByOrg({});
      readAllAdminViewsPromise
        .then((data) => {
          let newData = {
            result: data.result.data,
            status: "success",
            messsage: "",
          };

          ReduxDispatcher(updateData("views", newData));
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            const message = json.error || config.messages.uiErrorMessage;

            let newData = {
              result: [],
              status: "error",
              messsage: "Views API failed",
            };
            ReduxDispatcher(updateData("views", newData));

            console.groupCollapsed("UI ERROR");
            console.log("JSON", json);
            console.log("ERROR ->", message);
            console.groupEnd();
          }
        });
    }
  }, [user.reqMetadata, reloadViewsData]);

  // getViewGroupList Data (for admin)
  useEffect(() => {
    const runFlag = user.reqMetadata && allData.views.status !== "success";
    if (runFlag) {
      const source = axios.CancelToken.source();
      const fetchProps = {
        cancelToken: source.token,
        payload: {
          organization: user.reqMetadata.organization,
          email: user.reqMetadata.email,
        },
      };
      const readViewGroupListAwsByOrgPromise =
        readViewGroupListAwsByOrg(fetchProps);
      readViewGroupListAwsByOrgPromise
        .then((data) => {
          let newData = {
            result: data.result.data,
            status: "success",
            messsage: "",
          };

          ReduxDispatcher(updateData("viewGroupList", newData));
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            const message = json.error || config.messages.uiErrorMessage;

            let newData = {
              result: [],
              status: "error",
              messsage: "Views API failed",
            };
            ReduxDispatcher(updateData("views", newData));

            console.groupCollapsed("UI ERROR");
            console.log("JSON", json);
            console.log("ERROR ->", message);
            console.groupEnd();
          }
        });
    }
  }, [user.reqMetadata, reloadViewsData]);

  // Update selections
  useEffect(() => {
    if (viewsData.status === "success") {
      const viewsDataUi = unWrapperviews(viewsData.result);
      const defaultView = viewsDataUi[0];
      const newAdminForm = {
        name: {
          message: "",
          value: user?.reqMetadata?.organization,
          status: "valid",
        },
        activeView: {
          message: "",
          value: {
            id: defaultView.id,
            title: defaultView.name,
          },
          status: "valid",
        },
        activeTab: {
          message: "",
          value: {
            id: "accessList",
            title: "accessList",
          },
          status: "valid",
        },
      };
      const payload = { value: newAdminForm };
      const action = replaceAdminForm(payload);
      dispatchSelections(action);
    }
  }, [viewsData]);

  // * Define required event handlers
  // For activenab and activetab
  const handleActiveTab = (e, id) => {
    //Return to home (will be controlled by router later)
    // const activeTab = id;
    // const activeNav = "admin"; //correct
    // const value = { activeNav, activeTab };
    // const action = updateUserScreen(null, value);
    // ReduxDispatcher(action);
  };
  const handleViewChange = (e, value) => {
    const payload = {
      key: "activeView",
      value,
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleViewsDataReload = () => {
    setReloadViewsData(Date.now());
  };

  // * Define required static variables for props
  const activeTab = user.screen.activeTab;
  const breadcrumbsData = getBreadcrumbsDataAdminAws({
    pathname: location.pathname,
  });
  const layoutTopSideBottomAdminProps = {
    activeTab,
    handleActiveTab,
    variant: "AdminAws",
  };
  const viewsDataUi = unWrapperviews(viewsData.result);
  const activeViewId = selections.activeView.value.id;
  const activeView =
    viewsDataUi.find((row) => row.id === activeViewId) || defaultActiveView;
  // Styling
  const layoutRowStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "50px",
    padding: "0px 25px",
  };
  const box1Style = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  };
  const box2Style = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  };
  const box3Style = {
    backgroundColor: `${themeColors["secondaryColorLightest"]} `,
    borderRadius: "3px 3px 0px 0px",
    // height: "100%",
    width: "100%",
  };
  const attributeTitleStyling = {
    height: "max-content",
    padding: "7px 0px 7px 15px",
  };
  const formTitleStyling = {
    height: "max-content",
    padding: "0px 10px 0px 0px",
  };

  const handelAttributeEdit = (item, activeAttributeType = "") => {
    masterTrackGaEvent({
      category: "Attribute",
      action: `Edit ${item.id}`,
      label: item.id,
    });

    let filteredView = viewsData.result.find(
      (row) => row.id === selections.activeView.value.id
    );

    let activeAttribute = filteredView[activeAttributeType].find(
      (row) => row._id === item.id
    );

    //update screen
    const activeTab = "attributes";
    const newActiveAttribute = {
      payload: {
        ...activeAttribute,
        id: activeAttribute._id,
        name: activeAttribute.title,
      },
      metadata: {
        crudType: "update",
        activeAttribute: activeAttribute._id,
        activeAttributeType,
        activeView: selections.activeView.value.id,
      },
    };

    // const value = { activeNav: "admin", activeTab };
    // var action = updateUserScreen(null, value);
    // ReduxDispatcher(action);

    var action = updateUserScreen("newActiveAttribute", newActiveAttribute);
    ReduxDispatcher(action);

    var pathname = "/admin/attributes";
    // `${location.pathname}${location.hash}${location.search}`;
    // pathname = pathname.replace("/app/#", "");
    history.push(pathname);
  };

  return (
    <LayoutTopSideBottomAdmin {...layoutTopSideBottomAdminProps}>
      <SigviewBreadcrumb
        data={breadcrumbsData} //data
        onClick={handleActiveTab} //onClick
      />
      <>
        {viewsData.status === "loading" && <Loader />}
        {viewsData.status === "error" && (
          <ErrorHandler
            message={viewsData.message || config.hardCoded.uiErrorMessage}
            reloadFlag={true}
            onReload={handleViewsDataReload}
          />
        )}
        {viewsData.status === "success" && (
          <>
            <LayoutRow style={layoutRowStyle}>
              <SigviewTypography style={formTitleStyling}>
                Organization:
              </SigviewTypography>
              <SigviewTextField
                value={selections.name.value}
                readOnly={true}
                width="200px"
              />
            </LayoutRow>

            <UserProvider value={"calc(100% - 35px)"}>
              <LayoutAdminContent
                fullHeight={"calc(100% - 100px)"}
                padding="0px 25px"
              >
                <Box css={box1Style}>
                  <Box
                    css={{
                      display: "flex",
                      alignItems: "center",
                      height: "45px !important",
                    }}
                  >
                    <SigviewTypography
                      style={{
                        padding: "0px 10px ",
                        height: "max-content",
                        // boxSizing: "border-box !important",
                        color: themeColors["primaryColor"],
                      }}
                    >
                      Available Views
                    </SigviewTypography>
                  </Box>

                  <SigviewTab
                    disabled={true}
                    data={viewsDataUi}
                    type="dataOnly"
                    activeView={selections.activeView.value}
                    handleActiveView={handleViewChange}
                  />
                </Box>

                <Box
                  css={{
                    display: "flex",
                    alignItems: "center",
                    height: "45px !important",
                  }}
                >
                  <SigviewTypography
                    variant="pMedium"
                    style={{
                      padding: "0px 15px ",
                      height: "max-content",
                      // boxSizing: "border-box !important",
                      color: themeColors["primaryColor"],
                    }}
                  >
                    {selections.activeView.value.id}
                  </SigviewTypography>
                </Box>

                <Box css={box2Style}>
                  <Box css={box3Style}>
                    <SigviewTypography style={attributeTitleStyling}>
                      {`Dimensions (${activeView.DimensionData.length})`}
                    </SigviewTypography>
                  </Box>
                  <SigviewCheckList
                    disabled={true}
                    data={activeView.DimensionData}
                    type="dataOnly"
                    handelAttributeEdit={(value) => {
                      handelAttributeEdit(value, "dimensions");
                    }}
                  />
                </Box>

                <Box css={box2Style}>
                  <Box css={box3Style}>
                    <SigviewTypography style={attributeTitleStyling}>
                      {`Metrics (${activeView.metricData.length})`}
                    </SigviewTypography>
                  </Box>
                  <SigviewCheckList
                    disabled={true}
                    data={activeView.metricData}
                    type="dataOnly"
                    handelAttributeEdit={(value) => {
                      handelAttributeEdit(value, "metrics");
                    }}
                  />
                </Box>

                <Box css={box2Style}>
                  <Box css={box3Style}>
                    <SigviewTypography style={attributeTitleStyling}>
                      {`Custom Metrics (${activeView.customMetricData.length})`}
                    </SigviewTypography>
                  </Box>
                  <SigviewCheckList
                    disabled={false}
                    data={activeView.customMetricData}
                    type="dataOnly"
                    handelAttributeEdit={(value) => {
                      handelAttributeEdit(value, "customMetrics");
                    }}
                    variant="editable"
                  />
                </Box>
              </LayoutAdminContent>
            </UserProvider>
          </>
        )}
      </>
    </LayoutTopSideBottomAdmin>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(Home);
