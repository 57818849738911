// * Import required libraies
import React, { useEffect } from "react";
import _ from "underscore";
import axios from "axios";
import { connect, useSelector } from "react-redux";

// * Import hooks
import useUpdateEffect from "../../../hooks/useUpdateEffect";

// * Import utils/data
import { config } from "../../../config/config";
import { wrapperChartObject } from "../../../utils/chartObjectUtils";

// * Import redux utils
// Selectors
import {
  selectDimTableSelections,
  selectDimTableData,
  selectReloadEpoch,
} from "../../../redux/selectors/standaloneWsSelectors";
// Actions
import { updateWsFormDimensionTableData } from "../../../redux/actions";

// * Import API functions
import { getData, getComparisonData } from "../../../services/api";

function WsDimTable(props = {}) {
  const {
    // REDUX PROPS
    dispatch: ReduxDispatcher,
    user,
    // PARENT PROPS
    id = "",
  } = props;

  // * Destructure REDUX props
  const dimensionTableDate = useSelector((state) =>
    selectDimTableData(state, id)
  );
  const selections = useSelector((state) =>
    selectDimTableSelections(state, id)
  );
  const reloadEpoch = useSelector((state) => selectReloadEpoch(state, id));

  // * Define required event handlers
  const handleDataChange = (value) => {
    let payload = { dimId: id, dimData: value };
    let action = updateWsFormDimensionTableData(payload);
    ReduxDispatcher(action);
  };
  const handleError = (message) => {
    handleDataChange({
      result: { dataFromQE: [], extraData: {} },
      status: "error",
      message: message || config.hardCoded.uiErrorMessage,
    });
  };

  // getData Chart Data
  useEffect(() => {
    // Making required source objects for cancellation in clean-up function
    const sourceSingleCall = axios.CancelToken.source();
    const timer = setTimeout(() => {
      const runFlag =
        dimensionTableDate.status === "loading" ||
        dimensionTableDate.status === "error";
      if (runFlag) {
        // Initializing required variables
        let dataFromQEFinal = [];
        let extraDataFinal = [];

        try {
          if (selections.timeFilters.isComparisonOn) {
            const fetchProps = {
              payload: wrapperChartObject({ selections, user }),
              cancelToken: sourceSingleCall.token,
            };
            const getComparisonDataPromise = getComparisonData(fetchProps);
            getComparisonDataPromise
              .then((responseDate) => {
                dataFromQEFinal = responseDate.result.data;
                extraDataFinal = {};
                // Update parent state
                handleDataChange({
                  status: "success",
                  message: "",
                  result: {
                    dataFromQE: dataFromQEFinal,
                    extraData: extraDataFinal,
                  },
                });
              })
              .catch((error) => {
                if (error.error !== config.hardCoded.queryCancelled) {
                  handleError(error.error);
                }
              });
          } else {
            const fetchProps = {
              payload: wrapperChartObject({ selections, user }),
              cancelToken: sourceSingleCall.token,
            };
            const getDataPromise = getData(fetchProps);
            getDataPromise
              .then((responseDate) => {
                dataFromQEFinal = responseDate.result.data;
                extraDataFinal = {};
                // Update parent state
                handleDataChange({
                  status: "success",
                  message: "",
                  result: {
                    dataFromQE: dataFromQEFinal,
                    extraData: extraDataFinal,
                  },
                });
              })
              .catch((error) => {
                if (error.error !== config.hardCoded.queryCancelled) {
                  handleError(error.error);
                }
              });
          }
        } catch (err) {
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.error(err);
          console.groupEnd();
          handleError(config.hardCoded.uiErrorMessage);
        }
      }
    }, 1);

    // Clean-up function to cancel all pending fetch calls
    return () => {
      // Cancel all previous fetch calls
      if (sourceSingleCall) sourceSingleCall.cancel();
      clearTimeout(timer);
    };
  }, [dimensionTableDate.status, reloadEpoch]);

  useUpdateEffect(() => {
    // ! Creates a bug where if the chart data is loading, it won't cancel or refetch the request
    // if (["success", "error"].includes(dimensionTableDate.status)) {
    //   // console.error("I CAME HERE 2");
    //   // Reset parent chart data state to loading anytime selections or reloadEpoch change
    // handleDataChange({
    //     result: { dataFromQE: [], extraData: {} },
    //     status: "loading",
    //     message: "",
    //   });
    // }

    // Change state to loading
    handleDataChange({
      result: { dataFromQE: [], extraData: {} },
      status: "loading",
      message: "",
    });
    return () => {
      // console.error("CLEANUP");
    };
    // ! Don't replace this with selections object. It doesn't work. Perhaps because of a
    // ! new reference that gets created on every change in props or state
  }, [
    // ! Comment out selections.chartType below to achieve smooth transition of charts (no reload); can be done only when all charts have the same call
    // selections.dimensionFilters, // ! Dimension related changes in standaloneWs using manipulateStateBasedOnTimeFiltersChange
    // selections.timeFilters.selectedDatesQE, // ! Time filter related changes in standaloneWs using manipulateStateBasedOnTimeFiltersChange
    // selections.timeFilters.compareDates, // ! Time filter related changes in standaloneWs using manipulateStateBasedOnTimeFiltersChange
    // selections.timeFilters.isComparisonOn, // ! Time filter related changes in standaloneWs using manipulateStateBasedOnTimeFiltersChange
    selections.dimensionsList,
    selections.metricsList,
    selections.orderById,
    selections.orderBy,
    selections.orderByType,
    selections.percentCalList,
    selections.dataLimit,
  ]);

  return <></>;
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(WsDimTable);
