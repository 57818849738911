//Import required libraies
import { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";

//import library components
import { makeStyles } from "@material-ui/core";

//Import Custom Component
import SigviewTypography from "../../components/Common/SigviewTypography";

//Import Custom Hooks

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

//Import utils/data

//making styles for all variations
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const { overlayPadding = "30px" } = customStyle;
  const useStyles = makeStyles(() => ({
    overlay: {
      width: "100vw",
      height: "100vh",
      padding: overlayPadding,
      backgroundColor: themeColors["overlayBgColor"],
      zIndex: 10000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      top: "0px",
      left: "0px",
      boxSizing: "border-box",
    },
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      backgroundColor: themeColors["popoverBgColor"],
      borderRadius: "3px",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "8px 16px 8px 22px",
      height: "50px",
      boxSizing: "border-box",
      borderBottom: `1px solid ${themeColors["secondaryColorLightest"]}`,
      backgroundColor: themeColors["headerBgColor"],
      borderRadius: "3px 3px 0px 0px",
    },
    headerLeft: {},
    headerRight: {},
    titleContainer: {},
    closeIconContainer: {
      borderRadius: "50%",
      padding: "5px",
      fontSize: "18px",
      cursor: "pointer",
      color: themeColors["secondaryColor"],
      "&:hover": {
        backgroundColor: themeColors["secondaryColorLightest"],
      },
    },
    body: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      height: "calc(100% - 50px)",
      backgroundColor: themeColors["headerBgColor"],
      borderRadius: "0px 0px 3px 3px",
    },
  }));
  return useStyles;
};

// Defining required static variables
const initialSettings = {
  children: null,
  title: "Sample Layout Title",
  onClose: () => {},
  style: {},
};

const LayoutBigDialogTitle = (props) => {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    title = "Sample Page Title",
    variant = "heading",
    style: customStyle = { color: "#f5f4f3" },
  } = props;

  // Defining required variables
  const useSigviewStyles = makeSigviewStyles(themeColors, customStyle);
  const classes = useSigviewStyles();
  const { titleContainer } = classes;

  return (
    <div className={titleContainer}>
      <SigviewTypography variant={variant} style={{ height: "max-content" }}>
        {title}
      </SigviewTypography>
    </div>
  );
};

function LayoutBigDialog(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const { user } = props;

  // Defining requried state
  const [settings, setSettings] = useState(initialSettings);
  const { title, onClose, children, style: customStyle = {} } = settings;

  // Defining required side effects
  // Update props
  useEffect(() => {
    const newSettings = {
      ...(user?.screen?.sigviewBigDialog?.props || { ...initialSettings }),
    };
    setSettings(newSettings);
  }, []);

  // Defining required variables
  const useSigviewStyles = makeSigviewStyles(themeColors, customStyle);
  const classes = useSigviewStyles();
  const {
    overlay,
    root,
    body,
    header,
    headerLeft,
    headerRight,
    closeIconContainer,
  } = classes;
  const typeofTitle = typeof title;

  return (
    <div className={overlay}>
      <section className={root}>
        <article className={header}>
          <div className={headerLeft}>
            {typeofTitle === "string" && <LayoutBigDialogTitle title={title} />}
            {typeofTitle !== "string" && <>{title}</>}
          </div>
          <div className={headerRight}>
            <span
              className={`material-icons-round ${closeIconContainer}`}
              onClick={onClose}
            >
              close
            </span>
          </div>
        </article>
        <article className={`${body} sigview-styled-scroller`}>
          {children}
        </article>
        {/* <article className="sigview-big-dialog-footer"></article> */}
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(LayoutBigDialog);
