// * Import required libraies
import React from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useReducer, useContext, useState, useEffect } from "react";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * import lib component
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// * Import custom components
import SigviewIcon from "../../components/Common/SigviewIcon";
import SigviewTextField from "../../components/Common/SigviewTextField";
import SigviewButton from "../../components/Common/SigviewButton";
import SigviewTypography from "../../components/Common/SigviewTypography";
import LayoutNone from "../../layouts/LayoutNone/LayoutNone";
import Loader from "../../components/Loader/Loader";
import LayoutBottom from "../../layouts/LayoutBottom/LayoutBottom";
import SigviewHoverPopup from "../../components/Common/SigviewHoverPopup";

// * Import redux utils
import { masterTrackGaEvent } from "../../services/ga";
import useReducerLogger from "../../utils/useReducerLogger";
import { config } from "../../config/config";
import {
  updateResetPassword,
  submitResetPasswordInitialError,
} from "../../redux/actions";
import resetPasswordReducer from "../../redux/reducers/resetPassword";
import { isSelectionsInvalid } from "../../utils/resetPasswordUtils";
import { isHostnameLocalDev, isNativeDomain } from "../../utils/utils";

//  * Import APIs
import { resetPasswordAPIcall, isTokenValidAPIcall } from "../../services/api";

// * Import styles
import signInImage from "../../../assets/images/loginFlowImg.svg";

// * Define style functions
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
    },
    rootLoader: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    leftPanel: {
      width: "50%",
      height: "100%",
      display: "inline-block",
    },
    rightPanel: {
      width: "50%",
      height: "100%",
      display: "inline-block",
      background: "#045ad9",
      borderRadius: "50px 0px 0px 50px",
    },
    signInSignUpImageContainer: {
      width: "100%",
      height: "calc(100vh - 20px)",
      background: "url(" + signInImage + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center",
      borderRadius: "50px 0px 0px 50px",
    },
    mainSignInFormRowLeft: {
      padding: "10px",
      width: "350px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    signInImageContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    mainSignInFormRowBottom: {
      padding: "10px",
      width: "350px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    alignHelpIcon: {
      position: "relative",
      top: "4px",
    },
    sigmoidLogoContainer: {
      height: "50px",
      width: "100px",
      padding: "50px",
      cursor: "pointer",
    },
    mainSignInContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100vh - 50px)",
    },
    mainSignInFormRow: {
      padding: "10px",
      width: "350px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
  });
  return useStyles;
};

// * Define required utility components
// ! It may be temptive to define utility components inside the main component because it will reduce sending and destructuring props
// ! But it should NOT be defined inside the main component
// * BECAUSE everytime state changes those functional components get redefined and are unmounted/mounted on every state change
// * This creates a bug where the input loses focus on each key stroke as the component has mounted and unmounted

const SignInImage = (props = {}) => {
  const { classes } = props;
  return (
    <Box className={classes.signInSignUpImageContainer}>
      {/* <img src={signInImage} width="80%" height="80%" /> */}
    </Box>
  );
};

const SigmoidLogo = (props = {}) => {
  const { eventHandlers, classes } = props;
  const { handleBackToHome } = eventHandlers;
  return (
    <Box className={classes.mainSignInFormRowLeft} onClick={handleBackToHome}>
      <img
        src="https://logo.sigmoid.io/sigmoid.png"
        alt="Sigmoid"
        height="auto"
        width="70px"
      />
    </Box>
  );
};
const LinkExpired = (props = {}) => {
  const { eventHandlers, themeColors, classes } = props;
  const { handleBackToHome } = eventHandlers;

  return (
    <Box className={classes.mainSignInContainer}>
      <SigmoidLogo eventHandlers={eventHandlers} classes={classes} />
      <Box
        css={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "350px",
          padding: "20px 0px 0px 0px",
          height: "60px",
        }}
      >
        <Box>
          <SigviewIcon
            iconName="warning"
            style={{
              fontSize: "30px !important",
              padding: "20px 20px 25px 0px",
              color: themeColors["failureColor"],
              hoverColor: themeColors["failureColor"],
              cursor: "default",
            }}
          />
        </Box>
        <Box>
          <SigviewTypography
            variant="pLargeMiddle"
            style={{
              lineHeight: "16px",
              textAlign: "left",
              // padding: "10px 0px",
              width: "350px",
              height: "auto",
            }}
          >
            The Link has expired
          </SigviewTypography>
        </Box>
      </Box>
      {/* <Box className={classes.mainSignInFormRow}></Box> */}

      <Box className={classes.mainSignInFormRow}>
        <SigviewButton
          variant="containedLighterNewBlue"
          style={{
            btnWidth: "350px",
            btnHeight: "40px",
            fontSize: "12px !important",
            bgColor: "#045ad9",
            color: "#fff",
            bgHoverColor: "#fff",
            backgroundColor: "#045ad9",
          }}
          customClassName="ResetPasswordLinkExpired-GA"
          onClick={handleBackToHome}
          title="Back to home"
        />
      </Box>
    </Box>
  );
};
const Reset = (props = {}) => {
  const { selections, classes, eventHandlers, staticVariables, themeColors } =
    props;
  const { handleNewPassword, handleConfirmPassword, submitResetPassword } =
    eventHandlers;
  const { resetTitle } = staticVariables;
  const passwordValidationRules = config.hardCoded["passwordValidationRules"];

  return (
    <Box className={classes.mainSignInContainer}>
      <SigmoidLogo eventHandlers={eventHandlers} classes={classes} />
      <Box className={classes.mainSignInFormRowLeft}>
        <Box
          css={{
            display: "flex",
            width: "190px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <SigviewTypography
              variant="pLargest"
              style={{ padding: "10px 0px 0px 0px" }}
            >
              Reset Password
            </SigviewTypography>
          </Box>
          <Box></Box>
        </Box>

        <SigviewTypography
          variant="pSmallMedium"
          style={{ padding: "10px 0px 0px 0px" }}
        >
          Enter new password and then repeat it
        </SigviewTypography>
      </Box>
      {selections.resetPasswordResponseMessage.value === "error" && (
        <Box>
          <SigviewTypography
            style={{
              color: "#f44336",
              lineHeight: "16px",
              textAlign: "left",
              width: "350px",
              padding: "0px 0px 10px 0px",
            }}
            variant="pMedium"
          >
            {selections.resetPasswordResponseMessage.value === "error" &&
              selections.resetPasswordResponseMessage.message}
          </SigviewTypography>
        </Box>
      )}
      <form onSubmit={submitResetPassword}>
        <Box className={classes.mainSignInFormRow}>
          <SigviewTextField
            width="350px"
            height="40px"
            fontSize="12px"
            border="1px solid #ddd"
            placeholder="New Password"
            type="password"
            value={selections.newPassword.value}
            onChange={handleNewPassword}
            error={
              selections.newPassword.status === "invalid" &&
              selections.showResetPasswordError.value === true
            }
            helperText={selections.newPassword.message}
          />
        </Box>
        <Box className={classes.mainSignInFormRow}>
          <SigviewTextField
            width="350px"
            height="40px"
            fontSize="12px"
            border="1px solid #ddd"
            placeholder="Confirm Password"
            type="password"
            value={selections.confirmPassword.value}
            onChange={handleConfirmPassword}
            error={
              selections.confirmPassword.status === "invalid" &&
              selections.showResetPasswordError.value === true
            }
            helperText={selections.confirmPassword.message}
          />
        </Box>

        <Box className={classes.mainSignInFormRow}>
          <SigviewButton
            variant="containedLighterNewBlue"
            style={{
              btnWidth: "350px",
              btnHeight: "40px",
              fontSize: "12px !important",
              bgColor: "#045ad9",
              color: "#fff",
              bgHoverColor: "#fff",
              backgroundColor: "#045ad9",
            }}
            customClassName="ResetPasswordClick-GA"
            title={resetTitle}
            disabled={
              (selections.showResetPasswordError.value
                ? isSelectionsInvalid(selections)
                : false) || selections.resetLoader.value
            }
            type="submit"
          />
        </Box>
      </form>
    </Box>
  );
};
const PostReset = (props = {}) => {
  const { classes, eventHandlers, themeColors, count } = props;
  const { handleBackToHome } = eventHandlers;
  return (
    <>
      <Box className={classes.mainSignInContainer}>
        <SigmoidLogo eventHandlers={eventHandlers} classes={classes} />
        <Box
          css={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "350px",
            padding: "20px 0px 0px 0px",
          }}
        >
          <Box>
            <SigviewIcon
              iconName="check_circle"
              style={{
                fontSize: "30px !important",
                padding: "0px 10px 0px 0px",
                color: themeColors["primaryColor"],
                hoverColor: themeColors["primaryColor"],
                cursor: "default",
              }}
            />
          </Box>

          <SigviewTypography
            variant="pLargeMiddle"
            style={{
              lineHeight: "16px",
              textAlign: "left",
              padding: "10px 10px 10px 0px",
              width: "350px",
              height: "auto",
            }}
          >
            Password Successfully Changed!
          </SigviewTypography>
        </Box>
        <Box css={{ paddingLeft: "83px" }}>
          {count > 0 && (
            <SigviewTypography
              variant="pMedium"
              style={{
                lineHeight: "16px",
                textAlign: "left",
                width: "350px",
                height: "auto",
                padding: "10px",
              }}
            >
              You will be automatically redirected in {count} seconds
            </SigviewTypography>
          )}
        </Box>
        <Box css={{ paddingLeft: "83px" }}>
          {count === 0 && (
            <SigviewTypography
              variant="pMedium"
              style={{
                lineHeight: "16px",
                textAlign: "left",
                width: "350px",
                height: "auto",
                padding: "10px",
              }}
            >
              Redirecting to Login Page...
            </SigviewTypography>
          )}
        </Box>
        <Box className={classes.mainSignInFormRow}>
          <SigviewButton
            variant="containedLighterNewBlue"
            style={{
              btnWidth: "350px",
              btnHeight: "40px",
              fontSize: "12px !important",
              bgColor: "#045ad9",
              color: "#fff",
              bgHoverColor: "#fff",
              backgroundColor: "#045ad9",
            }}
            customClassName="PasswordResetSuccessfully-GA"
            onClick={handleBackToHome}
            title="Back to home"
          />
        </Box>
      </Box>
    </>
  );
};

// * Define required static variables
const initialSelections = {
  email: {
    message: "",
    value: "",
    status: "",
  },
  newPassword: {
    message: "",
    value: "",
    status: "invalid",
  },
  confirmPassword: {
    message: "",
    value: "",
    status: "invalid",
  },
  tab: {
    message: "",
    value: "",
    status: "",
  },
  resetPasswordResponseMessage: {
    message: "",
    value: "",
    status: "",
  },
  showResetPasswordError: {
    message: "",
    value: false,
    status: "",
  },
  resetLoader: {
    message: "",
    value: false,
    status: "",
  },
};

function ResetPasswordForm(props = {}) {
  // * Define required hooks
  const history = useHistory();
  const location = useLocation();
  const search = location.search;
  const resetTokenId = search.replace("?", "");
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define required states
  const [selections, dispatch] = useReducer(
    useReducerLogger(resetPasswordReducer),
    initialSelections
  );
  const [count, setCount] = useState(10);

  // * Define requried event handlers
  const handleBackToHome = () => {
    // ! THE BELOW CODE WAS REDIRECTING TO 404 Page Not Found for a second before redirecting to sign-in page
    // ! This is happening because authForm.status === "public" which is triggering the first switch case in AppAuth
    // history.push("/sign-in");
    // window.location.reload();

    // * There are 2 solutions:
    // Solution 1. Faster, easier but poor UX as the entire page will reload
    // Solution 2. Slower, complicated but better UX as the entire page will not reload
    //             This will require moving authForm state from AppAuth to redux so that we can trigger global state update
    // We have implemented solution 1 for now

    // Solution 1
    var a = document.createElement("a");
    var url = `https://${window.location.hostname}:${window.location.port}/sign-in`;
    if (isHostnameLocalDev()) {
      url = `http://${window.location.hostname}:${window.location.port}/sign-in`;
    }
    a.href = url;
    document.body.appendChild(a);
    a.click();
  };
  const handleNewPassword = (value) => {
    const payload = {
      key: "newPassword",
      value,
    };
    const action = updateResetPassword(payload);
    dispatch(action);
  };
  const handleConfirmPassword = (value) => {
    const payload = {
      key: "confirmPassword",
      value,
    };
    const action = updateResetPassword(payload);
    dispatch(action);
  };

  // * Define required API functions
  const isTokenValid = () => {
    if (resetTokenId !== "") {
      const isTokenValidPromise = isTokenValidAPIcall({ resetTokenId });
      isTokenValidPromise
        .then((result) => result.json())
        .then((data) => {
          const payload = {
            key: "resetLoader",
            value: false,
          };
          const action = updateResetPassword(payload);
          dispatch(action);
          if (data.status === "0") {
            const payload = {
              key: "tab",
              value: "linkExpired",
            };
            const action = updateResetPassword(payload);
            dispatch(action);
            masterTrackGaEvent({
              category: "OpenResetPage",
              action: "TokenInValid",
              label: "LinkExpired",
            });
          }
          if (data.status === "1") {
            const payload = {
              key: "tab",
              value: "resetPasswordForm",
            };
            const action = updateResetPassword(payload);
            dispatch(action);
            masterTrackGaEvent({
              category: "OpenResetPage",
              action: "TokenValid",
              label: "Open",
            });

            const actionEmail = updateResetPassword({
              key: "email",
              value: data.email,
            });
            dispatch(actionEmail);
          }
        })
        .catch((data) => {
          const actionLoader = updateResetPassword({
            key: "resetLoader",
            value: false,
          });
          dispatch(actionLoader);
          const payload = {
            key: "tab",
            value: "linkExpired",
          };
          const action = updateResetPassword(payload);
          dispatch(action);
          console.error("Error catch");
        });
    } else {
      const actionLoader = updateResetPassword({
        key: "resetLoader",
        value: false,
      });
      dispatch(actionLoader);
      const payload = {
        key: "tab",
        value: "linkExpired",
      };
      const action = updateResetPassword(payload);
      dispatch(action);
    }
  };
  const submitResetPassword = (e) => {
    e.preventDefault();
    const payload = {
      key: "resetLoader",
      value: true,
    };
    const action = updateResetPassword(payload);
    dispatch(action);
    const actionResetError = updateResetPassword({
      key: "showResetPasswordError",
      value: true,
    });
    dispatch(actionResetError);
    if (!isSelectionsInvalid(selections)) {
      const resetPasswordPromise = resetPasswordAPIcall(
        isNativeDomain()
          ? {
              payload: {
                email: selections.email.value,
                newPassword: selections.newPassword.value,
                tokenId: resetTokenId,
              },
            } //Added only for Native Domain like (keyzen,localhost)
          : {
              payload: {
                domain: window.location.hostname,
                email: selections.email.value,
                newPassword: selections.newPassword.value,
                tokenId: resetTokenId,
              },
            }
      );
      resetPasswordPromise
        .then((data) => data.json())
        .then((result) => {
          const actionLoader = updateResetPassword({
            key: "resetLoader",
            value: false,
          });
          dispatch(actionLoader);
          if (result.status === "1") {
            const payload = {
              key: "tab",
              value: "postReset",
            };
            const action = updateResetPassword(payload);
            dispatch(action);
            masterTrackGaEvent({
              category: "ResetPassword",
              action: "Submit",
              label: "Password Reset Successfully",
            });
          } else if (result.status === "2") {
            const payload = {
              key: "resetPasswordResponseMessage",
              value: {
                ...selections.resetPasswordResponseMessage,
                message: result.message,
                value: "error",
                status: "invalid",
              },
            };
            const action = submitResetPasswordInitialError(payload);
            dispatch(action);

            const actionResetError = updateResetPassword({
              key: "showResetPasswordError",
              value: true,
            });
            dispatch(actionResetError);
          } else {
            dispatch(actionLoader);
            const payload = {
              key: "resetPasswordResponseMessage",
              value: {
                ...selections.resetPasswordResponseMessage,
                message: "Password could not be reset. Please try again later.",
                value: "error",
                status: "invalid",
              },
            };
            const action = submitResetPasswordInitialError(payload);
            dispatch(action);
            const actionResetError = updateResetPassword({
              key: "showResetPasswordError",
              value: true,
            });
            dispatch(actionResetError);
          }
        })
        .catch((result) => {
          const actionLoader = updateResetPassword({
            key: "resetLoader",
            value: false,
          });
          dispatch(actionLoader);
          const payload = {
            key: "resetPasswordResponseMessage",
            value: {
              ...selections.resetPasswordResponseMessage,
              message: "Password could not be reset. Please try again later.",
              value: "error",
              status: "invalid",
            },
          };
          const action = submitResetPasswordInitialError(payload);
          dispatch(action);

          const actionResetError = updateResetPassword({
            key: "showResetPasswordError",
            value: true,
          });
          dispatch(actionResetError);
          console.error("ERROR -> Catch");
        });
    } else {
      const actionLoader = updateResetPassword({
        key: "resetLoader",
        value: false,
      });
      dispatch(actionLoader);
      const payload = {
        key: "showResetPasswordError",
        value: true,
      };
      const action = updateResetPassword(payload);
      dispatch(action);
    }
  };

  // * Define required static variables
  // Check email link is valid or not on component mount
  useEffect(() => {
    isTokenValid();
  }, []);
  // Automatic Redirection on home Link count
  useEffect(() => {
    if (selections.tab.value === "postReset") {
      var intervalId = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount === 0) {
            handleBackToHome();
          }
          return prevCount - 1;
        });
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [selections.tab.value]);

  // * DEBUGGER
  // console.groupCollapsed("ResetPassword.js");
  // console.log("history", history);
  // console.log("location", location);
  // console.log("resetTokenId", resetTokenId);
  // console.groupEnd();

  // * Define required variables
  const resetTitle =
    selections.resetLoader.value === true ? <Loader /> : "Reset";
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const eventHandlers = {
    handleBackToHome,
    submitResetPassword,
    handleNewPassword,
    handleConfirmPassword,
  };
  const staticVariables = { resetTitle };
  const commonChildrenProps = {
    selections,
    eventHandlers,
    classes,
    themeColors,
    count,
    staticVariables,
  };

  return (
    <>
      {selections.tab.value === "" && (
        <LayoutNone>
          <Box className={classes.rootLoader}>
            <Loader />
          </Box>
        </LayoutNone>
      )}
      {selections.tab.value !== "" && (
        <LayoutBottom>
          <Box className={classes.root}>
            <Box className={classes.leftPanel}>
              {selections.tab.value === "linkExpired" && (
                <LinkExpired {...commonChildrenProps} />
              )}
              {selections.tab.value === "resetPasswordForm" && (
                <Reset {...commonChildrenProps} />
              )}
              {selections.tab.value === "postReset" && (
                <PostReset {...commonChildrenProps} />
              )}
            </Box>
            <Box className={classes.rightPanel}>
              <SignInImage {...commonChildrenProps} />
            </Box>
          </Box>
        </LayoutBottom>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ResetPasswordForm);
