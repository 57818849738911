// * Import required libraies
import React, { useContext, useRef } from "react";
import { toast } from "react-toastify";
import { connect, useSelector } from "react-redux";
import _ from "underscore";
import { useTranslation } from "react-i18next";

// * Import lib components
import { Box } from "@material-ui/core";

// * Import Custom Component
import SigviewIcon from "../../Common/SigviewIcon";
import SigviewTooltip from "../../Common/SigviewTooltip";
import SigviewTypography from "../../Common/SigviewTypography";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Import action creators
import {
  updateUserScreen,
  updateGlobalFiltersDimensionFilters,
  updateGlobalFiltersTimeFilters,
} from "../../../redux/actions";

// * Import utils
import { wrapperDimDownloadRequest } from "../../../utils/analyzeUtils";
import { initialWsFormScreenValue } from "../../../utils/analyzeUtils";
import { getInitialMsvForm } from "../../../utils/msvUtils";

// * Import redux utils
// Selectors
import {
  selectDimTableSelections,
  selectDimTableFilterDetails,
  selectIsDimTableDeleteVisible,
} from "../../../redux/selectors/standaloneWsSelectors";
// Actions
import {
  updateStandaloneWsForm,
  deleteStandaloneWsDimensionTable,
  updateStandaloneWsAndMsvByOpeningMsv,
} from "../../../redux/actions";

// * Import API functions
import { dimDownloadRequest, downloadReportFile } from "../../../services/api";
import { masterTrackGaEvent } from "../../../services/ga";

function WsDimHeader(props) {
  const { t } = useTranslation();
  // * Destructure Props
  const {
    // REDUX PROPS
    dispatch: ReduxDispatcher,
    allData,
    user,
    standaloneWs: wsForm,
    dimensionTableDate,
    // PARENT PROPS
    id,
    classes,
  } = props;

  // * Define required hooks/refs/contexts
  const toastId = useRef(null);
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Destructure REDUX props
  const isDeleteVisible = useSelector((state) =>
    selectIsDimTableDeleteVisible(state, id)
  );
  const selections = useSelector((state) =>
    selectDimTableSelections(state, id)
  );
  const dimTableFilterDetails = useSelector(
    (state) => selectDimTableFilterDetails(state, id, themeColors),
    themeColors
  );

  // * Define required event handlers
  const handleMsvFullView = () => {
    if (!dimTableFilterDetails.isFullViewDisabled) {
      // Google Analytics Event - Master
      masterTrackGaEvent({
        category: "Workspace",
        action: "TableFullView",
        label: "fullView",
      });

      const metadata = { workspaceSelections: wsForm, msvTableId: id };
      const newMsvValue = {
        isOpen: true,
        metadata: metadata,
      };
      const initialMsvForm = getInitialMsvForm({ ...metadata, themeState });
      const payload = {
        wsFormMsvValue: newMsvValue,
        msvFormValue: initialMsvForm,
      };
      const action = updateStandaloneWsAndMsvByOpeningMsv(payload);
      ReduxDispatcher(action);
    }
  };
  const handleAddMetrics = () => {
    const newScreen = {
      ...initialWsFormScreenValue,
      dimensionDrawer: true,
      dimensionDrawerList: { [id]: true },
    };
    const payload = { key: "screen", value: newScreen };
    const action = updateStandaloneWsForm(payload);
    ReduxDispatcher(action);
  };
  const handleDelete = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "TableRemove",
      label: "Remove",
    });
    let payload = {
      deleteItemId: id,
    };
    let action = deleteStandaloneWsDimensionTable(payload);
    ReduxDispatcher(action);
  };
  const handleDownload = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "TableDownload",
      label: "Download",
    });
    // Show info toaster
    toastId.current = toast.info(
      <p>
        {t("Download initiated for")} {selections.dimensionsList[0].dimTitle}
      </p>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );

    const fetchPayload = wrapperDimDownloadRequest(selections, user);
    const fetchProps = {
      payload: fetchPayload,
    };
    const dimDownloadRequestPromise = dimDownloadRequest(fetchProps);
    dimDownloadRequestPromise
      .then((responseDate) => {
        //Initiate download file
        const fileName = responseDate.result.data.downloadFileName;
        const fileID = responseDate.result.data.file;
        const downloadUrl = `${user.apiEndpoints.baseUrl}/downloadFile?fid=${fileID}&fname=${fileName}`;

        // * Old implementation
        // This stopped working in new app server as the opened URL required authentication token
        // window.open doesn't allow sending headers (https://stackoverflow.com/questions/4325968/window-open-with-headers)
        // window.open(downloadUrl);

        // * New implementation
        // Fetching the required data from the download file url and then downloading via blob
        const fetchProps = {
          url: downloadUrl,
        };
        const downloadReportFilePromise = downloadReportFile(fetchProps);
        downloadReportFilePromise
          .then((blob) => {
            // Show success toaster
            toast.dismiss(toastId.current);
            toastId.current = toast.success(
              <p>
                {t("Download completed for")}{" "}
                {selections.dimensionsList[0].dimTitle}
              </p>,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            setTimeout(() => {
              toast.dismiss(toastId.current);
            }, 5000);
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
          })
          .catch((json) => {
            console.log("API FAILED", json);
            // Show failure toaster
            toast.dismiss(toastId.current);
            toastId.current = toast.error(
              <p>
                {t("Download failed for")}{" "}
                {selections.dimensionsList[0].dimTitle}! {json.error}
              </p>,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            setTimeout(() => {
              toast.dismiss(toastId.current);
            }, 5000);

            //Reload data even if it fails so that the user can see the refreshed history
            // reloadData("download");
          });

        //Reload data
        // reloadData("download");
      })
      .catch((json) => {
        // Show failure toaster
        toast.dismiss(toastId.current);
        toastId.current = toast.error(
          <p>
            {t("Download failed for")} {selections.dimensionsList[0].dimTitle}!{" "}
            {json.error}
          </p>,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 5000);
        console.log("API FAILED", json);
      });
  };
  const handleGoToFilters = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "TableGoToFilters",
      label: "GoToFilters",
    });

    // * Define required static variables
    const commonGlobalFilterProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: true,
      showMetricFilters: false,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      timeFilters: selections.timeFilters, //for initializing filters
      dimensionFilters: selections.dimensionFilters, //for initializing filters
      metricFilters: [], //for initializing filters
      settings: { isAdvancedFilterVisible: true, isExcludeAllowed: true },
      filtersDimData: allData.plotlyDimensions,
      filtersMetricsData: allData.plotlyMetrics,
      selections: {},
    };

    // * Define required event handlers
    const handleApplyFilters = (payload, filterType) => {
      switch (filterType) {
        case "dimensions":
          const actionPayload = {
            key: "dimensionFilters",
            value: payload.newDimensionFilters,
          };
          const action = updateStandaloneWsForm(actionPayload);
          ReduxDispatcher(action);
          var action_globalDimFilters =
            updateGlobalFiltersDimensionFilters(actionPayload);
          ReduxDispatcher(action_globalDimFilters);
          break;
        case "time":
          const actiontimeFilterPayload = {
            key: "timeFilters",
            value: payload,
          };
          const action1 = updateStandaloneWsForm(actiontimeFilterPayload);
          ReduxDispatcher(action1);
          var action_globalTimeFilters = updateGlobalFiltersTimeFilters(
            actiontimeFilterPayload
          );
          ReduxDispatcher(action_globalTimeFilters);
          break;
      }
    };

    // Open Global Filters
    const newGlobalFiltersProps = {
      ...commonGlobalFilterProps,
      activeDimensionFilter: selections.dimensionsList[0],
      handleApplyFilters,
    };
    ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
  };
  const handleGotoReports = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "TableGoToReports",
      label: "GoToReports",
    });
    const value = {
      isOpen: true,
      metadata: {
        variant: "gotoReport",
        selections: {
          timeFilters: selections.timeFilters,
          dimensionsList: selections.dimensionsList,
          metricsList: selections.metricsList,
          dimensionFilters: selections.dimensionFilters,
          //  TODO: change the title name
          // title: `Report from Analyze - ${selections.title}`,
          title: `Report from Analyze `,
        },
      },
    };
    const action = updateUserScreen("reportManager", value);
    ReduxDispatcher(action);
  };

  // * Define required static variables
  const {
    filterIconColor,
    isFullViewDisabled,
    fullViewTooltip,
    filterAppliedOnDimTable,
    filterIconTooltip,
  } = dimTableFilterDetails;
  const isDownloadAvailable = user.uiFeatureList.download;

  const globalStyle = {
    display: "flex",
    alignItems: "center",
  };

  const createCrudType =
    user.screen.activeWorkspace.wsCategory === "shared"
      ? true
      : user.screen.activeWorkspace.wsCategory !== "shared"
      ? user.screen.activeWorkspace.crudType !== "create"
      : false;

  return (
    <div className={classes.headerContainer} style={{ cursor: "move" }}>
      <div className={`${classes.leftHeader} dndClass`}>
        {filterAppliedOnDimTable && (
          <SigviewTooltip title={filterIconTooltip}>
            <Box>
              <SigviewIcon
                iconName="filter_alt"
                style={{
                  fontSize: "16px !important",
                  padding: "0px 0px 0px 0px",
                  color: filterIconColor,
                  hoverColor: filterIconColor,
                }}
              />
            </Box>
          </SigviewTooltip>
        )}

        <div
          style={{
            width: "calc(100% - 20px)",
          }}
        >
          <Box
            style={{
              marginLeft: "7px",
              width: "80%",
            }}
          >
            <SigviewTypography
              variant="pMediumLarge"
              title={selections?.dimensionsList[0]?.dimTitle}
              // style={{ height: "max-content" }}
              style={{
                height: "max-content",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "500",
                color: themeColors["secondaryColor"],
                cursor: isFullViewDisabled ? "initial" : "pointer",
              }}
              onClick={handleMsvFullView}
            >
              {selections?.dimensionsList[0]?.dimTitle}
            </SigviewTypography>
          </Box>
        </div>
      </div>
      <Box className={classes.dimTableMenuOptions}>
        <>
          {createCrudType && (
            <SigviewTooltip title={fullViewTooltip} placement="bottom">
              <Box css={globalStyle}>
                <SigviewIcon
                  iconName="open_in_full"
                  style={{
                    fontSize: "13px !important",
                    padding: "0px 7px 0px 0px",
                    color: themeColors["secondaryColorLight"],
                    hoverColor: themeColors["primaryColor"],
                    cursor: "pointer",
                  }}
                  onClick={handleMsvFullView}
                  disabled={isFullViewDisabled}
                  className={`material-icons-round analyzeTableFullView-GA`}
                />
              </Box>
            </SigviewTooltip>
          )}

          <SigviewTooltip title="Add Metrics" placement="bottom">
            <Box css={globalStyle}>
              <SigviewIcon
                iconName="add"
                style={{
                  fontSize: "18px !important",
                  padding: "0px 7px 0px 0px",
                  color: themeColors["secondaryColorLight"],
                  hoverColor: themeColors["primaryColor"],
                  cursor: "pointer",
                }}
                className={`material-icons-round analyzeTableAddMetrics-GA`}
                onClick={handleAddMetrics}
              />
            </Box>
          </SigviewTooltip>

          {isDownloadAvailable && createCrudType && (
            <SigviewTooltip title={"Download"} placement="bottom">
              <Box css={globalStyle}>
                <SigviewIcon
                  iconName="file_download"
                  style={{
                    fontSize: "16px !important",
                    padding: "1px 7px 0px 0px",
                    color: themeColors["secondaryColorLight"],
                    hoverColor: themeColors["primaryColor"],
                    cursor: "pointer",
                  }}
                  onClick={(e, selections) => {
                    handleDownload(e, selections);
                  }}
                  disabled={
                    dimensionTableDate.status === "loading" ||
                    dimensionTableDate.status === "error"
                      ? true
                      : false
                  }
                  className={`material-icons-round analyzeTableFileDownload-GA`}
                />
              </Box>
            </SigviewTooltip>
          )}

          <SigviewTooltip title="Go To Filters" placement="bottom">
            <Box css={globalStyle}>
              <SigviewIcon
                iconName="filter_alt"
                style={{
                  fontSize: "16px !important",
                  padding: "0px 7px 0px 0px",
                  color: themeColors["secondaryColorLight"],
                  hoverColor: themeColors["primaryColor"],
                  cursor: "pointer",
                }}
                onClick={handleGoToFilters}
                className={`material-icons-round analyzeTableGoToFilters-GA`}
              />
            </Box>
          </SigviewTooltip>

          <SigviewTooltip title="Go To Reports" placement="bottom">
            <Box css={globalStyle}>
              <SigviewIcon
                iconName="article"
                style={{
                  fontSize: "16px !important",
                  padding: "0px 7px 0px 0px",
                  color: themeColors["secondaryColorLight"],
                  hoverColor: themeColors["primaryColor"],
                  cursor: "pointer",
                }}
                onClick={handleGotoReports}
                className={`material-icons-round analyzeTableGoToReports-GA`}
              />
            </Box>
          </SigviewTooltip>
          {isDeleteVisible && (
            <SigviewTooltip title="Remove" placement="bottom">
              <Box css={globalStyle}>
                <SigviewIcon
                  iconName="close"
                  style={{
                    fontSize: "17px !important",
                    padding: "0px 7px 0px 0px",
                    color: themeColors["secondaryColorLight"],
                    hoverColor: themeColors["negChangeColor"],
                    cursor: "pointer",
                  }}
                  onClick={handleDelete}
                  className={`material-icons-round analyzeTableDelete-GA`}
                />
              </Box>
            </SigviewTooltip>
          )}
        </>
      </Box>
    </div>
  );
}

const mapStateToProps = (state) => ({
  allData: state.data,
  user: state.user,
  standaloneWs: state.standaloneWs,
});

export default connect(mapStateToProps)(WsDimHeader);
