// * Import required libraies
import React, { useState, useEffect, useReducer, useContext } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

// * Import lib components
import { Box } from "@material-ui/core";

// * Import Custom Component
import LayoutTopSideBottomAdmin from "../../../layouts/LayoutTopSideBottomAdmin/LayoutTopSideBottomAdmin";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import SigviewCheckList from "../../../components/SigviewChecklist/SigviewChecklist";
import SigviewTab from "../../../components/SigviewTab";
import LayoutAdminContent from "../../../layouts/LayoutAdminContent/LayoutAdminContent";
import LayoutRow from "../../../layouts/LayoutRow/LayoutRow";
import SigviewTextField from "../../../components/Common/SigviewTextField";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";

// * Import Context
import { ThemeContext } from "../../../contexts/ThemeContext";
import { UserProvider } from "../../../contexts/AdminContext";

// * Import utils, config & static data
import { unWrapperviews, defaultActiveView } from "../../../utils/adminUtils";
import { updateUserScreen } from "../../../redux/actions";
import useReducerLogger from "../../../utils/useReducerLogger";
import { userSelection as initialUserSelection } from "../../../utils/adminUtils";
import { config } from "../../../config/config";
import { getBreadcrumbsDataFromRoute } from "../../../utils/utils";
import { masterTrackGaEvent } from "../../../../js/services/ga/index";

// * Import action creators
// Reducers
import {
  updateAdminForm,
  replaceAdminForm,
  updateData,
} from "../../../redux/actions";
import { adminHome } from "../../../redux/reducers/admin";

// * Import APIs
import {
  readAdminByOrg,
  readAdminByOrgVeiwDetails,
} from "../../../services/api";
import { useHistory } from "react-router";

function Home(props) {
  const { t } = useTranslation();
  // * Destructure props
  const { dispatch: ReduxDispatcher, user = {}, allData = {} } = props;
  const { views: viewsData } = allData;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define contexts
  const history = useHistory();

  // * Define required states
  const [selections, dispatchSelections] = useReducer(
    useReducerLogger(adminHome),
    initialUserSelection
  );
  const [reloadViewsData, setReloadViewsData] = useState(Date.now());

  // * Define required side effects
  //Get Views Data (for admin)
  useEffect(() => {
    const runFlag = user.reqMetadata && allData.views.status !== "success";
    if (runFlag) {
      //Make fetch call using axios
      const source = axios.CancelToken.source();
      const fetchProps = {
        cancelToken: source.token,
      };

      const readAllAdminViewsPromise = readAdminByOrg(fetchProps);
      readAllAdminViewsPromise
        .then((data) => {
          let newData = {
            result: data.result.data,
            status: "success",
            messsage: "",
          };

          ReduxDispatcher(updateData("views", newData));
        })
        .catch((json) => {
          let newData = {
            result: [],
            status: "error",
            messsage: "Views API failed",
          };
          ReduxDispatcher(updateData("views", newData));
        });

      const readAllAdminViewsDetailsPromise =
        readAdminByOrgVeiwDetails(fetchProps);
      readAllAdminViewsDetailsPromise
        .then((data) => {
          let newData = {
            result: data.result.data,
            status: "success",
            messsage: "",
          };

          ReduxDispatcher(updateData("viewsDetails", newData));
        })
        .catch((json) => {
          let newData = {
            result: [],
            status: "error",
            messsage: "Views API failed",
          };
          ReduxDispatcher(updateData("viewsDetails", newData));
        });
    }
  }, [user.reqMetadata, reloadViewsData]);

  // Update selections
  useEffect(() => {
    if (viewsData.status === "success") {
      const viewsDataUi = unWrapperviews(viewsData.result);
      const defaultView = viewsDataUi[0];
      const newAdminForm = {
        name: {
          message: "",
          value: user?.reqMetadata?.organization,
          status: "valid",
        },
        activeView: {
          message: "",
          value: {
            id: defaultView.id,
            title: defaultView.name,
          },
          status: "valid",
        },
        activeTab: {
          message: "",
          value: {
            id: "accessList",
            title: "accessList",
          },
          status: "valid",
        },
      };
      const payload = { value: newAdminForm };
      const action = replaceAdminForm(payload);
      dispatchSelections(action);
    }
  }, [viewsData]);

  // * Define required event handlers
  const handleBreadcrumbChange = (event, value) => {
    history.push(value.path);
  };
  // For activenab and activetab
  const handleActiveTab = (e, id) => {
    //Return to home (will be controlled by router later)
    const activeTab = id;
    const activeNav = "admin"; //correct
    const value = { activeNav, activeTab };
    const action = updateUserScreen(null, value);
    ReduxDispatcher(action);
  };
  const handleViewChange = (e, value) => {
    const payload = {
      key: "activeView",
      value,
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleViewsDataReload = () => {
    setReloadViewsData(Date.now());
  };

  // * Define required static variables for props
  const activeTab = user.screen.activeTab;
  const breadcrumbsData = getBreadcrumbsDataFromRoute(user, history);
  const layoutTopSideBottomAdminProps = {
    activeTab,
    handleActiveTab,
  };
  const viewsDataUi = unWrapperviews(viewsData.result);
  const activeViewId = selections.activeView.value.id;
  const activeView =
    viewsDataUi.find((row) => row.id === activeViewId) || defaultActiveView;
  // Styling
  const layoutRowStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "50px",
    padding: "0px 25px",
  };
  const box1Style = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  };
  const box2Style = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  };
  const jpStyle1 = {
    fontSize: "12px",
    color: "#46596a",
    height: "25px",
    fontWeight: "400",
    letterSpacing: "0.0125em",
    lineHeight: "2",
    padding: "7px 0px 7px 15px",
    height: "max-content",
  };
  const jpStyle2 = {
    fontSize: "12px",
    color: "#46596a",
    height: "25px",
    fontWeight: "400",
    letterSpacing: "0.0125em",
    lineHeight: "2",
    padding: "7px 0px 7px 7px",
    height: "max-content",
  };
  const box3Style = {
    backgroundColor: `${themeColors["secondaryColorLightest"]} `,
    borderRadius: "3px 3px 0px 0px",
    // height: "100%",
    width: "100%",
  };
  const attributeTitleStyling = {
    height: "max-content",
    padding: "7px 0px 7px 15px",
  };
  const formTitleStyling = {
    height: "max-content",
    padding: "0px 10px 0px 0px",
  };

  const handelAttributeEdit = (item, activeAttributeType = "") => {
    masterTrackGaEvent({
      category: "Attribute",
      action: `Edit ${item.id}`,
      label: item.id,
    });

    console.log("item", item, activeAttributeType, viewsData);

    let filteredView = viewsData.result.find(
      (row) => row.id === selections.activeView.value.id
    );

    let activeAttribute = filteredView[activeAttributeType].find(
      (row) => row._id === item.id
    );

    console.log("activeAttribute", activeAttribute);

    //update screen
    const activeTab = "attributes";
    const newActiveAttribute = {
      payload: {
        ...activeAttribute,
        id: activeAttribute._id,
        name: activeAttribute.title,
      },
      metadata: {
        crudType: "update",
        activeAttribute: activeAttribute._id,
        activeAttributeType,
        activeView: selections.activeView.value.id,
      },
    };

    const value = { activeNav: "admin", activeTab };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);

    var action = updateUserScreen("newActiveAttribute", newActiveAttribute);
    ReduxDispatcher(action);

    const pathname = "/admin/attributes";
    history.push(pathname);
  };

  return (
    <LayoutTopSideBottomAdmin {...layoutTopSideBottomAdminProps}>
      <SigviewBreadcrumb
        data={breadcrumbsData} //data
        onClick={handleBreadcrumbChange} //onClick
      />
      <>
        {viewsData.status === "loading" && <Loader />}
        {viewsData.status === "error" && (
          <ErrorHandler
            message={viewsData.message || config.hardCoded.uiErrorMessage}
            reloadFlag={true}
            onReload={handleViewsDataReload}
          />
        )}
        {viewsData.status === "success" && (
          <>
            <LayoutRow style={layoutRowStyle}>
              <SigviewTypography style={formTitleStyling}>
                Organization:
              </SigviewTypography>
              <SigviewTextField
                value={selections.name.value}
                readOnly={true}
                width="200px"
              />
            </LayoutRow>

            <UserProvider value={"calc(100% - 35px)"}>
              <LayoutAdminContent
                fullHeight={"calc(100% - 100px)"}
                padding="0px 25px"
              >
                <Box css={box1Style}>
                  <SigviewTypography
                    style={{
                      padding: "10px",
                      height: "45px !important",
                      boxSizing: "border-box !important",
                    }}
                  >
                    Available Views
                  </SigviewTypography>
                  <SigviewTab
                    disabled={true}
                    data={viewsDataUi}
                    type="dataOnly"
                    activeView={selections.activeView.value}
                    handleActiveView={handleViewChange}
                  />
                </Box>

                <SigviewTypography
                  variant="pMedium"
                  style={{
                    color: `${themeColors["primaryColor"]} `,
                    padding: "10px 0px 15px 15px",
                    height: "25px !important",
                    boxSizing: "border-box",
                  }}
                >
                  {selections.activeView.value.id}
                </SigviewTypography>

                <Box css={box2Style}>
                  <Box css={box3Style}>
                    <span style={jpStyle1}>{t("Dimensions")}</span>
                    <span style={jpStyle2}>
                      ({activeView.DimensionData.length})
                    </span>
                  </Box>
                  <SigviewCheckList
                    disabled={true}
                    data={activeView.DimensionData}
                    type="dataOnly"
                    handelAttributeEdit={(value) => {
                      handelAttributeEdit(value, "dimensions");
                    }}
                    variant="editable"
                  />
                </Box>

                <Box css={box2Style}>
                  <Box css={box3Style}>
                    <span style={jpStyle1}>{t("Metrics")}</span>
                    <span style={jpStyle2}>
                      ({activeView.metricData.length})
                    </span>
                  </Box>
                  <SigviewCheckList
                    disabled={true}
                    data={activeView.metricData}
                    type="dataOnly"
                    handelAttributeEdit={(value) => {
                      handelAttributeEdit(value, "metrics");
                    }}
                    variant="editable"
                  />
                </Box>

                <Box css={box2Style}>
                  <Box css={box3Style}>
                    <span style={jpStyle1}>{t("Custom Metrics")}</span>
                    <span style={jpStyle2}>
                      ({activeView.customMetricData.length})
                    </span>
                  </Box>
                  <SigviewCheckList
                    disabled={true}
                    data={activeView.customMetricData}
                    type="dataOnly"
                    handelAttributeEdit={(value) => {
                      handelAttributeEdit(value, "customMetrics");
                    }}
                    variant="editable"
                  />
                </Box>
              </LayoutAdminContent>
            </UserProvider>
          </>
        )}
      </>
    </LayoutTopSideBottomAdmin>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(Home);
