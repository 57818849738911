// Import required libraries
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";

// Import custom components

// Import styles
import "./UserInfoAdmin.scss";

// Import config

// Import action creators
import { updateUserScreen, redirectToAngular } from "../../redux/actions";

// Import utils/data
import { navigateToAngular } from "../../utils/utils";

// Import utils & data

const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root ": {
      backgroundColor: "transparent !important",
    },
  },
});

function UserInfoAdmin(props) {
  // * Destructure props
  const { user = {}, dispatch: ReduxDispatcher } = props;

  const history = useHistory();

  // * Define required states
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const userEmailFirstChar = user?.reqMetadata?.email?.slice(0, 1) || "";

  // * Define required event handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleGoToDashboard = () => {
    history.push("/analyze/dashboard");
    window.location.reload();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div
        className="user-info-ball"
        onClick={(e) => {
          handleClick(e);
        }}
      >
        {userEmailFirstChar}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.root}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className={`user-info-pop-up-container`}>
          <div
            className={`pop-up-item-container`}
            onClick={handleGoToDashboard}
          >
            <p className={`pop-up-item-title`}>Go To The DashBoard</p>
            <i className={`pop-up-item-icon material-icons hidden`}>save</i>
          </div>
          {/* <div
            className={`pop-up-item-container`}
            onClick={() => {
              handleLogout();
            }}
          >
            <p className={`pop-up-item-title`}>Logout</p>
            <i className={`pop-up-item-icon material-icons hidden`}>save</i>
          </div> */}
        </div>
      </Popover>
    </>
  );
}

UserInfoAdmin.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(UserInfoAdmin);
