// * Import required libraies
import React, { useEffect, useContext, useMemo } from "react";
import isEqual from "lodash.isequal";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { v4 } from "uuid";

// * Import 3rd party lib comp
import { Box } from "@material-ui/core";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";

// * Import Custom Component
// Layouts
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import LayoutMenuBar from "../../layouts/LayoutMenuBar/LayoutMenuBar";
import LayoutAnalyzeDashboard from "../../layouts/LayoutAnalyzeDashboard";
import LayoutMetricChartsMenu from "../../layouts/LayoutMetricChartsMenu";
import LayoutDimTablesMenu from "../../layouts/LayoutDimTablesMenu";
// Common Kit
import SigviewButton from "../../components/Common/SigviewButton";
import SigviewButtonSplit from "../../components/Common/SigviewButtonSplit";
import SigviewTextFieldAsync from "../../components/Common/SigviewTextFieldAsync";
import SigviewChartTypeSingleSelect from "../../components/Common/SigviewChartTypeSingleSelect";
import SigviewSingleSelect from "../../components/Common/SigviewSingleSelect";
import SigviewTooltip from "../../components/Common/SigviewTooltip";
import SigviewIcon from "../../components/Common/SigviewIcon";
import SigviewTypography from "../../components/Common/SigviewTypography";
// Sigview Specific
import DimensionFilters from "../../components/DimensionFilters";
import TimeFilters from "../../components/TimeFilters/TimeFilters";
// MSV Specific
import AddMetricDrawer from "../../components/AddMetricDrawer/AddMetricDrawer";
import AddDimensionDrawer from "../../components/AddDimensionDrawer";
import MsvDimensionTableItem from "./MsvDimensionTableItem";
import MsvCalendarMetricChartItem from "./MsvCalendarMetricChartItem";
import MsvMetricChartItem from "./MsvMetricChartItem";
import { AddAttributeCard } from "../Analyze/AnalyzeDashboard/AnalyzeDashboardHelpers";

// * Import redux utils

import {
  selectDimTableSelections,
  selectDimTableFilterDetails,
  selectIsDimTableDeleteVisible,
} from "../../redux/selectors/standaloneWsSelectors";
// Actions
import {
  updateUserScreen,
  replaceAllDimensionFilters,
  updateStandaloneMsvDimensionTableMetrics,
  updateStandaloneMsvForm,
  updateStandaloneMsvFormMetricsChartsData,
  updateStandaloneMsvFormMetricsChartsDataWithFilter,
  updateStandaloneMsvFormMetricsData,
  updateStandaloneMsvMultipleDimensionsFromDimensionDrawer,
  updateStandaloneMsvMultipleMetrics,
  updateGlobalFiltersDimensionFilters,
  updateGlobalFiltersTimeFilters,
  updateGlobalFiltersRollingDateType,
  updateMetricChartGranularity,
} from "../../redux/actions";
// Reducers
import dimensionFiltersReducer from "../../redux/reducers/dimensionFilters";

// * Import data/utils
import {
  plotlyDimensionsToDimensionDwawer,
  plotlyMetricsToDimensionDwawer,
  makeMetricsChartDataConsistent,
  makeMetricsDataConsistent,
  makeMetricsChartDataConsistentMultiFilter,
  isSameDimFilterApplied,
  initialWsFormScreenValue,
  dispatchErrorForMetricsOnApiFailure,
  dispatchErrorForMetricsOnNoData,
  dispatchErrorForMetricChartsOnApiFailure,
  dispatchErrorForMetricChartsOnNoData,
  dispatchErrorForMsvMetricChartsOnApiFailure,
  dispatchErrorForMsvMetricChartsOnNoData,
  getAddMetricDrawerInitialSelectionsForMsv,
  areMultiItemsSelectedInMsv,
} from "../../utils/analyzeUtils";
import {
  getListOfMsvKeysForWhichStatusIsLoadingInDataQe,
  getListOfDimensionValuesForWhichGetDataCallIsRequired,
} from "../../utils/msvUtils";
import {
  getDimensionObjByID,
  getMeasureObjByOriginalID,
} from "../../utils/plotlyUtils";
import { wrapperChartObject } from "../../utils/chartObjectUtils";
import { getData } from "../../services/api";
import { config } from "../../config/config";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Import API functions
import { masterTrackGaEvent } from "../../services/ga";

function MSVContainer(props = {}) {
  // * Destructure props
  const {
    dispatch: ReduxDispatcher = () => {},
    user = {},
    allData = {},
    workspaceEventHandlers = {},
    standaloneMsv: msvForm,
  } = props;
  const { onMsvCancel = () => {}, onMsvApplyFilters = () => {} } =
    workspaceEventHandlers;

  // * Define required hooks
  const history = useHistory();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const id = msvForm.msvTable.value.id;
  const dimTableFilterDetails = useSelector(
    (state) => selectDimTableFilterDetails(state, id, themeColors),
    themeColors
  );

  // * Define required states

  // * Define required variables
  const dimensionFiltersSettings = {
    showTimeFiltersInGlobalFilters: true,
    isAdvancedFilterVisible: true,
    isExcludeAllowed: true,
    disabledItemsList: [msvForm.activeMsvTableId.value], // ["D044"] --> This needs to be a array of ids
  };

  // * Define required side effects
  // Fetch metrics charts data from API (all at once to optimize the number of fetch calls)
  useEffect(() => {
    // Make fetch call using axios
    const source = axios.CancelToken.source();
    const runFlag = Object.keys(msvForm.selectedKpis.value).length > 0;
    if (runFlag) {
      const metricsIds = Object.keys(msvForm.selectedKpis.value);
      const metricsList = metricsIds.map((metricId) =>
        getMeasureObjByOriginalID(metricId, allData.plotlyMetrics)
      );

      // Flag to decide if we need to make one API call for calendar (WsMetricChart)
      // Or Multiple API calls based on multi select
      const areMultiItemsSelectedInMsvFlag =
        areMultiItemsSelectedInMsv(msvForm);

      if (!areMultiItemsSelectedInMsvFlag) {
        try {
          const granularity = msvForm.metricChartGranularity.value;
          const granDimObject = getDimensionObjByID(
            granularity,
            allData.plotlyDimensions
          );
          let selections = {
            chartType: "chart",
            dimensionFilters: msvForm.dimensionFilters.value || [],
            timeFilters: msvForm.timeFilters.value || {},
            metricFilters: [], // HARD CODED
            dimensionsList: [granDimObject],
            metricsList: metricsList,
            orderById: granDimObject._id,
            orderBy: "asc",
            orderByType: "abs",
            percentCalList: [],
            granularity: granularity,
          };
          var payload = wrapperChartObject({ selections, user });
          // // ! Remove compareDate
          // delete payload["chartObject"]["requestParam"]["compareDate"];
          //Make fetch call using axios
          const fetchProps = {
            payload,
            cancelToken: source.token,
          };
          const getDataPromise = getData(fetchProps);

          getDataPromise
            .then((responseData) => {
              // * Update metrics chart data
              const makeMetricsChartDataConsistentProps = {
                data: responseData?.result?.data,
                dateRange: {
                  startDate:
                    msvForm.timeFilters.value.selectedDatesQE.startDate,
                  endDate: msvForm.timeFilters.value.selectedDatesQE.endDate,
                },
                allData,
                metricsList,
                granDimObject,
              };
              const { response, status } = makeMetricsChartDataConsistent(
                makeMetricsChartDataConsistentProps
              );
              console.log("response", response);
              console.log("status", status);
              // Update msvForm
              let payload = {
                metricsChartsDataFromQE: response,
                metricsChartsDataFromQEStatus: status,
              };
              let action = updateStandaloneMsvFormMetricsChartsData(payload);
              ReduxDispatcher(action);
            })
            .catch((json) => {
              if (json.error !== config.hardCoded.queryCancelled) {
                console.error("API FAILED");
                console.groupCollapsed("DETAILS");
                console.log(json);
                console.groupEnd();

                const isNoDataAvailableError =
                  json.error === config.hardCoded.noDataAvailableMessage; // TODO : To be changed based on sigview user type (aws vs openx)
                if (isNoDataAvailableError) {
                  // Update msvForm.dataQE to show error
                  const dispatchErrorForMetricChartsOnNoDataProps = {
                    metricsList,
                    wsForm: msvForm,
                    updateStandaloneWsFormMetricsChartsData:
                      updateStandaloneMsvFormMetricsChartsData,
                    ReduxDispatcher,
                  };
                  dispatchErrorForMetricChartsOnNoData(
                    dispatchErrorForMetricChartsOnNoDataProps
                  );
                } else {
                  // Update msvForm.dataQE to show error
                  const dispatchErrorForMetricChartsOnApiFailureProps = {
                    metricsList,
                    wsForm: msvForm,
                    updateStandaloneWsFormMetricsChartsData:
                      updateStandaloneMsvFormMetricsChartsData,
                    ReduxDispatcher,
                  };
                  dispatchErrorForMetricChartsOnApiFailure(
                    dispatchErrorForMetricChartsOnApiFailureProps
                  );
                }
              }
            });
        } catch (err) {
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log(err);
          console.groupEnd();
          // Update msvForm.dataQE to show error
          const dispatchErrorForMetricChartsOnApiFailureProps = {
            metricsList,
            wsForm: msvForm,
            updateStandaloneWsFormMetricsChartsData:
              updateStandaloneMsvFormMetricsChartsData,
            ReduxDispatcher,
          };
          dispatchErrorForMetricChartsOnApiFailure(
            dispatchErrorForMetricChartsOnApiFailureProps
          );
        }
      } else {
        // TODO : ADD TRY CATCH BLOCK POST A STABLE BUILD
        // ! Here, we make getData calls for rows that are clicked in the MSV table
        // * We need to take care of making the API calls for ONLY those values for which data is loading in dataQE
        // * to prevent unnecessary calls

        // * Define required variables
        var listOfMsvKeysForWhichStatusIsLoadingInDataQe =
          getListOfMsvKeysForWhichStatusIsLoadingInDataQe(msvForm);
        var listOfDimensionValuesForWhichGetDataCallIsRequired =
          getListOfDimensionValuesForWhichGetDataCallIsRequired(
            listOfMsvKeysForWhichStatusIsLoadingInDataQe
          );
        let activeMsvFilter = msvForm.dimensionFilters.value.find(
          (row) => row.id === msvForm.activeMsvTableId.value
        );
        let commonMsvFilter = msvForm.dimensionFilters.value.filter(
          (row) => row.id !== msvForm.activeMsvTableId.value
        );

        for (const filter of listOfDimensionValuesForWhichGetDataCallIsRequired) {
          // * Make Correct Dimension Filters
          let dimensionFilterMsvItem = {
            ...activeMsvFilter,
            values: [filter],
          };
          let dimensionFiltersValue = [
            ...commonMsvFilter,
            dimensionFilterMsvItem,
          ];
          const granularity = msvForm.metricChartGranularity.value;
          const granDimObject = getDimensionObjByID(
            granularity,
            allData.plotlyDimensions
          );
          let selections = {
            chartType: "chart",
            dimensionFilters: dimensionFiltersValue,
            timeFilters: msvForm.timeFilters.value || {},
            metricFilters: [], // HARD CODED
            dimensionsList: [granDimObject],
            metricsList: metricsList,
            orderById: granDimObject._id,
            orderBy: "asc",
            orderByType: "abs",
            percentCalList: [],
            granularity: granularity,
          };
          var payloadTemp = wrapperChartObject({ selections, user });
          // // ! Remove compareDate
          // delete payload["chartObject"]["requestParam"]["compareDate"];
          //Make fetch call using axios
          const fetchProps = {
            payload: payloadTemp,
            cancelToken: source.token,
          };
          const getDataPromise = getData(fetchProps);
          getDataPromise
            .then((responseData) => {
              // * Update metrics chart data
              const makeMetricsChartDataConsistentProps = {
                data: responseData?.result?.data,
                metricsList,
                granDimObject,
                filterName: filter,
              };
              const { response, status } =
                makeMetricsChartDataConsistentMultiFilter(
                  makeMetricsChartDataConsistentProps
                );
              console.log("response", response);
              console.log("status", status);
              // Update msvForm
              let payload = {
                metricsChartsDataFromQE: response,
                metricsChartsDataFromQEStatus: status,
              };
              let action =
                updateStandaloneMsvFormMetricsChartsDataWithFilter(payload);
              ReduxDispatcher(action);
            })
            .catch((json) => {
              if (json.error !== config.hardCoded.queryCancelled) {
                console.error("API FAILED");
                console.groupCollapsed("DETAILS");
                console.log(json);
                console.groupEnd();

                const isNoDataAvailableError =
                  json.error === config.hardCoded.noDataAvailableMessage; // TODO : To be changed based on sigview user type (aws vs openx)
                if (isNoDataAvailableError) {
                  // Update msvForm.dataQE to show error
                  const dispatchErrorForMsvMetricChartsOnNoDataProps = {
                    metricsList,
                    wsForm: msvForm,
                    updateStandaloneWsFormMetricsChartsData:
                      updateStandaloneMsvFormMetricsChartsDataWithFilter,
                    ReduxDispatcher,
                  };
                  dispatchErrorForMsvMetricChartsOnNoData(
                    dispatchErrorForMsvMetricChartsOnNoDataProps
                  );
                } else {
                  // Update msvForm.dataQE to show error
                  const dispatchErrorForMsvMetricChartsOnApiFailureProps = {
                    metricsList,
                    wsForm: msvForm,
                    updateStandaloneWsFormMetricsChartsData:
                      updateStandaloneMsvFormMetricsChartsDataWithFilter,
                    ReduxDispatcher,
                  };
                  dispatchErrorForMsvMetricChartsOnApiFailure(
                    dispatchErrorForMsvMetricChartsOnApiFailureProps
                  );
                }
              }
            });
        }
      }
    }
    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
    };
  }, [
    msvForm.reloadEpochs.value["allMetricCharts"],
    // ! Logic moved to standaloneWs using manipulateStateBasedOnTimeFiltersChange
    // msvForm.timeFilters.value,
    // msvForm.dimensionFilters.value,
    // msvForm.metricChartGranularity.value,
    // msvForm.reloadEpochs.value["metrics"],
    // msvForm.selectedKpis.value, // ! Triggering an unnecessary reload when a metric is deleted
  ]);

  // Fetch metrics data from API (all at once to optimize the number of fetch calls)
  useEffect(() => {
    // Make fetch call using axios
    const source = axios.CancelToken.source();
    const runFlag = Object.keys(msvForm.selectedKpis.value).length > 0;
    if (runFlag) {
      const metricsIds = Object.keys(msvForm.selectedKpis.value);
      const granularity = msvForm.metricChartGranularity.value;
      const metricsList = metricsIds.map((metricId) =>
        getMeasureObjByOriginalID(metricId, allData.plotlyMetrics)
      );

      try {
        let selections = {
          chartType: "chart",
          dimensionFilters: msvForm.dimensionFilters.value || [],
          timeFilters: msvForm.timeFilters.value || {},
          metricFilters: [], // HARD CODED
          dimensionsList: [], // HARD CODED
          metricsList: metricsList,
          orderById: metricsList[0]._id,
          orderBy: "asc",
          orderByType: "abs",
          percentCalList: [],
          granularity: granularity,
        };
        const payload = wrapperChartObject({ selections, user });
        //Make fetch call using axios
        const fetchProps = {
          payload,
          cancelToken: source.token,
        };
        const getDataPromise = getData(fetchProps);

        getDataPromise
          .then((responseData) => {
            // * Update metrics chart data
            const makeMetricsDataConsistentProps = {
              data: responseData?.result?.data,
              allData,
              // isComparisonOn: msvForm.timeFilters.value.isComparisonOn,
            };
            const { response, status } = makeMetricsDataConsistent(
              makeMetricsDataConsistentProps
            );
            console.log("status", status);
            // Update msvForm
            let payload = {
              metricsDataFromQE: response,
              metricsDataFromQEStatus: status,
            };
            let action = updateStandaloneMsvFormMetricsData(payload);
            ReduxDispatcher(action);
          })
          .catch((json) => {
            if (json.error !== config.hardCoded.queryCancelled) {
              console.error("API FAILED");
              console.groupCollapsed("DETAILS");
              console.log(json);
              console.groupEnd();

              const isNoDataAvailableError =
                json.error === config.hardCoded.noDataAvailableMessage; // TODO : To be changed based on sigview user type (aws vs openx)
              if (isNoDataAvailableError) {
                // Update wsForm.dataQE to show error
                const dispatchErrorForMetricsOnNoDataProps = {
                  metricsList,
                  updateStandaloneWsFormMetricsData:
                    updateStandaloneMsvFormMetricsData,
                  ReduxDispatcher,
                };
                dispatchErrorForMetricsOnNoData(
                  dispatchErrorForMetricsOnNoDataProps
                );
              } else {
                // Update msvForm.dataQE to show error
                const dispatchErrorForMetricsOnApiFailureProps = {
                  metricsList,
                  updateStandaloneWsFormMetricsData:
                    updateStandaloneMsvFormMetricsData,
                  ReduxDispatcher,
                };
                dispatchErrorForMetricsOnApiFailure(
                  dispatchErrorForMetricsOnApiFailureProps
                );
              }
            }
          });
      } catch (err) {
        console.error("UI ERROR");
        console.groupCollapsed("DETAILS");
        console.log(err);
        console.groupEnd();
        // Update msvForm.dataQE to show error
        const dispatchErrorForMetricsOnApiFailureProps = {
          metricsList,
          updateStandaloneWsFormMetricsData: updateStandaloneMsvFormMetricsData,
          ReduxDispatcher,
        };
        dispatchErrorForMetricsOnApiFailure(
          dispatchErrorForMetricsOnApiFailureProps
        );
      }
    }
    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
    };
  }, [
    msvForm.reloadEpochs.value["allMetrics"],
    // ! Logic moved to standaloneWs using manipulateStateBasedOnTimeFiltersChange
    // msvForm.timeFilters.value,
    // msvForm.dimensionFilters.value,
    // msvForm.reloadEpochs.value["metrics"],
    // msvForm.selectedKpis.value,
  ]);

  // * Define required event handlers
  const handleWsFormChange = (key, value) => {
    const payload = { key, value };
    const action = updateStandaloneMsvForm(payload);
    ReduxDispatcher(action);
    const payload1 = ("rollingDateType", { value: value });
    const action1 = updateGlobalFiltersRollingDateType(payload1);
    ReduxDispatcher(action1);
    if (key === "metricChartGranularity") {
      const payload = ("metricChartGranularity", { value: value });
      const action = updateMetricChartGranularity(payload);
      ReduxDispatcher(action);
    }
  };

  const handleDimensionFiltersChange = (value) => {
    handleWsFormChange("dimensionFilters", value);
    const payload = { key: "dimensionFilters", value };
    const action = updateGlobalFiltersDimensionFilters(payload);
    ReduxDispatcher(action);
  };

  const handleTimeFiltersChange = (value) => {
    handleWsFormChange("timeFilters", value);
    const payload = { value };
    const action = updateGlobalFiltersTimeFilters(payload);
    ReduxDispatcher(action);
  };

  const handleGoToPivot = () => {
    const activeMsvTableId = msvForm.activeMsvTableId.value;
    let activeMsvTableObj = msvForm.selectedDimensions.value[activeMsvTableId];

    const makingAdvanceSortSelection = {
      desc: activeMsvTableObj.orderBy === "desc" ? true : false,
      dimId: activeMsvTableId,
      sortType:
        activeMsvTableObj.orderById === "_dimension" ? "dimension" : "metric",
      sortedOn:
        activeMsvTableObj.orderById === "_dimension"
          ? activeMsvTableId
          : activeMsvTableObj.orderById,
    };
    const validDimensionFilterForMsv = (dimensionFilterArr) => {
      const finalDimensionList = dimensionFilterArr.filter(
        (el) => el.id !== activeMsvTableId
      );
      return finalDimensionList;
    };

    const chartObjectUI = {
      id: v4(),
      title: `Pivot from Msv - ${
        msvForm.name.value
      } ${new Date().getSeconds()}`,
      dimensionsList: activeMsvTableObj.dimensionsList,
      metricsList: activeMsvTableObj.metricsList,
      metricFilters: [],
      dimensionFilters: validDimensionFilterForMsv(
        msvForm.dimensionFilters.value
      ),
      timeFilters: msvForm.timeFilters.value,
      orderById: activeMsvTableObj.orderById,
      orderBy: activeMsvTableObj.orderBy,
      chartType: "pivotx",
      chartList: activeMsvTableObj.chartList,
      valid: true,
      progressiveDateFlag: msvForm.rollingDateType.value,
      advanceSort: [makingAdvanceSortSelection],
      percentCalList: [],
      renderFlag: false,
      transpose: false,
    };

    const userScreenValue = {
      activeTab: "createPivot",
      activePivot: {
        ...chartObjectUI,
        elementType: "update",
        renderFlag: true,
        transpose: false,
        dataLimit: msvForm.dataLimit.value,
        feature: "pivotx",
      },
    };
    var action = updateUserScreen(null, userScreenValue);
    ReduxDispatcher(action);
    history.push("/pivotx/create");
  };

  const handleAddFilters = () => {
    const handleApplyFilters = (payload, filterType) => {
      switch (filterType) {
        case "dimensions":
          var action = replaceAllDimensionFilters(payload);
          var newDimensionFilters = dimensionFiltersReducer(
            msvForm.dimensionFilters.value,
            action
          );
          handleDimensionFiltersChange(newDimensionFilters);
          break;
        // case "metrics":
        //   var action = replaceAllMetricFilters(payload);
        //   dispatchMetricFilters(action);
        //   break;
        case "time":
          handleTimeFiltersChange(payload);
          break;
      }
    };

    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Open Filters",
      label: "Sidenav",
    });
    const commonGlobalFilterProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: true,
      showMetricFilters: false,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      timeFilters: msvForm.timeFilters.value, //for initializing filters
      dimensionFilters: msvForm.dimensionFilters.value, //for initializing filters
      metricFilters: [], //for initializing filters
      settings: dimensionFiltersSettings,
      filtersDimData: allData.plotlyDimensions,
      filtersMetricsData: allData.plotlyMetrics,
      selections: {},
    };
    //Open Global Filters
    const newGlobalFiltersProps = {
      ...commonGlobalFilterProps,
      activeDimensionFilter: {},
      handleApplyFilters, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };

    const action = updateUserScreen("globalFilters", newGlobalFiltersProps);
    ReduxDispatcher(action);
  };

  const handleMsvApplyFilters = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ZoomedView",
      action: "ApplyFilters",
      label: "Zoomed View Apply Filters",
    });
    onMsvApplyFilters(msvForm, "applyFilters");
  };

  const handleMsvCancel = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ZoomedView",
      action: "CloseMSV",
      label: "Zoomed View Close",
    });
    onMsvCancel(msvForm, "cancel");
  };

  const handleShowAddMetricDrawer = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ZoomedView",
      action: "TableAddMetrics",
      label: "AddMetrics",
    });
    const newScreen = { ...initialWsFormScreenValue, metricDrawer: value };
    handleWsFormChange("screen", newScreen);
  };

  const handleShowAddDimensionDrawer = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ZoomedView",
      action: "TableAddDimensions",
      label: "AddDimensions",
    });
    const newScreen = { ...initialWsFormScreenValue, dimensionDrawer: value };
    handleWsFormChange("screen", newScreen);
  };

  const handleMetricDrawerSubmit = (event, selections = "") => {
    console.log("SELECTIONS AFTER DONE CLICKED:", selections);

    // * NEW IMPLEMENTATION
    // * WHERE ALL DIMENSION TABLES HAVE DIFFERENT METRICS
    // CHART METRICS
    const oldSelectedChartMetrics = Object.keys(msvForm.selectedKpis.value);
    const newSelectedChartMetrics = selections.chartMetrics;
    const areChartMetricsEqual = isEqual(
      oldSelectedChartMetrics,
      newSelectedChartMetrics
    );
    if (!areChartMetricsEqual) {
      let payload = {
        selections,
        plotlyMetrics: allData.plotlyMetrics,
      };
      let action = updateStandaloneMsvMultipleMetrics(payload);
      ReduxDispatcher(action);
    }

    // DIMENSION METRICS
    // Trigger only if the metrics list is different
    const oldSelectedTableMetrics =
      getAddMetricDrawerInitialSelectionsForMsv(msvForm).tableMetrics;
    const newSelectedTableMetrics = selections["tableMetrics"];
    const tableMetricsEqual = isEqual(
      oldSelectedTableMetrics,
      newSelectedTableMetrics
    );
    if (!tableMetricsEqual) {
      let payload = {
        oldSelectedTableMetrics,
        newSelectedTableMetrics,
        plotlyMetrics: allData.plotlyMetrics,
      };
      let action = updateStandaloneMsvDimensionTableMetrics(payload);
      ReduxDispatcher(action);
    }

    // ! OLD IMPLEMENTATION
    // ! WHERE ALL DIMENSION TABLES HAD COMMON METRICS
    // const oldSelectedTableMetrics = msvForm.selectedDimTableMetrics.value.map(
    //   (row) => row._id
    // );
    // const oldSelectedChartMetrics = [];

    // for (const key in msvForm.selectedKpis.value) {
    //   oldSelectedChartMetrics.push(key);
    // }
    // const newSelectedChartMetrics = selections.chartMetrics;
    // const newSelectedTableMetrics = selections.tableMetrics;
    // const areChartMetricsEqual = isEqual(
    //   oldSelectedChartMetrics,
    //   newSelectedChartMetrics
    // );
    // const areTableMetricsEqual = isEqual(
    //   oldSelectedTableMetrics,
    //   newSelectedTableMetrics
    // );

    // if (!areChartMetricsEqual) {
    //   let payload = {
    //     selections,
    //     plotlyMetrics: allData.plotlyMetrics,
    //   };
    //   let action = updateStandaloneMsvMultipleMetrics(payload);
    //   ReduxDispatcher(action);
    // }

    // if (!areTableMetricsEqual) {
    //   let payload = {
    //     selections,
    //     plotlyMetrics: allData.plotlyMetrics,
    //   };
    //   let action = updateStandaloneMsvMultipleMetricsTable(payload);
    //   ReduxDispatcher(action);
    // }
  };

  const handleDimensionDrawerSubmit = (selections = {}) => {
    // * NEW IMPLEMENTATION
    // SAMPLE SELECTIONS OBJECT
    // selections = [{ id: "D001", metrics: ["M001", "M002"] }];
    let payload = {
      selections,
      plotlyDimensions: allData.plotlyDimensions,
      plotlyMetrics: allData.plotlyMetrics,
    };
    let action =
      updateStandaloneMsvMultipleDimensionsFromDimensionDrawer(payload);
    ReduxDispatcher(action);

    // ! OLD IMPLEMENTATION
    // const oldSelectedDimTableMetrics = msvForm.selectedDimTableMetrics.value.map(
    //   (row) => row._id
    // );
    // const newSelectedDimTableMetrics = selections.selectedMetric;
    // const areMetricsEqual = isEqual(
    //   oldSelectedDimTableMetrics,
    //   newSelectedDimTableMetrics
    // );
    // if (areMetricsEqual) {
    //   let payload = {
    //     selections,
    //     plotlyDimensions: allData.plotlyDimensions,
    //   };
    //   let action = updateStandaloneMsvMultipleDimensions(payload);
    //   ReduxDispatcher(action);
    // } else {
    //   let payload = {
    //     selections,
    //     plotlyDimensions: allData.plotlyDimensions,
    //     plotlyMetrics: allData.plotlyMetrics,
    //   };
    //   let action = updateStandaloneMsvMultipleDimensionsAndMetrics(payload);
    //   ReduxDispatcher(action);
    // }
  };

  const handleKpisLayoutChange = (...args) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ZoomedView",
      action: "ChartRearrange",
      label: "1",
    });

    const [sourceId, sourceIndex, targetIndex, targetId] = args;
    const items = msvForm.layout.value.kpis;
    var kpisValue = swap(items, sourceIndex, targetIndex);
    kpisValue = kpisValue.filter((row) => row !== undefined);
    const value = {
      dimensions: msvForm.layout.value.dimensions,
      kpis: kpisValue,
    };
    handleWsFormChange("layout", value);
  };

  // * Define required static variables
  const additionalSidenavItems = [
    {
      id: "filters",
      name: "Filters",
      tooltip: "Open filters",
      elementId: "filtersInSidenav",
      iconId: "custom-filter-icon",
      onClick: (user) => handleAddFilters(),
      isVisible: true,
    },
    {
      id: "metricCharts",
      name: "Metrics",
      tooltip: "Add Metric Charts",
      elementId: "metricChartsInSidenav",
      iconId: "custom-metricCharts-icon",
      onClick: (user) => handleShowAddMetricDrawer(true),
      isVisible: true,
    },
    {
      id: "dimensionTable",
      name: "Dimensions",
      tooltip: "Add Dimension Tables",
      elementId: "dimensionTablesInSidenav",
      iconId: "custom-dimensionTables-icon",
      onClick: (user) => handleShowAddDimensionDrawer(true),
      isVisible: true,
    },
    // {
    //   id: "wsShare",
    //   name: "Share",
    //   tooltip: "Share",
    //   elementId: "wsShareInSidenav",
    //   iconId: "custom-share-icon",
    //   onClick: (user) => {
    //     alert("Share Clicked");
    //   },
    //   isVisible: true,
    // },
  ];
  const sidenavItemsOrderInfo = {
    filters: 1,
    metricCharts: 2,
    dimensionTable: 3,
    reports: 4,
    alerts: 5,
  };
  const sidenavProps = { additionalSidenavItems, sidenavItemsOrderInfo };
  const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarDashboard;
  var chartTypeData = [
    { id: "line", name: "Line Chart", disabled: false },
    { id: "bar", name: "Bar Chart", disabled: false },
    { id: "area", name: "Area Chart", disabled: false },
  ];
  if (areMultiItemsSelectedInMsv(msvForm)) {
    var chartTypeData = [
      { id: "multiline", name: "Multi line Chart", disabled: false },
      { id: "groupedbar", name: "Grouped bar Chart", disabled: false },
      {
        id: "verticalstackedbar",
        name: "vertical stacked bar Chart",
        disabled: false,
      },
    ];
  }
  let menuBarSplitButtonOptions = [];
  const timeFiltersSettings = {
    compareCalendarRowLimit: 3, //hard coded; it will come from /getUserAccessList
    isMultiCompareRowAllowed: true, //hard coded; it will come from /getUserAccessList
    isComparisonAvailable: true, //hard coded; it should come from where this component is being called
    isChangeTypeDropdownAvailable: true,
    isApplyButtonAvailable: false,
    isRollingDatesAvailable: true,
  };
  const rollingDatesProps = {
    value: msvForm.rollingDateType.value,
    onChange: (value) => handleWsFormChange("rollingDateType", value),
  };
  const isSameDimFilterAppliedFlag = isSameDimFilterApplied(msvForm);
  const isAnyDimFiltersApplied = msvForm.dimensionFilters.value.length > 0;
  const dataLimitData = config.hardCoded.defaultDataLimitData; // Can be configured from backend
  const addMetricDrawerInitialSelections =
    getAddMetricDrawerInitialSelectionsForMsv(msvForm);
  const filteredPlotlyDimensions = allData.plotlyDimensions.filter(
    (row) => row._id === msvForm.activeMsvTableId.value
  );
  const addDimensionDrawerData = {
    dimData: plotlyDimensionsToDimensionDwawer(filteredPlotlyDimensions),
    metricData: plotlyMetricsToDimensionDwawer(allData.plotlyMetrics),
  };
  const addDimensionDrawerInitialSelections = [
    msvForm.selectedDimensions.value[msvForm.activeMsvTableId.value],
  ];
  const isChartTypeAvailable = user.uiFeatureList.chartType;
  const textFieldCustomStyle = {
    color: "#46596A",
    width: "100%",
    border: "none",
    height: "100%",
    outline: "none !important",
    padding: "5px 0px 0px 0px",
    overflow: "hidden",
    fontSize: "16px !important",
    boxSizing: "border-box",
    fontFamily: "inherit",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    pointerEvents: "auto",
    cursor: "not-allowed",
    backgroundColor: "transparent",
    fontWeight: "500",
  };
  const isApplyFiltersDisabled = !areMultiItemsSelectedInMsv(msvForm);
  const layoutKpisMemoized = useMemo(
    () => msvForm.layout.value.kpis,
    [msvForm.layout.value.kpis]
  );
  // * Define required static variables
  const {
    filterIconColor,
    isFullViewDisabled,
    fullViewTooltip,
    filterAppliedOnDimTable,
    filterIconTooltip,
  } = dimTableFilterDetails;

  //* hide add new dimension/metric card from dashboard when all dimensions are selected */
  const mapSelectedMetrics = msvForm.selectedKpis.value;
  const selectedMetricsArray = Object.values(mapSelectedMetrics);

  return (
    <LayoutTopSideBottom sidenavProps={sidenavProps}>
      <LayoutAnalyzeDashboard style={{ rootHeight: "initial" }}>
        {/* Menu Bar */}
        <LayoutMenuBar>
          <SigviewTooltip title="Operation Not Allowed" placement="bottom">
            <Box>
              <SigviewTypography variant="pLarger" style={textFieldCustomStyle}>
                {msvForm.name.value}
              </SigviewTypography>
            </Box>
          </SigviewTooltip>

          <TimeFilters
            user={user}
            allData={allData}
            initialTimeFilters={msvForm.timeFilters.value}
            onChange={handleTimeFiltersChange}
            googleAnalytics={{
              category: "DatastoryDashboardPage",
            }}
            calendarDaysLimits={calendarDaysLimits}
            settings={timeFiltersSettings}
            rollingDatesProps={rollingDatesProps}
          />
          <>
            {menuBarSplitButtonOptions.length > 1 && (
              <SigviewButtonSplit
                options={menuBarSplitButtonOptions}
                customClassName="WorkSpace-GA"
              />
            )}
            {menuBarSplitButtonOptions.length === 1 && (
              <SigviewButton
                id={menuBarSplitButtonOptions[0]["id"]}
                title={menuBarSplitButtonOptions[0]["label"]}
                disabled={menuBarSplitButtonOptions[0]["disabled"]}
                onClick={menuBarSplitButtonOptions[0]["onClick"]}
                customClassName="WorkSpace-GA"
              />
            )}
          </>
        </LayoutMenuBar>

        {/* Dimension Filters */}
        {isAnyDimFiltersApplied ? (
          <DimensionFilters
            initialDimensionFilters={msvForm.dimensionFilters.value}
            initialTimeFilters={msvForm.timeFilters.value}
            onTimeFiltersChange={handleTimeFiltersChange}
            onDimensionFiltersChange={handleDimensionFiltersChange}
            settings={dimensionFiltersSettings}
          />
        ) : (
          <></> // ADDING THIS BECAUSE LAYOUT NEEDS SOMETHING IN THAT INDEX
        )}

        {/* METRIC CHARTS MENU */}
        <LayoutMetricChartsMenu showTitle={false}>
          {/* CHART TYPE */}
          {/* WITH TOOLTIP - THIS CREATES A BUG */}
          {/* <Box>
            <SigviewTooltip title="Chart Type">
              <Box>
                <SigviewChartTypeSingleSelect
                  data={chartTypeData}
                  value={msvForm.metricChartType.value}
                  onChange={(value) =>
                    handleWsFormChange("metricChartType", value)
                  }
                />
              </Box>
            </SigviewTooltip>
          </Box> */}

          {/* WITHOUT TOOLTIP - IF TITLE IS PRESENT */}
          <Box>
            {isChartTypeAvailable && (
              <SigviewChartTypeSingleSelect
                data={chartTypeData}
                value={msvForm.metricChartType.value}
                onChange={(value) =>
                  handleWsFormChange("metricChartType", value)
                }
                style={{ border: "none", fontSize: "11px" }}
              />
            )}
          </Box>

          {/* COMPARE LEGEND */}
          <></>

          {/* WITH TOOLTIP - THIS CREATES A BUG */}
          {/* GRANULARITY DROPDOWN */}
          {/* <Box>
            <SigviewTooltip title="Granularity">
              <Box>
                <SigviewSingleSelect
                  value={msvForm.metricChartGranularity.value}
                  data={msvForm.metricGranData.value}
                  onChange={(value) => {
                    handleWsFormChange("metricChartGranularity", value);
                  }}
                  minWidth="60px"
                  // margin={{ right: "15px" }}
                  border="none"
                />
              </Box>
            </SigviewTooltip>
          </Box> */}

          {/* WITHOUT TOOLTIP - IF TITLE IS PRESENT */}
          <Box>
            <SigviewSingleSelect
              value={msvForm.metricChartGranularity.value}
              data={msvForm.metricGranData.value}
              onChange={(value) => {
                handleWsFormChange("metricChartGranularity", value);
              }}
              minWidth="60px"
              fontSize="11px"
              // margin={{ right: "15px" }}
              border="none"
              customClassName="Granularity-GA"
            />
          </Box>
        </LayoutMetricChartsMenu>

        {/* DIMENSION TABLES MENU */}
        <LayoutDimTablesMenu showTitle={false} variant="msv">
          <>
            {/* WITHOUT TOOLTIP */}
            {/* <SigviewTypography
              variant="pSmall"
              style={{
                height: "max-content",
                margin: "0px 3px 0px 3px !important",
              }}
            >
              Row Count:
            </SigviewTypography>
            <SigviewSingleSelect
              minWidth={"50px"}
              maxWidth={"50px"}
              value={msvForm.dataLimit.value}
              data={dataLimitData}
              onChange={(newValue) => handleWsFormChange("dataLimit", newValue)}
              border="none"
              // margin={{ left: "10px", right: "10px" }}
            /> */}
            {/* WITH TOOLTIP - WIHTOUT TITLE */}
            {filterAppliedOnDimTable && (
              <SigviewTooltip title="Filter">
                <Box>
                  <SigviewIcon
                    iconName="filter_alt"
                    style={{
                      fontSize: "16px !important",
                      padding: "0px 0px 0px 0px",
                      color: themeColors["primaryColor"],
                      hoverColor: themeColors["primaryColor"],
                    }}
                  />
                </Box>
              </SigviewTooltip>
            )}
            <Box
              style={{
                marginLeft: "7px",
                borderRight: "15px solid var(--mainContentBgColor)",
                marginRight: "7px",
                display: "flex",
                alignItems: "center",
                height: "25px",
              }}
            >
              <Box
                style={{
                  height: "max-content",
                  width: "max-content",
                  display: "inline-block",
                }}
              >
                <SigviewTypography
                  variant="pMediumLarge"
                  title={msvForm.msvTable.value.dimensionsList[0].dimTitle}
                  // style={{ height: "max-content" }}
                  style={{
                    height: "max-content",
                    width: "max-content",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "default",
                    color: themeColors["secondaryColor"],
                    hoverColor: themeColors["secondaryColor"],
                    fontWeight: "500",
                  }}
                >
                  {msvForm.msvTable.value.dimensionsList[0].dimTitle}
                </SigviewTypography>
              </Box>
              <Box
                style={{
                  height: "max-content",
                  width: "max-content",
                  display: "inline-block",
                }}
              >
                <SigviewTooltip
                  title="Selected and non-selected values are sorted separately"
                  placement="bottom"
                >
                  <span>
                    <SigviewIcon
                      className="material-icons"
                      iconName="info"
                      style={{
                        padding: "0px 10px",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </SigviewTooltip>
              </Box>
            </Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <SigviewTypography
                variant="pSmallMedium"
                style={{
                  height: "max-content",
                  margin: "0px 3px 0px 3px !important",
                  width: "60px",
                }}
              >
                Row Count:
              </SigviewTypography>
              <SigviewTooltip title="Row Count">
                <Box>
                  <SigviewSingleSelect
                    minWidth={"50px"}
                    maxWidth={"50px"}
                    value={msvForm.dataLimit.value}
                    data={dataLimitData}
                    onChange={(newValue) =>
                      handleWsFormChange("dataLimit", newValue)
                    }
                    border="none"
                    customClassName="MSVTableRowCount-GA"
                  />
                </Box>
              </SigviewTooltip>
            </Box>
          </>
          <></>
          <>
            <SigviewButton
              customClassName="MSVClose-GA"
              title="Cancel"
              variant="outlinedPrimary"
              onClick={handleMsvCancel}
            />
            <SigviewButton
              customClassName="MSVGoToPivot-GA"
              title="Go To Pivot"
              variant="containedPrimary"
              style={{ margin: { left: "10px" } }}
              onClick={handleGoToPivot}
            />
            <SigviewTooltip
              title={isApplyFiltersDisabled ? "Select a row to enable" : ""}
            >
              <Box>
                <SigviewButton
                  customClassName="MSVApplyFilter-GA"
                  title="Apply Filters"
                  variant="containedPrimary"
                  style={{ margin: { left: "10px" } }}
                  onClick={handleMsvApplyFilters}
                  disabled={isApplyFiltersDisabled}
                />
              </Box>
            </SigviewTooltip>
          </>
        </LayoutDimTablesMenu>

        <GridContextProvider onChange={handleKpisLayoutChange}>
          <Box className="ws-react-dnd-container">
            <GridDropZone
              className="ws-react-dnd-dropzone workspace-kpis"
              id="kpis2"
              boxesPerRow={1}
              rowHeight={180}
            >
              {layoutKpisMemoized.map((item) => {
                return (
                  <GridItem key={item.id}>
                    <Box className="ws-react-dnd-grid-item">
                      {!isSameDimFilterAppliedFlag && (
                        <MsvCalendarMetricChartItem id={item.id} />
                      )}
                      {isSameDimFilterAppliedFlag && (
                        <MsvMetricChartItem id={item.id} />
                      )}
                    </Box>
                  </GridItem>
                );
              })}
              {addDimensionDrawerData.metricData.length !==
                selectedMetricsArray.length && (
                <GridItem key="Add Metric Card">
                  <AddAttributeCard
                    themeColors={themeColors}
                    onClick={() => handleShowAddMetricDrawer(true)}
                  />
                </GridItem>
              )}
            </GridDropZone>
          </Box>
        </GridContextProvider>

        <MsvDimensionTableItem id={msvForm.activeMsvTableId.value} />
      </LayoutAnalyzeDashboard>

      <AddMetricDrawer
        data={allData.plotlyMetrics}
        user={user}
        onCloseHandler={handleShowAddMetricDrawer}
        initialSelections={addMetricDrawerInitialSelections}
        isOpen={msvForm.screen.value.metricDrawer}
        onChange={handleMetricDrawerSubmit}
      />
      <AddDimensionDrawer
        data={addDimensionDrawerData}
        initialSelections={addDimensionDrawerInitialSelections}
        onChange={handleDimensionDrawerSubmit}
        isOpen={msvForm.screen.value.dimensionDrawer}
        onCloseHandler={handleShowAddDimensionDrawer}
        // ! Hard Coding : By default, the first active msv id should be open whenever the drawer opens
        dimOpenByDefault={{ [msvForm.activeMsvTableId.value]: true }} // Should be object like this ---> { D017: true }
      />
    </LayoutTopSideBottom>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  standaloneMsv: state.standaloneMsv,
});

export default connect(mapStateToProps)(MSVContainer);
