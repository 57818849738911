//import custom component
import CardGrid from "../CardGrid";

function CardChart(props) {
  const { payload, classes, datastoryStateTitleClass } = props;
  const { bgIMG, isSelected, title, moreOptions, tag, onClick } = payload;
  const { flag, icon: moreOptionsIcon } = moreOptions;
  const isMoreOptionsIconPresent = flag;

  const extraStyles = {
    cardImgContainerStyles: {
      backgroundImage: `url(${bgIMG})`,
      height: "100%",
      width: "100%",
      backgroundSize: "contain",
    },
    cardImgWithIconContainerStyles: {
      backgroundImage: `url(${bgIMG})`,
      height: "60px",
      width: "60px",
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
    cardTitleContainerStyles: {
      padding: `${
        isMoreOptionsIconPresent ? "6px 6px 6px 10px" : "6px 10px 6px 10px"
      }`,
    },
    cardTitleStyles: {
      width: `${isMoreOptionsIconPresent ? "145px" : "100%"}`,
    },
  };
  const {
    cardImgContainerStyles,
    cardTitleContainerStyles,
    cardTitleStyles,
    cardImgWithIconContainerStyles,
  } = extraStyles;

  // Defining required event handlers
  const handleClick = (e) => onClick(e, props);

  //Defining static variables
  let cardBackground;

  // switch case which is checking witch component will mount
  switch (tag) {
    case "chart":
      cardBackground = (
        <div
          className={`${datastoryStateTitleClass}-GA card-image-container`}
          style={cardImgContainerStyles}
        ></div>
      );
      break;
    case "chartWithIcon":
      cardBackground = (
        <div
          className={`${datastoryStateTitleClass}-GA card-image-container`}
          style={cardImgWithIconContainerStyles}
        ></div>
      );
      break;
    case "gridChart":
      const { gridData } = props;
      cardBackground = (
        <div className={`${datastoryStateTitleClass}-GA card-image-container`}>
          <CardGrid gridData={gridData} width={180} height={92} />
        </div>
      );
      break;
    default:
      cardBackground = <></>;
      console.log("somethings went wrong");
      break;
  }

  return (
    <>
      <article
        className={`${classes.card} ${datastoryStateTitleClass}-GA`}
        onClick={handleClick}
      >
        <div
          className={`${datastoryStateTitleClass}-GA card-image-body-container`}
        >
          {cardBackground}
          {isSelected && (
            <div className="card-overlay-container">
              <div className="card-tick-icon-container">
                <span className="material-icons">check_circle</span>
              </div>
            </div>
          )}
        </div>
        <div
          className={`${datastoryStateTitleClass}-GA card-title-container`}
          style={cardTitleContainerStyles}
        >
          <p
            className={`${datastoryStateTitleClass}-GA card-title`}
            style={cardTitleStyles}
            title={title}
          >
            {title}
          </p>
          {flag && (
            <div className="more-option-icon-container">
              <span className={moreOptionsIcon.className}>
                {moreOptionsIcon.value}
              </span>
            </div>
          )}
        </div>
      </article>
    </>
  );
}

export default CardChart;
