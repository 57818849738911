// Import required libraies
import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

// * Import 3rd party lib
import { Box } from "@material-ui/core";

// Import Custom Component
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import AppRow from "../../../components/AppRow";
import AppMenuBar from "../../../components/AppMenuBar";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import Loader from "../../../components/Loader/Loader";

// Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// Import utils/data
import {
  unwrapperAppRow,
  capitalize,
  getBreadcrumbsDataFromRoute,
} from "../../../utils/utils";
import { masterTrackGaEvent } from "../../../services/ga";

// Import config
import { config } from "../../../config/config";

// Import action creators
import { updateUserScreen } from "../../../redux/actions";

// Import styles

// Import API functions
import { getDatastories } from "../../../services/api";

// Defining required static variables
const initialData = {
  status: "loading",
  message: "",
  result: [],
};

function DatastoryCat(props) {
  const { t } = useTranslation();
  const { dispatch: ReduxDispatcher, user, allData } = props;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const [data, setData] = useState(initialData);
  const [reload, setReload] = useState(false);
  const [searchField, setSearchField] = useState("");

  // * Define required hooks
  const history = useHistory();

  // Defining required static variables
  // Should come from params later
  const datastoryActiveCategory = user.screen.activeDsCategory;
  const datastoryActiveCategoryCap = capitalize(datastoryActiveCategory);
  const apiKey = `datastory${datastoryActiveCategoryCap}`;
  const trackItemLaunchGA = (label) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryCategory",
      action: "Launch",
      label,
    });
  };

  // Defining required change/click handlers
  const handleSearchFieldChange = (event, value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryCategory",
      action: "Search",
      label: `${datastoryActiveCategoryCap}DS`,
    });
    setSearchField(value);
  };
  const handleReload = () => setReload(!reload);
  const handleDsClick = (value) => {
    trackItemLaunchGA(`${datastoryActiveCategoryCap}DS`);

    const { actualPayload } = value;
    // Update activeChart in redux and screen so that user can be redirected to chart
    const userScreenValue = {
      activeNav: "datastory",
      activeTab: "dashboard",
      activeDatastory: {
        ...actualPayload,
        dsCategory: datastoryActiveCategoryCap,
      },
    };
    var action = updateUserScreen(null, userScreenValue);
    ReduxDispatcher(action);
    history.push("/datastory/dashboard");
  };
  const handleAddNewDsClick = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryCategory",
      action: "CreateNew",
      label: "Datastory",
    });
    const newScreenData = {
      activeNav: "datastory",
      activeTab: "create",
      activeDatastory: {
        elementType: "create",
        title: `Untitled Datastory - ${data.result.length + 1}`,
      },
    };
    const action = updateUserScreen(null, newScreenData);
    ReduxDispatcher(action);
    history.push("/datastory/dashboard/create");
  };
  const handleBreadcrumbChange = (event, value) => {
    const data = { activeNav: "datastory", activeTab: value };
    const action = updateUserScreen(null, data);
    ReduxDispatcher(action);
    history.push(value.path);
  };

  // Defining required side effects
  //Fetch category data
  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();
    const runFlag = true;
    if (runFlag) {
      const fetchProps = {
        url: `${user.apiEndpoints.baseUrl}${config.apiEndpoints[apiKey]}/${user.reqMetadata.organization}/${user.reqMetadata.view}`,
        cancelToken: source.token,
      };
      const getDatastoriesPromise = getDatastories(fetchProps);
      getDatastoriesPromise
        .then((responseDate) => {
          const newData = {
            status: "success",
            message: "",
            result: responseDate.result.data,
          };
          setData(newData);
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            console.groupCollapsed("UI ERROR");
            console.log("ERROR -> ", json.error);
            console.groupEnd();
            const newData = {
              status: "error",
              message: json.error,
              result: [],
            };
            setData(newData);
          }
        });
    }
    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
      setData(initialData);
    };
  }, [reload]); //dependency array to have view and organization

  // // DEBUGGER
  // useEffect(() => {
  //   console.groupCollapsed(
  //     "%cRENDERED | DatastoryCat.js",
  //     "color: yellow; font-size: 15px"
  //   );
  //   console.log("data -> ", data);
  //   console.groupEnd();
  // });

  // Defining required static variables
  const pageTitle = `${datastoryActiveCategoryCap} Datastory`;
  const filteredData = data.result.filter((row) =>
    row.title.toLowerCase().includes(searchField.toLowerCase())
  );
  const appRowProps = {
    title: pageTitle,
    payload: {},
    data: filteredData,
    noCardProps: {
      title: "No Charts Available",
      flag: false,
    },
    createNewCardFlag: false,
    singleRowFlag: false,
    titleRowFlag: false,
    buttonRowFlag: true,
    onAppCardClick: (event, value) => {
      handleDsClick(value);
    },
    appCardTag: "gridChart",
  };
  const uiFriendlyData = unwrapperAppRow(appRowProps);
  const buttonProps = {
    handleClick: handleAddNewDsClick,
    title: "Create Datastory",
  };
  const appMenuBarProps = {
    buttonProps,
    onSearchFieldChange: handleSearchFieldChange,
  };
  const stickyStyles = {
    position: "sticky",
    top: "0px",
    zIndex: 1,
  };
  const breadcrumbData = getBreadcrumbsDataFromRoute(user, history);

  const handleGoHome = (user) => {
    history.push("/datastory");
  };

  const handleAddFilters = () => {
    const handleApplyFilters = (payload, filterType) => {
      // switch (filterType) {
      //   case "dimensions":
      //     var action = replaceAllDimensionFilters(payload);
      //     var newDimensionFilters = dimensionFiltersReducer(
      //       wsForm.dimensionFilters.value,
      //       action
      //     );
      //     handleDimensionFiltersChange(newDimensionFilters);
      //     break;
      //   // case "metrics":
      //   //   var action = replaceAllMetricFilters(payload);
      //   //   dispatchMetricFilters(action);
      //   //   break;
      //   // case "time":
      //   //   onTimeFiltersChange(payload);
      //   //   break;
      // }
    };

    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Open Filters",
      label: "Sidenav",
    });
    const commonGlobalFilterProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: false,
      showMetricFilters: false,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      timeFilters: user.timeFilters, //for initializing filters
      dimensionFilters: [], //for initializing filters
      metricFilters: [], //for initializing filters
      // settings: dimensionFiltersSettings,
      filtersDimData: allData.plotlyDimensions,
      filtersMetricsData: allData.plotlyMetrics,
      selections: {},
    };
    //Open Global Filters
    const newGlobalFiltersProps = {
      ...commonGlobalFilterProps,
      activeDimensionFilter: {},
      handleApplyFilters, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };

    const action = updateUserScreen("globalFilters", newGlobalFiltersProps);
    ReduxDispatcher(action);
  };
  const pivotNoImagePosition = {
    height: "calc(100vh - 123px)",
  };
  const additionalSidenavItems = [
    {
      id: "home",
      name: "Home",
      tooltip: "Go back to Datastory home",
      elementId: "dsBackToHome",
      iconId: "custom-home-icon",
      customClass: "sidenav-home",
      onClick: (user) => handleGoHome(user),
      isVisible: true,
    },
    {
      id: "filters",
      name: "Filters",
      tooltip: "Open filters",
      elementId: "filtersInSidenav",
      iconId: "custom-filter-icon",
      customClass: "sidenav-filters",
      onClick: (user) => handleAddFilters(),
      isVisible: true,
    },
  ];
  const sidenavItemsOrderInfo = {
    home: 1,
    filters: 2,
    reports: 3,
    alerts: 4,
  };
  const tabName = "datastory-list";
  const sidenavProps = { additionalSidenavItems, sidenavItemsOrderInfo, tabName };

  return (
    <>
      <LayoutTopSideBottom sidenavProps={sidenavProps}>
        <SigviewBreadcrumb
          data={breadcrumbData}
          onClick={handleBreadcrumbChange}
        />
        {/* THIS WRAPPER BOX IS FOR STICKY PURPOSES ONLY */}
        <Box>
          <Box css={stickyStyles}>
            <AppMenuBar {...appMenuBarProps} />
          </Box>
          <Box
            className="datastory-cat-content-wrapper"
            css={pivotNoImagePosition}
          >
            {data.status === "success" && uiFriendlyData.data.length > 0 && (
              <AppRow {...uiFriendlyData} />
            )}
            {data.status === "success" && uiFriendlyData.data.length === 0 && (
              <div className="no-data-container">
                <div className="no-data-image"></div>
                <p className="no-data">{t(config.messages.noDs)}</p>
              </div>
            )}
            {data.status === "loading" && <Loader />}
            {data.status === "error" && (
              <ErrorHandler
                message={data.message}
                reloadFlag={true}
                onReload={handleReload}
              />
            )}
          </Box>
        </Box>
      </LayoutTopSideBottom>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(DatastoryCat);
