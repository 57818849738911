// Import required libraies
import React, { useState, useEffect } from "react";
import cloneDeep from "lodash.clonedeep";

// Import Custom Component

// Import custom hooks

// Import action creators

// Import utils
import { config } from "../../../config/config";

// Import styles
import "./Table.scss";

// Import plotly utils
import {
  buildXvalFromChartObject,
  buildYvalFromChartObject,
  formatTableForDisplay,
} from "../../../utils/plotlyUtils";

// Import API functions

function TableUI(props) {
  // * Destructure Props
  const {
    id = "",
    data,
    onChange = () => {},
    selections = {},
    payload,
    chartContainerId,
    displayLayout,
    resizeFlag,
    allData,
  } = props;
  const { result = { dataFromQE: [], extraData: {} } } = data;
  const { dataFromQE = [] } = result;
  const { timeFilters } = selections;

  let initalChartContainerDim = {
    width: document.getElementById(chartContainerId)?.offsetWidth || 20,
    height: document.getElementById(chartContainerId)?.offsetHeight - 10 || 20,
  };

  // * Defining required state
  const [tableData, setTableData] = useState({ headers: [], data: [] }); //{headers: [], rows: []}
  const [chartContainerDim, setChartContainerDim] = useState(
    initalChartContainerDim
  );

  // * Defining required event handlers/util functions
  const handleError = (message) => {
    onChange(null, id, {
      status: "error",
      message: message || config.hardCoded.uiErrorMessage,
      result: { dataFromQE: [], extraData: {} },
    });
  };

  // * Defining required side effects
  useEffect(() => {
    try {
      //Make a copy of payload
      var payloadCopy = cloneDeep(payload);

      //Format data based on logic
      let chartObject = payloadCopy.chartObject;
      let xval = buildXvalFromChartObject({
        chartObject,
        plotlyDimensions: allData.plotlyDimensions,
      });
      let yval = buildYvalFromChartObject({
        chartObject,
        plotlyMetrics: allData.plotlyMetrics,
      });
      let selectedTimezone = timeFilters.selectedTimezone;
      let newTableData = formatTableForDisplay({
        xval,
        yval,
        selectedTimezone,
        rawData: dataFromQE,
        selectedTimezone,
        allData,
      });
      setTableData(newTableData);
    } catch (err) {
      console.error("UI ERROR in Table.js", err);
      handleError(config.hardCoded.uiErrorMessage);
    }
  }, [data.status]);

  // Remove the chart container when the component unmounts
  useEffect(() => {
    return () => {
      //Remove child element (plotly container) if it exists
      var chartContainer = document.getElementById(chartContainerId);
      if (chartContainer) {
        var plotlyChartContainer =
          chartContainer.getElementsByClassName("plot-container");
        if (plotlyChartContainer.length) {
          plotlyChartContainer[0].remove();
        }
      }
    };
  }, []);

  //Add resize event listener
  useEffect(() => {
    function handleTableResize() {
      setChartContainerDim({
        width: document.getElementById(chartContainerId)?.offsetWidth || 20,
        height:
          document.getElementById(chartContainerId)?.offsetHeight - 10 || 20,
      });
    }
    window.addEventListener("resize", handleTableResize);

    // Call for the first time when component mounts
    handleTableResize();

    return function cleanupListener() {
      window.removeEventListener("resize", handleTableResize);
    };
  }, []);

  //On change of display layout, replot the chart
  useEffect(() => {
    setChartContainerDim({
      width: document.getElementById(chartContainerId)?.offsetWidth || 20,
      height:
        document.getElementById(chartContainerId)?.offsetHeight - 10 || 20,
    });
  }, [displayLayout, resizeFlag]);

  let tableStyle = {
    // maxWidth: chartContainerDim.width, //Removed because CSS was breaking in angular/react integration
    // maxHeight: chartContainerDim.height,
    height: "97%",
  };

  return (
    <>
      <div className="sigview-table-container" style={tableStyle}>
        <table className="sigview-table">
          <thead>
            <tr>
              {tableData.headers.map((header) => (
                <th className="sigview-table-head-cell" key={header.id}>
                  {header.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.data.map((row) => (
              <tr className="sigview-table-row" key={row.id}>
                {row.colData.map((cell) => {
                  const className = `sigview-table-cell ${cell.type}`;
                  return (
                    <td key={cell.id} className={className}>
                      <span className="sigview-table-cell-value">
                        {cell.name}
                      </span>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default TableUI;
