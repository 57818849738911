// * Import required libraies
import React, { useEffect } from "react";
import _ from "underscore";
import axios from "axios";
import { connect, useSelector } from "react-redux";

// * Import hooks
import useUpdateEffect from "../../../hooks/useUpdateEffect";

// * Import redux utils
// Selectors
import {
  selectDimTableData,
  selectReloadEpoch,
  selectMsvTableSelections,
} from "../../../redux/selectors/standaloneMsvSelectors";
// Actions
import { updateStandaloneMsvFormDimensionTableData } from "../../../redux/actions";

// Import utils/data
import { config } from "../../../config/config";
import {
  areMultiItemsSelectedInMsv,
  makeDataFromQeForMsvMultiSelectApiCall,
  standardizeReponseFromGetDataPromiseAllSelected,
} from "../../../utils/analyzeUtils";
import { wrapperChartObject } from "../../../utils/chartObjectUtils";

// * Import API functions
import { getData, getComparisonData } from "../../../services/api";

function MsvDimTable(props) {
  const {
    // REDUX PROPS
    dispatch: ReduxDispatcher,
    standaloneMsv: msvForm,
    user,
    // PARENT PROPS
    id = "",
  } = props;

  // * Destructure REDUX props
  const dimensionTableDate = useSelector((state) =>
    selectDimTableData(state, id)
  );
  const selections = useSelector((state) =>
    selectMsvTableSelections(state, id, msvForm)
  );
  const reloadEpoch = useSelector((state) => selectReloadEpoch(state, id));

  // * Define required event handlers
  const handleDataChange = (value) => {
    let payload = { dimId: id, dimData: value };
    let action = updateStandaloneMsvFormDimensionTableData(payload);
    ReduxDispatcher(action);
  };
  const handleError = (message) => {
    handleDataChange({
      result: { dataFromQE: [], extraData: {} },
      status: "error",
      message: message || config.hardCoded.uiErrorMessage,
    });
  };

  // getData Chart Data
  useEffect(() => {
    // Making required source objects for cancellation in clean-up function
    const sourceSingleCall1 = axios.CancelToken.source();
    const sourceSingleCall2 = axios.CancelToken.source();

    if (
      dimensionTableDate.status === "loading" ||
      dimensionTableDate.status === "error"
    ) {
      // Initializing required variables
      let dataFromQEFinal = [];
      let extraDataFinal = [];

      try {
        // * Catering to 2 separate cases here:
        // Please refer MsvDimensionTableItemNotes.txt for details
        const areMultiItemsSelectedInMsvFlag =
          areMultiItemsSelectedInMsv(msvForm);
        if (areMultiItemsSelectedInMsvFlag) {
          if (selections.timeFilters.isComparisonOn) {
            // * Without selected items in the msv table
            var payload1 = wrapperChartObject({ selections, user });
            const fetchProps1 = {
              payload: payload1,
              cancelToken: sourceSingleCall1.token,
            };
            const getComparisonDataPromise1 = getComparisonData(fetchProps1);

            // * With selected items in the msv table
            // Add new filter row for selected items
            var activeMsvTableId = msvForm.activeMsvTableId.value;
            var filterDimObject =
              msvForm.selectedDimensions.value[activeMsvTableId]
                .dimensionsList[0];
            var newMsvFilterItem = {
              id: activeMsvTableId,
              values: msvForm.allSelectedTableItem.value,
              metadata: filterDimObject,
              filterType: "include",
              advancedFilters: [],
              valid: true,
            };
            var newDimensionFilters = [
              ...selections.dimensionFilters,
              newMsvFilterItem,
            ];
            // ! Remove regex search item (as it's not applied on the regex rows)
            newDimensionFilters = newDimensionFilters.filter(
              (row) => row.filterType !== "regex"
            );
            var newSelections = {
              ...selections,
              dimensionFilters: newDimensionFilters,
              metricFilters: [], // ! Metric filters to be not applied on selected rows
            };
            var payload2 = wrapperChartObject({
              selections: newSelections,
              user,
            });
            const fetchProps2 = {
              payload: payload2,
              cancelToken: sourceSingleCall2.token,
            };
            const getComparisonDataPromise2 = getComparisonData(fetchProps2);
            Promise.allSettled([
              getComparisonDataPromise1,
              getComparisonDataPromise2,
            ])
              .then((responseArr) => {
                const standardizedResponse =
                  standardizeReponseFromGetDataPromiseAllSelected(responseArr);
                if (standardizedResponse.status !== "apiError") {
                  const response1 = standardizedResponse.result[0];
                  const response2 = standardizedResponse.result[1];
                  const response1StatusCode = response1.status.statusCode;
                  const response2StatusCode = response2.status.statusCode;
                  const runFlag =
                    ["200", "304"].includes(response1StatusCode) &&
                    ["200", "304"].includes(response2StatusCode);
                  if (runFlag) {
                    var data1 = response1.result.data;
                    var data2 = response2.result.data;
                    const dataFromQEFinal =
                      makeDataFromQeForMsvMultiSelectApiCall({
                        data1,
                        data2,
                        filterDimObject,
                        msvForm,
                      });
                    var extraDataFinal = {};
                    // Update parent state
                    handleDataChange({
                      status: "success",
                      message: "",
                      result: {
                        dataFromQE: dataFromQEFinal,
                        extraData: extraDataFinal,
                      },
                    });
                  } else {
                    // Update parent state
                    handleDataChange({
                      status: "success",
                      message: "",
                      result: {
                        dataFromQE: [],
                        extraData: extraDataFinal,
                      },
                    });
                  }
                } else {
                  handleError(standardizedResponse.message);
                }
              })
              .catch((error) => {
                if (error.error !== config.hardCoded.queryCancelled) {
                  handleError(error.error);
                }
              });
          } else {
            // * Without selected items in the msv table
            var payload1 = wrapperChartObject({ selections, user });
            const fetchProps1 = {
              payload: payload1,
              cancelToken: sourceSingleCall1.token,
            };
            const getDataPromise1 = getData(fetchProps1);

            // * With selected items in the msv table
            // Add new filter row for selected items
            var activeMsvTableId = msvForm.activeMsvTableId.value;
            var filterDimObject =
              msvForm.selectedDimensions.value[activeMsvTableId]
                .dimensionsList[0];
            var newMsvFilterItem = {
              id: activeMsvTableId,
              values: msvForm.allSelectedTableItem.value,
              metadata: filterDimObject,
              filterType: "include",
              advancedFilters: [],
              valid: true,
            };
            var newDimensionFilters = [
              ...selections.dimensionFilters,
              newMsvFilterItem,
            ];
            // ! Remove regex search item (as it's not applied on the regex rows)
            newDimensionFilters = newDimensionFilters.filter(
              (row) => row.filterType !== "regex"
            );
            var newSelections = {
              ...selections,
              dimensionFilters: newDimensionFilters,
              metricFilters: [], // ! Metric filters to be not applied on selected rows
            };
            var payload2 = wrapperChartObject({
              selections: newSelections,
              user,
            });
            const fetchProps2 = {
              payload: payload2,
              cancelToken: sourceSingleCall2.token,
            };
            const getDataPromise2 = getData(fetchProps2);
            Promise.allSettled([getDataPromise1, getDataPromise2])
              .then((responseArr) => {
                const standardizedResponse =
                  standardizeReponseFromGetDataPromiseAllSelected(responseArr);
                if (standardizedResponse.status !== "apiError") {
                  const response1 = standardizedResponse.result[0];
                  const response2 = standardizedResponse.result[1];
                  const response1StatusCode = response1.status.statusCode;
                  const response2StatusCode = response2.status.statusCode;
                  const runFlag =
                    ["200", "304"].includes(response1StatusCode) &&
                    ["200", "304"].includes(response2StatusCode);
                  if (runFlag) {
                    var data1 = response1.result.data;
                    var data2 = response2.result.data;
                    const dataFromQEFinal =
                      makeDataFromQeForMsvMultiSelectApiCall({
                        data1,
                        data2,
                        filterDimObject,
                        msvForm,
                      });
                    var extraDataFinal = {};
                    // Update parent state
                    handleDataChange({
                      status: "success",
                      message: "",
                      result: {
                        dataFromQE: dataFromQEFinal,
                        extraData: extraDataFinal,
                      },
                    });
                  } else {
                    // Update parent state
                    handleDataChange({
                      status: "success",
                      message: "",
                      result: {
                        dataFromQE: [],
                        extraData: extraDataFinal,
                      },
                    });
                  }
                } else {
                  handleError(standardizedResponse.message);
                }
              })
              .catch((error) => {
                if (error.error !== config.hardCoded.queryCancelled) {
                  handleError(error.error);
                }
              });
          }
        } else {
          if (selections.timeFilters.isComparisonOn) {
            var payload = wrapperChartObject({ selections, user });
            const fetchProps = {
              payload: payload,
              cancelToken: sourceSingleCall1.token,
            };
            const getComparisonDataPromise = getComparisonData(fetchProps);
            getComparisonDataPromise
              .then((responseDate) => {
                dataFromQEFinal = responseDate.result.data;
                extraDataFinal = {};
                // Update parent state
                handleDataChange({
                  status: "success",
                  message: "",
                  result: {
                    dataFromQE: dataFromQEFinal,
                    extraData: extraDataFinal,
                  },
                });
              })
              .catch((error) => {
                if (error.error !== config.hardCoded.queryCancelled) {
                  handleError(error.error);
                }
              });
          } else {
            var payload = wrapperChartObject({ selections, user });
            const fetchProps = {
              payload: payload,
              cancelToken: sourceSingleCall1.token,
            };
            const getDataPromise = getData(fetchProps);
            getDataPromise
              .then((responseDate) => {
                dataFromQEFinal = responseDate.result.data;
                extraDataFinal = {};
                // Update parent state
                handleDataChange({
                  status: "success",
                  message: "",
                  result: {
                    dataFromQE: dataFromQEFinal,
                    extraData: extraDataFinal,
                  },
                });
              })
              .catch((error) => {
                if (error.error !== config.hardCoded.queryCancelled) {
                  handleError(error.error);
                }
              });
          }
        }
      } catch (err) {
        console.error("UI ERROR");
        console.groupCollapsed("DETAILS");
        console.error(err);
        console.groupEnd();
        handleError(config.hardCoded.uiErrorMessage);
      }
    }

    // Clean-up function to cancel all pending fetch calls
    return () => {
      // Cancel all previous fetch calls
      if (sourceSingleCall1) sourceSingleCall1.cancel();
    };
  }, [dimensionTableDate.status, reloadEpoch]);

  useUpdateEffect(() => {
    // console.error("I CAME HERE");
    // console.error("MOUNTED");
    // console.log("selections", selections);
    // console.log("dimensionTableDate.status", dimensionTableDate.status);
    // ! Creates a bug where if the chart data is loading, it won't cancel or refetch the request
    // if (["success", "error"].includes(dimensionTableDate.status)) {
    //   // console.error("I CAME HERE 2");
    //   // Reset parent chart data state to loading anytime selections or reloadEpoch change
    // handleDataChange({
    //     result: { dataFromQE: [], extraData: {} },
    //     status: "loading",
    //     message: "",
    //   });
    // }
    // Change state to loading
    handleDataChange({
      result: { dataFromQE: [], extraData: {} },
      status: "loading",
      message: "",
    });
    // Change reload epoch to retrigger fetch
    // handleDataReload();
    return () => {
      // console.error("CLEANUP");
    };
    // ! Don't replace this with selections object. It doesn't work. Perhaps because of a
    // ! new reference that gets created on every change in props or state
  }, [
    // ! Comment out selections.chartType below to achieve smooth transition of charts (no reload); can be done only when all charts have the same call
    // selections.dimensionFilters, // ! Dimension related changes in standaloneWs using manipulateStateBasedOnTimeFiltersChange
    // selections.timeFilters.selectedDatesQE, // ! Time filter related changes in standaloneWs using manipulateStateBasedOnTimeFiltersChange
    // selections.timeFilters.compareDates, // ! Time filter related changes in standaloneWs using manipulateStateBasedOnTimeFiltersChange
    // selections.timeFilters.isComparisonOn, // ! Time filter related changes in standaloneWs using manipulateStateBasedOnTimeFiltersChange
    selections.dimensionsList,
    selections.metricsList,
    selections.orderById,
    selections.orderBy,
    selections.orderByType,
    selections.percentCalList,
    selections.dataLimit,
    selections.regexSearch,
    selections.metricFilters,
  ]);

  return <></>;
}

const mapStateToProps = (state) => ({
  user: state.user,
  standaloneMsv: state.standaloneMsv,
});

export default connect(mapStateToProps)(MsvDimTable);
