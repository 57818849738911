import GridLayout, { WidthProvider } from "react-grid-layout";

import { config } from "../../../config/config";

const ResponsiveGridLayout = WidthProvider(GridLayout);

function Grid(props) {
  const { layout, datastoryStateTitleClass } = props;
  const gridStyle = { height: "100%", width: "100%", overflow: "hidden" };

  return (
    <ResponsiveGridLayout
      style={gridStyle}
      layout={layout}
      cols={12}
      rowHeight={1}
      margin={[5, 5]}
      className={`${datastoryStateTitleClass}-GA`}
    >
      {layout.map((widget) => {
        const imageUrl = `${config.hardCoded.chartTypesBaseUrl}/${widget.chartType}Hovered.svg`;
        const itemStyle = {
          // border: "1px solid #c5cfd9",
          border: "1px solid #eff2f5",
          borderRadius: "2px",
          padding: widget.chartType === "counter" ? "3px" : "10px",
        };
        const gridItemStyle = {
          backgroundImage: `url(${imageUrl})`,
          // backgroundColor: "lightgray" /* Used if the image is unavailable */,
          backgroundPosition: "center" /* Center the image */,
          backgroundRepeat: "no-repeat" /* Do not repeat the image */,
          backgroundSize:
            "contain" /* Resize the background image to cover the entire container */,
          height: "100%",
          width: "100%",
          boxSizing: "border-box",
        };
        return (
          <div
            className={`${datastoryStateTitleClass}-GA`}
            key={widget.i}
            style={itemStyle}
          >
            <div
              className={`${datastoryStateTitleClass}-GA`}
              style={gridItemStyle}
            ></div>
          </div>
        );
      })}
    </ResponsiveGridLayout>
  );
}

function CardGrid(props) {
  const { payload, classes, datastoryStateTitleClass } = props;
  const {
    bgIMG,
    isSelected,
    title,
    moreOptions,
    gridLayoutData,
    onClick = () => {},
  } = payload;
  const { flag, icon: moreOptionsIcon } = moreOptions;
  const isMoreOptionsIconPresent = flag;

  // Defining required event handlers
  const handleClick = (e) => onClick(e, props);

  const extraStyles = {
    cardImgContainerStyles: {
      height: "100%",
      width: "100%",
      paddingBottom: "5px",
    },
    cardTitleContainerStyles: {
      padding: `${
        isMoreOptionsIconPresent ? "6px 6px 6px 10px" : "6px 10px 6px 10px"
      }`,
    },
  };
  const { cardImgContainerStyles, cardTitleContainerStyles } = extraStyles;

  return (
    <>
      <article
        className={`${classes.card} ${datastoryStateTitleClass}-GA`}
        onClick={handleClick}
      >
        <div
          className={`${datastoryStateTitleClass}-GA card-image-body-container`}
        >
          <div
            className={`${datastoryStateTitleClass}-GA card-image-container`}
            style={cardImgContainerStyles}
          >
            <Grid
              layout={gridLayoutData}
              datastoryStateTitleClass={datastoryStateTitleClass}
            />
          </div>

          {isSelected && (
            <div className="card-overlay-container">
              <div className="card-tick-icon-container">
                <span className="material-icons">check_circle</span>
              </div>
            </div>
          )}
        </div>
        <div
          className={`${datastoryStateTitleClass}-GA card-title-container`}
          style={cardTitleContainerStyles}
        >
          <p
            className={`${datastoryStateTitleClass}-GA card-title`}
            title={title}
          >
            {title}
          </p>
          {flag && (
            <div className="more-option-icon-container">
              <span className={moreOptionsIcon.className}>
                {moreOptionsIcon.value}
              </span>
            </div>
          )}
        </div>
      </article>
    </>
  );
}

export default CardGrid;
