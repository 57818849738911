// Import Constants
import C from "../constants";
import { validateAttributeDimension } from "../../utils/attributeUtils";

const attributeDimension = (state = {}, action = {}) => {
  const { type = "", payload = {} } = action;
  const { key = "", value = "" } = payload;

  let newState = {};
  switch (type) {
    case C.UPDATE_ATTRIBUTE_DIMENSION_FORM:
      switch (key) {
        case "name":
        case "backendName":
        case "groups":
        case "dataType":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          break;
        default:
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log("UNIDENTIFIED KEY in UPDATE_ATTRIBUTE_DIMENSION_FORM");
          console.log("REDUCER -> ", "AttributeDimension Reducer.js");
          console.log("state -> ", state);
          console.log("action -> ", action);
          console.groupEnd();
          newState = { ...state };
      }

      break;

    case C.UPDATE_ATTRIBUTE_DIMENSION_FORM_WHOLE_KEY:
      switch (key) {
        case "name":
        case "backendName":
        case "groups":
        case "dataType":
          newState = {
            ...state,
            [key]: { ...value },
          };
          break;
        default:
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log("UNIDENTIFIED KEY in UPDATE_ATTRIBUTE_DIMENSION_FORM");
          console.log("REDUCER -> ", "AttributeDimension Reducer.js");
          console.log("state -> ", state);
          console.log("action -> ", action);
          console.groupEnd();
          newState = { ...state };
      }
      break;

    case C.REPLACE_ATTRIBUTE_DIMENSION_FORM:
      newState = { ...payload };
      break;

    default:
      console.error("UI ERROR");
      console.groupCollapsed("DETAILS");
      console.log("UNIDENTIFIED TYPE");
      console.log("REDUCER -> ", "AttributeDimension Reducer.js");
      console.log("state -> ", state);
      console.log("action -> ", action);
      console.groupEnd();
      newState = { ...state };
      break;
  }

  // TODO Add validation
  console.log("newState", newState);
  const validatedNewState = validateAttributeDimension({ ...newState });

  return validatedNewState;
};

export default attributeDimension;
