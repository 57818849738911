//Import required libraies
import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

// Import Custom Component
import Loader from "../Loader/Loader";
import SigviewTooltip from "./SigviewTooltip";

const useStyles = makeStyles((theme) => ({
  root: { width: "100%" },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: (props) =>
      props?.customStyle?.wrapperBorder
        ? props?.customStyle?.wrapperBorder
        : props.errorFlag
        ? "1px solid red !important"
        : "1px solid transparent",
    boxSizing: "border-box",
    width: (props) => props?.customStyle?.wrapperWidth || "200px",
    minWidth: (props) => props?.customStyle?.wrapperMinWidth || "100px",
    height: (props) => props?.customStyle?.wrapperHeight || "30px",
    // height: "30px",
    borderRadius: "3px",
    padding: (props) =>
      props?.customStyle?.wrapperPadding || "0px 10px 0px 3px",
    border: (props) =>
      props?.customStyle?.wrapperBorder
        ? props.errorFlag
          ? "1px solid red !important"
          : props?.customStyle?.wrapperBorder
        : "0px 10px 0px 3px",
    boxSizing: "border-box",
    "&:hover": {
      //border: `1px solid ${theme.palette.misc["secondaryColorLighter"]}`,
      backgroundColor: `${theme.palette.misc["headerBgColor"]}`,
    },
    "&:focus-within": {
      //border: `1px solid ${theme.palette.misc["secondaryColorLight"]}`,
      backgroundColor: `${theme.palette.misc["headerBgColor"]}`,
    },
    backgroundColor: (props) =>
      props?.customStyle?.wrapperBgColor || "transparent",
    margin: (props) => props?.customStyle?.wrapperMargin || "0px 10px 0px 0px",
    cursor: (props) => (props.readOnly ? "not-allowed" : "auto"),
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20px",
    height: "20px",
    boxSizing: "border-box",
    "& span": {
      color: theme.palette.misc["secondaryColor"],
      fontSize: "16px !important",
    },
  },
  errorMessageStyle: {
    color: "red",
    fontSize: "10px",
    wordWrap: "break-word",
    display: "inline-block",
    width: "300px",
    wordWrap: "break-word",
  },
  postIconWrapper: { cursor: "pointer" },
  textField: {
    pointerEvents: (props) => (props.readOnly ? "none" : "auto"),
    height: "100%",
    width: (props) =>
      props.status === "loading" ? "calc(100% - 40px)" : "100%",
    boxSizing: "border-box",
    fontSize: (props) => props?.customStyle?.textFieldFontSize || "16px",
    fontFamily: "inherit",
    fontWeight: (props) => props?.customStyle?.fontWeightTextField || "500",
    backgroundColor: "transparent",
    border: "none",
    outline: "none !important",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: (props) => (props.iconFlag ? "0px 0px 0px 7px" : "0px"),
    color: theme.palette.misc["secondaryColor"],
    "&::placeholder": {
      color: theme.palette.misc["secondaryColorLight"],
      fontSize: (props) => props?.customStyle?.placeholderFontSize || "14px",
    },
  },
}));

function SigviewTextFieldAsync(props) {
  const { t } = useTranslation();
  const elementId = useRef(v4());
  const {
    value = "",
    onChange = () => {},
    errorFlag = false,
    errorMessage = "",
    onFocus = () => {},
    onBlur = () => {},
    onClickAway = () => {},
    readOnly = false,
    placeholder = "Enter text...",
    type = "text",
    status = "success",
    iconFlag = false,
    iconProps = { className: "material-icons", iconName: "search" },
    postIconFlag = false,
    postIconProps = {
      className: "material-icons",
      iconName: "clear",
      onClick: () => {},
    },
    tooltipTitle = "",
    inputClassName = ""
  } = props;

  //Required Functions
  const handleChange = (event) => {
    onChange(event, event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // onClickAway();

    //Remove focus from text field
    const textField = document.getElementById(
      `sigviewTextField-${elementId.current}`
    );
    textField.blur();
  };

  //Styling
  const classes = useStyles(props);
  const tooltipTitleFinal = readOnly ? "Operation Not Allowed" : tooltipTitle;
  let showPostIconFlag = postIconFlag;
  if (value.length === 0) showPostIconFlag = false;
  let disabled = false;
  if (status === "loading") disabled = true;

  return (
    // <ClickAwayListener onClickAway={onClickAway}>
    <div className={classes.root}>
      {/* Need to wrap it in a fragment to because ClickAwayListener requires elements/fragments as children */}
      <SigviewTooltip title={tooltipTitleFinal} placement="bottom">
        <form className={classes.wrapper} onSubmit={handleSubmit}>
          {iconFlag && (
            <span className={classes.iconWrapper}>
              <span className={iconProps.className}>{iconProps.iconName}</span>
            </span>
          )}
          <input
            className={`${classes.textField} ${inputClassName}`}
            id={`sigviewTextField-${elementId.current}`}
            value={value}
            onChange={handleChange}
            placeholder={t(placeholder)}
            onFocus={onFocus}
            onBlur={onClickAway}
            type={type}
            disabled={disabled}
          />
          {showPostIconFlag && (
            <span
              className={`${classes.iconWrapper} ${classes.postIconWrapper}`}
              onClick={postIconProps.onClick}
            >
              <span className={postIconProps.className}>
                {postIconProps.iconName}
              </span>
            </span>
          )}
          {status === "loading" && (
            <span className={classes.loaderWrapper}>
              <Loader size="16px" />
            </span>
          )}
        </form>
      </SigviewTooltip>
      {errorFlag && (
        <span className={classes.errorMessageStyle}>{errorMessage}</span>
      )}
    </div>
    // </ClickAwayListener>
  );
}

export default SigviewTextFieldAsync;
