import React from "react";

function LayoutRow(props) {
  const {
    children,
    style = {
      display: "flex",
      height: "100%",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "10px 0px",
    },
  } = props;
  return <div style={style}>{children}</div>;
}

export default LayoutRow;
