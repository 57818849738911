// Import required libraies
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import Custom Component
import LayoutTopBottom from "../../layouts/LayoutTopBottom/LayoutTopBottom";
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler";

// Import Context

// Import utils/data

// Import styles

// Import action creators
import { updateUserScreen } from "../../redux/actions";

function AppError(props) {
  const { dispatch: ReduxDispatcher, user, errorHandlerProps } = props;

  //Update isDashboardLoading to false once this component mounts
  //Otherwise dashboard loader is shown
  useEffect(() => {
    const action = updateUserScreen("isDashboardLoading", false);
    ReduxDispatcher(action);
  }, []);

  return (
    <LayoutTopBottom>
      <ErrorHandler {...errorHandlerProps} />
    </LayoutTopBottom>
  );
}

AppError.propTypes = {
  location: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(AppError);
