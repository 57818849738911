// * Import required libraries
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Import utils
import { masterTrackGaEvent } from "../../../services/ga";

// * Making styles
const makeSigviewStyles = (...args) => {
  const [themeColors] = args;
  const useStyles = makeStyles(() => ({
    mainContainer: {
      padding: "0px 22px",
      height: "25px",
      display: "flex",
      alignItems: "flex-end",
      fontSize: "13px",
      marginBottom: "5px",
    },
    elementActive: {
      color: themeColors["primaryColor"],
      cursor: "pointer",
      paddingLeft: "3px",
      paddingRight: "3px",
    },
    elementDisabled: {
      color: themeColors["secondaryColor"],
      paddingLeft: "3px",
      paddingRight: "3px",
    },
    dashed: {
      color: themeColors["primaryColor"],
    },
  }));
  return useStyles;
};

function SigviewBreadcrumb(props = {}) {
  const { t } = useTranslation();
  // * Destructure props
  const { data = [], onClick = () => {} } = props;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define static variables
  // lastElementName of the data array to disable on click and color
  const lastElementName = data[data.length - 1].name;

  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();

  const SigviewBreadcrumbItem = (props = {}) => {
    // * Destructure props
    const { id = "", name = "" } = props;

    // * Define static variables
    const isLastElement = lastElementName === name;
    const handleClick = (e) => {
      // Google Analytics Event - Master
      masterTrackGaEvent({
        category: "Navigation",
        action: "Breadcrumb",
        label: id,
      });
      onClick(e, props);
    };
    var breadcumbName = name.replace(/ +/g, "");

    return (
      <>
        {isLastElement && (
          <span key={id} className={classes.elementDisabled}>
            {t(name)}
          </span>
        )}
        {!isLastElement && (
          <>
            <span
              key={id}
              className={`${classes.elementActive} ${breadcumbName}Open-GA`}
              onClick={handleClick}
            >
              {t(name)}
            </span>
            <span className={classes.dashed}>/</span>
          </>
        )}
      </>
    );
  };

  return (
    <div className={classes.mainContainer}>
      {data.map((el) => (
        <SigviewBreadcrumbItem key={el.id} {...el} />
      ))}
    </div>
  );
}

export default SigviewBreadcrumb;
