// Import Constants
import C from "../constants";

import { validateGroup } from "../../utils/adminUtils";

const adminHome = (state, action) => {
  const { type = "", payload = {} } = action;
  const { key = "", value = "", viewsData = [] } = payload;

  let newState = {};
  switch (type) {
    case C.UPDATE_ADMIN_FORM:
      switch (key) {
        case "name":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          // console.log("DEBUG", newState);
          break;
        case "activeView":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          // console.log("DEBUG", newState);
          break;
        case "selectedView":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
        case "selectedView":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          // console.log("DEBUG", newState);
          break;
        // case "name":
        //   let newName = value;
        //   newName = newName.replace(/\s/g, "_");
        //   // console.log("newName", newName);

        //   newState = {
        //     ...state,
        //     [key]: {
        //       ...state[key],
        //       value,
        //     },
        //     id: {
        //       ...state.id,
        //       value: `${newName}_${Date.now().toString()}`,
        //     },
        //   };
        //   break;

        case "dimensionValue":
        case "metricValue":
        case "customMetricValue":
          const { newdata, activeViewId } = value;
          console.log("object", value);
          newState = {
            ...state,
            views: {
              ...state.views,
              value: {
                ...state.views.value,
                [activeViewId]: {
                  ...state.views.value[activeViewId],
                  [key]: newdata,
                },
              },
            },
          };

          break;

        case "role":
        case "groupName":
        case "firstName":
        case "lastName":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          // console.log("DEBUG", newState);
          break;

        case "modifyUserViews":
          newState = {
            ...state,
            views: value.views,
            selectedView: value.selectedView,
            activeView: value.activeView,
          };

        default:
          console.log("Something Went Wrong");
      }
      break;

    case C.REPLACE_ADMIN_FORM:
      newState = { ...value };
      break;

    case C.VALIDATE_ADMIN_FORM:
      newState = { ...value };
      break;
  }

  // TODO Add validation function
  // validateGroup(newState);

  return newState;
};
const adminGroup = (state, action) => {
  const { type = "", payload = {} } = action;
  const { key = "", value = "", viewsData = [] } = payload;

  let newState = {};
  switch (type) {
    case C.UPDATE_ADMIN_FORM:
      switch (key) {
        case "name":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          // console.log("DEBUG", newState);
          break;
        case "activeView":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          // console.log("DEBUG", newState);
          break;
        case "selectedView":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          // console.log("DEBUG", newState);
          break;
        // case "name":
        //   let newName = value;
        //   newName = newName.replace(/\s/g, "_");
        //   // console.log("newName", newName);

        //   newState = {
        //     ...state,
        //     [key]: {
        //       ...state[key],
        //       value,
        //     },
        //     id: {
        //       ...state.id,
        //       value: `${newName}_${Date.now().toString()}`,
        //     },
        //   };
        //   break;

        case "dimensionValue":
        case "metricValue":
        case "customMetricValue":
        case "groupSecurityFilter":
          const { newdata, activeViewId } = value;
          console.log("object", value);
          newState = {
            ...state,
            views: {
              ...state.views,
              value: {
                ...state.views.value,
                [activeViewId]: {
                  ...state.views.value[activeViewId],
                  [key]: newdata,
                },
              },
            },
          };

          break;

        case "role":
        case "groupName":
        case "firstName":
        case "lastName":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          // console.log("DEBUG", newState);
          break;

        case "modifyUserViews":
          newState = {
            ...state,
            views: value.views,
            selectedView: value.selectedView,
            activeView: value.activeView,
          };

        default:
          console.log("Something Went Wrong");
      }
      break;

    case C.REPLACE_ADMIN_FORM:
      newState = { ...value };
      break;

    case C.VALIDATE_ADMIN_FORM:
      newState = { ...value };
      break;
  }

  // TODO Add validation function

  return newState;
};
const adminUser = (state, action) => {
  const { type = "", payload = {} } = action;
  const { key = "", value = "" } = payload;

  let newState = {};
  switch (type) {
    case C.UPDATE_ADMIN_FORM:
      switch (key) {
        case "name":
        case "views":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          // console.log("DEBUG", newState);
          break;
        case "activeView":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          // console.log("DEBUG", newState);
          break;
        case "selectedView":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          // console.log("DEBUG", newState);
          break;
        case "selectedGroup":
          console.log("selectedGroup", value);
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value: {
                ...state[key].value,
                [value.item]: value.value,
              },
            },
          };
          // console.log("DEBUG", newState);
          break;
        // case "name":
        //   let newName = value;
        //   newName = newName.replace(/\s/g, "_");
        //   // console.log("newName", newName);

        //   newState = {
        //     ...state,
        //     [key]: {
        //       ...state[key],
        //       value,
        //     },
        //     id: {
        //       ...state.id,
        //       value: `${newName}_${Date.now().toString()}`,
        //     },
        //   };
        //   break;

        case "dimensionValue":
        case "metricValue":
        case "customMetricValue":
        case "userFilters":
          const { newdata, activeViewId } = value;
          console.log("object", value);
          newState = {
            ...state,
            views: {
              ...state.views,
              value: {
                ...state.views.value,
                [activeViewId]: {
                  ...state.views.value[activeViewId],
                  [key]: newdata,
                },
              },
            },
          };

          break;

        case "role":
        case "groupName":
        case "firstName":
        case "lastName":
        case "email":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          // console.log("DEBUG", newState);
          break;

        case "modifyUserViews":
          newState = {
            ...state,
            views: value.views,
            selectedView: value.selectedView,
            activeView: value.activeView,
          };

        default:
          console.log("Something Went From Reducer");
      }
      break;

    case C.REPLACE_ADMIN_FORM:
      newState = { ...value };
      break;

    case C.VALIDATE_ADMIN_FORM:
      newState = { ...value };
      break;
  }

  // TODO Add validation function

  // validateGroup(newState);

  return newState;
};

export { adminHome, adminGroup, adminUser };
