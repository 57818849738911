// Import Constants
import C from "../constants";
import {
  validateAttributeCustomMetrics,
  validateformulaEntityValueNum,
} from "../../utils/attributeUtils";

const attributeCustomMetric = (state, action) => {
  const { type = "", payload = {} } = action;
  const { key = "", value = "" } = payload;
  let newState = {};
  switch (type) {
    case C.UPDATE_ATTRIBUTE_CUSTOM_METRIC_FORM:
      switch (key) {
        case "name":
        case "backendName":
        case "groups":
        case "enteredFormula":
        case "dataUnitSymbol":
        case "isAdditive":
          // case "formulaType":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          break;

        case "dataUnitType":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          if (value === "percentile") {
            newState = {
              ...newState,
              formulaType: {
                ...newState.formulaType,
                value: "(A,B]",
              },
              isAdditive: {
                ...newState.isAdditive,
                value: false,
              },
            };
          } else if (newState.formulaType.value === "(A,B]") {
            newState = {
              ...newState,
              formulaType: {
                ...newState.formulaType,
                value: "A/B",
              },
            };
          }
          break;

        case "formulaType":
          const { formulaType = "", formulaEntity = "" } = value;

          newState = {
            ...state,
            formulaEntity,
            [key]: { ...state[key], value: formulaType },
          };
          break;

        case "formulaEntity":
          const { id = "", entityValue = "", entityValueType = "" } = value;

          for (const i in state.formulaEntity) {
            if (i === id && state.dataUnitType.value !== "percentile") {
              newState = {
                ...state,
                formulaEntity: {
                  ...state.formulaEntity,
                  [i]: {
                    ...state.formulaEntity[i],

                    value:
                      entityValueType === "numeric"
                        ? {
                            id: "numeric",
                            num: isNaN(Number(entityValue))
                              ? 0
                              : Number(entityValue),
                          }
                        : {
                            id: entityValue,
                          },
                  },
                },
              };
            } else if (i == id && state.dataUnitType.value === "percentile") {
              newState = {
                ...state,
                formulaEntity: {
                  ...state.formulaEntity,
                  [i]: {
                    ...state.formulaEntity[i],

                    value:
                      entityValueType === "numeric"
                        ? {
                            id: "numeric",
                            num: isNaN(Number(entityValue)) ? 0.1 : entityValue,
                          }
                        : {
                            id: entityValue,
                          },
                  },
                },
              };
            }
          }

          break;

        default:
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log(
            "UNIDENTIFIED KEY in UPDATE_ATTRIBUTE_CUSTOM_METRIC_FORM"
          );
          console.log("REDUCER -> ", "AttributeDimension Reducer.js");
          console.log("state -> ", state);
          console.log("action -> ", action);
          console.groupEnd();
          newState = { ...state };
      }

      break;

    case C.UPDATE_ATTRIBUTE_CUSTOM_METRIC_FORM_WHOLE_KEY:
      switch (key) {
        case "name":
        case "backendName":
        case "groups":
        case "dataType":
          newState = {
            ...state,
            [key]: { ...value },
          };
          break;
        default:
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log(
            "UNIDENTIFIED KEY in UPDATE_ATTRIBUTE_CUSTOM_METRIC_FORM"
          );
          console.log("REDUCER -> ", "AttributeDimension Reducer.js");
          console.log("state -> ", state);
          console.log("action -> ", action);
          console.groupEnd();
          newState = { ...state };
      }

      break;

    case C.REPLACE_ATTRIBUTE_CUSTOM_METRIC_FORM:
      newState = { ...payload };

      break;

    default:
      console.error("UI ERROR");
      console.groupCollapsed("DETAILS");
      console.log("UNIDENTIFIED TYPE");
      console.log("REDUCER -> ", "AttributeDimension Reducer.js");
      console.log("state -> ", state);
      console.log("action -> ", action);
      console.groupEnd();
      newState = { ...state };
  }
  const validatedNewState = validateAttributeCustomMetrics({ ...newState });

  return validatedNewState;
};

export default attributeCustomMetric;
