// Import required libraries
import React, { useContext } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import PropTypes from "prop-types";

// Import custom components
import GlobalFilters from "../../components/GlobalFilters";
import ReportManager from "../../components/ReportManager";
import AlertManager from "../../components/AlertManger";
import SigviewSnackbar from "../../components/Common/SigviewSnackbar";
import SigviewDialog from "../../components/Common/SigviewDialog";
import LayoutBigDialog from "../../layouts/LayoutBigDialog";
import DialogAlert from "../../../js/components/DialogAlert/DialogAlert";
import OverlayDialog from "../../components/Common/OverlayDialog";
import AdminReportOverlay from "../../components/ReportManager/AdminReportOverlay";
// Import styles

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import config
import { config } from "../../config/config";

// Import action creators
import { updateUserScreen } from "../../redux/actions";

// Import utils & data

function SigviewCommon(props) {
  const { dispatch: ReduxDispatcher, user } = props;
  const { state: themeState } = useContext(ThemeContext);

  const snackbarOnclose = () => {
    ReduxDispatcher(
      updateUserScreen("snackbar", {
        ...user.screen.snackbar,
        open: false,
        message: "",
      })
    );
  };

  // DEBUGGER
  // useEffect(() => {
  //   console.log("SigviewCommon Mounted");
  //   return () => {
  //     console.log("SigviewCommon Unmounted");
  //   };
  // }, []);

  // Defining required variables
  const isBigDialogOpen = user?.screen?.sigviewBigDialog?.open;

  return (
    <>
      {user.screen.globalFilters.isOpen && <GlobalFilters />}
      {user.screen.reportManager.isOpen && <ReportManager />}
      {user?.screen?.adminReport && <AdminReportOverlay />}
      {user?.screen?.alertManager?.isOpen && <AlertManager />}
      <SigviewSnackbar
        open={user.screen.snackbar.open}
        message={user.screen.snackbar.message}
        iconFlag={user.screen.snackbar.iconFlag}
        severity={user.screen.snackbar.severity}
        onClose={snackbarOnclose}
      />
      <OverlayDialog />
      <SigviewDialog />
      <DialogAlert />
      <ToastContainer autoClose={false} />
      {isBigDialogOpen && <LayoutBigDialog />}
    </>
  );
}

SigviewCommon.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(SigviewCommon);
