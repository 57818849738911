import C from "../constants";

export default function changeFlag(state = {}, payload) {
  let { type, key, data } = payload;
  switch (type) {
    case C.UPDATE_CHANGE_FLAG:
      return {
        ...state,
        [key]: data,
      };
    default:
      return state;
  }
}
