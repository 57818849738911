// * Import required libraries
import React, { Children, useContext } from "react";

// * import lib component
import { Box } from "@material-ui/core";

// Import contexts
import { ThemeContext } from "../../../js/contexts/ThemeContext";
import { UserConsumer } from "../../contexts/AdminContext";

function LayoutAdminAttributes(props) {
  // * Destructure props
  const { children } = props;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // Styles
  const box1 = {
    height: "calc(100% - 84px)",
    width: "100%",
    boxSizing: "border-box",
    padding: "10px 25px 0px 25px",
  };
  const box2 = {
    height: "100%",
    width: "100%",
    border: `1px solid ${themeColors["secondaryColorLighter"]}`,
    boxSizing: "border-box",
    //   padding: "10px",
    display: "flex",
    flexDirection: "row",
  };
  const box3 = {
    height: "100%",
    width: "250px",
    borderRight: `1px solid ${themeColors["secondaryColorLighter"]}`,
    boxSizing: "border-box",
    paddingTop: "10px",
  };
  const box4 = {
    height: "100%",
    width: "calc(100% - 250px)",
    boxSizing: "border-box",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
  };

  return (
    <Box css={box1}>
      <Box css={box2}>
        <Box css={box3}>{children[0]}</Box>
        <Box css={box4}>{children[1]}</Box>
      </Box>
    </Box>
  );
}

export default LayoutAdminAttributes;
