import C from "../redux/constants";
import { config } from "../config/config";
import SIGVIEW_CONTANTS from "../constants/sigviewConstants";
import standaloneMsv from "../redux/reducers/standaloneMsv";

const defaultQeData = SIGVIEW_CONTANTS.defaultQeData;
const defaultKpiData = SIGVIEW_CONTANTS.defaultKpiData;

const getInitialMsvForm = (props = {}) => {
  // * Destructure props
  const {
    workspaceSelections: wsData = {},
    msvTableId = "",
    themeState = {},
  } = props;

  // * Define required variables
  var allowedKey = [
    "id",
    "name",
    "rollingDateType",
    "metricChartGranularity",
    "dimTablesGridViewType",
    "selectedKpis",
    "metricChartType",
    "type",
    "layout",
    "actualPayload",
    "dimensionFilters",
    "timeFilters",
    "selectedDimTableMetrics",
    "orderById",
    "selectedDimensions",
    "metricGranData",
    "utils",
    "screen",
    "dataLimit",
    "orderByType",
    "orderById",
    "orderBy",
    "globalSort",
  ];
  var kpisQEObj = {};
  var reloadEpochObj = {};
  const msvTableIdFilterValues =
    wsData.dimensionFilters.value.find((el) => el.id === msvTableId)?.values ||
    [];
  const isAnyFilterAppliedOnMsvId = msvTableIdFilterValues.length > 0;
  const msvColorArr = config.hardCoded.msvColorArr[themeState.activeTheme];
  var selectedTableItemInChartVal = [];
  var allSelectedTableItemVal = [];
  var msvColorMappingVal = {};
  var filteredWsform = Object.keys(wsData)
    .filter((key) => allowedKey.includes(key))
    .reduce((obj, key) => {
      obj[key] = wsData[key];
      return obj;
    }, {});
  var msvTableVal = wsData.selectedDimensions.value[msvTableId];

  // * Update below keys:
  // 1. kpisQEObj
  // 2. selectedTableItemInChartVal
  // 3. allSelectedTableItemVal
  // 4. msvColorMappingVal
  if (!isAnyFilterAppliedOnMsvId) {
    for (const selectedKpi in wsData.selectedKpis.value) {
      const chartkey =
        selectedKpi +
        "_chart_" +
        wsData.timeFilters.value.selectedDatesQE.startDate +
        "_" +
        wsData.timeFilters.value.selectedDatesQE.endDate;
      const trueDelta = selectedKpi + "_trueDelta";
      const deltaPercentage = selectedKpi + "_deltaPercentage";

      kpisQEObj = {
        ...kpisQEObj,
        [selectedKpi]: defaultKpiData,
        [chartkey]: defaultQeData,
        [trueDelta]: defaultKpiData,
        [deltaPercentage]: defaultKpiData,
      };
      reloadEpochObj = {
        ...reloadEpochObj,
        [selectedKpi]: Date.now(),
        [chartkey]: Date.now(),
      };
    }
  } else {
    // Define required variables
    allSelectedTableItemVal = [...msvTableIdFilterValues];
    selectedTableItemInChartVal = allSelectedTableItemVal.slice(
      Math.max(allSelectedTableItemVal.length - 5, 0)
    );

    // Update msvColorMappingVal
    for (const index in selectedTableItemInChartVal) {
      const msvItem = selectedTableItemInChartVal[index];
      const msvColor = msvColorArr[index];
      msvColorMappingVal[msvItem] = msvColor;
    }

    for (const selectedKpi in wsData.selectedKpis.value) {
      // * Add Metric Keys
      // ! Adding these keys just for parity; they are not required in msv metric chart
      const trueDelta = selectedKpi + "_trueDelta";
      const deltaPercentage = selectedKpi + "_deltaPercentage";

      kpisQEObj = {
        ...kpisQEObj,
        [selectedKpi]: defaultKpiData,
        [trueDelta]: defaultKpiData,
        [deltaPercentage]: defaultKpiData,
      };
      reloadEpochObj = {
        ...reloadEpochObj,
        [selectedKpi]: Date.now(),
      };

      // * MSV Selected Items
      for (const msvItem of selectedTableItemInChartVal) {
        const chartkey = selectedKpi + "_chart_" + msvItem;

        kpisQEObj = {
          ...kpisQEObj,
          [chartkey]: defaultQeData,
        };
        reloadEpochObj = {
          ...reloadEpochObj,
          [chartkey]: Date.now(),
        };
      }
    }
  }

  // * Add Dimension Rows in dataQE and reloadEpochs
  kpisQEObj = {
    ...kpisQEObj,
    [msvTableId]: defaultQeData,
  };
  reloadEpochObj = { ...reloadEpochObj, [msvTableId]: Date.now() };

  // * Add allMetricCharts, allMetricCharts in reloadEpochs
  reloadEpochObj = {
    ...reloadEpochObj,
    allMetrics: Date.now(),
    allMetricCharts: Date.now(),
  };

  // * Making final initial msv form
  let initialMsvForm = {
    ...filteredWsform,
    // selectedTableItem: {
    //   message: "",
    //   value: [], // TODO
    //   status: "",
    // },
    dataQE: { value: kpisQEObj, status: "valid", message: "" },
    reloadEpochs: { value: reloadEpochObj, status: "valid", message: "" },
    allSelectedTableItem: {
      message: "",
      value: allSelectedTableItemVal,
      status: "",
    },
    selectedTableItemInChart: {
      value: selectedTableItemInChartVal,
      status: "valid",
      message: "",
    },
    msvColorMapping: {
      value: msvColorMappingVal,
      status: "valid",
      message: "",
    },
    workspaceSelections: {
      value: { ...wsData, msv: {} },
      status: "valid",
      message: "",
    },
    msvTable: {
      value: msvTableVal,
      status: "valid",
      message: "",
    },
    isItMsvForm: { value: true, status: "valid", message: "", stepTag: "" },
    activeMsvTableId: {
      message: "",
      status: "valid",
      value: msvTableId,
    },
    regexSearchField: {
      value: "",
      status: "valid",
      message: "",
    },
    regexSearchCheckAll: {
      value: false, // true/false
      status: "valid",
      message: "",
    },
  };

  // * Validate state using validation functions from standaloneWs
  let reducerReturendState = standaloneMsv(initialMsvForm, {
    type: C.NO_ACTION,
    payload: {},
  });

  // * DEBUGGER
  // console.groupCollapsed("getInitialMsvForm");
  // console.log("wsData", wsData);
  // console.log("initialMsvForm", initialMsvForm);
  // console.log("reducerReturendState", reducerReturendState);
  // console.groupEnd();

  return reducerReturendState;
};

const getListOfMsvKeysForWhichStatusIsLoadingInDataQe = (wsForm) => {
  // * Define required variables
  var listOfMsvKeysForWhichStatusIsLoadingInDataQe = [];
  const objectEntries = Object.entries(wsForm.dataQE.value);

  for (const [key, value] of objectEntries) {
    const isMsvKey = key.includes("_chart_");
    const isStatusLoading = value.status === "loading";
    if (isMsvKey && isStatusLoading) {
      listOfMsvKeysForWhichStatusIsLoadingInDataQe.push(key);
    }
  }
  return listOfMsvKeysForWhichStatusIsLoadingInDataQe;
};

const getListOfDimensionValuesForWhichGetDataCallIsRequired = (msvKeyArr) => {
  // input = ["M001_chart_Woobi", "CM001_chart_Woobi"]
  // output = ["Woobi"]

  // * Define required variables
  var dimensionValues = [];

  for (const msvKey of msvKeyArr) {
    const dimensionValue = msvKey.replace(/[CM]{1,2}[0-9]{1,3}_chart_/g, "");
    dimensionValues.push(dimensionValue);
  }

  // Taking unique values
  dimensionValues = [...new Set(dimensionValues)];

  return dimensionValues;
};

const getLoadingStatusForMsvMetricChart = (wsForm, metricId) => {
  const dataQEAll = wsForm.dataQE.value;
  const selectedTableItemInChart = wsForm.selectedTableItemInChart.value;
  let partialLoading = selectedTableItemInChart.some(
    (el) => dataQEAll[metricId + "_chart_" + el].status === "loading"
  );
  let partialSuccess = selectedTableItemInChart.some(
    (el) => dataQEAll[metricId + "_chart_" + el].status === "success"
  );
  let fullLoading = selectedTableItemInChart.every(
    (el) => dataQEAll[metricId + "_chart_" + el].status === "loading"
  );
  let fullSuccess = selectedTableItemInChart.every(
    (el) => dataQEAll[metricId + "_chart_" + el].status === "success"
  );
  let anyError = selectedTableItemInChart.some(
    (el) => dataQEAll[metricId + "_chart_" + el].status === "error"
  );

  return { partialLoading, partialSuccess, fullLoading, fullSuccess, anyError };
};

let regexSearchFieldValidation = (value) => {
  if (value === "" || value.length >= 2) {
    return true;
  }

  return false;
};

const wrapperMsvFilters = (response) => {
  let wrappedResponse = response.map((row) => {
    if (row.absoluteChange !== "id_only") {
      let predecessor = row.absoluteChange.split("_")[1];

      return {
        ...row,
        metricId: row.metricId + "_" + predecessor,
      };
    } else {
      return row;
    }
  });

  return wrappedResponse;
};

const unwrapperMsvFilters = (response) => {
  let unwrappedResponse = response.map((row) => {
    if (row.absoluteChange !== "id_only") {
      let accessor = row.metricId.split("_")[0];

      return {
        ...row,
        metricId: accessor,
      };
    } else {
      return row;
    }
  });
  return unwrappedResponse;
};

const createResizableTable = function (props = {}) {
  const { table = {}, themeColors = {} } = props;

  const cols = table.querySelectorAll(".firstHeader");

  [].forEach.call(cols, function (col) {
    // Add a resizer element to the column
    const resizer = document.createElement("div");

    resizer.classList.add("resizer");

    // Set the height
    // resizer.style.cssText =
    //   "height: 5px; width:15px; padding:0px; background:red; display:inline-block; cursor: col-resize;";
    resizer.style.cssText = ` width: 0;height: 0;border-bottom: 8px solid ${themeColors["secondaryColorLight"]};border-left: 8px solid transparent; cursor: e-resize; display: inline-block`;
    resizer.title = `Dimension Name Resizer`;
    // let resizerCount = table.querySelectorAll(".resizer");
    // if (resizerCount.length === 0) col.appendChild(resizer)

    col.appendChild(resizer);

    createResizableColumn(col, resizer);
  });
};

const createResizableColumn = function (col, resizer) {
  let x = 0;
  let w = 0;

  const mouseDownHandler = function (e) {
    x = e.clientX;

    const styles = window.getComputedStyle(col);

    w = parseInt(styles.minWidth, 10);

    if (w <= 500) {
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);

      resizer.classList.add("resizing");
    }
  };

  const mouseMoveHandler = function (e) {
    const dx = e.clientX - x;

    if (dx > 180 - w) {
      if (w + dx <= 500) col.style.minWidth = `${w + dx}px`;
      else col.style.minWidth = `500px`;
    } else col.style.minWidth = `180px`;
  };

  const mouseUpHandler = function () {
    resizer.classList.remove("resizing");
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);
  };

  resizer.addEventListener("mousedown", mouseDownHandler);
};

export {
  getInitialMsvForm,
  getListOfMsvKeysForWhichStatusIsLoadingInDataQe,
  getListOfDimensionValuesForWhichGetDataCallIsRequired,
  getLoadingStatusForMsvMetricChart,
  regexSearchFieldValidation,
  wrapperMsvFilters,
  unwrapperMsvFilters,
  createResizableTable,
};
