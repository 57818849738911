// * Import required libraies
import React, { useEffect, useState, memo } from "react";
import Plotly from "plotly.js";
import _ from "underscore";
import moment from "moment";

// * Import utils
import { appendMetricSym } from "../../../utils/plotlyUtils";

// * Define required functions
const makePlotlyParams = (props = {}) => {
  const makePlotlyData = (props = {}) => {
    const {
      accessor = "",
      chartType = "line",
      granularity = "hour",
      selectedTimezone = {},
      metricObj,
      selectedTableItemInChart = [],
      dataForSelectedTableItemInChart = [],
      msvColorMapping = {},
    } = props;

    // * Define required variables
    var minY = 0;
    var maxY = 0;
    var plotlyType = undefined;
    var plotlyFill = undefined;
    var plotlyMode = undefined;
    var plotlyBarMode = undefined;
    switch (chartType) {
      case "line":
        plotlyType = "scatter";
        plotlyMode = "lines+markers";
        break;
      case "bar":
        plotlyType = "bar";
        plotlyMode = "bar+markers";

        break;
      case "area":
        plotlyType = "scatter";
        plotlyFill = "tozeroy";
        break;
      case "multiline":
        plotlyType = "scatter";
        plotlyMode = "lines+markers";
        break;
      case "groupedbar":
        plotlyType = "bar";
        plotlyBarMode = "group";
        break;
      case "verticalstackedbar":
        plotlyType = "bar";
        plotlyBarMode = "stack";
        break;
      default:
    }

    // * Making xAxisCommon
    const minutesOffset = selectedTimezone.minutesOffset;

    var plotlyData = [];
    const metricId = metricObj._id;
    // * Adding lines for selected items in MSV Table
    for (const filterName of selectedTableItemInChart) {
      const msvDataQeKey = `${metricId}_chart_${filterName}`;
      const dataQeItem = dataForSelectedTableItemInChart[msvDataQeKey];
      const color = msvColorMapping[filterName];

      const runFlag = dataQeItem.status === "success";
      if (runFlag) {
        var xAxis = dataQeItem.result.dataFromQE.map((row) => {
          const epoch = Number(row[granularity]);
          const format = "YYYY-MM-DD HH:mm:ss";
          var formattedValue = moment(epoch)
            .utcOffset(minutesOffset)
            .format(format);
          switch (granularity) {
            case "hour":
              formattedValue = moment(epoch)
                .utcOffset(minutesOffset)
                .format(format);
              break;
            case "day":
              formattedValue = moment(epoch)
                .utcOffset(minutesOffset)
                .format(format);
              break;
            case "month":
              formattedValue = moment(epoch)
                .utcOffset(minutesOffset)
                .format(format);
              break;
            default:
              formattedValue = moment(epoch)
                .utcOffset(minutesOffset)
                .format(format);
          }
          return formattedValue;
        });

        let yPrimary = [];
        let textPrimary = [];
        for (const row of dataQeItem.result.dataFromQE) {
          const epoch = Number(row[granularity]);
          var format = "MM-DD-YYYY HH:mm:ss";
          var formattedDimValue = moment(epoch)
            .utcOffset(minutesOffset)
            .format(format);
          switch (granularity) {
            case "hour":
              formattedDimValue = moment(epoch)
                .utcOffset(minutesOffset)
                .format(format);
              break;
            case "day":
              format = "MM-DD-YYYY";
              formattedDimValue = moment(epoch)
                .utcOffset(minutesOffset)
                .format(format);
              break;
            case "month":
              format = "MM-YYYY";
              formattedDimValue = moment(epoch)
                .utcOffset(minutesOffset)
                .format(format);
              break;
            default:
              formattedDimValue = moment(epoch)
                .utcOffset(minutesOffset)
                .format(format);
          }
          const formattedMetricValue = appendMetricSym(
            row[accessor],
            metricObj.dataUnit,
            true
          );
          const value = Number(row[accessor]);
          if (value < minY) minY = value;
          if (value > maxY) maxY = value;
          yPrimary.push(row[accessor]);

          textPrimary.push(
            `${filterName}<br>${formattedDimValue}<br>${formattedMetricValue}`
          );
        }

        const primaryObj = {
          y: yPrimary,
          x: xAxis,
          // name: "29th to 30th",
          type: plotlyType,
          mode: plotlyMode,
          fill: plotlyFill,
          text: textPrimary,
          hoverinfo: "text",

          marker: {
            color: color,
            size: 4,
          },
          line: {
            color: color,
            shape: "spline",
          },
        };
        plotlyData.push(primaryObj);
      }
    }

    // * Defining dynamic range based on values so that the yAxis doesn't start from 0
    const range = maxY - minY;
    var offsetPer = 0.1;
    var yRange = [
      Math.round(minY - offsetPer * range),
      Math.round(maxY + offsetPer * range),
    ];
    if (range <= 1) {
      offsetPer = 0.2;
      var yRange = [minY - offsetPer * range, maxY + offsetPer * range];
    } else if (range > 1 && range <= 5) {
      offsetPer = 0.2;
      var yRange = [minY - offsetPer * range, maxY + offsetPer * range];
    }
    // console.log("maxY", maxY);
    // console.log("minY", minY);
    // console.log(
    //   "Math.round(minY - 0.1 * range)",
    //   Math.round(minY - 0.1 * range)
    // );
    // console.log(
    //   "Math.round(maxY - 0.1 * range)",
    //   Math.round(maxY - 0.1 * range)
    // );
    // console.log("range", range);
    // console.log("yRange", yRange);
    // console.log("offsetPer", offsetPer);
    // console.log("######################");

    return { plotlyData, yRange: yRange };
  };
  const makePlotlyLayout = (props = {}) => {
    const {
      chartContainer,
      chartType = "line",
      granularity = "hour",
      yRange = [0, 10000],
      themeColors,
      metricChartYaxisRangeType = "auto",
    } = props;

    // * Define required variables
    var xAxisType = "date";
    switch (granularity) {
      case "hour":
        xAxisType = "hour";
        break;
      case "day":
        xAxisType = "date";
        break;
      case "month":
        xAxisType = "month";
        break;
      default:
        xAxisType = "date";
    }
    var plotlyType = undefined;
    var plotlyFill = undefined;
    var plotlyMode = undefined;
    var plotlyBarMode = undefined;
    switch (chartType) {
      case "line":
        plotlyType = "scatter";
        plotlyMode = "lines+markers";
        break;
      case "bar":
        plotlyType = "bar";
        break;
      case "area":
        plotlyType = "scatter";
        plotlyFill = "tozeroy";
        break;
      case "multiline":
        plotlyType = "scatter";
        plotlyMode = "lines+markers";
        break;
      case "groupedbar":
        plotlyType = "scatter";
        plotlyMode = "lines+markers";
        plotlyBarMode = "group";
        break;
      case "verticalstackedbar":
        plotlyType = "bar";
        plotlyMode = "stack";
        plotlyBarMode = "stack";
        break;
      default:
    }

    var plotlyLayout = {
      xaxis: {
        tickangle: 0,
        showticklabels: true,
        showline: true,
        tickfont: {
          family: "Fira Sans",
          size: 10,
          color: themeColors["secondaryColorLight"],
        },
        zeroline: false,
        linecolor: themeColors["secondaryColorLight"],
        gridcolor: "#eff2f5",
        type: xAxisType,
        autorange: true,
        fixedrange: true,
      },
      yaxis: {
        showticklabels: true,
        tickangle: 0,
        showline: true,
        zeroline: false,
        tickfont: {
          family: "Fira Sans",
          size: 10,
          color: themeColors["secondaryColorLight"],
        },
        linecolor: themeColors["secondaryColorLight"],
        gridcolor: "#eff2f5",
        rangemode: "tozero",
        type: "linear",
        fixedrange: true,
      },
      height: chartContainer.offsetHeight,
      width: chartContainer.offsetWidth,
      plot_bgcolor: "inherit",
      paper_bgcolor: "inherit",
      modebar: {
        bgcolor: "transparent",
        color: "#c5cfd9",
        activecolor: "#46596A",
      },
      showlegend: false,
      legend: {
        font: {
          family: "Fira Sans",
          size: 10,
          color: "#46596A",
        },
        bgcolor: "inherit",
        orientation: "v",
      },
      margin: {
        l: 25,
        b: 30,
        r: 45,
        t: 20,
      },
      hovermode: "closest",
      ...(plotlyBarMode ? { barmode: plotlyBarMode } : {}),
    };

    // * NEW FEATURE where the y-axis range is auto or dynamic
    if (metricChartYaxisRangeType === "auto") {
      plotlyLayout.yaxis.autorange = true;
    } else {
      plotlyLayout.yaxis.range = yRange;
    }

    return plotlyLayout;
  };
  const makePlotlyConfigurations = (props = {}) => {
    const { isMetricChartOpen = false } = props;
    const plotlyConfigurations = {
      showLink: false,
      modeBarButtonsToRemove: [
        "editable",
        "sendData",
        "sendDataToCloud",
        "lasso2d",
        "select2d",
        "zoomIn2d",
        "zoomOut2d",
        "autoScale2d",
      ],
      displaylogo: false,
      displayModeBar: isMetricChartOpen,
      // displayModeBar: "hover",
      sendData: false,
    };
    return plotlyConfigurations;
  };

  const { plotlyData, yRange } = makePlotlyData(props);
  var plotlyLayout = makePlotlyLayout({ ...props, yRange });
  var plotlyConfigurations = makePlotlyConfigurations();
  return { plotlyData, plotlyLayout, plotlyConfigurations };
};

function MsvMetricChart(props) {
  // * Destructure Props
  const { memoizedStringedChildrenProps = "" } = props;
  const childrenProps = JSON.parse(memoizedStringedChildrenProps);
  const {
    chartContainerId = "",
    isMetricChartOpen = false,
    chartType = "line",
    themeState,
    selectedTableItemInChart = [],
    dataForSelectedTableItemInChart = [],
    msvColorMapping = {},
  } = childrenProps;
  console.log(
    "%c MsvMetricChart - Re-Rendered",
    "color:purple; font-size: 20px"
  );

  // * Defining required state
  const [windowResize, setWindowResized] = useState(false);

  // * Defining required side effects
  // Plot the charts
  useEffect(() => {
    //Remove child element (plotly container) if it exists
    var plotlyChartContainer = document
      .getElementById(chartContainerId)
      .getElementsByClassName("plot-container");
    if (plotlyChartContainer.length) {
      plotlyChartContainer[0].remove();
    }

    const myDiv = document.getElementById(chartContainerId);
    if (myDiv) {
      // Add style of height 100% just before plotting
      // Hack to bring the loader in the center
      //   if (resizeFlag !== undefined) myDiv.style.height = "100%";
      // try {
      const plotlyParams = makePlotlyParams({
        ...childrenProps,
        chartContainer: myDiv,
      });

      Plotly.newPlot(
        myDiv,
        plotlyParams.plotlyData,
        plotlyParams.plotlyLayout,
        plotlyParams.plotlyConfigurations
      );
      // } catch (err) {
      //   console.error("UI ERROR");
      //   console.groupCollapsed("DETAILS");
      //   console.log("Something went wrong in WsMetricChart.js");
      //   console.log("error", err);
      //   console.groupEnd();
      // }
    }
  }, [
    windowResize,
    themeState,
    chartType,
    isMetricChartOpen,
    selectedTableItemInChart,
    dataForSelectedTableItemInChart,
    msvColorMapping,
  ]);

  // Remove the chart container when the component unmounts
  useEffect(() => {
    return () => {
      //Remove child element (plotly container) if it exists
      var chartContainer = document.getElementById(chartContainerId);
      if (chartContainer) {
        var plotlyChartContainer =
          chartContainer.getElementsByClassName("plot-container");
        if (plotlyChartContainer.length) {
          plotlyChartContainer[0].remove();
        }
      }
    };
  }, []);

  //Add resize event listener
  useEffect(() => {
    function handlePlotResize() {
      setWindowResized(new Date().valueOf());
    }
    window.addEventListener("resize", handlePlotResize);

    return function cleanupListener() {
      window.removeEventListener("resize", handlePlotResize);
    };
  }, []);

  return <></>;
}

export default MsvMetricChart;
// ! COMMENTING OUT THE MEMOIZATION AS THE HOVER CHART TOOLTIP FUNC IS AFFECTING
// ! FIX THAT BUG IF YOU WANT TO IMPLEMENT MEMOIZATION
// export default memo(MsvMetricChart);
