import { Box } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import SigviewBreadcrumb from "../../components/Common/SigviewBreadcrumb";
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler";
import Loader from "../../components/Loader/Loader";
import { config } from "../../config/config";
import LayoutTopSideBottomBucket from "../../layouts/LayoutTopSideBottomBucket/LayoutTopSideBottomBucket";
import { updateUserScreen } from "../../redux/actions";
import { fetchBucket, fetchBucketList } from "../../services/api";
import {
  getBucketBreadcrumbsData,
  unwrapperBucket,
} from "../../utils/bucketUtils";
import ManageBuckets from "./ManageBuckets";
import RegisterBucket from "./RegisterBucket";

function BucketContainer(props) {
  const { dispatch: ReduxDispatcher, user = {}, allData = {} } = props;

  const [bucketList, setBucketList] = useState({
    result: [],
    status: "initial",
    message: "",
  });
  const [reloadList, setReloadList] = useState(false);

  const [reloadBucketData, setReloadBucketData] = useState(Date.now());

  useEffect(() => {
    //Make fetch call using axios
    const fetchBucketListPromise = fetchBucketList({});
    fetchBucketListPromise
      .then((data) => {
        let newData = {
          result: data.result.data,
          status: "success",
          messsage: "",
        };
        setBucketList(newData);

        if (newData.result.length > 0) {
          var action = updateUserScreen("bucketScreen", {
            type: "manageBucket",
            payload: {},
          });
          ReduxDispatcher(action);
        } else {
          var action = updateUserScreen("bucketScreen", {
            type: "registerBucket",
            payload: {},
          });
          ReduxDispatcher(action);
        }
      })
      .catch((json) => {
        let newData = {
          result: [],
          status: "error",
          messsage: "Fetch bucketList API failed",
        };
        setBucketList(newData);

        // ReduxDispatcher(updateData("views", newData));
      });
  }, [reloadBucketData]);

  const handleClickRow = (value) => {
    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));

    const id = value._id;
    const fetchBucketPromise = fetchBucket({ id });
    fetchBucketPromise
      .then((data) => {
        var action = updateUserScreen("bucketScreen", {
          type: "updateBucket",
          payload: { ...unwrapperBucket(data.result.data) },
        });
        ReduxDispatcher(action);
        ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
      })
      .catch((json) => {
        console.log("API FAILED");

        ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Failed to open bucket details",
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };
  const history = useHistory();
  const activeTab = user.screen.activeTab;
  const handleActiveTab = (e, id) => {
    var action = updateUserScreen("bucketScreen", {
      type: id.id,
      payload: {},
    });
    ReduxDispatcher(action);

    console.log("id", id);

    history.push(`/${id.id}`);
  };
  const layoutTopSideBottomBucketProps = {
    activeTab,
    handleActiveTab,
  };

  const handleBucketDataReload = () => {
    setReloadBucketData(Date.now());
  };

  return (
    <LayoutTopSideBottomBucket {...layoutTopSideBottomBucketProps}>
      <Box css={{ padding: "20px 0px 10px 0px" }}>
        <SigviewBreadcrumb
          data={getBucketBreadcrumbsData(user.screen.bucketScreen.type)} //data
          onClick={handleActiveTab} //onClick
        />
      </Box>

      {(bucketList.status === "loading" || bucketList.status === "initial") && (
        <Loader />
      )}
      {bucketList.status === "error" && (
        <ErrorHandler
          message={bucketList.message || config.hardCoded.uiErrorMessage}
          reloadFlag={true}
          onReload={handleBucketDataReload}
        />
      )}

      {bucketList.status === "success" && (
        <>
          {user.screen.bucketScreen.type === "manageBucket" && (
            <ManageBuckets
              bucketList={bucketList}
              // handleregisterBucket={handleregisterBucket}
              handleClickRow={handleClickRow}
            />
          )}
          {(user.screen.bucketScreen.type === "registerBucket" ||
            user.screen.bucketScreen.type === "updateBucket") && (
            <RegisterBucket setReloadBucketData={setReloadBucketData} />
          )}
        </>
      )}
    </LayoutTopSideBottomBucket>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(BucketContainer);
