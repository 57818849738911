// Import required libraies
import React, { useState, useRef, useEffect } from "react";
import { v4 } from "uuid";

//import library components
import { makeStyles } from "@material-ui/core";

// Import Custom Component
import AppCard from "../AppCard";
import SigviewButton from "../Common/SigviewButton";
import SigviewTypography from "../Common/SigviewTypography";

// Import utils & data
import { getElementContentWidth } from "../../utils/utils";

// Defining styles
const useSigviewStyles = makeStyles(() => {
  const styles = {
    mainContainer: {
      padding: "25px",
      "&.title-row-less": { paddingTop: "5px" },
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
    },
    itemsContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, 180px)",
      justifyItems: "start",
      gridGap: "12px",
    },
    renderPropContainer: {
      display: "flex",
      width: "100%",
      height: "125px",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  return styles;
});

function AppRow(props) {
  // Destructing props
  const {
    title,
    onButtonClick,
    titleRowFlag,
    buttonRowFlag,
    singleRowFlag,
    data,
    buttonTitle = "Show All",
    renderProps = { flag: false, component: () => {} },
  } = props;

  // Defining required states
  const containerId = useRef(`appRow_${v4()}`);
  const [sliceCount, setSliceCount] = useState(data.length);

  // Defining required side effects
  useEffect(() => {
    // Defining required function
    const handleWindowSize = () => {
      // Should come from config
      const appCardWidth = 180;
      const appRowGridGap = 12;

      // Getting slice count based on width
      const appRowEl = document.getElementById(containerId.current);
      const appRowContentWidth = getElementContentWidth(appRowEl);
      const newSliceCount = Math.floor(
        appRowContentWidth / (appCardWidth + appRowGridGap)
      );

      // Setting slice count
      setSliceCount(newSliceCount);
    };

    // Do this only when singleRowFlag is true
    if (singleRowFlag) {
      // Call the function when component mounts
      handleWindowSize();

      // Add event listener to window resize
      window.addEventListener("resize", handleWindowSize);
    }

    return () => {
      // Remove event listener to window resize
      window.removeEventListener("resize", handleWindowSize);
    };
  }, []);
  // Update slice count everytime data changes
  useEffect(() => {
    if (!singleRowFlag) setSliceCount(data.length);
  }, [data]);

  // Defining required static variables
  let {
    mainContainer,
    titleContainer,
    itemsContainer: itemsContainerClassName,
    renderPropContainer,
  } = useSigviewStyles();
  if (!titleRowFlag) mainContainer += " title-row-less";
  if (renderProps.flag) itemsContainerClassName = renderPropContainer;

  var datastoryStateTitleClass = title.replace(/ +/g, "");

  return (
    <div className={mainContainer} id={containerId.current}>
      {titleRowFlag && (
        <section className={titleContainer}>
          <SigviewTypography variant="pLarger">{title}</SigviewTypography>
          {buttonRowFlag && (
            <SigviewButton
              variant="containedLighter"
              onClick={onButtonClick}
              title={buttonTitle}
              customClassName={`${datastoryStateTitleClass}SeeAll-GA`}
            />
          )}
        </section>
      )}
      <section className={itemsContainerClassName}>
        {renderProps.flag && renderProps.component()}
        {!renderProps.flag &&
          data
            .slice(0, sliceCount)
            .map((row) => (
              <AppCard
                datastoryStateTitleClass={datastoryStateTitleClass}
                key={row.id}
                {...row}
              />
            ))}
      </section>
    </div>
  );
}

export default AppRow;
