import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import isEqual from "lodash.isequal";
import axios from "axios";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from "@material-ui/core";
import { format as d3Format } from "d3";

// Import custom components
import SigviewProgressBar from "./../../../components/Common/SigviewProgressBar";
import SigviewButton from "../../../components/Common/SigviewButton";
import Loader from "../../../components/Loader/Loader";
import SigviewIcon from "../../../components/Common/SigviewIcon";
// * Import utils/data
import { wrapperChartObject } from "../../../utils/chartObjectUtils";
import {
  updatePivotAdvanceSort,
  updatePivotReloadEpoch,
} from "../../../redux/actions";
import {
  getSortDetails,
  updateRowData,
  makeSigviewStyles,
  makeUiFriendlyDimensionFilters,
  updateChildrenShowMoreLoading,
  updateChildrenData,
  formatDimValue,
  extractOrderFromAdvanceSort,
  getComparisonHeaderColumns,
  updateChildrenDataRecursively,
  updateChildrenLoading,
} from "../../../utils/pivotUtils";

// Import API functions
import { getData, getComparisonData } from "../../../services/api";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";
import { appendMetricSym } from "../../../utils/plotlyUtils";

// Defining required constants
const initialPivotData = { result: [], status: "loading", message: "" };

// Function to make the row ui friendly(unique for Comparision table)
const totalRowUiDataFriendly = (rowUiDataProps = {}) => {
  const { getDataResponse, columnData, columnIndex } = rowUiDataProps;
  const currentcoldata = columnData[columnIndex];
  const newRowData = getDataResponse.map((dataArr, index) => {
    let metricData = { [currentcoldata[0].accessor]: "Total" };
    for (let i = 1; i < currentcoldata.length; i++) {
      const temp = {
        [currentcoldata[i].normal.accessor]:
          dataArr[currentcoldata[i].normal.accessor],
        [currentcoldata[i].deltaPercentage.accessor]:
          dataArr[currentcoldata[i].deltaPercentage.accessor],
        [currentcoldata[i].trueDelta.accessor]:
          dataArr[currentcoldata[i].trueDelta.accessor],
      };
      metricData = { ...metricData, ...temp };
    }
    const rowData = {
      [currentcoldata[0].accessor]: dataArr[currentcoldata[0].accessor],
      ...metricData,
      isLastChild: false, // for showing plus sign
      isExpanded: false, // plus or minus icon flag
      isChildrenLoading: false, //is there a loading sign or not
      isClickButton: false,
      isShowMore: false,
      level: columnIndex,
      children: [],
      isError: false,
    };
    return rowData;
  });
  return newRowData;
};

const rowUiDataFriendly = (rowUiDataProps = {}) => {
  const {
    getDataResponse,
    columnData,
    columnIndex,
    dataLimit,
    newDimensionInfo = "",
    dimensionInfo = [],
  } = rowUiDataProps;

  //   // * DEBUGGER
  //   console.groupCollapsed("rowUiDataFriendly");
  //   console.log("columnIndex", columnIndex);
  //   console.log("getDataResponse", getDataResponse);
  //   console.log("newDimensionInfo", newDimensionInfo);
  //   console.log("dimensionInfo", dimensionInfo);
  //   console.groupEnd();

  const currentcoldata = columnData[columnIndex];
  const newRowData = getDataResponse.map((dataArr, index) => {
    let metricData = {};
    for (let i = 1; i < currentcoldata.length; i++) {
      const temp = {
        [currentcoldata[i].normal.accessor]:
          dataArr[currentcoldata[i].normal.accessor],
        [currentcoldata[i].deltaPercentage.accessor]:
          dataArr[currentcoldata[i].deltaPercentage.accessor],
        [currentcoldata[i].trueDelta.accessor]:
          dataArr[currentcoldata[i].trueDelta.accessor],
      };
      metricData = { ...metricData, ...temp };
    }
    const rowData = {
      [currentcoldata[0].accessor]: dataArr[currentcoldata[0].accessor],
      ...metricData,
      isLastChild: false, // for showing plus sign
      isExpanded: false, // plus or minus icon flag
      isChildrenLoading: false, //is there a loading sign or not
      isClickButton: columnData.length > columnIndex + 1 ? true : false,
      isShowMore:
        getDataResponse.length === dataLimit &&
        index === getDataResponse.length - 1
          ? true
          : false,
      level: columnIndex,
      dataLimit: dataLimit,
      parentInfo: [...dimensionInfo, newDimensionInfo],
      children: [],
    };
    return rowData;
  });
  return newRowData;
};

function PivotTableRow(props = {}) {
  const {
    row,
    columns,
    pivotData,
    setPivotData,
    selections,
    user,
    allData,
    globalFilters,
    totalLength = 0,
    rowIndex = 0,
  } = props;

  const level = row.level;
  const actualColumns = columns[level];

  // * Function run after clicking the Error button form the table
  const handleError = (event, { row, columns }) => {
    // * DEBUGGER
    // console.groupCollapsed("handleError");
    // console.log("row", row);
    // console.log("columns", columns);
    // console.groupEnd();
    if (row.level === 0) {
      const extractOrderFromAdvanceSortProps = {
        advanceSort: selections.advanceSort,
        dimension: selections.dimensionsList[0],
        allData,
      };
      // console.log(
      //   "extractOrderFromAdvanceSortProps",
      //   extractOrderFromAdvanceSortProps
      // );
      const orderDetails = extractOrderFromAdvanceSort(
        extractOrderFromAdvanceSortProps
      );
      var source1 = axios.CancelToken.source();
      var newSelections = {
        ...selections,
        dimensionsList: [selections.dimensionsList[row.level]],
        dataLimit: selections.dataLimit,
        granularity: globalFilters?.metricChartGranularity?.value,
        ...orderDetails,
      };
      var props = { selections: newSelections, user };
      var getDataPayload = wrapperChartObject(props);
      var fetchProps = {
        payload: getDataPayload,
        cancelToken: source1.token,
      };
      var getDataPromise = getComparisonData(fetchProps);
      getDataPromise
        .then((data) => {
          setPivotData((latestState) => {
            const rowUiDataProps = {
              getDataResponse: data.result.data,
              columnData: columns,
              columnIndex: row.level,
              dataLimit: selections.dataLimit,
            };
            const updatedPivotData = rowUiDataFriendly(rowUiDataProps);

            return {
              result: updatedPivotData,
              status: "success",
              message: "",
            };
          });
        })
        .catch((json) => {
          const rowUiDataProps = {
            getDataResponse: [],
            columnData: columns,
            columnIndex: 0,
            dataLimit: selections.dataLimit,
            apiResponseLength: 0,
            errMessage: json.error,
          };
          const finalData = rowUiDataFriendly(rowUiDataProps);
          setPivotData({
            result: finalData,
            status: "success",
            message: "",
          });
          console.groupCollapsed("UI ERROR USE-EFFECT");
          console.log(json);
          console.groupEnd();
        });
    } else {
      // Get all the parent row Filters Function
      const { actualParent } = row;
      const additionalDimensionFilters = makeUiFriendlyDimensionFilters(
        columns,
        actualParent,
        pivotData.result
      );

      const clickedRowDimensionFilters = [
        {
          id: columns[actualParent.level][0].id,
          values: [actualParent[columns[actualParent.level][0]["accessor"]]],
          metadata: {
            ...columns[actualParent.level][0].actualPayload,
          },
          filterType: "include",
          advancedFilters: [],
          valid: true,
        },
      ];

      const extractOrderFromAdvanceSortProps = {
        advanceSort: selections.advanceSort,
        dimension: selections.dimensionsList[actualParent.level + 1],
        allData,
      };
      const orderDetails = extractOrderFromAdvanceSort(
        extractOrderFromAdvanceSortProps
      );
      const source = axios.CancelToken.source();
      const newSelections = {
        ...selections,
        dimensionFilters: [
          ...selections.dimensionFilters,
          ...additionalDimensionFilters,
          ...clickedRowDimensionFilters,
        ],
        dimensionsList: [selections.dimensionsList[actualParent.level + 1]],
        granularity: globalFilters?.metricChartGranularity?.value,
        dataLimit: 11,
        ...orderDetails,
      };
      const props = { selections: newSelections, user };
      const getDataPayload = wrapperChartObject(props);
      const fetchProps = {
        payload: getDataPayload,
        cancelToken: source.token,
      };
      // Api calling and updating the children array with api response
      const getDataPromise = getComparisonData(fetchProps);
      getDataPromise
        .then((data) => {
          if (data.status.statusCode === "200") {
            setPivotData((latestState) => {
              const updateChildrenDataProps = {
                apiResponse: data?.result.data,
                columns,
                data: latestState.result,
                row: actualParent,
                dataLimit: newSelections.dataLimit,
                isExpandedValue: true,
              };
              const newPivotData = updateChildrenData(updateChildrenDataProps);
              return {
                result: newPivotData,
                status: "success",
                message: "",
              };
            });
          } else return data;
        })
        .catch((json) => {
          setPivotData((latestState) => {
            console.log("ROW LEVEL", actualParent.level);
            const rowLevel = actualParent.level;
            const id = actualParent[columns[rowLevel][0].accessor];
            const key = columns[rowLevel][0].accessor;
            const updateChildrenDataRecursivelyProps = {
              id,
              key,
              children: latestState.result,
              value: [],
              rowLevel,
              dataLimit: newSelections.dataLimit,
              isExpandedValue: true,
              errMessage: json.error,
              actualParent,
            };
            let newPivotData = updateChildrenDataRecursively(
              updateChildrenDataRecursivelyProps
            );
            return {
              result: newPivotData,
              status: "success",
              message: "",
            };
          });

          console.groupCollapsed("UI ERROR +ICON CLICK");
          console.log(json);
          console.groupEnd();
        });
    }
  };

  // * Function run after clicking the Show More button form the table
  const handleShowMore = (event, { row, columns }) => {
    // console.log("handleshowmore");
    const rowLevel = row.level;
    //  For showing Loading to ui and adding +10 to datalimit feild
    setPivotData((latestState) => {
      const updateChildrenLoadingProps = {
        columns,
        data: latestState.result,
        row,
        rowLevel: row.level,
        value: true,
        selections,
      };
      var updatedPivotData = updateChildrenShowMoreLoading(
        updateChildrenLoadingProps
      );
      return {
        result: updatedPivotData,
        status: "success",
        message: "",
      };
    });
    const extractOrderFromAdvanceSortProps = {
      advanceSort: selections.advanceSort,
      dimension: selections.dimensionsList[rowLevel],
      allData,
    };

    // Get all the parent row Filters Function
    const additionalDimensionFilters = makeUiFriendlyDimensionFilters(
      columns,
      row
    );
    const orderDetails = extractOrderFromAdvanceSort(
      extractOrderFromAdvanceSortProps
    );
    //For cancelling the query if the activeDimension or
    const source = axios.CancelToken.source();
    const newSelections = {
      ...selections,
      dimensionsList: [selections.dimensionsList[rowLevel]],
      dataLimit: row.dataLimit + selections.dataLimit,
      ...orderDetails,
      dimensionFilters: row.level === 0 ? [] : [...additionalDimensionFilters],
      granularity: globalFilters?.metricChartGranularity?.value,
    };
    // console.log("newSelections", newSelections);

    const props = { selections: newSelections, user };
    const getDataPayload = wrapperChartObject(props);
    const fetchProps = {
      payload: getDataPayload,
      cancelToken: source.token,
    };
    // console.log("show More payload", getDataPayload);

    const getDataPromise = getComparisonData(fetchProps);
    getDataPromise
      .then((data) => {
        if (data.status.statusCode === "200") {
          // * First update the loader to normal
          setPivotData((latestState) => {
            const updateChildrenLoadingProps = {
              columns,
              data: latestState.result,
              row,
              rowLevel: row.level,
              value: false,
              selections,
            };
            var updatedPivotData = updateChildrenShowMoreLoading(
              updateChildrenLoadingProps
            );
            return {
              result: updatedPivotData,
              status: "success",
              message: "",
            };
          });
          // * Second update the pivotData state with the new Api Data
          setPivotData((latestState) => {
            const newPivotData = updateRowData({
              apiResponse: data?.result.data,
              columns,
              data: latestState.result,
              row,
              dataLimit: newSelections.dataLimit,
              previousDataLimit: row.dataLimit,
              isComparisonTable: true,
            });

            return {
              result: newPivotData,
              status: "success",
              message: "",
            };
          });
        } else return data;
      })
      .catch((json) => {
        console.groupCollapsed("UI ERROR HANDLESHOWMORE");
        console.log(json);
        console.groupEnd();
      });
  };

  // * Func to clear children - when click -Icon to clear children
  const handleClearChildren = (event, clearChildrenProps = {}) => {
    const { row = {}, columns = [] } = clearChildrenProps;
    // * DEBUGGER
    // console.groupCollapsed("handleClearChildren");
    // console.log("row", row);
    // console.groupEnd();

    setPivotData((latestState) => {
      const newPivotData = updateChildrenData({
        apiResponse: [],
        columns,
        data: latestState.result,
        row,
        isExpandedValue: false,
        clearChilren: "true",
      });

      return {
        result: newPivotData,
        status: "success",
        message: "",
      };
    });
  };

  // * Function run after clicking the + icon form the table
  const handleUpdateIsLoading = (event, handleUpdateIsLoadingProps = {}) => {
    const { row = {}, columns = [] } = handleUpdateIsLoadingProps;

    // For updating to the loader form where it is clicked
    setPivotData((latestState) => {
      const updateChildrenLoadingProps = {
        columns,
        data: latestState.result,
        row,
        rowLevel: row.level,
        value: true,
      };
      const updatedPivotData = updateChildrenLoading(
        updateChildrenLoadingProps
      );
      return {
        result: updatedPivotData,
        status: "success",
        message: "",
      };
    });

    // Get all the parent row Filters Function
    const additionalDimensionFilters = makeUiFriendlyDimensionFilters(
      columns,
      row,
      pivotData.result
    );

    const clickedRowDimensionFilters = [
      {
        id: columns[row.level][0].id,
        values: [row[columns[row.level][0]["accessor"]]],
        metadata: {
          ...columns[row.level][0].actualPayload,
        },
        filterType: "include",
        advancedFilters: [],
        valid: true,
      },
    ];

    const extractOrderFromAdvanceSortProps = {
      advanceSort: selections.advanceSort,
      dimension: selections.dimensionsList[row.level + 1],
      allData,
    };
    const orderDetails = extractOrderFromAdvanceSort(
      extractOrderFromAdvanceSortProps
    );
    const source = axios.CancelToken.source();
    const newSelections = {
      ...selections,
      dimensionFilters: [
        ...selections.dimensionFilters,
        ...additionalDimensionFilters,
        ...clickedRowDimensionFilters,
      ],
      dimensionsList: [selections.dimensionsList[row.level + 1]],
      granularity: globalFilters?.metricChartGranularity?.value,
      ...orderDetails,
    };
    const props = { selections: newSelections, user };
    const getDataPayload = wrapperChartObject(props);
    const fetchProps = {
      payload: getDataPayload,
      cancelToken: source.token,
    };
    // Api calling and updating the children array with api response
    const getDataPromise = getComparisonData(fetchProps);
    getDataPromise
      .then((data) => {
        if (data.status.statusCode === "200") {
          setPivotData((latestState) => {
            const updateChildrenDataProps = {
              apiResponse: data?.result.data,
              columns,
              data: latestState.result,
              row,
              dataLimit: newSelections.dataLimit,
              isExpandedValue: true,
              isComparisonTable: true,
            };
            const newPivotData = updateChildrenData(updateChildrenDataProps);
            return {
              result: newPivotData,
              status: "success",
              message: "",
            };
          });
        } else return data;
      })
      .catch((json) => {
        setPivotData((latestState) => {
          const rowLevel = row.level;
          const id = row[columns[rowLevel][0].accessor];
          const key = columns[rowLevel][0].accessor;
          const updateChildrenDataRecursivelyProps = {
            id,
            key,
            children: latestState.result,
            value: [],
            rowLevel,
            dataLimit: newSelections.dataLimit,
            isExpandedValue: true,
            errMessage: json.error,
            actualParent: row,
          };
          let newPivotData = updateChildrenDataRecursively(
            updateChildrenDataRecursivelyProps
          );
          return {
            result: newPivotData,
            status: "success",
            message: "",
          };
        });
        console.groupCollapsed("UI ERROR +ICON CLICK");
        console.log(json);
        console.groupEnd();
      });
  };

  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  // console.log("row", row);

  return (
    <>
      <>
        {!row.isError && (
          <TableRow>
            {actualColumns.map((col, index) => {
              const value = index === 0 ? row[col.accessor] : "";
              const formatDimValueProps = {
                value,
                accessor: col.accessor,
                selections,
              };
              const isTotalRow = value?.toLowerCase() === "total";
              let dimDisplayValue = formatDimValue(formatDimValueProps);
              let dimTitle = value;
              if (isTotalRow) dimDisplayValue = value;
              var metricNormalValue = "";
              var metricNormalValueTitle = "";
              var metricDeltaPerValue = "";
              var metricDeltaPerValueTitle = "";
              var metricDeltaPerValueChangeType = "";
              var metricTrueDeltaValue = "";
              var metricTrueDeltaValueTitle = "";
              var metricTrueDeltaValueChangeType = "";
              if (index !== 0) {
                // Only format if it's a metric (which is non 0 index in our case)
                metricNormalValue = row[col.normal.accessor];
                metricNormalValueTitle = row[col.normal.accessor];
                metricDeltaPerValue = row[col.deltaPercentage.accessor];
                metricDeltaPerValueTitle = row[col.deltaPercentage.accessor];
                metricDeltaPerValueChangeType =
                  parseFloat(metricDeltaPerValue) < 0
                    ? "negChange"
                    : "posChange";
                metricTrueDeltaValue = row[col.trueDelta.accessor];
                metricTrueDeltaValueTitle = row[col.trueDelta.accessor];
                metricTrueDeltaValueChangeType =
                  parseFloat(metricTrueDeltaValue) < 0
                    ? "negChange"
                    : "posChange";
                var metricObj = col.normal.actualPayload;
                metricNormalValueTitle =
                  metricNormalValue === "NA"
                    ? metricNormalValue
                    : d3Format(",")(metricNormalValue);
                metricNormalValue =
                  metricNormalValue === "NA"
                    ? metricNormalValue
                    : appendMetricSym(
                        metricNormalValue,
                        metricObj.dataUnit,
                        true
                      ).toUpperCase();
                metricTrueDeltaValueTitle =
                  metricTrueDeltaValue === "NA"
                    ? metricTrueDeltaValue
                    : d3Format(",")(metricTrueDeltaValue);
                metricTrueDeltaValue =
                  metricTrueDeltaValue === "NA"
                    ? metricTrueDeltaValue
                    : appendMetricSym(
                        metricTrueDeltaValue,
                        metricObj.dataUnit,
                        true
                      ).toUpperCase();
                metricDeltaPerValueTitle =
                  metricDeltaPerValue === "NA"
                    ? metricDeltaPerValue
                    : `${d3Format(",.2f")(metricDeltaPerValue)}%`;
                metricDeltaPerValue =
                  metricDeltaPerValue === "NA"
                    ? metricDeltaPerValue
                    : `${appendMetricSym(
                        metricDeltaPerValue,
                        {
                          dType: "String",
                          value: "String",
                        },
                        true
                      ).toUpperCase()}%`;
              }
              const style1 = {
                paddingLeft: `${row.level * 30}px`,
                alignItems: "center",
                backgroundColor: "white",
                textAlign: "left",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              };
              const style2 = {
                paddingLeft: `${row.level * 30}px`,
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                textAlign: "left",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              };
              const finalStyle = dimDisplayValue === "" ? style1 : style2;
              const className = `${classes.tableCell} ${classes.sticky}`;

              if (index === 0) {
                return (
                  <TableCell
                    style={finalStyle}
                    key={index}
                    className={className}
                  >
                    {row.isClickButton && row.isExpanded === false && (
                      <span
                        onClick={(event) =>
                          handleUpdateIsLoading(event, {
                            row,
                            columns,
                          })
                        }
                        class="material-icons-outlined"
                        style={{
                          cursor: "pointer",
                          fontSize: "14px",
                          marginRight: "2px",
                          color: themeColors["primaryColor"],
                        }}
                      >
                        add_circle
                      </span>
                    )}
                    {row.isClickButton && row.isExpanded === true && (
                      <span
                        onClick={(event) =>
                          handleClearChildren(event, {
                            row,
                            columns,
                          })
                        }
                        class="material-icons-outlined"
                        style={{
                          cursor: "pointer",
                          fontSize: "14px",
                          marginRight: "2px",
                          color: themeColors["secondaryColorLight"],
                        }}
                      >
                        remove_circle
                      </span>
                    )}
                    <span title={dimTitle}>{dimDisplayValue}</span>
                  </TableCell>
                );
              } else {
                return (
                  <>
                    <TableCell
                      className={classes.tableCell}
                      title={metricNormalValueTitle}
                    >
                      {metricNormalValue}
                    </TableCell>
                    <TableCell
                      className={`${classes.tableCell} ${classes[metricDeltaPerValueChangeType]}`}
                      title={metricDeltaPerValueTitle}
                    >
                      {metricDeltaPerValue}
                    </TableCell>
                    <TableCell
                      className={`${classes.tableCell} ${classes[metricTrueDeltaValueChangeType]}`}
                      title={metricTrueDeltaValueTitle}
                    >
                      {metricTrueDeltaValue}
                    </TableCell>
                  </>
                );
              }
            })}
          </TableRow>
        )}
        {/* show error button code */}
        {row.isError && row.isChildrenLoading === false && (
          <SigviewButton
            variant="containedPrimary"
            onClick={(event) =>
              handleError(event, {
                row,
                columns,
              })
            }
            title={row.errMessage}
            iconFlag={true}
            iconProps={{
              className: "material-icons-outlined",
            }}
          />
        )}
        {/* For showing the loader  */}{" "}
        {row.isChildrenLoading && (
          <TableRow>
            <TableCell className={classes.tableCell}>
              <SigviewProgressBar />
            </TableCell>
          </TableRow>
        )}
        {/* show more code */}
        {/* ORDER of SHOW MORE AND CHILDREN DEPENDS ON IF THE ROW IS THE LAST INDEX */}
        {/* ROW IS NOT THE LAST INDEX */}
        {row.isShowMore &&
          row.isChildrenLoading === false &&
          totalLength - 1 !== rowIndex && (
            <Box css={{ padding: `5px 0px 5px ${row.level * 30}px` }}>
              <SigviewButton
                variant="containedPrimary"
                onClick={(event) =>
                  handleShowMore(event, {
                    row,
                    columns,
                  })
                }
                title="Show More"
                iconFlag={true}
                iconProps={{
                  className: "material-icons-outlined",
                }}
              />
            </Box>
          )}
        {row.children.length > 0 &&
          totalLength - 1 !== rowIndex &&
          row.children.map((childRow, index) => (
            <PivotTableRow
              key={index}
              row={childRow}
              columns={columns}
              pivotData={pivotData}
              setPivotData={setPivotData}
              selections={selections}
              user={user}
              allData={allData}
              globalFilters={globalFilters}
              totalLength={row.children.length}
              rowIndex={index}
            />
          ))}
        {/* ROW IS THE LAST INDEX */}
        {row.children.length > 0 &&
          totalLength - 1 === rowIndex &&
          row.children.map((childRow, index) => (
            <PivotTableRow
              key={index}
              row={childRow}
              columns={columns}
              pivotData={pivotData}
              setPivotData={setPivotData}
              selections={selections}
              user={user}
              allData={allData}
              globalFilters={globalFilters}
              totalLength={row.children.length}
              rowIndex={index}
            />
          ))}
        {row.isShowMore &&
          row.isChildrenLoading === false &&
          totalLength - 1 === rowIndex && (
            <Box
              className={classes.sticky}
              css={{ padding: `5px 0px 5px ${row.level * 30}px` }}
            >
              <SigviewButton
                variant="containedPrimary"
                onClick={(event) =>
                  handleShowMore(event, {
                    row,
                    columns,
                  })
                }
                title="Show More"
                iconFlag={true}
                iconProps={{
                  className: "material-icons-outlined",
                }}
              />
            </Box>
          )}
      </>
    </>
  );
}
function PivotTableTotalRow(props = {}) {
  const {
    row,
    columns,
    selections,
    user,
    allData = {},
    globalFilters,
    setPivotDataTotal,
  } = props;
  // * DEBUGGER
  // console.groupCollapsed("PivotTableTotalRow");
  // console.log("row", row);
  // console.log("columns", columns);
  // console.groupEnd();

  // * Function run after clicking the Error button form the table
  const handleError = (event, { row, columns }) => {
    //! Total api call
    var source2 = axios.CancelToken.source();
    var newSelections = {
      ...selections,
      dimensionsList: [],
      dataLimit: 11,
      orderBy: "desc", //HARD CODED
      orderById: selections.metricsList[0]._id, //HARD CODED
      granularity: globalFilters?.metricChartGranularity?.value,
    };
    var props = { selections: newSelections, user };
    var getDataPayload = wrapperChartObject(props);
    console.log("getDataPayload", getDataPayload);
    var fetchProps = {
      payload: getDataPayload,
      cancelToken: source2.token,
    };

    var getDataPromise = getData(fetchProps);
    getDataPromise
      .then((data) => {
        setPivotDataTotal((latestState) => {
          const rowUiDataProps = {
            getDataResponse: data.result.data,
            columnData: columns,
            columnIndex: 0,
          };
          const totalRowData = totalRowUiDataFriendly(rowUiDataProps);
          const updatedPivotData = [...totalRowData, ...latestState.result];
          return {
            result: totalRowData,
            status: "success",
            message: "",
          };
        });
      })
      .catch((json) => {
        const rowUiDataProps = {
          getDataResponse: [],
          columnData: columns,
          columnIndex: 0,
          dataLimit: selections.dataLimit,
          apiResponseLength: 0,
          errMessage: json.error,
        };
        const finalData = rowUiDataFriendly(rowUiDataProps);
        setPivotDataTotal({
          result: finalData,
          status: "success",
          message: "",
        });
        console.groupCollapsed("UI ERROR USE-EFFECT FOR TOTAL");
        console.log(json);
        console.groupEnd();
      });
  };

  const actualColumns = columns[0];
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();

  return (
    <>
      <>
        {!row.isError && (
          <TableRow className={classes.totalRow}>
            {actualColumns.map((col, index) => {
              const value = index === 0 ? row[col.accessor] : "";
              const formatDimValueProps = {
                value,
                accessor: col.accessor,
                selections,
              };
              const isTotalRow = value?.toLowerCase() === "total";
              let dimDisplayValue = formatDimValue(formatDimValueProps);
              let dimTitle = value;
              if (isTotalRow) dimDisplayValue = value;
              var metricNormalValue = "";
              var metricNormalValueTitle = "";
              var metricDeltaPerValue = "";
              var metricDeltaPerValueTitle = "";
              var metricDeltaPerValueChangeType = "";
              var metricTrueDeltaValue = "";
              var metricTrueDeltaValueTitle = "";
              var metricTrueDeltaValueChangeType = "";
              if (index !== 0) {
                // Only format if it's a metric (which is non 0 index in our case)
                metricNormalValue = row[col.normal.accessor];
                metricNormalValueTitle = row[col.normal.accessor];
                metricDeltaPerValue = row[col.deltaPercentage.accessor];
                metricDeltaPerValueTitle = row[col.deltaPercentage.accessor];
                metricDeltaPerValueChangeType =
                  parseFloat(metricDeltaPerValue) < 0
                    ? "negChange"
                    : "posChange";
                metricTrueDeltaValue = row[col.trueDelta.accessor];
                metricTrueDeltaValueTitle = row[col.trueDelta.accessor];
                metricTrueDeltaValueChangeType =
                  parseFloat(metricTrueDeltaValue) < 0
                    ? "negChange"
                    : "posChange";
                var metricObj = col.normal.actualPayload;
                metricNormalValueTitle =
                  metricNormalValue === "NA"
                    ? metricNormalValue
                    : d3Format(",")(metricNormalValue);
                metricNormalValue =
                  metricNormalValue === "NA"
                    ? metricNormalValue
                    : appendMetricSym(
                        metricNormalValue,
                        metricObj.dataUnit,
                        true
                      ).toUpperCase();
                metricTrueDeltaValueTitle =
                  metricTrueDeltaValue === "NA"
                    ? metricTrueDeltaValue
                    : d3Format(",")(metricTrueDeltaValue);
                metricTrueDeltaValue =
                  metricTrueDeltaValue === "NA"
                    ? metricTrueDeltaValue
                    : appendMetricSym(
                        metricTrueDeltaValue,
                        metricObj.dataUnit,
                        true
                      ).toUpperCase();
                metricDeltaPerValueTitle =
                  metricDeltaPerValue === "NA"
                    ? metricDeltaPerValue
                    : `${d3Format(",.2f")(metricDeltaPerValue)}%`;
                metricDeltaPerValue =
                  metricDeltaPerValue === "NA"
                    ? metricDeltaPerValue
                    : `${appendMetricSym(
                        metricDeltaPerValue,
                        {
                          dType: "String",
                          value: "String",
                        },
                        true
                      ).toUpperCase()}%`;
              }

              let className = classes.totaltableCellHeader;
              if (index === 0) className += ` ${classes.sticky}`;
              const style = {
                zIndex: `${row[col.accessor] === "Total" ? 0 : 0}`,
              };

              if (index === 0) {
                return (
                  <TableCell style={style} key={index} className={className}>
                    <span title={dimTitle}>{dimDisplayValue}</span>
                  </TableCell>
                );
              } else {
                return (
                  <>
                    <TableCell
                      className={className}
                      title={metricNormalValueTitle}
                    >
                      {metricNormalValue}
                    </TableCell>
                    <TableCell
                      className={`${className} ${classes[metricDeltaPerValueChangeType]}`}
                      title={metricDeltaPerValueTitle}
                    >
                      {metricDeltaPerValue}
                    </TableCell>
                    <TableCell
                      className={`${className} ${classes[metricTrueDeltaValueChangeType]}`}
                      title={metricTrueDeltaValueTitle}
                    >
                      {metricTrueDeltaValue}
                    </TableCell>
                  </>
                );
              }
            })}
          </TableRow>
        )}
        {/* show error button code */}
        {row.isError && row.isChildrenLoading === false && (
          <SigviewButton
            variant="containedPrimary"
            onClick={(event) =>
              handleError(event, {
                row,
                columns,
              })
            }
            title={row.errMessage}
            iconFlag={true}
            iconProps={{
              className: "material-icons-outlined",
            }}
          />
        )}
      </>
    </>
  );
}

function PivotComparisonTable(props) {
  const {
    selections = {},
    user = {},
    allData = {},
    onDataChange = () => {},
    dispatchPivotState = () => {},
    globalFilters = {},
  } = props;
  // console.log("pivottable selections", selections);
  const {
    dimensionsList,
    metricsList,
    dimensionFilters,
    advanceSort,
    timeFilters,
    granularity = "day",
  } = selections;
  const [pivotData, setPivotData] = useState(initialPivotData);
  const [pivotDataTotal, setPivotDataTotal] = useState(initialPivotData);
  // console.log("Pivot compare table", selections);
  console.log("initial pivotData", pivotData);

  const getColumn = (dimList, metricList) => {
    let newDimObj = dimList.map((dimListObj) => {
      let tempObj = {
        id: dimListObj._id,
        dimId: dimListObj.dimID,
        name: "Dimension",
        orderBy: "desc",
        accessor: dimListObj.dimID,
        actualPayload: dimListObj,
      };
      let newMericObj = metricList.map((metricListObj) => {
        const normal = {
          id: metricListObj._id,
          name: metricListObj._title,
          orderBy: "desc",
          accessor: metricListObj._id.startsWith("M")
            ? metricListObj.measureID
            : metricListObj._id,
          actualPayload: metricListObj,
        };
        const deltaPercentage = {
          id: metricListObj._id,
          name: `${metricListObj._title}_deltaPercentage`,
          orderBy: "desc",
          accessor: `${
            metricListObj._id.startsWith("M")
              ? metricListObj.measureID
              : metricListObj._id
          }_deltaPercentage`,
          actualPayload: metricListObj,
        };
        const trueDelta = {
          id: metricListObj._id,
          name: `${metricListObj._title}_trueDelta`,
          orderBy: "desc",
          accessor: `${
            metricListObj._id.startsWith("M")
              ? metricListObj.measureID
              : metricListObj._id
          }_trueDelta`,
          actualPayload: metricListObj,
        };
        return {
          normal,
          deltaPercentage,
          trueDelta,
        };
      });

      newMericObj.unshift(tempObj);

      return newMericObj;
    });
    return newDimObj;
  };

  // * Define required side effects
  useEffect(() => {
    const extractOrderFromAdvanceSortProps = {
      advanceSort: selections.advanceSort,
      dimension: dimensionsList[0],
      allData,
    };
    // console.log(
    //   "extractOrderFromAdvanceSortProps",
    //   extractOrderFromAdvanceSortProps
    // );
    const orderDetails = extractOrderFromAdvanceSort(
      extractOrderFromAdvanceSortProps
    );
    var source1 = axios.CancelToken.source();
    var newSelections = {
      ...selections,
      dimensionsList: [dimensionsList[0]],
      granularity: globalFilters?.metricChartGranularity?.value,
      ...orderDetails,
    };
    var props = { selections: newSelections, user };
    var getDataPayload = wrapperChartObject(props);
    // console.log("comparision", getDataPayload);
    var fetchProps = {
      payload: getDataPayload,
      cancelToken: source1.token,
    };
    var getDataPromise = getComparisonData(fetchProps);
    getDataPromise
      .then((data) => {
        setPivotData((latestState) => {
          const rowUiDataProps = {
            getDataResponse: data.result.data,
            columnData: columns,
            columnIndex: 0,
            dataLimit: selections.dataLimit,
            apiResponseLength: data.result.data.length,
          };
          const updatedPivotData = rowUiDataFriendly(rowUiDataProps);

          return {
            result: updatedPivotData,
            status: "success",
            message: "",
          };
        });
      })
      .catch((json) => {
        const rowUiDataProps = {
          getDataResponse: [],
          columnData: columns,
          columnIndex: 0,
          dataLimit: selections.dataLimit,
          apiResponseLength: 0,
          errMessage: json.error,
        };
        const finalData = rowUiDataFriendly(rowUiDataProps);
        setPivotData({
          result: finalData,
          status: "success",
          message: "",
        });
        console.groupCollapsed("UI ERROR USE-EFFECT");
        console.log(json);
        console.groupEnd();
      });
    //! Total api call
    var source2 = axios.CancelToken.source();
    var newSelections = {
      ...selections,
      granularity: globalFilters?.metricChartGranularity?.value,
      dimensionsList: [],
      orderBy: "desc", //HARD CODED
      orderById: selections.metricsList[0]._id, //HARD CODED
    };
    var props = { selections: newSelections, user };
    var getDataPayload = wrapperChartObject(props);
    // console.log("getDataPayload", getDataPayload);
    var fetchProps = {
      payload: getDataPayload,
      cancelToken: source2.token,
    };

    var getDataPromise = getData(fetchProps);
    getDataPromise
      .then((data) => {
        setPivotDataTotal((latestState) => {
          const rowUiDataProps = {
            getDataResponse: data.result.data,
            columnData: columns,
            columnIndex: 0,
          };
          const totalRowData = totalRowUiDataFriendly(rowUiDataProps);
          return {
            result: totalRowData,
            status: "success",
            message: "",
          };
        });
      })
      .catch((json) => {
        const rowUiDataProps = {
          getDataResponse: [],
          columnData: columns,
          columnIndex: 0,
          dataLimit: selections.dataLimit,
          apiResponseLength: 0,
          errMessage: json.error,
        };
        const finalData = rowUiDataFriendly(rowUiDataProps);
        setPivotDataTotal({
          result: finalData,
          status: "success",
          message: "",
        });
        console.groupCollapsed("UI ERROR USE-EFFECT FOR TOTAL");
        console.log(json);
        console.groupEnd();
      });
    return () => {
      //Cancel all previous fetch calls
      if (source1) source1.cancel();
      if (source2) source2.cancel();
    };
  }, []);

  // Pass data to parent whenever data changes
  useEffect(() => {
    onDataChange(pivotData);
  }, [pivotData]);

  const columns = getColumn(dimensionsList, metricsList);
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const actualColumns = columns[0];

  const TableHeader = (props = {}) => {
    // * Destructure props
    const { head = {}, index = 0 } = props;

    // * Define required states
    const [mouseIn, setMouseIn] = useState(false);

    // * Define event handlers
    const handleMouseEnter = () => setMouseIn(true);
    const handleMouseLeave = () => setMouseIn(false);
    const handleMetricRowClick = () => {
      const payload = {
        sortedOn: head.id,
        orderBy: orderBy === "asc" ? "desc" : "asc",
      };
      let action = updatePivotAdvanceSort(payload);
      dispatchPivotState(action);

      action = updatePivotReloadEpoch();
      dispatchPivotState(action);
    };

    // * Define required variables
    const { isAllDimSortedOnThisMetric, orderBy } = getSortDetails(
      head,
      advanceSort
    );
    let className = classes.tableCellHeader;
    if (index === 0) className += ` ${classes.sticky}`;
    const style = {
      zIndex: `${head.name === "Dimension" ? 10 : 0}`,
    };
    const arrowType = orderBy === "asc" ? "arrow_drop_up" : "arrow_drop_down";

    return (
      <>
        <TableCell
          className={className}
          style={style}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              style={{
                maxWidth: "20px",
                minWidth: "20px",
              }}
            ></span>
            <span
              // className={classes.tableHeadTitle}
              title={head.name}
              style={{
                maxWidth: "70px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                marginRight: "3px",
              }}
            >
              {head.name}
            </span>

            <span
              style={{
                maxWidth: "20px",
                minWidth: "20px",
              }}
            >
              {isAllDimSortedOnThisMetric && index !== 0 && (
                <SigviewIcon
                  iconName={arrowType}
                  style={{
                    fontSize: "20px !important",
                    // padding: "0px 5px 0px 0px",
                    color: themeColors["primaryColor"],
                    hoverColor: themeColors["primaryColor"],
                    cursor: "pointer",
                  }}
                  onClick={handleMetricRowClick}
                />
              )}
              {!isAllDimSortedOnThisMetric && index !== 0 && mouseIn && (
                <SigviewIcon
                  iconName={arrowType}
                  style={{
                    fontSize: "20px !important",
                    // padding: "0px 5px 0px 0px",
                    color: themeColors["primaryColor"],
                    hoverColor: themeColors["primaryColor"],
                    cursor: "pointer",
                  }}
                  onClick={handleMetricRowClick}
                />
              )}
            </span>
          </span>
        </TableCell>
      </>
    );
  };

  const comparisonHeaderColumns = getComparisonHeaderColumns(actualColumns);

  return (
    <Box css={{ height: "100%", overflowX: "auto", boxSizing: "border-box" }}>
      {pivotData.status === "loading" && <Loader />}
      {pivotData.status === "success" && (
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableRowHeader}>
              {comparisonHeaderColumns.map((head, index) => (
                <TableHeader head={head} index={index} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pivotDataTotal.status === "loading" && <SigviewProgressBar />}
            {pivotDataTotal.status === "success" && (
              <PivotTableTotalRow
                row={pivotDataTotal.result[0]}
                columns={columns}
                setPivotDataTotal={setPivotDataTotal}
                selections={selections}
                user={user}
                allData={allData}
                globalFilters={globalFilters}
              />
            )}
            {pivotData.result.map((row, index) => (
              <PivotTableRow
                key={index}
                row={row}
                columns={columns}
                pivotData={pivotData}
                setPivotData={setPivotData}
                selections={selections}
                user={user}
                allData={allData}
                globalFilters={globalFilters}
                totalLength={pivotData.result.length}
                rowIndex={index}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(PivotComparisonTable);
