// * Import required libraies
import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";

// * Import lib comp
import { Box } from "@material-ui/core";

// * Import Custom Component
import LayoutTopSideBottomAdmin from "../../../layouts/LayoutTopSideBottomAdmin/LayoutTopSideBottomAdmin";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import SigviewAdminTable from "../../../components/SigviewAdminTableAws";
import AppMenuBar from "../../../components/AppMenuBar";
import Loader from "../../../components/Loader/Loader";
import { config } from "../../../config/config";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";

// * Import utils, config & static data
import { updateUserScreen, updateDialogInfo } from "../../../redux/actions";
import {
  getBreadcrumbsDataAdminAws,
  getBreadcrumbsDataFromRoute,
} from "../../../utils/utils";
import {
  unwrapperGroupsInViews,
  unWrapperUsers,
  unwrapperViewsFromAllData,
} from "../../../utils/adminAwsUtils";
import { masterTrackGaEvent } from "../../../../js/services/ga/index";

// * Import APIs
import { deleteUserAws, readUsersAwsByOrg } from "../../../services/api";

import SigviewSingleSelect from "../../../components/Common/SigviewSingleSelect";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import { useHistory, useLocation } from "react-router";

function Users(props) {
  // * Destructure props
  const { dispatch: ReduxDispatcher, user, allData = {} } = props;

  // * Define contexts
  const history = useHistory();
  const location = useLocation();

  const allViewValues = unwrapperViewsFromAllData(allData);

  // * Define required states
  const [usersData, setUsersData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });
  const [searchField, setSearchField] = useState("");
  const [viewValue, setViewValue] = useState(allViewValues[0].id);
  const [groupValue, setGroupValue] = useState("");
  const [groupsValues, setGroupsValues] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(true);

  const showSigmoidEmail = user.reqMetadata.email
    .split("@")[1]
    .includes("sigmoid");

  useEffect(() => {
    if (reloadUsers === false) {
      setReloadUsers(true);
    }
  }, [reloadUsers]);

  // * Define required side effects
  // Fetch groups Data
  useEffect(() => {
    // Make fetch call using axios
    if (groupValue !== "" && groupValue !== undefined) {
      const source = axios.CancelToken.source();
      const fetchProps = {
        cancelToken: source.token,
        payload: {
          orgViewReq: {
            // user.reqMetadata.organization
            organization: user.reqMetadata.organization,
            view: viewValue,
          },
          groupId: groupValue,
        },
      };
      const readAllUsersViews = readUsersAwsByOrg(fetchProps);
      readAllUsersViews
        .then((data) => {
          setUsersData({
            result: data?.result?.data,
            status: "success",
            messsage: "",
          });
          // let newData = {
          //   result: data.result.data,
          //   status: "success",
          //   messsage: "",
          // };
          // ReduxDispatcher(updateData("adminUser", newData));
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            const message = json.error || config.messages.uiErrorMessage;
            setUsersData({
              result: [],
              status: "error",
              messsage: "API failed",
            });

            console.groupCollapsed("UI ERROR");
            console.log("JSON", json);
            console.log("ERROR ->", message);
            console.groupEnd();
          }
        });

      return () => {
        setUsersData({
          result: [],
          status: "loading",
          messsage: "",
        });
      };
    }
  }, [groupValue, reloadUsers]);

  useEffect(() => {
    setGroupsValues(
      unwrapperGroupsInViews(allData.viewGroupList.result, viewValue)
    );

    // setGroupValue(groupsValues[0]?.id);
  }, [viewValue]);
  useEffect(() => {
    setGroupValue(groupsValues[0]?.id);
  }, [groupsValues]);

  // * Define required event handlers
  const handleActiveTab = (e, id) => {
    //Return to home (will be controlled by router later)
    // const activeTab = id;
    // const value = { activeNav: "admin", activeTab };
    // const action = updateUserScreen(null, value);
    // ReduxDispatcher(action);

    if (id === "home") {
      history.push("/admin");
    }
  };
  const handleClickRow = (row) => {
    // Google Analytics Event - Master Admin
    masterTrackGaEvent({
      category: "Admin",
      action: "UserOpen",
      label: row._id,
    });

    //update screen
    // Both the action can be dispatch into one reducers
    //console.log("row", row);
    const activeTab = "user";
    const newActiveAdminUser = {
      payload: {
        ...row,
      },
      metadata: { crudType: "edit", name: row.emailId, view: viewValue },
    };

    // const value = { activeNav: "admin", activeTab };
    // var action = updateUserScreen(null, value);
    // ReduxDispatcher(action);

    var action = updateUserScreen("activeAdminUser", newActiveAdminUser);
    ReduxDispatcher(action);

    var pathname = "/admin/user";
    // `${location.pathname}${location.hash}${location.search}`;
    // pathname = pathname.replace("/app/#", "");
    history.push(pathname);
  };

  const handleDeleteUser = (value) => {
    const handleNo = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
        })
      );
    };

    const handleYes = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
          message: { title: "", subtitle: "" },
        })
      );

      //Update snackbar
      let snackbarPayload = {
        ...user.screen.snackbar,
        open: true,
        message: "Requesting deleting User",
      };
      ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

      const source = axios.CancelToken.source();
      const fetchProps = {
        cancelToken: source.token,
        payload: {
          orgViewReq: {
            // user.reqMetadata.organization
            organization: user.reqMetadata.organization,
            view: viewValue,
          },
          email: value,
        },
      };
      const deleteUserAwsPromise = deleteUserAws(fetchProps);
      deleteUserAwsPromise
        .then((data) => {
          setReloadUsers(false);
          snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: `${value} deleted successfully`,
          };
          ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            const message = json.error || config.messages.uiErrorMessage;

            console.groupCollapsed("UI ERROR");
            console.log("JSON", json);
            console.log("ERROR ->", message);
            console.groupEnd();
          }
        });
    };

    const message = {
      title: `Are you sure you want to delete? ${value}`,
      subtitle: "",
    };

    ReduxDispatcher(
      updateDialogInfo({
        ...user.dialogInfo,
        open: true,
        message,
        handleYes,
        handleNo,
      })
    );
  };
  const handleSearchFieldChange = (event, value) => setSearchField(value);
  const handleCreateNewUser = () => {
    // ! metadata name must be replaces with no of users +1
    // ! action dispatch to the user selection state
    // ! for the untitled 1 we have to dispatch the action on selection (state)
    // ! Assigned group bhi dispatch karna hai
    // ! role must be user

    // Google Analytics Event - Master Admin
    masterTrackGaEvent({
      category: "Admin",
      action: "UserOpen",
      label: "CreateNew",
    });

    const newActiveAdminUser = {
      payload: {},
      metadata: {
        crudType: "create",
        name: `Untitled-${usersData?.result.length + 1}`,
      },
    };

    // update screen
    // const activeTab = "user";
    // const value = { activeNav: "admin", activeTab };
    // var action = updateUserScreen(null, value);
    // ReduxDispatcher(action);

    var action = updateUserScreen("activeAdminUser", newActiveAdminUser);
    ReduxDispatcher(action);

    var pathname = "/admin/user";
    // `${location.pathname}${location.hash}${location.search}`;
    // pathname = pathname.replace("/app/#", "");
    history.push(pathname);
  };

  const handleViewValueChange = (value) => {
    setViewValue(value);
  };
  const handleGroupValueChange = (value) => {
    setGroupValue(value);
  };

  // * Define required static variables for props
  const activeTab = user.screen.activeTab;
  const breadcrumbsData = getBreadcrumbsDataFromRoute(user, history);
  const handleBreadcrumbChange = (event, value) => {
    history.push(value.path);
  };
  const layoutTopSideBottomAdminProps = {
    activeTab,
    handleActiveTab,
    variant: "AdminAws",
  };
  const buttonProps = {
    handleClick: handleCreateNewUser,
    title: "Create New User",
    flag: true,
  };
  const appMenuBarProps = {
    buttonProps,
    placeholder: "Search Email",
    onSearchFieldChange: handleSearchFieldChange,
    style: {
      wrapperBgColor: "transparent",
      wrapperBorderHover: "1px solid #c5cfd9",
      wrapperBorderFocus: "1px solid #c5cfd9",
      wrapperBorderColor: "#c5cfd9",
    },
  };
  const modifiedUsersData = usersData.result.map((row) => {
    return {
      userName: row.firstName + " " + row.lastName,
      ...row,
      createdOn: moment(Number(row.createdOn)).format("MMMM Do YYYY"),
      lastSuccessLoginTime:
        row.lastSuccessLoginTime === "0"
          ? "Inactive User"
          : moment(Number(row.lastSuccessLoginTime)).format("MMMM Do YYYY"),
    };
  });
  // const headCells = unWrapperUsers(modifiedUsersData);

  let sigmoidEmailFilter = modifiedUsersData;

  if (!showSigmoidEmail) {
    sigmoidEmailFilter = modifiedUsersData.filter((row) => {
      return !row.emailId.split("@")[1].includes("sigmoid");
    });
  }

  const filteredData = sigmoidEmailFilter.filter((el) =>
    el.emailId.toLowerCase().includes(searchField.toLowerCase())
  );

  return (
    <LayoutTopSideBottomAdmin {...layoutTopSideBottomAdminProps}>
      <SigviewBreadcrumb
        data={breadcrumbsData} //data
        onClick={handleBreadcrumbChange} //onClick
      />

      <Box css={{ width: "100%" }}>
        <Box
          css={{
            width: "300px",
            display: "inline-block",
            paddingLeft: "25px",
          }}
        >
          <Box css={{ display: "inline-block", paddingRight: "40px" }}>
            <Box css={{ paddingBottom: "5px" }}>
              <SigviewTypography
                variant="pSmall"
                style={{
                  height: "max-content",
                }}
              >
                Select View:
              </SigviewTypography>
            </Box>
            <SigviewSingleSelect
              data={allViewValues}
              value={viewValue}
              onChange={handleViewValueChange}
              minWidth="120px"
              maxWidth="120px"
            />
          </Box>
          <Box css={{ display: "inline-block" }}>
            <Box css={{ paddingBottom: "5px" }}>
              <SigviewTypography
                variant="pSmall"
                style={{
                  height: "max-content",
                }}
              >
                Select Group:
              </SigviewTypography>
            </Box>
            <SigviewSingleSelect
              data={groupsValues}
              value={groupValue}
              onChange={handleGroupValueChange}
              disabled={false}
              minWidth="120px"
              maxWidth="120px"
            />
          </Box>
        </Box>
        <Box css={{ width: "calc(100% - 325px)", display: "inline-block" }}>
          <AppMenuBar {...appMenuBarProps} />
        </Box>
      </Box>
      {usersData.status === "loading" && <Loader />}
      {usersData.status === "error" && (
        <ErrorHandler
          message={config.hardCoded.uiErrorMessage}
          reloadFlag={true}
          onReload={() => {}}
        />
      )}
      {usersData.status === "success" && filteredData.length > 0 && (
        <>
          <Box
            css={{
              padding: "5px 25px 0px 25px",
            }}
          >
            <SigviewAdminTable
              headCells={unWrapperUsers(modifiedUsersData)}
              handleClickRow={handleClickRow}
              handleDeleteRow={handleDeleteUser}
              rows={filteredData}
            />
          </Box>
        </>
      )}
      {usersData.status === "success" && filteredData.length === 0 && (
        <div className="no-data-container">
          <div className="no-data-image"></div>
          <p className="no-data">{config.messages.noUsers}</p>
        </div>
      )}
    </LayoutTopSideBottomAdmin>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(Users);
