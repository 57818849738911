import moment from "moment";
import { v4 } from "uuid";

//------------------------------------DOCUMENTATION------------------------------------
// All startDate and endDate objects have 4 values:
// 1) epoch value (in the selected timezone)
// 2) string value (in the selected timezone)
// 3) javascript native date object (value in the user system's timezone) | needed for react-date-range library
// 4) timezone selected by the user

export const getValidDates = (props) => {
  // INPUTS
  // startDateEpoch: Valid start epoch value sent by the QE
  // endDateEpoch: Valid end epoch value sent by the QE
  // selectedTimezone: Object containing user selected timezone info
  // format: String to format the date
  //
  // OUTPUT
  // Object containing valid start and end dates as objects (explained at the top)
  //
  // NOTES
  // data is present for this date range in the QE
  // For JS native date object, we first convert the epoch to utc and then to user system's timezone

  let { startDateEpoch, endDateEpoch, selectedTimezone, format } = props;

  let startDateEpochInUTC =
    startDateEpoch + new Date().getTimezoneOffset() * 60 * 1000;
  let startDateEpochInUserSystemTimezone =
    startDateEpochInUTC + selectedTimezone.minutesOffset * 60 * 1000;
  let startDate = {
    epoch: startDateEpoch,
    timezone: selectedTimezone.name,
    nativeDateObj: new Date(startDateEpochInUserSystemTimezone),
    formattedDate: moment(startDateEpoch)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
  };

  let endDateEpochInUTC =
    endDateEpoch + new Date().getTimezoneOffset() * 60 * 1000;
  let endDateEpochInUserSystemTimezone =
    endDateEpochInUTC + selectedTimezone.minutesOffset * 60 * 1000;
  let endDate = {
    epoch: endDateEpoch,
    timezone: selectedTimezone.name,
    nativeDateObj: new Date(endDateEpochInUserSystemTimezone),
    formattedDate: moment(endDateEpoch)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
  };
  return { startDate, endDate };
};

export const getSelectedDates = (props) => {
  // INPUTS
  // selectedStartDate: JS native date object (selected start date by the user)
  // selectedEndDate: JS native date object (selected end date by the user)
  // startDateEpoch: Valid start epoch value sent by the QE
  // endDateEpoch: Valid end epoch value sent by the QE
  // selectedTimezone: Object containing user selected timezone info
  // format: String to format the date
  //
  // OUTPUT
  // Object containing selected start and end dates as objects (explained at the top)
  //
  // NOTES
  // data is present for this date range in the QE
  // For JS native date object, we first convert the epoch to utc and then to user system's timezone
  let {
    selectedStartDate,
    selectedEndDate,
    selectedTimezone,
    startDateEpoch,
    endDateEpoch,
    format,
  } = props;

  //Converting to user system timezone
  let selectedStartDateEpochInUTC =
    selectedStartDate.valueOf() -
    selectedStartDate.getTimezoneOffset() * 60 * 1000;
  let selectedStartDateEpochInUserSystemTimezone =
    selectedStartDateEpochInUTC - selectedTimezone.minutesOffset * 60 * 1000;

  //Taking startDateEpoch as the finalStartEpoch if selectedStartEpoch is before the valid available date
  let finalStartEpoch = Math.max(
    selectedStartDateEpochInUserSystemTimezone,
    startDateEpoch
  );

  let startDate = {
    timezone: selectedTimezone.name,
    epoch: moment(finalStartEpoch)
      .utcOffset(selectedTimezone.minutesOffset)
      .valueOf(),
    timezone: selectedTimezone,
    nativeDateObj: selectedStartDate,
    formattedDate: moment(finalStartEpoch)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
  };

  //Converting to user system timezone
  let selectedEndDateEpochInUTC =
    selectedEndDate.valueOf() - selectedEndDate.getTimezoneOffset() * 60 * 1000;
  let selectedEndDateEpochInUserSystemTimezone =
    selectedEndDateEpochInUTC - selectedTimezone.minutesOffset * 60 * 1000;

  //adding 23 hours 59 minutes 59 seconds 999 milliseconds because date picker gives the fisrt hour
  let finalEndEpoch = addOneDayMinus1SecondMilliseconds(
    moment(selectedEndDateEpochInUserSystemTimezone)
      .utcOffset(selectedTimezone.minutesOffset)
      .valueOf()
  );
  //Taking endDateEpoch as the finalEndEpoch if finalEndEpoch is after the valid available date
  finalEndEpoch = Math.min(finalEndEpoch, endDateEpoch);

  let endDate = {
    timezone: selectedTimezone.name,
    epoch: finalEndEpoch,
    timezone: selectedTimezone,
    nativeDateObj: new Date(
      finalEndEpoch +
        selectedTimezone.minutesOffset * 60 * 1000 +
        new Date().getTimezoneOffset() * 60 * 1000
    ),
    formattedDate: moment(finalEndEpoch)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
  };
  return { startDate, endDate, valid: true };
};

const getDaysDiffFromStartEndEpoch = (startEpoch, endEpoch) =>
  parseInt((endEpoch - startEpoch) / (1000 * 60 * 60 * 24));

export const getStartEndByDatePreset = (props) => {
  let {
    startDateEpoch,
    endDateEpoch,
    selectedDatePreset,
    selectedTimezone,
    format,
    daysLimit,
    validDates,
  } = props;
  let startDate,
    endDate,
    startEpoch,
    endEpoch,
    validByMinMaxDate,
    validByDaysLimit;

  switch (selectedDatePreset) {
    case "today":
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("day")
        .valueOf();
      endEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .endOf("day")
        .valueOf();
      validByMinMaxDate =
        startEpoch >= startDateEpoch && startEpoch <= endDateEpoch;
      validByDaysLimit =
        getDaysDiffFromStartEndEpoch(startEpoch, endEpoch) < daysLimit;
      break;
    case "yesterday":
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("day")
        .subtract(1, "day")
        .valueOf();
      endEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .endOf("day")
        .subtract(1, "day")
        .valueOf();
      validByMinMaxDate =
        startEpoch >= startDateEpoch && startEpoch <= endDateEpoch;
      validByDaysLimit =
        getDaysDiffFromStartEndEpoch(startEpoch, endEpoch) < daysLimit;
      break;
    case "last_2_days":
      // ! BUG FIX ONLY FOR THIS CASE
      // ! REST ALL ARE WORKING FINE
      // ! LOGIC COPIED FROM getDefaultStartAndEndDateEpochBasedOnValidDates function
      var endDateEpochTemp = parseInt(validDates.endDate.epoch);
      //startDateEpoch is last_2_days wrt valid endDate epoch
      //If the calculated value is smaller than the valid startDate epoch, replace it with valid startDate epoch
      startEpoch = Math.max(
        moment(endDateEpochTemp)
          .utcOffset(selectedTimezone.minutesOffset)
          .startOf("day")
          .subtract(1, "day")
          .valueOf(),
        validDates.startDate.epoch
      );
      // startEpoch = moment()
      //   .utcOffset(selectedTimezone.minutesOffset)
      //   .startOf("day")
      //   .subtract(1, "day")
      //   .valueOf();
      endEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .endOf("day")
        .valueOf();
      validByMinMaxDate =
        startEpoch >= startDateEpoch && startEpoch <= endDateEpoch;
      validByDaysLimit =
        getDaysDiffFromStartEndEpoch(startEpoch, endEpoch) < daysLimit;
      break;
    case "last_7_days":
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("day")
        .subtract(6, "day")
        .valueOf();
      endEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .endOf("day")
        .valueOf();
      validByMinMaxDate =
        startEpoch >= startDateEpoch && startEpoch <= endDateEpoch;
      validByDaysLimit =
        getDaysDiffFromStartEndEpoch(startEpoch, endEpoch) < daysLimit;
      break;
    case "last_14_days":
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("day")
        .subtract(13, "day")
        .valueOf();
      endEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .endOf("day")
        .valueOf();
      validByMinMaxDate =
        startEpoch >= startDateEpoch && startEpoch <= endDateEpoch;
      validByDaysLimit =
        getDaysDiffFromStartEndEpoch(startEpoch, endEpoch) < daysLimit;
      break;
    case "last_30_days":
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("day")
        .subtract(29, "day")
        .valueOf();
      endEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .endOf("day")
        .valueOf();
      validByMinMaxDate =
        startEpoch >= startDateEpoch && startEpoch <= endDateEpoch;
      validByDaysLimit =
        getDaysDiffFromStartEndEpoch(startEpoch, endEpoch) < daysLimit;
      break;
    case "last_60_days":
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("day")
        .subtract(59, "day")
        .valueOf();
      endEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .endOf("day")
        .valueOf();
      validByMinMaxDate =
        startEpoch >= startDateEpoch && startEpoch <= endDateEpoch;
      validByDaysLimit =
        getDaysDiffFromStartEndEpoch(startEpoch, endEpoch) < daysLimit;
      break;
    case "this_week":
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("isoweek")
        .valueOf();
      endEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .endOf("day")
        .valueOf();
      validByMinMaxDate =
        startEpoch >= startDateEpoch && startEpoch <= endDateEpoch;
      validByDaysLimit =
        getDaysDiffFromStartEndEpoch(startEpoch, endEpoch) < daysLimit;
      break;
    case "last_week":
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("isoweek")
        .subtract(7, "day")
        .valueOf();
      endEpoch =
        moment()
          .utcOffset(selectedTimezone.minutesOffset)
          .startOf("isoweek")
          .valueOf() - 1;
      validByMinMaxDate =
        startEpoch >= startDateEpoch && startEpoch <= endDateEpoch;
      validByDaysLimit =
        getDaysDiffFromStartEndEpoch(startEpoch, endEpoch) < daysLimit;
      break;
    case "this_month":
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("month")
        .valueOf();
      endEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .endOf("day")
        .valueOf();
      validByMinMaxDate =
        startEpoch >= startDateEpoch && startEpoch <= endDateEpoch;
      validByDaysLimit =
        getDaysDiffFromStartEndEpoch(startEpoch, endEpoch) < daysLimit;
      break;
    case "last_month":
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("month")
        .subtract(1, "month")
        .valueOf();
      endEpoch =
        moment()
          .utcOffset(selectedTimezone.minutesOffset)
          .startOf("month")
          .valueOf() - 1;
      validByMinMaxDate =
        startEpoch >= startDateEpoch && startEpoch <= endDateEpoch;
      validByDaysLimit =
        getDaysDiffFromStartEndEpoch(startEpoch, endEpoch) < daysLimit;
      break;
    case "this_quarter":
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("quarter")
        .valueOf();
      endEpoch =
        moment()
          .utcOffset(selectedTimezone.minutesOffset)
          .endOf("quarter")
          .valueOf() - 1;
      validByMinMaxDate =
        startEpoch >= startDateEpoch && startEpoch <= endDateEpoch;
      validByDaysLimit =
        getDaysDiffFromStartEndEpoch(startEpoch, endEpoch) < daysLimit;
      break;
    case "year_till_date":
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("year")
        .valueOf();
      endEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .endOf("day")
        .valueOf();
      validByMinMaxDate =
        startEpoch >= startDateEpoch && startEpoch <= endDateEpoch;
      validByDaysLimit =
        getDaysDiffFromStartEndEpoch(startEpoch, endEpoch) < daysLimit;
      break;
    case "custom":
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("day")
        .valueOf();
      endEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .endOf("day")
        .valueOf();
      validByMinMaxDate = true;
      validByDaysLimit = true;
      break;
    default:
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("day")
        .valueOf();
      endEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .endOf("day")
        .valueOf();
      validByMinMaxDate =
        startEpoch >= startDateEpoch && startEpoch <= endDateEpoch;
      validByDaysLimit =
        getDaysDiffFromStartEndEpoch(startEpoch, endEpoch) < daysLimit;
      break;
  }
  let endDateEpochSelectedTimezone = moment(endDateEpoch)
    .utcOffset(selectedTimezone.minutesOffset)
    .valueOf();
  endEpoch = Math.min(endEpoch, endDateEpochSelectedTimezone);
  startDate = {
    formattedDate: moment(startEpoch)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
    epoch: startEpoch,
    nativeDateObj: new Date(
      startEpoch +
        selectedTimezone.minutesOffset * 60 * 1000 +
        new Date().getTimezoneOffset() * 60 * 1000
    ),
    timezone: selectedTimezone.name,
  };
  endDate = {
    formattedDate: moment(endEpoch)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
    epoch: endEpoch,
    nativeDateObj: new Date(
      endEpoch +
        selectedTimezone.minutesOffset * 60 * 1000 +
        new Date().getTimezoneOffset() * 60 * 1000
    ),
    timezone: selectedTimezone.name,
  };
  const valid = validByMinMaxDate && validByDaysLimit;
  return { startDate, endDate, valid };
};

export const getTimeZoneObj = (name, allTimezones) =>
  allTimezones.find((obj) => obj.name === name);

export const getDatePresetObj = (value, allDatePresets, key) => {
  const customDatePresetObj = allDatePresets.find(
    (obj) => obj["id"] === "custom"
  );
  const datePresetObj = allDatePresets.find((obj) => obj[key] === value);
  return datePresetObj || customDatePresetObj;
};

export const getObjectByKey = (props) => {
  const { key = "id", value = "", data = [] } = props;
  const object = data.find((obj) => obj[key] === value);
  return object || {};
};

export const getDatesSelectedQE = (selectedDates) => {
  let startDate = selectedDates.startDate.epoch;
  let endDate = selectedDates.endDate.epoch;

  // * OLD IMPLEMENTATION
  // // THIS WAS THE OLD IMPLEMENTATION WHICH WORK CORRECTLY
  // // ASSUMING THAT THE endDate of getDateRange that we get is a proper hour
  // // * CASE 1
  // // INPUT
  // // selectedDates.startDate: 15th July 00:00:00:0000
  // // selectedDates.endDate: 15th July 23:00:00:0000
  // // OUTPUT
  // // startDate: epoch for - 15th July 00:00:00:0000
  // // endDate: epoch for - 16th July 00:00:00:0000 // ! because it's a proper hour, hence true condition will run

  // // * CASE 2
  // // INPUT
  // // selectedDates.startDate: 15th July 12:00:00:0000
  // // selectedDates.endDate: 15th July 04:00:00:0000
  // // OUTPUT
  // // startDate: epoch for - 15th July 12:00:00:0000
  // // endDate: epoch for - 15th July 05:00:00:0000 // ! because it's a proper hour, hence true condition will run

  // // * CASE 3
  // // INPUT
  // // selectedDates.startDate: 15th July 12:00:00:0000
  // // selectedDates.endDate: 15th July 23:59:59:999
  // // OUTPUT
  // // startDate: epoch for - 15th July 12:00:00:0000
  // // endDate: epoch for - 16th July 00:00:00:0000 // ! because it's NOT a proper hour, hence false condition will run

  // //If it's not hour, add 1 millisecond, else add 1 hour
  // //Add one hour to end date to cater to QE (QE includes start date but not end date)
  // endDate = endDate % 3600000 === 0 ? endDate + 3600000 : endDate + 1;

  // * NEW IMPLEMENTATION
  // (Please read the above old implementation comments first)
  // This new implementation where the endDate that we get in the getDateRange is not a proper hour (new org case - ChartBoost)
  // ! In this new implementation we will check the difference between the selectedDates.endDate.epoch and the next hour
  // ! and add that missing milliseconds to make it the next hour
  // * CASE 1
  // INPUT
  // selectedDates.startDate: 15th July 00:00:00:0000
  // selectedDates.endDate: 15th July 23:00:00:0000
  // OUTPUT
  // startDate: epoch for - 15th July 00:00:00:0000
  // endDate: epoch for - 16th July 00:00:00:0000

  // * CASE 2
  // INPUT
  // selectedDates.startDate: 15th July 12:00:00:0000
  // selectedDates.endDate: 15th July 04:00:00:0000
  // OUTPUT
  // startDate: epoch for - 15th July 12:00:00:0000
  // endDate: epoch for - 15th July 05:00:00:0000

  // * CASE 3
  // INPUT
  // selectedDates.startDate: 15th July 12:00:00:0000
  // selectedDates.endDate: 15th July 23:59:59:999
  // OUTPUT
  // startDate: epoch for - 15th July 12:00:00:0000
  // endDate: epoch for - 16th July 00:00:00:0000

  // * CASE 4
  // INPUT
  // selectedDates.startDate: 15th July 12:00:00:0000
  // selectedDates.endDate: 15th July 04:22:00:0000 // !(since the endDate that we get from the getDateRange is less than the endDate that the user selected --- refer to getSelectedDates for better understanding)
  // OUTPUT
  // startDate: epoch for - 15th July 12:00:00:0000
  // endDate: epoch for - 16th July 05:00:00:0000

  // Add that missing milliseconds to make it the next hour
  endDate = endDate + (3600000 - (endDate % 3600000));

  return { startDate, endDate };
};

export const getPresetBasedOnSelectedDates = (props) => {
  let {
    startDateEpoch,
    endDateEpoch,
    selectedTimezone,
    allDatePresets,
    format,
    newSelectedDates,
    daysLimit,
  } = props;

  let newSelectedDatePreset;

  //Removing custom from allDatePresets
  allDatePresets = allDatePresets.filter(
    (datePresetRow) => datePresetRow.id !== "custom" //hardCoded
  );

  //Removing invalid dates preset
  allDatePresets = allDatePresets.filter(
    (datePresetRow) =>
      getStartEndByDatePreset({
        startDateEpoch,
        endDateEpoch,
        selectedDatePreset: datePresetRow.id,
        selectedTimezone,
        format,
        daysLimit,
      }).valid
  );

  //Looping through each VALID preset and checking if selectedDates match for any preset
  allDatePresets.forEach((datePresetRow) => {
    let selectedDatesPresetRow = getStartEndByDatePreset({
      startDateEpoch,
      endDateEpoch,
      selectedDatePreset: datePresetRow.id,
      selectedTimezone,
      format,
    });
    if (
      selectedDatesPresetRow.startDate.epoch ===
        newSelectedDates.startDate.epoch &&
      selectedDatesPresetRow.endDate.epoch === newSelectedDates.endDate.epoch
    ) {
      newSelectedDatePreset = datePresetRow.id;
    }
  });
  newSelectedDatePreset = newSelectedDatePreset || "custom";

  return newSelectedDatePreset;
};

export const addOneDayMinus1SecondMilliseconds = (value) => value + 86399999;
export const subtractOneDayMinus1SecondMilliseconds = (value) =>
  value % 3600000 ? value - 86399999 : value; // if it's not an hour make it an hour

export const addOneHourMinus1SecondMilliseconds = (value) => value + 3599999;

export const getStartEndBySelectedDatesInEpoch = (props) => {
  let {
    startDateEpoch,
    endDateEpoch,
    selectedStartDateEpoch,
    selectedEndDateEpoch,
    selectedTimezone,
    format,
  } = props;

  //converting to integer
  selectedStartDateEpoch = parseInt(selectedStartDateEpoch);
  selectedEndDateEpoch = parseInt(selectedEndDateEpoch);

  //Taking the valid start and end epoch
  selectedStartDateEpoch = Math.max(selectedStartDateEpoch, startDateEpoch);
  selectedEndDateEpoch = Math.min(selectedEndDateEpoch, endDateEpoch);

  //convert selectedStartDateEpoch to epoch utc and then to system timezone
  let nativeObjStartDate =
    selectedStartDateEpoch + selectedTimezone.minutesOffset * 60 * 1000;
  nativeObjStartDate =
    nativeObjStartDate + new Date().getTimezoneOffset() * 60 * 1000;
  // nativeObjStartDate = Math.max(nativeObjStartDate, startDateEpoch);
  let startDate = {
    timezone: selectedTimezone.name,
    epoch: selectedStartDateEpoch,
    timezone: selectedTimezone,
    nativeDateObj: new Date(nativeObjStartDate),
    formattedDate: moment(selectedStartDateEpoch)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
  };

  //convert selectedEndDateEpoch to epoch utc and then to system timezone
  let nativeObjEndDate =
    selectedEndDateEpoch + selectedTimezone.minutesOffset * 60 * 1000;
  nativeObjEndDate =
    nativeObjEndDate + new Date().getTimezoneOffset() * 60 * 1000;
  // nativeObjEndDate = Math.min(nativeObjEndDate, endDateEpoch);
  let endDate = {
    timezone: selectedTimezone.name,
    epoch: Math.min(selectedEndDateEpoch, endDateEpoch),
    timezone: selectedTimezone,
    nativeDateObj: new Date(nativeObjEndDate),
    formattedDate: moment(selectedEndDateEpoch)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
  };

  return { startDate, endDate, valid: true };
};

export const getDateAllVariations = (props) => {
  // INPUTS
  // selectedDate: JS native date object (selected end date by the user)
  // selectedTimezone: Object containing user selected timezone info
  // format: String to format the date
  //
  // OUTPUT
  // Object containing selected start and end dates as objects (explained at the top)
  //
  // NOTES
  // For JS native date object, we first convert the epoch to utc and then to user system's timezone

  let { selectedDate, selectedTimezone, format } = props;

  //Converting to user system timezone
  let selectedStartDateEpochInUTC =
    selectedDate.valueOf() - selectedDate.getTimezoneOffset() * 60 * 1000;
  let selectedStartDateEpochInUserSystemTimezone =
    selectedStartDateEpochInUTC - selectedTimezone.minutesOffset * 60 * 1000;

  return {
    epoch: moment(selectedStartDateEpochInUserSystemTimezone)
      .utcOffset(selectedTimezone.minutesOffset)
      .valueOf(),
    timezone: selectedTimezone,
    nativeDateObj: selectedDate,
    formattedDate: moment(selectedStartDateEpochInUserSystemTimezone)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
  };
};

export const getDateAllVariationsFromEpoch = (props) => {
  // INPUTS
  // selectedDateEpoch: Epoch (selected date by the user in epoch UTC)
  // selectedTimezone: Object containing user selected timezone info
  // format: String to format the date
  //
  // OUTPUT
  // Object containing selected start and end dates as objects (explained at the top)
  //
  // NOTES
  // For JS native date object, we first convert the epoch to utc and then to user system's timezone

  let { selectedDateEpoch, selectedTimezone, format } = props;

  //Converting to user system timezone
  let selectedDateEpochInUTC =
    selectedDateEpoch + selectedTimezone.minutesOffset * 60 * 1000;
  let selectedStartDateEpochInUserSystemTimezone =
    selectedDateEpochInUTC + new Date().getTimezoneOffset() * 60 * 1000;
  let nativeDateObj = new Date(selectedStartDateEpochInUserSystemTimezone);

  return {
    epoch: selectedDateEpoch,
    timezone: selectedTimezone,
    nativeDateObj,
    formattedDate: moment(selectedDateEpoch)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
  };
};

export const isStartEpochValid = (props) => {
  const { selectedStartEpoch, startDateEpoch } = props;
  if (selectedStartEpoch >= startDateEpoch) return true;
  return false;
};

export const isEndEpochValid = (props) => {
  const { selectedEndEpoch, endDateEpoch } = props;
  if (selectedEndEpoch <= endDateEpoch) return true;
  return false;
};

export const getDefaultCompareDates = (props) => {
  // INPUTS
  // selectedDates: current selected dates which has start and end dates
  // format: String to format the date
  //
  // OUTPUT
  // Object containing valid start and end dates as objects (explained at the top)
  //
  // NOTES

  const {
    selectedDates,
    selectedTimezone,
    format,
    startDateEpoch,
    endDateEpoch,
    compareSelectedStartDate,
    sameDurationFlag,
  } = props;

  //Difference between endDate epoch and startDate epoch
  //Here the diff in not exactly = selectedDates.endDate.epoch - selectedDates.startDate.epoch
  //because our endDate has the last millisecond of the end date
  //To get the actual duration, we need to add 1 millisecond to it
  const difference =
    selectedDates.endDate.epoch - selectedDates.startDate.epoch + 1;
  const differenceInDays = difference / (1000 * 60 * 60 * 24);
  const differenceInMilliseconds =
    Math.ceil(differenceInDays) * (1000 * 60 * 60 * 24);
  //If user has selected a start date from the calendar in compare calendar
  //compareSelectedStartDate will not be undefined
  //It means we consider that as the start date and not the one in the main calendar
  const compareStartDateEpoch = compareSelectedStartDate
    ? compareSelectedStartDate.epoch
    : selectedDates.startDate.epoch - differenceInMilliseconds;
  //Taking min of calculated endDate and actual endDate present in dateRange
  //If same duration flag is true, it means that the compare dates has to have the same duration
  const compareEndDateEpoch = sameDurationFlag
    ? Math.min(compareStartDateEpoch + difference - 1, endDateEpoch)
    : Math.min(
        compareStartDateEpoch + differenceInMilliseconds - 1,
        endDateEpoch
      );
  const compareStartDate = getDateAllVariationsFromEpoch({
    selectedDateEpoch: compareStartDateEpoch,
    selectedTimezone,
    format,
  });
  const compareEndDate = getDateAllVariationsFromEpoch({
    selectedDateEpoch: compareEndDateEpoch,
    selectedTimezone,
    format,
  });
  //If calculated compare dates fall out of valid start or end epoch
  //Set compare dates as selectedDates
  // const valid =
  //   isStartEpochValid({
  //     selectedStartEpoch: compareStartDateEpoch,
  //     startDateEpoch,
  //   }) &&
  //   isEndEpochValid({
  //     selectedEndEpoch: compareEndDateEpoch,
  //     endDateEpoch,
  //   });

  const valid = isStartEpochValid({
    selectedStartEpoch: compareStartDateEpoch,
    startDateEpoch,
  });
  const compareSelectedDates = valid
    ? {
        startDate: compareStartDate,
        endDate: compareEndDate,
        valid: true,
      }
    : { ...selectedDates };
  return compareSelectedDates;
};

export const getCompareValidDates = (props) => {
  // INPUTS
  // startDateEpoch: Valid start epoch value sent by the QE
  // endDateEpoch: Valid end epoch value sent by the QE
  // selectedTimezone: Object containing user selected timezone info
  // format: String to format the date
  //
  // OUTPUT
  // Object containing valid start and end dates as objects (explained at the top)
  //
  // NOTES
  // data is present for this date range in the QE
  // For JS native date object, we first convert the epoch to utc and then to user system's timezone

  let {
    startDateEpoch,
    endDateEpoch,
    selectedTimezone,
    format,
    selectedDates,
  } = props;

  let startDateEpochInUTC =
    startDateEpoch + new Date().getTimezoneOffset() * 60 * 1000;
  let startDateEpochInUserSystemTimezone =
    startDateEpochInUTC + selectedTimezone.minutesOffset * 60 * 1000;
  let startDate = {
    epoch: startDateEpoch,
    timezone: selectedTimezone.name,
    nativeDateObj: new Date(startDateEpochInUserSystemTimezone),
    formattedDate: moment(startDateEpoch)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
  };

  //Difference between endDate epoch and startDate epoch
  //Here the diff in not exactly = selectedDates.endDate.epoch - selectedDates.startDate.epoch
  //because our endDate has the last millisecond of the end date
  //To get the actual duration, we need to add 1 millisecond to it
  const difference =
    selectedDates.endDate.epoch - selectedDates.startDate.epoch + 1;

  //Subtracting difference from endEpoch so that user cannot select a date which is beyond endDate-difference
  //Eg: User select 1st June to 10th June and data is available till 15th June
  //So user shouldn't be allowed to select 6th to 15th June because any date between that plus 10 days will
  //fall out of range for compare dates
  //Adding 1 day equivalent milliseconds because we need to include last day as well
  let endDateEpochFinal = endDateEpoch - difference + 86400 * 1000;
  let endDateEpochInUTC =
    endDateEpochFinal + new Date().getTimezoneOffset() * 60 * 1000;
  let endDateEpochInUserSystemTimezone =
    endDateEpochInUTC + selectedTimezone.minutesOffset * 60 * 1000;
  let endDate = {
    epoch: endDateEpochFinal,
    timezone: selectedTimezone.name,
    nativeDateObj: new Date(endDateEpochInUserSystemTimezone),
    formattedDate: moment(endDateEpochFinal)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
  };
  return { startDate, endDate };
};

export const getDateAllVariationsFromNativeObject = (props) => {
  const { selectedDateNativeObject, selectedTimezone, format } = props;

  //Converting to user system timezone
  const selectedDateNativeObjectEpochInUTC =
    selectedDateNativeObject.valueOf() -
    selectedDateNativeObject.getTimezoneOffset() * 60 * 1000;
  const selectedDateNativeObjectEpochInUserSystemTimezone =
    selectedDateNativeObjectEpochInUTC -
    selectedTimezone.minutesOffset * 60 * 1000;

  const dateObject = {
    timezone: selectedTimezone.name,
    epoch: moment(selectedDateNativeObjectEpochInUserSystemTimezone)
      .utcOffset(selectedTimezone.minutesOffset)
      .valueOf(),
    timezone: selectedTimezone,
    nativeDateObj: selectedDateNativeObject,
    formattedDate: moment(selectedDateNativeObjectEpochInUserSystemTimezone)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
  };

  return dateObject;
};

export const getCompareNewRow = () => ({
  id: v4(),
  compareSelectedDates: {},
  compareSelectedDatesSameDuration: {},
  compareValidDates: {},
  compareSelectedDatesQE: {},
  compareSelectedDatesQESameDuration: {},
  compareValidDatePresets: [
    {
      id: "custom",
      name: "Custom",
      available: true,
      priority: 1,
    },
  ],
  compareSelectedDatePreset: "",
  isSelected: false,
});

export const getStartEndByDatePresetForCompare = (props) => {
  const {
    selectedDates,
    mainSelectedDatePreset,
    currDatePreset,
    selectedTimezone,
    format,
    sameDurationFlag,
    startDateEpoch,
    endDateEpoch,
  } = props;
  let valid = true;
  let startEpoch = 0,
    endEpoch = 0,
    difference = 0,
    differenceInDays = 0,
    differenceInMilliseconds = 0;

  const selectedDatesDifference =
    selectedDates.endDate.epoch - selectedDates.startDate.epoch + 1;

  switch (currDatePreset) {
    case "yesterday":
      //CONDITION 1 -> Is valid only when the mainCalendarSelectedDatePreset is today
      //CONDITION 2 -> startEpoch >= startDateEpoch && endEpoch <= endDateEpoch
      //CONDITION 1
      valid = mainSelectedDatePreset === "today";
      startEpoch = moment()
        .utcOffset(selectedTimezone.minutesOffset)
        .startOf("day")
        .subtract(1, "day")
        .valueOf();
      endEpoch = sameDurationFlag
        ? startEpoch + selectedDatesDifference - 1
        : moment()
            .utcOffset(selectedTimezone.minutesOffset)
            .endOf("day")
            .subtract(1, "day")
            .valueOf();
      //CONDITION 2
      valid = valid && startEpoch >= startDateEpoch && endEpoch <= endDateEpoch;
      break;
    case "previous_week":
      //CONDITION 1 -> Selected startDate and endDate should fall within the same week
      //CONDITION 2 -> startEpoch >= startDateEpoch && endEpoch <= endDateEpoch
      //CONDITION 1
      valid =
        selectedDates.startDate.epoch >=
          moment(selectedDates.startDate.epoch)
            .utcOffset(selectedTimezone.minutesOffset)
            .startOf("isoweek")
            .valueOf() &&
        selectedDates.endDate.epoch <
          moment(selectedDates.startDate.epoch)
            .utcOffset(selectedTimezone.minutesOffset)
            .startOf("isoweek")
            .valueOf() +
            7 * 24 * 60 * 60 * 1000;
      difference =
        selectedDates.endDate.epoch - selectedDates.startDate.epoch + 1;
      differenceInDays = difference / (1000 * 60 * 60 * 24);
      differenceInMilliseconds =
        Math.ceil(differenceInDays) * (1000 * 60 * 60 * 24);
      startEpoch = moment(selectedDates.startDate.epoch)
        .utcOffset(selectedTimezone.minutesOffset)
        .subtract(1, "week")
        .valueOf();
      endEpoch = sameDurationFlag
        ? startEpoch + selectedDatesDifference - 1
        : startEpoch + differenceInMilliseconds - 1;
      //CONDITION 2
      valid = valid && startEpoch >= startDateEpoch && endEpoch <= endDateEpoch;
      break;
    case "previous_month":
      //CONDITION 1 -> Selected startDate and endDate should fall within the same month
      //CONDITION 2 -> startEpoch >= startDateEpoch && endEpoch <= endDateEpoch
      //CONDITION 1
      valid =
        selectedDates.startDate.epoch >=
          moment(selectedDates.startDate.epoch)
            .utcOffset(selectedTimezone.minutesOffset)
            .startOf("month")
            .valueOf() &&
        selectedDates.endDate.epoch <
          moment(selectedDates.startDate.epoch)
            .utcOffset(selectedTimezone.minutesOffset)
            .startOf("month")
            .add(1, "month")
            .valueOf();
      difference =
        selectedDates.endDate.epoch - selectedDates.startDate.epoch + 1;
      differenceInDays = difference / (1000 * 60 * 60 * 24);
      differenceInMilliseconds =
        Math.ceil(differenceInDays) * (1000 * 60 * 60 * 24);
      startEpoch = moment(selectedDates.startDate.epoch)
        .utcOffset(selectedTimezone.minutesOffset)
        .subtract(1, "month")
        .valueOf();
      endEpoch = sameDurationFlag
        ? startEpoch + selectedDatesDifference - 1
        : startEpoch + differenceInMilliseconds - 1;
      //CONDITION 2
      valid = valid && startEpoch >= startDateEpoch && endEpoch <= endDateEpoch;
      break;
    case "custom":
      startEpoch = selectedDates.startDate.epoch;
      endEpoch = selectedDates.endDate.epoch;
      valid = true;
      break;
    default:
      startEpoch = selectedDates.startDate.epoch;
      endEpoch = selectedDates.endDate.epoch;
      valid = true;
      break;
  }

  let startDate = {
    formattedDate: moment(startEpoch)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
    epoch: startEpoch,
    nativeDateObj: new Date(
      startEpoch +
        selectedTimezone.minutesOffset * 60 * 1000 +
        new Date().getTimezoneOffset() * 60 * 1000
    ),
    timezone: selectedTimezone.name,
  };
  let endDate = {
    formattedDate: moment(endEpoch)
      .utcOffset(selectedTimezone.minutesOffset)
      .format(format),
    epoch: endEpoch,
    nativeDateObj: new Date(
      endEpoch +
        selectedTimezone.minutesOffset * 60 * 1000 +
        new Date().getTimezoneOffset() * 60 * 1000
    ),
    timezone: selectedTimezone.name,
  };
  // const valid = validByMinMaxDate && validByDaysLimit;
  return { startDate, endDate, valid };
};

export const getDefaultStartAndEndDateEpochBasedOnValidDates = (props) => {
  //It's value is equal to validDate's endDate epoch
  const { validDates, selectedTimezone } = props;
  const endDateEpoch = parseInt(validDates.endDate.epoch);
  //startDateEpoch is last_2_days wrt valid endDate epoch
  //If the calculated value is smaller than the valid startDate epoch, replace it with valid startDate epoch
  const startDateEpoch = Math.max(
    moment(endDateEpoch)
      .utcOffset(selectedTimezone.minutesOffset)
      .startOf("day")
      .subtract(1, "day")
      .valueOf(),
    validDates.startDate.epoch
  );
  return { startDateEpoch, endDateEpoch };
};

export const areSelectedDatesValid = (props) => {
  //A function that takes:
  //  1) selectedDates: {startDate: <epoch>, endDate: <epoch>}
  //  2) validDates: {startDate: <epoch>, endDate: <epoch>}
  //And returns if the selectedDates are valid with respect to the passed valid dates
  const { selectedDates, validDates } = props;
  const isStartDateValid =
    parseInt(selectedDates.startDate) >= parseInt(validDates.startDate) &&
    parseInt(selectedDates.startDate) <= parseInt(validDates.endDate);
  const isEndDateValid =
    parseInt(selectedDates.endDate) >= parseInt(validDates.startDate) &&
    parseInt(selectedDates.endDate) <= parseInt(validDates.endDate);
  if (isStartDateValid && isEndDateValid) return true;
  return false;
};

export const getPrimaryCompareDateObject = (timeFilters) => {
  var primaryCompareDateObject = timeFilters.compareDates.find(
    (row) => row.isSelected
  );
  // ! If no rows are selected, take the first one
  if (!primaryCompareDateObject) {
    primaryCompareDateObject = timeFilters.compareDates[0];
  }
  return primaryCompareDateObject;
};
