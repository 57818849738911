// import d3 from "d3";
import * as d3 from "d3";
import moment from "moment";
import "moment-timezone";
import _ from "underscore";
import cloneDeep from "lodash/cloneDeep";
import numeral from "numeral";
import Treemap from "./treemap_sqaured";
import { config } from "../config/config";
import { v4 } from "uuid";

// function to generate array of colors - used for gradient in stacked bar chart
function hex(c) {
  var s = "0123456789abcdef";
  var i = parseInt(c);
  if (i === 0 || isNaN(c)) return "00";
  i = Math.round(Math.min(Math.max(0, i), 255));
  return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
}

function getTooltipLayout(tooltipType) {
  return d3
    .select("body")
    .append("div")
    .attr("id", "tooltip_" + Date.now().toString())
    .attr("class", "plotlyTooltip_" + tooltipType)
    .style("position", "absolute")
    .style("z-index", "10")
    .style("visibility", "hidden")
    .style("padding", "3px 11px")
    .style("background", "#000000")
    .style("border-radius", "4px");
}

function getColorByDecileVal(colorGrad, val, decileArr, themeState) {
  if (val === 0) return themeState.themes[themeState.activeTheme].dsItemBgColor;
  if (val < decileArr[0]) return colorGrad[0];
  else if (val >= decileArr[decileArr.length - 1])
    return colorGrad[colorGrad.length - 1];
  else {
    for (var i = 0; i < decileArr.length - 1; i++) {
      if (val >= decileArr[i] && val < decileArr[i + 1]) return colorGrad[i];
    }
    console.log("could not allocate color");
  }
}

// ! New Fucntion for data showing on metric chart duew to Openx Client request
export function appendMetricValue(val, dataUnit, formatNum) {
  if (!_.isUndefined(dataUnit) && !_.isUndefined(val)) {
    if (dataUnit.dType.toLowerCase() === "currency") {
      if (numeral(val).value() >= 0) {
        if (formatNum)
          return dataUnit.value + numeral(val).format("0.[00] a").toLowerCase();
        else return dataUnit.value + numeral(val).format("0,000.[00]");
      } else {
        if (formatNum)
          return (
            "-" +
            dataUnit.value +
            numeral(Math.abs(Number(val)))
              .format("0.[00] a")
              .toLowerCase()
          );
        //return '- ' + dataUnit.value + numeral(Math.abs(Number(val))).format("0,.[00]");
        else
          return (
            "-" +
            dataUnit.value +
            numeral(numeral(val).value() * -1).format("0,.[00]")
          );
      }
    }
    if (dataUnit.dType.toLowerCase() === "percent") {
      if (formatNum)
        return numeral(val).format("0.[00] a").toLowerCase() + dataUnit.value;
      else return numeral(val).format("0,000.[00]") + dataUnit.value;
    }
    if (dataUnit.dType.toLowerCase() === "string") {
      if (formatNum) return numeral(val).format("0,0.[00]").toLowerCase();
      else return numeral(val).format("0,0.[00]");
    }
  }
  if (formatNum) return numeral(val).format("0.[00] a").toLowerCase();
  else return numeral(val).format("0,000.[00]");
}

export function appendMetricSym(val, dataUnit, formatNum) {
  if (!_.isUndefined(dataUnit) && !_.isUndefined(val)) {
    if (dataUnit.dType.toLowerCase() === "currency") {
      if (numeral(val).value() >= 0) {
        if (formatNum)
          return dataUnit.value + numeral(val).format("0.[00] a").toLowerCase();
        else return dataUnit.value + numeral(val).format("0,000.[00]");
      } else {
        if (formatNum)
          return (
            "-" +
            dataUnit.value +
            numeral(Math.abs(Number(val)))
              .format("0.[00] a")
              .toLowerCase()
          );
        //return '- ' + dataUnit.value + numeral(Math.abs(Number(val))).format("0,.[00]");
        else
          return (
            "-" +
            dataUnit.value +
            numeral(numeral(val).value() * -1).format("0,.[00]")
          );
      }
    }
    if (dataUnit.dType.toLowerCase() === "percent") {
      if (formatNum)
        return numeral(val).format("0.[00] a").toLowerCase() + dataUnit.value;
      else return numeral(val).format("0,000.[00]") + dataUnit.value;
    }
  }
  if (formatNum) return numeral(val).format("0.[00] a").toLowerCase();
  else return numeral(val).format("0,000.[00]");
}

export function appendMetricSymPercentageTotal(val, dataUnit, formatNum) {
  if (!_.isUndefined(dataUnit) && !_.isUndefined(val)) {
    if (dataUnit.dType.toLowerCase() === "currency") {
      if (numeral(val).value() >= 0) {
        if (formatNum)
          return dataUnit.value + numeral(val).format("0,000 ").toUpperCase();
        else return dataUnit.value + numeral(val).format("0,000");
      } else {
        if (formatNum)
          return (
            "-" +
            dataUnit.value +
            numeral(Math.abs(Number(val)))
              .format("0,000 ")
              .toUpperCase()
          );
        //return '- ' + dataUnit.value + numeral(Math.abs(Number(val))).format("0,.[00]");
        else
          return (
            "-" +
            dataUnit.value +
            numeral(numeral(val).value() * -1).format("0,000")
          );
      }
    }
    if (dataUnit.dType.toLowerCase() === "percent") {
      if (formatNum)
        return numeral(val).format("0.[00] a").toLowerCase() + dataUnit.value;
      else return numeral(val).format("0,000.[00]") + dataUnit.value;
    }
  }
  if (formatNum) return numeral(val).format("0,000").toUpperCase();
  else return numeral(val).format("0,000");
}

function findChartType(funcName, chartType) {
  switch (funcName) {
    case "isBarTypeChart":
      return chartType === "bar" || chartType === "horizontalbar"
        ? true
        : false;
    case "isGroupedBarTypeChart":
      return chartType === "groupedbar" || chartType === "groupedhorizontalbar"
        ? true
        : false;
    case "isStackedBarTypeChart":
      return (
        chartType === "verticalstackedbar" ||
        chartType === "horizontalstackedbar" ||
        chartType === "percentagestackedbar"
      );
    case "isPieChart":
      return chartType === "pie" ? true : false;
    case "isTableTypeChart":
      return chartType === "table" ||
        chartType === "nestedtable" ||
        chartType === "multitable"
        ? true
        : false;
    case "isLineTypeChart":
      return chartType === "line" ||
        chartType === "multiline" ||
        chartType === "multiaxisline" ||
        chartType === "barline"
        ? true
        : false;
    case "isCounter":
      return chartType === "counters" ? true : false;
    case "isMultiLineChart":
      return chartType === "multiline" ? true : false;
    case "isMultiAxisLineChart":
      return chartType === "multiaxisline" ? true : false;
    case "isBarLineChart":
      return chartType === "barline" ? true : false;
    case "isMap":
      return chartType === "heatmapworld" ? true : false;
    case "isScatterChart":
      return chartType === "scatterplot" ? true : false;
    case "isBubbleChart":
      return chartType === "bubble" ? true : false;
    case "isTreeMap":
      return chartType === "treemap" ? true : false;
    case "isCrossTabHeatMap":
      return chartType === "crosstabheatmap" ? true : false;
    case "isAxisChartType":
      return findChartType("isBarTypeChart", chartType) ||
        findChartType("isGroupedBarTypeChart", chartType) ||
        findChartType("isStackedBarTypeChart", chartType) ||
        findChartType("isLineTypeChart", chartType) ||
        findChartType("isScatterChart", chartType) ||
        findChartType("isBubbleChart", chartType) ||
        findChartType("isCrossTabHeatMap", chartType)
        ? true
        : false;
    case "isLegendsTypeChart":
      return findChartType("isGroupedBarTypeChart", chartType) ||
        findChartType("isStackedBarTypeChart", chartType) ||
        findChartType("isPieChart", chartType) ||
        findChartType("isMultiLineChart", chartType)
        ? true
        : false;
    case "isXAxisPrimaryChart":
      return chartType === "bar" ||
        chartType === "groupedbar" ||
        chartType === "verticalstackedbar" ||
        chartType === "percentagestackedbar" ||
        findChartType("isLineTypeChart", chartType) ||
        findChartType("isScatterChart", chartType) ||
        findChartType("isBubbleChart", chartType) ||
        findChartType("isCrossTabHeatMap", chartType)
        ? true
        : false;
    case "isYAxisPrimaryChart":
      return chartType === "horizontalbar" ||
        chartType === "groupedhorizontalbar" ||
        chartType === "horizontalstackedbar"
        ? true
        : false;
    case "isTimeSeriesSupportedChart":
      return chartType === "line" ||
        chartType === "multiline" ||
        chartType === "multiaxisline" ||
        chartType === "barline" ||
        chartType === "bar" ||
        chartType === "groupedbar" ||
        chartType === "area"
        ? true
        : false;
    default:
      return undefined;
  }
}

/* Convert an RGB triplet to a hex string */
function convertToHex(rgb) {
  return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);
}

function parseRGBColor(colorCode) {
  var startIndex = colorCode.indexOf("("),
    endIndex = colorCode.indexOf(")");
  colorCode = colorCode.substring(startIndex + 1, endIndex);
  colorCode = colorCode.split(", ");
  colorCode = colorCode.map((item) => Number(item));
  return colorCode;
}

function isAxisChartType(chartType) {
  return chartType === "pie" || isChartTypeTable(chartType) ? false : true;
}

function isChartTypeTable(chartType) {
  return chartType === "table" ||
    chartType === "multitable" ||
    chartType === "nestedtable"
    ? true
    : false;
}

function isLineChart(chartType) {
  return chartType === "line" || chartType === "multiline" ? true : false;
}

function isHorizontalOrientedChart(chartType) {
  return chartType === "horizontalbar" ||
    chartType === "groupedhorizontalbar" ||
    chartType === "horizontalstackedbar"
    ? true
    : false;
}

// function getLegendNameColorMap({ xval, data, chartType }) {
//   let legendNameColorMap = new Map();
//   if (findChartType("isLegendsTypeChart", chartType)) {
//     if (findChartType("isPieChart", chartType)) {
//       if (xval[0].dataType !== "dateTime") {
//         var labels = data[0].labels,
//           colors = data[0].marker.colors;
//         labels.forEach(function (item, index) {
//           legendNameColorMap[colors[index]] = item;
//         });
//       }
//     }
//     if (
//       findChartType("isGroupedBarTypeChart", chartType) ||
//       findChartType("isStackedBarTypeChart", chartType) ||
//       findChartType("isMultiLineChart", chartType) ||
//       findChartType("isMultiAxisLineChart", chartType)
//     ) {
//       if (xval.length > 1 && xval[1].dataType === "dateTime") {
//       } else {
//         data.forEach(function (trace) {
//           legendNameColorMap[trace.marker.color] = trace.name;
//         });
//       }
//     }
//   }
//   return legendNameColorMap;
// }

var thousandSeperator = d3.format(",");

//Need to change this function based on correct understanding
// function getTimeZone() {
//   return { name: "UTC (+00:00)", location: "UTC" };
// }

//Need to change this function based on correct understanding
function getGranularity() {
  return "hour";
}

//Need to change this function based on correct understanding (getTimeZone depend)
function adjustTimeStamps(data, granularity, selectedTimezone) {
  let timeZoneOffset =
    moment.tz(selectedTimezone.location).utcOffset() * 60 * 1000;
  let newData = data.map((row) => ({
    ...row,
    [granularity]: Number(row[granularity]) + timeZoneOffset,
  }));
  return newData;
}

export const makeSequentialColorArray = (startHex, endHex) => {
  let count = 11;
  let arr = d3.range(count);
  let colorFunc = d3
    .scaleSequential()
    .domain([d3.min(arr), d3.max(arr)])
    .interpolator(d3.interpolate(startHex, endHex));
  let colorArr = d3.range(count).map((d, i) => colorFunc(d));
  return colorArr;
};

function getColorDistributionForWorldmap() {
  var colors = [];
  var COLOR_FIRST = "#e6f0fc",
    COLOR_LAST = "#267EE6";
  var COLOR_COUNTS = 10;

  //noinspection JSAnnotator
  function Interpolate(start, end, steps, count) {
    var s = start,
      e = end,
      final = s + ((e - s) / steps) * count;
    return Math.floor(final);
  }

  //noinspection JSAnnotator
  function Color(_r, _g, _b) {
    var r, g, b;
    var setColors = function (_r, _g, _b) {
      r = _r;
      g = _g;
      b = _b;
    };

    setColors(_r, _g, _b);
    this.getColors = function () {
      var colors = {
        r: r,
        g: g,
        b: b,
      };
      return colors;
    };
  }

  //noinspection JSAnnotator
  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  var rgb = hexToRgb(COLOR_FIRST);
  var COLOR_START = new Color(rgb.r, rgb.g, rgb.b);

  rgb = hexToRgb(COLOR_LAST);
  var COLOR_END = new Color(rgb.r, rgb.g, rgb.b);

  var startColors = COLOR_START.getColors(),
    endColors = COLOR_END.getColors();

  for (var i = 0; i <= COLOR_COUNTS; i++) {
    var r = Interpolate(startColors.r, endColors.r, COLOR_COUNTS, i);
    var g = Interpolate(startColors.g, endColors.g, COLOR_COUNTS, i);
    var b = Interpolate(startColors.b, endColors.b, COLOR_COUNTS, i);
    colors.push(new Color(r, g, b));
  }

  function convertToRGB(obj) {
    return "rgb(" + obj["r"] + "," + obj["g"] + "," + obj["b"] + ")";
  }

  //colorsLayoutFormat.push([0, 'rgb(255, 255, 255)']);
  // var colorsLayoutFormat = _.map(colors, function (item, index) {
  //   return convertToRGB(item.getColors());
  // });

  var colors = [
    "#eaf4f9",
    "#d5eaf4",
    "#c0dfee",
    "#aad4e8",
    "#94c9e3",
    "#7fbedd",
    "#69b3d7",
    "#55a9d2",
    "#3f9ecc",
    "#2892c6",
  ];
  colors = _.map(colors, function (color) {
    return hexToRgb(color);
  });
  colors = _.map(colors, function (color) {
    return new Color(color.r, color.g, color.b);
  });
  colors = _.map(colors, function (item, index) {
    return convertToRGB(item.getColors());
  });

  return colors;
}

function getDecileList(list) {
  var decileArr = [];
  list.sort(function (a, b) {
    return b - a;
  });
  var mainList = [];
  mainList[0] = list;
  var zeroesList = [];
  for (let i = 0; i < list.length; i++) zeroesList.push(0);
  mainList[1] = zeroesList;
  mainList[1][0] = list[0];
  for (let i = 1; i < list.length; i++) {
    mainList[1][i] = mainList[1][i - 1] + mainList[0][i];
  }
  var sum = _.reduce(list, function (num, memo) {
    return num + memo;
  });
  var decileLimit = sum / 10,
    factor = 1;
  mainList[2] = zeroesList;
  for (let i = 0; i < list.length; i++) {
    if (mainList[1][i] >= decileLimit * factor) {
      decileArr.push(mainList[0][i]);
      factor = factor + 1;
    }
  }

  while (decileArr.length > 10) {
    decileArr = decileArr.slice(0, decileArr.length - 1);
  }
  decileArr.reverse();
  return decileArr;
}

export function isTimeDimension(dim) {
  return dim.dataType === "dateTime" ? true : false;
}

export function getCustomColorPallete(themeState) {
  const activeTheme = themeState.activeTheme;
  const themeColors = themeState.themes[activeTheme];
  const allColors = {
    light: [
      themeColors["primaryColor"],
      //themeColors["primaryColorLighter"],
      "#ffd700",
      "#5EC7AA",
      "#D369B6",
      "#03dffc",
      "#A4BFFA",
      "#9FE1D2",
      "#FBE9A1",
      //OLD ONES (because we only got 9 colors from the design team)
      // "#cfd0cf",
      // "#2892c6",
      // "#2892c6",
      // "#3b51e4",
      // "#d07573",
      // "#d89426",
      // "#71c976",
      // "#038e80",
      // "#a25fd5",
      // "#d2b93b",
      // "#b38983",
      // "#bebfbe",
      //Iteration 1
      "#F6A9A9",
      "#FFBF86",
      "#FFF47D",
      "#C2F784",
      "#64C9CF",
      "#FFB740",
      "#DF711B",
      "#B980F0",
      "#F6B8B8",
      "#AC66CC",
      "#1EAE98",
      "#34656D",
      // //Iteration 2
      // "#9B72AA",
      // "#C6B4CE",
      // "#FFBCBC",
      // "#F38BA0",
      // "#B5CDA3",
      // "#C9E4C5",
      // "#A7C4BC",
      // "#5E8B7E",
      // "#2F5D62",
      // "#0C4271",
      // "#0A81AB",
      // "#867AE9",
      //Iteration 3
      // "#F6A9A9",
      // "#6F4C5B",
      // "#464660",
      // "#368B85",
      // "#B4B897",
      // "#BD4B4B",
      // "#3C5186",
      // "#5089C6",
      // "#57837B",
      // "#ED8E7C",
      // "#515E63",
      // "#F08FC0",
    ],
    dark: [
      themeColors["primaryColor"],
      // themeColors["primaryColorLighter"],
      "#ffd700",
      "#5EC7AA",
      "#D369B6",
      "#03dffc",
      "#A4BFFA",
      "#9FE1D2",
      "#FBE9A1",
      //OLD ONES (because we only got 9 colors from the design team)
      "#cfd0cf",
      "#2892c6",
      "#2892c6",
      "#3b51e4",
      "#d07573",
      "#d89426",
      "#71c976",
      "#038e80",
      "#a25fd5",
      "#d2b93b",
      "#b38983",
      "#bebfbe",
    ],
  };
  return allColors[activeTheme];
  //OLD
  // const original = [
  //   "#52baed",
  //   "#7383ec",
  //   "#ef8b88",
  //   "#f8ae35",
  //   "#9be89f",
  //   "#06c9b5",
  //   "#c283f3",
  //   "#efd450",
  //   "#c89e97",
  //   "#cfd0cf",
  //   "#2892c6",
  //   "#2892c6",
  //   "#3b51e4",
  //   "#d07573",
  //   "#d89426",
  //   "#71c976",
  //   "#038e80",
  //   "#a25fd5",
  //   "#d2b93b",
  //   "#b38983",
  //   "#bebfbe",
  // ];
  //NEW
  // const original = [
  //   "#267EE6",
  //   "#5EC7AA",
  //   "#FCE74E",
  //   "#EEB04F",
  //   "#D369B6",
  //   "#A4BFFA",
  //   "#9FE1D2",
  //   "#FBE9A1",
  //   //OLD ONES (because we only got 9 colors from the design team)
  //   "#cfd0cf",
  //   "#2892c6",
  //   "#2892c6",
  //   "#3b51e4",
  //   "#d07573",
  //   "#d89426",
  //   "#71c976",
  //   "#038e80",
  //   "#a25fd5",
  //   "#d2b93b",
  //   "#b38983",
  //   "#bebfbe",
  // ];
  // const lightColors = [
  //   "#D3F6E4",
  //   "#EDEEF6",
  //   "#B7B6FA",
  //   "#ABD6DE",
  //   "#F2E0EA",
  //   "#DDBCD3",
  //   "#A7DBCB",
  //   "#D9EBC6",
  //   "#F8D5B8",
  //   "#F3AEA9",
  //   ...original,
  // ];
  // const darkColors = [
  //   "#232830",
  //   "#3A3E45",
  //   "#4CAAB3",
  //   "#224C72",
  //   "#F7EC7D",
  //   "#E28E66",
  //   "#AA445F",
  //   "#62326D",
  //   "#62D6D5",
  //   "#EC4566",
  //   ...original,
  // ];
  // const mediumColors = [
  //   "#D6E4A4",
  //   "#3E8F7D",
  //   "#A0D6AC",
  //   "#B7B6FA",
  //   "#746CDE",
  //   "#EEE4D8",
  //   "#EDC2C1",
  //   "#EE7975",
  //   "#ABD6DE",
  //   "#CE8390",
  //   ...original,
  // ];
  // return original;
}

function convertLineChartData(lineChartData, xAxisId) {
  var newLineChartData = _.map(lineChartData, function (item) {
    var granularityValue = getGranularity();

    if (
      granularityValue === "month" ||
      (xAxisId === "month" &&
        xAxisId !== undefined &&
        xAxisId !== null &&
        xAxisId !== "")
    ) {
      item[xAxisId] = moment.utc(Number(item[xAxisId])).format("YYYY-MM-DD");
    } else {
      item[xAxisId] = moment
        .utc(Number(item[xAxisId]))
        .format("YYYY-MM-DD HH:mm:SS");
    }
    return item;
  });
  return newLineChartData;
}

function getMarkerSize(length) {
  if (length <= 25) {
    return 4;
  } else if (length <= 100) {
    return 4;
  } else if (length <= 220) {
    return 4;
  }
  return 3;
}

function getYAxisValues(dataFromQE, yAxis) {
  var yArray = _.pluck(dataFromQE, yAxis.elementID);
  return yArray;
}

function getXAxisValues(dataFromQE, xAxis) {
  var xArray = _.pluck(dataFromQE, xAxis.elementID);
  return xArray;
}

function getBaseColor(themeState) {
  return themeState.themes[themeState.activeTheme]["primaryColor"];
  // return "#52baed";
  // return "#267EE6";
}

function getColorGradMap(color) {
  let colorGradMap = new Map();
  colorGradMap["#52baed"] = "#2892c6";
  colorGradMap["#7383ec"] = "#3b51e4";
  colorGradMap["#ef8b88"] = "#d07573";
  colorGradMap["#f8ae35"] = "#d89426";
  colorGradMap["#9be89f"] = "#71c976";
  return colorGradMap[color];
}

//DatastoryMetadataServive
function getDimensionTitleByID(dimID, plotlyDimensions) {
  var dim = _.filter(plotlyDimensions, function (dim) {
    return dim.dimID === dimID;
  });
  if (dim.length) return dim[0].dimTitle;
  return undefined;
}

function getMeasureTitleByID(metricID, plotlyMetrics) {
  var metric = _.filter(plotlyMetrics, function (metric) {
    return metric.measureID === metricID;
  });
  if (metric.length) return metric[0].measureTitle;
  return undefined;
}

//Metadata service
export function getMeasureDataUnitByID(metricID, plotlyMetrics) {
  var metric = _.filter(plotlyMetrics, function (metricObj) {
    return metricObj.measureID === metricID;
  });
  if (metric.length > 0) return metric[0].dataUnit;
}

//ChartService
export function getDimensionObjByID(dimID, plotlyDimensions) {
  var item = _.filter(plotlyDimensions, function (d) {
    return d.dimID === dimID;
  });
  if (item.length > 0) {
    return item[0];
  } else {
    return undefined;
  }
}

export function getMeasureObjByID(metricID, plotlyMetrics) {
  var metric = _.filter(plotlyMetrics, function (item) {
    return item.measureID === metricID;
  });
  if (metric.length > 0) {
    return metric[0];
  }
  return undefined;
}

function getTooltipData(
  chartData,
  xAxis,
  yAxis,
  plotlyMetrics,
  plotlyDimensions,
  isSigviewPage
) {
  var tooltipInfo = _.map(chartData, function (item) {
    var string = "",
      metricVal;
    _.each(xAxis, function (item2) {
      if (
        !_.isUndefined(
          getDimensionTitleByID(item2.elementID, plotlyDimensions)
        ) &&
        !isSigviewPage
      )
        string +=
          getDimensionTitleByID(item2.elementID, plotlyDimensions) +
          ": " +
          item[item2.elementID];
      else string += item[item2.elementID];
      string += "<br>";
    });
    _.each(yAxis, function (item2) {
      metricVal = Number(item[item2.elementID]);
      metricVal =
        metricVal === Math.floor(metricVal) ? metricVal : metricVal.toFixed(2);
      var metricTitle = getMeasureTitleByID(item2.elementID, plotlyMetrics);
      var dataUnit = getMeasureDataUnitByID(item2.elementID, plotlyMetrics);
      if (!_.isUndefined(metricTitle) && !isSigviewPage) {
        string +=
          metricTitle +
          ": " +
          appendMetricSym(thousandSeperator(metricVal), dataUnit, false);
      } else {
        string += appendMetricSym(
          thousandSeperator(metricVal),
          dataUnit,
          false
        );
      }
      string += "<br>";
    });
    return string;
  });
  return tooltipInfo;
}

function getStackedData(
  dataFromQE,
  xAxis,
  yAxis,
  secondaryData,
  orderBy,
  plotlyMetrics,
  plotlyDimensions
) {
  var maxBars = 20;
  var groupedByFirstDimension = _.groupBy(dataFromQE, function (item) {
    return item[xAxis[0].elementID];
  });
  var allKeyNames = _.keys(groupedByFirstDimension);
  var keyTotalArray = [];
  _.each(allKeyNames, function (value, index) {
    var sum = 0;
    _.each(groupedByFirstDimension[value], function (v, i) {
      sum = sum + Number(v[yAxis[0].elementID]);
    });
    keyTotalArray.push({
      name: value,
      total: sum,
    });
  });
  var sortedArray = [];
  //var sortedArray = _.sortBy(keyTotalArray, 'total');
  //sortedArray.reverse();
  var obj = getDimensionObjByID(orderBy[0].id, plotlyDimensions);
  if (obj) {
    sortedArray = _.sortBy(keyTotalArray, "name");
    if (orderBy[0].desc) {
      sortedArray.reverse();
    }
  } else {
    obj = getMeasureObjByID(orderBy[0].id, plotlyMetrics);
    if (obj) {
      sortedArray = _.sortBy(keyTotalArray, "total");
      if (orderBy[0].desc) {
        sortedArray.reverse();
      }
    }
  }

  //var sortedArray = keyTotalArray;
  if (!isTimeDimension(xAxis[0])) {
    sortedArray = sortedArray.slice(0, maxBars);
  }
  var secondaryDataValues = _.pluck(secondaryData, xAxis[1].elementID);
  secondaryDataValues = _.unique(secondaryDataValues);
  if (isTimeDimension(xAxis[1])) {
    secondaryDataValues = _.map(secondaryDataValues, function (item) {
      return Number(item);
    });
  }
  var clippedArray = [];
  _.each(sortedArray, function (value, index) {
    var obj = {};
    obj[value.name] = groupedByFirstDimension[value.name];
    clippedArray.push(obj);
  });
  var rObject = {
    clippedArray: clippedArray,
    secondaryDataValues: secondaryDataValues,
    sortedArray: sortedArray,
  };
  return rObject;
}

function getPlotlyData({
  dataFromQE,
  xAxis,
  yAxis,
  chartType,
  extraData,
  orderBy,
  plotlyMetrics,
  plotlyDimensions,
  isSigviewPage,
  selectedTimezone,
  themeState,
}) {
  var returningDataArray = [];
  var maxBars = 20;
  var colors = getCustomColorPallete(themeState);
  switch (chartType) {
    case "line":
      //   var data = angular.copy(dataFromQE);
      var data = cloneDeep(dataFromQE);
      var lineChartData = convertLineChartData(data, xAxis[0].elementID);
      var markerSize = getMarkerSize(data.length);
      var trace = {
        y: getYAxisValues(lineChartData, yAxis[0]),
        x: getXAxisValues(lineChartData, xAxis[0]),
        type: "scatter",
        name: getMeasureTitleByID(yAxis[0].elementID, plotlyMetrics),
        mode: "lines+markers",
        text: [],
        hoverinfo: "text",
        marker: {
          color: getColorGradMap(getBaseColor(themeState)),
          size: markerSize,
        },
        line: {
          shape: "spline",
          color: getBaseColor(themeState),
        },
      };
      trace.text = getTooltipData(
        lineChartData,
        xAxis,
        yAxis,
        plotlyMetrics,
        plotlyDimensions,
        isSigviewPage
      ); //big function left
      returningDataArray.push(trace);
      break;
    case "multiline":
      //   var data = angular.copy(dataFromQE);
      var data = cloneDeep(dataFromQE);
      var lineChartData = convertLineChartData(data, xAxis[0].elementID);
      var markerSize = getMarkerSize(data.length);
      _.each(yAxis, function (item, index) {
        var trace = {
          y: getYAxisValues(lineChartData, item),
          x: getXAxisValues(lineChartData, xAxis[0]),
          type: "scatter",
          name: getMeasureTitleByID(item.elementID, plotlyMetrics), //left
          mode: "lines+markers",
          text: [],
          hoverinfo: "text",
          marker: {
            color: getColorGradMap(colors[index]),
            size: markerSize,
          },
          line: {
            color: colors[index],
            shape: "spline",
          },
        };
        var newXAxis = [],
          newYAxis = [];
        newXAxis[0] = xAxis[0];
        newYAxis[0] = item;
        var tooltipData = getTooltipData(
          lineChartData,
          newXAxis,
          newYAxis,
          plotlyMetrics,
          plotlyDimensions,
          isSigviewPage
        );
        trace.text = tooltipData;
        returningDataArray.push(trace);
      });
      break;
    case "multiaxisline":
      //   var data = angular.copy(dataFromQE);
      var data = cloneDeep(dataFromQE);
      var lineChartData = convertLineChartData(data, xAxis[0].elementID);
      var yAxisLen = data.length,
        markerSize = getMarkerSize(yAxisLen);
      _.each(yAxis, function (item, index) {
        var trace = {
          x: getXAxisValues(lineChartData, xAxis[0]),
          y: getYAxisValues(lineChartData, item),
          name: getMeasureTitleByID(item.elementID, plotlyMetrics),
          yaxis: "y" + (index + 1),
          type: "scatter",
          hoverinfo: "text",
          mode: "lines+markers",
          marker: {
            color: getColorGradMap(colors[index]),
            size: markerSize,
          },
          line: {
            color: colors[index],
            shape: "spline",
          },
        };
        var newXAxis = [],
          newYAxis = [];
        newXAxis[0] = xAxis[0];
        newYAxis[0] = item;
        var tooltipData = getTooltipData(
          lineChartData,
          newXAxis,
          newYAxis,
          plotlyMetrics,
          plotlyDimensions
        );
        trace.text = tooltipData;
        returningDataArray.push(trace);
      });
      break;
    case "barline":
      //   var data = angular.copy(dataFromQE);
      var data = cloneDeep(dataFromQE);
      var lineChartData = convertLineChartData(data, xAxis[0].elementID);
      var yAxisLen = data.length,
        markerSize = getMarkerSize(yAxisLen);
      var markerSize = getMarkerSize(data.length);
      var barLineColors = [colors[0], colors[1]];
      _.each(yAxis, function (item, index) {
        var trace = {
          x: getXAxisValues(lineChartData, xAxis[0]),
          y: getYAxisValues(lineChartData, item),
          name: getMeasureTitleByID(item.elementID, plotlyMetrics),
          yaxis: "y" + (index + 1),
          type: "scatter",
          hoverinfo: "text",
          mode: "lines+markers",
          marker: {
            // color: getColorGradMap(barLineColors[index]),
            color: barLineColors[index],
            size: markerSize,
          },
          line: {
            color: barLineColors[index],
            shape: "spline",
          },
        };
        if (index === 0) {
          trace.type = "bar";
          delete trace.marker.size;
          delete trace.line;
        }
        var newXAxis = [],
          newYAxis = [];
        newXAxis[0] = xAxis[0];
        newYAxis[0] = item;
        var tooltipData = getTooltipData(
          lineChartData,
          newXAxis,
          newYAxis,
          plotlyMetrics,
          plotlyDimensions
        );
        trace.text = tooltipData;
        returningDataArray.push(trace);
      });
      break;
    case "multiaxisgroupedbar":
      var dataFromQE1 = isSigviewPage
        ? dataFromQE
        : dataFromQE.slice(0, maxBars);
      //var dataFromQE1 = dataFromQE;
      if (isTimeDimension(xAxis[0])) {
        dataFromQE1 = convertLineChartData(dataFromQE1, xAxis[0].elementID);
      }
      _.each(yAxis, function (value, index) {
        let yaxis = index === 0 ? "y" : `y${index + 1}`;
        var trace = {
          y: getYAxisValues(dataFromQE1, value),
          x: getXAxisValues(dataFromQE1, xAxis[0]),
          type: "bar",
          name: getMeasureTitleByID(value.elementID, plotlyMetrics),
          text: [],
          hoverinfo: "text",
          marker: {
            color: colors[index],
          },
          yaxis,
          offsetgroup: index + 1,
        };
        var newYAxis = [];
        newYAxis[0] = value;
        trace.text = getTooltipData(
          dataFromQE1,
          xAxis,
          newYAxis,
          plotlyMetrics,
          plotlyDimensions,
          isSigviewPage
        );
        returningDataArray.push(trace);
      });
      break;

    case "bar":
      //   var data = angular.copy(dataFromQE);
      //   console.log("MATCH THIS 1", dataFromQE);
      var dataFromQE1 = cloneDeep(dataFromQE);
      //   console.log("MATCH THIS 2", data);
      if (isTimeDimension(xAxis[0])) {
        dataFromQE1 = convertLineChartData(dataFromQE1, xAxis[0].elementID);
      }
      //   console.log("MATCH THIS 3", plotlyMetrics);
      var trace = {
        y: getYAxisValues(dataFromQE1, yAxis[0]),
        x: getXAxisValues(dataFromQE1, xAxis[0]),
        type: "bar",
        name: getMeasureTitleByID(yAxis[0].elementID, plotlyMetrics),
        marker: {
          color: getBaseColor(themeState),
        },
        text: [],
        hoverinfo: "text",
      };
      trace.text = getTooltipData(
        dataFromQE1,
        xAxis,
        yAxis,
        plotlyMetrics,
        plotlyDimensions,
        isSigviewPage
      );
      returningDataArray.push(trace);
      break;
    case "verticalstackedbar":
      //   var data = angular.copy(dataFromQE);
      var dataFromQE1 = cloneDeep(dataFromQE);
      if (isTimeDimension(xAxis[0])) {
        dataFromQE1 = convertLineChartData(dataFromQE, xAxis[0].elementID);
      }
      var secondaryData = extraData.legendsData.slice(0, 9);
      var rObject = getStackedData(
        dataFromQE1,
        xAxis,
        yAxis,
        secondaryData,
        orderBy,
        plotlyMetrics,
        plotlyDimensions
      );
      var secondaryDataValues = rObject.secondaryDataValues;
      var sortedArray = rObject.sortedArray;
      var clippedArray = rObject.clippedArray;
      _.each(secondaryDataValues, function (value, index) {
        var trace = {
          y: [],
          x: _.pluck(sortedArray, "name"),
          type: "bar",
          name: value,
          text: [],
          hoverinfo: "text",
          marker: {
            color: colors[index],
          },
        };
        var legendVals = [];
        var dataUnit = getMeasureDataUnitByID(
          yAxis[0].elementID,
          plotlyMetrics
        );
        _.each(clippedArray, function (v, i) {
          var arrayToFocus = v[trace.x[i]];
          var foundStackFlag = false;
          for (let i = 0; i < arrayToFocus.length; i++) {
            legendVals.push(value);
            if (arrayToFocus[i][xAxis[1].elementID] === value) {
              //var q = typeof arrayToFocus[i][yAxis[0].elementID] !== 'number' ? parseInt(arrayToFocus[i][yAxis[0].elementID]) : arrayToFocus[i][yAxis[0].elementID];
              var string = "";
              var dim1, meas1, dim2;
              dim1 = arrayToFocus[i][xAxis[0].elementID];
              //dim1 = isTimeDimension(xAxis[0]) ? moment.utc(Number(dim1)).format("YYYY-MM-DD HH:mm:SS") : dim1;
              dim2 = arrayToFocus[i][xAxis[1].elementID];
              dim2 = isTimeDimension(xAxis[1])
                ? moment.utc(Number(dim2)).format("YYYY-MM-DD HH:mm:SS")
                : dim2;
              meas1 = Number(arrayToFocus[i][yAxis[0].elementID]);
              meas1 = meas1 === Math.floor(meas1) ? meas1 : meas1.toFixed(2);

              string +=
                getDimensionTitleByID(xAxis[0].elementID, plotlyDimensions) +
                ": " +
                dim1 +
                "<br>";
              string +=
                getDimensionTitleByID(xAxis[1].elementID, plotlyDimensions) +
                ": " +
                dim2 +
                "<br>";

              string +=
                getMeasureTitleByID(yAxis[0].elementID, plotlyMetrics) +
                ": " +
                appendMetricSym(thousandSeperator(meas1), dataUnit, false) +
                "<br>";

              trace.text.push(string);
              trace.y.push(arrayToFocus[i][yAxis[0].elementID]);
              foundStackFlag = true;
              break;
            }
          }
          if (foundStackFlag === false) {
            trace.y.push(0);
            trace.text.push("");
          }
        });
        if (isTimeDimension(xAxis[1])) {
          /*trace.text = _.map(legendVals, function (item) {
           return moment.utc(Number(item)).format("YYYY-MM-DD HH:mm:SS");
           });*/
          trace.name = moment.utc(Number(trace.name)).format("MM-DD HH:mm");
        } else {
          //trace.text = legendVals;
        }
        returningDataArray.push(trace);
      });
      break;
    case "horizontalstackedbar":
      //   var data = angular.copy(dataFromQE);
      var dataFromQE1 = cloneDeep(dataFromQE);
      if (isTimeDimension(xAxis[0])) {
        dataFromQE1 = convertLineChartData(dataFromQE, xAxis[0].elementID);
      }
      var secondaryData = extraData.legendsData.slice(0, 9);
      var rObject = getStackedData(
        dataFromQE1,
        xAxis,
        yAxis,
        secondaryData,
        orderBy,
        plotlyMetrics,
        plotlyDimensions
      );
      var secondaryDataValues = rObject.secondaryDataValues;
      var sortedArray = rObject.sortedArray;
      var clippedArray = rObject.clippedArray;
      _.each(secondaryDataValues, function (value, index) {
        var trace = {
          x: [],
          y: _.pluck(sortedArray, "name"),
          type: "bar",
          name: value,
          orientation: "h",
          text: [],
          hoverinfo: "text",
          marker: {
            color: colors[index],
          },
        };
        var legendVals = [];
        var dataUnit = getMeasureDataUnitByID(
          yAxis[0].elementID,
          plotlyMetrics
        );
        _.each(clippedArray, function (v, i) {
          var arrayToFocus = v[trace.y[i]];
          var foundStackFlag = false;
          for (let i = 0; i < arrayToFocus.length; i++) {
            legendVals.push(value);
            if (arrayToFocus[i][xAxis[1].elementID] === value) {
              //var q = typeof arrayToFocus[i][yAxis[0].elementID] !== 'number' ? parseInt(arrayToFocus[i][yAxis[0].elementID]) : arrayToFocus[i][yAxis[0].elementID];
              trace.x.push(arrayToFocus[i][yAxis[0].elementID]);
              var string = "";
              var dim1, meas1, dim2;
              dim1 = arrayToFocus[i][xAxis[0].elementID];
              //dim1 = isTimeDimension(xAxis[0]) ? moment.utc(Number(dim1)).format("YYYY-MM-DD HH:mm:SS") : dim1;
              dim2 = arrayToFocus[i][xAxis[1].elementID];
              dim2 = isTimeDimension(xAxis[1])
                ? moment.utc(Number(dim2)).format("YYYY-MM-DD HH:mm:SS")
                : dim2;
              meas1 = Number(arrayToFocus[i][yAxis[0].elementID]);
              meas1 = meas1 === Math.floor(meas1) ? meas1 : meas1.toFixed(2);

              string +=
                getDimensionTitleByID(xAxis[0].elementID, plotlyDimensions) +
                ": " +
                dim1 +
                "<br>";
              string +=
                getDimensionTitleByID(xAxis[1].elementID, plotlyDimensions) +
                ": " +
                dim2 +
                "<br>";
              //string  += getMeasureTitleByID(yAxis[0].elementID) + ": " + thousandSeperator(meas1) + '<br>';
              string +=
                getMeasureTitleByID(yAxis[0].elementID, plotlyMetrics) +
                ": " +
                appendMetricSym(thousandSeperator(meas1), dataUnit, false) +
                "<br>";

              trace.text.push(string);
              foundStackFlag = true;
              break;
            }
          }
          if (foundStackFlag === false) {
            trace.x.push(0);
            trace.text.push("");
          }
        });
        if (isTimeDimension(xAxis[1])) {
          /*trace.text = _.map(legendVals, function (item) {
           return moment.utc(Number(item)).format("YYYY-MM-DD HH:mm:SS");
           });*/
          trace.name = moment.utc(Number(trace.name)).format("MM-DD HH:mm");
        } else {
          //trace.text = legendVals;
        }
        returningDataArray.push(trace);
      });
      break;
    case "percentagestackedbar":
      //   var data = angular.copy(dataFromQE);
      var dataFromQE1 = cloneDeep(dataFromQE);
      if (isTimeDimension(xAxis[0])) {
        dataFromQE1 = convertLineChartData(dataFromQE, xAxis[0].elementID);
      }
      var xAxisTotals = new Map();
      if (isTimeDimension(xAxis[0])) {
        _.each(extraData.xAxisData, function (row) {
          xAxisTotals[
            moment
              .tz(Number(row[xAxis[0].elementID]), selectedTimezone.location)
              .format("YYYY-MM-DD HH:mm:SS")
          ] = Number(row[yAxis[0].elementID]);
        });
      } else {
        _.each(extraData.xAxisData, function (row) {
          xAxisTotals[row[xAxis[0].elementID]] = Number(
            row[yAxis[0].elementID]
          );
        });
      }
      var secondaryData = extraData.legendsData.slice(0, 9);
      var rObject = getStackedData(
        dataFromQE1,
        xAxis,
        yAxis,
        secondaryData,
        orderBy,
        plotlyMetrics,
        plotlyDimensions
      );
      var secondaryDataValues = rObject.secondaryDataValues;
      var sortedArray = rObject.sortedArray;
      var clippedArray = cloneDeep(rObject.clippedArray);
      var x = _.pluck(sortedArray, "name");
      var dataUnit = getMeasureDataUnitByID(yAxis[0].elementID, plotlyMetrics);

      var newClippedArray = [],
        addOthersLegend = true;
      _.each(clippedArray, function (item, index) {
        var tempObj = {},
          tempClippedArray = [];
        var dim1Arr = item[x[index]];
        var sum = 0;
        _.each(dim1Arr, function (item2) {
          if (_.contains(secondaryDataValues, item2[xAxis[1].elementID])) {
            tempClippedArray.push(item2);
            sum += Number(item2[yAxis[0].elementID]);
          }
        });
        if (sum !== xAxisTotals[x[index]]) {
          if (sum < xAxisTotals[x[index]]) {
            var obj = {};
            obj[xAxis[0].elementID] = x[index];
            obj[xAxis[1].elementID] = "Others";
            obj[yAxis[0].elementID] = xAxisTotals[x[index]] - sum;
            tempClippedArray.push(obj);
            if (addOthersLegend) {
              secondaryDataValues.push("Others");
              addOthersLegend = false;
            }
          } else {
            console.log("Something is going wrong % computation");
          }
        }
        tempObj[x[index]] = tempClippedArray;
        newClippedArray.push(tempObj);
      });
      _.each(secondaryDataValues, function (value, index) {
        var trace = {
          y: [],
          x: _.pluck(sortedArray, "name"),
          type: "bar",
          name: value,
          text: [],
          hoverinfo: "text",
          marker: {
            color: colors[index],
          },
        };
        var legendVals = [];
        _.each(newClippedArray, function (v, i) {
          var arrayToFocus = v[trace.x[i]];
          var foundStackFlag = false;
          for (let i = 0; i < arrayToFocus.length; i++) {
            legendVals.push(value);
            if (arrayToFocus[i][xAxis[1].elementID] === value) {
              //var q = typeof arrayToFocus[i][yAxis[0].elementID] !== 'number' ? parseInt(arrayToFocus[i][yAxis[0].elementID]) : arrayToFocus[i][yAxis[0].elementID];
              /*var dim1 = _.filter(sortedArray, function (item) {
                return item['name'] === arrayToFocus[i][xAxis[0].elementID];
              });*/
              var perVal =
                xAxisTotals[arrayToFocus[i][xAxis[0].elementID]] != 0
                  ? (
                      (Number(arrayToFocus[i][yAxis[0].elementID]) * 100) /
                      xAxisTotals[arrayToFocus[i][xAxis[0].elementID]]
                    ).toFixed(2)
                  : 0;
              trace.y.push(perVal);
              //trace.text.push(Number(arrayToFocus[i][yAxis[0].elementID])/dim1[0]['total']);
              var string = "";
              var dim1, meas1, dim2;
              dim1 = arrayToFocus[i][xAxis[0].elementID];
              //dim1 = (isTimeDimension(xAxis[0]) && dim1 != "Others") ? moment.utc(Number(dim1)).format("YYYY-MM-DD HH:mm:SS") : dim1;
              dim2 = arrayToFocus[i][xAxis[1].elementID];
              dim2 =
                isTimeDimension(xAxis[1]) && dim2 !== "Others"
                  ? moment.utc(Number(dim2)).format("YYYY-MM-DD HH:mm:SS")
                  : dim2;
              meas1 = Number(arrayToFocus[i][yAxis[0].elementID]);
              meas1 = meas1 === Math.floor(meas1) ? meas1 : meas1.toFixed(2);

              string +=
                getDimensionTitleByID(xAxis[0].elementID, plotlyDimensions) +
                ": " +
                dim1 +
                "<br>";
              string +=
                getDimensionTitleByID(xAxis[1].elementID, plotlyDimensions) +
                ": " +
                dim2 +
                "<br>";
              //string  += getMeasureTitleByID(yAxis[0].elementID) + ": " + thousandSeperator(meas1) + ' [' + perVal.toString() + '%]' + '<br>';

              string +=
                getMeasureTitleByID(yAxis[0].elementID, plotlyMetrics) +
                ": " +
                appendMetricSym(thousandSeperator(meas1), dataUnit, false) +
                " [" +
                perVal.toString() +
                "%]" +
                "<br>";

              trace.text.push(string);
              foundStackFlag = true;
              break;
            }
          }
          if (foundStackFlag === false) {
            trace.y.push(0);
            trace.text.push(0);
          }
        });
        if (isTimeDimension(xAxis[1])) {
          if (trace.name !== "Others") {
            trace.name = moment.utc(Number(trace.name)).format("MM-DD HH:mm");
          }
        } else {
          //trace.text = legendVals;
        }
        returningDataArray.push(trace);
      });
      break;
    case "groupedbar":
      if (xAxis.length === 2 && yAxis.length === 1) {
        if (isTimeDimension(xAxis[0])) {
          dataFromQE = convertLineChartData(dataFromQE, xAxis[0].elementID);
        }
        var secondaryData = extraData.legendsData.slice(0, 20);
        var rObject = getStackedData(
          dataFromQE,
          xAxis,
          yAxis,
          secondaryData,
          orderBy,
          plotlyMetrics,
          plotlyDimensions
        );
        var secondaryDataValues = rObject.secondaryDataValues;
        var sortedArray = rObject.sortedArray;
        var clippedArray = rObject.clippedArray;
        // console.log("rObject", rObject);
        _.each(secondaryDataValues, function (value, index) {
          var trace = {
            y: [],
            x: _.pluck(sortedArray, "name"),
            type: "bar",
            name: value,
            text: [],
            hoverinfo: "text",
            marker: {
              color: colors[index],
            },
          };
          var dataUnit = getMeasureDataUnitByID(
            yAxis[0].elementID,
            plotlyMetrics
          );
          _.each(clippedArray, function (v, i) {
            var arrayToFocus = v[trace.x[i]];
            var foundStackFlag = false;
            for (let i = 0; i < arrayToFocus.length; i++) {
              if (arrayToFocus[i][xAxis[1].elementID] === value) {
                //var q = typeof arrayToFocus[i][yAxis[0].elementID] !== 'number' ? parseInt(arrayToFocus[i][yAxis[0].elementID]) : arrayToFocus[i][yAxis[0].elementID];
                var dim1,
                  meas1,
                  dim2,
                  string = "";
                dim1 = arrayToFocus[i][xAxis[0].elementID];
                //dim1 = isTimeDimension(xAxis[0]) ? moment.utc(Number(dim1)).format("YYYY-MM-DD HH:mm:SS") : dim1;
                dim2 = arrayToFocus[i][xAxis[1].elementID];
                var granularityVlaue = getGranularity();

                if (
                  granularityVlaue === "month" ||
                  (xAxis[0].elementID !== undefined &&
                    xAxis[0].elementID === "month")
                ) {
                  // dim2 = isTimeDimension(xAxis[1]) ? moment.utc(Number(dim2)).format("MMM YYYY") : dim2;
                  dim2 = isTimeDimension(xAxis[1])
                    ? moment.utc(Number(dim2)).format("YYYY-MM")
                    : dim2;
                } else {
                  dim2 = isTimeDimension(xAxis[1])
                    ? moment.utc(Number(dim2)).format("YYYY-MM-DD HH:mm:SS")
                    : dim2;
                }

                meas1 = Number(arrayToFocus[i][yAxis[0].elementID]);
                meas1 = meas1 === Math.floor(meas1) ? meas1 : meas1.toFixed(2);

                string +=
                  getDimensionTitleByID(xAxis[0].elementID, plotlyDimensions) +
                  ": " +
                  dim1 +
                  "<br>";
                string +=
                  getDimensionTitleByID(xAxis[1].elementID, plotlyDimensions) +
                  ": " +
                  dim2 +
                  "<br>";

                string +=
                  getMeasureTitleByID(yAxis[0].elementID, plotlyMetrics) +
                  ": " +
                  appendMetricSym(thousandSeperator(meas1), dataUnit, false) +
                  "<br>";

                trace.text.push(string);
                trace.y.push(arrayToFocus[i][yAxis[0].elementID]);
                foundStackFlag = true;
                break;
              }
            }
            if (foundStackFlag === false) {
              trace.y.push(0);
              trace.text.push("");
            }
          });
          if (isTimeDimension(xAxis[1])) {
            /*trace.text = _.map(legendVals, function (item) {
             return moment.utc(Number(item)).format("YYYY-MM-DD HH:mm:SS");
             });*/
            trace.name = moment.utc(Number(trace.name)).format("MM-DD HH:mm");
          } else {
            //trace.text = legendVals;
          }
          returningDataArray.push(trace);
        });
      } else if (xAxis.length === 1 && yAxis.length >= 2) {
        var dataFromQE1 = isSigviewPage
          ? dataFromQE
          : dataFromQE.slice(0, maxBars);
        //var dataFromQE1 = dataFromQE;
        if (isTimeDimension(xAxis[0])) {
          dataFromQE1 = convertLineChartData(dataFromQE1, xAxis[0].elementID);
        }
        _.each(yAxis, function (value, index) {
          var trace = {
            y: getYAxisValues(dataFromQE1, value),
            x: getXAxisValues(dataFromQE1, xAxis[0]),
            type: "bar",
            name: getMeasureTitleByID(value.elementID, plotlyMetrics),
            text: [],
            hoverinfo: "text",
            marker: {
              color: colors[index],
            },
          };
          var newYAxis = [];
          newYAxis[0] = value;
          trace.text = getTooltipData(
            dataFromQE1,
            xAxis,
            newYAxis,
            plotlyMetrics,
            plotlyDimensions,
            isSigviewPage
          );
          returningDataArray.push(trace);
        });
      }
      break;
    case "horizontalbar":
      var dataFromQE1 = dataFromQE;
      if (isTimeDimension(xAxis[0])) {
        dataFromQE1 = convertLineChartData(dataFromQE1, xAxis[0].elementID);
      }
      //dataFromQE1.reverse(); //Chart to be from top to bottom
      var trace = {
        x: getYAxisValues(dataFromQE1, yAxis[0]),
        y: getXAxisValues(dataFromQE1, xAxis[0]),
        type: "bar",
        name: getMeasureTitleByID(yAxis[0].elementID, plotlyMetrics),
        orientation: "h",
        text: [],
        hoverinfo: "text",
        marker: {
          color: getBaseColor(themeState),
        },
      };
      trace.text = getTooltipData(
        dataFromQE1,
        xAxis,
        yAxis,
        plotlyMetrics,
        plotlyDimensions
      );
      returningDataArray.push(trace);
      break;
    case "groupedhorizontalbar":
      if (xAxis.length === 2 && yAxis.length === 1) {
      } else if (xAxis.length === 1 && yAxis.length >= 2) {
        var dataFromQE1 = dataFromQE.slice(0, maxBars);
        if (isTimeDimension(xAxis[0])) {
          dataFromQE1 = convertLineChartData(dataFromQE1, xAxis[0].elementID);
        }
        _.each(yAxis, function (value, index) {
          var trace = {
            x: getYAxisValues(dataFromQE1, value),
            y: getXAxisValues(dataFromQE1, xAxis[0]),
            type: "bar",
            name: getMeasureTitleByID(value.elementID, plotlyMetrics),
            orientation: "h",
            text: [],
            hoverinfo: "text",
            marker: {
              color: colors[index],
            },
          };
          var newYAxis = [];
          newYAxis[0] = value;
          trace.text = getTooltipData(
            dataFromQE1,
            xAxis,
            newYAxis,
            plotlyMetrics,
            plotlyDimensions
          );
          returningDataArray.push(trace);
        });
      }
      break;
    case "pie":
      //   var data = angular.copy(dataFromQE);
      var data = cloneDeep(dataFromQE);
      var xID = xAxis[0]["elementID"];
      var yID = yAxis[0]["elementID"];
      if (isTimeDimension(xAxis[0])) {
        data = convertLineChartData(data, xID);
      }
      if (data.length >= 7) {
        var totalPercent = _.reduce(
          data.slice(0, 7),
          function (memo, num) {
            return memo + numeral(num[yID + "_percent"]);
            //return memo + Number(num[yID+"_percent"].slice(0,num[yID+"_percent"].length-1));
          },
          0
        );
        if (Math.floor(totalPercent) < 100) {
          data = data.slice(0, 7);
          var val1 = Number(data[0][yID]),
            per1 = Number(data[0][yID + "_percent"]);
          //var val1 = Number(data[0][yID]), per1 = Number(data[0][yID+"_percent"].slice(0,data[0][yID+"_percent"].length-1));
          var othersVal = (val1 * (100 - totalPercent)) / per1;
          var obj = {};
          obj[xID] = "Others";
          obj[yID] = othersVal;
          obj[yID + "_percent"] = (100 - totalPercent).toString();
          data.push(obj);
        }
      }
      var percentVals = _.pluck(data, yID + "_percent");
      percentVals = _.map(percentVals, function (num) {
        var percent = Number(num.slice(0, num.length - 1));
        return percent >= 2.0 ? percent.toFixed(2).toString() + "%" : null;
      });
      var trace = {
        values: getYAxisValues(data, yAxis[0]),
        labels: getXAxisValues(data, xAxis[0]),
        z: getXAxisValues(data, xAxis[0]),
        type: "pie",
        name: getMeasureTitleByID(yAxis[0].elementID, plotlyMetrics),
        sort: false,
        direction: "clockwise",
        textinfo: "text",
        text: percentVals,
        hoverinfo: "label+value+text",
        marker: {
          colors: colors,
        },
      };
      //console.log(trace);
      returningDataArray.push(trace);
      break;
    case "crosstabheatmap":
      var data = cloneDeep(dataFromQE);
      _.each(data, function (row) {
        row[yAxis[0].elementID] = numeral(row[yAxis[0].elementID]).format(
          "00.[00]"
        );
        row[yAxis[0].elementID] = numeral(row[yAxis[0].elementID]).value();
      });
      var d1Val = extraData.legendsData[xAxis[0].elementID];
      var d2Val = extraData.legendsData[xAxis[1].elementID];
      if (isTimeDimension(xAxis[0])) {
        data = convertLineChartData(data, xAxis[0].elementID);
        d1Val = _.map(d1Val, function (val) {
          return moment.utc(Number(val)).format("YYYY-MM-DD HH:mm:SS");
        });
      }
      if (isTimeDimension(xAxis[1])) {
        data = convertLineChartData(data, xAxis[1].elementID);
        d2Val = _.map(d2Val, function (val) {
          return moment.utc(Number(val)).format("YYYY-MM-DD HH:mm:SS");
        });
      }
      var dummyMetricVals = [];
      for (let i = 0; i < d1Val.length * d2Val.length; i++) {
        dummyMetricVals.push(1);
      }
      //change the values(now 150) according to number of cells in each row(now 15)
      var rectangles = Treemap.generate(
        dummyMetricVals,
        d1Val.length * 10,
        d2Val.length * 10
      );
      rectangles.sort(function (a, b) {
        if (a[0] != b[0]) return a[0] - b[0];
        return a[1] - b[1];
      });
      var x_trace = [],
        y_trace = [],
        shapes = [];
      var colorDist = getColorDistributionForWorldmap();
      colorDist.splice(0, 1);
      var newData = [];
      var dim1ID = xAxis[0].elementID,
        dim2ID = xAxis[1].elementID,
        metricId = yAxis[0].elementID;
      _.each(d1Val, function (d1) {
        _.each(d2Val, function (d2) {
          var filteredObj = _.filter(data, function (row) {
            return row[dim1ID] === d1 && row[dim2ID] === d2;
          });
          var tempObj = {};
          tempObj[dim1ID] = d1;
          tempObj[dim2ID] = d2;
          tempObj[metricId] =
            filteredObj.length > 0 ? parseFloat(filteredObj[0][metricId]) : 0; //if values are not float, decVal becomes empty arr which creates a difficult to catch bug in crosstabheatmap
          newData.push(tempObj);
        });
      });
      var decVals = getDecileList(_.pluck(newData, yAxis[0].elementID));
      var text = [];
      var dataUnit = getMeasureDataUnitByID(yAxis[0].elementID, plotlyMetrics);
      _.each(rectangles, function (item, index) {
        x_trace.push((item[0] + item[2]) / 2);
        y_trace.push((item[1] + item[3]) / 2);
        if (d2Val.length === 1) {
          text.push(
            getDimensionTitleByID(dim1ID, plotlyDimensions) +
              ": " +
              newData[index][dim1ID].toString() +
              "  " +
              getDimensionTitleByID(dim2ID, plotlyDimensions) +
              ": " +
              newData[index][dim2ID].toString() +
              "  " +
              getMeasureTitleByID(metricId, plotlyMetrics) +
              ": " +
              appendMetricSym(
                thousandSeperator(newData[index][metricId]),
                dataUnit,
                false
              )
          );
        } else {
          text.push(
            getDimensionTitleByID(dim1ID, plotlyDimensions) +
              ": " +
              newData[index][dim1ID].toString() +
              "<br>" +
              getDimensionTitleByID(dim2ID, plotlyDimensions) +
              ": " +
              newData[index][dim2ID].toString() +
              "<br>" +
              getMeasureTitleByID(metricId, plotlyMetrics) +
              ": " +
              appendMetricSym(
                thousandSeperator(newData[index][metricId]),
                dataUnit,
                false
              )
          );
        }
      });
      var uniqXTrace = _.unique(x_trace),
        uniqYTrace = _.unique(y_trace);
      var mapD1 = new Map(),
        mapD2 = new Map();
      _.each(uniqXTrace, function (val, index) {
        mapD1[val] = d1Val[index];
      });
      _.each(uniqYTrace, function (val, index) {
        mapD2[val] = d2Val[index];
      });
      var modD1Val = _.map(x_trace, function (val) {
        return mapD1[val];
      });
      var modD2Val = _.map(y_trace, function (val) {
        return mapD2[val];
      });
      if (isTimeDimension(xAxis[0])) {
        modD1Val = _.map(modD1Val, function (item) {
          return moment(item, "YYYY-MM-DD HH:mm:SS").format("MM-DD HH:mm");
        });
      }
      if (isTimeDimension(xAxis[1])) {
        modD2Val = _.map(modD2Val, function (item) {
          return moment(item, "YYYY-MM-DD HH:mm:SS").format("MM-DD HH:mm");
        });
      }
      // setTreemapLayout({
      //   shapes: shapes,
      //   hovermode: "closest",
      //   xaxis: {
      //     tickvals: x_trace,
      //     ticktext: modD1Val,
      //     showgrid: false,
      //     zeroline: false,
      //   },
      //   yaxis: {
      //     tickvals: y_trace,
      //     ticktext: modD2Val,
      //     showgrid: false,
      //     zeroline: false,
      //   },
      // });
      var trace = {
        x: x_trace,
        y: y_trace,
        //mode: "text",
        name: "y",
        text: [],
        hoverinfo: "text",
        textsrc: "",
        //type: "scatter",
        xsrc: "",
        ysrc: "",
      };
      trace.text = text;
      returningDataArray.push(trace);

      break;
    default:
  }
  return returningDataArray;
}

function trimLabels(labels) {
  //Trim label values to have only 6 characters
  let trimmedLabels = labels.map((item) =>
    item.length > 6 ? item.substr(0, 6) + "." : item
  );
  return trimmedLabels;
}

function formatAxisLabels(layout, axis, labels) {
  var ticktext = trimLabels(labels);
  layout[axis]["tickmode"] = "array";
  layout[axis]["tickvals"] = labels;
  layout[axis]["ticktext"] = ticktext;
  return layout;
}

function isStackedBarChart(chartType) {
  return chartType === "verticalstackedbar" ||
    chartType === "horizontalstackedbar" ||
    chartType === "percentagestackedbar"
    ? true
    : false;
}

function isGroupedBarChart(chartType) {
  return chartType === "groupedhorizontalbar" || chartType === "groupedbar"
    ? true
    : false;
}

function getPlotlyChartLayout({
  chartType,
  xval,
  yval,
  data,
  orgData,
  plotlyMetrics,
  plotlyDimensions,
  dataFromQE,
  extraData,
  themeState,
}) {
  const activeTheme = themeState.activeTheme;
  const themeColors = themeState.themes[activeTheme];
  var maxBars = 20;
  var layout = {
    xaxis: {
      title: "",
      titlefont: {
        family: "Fira Sans",
        size: 10,
        color: themeColors["secondaryColor"],
      },
      tickangle: 315,
      showticklabels: true,
      showline: true,
      tickfont: {
        family: "Fira Sans",
        size: 10,
        color: themeColors["secondaryColor"],
      },
      zeroline: false,
      linecolor: themeColors["secondaryColor"],
      gridcolor: themeColors["secondaryColorLightest"],
    },
    yaxis: {
      title: "",
      titlefont: {
        family: "Fira Sans",
        size: 10,
        color: themeColors["secondaryColor"],
      },
      showticklabels: true,
      tickangle: 0,
      showline: true,
      zeroline: false,
      tickfont: {
        family: "Fira Sans",
        size: 10,
        color: themeColors["secondaryColor"],
      },
      linecolor: themeColors["secondaryColor"],
      gridcolor: themeColors["secondaryColorLightest"],
    },
    height: 600,
    width: 1200,
    plot_bgcolor: "inherit",
    paper_bgcolor: "inherit",
    modebar: {
      bgcolor: "transparent",
      color: themeColors["secondaryColorLighter"],
      activecolor: themeColors["secondaryColor"],
    },
    legend: {
      font: {
        family: "Fira Sans",
        size: 10,
        color: themeColors["secondaryColor"],
      },
      bgcolor: "inherit",
      orientation: "v",
    },
    margin: {
      l: 60,
      b: 60,
      r: 40,
      t: 30,
    },
  };
  //If there is exactly one metric (metric or customMetric), add dollar or percentage symbol in the tick values
  //If there are more than one metrics, one may be percent and the other may not
  //Hence we do not add any prefix or suffix
  if (yval.length === 1) {
    //Get the dataUnit (string, currency, percent)
    var dataUnit = getMeasureDataUnitByID(yval[0].elementID, plotlyMetrics);

    //If dataUnit is not string (meaning if it is percent or currency)
    //We need to add the appropriate symbol like dollar or percentage sign
    if (!_.isUndefined(dataUnit) && dataUnit.value.toLowerCase() !== "string") {
      //We find out the chart type
      //if the y-axis has dimension and x-axis has metric
      //if the x-axis has dimension and y-axis has metric
      //If the metric in in x-axis, we add the prefix/suffix in x-axis
      if (findChartType("isYAxisPrimaryChart", chartType)) {
        if (dataUnit.dType.toLowerCase() === "currency") {
          layout.xaxis["tickprefix"] = dataUnit.value;
        } else {
          layout.xaxis["ticksuffix"] = dataUnit.value;
        }
      } else if (findChartType("isXAxisPrimaryChart", chartType)) {
        //If the metric in in y-axis, we add the prefix/suffix in y-axis
        if (dataUnit.dType.toLowerCase() === "currency") {
          layout.yaxis["tickprefix"] = dataUnit.value;
        } else {
          layout.yaxis["ticksuffix"] = dataUnit.value;
        }
      }
    }
  }

  //Get titles for the axes
  // X-AXIS
  if (
    layout.hasOwnProperty("xaxis") &&
    layout.xaxis.hasOwnProperty("title") &&
    xval.length > 0
  ) {
    layout.xaxis.title = getDimensionTitleByID(
      xval[0].elementID,
      plotlyDimensions
    );
  }
  //Y-AXIS
  if (
    layout.hasOwnProperty("yaxis") &&
    layout.yaxis.hasOwnProperty("title") &&
    yval.length > 0
  ) {
    layout.yaxis.title = getMeasureTitleByID(yval[0].elementID, plotlyMetrics);
  }

  //Find the chart type
  //If x-axis is primary (meaning x-axis has the dimension)
  if (findChartType("isXAxisPrimaryChart", chartType)) {
    //DOUBT
    layout.yaxis.rangemode = "tozero";

    //Adding x-axis title if xval length is greater than 0
    if (xval.length > 0) {
      layout.xaxis.title = getDimensionTitleByID(
        xval[0].elementID,
        plotlyDimensions
      );
    }

    //Adding y-axis title if yval length is greater than 0
    if (yval.length > 0) {
      layout.yaxis.title = getMeasureTitleByID(
        yval[0].elementID,
        plotlyMetrics
      );
    }

    //Restricting number of ticks in y-axis if we have less than 5 values in y-axis
    if (data[0].y.length < 5) {
      layout.yaxis.nticks = data[0].y.length + 1;
    }
    //Restricting number of ticks in x-axis if x-axis is time dimension and we have less than 3 values in the data
    if (isTimeDimension(xval[0])) {
      if (data[0].x.length < 3) {
        layout.xaxis.nticks = data[0].x.length + 1;
      }
    }
  }
  //If y-axis is primary meaning y-axis has the dimension
  if (findChartType("isYAxisPrimaryChart", chartType)) {
    //DOUBT
    layout.xaxis.rangemode = "tozero";

    //Adding y-axis title
    layout.yaxis.title = getDimensionTitleByID(
      xval[0].elementID,
      plotlyDimensions
    );

    //Adding x-axis title
    layout.xaxis.title = getMeasureTitleByID(yval[0].elementID, plotlyMetrics);

    //Restricting number of ticks in x-axis if we have less than 5 values in x-axis
    if (data[0].x.length < 5) {
      layout.xaxis.nticks = data[0].x.length + 1;
    }

    //Restricting number of ticks in y-axis if we have less than 3 values in y-axis
    if (data[0].y.length < 3) {
      layout.yaxis.nticks = data[0].y.length + 1;
    }
  }

  //If the chart time is line or groupedBar or stackedBar, add hovermode "closest"
  if (
    chartType !== undefined &&
    (isStackedBarChart(chartType) ||
      isGroupedBarChart(chartType) ||
      isLineChart(chartType))
  ) {
    layout["hovermode"] = "closest";
  }

  //Modify layout based on chartType
  switch (chartType) {
    case "line":
      break;

    case "multiline":
      break;

    case "bar":
      //If the dimension is not a time dimension, trim it's values
      if (!isTimeDimension(xval[0])) {
        layout = formatAxisLabels(layout, "xaxis", data[0].x);
        layout.xaxis["type"] = "category";
      }
      break;

    case "horizontalbar":
      //If the dimension is not a time dimension, trim it's values
      if (!isTimeDimension(xval[0])) {
        layout.yaxis["type"] = "category";
        layout = formatAxisLabels(layout, "yaxis", data[0].y);
      }
      break;

    case "verticalstackedbar":
      //Add other necessary information for the layout
      layout["barmode"] = "stack";
      layout.showlegend = true;

      //If the dimension is not a time dimension, trim it's values
      if (!isTimeDimension(xval[0])) {
        layout.xaxis["type"] = "category";
        layout = formatAxisLabels(layout, "xaxis", data[0].x.slice(0, maxBars));
      }
      break;

    case "groupedbar":
      //Add other necessary information for the layout

      //If there are 2 metrics and 1 dimension, remove the title from y-axis because the same axis is for 2 metrics
      if (!(xval.length === 2 && yval.length === 1)) {
        layout.yaxis.title = "";
      }

      //If the dimension is not a time dimension, trim it's values
      if (!isTimeDimension(xval[0])) {
        layout = formatAxisLabels(layout, "xaxis", data[0].x);
        layout["barmode"] = "group";
        layout.xaxis["type"] = "category";
      }
      break;

    case "groupedhorizontalbar":
      //Add other necessary information for the layout
      // var newYaxisTitle = layout.xaxis.title;
      // layout.yaxis.title = newYaxisTitle;
      //If there are 2 metrics and 1 dimension, remove the title from x-axis because the same axis is for 2 metrics
      layout.xaxis.title = "";

      //If the dimension is not a time dimension, trim it's values
      if (!isTimeDimension(xval[0])) {
        layout["barmode"] = "group";
        layout.yaxis["type"] = "category";
        layout = formatAxisLabels(layout, "yaxis", data[0].y.slice(0, maxBars));
      }
      break;

    case "horizontalstackedbar":
      //Add other necessary information for the layout
      layout["barmode"] = "stack";
      layout.showlegend = true;

      //Swap titles
      // var newYaxisTitle = layout.xaxis.title;
      // var newXaxisTitle = layout.yaxis.title;
      // layout.xaxis.title = newXaxisTitle;
      // layout.yaxis.title = newYaxisTitle;

      //If the dimension is not a time dimension, trim it's values
      if (!isTimeDimension(xval[0])) {
        layout.yaxis["type"] = "category";
        layout = formatAxisLabels(layout, "yaxis", data[0].y.slice(0, maxBars));
      }
      break;

    case "barline":
      //Defining required variables
      //Total count is total number of metrics
      var totalCount = yval.length;
      var isLeft = true;
      var yAxisList = [];
      var colorsList = data.map((trace) => trace.marker.color);
      var axisTitles = yval.map((item) =>
        getMeasureTitleByID(item.elementID, plotlyMetrics)
      );
      var leftMargin =
          0 + (Math.floor(totalCount / 2) + (totalCount % 2)) * 0.1,
        rightMargin = 1 - Math.floor(totalCount / 2) * 0.1;

      //Updating x-axis domain
      layout.xaxis.domain = [leftMargin, rightMargin];

      //Updating yAxisList to have yaxis, yaxis2, yaxis3, so on... based on totalCount
      //Since barline will always have 2 metrics and 1 dimension
      //It could have been yAxisList = ["yaxis", "yaxis2"]
      for (let i = 0; i < totalCount; i++) {
        var string = "yaxis";
        string = i === 0 ? string : string + (i + 1);
        yAxisList.push(string);
      }

      //Update layout based on totalCount
      for (let i = 0; i < totalCount; i++) {
        layout[yAxisList[i]] = {};
        layout[yAxisList[i]].title = axisTitles[i];
        layout[yAxisList[i]].titlefont = {
          color: colorsList[i],
          family: "Fira Sans",
          size: 10,
        };
        layout[yAxisList[i]].tickfont = {
          color: colorsList[i],
          family: "Fira Sans",
          size: 10,
        };
        layout[yAxisList[i]].showline = true;
        layout[yAxisList[i]].zeroline = false;
        layout[yAxisList[i]].showgrid = false;

        //If dataUnit is not string (meaning if it is percent or currency)
        //We need to add the appropriate symbol like dollar or percentage sign
        var dataUnit = getMeasureDataUnitByID(yval[i].elementID, plotlyMetrics);
        if (
          !_.isUndefined(dataUnit) &&
          dataUnit.dType.toLowerCase() !== "string"
        ) {
          if (dataUnit.dType.toLowerCase() === "currency") {
            layout[yAxisList[i]]["tickprefix"] = dataUnit.value;
          } else {
            layout[yAxisList[i]]["ticksuffix"] = dataUnit.value;
          }
        }

        //Adding certain layout fields to even valued y-axis items
        if (isLeft) {
          if (i !== 0) {
            layout[yAxisList[i]].anchor = "free";
            layout[yAxisList[i]].overlaying = "y";
            layout[yAxisList[i]].side = "left";
            var val = 0 + (Math.floor(i / 2) + (i % 2)) * 0.1;
            layout[yAxisList[i]].position = val;
          }
          isLeft = false;
        } else {
          if (i != 1) {
            layout[yAxisList[i]].anchor = "free";
            var val =
              1 -
              (Math.floor((totalCount - i) / 2) + ((totalCount - i) % 2)) * 0.1;
            layout[yAxisList[i]].position = val;
          } else {
            layout[yAxisList[i]].anchor = "x";
          }
          layout[yAxisList[i]].side = "right";
          layout[yAxisList[i]].overlaying = "y";
          isLeft = true;
        }
      }
      break;

    case "multiaxisgroupedbar":
      // //Add other necessary information for the layout

      // //If there are 2 metrics and 1 dimension, remove the title from y-axis because the same axis is for 2 metrics
      // if (!(xval.length === 2 && yval.length === 1)) {
      //   layout.yaxis.title = "";
      // }

      // //If the dimension is not a time dimension, trim it's values
      // if (!isTimeDimension(xval[0])) {
      //   layout = formatAxisLabels(layout, "xaxis", data[0].x);
      //   layout["barmode"] = "grouped";
      //   layout.xaxis["type"] = "category";
      // }
      // var colorsList = data.map((trace) => trace.marker.color);
      // layout["barmode"] = "group";
      // layout["yaxis"]["tickfont"]["color"] = colorsList[0];
      // layout["yaxis2"] = {
      //   title: {
      //     text: "",
      //     font: {
      //       family: "Fira Sans",
      //       size: 10,
      //       color: "#46596A",
      //     },
      //   },
      //   showticklabels: true,
      //   tickangle: 0,
      //   showline: true,
      //   zeroline: false,
      //   tickfont: {
      //     family: "Fira Sans",
      //     size: 10,
      //     color: colorsList[1],
      //   },
      //   gridcolor: "#eff2f5",
      //   type: "linear",
      //   // range: [0, 7.784970349392758],
      //   autorange: true,
      //   anchor: "x",
      //   side: "right",
      //   overlaying: "y",
      // };
      //Defining required variables
      //Total count is total number of metrics
      var totalCount = yval.length;
      var isLeft = true;
      var yAxisList = [];
      var colorsList = data.map((trace) => trace.marker.color);
      var axisTitles = yval.map((item) =>
        getMeasureTitleByID(item.elementID, plotlyMetrics)
      );
      var leftMargin =
          0 + (Math.floor(totalCount / 2) + (totalCount % 2)) * 0.1,
        rightMargin = 1 - Math.floor(totalCount / 2) * 0.1;

      //Updating x-axis domain
      layout.xaxis.domain = [leftMargin, rightMargin];

      //Updating yAxisList to have yaxis, yaxis2, yaxis3, so on... based on totalCount
      //Since barline will always have 2 metrics and 1 dimension
      //It could have been yAxisList = ["yaxis", "yaxis2"]
      for (let i = 0; i < totalCount; i++) {
        var string = "yaxis";
        string = i = 0 ? string : string + (i + 1);
        yAxisList.push(string);
      }

      //Update layout based on totalCount
      for (let i = 0; i < totalCount; i++) {
        layout[yAxisList[i]] = {};
        layout[yAxisList[i]].title = axisTitles[i];
        layout[yAxisList[i]].titlefont = {
          color: colorsList[i],
          family: "Fira Sans",
          size: 10,
        };
        layout[yAxisList[i]].tickfont = {
          color: colorsList[i],
          family: "Fira Sans",
          size: 10,
        };
        layout[yAxisList[i]].showline = true;
        layout[yAxisList[i]].zeroline = false;
        layout[yAxisList[i]].showgrid = false;

        //If dataUnit is not string (meaning if it is percent or currency)
        //We need to add the appropriate symbol like dollar or percentage sign
        var dataUnit = getMeasureDataUnitByID(yval[i].elementID, plotlyMetrics);
        if (
          !_.isUndefined(dataUnit) &&
          dataUnit.dType.toLowerCase() != "string"
        ) {
          if (dataUnit.dType.toLowerCase() === "currency") {
            layout[yAxisList[i]]["tickprefix"] = dataUnit.value;
          } else {
            layout[yAxisList[i]]["ticksuffix"] = dataUnit.value;
          }
        }

        //Adding certain layout fields to even valued y-axis items
        if (isLeft) {
          if (i !== 0) {
            layout[yAxisList[i]].anchor = "free";
            layout[yAxisList[i]].overlaying = "y";
            layout[yAxisList[i]].side = "left";
            var val = 0 + (Math.floor(i / 2) + (i % 2)) * 0.1;
            layout[yAxisList[i]].position = val;
          }
          isLeft = false;
        } else {
          if (i !== 1) {
            layout[yAxisList[i]].anchor = "free";
            var val =
              1 -
              (Math.floor((totalCount - i) / 2) + ((totalCount - i) % 2)) * 0.1;
            layout[yAxisList[i]].position = val;
          } else {
            layout[yAxisList[i]].anchor = "x";
          }
          layout[yAxisList[i]].side = "right";
          layout[yAxisList[i]].overlaying = "y";
          isLeft = true;
        }
      }
      break;

    case "multiaxisline":
      //Defining required variables
      //Total count is total number of metrics
      var totalCount = yval.length;
      var isLeft = true;
      var yAxisList = [];
      var colorsList = data.map((trace) => trace.marker.color);
      var axisTitles = yval.map((item) =>
        getMeasureTitleByID(item.elementID, plotlyMetrics)
      );
      var leftMargin =
          0 + (Math.floor(totalCount / 2) + (totalCount % 2)) * 0.1,
        rightMargin = 1 - Math.floor(totalCount / 2) * 0.1;

      //Updating x-axis domain
      layout.xaxis.domain = [leftMargin, rightMargin];

      //Updating yAxisList to have yaxis, yaxis2, yaxis3, so on... based on totalCount
      //Since barline will always have 2 metrics and 1 dimension
      //It could have been yAxisList = ["yaxis", "yaxis2"]
      for (let i = 0; i < totalCount; i++) {
        var string = "yaxis";
        string = i === 0 ? string : string + (i + 1);
        yAxisList.push(string);
      }

      //Update layout based on totalCount
      for (let i = 0; i < totalCount; i++) {
        layout[yAxisList[i]] = {};
        layout[yAxisList[i]].title = axisTitles[i];
        layout[yAxisList[i]].titlefont = { color: colorsList[i] };
        layout[yAxisList[i]].tickfont = { color: colorsList[i] };
        layout[yAxisList[i]].showline = true;
        layout[yAxisList[i]].zeroline = false;
        layout[yAxisList[i]].showgrid = false;

        //If dataUnit is not string (meaning if it is percent or currency)
        //We need to add the appropriate symbol like dollar or percentage sign
        var dataUnit = getMeasureDataUnitByID(yval[i].elementID, plotlyMetrics);
        if (
          !_.isUndefined(dataUnit) &&
          dataUnit.dType.toLowerCase() !== "string"
        ) {
          if (dataUnit.dType.toLowerCase() === "currency") {
            layout[yAxisList[i]]["tickprefix"] = dataUnit.value;
          } else {
            layout[yAxisList[i]]["ticksuffix"] = dataUnit.value;
          }
        }

        //Adding certain layout fields to even valued y-axis items
        if (isLeft) {
          if (i !== 0) {
            layout[yAxisList[i]].anchor = "free";
            layout[yAxisList[i]].overlaying = "y";
            layout[yAxisList[i]].side = "left";
            var val = 0 + (Math.floor(i / 2) + (i % 2)) * 0.1;
            layout[yAxisList[i]].position = val;
          }
          isLeft = false;
        } else {
          if (i !== 1) {
            layout[yAxisList[i]].anchor = "free";
            var val =
              1 -
              (Math.floor((totalCount - i) / 2) + ((totalCount - i) % 2)) * 0.1;
            layout[yAxisList[i]].position = val;
          } else {
            layout[yAxisList[i]].anchor = "x";
          }
          layout[yAxisList[i]].side = "right";
          layout[yAxisList[i]].overlaying = "y";
          isLeft = true;
        }
      }
      break;

    case "pie":
      //Add other necessary information for the layout
      layout["xaxis"] = {
        type: "category",
      };
      delete layout["yaxis"];
      break;

    case "percentagestackedbar":
      //Add other necessary information for the layout
      layout["barmode"] = "stack";
      layout.showlegend = true;
      layout.yaxis["ticksuffix"] = "%";

      //If the dimension is not a time dimension, trim it's values
      if (!isTimeDimension(xval[0])) {
        layout.xaxis["type"] = "category";
        layout = formatAxisLabels(layout, "xaxis", data[0].x.slice(0, maxBars));
      }
      break;

    case "crosstabheatmap":
      //Make a copy of the dataFromQE
      var dataFromQECopy = cloneDeep(dataFromQE);
      var d1Val = extraData.legendsData[xval[0].elementID];
      var d2Val = extraData.legendsData[xval[1].elementID];

      //Make dummy array with values equal to product of length of d1Val & d2Val
      var dummyMetricVals = [];
      for (let i = 0; i < d1Val.length * d2Val.length; i++) {
        dummyMetricVals.push(1);
      }

      //Make rectangles using treemap library
      var rectangles = Treemap.generate(
        dummyMetricVals,
        d1Val.length * 10,
        d2Val.length * 10
      );
      rectangles.sort(function (a, b) {
        if (a[0] !== b[0]) return a[0] - b[0];
        return a[1] - b[1];
      });
      var x_trace = [],
        y_trace = [],
        shapes = [];
      var colorDist = makeSequentialColorArray(
        themeColors["primaryColorLightest"],
        themeColors["primaryColor"]
      );
      colorDist.splice(0, 1);
      var newData = [];
      var dim1ID = xval[0].elementID,
        dim2ID = xval[1].elementID,
        metricId = yval[0].elementID;
      _.each(d1Val, function (d1) {
        _.each(d2Val, function (d2) {
          var filteredObj = _.filter(dataFromQECopy, function (row) {
            return row[dim1ID] === d1 && row[dim2ID] === d2;
          });
          var tempObj = {};
          tempObj[dim1ID] = d1;
          tempObj[dim2ID] = d2;
          tempObj[metricId] =
            filteredObj.length > 0 ? parseFloat(filteredObj[0][metricId]) : 0; //if values are not float, decVal becomes empty arr which creates a difficult to catch bug in crosstabheatmap
          newData.push(tempObj);
        });
      });
      var decVals = getDecileList(_.pluck(newData, yval[0].elementID));
      var text = [];
      var dataUnit = getMeasureDataUnitByID(yval[0].elementID, plotlyMetrics);
      _.each(rectangles, function (item, index) {
        x_trace.push((item[0] + item[2]) / 2);
        y_trace.push((item[1] + item[3]) / 2);
        shapes.push({
          type: "rect",
          x0: item[0],
          y0: item[1],
          x1: item[2],
          y1: item[3],
          line: {
            width: 0.75,
            color: themeColors["secondaryColorLightest"],
          },
          fillcolor: getColorByDecileVal(
            colorDist,
            newData[index][metricId],
            decVals,
            themeState
          ),
        });
        if (d2Val.length === 1) {
          text.push(
            getDimensionTitleByID(dim1ID, plotlyDimensions) +
              ": " +
              newData[index][dim1ID].toString() +
              "  " +
              getDimensionTitleByID(dim2ID, plotlyDimensions) +
              ": " +
              newData[index][dim2ID].toString() +
              "  " +
              getMeasureTitleByID(metricId, plotlyMetrics) +
              ": " +
              appendMetricSym(
                thousandSeperator(newData[index][metricId]),
                dataUnit,
                false
              )
          );
        } else {
          text.push(
            getDimensionTitleByID(dim1ID, plotlyDimensions) +
              ": " +
              newData[index][dim1ID].toString() +
              "<br>" +
              getDimensionTitleByID(dim2ID, plotlyDimensions) +
              ": " +
              newData[index][dim2ID].toString() +
              "<br>" +
              getMeasureTitleByID(metricId, plotlyMetrics) +
              ": " +
              appendMetricSym(
                thousandSeperator(newData[index][metricId]),
                dataUnit,
                false
              )
          );
        }
      });
      var uniqXTrace = _.unique(x_trace),
        uniqYTrace = _.unique(y_trace);
      var mapD1 = new Map(),
        mapD2 = new Map();
      _.each(uniqXTrace, function (val, index) {
        mapD1[val] = d1Val[index];
      });
      _.each(uniqYTrace, function (val, index) {
        mapD2[val] = d2Val[index];
      });
      var modD1Val = _.map(x_trace, function (val) {
        return mapD1[val];
      });
      var modD2Val = _.map(y_trace, function (val) {
        return mapD2[val];
      });
      var savedLayout = {
        shapes: shapes,
        hovermode: "closest",
        xaxis: {
          tickvals: x_trace,
          ticktext: modD1Val,
          showgrid: false,
          zeroline: false,
        },
        yaxis: {
          tickvals: y_trace,
          ticktext: modD2Val,
          showgrid: false,
          zeroline: false,
        },
      };
      layout.shapes = savedLayout.shapes;
      layout.hovermode = savedLayout.hovermode;
      layout.xaxis.tickvals = savedLayout.xaxis.tickvals;
      layout.xaxis.ticktext = trimLabels(savedLayout.xaxis.ticktext);
      layout.yaxis.tickvals = savedLayout.yaxis.tickvals;
      layout.yaxis.ticktext = trimLabels(savedLayout.yaxis.ticktext);
      layout.yaxis.title = getDimensionTitleByID(
        xval[1].elementID,
        plotlyDimensions
      );
      layout.xaxis.title = getDimensionTitleByID(
        xval[0].elementID,
        plotlyDimensions
      );
      layout.xaxis.showgrid = false;
      layout.xaxis.zeroline = false;
      layout.yaxis.showgrid = false;
      layout.yaxis.zeroline = false;
      layout.xaxis.tickfont.size = 10;
      layout.yaxis.tickfont.size = 10;
      break;
  }

  return layout;
}

function getPlotlyConfigurations() {
  var config = {
    showLink: false, //HIDE EDIT LINK AT THE LEFT BOTTOM
    modeBarButtonsToRemove: [
      "editable",
      "sendData",
      "sendDataToCloud",
      "lasso2d",
      "select2d",
      "zoomIn2d",
      "zoomOut2d",
      "autoScale2d",
    ], //HIDE EDIT IN CLOUD LINK AT TOP RIGHT
    displaylogo: false, //HIDES THE PLOTLY LOGO
    displayModeBar: "hover", //ALWAYS DISPLAY THE MODE BAR
    sendData: false,
  };
  return config;
}

export function getPlotlyParams(props) {
  let {
    dataFromQE,
    xval,
    yval,
    extraData,
    chartType,
    chartDivHolderId,
    sortBy,
    plotlyMetrics,
    plotlyDimensions,
    reqPayload,
    user,
    selectedTimezone,
    themeState,
  } = props;

  //UPDATING datafromQE
  //For all dimensions, check if they are timestamp
  //If yes, change the epoch value according to selected timezone
  xval.forEach((dim) => {
    if (dim.dataType === "dateTime") {
      dataFromQE = adjustTimeStamps(
        dataFromQE,
        dim.elementID,
        selectedTimezone
      );
    }
  });

  //UPDATING extraData
  //For all dimensions, check if they are timestamp
  //If yes, change the epoch value according to selected timezone
  if (extraData) {
    if (
      extraData.hasOwnProperty("legendsData") &&
      extraData.legendsData.length > 0
    ) {
      xval.forEach((dim) => {
        if (
          dim.dataType === "dateTime" &&
          extraData.legendsData[0].hasOwnProperty(dim.elementID)
        ) {
          extraData.legendsData = adjustTimeStamps(
            extraData.legendsData,
            dim.elementID,
            selectedTimezone
          );
        }
      });
    }
  }

  //Make a copy of datafromQE
  var dataFromQECopy = dataFromQE;

  //Make necessary variables
  var legendNameColorMap = new Map(),
    maxLegendLen = 12,
    maxBars = 20,
    legendTooltip;

  //Limit data values based on chart type
  //bar: Limit it to 50
  //horizontalbar: Limit it to 20
  //groupedbar: Limit it to maxBars (20)
  switch (chartType) {
    case "bar":
      if (!isTimeDimension(xval[0])) {
        dataFromQECopy = dataFromQE.slice(0, 50);
      }
      break;
    case "horizontalbar":
      if (!isTimeDimension(xval[0])) {
        dataFromQECopy = dataFromQE.slice(0, 20);
      }
      break;
    case "groupedbar":
      if (!isTimeDimension(xval[0])) {
        dataFromQE = dataFromQE.slice(0, maxBars);
      }
      break;
  }

  //Get required data for plotly
  //Data (not understood)
  var data = getPlotlyData({
    dataFromQE: dataFromQECopy,
    xAxis: xval,
    yAxis: yval,
    chartType,
    extraData,
    orderBy: sortBy,
    plotlyMetrics,
    plotlyDimensions,
    selectedTimezone,
    themeState,
  });
  //Layout (in progress)
  var layout = getPlotlyChartLayout({
    chartType,
    xval,
    yval,
    data,
    dataFromQE: dataFromQECopy,
    plotlyMetrics,
    plotlyDimensions,
    extraData,
    themeState,
  });
  //Configurations (understood)
  var configurations = getPlotlyConfigurations(chartType);

  //Update data, layout based on some logic
  if (findChartType("isLegendsTypeChart", chartType)) {
    if (findChartType("isPieChart", chartType)) {
      if (xval[0].dataType !== "dateTime") {
        var labels = data[0].labels,
          colors = data[0].marker.colors;
        _.each(labels, function (item, index) {
          legendNameColorMap[colors[index]] = item;
        });
      } else {
        data[0].labels = _.map(data[0].labels, function (legend) {
          return legend === "Others"
            ? legend
            : moment(legend, "YYYY-MM-DD HH:mm:SS").format("MM-DD HH:mm");
        });
      }
    }
    if (
      findChartType("isGroupedBarTypeChart", chartType) ||
      findChartType("isStackedBarTypeChart", chartType) ||
      findChartType("isMultiLineChart", chartType) ||
      findChartType("isMultiAxisLineChart", chartType)
    ) {
      if (xval.length > 1 && xval[1].dataType === "dateTime") {
      } else {
        _.each(data, function (trace) {
          legendNameColorMap[trace.marker.color] = trace.name;
        });
        _.each(data, function (trace) {
          if (trace.name.length > maxLegendLen) {
            trace.name = trace.name.substr(0, maxLegendLen) + "..";
          }
        });
      }
    }
  }

  //Not needed; will handle in react
  //ADDED in REACT
  layout.width =
    document.getElementById(chartDivHolderId).offsetWidth -
    config.hardCoded.widgetPadding;
  layout.height =
    document.getElementById(chartDivHolderId).offsetHeight -
    config.hardCoded.widgetPadding;
  // layout.height = 400;

  //Update data, layout based on some logic
  //ADDED in REACT
  if (findChartType("isCrossTabHeatMap", chartType)) {
    if (layout.width > layout.height) {
      //layout.margin = { l: 0 + Math.floor( (layout.width - layout.height) / 2), b: 70 , r: 0 + Math.floor( (layout.width - layout.height) / 2), t: 0};
      layout.margin = {
        l: 0 + Math.floor((layout.width - layout.height) / 2),
        b: 80,
        r: 0 + Math.floor((layout.width - layout.height) / 2),
        t: 10,
      };
    } else {
      //layout.margin = { l: 0, b: 50 + Math.floor( (layout.height - layout.width) / 2), r: 0, t: 0 + Math.floor( (layout.height - layout.width) / 2) };
      layout.margin = {
        l: 30,
        b: 0 + Math.floor((layout.height - layout.width) / 2),
        r: 0,
        t: 0 + Math.floor((layout.height - layout.width) / 2),
      };
    }
    if (
      extraData.legendsData[xval[0].elementID].length >
      extraData.legendsData[xval[1].elementID].length
    ) {
      var width = layout.width - layout.margin.l - layout.margin.r,
        height = layout.height - layout.margin.t - layout.margin.b;
      var newHeight = Math.floor(
        (extraData.legendsData[xval[1].elementID].length / 15) * width
      );
      var newWidth = Math.floor(
        (extraData.legendsData[xval[0].elementID].length / 15) * width
      );

      layout.margin.l += Math.floor((width - newWidth) / 2);
      layout.margin.r += Math.floor((width - newWidth) / 2);

      layout.margin.b += Math.floor((height - newHeight) / 2);
      layout.margin.t += Math.floor((height - newHeight) / 2);
    } else if (
      extraData.legendsData[xval[0].elementID].length <
      extraData.legendsData[xval[1].elementID].length
    ) {
      var width = layout.width - layout.margin.l - layout.margin.r,
        height = layout.height - layout.margin.t - layout.margin.b;
      //var newWidth = Math.floor((extraData.legendsData[xval[0].elementID].length / extraData.legendsData[xval[1].elementID].length) * width);
      var newHeight = Math.floor(
        (extraData.legendsData[xval[1].elementID].length / 15) * height
      );
      var newWidth = Math.floor(
        (extraData.legendsData[xval[0].elementID].length / 15) * height
      );
      layout.margin.r += Math.floor((width - newWidth) / 2);
      layout.margin.l += Math.floor((width - newWidth) / 2);

      layout.margin.t += Math.floor((height - newHeight) / 2);
      layout.margin.b += Math.floor((height - newHeight) / 2);
    } else {
      var width = layout.width - layout.margin.l - layout.margin.r,
        height = layout.height - layout.margin.t - layout.margin.b;
      var newHeight = Math.floor(
        (extraData.legendsData[xval[1].elementID].length / 15) * height
      );
      var newWidth = Math.floor(
        (extraData.legendsData[xval[0].elementID].length / 15) * width
      );
      layout.margin.r += Math.floor((width - newWidth) / 2);
      layout.margin.l += Math.floor((width - newWidth) / 2);
      layout.margin.b += Math.floor((height - newHeight) / 2);
      layout.margin.t += Math.floor((height - newHeight) / 2);
    }
  } else if (findChartType("isXAxisPrimaryChart", chartType)) {
    var numBars = undefined;
    if (
      findChartType("isBarTypeChart", chartType) ||
      findChartType("isStackedBarTypeChart", chartType)
    ) {
      numBars = data[0].x.length;
    } else if (findChartType("isGroupedBarTypeChart", chartType)) {
      numBars = data[0].x.length * data.length;
    }
    if (numBars > 0) {
      var leftMargin;
      if (findChartType("isStackedBarTypeChart", chartType))
        leftMargin = (layout.width - (120 + numBars * 30)) / 2;
      else leftMargin = (layout.width - (20 + numBars * 30)) / 2;
      leftMargin = Math.max(100, leftMargin);

      //leftMargin = Math.max((layout.width-50)/2, leftMargin);
      layout.margin = { l: leftMargin, b: 100, r: leftMargin, t: 50 };
    } else {
      layout.margin = { l: 100, b: 100, r: 100, t: 50 };
    }
    if (findChartType("isLineTypeChart", chartType)) {
      layout.margin = { l: 250, b: 100, r: 250, t: 50 };
    }
  } else if (findChartType("isYAxisPrimaryChart", chartType)) {
    var numBars = undefined;
    if (
      findChartType("isBarTypeChart", chartType) ||
      findChartType("isStackedBarTypeChart", chartType)
    ) {
      numBars = data[0].y.length;
    } else if (findChartType("isGroupedBarTypeChart", chartType)) {
      numBars = data[0].y.length * data.length;
    }
    if (numBars > 0) {
      var bottomMargin;
      if (findChartType("isStackedBarTypeChart", chartType))
        bottomMargin = (layout.height - (70 + numBars * 30)) / 2;
      else bottomMargin = (layout.height - (20 + numBars * 30)) / 2;
      bottomMargin = Math.max(100, bottomMargin);
      layout.margin = {
        l: 120,
        b: bottomMargin,
        r: 50,
        t: bottomMargin,
      };
    } else {
      layout.margin = { l: 120, b: 100, r: 100, t: 50 };
    }
  } else if (findChartType("isPieChart", chartType)) {
    layout.margin = { l: 500, b: 100, r: 500, t: 50 };
  } else {
    layout.margin = { l: 100, b: 100, r: 100, t: 50 };
  }

  //Functions to be sent back for attaching to plotly
  function resetLegendTooltip() {
    /* do something wilh data */
    d3.selectAll("div.plotlyTooltip_legend").remove();
    legendTooltip = getTooltipLayout("legend");
  }

  //Attach function to be added to plotly chart
  function attach() {
    var legendLayer = d3.select("g.legend");
    if (legendLayer) {
      var legendItems = legendLayer.selectAll("g.traces");
      legendTooltip = getTooltipLayout("legend");

      legendItems.on("mouseover", function (d) {
        //var legendName = (chartType === "pie") ? d[0].label : d[0].trace.name;
        var legendName;
        if (findChartType("isPieChart", chartType)) {
          var colorCode = convertToHex(parseRGBColor(d[0].color));
          legendName = legendNameColorMap["#" + colorCode];
        }
        if (
          findChartType("isGroupedBarTypeChart", chartType) ||
          findChartType("isStackedBarTypeChart", chartType) ||
          findChartType("isMultiLineChart", chartType) ||
          findChartType("isMultiAxisLineChart", chartType)
        ) {
          legendName = legendNameColorMap[d[0].trace.marker.color];
        }
        if (legendName && legendName.length > maxLegendLen) {
          legendTooltip
            .text(legendName)
            .style("visibility", "visible")
            .style("color", "#fff")
            .style("font-size", "11px")
            .style("font-family", "Fira Sans")
            .style("top", d3.event.pageY - 10 + "px")
            .style("left", d3.event.pageX + 10 + "px");
        }
      });

      legendItems.on("mouseout", function () {
        legendTooltip.style("visibility", "hidden");
      });

      legendItems.on("click", function () {
        resetLegendTooltip();
        legendTooltip.style("visibility", "hidden");
      });
    }

    if (isAxisChartType() && !isLineChart()) {
      var axisLayer, axisItems, axisLayer2, axisItems2;
      var axisTooltip = getTooltipLayout("axis");
      if (isHorizontalOrientedChart()) {
        axisLayer = d3.select("g.yaxislayer");
        axisItems = axisLayer.selectAll("g.ytick");
      } else if (chartType === "crosstabheatmap") {
        axisLayer = d3.select("g.xaxislayer");
        axisItems = axisLayer.selectAll("g.xtick");
        axisLayer2 = d3.select("g.yaxislayer");
        axisItems2 = axisLayer2.selectAll("g.ytick");
      } else {
        axisLayer = d3.select("g.xaxislayer");
        axisItems = axisLayer.selectAll("g.xtick");
      }

      axisItems.on("mouseover", function (d) {
        var tooltipText = d.text;
        tooltipText = tooltipText.replace("<br>", " ");
        axisTooltip
          .text(tooltipText)
          .style("color", "#fff")
          .style("font-size", "11px")
          .style("font-family", "Fira Sans")
          .style("visibility", "visible")
          .style("top", d3.event.pageY - 10 + "px")
          .style("left", d3.event.pageX + 10 + "px");
      });

      axisItems.on("mouseout", function () {
        axisTooltip.style("visibility", "hidden");
      });

      if (!_.isUndefined(axisItems2)) {
        axisItems2.on("mouseover", function (d) {
          var tooltipText = d.text;
          tooltipText = tooltipText.replace("<br>", " ");
          axisTooltip
            .text(tooltipText)
            .style("color", "#fff")
            .style("font-size", "11px")
            .style("font-family", "Fira Sans")
            .style("visibility", "visible")
            .style("top", d3.event.pageY - 10 + "px")
            .style("left", d3.event.pageX + 10 + "px");
        });

        axisItems2.on("mouseout", function () {
          axisTooltip.style("visibility", "hidden");
        });
      }
    }
  }

  return { data, layout, configurations, attach };
}

//ChartService
export function getDimensionObjByOriginalID(dimID, plotlyDimensions) {
  var item = _.filter(plotlyDimensions, function (d) {
    return d._id === dimID;
  });
  if (item.length > 0) {
    return item[0];
  } else {
    throw new Error(`${dimID} is not available`);
  }
}

export function getMeasureObjByOriginalID(metricID, plotlyMetrics) {
  var metric = _.filter(plotlyMetrics, function (item) {
    // return item._id === metricID?.id || metricID;
    return item._id === metricID;
  });
  if (metric.length > 0) {
    return metric[0];
  } else {
    throw new Error(`${metricID} is not available`);
  }
}

export const buildYvalFromChartObject = ({ chartObject, plotlyMetrics }) => {
  let yval = [];
  let metricsList = chartObject.requestParam.yAxis;
  let approxCountDistinctList = chartObject.requestParam.approxCountDistinct;
  let customMetricsList = chartObject.requestParam.specialCalculation;
  let allMetrics = [
    ...metricsList,
    ...approxCountDistinctList,
    ...customMetricsList,
  ];
  allMetrics.forEach((metricId) => {
    let obj = getMeasureObjByOriginalID(metricId, plotlyMetrics);
    let updObj = {
      rowID: obj.measureID,
      rowType: "measure",
      elementID: obj.measureID,
      dataType: obj.dataType,
      title: obj.measureTitle,
      _id: obj._id,
    };
    yval.push(updObj);
  });
  return yval;
};

export const buildXvalFromChartObject = ({ chartObject, plotlyDimensions }) => {
  let xval = [];
  let allDim = chartObject.requestParam.xAxis;
  allDim.forEach((dimId) => {
    let obj = getDimensionObjByOriginalID(dimId, plotlyDimensions);
    let updObj = {
      columnID: obj.dimID,
      columnType: "dimension",
      elementID: obj.dimID,
      dataType: obj.dataType,
      title: obj.dimTitle,
      _id: obj._id,
    };
    xval.push(updObj);
  });
  return xval;
};

export const extractChartTypeFromChartObject = (chartObject) =>
  chartObject.metadata.chartType;

export const buildSortbyFromChartObject = ({
  chartObject,
  plotlyMetrics,
  plotlyDimensions,
}) => {
  let sortBy = [];
  let metricOrdByList = chartObject.requestParam.orderBy.metricOrdByList;
  let customMetricOrdByList =
    chartObject.requestParam.orderBy.customMetricOrdByList;
  let dimOrdByList = chartObject.requestParam.orderBy.dimOrdByList;
  if (metricOrdByList) {
    metricOrdByList.forEach((ele) => {
      let measureObj = getMeasureObjByOriginalID(ele.id, plotlyMetrics);
      sortBy.push({
        id: measureObj.measureID,
        name: measureObj.measureTitle,
        desc: ele.desc,
        objType: "metric",
        _id: measureObj._id,
      });
    });
  }
  if (customMetricOrdByList) {
    customMetricOrdByList.forEach((ele) => {
      let measureObj = getMeasureObjByOriginalID(ele.id, plotlyMetrics);
      sortBy.push({
        id: measureObj.measureID,
        name: measureObj.measureTitle,
        desc: ele.desc,
        objType: "metric",
        _id: measureObj._id,
      });
    });
  }
  if (dimOrdByList) {
    dimOrdByList.forEach((ele) => {
      let dimObj = getMeasureObjByOriginalID(ele.id, plotlyDimensions);
      sortBy.push({
        id: dimObj.dimID,
        name: dimObj.dimTitle,
        desc: ele.desc,
        objType: "dimension",
        _id: dimObj._id,
      });
    });
  }
  return sortBy;
};

export function supportsStackedBarChart({ xval, yval, sortBy, chartType }) {
  if (
    xval.length === 2 &&
    sortBy[0].objType === "dimension" &&
    xval[1].dimID === sortBy[0].id &&
    yval.length === 1 &&
    chartType !== "crosstabheatmap"
  ) {
    return false;
  }
  return xval.length === 2 &&
    yval.length === 1 &&
    chartType !== "crosstabheatmap" &&
    chartType !== "multitable"
    ? true
    : false;
}

export function formatFiltersFromVals(
  mainFilters,
  dimension,
  values,
  queryNum
) {
  var obj = {};
  var hasFiltersOnDim1 = false,
    hasMoreFilterVals = false;
  _.each(mainFilters, function (item) {
    if (item.elementID === dimension.elementID) {
      hasFiltersOnDim1 = true;
      if (item.values.length > 20) {
        hasMoreFilterVals = true;
      }
    }
  });
  if (!hasFiltersOnDim1) {
    if (queryNum === 1) {
      obj.sendQuery = true;
      obj.sendMainFilters = true;
      obj.sendCustomFilters = false;
      obj.filters = mainFilters;
    } else if (queryNum === 2) {
      obj.sendQuery = true;
      obj.sendMainFilters = true;
      obj.sendCustomFilters = true;
      var customFilters = {
        filterType: "sum",
        values: values,
        id: dimension._id,
      };
      mainFilters.push(customFilters);
      obj.filters = mainFilters;
    }
  } else {
    if (!hasMoreFilterVals) {
      if (queryNum === 1) {
        obj.sendQuery = false;
      } else if (queryNum === 2) {
        obj.sendQuery = true;
        obj.sendMainFilters = true;
        obj.sendCustomFilters = false;
        obj.filters = mainFilters;
      }
    } else {
      if (queryNum === 1) {
        obj.sendQuery = true;
        obj.sendMainFilters = true;
        obj.sendCustomFilters = false;
        obj.filters = mainFilters;
      } else if (queryNum === 2) {
        obj.sendQuery = true;
        obj.sendMainFilters = true;
        obj.sendCustomFilters = true;
        var customFilters = {
          filterType: "sum",
          values: values,
          id: dimension._id,
        };
        var filtersAdded = false;
        _.each(mainFilters, function (item) {
          if (item.elementID === dimension.elementID) {
            item.values = values;
            filtersAdded = true;
          }
        });
        if (!filtersAdded) {
          mainFilters.push(customFilters);
        }
        obj.filters = mainFilters;
      }
    }
  }
  return obj;
}

export function getParamsFromChartObj(chartObj, param) {
  switch (param) {
    case "id":
      return chartObj._id;
    case "title":
      return chartObj.metadata.title;
    case "xAxis":
      return chartObj.requestParam.xAxis;
    case "yAxis":
      return chartObj.requestParam.yAxis;
    case "specialCalculation":
      return chartObj.requestParam.specialCalculation;
    case "chartType":
      return chartObj.metadata.chartType;
    case "filter":
      return chartObj.requestParam.filter;
    case "orderBy":
      return chartObj.requestParam.orderBy;
    default:
      return undefined;
  }
}

export const formatTableForDisplay = (props) => {
  const {
    xval,
    yval,
    selectedTimezone,
    rawData,
    allData,
    isComparisonOn = false,
    abbrFlag = false, //flag to tell this function if the values need to be abbreviated
  } = props;
  let formattedTableData = [...rawData];

  //Making table headers
  let dimHeaders = [...xval].map((row) => ({
    id: row._id,
    name: row.title,
    accessor: row.elementID,
    type: "dimension",
    dataType: row.dataType,
  }));
  let metricHeaders = [...yval].map((row) => ({
    id: row._id,
    name: row.title,
    accessor: row._id.startsWith("M") ? row.elementID : row._id,
    type: row._id.startsWith("M") ? "metric" : "customMetric",
    dataType: row.dataType,
  }));
  if (isComparisonOn) {
    //Adding 3 rows for each metric (value, change, %change)
    metricHeaders = metricHeaders
      .map((row) => [
        row,
        {
          id: `${row.id}_change`,
          name: "Change",
          accessor: row.id.startsWith("M")
            ? `${row.accessor}_trueDelta`
            : `${row.id}_trueDelta`,
          type: "change",
          actualAccessor: row.accessor, //Adding this so that we can use it in the switch case of change in func getMeasureDataUnitByID
          dataType: row.dataType,
        },
        {
          id: `${row.id}_per_change`,
          name: "%Change",
          accessor: row.id.startsWith("M")
            ? `${row.accessor}_deltaPercentage`
            : `${row.id}_deltaPercentage`,
          type: "per_change",
          actualAccessor: row.accessor,
          dataType: row.dataType,
        },
      ])
      .flat();
  }
  let tableHeaders = [...dimHeaders, ...metricHeaders];

  //Formatting Table Data
  formattedTableData = formattedTableData.map((rowObj) => {
    let colData = [];
    tableHeaders.forEach((colObj) => {
      let { id, type, dataType, accessor, actualAccessor } = colObj;
      let displayValue;
      switch (type) {
        case "dimension":
          if (dataType === "dateTime") {
            let value = parseInt(rowObj[accessor]);
            switch (accessor) {
              case "month":
                displayValue = moment(value)
                  .utcOffset(selectedTimezone.minutesOffset)
                  .format("MMMM YYYY");
                break;
              case "day":
                displayValue = moment(value)
                  .utcOffset(selectedTimezone.minutesOffset)
                  .format("YYYY-MM-DD");
                break;
              case "hour":
                displayValue = moment(value)
                  .utcOffset(selectedTimezone.minutesOffset)
                  .format("YYYY-MM-DD HH:mm");
                break;
              default:
                break;
            }
          } else {
            displayValue = rowObj[accessor];
          }
          break;
        case "metric":
          var dataUnit = getMeasureDataUnitByID(
            accessor,
            allData.plotlyMetrics
          );
          displayValue = appendMetricSym(rowObj[accessor], dataUnit, abbrFlag);
          break;
        case "customMetric":
          var dataUnit = getMeasureDataUnitByID(
            accessor,
            allData.plotlyMetrics
          );
          displayValue = appendMetricSym(rowObj[accessor], dataUnit, abbrFlag);
          break;
        case "change":
          var dataUnit = getMeasureDataUnitByID(
            actualAccessor,
            allData.plotlyMetrics
          );
          displayValue = appendMetricSym(rowObj[accessor], dataUnit, abbrFlag);
          break;
        case "per_change":
          // var dataUnit = getMeasureDataUnitByID(
          //   actualAccessor,
          //   allData.plotlyMetrics
          // );
          var dataUnit = { dType: "percent", value: "%" };
          displayValue = `${appendMetricSym(
            rowObj[accessor],
            dataUnit,
            abbrFlag
          )}`;
          break;
        default:
          break;
      }
      let newColObj = {
        id,
        name: displayValue,
        type,
        actualValue: rowObj[accessor],
      };
      colData.push(newColObj);
    });
    return { id: v4(), colData };
  });

  return { headers: tableHeaders, data: formattedTableData };
};

export const manipulateDataLimit = (xAxis, plotlyDimensions) => {
  //if the dimension list has only one value and it is a time dimension, return 500
  //else return undefined

  if (xAxis.length !== 1) return undefined;
  if (!isTimeDimension(getDimensionObjByOriginalID(xAxis[0], plotlyDimensions)))
    return undefined;
  return 500;
};

export const getMetricTypeForChartObject = (metricObj) => {
  //Based on metricId and aggregationList, a metric is categorized into one of:
  //  1) base_sum (yAxis)
  //  2) base_approxCountDistinct (approxCountDistinct)
  //  3) custom (specialCalculation)
  //Based on the type, the chart object changes
  const metricId = metricObj._id;
  const metricAggInfo = metricObj?.aggregationList?.includes(
    "approxCountDistinct"
  ) //Hard coded
    ? "approxCountDistinct"
    : "sum";
  if (metricId.startsWith("C")) return "custom";
  if (metricAggInfo === "approxCountDistinct")
    return "base_approxCountDistinct";
  if (metricAggInfo === "sum") return "base_sum";
  return "base_sum";
};

export const unwrapperGetDataToTableData = (props) => {
  const {
    payload,
    allData,
    rawData,
    user,
    isComparisonOn = false,
    abbrFlag = false,
  } = props;

  //Make a copy of payload
  const payloadCopy = cloneDeep(payload);

  //Format data based on logic
  const chartObject = payloadCopy.chartObject;
  const xval = buildXvalFromChartObject({
    chartObject,
    plotlyDimensions: allData.plotlyDimensions,
  });
  const yval = buildYvalFromChartObject({
    chartObject,
    plotlyMetrics: allData.plotlyMetrics,
  });
  const selectedTimezone = user.timeFilters.selectedTimezone;
  const newTableData = formatTableForDisplay({
    xval,
    yval,
    selectedTimezone,
    rawData,
    allData,
    isComparisonOn,
    abbrFlag,
  });
  return newTableData;
};

export const getOriginalIdFromMeasureId = (
  measureID = "",
  plotlyMetrics = []
) => {
  const reqMetricObj = plotlyMetrics.find((row) => row.measureID === measureID);
  if (!reqMetricObj)
    throw new Error(`MeasureID "${measureID}" unavailable in metrics list`);
  return reqMetricObj._id;
};
