//Import required libraies
import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

// Import Custom Component
import SigviewTextField from "./SigviewTextField";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import action creators

// Import utils

// Import styles

function SigviewInputTags(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    data,
    onChange,
    inputValidationFunction,
    variant = "report",
    border,
  } = props;
  const [focused, setFocused] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [textError, setTextError] = useState({ flag: false, message: "" });
  let modifiedData = data;

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexWrap: "wrap",
      border: `1px solid ${themeColors["headerBgColor"]}`,
      borderRadius: "3px",
      height: "max-content",
      padding: "5px 5px 2px 5px",
      boxSizing: "border-box",
      backgroundColor: "#fff",
    },
    chipsContainer: {
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      listStyle: "none",
      // padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      marginRight: "3px",
      marginBottom: "3px",
      height: "24px",
      backgroundColor: "#f5f5f5",
      "&.MuiChip-deletable": {
        backgroundColor: "#eaf3ff",
        border: `1px solid #f4f7ff`,
      },
      "& .MuiChip-label": {
        fontSize: "11px",
        fontFamily: "Fira Sans !important",
        maxWidth: "100px",
        color: themeColors["secondaryColor"],
      },
      "& .MuiChip-deleteIcon": {
        color: themeColors["secondaryColorLighter"],
        width: "16px",
        "&:hover": {
          color: themeColors["failureColor"],
        },
      },
    },
  }));

  const handleDelete = (chipToDelete) => () => {
    const newData = data.filter((chip) => chip.id !== chipToDelete.id);
    onChange(newData);
  };

  //Add event listener on keydown
  useEffect(() => {
    let keysPressed = {};

    const keydownFunc = (event) => {
      keysPressed[event.key] = true;
      if (keysPressed["Enter"] && focused) {
        const { isValid, message } = inputValidationFunction(textValue);
        if (isValid) {
          const newData = [
            ...data,
            { id: textValue, name: textValue, deletable: true },
          ];
          onChange(newData);
          setTextValue("");
          setTextError({ flag: false, message: "" });
        } else {
          setTextError({ flag: true, message });
        }
      }
    };

    const keyupFunc = (event) => {
      delete keysPressed[event.key];
    };

    window.addEventListener("keydown", keydownFunc);
    window.addEventListener("keyup", keyupFunc);
    return () => {
      window.removeEventListener("keydown", keydownFunc);
      window.removeEventListener("keyup", keyupFunc);
    };
  });

  const classes = useStyles();
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  if (variant === "adminReport") {
    modifiedData = data.filter((el, index) => index !== 0);
  }
  return (
    <div className={classes.root}>
      <div className={classes.chipsContainer}>
        {modifiedData.map((row) => {
          return (
            <Chip
              label={row.name}
              title={row.name}
              key={row.name}
              onDelete={row.deletable ? handleDelete(row) : undefined}
              className={classes.chip}
            />
          );
        })}
        <SigviewTextField
          placeholder="Type email and press enter"
          onFocus={onFocus}
          onBlur={onBlur}
          value={textValue}
          onChange={(newValue) => setTextValue(newValue)}
          error={textError.flag}
          helperText={textError.message}
          minWidth="150px"
          border={border}
          maxWidth="150px"
          margin={{ right: "5px" }}
        />
      </div>
    </div>
  );
}

export default SigviewInputTags;
