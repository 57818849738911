// * Import required libraies
import React, { useRef, useContext, useMemo } from "react";
import { v4 } from "uuid";
import { useSelector } from "react-redux";
import { connect } from "react-redux";

// * Import lib components
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// * Import custom components
import MsvCalendarMetricChart from "../../components/VizTypes/MsvCalendarMetricChart/MsvCalendarMetricChart";
import Loader from "../../components/Loader/Loader";
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler";
import MsvCalendarMetricChartHeader from "../../components/VizTypes/MsvCalendarMetricChart/MsvCalendarMetricChartHeader";
import SigviewProgressBar from "../../components/Common/SigviewProgressBar";

// * Import redux utils
// Selectors
import {
  selectPrimaryCalendarData,
  selectSecondaryCalendarData,
  selectMetricChartType,
  selecteMetricChartGranularity,
  selectSelectedTimezone,
  selectMetricObjectFromPlotlyMetrics,
  selectMetricChartYaxisRangeType,
  selectLoadingStatusForWsMetricChart,
  selectMetricAccessor,
} from "../../redux/selectors/standaloneMsvSelectors";
// Actions
import { reloadStandaloneMsvAllMetricChartsData } from "../../redux/actions";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Define required functions
const makeSigviewStyle = (themeColors, style) => {
  //   const {} = style;
  const useStyles = makeStyles({
    root: {
      height: "100%",
      overflow: "hidden",
      backgroundColor: "white",
      border: "1px solid transparent",
      "&:hover": {
        boxShadow: themeColors["dsItemBoxShadow"],
        //border: `1px solid ${themeColors["secondaryColorLighter"]}`,
        "& $headerRightContainer": { display: "flex" },
      },
    },
    chartContainer: {
      height: "calc(100% - 31px - 5px)",
      width: "100%",
      // This padding is not correct here, if you really want that padding and want child not to overflow use the below width then.
      // padding: "0px 10px",
      // width: "calc(100% - 20px)",
      boxSizing: "border-box",
    },
    headerRoot: {
      // padding: "5px 10px",
      padding: "0px 7px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: "31px",
      boxSizing: "border-box",
    },
    headerLeftContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexGrow: 1,
      flexBasis: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      cursor: "all-scroll",
    },
    headerValueContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // flexGrow: 1,
      // flexBasis: 0,
      flexShrink: 0,
    },
    headerTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // flexGrow: 1,
      // flexBasis: 0,
      flexShrink: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    headerInfoContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexShrink: 0,
    },
    headerCompareContainer: {
      display: "flex",
      justifyContent: "space-between",
      justifyContent: "flex-start",
      alignItems: "center",
      // flexShrink: 0,
      flexGrow: 1,
      flexBasis: 0,
    },
    headerRightContainer: {
      display: "none",
      justifyContent: "flex-end",
      alignItems: "center",
      flexShrink: 0,
      width: "max-content",
    },
    headerCustomIcon: {
      cursor: "pointer",
      padding: "0px 5px",
      color: `${themeColors["secondaryColorLight"]} !important`,
      "&:hover": {
        color: `${themeColors["primaryColor"]} !important`,
      },
    },
    headerDeleteIcon: {
      "&:hover": {
        color: themeColors["negChangeColor"] + " !important",
      },
    },
    progressBarContainer: {
      height: "5px",
      width: "100%",
      padding: "3px 0px 0px 0px !important",
      boxSizing: "border-box",
    },
  });
  return useStyles;
};

function WsMetricChartItem(props) {
  // * Destructure props
  const {
    // REDUX PROPS
    dispatch: ReduxDispatcher,
    // PARENT PROPS
    id,
  } = props;

  // * Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define required states
  const chartContainerId = useRef(`chartHolder-${v4()}`);

  // * Destructure REDUX props
  const chartType = useSelector(selectMetricChartType);
  const primaryCalendarData = useSelector((state) =>
    selectPrimaryCalendarData(state, id)
  );
  const secondaryCalendarData = useSelector((state) =>
    selectSecondaryCalendarData(state, id)
  );
  const wsMetricChartLoadingStatus = useSelector((state) =>
    selectLoadingStatusForWsMetricChart(state, id)
  );
  const accessor = useSelector((state) => selectMetricAccessor(state, id));
  const granularity = useSelector(selecteMetricChartGranularity);
  const selectedTimezone = useSelector(selectSelectedTimezone);
  const metricObj = useSelector((state) =>
    selectMetricObjectFromPlotlyMetrics(state, id)
  );
  const metricChartYaxisRangeType = useSelector(
    selectMetricChartYaxisRangeType
  );

  // * Define ONLY ABSOLUTE NECEASSRY MEMOIZED STATES
  const childrenProps = {
    id,
    primaryCalendarData: primaryCalendarData,
    secondaryCalendarData: secondaryCalendarData,
    chartContainerId: chartContainerId.current,
    isMetricChartOpen: false,
    chartType: chartType,
    accessor: accessor,
    granularity: granularity,
    metricChartYaxisRangeType: metricChartYaxisRangeType,
    selectedTimezone: selectedTimezone,
    metricObj: metricObj,
    themeColors: themeColors,
    themeState: themeState,
  };
  const memoizedStringedChildrenProps = JSON.stringify(childrenProps);
  // ! COMMENTING OUT THE MEMOIZATION AS THE HOVER CHART TOOLTIP FUNC IS AFFECTING
  // ! FIX THAT BUG IF YOU WANT TO IMPLEMENT MEMOIZATION
  // const memoizedStringedChildrenProps = useMemo(
  //   () => JSON.stringify(childrenProps),
  //   [
  //     primaryCalendarData,
  //     secondaryCalendarData,
  //     chartType,
  //     granularity,
  //     metricChartYaxisRangeType,
  //     selectedTimezone,
  //   ]
  // );

  // * Define required event handlers
  const handleReloadMetricCharts = () => {
    const action = reloadStandaloneMsvAllMetricChartsData();
    ReduxDispatcher(action);
  };

  // * Define required static variables
  const useSigviewStyles = makeSigviewStyle(themeColors, {});
  const classes = useSigviewStyles();
  const { fullLoading, fullSuccess, partialLoading, partialSuccess, anyError } =
    wsMetricChartLoadingStatus;

  return (
    <Box className={classes.root}>
      <MsvCalendarMetricChartHeader
        id={id}
        classes={classes}
        anyError={anyError}
        metricChartProps={childrenProps}
      />

      <Box className={classes.chartContainer} id={chartContainerId.current}>
        {/* UI */}
        {!anyError && fullLoading && <Loader />}
        {anyError && (
          <ErrorHandler
            layout="metricChart"
            message={primaryCalendarData.message}
            reloadFlag={true}
            onReload={handleReloadMetricCharts}
          />
        )}
        {!anyError && (fullSuccess || partialSuccess) && (
          <MsvCalendarMetricChart
            memoizedStringedChildrenProps={memoizedStringedChildrenProps}
          />
        )}
      </Box>
      <Box className={classes.progressBarContainer}>
        {!anyError && partialLoading && !fullLoading && <SigviewProgressBar />}
      </Box>
    </Box>
  );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(WsMetricChartItem);
