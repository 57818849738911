// * Import required libraies
import React from "react";

// * Import 3rd party lib comp
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";

// * Import custom components
import SigviewTypography from "../../components/Common/SigviewTypography";

// * Define required functions
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "0px 22px 10px 22px",
  },
  leftPanel: { display: "flex", alignItems: "center" },
  rightPanel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    height: "25px",
    // alignItems: "center",
    // justifyContent: "flex-end",
  },
  itemTitle: {},
  item: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    marginLeft: "5px",
    padding: "0px 0px",
    boxShadow: theme.palette.misc["dsItemBoxShadow"],
    borderRadius: "2px",
    width: "100%",
    justifyContent: "center",
  },
  msvRightPanel: {
    display: "flex",
    alignItems: "center",
    marginLeft: "5px",
    padding: "0px 0px 0px 3px",
    boxShadow: theme.palette.misc["dsItemBoxShadow"],
    borderRadius: "2px",
  },
  lastChildInRightPanel: { marginLeft: "15px" },
}));

function LayoutMetricChartsMenu(props = {}) {
  // * Destructure props
  const { children = <></>, showTitle = false, variant = "workspace" } = props;

  // * Define requires static variables
  const classes = useStyles();
  const {
    root,
    leftPanel,
    rightPanel,
    itemContainer,
    itemTitle,
    item,
    msvRightPanel,
  } = classes;
  var itemInRightPanel = item;
  if (variant === "msv") itemInRightPanel = msvRightPanel;

  return (
    <Grid container className={root}>
      <Grid item xs={4} className={leftPanel}>
        <Box className={itemContainer}>
          {showTitle && (
            <Box className={itemTitle}>
              <SigviewTypography
                style={{
                  height: "max-content",
                  margin: "0px 0px 5px 3px !important",
                }}
                variant="pSmaller"
              >
                Global Sort
              </SigviewTypography>
            </Box>
          )}

          <Box className={item}>{children[0]}</Box>
        </Box>
      </Grid>
      <Grid item xs={8} className={rightPanel}>
        <Box className={itemContainer}>
          {showTitle && (
            <Box className={itemTitle}>
              <SigviewTypography
                style={{
                  height: "max-content",
                  margin: "0px 0px 5px 3px !important",
                }}
                variant="pSmaller"
              >
                Row Count
              </SigviewTypography>
            </Box>
          )}

          <Box className={item}>{children[1]}</Box>
        </Box>
        <Box
          className={`${classes.itemContainer} ${classes.lastChildInRightPanel}`}
        >
          {showTitle && (
            <Box className={itemTitle}>
              <SigviewTypography
                style={{
                  height: "max-content",
                  margin: "0px 0px 5px 3px !important",
                }}
                variant="pSmaller"
              >
                Grid View
              </SigviewTypography>
            </Box>
          )}

          <Box className={itemInRightPanel}>{children[2]}</Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LayoutMetricChartsMenu;
