// * Import required libraies
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { v4 } from "uuid";
import moment from "moment";

// * Import 3rd party lib comp

// * Import Custom Component
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import PivotCreate from "./PivotCreate";
// * Import actions

// * Import data/utils
import SIGVIEW_CONTANTS from "../../../constants/sigviewConstants";
import { config } from "../../../config/config";

// * Import contexts

// * Import API functions

// * Define required variables

// *  Import action creators
import { updateUserScreen } from "../../../redux/actions";

function PivotCreateContainer(props = {}) {
  // * Destructure props
  const { user = {}, dispatch: ReduxDispatcher, globalFilters = {} } = props;

  // * Define hooks
  const history = useHistory();

  // * Define required states
  const [pageStatus, setPageStatus] = useState(
    SIGVIEW_CONTANTS.initialPageStatus
  );

  useEffect(() => {
    return () => {
      let id = v4();
      const newTitle = `Untitled Pivot - ${moment(Date.now()).format(
        config.hardCoded.defaultStandaloneChartTimestampFormat
      )}`;
      const defaultPivotSelections = {
        id: id,
        title: newTitle,
        dimensionsList: [],
        metricsList: [],
        advanceSort: [],
        timeFilters: globalFilters.timeFilters,
        elementType: "create",
        renderFlag: false,
        transpose: false,
        dimensionFilters: [],
        metricFilters: [],
        dataLimit: 10,
        progressiveDateFlag: false,
        feature: "pivotx",
        reloadRenderEpoch: Date.now(),
      };

      // Update activeChart in redux and screen so that user can be redirected to create pivot screen
      const value = {
        activeTab: "createPivot",
        activePivot: defaultPivotSelections,
      };
      var action = updateUserScreen(null, value);
      ReduxDispatcher(action);
    };
  }, [user.reqMetadata.view]);

  useEffect(() => {
    // ! QUICK FIX
    const runFlag = user.screen.activePivot.feature !== undefined;

    // * CORRECT BUT LENGHTY FIX
    // const runFlag = isActiveDatastoryValid(user.screen.activeDatastory)

    if (runFlag) {
      setPageStatus({
        status: "success",
        message: "",
      });
    } else {
      history.push("/pivotx");
      // setPageStatus({
      //   status: "loading",
      //   message: "",
      // });
    }
  }, [user.screen.activePivot]);

  return (
    <>
      {pageStatus.status === "loading" && (
        <LayoutTopSideBottom>
          <Loader />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "error" && (
        <LayoutTopSideBottom>
          <ErrorHandler
            message={pageStatus.message}
            reloadFlag={true}
            onReload={() => {
              window.reload();
            }}
          />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "success" && <PivotCreate />}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(PivotCreateContainer);
