// ! IMPORTANT NOTES
// Caution:
// 1. This is a fast fix to solve website performance
// 2. This may not adhere to our kit guidelines

//Import required libraies
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// Defining required functions
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: (style) => style?.rootFlexDirection || "row",
  },
  checkboxItemContainer: {
    height: (style) => style?.height || "24px",
    display: "flex",
    alignItems: "center",
    marginRight: "5px",
    padding: "0px !important",
    boxSizing: "border-box",
  },
  disabled: {
    opacity: 0.5,
  },
  checkboxItemLabel: {
    fontSize: "12px",
    color: theme.palette.misc["secondaryColor"],
  },
  gridRoot: {
    height: "400px",
    width: "100%",
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateRows: "repeat(15, 1fr)",
    // gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: "0px 10px",
    gridAutoColumns: "minmax(50px, auto)",
    // gridAutoRows: "minmax(20px, auto)",
    // backgroundColor: "green",
    alignItems: "start",
    justifyItems: "stretch",
    // overflow: "auto",
  },
  gridCheckboxItemContainer: {
    cursor: "pointer",
    height: (style) => style?.height || "24px",
    display: "flex",
    alignItems: "center",
    marginRight: "5px",
    padding: "0px !important",
    boxSizing: "border-box",
    // backgroundColor: "red",
  },
  gridCheckboxItemLabel: {
    fontSize: "11px",
    color: theme.palette.misc["secondaryColor"],
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

// Making styled SigviewCheckbox
const SigviewCheckbox = withStyles((theme) => ({
  root: {
    color: `${theme.palette.misc["checkboxColor"]} !important`,
    padding: "0px !important",
    marginRight: "5px",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&.MuiCheckbox-colorSecondary.Mui-checked:hover": {
      backgroundColor: "transparent",
    },
    "&.MuiCheckbox-colorSecondary.Mui-disabled": {
      backgroundColor: "transparent",
      opacity: 0.5,
      cursor: "not-allowed",
    },

    "& .MuiIconButton-label": {
      color: "inherit !important",
    },

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function SigviewCheckboxGroup(props) {
  const { t } = useTranslation();
  const {
    onChange = () => {},
    data = [],
    style = {},
    variant = "flex",
    renderProp = {},
    disabled = false,
    customID,
    customClassName,
  } = props;
  const {
    flag: renderPropFlag = false,
    component: renderPropComp = () => <></>,
  } = renderProp;

  const classes = useStyles(style);
  let Component = <></>;

  switch (variant) {
    case "grid":
      Component = (
        <div className={classes.gridRoot}>
          {data.map((row) => {
            var labelClassName = classes.gridCheckboxItemLabel;
            if (disabled)
              labelClassName = `${labelClassName} ${classes.disabled}`;

            return (
              <div
                className={classes.gridCheckboxItemContainer}
                key={row.id}
                onClick={(event) => {
                  onChange(event, row.id, !row.checked, row);
                }}
              >
                <SigviewCheckbox
                  disableRipple
                  checked={row.checked}
                  disabled={disabled}
                  id={customID}
                  className={customClassName}
                  // onClick={(event) => {
                  //   onChange(event, row.id, !row.checked);
                  // }}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <label className={labelClassName}>{t(row.name)}</label>
                {renderPropFlag && renderPropComp(row)}
              </div>
            );
          })}
        </div>
      );
      break;
    default:
      Component = (
        <div className={classes.root}>
          {data.map((row) => {
            var labelClassName = classes.checkboxItemLabel;
            if (disabled)
              labelClassName = `${labelClassName} ${classes.disabled}`;
            return (
              <div
                className={classes.checkboxItemContainer}
                key={row.id}
                // onClick={(event) => {
                //   onChange(event, row.id, !row.checked, row);
                // }}
              >
                <SigviewCheckbox
                  disableRipple
                  disabled={disabled}
                  checked={row.checked}
                  id={customID}
                  className={customClassName}
                  onClick={(event) => {
                    onChange(event, row.id, !row.checked, row);
                  }}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <label className={labelClassName}>{t(row.name)}</label>
              </div>
            );
          })}
        </div>
      );
  }

  return Component;
}

export default SigviewCheckboxGroup;
