//import required libraries
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//import library components
import { makeStyles } from "@material-ui/core";

//import custom components
import CardChart from "./CardChart";
import CardCreate from "./CardCreate";
import CardGrid from "./CardGrid";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

//utility functions
function hexToRGBA(hex = "#000000", alpha = 1) {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
}

function AppCard(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const { payload } = props;
  const { tag, gridLayoutData } = payload;
  let Component = <></>;

  //making styles for all variations
  const useStyles = makeStyles(() => ({
    card: {
      height: "123px",
      width: "180px",
      backgroundColor: "#ffffff",
      borderRadius: "3px",
      border: "1px solid #eff2f5",
      boxShadow: "0px 0px 10px rgba(239, 242, 245, 0.4)",
      cursor: "pointer",
      transition: "transform 0.1s linear",

      "&:hover": {
        border: `1px solid ${themeColors["primaryColor"]}`,
        //EXPERIMENTAL
        // "& .card-title-container": {
        //   backgroundColor: themeColors["primaryColor"],
        //   "& .card-title": { color: "#ffffff" },
        // },
      },

      "& *": {
        boxSizing: "border-box",
      },

      "&>.card-image-container": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
      },

      "&>.card-image-body-container": {
        height: "calc(100% - 31px)",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",

        "&>.card-overlay-container": {
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          backgroundColor: hexToRGBA("#267EE6", 0.3),
          borderRadius: "2px 2px 0 0",

          "& .card-tick-icon-container": {
            position: "absolute",
            top: "4px",
            right: "4px",
            width: "14px",
            height: "14px",
            backgroundColor: "white !important",
            borderRadius: "50%",

            "&>span": {
              fontSize: "14px !important",
              color: "#267EE6",
            },
          },
        },
      },

      "&>.card-title-container": {
        height: "31px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "rgba(239, 242, 245, 0.4)",
        borderRadius: "0px 0px 2px 2px",

        "&>*": {
          color: "#46596a",
          fontSize: "12px",
        },
        "&>.card-title": {
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },

        "&>.more-option-icon-container": {
          width: "16px",
          height: "16px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",

          "&> span": {
            fontSize: "14px",
          },
        },
      },

      "&.card-create-and-nochart": {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // * Different Variation
        border: "1px solid transparent",
        boxShadow: "none",
        backgroundColor: "transparent",
        "&:hover": { border: `1px solid transparent` },
      },

      "&.card-create": {
        backgroundColor: "#267ee6",
        color: "#ffffff",
        "&:hover": { border: `1px solid #eff2f5`, transform: "scale(1.05)" },
        "&>.card-title": {
          marginTop: "7px !important",
        }, //> not working
      },

      "&.card-nochart": {
        "&:hover": { border: `1px solid #eff2f5` },
        cursor: "auto",
        "&>.card-title": {
          color: "#267ee6",
          fontSize: "12px",
          marginTop: "9px !important",
        },
      },
    },
  }));

  const classes = useStyles();

  switch (tag) {
    case "chart":
    case "chartWithIcon":
      Component = (
        <CardChart {...props} classes={classes} gridData={gridLayoutData} />
      );
      break;
    case "gridChart":
      Component = (
        <CardGrid {...props} classes={classes} gridData={gridLayoutData} />
      );
      break;
    case "createNew":
    case "noCharts":
      Component = <CardCreate {...props} classes={classes} />;
      break;
    default:
      Component = <h1>t("Something Went Wrong!")</h1>;
      break;
  }
  return Component;
}

export default AppCard;
