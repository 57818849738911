//Can be pulled out as a reusable component
//Import required libraies
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";

// Import Custom Component

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import action creators

// Import utils

// Import styles

function DimensionsDropdown(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    allDimensionsList,
    activeDimension,
    globalFiltersStore,
    handleSelectDimensionChange,
    disabledItemsList = [], // ["D044"] --> This needs to be a array of ids
  } = props;

  //Styling
  const useStyles = makeStyles({
    root: {
      height: "100%",
      "& .MuiPaper-root": {
        // backgroundColor: `${themeColors["buttonBgColor"]} !important`,
        backgroundColor: `${themeColors["primaryColor"]} !important`,
      },
      "& .MuiAutocomplete-root ": {
        width: "100% !important",
        height: "100%",
        // backgroundColor: `${themeColors["buttonBgColor"]} !important`,
        backgroundColor: `${themeColors["primaryColor"]} !important`,
        borderRadius: "3px",
      },
      "& .MuiFormControl-root": {
        height: "100%",
      },
      "& .MuiInputBase-root": {
        padding: "0px !important",
        height: "100%",
      },
      "& .MuiInputBase-input": {
        padding: "0px 40px 0px 10px !important",
        height: "100%",
        fontSize: "11px",
        fontFamily: "Fira Sans !important",
        color: `${themeColors["buttonColor"]} !important`,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none!important",
      },
      "& .MuiAutocomplete-option": {
        fontFamily: "Fira Sans !important",
        fontSize: "11px",
        padding: "5px 10px 5px 5px !important",
        color: `${themeColors["secondaryColor"]} !important`,
        "& .filter-dropdown-option-icon": {
          fontSize: "16px !important",
          marginRight: "5px",
        },
        "& .filter-dropdown-option-icon.invisible": {
          visibility: "hidden",
        },
        "& .filter-dropdown-option-icon.include": {
          color: `${themeColors["primaryColor"]} !important`,
        },
        "& .filter-dropdown-option-icon.exclude": {
          color: `${themeColors["secondaryColorLight"]} !important`,
        },
      },
      '& .MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: `${themeColors["secondaryColorLighter"]} !important`,
      },
      '& .MuiAutocomplete-option[data-focus="true"]': {
        backgroundColor: `${themeColors["primaryColorLightest"]} !important`,
      },
      "& .MuiAutocomplete-listbox": {
        padding: "2px 0px !important",
        backgroundColor: `${themeColors["popoverBgColor"]} !important`,
        "&::-webkit-scrollbar": {
          width: "5px",
          height: "5px",
          backgroundColor: `transparent !important`,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: `${themeColors["secondaryColorLighter"]} !important`,
          borderRadius: "5px",
        },
      },
      "& .MuiAutocomplete-noOptions": {
        fontFamily: "Fira Sans !important",
        fontSize: "11px",
        padding: "5px 10px !important",
        color: themeColors["secondaryColorLight"],
        backgroundColor: themeColors["popoverBgColor"],
      },
      "& .MuiAutocomplete-clearIndicator": {
        marginTop: "2px !important",
        "& .MuiIconButton-label": {
          "& .MuiSvgIcon-root": {
            fontSize: "12px !important",
            color: `${themeColors["headerBgColor"]} !important`,
          },
        },
      },
      "& .MuiAutocomplete-popupIndicatorOpen": {
        "& .MuiIconButton-label": {
          "& .MuiSvgIcon-root": {
            fontSize: "20px !important",
            color: `${themeColors["buttonColor"]} !important`,
          },
        },
      },
      "& .MuiAutocomplete-popupIndicator": {
        "& .MuiIconButton-label": {
          "& .MuiSvgIcon-root": {
            fontSize: "20px !important",
            color: `${themeColors["buttonColor"]} !important`,
          },
        },
      },
      "& .MuiAutocomplete-endAdornment": {
        right: "4px !important",
        top: "0px !important",
        height: "100%",
      },
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        disablePortal //to let dropdown menu render in the same zIndex as the parent
        id="globalFiltersAllDimensionsList"
        options={allDimensionsList}
        value={activeDimension}
        getOptionLabel={(option) => option.dimTitle}
        getOptionDisabled={(option) => disabledItemsList.includes(option._id)}
        renderOption={(option) => {
          const activeDimFilterObj = globalFiltersStore.find(
            (row) => row.id === option._id
          );
          const iconFlag = activeDimFilterObj?.valid ? true : false;
          const filterType = activeDimFilterObj?.filterType;
          const iconName =
            filterType === "include"
              ? "check_circle"
              : filterType === "exclude"
              ? "remove_circle"
              : "check_circle";
          return (
            <>
              <i
                className={`material-icons filter-dropdown-option-icon ${
                  iconFlag ? "" : "invisible"
                } ${filterType ? filterType : ""} `}
              >
                {iconName}
              </i>
              <span>{option.dimTitle}</span>
            </>
          );
        }}
        onChange={handleSelectDimensionChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={t("Select Dimension")}
          />
        )}
      />
    </div>
  );
}

export default DimensionsDropdown;
