// * Import required liraries
import React from "react";
import { Link } from "react-router-dom";

// * import lib component
import { Box } from "@material-ui/core";

// * Import custom components
import SigviewButton from "../../components/Common/SigviewButton";
import LayoutNone from "../../layouts/LayoutNone/LayoutNone";

// * Import styles
import pageNotFoundSvg from "../../../assets/images/pageNotFound.svg";

// * Import redux utils
import { config } from "../../config/config";
function PageNotFound(props = {}) {
  const rootCss = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  const pageNotFoundImageCss = {
    width: "500px",
    height: "400px",
    display: "inline-block",
    backgroundImage: `url(${pageNotFoundSvg})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <LayoutNone>
      <Box css={rootCss}>
        <Box css={pageNotFoundImageCss}></Box>
        <Link to={config.hardCoded.rootUrlOnLogin}>
          <SigviewButton title="Go back to Home" variant="containedPrimary" />
        </Link>
      </Box>
    </LayoutNone>
  );
}

PageNotFound.propTypes = {};

export default PageNotFound;
