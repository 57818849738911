// Import required libraries
import React, { useState, useReducer, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";

// Import custom components
import SigviewButton from "../Common/SigviewButton";
import AdvancedFilters from "./AdvancedFilters";
import SigviewHoverPopup from "../Common/SigviewHoverPopup";

// Import styles

// Import config
import { config } from "../../config/config";

// Import action creators
import {
  updateUserScreen,
  updateDialogInfo,
  replaceAllAdvancedFilters,
} from "../../redux/actions";

// Import reducers
import advancedFiltersReducer from "../../redux/reducers/advancedFilters";

// Import utils & data
import {
  getNewAdFilterRow,
  removeIdFromAllRows,
} from "../../utils/filtersUtils";
import {
  addErrorInfoToAdvancedFilters,
  validateAdvancedFilters,
  subsetAdFilterDropdownTypesBasedOnFeatureAvailability,
} from "../../utils/filtersUtils";
import { getSigviewUserType, getAdFiltersRules } from "../../utils/utils";
import useReducerLogger from "../../utils/useReducerLogger";
import globalFiltersAdFilterDropdownTypes from "../../../assets/data/globalFiltersAdFilterDropdownTypes.json";
import { masterTrackGaEvent } from "../../services/ga";

function AdvancedFiltersContainer(props) {
  const { t } = useTranslation();
  const {
    dispatch: ReduxDispatcher,
    user,
    activeFilterObj,
    handleAdFiltersReplacement,
    filterType,
    activeDimension,
    allData,
  } = props;

  //Definint required reducers
  const [adFiltersStore, dispatchAdFiltersStore] = useReducer(
    useReducerLogger(advancedFiltersReducer),
    []
  );
  //Making a copy to compare with the updated one to check if the toasts need to be shown or not
  const adFiltersOriginal = useRef([]);

  //Defining required state
  const [showError, setShowError] = useState(false);

  //Defining required variables
  const sigviewUserType = getSigviewUserType();
  const globalFiltersAdFilterDropdownTypesFinal =
    globalFiltersAdFilterDropdownTypes[sigviewUserType];
  const activeDimDatatype = activeDimension?.dataType?.toLowerCase();
  //CONFIGURABLE FEATURE
  const availableAdFiltersFeatureIdList =
    config.hardCoded.adFiltersAllFeatureIds.filter(
      (featureKey) => user.uiFeatureList[featureKey]
    );
  const allFilterTypes = subsetAdFilterDropdownTypesBasedOnFeatureAvailability({
    allFilterTypes: globalFiltersAdFilterDropdownTypesFinal[activeDimDatatype],
    availableAdFiltersFeatureIdList,
  });
  const minMaxDates = {
    minDate: new Date(config.hardCoded.adFiltersCalendarMinDateEpoch), //need to convert to user system
    maxDate: new Date(parseInt(allData.dateRange.data.endDate)), //need to convert to user system
  };
  // const activeOrg = user?.reqMetadata?.organization?.toLowerCase();
  // const activeOrgFinal = activeOrg === "dnb" ? "dnb" : "others"; //hard coded (very very very poor)
  // const adFilterRulesData = adFiltersRules[activeDimDatatype][activeOrgFinal];
  // * CONFIGURABLE FEATURE
  const uiLimitsList = user.uiLimitsList;
  const uiFeatureList = user.uiFeatureList;
  const adFiltersRulesProps = {
    maxFileUploadRowCount: uiLimitsList.maxFileUploadRowCount,
    maxFileUploadSizeInMB: uiLimitsList.maxFileUploadSizeInMB,
    uiFeatureList,
  };
  const adFilterRulesData =
    getAdFiltersRules(adFiltersRulesProps)[activeDimDatatype];
  const newAdvancedFilters = addErrorInfoToAdvancedFilters(
    adFiltersStore,
    activeDimDatatype,
    minMaxDates
  );
  const oldNewAdFiltersEqualFlag = isEqual(
    removeIdFromAllRows(newAdvancedFilters),
    removeIdFromAllRows(adFiltersOriginal.current)
  );
  //If there's no change in the filters, DONE button will act like cancel or close
  //NOT THE BEST UX in my opinion
  const applyButtonText = oldNewAdFiltersEqualFlag ? "Done" : "Add Filters";

  //update adFiltersStore everytime activeFilterObj changes
  useEffect(() => {
    // if activeFilterObj exists
    if (activeFilterObj) {
      if (activeFilterObj.advancedFilters.length > 0) {
        let newAdvancedFilters = addErrorInfoToAdvancedFilters(
          activeFilterObj.advancedFilters,
          activeDimDatatype,
          minMaxDates
        );
        let payload = { newAdvancedFilters };
        dispatchAdFiltersStore(replaceAllAdvancedFilters(payload));
        adFiltersOriginal.current = newAdvancedFilters;
      } else {
        let newAdvancedFilters = addErrorInfoToAdvancedFilters(
          [getNewAdFilterRow(activeDimDatatype)],
          activeDimDatatype,
          minMaxDates
        );
        let payload = {
          newAdvancedFilters,
        };
        dispatchAdFiltersStore(replaceAllAdvancedFilters(payload));
        adFiltersOriginal.current = newAdvancedFilters;
      }
    } else {
      let newAdvancedFilters = addErrorInfoToAdvancedFilters(
        [getNewAdFilterRow(activeDimDatatype)],
        activeDimDatatype
      );
      let payload = {
        newAdvancedFilters,
      };
      dispatchAdFiltersStore(replaceAllAdvancedFilters(payload));
      adFiltersOriginal.current = newAdvancedFilters;
    }
  }, [activeFilterObj]);

  //Add errorInfo everytime adFiltersStoreChanges
  //NOT THE BEST WAY BUT CAN'T DO MUCH because timestamp validation requires all the values
  //whereas string validation can be done independently
  //Either we replicate the code and make 2 reducers (one for timestamp and other for string)
  //So that we can place the validation in the reducer for each row
  //Or we refactor the UI to make more re-usable components
  useEffect(() => {
    const newAdvancedFilters = addErrorInfoToAdvancedFilters(
      adFiltersStore,
      activeDimDatatype,
      minMaxDates
    );
    const payload = {
      newAdvancedFilters,
    };
    const equalFlag = isEqual(adFiltersStore, newAdvancedFilters);
    // Update only when the old and new are not equal otherwise it will result in an infinte loop
    if (!equalFlag) {
      dispatchAdFiltersStore(replaceAllAdvancedFilters(payload));
    }
  }, [adFiltersStore]);

  const handleAdvancedFiltersClose = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "AdvancedFilters",
      label: "Cancel",
    });

    //Check if there's a change in advanced filter values
    //If yes, show the dialog, else close the filters
    const flag = !isEqual(
      removeIdFromAllRows(adFiltersStore),
      removeIdFromAllRows(adFiltersOriginal.current)
    );
    if (flag) {
      const message = {
        title: config.dialogInfo.filtersClose.title,
        subtitle: config.dialogInfo.filtersClose.subtitle,
      };
      const handleNo = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
          })
        );
      };
      const handleYes = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
            message: { title: "", subtitle: "" },
          })
        );
        closeAdFilters();
      };
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: true,
          message,
          handleYes,
          handleNo,
        })
      );
    } else {
      closeAdFilters();
    }
  };

  const closeAdFilters = () => {
    //Close the advanced filters
    let newGlobalFiltersProps = {
      ...user.screen.globalFilters,
      isAdFiltersOpen: null, //advanced filters dialog open close
    };
    ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
  };

  const handleApplyAdvancedFilters = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "AdvancedFilters",
      label: "ApplyFilters",
    });

    //Check if the user has clicked Clear All which means he wants to remove all the filters
    //In that case we will have newAdvancedFilters similar to [getNewAdFilterRow(activeDimDatatype)]
    //If that's the case, pass empty array as the advanced filers and close the adFilters
    const typicalNewAdvancedFilters = addErrorInfoToAdvancedFilters(
      [getNewAdFilterRow(activeDimDatatype)],
      activeDimDatatype,
      minMaxDates
    );
    const adFiltersStoreErrorInfoAdded = addErrorInfoToAdvancedFilters(
      adFiltersStore,
      activeDimDatatype,
      minMaxDates
    );
    const hasUserClickedClearAll = isEqual(
      removeIdFromAllRows(adFiltersStoreErrorInfoAdded),
      removeIdFromAllRows(typicalNewAdvancedFilters)
    );
    const oldNewAdFiltersEqualFlag = isEqual(
      removeIdFromAllRows(adFiltersStoreErrorInfoAdded),
      removeIdFromAllRows(adFiltersOriginal.current)
    );
    const validFlag = validateAdvancedFilters(adFiltersStoreErrorInfoAdded);

    if (hasUserClickedClearAll) {
      handleAdFiltersReplacement([]);
      //Close the filters
      closeAdFilters();
    } else if (oldNewAdFiltersEqualFlag) {
      //Close the filters
      closeAdFilters();
    } else if (validFlag) {
      handleAdFiltersReplacement(adFiltersStore);
      //Close the filters
      closeAdFilters();
    } else {
      setShowError(true);
    }
  };

  const handleClearAll = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "AdvancedFilters",
      label: "ClearAll",
    });

    const payload = {
      newAdvancedFilters: [getNewAdFilterRow(activeDimDatatype)],
    };
    dispatchAdFiltersStore(replaceAllAdvancedFilters(payload));
    setShowError(false);
  };

  //Add event listener on keydown
  useEffect(() => {
    let keysPressed = {};

    const keydownFunc = (event) => {
      keysPressed[event.key] = true;
      //Prompt filters close if escape is clicked
      if (keysPressed["Escape"]) {
        handleAdvancedFiltersClose();
      }
    };

    const keyupFunc = (event) => {
      delete keysPressed[event.key];
    };

    window.addEventListener("keydown", keydownFunc);
    window.addEventListener("keyup", keyupFunc);
    return () => {
      window.removeEventListener("keydown", keydownFunc);
      window.removeEventListener("keyup", keyupFunc);
    };
  });

  return (
    <div className="advanced-filters-container">
      <div className="advanced-filters-title-container">
        <div className="left-panel">
          {/* <i
            className="material-icons-round advanced-filters-go-back-icon"
            onClick={handleAdvancedFiltersClose}
          >
            fast_rewind
          </i> */}
          <i
            className="material-icons-round advanced-filters-go-back-icon part-1"
            onClick={handleAdvancedFiltersClose}
          >
            arrow_back_ios
          </i>
          <i
            className="material-icons-round advanced-filters-go-back-icon part-2"
            onClick={handleAdvancedFiltersClose}
          >
            arrow_back_ios
          </i>
          <p className="advanced-filters-title">{t("Advance Filter")}</p>
          <SigviewHoverPopup
            title="Advanced Filter Rules"
            data={adFilterRulesData}
          >
            <i className="material-icons-round advanced-filter-info-icon">
              info
            </i>
          </SigviewHoverPopup>
        </div>
        <div className="right-panel">
          <i
            className="material-icons-round advanced-filter-close-icon"
            onClick={handleAdvancedFiltersClose}
          >
            close
          </i>
        </div>
      </div>
      <div className="advanced-filters-row-container">
        {/* <div className="advanced-filters-clear-all-container">
          <p
            className="advanced-filters-clear-all-title"
            onClick={() => {
              handleClearAll();
            }}
          >
            clear all
          </p>
        </div> */}
        {activeDimDatatype && (
          <AdvancedFilters
            adFiltersStore={adFiltersStore}
            dispatchAdFiltersStore={dispatchAdFiltersStore}
            showError={showError}
            filterType={filterType}
            allFilterTypes={allFilterTypes}
            activeDimDatatype={activeDimDatatype}
            allData={allData}
            user={user}
            activeDimension={activeDimension}
          />
        )}
      </div>
      <div className="advanced-filters-actions-bar-container">
        <section className="left-panel">
          <SigviewButton
            variant="outlined"
            onClick={handleClearAll}
            title="Clear All"
            customClassName="AdvanceFilterClearAll-GA"
          />
        </section>
        <section className="right-panel">
          <SigviewButton
            variant="outlined"
            onClick={handleAdvancedFiltersClose}
            title="Cancel"
            style={{ width: "50px", width: "max-content" }}
            customClassName="AdvanceCancel-GA"
          />
          <SigviewButton
            variant="contained"
            onClick={handleApplyAdvancedFilters}
            title={applyButtonText}
            customClassName="ApplyAdvancedFilters-GA"
            style={{
              margin: { left: "10px" },
              width: "95px",
              width: "max-content",
            }}
          />
        </section>
      </div>
    </div>
  );
}

AdvancedFiltersContainer.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
  allData: state.data,
});

export default connect(mapStateToProps)(AdvancedFiltersContainer);
