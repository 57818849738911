// * Import required libraies
import React, { useState, useEffect, memo, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles, Box, withStyles, Menu, MenuItem } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./WsDimTableUI.css";
import { useTranslation } from "react-i18next";

// * Import Custom Component
import SigviewIcon from "../../Common/SigviewIcon";
import SigviewTooltip from "../../Common/SigviewTooltip";

// * Import utils
import { config } from "../../../config/config";
import {
  makeUiRowDataFriendlyForWsDimTable,
  getWsDimTableColumns,
  formatDimValueWsDimTable,
} from "../../../utils/analyzeUtils";
import { getDimensionObjByOriginalID } from "../../../utils/plotlyUtils";

// * Import redux utils
// Actions
import {
  updateStandaloneWsFormDataSort,
  updateStandaloneWsTogglePercentCalList,
  updateWsFormDimensionTableData,
  updateStandaloneWsFiltersFromDimTable,
  updateGlobalFiltersDimensionFilters,
} from "../../../redux/actions";

// * Import services
import { masterTrackGaEvent } from "../../../services/ga";

// * Define required static styled components
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

// * Making styles
const useStyles = makeStyles((theme) => ({
  wsDimTableUiRoot: {
    overflow: "auto",
    boxSizing: "border-box",
    height: "calc(100% - 30px)",
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent !important",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: `${theme.palette.misc["secondaryColorLighter"]} !important`,
      },
    },
  },
  header1: {
    width: "5px",
  },
  header2: {
    width: "calc(100% - 25px)",
    textOverflow: "ellipsis",
  },
  header3: {
    width: "10px",
  },
  header4: {
    width: "10px",
  },

  mainContainer: {
    padding: "0px 22px",
    height: "30px",
    display: "flex",
    alignItems: "center",
  },
  table: {
    width: "0px !important",
    backgroundColor: "white",
  },
  tableMain: {
    // margin: " 50px 0px 20px 20px",
  },
  tableHead: {
    width: "100%",
  },
  tableHeadTitle: {
    // width: `calc(100% - 50px)`,
    boxSizing: "border-box",
  },
  tableHeadIcon: {
    // width: "10px !important",
  },

  tableRowHeader: {
    // paddingTop: "500px !important",
  },

  tableCellHeader: {
    // color: theme.palette.misc["secondaryColor"],
    // backgroundColor: "#CCD9E0",
    // borderRightStyle: "solid",
    // borderRightColor: "#dfdfdf",
    fontFamily: "Fira Sans",
    fontWeight: "500",
    fontSize: "12px !important",
    textAlign: "left",
    // padding: "5px",
    maxWidth: "200px !important",
    // maxWidth: "139px !important",
    minWidth: "70px !important",
    // width: "70px !important",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textTransform: "uppercase",
    borderBottom: "white",
    backgroundColor: "white !important",
    color: theme.palette.misc["secondaryColor"],
  },

  sticky: {
    position: "sticky",
    left: 0,
    // paddingLeft: "11px",
    "& span": {
      justifyContent: "flex-start !important",
    },
  },

  tableCell: {
    // width: "70px !important",
    maxWidth: "139px !important",
    minWidth: "70px !important",
    // borderRightStyle: "solid",
    // borderRightColor: "#dfdfdf",
    borderBottom: "white",
    fontFamily: "Fira Sans",
    fontSize: "11px !important",
    textAlign: "left",
    padding: "3px 11px 3px 11px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "pointer",
    color: theme.palette.misc["secondaryColorLight"],
    backgroundColor: "white",
    textTransform: "uppercase",
  },
  rowSelectedHighlightedClassName: {
    backgroundColor: theme.palette.misc["mainContentBgColor"],
  },
  tableData: {},
  elementActive: {
    // color: theme.palette.misc["primaryColor"],
    cursor: "pointer",
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  elementDisabled: {
    // color: theme.palette.misc["secondaryColor"],
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  tableColumns: {
    // border: "10px solid white",
    // background: "grey",
  },
  totalRow: {
    backgroundColor: "white !important",
  },
  popoverContainer: {
    fontFamily: "Fira Sans",
    color: theme.palette.misc["secondaryColorLight"],
    hoverColor: "blue",
    textAlign: "left",
    padding: "3px 3px 3px 3px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "pointer",
    fontSize: "12px !important",
    "&:hover": {
      backgroundColor: theme.palette.misc["secondaryColorLighter"],
    },
  },
}));

// * Define required helper components
const WsDimTableHeader = (props = {}) => {
  const { t } = useTranslation();
  // * Destructure props
  const {
    header = {},
    index = 0,
    selections = {},
    themeColors = {},
    id = "",
    classes = {},
    ReduxDispatcher,
  } = props;
  const { percentCalList } = selections;

  // * Define required states
  const [mouseIn, setMouseIn] = useState(false);

  // * Define event handlers
  const handleMouseEnter = () => setMouseIn(true);
  const handleMouseLeave = () => setMouseIn(false);
  const handleDimTableHeaderSort = (header, index) => {
    const sortType = index === 0 ? "_dimension" : header.id;
    const sortTypeId = header.accessor.includes("_trueDelta")
      ? "id_trueDelta"
      : header.accessor.includes("_deltaPercentage")
      ? "id_deltaPercentage"
      : "id_only";
    const value = {
      sortType: {
        id: sortType,
      },
      sortTypeId: {
        id: sortTypeId,
      },
      sortTypeValue: selections.orderBy === "asc" ? "desc" : "asc",
      dimId: id,
    };

    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "TableSort",
      label: selections.orderBy === "asc" ? "desc" : "asc",
    });

    const payload = { value };
    const action = updateStandaloneWsFormDataSort(payload);
    ReduxDispatcher(action);
  };
  const handlePercentageTotal = (header, flag) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "Table%ofTotal",
      label: flag ? "Show" : "Hide",
    });

    const payload = { metricId: header.id, flag, dimId: id };
    const action = updateStandaloneWsTogglePercentCalList(payload);
    ReduxDispatcher(action);
  };

  // * Define required variables
  let className = classes.tableCellHeader;
  if (index === 0) className += ` ${classes.sticky}`;
  const style = {
    zIndex: index === 0 ? 10 : 0,
  };
  var isSortedOnThisColumn = false;
  if (selections.orderById === "_dimension" && index === 0) {
    isSortedOnThisColumn = true;
  } else {
    if (selections.orderByType === "id_trueDelta") {
      if (
        selections.orderById === header.id &&
        header.accessor.endsWith("_trueDelta")
      ) {
        isSortedOnThisColumn = true;
      }
    } else if (selections.orderByType === "id_deltaPercentage") {
      if (
        selections.orderById === header.id &&
        header.accessor.endsWith("_deltaPercentage")
      ) {
        isSortedOnThisColumn = true;
      }
    } else {
      if (
        selections.orderById === header.id &&
        !header.accessor.includes("_trueDelta") &&
        !header.accessor.includes("_deltaPercentage") &&
        !header.accessor.includes("_percent")
      ) {
        isSortedOnThisColumn = true;
      }
    }
  }
  const arrowType =
    selections.orderById === header.id && selections.orderBy === "asc"
      ? "arrow_drop_up"
      : "arrow_drop_down";
  let displayValue = index === 0 ? "Name" : header.name;
  let percentCalListIds = percentCalList.map((row) => row._id);
  const expandTotalIconFlag =
    header.type === "metric" &&
    mouseIn &&
    !percentCalListIds.includes(header.id);
  const collapseTotalIconFlag = header.type === "percentTotal" && mouseIn;

  return (
    <Box
      className={`${className} tableHeader`}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span
        style={{
          display: "flex",
          //justifyContent: "space-between",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <span
          className={classes.tableHeadTitle}
          title={t(displayValue)}
          style={{
            // width: "600px",
            // display: "inline-block",
            // width: true ? "max-content !important" : "60px",
            textAlign: "left",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginRight: "3px",
            fontSize: "11px",
            color: isSortedOnThisColumn
              ? themeColors["primaryColor"]
              : themeColors["secondaryColor"],
            paddingLeft: displayValue === "Change" ? "6px" : "12",
            fontWeight: "500",
          }}
        >
          {t(displayValue)}
        </span>

        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
          // style={{
          //   maxWidth: "10px",
          //   minWidth: "10px",
          // }}
          >
            {expandTotalIconFlag && (
              <SigviewTooltip title="Show % of Total" placement="bottom">
                <Box>
                  <SigviewIcon
                    iconName={"keyboard_double_arrow_right"}
                    style={{
                      fontSize: "12px !important",
                      color: themeColors["secondaryColor"],
                      hoverColor: themeColors["primaryColor"],
                      cursor: "pointer",
                    }}
                    className={`material-icons-round AnalyzeTablePercentOfTotalShow-GA`}
                    onClick={() => handlePercentageTotal(header, true)}
                  />
                </Box>
              </SigviewTooltip>
            )}
            {collapseTotalIconFlag && (
              <SigviewTooltip title="Hide % of Total" placement="bottom">
                <Box>
                  <SigviewIcon
                    iconName={"keyboard_double_arrow_left"}
                    style={{
                      fontSize: "12px !important",
                      color: themeColors["secondaryColor"],
                      hoverColor: themeColors["primaryColor"],
                      cursor: "pointer",
                    }}
                    className={`material-icons-round AnalyzeTablePercentOfTotalHide-GA`}
                    onClick={() => handlePercentageTotal(header, false)}
                  />
                </Box>
              </SigviewTooltip>
            )}
          </span>

          <span
          // style={{
          //   maxWidth: "10px",
          //   minWidth: "10px",
          // }}
          >
            {isSortedOnThisColumn && header.type !== "percentTotal" && (
              <SigviewTooltip title="Toggle Sort" placement="bottom">
                <Box>
                  <SigviewIcon
                    iconName={arrowType}
                    className={`material-icons-round analyzeTable-${selections.orderBy}-GA`}
                    style={{
                      fontSize: "20px !important",
                      color: themeColors["primaryColor"],
                      hoverColor: themeColors["primaryColor"],
                      cursor: "pointer",
                    }}
                    onClick={() => handleDimTableHeaderSort(header, index)}
                  />
                </Box>
              </SigviewTooltip>
            )}
            {!isSortedOnThisColumn &&
              mouseIn &&
              header.type !== "percentTotal" && (
                <SigviewTooltip title="Toggle Sort" placement="bottom">
                  <Box>
                    <SigviewIcon
                      iconName={arrowType}
                      style={{
                        fontSize: "20px !important",
                        color: themeColors["primaryColor"],
                        hoverColor: themeColors["primaryColor"],
                        cursor: "pointer",
                      }}
                      className={`material-icons-round analyzeTable-${selections.orderBy}-GA`}
                      onClick={() => handleDimTableHeaderSort(header, index)}
                    />
                  </Box>
                </SigviewTooltip>
              )}
          </span>
        </span>
      </span>
    </Box>
  );
};

function WsDimTableUI(props) {
  // * Destructure Props
  const {
    // REDUX PROPS
    dispatch: ReduxDispatcher,
    // PARENT PROPS
    state,
    wsForm,
    memoizedStringedChildrenProps = "",
  } = props;
  const childrenProps = JSON.parse(memoizedStringedChildrenProps);
  const {
    id = "",
    data,
    selections = {},
    filterAppliedOnDimTable,
    filterAppliedOnDimTableType,
    themeColors,
  } = childrenProps;
  const { result = { dataFromQE: [], extraData: {} } } = data;
  const { dataFromQE = [] } = result;
  const { timeFilters, dimensionsList, metricsList, percentCalList } =
    selections;
  console.log("%c WsDimTableUI - Re-Rendered", "color:blue; font-size: 20px");

  // * Defining required state
  const [tableData, setTableData] = useState({ headers: [], data: [] }); // { headers: [], rows: [] }
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState();

  // * Defining required event handlers/util functions
  const handleError = (message) => {
    const dimData = {
      status: "error",
      message: message || config.hardCoded.uiErrorMessage,
      result: { dataFromQE: [], extraData: {} },
    };
    const payload = { dimId: id, dimData };
    const action = updateWsFormDimensionTableData(payload);
    ReduxDispatcher(action);
  };
  const handleDataRowClick = (event, value) => {
    // console.log("event", event);
    // console.log("value", value);
    // console.log("event.target", event.target);
    // console.log("event.type", event.type);
    // console.log("event.target.className", event.target.className);
    // console.log(
    //   "event.target.className.includes",
    //   event.target.className.includes("NO-CSS-DIM-WS-ROW")
    // );
    if (
      event.type === "click" &&
      event.target.className.includes("NO-CSS-DIM-WS-ROW")
    ) {
      event.preventDefault();

      // Google Analytics Event - Master
      masterTrackGaEvent({
        category: "Workspace",
        action: "TableZoomedViewFiltersCount",
        label: "1",
      });

      const payload = { dimId: id, value };
      const action = updateStandaloneWsFiltersFromDimTable(payload);
      ReduxDispatcher(action);
    }
  };
  const handleDataRowContextMenu = (...args) => {
    const [event, copyValue = null, copyText = null] = args;
    if (event.type === "contextmenu") {
      event.preventDefault();
      setAnchorEl({
        currentTarget: event.currentTarget,
        copyText: copyText,
        copyValue: copyValue,
      });
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCopyText = (event, condition) => {
    // ! Navigator is available only on a secure connection
    // ! It won't work locally
    // ! To test locally, use ngrok which creates a secure tunnel between local and internet
    if (window.navigator.clipboard) {
      navigator.clipboard.writeText(anchorEl[condition]);
    } else {
      console.error("UI ERROR");
      console.error("window.navigator.clipboard not available");
    }

    setAnchorEl(null);
  };

  const handleOnRowClicked = (e) => {
    const payload = {
      dimId: id,
      value: e.data[selections.dimensionsList[0].dimID].actualValue,
    };
    const action = updateStandaloneWsFiltersFromDimTable(payload);
    ReduxDispatcher(action);
    const newDimObj = getDimensionObjByOriginalID(
      payload.dimId,
      wsForm.utils.value.plotlyDimensions
    );

    // Sample dimensionFilters row
    const newFilterRow = {
      id: newDimObj._id,
      values: [payload.value],
      metadata: newDimObj,
      filterType: "include",
      advancedFilters: [],
      valid: true,
    };

    let newDimFiltersVal = wsForm.dimensionFilters.value.filter(
      (row) => row.id !== newDimObj._id
    );
    newDimFiltersVal = [...newDimFiltersVal, newFilterRow];

    let newState = {
      ...state,
      dimensionFilters: {
        ...wsForm.dimensionFilters,
        value: newDimFiltersVal,
      },
    };

    const payload_Filters = {
      key: "dimensionFilters",
      value: newState.dimensionFilters.value,
    };

    var action_globalDimFilters =
      updateGlobalFiltersDimensionFilters(payload_Filters);
    ReduxDispatcher(action_globalDimFilters);
  };

  // * Defining required side effects
  useEffect(() => {
    try {
      const newColumns = getWsDimTableColumns(
        dimensionsList,
        metricsList,
        timeFilters,
        percentCalList
      );
      const makeUiRowDataFriendlyForWsDimTableProps = {
        getDataResponse: dataFromQE,
        columns: newColumns[0],
      };
      const formattedData = makeUiRowDataFriendlyForWsDimTable(
        makeUiRowDataFriendlyForWsDimTableProps
      );
      setTableData({ headers: newColumns[0], data: formattedData });
    } catch (err) {
      console.error("UI ERROR in WsDimTableUI.js", err);
      handleError(config.hardCoded.uiErrorMessage);
    }
  }, [
    data.status,
    selections.timeFilters.comparisonType, // This will reload the table UI; no API call needed for this as data is already present in dataQE
    selections.timeFilters.isComparisonOn, // This will reload the table UI in case ONLY the comparison is turned off; in case it's turned on, API will be triggered
  ]);

  useEffect(() => {
    let mappedFilteredData = tableData.data.map((el) => {
      let mappedData = {};

      tableData.headers.map((cell, index) => {
        const formatDimValueWsDimTableProps = {
          row: el,
          selections,
          cell,
        };
        let dimValueResponse = formatDimValueWsDimTable(
          formatDimValueWsDimTableProps
        );

        mappedData[cell.accessor] = dimValueResponse;
      });

      return mappedData;
    });

    setRowData(mappedFilteredData);
  }, [tableData.data]);

  // * Define required static variables
  const classes = useStyles();
  const tableBodyClassName = "sigview-styled-scroller-thin";

  // Can be used later for critical enhancements hence commented and not removed
  // const defaultColDef = useMemo(
  //   () => ({
  //     resizable: false,
  //     sortable: false,
  //   }),
  //   []
  // );

  // Passing refereance to Header Component
  const components = useMemo(() => {
    return {
      agColumnHeader: WsDimTableHeader,
    };
  }, []);

  // AgGrid column renderer
  const columnDefs = useMemo(() => {
    // Styles
    const box1 = { cursor: "pointer" };
    const box2 = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      paddingLeft: "20px",
      cursor: "pointer",
    };
    const box3 = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      color: "var(--negChangeColor)",
      cursor: "pointer",
      justifyContent: "center",
    };
    const box4 = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      color: "var(--posChangeColor)",
      cursor: "pointer",
      justifyContent: "center",
    };
    const box5 = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      paddingLeft: "20px",
      cursor: "pointer",
    };
    const box6 = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      color: "var(--negChangeColor)",
      cursor: "pointer",
    };
    const box7 = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      color: "var(--posChangeColor)",
      cursor: "pointer",
    };
    const box8 = { cursor: "pointer" };

    const cellStyle = {
      display: "block",
      //width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textAlign: "center",
      //textOverflow: "ellipsis",
    };
    const cellDimStyle = {
      display: "block",
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    };

    return tableData.headers.map((el, index) => {
      if (index === 0) {
        return {
          field: el.accessor,
          pinned: "left",
          width: 130,
          headerName: el.name,
          minWidth: 85,
          maxWidth: 250,
          resizable: true,
          lockPosition: true,
          suppressMovable: true,

          headerComponentParams: {
            // keyHeader: `${el.id}_${index}`,
            header: el,
            index: index,
            selections: selections,
            themeColors: themeColors,
            id: id,
            classes: classes,
            ReduxDispatcher: ReduxDispatcher,
          },

          // tooltipField: el.accessor,
          // tooltipComponentParams: { color: "#ececec" },
          cellRenderer: (p) => {
            return (
              <div
                onContextMenu={(event) =>
                  handleDataRowContextMenu(
                    event,
                    p.value.actualValue,
                    p.value.displayValue
                  )
                }
                style={box1}
              >
                <span style={cellDimStyle} title={p.value.actualValue}>
                  {p.value.displayValue}
                </span>
              </div>
            );
          },
        };
      }

      if (el.name === "Change" || el.name === "% Change") {
        return {
          field: el.accessor,
          headerName: el.name,
          width: 100,
          minWidth: 85,
          maxWidth: 250,
          resizable: true,
          lockPosition: true,
          suppressMovable: true,

          headerComponentParams: {
            // keyHeader: `${el.id}_${index}`,
            header: el,
            index: index,
            selections: selections,
            themeColors: themeColors,
            id: id,
            classes: classes,
            ReduxDispatcher: ReduxDispatcher,
          },

          cellRenderer: (p) => {
            if (p.value === undefined) {
              return <></>;
            } else {
              let slicedValue = p.value.actualValue.slice("")[0];

              if (["0", "0.0", "0.00"].includes(p.value.actualValue)) {
                return (
                  <div style={box2}>
                    <span
                      style={cellStyle}
                      title={p.value.actualValue}
                      onContextMenu={(event) =>
                        handleDataRowContextMenu(
                          event,
                          p.value.actualValue,
                          p.value.displayValue
                        )
                      }
                    >
                      {p.value.displayValue}
                    </span>
                  </div>
                );
              }

              if (slicedValue === "-") {
                return (
                  <div style={box3}>
                    <span
                      class="material-icons-round"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>{" "}
                    <span
                      style={cellStyle}
                      title={p.value.actualValue}
                      onContextMenu={(event) =>
                        handleDataRowContextMenu(
                          event,
                          p.value.actualValue,
                          p.value.displayValue
                        )
                      }
                    >
                      {p.value.displayValue}
                    </span>
                  </div>
                );
              } else {
                return (
                  <div style={box4}>
                    <span
                      class="material-icons-round"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_up
                    </span>{" "}
                    <span
                      style={cellStyle}
                      title={p.value.actualValue}
                      onContextMenu={(event) =>
                        handleDataRowContextMenu(
                          event,
                          p.value.actualValue,
                          p.value.displayValue
                        )
                      }
                    >
                      {p.value.displayValue}
                    </span>
                  </div>
                );
              }
            }
          },
        };
      }

      return {
        field: el.accessor,
        headerName: el.name,
        width: 100,
        minWidth: 85,
        maxWidth: 250,
        resizable: true,
        lockPosition: true,
        suppressMovable: true,

        headerComponentParams: {
          // keyHeader: `${el.id}_${index}`,
          header: el,
          index: index,
          selections: selections,
          themeColors: themeColors,
          id: id,
          classes: classes,
          ReduxDispatcher: ReduxDispatcher,
        },

        cellRenderer: (p) => {
          return (
            <div
              onContextMenu={(event) =>
                handleDataRowContextMenu(
                  event,
                  p.value.actualValue,
                  p.value.displayValue
                )
              }
              style={box8}
            >
              <span style={cellStyle} title={p.value.actualValue}>
                {p.value.displayValue}
              </span>
            </div>
          );
        },
      };
    });
  }, [tableData]);

  // * DEBUGGER
  // console.groupCollapsed("WsDimTableUI");
  // console.log("props", props);
  // console.log("tableData", tableData);
  // console.groupEnd();

  // set background colour on filter applied rows

  const dimensionFiltersValue = useSelector(
    (state) => state.standaloneWs.dimensionFilters.value
  ).map((el) => el.id);

  const rowStyle = { background: "#FFFFFF" };

  const getRowStyle = (params) => {
    if (dimensionFiltersValue.includes(id)) {
      return { background: themeColors["primaryColorLightest"] };
    }
    if (params.node.rowIndex % 2 === 0) {
      // return { background: themeColors["secondaryColorLightest"] };
      return { background: "#f9f9fa" };
    }
  };
  return (
    <>
      <AgGridReact
        className="ag-theme-alpine"
        animateRows="true"
        rowBuffer={1}
        // suppressDragLeaveHidesColumns={true}
        columnDefs={columnDefs}
        components={components}
        suppressRowHoverHighlight={false}
        // defaultColDef={defaultColDef}
        // enableRangeSelection="true"
        rowData={rowData}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        rowSelection="multiple"
        suppressRowClickSelection="true"
        rowHeight={25}
        headerHeight={30}
        onRowClicked={(e) => handleOnRowClicked(e)}
      />
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl?.currentTarget}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.popoverContainer}
          onClick={(event) => {
            handleCopyText(event, "copyText");
          }}
        >
          Copy text
        </MenuItem>
        <MenuItem
          className={classes.popoverContainer}
          onClick={(event) => {
            handleCopyText(event, "copyValue");
          }}
        >
          Copy value
        </MenuItem>
      </StyledMenu>
    </>
  );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(memo(WsDimTableUI));
