import C from "../constants";

// DEFINING REDUCERS
function stepReducer(state, action) {
  const { type = "", payload = {} } = action;
  const { id = "", valid = {}, replacedState = {} } = payload;

  let newState = {};
  switch (type) {
    case C.CHANGE_STEP:
      newState = { ...state };
      if (state.id === id)
        newState = { ...state, visited: { flag: true, message: "" } };
      break;
    case C.UPDATE_STEP_VALIDITY:
      newState = { ...state };
      if (state.id === id) newState = { ...state, valid };
      break;
    case C.REPLACE_STATE:
      newState = { ...replacedState };
      break;
    default:
      console.group("UI ERROR -> ", "UNIDENTIFIED TYPE");
      console.log("state", state);
      console.log("action", action);
      console.groupEnd();
      newState = { ...state };
  }
  return newState;
}

function stepsReducer(state, action) {
  const { type = "", payload = {} } = action;
  const {
    id = "",
    replacedState = {},
    form = {},
    formValidator = () => {},
  } = payload;

  let newState = {};
  let newSteps = [];
  let stepReducerAction = {};
  switch (type) {
    case C.CHANGE_STEP:
      // VALIDATION LOGIC
      const currStepValid = formValidator(state, payload);
      if (currStepValid.flag) {
        // UPDATE VISITED FLAG FOR CLICKED STEP
        newSteps = state.steps.map((step) => stepReducer(step, action));
        // UPDATE VALIDITY FOR CURRENT/ACTIVE STEP
        //false to true
        stepReducerAction = {
          type: C.UPDATE_STEP_VALIDITY,
          valid: currStepValid,
        };
        newSteps = newSteps.map((step) => stepReducer(step, stepReducerAction));
        newState = {
          ...state,
          activeStep: id,
          steps: newSteps,
        };
      } else {
        // UPDATE VALIDITY FOR CURRENT/ACTIVE STEP
        //True to false
        stepReducerAction = {
          type: C.UPDATE_STEP_VALIDITY,
          valid: currStepValid,
        };
        newSteps = state.steps.map((step) =>
          stepReducer(step, stepReducerAction)
        );
        newState = {
          ...state,
          steps: newSteps,
        };
      }
      break;
    case C.REPLACE_STATE:
      newState = { ...replacedState };
      break;
    default:
      console.group("UI ERROR -> ", "UNIDENTIFIED TYPE");
      console.log("state", state);
      console.log("action", action);
      console.groupEnd();
      newState = { ...state };
  }
  return newState;
}

export default stepsReducer;
