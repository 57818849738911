import isEqual from "lodash.isequal";
import moment from "moment";
import CryptoJS from "crypto-js";

const mapping = {
  id: "id",
  name: "Name",
  lastModifiedOn: "Last Modified On",
  lastModifiedBy: "Last Modified By",
  storageName: "Storage Name",
  bucketName: "Bucket Name",
  bucketType: "Storage Type",
  registeredOn: "Registered On",
  registeredBy: "Registered By",
};
function unWrapperBucketHeader(bucketData) {
  if (bucketData.length === 0) return [];
  let bucket = bucketData;
  let bucketKey = Object.keys(bucket[0]);

  let bucketKeyObj = bucketKey.map((row) => {
    if (row !== "_id" && row !== "organization" && row !== "instance") {
      return { id: row, name: row, label: mapping[row] };
    }
  });

  let bucketKeyObjFiltered = bucketKeyObj.filter((row) => row !== undefined);

  return bucketKeyObjFiltered;
}

function unWrapperBucketBody(bucketData) {
  return bucketData.map((row) => ({
    ...row,
    registeredOn: moment(Number(row.registeredOn)).format("MMMM Do YYYY"),
    lastModifiedOn:
      row.lastModifiedOn === "0"
        ? "Inactive User"
        : moment(Number(row.lastModifiedOn)).format("MMMM Do YYYY"),
  }));
}

function unwrapperBucket(data) {
  const bucketType = Object.keys(data).filter((el) => el !== "_id")[0];

  let unwrappedData = {};

  if (bucketType === "s3")
    unwrappedData = {
      id: { message: "", value: data._id, status: "valid" },
      name: {
        message: "",
        value: data[bucketType].bucketName,
        status: "valid",
      },
      storageName: {
        message: "",
        value: data[bucketType].storageName,
        status: "valid",
      },
      type: {
        message: "",
        value: bucketType,
        status: "valid",
      },
      path: {
        message: "",
        value: data[bucketType].bucketPath,
        status: "valid",
      },
      accessKey: {
        message: "",
        value: decryptor(data[bucketType].accessKey),
        status: "valid",
      },
      secretKey: {
        message: "",
        value: decryptor(data[bucketType].secretKey),
        status: "valid",
      },
      region: { message: "", value: data[bucketType].region, status: "valid" },
      emailId: { message: "", value: "", status: null },
      privateId: { message: "", value: "", status: null },
      privateKey: { message: "", value: "", status: null },
      clientId: { message: "", value: "", status: null },
      actualPayload: { message: "", value: data, status: null },
    };
  if (bucketType === "gcs")
    unwrappedData = {
      id: { message: "", value: data._id, status: "valid" },
      name: {
        message: "",
        value: data[bucketType].bucketName,
        status: "valid",
      },
      storageName: {
        message: "",
        value: data[bucketType].storageName,
        status: "valid",
      },
      type: {
        message: "",
        value: bucketType,
        status: "valid",
      },
      path: {
        message: "",
        value: data[bucketType].bucketPath,
        status: "valid",
      },
      accessKey: {
        message: "",
        value: "",
        status: null,
      },
      secretKey: {
        message: "",
        value: "",
        status: null,
      },
      region: { message: "", value: "", status: null },
      emailId: {
        message: "",
        value: decryptor(data[bucketType].emailId),
        status: "valid",
      },
      privateId: {
        message: "",
        value:
          data[bucketType].privateId?.length > 0
            ? decryptor(data[bucketType].privateId)
            : "",
        status: "valid",
      },
      privateKey: {
        message: "",
        value: decryptor(data[bucketType].privateKey),
        status: "valid",
      },
      clientId: {
        message: "",
        value:
          data[bucketType].clientId?.length > 0
            ? decryptor(data[bucketType].clientId)
            : "",
        status: "valid",
      },
      actualPayload: { message: "", value: data, status: "valid" },
    };

  return unwrappedData;
}

const initialRegisterBucketState = {
  id: { message: "", value: "", status: null },
  name: { message: "", value: "", status: null },
  storageName: { message: "", value: "", status: null },
  type: { message: "", value: "", status: null },
  path: { message: "", value: "", status: null },
  accessKey: { message: "", value: "", status: null },
  secretKey: { message: "", value: "", status: null },
  region: { message: "", value: "", status: null },
  emailId: { message: "", value: "", status: null },
  privateId: { message: "", value: "", status: null },
  privateKey: { message: "", value: "", status: null },
  clientId: { message: "", value: "", status: null },
  actualPayload: { message: "", value: "", status: null },
};

const getRemovedSlashValue = (value) => {
  let modifiedValue = value.split("");

  if (modifiedValue[0] === "/") modifiedValue.shift();
  if (modifiedValue[modifiedValue.length - 1] === "/") modifiedValue.pop();

  let finalValue = modifiedValue.join("");

  console.log("modifiedValue", modifiedValue, finalValue);

  return finalValue;
};

const getBucketBreadcrumbsData = (path) =>
  path === "registerBucket"
    ? [
        { id: "manageBucket", name: "Manage Storage", disabled: false },
        { id: "registerBucket", name: "Register Storage", disabled: false },
      ]
    : path === "updateBucket"
    ? [
        { id: "manageBucket", name: "Manage Storage", disabled: false },
        { id: "updateBucket", name: "Update Storage", disabled: false },
      ]
    : [{ id: "manageBucket", name: "Manage Storage", disabled: false }];

const unwrapperBucketState = (state) => {
  if (state.type.value === "s3") {
    return {
      s3: {
        secretKey: encryptor(state.secretKey.value),
        accessKey: encryptor(state.accessKey.value),
        region: state.region.value,
        bucketName: state.name.value,
        storageName: state.storageName.value,
        bucketPath: state.path.value,
      },
    };
  } else if (state.type.value === "gcs") {
    return {
      gcs: {
        emailId: encryptor(state.emailId.value),
        privateKey: encryptor(state.privateKey.value),
        bucketName: state.name.value,
        storageName: state.storageName.value,
        bucketPath: state.path.value,
        privateId:
          state.privateId.value?.length > 0
            ? encryptor(state.privateId.value)
            : undefined,
        clientId:
          state.clientId.value?.length > 0
            ? encryptor(state.clientId.value)
            : undefined,
      },
    };
  }
};
const wrapperBucketState = (state, user) => {
  if (state.type.value === "s3") {
    return {
      _id: state.id?.value,
      s3: {
        secretKey: encryptor(state.secretKey.value),
        accessKey: encryptor(state.accessKey.value),
        region: state.region.value,
        bucketName: state.name.value,
        storageName: state.storageName.value,
        bucketPath: state.path.value,
        lastModifiedBy: user?.reqMetadata?.email,
        lastModifiedOn: Date.now(),
      },
    };
  } else if (state.type.value === "gcs") {
    return {
      _id: state.id?.value,
      gcs: {
        emailId: encryptor(state.emailId.value),
        privateKey: encryptor(state.privateKey.value),
        bucketName: state.name.value,
        storageName: state.storageName.value,
        bucketPath: state.path.value,
        privateId:
          state.privateId.value?.length > 0
            ? encryptor(state.privateId.value)
            : undefined,
        clientId:
          state.clientId.value?.length > 0
            ? encryptor(state.clientId.value)
            : undefined,
        lastModifiedBy: user?.reqMetadata?.email,
        lastModifiedOn: Date.now(),
      },
    };
  }
};

const autoValidate = (value) => {
  return {
    message: "",
    status: "valid",
  };
};
const validateName = (value) => {
  if (value === "")
    return {
      message: "Name should not be empty",
      status: "invalid",
    };

  if (value.length < 3)
    return {
      message: "Name should be of at least three characters",
      status: "invalid",
    };

  // if (value.length > 50)
  //   return {
  //     message: "Name should not be more than 50 characters",
  //     status: "invalid",
  //   };

  return {
    message: "",
    status: "valid",
  };
};
const validateStorageName = (value) => {
  if (value === "")
    return {
      message: "Storage name should not be empty",
      status: "invalid",
    };

  if (value.length < 3)
    return {
      message: "Storage name should be of at least three characters",
      status: "invalid",
    };

  if (value.length > 50)
    return {
      message: "Storage name should not be more than 50 characters",
      status: "invalid",
    };

  return {
    message: "",
    status: "valid",
  };
};
const isEmptyString = (value) => {
  if (value === "" || value === undefined) {
    return { message: "This field should not be empty", status: "invalid" };
  }

  return {
    message: "",
    status: "valid",
  };
};

const validatePath = (value) => {
  if (value === "" || value === undefined) {
    return { message: "This field should not be empty", status: "invalid" };
  }
  let modifiedValue = value.split("");

  if (modifiedValue[0] === "/") {
    return {
      message: "There should not be forward slash (/) at the beginning",
      status: "invalid",
    };
  }
  if (modifiedValue[modifiedValue.length - 1] === "/") {
    return {
      message: "There should not be forward slash (/) at the end",
      status: "invalid",
    };
  }

  return {
    message: "",
    status: "valid",
  };
};

function validateEmail(email) {
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let isValid = re.test(email);

  if (isValid) {
    if (email.split("").filter((el) => el === "@").length > 1)
      return {
        status: "invalid",
        message: "there should be only one @",
      };
    else
      return {
        status: "valid",
        message: "",
      };
  } else {
    return {
      status: "invalid",
      message: "email is not valid",
    };
  }
}

const validateBucketForm = (state) => {
  const validatedState = {
    ...state,
    id: {
      value: state.id.value,
      ...autoValidate(state.id.value),
    },
    name: {
      value: state.name.value,
      ...validateName(state.name.value),
    },
    storageName: {
      value: state.storageName.value,
      ...validateStorageName(state.storageName.value),
    },
    type: {
      value: state.type.value,
      ...isEmptyString(state.type.value),
    },
    path: {
      value: state.path.value,
      ...validatePath(state.path.value),
    },
    accessKey: {
      value: state.accessKey.value,
      ...isEmptyString(state.accessKey.value),
    },
    secretKey: {
      value: state.secretKey.value,
      ...isEmptyString(state.secretKey.value),
    },
    region: { value: state.region.value, ...isEmptyString(state.region.value) },
    emailId: {
      value: state.emailId.value,
      ...validateEmail(state.emailId.value),
    },
    privateId: {
      value: state.privateId.value,
      ...isEmptyString(state.privateId.value),
    },
    privateKey: {
      value: state.privateKey.value,
      ...isEmptyString(state.privateKey.value),
    },
    clientId: {
      value: state.clientId.value,
      ...isEmptyString(state.clientId.value),
    },
    actualPayload: {
      value: state.actualPayload.value,
      ...autoValidate(state.type.value),
    },
  };

  return validatedState;
};

const gcsValidationKeys = [
  "name",
  "storageName",
  "emailId",
  "path",
  "privateKey",
];
const s3ValidationKeys = [
  "name",
  "storageName",
  "path",
  "accessKey",
  "secretKey",
  "region",
];

const isSelectionInvalid = (selection) => {
  if (selection.type.value === "s3") {
    for (const [key, value] of Object.entries(selection).filter((el) =>
      s3ValidationKeys.includes(el[0])
    )) {
      if (value.status === "invalid") {
        return {
          status: value.status,
          message: value.message,
        };
      }
    }
    return {
      status: "valid",
      message: "",
    };
  } else if (selection.type.value === "gcs") {
    for (const [key, value] of Object.entries(selection).filter((el) =>
      gcsValidationKeys.includes(el[0])
    )) {
      if (value.status === "invalid") {
        return {
          status: value.status,
          message: value.message,
        };
      }
    }
    return {
      status: "valid",
      message: "",
    };
  } else {
    return {
      status: "invalid",
      message: "",
    };
  }
};

const gcsComparatorKeys = [
  "name",
  "storageName",
  "type",
  "path",
  "emailId",
  "privateKey",
  "privateid",
  "clientid",
];
const s3ComparatorKeys = [
  "name",
  "storageName",
  "path",
  "type",
  "accessKey",
  "secretKey",
  "region",
];

function areSelectionsEqual(selection, referenceSelection) {
  console.log("selection, referenceSelection", selection, referenceSelection);

  if (selection.type.value === "s3") {
    return !isEqual(
      Object.entries(selection).filter((el) =>
        s3ComparatorKeys.includes(el[0])
      ),
      Object.entries(referenceSelection).filter((el) =>
        s3ComparatorKeys.includes(el[0])
      )
    );
  } else if (selection.type.value === "gcs") {
    return !isEqual(
      Object.entries(selection).filter((el) =>
        gcsComparatorKeys.includes(el[0])
      ),
      Object.entries(referenceSelection).filter((el) =>
        gcsComparatorKeys.includes(el[0])
      )
    );
  } else return false;
}

// Secret Key(HARDCODED)
let key = "cDJKCBzskjdRvbdf";
key = CryptoJS.enc.Utf8.parse(key);

let iv = "1234567890123456";
// iv = CryptoJS.enc.Utf8.parse(iv);
iv = CryptoJS.enc.Hex.parse("00000000000000000000000000000000");

const encryptor = (word) => {
  let encrypted = CryptoJS.AES.encrypt(word, key, { iv: iv });
  encrypted = encrypted.toString();
  return encrypted;
};

const decryptor = (phrase) => {
  let decrypted = CryptoJS.AES.decrypt(phrase, key, { iv: iv });
  decrypted = decrypted.toString(CryptoJS.enc.Utf8);

  return decrypted;
};

export {
  unWrapperBucketHeader,
  unWrapperBucketBody,
  initialRegisterBucketState,
  getBucketBreadcrumbsData,
  unwrapperBucketState,
  validateBucketForm,
  unwrapperBucket,
  wrapperBucketState,
  isSelectionInvalid,
  areSelectionsEqual,
  encryptor,
  decryptor,
};
