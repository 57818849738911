// Import required libraies
import React from "react";
import { useTranslation } from "react-i18next";

// Import Custom Component
import SigviewTooltip from "../Common/SigviewTooltip";
import SigviewButton from "../Common/SigviewButton";
import { Box } from "@material-ui/core";
import SigviewTypography from "../Common/SigviewTypography";

// Import Context

// Import utils/data

// Import styles
import "./ErrorHandler.scss";

import CalendarSvg from "../../../assets/images/calendar.svg";
import noUserSvg from "../../../assets/images/noUser.svg";
import noDataAvailSvg from "../../../assets/images/NoDataAvail.svg";
import corruptedFileSvg from "../../../assets/images/corruptedFile.svg";
import accessModifiedSvg from "../../../assets/images/accessModified.svg";
import failureSvg from "../../../assets/images/failure.svg";
import SomethingWentWrongSvg from "../../../assets/images/SomethingWentWrong.svg";
import internalServerErrorSvg from "../../../assets/images/internalServerError.svg";

function ErrorHandler(props) {
  const { t } = useTranslation();
  const {
    message,
    reloadFlag,
    onReload = () => {},
    reloadButtonProps = {},
    title = "Reload",
    layout = "mainScreen",
    type = "",
  } = props;
  const {
    flag: reloadButtonFlag = false,
    variant: buttonVariant = "containedPrimary",
    title: buttonTitle = "Load Default Profile",
  } = reloadButtonProps;

  return (
    <div className="error-handler-container">
      {type !== "noImg" && (
        <Box
          css={{
            width: "100%",
            height: "calc(100% - 50px)",
            display: "flex",
            flexDirection: layout === "mainScreen" ? "column" : "row",
            justifyContent: "center",
            paddingTop: "30px",
            paddingBottom: "20px",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px",
          }}
        >
          {props.message
            ?.toLowerCase()
            .includes("fetching date range failed".toLowerCase()) && (
            <>
              <img
                src={CalendarSvg}
                alt="Fetching date range failed"
                // width="20%"
                height="20%"
              />
            </>
          )}
          {props.message
            ?.toLowerCase()
            .includes("fetching user details failed".toLowerCase()) && (
            <>
              <img
                src={noUserSvg}
                alt="Fetching user details failed"
                height="20%"
              />
            </>
          )}
          {props.message
            ?.toLowerCase()
            .includes("data not available".toLowerCase()) && (
            <>
              <img
                src={noDataAvailSvg}
                alt="Data not available"
                height={
                  layout === "mainScreen"
                    ? "20%"
                    : layout === "metricChart"
                    ? "75%"
                    : "50%"
                }
              />
            </>
          )}
          {props.message
            ?.toLowerCase()
            .includes(
              "there are some isssues in the workspace".toLowerCase()
            ) && (
            <>
              <img
                src={corruptedFileSvg}
                alt="There are some isssues in the workspace"
                height="20%"
              />
            </>
          )}
          {props.message
            ?.toLowerCase()
            .includes("access modified".toLowerCase()) && (
            <>
              <img src={accessModifiedSvg} alt="Access Modified" height="20%" />
            </>
          )}
          {props.message
            ?.toLowerCase()
            .includes("something went wrong".toLowerCase()) && (
            <>
              <img
                src={SomethingWentWrongSvg}
                alt="Something Went Wrong!"
                height={
                  layout === "mainScreen"
                    ? "20%"
                    : layout === "metricChart"
                    ? "75%"
                    : "50%"
                }
              />
            </>
          )}

          {props.message
            ?.toLowerCase()
            .includes("internal server error".toLowerCase()) && (
            <>
              <img
                src={internalServerErrorSvg}
                alt="Something Went Wrong!"
                width={layout === "reportScreen" ? "20%" : ""}
                height={
                  layout === "mainScreen"
                    ? "20%"
                    : layout === "metricChart"
                    ? "75%"
                    : "50%"
                }
              />
            </>
          )}
          <Box
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <SigviewTypography
            variant={layout === "mainScreen" ? "pLarge" : "pMedium"}
            style={
              layout === "mainScreen"
                ? {
                    height: "max-content",
                    // color: themeColors["secondaryColor"],
                    fontWeight: 500,
                    padding: "20px 0px 10px 0px",
                  }
                : {
                    height: "max-content",
                    // color: themeColors["secondaryColor"],
                    fontWeight: 400,
                    padding: "20px 10px 10px 10px",
                    fontSize: "12px",
                    wordBreak: "break-all",
                    lineHeight: "1.3",
                  }
            }
          >
            {t(message)}
          </SigviewTypography> */}
            <Box
              css={
                layout === "mainScreen"
                  ? {
                      height: "max-content",
                      // color: themeColors["secondaryColor"],
                      fontWeight: 500,
                      padding: "20px 0px 10px 0px",
                    }
                  : {
                      height: "max-content",
                      // color: themeColors["secondaryColor"],
                      fontWeight: 400,
                      padding: "20px 10px 10px 10px",
                      fontSize: "12px",
                      wordBreak: "break-all",
                      lineHeight: "1.3",
                    }
              }
            >
              <p
                className="error-handler-message"
                dangerouslySetInnerHTML={{ __html: t(message) }}
              ></p>
            </Box>
            {reloadFlag && (
              <>
                {!reloadButtonFlag && (
                  <SigviewTooltip title={title}>
                    <i
                      className="material-icons error-handler-reload-icon"
                      onClick={onReload}
                    >
                      cached
                    </i>
                  </SigviewTooltip>
                )}
                {reloadButtonFlag && (
                  <SigviewButton
                    title={buttonTitle}
                    variant={buttonVariant}
                    onClick={onReload}
                    style={{ margin: { top: "10px" } }}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      )}

      {type === "noImg" && (
        <>
          <p
            className="error-handler-message"
            dangerouslySetInnerHTML={{ __html: t(message) }}
          ></p>
          {reloadFlag && (
            <>
              {!reloadButtonFlag && (
                <SigviewTooltip title={title}>
                  <i
                    className="material-icons error-handler-reload-icon"
                    onClick={onReload}
                  >
                    cached
                  </i>
                </SigviewTooltip>
              )}
              {reloadButtonFlag && (
                <SigviewButton
                  title={buttonTitle}
                  variant={buttonVariant}
                  onClick={onReload}
                  style={{ margin: { top: "10px" } }}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
export default ErrorHandler;
