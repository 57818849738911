//Import required libraies
import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// Import Custom Component
import SigviewIcon from "./SigviewIcon";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import action creators

// Import utils

// Import styles

function SigviewButtonMaker(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    title = "",
    onClick = () => {},
    variant = "contained",
    style = {
      padding: "6px 16px",
      fontSize: "12px !important",
    },
    disabled = false,
    iconProps = {
      className: "material-icons",
      iconName: "add",
    },
    iconFlag = false,
    iconStyle = { fontSize: "18px !important" },
    type = "button",
    iconName = "info",
    iconClassName = "material-icons",
  } = props;

  //Defining styling variables
  let buttonHeight = "24px";
  if (style.buttonHeight) buttonHeight = style.buttonHeight;
  let wrapperHeight = "max-content";
  if (style.wrapperHeight) wrapperHeight = style.wrapperHeight;

  //Styling
  const commonStyle = {
    height: buttonHeight,
    fontFamily: "inherit",
    fontSize: style?.fontSize ? `${style.fontSize}` : "12px !important",
    fontWeight: "400 !important",
    textTransform: "none",
    minWidth: "30px !important",
    borderRadius: "3px",
    boxSizing: "border-box !important",

    "& .MuiButton-label": { justifyContent: "space-around" },
    // "& .MuiButton-text": {
    //   padding: "6px 12px",
    //   width: style.btnWidth,
    //   height: style.btnHeight,
    //   "& .MuiButton-label": { justifyContent: "space-around !important" },
    // },
  };
  const useStyles = makeStyles(() => ({
    root: {
      marginLeft: style?.margin?.left || "0px",
      marginRight: style?.margin?.right || "0px",
      marginTop: style?.margin?.top || "0px",
      marginBottom: style?.margin?.bottom || "0px",
      fontSize: 11,
      width: style?.width,
      "& .MuiButton-startIcon": {
        marginRight: "3px !important",
        lineHeight: 1,
        display: "inline-block",
      },
      "& .MuiButton-iconSizeMedium > *:first-child": {
        fontSize: iconStyle.fontSize,
      },
      height: wrapperHeight,
    },
    buttonIconContainer: {
      display: "inline-block",
      height: "max-content",
      width: "max-content",
      marginLeft: "5px",
    },
    contained: {
      ...commonStyle,
      color: themeColors["buttonColor"],
      backgroundColor: themeColors["buttonBgColor"],
      boxShadow: "none !important",
      "&:hover": {
        boxShadow: "none !important",
        color: themeColors["buttonColor"],
        backgroundColor: themeColors["buttonBgColor"],
      },
      width: style?.width,
      padding: style.padding,
      "&.Mui-disabled": {
        color: themeColors["buttonDisabledColor"],
        backgroundColor: themeColors["buttonDisabledBgColor"],
        // color: themeColors["buttonColor"],
        // backgroundColor: themeColors["buttonBgColor"],
        // boxShadow: "none !important",
        // opacity: 0.3,
      },
    },
    containedPrimary: {
      ...commonStyle,
      color: themeColors["buttonColor"],
      backgroundColor: themeColors["primaryColor"],
      boxShadow: "none !important",
      "&:hover": {
        boxShadow: "none !important",
        color: themeColors["buttonColor"],
        backgroundColor: themeColors["primaryColor"],
      },
      width: style?.width,
      padding: style.padding,
      "&.Mui-disabled": {
        color: themeColors["buttonDisabledColor"],
        backgroundColor: themeColors["buttonDisabledBgColor"],
        // color: themeColors["buttonColor"],
        // backgroundColor: themeColors["buttonBgColor"],
        // boxShadow: "none !important",
        // opacity: 0.3,
      },
    },
    containedLighter: {
      ...commonStyle,
      color: themeColors["buttonColorLightest"],
      backgroundColor: themeColors["buttonBgColorLightest"],
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
        color: themeColors["buttonColorLightest"],
        backgroundColor: themeColors["buttonBgColorLighter"],
      },
      width: style?.width,
      padding: style.padding,
    },
    outlined: {
      ...commonStyle,
      // borderColor: themeColors["buttonBgColor"],
      color: themeColors["secondaryColor"],
      backgroundColor: "transparent",
      border: `1px solid ${themeColors["buttonBgColor"]}`,
      "&:hover": {
        boxShadow: "none",
        borderColor: themeColors["buttonBgColor"],
        color: themeColors["secondaryColor"],
        backgroundColor: "transparent",
      },
      width: style?.width,
      padding: style.padding,
      "&.MuiButton-root.Mui-disabled": {
        color: themeColors["secondaryColor"],
        backgroundColor: "transparent",
        border: `1px solid ${themeColors["buttonBgColor"]}`,
        opacity: 0.3,
      },
    },
    outlinedLighter: {
      ...commonStyle,
      color: themeColors["buttonColorLighter"],
      backgroundColor: "transparent",
      border: `1px solid ${themeColors["buttonBgColorLighter"]}`,
      "&:hover": {
        boxShadow: "none",
        borderColor: themeColors["buttonBgColorLighter"],
        color: themeColors["buttonColorLighter"],
        backgroundColor: themeColors["buttonBgColorLightest"],
      },
      width: style?.width,
      padding: style.padding,
    },
    text: {
      ...commonStyle,
      color: themeColors["buttonColorLighter"],
      backgroundColor: "transparent",
      border: "none",
      "&:hover": {
        boxShadow: "none",
        borderColor: themeColors["buttonBgColorLighter"],
        color: themeColors["buttonColorLighter"],
        backgroundColor: themeColors["buttonBgColorLightest"],
      },
      width: style?.width,
      padding: style.padding,
    },
  }));
  const classes = useStyles();

  const startIcon = () => {
    return iconFlag ? (
      <span className={`${iconProps.className}`}>{iconProps.iconName}</span>
    ) : null;
  };

  return (
    <div className={classes.root}>
      <Button
        // variant="contained" //to avoid the MUI warning; styling is controlled by our component
        className={classes[variant]}
        onClick={onClick}
        disabled={disabled}
        // startIcon={startIcon()}
        type={type}
      >
        {title}
        <Box className={classes.buttonIconContainer}>
          <SigviewIcon
            iconName={iconName}
            className={iconClassName}
            style={{
              color: themeColors["secondaryColor"],
              hoverColor: themeColors["secondaryColor"],
            }}
          />
        </Box>
      </Button>
    </div>
  );
}

export default SigviewButtonMaker;
