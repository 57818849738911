// * Import required libraies
import React, { useState } from "react";

// * Import lib comp
import { Box } from "@material-ui/core";

// * Import Custom Component
import Loader from "../../../components/Loader/Loader";
import SigviewTextField from "../../../components/Common/SigviewTextField";
import SigviewButton from "../../../components/Common/SigviewButton";
import SigviewSimpleText from "../../../components/Common/SigviewSimpleText";
import SigviewHoverPopup from "../../../components/Common/SigviewHoverPopup";
import SigviewIcon from "../../../components/Common/SigviewIcon";

// * Import action creators
import { updateUserScreen } from "../../../redux/actions";

// * Import utils/data
import { validateGroupName, wrapperGroup } from "../../../utils/adminUtils";
import { config } from "../../../config/config";

// * Import APIs
import { createGroup } from "../../../services/api";

const GroupClone = (props = {}) => {
  // * Destructure props
  const {
    user = {},
    ReduxDispatcher,
    initialName = "",
    selections = {},
    viewsDataUi = {},
    groupData = [],
  } = props;

  // * Define requried state
  const [name, setName] = useState({
    value: initialName,
    status: "success", // success/error/loading
    message: "",
  });

  // * Define required event handlers
  const handleNameChange = (newValue) => {
    setName(() => {
      const { status, message } = validateGroupName(newValue);
      let finalStatus = "success";
      if (status === "invalid") finalStatus = "error";
      const newState = { status: finalStatus, message, value: newValue };
      return newState;
    });
  };

  const handleCancel = () => {
    const payload = { open: false, children: null };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleClone = (type = "clone") => {
    const finalSelections = {
      ...selections,
      name: {
        ...selections.name,
        value: name.value,
      },
      actualPayload: {
        ...selections.actualPayload,
        _id: "",
      },
    };
    const payload = wrapperGroup(
      { ...finalSelections },
      user.reqMetadata,
      viewsDataUi,
      groupData.result,
      "create"
    );
    const fetchProps = {
      payload,
    };
    setName((prevState) => ({ ...prevState, status: "loading" }));
    const createGroupPromise = createGroup(fetchProps);
    createGroupPromise
      .then(() => {
        // Update local status to success
        setName((prevState) => ({ ...prevState, status: "success" }));

        // Close the dialog
        handleCancel();

        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Group Created sucessfully",
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      })
      .catch((json) => {
        console.error("Group Clone API Failed");
        console.groupCollapsed("DETAILS");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        const message = json.error || "Cloning group failed";
        // Update local status to error
        setName((prevState) => ({
          ...prevState,
          status: "error",
          message,
        }));
      });
  };

  // * Define required variables
  const errorFlag = name.status === "error";
  const helperText = name.message;
  const cloneDisabled = ["error", "loading"].includes(name.status);
  const cloneTitle = name.status === "loading" ? <Loader /> : "Clone";
  const box1Style = {
    display: "flex",
    margin: "0px 0px 8px 0px",
    alignItems: "center",
  };

  return (
    <section className="sigview-dialog-chart-clone">
      <Box css={box1Style}>
        <SigviewSimpleText
          value="Group Name"
          style={{
            border: "none",
            fontSize: "12px",
            padding: "0px",
            margin: "0px 5px 0px 0px",
          }}
        />
        <SigviewHoverPopup
          title="Group Name Rules"
          data={config.hardCoded.adminGroupNameRules}
        >
          <SigviewIcon className="material-icons-round" iconName="info" />
        </SigviewHoverPopup>
      </Box>

      <SigviewTextField
        placeholder="Please enter a valid group name"
        value={name.value}
        onChange={(newValue) => handleNameChange(newValue)}
        error={errorFlag}
        helperText={helperText}
        minWidth="400px"
        maxWidth="400px"
        height="40px"
        margin={{ right: "5px", bottom: "10px" }}
      />

      <article className="sigview-dialog-chart-clone-menu-bar">
        <SigviewButton
          variant="outlined"
          onClick={handleCancel}
          title="Cancel"
          style={{ width: "auto", margin: { left: "3px", right: "3px" } }}
        />
        <SigviewButton
          variant="contained"
          onClick={() => handleClone("clone")}
          disabled={cloneDisabled}
          title={cloneTitle}
          style={{ width: "auto", margin: { left: "3px", right: "3px" } }}
          customClassName="confirmGroupClone-GA"
        />
      </article>
    </section>
  );
};

export { GroupClone };
