// ! IMPORTANT NOTES
// Replica of common components
// Caution:
// 1. This is a fast fix to solve website performance
// 2. This may not adhere to our kit guidelines

// Import required libraries
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alingItems: "center",
  },
  vr: {
    height: "16px",
    width: "1px",
    margin: "0px 10px",
    backgroundColor: theme.palette.misc["secondaryColorLighter"],
  },
}));

function SigviewVR(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.vr}></div>
    </div>
  );
}

export default SigviewVR;
