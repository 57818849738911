// *  COMPONENT HIERARCHY
// AnalyzeDashboardContainer
//      AnalyzeDashboard
//          WsDimensionTableItem
//              WsDimHeader
//              WsDimTableData
//              WsDimTableUI (Memoized for performance optimization)
//          WsMetricChartItem
//              WsMetricChartHeader
//              WsMetricChart (Memoized for performance optimization)

import AnalyzeDashboardContainer from "./AnalyzeDashboardContainer";
export default AnalyzeDashboardContainer;
