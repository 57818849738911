// Import required libraries
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

// Import custom components
import AppContainer from "./js/AppContainer";

// Import styles
// import "../node_modules/react-grid-layout/css/styles.css";
import "./index.scss";

// Import gaId
import { config } from "./js/config/config";

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const tagManagerArgs = {
  gtmId: "GTM-WZSCLGC", // Dev ID
};

TagManager.initialize(tagManagerArgs);

// Initialize Google Analytics Tracking
ReactGA.initialize(config.hardCoded.gaId, {
  // debug: config.applicationMode === "development",
  debug: false,
  gaOptions: {
    cookieFlags: "max-age=7200;secure;same",
  },
});

ReactDOM.render(<AppContainer />, document.getElementById("root"));
