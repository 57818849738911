import React, { useState, useContext } from "react";

import "./SigviewBanner.scss";

// * Import Context
import { ThemeContext } from "../../contexts/ThemeContext";

import { Box, makeStyles } from "@material-ui/core";
import SigviewIcon from "../Common/SigviewIcon";

function SigviewBanner(props) {
  const { title, handleCloseBanner = () => {} } = props;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const pathname = window.location.pathname;
  const rootPage = pathname.split("/")[1]; // 0th element is empty, 1st element is the root page
  // Styles
  const box1 = {
    width: "70%",
    margin: "auto",
    padding: "2px 0px",
  };
  const box2 = {
    background: themeColors["primaryColor"],
    // width: "200px",
    //   height: "calc(100% - 35px)",
    margin: "auto auto",
    minHeight: "24px",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    minWidth: "200px",
    maxWidth: "max-content",
    boxSizing: "border-box",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
  };
  const box3 = { paddingLeft: "10px", paddingTop: "3px" };
  const box4 = {
    //   whiteSpace: "nowrap",
    //   width: "50px",
    //   maxWidth: "max-content",
    //   minWidth: "20px",
    //   overflow: "hidden",
    //   textOverflow: "ellipsis",
    width: "100%",
    padding: "1px 10px 4px 10px",
    height: "max-content",
  };
  const box5 = {
    paddingRight: "10px",
    cursor: "pointer",
  };
  const iconStyle = {
    color: "white",
    cursor: "pointer",
    fontSize: "16px !important",
  };

  const titleStyle = {
    height: "max-content",
    color: "white",
    fontSize: "11px",
    height: "max-content",
  };

  return (
    <Box css={box1}>
      <Box css={box2}>
        <Box css={box3}>
          <div className="ag-format-container-react">
            <div className="ag-bell-react">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.5 56.44">
                <g>
                  <g>
                    <path
                      d="M23.75,56.44a7.81,7.81,0,0,0,7.82-7.77H15.91a7.83,7.83,0,0,0,7.84,7.77Z"
                      className="svg-bell-react svg-bell-react_clapper"
                    ></path>
                    <path
                      d="M41.9,38.72V23.52a17.83,17.83,0,0,0-14-17.43V4.15a4.19,4.19,0,0,0-8.38,0V6.09a17.84,17.84,0,0,0-14,17.43v15.2L0,44.26V47H47.5V44.26Z"
                      className="svg-bell-react svg-bell-react_whole"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </Box>
        <Box css={box4}>
          <span style={titleStyle}>{title}</span>
        </Box>

        <Box css={box5}>
          <SigviewIcon
            onClick={handleCloseBanner}
            className={`material-icons ${rootPage}BannerClickToHide-GA`}
            iconName="close"
            style={iconStyle}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default SigviewBanner;
