// * Import required libraries
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import isEqual from "lodash.isequal";

// * Import custom components
import SigviewSimpleText from "../../Common/SigviewSimpleText";

// * Import utils
import { getValidDates } from "../../../utils/analyzeUtils";

function DashboardLastSaved(props) {
  const { standaloneWs } = props;
  const [checkApiStatus, setCheckApiStatus] = useState();
  const [validDates, setValidDates] = useState(getValidDates());
  const autosaveApiStatus = standaloneWs.autosaveApiStatus.value;
  const autosaveApiStatusOriginal = useRef(autosaveApiStatus);
  console.log("first DashboardLastSaved", autosaveApiStatus);
  const flag = !isEqual(autosaveApiStatus, autosaveApiStatusOriginal.current);
  useEffect(() => {
    setCheckApiStatus(standaloneWs.autosaveApiStatus.value);
    if (checkApiStatus !== standaloneWs.autosaveApiStatus.value) {
      setValidDates(getValidDates);
    }
  }, [standaloneWs.autosaveApiStatus.value]);

  return (
    <>
      <SigviewSimpleText
        value={validDates}
        style={{ border: "none", padding: "0px 5px" }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  standaloneWs: state.standaloneWs,
});

export default connect(mapStateToProps)(DashboardLastSaved);
