// Import required libraries
import React, { useState } from "react";
import i18n from "../../i18n";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Import custom components
import SigviewSingleSelect from "../Common/SigviewSingleSelect";

// Import styles
import "./LanguageSelection.scss";

// Import config

// Import action creators
import {
  updateUserScreen,
  updateUserLanguage,
  updateChangeFlag,
} from "../../redux/actions";

// Import API functions
import { savePreferredLanguage } from "../../services/api";

function LanguageSelection(props) {
  const { dispatch: ReduxDispatcher, user, changeFlag } = props;

  const [error, setError] = useState(false);
  const languageData = [
    { id: "en", name: "English", disabled: false },
    { id: "ja", name: "Japanese", disabled: false },
  ];

  const handleLanguageChange = (newValue) => {
    // Show loader on the entire page
    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    // Change the language
    // changeLanguage(newValue);
    i18n.changeLanguage(newValue);

    //Return to home (will be controlled by router later)
    ReduxDispatcher(updateUserScreen("activeTab", "home"));

    // Update the user  loader on the entire page
    ReduxDispatcher(updateUserLanguage(newValue));

    const fetchPayload = {
      email: user?.reqMetadata?.email,
      userPreferredLanguage: newValue,
    };
    const fetchProps = {
      payload: fetchPayload,
    };

    const updatedUserLanguagePromise = savePreferredLanguage(fetchProps);
    updatedUserLanguagePromise
      .then(() => {
        // Do not show loader on the entire page
        ReduxDispatcher(updateChangeFlag("view", !changeFlag.view));
        console.log("language changed");
      })
      .catch((error) => setError(error.error));
  };

  return (
    <section className={`view-container`}>
      {error ? (
        <p>{error}</p>
      ) : (
        <>
          <SigviewSingleSelect
            value={user?.reqMetadata?.userPreferredLanguage}
            data={languageData}
            onChange={(newValue) => {
              handleLanguageChange(newValue);
            }}
            minWidth="10px"
            // margin={{ left: "5px", right: "5px" }}
            border="none"
            theme={user.theme}
            themeColors={user.themeColors}
          />
        </>
      )}
    </section>
  );
}

LanguageSelection.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
});

export default connect(mapStateToProps)(LanguageSelection);
