// * Import required libraries
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { v4 } from "uuid";
import isEqual from "lodash.isequal";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

// * Import custom components
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import AppRow from "../../../components/AppRow";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
// import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";

// * Import utils, config & static data
import {
  unwrapperAppRow,
  convertChartResToDsRes,
  // getBreadcrumbsData,
} from "../../../utils/utils";
import { unwrapperChartObject } from "../../../utils/chartObjectUtils";
import staticChartJson from "../../../../assets/data/chartTitle.json";
import { masterTrackGaEvent } from "../../../services/ga";
import { config } from "../../../config/config";

// * Import redux utilities
// Reducers
import standaloneChartReducer from "../../../redux/reducers/standaloneChart";
// Action creators
import {
  updateUserScreen,
  updateStandaloneChartForm,
  replaceTimeFilters,
  replaceAllDimensionFilters,
  updateGlobalFiltersDimensionFilters,
  updateGlobalFiltersTimeFilters,
} from "../../../redux/actions";

// * Import API functions
import { getDatastories, getChartObjectList } from "../../../services/api";

// * Define static variables
// Initial data
const initialData = {
  status: "loading",
  message: "",
  result: [],
};

function DatastoryHome(props) {
  const { t } = useTranslation();
  // * Destructure props
  const {
    dispatch: ReduxDispatcher = () => {},
    user = {},
    globalFilters = {},
    allData = {},
  } = props;

  // * Define required hooks
  const history = useHistory();

  // * Define required states
  const [savedCharts, setSavedCharts] = useState(initialData);
  const [reloadSavedCharts, setReloadSavedCharts] = useState(false);
  const [savedDs, setSavedDs] = useState(initialData);
  const [reloadSavedDs, setReloadSavedDs] = useState(false);
  const [sampleDs, setSampleDs] = useState(initialData);
  const [reloadSampleDs, setReloadSampleDs] = useState(false);

  // * Define required event handlers
  const trackShowAllGA = (label) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryHome",
      action: "ShowAll",
      label,
    });
  };
  const trackItemLaunchGA = (label) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryHome",
      action: "Launch",
      label,
    });
  };
  const handleSavedChartsShowAll = () => {
    trackShowAllGA("SavedCharts");
    const newScreenData = { activeNav: "datastory", activeTab: "chartsHome" };
    const action = updateUserScreen(null, newScreenData);
    ReduxDispatcher(action);
    history.push("/datastory/chart");
  };
  const handleSavedDsShowAll = () => {
    trackShowAllGA("SavedDS");
    const newScreenData = {
      activeNav: "datastory",
      activeTab: "category",
      activeDsCategory: "saved",
    };
    const action = updateUserScreen(null, newScreenData);
    ReduxDispatcher(action);
    history.push("/datastory/saved");
  };
  const handleSampleDsShowAll = () => {
    trackShowAllGA("SampleDS");
    const newScreenData = {
      activeNav: "datastory",
      activeTab: "category",
      activeDsCategory: "sample",
    };
    const action = updateUserScreen(null, newScreenData);
    ReduxDispatcher(action);
    history.push("/datastory/sample");
  };
  const handleGoHome = (user) => {};

  const handleAddNewChartClick = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryHome",
      action: "CreateNew",
      label: "Chart",
    });
    //Remove charts which are pivotx
    const filteredChartList = savedCharts.result.filter(
      (row) => row.chartObject.metadata.chartType !== "pivotx"
    );
    const newTitle = `Untitled Chart - ${filteredChartList.length + 1}`;
    // Update activeChart details with defaultState
    let initialStandaloneChartState = {
      id: `chart-object-${v4()}`,
      title: newTitle,
      dimensionsList: [],
      metricsList: [],
      orderById: "",
      orderBy: "desc",
      chartType: "bar",
      metricFilters: [],
      dimensionFilters: globalFilters.dimensionFilters,
      timeFilters: globalFilters.timeFilters,
      chartList: staticChartJson,
      elementType: "create", // create or update to help differentiate in PlotCreate,
      renderFlag: false, // to control rendering of the chart in the component
    };
    // Updating plot by calling the reducer
    // Adding required business logic
    const payload = { key: "chartType", value: "bar" };
    var action = updateStandaloneChartForm(payload);
    initialStandaloneChartState = standaloneChartReducer(
      initialStandaloneChartState,
      action
    );

    // Update activeChart in redux and screen so that user can be redirected to chart
    const value = {
      activeNav: "datastory",
      activeTab: "createChart",
      activeChart: initialStandaloneChartState,
    };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);
    history.push("/datastory/chart/create");
  };
  const handleAddNewDsClick = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryHome",
      action: "CreateNew",
      label: "Datastory",
    });
    const newScreenData = {
      activeNav: "datastory",
      activeTab: "create",
      activeDatastory: {
        elementType: "create",
        title: `Untitled Datastory - ${savedDs.result.length + 1}`,
      },
    };
    const action = updateUserScreen(null, newScreenData);
    ReduxDispatcher(action);

    history.push("/datastory/dashboard/create");
  };
  const handleSavedChartsReload = () => {
    setReloadSavedCharts(!reloadSavedCharts);
  };
  const handleSavedDsReload = () => {
    setReloadSavedDs(!reloadSavedDs);
  };
  const handleSampleDsReload = () => {
    setReloadSampleDs(!reloadSampleDs);
  };
  const handleSavedChartClick = (value) => {
    trackItemLaunchGA("SavedCharts");

    const { actualPayload: uiAppCardActualPayload } = value;
    const { chartItemPayload } = uiAppCardActualPayload;
    const { chartObjectUI } = chartItemPayload;

    // First update the global time filters because PlotCreate takes it from that
    // Dispatch only when they are unequal
    if (!isEqual(user.timeFilters, chartObjectUI.timeFilters)) {
      var action = replaceTimeFilters(chartObjectUI.timeFilters);
      ReduxDispatcher(action);
    }

    //Update dimension filters
    if (!isEqual(user.dimensionFilters, chartObjectUI.dimensionFilters)) {
      var payload = {
        newDimensionFilters: chartObjectUI.dimensionFilters,
      };
      var action = replaceTimeFilters(payload);
      ReduxDispatcher(replaceAllDimensionFilters(payload));
    }

    // Update activeChart in redux and screen so that user can be redirected to chart
    const userScreenValue = {
      activeNav: "datastory",
      activeTab: "createChart",
      activeChart: {
        ...chartObjectUI,
        elementType: "update",
        renderFlag: true,
      },
    };
    var action = updateUserScreen(null, userScreenValue);
    ReduxDispatcher(action);
    var payloadDimFilter = {
      value: userScreenValue.activeChart.dimensionFilters,
    };
    var action_globalDimFilters =
      updateGlobalFiltersDimensionFilters(payloadDimFilter);
    ReduxDispatcher(action_globalDimFilters);
    var payloadTimeFilter = {
      value: userScreenValue.activeChart.timeFilters,
    };
    var action_globalTimeFilters =
      updateGlobalFiltersTimeFilters(payloadTimeFilter);
    ReduxDispatcher(action_globalTimeFilters);

    history.push("/datastory/chart/edit");
  };
  const handleDsClick = (value, dsCategory = "Saved") => {
    trackItemLaunchGA(`${dsCategory}DS`);

    const { actualPayload } = value;
    // Update activeChart in redux and screen so that user can be redirected to chart
    const userScreenValue = {
      activeNav: "datastory",
      activeTab: "dashboard",
      activeDsCategory: dsCategory.toLowerCase(),
      activeDatastory: { ...actualPayload, dsCategory, elementType: "edit" },
    };
    var action = updateUserScreen(null, userScreenValue);
    ReduxDispatcher(action);
    history.push("/datastory/dashboard");
  };
  // const handleBreadcrumbChange = (event, value) => {
  //   // const data = {}
  //   // const action = updateUserScreen(null, data)
  //   // updateUserScreen(action)
  // };

  // * Define required side effects
  // Saved Charts
  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();
    const runFlag = !user.screen.isDashboardLoading;
    if (runFlag) {
      const fetchProps = {
        cancelToken: source.token,
      };
      const getChartObjectListPromise = getChartObjectList(fetchProps);
      getChartObjectListPromise
        .then((responseDate) => {
          const newData = {
            status: "success",
            message: "",
            result: responseDate.result.data,
          };
          setSavedCharts(newData);
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            console.groupCollapsed("UI ERROR");
            console.log("ERROR -> ", json.error);
            console.groupEnd();
            const newData = {
              status: "error",
              message: json.error,
              result: [],
            };
            setSavedCharts(newData);
          }
        });
    }
    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
      setSavedCharts(initialData);
    };
  }, [reloadSavedCharts, user.screen.isDashboardLoading]);

  // Saved DS
  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();
    const runFlag = !user.screen.isDashboardLoading;
    if (runFlag) {
      const fetchProps = {
        url: `${user.apiEndpoints.baseUrl}${config.apiEndpoints["datastorySaved"]}/${user.reqMetadata.organization}/${user.reqMetadata.view}`,
        cancelToken: source.token,
      };
      const getDatastoriesPromise = getDatastories(fetchProps);
      getDatastoriesPromise
        .then((responseDate) => {
          const newData = {
            status: "success",
            message: "",
            result: responseDate.result.data,
          };
          setSavedDs(newData);
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            console.groupCollapsed("UI ERROR");
            console.log("ERROR -> ", json.error);
            console.groupEnd();
            const newData = {
              status: "error",
              message: json.error,
              result: [],
            };
            setSavedDs(newData);
          }
        });
    }
    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
      setSavedDs(initialData);
    };
  }, [reloadSavedDs, user.screen.isDashboardLoading]);

  // Sample DS
  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();
    const runFlag = !user.screen.isDashboardLoading;
    if (runFlag) {
      const fetchProps = {
        url: `${user.apiEndpoints.baseUrl}${config.apiEndpoints["datastorySample"]}/${user.reqMetadata.organization}/${user.reqMetadata.view}`,
        cancelToken: source.token,
      };
      const getDatastoriesPromise = getDatastories(fetchProps);
      getDatastoriesPromise
        .then((responseDate) => {
          const newData = {
            status: "success",
            message: "",
            result: responseDate.result.data,
          };
          setSampleDs(newData);
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            console.groupCollapsed("UI ERROR");
            console.log("ERROR -> ", json.error);
            console.groupEnd();
            const newData = {
              status: "error",
              message: json.error,
              result: [],
            };
            setSampleDs(newData);
          }
        });
    }
    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
      setSampleDs(initialData);
    };
  }, [reloadSampleDs, user.screen.isDashboardLoading]);

  // DEBUGGER
  // useEffect(() => {
  //   console.groupCollapsed(
  //     "%cRENDERED | DatastoryHome.js",
  //     "color: yellow; font-size: 15px"
  //   );
  //   console.log("savedCharts -> ", savedCharts);
  //   console.log("savedDs -> ", savedDs);
  //   console.log("sampleDs -> ", sampleDs);
  //   console.groupEnd();
  // });

  // * Define required static variables
  // SAVED CHARTS
  // Filter Charts
  // Remove charts which are pivotx
  let filteredSavedCharts = savedCharts.result.filter(
    (row) => row.chartObject.metadata.chartType !== "pivotx"
  );
  // Conversion so that it can be dev to unwrapperChartObjec func
  filteredSavedCharts = filteredSavedCharts.map((row) => ({
    id: row._id,
    name: row.chartObject.metadata.title,
    payload: row,
    chartType: row.chartObject.metadata.chartType,
  }));
  // Adding chartObjectUI using uwrapperChartObject to filter out corrupt objects
  filteredSavedCharts = filteredSavedCharts.map((row) => ({
    ...row,
    chartObjectUI: unwrapperChartObject({
      allData: allData,
      user: user,
      payload: { ...row.payload },
    }),
  }));
  // Filter out corrupt objects
  filteredSavedCharts = filteredSavedCharts.filter(
    (row) => row.chartObjectUI.valid
  );
  // ! HARD CODED: Chart list ordering not present in the backend
  // ! Hence, doing it here
  filteredSavedCharts = [...filteredSavedCharts].reverse();
  filteredSavedCharts = filteredSavedCharts.map(convertChartResToDsRes);
  const savedChartsAppRowProps = {
    title: t("Saved Charts"),
    payload: {},
    data: filteredSavedCharts,
    newCardProps: {
      title: t("Create New Chart"),
      iconsProps: {
        flag: true,
        className: "material-icons-outlined",
        value: "add_box",
      },
      onClick: handleAddNewChartClick,
    },
    noCardProps: {
      title: t("No Charts Available"),
      flag: true,
    },
    createNewCardFlag: true,
    singleRowFlag: true,
    titleRowFlag: true,
    buttonRowFlag: true,
    appCardTag: "chartWithIcon",
    onButtonClick: handleSavedChartsShowAll,
    onAppCardClick: (event, value) => {
      handleSavedChartClick(value);
    },
  };
  const uiFriendlySavedCharts = unwrapperAppRow(savedChartsAppRowProps);
  const renderPropsSavedChartsLoading = {
    flag: true,
    component: () => <Loader />,
  };
  const renderPropsSavedChartsError = {
    flag: true,
    component: () => (
      <ErrorHandler
        message={savedCharts.message}
        reloadFlag={true}
        onReload={handleSavedChartsReload}
      />
    ),
  };
  //SAVED DS
  const savedDsAppRowProps = {
    title: t("Saved Datastory"),
    payload: {},
    data: savedDs.result,
    newCardProps: {
      title: t("Create New Datastory"),
      iconsProps: {
        flag: true,
        className: "material-icons-outlined",
        value: "add_box",
      },
      onClick: handleAddNewDsClick,
    },
    noCardProps: {
      title: t("No Datastory Available"),
      flag: true,
    },
    createNewCardFlag: true,
    singleRowFlag: true,
    titleRowFlag: true,
    buttonRowFlag: true,
    onButtonClick: handleSavedDsShowAll,
    onAppCardClick: (event, value) => {
      handleDsClick(value, "Saved");
    },
    appCardTag: "gridChart",
  };
  const uiFriendlySavedDs = unwrapperAppRow(savedDsAppRowProps);
  const renderPropsSavedDsLoading = { flag: true, component: () => <Loader /> };
  const renderPropsSavedDsError = {
    flag: true,
    component: () => (
      <ErrorHandler
        message={savedDs.message}
        reloadFlag={true}
        onReload={handleSavedDsReload}
      />
    ),
  };
  //SAMPLE DS
  const sampleDsAppRowProps = {
    title: "Sample Datastory",
    payload: {},
    data: sampleDs.result,
    noCardProps: {
      title: t("No Datastory Available"),
      flag: true,
    },
    createNewCardFlag: false,
    singleRowFlag: true,
    titleRowFlag: true,
    buttonRowFlag: true,
    onButtonClick: handleSampleDsShowAll,
    onAppCardClick: (event, value) => {
      handleDsClick(value, "Sample");
    },
    appCardTag: "gridChart",
  };
  const uiFriendlySampleDs = unwrapperAppRow(sampleDsAppRowProps);
  const renderPropsSampleDsLoading = {
    flag: true,
    component: () => <Loader />,
  };
  const renderPropsSampleDsError = {
    flag: true,
    component: () => (
      <ErrorHandler
        message={sampleDs.message}
        reloadFlag={true}
        onReload={handleSampleDsReload}
      />
    ),
  };
  // Misc
  const isDatastoryAvailable = user.uiFeatureList.datastory;
  const isChartsAvailable = user.uiFeatureList.plot;
  // const isDatastoryAvailable = false;
  // const isChartsAvailable = false;
  // const breadcrumbData = getBreadcrumbsData(user);
  const additionalSidenavItems = [
    {
      id: "home",
      name: "Home",
      tooltip: "Go back to home",
      elementId: "dsBackToHome",
      iconId: "custom-home-icon",
      customClass: "sidenav-home",
      onClick: (user) => handleGoHome(user),
      isVisible: true,
    },
  ];

  const sidenavItemsOrderInfo = {
    home: 1,
    reports: 2,
    alerts: 3,
  };

  const tabName = "datastory-home";

  const sidenavProps = { additionalSidenavItems, sidenavItemsOrderInfo, tabName };

  return (
    <>
      <LayoutTopSideBottom sidenavProps={sidenavProps}>
        {/* <SigviewBreadcrumb
          data={breadcrumbData}
          onClick={handleBreadcrumbChange}
        /> */}

        {!isDatastoryAvailable && !isChartsAvailable && (
          <div className="no-data-container">
            <div className="no-data-image"></div>
            <p className="no-data">{t(config.messages.plotDsUnavailable)}</p>
          </div>
        )}

        {isChartsAvailable && (
          <>
            {/* SAVED CHARTS */}
            {savedCharts.status === "success" && (
              <AppRow {...uiFriendlySavedCharts} />
            )}
            {savedCharts.status === "loading" && (
              <AppRow
                {...uiFriendlySavedCharts}
                renderProps={renderPropsSavedChartsLoading}
              />
            )}
            {savedCharts.status === "error" && (
              <AppRow
                {...uiFriendlySavedCharts}
                renderProps={renderPropsSavedChartsError}
              />
            )}
          </>
        )}

        {isDatastoryAvailable && (
          <>
            {/* SAVED DS */}
            {savedDs.status === "success" && <AppRow {...uiFriendlySavedDs} />}
            {savedDs.status === "loading" && (
              <AppRow
                {...uiFriendlySavedDs}
                renderProps={renderPropsSavedDsLoading}
              />
            )}
            {savedDs.status === "error" && (
              <AppRow
                {...uiFriendlySavedDs}
                renderProps={renderPropsSavedDsError}
              />
            )}

            {/* SAMPLE DS */}
            {sampleDs.status === "success" && (
              <AppRow {...uiFriendlySampleDs} />
            )}
            {sampleDs.status === "loading" && (
              <AppRow
                {...uiFriendlySampleDs}
                renderProps={renderPropsSampleDsLoading}
              />
            )}
            {sampleDs.status === "error" && (
              <AppRow
                {...uiFriendlySampleDs}
                renderProps={renderPropsSampleDsError}
              />
            )}
          </>
        )}
      </LayoutTopSideBottom>
      {/* <SigviewCommon /> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(DatastoryHome);
