// * Import required libraies
import React, { useContext } from "react";

// * Import 3rd party lib comp
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Define required functions
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    fontSize: "10px",
    backgroundColor: theme.palette.misc["mainContentBgColor"],
    padding: "0px 0px 10px 0px",
  },
  leftPanel: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
    width: "30%",
    flex: "1 1",
  },
  centerPanel: {
    padding: "0px 10px",
    flex: "1 1",
  },
  rightPanel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "135px",
    height: "100%",
    flex: "1 1",
  },
  nameContainer: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
    width: "100%",
  },
  timeFiltersContainer: {
    width: "max-content",
    //boxShadow: theme.palette.misc["timeFiltersBoxShadow"],
    borderRadius: "5px",
    padding: "0px 4px 0px 4px",
    backgroundColor: theme.palette.misc["timeFiltersBgColor"],
  },
  optionsContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

function LayoutMenuBar(props = {}) {
  // * Destructure props
  const { children = <></>, style = {} } = props;

  // * Define requires static variables
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const classes = useStyles();
  const {
    root,
    leftPanel,
    centerPanel,
    rightPanel,
    nameContainer,
    timeFiltersContainer,
    optionsContainer,
  } = classes;

  // ---LAYOUT HIERARCHY---
  // Menu Bar
  //    Name
  //    Time Filters
  //    Options

  return (
    <Box className={root}>
      <Box className={leftPanel}>
        <Box className={nameContainer}>{children[0]}</Box>
      </Box>
      <Box className={centerPanel}>
        <Box className={timeFiltersContainer}>{children[1]}</Box>
      </Box>
      <Box className={rightPanel}>
        <Box className={optionsContainer}>{children[2]}</Box>
      </Box>
    </Box>
  );
}

export default LayoutMenuBar;
