// Import required libraries
import React, { useEffect, useState, useReducer, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import orderBy from "lodash/orderBy";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";

// Import custom components
import AdvancedFiltersContainer from "./AdvancedFiltersContainer";
import SigviewButton from "../Common/SigviewButton";
import DimensionValues from "./DimensionValues";
import Loader from "../Loader/Loader";
import SigviewTextField from "../Common/SigviewTextField";
import TimeFilters from "../TimeFilters/TimeFilters";
import SelectedFilters from "./SelectedFilters";
import Tour from "../Tour/Tour";
import SigviewTooltip from "../Common/SigviewTooltip";
import MetricFiltersContainer from "./MetricFiltersContainer";
import SigviewButtonSplit from "../Common/SigviewButtonSplit";
import DimensionsDropdown from "./DimensionsDropdown";
import SigviewVR from "../Common/SigviewVR";
import SigviewRadioGroup from "../Common/SigviewRadioGroup";

// Import styles
import "./GlobalFilters.scss";

// Import config
import { config } from "../../config/config";
import useReducerLogger from "../../utils/useReducerLogger";

// Import action creators and reducers
import {
  updateUserScreen,
  updateDialogInfo,
  changeFilterType,
  addFilterValues,
  removeFilterValues,
  replaceAllAdvancedFilters,
  replaceAllDimensionFilters,
  removeFilter,
  addFilter,
} from "../../redux/actions";
import dimensionFiltersReducer from "../../redux/reducers/dimensionFilters";
import { removeInvalidRows } from "../../utils/utils";
import { getFinalDimensionValuesData } from "../../utils/filtersUtils";
import { getValidDimensionsList } from "../../utils/filtersUtils";
import tourData from "../../../assets/data/tourData.json";
import { masterTrackGaEvent } from "../../services/ga";

//DATA NEEDED FOR THIS COMPONENT TO WORK
//1) allDimensionsList (allData.plotlyDimensions)

//Making required variables
const initialDimensionData = { status: "loading", message: "", data: [] };

function GlobalFilters(props) {
  const { t } = useTranslation();
  const { dispatch: ReduxDispatcher, user, allData, globalFilters } = props;
  const initialTimeFilters = user.screen.globalFilters.timeFilters;
  const initialDimensionFilters = user.screen.globalFilters.dimensionFilters;
  const initialMetricFilters = user.screen.globalFilters.metricFilters;
  const {
    settings = {},
    filtersDimData = allData.plotlyDimensions,
    filtersMetricsData = allData.plotlyMetrics,
    selections = {},
  } = user.screen.globalFilters;
  const {
    isAdvancedFilterVisible: isAdvancedFilterVisibleInSettings = true,
    isExcludeAllowed: isExcludeAllowedInSettings = true,
    disabledItemsList: disabledItemsListInSettings = [], // ["D044"] --> This needs to be a array of ids
  } = settings;

  const pathname = window.location.pathname;
  const rootPage = pathname.split("/")[1];

  //Defining the main reducers
  const [globalFiltersStore, dispatchGlobalFiltersStore] = useReducer(
    useReducerLogger(dimensionFiltersReducer),
    initialDimensionFilters
  );

  //Making a copy to compare with the updated one to check if the toasts need to be shown or not
  const globalFiltersOriginal = useRef(initialDimensionFilters);
  const isFirstRender = useRef(true); //CAUTION: This can be used just ONCE in the entire component; If we need to replicate it, we will have to create a second one

  //Defining required states
  const [allDimensionsList, setAllDimensionsList] = useState(
    getValidDimensionsList(filtersDimData)
  );
  const [metricFilters, setMetricFilters] = useState(initialMetricFilters);
  const [activeDimension, setActiveDimension] = useState();
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const searchKeywordRef = useRef("");
  const [dimensionData, setDimensionData] = useState(initialDimensionData); //rename to dimensionValues
  const [dimensionDataReload, setDimensionDataReload] = useState(false);
  const [localTimeFilters, setLocalTimeFilters] = useState(initialTimeFilters);
  const [tourRun, setTourRun] = useState(false);

  //Update the list of dimensions on change of plotlyDimensions and first render
  useEffect(() => {
    //Get only those dimensions which are of type string
    let newAllDimensionsList = getValidDimensionsList(filtersDimData);
    //Order the list by title ascending
    newAllDimensionsList = orderBy(
      newAllDimensionsList,
      (row) => row["dimTitle"].toLowerCase(),
      ["asc"]
    );
    //Update the list
    setAllDimensionsList(newAllDimensionsList);

    //Check if the redux has active dimension, if not update it with the first value
    const reduxActiveDimension =
      user?.screen?.globalFilters?.activeDimensionFilter;
    if (reduxActiveDimension?._id) {
      setActiveDimension(reduxActiveDimension);
      //Remove activeDimensionFilter from userScreen so that if the user comes back GF again
      //The old clicked filter is not opened first but the first one gets opened
      let newGlobalFiltersProps = {
        ...user.screen.globalFilters,
        activeDimensionFilter: {},
      };
      ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
    } else {
      //Update the active Dimension as the first element
      const firstAdFilterObj = newAllDimensionsList[0];
      //Add filterRow to globalFilterStore if it doesn't exist
      let activeFilterObj = globalFiltersStore.find(
        (filterRow) => filterRow.id === firstAdFilterObj?._id
      );
      if (!activeFilterObj) {
        const payload = {
          filterId: firstAdFilterObj._id,
          filterMetadata: firstAdFilterObj,
          filterValues: [],
          filterType: "include",
          filterValidity: false,
        };
        const action = addFilter(payload);
        dispatchGlobalFiltersStore(action);
      }
      setActiveDimension(firstAdFilterObj);
    }
  }, [filtersDimData]);

  //User deletes a filter from the selected dimension row
  //That object is removed from the globalFiltersStore making the include/exclude radio button buggy
  //Listen to everytime globalFiltersStore changes
  //And if activeDimFilterObject doesn't exist add it
  useEffect(() => {
    if (!isFirstRender.current) {
      if (filtersDimData) {
        //Get only those dimensions which are of type string
        let newAllDimensionsList = getValidDimensionsList(filtersDimData);
        //Order the list by title ascending
        newAllDimensionsList = orderBy(
          newAllDimensionsList,
          (row) => row["dimTitle"].toLowerCase(),
          ["asc"]
        );
        //Update the active Dimension as the first element
        const firstAdFilterObj = newAllDimensionsList[0];
        //Add filterRow to globalFilterStore if it doesn't exist
        const activeFilterObj = globalFiltersStore.find(
          (filterRow) => filterRow.id === activeDimension?._id
        );
        const filterObjectToBeAdded = activeDimension || firstAdFilterObj;
        if (!activeFilterObj) {
          const payload = {
            filterId: filterObjectToBeAdded._id,
            filterMetadata: filterObjectToBeAdded,
            filterValues: [],
            filterType: "include",
            filterValidity: false,
          };
          const action = addFilter(payload);
          dispatchGlobalFiltersStore(action);
        }
      }
    } else {
      isFirstRender.current = false;
    }
  }, [globalFiltersStore]);

  //Add event listener on keydown
  useEffect(() => {
    let keysPressed = {};

    const keydownFunc = (event) => {
      keysPressed[event.key] = true;
      //Prompt filters close if escape is clicked
      if (keysPressed["Escape"]) {
        if (
          !user.screen.globalFilters.isAdFiltersOpen &&
          user.screen.globalFilters.activeFilterType === "dimensions"
        ) {
          //Don't close filters if adFilters is open
          handleGlobalFiltersClose();
        }
      }

      //Trigger dimension values search if user clicks enter
      if (keysPressed["Enter"]) {
        //Check if the searchKeyword is not the same as searchKeywordRef
        //And check if the length of search keyword is not empty
        if (
          // searchKeyWord.length &&
          searchKeyWord !== searchKeywordRef.current
        ) {
          handleDimValueSearch();
        }
      }
    };

    const keyupFunc = (event) => {
      delete keysPressed[event.key];
    };

    window.addEventListener("keydown", keydownFunc);
    window.addEventListener("keyup", keyupFunc);
    return () => {
      window.removeEventListener("keydown", keydownFunc);
      window.removeEventListener("keyup", keyupFunc);
    };
  });

  //Defining utility functions
  const handleGlobalFiltersClose = () => {
    //Get filters with rows removed where there are no values/adFilters
    const globalFiltersStoreFiltered = removeInvalidRows(globalFiltersStore);
    //Check if there's a change in advanced filter values
    //If yes, show the dialog, else close the filters
    const flag = !isEqual(
      globalFiltersStoreFiltered,
      globalFiltersOriginal.current
    );
    if (flag) {
      const message = {
        title: config.dialogInfo.filtersClose.title,
        subtitle: config.dialogInfo.filtersClose.subtitle,
      };
      const handleNo = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
          })
        );
      };
      const handleYes = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
            message: { title: "", subtitle: "" },
          })
        );

        //Close the filters
        closeGlobalFilters();
      };
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: true,
          message,
          handleYes,
          handleNo,
        })
      );
    } else {
      //Close the filters
      closeGlobalFilters();
    }
  };

  const handleAdvancedFiltersOpen = (event) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Open Advanced Filters",
      label: "GlobalFilters",
    });
    //Open the advanced filters
    const newGlobalFiltersProps = {
      ...user.screen.globalFilters,
      isAdFiltersOpen: event.currentTarget, //advanced filters dialog open close
    };
    ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
  };

  const handleSelectDimensionChange = (e, value) => {
    if (value) {
      //Add filterRow to globalFilterStore if it doesn't exist
      let activeFilterObj = globalFiltersStore.find(
        (filterRow) => filterRow.id === value?._id
      );
      if (!activeFilterObj) {
        const payload = {
          filterId: value._id,
          filterMetadata: value,
          filterValues: [],
          filterType: "include",
          filterValidity: false,
        };
        const action = addFilter(payload);
        dispatchGlobalFiltersStore(action);
      }

      //Empty the search keyword
      searchKeywordRef.current = "";
      setSearchKeyWord("");

      // Google Analytics Event - Master
      masterTrackGaEvent({
        category: "GlobalFilters",
        action: "DimensionDropdown",
        label: "DimensionDropdown",
      });
      setActiveDimension(value);
    } else {
      setActiveDimension(activeDimension);
    }
  };

  const handleAdFiltersReplacement = (newAdvancedFilters) => {
    dispatchGlobalFiltersStore(
      replaceAllAdvancedFilters({
        filterId: activeDimension?._id,
        filterMetadata: activeDimension,
        newAdvancedFilters,
      })
    );
  };

  const handleApplyFilters = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "GlobalFilters",
      label: "ApplyFilters",
    });

    //Get filters with rows removed where there are no values/adFilters
    const globalFiltersStoreFiltered = removeInvalidRows(globalFiltersStore);

    //Update dimension filters globally if they changed
    if (!isEqual(globalFiltersStoreFiltered, globalFiltersOriginal.current)) {
      const payload = {
        newDimensionFilters: globalFiltersStoreFiltered,
      };
      user.screen.globalFilters.handleApplyFilters(payload, "dimensions");
    }

    //Update time filters globally if they changed
    if (!isEqual(initialTimeFilters, localTimeFilters)) {
      user.screen.globalFilters.handleApplyFilters(localTimeFilters, "time");
    }

    //Update time filters globally if they changed
    if (!isEqual(initialMetricFilters, metricFilters)) {
      const metricFiltersPayload = { newMetricFilters: metricFilters };
      user.screen.globalFilters.handleApplyFilters(
        metricFiltersPayload,
        "metrics"
      );
    }

    //Close the filters
    closeGlobalFilters();
  };

  const handleDimValueSearch = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "SearchInDimension",
      label: "SearchInDimension",
    });
    searchKeywordRef.current = searchKeyWord;
    setDimensionDataReload(!dimensionDataReload);
  };

  const handleSelectAll = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "DimensionValue",
      label: "SelectAll",
    });
    const payload = {
      filterId: activeDimension?._id,
      filterMetadata: activeDimension,
      filterValues: getFinalDimensionValuesData(dimensionData, activeFilterObj)
        .allValues,
    };
    dispatchGlobalFiltersStore(addFilterValues(payload));
  };

  const handleClearAll = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "DimensionValue",
      label: "ClearAll",
    });
    const payload = {
      filterId: activeDimension?._id,
      filterValues: getFinalDimensionValuesData(dimensionData, activeFilterObj)
        .allValues,
    };
    dispatchGlobalFiltersStore(removeFilterValues(payload));
  };

  const handleDimFilterClick = (filterRow) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Open Filters",
      label: "SelectedDimensions",
    });
    const newActiveDimension = filterRow.metadata;
    setActiveDimension(newActiveDimension);
  };

  const handleDimFilterDelete = (filterRow) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Clear Filters",
      label: "SelectedDimensions",
    });
    const payload = {
      filterId: filterRow.id,
    };
    dispatchGlobalFiltersStore(removeFilter(payload));
  };

  const handleMetricFilterClick = () => {
    //Open Metric Filters
    const newGlobalFiltersProps = {
      ...user.screen.globalFilters,
      activeFilterType: "metrics", //metrics filters close
    };
    ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
  };

  const handleMetricFilterDelete = (metricRow) => {
    const newMetricFilters = metricFilters.filter(
      (filter) => filter.id !== metricRow.id
    );
    setMetricFilters(newMetricFilters);
  };

  const handleClearAllFilters = () => {
    const disabledDimensionFilters = globalFiltersStore.filter((row) =>
      disabledItemsListInSettings.includes(row.id)
    );
    //Update dimension filters
    var payload = {
      newDimensionFilters: [...disabledDimensionFilters],
    };
    dispatchGlobalFiltersStore(replaceAllDimensionFilters(payload));
    setMetricFilters([]);

    // ! OLD IMPLEMENTATION
    // //Update dimension filters globally
    // var payload = {
    //   newDimensionFilters: [],
    // };
    // dispatchGlobalFiltersStore(replaceAllDimensionFilters(payload));
    // setMetricFilters([]);
  };

  const openMetricFilters = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "MetricFilters",
      label: "OpenMetricFilters",
    });

    //Open the metric filters
    let newGlobalFiltersProps = {
      ...user.screen.globalFilters,
      activeFilterType: "metrics", //metrics filters open
    };
    ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
  };

  const closeGlobalFilters = () => {
    //Close the filters
    const newGlobalFiltersProps = {
      ...user.screen.globalFilters,
      isOpen: false, //global filters dialog open close
      activeFilterType: "dimensions",
      activeDimensionFilter: {},
    };
    ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
  };

  //Make required variables
  //Re-order and format dimensionData values
  const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarDashboard;
  const activeFilterObj = globalFiltersStore.find(
    (filterRow) => filterRow.id === activeDimension?._id
  );
  const gfCloseIconStyle = {
    visibility:
      user.screen.globalFilters.activeFilterType === "dimensions" &&
      !user.screen.globalFilters.isAdFiltersOpen
        ? "visible"
        : "hidden",
  };
  const selectedValidFilters = globalFiltersStore.filter(
    (filterRow) => filterRow.valid
  );
  const showSelectedFilters =
    selectedValidFilters.length > 0 || metricFilters.length > 0;
  //CONFIGURABLE FEATURE
  const isAdvancedFilterAvailable = !isAdvancedFilterVisibleInSettings
    ? false
    : activeFilterObj?.metadata?.dataType?.toLowerCase() === "string"
    ? user.uiFeatureList.globalFiltersFileUpload ||
      user.uiFeatureList.globalFiltersStringMatch
    : user.uiFeatureList.globalFiltersTimestamp;
  const tourStepsData = tourData["globalFilters"].map((row) => ({
    ...row,
    placement: "auto",
  }));
  const isDimFiltersVisible =
    user.screen.globalFilters.activeFilterType === "dimensions" &&
    !user.screen.globalFilters.isAdFiltersOpen;

  const isMultiCompareRowAllowed = user.uiFeatureList.multiCompareCalendar;
  // If multi compare row is not allowed, hard code to compareCalendarRowLimit to 1

  const compareCalendarRowLimit = isMultiCompareRowAllowed
    ? user.uiLimitsList.maxCompareCalendarInDashboard
    : 1;

  const timeFiltersSettings = {
    compareCalendarRowLimit: compareCalendarRowLimit, //hard coded; it will come from /getUserAccessList
    isMultiCompareRowAllowed: isMultiCompareRowAllowed, //hard coded; it will come from /getUserAccessList
    isComparisonAvailable: true, //hard coded; it should come from where this component is being called
    isChangeTypeDropdownAvailable: true,
    isRollingDatesAvailable: false,
    isApplyButtonAvailable: false,
  };

  const buttonSplitOptions = [
    ...(isAdvancedFilterAvailable
      ? [
          {
            id: "advancedFilters",
            label: "Advanced Filters",
            disabled: false,
            onClick: handleAdvancedFiltersOpen,
          },
        ]
      : []),
    ...(user.screen.globalFilters.showMetricFilters
      ? [
          {
            id: "metricFilters",
            label: "Metric Filters",
            disabled: false,
            onClick: openMetricFilters,
          },
        ]
      : []),
  ];
  var includeExcludeData = config.hardCoded.includeExcludeJson;
  if (!isExcludeAllowedInSettings) {
    includeExcludeData = includeExcludeData.filter(
      (row) => row.id !== "exclude"
    );
  }

  return (
    <>
      {user.screen.globalFilters.isOpen && (
        <div className="global-filters-container-overlay">
          <Draggable bounds="parent" handle=".title-row-container">
            <div className="global-filters-container">
              {/* {user.screen.globalFilters.isAdFiltersOpen && (
                <section className="global-filters-container-backdrop"></section>
              )} */}
              {isDimFiltersVisible && (
                <section
                  className="title-row-container"
                  id="globalFiltersTitleRowHandler"
                >
                  <div className="left-panel">
                    {/* <span
                    className="material-icons-outlined"
                    id="globalFiltersDragIcon"
                  >
                    drag_handle
                  </span> */}
                    <p className="global-filters-title">{t("Filters")}</p>
                    <SigviewTooltip title="Start tour">
                      <i
                        className="material-icons-outlined global-filters-tour-icon"
                        onClick={() => {
                          setTourRun(true);
                        }}
                      >
                        help_outline
                      </i>
                    </SigviewTooltip>
                  </div>
                  <div className="right-panel">
                    <i
                      className="material-icons-round global-filter-close-icon"
                      onClick={handleGlobalFiltersClose}
                      style={gfCloseIconStyle}
                    >
                      close
                    </i>
                  </div>
                </section>
              )}

              <>
                {user.screen.globalFilters.showTimeFilters &&
                  isDimFiltersVisible && (
                    <section
                      className="global-time-filters-outer-container"
                      id="globalFiltersTimeFilter"
                    >
                      <section className="global-time-filters-container">
                        <TimeFilters
                          user={user}
                          allData={allData}
                          initialTimeFilters={localTimeFilters}
                          onChange={(newTimeFilters) => {
                            setLocalTimeFilters(newTimeFilters);
                          }}
                          googleAnalytics={{ category: "GlobalFilters" }}
                          calendarDaysLimits={calendarDaysLimits}
                          settings={timeFiltersSettings}
                        />
                      </section>
                    </section>
                  )}

                {/* {user.screen.globalFilters.showMetricFilters &&
                  isDimFiltersVisible && (
                    <section className="metric-filters-button-row">
                      <div
                        className="metric-filters-button-container"
                        onClick={openMetricFilters}
                      >
                        <p className="metric-filters-button-title">
                          Metric Filters
                        </p>
                        <i className="material-icons-round metric-filters-forward-icon">
                          fast_forward
                        </i>
                      </div>
                    </section>
                  )} */}

                <section
                  className={`dimension-filters-container ${
                    isDimFiltersVisible ? "" : "hidden"
                  }`}
                >
                  {isDimFiltersVisible && (
                    <>
                      <div className="dimension-dropdown-search-container">
                        <div
                          className="dimension-dropdown-container"
                          id="globalFiltersDimensionDropdown"
                        >
                          {allDimensionsList && activeDimension ? (
                            <DimensionsDropdown
                              allDimensionsList={allDimensionsList}
                              activeDimension={activeDimension}
                              globalFiltersStore={globalFiltersStore}
                              handleSelectDimensionChange={
                                handleSelectDimensionChange
                              }
                              disabledItemsList={disabledItemsListInSettings}
                            />
                          ) : (
                            <Loader />
                          )}
                        </div>
                        <div
                          className="search-dropdown-container"
                          id="searchGlobalFilters"
                        >
                          <div className="search-text-container">
                            <SigviewTextField
                              value={searchKeyWord}
                              onChange={(newValue) =>
                                setSearchKeyWord(newValue)
                              }
                              placeholder="Search in dimension"
                              minWidth="100%"
                              borderRadius="3px 0px 0px 3px"
                              border="1px solid transparent"
                              borderHover="1px solid transparent"
                              customClassName="SearchInDimensionFilter-GA"
                            />
                          </div>
                          <div
                            // className={`search-icon-container clear-text-icon-container
                            // ${
                            //   searchKeywordRef.current === searchKeyWord
                            //     ? ""
                            //     : "hidden"
                            // }`}
                            className={`search-icon-container clear-text-icon-container`}
                          >
                            <i
                              className="material-icons clear-text-icon"
                              onClick={() => {
                                searchKeywordRef.current = "";
                                setSearchKeyWord("");
                                setDimensionDataReload(!dimensionDataReload);
                              }}
                            >
                              clear
                            </i>
                          </div>
                          <div
                            className={`search-icon-container ${
                              searchKeyWord ? "" : "disabled"
                            }`}
                          >
                            <i
                              className="material-icons-round search-icon"
                              onClick={() => {
                                handleDimValueSearch();
                              }}
                            >
                              search
                            </i>
                          </div>
                        </div>
                      </div>
                      <div className="dimension-filters-options-container">
                        <section className="left-panel">
                          <div className="dimension-select-all-container dimension-select-all-container-GA">
                            <p
                              onClick={() => {
                                handleSelectAll();
                              }}
                              className="dimension-select-all-title"
                              id="selectAllDimensionsButton"
                            >
                              {t("SELECT ALL")}
                            </p>
                            <p
                              onClick={() => {
                                handleClearAll();
                              }}
                              className="dimension-select-all-title"
                              id="clearAllDimensionsButton"
                            >
                              {t("CLEAR FILTERS")}
                            </p>
                          </div>
                          <SigviewVR />
                          <div className="dimension-include-exclude-container">
                            <div id="globalFiltersIncludeExclude">
                              <SigviewRadioGroup
                                data={includeExcludeData}
                                value={
                                  activeFilterObj?.filterType || t("include")
                                }
                                onChange={(newValue) => {
                                  const payload = {
                                    filterId: activeDimension?._id,
                                    filterMetadata: activeDimension,
                                    filterType: newValue,
                                  };
                                  dispatchGlobalFiltersStore(
                                    changeFilterType(payload)
                                  );
                                }}
                                style={{ rootFlexDirection: "row" }}
                              />
                            </div>
                          </div>
                        </section>

                        <section className="right-panel">
                          <div className="dimension-advanced-filters-container">
                            {buttonSplitOptions.length === 1 && (
                              <div id="additionalFiltersButton">
                                <SigviewButton
                                  variant="containedLighter"
                                  onClick={buttonSplitOptions[0].onClick}
                                  title={buttonSplitOptions[0].label}
                                  iconFlag={false}
                                  style={{ fontSize: "11px" }}
                                  customClassName={`${rootPage}${buttonSplitOptions[0].label.replace(
                                    / +/g,
                                    ""
                                  )}Open-GA`}
                                />
                              </div>
                            )}
                            {buttonSplitOptions.length > 1 && (
                              <div id="additionalFiltersButton">
                                <SigviewButtonSplit
                                  options={buttonSplitOptions}
                                  variant="containedLighter"
                                  iconProps={{ style: { color: "#000" } }}
                                  customClassName={`-${rootPage}-GA`}
                                />
                              </div>
                            )}
                            {/* {activeFilterObj?.advancedFilters?.length > 0 && (
                            <i
                              className="material-icons-round af-filter-icon"
                              onClick={(e) => {
                                handleAdvancedFiltersOpen(e);
                              }}
                            >
                              filter_alt
                            </i>
                          )}
                          {isAdvancedFilterAvailable && (
                            <span
                              onClick={handleAdvancedFiltersOpen}
                              // className="dimension-advanced-filters-title"
                              id="advancedFiltersButton"
                            >
                              <SigviewButton
                                variant="containedLighter"
                                onClick={handleAdvancedFiltersOpen}
                                title="Advanced Filters"
                                iconFlag={false}
                                style={{ fontSize: "11px" }}
                              />
                            </span>
                          )} */}
                          </div>
                        </section>
                      </div>
                    </>
                  )}
                  <div
                    className={`dimension-values-container ${
                      isDimFiltersVisible ? "" : "hidden"
                    }`}
                    id="dimensionValuesContainer"
                  >
                    <DimensionValues
                      user={user}
                      activeDimension={activeDimension}
                      searchKeyWord={searchKeyWord}
                      setSearchKeyWord={setSearchKeyWord}
                      dimensionData={dimensionData}
                      setDimensionData={setDimensionData}
                      dimensionDataReload={dimensionDataReload}
                      setDimensionDataReload={setDimensionDataReload}
                      initialDimensionData={initialDimensionData}
                      globalFiltersStore={globalFiltersStore}
                      dispatchGlobalFiltersStore={dispatchGlobalFiltersStore}
                      localTimeFilters={localTimeFilters}
                      globalFilters={globalFilters}
                      // allData={allData}
                      filtersDimData={filtersDimData}
                      filtersMetricsData={filtersMetricsData}
                      isVisible={isDimFiltersVisible}
                      selections={selections}
                    />
                  </div>
                </section>

                {isDimFiltersVisible && (
                  <section
                    className="selected-dimension-filters-container"
                    id="selectedDimensionFilters"
                  >
                    {/* <div className="selected-dimension-filters-title">
                      {selectedFiltersTitle}
                    </div> */}
                    {showSelectedFilters ? (
                      <div className="selected-dimensions-filters-gf-container">
                        <SelectedFilters
                          onClick={(row, type) => {
                            switch (type) {
                              case "dimension":
                                handleDimFilterClick(row);
                                break;
                              case "metric":
                                handleMetricFilterClick(row);
                                break;
                            }
                          }}
                          onDelete={(row, type) => {
                            switch (type) {
                              case "dimension":
                                handleDimFilterDelete(row);
                                break;
                              case "metric":
                                handleMetricFilterDelete(row);
                                break;
                            }
                          }}
                          style={{ maxHeight: "62px" }}
                          selectedDimensionFilters={selectedValidFilters}
                          selectedMetricFilters={metricFilters}
                          allData={allData}
                          showClearAllButton={true}
                          onClearAll={handleClearAllFilters}
                          disabledItemsList={disabledItemsListInSettings}
                        />
                      </div>
                    ) : (
                      <span className="selected-dimension-filters-subtitle">
                        {t("Selected filters will appear here")}
                      </span>
                    )}
                  </section>
                )}

                {isDimFiltersVisible && (
                  <section className="global-filters-actions-bar-container">
                    <SigviewButton
                      variant="outlined"
                      onClick={() => {
                        handleGlobalFiltersClose();
                      }}
                      title="Cancel"
                      style={{ width: "auto" }}
                      disabled={!isDimFiltersVisible}
                    />
                    <SigviewButton
                      variant="contained"
                      onClick={() => handleApplyFilters()}
                      title="Apply Filters"
                      style={{ margin: { left: "10px" } }}
                      disabled={!isDimFiltersVisible}
                      customClassName={`ApplyFilters-GA  ${rootPage}ApplyFilter-GA`}
                    />
                  </section>
                )}
              </>

              {user.screen.globalFilters.isAdFiltersOpen ? (
                <AdvancedFiltersContainer
                  activeFilterObj={activeFilterObj}
                  handleAdFiltersReplacement={handleAdFiltersReplacement}
                  filterType={activeFilterObj?.filterType}
                  activeDimension={activeDimension}
                />
              ) : user.screen.globalFilters.activeFilterType === "metrics" ? (
                <MetricFiltersContainer
                  metricFilters={metricFilters}
                  setMetricFilters={setMetricFilters}
                />
              ) : null}
            </div>
          </Draggable>
        </div>
      )}
      {tourRun && (
        <Tour
          steps={tourStepsData}
          tourRun={tourRun}
          setTourRun={setTourRun}
          autoStart={true}
        />
      )}
    </>
  );
}

GlobalFilters.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(GlobalFilters);
