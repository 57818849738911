// * Import required libraies
import React from "react";
import { connect } from "react-redux";

// * Import 3rd party lib comp

// * Import Custom Component
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Loader from "../../../components/Loader/Loader";
import PivotHome from "./PivotHome";

// * Import actions

// * Import data/utils

// * Import contexts

// * Import API functions

// * Define required variables

function PivotHomeContainer(props) {
  // * Destructure props
  const {
    dispatch: ReduxDispatcher = () => {},
    user = {},
    allData = {},
  } = props;

  // * Define required static variables
  const isWsReadyToLoad =
    user.timeFilters.isLoading === false &&
    allData.plotlyMetrics !== null &&
    allData.plotlyDimensions !== null;

  return (
    <>
      {isWsReadyToLoad && <PivotHome />}
      {!isWsReadyToLoad && (
        <LayoutTopSideBottom>
          <Loader />
        </LayoutTopSideBottom>
      )}
    </>
  );
}

PivotHomeContainer.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(PivotHomeContainer);
