import { Box } from "@material-ui/core";
import React, { memo } from "react";
import isEqual from "lodash.isequal";

function areEqual(prevProps, nextProps) {
  let prev = prevProps.backgroundImage;
  let next = nextProps.backgroundImage;

  return isEqual(prev, next);
}

function AddToMetricChartIcon(props) {
  const {
    // iconStyles,
    iconStyleProp,
    isMetricInDimTablesDisabledClassName,
    id,
    backgroundImage,
    onClick,
  } = props;

  return (
    <Box
      // className={iconStyles}

      className={` ${isMetricInDimTablesDisabledClassName}`}
      id={id}
      style={{
        backgroundImage: backgroundImage,
        ...iconStyleProp,
      }}
      onClick={onClick}
    ></Box>
  );
}

export default memo(AddToMetricChartIcon, areEqual);
