// Import required libraries
import { config } from "../config/config";
import _ from "underscore";
import moment from "moment";
import isEqual from "lodash.isequal";
import { v4 } from "uuid";
import * as d3 from "d3";

// Import custom utils
import {
  transformFiltersUiToBackend,
  transformMetricFiltersUiToBackend,
} from "./filtersUtils";
import { getMetricTypeForChartObject } from "./plotlyUtils";
import {
  getDatePresetObj,
  getPrimaryCompareDateObject,
} from "./timeFiltersUtils";
import { isEmbeddedUser } from "./authUtils";

// Import data
import allDatePresets from "../../assets/data/allDatePresets.json";

// Defining required variables
const orderByTypeMapping = {
  id_only: "current",
  id_trueDelta: "_trueDelta",
  id_deltaPercentage: "_deltaPercentage",
};

export const makeDefaultResponseJson = () => ({
  result: { data: [] },
  error: "",
  status: { statusCode: "", statusMessage: "" },
});

export const log = (title, message) => {
  console.log(`${title} ---> `, message);
};

export const getChartTitle = (allData, id) => {
  let allMetricsAndDimensions = [
    ...allData.plotlyMetrics,
    ...allData.plotlyDimensions,
  ];
  const object = allMetricsAndDimensions.find((row) => row._id === id);
  return object?.["_title"] || config.messages.noTitle;
};

export const getChartId = (allData, id) => {
  let allMetrics = {
    ...allData.metricList.data,
    ...allData.dimensionList.data,
    ...allData.customMetricList.data,
  };
  let chartId = allMetrics[id]?._id;
  return chartId;
};

export const getActiveViewObjFromViewsArr = (viewsArr) => {
  if (viewsArr === null || viewsArr === undefined) return null;
  var activeViewObj = viewsArr.find((view) => view.isActive);
  if (activeViewObj === undefined) activeViewObj = viewsArr[0];
  return activeViewObj;
};

export const extractReqMetadataFromMetadata = (metadata, auth) => {
  if (
    metadata === null ||
    metadata === undefined ||
    auth.status !== "success" ||
    metadata.status !== "success"
  )
    return {};
  const activeOrg = getActiveOrg(metadata.data);
  const activeOrgObject = getActiveOrgObject(metadata.data);
  let reqMetadata = {
    email: metadata.data?.email,
    view: getActiveViewObjFromViewsArr(activeOrgObject.viewInfoList)?.name,
    organization: activeOrg,
    userPreferredLanguage:
      metadata.data?.userPreferredLanguage ||
      config.hardCoded.userPreferredLanguage,
    groupId: getActiveViewObjFromViewsArr(activeOrgObject.viewInfoList)
      ?.groupInfoList[0]?.groupId,
    userType: auth.data["openx-userType"],
    userId: metadata.data.userId,
    jobRole: getActiveViewObjFromViewsArr(activeOrgObject.viewInfoList)
      ?.jobRole,
    session_id: v4(), // For Querry cancellation
  };
  return reqMetadata;
};

function isPercentageOfTotalValid(inputExp) {
  var inputExpression = inputExp;
  var number = new RegExp("[0-9]*\\.?[0-9]+", "g");
  var space = new RegExp(" ", "g");

  var getId = (function () {
    var counter = 0;
    return function () {
      return (counter += 1);
    };
  })();

  function fixParenthesis(inputExpression) {
    var stack = {
      arr: [],
      recentOpenParenthesisIndex: -1,
      pop: function () {
        return this.arr.splice(this.arr.length - 1, 1);
      },
      push: function (elem) {
        if (elem === "(") this.recentOpenParenthesisIndex = this.arr.length;
        this.arr.push(elem);
      },
      reset: function () {
        this.arr = [];
        this.recentOpenParenthesisIndex = -1;
      },
    };

    function removeExtraParenthesis(expression) {
      let newExp = "";
      for (var ind = 0; ind < expression.length; ind++) {
        if (expression[ind] === ")") {
          if (2 === ind - stack.recentOpenParenthesisIndex) {
            var el = stack.pop();
            stack.pop();
            stack.push(el);
          } else {
            stack.push(expression[ind]);
          }
        } else {
          stack.push(expression[ind]);
        }
      }

      newExp = stack.arr.join("");
      stack.reset();

      if (expression === newExp) return newExp;
      else return removeExtraParenthesis(newExp);
    }

    var newExpression = removeExtraParenthesis(inputExpression);

    stack.reset();
    var appendParenthesis = false;

    if (newExpression[0] !== "(" || _.last(newExpression) !== ")") {
      appendParenthesis = true;
    } else {
      var count = 1;
      for (var ind = 1; ind < newExpression.length - 1; ind++) {
        if (newExpression[ind] === "(") {
          count++;
        }
        if (newExpression[ind] === ")") {
          count--;
          if (count === 0) appendParenthesis = true;
        }
      }
    }

    return appendParenthesis ? "(" + newExpression + ")" : newExpression;
  }

  function createNode() {
    var node = {
      id: getId(),
      left: null,
      right: null,
      value: null,
      parent: null,
    };

    return node;
  }

  function createExpressionTree(expression) {
    var root = createNode();
    var currentNode = root;
    let newNode;

    for (var ind = 0; ind < expression.length; ind++) {
      switch (expression[ind]) {
        case "(":
          newNode = createNode();

          newNode.parent = currentNode;

          currentNode.left = newNode;
          currentNode = newNode;

          break;

        case "+":
          currentNode.value = expression[ind];

          newNode = createNode();
          newNode.parent = currentNode;

          currentNode.right = newNode;
          currentNode = newNode;

          break;

        case "-":
          currentNode.value = expression[ind];

          newNode = createNode();
          newNode.parent = currentNode;

          currentNode.right = newNode;
          currentNode = newNode;

          break;

        case "/":
          currentNode.value = expression[ind];

          newNode = createNode();
          newNode.parent = currentNode;

          currentNode.right = newNode;
          currentNode = newNode;

          break;

        case "*":
          currentNode.value = expression[ind];

          newNode = createNode();
          newNode.parent = currentNode;

          currentNode.right = newNode;
          currentNode = newNode;

          break;

        case ")":
          currentNode = currentNode.parent;
          break;

        default:
          currentNode.value = expression[ind];
          currentNode = currentNode.parent;
          break;
      }
    }

    return root;
  }

  function traverse(node) {
    var left = null;
    var right = null;

    if (node.left === null && node.right === null) {
      // reached leaf node
      return node.value === "#" ? "Number" : "Variable";
    } else {
      // operator

      left = traverse(node.left);
      right = traverse(node.right);

      if (left === "Failure" || right === "Failure") return "Failure";
      switch (node.value) {
        case "+":
          if (left === "Number") {
            return right === "Number" ? "Number" : "Variable";
          } else {
            return "Variable";
          }
          break;
        case "-":
          if (left === "Number") {
            return right === "Number" ? "Number" : "Variable";
          } else {
            return "Variable";
          }
          break;
        case "*":
          if (left === "Number") {
            return right === "Number" ? "Number" : "Variable";
          } else {
            return right === "Number" ? "Variable" : "Failure";
          }
          break;
        case "/":
          if (left === "Number") {
            return right === "Number" ? "Number" : "Variable";
          } else {
            return right === "Number" ? "Variable" : "Failure";
          }
          break;
      }
    }
  }

  // replace numbers with # symbol
  inputExpression = inputExpression.replace(number, "#");

  // remove spaces
  inputExpression = inputExpression.replace(space, "");

  try {
    inputExpression = fixParenthesis(inputExpression);

    var rootNode = createExpressionTree(inputExpression);

    return traverse(rootNode) !== "Failure";
  } catch (error) {
    return false;
  }
}

export const transformMetricsForPlotly = (metricList, customMetricList) => {
  let updMetricList = [],
    updCustomMetricList = [];
  _.each(metricList, function (metricObj) {
    metricObj.isPercentCalOn = _.contains(metricObj.aggregationList, "sum");

    var obj = {
      measureID: metricObj.metricId,
      measureName: metricObj.metricId,
      measureTitle: metricObj.title,
      measureInfo: "default",
      formula: "None",
      dataType: "Numeric",
      status: true,
      isPercentCalOn: metricObj.isPercentCalOn,
      dataUnit: metricObj.dataUnit,
      aggregationList: metricObj.aggregationList,
      _id: metricObj._id,
      _title: metricObj.title,
    };
    updMetricList.push(obj);
  });

  _.each(customMetricList, function (metricObj) {
    if (metricObj && metricObj.isPercentageOn) {
      metricObj.isPercentCalOn = metricObj.isPercentageOn;
    } else {
      metricObj.isPercentCalOn = isPercentageOfTotalValid(
        metricObj.dummyFormula
      );
    }

    var obj = {
      measureID: metricObj._id,
      measureName: metricObj._id,
      measureTitle: metricObj.title,
      measureInfo: "custom",
      formula: metricObj.originalFormula,
      dataType: "Numeric",
      status: true,
      isPercentCalOn: metricObj.isPercentCalOn,
      dataUnit: metricObj.dataUnit,
      displayFormula: metricObj.displayFormula || undefined,
      isFormulaVisible: metricObj.isFormulaVisible,
      _id: metricObj._id,
      _title: metricObj.title,
      //"idsMap": metricObj.idsMap || undefined
    };
    updCustomMetricList.push(obj);
  });

  return [...updMetricList, ...updCustomMetricList];
};

export const transformDimensionsForPlotly = (dimensionList) => {
  let updDimensionList = [];
  _.each(dimensionList, function (dimObj) {
    var obj = {
      dimID: dimObj.dimId,
      dimName: dimObj.dimId,
      dimTitle: dimObj.title,
      dimInfo: dimObj.dimId,
      dataType: dimObj.dataType,
      status: true,
      _id: dimObj._id,
      _title: dimObj.title,
    };
    updDimensionList.push(obj);
  });
  return updDimensionList;
};

export const getGoogleAnalyticsCustomDimensions = (props) => {
  const { reqMetadata, currTab } = props;
  return {
    dimension1: reqMetadata.organization, //organization
    dimension2: reqMetadata.view, //view
    dimension3: reqMetadata.groupId, //group
    dimension4: reqMetadata.userId, //userId
    dimension5: reqMetadata.userType, //userType
    dimension6: currTab, //tab
  };
};

export const logoutUser = (newUrl) => {
  localStorage.removeItem("openxUserData");
  localStorage.removeItem("satellizer_token");
  localStorage.removeItem("user_email");
  if (newUrl) {
    window.location.href = newUrl;
    window.location.reload();
  }
};

export const getCounterContainerType = ({ width, height }) => {
  if (width <= 130 || height <= 45) return "small";
  if (width >= 200 && height >= 120) return "large";
  return "medium";
};

function prettifyTimeLag(_timeInMS) {
  var _timeLeft = _timeInMS;
  var days = Math.floor(_timeLeft / 86400000);
  _timeLeft -= days * 86400000;

  var hours = Math.floor(_timeLeft / 3600000);
  _timeLeft -= hours * 3600000;

  var minutes = Math.floor(_timeLeft / 60000);
  _timeLeft -= minutes * 60000;

  //console.log("DATA LAG:", days + "days, ", hours + "hours, ", minutes + "minutes");

  if (days > 0) return days + " days, " + hours + " hrs";
  if (hours > 0) {
    if (hours === 1) {
      return hours + " hr, " + minutes + " mins";
    } else {
      return hours + " hrs, " + minutes + " mins";
    }
  }
  if (minutes >= 0) return minutes + " mins";
}

export const getTimeLag = (allData) =>
  prettifyTimeLag(
    moment.utc().valueOf() - Number(allData.dateRange.data.endDate)
  );

export const removeDupFromArray = (arr) => {
  let newArr = [];
  arr.forEach((val) => {
    newArr.indexOf(val) === -1 && newArr.push(val);
  });
  return newArr;
};

export const navigateToAngular = (props) => {
  let { redirectToAngular, user, angularTabId } = props;

  //Dispatch action redirectToAngular which will:
  //  1) Change activeTab to home
  //  2) Empty all filters
  redirectToAngular();

  //Make the react root component hidden
  document.getElementById("react_root").style.visibility = "hidden";

  //Update local storage so that react code can listen to it
  localStorage.setItem("renderReactDom", "no");

  //Make body overflow auto when user navigates back to angular
  var bodyTag = document.getElementsByTagName("BODY")[0];
  bodyTag.style.overflow = "auto";

  //Update local storage so that angular code can use it
  localStorage.setItem("current_angular_tab", angularTabId);

  //Update local storage with the activeView for angular to act upon
  localStorage.setItem("activeView", user?.reqMetadata?.view || "CPR");

  //Change location to analyze page
  // let newUrl = `${window.location.origin}/app/#/home/${navItem.id}`;
  // window.location.href = newUrl;
  // window.location.reload();

  //Dispatch react custom event so that react code can listen to it to hide the elements
  // window.dispatchEvent(reactReloadEvent);
  console.log("DISPATCHING CUSTOM EVENT for react reload");

  // Dispatch the event so that angular code can listen to it
  // window.dispatchEvent(angularReloadEvent);
  console.log("DISPATCHING CUSTOM EVENT for angular reload");
};

export const getNewDatastoryObject = (data, newLayout) => {
  let mappingLayout = {};
  newLayout.forEach((row) => {
    mappingLayout[row.i] = {
      i: row.i,
      x: row.x,
      y: row.y,
      w: row.w,
      h: row.h,
    };
  });
  let newData = {
    ...data,
    datastoryParam: {
      chartList: data.datastoryParam.chartList.map((row) => ({
        ...row,
        container: {
          ...row.container,
          metadata: mappingLayout[row.container.id],
        },
      })),
      metricList: data.datastoryParam.metricList.map((row) => ({
        ...row,
        container: {
          ...row.container,
          metadata: mappingLayout[row.container.id],
        },
      })),
    },
  };
  return newData;
};

export const getActiveOrg = (userMetadata) => {
  if (
    userMetadata === null ||
    userMetadata === undefined ||
    userMetadata.activeOrg === undefined
  )
    return null;

  // * The below is to cater to a case where the activeOrg and userMetadata.orgInfoList are not in sync (SSO User)

  // Get active org from userMetadata
  var activeOrg = userMetadata.activeOrg;

  // Check if that org is present in userMetadata.orgInfoList
  var activeOrgObject = userMetadata.orgInfoList.find(
    (row) => row.name === activeOrg
  );

  // If not, make first available org as the activeOrg
  if (activeOrgObject === undefined) {
    activeOrg = userMetadata.orgInfoList[0].name;
  }

  return activeOrg;
};

export const getActiveOrgObject = (userMetadata) => {
  if (
    userMetadata === null ||
    userMetadata === undefined ||
    userMetadata.activeOrg === undefined
  )
    return null;

  // Since activeOrg is same for normal and embedded user
  // A case occurs where the backend sends activeOrg as DemoTest which is not present in the orgInfoList
  // since the user is embedded and has access to only one org
  // Post a dicsussion with the backend team, they will be sending the correct org going forward
  // But putting this login in UI as well so that the UI doesn't break
  var activeOrgObject = {};
  var activeOrg = "";
  if (isEmbeddedUser()) {
    activeOrg = userMetadata.activeOrg;
    activeOrgObject = userMetadata.orgInfoList.find(
      (row) => row.name === activeOrg
    );
    if (activeOrgObject === undefined) {
      activeOrgObject = userMetadata.orgInfoList[0];
    }
  } else {
    activeOrg = userMetadata.activeOrg;
    activeOrgObject = userMetadata.orgInfoList.find(
      (row) => row.name === activeOrg
    );
    if (activeOrgObject === undefined) {
      activeOrgObject = userMetadata.orgInfoList[0];
    }
  }

  return activeOrgObject;
};

export const getSigviewUserType = () => {
  const hostname = window.location.hostname;
  const sigviewUserType =
    config.hardCoded.sigviewUrlUserTypeMapping[hostname] || "sigview";
  return sigviewUserType;
};

export const isFeatureAvailable = (allAvailableFeatures, featureId) => {
  const featureObj = allAvailableFeatures.find(
    (row) => row.featureId === featureId
  );
  const isFeatureAvailable = featureObj ? true : false;
  return isFeatureAvailable;
};

export const getLimitValueFromLimitList = (allLimits, limitName) => {
  const limitObj = allLimits?.find((row) => row.limitName === limitName);
  const limitValue = limitObj?.limitValue;
  return limitValue;
};

export const removeInvalidRows = (state) => state.filter((row) => row.valid);

export const getBaseUrlEndpoints = () => {
  const hostname = window.location.hostname;
  const mapper = config.hardCoded.hostnameBackendApiMapping[hostname];
  const defaultMapper =
    config.hardCoded.hostnameBackendApiMapping[
      config.hardCoded.defaultHostnameId
    ];
  var newApiEndPoints = {};
  if (mapper !== undefined) {
    newApiEndPoints = {
      baseUrl: mapper.baseUrl,
      baseUrlOld: mapper.baseUrlOld,
      dataUrl: mapper.dataUrl,
    };
  } else {
    newApiEndPoints = {
      baseUrl: defaultMapper.baseUrl,
      baseUrlOld: defaultMapper.baseUrlOld,
      dataUrl: defaultMapper.dataUrl,
    };
  }
  return newApiEndPoints;
};

export const validateEmailAddress = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const masterMakeChartObject = (props) => {
  const {
    metadataParams,
    filters,
    orderByDetails,
    dimensionsList,
    metricsList,
    percentCalList = [],
    settings = {},
    granularity,
  } = props;
  const { compareFlag = true, progressiveDateFlag = false } = settings;
  const timeFilters = filters.timeFilters;
  const primaryCompareDateObject = getPrimaryCompareDateObject(timeFilters);
  const dateRangeType = getDatePresetObj(
    timeFilters.selectedDatePreset,
    allDatePresets,
    "id"
  ).name;
  const compareDate =
    timeFilters.isComparisonOn && compareFlag
      ? {
          startDate:
            primaryCompareDateObject.compareSelectedDatesQESameDuration.startDate.toString(),
          endDate:
            primaryCompareDateObject.compareSelectedDatesQESameDuration.endDate.toString(),
        }
      : undefined;
  const timeFiltersParams = {
    timeZone: {
      location: timeFilters.selectedTimezone.location,
      name: timeFilters.selectedTimezone.name,
    },
    dateRange: {
      startDate: timeFilters.selectedDatesQE.startDate.toString(),
      endDate: timeFilters.selectedDatesQE.endDate.toString(),
    },
    dateRangeType,
    ...(compareDate ? { compareDate } : {}),
  };
  const dimensionsParams = { xAxis: dimensionsList.map((row) => row._id) };
  let yAxis = [],
    specialCalculation = [],
    approxCountDistinct = [];
  metricsList.forEach((metricRow) => {
    const metricType = getMetricTypeForChartObject(metricRow);
    const metricRowId = metricRow._id;
    if (metricType === "base_sum") {
      yAxis.push(metricRowId);
    } else if (metricType === "custom") {
      specialCalculation.push(metricRowId);
    } else if (metricType === "base_approxCountDistinct") {
      approxCountDistinct.push(metricRowId);
    }
  });
  const orderByMetricType = orderByDetails.orderById.startsWith("D")
    ? undefined
    : orderByTypeMapping[orderByDetails.orderByType];
  const orderById = orderByMetricType
    ? orderByMetricType !== "current"
      ? `${orderByDetails.orderById}${orderByMetricType}`
      : orderByDetails.orderById
    : orderByDetails.orderById;
  const orderByObject = {
    id: orderById,
    desc: orderByDetails.orderBy === "desc",
    ...(orderByMetricType ? { metricType: orderByMetricType } : {}),
  };
  const orderBy = orderByDetails.orderById.startsWith("D")
    ? { dimOrdByList: [orderByObject] }
    : orderByDetails.orderById.startsWith("M")
    ? { metricOrdByList: [orderByObject] }
    : { customMetricOrdByList: [orderByObject] };
  const metricsParams = {
    yAxis,
    approxCountDistinct,
    specialCalculation,
  };
  const percentCalListFinal =
    metadataParams?.chartType === "pie"
      ? [{ id: metricsList[0]._id }]
      : percentCalList.map((row) => ({ id: row._id })); //hard coded
  const otherParams = { percentCalList: percentCalListFinal };
  const metadata = {
    title: metadataParams?.title || "",
    img_thumbnail: metadataParams?.img_thumbnail || "../img/chart.png",
    chartType: metadataParams?.chartType || "table",
    dataLimit: metadataParams?.dataLimit || 500,
    comparisonMode: metadataParams?.comparisonMode || false,
  };
  const progressiveDate = progressiveDateFlag.toString() || "false";
  const requestParam = {
    ...timeFiltersParams,
    ...dimensionsParams,
    ...metricsParams,
    ...otherParams,
    granularity,
    progressiveDate,
    orderBy,
    filter: transformFiltersUiToBackend(filters.dimensionFilters),
    metricFilter: transformMetricFiltersUiToBackend(filters.metricFilters),
  };
  const chartObject = {
    metadata,
    requestParam,
  };

  return chartObject;
};
export const masterMakeChartObjectReport = (props) => {
  const {
    metadataParams,
    filters,
    granularity,
    orderByDetails,
    dimensionsList,
    metricsList,
    percentCalList = [],
    settings = {},
  } = props;
  const { compareFlag = true, progressiveDateFlag = false } = settings;
  const timeFilters = filters.timeFilters;
  const primaryCompareDateObject = getPrimaryCompareDateObject(timeFilters);
  const dateRangeType = getDatePresetObj(
    timeFilters.selectedDatePreset,
    allDatePresets,
    "id"
  ).name;
  const compareDate =
    timeFilters.isComparisonOn && compareFlag
      ? {
          startDate:
            primaryCompareDateObject.compareSelectedDates.startDate.epoch.toString(),
          endDate: (
            primaryCompareDateObject.compareSelectedDates.endDate.epoch + 1
          ).toString(),
        }
      : undefined;
  const timeFiltersParams = {
    timeZone: {
      location: timeFilters.selectedTimezone.location,
      name: timeFilters.selectedTimezone.name,
    },
    dateRange: {
      startDate: timeFilters.selectedDatesQE.startDate.toString(),
      endDate: timeFilters.selectedDatesQE.endDate.toString(),
    },
    dateRangeType,
    ...(compareDate ? { compareDate } : {}),
  };
  const dimensionsParams = { xAxis: dimensionsList.map((row) => row._id) };
  let yAxis = [],
    specialCalculation = [],
    approxCountDistinct = [];
  metricsList.forEach((metricRow) => {
    const metricType = getMetricTypeForChartObject(metricRow);
    const metricRowId = metricRow._id;
    if (metricType === "base_sum") {
      yAxis.push(metricRowId);
    } else if (metricType === "custom") {
      specialCalculation.push(metricRowId);
    } else if (metricType === "base_approxCountDistinct") {
      approxCountDistinct.push(metricRowId);
    }
  });
  const orderByMetricType = orderByDetails.orderById.startsWith("D")
    ? undefined
    : orderByTypeMapping[orderByDetails.orderByType];
  const orderById = orderByMetricType
    ? orderByMetricType !== "current"
      ? `${orderByDetails.orderById}${orderByMetricType}`
      : orderByDetails.orderById
    : orderByDetails.orderById;
  const orderByObject = {
    id: orderById,
    desc: orderByDetails.orderBy === "desc",
    ...(orderByMetricType ? { metricType: orderByMetricType } : {}),
  };
  const orderBy = orderByDetails.orderById.startsWith("D")
    ? { dimOrdByList: [orderByObject] }
    : orderByDetails.orderById.startsWith("M")
    ? { metricOrdByList: [orderByObject] }
    : { customMetricOrdByList: [orderByObject] };
  const metricsParams = {
    yAxis,
    approxCountDistinct,
    specialCalculation,
  };
  const percentCalListFinal =
    metadataParams?.chartType === "pie"
      ? [{ id: metricsList[0]._id }]
      : percentCalList.map((row) => ({ id: row._id })); //hard coded
  const otherParams = { percentCalList: percentCalListFinal };
  const metadata = {
    title: metadataParams?.title || "",
    img_thumbnail: metadataParams?.img_thumbnail || "../img/chart.png",
    chartType: metadataParams?.chartType || "table",
    dataLimit: metadataParams?.dataLimit || 500,
    comparisonMode: metadataParams?.comparisonMode || false,
  };
  const progressiveDate = progressiveDateFlag.toString() || "false";
  const requestParam = {
    ...timeFiltersParams,
    ...dimensionsParams,
    ...metricsParams,
    ...otherParams,
    progressiveDate,
    granularity,
    orderBy,
    filter: transformFiltersUiToBackend(filters.dimensionFilters),
    metricFilter: transformMetricFiltersUiToBackend(filters.metricFilters),
  };
  const chartObject = {
    metadata,
    requestParam,
  };

  return chartObject;
};

export const getUniqueArrayByKey = (array, key) => [
  ...new Map(array.map((item) => [item[key], item])).values(),
];

//This function can be replaced with react-timeago
export const timeSinceCreation = (creationTimeUTC) => {
  creationTimeUTC = parseInt(creationTimeUTC);
  var diff = (moment.utc().valueOf() - Number(creationTimeUTC)) / 1000;
  var days = Math.round(diff / 86400);
  var hours = Math.round(diff / 3600);
  var minutes = Math.round(diff / 60);
  if (days > 0) return days === 1 ? "1 day ago" : days + " days ago";
  if (hours > 0) return hours === 1 ? "1 hour ago" : hours + " hours ago";
  if (minutes > 0)
    return minutes === 1 ? "1 minute ago" : minutes + " minutes ago";
  else return "1 minute ago";
};

export const capitalize = (text) => {
  if (typeof text === "string" || text instanceof String)
    return text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
  return "";
};

export const objectFlip = (obj) => {
  return Object.entries(obj).reduce((ret, entry) => {
    const [key, value] = entry;
    ret[value] = key;
    return ret;
  }, {});
};

// Convert big numbers to B, M, K
export function formatValueForMetricFilter(value) {
  value = parseInt(value);
  if (value >= 1000000000) {
    return Number(Math.abs(value / 1000000000).toFixed(1)) + "B";
  } else if (value >= 1000000) {
    return Number(Math.abs(value / 1000000).toFixed(1)) + "M";
  } else if (value >= 1000) {
    return Number(Math.abs(value / 1000).toFixed(1)) + "K";
  } else {
    return value;
  }
}

export const checkIfElementIsOverflowing = (el) => {
  var curOverf = el.style.overflow;

  if (!curOverf || curOverf === "visible") el.style.overflow = "hidden";

  var isOverflowing =
    el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

  el.style.overflow = curOverf;

  return isOverflowing;
};

export const getEmbedInfo = (user) => {
  let tokenKey = "satellizer_token";
  const embeddedFlag = user.embeddedInfo.isEmbeddedUser;
  const clientId = user.embeddedInfo.clientId;
  if (embeddedFlag) {
    tokenKey = `${clientId}_token`;
  }
  const xAuthToken = localStorage.getItem(tokenKey);
  const embedObject = { embeddedFlag, clientId, tokenKey, xAuthToken };

  // * DEBUGGER
  // console.groupCollapsed("getEmbedInfo")
  // console.log("%cxAuthToken", "color: yellow; font-size: 15px;");
  // console.log("xAuthToken", xAuthToken);
  // console.log("%cFINAL EMBED OBJECT", "color: yellow; font-size: 15px;");
  // console.log(embedObject);
  // console.groupEnd()
  return embedObject;
};

const isCurrentRouteValid = (location, uiFeatureList) => {
  // Example 1
  // Route ---> /analyze/dashboard
  // Root Page ---> analyze
  // featureList = { analyze: true }
  // Since analyze is present in the url and the analyze is true, it's an valid URL

  // Example 2
  // Route ---> /report
  // Root Page ---> report
  // featureList = { reportManagerAsTab: false }
  // Since report is present in the url and the reportManagerAsTab is false, it's an invalid URL

  const validRootPageToFeaturesMapping =
    config.hardCoded.validRootPageToFeaturesMapping;
  const pathname = location.pathname;
  const rootPage = pathname.split("/")[1]; // 0th element is empty, 1st element is the root page

  // In case the user is on the root url (sigview.sigmoid.io)
  // Consider it a valid URL
  if (rootPage === undefined || rootPage === "") {
    return true;
  }

  const rootPageFeaturesMapping = validRootPageToFeaturesMapping[rootPage];
  const rootPageFeatureList = rootPageFeaturesMapping.requiredFeatureList;
  const condition = rootPageFeaturesMapping.condition;
  const finalFlagArr = [];
  for (const requiredFeature of rootPageFeatureList) {
    const isRequiredFeatureAvailable = uiFeatureList[requiredFeature];
    finalFlagArr.push(isRequiredFeatureAvailable);
  }

  if (condition === "or") {
    return finalFlagArr.some((flag) => flag === true);
  }
  if (condition === "and") {
    return finalFlagArr.every((flag) => flag === true);
  }

  // URL is valid
  return true;
};

export const checkAppError = (props = {}) => {
  const { mandatoryApiListData } = props;

  // * CHECKLIST
  // 1. If any of the mandatory APIs fail
  // 2. If the route is not a part of features list

  // 1. If any of the mandatory APIs fail
  if (mandatoryApiListData === undefined || mandatoryApiListData === null) {
    return { errorFlag: false, errorMessage: "" };
  }
  const arrWithErrorStatus = mandatoryApiListData.filter(
    (row) => row.status === "error"
  );
  if (arrWithErrorStatus.length > 0) {
    return {
      errorFlag: true,
      errorMessage: arrWithErrorStatus[0]?.message || "Something went wrong",
    };
  }

  // ! COMMENTING OUT isCurrentRouteValid which makes our application configurable at
  // ! tab level since it needs more work and is currently unstable
  // // 2. If the route is not a part of features list
  // const isCurrentRouteValidFlag = isCurrentRouteValid(location, uiFeatureList);
  // if (!isCurrentRouteValidFlag) {
  //   return {
  //     errorFlag: true,
  //     errorMessage: config.messages.featureUnavailable,
  //   };
  // }

  // No App Error
  return { errorFlag: false, errorMessage: "" };
};

export const getUiFeatureList = (featureAccessList) => {
  //Getting user type
  const sigviewUserType = getSigviewUserType();

  //Defining feature IDs as variables
  const pivotFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].pivot;
  const plotFeatureId = config.hardCoded.featureIdMapping[sigviewUserType].plot;
  const datastoryFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].datastory;
  const adFiltersFileUploadFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].globalFiltersFileUpload;
  const adFiltersStringMatchFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].globalFiltersStringMatch;
  const adFiltersTimestampFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].globalFiltersTimestamp;
  const downloadFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].download;
  const fullReportFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].fullReport;
  const metricFiltersFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].metricFilters;
  const reportManagerFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].reportManager;
  const alertManagerFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].alertManager;
  const multipleComparisonCalendarsFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType]
      .multipleComparisonCalendars;
  const hourGranularityFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].hourGranularity;
  const dayGranularityFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].dayGranularity;
  const monthGranularityFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].monthGranularity;
  const reportManagerAsTabFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].reportManagerAsTab;
  const reportManagerAsSidenavFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].reportManagerAsSidenav;
  const alertManagerAsTabFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].alertManagerAsTab;
  const alertManagerAsSidenavFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].alertManagerAsSidenav;
  const analyzeFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].analyze;
  const workspaceShareFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].workspaceShare;

  const chartTypeFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].chartType;
  const overlayUpdatesFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].overlayUpdates;
  const forcedRefreshToastFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].forcedRefreshToast;
  const customMetricInAdminFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].customMetricInAdmin;
  const i18nFeatureId = config.hardCoded.featureIdMapping[sigviewUserType].i18n;
  const bannerNotificationFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].bannerNotification;
  const bannerLinkFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].bannerLink;

  //Making variables for feature availability
  const isPivotAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    pivotFeatureId
  );
  const isPlotAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    plotFeatureId
  );
  const isDatastoryAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    datastoryFeatureId
  );
  const isAdFilterFileUploadAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    adFiltersFileUploadFeatureId
  );
  const isAdFilterStringMatchAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    adFiltersStringMatchFeatureId
  );
  const isAdFilterTimestampAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    adFiltersTimestampFeatureId
  );
  const isDownloadAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    downloadFeatureId
  );
  const isFullReportAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    fullReportFeatureId
  );
  const isMetricFiltersAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    metricFiltersFeatureId
  );
  const isReportManagerAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    reportManagerFeatureId
  );
  const isAlertManagerAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    alertManagerFeatureId
  );
  const isMultiCompareCalendarAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    multipleComparisonCalendarsFeatureId
  );
  const workspaceShareAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    workspaceShareFeatureId
  );
  const isHourGranularityAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    hourGranularityFeatureId
  );
  const isDayGranularityAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    dayGranularityFeatureId
  );
  const isMonthGranularityAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    monthGranularityFeatureId
  );
  const isReportManagerAsTabAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    reportManagerAsTabFeatureId
  );
  const isReportManagerAsSidenavAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    reportManagerAsSidenavFeatureId
  );
  const isAlertManagerAsTabAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    alertManagerAsTabFeatureId
  );
  const isAlertManagerAsSidenavFAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    alertManagerAsSidenavFeatureId
  );
  const isAnalyzeAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    analyzeFeatureId
  );
  const isChartTypeAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    chartTypeFeatureId
  );
  const isoverlayUpdatesAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    overlayUpdatesFeatureId
  );
  const isforcedRefreshToastAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    forcedRefreshToastFeatureId
  );

  const iscustomMetricInAdminAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    customMetricInAdminFeatureId
  );
  const isi18nAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    i18nFeatureId
  );

  const isbannerNotificationAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    bannerNotificationFeatureId
  );

  const isbannerLinkAvailable = isFeatureAvailable(
    featureAccessList.accessList,
    bannerLinkFeatureId
  );
  return {
    datastory: isDatastoryAvailable,
    pivot: isPivotAvailable,
    plot: isPlotAvailable,
    globalFiltersFileUpload: isAdFilterFileUploadAvailable,
    globalFiltersStringMatch: isAdFilterStringMatchAvailable,
    globalFiltersTimestamp: isAdFilterTimestampAvailable,
    download: isDownloadAvailable,
    fullReport: isFullReportAvailable,
    metricFilters: isMetricFiltersAvailable,
    reportManager: isReportManagerAvailable,
    alertManager: isAlertManagerAvailable,
    multiCompareCalendar: isMultiCompareCalendarAvailable,
    workspaceShare: workspaceShareAvailable,
    // ! HARD CODED : This is currently not coming from feature access list;
    // ! Hence, commenting the actual code and hard coding to true
    // ! Revert when it starts coming from feature access list
    hourGranularity: true,
    dayGranularity: true,
    // hourGranularity: isHourGranularityAvailable,
    // dayGranularity: isDayGranularityAvailable,
    monthGranularity: isMonthGranularityAvailable,
    analyze: isAnalyzeAvailable,
    // ! HARD CODED for now as they don't come from feature list
    reportManagerAsTab: false,
    reportManagerAsSidenav: true,
    alertManagerAsTab: false,
    alertManagerAsSidenav: true,
    // reportManagerAsTab: isReportManagerAsTabAvailable,
    // reportManagerAsSidenav: isReportManagerAsSidenavAvailable,
    // alertManagerAsTab: isAlertManagerAsTabAvailable,
    // alertManagerAsSidenav: isAlertManagerAsSidenavFAvailable,
    chartType: isChartTypeAvailable,
    overlayUpdates: isoverlayUpdatesAvailable,
    forcedRefreshToast: isforcedRefreshToastAvailable,
    customMetricInAdmin: iscustomMetricInAdminAvailable,
    i18n: isi18nAvailable,
    bannerNotification: isbannerNotificationAvailable,
    bannerLink: isbannerLinkAvailable,
  };
};

export const getUiLimitList = (featureAccessList) => {
  //Getting user type
  const sigviewUserType = getSigviewUserType();

  //Defining feature IDs as variables
  const daysLimitCalendarDashboardFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType]
      .daysLimitCalendarDashboard;
  const daysLimitCalendarReportsFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].daysLimitCalendarReports;
  const maxSavedReportsFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].maxSavedReports;
  const maxScheduledReportsFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].maxScheduledReports;
  const maxFileUploadRowCountFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].maxFileUploadRowCount;
  const maxFileUploadSizeInMBFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].maxFileUploadSizeInMB;
  const maxCompareCalendarInDashboardFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType]
      .maxCompareCalendarInDashboard;
  const maxCompareCalendarInReportsFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType]
      .maxCompareCalendarInReports;
  const minHourGranDurationInDaysFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType]
      .minHourGranDurationInDays;
  const maxHourGranDurationInDaysFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType]
      .maxHourGranDurationInDays;
  const minDayGranDurationInDaysFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].minDayGranDurationInDays;
  const maxDayGranDurationInDaysFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].maxDayGranDurationInDays;
  const minMonthGranDurationInDaysFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType]
      .minMonthGranDurationInDays;
  const maxMonthGranDurationInDaysFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType]
      .maxMonthGranDurationInDays;
  const maxSavedAlertsFeatureId =
    config.hardCoded.featureIdMapping[sigviewUserType].maxSavedAlerts;

  //Making variables for feature availability
  const daysLimitCalendarDashboard =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      daysLimitCalendarDashboardFeatureId
    ) || config.hardCoded.defaultLimitList.daysLimitCalendarDashboard;
  const daysLimitCalendarReports =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      daysLimitCalendarReportsFeatureId
    ) || config.hardCoded.defaultLimitList.daysLimitCalendarReports;
  const maxSavedReports =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      maxSavedReportsFeatureId
    ) || config.hardCoded.defaultLimitList.maxSavedReports;
  const maxScheduledReports =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      maxScheduledReportsFeatureId
    ) || config.hardCoded.defaultLimitList.maxScheduledReports;
  const maxFileUploadRowCount =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      maxFileUploadRowCountFeatureId
    ) || config.hardCoded.defaultLimitList.maxFileUploadRowCount;
  const maxFileUploadSizeInMB =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      maxFileUploadSizeInMBFeatureId
    ) || config.hardCoded.defaultLimitList.maxFileUploadSizeInMB;
  const maxCompareCalendarInDashboard =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      maxCompareCalendarInDashboardFeatureId
    ) || config.hardCoded.defaultLimitList.maxCompareCalendarInDashboard;
  const maxCompareCalendarInReports =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      maxCompareCalendarInReportsFeatureId
    ) || config.hardCoded.defaultLimitList.maxCompareCalendarInReports;
  const minHourGranDurationInDays =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      minHourGranDurationInDaysFeatureId
    ) || config.hardCoded.defaultLimitList.minHourGranDurationInDays;
  const maxHourGranDurationInDays =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      maxHourGranDurationInDaysFeatureId
    ) || config.hardCoded.defaultLimitList.maxHourGranDurationInDays;
  const minDayGranDurationInDays =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      minDayGranDurationInDaysFeatureId
    ) || config.hardCoded.defaultLimitList.minDayGranDurationInDays;
  const maxDayGranDurationInDays =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      maxDayGranDurationInDaysFeatureId
    ) || config.hardCoded.defaultLimitList.maxDayGranDurationInDays;
  const minMonthGranDurationInDays =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      minMonthGranDurationInDaysFeatureId
    ) || config.hardCoded.defaultLimitList.minMonthGranDurationInDays;
  const maxMonthGranDurationInDays =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      maxMonthGranDurationInDaysFeatureId
    ) || config.hardCoded.defaultLimitList.maxMonthGranDurationInDays;
  const maxSavedAlerts =
    getLimitValueFromLimitList(
      featureAccessList.limitsList,
      maxSavedAlertsFeatureId
    ) || config.hardCoded.defaultLimitList.maxSavedAlerts;

  return {
    daysLimitCalendarDashboard,
    daysLimitCalendarReports,
    maxSavedReports,
    maxScheduledReports,
    maxFileUploadRowCount,
    maxFileUploadSizeInMB,
    maxCompareCalendarInDashboard,
    maxCompareCalendarInReports,
    minHourGranDurationInDays,
    maxHourGranDurationInDays,
    minDayGranDurationInDays,
    maxDayGranDurationInDays,
    minMonthGranDurationInDays,
    maxMonthGranDurationInDays,
    maxSavedAlerts,
  };
};

export const checkMenuBarVisibility = (...args) => {
  const [activeNav, activeTab] = args;
  let flag = false;
  switch (activeNav) {
    case "datastory":
      switch (activeTab) {
        case "home":
          flag = false;
          break;
        case "create":
          flag = false;
          break;
        case "dashboard":
          flag = true;
          break;
        default:
          console.groupCollapsed(
            "UNIDENTIFIED TYPE -> checkMenuBarVisibility in utils"
          );
          console.log("activeNav", activeNav);
          console.log("activeTab", activeTab);
          console.groupEnd();
      }
      break;
    case "charts":
      switch (activeTab) {
        case "home":
          flag = false;
          break;
        case "createChart":
          flag = true;
          break;
        default:
          console.groupCollapsed(
            "UNIDENTIFIED TYPE -> checkMenuBarVisibility in utils"
          );
          console.log("activeNav", activeNav);
          console.log("activeTab", activeTab);
          console.groupEnd();
      }
      break;
    default:
      console.groupCollapsed(
        "UNIDENTIFIED TYPE -> checkMenuBarVisibility in utils"
      );
      console.log("activeNav", activeNav);
      console.log("activeTab", activeTab);
      console.groupEnd();
  }
  return flag;
};

export const checkFilterBarVisibility = (...args) => {
  const [activeNav, activeTab] = args;
  let flag = false;
  switch (activeNav) {
    case "datastory":
      switch (activeTab) {
        case "home":
          flag = false;
          break;
        case "create":
          flag = false;
          break;
        case "dashboard":
          flag = true;
          break;
        default:
          console.groupCollapsed(
            "UNIDENTIFIED TYPE -> checkFilterBarVisibility in utils"
          );
          console.log("activeNav", activeNav);
          console.log("activeTab", activeTab);
          console.groupEnd();
      }
      break;
    case "charts":
      switch (activeTab) {
        case "home":
          flag = false;
          break;
        case "createChart":
          flag = true;
          break;
        default:
          console.groupCollapsed(
            "UNIDENTIFIED TYPE -> checkFilterBarVisibility in utils"
          );
          console.log("activeNav", activeNav);
          console.log("activeTab", activeTab);
          console.groupEnd();
      }
      break;
    default:
      console.groupCollapsed(
        "UNIDENTIFIED TYPE -> checkFilterBarVisibility in utils"
      );
      console.log("activeNav", activeNav);
      console.log("activeTab", activeTab);
      console.groupEnd();
  }
  return flag;
};

export const isEqualWithoutCertainKeys = (...args) => {
  const [obj1, obj2, removalKey] = args;
  const { [removalKey]: removalKeyObj1, ...obj1Filtered } = obj1;
  const { [removalKey]: removalKeyObj2, ...obj2Filtered } = obj2;
  return isEqual(obj1Filtered, obj2Filtered);
};

const getLayoutFromDs = (ds) => {
  let layout = [];
  for (const metricEl of ds.datastoryParam.metricList) {
    const obj = {
      ...metricEl.container.metadata,
      chartType: "counter",
      static: true,
    };
    layout.push(obj);
  }
  for (const chartEl of ds.datastoryParam.chartList) {
    const obj = {
      ...chartEl.container.metadata,
      chartType: chartEl.metadata.chartType,
      static: true,
    };
    layout.push(obj);
  }
  return layout;
};

//TODO: FUNCTION USAGE
//TODO: UNIT TESTING
function unwrapperAppRow(props) {
  // Destructuring resultdata
  const {
    title = "",
    newCardProps = {},
    noCardProps = {},
    data = [],
    singleRowFlag = false,
    titleRowFlag = false,
    buttonRowFlag = false,
    createNewCardFlag = false,
    buttonTitle = "Show All",
    onButtonClick = () => {},
    renderProps = { flag: false, component: () => {} },
    appCardTag = "chart",
    onAppCardClick = () => {},
    appCardSelected = false,
  } = props;
  const {
    iconProps: newCardIconProps = {
      flag: true,
      className: "material-icons-outlined",
      value: "add_box",
    },
    title: newCardTitle = "",
    onClick: newCardOnClick = () => {},
  } = newCardProps;
  const {
    flag: noCardFlag = true,
    title: noCardTitle = "",
    bgIMG:
      noCardImageUrl = "https://storage.googleapis.com/sigview-icons/illustrations/noDatastory.svg",
  } = noCardProps;

  // unwrapping uiFriendlyData
  const uiFriendlyData = unwrapperAppCards({
    data,
    tag: appCardTag,
    onAppCardClick,
    isSelected: appCardSelected,
  });

  // For Creating newcard
  if (createNewCardFlag) {
    let newAppCardProps = {
      title: newCardTitle,
      iconProps: newCardIconProps,
      tag: "createNew",
      onAppCardClick: newCardOnClick,
    };
    // unwrapping uiFriendlyData for new card
    const newCardData = makeNewAppCard(newAppCardProps);
    uiFriendlyData.unshift(newCardData);
  }

  // For no data available
  if (data.length === 0 && noCardFlag) {
    let newAppCardProps = {
      title: noCardTitle,
      dsMetaData: { thumbnail: { url: noCardImageUrl } },
      tag: "noCharts",
    };
    // unwrapping uiFriendlyData for no data available
    const newCardData = makeNewAppCard(newAppCardProps);
    uiFriendlyData.push(newCardData);
  }

  //Add those 2 cards based on props/logic
  const finalData = [...uiFriendlyData];

  const uiFriendlyAppRow = {
    singleRowFlag,
    titleRowFlag,
    buttonRowFlag,
    data: finalData,
    title,
    buttonTitle,
    onButtonClick,
    renderProps,
  };
  return uiFriendlyAppRow;
}

// SAMPLE DATA STRUCTURE FOR APP CARD ROW
// const sampleData = {
//   id: 168523,
//   payload: {
//     title: "Chart",
//     tag: "chart",
//     bgIMG: "../../assets/images/chart.svg",
//     isSelected: true,
//     moreOptions: {
//       flag: false,
//       data: [{ id: 1231241, title: "something", onClick: () => {} }],
//       moreOptionsIcon: {
//         className: "material-icons-outlined",
//         value: "more_vert",
//       },
//     },
//     icon: {
//       flag: false,
//       className: "material-icons-outlined",
//       value: "add_box",
//     },
//     onClick: () => {},
//     onRename: () => {},
//     isClickable: true,
//     disabled: false,
//   },
//   actualPayload: {},
// };
function makeNewAppCard(props) {
  // Destructuring row
  const {
    _id = v4(),
    title = "",
    dsMetaData = {},
    tag = "chart",
    moreOptions = {
      flag: false,
      data: [],
      icon: {
        className: "material-icons-outlined",
        value: "more_vert",
      },
    },
    isSelected = false,
    iconProps = {
      flag: false,
      className: "material-icons-outlined",
      value: "add_box",
    },
    onAppCardClick: onClick = () => {},
    onRename = () => {},
    isClickable = true,
    disabled = false,
    actualPayload = {},
  } = props;
  const { thumbnail = {} } = dsMetaData;
  const { url = "" } = thumbnail;

  let finalTagType = tag;
  let finalBgIMG = url;

  let gridLayoutData = [];
  if (tag === "gridChart") {
    gridLayoutData = getLayoutFromDs(actualPayload);
    // * UI LOGIC
    // In case, chart type is gridChart and there are not metrics or charts available,
    // change tag to chartWithIcon and add illustration
    const isAnyWidgetPresent =
      [
        ...(props?.datastoryParam?.chartList || []),
        ...(props?.datastoryParam?.metricList || []),
      ].length > 0;
    if (!isAnyWidgetPresent) {
      finalTagType = "chartWithIcon";
      finalBgIMG = config.hardCoded.voidUrl;
    }
  }

  // Making required variables
  const payload = {
    title,
    tag: finalTagType,
    bgIMG: finalBgIMG,
    isSelected,
    moreOptions,
    icon: iconProps,
    onClick,
    onRename,
    isClickable,
    disabled,
    gridLayoutData,
  };

  // Making final object
  const uiFriendlyCardData = { id: _id, payload, actualPayload };

  return uiFriendlyCardData;
}

function unwrapperAppCards(props) {
  const {
    data = [],
    tag = "chart",
    onAppCardClick = () => {},
    isSelected = false,
  } = props;
  const uiFriendlyAppCards = data.map((row) =>
    makeNewAppCard({
      ...row,
      actualPayload: row,
      tag,
      onAppCardClick,
      isSelected,
    })
  );
  return uiFriendlyAppCards;
}

// REFERENCE: https://stackoverflow.com/questions/17438064/get-content-width-of-an-element/47224153
function getElementContentWidth(element) {
  var styles = window.getComputedStyle(element);

  return (
    element.clientWidth -
    parseFloat(styles.paddingLeft) -
    parseFloat(styles.paddingRight)
  );
}

function makeAppCardRow(props) {
  // Destructuring row
  const { id, tag, title, actualPayload, layout } = props;

  // Making required variables
  const data = [
    {
      id: v4(),
      title: "something",
      onClick: () => {},
    },
  ];
  const moreOptionsIcon = {
    className: "material-icons-outlined",
    value: "more_vert",
  };
  const moreOptions = {
    flag: false,
    data,
    icon: moreOptionsIcon,
  };
  const icon = {
    flag: false,
    className: "material-icons-outlined",
    value: "add_box",
  };
  const payload = {
    title,
    tag,
    bgIMG: "",
    isSelected: false,
    moreOptions,
    icon,
    onClick: () => {},
    onRename: () => {},
    isClickable: true,
    disabled: false,
    layout,
  };

  // Making final object
  const uiFriendlyRow = { id, payload, actualPayload };
  return uiFriendlyRow;
}

function getReactGridLayout(data) {
  const layoutMetrics = data?.datastoryParam.metricList.map((metric) => ({
    ...metric.container.metadata,
    chartType: "counter",
    container: metric.container,
  }));
  const layoutCharts = data?.datastoryParam.chartList.map((chart) => ({
    ...chart.container.metadata,
    chartType: chart.metadata.chartType,
    container: chart.container,
  }));
  let layoutFinal = [...layoutMetrics, ...layoutCharts];
  //Adding static flag to all elements in layout
  layoutFinal = layoutFinal.map((ele) => ({
    ...ele,
    static: true,
  }));
  return layoutFinal;
}

const convertChartResToDsRes = (row) => ({
  id: row.id,
  title: row.name,
  chartItemPayload: { ...row },
  dsMetaData: {
    isActive: true,
    thumbnail: {
      url: `${config.hardCoded.chartTypesBaseUrl}/${row.chartType}Hovered.svg`,
    },
  },
});

const convertToAppCardFriendlyDs = (row) => ({
  id: row.id,
  title: row.name,
  chartItemPayload: row,
  dsMetaData: {
    isActive: true,
    thumbnail: {
      url: `${config.hardCoded.chartTypesBaseUrl}/${row.metadata.chartType}Hovered.svg`,
    },
  },
});

const getAdFiltersRules = (props) => {
  const {
    maxFileUploadRowCount = config.hardCoded.defaultLimitList
      .maxFileUploadRowCount,
    maxFileUploadSizeInMB = config.hardCoded.defaultLimitList
      .maxFileUploadSizeInMB,
    uiFeatureList = {},
  } = props;
  const { globalFiltersFileUpload = true, globalFiltersStringMatch = true } =
    uiFeatureList;
  let adFiltersString = [];
  const maxFileUploadRowCountFormatted = d3.format(",")(maxFileUploadRowCount);
  const adFiltersRulesFileUpload = [
    "Maximum of 1 filter file upload is permitted per dimension",
    "Filter file upload is available only for exactly matches",
    "File formats permitted for upload: .csv and .xlsx",
    `Maximum File Size: ${maxFileUploadSizeInMB}MB with at most ${maxFileUploadRowCountFormatted} values`,
    "File must not contain a header row",
    "Empty Rows will be trimmed in the file",
    "The values in the file for exact match will be treated as case sensitive",
  ];
  const adFiltersRulesString = [
    "Search string length should be between 2 and 50 characters",
    "The text fields values are not case sensitive for starts & ends with, contains string & word",
    "In contains whole word selection, filter values will be displayed which are separated by space, hyphen or apostrophe S for the entered search keyword",
    "Maximum of 10 keyword searches is permitted per dimension",
  ];
  if (globalFiltersFileUpload)
    adFiltersString = [...adFiltersString, ...adFiltersRulesFileUpload];
  if (globalFiltersStringMatch)
    adFiltersString = [...adFiltersString, ...adFiltersRulesString];
  const adFilterRules = {
    string: adFiltersString,
    timestamp: [
      '"From" is inclusive of the selected date',
      '"Before" is exclusive of the selected date',
      '"Between" is inclusive of both the selected dates',
      'Either "From" or "Before" selection is permitted per dimension',
      "Hour selections are not permitted for any condition",
      "Multiple selections will be OR'ed within each other",
      "Multiple selections should not have overlapping dates",
      "File must not contain a header row",
      "Empty Rows will be trimmed in the file",
      "The values in the file for exact match will be treated as case sensitive",
    ],
  };
  return adFilterRules;
};

const isUserNonSigviewOaa = (user) => {
  const openxUserData = JSON.parse(localStorage.getItem("openxUserData"));
  var openxUserType = "";
  if (openxUserData) openxUserType = openxUserData[0]?.userType;
  if (
    user?.reqMetadata?.userType?.toLowerCase() === "oaa" ||
    openxUserType?.toLowerCase() === "oaa"
  )
    return true;
  return false;
};

const sigviewUserType = getSigviewUserType();
const isLogoutVisible = (user) => {
  const isUserNonSigviewOaaFlag = isUserNonSigviewOaa(user);
  const isEmbeddedUser = user.embeddedInfo.isEmbeddedUser;
  const isLogoutVisibleFlag = !isUserNonSigviewOaaFlag && !isEmbeddedUser;
  return isLogoutVisibleFlag;
};
const isAdminVisible = (user) => {
  const isEmbeddedUser = user.embeddedInfo.isEmbeddedUser;
  const isUserJobRoleAdmin = user.reqMetadata?.jobRole === "admin";
  const isAdminVisible = isEmbeddedUser ? false : isUserJobRoleAdmin;
  return isAdminVisible;
};

const isPartnerAdminVisible = (user) => {
  const isEmbeddedUser = user.embeddedInfo.isEmbeddedUser;
  const isUserJobRolePartnerAdmin =
    user.reqMetadata?.jobRole === "partnerAdmin";
  const isPartnerAdminVisible = isEmbeddedUser
    ? false
    : isUserJobRolePartnerAdmin;
  return isPartnerAdminVisible;
};

// const isChangePasswordVisible = (user) => !user.embeddedInfo.isEmbeddedUser;
// ! TODO testing for embedded user and openX for changePassword
const isChangePasswordVisible = (user) =>
  !user.embeddedInfo.isEmbeddedUser && sigviewUserType === "sigview";

function getBreadcrumbsData(user) {
  const { screen = {} } = user;
  const {
    activeNav = "datastory",
    activeTab = "home",
    activeDsCategory = "saved",
    activeChart = {},
    activePivot,
  } = screen;

  let breadcrumbsData = [{ id: "home", name: "Home", disabled: false }];
  switch (activeNav) {
    case "pivot":
      switch (activeTab) {
        case "home":
          breadcrumbsData = [
            { id: "home", name: "Saved Pivot", disabled: false },
          ];
          break;
        case "createPivot":
          switch (activePivot.elementType) {
            case "create":
              breadcrumbsData = [
                { id: "home", name: "Saved Pivot", disabled: false },
                { id: "createPivot", name: "Create Pivot", disabled: false },
              ];
              break;
            case "update":
              breadcrumbsData = [
                { id: "home", name: "Saved Pivot", disabled: false },
                { id: "editPivot", name: "Edit Pivot", disabled: false },
              ];
              break;
            default:
              breadcrumbsData = [
                { id: "home", name: "Saved Pivot", disabled: false },
              ];
          }
          break;
        default:
          breadcrumbsData = [{ id: "home", name: "Home", disabled: false }];
      }
      break;
    case "admin":
      switch (activeTab) {
        case "home":
          breadcrumbsData = [{ id: "home", name: "Home", disabled: false }];
          break;
        case "groups":
          breadcrumbsData = [
            { id: "home", name: "Home", disabled: false },
            { id: "groups", name: "Groups", disabled: false },
          ];
          break;
        case "group":
          breadcrumbsData = [
            { id: "home", name: "Home", disabled: false },
            { id: "groups", name: "Groups", disabled: false },
            {
              id: screen.activeAdminGroup.metadata.name,
              name: screen.activeAdminGroup.metadata.name,
              disabled: false,
            },
          ];
          break;
        case "users":
          breadcrumbsData = [
            { id: "home", name: "Home", disabled: false },
            { id: "users", name: "Users", disabled: false },
          ];
          break;
        case "user":
          breadcrumbsData = [
            { id: "home", name: "Home", disabled: false },
            { id: "users", name: "Users", disabled: false },
            {
              id: screen.activeAdminUser.metadata.name,
              name: screen.activeAdminUser.metadata.name,
              disabled: false,
            },
          ];
          break;
        case "attributes":
          breadcrumbsData = [
            { id: "home", name: "Home", disabled: false },
            { id: "attributes", name: "Attributes", disabled: false },
          ];
          break;
        default:
          breadcrumbsData = [{ id: "home", name: "Home", disabled: false }];
      }
      break;

    case "datastory":
      switch (activeTab) {
        case "home":
          breadcrumbsData = [{ id: "home", name: "Home", disabled: false }];
          break;
        case "category":
          switch (activeDsCategory) {
            case "sample":
              breadcrumbsData = [
                { id: "home", name: "Home", disabled: false },
                { id: "category", name: "Sample Datastory", disabled: false },
              ];
              break;
            case "saved":
              breadcrumbsData = [
                { id: "home", name: "Home", disabled: false },
                { id: "category", name: "Saved Datastory", disabled: false },
              ];
              break;
            default:
              breadcrumbsData = [
                { id: "home", name: "Home", disabled: false },
                { id: "category", name: "Saved Datastory", disabled: false },
              ];
          }
          break;
        case "create":
          breadcrumbsData = [
            { id: "home", name: "Home", disabled: false },
            { id: "category", name: "Saved Datastory", disabled: false },
            { id: "create", name: "Create Datastory", disabled: false },
          ];
          break;
        case "dashboard":
          switch (activeDsCategory) {
            case "sample":
              breadcrumbsData = [
                { id: "home", name: "Home", disabled: false },
                { id: "sample", name: "Sample Datastory", disabled: false },
                { id: "dashboard", name: "Dashboard", disabled: false },
              ];
              break;
            case "saved":
              breadcrumbsData = [
                { id: "home", name: "Home", disabled: false },
                { id: "saved", name: "Saved Datastory", disabled: false },
                { id: "dashboard", name: "Dashboard", disabled: false },
              ];
              break;
            default:
              breadcrumbsData = [
                { id: "home", name: "Home", disabled: false },
                { id: "category", name: "Saved Datastory", disabled: false },
              ];
          }
          break;
        case "chartsHome":
          breadcrumbsData = [
            { id: "home", name: "Home", disabled: false },
            { id: "savedCharts", name: "Saved Charts", disabled: false },
          ];
          break;
        case "createChart":
          switch (activeChart.elementType) {
            case "create":
              breadcrumbsData = [
                { id: "home", name: "Home", disabled: false },
                { id: "chartsHome", name: "Saved Charts", disabled: false },
                { id: "createChart", name: "Create Chart", disabled: false },
              ];
              break;
            case "update":
              breadcrumbsData = [
                { id: "home", name: "Home", disabled: false },
                { id: "chartsHome", name: "Saved Charts", disabled: false },
                { id: "createChart", name: "Edit Chart", disabled: false },
              ];
              break;
            default:
              breadcrumbsData = [
                { id: "home", name: "Home", disabled: false },
                { id: "chartsHome", name: "Saved Charts", disabled: false },
              ];
          }
          break;

        default:
          breadcrumbsData = [{ id: "home", name: "Home", disabled: false }];
      }
      break;
    default:
      breadcrumbsData = [{ id: "home", name: "Home", disabled: false }];
  }
  return breadcrumbsData;
}

function getBreadcrumbsDataFromRoute(user = {}, history = {}) {
  const { screen = {} } = user;
  const {
    activeNav = "datastory",
    activeTab = "home",
    activeDsCategory = "saved",
    activeChart = {},
    activePivot,
    activeAdminUser,
    activeAdminGroup,
  } = screen;
  const pathname = history.location.pathname;

  let breadcrumbsData = [];
  switch (pathname) {
    case "/analyze/dashboard":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/analyze", disabled: false },
        {
          id: "analyzeDashboard",
          name: "Dashboard",
          path: "/",
          disabled: true,
        },
      ];
      break;
    case "/datastory":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/datastory", disabled: true },
      ];
      break;
    case "/datastory/dashboard":
      switch (activeDsCategory.toLowerCase()) {
        case "saved":
          breadcrumbsData = [
            { id: "home", name: "Home", path: "/datastory", disabled: false },
            {
              id: "datastorySaved",
              name: "Saved Datastory",
              path: "/datastory/saved",
              disabled: true,
            },
            {
              id: "datastoryDashboard",
              name: "Dashboard",
              path: "/",
              disabled: true,
            },
          ];
          break;
        case "sample":
          breadcrumbsData = [
            { id: "home", name: "Home", path: "/datastory", disabled: false },
            {
              id: "datastorySaved",
              name: "Sample Datastory",
              path: "/datastory/sample",
              disabled: true,
            },
            {
              id: "datastoryDashboard",
              name: "Dashboard",
              path: "/",
              disabled: true,
            },
          ];
          break;
        default:
          breadcrumbsData = [
            {
              id: "home",
              name: "Home",
              path: "/datastory",
              disabled: false,
            },
            {
              id: "datastorySaved",
              name: "Saved Datastory",
              path: "/datastory/saved",
              disabled: true,
            },
            {
              id: "datastoryDashboard",
              name: "Dashboard",
              path: "/",
              disabled: true,
            },
          ];
          break;
      }
      break;

    case "/datastory/dashboard/create":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/datastory", disabled: false },
        {
          id: "datastorySaved",
          name: "Saved Datastory",
          path: "/datastory/saved",
          disabled: true,
        },
        {
          id: "datastoryCreate",
          name: "Create Datastory",
          path: "/",
          disabled: true,
        },
      ];
      break;
    case "/datastory/dashboard/bulk-edit":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/datastory", disabled: false },
        {
          id: "datastorySaved",
          name: "Saved Datastory",
          path: "/datastory/saved",
          disabled: true,
        },
        {
          id: "datastoryBulkEdit",
          name: "Edit Datastory",
          path: "/",
          disabled: true,
        },
      ];
      break;
    case "/datastory/saved":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/datastory", disabled: false },
        {
          id: "datastorySaved",
          name: "Saved Datastory",
          path: "",
          disabled: false,
        },
      ];
      break;
    case "/datastory/sample":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/datastory", disabled: false },
        {
          id: "datastorySample",
          name: "Sample Datastory",
          path: "",
          disabled: false,
        },
      ];
      break;
    case "/datastory/chart":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/datastory", disabled: false },
        {
          id: "savedCharts",
          name: "Saved Charts",
          path: "/",
          disabled: true,
        },
      ];
      break;

    case "/datastory/chart/create":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/datastory", disabled: false },
        {
          id: "savedCharts",
          name: "Saved Charts",
          path: "/datastory/chart",
          disabled: true,
        },
        {
          id: "createChart",
          name: "Create Chart",
          path: "/",
          disabled: true,
        },
      ];
      break;

    case "/datastory/chart/edit":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/datastory", disabled: false },
        {
          id: "savedCharts",
          name: "Saved Charts",
          path: "/datastory/chart",
          disabled: true,
        },
        {
          id: "editChart",
          name: "Edit Chart",
          path: "/",
          disabled: true,
        },
      ];
      break;

    case "/pivotx":
      breadcrumbsData = [
        { id: "home", name: "Saved Pivot", path: "/pivotx", disabled: true },
      ];
      break;
    case "/pivotx/create":
      breadcrumbsData = [
        {
          id: "SavedPivot",
          name: "Saved Pivot",
          path: "/pivotx",
          disabled: false,
        },
        {
          id: "createPivot",
          name: "Create Pivot",
          path: "pivotx/create",
          disabled: false,
        },
      ];
      break;

    case "/admin":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/admin", disabled: false },
      ];
      break;

    case "/admin/groups":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/admin", disabled: false },
        {
          id: "groups",
          name: "Groups",
          path: "/admin/groups",
          disabled: false,
        },
      ];
      break;
    case "/admin/group":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/admin", disabled: false },
        {
          id: "groups",
          name: "Groups",
          path: "/admin/groups",
          disabled: false,
        },
        {
          id: activeAdminGroup?.metadata?.name || "createNewGroup",
          name: activeAdminGroup?.metadata?.name || "Create New Group",
          disabled: false,
        },
      ];
      break;

    case "/admin/users":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/admin", disabled: false },
        { id: "users", name: "Users", path: "/admin/users", disabled: false },
      ];
      break;
    case "/admin/user":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/admin", disabled: false },
        { id: "users", name: "Users", path: "/admin/users", disabled: false },
        {
          id: activeAdminUser?.metadata?.name || "createNewUser",
          name: activeAdminUser?.metadata?.name || "Create New User",
          disabled: false,
        },
      ];
      break;
    case "/admin/attributes":
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/admin", disabled: false },
        { id: "attributes", name: "Attributes", disabled: false },
      ];
      break;

    default:
      breadcrumbsData = [
        { id: "home", name: "Home", path: "/analyze", disabled: false },
      ];
  }
  return breadcrumbsData;
}

function getBreadcrumbsDataAdminAws(props) {
  const { pathname = "" } = props;

  let breadcrumbsData = [{ id: "home", name: "Home", disabled: false }];

  switch (pathname) {
    case "/admin":
      breadcrumbsData = [{ id: "home", name: "Home", disabled: false }];
      break;

    case "/admin/users":
      breadcrumbsData = [
        { id: "home", name: "Home", disabled: false },
        { id: "users", name: "Users", disabled: false },
      ];
      break;
    case "/admin/user":
      breadcrumbsData = [
        { id: "home", name: "Home", disabled: false },
        { id: "user", name: "User", disabled: false },
        // {
        //   id: activeAdminUserEmail,
        //   name: activeAdminUserEmail,
        //   disabled: false,
        // },
      ];
      break;
    case "/admin/attributes":
      breadcrumbsData = [
        { id: "home", name: "Home", disabled: false },
        { id: "attributes", name: "Attributes", disabled: false },
      ];
      break;
    default:
      breadcrumbsData = [{ id: "home", name: "Home", disabled: false }];
  }

  return breadcrumbsData;
}

const getActiveNav = (url) => {
  let activeNav = url.split("/")[url.split("/").length - 1];

  return activeNav === undefined || activeNav === "" ? "analyze" : activeNav;
};

const sigviewFormat = (number = 0) => {
  const formatInteger = d3.format(","); // hard coded, can be configured later
  const formatDecimal = d3.format(",.2f"); // hard coded, can be configured later
  return !(number % 1) ? formatInteger(number) : formatDecimal(number);
};

const isHostnameLocalDev = () =>
  config.hardCoded.localDevUrlList.includes(window.location.hostname);

// *  Check is our prod or either diffrent
const isNativeDomain = () =>
  config.hardCoded.prodURLList.includes(window.location.hostname);

const getWorkspaceSharedUrl = (tokenId) => {
  var sharedUrl = `https://${window.location.hostname}${window.location.port}/analyze/dashboard?id=${tokenId}`;
  if (isHostnameLocalDev())
    sharedUrl = `http://${window.location.hostname}:${window.location.port}/analyze/dashboard?id=${tokenId}`;

  return sharedUrl;
};
const isDateRangeChange = (allData) => {
  if (
    allData.dateRange.status === "success" &&
    allData.dateRangeLatest.status === "success"
  ) {
    if (
      allData.dateRange.data.startDate !==
        allData.dateRangeLatest.data.startDate ||
      allData.dateRange.data.endDate !== allData.dateRangeLatest.data.endDate
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const makeAttributeMissingErrorMessageMaster = (
  reponseFromValidationFunction
) => {
  let metadata = reponseFromValidationFunction.metadata;
  let missingDimensions = metadata.missingDimensions;
  let missingMetrics = metadata.missingMetrics;
  let missingCustomMetrics = metadata.missingCustomMetrics;
  const sigviewUserType = getSigviewUserType();
  let supportContact =
    "Please reach out to sigviewsupport@sigmoidanalytics.com";
  if (sigviewUserType === "sigview")
    supportContact = "Please reach out to sigviewsupport@sigmoidanalytics.com";
  if (sigviewUserType === "nonSigview")
    supportContact =
      "Please raise a ticket in Salesforce or reach out to support@openx.com";

  let message = `<span>It seems like the access to some attributes for this workspace have been modified</span>`;
  message += `<br/><span>${supportContact}</span>`;
  if (missingDimensions.length > 0)
    message += `<br/><span style="font-size: 10px; color: #607282">Dimensions: ${missingDimensions.join(
      ", "
    )}</span>`;
  if (missingMetrics.length > 0)
    message += `<br/><span style="font-size: 10px; color: #607282">Metrics: ${missingMetrics.join(
      ", "
    )}</span>`;
  if (missingCustomMetrics.length > 0)
    message += `<br/><span style="font-size: 10px; color: #607282">Custom Metrics: ${missingCustomMetrics.join(
      ", "
    )}</span>`;
  return message;
};

export {
  getElementContentWidth,
  unwrapperAppCards,
  makeAppCardRow,
  getReactGridLayout,
  unwrapperAppRow,
  convertChartResToDsRes,
  getAdFiltersRules,
  convertToAppCardFriendlyDs,
  isLogoutVisible,
  isAdminVisible,
  isUserNonSigviewOaa,
  getBreadcrumbsData,
  getActiveNav,
  getBreadcrumbsDataAdminAws,
  getBreadcrumbsDataFromRoute,
  sigviewFormat,
  isHostnameLocalDev,
  isNativeDomain,
  getWorkspaceSharedUrl,
  isDateRangeChange,
  isChangePasswordVisible,
  isPartnerAdminVisible,
  makeAttributeMissingErrorMessageMaster,
  isCurrentRouteValid,
};
