// Import required libraries
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import isEqual from "lodash.isequal";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

// Import custom components
import SigviewButton from "../Common/SigviewButton";
import ReportManagerForm from "./ReportManagerForm";
import ReportManagerPreview from "./ReportManagerPreview";
import Loader from "../Loader/Loader";
import SigviewTooltip from "../Common/SigviewTooltip";
import Tour from "../Tour/Tour";
import SigviewNav from "../Common/SigviewNav";

// Import styles
import "./ReportManager.scss";

// Import config
import { config } from "../../config/config";

// Import action creators and reducers
import {
  updateDialogInfo,
  updateUserMetadata,
  updateUserScreen,
} from "../../redux/actions";

// Import utils
import {
  isOrderByTypeValid,
  validateReportFormData,
  wrapperSavedReport,
  wrapperScheduledReport,
  wrapperDownloadReport,
  wrapperReportOverEmail,
  wrapperReportLinkOverEmail,
} from "../../utils/reportUtils";

// Import data
import timeRangeScheduledArr from "../../../assets/data/timeRangeScheduledArr";
import tourData from "../../../assets/data/tourData.json";

// Import api functions
import {
  addSavedReport,
  updateSavedReport,
  deleteSavedReport,
  downloadReport,
  getReportOverEmail,
  getReportLinkOverEmail,
  addScheduledReport,
  deleteScheduledReport,
  updateScheduledReport,
  downloadReportFile,
} from "../../services/api";

// Import utils
import { getMeasureObjByOriginalID } from "../../utils/plotlyUtils";
import { capitalize, getSigviewUserType } from "../../utils/utils";
import { masterTrackGaEvent } from "../../services/ga";
import { AdminReportClone } from "../../screens/Admin/AdminReport/AdminReportHelpers";

const reportNavArr = [{ id: "details", name: "Details", disabled: false }];

//REFERENCE
// const newInitialFormData = {
//   reportId: activeReport.id,
//   reportName: newReportName,
//   scheduleFlag: "no",
//   timeFilters: user.timeFilters,
//   triggerTimeRangeNumber: 1,
//   triggerType: "daily",
//   triggerDay: "monday",
//   triggerTime: "09:00",
//   triggerTimeZone: "UTC (+00:00)",
//   triggerTimeRangeTimeZone: "UTC (+00:00)",
//   isComparisonOn: false,
//   dimensionsList: [],
//   metricsList: [],
//   orderById: "",
//   orderBy: "asc",
//   orderByType: "id_only",
//   preferredLanguage: "en",
//   recipientsList,
//   reportStatus: "",
//   reportCountFileType,
//   reportRowCount: "five_thousand_records",
//   duplicateReportNameFlag: false,
//   dimensionFilters: [],
//   metricFilters: [],
// };

function ReportManager(props) {
  const { t } = useTranslation();
  const {
    ReduxDispatcher,
    user,
    allData,
    globalFilters,
    activeReportFormData,
    activeReport,
    setActiveReport,
    reloadData,
    tourRun,
    setTourRun = () => {},
    uiFeatureList,
    disabledAddNewReport,
    bucketList = [],
    variant = "report",
    usersData = {},
  } = props;

  const pathname = window.location.pathname;
  const rootPage = pathname.split("/")[1];

  //Defining required states
  const toastId = useRef(null);
  const [activeReportNav, setActiveReportNav] = useState("details");
  const [reportFormData, setReportFormData] = useState({
    ...activeReportFormData.reportFormData,
  });
  const [reportFormError, setReportFormError] = useState({
    flag: false,
    message: {
      reportName: { flag: false, message: "" },
      dimensionsList: { flag: false, message: "" },
      metricsList: { flag: false, message: "" },
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  //Defining required variables
  const reportMessages = config.messages.reports;

  //Defining utility functions
  const setReportFormDataSync = (key, value) => {
    //If key is not present, it means the value is an object (changing multiple fields at once), so use spread operator
    if (key) {
      setReportFormData((prevState) => ({ ...prevState, [key]: value }));
    } else {
      setReportFormData((prevState) => ({ ...prevState, ...value }));
    }
  };

  const handleGAButtonClick = (label) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ReportManager",
      action: "ButtonClicked",
      label,
    });
  };

  const handleCancel = () => {
    //todo
    //Check if there's a change in report form data
    //If yes, show the dialog, else close the active report
    // const flag = !isEqual(
    //   globalFiltersStoreFiltered,
    //   globalFiltersOriginal.current
    // );
    handleGAButtonClick("Cancel");
    const flag = true;
    if (flag) {
      const message = {
        title: config.dialogInfo.filtersClose.title,
        subtitle: config.dialogInfo.filtersClose.subtitle,
      };
      const handleNo = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
          })
        );
      };
      const handleYes = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
            message: { title: "", subtitle: "" },
          })
        );

        //Close the active report
        setActiveReport({ type: "none", id: "" });
      };
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: true,
          message,
          handleYes,
          handleNo,
        })
      );
    } else {
      //Close the active report
      setActiveReport({ type: "none", id: "" });
    }
  };

  const handleAddSaveReport = () => {
    handleGAButtonClick("Save");

    //Set is loading to true
    setIsLoading(true);

    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: reportMessages.savedRequest,
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    //Making fetch call
    const wrapperProps = {
      activeReportFormData: {
        reportFormData: reportFormData,
        reportPayload: activeReportFormData.reportPayload,
      },
      user: user,
      allData: allData,
      globalFilters: globalFilters,
      activeReport,
    };
    const fetchPayload = wrapperSavedReport(wrapperProps);
    const fetchProps = {
      payload: fetchPayload,
    };
    const addSavedReportPromise = addSavedReport(fetchProps);
    addSavedReportPromise
      .then((responseDate) => {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: reportMessages.savedSuccess,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        //Close the active report
        setActiveReport({ type: "none", id: "" });

        //Reload data
        reloadData("saved");
      })
      .catch((json) => {
        console.log("API FAILED", json);
        setIsLoading(false);
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || reportMessages.savedFailure,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };

  const handleAddToSavedReport = () => {
    handleGAButtonClick("AddToSavedReport");

    //Set is loading to true
    setIsLoading(true);

    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: reportMessages.savedRequest,
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
    const addSavedReportFlag = true;
    //Making fetch call
    const wrapperProps = {
      activeReportFormData: {
        reportFormData: { ...reportFormData, reportId: `report-${v4()}` }, //Updating Id
        reportPayload: {}, //Adding download/scheduled report to saved reports
      },
      user: user,
      allData: allData,
      globalFilters: globalFilters,
      activeReport,
      addSavedReportFlag,
    };
    const fetchPayload = wrapperSavedReport(wrapperProps);
    const fetchProps = {
      payload: fetchPayload,
    };
    const addSavedReportPromise = addSavedReport(fetchProps);
    addSavedReportPromise
      .then((responseDate) => {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: reportMessages.savedSuccess,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        //Close the active report
        setActiveReport({ type: "none", id: "" });

        //Reload data
        reloadData("saved");
      })
      .catch((json) => {
        console.log("API FAILED", json);
        setIsLoading(false);
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || reportMessages.savedFailure,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };

  const handleAddToScheduledReports = () => {
    handleGAButtonClick("AddToScheduledReport");

    //Set is loading to true
    setIsLoading(true);

    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: reportMessages.scheduledRequest,
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    //Making fetch call
    //hard coded
    //If the saved report had five_thoudsand_records selected, API will fail, change it to fifty_thousand_records
    const reportRowCount = ["fifty_thousand_records", "all_records"].includes(
      reportFormData.reportRowCount
    )
      ? reportFormData.reportRowCount
      : "fifty_thousand_records";
    const wrapperProps = {
      activeReportFormData: {
        reportFormData: {
          ...reportFormData,
          reportId: `report-${v4()}`,
          reportRowCount,
        }, //Updating Id
        reportPayload: {}, //Adding download/saved report to scheduled reports
      },
      user: user,
      globalFilters: globalFilters,
      allData: allData,
      activeReport,
    };
    const fetchPayload = wrapperScheduledReport(wrapperProps);
    const fetchProps = {
      payload: fetchPayload,
    };
    const addScheduledReportPromise = addScheduledReport(fetchProps);
    addScheduledReportPromise
      .then((responseDate) => {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: reportMessages.scheduledSuccess,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        //Close the active report
        setActiveReport({ type: "none", id: "" });

        //Reload data
        reloadData("scheduled");
      })
      .catch((json) => {
        console.log("API FAILED", json);
        setIsLoading(false);
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || reportMessages.scheduledFailure,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };

  const handleReportDownload = () => {
    handleGAButtonClick("Download");
    //Close the active report
    setActiveReport({ type: "none", id: "" });
    // //Update snackbar
    // let snackbarPayload = {
    //   ...user.screen.snackbar,
    //   open: true,
    //   message: reportMessages.downloadReportRequest,
    // };
    // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    // Show info toaster
    toastId.current = toast.info(
      ` ${t(reportMessages.downloadReportRequest)}`,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );

    //Making fetch call
    const wrapperProps = {
      activeReportFormData: {
        reportFormData: { ...reportFormData, reportId: `report-${v4()}` }, //Updating Id
        reportPayload: {}, //Adding download/scheduled report to saved reports
      },
      user: user,
      allData: allData,
      globalFilters: globalFilters,
      activeReport,
    };
    const fetchPayload = wrapperDownloadReport(wrapperProps);
    const fetchProps = {
      payload: fetchPayload,
    };
    const downloadReportPromise = downloadReport(fetchProps);
    downloadReportPromise
      .then((responseDate) => {
        // //Update snackbar
        // let snackbarPayload = {
        //   ...user.screen.snackbar,
        //   open: true,
        //   message: reportMessages.downloadReportSuccess,
        // };
        // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        // Show success toaster
        toast.dismiss(toastId.current);
        toastId.current = toast.success(
          ` ${t(reportMessages.downloadReportSuccess)}`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 5000);

        //Initiate download file
        const fileName = responseDate.result.data.downloadFileName;
        const fileID = responseDate.result.data.file;
        const downloadUrl = `${user.apiEndpoints.baseUrl}/downloadFile?fid=${fileID}&fname=${fileName}`;

        // * Old implementation
        // This stopped working in new app server as the opened URL required authentication token
        // window.open doesn't allow sending headers (https://stackoverflow.com/questions/4325968/window-open-with-headers)
        // window.open(downloadUrl);

        // * New implementation
        // Fetching the required data from the download file url and then downloading via blob
        const fetchProps = {
          url: downloadUrl,
        };
        const downloadReportFilePromise = downloadReportFile(fetchProps);
        downloadReportFilePromise
          .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
          })
          .catch((json) => {
            console.log("API FAILED", json);
            // let snackbarPayload = {
            //   ...user.screen.snackbar,
            //   open: true,
            //   message: json.error || reportMessages.downloadReportFailure,
            // };
            // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

            // Show failure toaster
            toast.dismiss(toastId.current);
            toastId.current = toast.error(
              `${json.error || t(reportMessages.downloadReportFailure)}`,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            setTimeout(() => {
              toast.dismiss(toastId.current);
            }, 5000);

            //Reload data even if it fails so that the user can see the refreshed history
            reloadData("download");
          });

        //Reload data
        reloadData("download");
      })
      .catch((json) => {
        console.log("API FAILED", json);
        // let snackbarPayload = {
        //   ...user.screen.snackbar,
        //   open: true,
        //   message: json.error || reportMessages.downloadReportFailure,
        // };
        // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        // Show failure toaster
        toast.dismiss(toastId.current);
        toastId.current = toast.error(
          `${json.error || t(reportMessages.downloadReportFailure)}`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 5000);

        //Reload data even if it fails so that the user can see the refreshed history
        reloadData("download");
      });
  };

  const handleGetReportOverEmail = () => {
    handleGAButtonClick("Email");
    //Close the active report
    setActiveReport({ type: "none", id: "" });
    // //Update snackbar
    // let snackbarPayload = {
    //   ...user.screen.snackbar,
    //   open: true,
    //   message: reportMessages.reportOverEmailRequest,
    // };
    // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    // Show info toaster
    toastId.current = toast.info(
      ` ${t(reportMessages.reportOverEmailRequest)}`,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );

    //Making fetch call
    const wrapperProps = {
      activeReportFormData: {
        reportFormData: { ...reportFormData, reportId: `report-${v4()}` }, //Updating Id
        reportPayload: {}, //Adding download/scheduled report to saved reports
      },
      user: user,
      allData: allData,
      globalFilters: globalFilters,
      activeReport,
    };
    const fetchPayload = wrapperReportOverEmail(wrapperProps);
    const fetchProps = {
      payload: fetchPayload,
    };
    const getReportOverEmailPromise = getReportOverEmail(fetchProps);
    getReportOverEmailPromise
      .then((responseDate) => {
        // //Update snackbar
        // let snackbarPayload = {
        //   ...user.screen.snackbar,
        //   open: true,
        //   message: reportMessages.reportOverEmailSuccess,
        // };
        // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        // Show success toaster
        toast.dismiss(toastId.current);
        toastId.current = toast.success(
          ` ${t(reportMessages.reportOverEmailSuccess)}`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 5000);

        //Reload data
        reloadData("download");
      })
      .catch((json) => {
        console.log("API FAILED", json);
        // let snackbarPayload = {
        //   ...user.screen.snackbar,
        //   open: true,
        //   message: json.error || reportMessages.reportOverEmailFailed,
        // };
        // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        // Show failure toaster
        toast.dismiss(toastId.current);
        toastId.current = toast.error(
          `${json.error || t(reportMessages.reportOverEmailFailed)}`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 5000);

        //Reload data even if it fails so that the user can see the refreshed history
        reloadData("download");
      });
  };

  const handleGetReportLinkOverEmail = () => {
    handleGAButtonClick("SendLink");
    //Close the active report
    setActiveReport({ type: "none", id: "" });
    // //Update snackbar
    // let snackbarPayload = {
    //   ...user.screen.snackbar,
    //   open: true,
    //   message: reportMessages.reportLinkOverEmailRequest,
    // };
    // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    // Show info toaster
    toastId.current = toast.info(
      ` ${t(reportMessages.reportLinkOverEmailRequest)}`,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );

    //Making fetch call
    // ! New Implementation

    const wrapperProps = {
      activeReportFormData: {
        reportFormData: {
          ...reportFormData,
          isComparisonOn: false,
          timeFilters: {
            ...reportFormData.timeFilters,
            compareDates: [],
            isComparisonOn: false,
          },
          reportId: `report-${v4()}`,
        }, //Updating Id
        reportPayload: {}, //Adding download/scheduled report to saved reports
      },
      user: user,
      allData: allData,
      globalFilters: globalFilters,
      activeReport,
    };

    // ! OLD implementation with comparison with all records bubugs
    // const wrapperProps = {
    //   activeReportFormData: {
    //     reportFormData: { ...reportFormData, reportId: `report-${v4()}` }, //Updating Id
    //     reportPayload: {}, //Adding download/scheduled report to saved reports
    //   },
    //   user: user,
    //   allData: allData,
    //   activeReport,
    // };

    const fetchPayload = wrapperReportLinkOverEmail(wrapperProps);
    const fetchProps = {
      payload: fetchPayload,
    };
    const getReportLinkOverEmailPromise = getReportLinkOverEmail(fetchProps);
    getReportLinkOverEmailPromise
      .then((responseDate) => {
        // //Update snackbar
        // let snackbarPayload = {
        //   ...user.screen.snackbar,
        //   open: true,
        //   message: reportMessages.reportLinkOverEmailSuccess,
        // };
        // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        // Show success toaster
        toast.dismiss(toastId.current);
        toastId.current = toast.success(
          ` ${t(reportMessages.reportLinkOverEmailSuccess)}`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 5000);

        //Reload data
        reloadData("download");
      })
      .catch((json) => {
        console.log("API FAILED", json);
        // let snackbarPayload = {
        //   ...user.screen.snackbar,
        //   open: true,
        //   message: json.error || reportMessages.reportLinkOverEmailFailed,
        // };
        // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        // Show failure toaster
        toast.dismiss(toastId.current);
        toastId.current = toast.error(
          `${json.error || t(reportMessages.reportLinkOverEmailFailed)}`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 5000);

        //Reload data even if it fails so that the user can see the refreshed history
        reloadData("download");
      });
  };

  const handleUpdateSavedReport = () => {
    //const updateSaveReportBtnFlag = param;
    handleGAButtonClick("UpdateSaved");
    //Set is loading to true
    setIsLoading(true);

    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: reportMessages.reportUpdateRequest,
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    //Making fetch call
    const wrapperProps = {
      activeReportFormData: {
        reportFormData: reportFormData,
        reportPayload: activeReportFormData.reportPayload,
      },
      user: user,
      allData: allData,
      globalFilters: globalFilters,
      activeReport,
      variant,
    };
    const fetchPayload = wrapperSavedReport(wrapperProps);
    const fetchProps = {
      payload: fetchPayload,
    };
    const updateSavedReportPromise = updateSavedReport(fetchProps);
    updateSavedReportPromise
      .then((responseDate) => {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: reportMessages.reportUpdateSuccess,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
        if (variant === "adminReport") {
          ReduxDispatcher(updateUserScreen("adminReport", false));
          ReduxDispatcher(updateUserScreen("adminReportHardReload", true));
        }

        if (variant === "report") {
          //Close the active report
          setActiveReport({ type: "none", id: "" });

          //Reload data
          reloadData("saved");
        }
      })
      .catch((json) => {
        console.log("API FAILED", json);
        setIsLoading(false);
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || reportMessages.reportUpdateFailed,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };

  const handleUpdateScheduledReport = (param) => {
    const updateScheduleReportBtnFlag = param;
    handleGAButtonClick("UpdateScheduled");
    //Set is loading to true
    setIsLoading(true);

    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: reportMessages.reportUpdateRequest,
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    //Making fetch call
    const wrapperProps = {
      activeReportFormData: {
        reportFormData: reportFormData,
        reportPayload: activeReportFormData.reportPayload,
      },
      user: user,
      allData: allData,
      globalFilters: globalFilters,
      activeReport,
      updateScheduleReportBtnFlag,
      variant,
    };
    const fetchPayload = wrapperScheduledReport(wrapperProps);
    const fetchProps = {
      payload: fetchPayload,
    };
    const updateScheduledReportPromise = updateScheduledReport(fetchProps);
    updateScheduledReportPromise
      .then((responseDate) => {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: reportMessages.reportUpdateSuccess,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        if (variant === "adminReport") {
          ReduxDispatcher(updateUserScreen("adminReport", false));
          ReduxDispatcher(updateUserScreen("adminReportHardReload", true));
        }

        if (variant === "report") {
          //Close the active report
          setActiveReport({ type: "none", id: "" });

          //Reload data
          reloadData("scheduled");
        }
      })
      .catch((json) => {
        console.log("API FAILED", json);
        setIsLoading(false);
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || reportMessages.reportUpdateFailed,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };

  const handleClone = () => {
    // Google Analytics Event - Master Admin
    // masterTrackGaEventAdmin({
    //   category: "Report",
    //   action: "ReportClone",
    //   label: selections.id.value,
    // });

    const payload = {
      open: true,
      children: (
        <AdminReportClone
          ReduxDispatcher={ReduxDispatcher}
          initialName={`Clone of ${reportFormData.reportName}`}
          user={user}
          reportFormData={reportFormData}
          reportType={
            reportFormData.scheduleFlag === "yes"
              ? "scheduled"
              : reportFormData.scheduleFlag === "no"
              ? "saved"
              : ""
          }
          allData={allData}
          globalFilters={globalFilters}
          activeReport={activeReport}
          // viewsDataUi={viewsDataUi}
          // groupData={groupData}
        />
      ),
    };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleDeleteSavedReport = () => {
    handleGAButtonClick("DeleteSaved");
    //Set is loading to true
    setIsLoading(true);

    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: reportMessages.reportDeleteRequest,
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    //Making fetch call
    const reportId = reportFormData.reportId;
    const fetchProps = {
      url: `${user.apiEndpoints.baseUrl}${config.apiEndpoints.deleteSavedReport}/${reportId}`,
    };
    const deleteSavedReportPromise = deleteSavedReport(fetchProps);
    deleteSavedReportPromise
      .then((responseDate) => {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: reportMessages.reportDeleteSuccess,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
        if (variant === "adminReport") {
          ReduxDispatcher(updateUserScreen("adminReport", false));
        }

        if (variant === "report") {
          //Close the active report
          setActiveReport({ type: "none", id: "" });

          //Reload data
          reloadData("saved");
        }
      })
      .catch((json) => {
        console.log("API FAILED", json);
        setIsLoading(false);
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || reportMessages.reportDeleteFailed,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };

  const handleDeleteScheduledReport = () => {
    handleGAButtonClick("DeleteScheduled");

    //Set is loading to true
    setIsLoading(true);

    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: reportMessages.reportDeleteRequest,
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    //Making fetch call
    const reportId = reportFormData.reportId;
    const fetchProps = {
      url: `${user.apiEndpoints.baseUrl}${config.apiEndpoints.deleteScheduledReport}/${reportId}`,
    };
    const deleteScheduledReportPromise = deleteScheduledReport(fetchProps);
    deleteScheduledReportPromise
      .then((responseDate) => {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: reportMessages.reportDeleteSuccess,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        if (variant === "adminReport") {
          ReduxDispatcher(updateUserScreen("adminReport", false));
        }

        if (variant === "report") {
          //Close the active report
          setActiveReport({ type: "none", id: "" });

          //Reload data
          reloadData("scheduled");
        }
      })
      .catch((json) => {
        console.log("API FAILED", json);
        setIsLoading(false);
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || reportMessages.reportDeleteFailed,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };

  const handleAddScheduleReport = (param) => {
    const addScheduleReportFlag = param;
    handleGAButtonClick("Schedule");

    //Set is loading to true
    setIsLoading(true);

    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: reportMessages.scheduledRequest,
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    //Making fetch call
    const wrapperProps = {
      activeReportFormData: {
        reportFormData: reportFormData,
        reportPayload: activeReportFormData.reportPayload,
      },
      user: user,
      allData: allData,
      globalFilters: globalFilters,
      activeReport,
      addScheduleReportFlag,
    };
    const fetchPayload = wrapperScheduledReport(wrapperProps);
    const fetchProps = {
      payload: fetchPayload,
    };
    const addScheduledReportPromise = addScheduledReport(fetchProps);
    addScheduledReportPromise
      .then((responseDate) => {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: reportMessages.scheduledSuccess,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        //Close the active report
        setActiveReport({ type: "none", id: "" });

        //Reload data
        reloadData("scheduled");
      })
      .catch((json) => {
        console.log("API FAILED", json);
        setIsLoading(false);
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || reportMessages.scheduledFailure,
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };

  const handleDeleteReportDialog = (reportType) => {
    const message = {
      title: reportMessages.reportDeleteConfirmation,
      subtitle: "",
    };
    const handleNo = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
        })
      );
    };
    const handleYes = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
          message: { title: "", subtitle: "" },
        })
      );
      if (reportType === "saved") {
        handleDeleteSavedReport();
      } else if (reportType === "scheduled") {
        handleDeleteScheduledReport();
      }
    };
    ReduxDispatcher(
      updateDialogInfo({
        ...user.dialogInfo,
        open: true,
        message,
        handleYes,
        handleNo,
      })
    );
  };

  const handleTourOpen = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ReportManager",
      action: "Tour",
      label: "Tour",
    });

    //Start the tour
    setTourRun(true);
  };

  const handleReportNavChange = (e, newValue) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ReportManager",
      action: "NavSwitch",
      label: capitalize(newValue),
    });
    setActiveReportNav(newValue);
  };

  //This useEffect runs everytime redux changes, so it resets to original value
  // //Update reportFormData everytime parent changes
  // useEffect(() => {
  //   console.log("DID I RUN?????????")
  //   setReportFormData(activeReportFormData.reportFormData);
  // }, [activeReportFormData]);

  //If metric filters feature is unavailable, empty reportFormData.metricFilters; runs for the first time only
  useEffect(() => {
    if (!uiFeatureList.metricFilters) {
      setReportFormDataSync("metricFilters", []);
    }
  }, []);

  //If scheduleFlag (report type) changes or if feature isn't available, update reportRowCount
  useEffect(() => {
    const sigviewUserType = getSigviewUserType();
    const selectedReportType =
      reportFormData.scheduleFlag === "yes" ? "scheduled" : "saved";
    let newReportCountFileType = reportFormData.reportCountFileType
      .filter((row) => row.reportTypeList.includes(selectedReportType))
      .filter((row) => row.reportTypeList.includes(selectedReportType))
      .filter((row) =>
        row.id === "all_records" ? uiFeatureList.fullReport : row
      )
      .filter((row) =>
        row.id === "five_thousand_records" ? uiFeatureList.download : row
      );
    //Hard coded: if userType is sigview, all_records (full report) is not available
    if (sigviewUserType === "sigview" && selectedReportType === "scheduled") {
      newReportCountFileType = newReportCountFileType.filter(
        (row) => row.id !== "all_records"
      );
    }
    const allAvailableReportCountIds = newReportCountFileType.map(
      (row) => row.id
    );
    const newSelectedReportRowCount = allAvailableReportCountIds.includes(
      reportFormData.reportRowCount
    )
      ? reportFormData.reportRowCount
      : allAvailableReportCountIds[0];
    //Update state only when necessary
    if (newSelectedReportRowCount !== reportFormData.reportRowCount) {
      setReportFormDataSync("reportRowCount", newSelectedReportRowCount);
    }
  }, [reportFormData.scheduleFlag]);

  //If triggerType changes, update triggerTimeRangeNumber
  useEffect(() => {
    const filteredTimeRangeScheduledArrIds = filteredTimeRangeScheduledArr.map(
      (row) => row.id
    );
    if (
      !filteredTimeRangeScheduledArrIds.includes(
        reportFormData.triggerTimeRangeNumber
      )
    ) {
      const newTriggerTimeRangeNumber = filteredTimeRangeScheduledArrIds[0];
      //Update state only when necessary
      if (newTriggerTimeRangeNumber !== reportFormData.triggerTimeRangeNumber) {
        setReportFormDataSync(
          "triggerTimeRangeNumber",
          newTriggerTimeRangeNumber
        );
      }
    }
  }, [reportFormData.triggerType]);

  // Validation function for orderBy details
  // Update orderBy/orderByType details if metricsList/dimensionsList/comparisonOn/orderById changes
  useEffect(() => {
    let newOrderByDetails = {
      orderById: reportFormData.orderById,
      orderBy: reportFormData.orderBy,
      orderByType: reportFormData.orderByType,
    };
    const isComparisonOn = reportFormData.isComparisonOn;
    //If the user removes a metric/dim on which it was sorted by, it becomes invalid
    if (!allSelectedMetricsDimensionsIds.includes(reportFormData.orderById)) {
      //If we have any selected metrics, change it to the first value
      if (allSelectedMetricsDimensionsIds.length) {
        newOrderByDetails = {
          ...newOrderByDetails,
          orderById: allSelectedMetricsDimensionsIds[0],
        };
      } else {
        //Else replace it with empty string
        newOrderByDetails = { ...newOrderByDetails, orderById: "" };
      }
    }
    //If isComparison is switched off and selected orderByType was not id_only, change it to id_only
    if (!isComparisonOn && reportFormData.orderByType !== "id_only") {
      newOrderByDetails = {
        ...newOrderByDetails,
        orderByType: "id_only",
      };
    }
    //Id newOrderByDetails.orderById doesn't comply with orderByType, change it
    if (
      !isOrderByTypeValid(
        newOrderByDetails.orderById,
        newOrderByDetails.orderByType
      )
    ) {
      newOrderByDetails = {
        ...newOrderByDetails,
        orderByType: "id_only",
      };
    }

    const currState = {
      orderById: reportFormData.orderById,
      orderBy: reportFormData.orderBy,
      orderByType: reportFormData.orderByType,
    };
    //Update state only when necessary
    if (!isEqual(currState, newOrderByDetails)) {
      setReportFormDataSync(undefined, newOrderByDetails);
    }
  }, [
    reportFormData.metricsList,
    reportFormData.dimensionsList,
    reportFormData.isComparisonOn,
    reportFormData.orderById,
  ]);

  //Update isComparisonOn everytime timeFilters change
  useEffect(() => {
    const newIsComparisonOf = reportFormData.timeFilters.isComparisonOn;
    //Update state only when they are different
    if (newIsComparisonOf !== reportFormData.isComparisonOn) {
      setReportFormDataSync("isComparisonOn", newIsComparisonOf);
    }
  }, [reportFormData.timeFilters]);

  //Update timeFilters.isComparisonOn everytime reportFormData.isComparisonOn changes
  useEffect(() => {
    const newIsComparisonOf = reportFormData.isComparisonOn;
    //Update state only when they are different
    if (newIsComparisonOf !== reportFormData.timeFilters.isComparisonOn) {
      setReportFormDataSync("timeFilters", {
        ...reportFormData.timeFilters,
        isComparisonOn: newIsComparisonOf,
      });
    }
  }, [reportFormData.isComparisonOn]);

  //Update metric list everytime metric filters change
  useEffect(() => {
    const allMetricFiltersIds = reportFormData.metricFilters.map(
      (row) => row.metricId
    );
    const allMetricListIds = reportFormData.metricsList.map((row) => row._id);
    let newMetricsToBeAddedToMetricList = [];
    allMetricFiltersIds.forEach((metricFilterId) => {
      //Check if metric list has that entry
      if (!allMetricListIds.includes(metricFilterId)) {
        //If not, add it
        const newMetricObject = getMeasureObjByOriginalID(
          metricFilterId,
          allData.plotlyMetrics
        );
        newMetricsToBeAddedToMetricList.push(newMetricObject);
      }
    });
    setReportFormData((prevState) => ({
      ...prevState,
      metricsList: [
        ...prevState.metricsList,
        ...newMetricsToBeAddedToMetricList,
      ],
    }));
  }, [reportFormData.metricFilters]);

  //Update metric filters everytime metrics list change
  //Show toast if we are removing any metric filters
  useEffect(() => {
    const allMetricListIds = reportFormData.metricsList.map((row) => row._id);
    let newMetricFilterList = [...reportFormData.metricFilters];
    let showSnackbar = false;
    let metricsRemoved = [];
    reportFormData.metricFilters.forEach(({ metricId }) => {
      //Check if it is absent in metrics list
      if (!allMetricListIds.includes(metricId)) {
        //If yes, remove it and change the toast/snackbar flag to true
        newMetricFilterList = newMetricFilterList.filter(
          (row) => row.metricId !== metricId
        );
        showSnackbar = true;
        const removedMeasureObj = getMeasureObjByOriginalID(
          metricId,
          allData.plotlyMetrics
        );
        metricsRemoved.push(removedMeasureObj.measureTitle);
      }
    });
    //It means atleast one id has been remove, set state again and update toast
    if (showSnackbar) {
      setReportFormData((prevState) => ({
        ...prevState,
        metricFilters: newMetricFilterList,
      }));
      const message = `${metricsRemoved.join(", ")} removed from filters as ${
        metricsRemoved.length > 1 ? "they were" : "it was"
      } removed from the metrics list!`;
      //Update snackbar
      let snackbarPayload = {
        ...user.screen.snackbar,
        open: true,
        message,
      };
      ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
    }
  }, [reportFormData.metricsList]);

  //Update reportFormError everytime reportFormData changes
  useEffect(() => {
    // const reportPayload = activeReportFormData.reportPayload;
    // let originalName = "";
    // //Could have been avoided if the structure was same for all
    // //In case reportPayload is defined, meaning it's an edit report case
    // if (reportPayload) {
    //   if (activeReport.type === "saved") {
    //     originalName = reportPayload.recentDownloadRecord.name;
    //   } else if (activeReport.type === "scheduled") {
    //     originalName = reportPayload.reportName;
    //   }
    // }
    // const newReportFormError = validateReportFormData({
    //   reportFormData,
    //   allSavedReportNames,
    //   allScheduledReportNames,
    //   originalName,
    // });
    const newReportFormError = validateReportFormData({
      reportFormData,
    });
    setReportFormError(newReportFormError);
  }, [reportFormData]);

  //Defining required variables
  const filteredTimeRangeScheduledArr = timeRangeScheduledArr.filter((row) =>
    row.triggerTypeList.includes(reportFormData.triggerType)
  );
  const allSelectedMetricsDimensions = [
    ...reportFormData.dimensionsList.map((row) => ({
      id: row._id,
      name: row.dimTitle,
    })),
    ...reportFormData.metricsList.map((row) => ({
      id: row._id,
      name: row.measureTitle,
    })),
  ];
  const allSelectedMetricsDimensionsIds = allSelectedMetricsDimensions.map(
    (row) => row.id
  );
  //based on these types the buttons and their expected behavior will change
  //So, there are 7 buttons in total
  // 1) Cancel: To cancel the changes; Will trigger a updateDialogInfo
  // 2) Schedule:
  //    A) To save a NEW scheduled report
  //    B) To schedule an old saved report
  // 3) Save:
  //    A) To save a NEW saved report
  //    B) To add a download report as saved report
  //    C) To add a scheduled report as saved report
  // 4) Delete: To delete an old saved/scheduled report
  // 5) Update
  //    A) To update a old saved report
  //    B) To update a old scheduled report
  // 6) Download: To download a 5000 rows report
  // 7) Email: To trigger report over email
  // 8) Send Link: To trigger full report over email
  const showSaveButton =
    reportFormData.scheduleFlag === "no" && !activeReportFormData.reportPayload;
  const showCancelButton = variant !== "adminReport";
  const showAddToSavedReportsButton =
    (activeReport.type === "download" || activeReport.type === "scheduled") &&
    activeReportFormData.reportPayload &&
    variant !== "adminReport";
  const showAddToScheduledReportsButton =
    activeReport.type === "saved" &&
    activeReportFormData.reportPayload &&
    variant !== "adminReport";
  const showScheduleButton =
    reportFormData.scheduleFlag === "yes" &&
    !activeReportFormData.reportPayload;
  const showUpdateButton =
    (reportFormData.scheduleFlag === "no" &&
      activeReport.type === "saved" &&
      activeReportFormData.reportPayload) ||
    (reportFormData.scheduleFlag === "yes" &&
      activeReport.type === "scheduled" &&
      activeReportFormData.reportPayload);
  const showCloneButton = variant === "adminReport";
  const showDeleteButton =
    activeReportFormData.reportPayload &&
    activeReport.type !== "download" &&
    variant !== "adminReport";
  const showDownloadButton =
    uiFeatureList.download &&
    reportFormData.scheduleFlag === "no" &&
    variant !== "adminReport" &&
    reportFormData.reportRowCount === "five_thousand_records"; //hard coded
  const showEmailButton =
    reportFormData.scheduleFlag === "no" &&
    variant !== "adminReport" &&
    reportFormData.reportRowCount === "fifty_thousand_records"; //hard coded
  const showSendLinkButton =
    uiFeatureList.fullReport &&
    reportFormData.scheduleFlag === "no" &&
    variant !== "adminReport" &&
    reportFormData.reportRowCount === "all_records"; //hard coded
  const isDownloadValid =
    !reportFormError.message.dimensionsList.flag &&
    !reportFormError.message.metricsList.flag &&
    reportFormData.reportName.length > 0;
  const buttonParams = {
    showAddToSavedReportsButton,
    showAddToScheduledReportsButton,
    handleAddToSavedReport,
    handleAddToScheduledReports,
  };
  const tourStepsData = tourData["reportManager"].map((row) => ({
    ...row,
    placement: "auto",
  }));
  const reportNavArrFinal = [
    ...reportNavArr,
    ...(reportFormData.scheduleFlag === "yes"
      ? []
      : [{ id: "preview", name: "Preview", disabled: reportFormError.flag }]),
  ];

  //DEBUGGING
  // console.groupCollapsed("REPORT MANAGER STATE");
  // console.log("reportFormData", reportFormData);
  // console.log("reportFormError", reportFormError);
  // console.groupEnd();

  return (
    <div
      className={`${
        variant === "adminReport" ? "right-panel-admin-report" : "right-panel"
      }`}
    >
      {tourRun && (
        <Tour
          steps={tourStepsData}
          tourRun={tourRun}
          setTourRun={setTourRun}
          autoStart={true}
        />
      )}
      {isLoading && (
        <div className="loading-overlay">
          <Loader />
        </div>
      )}
      <div className="content-nav-bar">
        <div className="content-nav-bar-container" id="reportFormNavBar">
          <SigviewNav
            data={reportNavArrFinal}
            value={activeReportNav}
            onChange={handleReportNavChange}
            style={{
              fontSize: "12px",
              textTransform: "uppercase",
              padding: "0px 11px",
            }}
            customClassName={`Reports`}
          />
        </div>
        <SigviewTooltip title="Start tour">
          <i
            className="material-icons-outlined tour-icon ReportTour-GA"
            onClick={handleTourOpen}
          >
            help_outline
          </i>
        </SigviewTooltip>
      </div>
      <div className="content-main">
        {activeReportNav === "details" ? (
          <ReportManagerForm
            reportFormData={reportFormData}
            setReportFormDataSync={setReportFormDataSync}
            user={user}
            allSelectedMetricsDimensions={allSelectedMetricsDimensions}
            filteredTimeRangeScheduledArr={filteredTimeRangeScheduledArr}
            ReduxDispatcher={ReduxDispatcher}
            allData={allData}
            reportFormError={reportFormError}
            activeReportFormData={activeReportFormData}
            activeReport={activeReport}
            buttonParams={buttonParams}
            uiFeatureList={uiFeatureList}
            disabledAddNewReport={disabledAddNewReport}
            bucketList={bucketList}
            variant={variant}
            usersData={usersData}
          />
        ) : (
          <ReportManagerPreview
            user={user}
            allData={allData}
            reportFormData={reportFormData}
            activeReportFormData={activeReportFormData}
            activeReport={activeReport}
            globalFilters={globalFilters}
          />
        )}
      </div>
      <div className="content-menu-bar" id="reportFormMenuBar">
        <div className="left-panel">
          {/* {showAddToSavedReportsButton && (
            <SigviewButton
              variant="outlined"
              onClick={handleAddToSavedReport}
              title="Add to Saved Reports"
              style={{ margin: { right: "5px" } }}
              disabled={reportFormError.flag}
            />
          )}
          {showAddToScheduledReportsButton && (
            <SigviewButton
              variant="outlined"
              onClick={handleAddToScheduledReports}
              title="Add to Scheduled Reports"
              style={{ margin: { right: "5px" } }}
              disabled={reportFormError.flag}
            />
          )} */}
        </div>
        <div className="right-panel">
          {showCancelButton && (
            <SigviewButton
              variant="outlined"
              onClick={handleCancel}
              title="Cancel"
              style={{ margin: { right: "5px" } }}
              customClassName="ReportCancel-GA"
            />
          )}
          {showSaveButton && (
            <SigviewButton
              variant="contained"
              onClick={handleAddSaveReport}
              title="Save"
              style={{ margin: { right: "5px" } }}
              disabled={reportFormError.flag}
              customClassName={`${rootPage}ReportSaved-GA`}
            />
          )}
          {showScheduleButton && (
            <SigviewButton
              variant="contained"
              onClick={() => {
                handleAddScheduleReport(true);
              }}
              customClassName={`${rootPage}ReportSchedule-GA`}
              title="Schedule"
              style={{ margin: { right: "5px" } }}
              disabled={
                reportFormError.flag ||
                (reportFormData.reportRowCount === "fifty_thousand_records" &&
                  reportFormData.reportCountFileType.filter(
                    (el) => el.id === reportFormData.reportRowCount
                  )[0].selectedDropdown === "writeToBucket" &&
                  ["initial", null, undefined].includes(
                    reportFormData.reportCountFileType.filter(
                      (el) => el.id === reportFormData.reportRowCount
                    )[0].bucketId
                  ))
              }
            />
          )}
          {showDeleteButton && (
            <SigviewButton
              variant="contained"
              customClassName={`${
                activeReport.type === "saved"
                  ? rootPage + "ReportDeleteSaved-GA"
                  : rootPage + "ReportDeleteScheduled-GA"
              }`}
              onClick={() => {
                const reportType = activeReport.type;
                handleDeleteReportDialog(reportType);
              }}
              title="Delete"
              style={{ margin: { right: "5px" } }}
            />
          )}
          {showCloneButton && (
            <SigviewButton
              title="Clone"
              variant="contained"
              onClick={handleClone}
              disabled={
                reportFormError.flag ||
                reportFormData.recipientsList[0].id === null
              }
              style={{ margin: { right: "5px" } }}
              customClassName={`${rootPage}CloneReport-GA`}
            />
          )}
          {showUpdateButton && (
            <SigviewButton
              variant="contained"
              customClassName={`${
                activeReport.type === "saved"
                  ? rootPage + "ReportUpdateSaved-GA"
                  : rootPage + "ReportUpdateScheduled-GA"
              }`}
              onClick={() => {
                if (activeReport.type === "saved") {
                  handleUpdateSavedReport(true);
                } else if (activeReport.type === "scheduled") {
                  handleUpdateScheduledReport(true);
                }
              }}
              title="Update"
              style={{ margin: { right: "5px" } }}
              disabled={
                reportFormError.flag ||
                reportFormData.recipientsList[0].id === null ||
                (reportFormData.reportRowCount === "fifty_thousand_records" &&
                  reportFormData.reportCountFileType.filter(
                    (el) => el.id === reportFormData.reportRowCount
                  )[0].selectedDropdown === "writeToBucket" &&
                  ["initial", null, undefined].includes(
                    reportFormData.reportCountFileType.filter(
                      (el) => el.id === reportFormData.reportRowCount
                    )[0].bucketId
                  ))
              }
            />
          )}
          {showDownloadButton && (
            <SigviewButton
              variant="contained"
              onClick={handleReportDownload}
              title="Download"
              style={{ margin: { right: "5px" } }}
              disabled={!isDownloadValid}
              customClassName={`${rootPage}ReportDownload-GA`}
            />
          )}
          {showEmailButton && (
            <SigviewButton
              variant="contained"
              onClick={handleGetReportOverEmail}
              title="Email"
              style={{ margin: { right: "5px" } }}
              disabled={!isDownloadValid}
              customClassName={`${rootPage}ReportEmail-GA`}
            />
          )}
          {showSendLinkButton && (
            <SigviewButton
              variant="contained"
              onClick={handleGetReportLinkOverEmail}
              title="Send Link"
              style={{ margin: { right: "5px" } }}
              disabled={!isDownloadValid}
              customClassName={`${rootPage}ReportSendLink-GA`}
            />
          )}
        </div>
      </div>
    </div>
  );
}

ReportManager.propTypes = {
  user: PropTypes.object,
};

export default ReportManager;
