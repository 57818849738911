// * import lib component
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { Box } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import Loader from "../../../components/Loader/Loader";
import { config } from "../../../config/config";
import { ThemeContext } from "../../../contexts/ThemeContext";
import LayoutTopSideBottomAdmin from "../../../layouts/LayoutTopSideBottomAdmin/LayoutTopSideBottomAdmin";
import { updateAdminForm, updateUserScreen } from "../../../redux/actions";
// import { masterTrackGaEventAdmin } from "../../../services/ga";
import { capitalize, getBreadcrumbsData } from "../../../utils/utils";
import SigviewSearchField from "../../../components/Common/SigviewSearchField";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SigviewSingleSelect from "../../../components/Common/SigviewSingleSelect";
import SigviewNav from "../../../components/Common/SigviewNav";
import {
  getAllSavedReports,
  getAllScheduledReports,
  readAvailableGroupData,
  readGroupsByOrg,
  usersWiseReport,
} from "../../../services/api";
import axios from "axios";
import {
  initialAdminreportState,
  unwrapperAllReports,
  unwrapperSavedReports,
  unwrapperScheduledReports,
} from "../../../utils/adminUtils";
import { adminReport } from "../../../redux/reducers/adminReport";
import useReducerLogger from "../../../utils/useReducerLogger";
import SigviewAdminTable from "../../../components/SigviewAdminTable";
import SigviewAdminReportTab from "../../../components/SigviewAdminReportTab";
import { debounce } from "underscore";
import { Pagination } from "@material-ui/lab";
import SigviewEnhancedTable from "../../../components/SigviewEnhancedTable";
import AppMenuBar from "../../../components/AppMenuBar";
import { useHistory } from "react-router";

function AdminReportContainer(props) {
  //  * Destructure props
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const { dispatch: ReduxDispatcher, user, allData = {} } = props;
  const { views: viewsData } = allData;

  let store = useSelector((state) => state);
  // console.log("store", store);

  // * Define contexts
  const history = useHistory();

  useEffect(() => {
    if (store.data.views.status !== "success") {
      history.push("/admin");
    }
  }, []);

  const initialData = { status: "initial", message: "", data: [] };

  const [search, setSearch] = useState("");
  const [blockApi, setBlockApi] = useState(false);
  const [searchReport, setSearchReport] = useState("");

  const [scheduledReports, setScheduledReports] = useState(initialData);
  const [availableGroupData, setAvailableGroupData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });
  const [savedReports, setSavedReports] = useState(initialData);
  const [allReports, setAllReports] = useState({
    status: "initial",
    message: "",
    data: {
      scheduledReports: [],
      savedReports: [],
    },
  });

  const [reloadReportData, setReloadReportData] = useState(Date.now());
  const [reloadUserData, setReloadUserData] = useState(Date.now());

  const handleReportDataReload = () => {
    setReloadReportData(Date.now());
  };
  const handleUserDataReload = () => {
    setReloadUserData(Date.now());
  };

  // As soon as adminReportOverlay closes Reports data reloads
  useEffect(() => {
    if (!store.user.screen.adminReport) {
      handleReportDataReload();
    }
  }, [store.user.screen.adminReport]);

  // As soon as adminReportOverlay closes User data reloads
  useEffect(() => {
    if (store.user.screen.adminReportHardReload) {
      handleUserDataReload();
      ReduxDispatcher(updateUserScreen("adminReportHardReload", false));
    }
  }, [store.user.screen.adminReportHardReload]);

  const activeTab = user.screen.activeTab;
  const handleActiveTab = (e, id) => {
    //Return to home (will be controlled by router later)

    // Google Analytics Event - Master Admin
    // masterTrackGaEventAdmin({
    //   category: "Admin",
    //   action: `${capitalize(id)}Open`,
    //   label: "Breadcrumb",
    // });

    const activeTab = id;
    const value = { activeNav: "admin", activeTab };
    const action = updateUserScreen(null, value);
    ReduxDispatcher(action);
  };

  // const breadcrumbsData = getBreadcrumbsData(user);
  const layoutTopSideBottomAdminProps = {
    activeTab,
    handleActiveTab,
  };

  const handleViewsDataReload = () => {
    // setViewsDataReload(new Date().valueOf());
    // TODO Update action to store so that views data can reload in App.js
    // ! HACK
    window.location.reload();
  };

  const handleSearch = (e, value) => {
    setBlockApi(true);
    setSearch(value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const [selections, dispatchSelections] = useReducer(
    useReducerLogger(adminReport),
    {
      ...initialAdminreportState,
      view: {
        message: "",
        value: store.data.views.result[0]?.id,
        status: "valid",
      },
    }
  );

  const [users, setUsers] = useState({
    result: [],
    status: "initial",
    messsage: "",
  });

  useEffect(() => {
    setUsers({
      message: "",
      status: "loading",
      value: {
        userList: [],
        totalUsers: 0,
      },
    });

    setAllReports({
      status: "loading",
      message: "",
      data: {
        scheduledReports: [],
        savedReports: [],
      },
    });
    setSavedReports({
      status: "loading",
      message: "",
      data: [],
    });
    setScheduledReports({
      status: "loading",
      message: "",
      data: [],
    });

    const payload = {
      page: selections.pageNumber.value,
      size: selections.rowsPerPage.value,
      sortBy:
        selections.sortBy.value === "asc"
          ? 1
          : selections.sortBy.value === "desc"
          ? -1
          : null,
      sortOn: selections.sortOn.value,
      // group: selections.group.value,
      searchText: search,
      orgViewReq: {
        view: selections.view.value,
        organization: user.reqMetadata.organization,
      },
    };

    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
      payload,
    };

    if (!blockApi || (blockApi && selections.pageNumber.value === 1)) {
      const usersWiseReportPromise = usersWiseReport(fetchProps);
      usersWiseReportPromise
        .then((data) => {
          setUsers({
            message: "",
            status: "success",
            value: {
              ...data.result.data,
              totalUsers: data.result.data.totalUsers?.[0]?.count || 0,
            },
          });
        })
        .catch((json) => {
          setUsers({
            message: json?.message || "Something went wrong!",
            status: "error",
            value: {
              userList: [],
              totalUsers: 0,
            },
          });
        });
    }
    setBlockApi(false);
  }, [
    selections.view.value,
    selections.group.value,
    selections.sortBy.value,
    selections.sortOn.value,
    selections.pageNumber.value,
    selections.rowsPerPage.value,
    search,
    reloadUserData,
  ]);

  useEffect(() => {
    handlePageNumber(1);
  }, [
    selections.view.value,
    selections.group.value,
    selections.sortBy.value,
    selections.sortOn.value,
    selections.rowsPerPage.value,
    search,
  ]);

  useEffect(() => {
    if (users.status === "success") {
      if (users.value.totalUsers === 0) {
        let payload = {
          key: "activeUser",
          value: "",
        };
        const action = updateAdminForm(payload);

        dispatchSelections(action);
      } else if (users.value.userList.length > 0) {
        let payload = {
          key: "activeUser",
          value: users.value.userList[0].email,
        };
        const action = updateAdminForm(payload);

        dispatchSelections(action);
      } else if (users.value.userList.length === 0) {
        let payload = {
          key: "activeUser",
          value: "",
        };
        const action = updateAdminForm(payload);

        dispatchSelections(action);
      }
    }
  }, [users]);

  useEffect(() => {
    // Make fetch call using axios
    const readAvailableGroupDataPromise = readAvailableGroupData({
      org: store.user.reqMetadata.organization,
      view: selections.view.value || store.data.views.result[0]?.id,
    });

    readAvailableGroupDataPromise
      .then((data) => {
        setAvailableGroupData({
          result: data?.result?.data.map((el) => ({
            id: el._id,
            name: el.name,
          })),
          status: "success",
          messsage: "",
        });
      })
      .catch((json) => {
        setAvailableGroupData({
          result: [],
          status: "error",
          messsage: "API failed",
        });
      });
  }, [selections.view.value]);

  useEffect(() => {
    if (
      scheduledReports.status === "success" &&
      savedReports.status === "success"
    )
      setAllReports({
        status: "success",
        message: "",
        data: {
          scheduledReports: [...scheduledReports.data],
          savedReports: [...savedReports.data],
        },
      });
    if (scheduledReports.status === "error" || savedReports.status === "error")
      setAllReports({
        status: "error",
        message:
          scheduledReports.status === "error"
            ? scheduledReports.message
            : savedReports.status === "error"
            ? savedReports.message
            : "Something went wrong!",
        data: {
          scheduledReports: [...scheduledReports.data],
          savedReports: [...savedReports.data],
        },
      });
  }, [scheduledReports.data, savedReports.data]);

  // Styles
  const box1Container = {
    padding: "5px 0px",
  };
  const box1 = {
    padding: "5px 0px",
    width: "100%",
    margin: "0px",
    width: "33%",
    boxSizing: "border-box",
    display: "inline-block",
  };
  const searchFieldContainerBox = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "7px",
    padding: "5px 10px",
  };
  const box2 = { width: "130px", display: "inline-block" };
  const box3 = {
    // width: "100px",
    display: "inline-block",
    display: "flex",
    justifyContent: "flex-end",
  };
  const tabContainerBox = { height: "calc(100% - 89px)", overflow: "auto" };

  const noAttributeMessageBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  };

  const sigviewSearchFieldStyle = {
    wrapperHeight: "20px",
    iconSize: "12px",
    textFieldFontSize: "10px",
    placeholderFontSize: "9px",
  };

  // const toggleReportManager = () => {
  //   // Google Analytics Event - Master
  //   masterTrackGaEvent({
  //     category: "ReportManager",
  //     action: "OpenReportManager",
  //     label: "Sidenav",
  //   });
  //   ReduxDispatcher(updateUserScreen("reportManager", true));
  // };

  const getAllReports = (props) => {
    if (props?.email !== selections.activeUser.value) {
      let payload = {
        key: "activeUser",
        value: props?.email,
      };
      const action = updateAdminForm(payload);

      dispatchSelections(action);

      setAllReports({
        status: "loading",
        message: "",
        data: {
          scheduledReports: [],
          savedReports: [],
        },
      });
      setSavedReports({
        status: "loading",
        message: "",
        data: [],
      });
      setScheduledReports({
        status: "loading",
        message: "",
        data: [],
      });
    }
  };

  useEffect(() => {
    let payload = {
      orgViewReq: {
        organization: store.user?.reqMetadata?.organization,
        view: selections.view.value,
      },
      email: selections.activeUser.value,
    };

    //Make fetch call using axios
    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
      payload,
    };
    const getAllScheduledReportsPromise = getAllScheduledReports(fetchProps);
    getAllScheduledReportsPromise
      .then((responseData) => {
        let newData = {
          status: "success",
          message:
            responseData?.result?.data?.length || [].length !== 0
              ? ""
              : "No scheduled reports to display",
          data: responseData?.result?.data || [],
        };
        setScheduledReports(newData);
      })
      .catch((json) => {
        if (json.error !== config.hardCoded.queryCancelled) {
          let newData = { status: "error", message: json.error, data: [] };
          setScheduledReports(newData);
          console.groupCollapsed("UI ERROR");
          console.log("ERROR ->", json.error);
          console.groupEnd();
        }
      });

    const getAllSavedReportsPromise = getAllSavedReports(fetchProps);
    getAllSavedReportsPromise
      .then((responseData) => {
        let newData = {
          status: "success",
          message:
            responseData?.result?.data?.length || [].length !== 0
              ? ""
              : "No saved reports to display",
          data: responseData?.result?.data || [],
        };
        setSavedReports(newData);
      })
      .catch((json) => {
        if (json.error !== config.hardCoded.queryCancelled) {
          let newData = { status: "error", message: json.error, data: [] };
          setSavedReports(newData);
          console.groupCollapsed("UI ERROR");
          console.log("ERROR ->", json.error);
          console.groupEnd();
        }
      });
  }, [selections.activeUser.value, selections.view.value, reloadReportData]);

  // console.log("REPORTS", scheduledReports, savedReports, allReports);

  const handleViewChange = (value) => {
    const payload = {
      key: "view",
      value,
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleGroupchange = (value) => {
    const payload = {
      key: "group",
      value,
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleSortBy = (value) => {
    const payload = {
      key: "sortBy",
      value,
    };
    const action = updateAdminForm(payload);

    dispatchSelections(action);
  };
  const handleSortOn = (value) => {
    const payload = {
      key: "sortOn",
      value,
    };
    const action = updateAdminForm(payload);

    dispatchSelections(action);
  };
  const handlePageNumber = (value) => {
    const payload = {
      key: "pageNumber",
      value,
    };
    const action = updateAdminForm(payload);

    dispatchSelections(action);
  };

  const toggleReportManager = (row) => {
    ReduxDispatcher(updateUserScreen("adminReport", true));
    ReduxDispatcher(
      updateUserScreen("adminReportData", {
        activeAdminReport: row,
        scheduledAdminReports: scheduledReports,
        savedAdminReports: savedReports,
      })
    );
  };

  const handleReportNavChange = (e, newValue) => {
    const payload = {
      key: "tab",
      value: newValue,
    };
    const action = updateAdminForm(payload);

    dispatchSelections(action);
  };
  const buttonProps = {
    handleClick: () => {},
    title: "",
    flag: false,
  };

  const handleSearchFieldChange = (e, value) => {
    setSearchReport(value);
  };

  const appMenuBarProps = {
    buttonProps,
    onSearchFieldChange: handleSearchFieldChange,
    placeholder: "Search report...",
    style: { padding: "10px 0px" },
  };

  const reportRows =
    selections.tab.value === "allReports"
      ? unwrapperAllReports(allReports.data).filter((el) =>
          el.name.toLowerCase().includes(searchReport.toLowerCase())
        )
      : selections.tab.value === "scheduledReports"
      ? unwrapperScheduledReports(scheduledReports.data).filter((el) =>
          el.name.toLowerCase().includes(searchReport.toLowerCase())
        )
      : selections.tab.value === "savedReports"
      ? unwrapperSavedReports(savedReports.data).filter((el) =>
          el.name.toLowerCase().includes(searchReport.toLowerCase())
        )
      : [];

  return (
    <>
      <LayoutTopSideBottomAdmin {...layoutTopSideBottomAdminProps}>
        {/* <SigviewBreadcrumb
          // data={breadcrumbsData} //data
          onClick={handleActiveTab} //onClick
        /> */}
        {viewsData.status === "loading" && <Loader />}
        {viewsData.status === "error" && (
          <ErrorHandler
            message={viewsData.message || config.hardCoded.uiErrorMessage}
            reloadFlag={true}
            onReload={handleViewsDataReload}
          />
        )}
        {viewsData.status && (
          <>
            <Box
              css={{
                padding: "20px 20px",
                display: "flex",
                height: "100%",
                boxSizing: "border-box",
              }}
            >
              <Box css={{ width: "300px" }}>
                <Box css={box1Container}>
                  <Box css={{ ...box1, paddingRight: "10px" }}>
                    <SigviewTypography
                      variant="pSmall"
                      style={{
                        height: "max-content",
                      }}
                    >
                      Select View:
                    </SigviewTypography>
                    <SigviewSingleSelect
                      data={store.data.views.result || []}
                      value={selections.view.value}
                      maxWidth="30px"
                      width="100%"
                      minWidth="100%"
                      onChange={handleViewChange}
                    />
                  </Box>
                  {/* <Box css={box1}>
                    <SigviewTypography
                      variant="pSmall"
                      style={{ height: "max-content" }}
                    >
                      Select Group:
                    </SigviewTypography>
                    <SigviewSingleSelect
                      data={availableGroupData.result}
                      value={selections.group.value}
                      maxWidth="100%"
                      width="100%"
                      minWidth="100%"
                      onChange={handleGroupchange}
                    />
                  </Box> */}
                  <Box css={{ ...box1, paddingRight: "10px" }}>
                    <SigviewTypography
                      variant="pSmall"
                      style={{ height: "max-content" }}
                    >
                      Sort by:
                    </SigviewTypography>
                    <SigviewSingleSelect
                      data={[
                        { id: "asc", name: "Ascending" },
                        { id: "desc", name: "Descending" },
                      ]}
                      value={selections.sortBy.value}
                      maxWidth="100%"
                      width="100%"
                      minWidth="100%"
                      onChange={handleSortBy}
                    />
                  </Box>
                  <Box css={{ ...box1 }}>
                    <SigviewTypography
                      variant="pSmall"
                      style={{ height: "max-content" }}
                    >
                      Sort on:
                    </SigviewTypography>
                    <SigviewSingleSelect
                      data={[
                        { id: "email", name: "Email" },
                        { id: "lastSuccessLoginTime", name: "Last login" },
                      ]}
                      value={selections.sortOn.value}
                      maxWidth="100%"
                      width="100%"
                      minWidth="100%"
                      onChange={handleSortOn}
                    />
                  </Box>
                </Box>
                <Box
                  css={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "7px",
                    padding: "5px 0px",
                  }}
                >
                  <Box css={{ width: "300px", display: "inline-block" }}>
                    <SigviewSearchField
                      onChange={debouncedResults}
                      customStyle={sigviewSearchFieldStyle}
                      placeholder="Search user..."
                    />
                  </Box>
                </Box>
                <Box
                  className={`sigview-styled-scroller-thin`}
                  css={{
                    height: "560px",
                    width: "100%",
                    // background: themeColors["secondaryColorLightest"],
                    border: `1px solid  ${themeColors["secondaryColorLighter"]}`,
                    boxSizing: "border-box",
                    borderRadius: "3px 3px 0px 0px",
                    overflow: "auto",
                  }}
                >
                  <>
                    {(users.status === "loading" ||
                      users.status === "initial") && <Loader />}
                    {users.status === "error" && (
                      <ErrorHandler
                        // message={
                        //   users.message || config.hardCoded.uiErrorMessage
                        // }
                        reloadFlag={true}
                        // onReload={handleViewsDataReload}
                      />
                    )}
                    {users.status === "success" && (
                      <SigviewAdminReportTab
                        data={users.value.userList}
                        onClick={getAllReports}
                        selectedValue={selections.activeUser.value}
                      />
                    )}
                  </>
                </Box>
                <Box
                  css={{
                    height: "50px",
                    width: "100%",
                    borderRadius: "0px 0px 3px 3px",
                    overflow: "hidden",
                    background: themeColors["secondaryColorLightest"],
                    boxShadow: `0 2px 3px -1px ${themeColors["secondaryColor"]} `,
                  }}
                >
                  {/* <Box css={box1}>
                    <SigviewTypography
                      variant="pSmall"
                      style={{ height: "max-content" }}
                    >
                      Sort on:
                    </SigviewTypography>
                    <SigviewSingleSelect
                      data={[
                        { id: 15, name: 15 },
                        { id: 25, name: 25 },
                      ]}
                      value={selections.rowsPerPage.value}
                      maxWidth="100%"
                      width="100%"
                      minWidth="100%"
                      // onChange={handleSortOn}
                    />
                  </Box> */}

                  <Box
                    css={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(users.value?.totalUsers / 15 || 0)}
                      size="small"
                      page={selections.pageNumber.value}
                      onChange={(e, page) => {
                        handlePageNumber(page);
                      }}
                    />
                  </Box>
                </Box>
                {/* <SigviewAdminTable
                // handleClickRow={handleClickRow}
                // headCells={unWrapperGroups(modifiedUsersData)}
                // rows={filteredData}
                /> */}
              </Box>

              <Box css={{ width: "100%" }}>
                <Box css={{ height: "46px" }}>
                  <SigviewNav
                    data={[
                      {
                        id: "allReports",
                        name: `All Reports (${
                          [
                            ...allReports.data.scheduledReports,
                            ...allReports.data.savedReports,
                          ].length
                        })`,
                        disabled: false,
                      },
                      {
                        id: "scheduledReports",
                        name: `Scheduled Reports (${
                          [...allReports.data.scheduledReports].length
                        })`,
                        disabled: false,
                      },
                      {
                        id: "savedReports",
                        name: `Saved Reports (${
                          [...allReports.data.savedReports].length
                        })`,
                        disabled: false,
                      },
                    ]}
                    value={selections.tab.value}
                    onChange={handleReportNavChange}
                  />
                </Box>

                <Box
                  css={{
                    padding: "0px 0px 0px 20px",
                    height: "calc(100% - 46px)",
                    boxSizing: "border-box",
                  }}
                >
                  <AppMenuBar {...appMenuBarProps} />
                  <>
                    {(selections.tab.value === "allReports"
                      ? ["loading", "initial"].includes(allReports.status)
                      : selections.tab.value === "scheduledReports"
                      ? ["loading", "initial"].includes(scheduledReports.status)
                      : selections.tab.value === "savedReports"
                      ? ["loading", "initial"].includes(savedReports.status)
                      : true) && <Loader />}
                    {((allReports.status === "error" &&
                      selections.tab.value === "allReports") ||
                      (scheduledReports.status === "error" &&
                        selections.tab.value === "scheduledReports") ||
                      (savedReports.status === "error" &&
                        selections.tab.value === "savedReports")) && (
                      <ErrorHandler
                        message={
                          allReports.status === "error"
                            ? allReports.message
                            : scheduledReports.status === "error"
                            ? scheduledReports.message
                            : savedReports.status === "error"
                            ? savedReports.message
                            : "Something went wrong!"
                        }
                        reloadFlag={true}
                        onReload={handleReportDataReload}
                      />
                    )}
                    {(selections.tab.value === "allReports"
                      ? ["success"].includes(allReports.status)
                      : selections.tab.value === "scheduledReports"
                      ? ["success"].includes(scheduledReports.status)
                      : selections.tab.value === "savedReports"
                      ? ["success"].includes(savedReports.status)
                      : true) && (
                      <>
                        {reportRows.length > 0 && (
                          <SigviewEnhancedTable
                            handleClickRow={toggleReportManager}
                            rows={reportRows}
                            headCells={[
                              {
                                id: "name",
                                name: "name",
                                label: "Name",
                              },

                              {
                                id: "createdOn",
                                name: "createdOn",
                                label: "Created On",
                              },
                              {
                                id: "recipients",
                                name: "recipients",
                                label: "Recipients",
                              },
                              {
                                id: "reportType",
                                name: "reportType",
                                label: "Report Type",
                              },
                            ]}
                            reloadData={selections.tab.value}
                          />
                        )}

                        {reportRows.length === 0 && (
                          <Box css={{ height: "calc(100% - 30px)" }}>
                            <div className="no-data-container">
                              <div className="no-data-image"></div>
                              <p className="no-data">No Reports available</p>
                            </div>
                          </Box>
                        )}
                      </>
                    )}
                  </>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </LayoutTopSideBottomAdmin>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(AdminReportContainer);
