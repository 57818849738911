//Import required libraries
import React, { useContext } from "react";

// Import Custom Components
import SigviewTypography from "../../Common/SigviewTypography";
import SigviewSwitch from "../../Common/SigviewSwitch";
import SigviewProgressBar from "../../Common/SigviewProgressBar";
// import Loader from "../../../../../components/Loader/Loader";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

function AlertRow(props) {
  // Destructure props
  const {
    id = id,
    alertTitle = "",
    alertSubTitle = "",
    isActive = false,
    handleActive = () => {},
    handleToggle = () => {},
    selectedAlertId = "",
    isActiveData = {},
  } = props;

  // console.log("AlertRow", props);
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  var isCurrentlySelectedClass =
    selectedAlertId === id ? "is-currently-selected-class" : "";

  const sideChange = (e) => {
    handleToggle(e, id);
  };

  const handleClick = (e) => {
    // console.log("alertlist", e, id);
    handleActive(e, id);
  };

  return (
    <div
      className={`alert-row ${isCurrentlySelectedClass} AlertClickFromSidenav-GA`}
    >
      <section
        id={id}
        onClick={handleClick}
        className="alert-row-left-panel AlertClickFromSidenav-GA"
      >
        <SigviewTypography
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontWeight: 500,
          }}
          variant="pMedium"
          title={alertTitle}
          customClassName="AlertClickFromSidenav-GA"
        >
          {alertTitle}
        </SigviewTypography>

        <SigviewTypography
          style={{
            color: `${themeColors["secondaryColorLight"]}`,
            padding: "6px 0px 0px 0px",
            fontWeight: 300,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          variant="pSmaller"
        >
          {alertSubTitle}
        </SigviewTypography>
      </section>
      <section className="alert-row-right-panel">
        {!(selectedAlertId === id) && (
          <>
            {isActiveData.status === "loading" && <SigviewProgressBar />}

            {isActiveData.status === "success" && (
              <SigviewSwitch
                name={alertTitle}
                id={id}
                checked={isActive}
                onChange={sideChange}
                customClassName="AlertON-OFF-GA"
              />
            )}
          </>
        )}
      </section>
    </div>
  );
}

export default AlertRow;
