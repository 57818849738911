// *  COMPONENT HIERARCHY
// MSVContainer
//      MSV
//          MsvDimensionTableItem
//              MsvDimHeader
//              MsvDimTableData
//              MsvDimTableUI (Memoized for performance optimization)
//          MsvCalendarMetricChartItem
//              MsvCalendarChartHeader
//              MsvCalendarMetricChart (Memoized for performance optimization)
//          MsvMetricChartItem
//              MsvChartHeader
//              MsvMetricChart (Memoized for performance optimization)

import MSVContainer from "./MSVContainer.js";
export default MSVContainer;
