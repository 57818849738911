import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import Loader from "../../../components/Loader/Loader";
import { config } from "../../../config/config";

import User from "./User";

function UserContainer(props) {
  const { dispatch: ReduxDispatcher, user, allData = {} } = props;

  const { views = {}, viewGroupList = {}, allDateRange = {} } = allData;

  const getAllDataStatus = (allData = []) => {
    const areAllSuccess = allData.every((data) => data.status === "success");
    const isAnyError = allData.some((data) => data.status === "error");
    let allVendorDataStatus = "loading";
    if (areAllSuccess) allVendorDataStatus = "success";
    if (isAnyError) allVendorDataStatus = "error";
    return allVendorDataStatus; //loading, success, error
  };

  const finalStatus = getAllDataStatus([views, viewGroupList, allDateRange]);

  console.log("finalStatus", finalStatus);

  const history = useHistory();

  if (finalStatus === "loading") {
    history.push("/admin");
  }

  return (
    <>
      {finalStatus === "loading" && <Loader />}

      {finalStatus === "error" && (
        <ErrorHandler
          message={config.hardCoded.uiErrorMessage}
          reloadFlag={true}
          onReload={() => {}}
        />
      )}
      {finalStatus === "success" && <User />}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(UserContainer);
