// ! IMPORTANT NOTES
// Caution:
// 1. This is a fast fix to solve website performance
// 2. This may not adhere to our kit guidelines

// Import required libraies
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

//Styling
const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.misc["popoverBgColor"],
    color: theme.palette.misc["secondaryColor"],
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontFamily: "inherit",
    fontWeight: 400,
  },
  popper: {
    top: (props) => {
      const popperTop =
        props.placement === "bottom" || props.placement === "bottom-start"
          ? (props.top || "-15px") + "!important"
          : "0px !important";
      return popperTop;
    },
    left: (props) => {
      const popperLeft =
        props.placement === "right"
          ? (props.left || "-15px") + "!important"
          : "0px !important";
      return popperLeft;
    },
    zIndex: "1000000000",
  },
}));

function SigviewTooltip(props) {
  const { t } = useTranslation();
  const { title, children, placement } = props;
  const classes = useStyles(props);

  return (
    <Tooltip
      classes={classes}
      title={t(title)}
      placement={placement}
      // disableFocusListener={true} //Tooltip stays on after clicking the div
    >
      {children}
    </Tooltip>
  );
}

// Set default props
SigviewTooltip.defaultProps = {
  title: "",
  placement: "bottom",
};

export default SigviewTooltip;
