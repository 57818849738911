//Import required libraies
import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";

// Import Custom Component

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import action creators

// Import utils

// Import styles

function SigviewButtonGroup(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const { value, data, onChange, style, disabled = false } = props;

  const handleClick = (newValue) => {
    onChange(newValue);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      marginRight: style?.margin?.right || "0px",
    },
    buttonGroup: { height: style?.height || "24px" },
    activeButton: {
      color: themeColors["buttonColor"],
      fontSize: "11px",
      fontFamily: "Fira Sans !important",
      backgroundColor: themeColors["primaryColor"],
      padding: "0px !important",
      border: `1px solid ${themeColors["secondaryColorLighter"]}`,
      minWidth: "30px",
      "&:hover": {
        color: themeColors["buttonColor"],
        fontSize: "11px",
        fontFamily: "Fira Sans !important",
        backgroundColor: themeColors["primaryColor"],
        padding: "0px !important",
        border: `1px solid ${themeColors["secondaryColorLighter"]}`,
      },
    },
    inactiveButton: {
      color: themeColors["secondaryColor"],
      fontSize: "11px",
      fontFamily: "Fira Sans !important",
      backgroundColor: themeColors["popoverBgColor"],
      padding: "0px !important",
      border:
        style?.border || `1px solid ${themeColors["secondaryColorLighter"]}`,
      minWidth: "30px",
      "&:hover": {
        color: themeColors["buttonColor"],
        fontSize: "11px",
        fontFamily: "Fira Sans !important",
        backgroundColor: themeColors["primaryColor"],
        padding: "0px !important",
        border: `1px solid ${themeColors["secondaryColorLighter"]}`,
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ButtonGroup
        className={classes.buttonGroup}
        disableRipple
        disabled={disabled}
      >
        {data.map((row) => {
          const activeFlag = row.id === value;
          return (
            <Button
              key={row.id}
              className={
                activeFlag ? classes.activeButton : classes.inactiveButton
              }
              onClick={() => handleClick(row.id)}
            >
              {row.name}
            </Button>
          );
        })}
      </ButtonGroup>
    </div>
  );
}

export default SigviewButtonGroup;
