//Import required libraies
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { v4 } from "uuid";

// Import Custom Component

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";
import {
  emailCheckUserAws,
  sendEmailVerificationLink,
} from "../../services/api";
import axios from "axios";
import Loader from "../Loader/Loader";
import { Box } from "@material-ui/core";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import SigviewIcon from "./SigviewIcon";
import SigviewButton from "./SigviewButton";
import { updateDialogInfo, updateUserScreen } from "../../redux/actions";
import { config } from "../../config/config";

// Import action creators

// Import utils
import { validateEmail } from "../../utils/adminAwsUtils";

// Import styles

function SigviewTextFieldAsync2(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    value,
    onChange,
    width = "100%",
    minWidth,
    maxWidth,
    margin,
    borderRadius,
    border,
    borderHover,
    error,
    helperText,
    placeholder,
    readOnly,
    onFocus = () => {},
    onBlur = () => {},
    type = "text",
    org = "",
    emailStatusValue,
    ReduxDispatcher,
    user,
  } = props;

  //Required Functions
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  //Required States

  const [emailStatus, setEmailStatus] = useState({
    result: {
      statusCode: "",
      statusMessage: "",
      result: false,
    },
    status: "",
    messsage: "",
  });

  const handleVerificationLink = () => {
    const handleNo = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
        })
      );
    };

    const handleYes = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
          message: { title: "", subtitle: "" },
        })
      );

      const source = axios.CancelToken.source();
      const fetchProps = {
        cancelToken: source.token,
        payload: {
          domain: window.location.hostname,
          email: value,
        },
      };
      const sendEmailVerificationLinkPromise =
        sendEmailVerificationLink(fetchProps);
      sendEmailVerificationLinkPromise
        .then((data) => {
          //Update snackbar
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: `Verification link sent to your email: ${value} again`,
          };
          ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            const message = json.error || config.messages.uiErrorMessage;

            console.groupCollapsed("UI ERROR");
            console.log("JSON", json);
            console.log("ERROR ->", message);
            console.groupEnd();
          }
        });
    };

    const message = {
      title: `Are you sure you want to send verification link again?`,
      subtitle: "",
    };

    ReduxDispatcher(
      updateDialogInfo({
        ...user.dialogInfo,
        open: true,
        message,
        handleYes,
        handleNo,
      })
    );
  };

  //Styling
  const useStyles = makeStyles({
    formControl: {
      minWidth: minWidth || "120px",
      maxWidth: maxWidth || "400px",
      // width,
      marginLeft: margin?.left || "0px",
      marginRight: margin?.right || "0px",
    },
    textField: {
      pointerEvents: readOnly ? "none" : "auto",
      fontSize: "10px",
      fontFamily: "inherit",
      minWidth: minWidth || "120px",
      maxWidth: maxWidth || "350px",
      width,
      boxSizing: "border-box",
      height: "24px",
      borderRadius: borderRadius || "3px",
      backgroundColor: "transparent",
      border:
        border ||
        `1px solid ${
          error
            ? themeColors["failureColor"]
            : themeColors["secondaryColorLighter"]
        }`,
      "& .MuiInput-underline:before": {
        display: "none !important",
      },
      "& .MuiInput-underline:after": {
        display: "none !important",
      },
      "& .MuiInputBase-root": {
        fontSize: "inherit",
        fontFamily: "inherit !important",
      },
      "& .MuiInputBase-input": {
        paddingLeft: "6px !important",
        paddingRight: "6px !important",
        color: themeColors["secondaryColor"],
      },
    },
    formHelperText: {
      fontSize: "9px",
      fontFamily: "inherit",
      minWidth: minWidth || "120px",
      maxWidth: maxWidth || "350px",
      width,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: `${themeColors["failureColor"]} !important`,
    },
  });
  const classes = useStyles();

  emailStatusValue(emailStatus);

  useUpdateEffect(() => {
    setEmailStatus({
      result: {
        statusCode: "",
        statusMessage: "",
        result: false,
      },
      status: "loading",
      messsage: "",
    });
    let isEmailValid = validateEmail(value);

    if (isEmailValid.status === "valid") {
      const source = axios.CancelToken.source();
      const fetchProps = {
        // cancelToken: source.token,
        email: value,
        org: org,
      };
      let emailCheckUserAwsPromise = emailCheckUserAws(fetchProps);
      emailCheckUserAwsPromise
        .then((data) => {
          setEmailStatus({
            result: data?.status,
            status: "success",
            messsage:
              data?.status.statusCode === "200"
                ? "Email already exists"
                : data?.status.statusCode === "104"
                ? "Verificaition Link has been sent to user's email address"
                : data?.status.statusCode === "301"
                ? "User exists for other organization"
                : data?.status.statusCode === "302"
                ? ""
                : data?.status.statusCode === "303"
                ? ""
                : "",
          });
        })
        .catch((json) => {
          {
            if (json.error !== config.hardCoded.queryCancelled) {
              const message = json.error || config.messages.uiErrorMessage;
              let newData = {
                result: {},
                status: "error",
                messsage: "API failed",
              };
              setEmailStatus(newData);

              console.groupCollapsed("UI ERROR");
              console.log("JSON", json);
              console.log("ERROR ->", message);
              console.groupEnd();
            }
          }
        });
    }

    if (isEmailValid.status === "invalid") {
      setEmailStatus({
        result: {
          statusCode: "",
          statusMessage: "",
          result: false,
        },
        status: "",
        messsage: "",
      });
    }
  }, [value]);

  return (
    <FormControl className={classes.formControl} error={error}>
      <Box css={{ display: "flex" }}>
        <TextField
          id={`sigviewTextField-${v4()}`}
          label=""
          value={value}
          onChange={handleChange}
          className={classes.textField}
          placeholder={placeholder || "Enter text"}
          onFocus={onFocus}
          onBlur={onBlur}
          type={type}
        />

        <Box css={{ height: "20px" }}>
          {emailStatus.status === "loading" && <Loader />}

          {emailStatus.status === "success" && (
            <>
              {!readOnly && (
                <>
                  {emailStatus.result.statusCode.includes(["302" || "301"]) && (
                    <SigviewIcon
                      iconName="check_circle"
                      style={{
                        fontSize: "22px !important",
                        //   display: "inline-flex",
                        padding: "0px 0px 0px 5px",
                        color: themeColors["posChangeColor"],
                        //   hoverColor: themeColors["secondaryColorLight"],
                        cursor: "grab",
                      }}
                    />
                  )}

                  {emailStatus.result.statusCode.includes("200") && (
                    <SigviewIcon
                      iconName="cancel"
                      style={{
                        fontSize: "20px !important",
                        //   display: "inline-flex",
                        padding: "0px 0px 0px 0px",
                        color: themeColors["negChangeColor"],
                        //   hoverColor: themeColors["secondaryColorLight"],
                        cursor: "grab",
                      }}
                    />
                  )}
                </>
              )}
              {emailStatus.result.statusCode.includes("303") && (
                <SigviewButton
                  title="Resend Verification Link"
                  onClick={handleVerificationLink}
                  style={{ margin: { left: "10px" } }}
                />
              )}
            </>
          )}
        </Box>
      </Box>
      {emailStatus.status === "success" &&
        !emailStatus.result.statusCode.includes("104") &&
        !readOnly && (
          <FormHelperText
            className={classes.formHelperText}
            title={emailStatus.messsage}
          >
            {emailStatus.messsage}
          </FormHelperText>
        )}

      {emailStatus.result.statusCode.includes("104") && (
        <FormHelperText
          className={classes.formHelperText}
          title={emailStatus.messsage}
        >
          {emailStatus.messsage}
        </FormHelperText>
      )}
      {error && (
        <FormHelperText className={classes.formHelperText} title={helperText}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default SigviewTextFieldAsync2;
