/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Button,
  ButtonGroup,
  Grid,
  makeStyles,
  Popper,
} from "@material-ui/core";

function SigviewComboBox(props) {
  const { data = [], value = "", onChange = () => {} } = props;

  //Styling
  const useStyles = makeStyles({
    root: {
      "& .MuiAutocomplete-root": {
        // backgroundColor: "red",
        width: "200px !important",
        height: "35px",
        "& .MuiFormControl-root": {
          height: "100%",
          "& .MuiInputBase-root": {
            height: "100%",
            paddingTop: "0px",
            paddingBottom: "0px",
            // borderRadius: "10px",
            // "&:hover": {

            //   borderStyle: "solid",
            // },
            "& .MuiInputBase-input": {
              fontSize: "11px",
              paddingTop: "0px",
              paddingBottom: "0px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none!important",
            },
          },
        },
      },
      "& .MuiAutocomplete-popper": {
        // backgroundColor: "red",
        zIndex: 1,

        "& .MuiPaper-root": {
          //   backgroundColor: "red",

          "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
            fontSize: "11px",
            width: "150px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
            boxSizing: "content-box",
          },
        },
      },
    },
    popper: {
      zIndex: 1,
      // minWidth: "65px",
      // width: "inherit",
      // width: "84px",
      "& .MuiList-root": {
        // backgroundColor: themeColors["popoverBgColor"],
      },
      "& .MuiListItem-root": {
        fontFamily: "Fira Sans !important",
        fontSize: "12px",
        padding: "3px 18px",
        // color: themeColors["buttonBgColor"],
        "&:hover": {
          // backgroundColor: themeColors["buttonBgColor"],
          // color: themeColors["buttonColor"],
          //   backgroundColor:
          //     variant === "contained"
          //       ? themeColors["buttonBgColor"]
          //       : variant === "containedLighter"
          //       ? themeColors["buttonBgColorLightest"]
          //       : themeColors["buttonBgColor"],
          //   color:
          //     variant === "contained"
          //       ? themeColors["buttonColor"]
          //       : variant === "containedLighter"
          //       ? themeColors["buttonColorDarkest"]
          //       : themeColors["buttonColor"],
        },
      },
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        // id="combo-box-demo"
        value={value}
        // open={true}
        PopperComponent={(props) => (
          <Popper disablePortal className={classes.popper} {...props}>
            {props.children}
          </Popper>
        )}
        options={data}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        onChange={onChange}
      />
    </div>
  );
}

export default SigviewComboBox;
