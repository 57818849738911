// Import required libraries
import React, { useState, useRef, useContext, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import orderBy from "lodash.orderby";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

// Import lib components
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Box } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";

// Import custom components
import SigviewMultiSelectDnD from "../../../components/Common/SigviewMultiSelectDnD";
import SigviewTooltip from "../../../components/Common/SigviewTooltip";
import SigviewButton from "../../../components/Common/SigviewButton";
import SigviewIcon from "../../../components/Common/SigviewIcon";
import PivotTable from "./PivotTable";
import PivotTransposeTable from "./PivotTransposeTable";
import PivotComparisonTable from "./PivotComparisonTable";
import SigviewAdvanceSort from "../../../components/Common/SigviewAdvanceSort";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SigviewCheckboxGroup from "../../../components/Common/SigviewCheckboxGroup";

// Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// Import styles
import "./StandalonePivot.scss";

// Import action creators
import { updatePivotForm, updateUserScreen } from "../../../redux/actions";

// Import utils
import { masterTrackGaEvent } from "../../../services/ga";
import {
  unwrapperDataAdvanceSort,
  wrapperSelectionsAdvanceSort,
  unwrapperSelectionsAdvanceSort,
  makeOrderByText,
  downloadPivotComparisonTableData,
  downloadPivotTableData,
} from "../../../utils/pivotUtils";

// * Define required constants
const initialPivotData = { result: [], status: "loading", message: "" };

const isDownloadEnabled = (data) => {
  if (data.status === "loading") return false;

  // Check if any of the isChildrenLoading is true
  const isChildrenLoadingTrue = (data, flag) => {
    for (const row of data) {
      if (row.isChildrenLoading) {
        flag = false;
        return flag;
      } else {
        flag = isChildrenLoadingTrue(row.children, flag);
      }
    }
    return flag;
  };
  let flag = true;
  flag = isChildrenLoadingTrue(data.result, flag);
  return flag;
};

// * Define requried styles
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      height: "100%",
      "& .MuiPaper-root": {
        boxShadow: themeColors["timeFiltersBoxShadow"],
        backgroundColor: themeColors["popoverBgColor"],
      },
      "& .MuiAccordion-root .MuiCollapse-container": {
        transitionDuration: "300ms !important",
      },
      "& .MuiAccordion-root .MuiCollapse-root": {
        transitionDuration: "300ms !important",
      },
      "& .MuiAccordionSummary-root": {
        padding: "0px 21px",
        cursor: "auto",
        "&.Mui-expanded": { minHeight: "40px !important" },
        alignItems: "center",
        justifyContent: "space-between",
      },
      "& .MuiAccordionSummary-content": {
        color: themeColors["secondaryColor"],
        alignItems: "center",
        justifyContent: "space-between",
        "&.Mui-expanded": {
          margin: "10px 0px !important",
        },
        "&>span": { fontSize: "14px !important" },
      },
      "& .MuiIconButton-label": { color: themeColors["secondaryColor"] },
      "& .MuiAccordionDetails-root": { paddingTop: "0px !important" },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));
  return useStyles;
};

function StandalonePivot(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    user,
    allData,
    pivotState,
    dispatchPivotState,
    dispatch: ReduxDispatcher,
    globalFilters,
  } = props;
  // console.log("standalonePivot", pivotState);
  const {
    globalFiltersFileUpload = false,
    globalFiltersStringMatch = false,
    globalFiltersTimestamp = false,
  } = user.uiFeatureList;
  // * Define required states
  const chartContainerId = useRef(`chartHolder-${v4()}`);
  const [accExpanded, setAccExpanded] = useState(true);
  const [reloadChartEpoch, setReloadChartEpoch] = useState(
    new Date().valueOf()
  );
  const [pivotData, setPivotData] = useState(initialPivotData);

  // * Define required side effects
  // useUpdateEffect(() => {
  //   const payload = { key: "renderFlag", value: true };
  //   const action = updatePivotForm(payload);
  //   dispatchPivotState(action);
  // }, [
  //   pivotState.dimensionsList,
  //   pivotState.metricsList,
  //   pivotState.dimensionFilters,
  //   pivotState.advanceSort,
  //   pivotState.timeFilters,
  //   pivotState.transpose,
  // ]);

  useEffect(() => {
    const flag = pivotState.renderFlag === false && !applyButtonDisabledFlag;
    if (flag) {
      var payload = { key: "renderFlag", value: true };
      var action = updatePivotForm(payload);
      dispatchPivotState(action);
    }
  }, [pivotState.reloadRenderEpoch]);

  //** Backward Compatibility Code Start Here**//
  useEffect(() => {
    if (
      !globalFiltersStringMatch &&
      !globalFiltersFileUpload &&
      !globalFiltersTimestamp &&
      pivotState.dimensionFilters.length > 0
    ) {
      var validityFlags = pivotState.dimensionFilters.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              ![
                "startsWith",
                "exactlyMatches",
                "endsWith",
                "containsString",
                "containsWholeWord",
                "from",
                "on",
                "between",
                "before",
              ].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      !globalFiltersStringMatch &&
      globalFiltersFileUpload &&
      !globalFiltersTimestamp &&
      pivotState.dimensionFilters.length > 0
    ) {
      var validityFlags = pivotState.dimensionFilters.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              ![
                "startsWith",
                "endsWith",
                "containsString",
                "containsWholeWord",
                "from",
                "on",
                "between",
                "before",
              ].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      globalFiltersStringMatch &&
      !globalFiltersFileUpload &&
      !globalFiltersTimestamp &&
      pivotState.dimensionFilters.length > 0
    ) {
      var validityFlags = pivotState.dimensionFilters.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              !["exactlyMatches", "from", "on", "between", "before"].includes(
                item.type
              )
          ),
        };
        return newRow;
      });
    } else if (
      !globalFiltersStringMatch &&
      !globalFiltersFileUpload &&
      globalFiltersTimestamp &&
      pivotState.dimensionFilters.length > 0
    ) {
      var validityFlags = pivotState.dimensionFilters.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              ![
                "startsWith",
                "exactlyMatches",
                "endsWith",
                "containsString",
                "containsWholeWord",
              ].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      globalFiltersFileUpload &&
      !globalFiltersStringMatch &&
      globalFiltersTimestamp &&
      pivotState.dimensionFilters.length > 0
    ) {
      var validityFlags = pivotState.dimensionFilters.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              ![
                "startsWith",
                "endsWith",
                "containsString",
                "containsWholeWord",
              ].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      !globalFiltersFileUpload &&
      globalFiltersStringMatch &&
      globalFiltersTimestamp &&
      pivotState.dimensionFilters.length > 0
    ) {
      var validityFlags = pivotState.dimensionFilters.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) => !["exactlyMatches"].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      globalFiltersFileUpload &&
      globalFiltersStringMatch &&
      !globalFiltersTimestamp &&
      pivotState.dimensionFilters.length > 0
    ) {
      var validityFlags = pivotState.dimensionFilters.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) => !["from", "on", "between", "before"].includes(item.type)
          ),
        };
        return newRow;
      });
    }
    if (validityFlags) {
      var finalValidateFilterArray = validityFlags.filter((row) => {
        if (
          (row.values.length !== 0 && row.advancedFilters.length === 0) ||
          (row.values.length === 0 && row.advancedFilters.length !== 0) ||
          (row.values.length !== 0 && row.advancedFilters.length !== 0)
        ) {
          return row;
        }
      });
      const payload = {
        key: "dimensionFilters",
        value: finalValidateFilterArray,
      };
      var action = updatePivotForm(payload);
      dispatchPivotState(action);
    }
  }, []);
  //** Backward Compatibility Code Ends Here **//
  // * Define required event handlers
  const handleDimensionsListChange = (newValue) => {
    var payload = { key: "dimensionsList", value: newValue };
    var action = updatePivotForm(payload);
    dispatchPivotState(action);
    var payload = { key: "transpose", value: false };
    var action = updatePivotForm(payload);
    dispatchPivotState(action);
  };
  const handleMetricsListChange = (newValue) => {
    var payload = { key: "metricsList", value: newValue };
    var action = updatePivotForm(payload);
    dispatchPivotState(action);
    var payload = { key: "transpose", value: false };
    var action = updatePivotForm(payload);
    dispatchPivotState(action);
  };
  const handleApplyDataLimit = (newValue) => {
    var payload = { key: "dataLimit", value: newValue };
    var action = updatePivotForm(payload);
    dispatchPivotState(action);
    var payload = { key: "transpose", value: false };
    var action = updatePivotForm(payload);
    dispatchPivotState(action);
  };
  const handleApply = () => {
    // Google Analytics Event -  Apply Pivot Table
    masterTrackGaEvent({
      category: "Pivot",
      action: "ApplyPivotTable",
      label: pivotState.timeFilters.isComparisonOn
        ? "CompareTable"
        : pivotState.transpose
        ? "TransposeTable"
        : "NormalTable",
    });
    var payload = { key: "renderFlag", value: true };
    var action = updatePivotForm(payload);
    dispatchPivotState(action);
  };
  const handleClear = () => {
    // Google Analytics Event - Master
    // masterTrackGaEvent({
    //   category: "CreateChart",
    //   action: "RemoveAllValues",
    //   label: "Dimensions&Metrics",
    // });

    //Remove child node of chartContainer
    const chartContainerEl = document.getElementById(chartContainerId.current);
    chartContainerEl.style.height = "0%";
    while (chartContainerEl.firstChild) {
      chartContainerEl.removeChild(chartContainerEl.lastChild);
    }
    // const clearAllState = {
    //   ...pivotState,
    //   dimensionsList: [],
    //   metricsList: [],
    //   orderById: "",
    //   orderBy: "asc",
    //   chartType: "bar",
    //   chartList: staticChartJson,
    //   renderFlag: false,
    // };
    // const action = replaceStandaloneChartForm(clearAllState);
    // dispatch(action);
  };
  const handleAccChange = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Pivot",
      action: "DataSelections",
      label: accExpanded ? "Collapse" : "Expand",
    });
    setAccExpanded(!accExpanded);
  };
  const handleChartDataReload = () => {
    setReloadChartEpoch(new Date().valueOf());
  };
  const handleTransposeChange = () => {
    const payload = { key: "transpose", value: !pivotState.transpose };
    // Google Analytics Event - Enable Transpose
    masterTrackGaEvent({
      category: "Pivot",
      action: "Transpose",
      label: payload.value ? "Enable" : "Disable",
    });
    const action = updatePivotForm(payload);
    dispatchPivotState(action);
  };
  const handleAdvanceSortChange = (value) => {
    // Google Analytics Event -  Pivot Advance Sort
    masterTrackGaEvent({
      category: "Pivot",
      action: "AdvanceSort",
      label: "OpenAdvanceSort",
    });
    const finalValue = wrapperSelectionsAdvanceSort(value);
    const payload = { key: "advanceSort", value: finalValue };
    const action = updatePivotForm(payload);
    dispatchPivotState(action);
  };
  const handleDownload = (e, fileType = "csv") => {
    // Google Analytics Event -  Pivot Download
    masterTrackGaEvent({
      category: "Pivot",
      action: "PivotDownload",
      label: fileType === "csv" ? "ExportsCSV" : "ExportsXLS",
    });
    const isTableCompare = pivotState.timeFilters.isComparisonOn;
    const isTableTranspose = pivotState.transpose;
    const isTableNormalPivot =
      !pivotState.timeFilters.isComparisonOn && !isTableTranspose;
    const fileName = `${pivotState.title}_${Date.now()}`;
    if (isTableCompare) {
      let props = {
        data: pivotData.result,
        selections,
        fileType,
        fileName,
        allData,
      };
      downloadPivotComparisonTableData(props);
    }
    if (isTableNormalPivot) {
      let props = {
        data: pivotData.result,
        selections,
        fileType,
        fileName,
        allData,
      };
      downloadPivotTableData(props);
    }
  };
  const handlePivotDataChange = (data) => setPivotData(data);
  const handleGotoReports = () => {
    if (!isGotoReportsDisabled) {
      // Google Analytics Event - Enable Transpose
      masterTrackGaEvent({
        category: "Pivot",
        action: "GoToReports",
        label: "OpenReportManager",
      });
      const value = {
        isOpen: true,
        metadata: {
          variant: "gotoReport",
          selections: {
            timeFilters: pivotState.timeFilters,
            dimensionsList: pivotState.dimensionsList,
            metricsList: pivotState.metricsList,
            dimensionFilters: pivotState.dimensionFilters,
            title: `Report from Pivot - ${pivotState.title}`,
          },
        },
      };
      const action = updateUserScreen("reportManager", value);
      ReduxDispatcher(action);
    }
  };

  // * Define requried components
  const PivotDownloadIcon = (props = {}) => {
    // * Destructure props
    const { disabled = false, onDownload = () => {} } = props;

    // * Define required states
    const [anchorEl, setAnchorEl] = useState(null);

    // * Define required event handlers
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleDownload = (fileType) => {
      handleClose();
      onDownload(null, fileType);
    };

    // * Define required variables
    const open = Boolean(anchorEl);
    const id = open ? "dsSettingsPopover" : undefined;
    const useStyles = makeStyles({
      root: {
        "& .MuiPaper-root ": {
          backgroundColor: "white !important",
        },
      },
      iconHolder: { display: "flex", alignItems: "center" },
      containerCss: {
        backgroundColor: themeColors["mainContentBgColor"],
        padding: "5px 0px",
      },
      optionRowCss: {
        display: "flex",
        alignItems: "center",
        padding: "5px",
        fontSize: "12px",
        color: themeColors["secondaryColor"],
        margin: "0px !important",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: themeColors["secondaryColorLightest"],
        },
      },
    });
    const classes = useStyles();

    return (
      <>
        <SigviewTooltip title="Download CSV/XLSX" placement="bottom">
          <Box className={classes.iconHolder}>
            <SigviewIcon
              className="material-icons-round"
              iconName="download"
              style={{
                fontSize: "22px !important",
                padding: "0px 10px 0px 0px",
                color: themeColors["secondaryColor"],
                hoverColor: themeColors["primaryColor"],
                cursor: "pointer",
              }}
              onClick={handleClick}
              disabled={disabled}
            />
          </Box>
        </SigviewTooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.root}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box className={classes.containerCss}>
            <Box
              component="p"
              className={`${classes.optionRowCss} DownloadCSV-GA`}
              onClick={() => handleDownload("csv")}
            >
              Download CSV
            </Box>
            <Box
              component="p"
              className={`${classes.optionRowCss} DownloadXLSX-GA`}
              onClick={() => handleDownload("xlsx")}
            >
              Download XLSX
            </Box>
          </Box>
        </Popover>
      </>
    );
  };

  // * Define required side effects
  // Required for DnD component
  // Dimensions
  const dimensionIdAccessor = "_id";
  const dimensionLabelAccessor = "dimTitle";
  const selectedDimensionsIds = pivotState.dimensionsList.map(
    (row) => row[dimensionIdAccessor]
  );
  const unselectedDimensions = allData.plotlyDimensions
    .filter((row) => !selectedDimensionsIds.includes(row[dimensionIdAccessor]))
    .filter((row) => row[dimensionLabelAccessor].toLowerCase() !== "week"); //hard coded: remove week from the list
  //   console.log("unselectedDimensions", unselectedDimensions);
  const dimensionsDndData = [
    ...pivotState.dimensionsList,
    //HARD CODED: Placing timestamp dimensions first
    ...orderBy(
      unselectedDimensions.filter(
        (row) => row.dataType.toLowerCase() === "datetime"
      ),
      [dimensionLabelAccessor],
      ["asc"]
    ),
    //HARD CODED: Placing non-timestamp dimensions later
    ...orderBy(
      unselectedDimensions.filter(
        (row) => row.dataType.toLowerCase() === "string"
      ),
      [dimensionLabelAccessor],
      ["asc"]
    ),
  ];
  // Metrics
  const metricIdAccessor = "_id";
  const metricLabelAccessor = "measureTitle";
  const selectedMetricsIds = pivotState.metricsList.map(
    (row) => row[metricIdAccessor]
  );
  const unselectedMetrics = allData.plotlyMetrics.filter(
    (row) => !selectedMetricsIds.includes(row[metricIdAccessor])
  );
  //   console.log("unselectedMetrics", unselectedMetrics);
  const metricsDndData = [
    // NOT ORDERDERING THE SELECTED DIMENSIONS; They will in the order they are selected
    ...pivotState.metricsList,
    //HARD CODED: Placing timestamp dimensions first
    ...orderBy(unselectedMetrics, [metricLabelAccessor], ["asc"]),
  ];
  const renderOptionAutoCompleteMetric = (props) => {
    const { option, allSelectedIds, idAccessor, labelAccessor } = props;
    const selectedFlag = allSelectedIds.includes(option[idAccessor]);
    const metricType = option._id.startsWith("M") ? "metric" : "customMetric";
    let infoTooltipStatus = false;
    if (
      user.reqMetadata.organization === "Kayzen" &&
      user.reqMetadata.view === "Percentile"
    ) {
      infoTooltipStatus =
        (option.displayFormula !== undefined &&
          option.displayFormula.startsWith("(Price Digest")) ||
        (option.displayFormula !== undefined &&
          option.displayFormula.startsWith("(price digest"));
    }
    return (
      <>
        <i
          className={`material-icons auto-complete-option-icon ${
            selectedFlag ? "" : "invisible"
          } `}
        >
          check_circle
        </i>
        <span
          className="quantity-selector-option-title"
          title={option[labelAccessor]}
        >
          {option[labelAccessor]}
        </span>
        {metricType === "customMetric" && !infoTooltipStatus && (
          <SigviewTooltip title={option.displayFormula} top="-12px">
            <i className="material-icons-round info-icon">info</i>
          </SigviewTooltip>
        )}
      </>
    );
  };
  const applyButtonDisabledFlag =
    pivotState.dimensionsList.length === 0 ||
    pivotState.dimensionsList.length > 5 ||
    pivotState.metricsList.length === 0 ||
    pivotState.renderFlag;
  const disabledAdvanceSort =
    pivotState.dimensionsList.length === 0 ||
    pivotState.metricsList.length === 0;
  const clearButtonEnabledFlag =
    pivotState.dimensionsList.length > 0 || pivotState.metricsList.length > 0;
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  let standaloneChartOuterContainerClassName =
    "standalone-chart-outer-container";
  if (accExpanded) standaloneChartOuterContainerClassName += " expanded";
  if (!accExpanded) standaloneChartOuterContainerClassName += " collapsed";
  let chartContainerClass = "standalone-chart-container";
  chartContainerClass += " no-height";

  // ! selections of the pivot table
  const selections = {
    dimensionsList: pivotState.dimensionsList,
    metricsList: pivotState.metricsList,
    dimensionFilters: pivotState.dimensionFilters,
    advanceSort: pivotState.advanceSort,
    timeFilters: pivotState.timeFilters,
    metricFilters: [],
    chartType: pivotState.chartType,
    // Hardcoded for DataLimit changes into dynamic
    dataLimit: pivotState.dataLimit,
    feature: pivotState.feature,
    globalFilters: globalFilters?.metricChartGranularity?.value,
  };
  const isTableCompare = pivotState.timeFilters.isComparisonOn;
  const isTableTranspose = pivotState.transpose;
  const isTableNormalPivot =
    !pivotState.timeFilters.isComparisonOn && !isTableTranspose;
  const advanceSortData = unwrapperDataAdvanceSort({
    dimensionsList: pivotState.dimensionsList,
    metricsList: pivotState.metricsList,
    timeFilters: pivotState.timeFilters,
  });
  const advanceSortSelections = unwrapperSelectionsAdvanceSort(
    pivotState.advanceSort
  );
  const isDownloadEnabledFlag = isDownloadEnabled(pivotData);

  const finalFlag = !applyButtonDisabledFlag
    ? true
    : pivotState.dimensionsList.length === 0 ||
      pivotState.dimensionsList.length > 5 ||
      pivotState.metricsList.length === 0 ||
      pivotData.result.length <= 1
    ? true
    : !isDownloadEnabledFlag;
  const buttonSplitOptions = [
    {
      id: "csv",
      label: "Export CSV",
      onClick: () => handleDownload(null, "csv"),
      disabled: finalFlag,
    },
    {
      id: "xlsx",
      label: "Export XLSX",
      onClick: () => handleDownload(null, "xlsx"),
      disabled: finalFlag,
    },
  ];
  const downloadIconBoxStyle = {
    padding: "0px 10px 0px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: finalFlag ? "none" : "initial",
    opacity: finalFlag ? 0.4 : 1,
  };
  const withTimeDimension = pivotState.dimensionsList.filter((dimList) => {
    if (dimList.dataType === "dateTime") {
      return dimList;
    }
  });
  const isGotoReportsDisabled =
    pivotState.dimensionsList.length === 0 ||
    pivotState.metricsList.length === 0;
  const transposeButtonTitle = "Transpose";
  const isTransposeEnabled =
    withTimeDimension.length === 1 &&
    pivotState.dimensionsList.length > 1 &&
    pivotState.metricsList.length > 0 &&
    !pivotState.timeFilters.isComparisonOn
      ? false
      : true;
  let orderByText = makeOrderByText(pivotState.advanceSort, allData);
  const orderByTextArr = orderByText.split("-");
  // This fix is required if the orderByTextArr is empty for create Flow
  if (orderByTextArr.length > 1) {
    const orderByTextTranslation = t(orderByTextArr[1].trim());
    orderByText = `${orderByTextArr[0]} - ${orderByTextTranslation}`;
  }

  // DEBUGGING;
  // console.groupCollapsed("STANDALONE PIVOT");
  // console.log("dimensionlist", pivotState.dimensionsList.length);
  // console.log("isTableCompare", isTableCompare);
  // console.log("isTableTranspose", isTableTranspose);
  // console.log("isTableNormalPivot", isTableNormalPivot);
  // console.log("Pivotstae", pivotState);
  // console.log("isTransposeEnabled", isTransposeEnabled);
  // console.groupEnd();

  return (
    <div className="standalone-chart-wrapper">
      <div className={classes.root}>
        <Accordion expanded={accExpanded}>
          <AccordionSummary
            // expandIcon={
            //   <span className="material-icons react-material-icons">
            //     expand_more
            //   </span>
            // }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span>{t("Data Selections")}</span>

            <Box
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                css={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // paddingLeft: "30px",
                  // marginBottom: "7px",
                }}
              >
                {!isTransposeEnabled && (
                  <SigviewTooltip
                    title="Enable when a time dimension is selected with other dimension"
                    placement="bottom"
                  >
                    <Box
                      css={{
                        display: "flex",
                      }}
                    >
                      {/* <SigviewButton
                      variant="containedLighter"
                      onClick={handleTransposeChange}
                      title={transposeButtonTitle}
                      disabled={isTransposeEnabled}
                      style={{ margin: { right: "5px" } }}
                    /> */}
                      <SigviewCheckboxGroup
                        data={[
                          {
                            id: transposeButtonTitle,
                            name: transposeButtonTitle,
                            checked: pivotState.transpose,
                          },
                        ]}
                        customID={`PivotTranspose${pivotState.transpose}-GA`}
                        disabled={isTransposeEnabled}
                        onChange={handleTransposeChange}
                        customClassName="PivotTranspose-GA"
                      />
                    </Box>
                  </SigviewTooltip>
                )}
                {isTransposeEnabled && (
                  <SigviewTooltip
                    title="Enable when a time dimension is selected with other dimension"
                    placement="bottom"
                  >
                    <Box
                      css={{
                        display: "flex",
                        opacity: "0.7",
                      }}
                    >
                      {/* <SigviewButton
                      variant="containedLighter"
                      onClick={handleTransposeChange}
                      title={transposeButtonTitle}
                      disabled={isTransposeEnabled}
                      style={{ margin: { right: "5px" } }}
                    /> */}
                      <SigviewCheckboxGroup
                        data={[
                          {
                            id: transposeButtonTitle,
                            name: transposeButtonTitle,
                            checked: pivotState.transpose,
                          },
                        ]}
                        customID={`PivotTranspose${pivotState.transpose}-GA`}
                        disabled={isTransposeEnabled}
                        onChange={handleTransposeChange}
                        customClassName="PivotTranspose-GA"
                      />
                    </Box>
                  </SigviewTooltip>
                )}
                <Box
                  css={{
                    display: "flex",
                  }}
                >
                  {/* <SigviewButton
                    variant="containedLighter"
                    onClick={handleGotoReports}
                    title={"Go To Reports"}
                    disabled={isGotoReportsDisabled}
                    style={{ margin: { right: "5px" } }}
                  /> */}

                  <SigviewTooltip title="Go To Reports" placement="bottom">
                    <Box
                      css={{
                        // backgroundColor: themeColors["secondaryColorLightest"],
                        marginRight: "7px",
                        marginLeft: "7px",
                      }}
                    >
                      <SigviewIcon
                        iconName="article"
                        style={{
                          fontSize: "22px !important",
                          padding: "2px",
                          color: themeColors["secondaryColor"],
                          hoverColor: themeColors["primaryColor"],
                          cursor: "pointer",
                        }}
                        disabled={isGotoReportsDisabled}
                        onClick={() => handleGotoReports(isGotoReportsDisabled)}
                        className={`${
                          isGotoReportsDisabled === false
                            ? `material-icons-round PivotGoToReports-GA`
                            : "material-icons-round"
                        }`}
                      />
                    </Box>
                  </SigviewTooltip>
                  {/* {!isTableTranspose && (
                    <SigviewButtonSplit
                      options={buttonSplitOptions}
                      variant="containedLighter"
                    />
                  )} */}
                  {!isTableTranspose && (
                    <PivotDownloadIcon
                      disabled={finalFlag}
                      onDownload={handleDownload}
                    />
                  )}

                  {/* {!isTableTranspose && (
                    <SigviewTooltip title="Download XLSX" placement="bottom">
                      <Box style={downloadIconBoxStyle}>
                        <span
                          className="sigview-icon-downloadXlsx"
                          // iconName="pin"
                          style={
                            {
                              // fontSize: "22px !important",
                              // padding: "0px 0px 0px 10px",
                              // color: themeColors["secondaryColorLight"],
                              // hoverColor: themeColors["primaryColor"],
                              // cursor: "pointer",
                            }
                          }
                          onClick={() => handleDownload(null, "xlsx")}
                        ></span>
                      </Box>
                    </SigviewTooltip>
                  )}
                  {!isTableTranspose && (
                    <SigviewTooltip title="Download CSV" placement="bottom">
                      <Box style={downloadIconBoxStyle}>
                        <span
                          className="sigview-icon-downloadCsv"
                          // iconName="pin"
                          style={
                            {
                              // fontSize: "22px !important",
                              // padding: "0px 0px 0px 10px",
                              // color: themeColors["secondaryColorLight"],
                              // hoverColor: themeColors["primaryColor"],
                              // cursor: "pointer",
                            }
                          }
                          onClick={() => handleDownload(null, "csv")}
                        ></span>
                      </Box>
                    </SigviewTooltip>
                  )} */}
                </Box>
              </Box>
              {accExpanded && (
                <SigviewTooltip title="Collapase" placement="bottom">
                  <Box>
                    <SigviewIcon
                      iconName="expand_less"
                      className={`material-icons-round PivotCollapse-GA`}
                      onClick={handleAccChange}
                      style={{
                        fontSize: "25px !important",
                        padding: "0 0 0 0px !important",
                        color: themeColors["secondaryColorLight"],
                        hoverColor: themeColors["secondaryColorLight"],
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                </SigviewTooltip>
              )}
              {!accExpanded && (
                <SigviewTooltip title="Expand" placement="bottom">
                  <Box>
                    <SigviewIcon
                      iconName="expand_more"
                      className="PivotExpand-GA"
                      onClick={handleAccChange}
                      style={{
                        fontSize: "25px !important",
                        padding: "0 0 0 10px !important",
                        color: themeColors["secondaryColorLight"],
                        hoverColor: themeColors["secondaryColorLight"],
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                </SigviewTooltip>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <section className="standalone-chart-data-selections-wrapper">
              <article className="left-panel">
                <div className="left-panel-row">
                  <SigviewMultiSelectDnD
                    data={dimensionsDndData}
                    value={pivotState.dimensionsList}
                    onChange={handleDimensionsListChange}
                    title="Dimensions"
                    idAccessor={dimensionIdAccessor}
                    labelAccessor={dimensionLabelAccessor}
                    variant="horizontal"
                    googleAnalytics={{ category: "CreateChart" }}
                    user={user}
                  />
                </div>

                <div className="left-panel-row">
                  <SigviewMultiSelectDnD
                    data={metricsDndData}
                    value={pivotState.metricsList}
                    onChange={handleMetricsListChange}
                    title="Metrics"
                    idAccessor={metricIdAccessor}
                    labelAccessor={metricLabelAccessor}
                    variant="horizontal"
                    // googleAnalytics={{ category: "ReportManager" }}
                    user={user}
                    renderOptionAutoComplete={renderOptionAutoCompleteMetric}
                  />
                </div>

                <div className="left-panel-row sort-menu-bar">
                  <section className="standalone-menu-bar">
                    <label className="row-title">{t("Data Sorted By:")}</label>
                    {/* <span>{orderByText}</span> */}
                    <SigviewTypography
                      variant="pSmall"
                      style={{ height: "100%" }}
                      customID={orderByText}
                      customClassName="PivotOrderBy-GA"
                    >
                      {orderByText}
                    </SigviewTypography>
                  </section>
                  {/* // ! TODO: Implemented for handling more then 5 dimension */}
                  {/* {pivotState.dimensionsList.length > 5 && (
                    <SigviewTooltip
                      title="Upto 5 Dimensions Only"
                      placement="bottom"
                    >
                      <Box></Box>
                    </SigviewTooltip>
                  )} */}
                  <section className="standalone-menu-bar">
                    <SigviewAdvanceSort
                      data={advanceSortData}
                      initialSelections={advanceSortSelections}
                      onChange={handleAdvanceSortChange}
                      disabledAdvanceSort={disabledAdvanceSort}
                    />

                    {/* <SigviewSingleSelect
                      minWidth={"50px"}
                      maxWidth={"50px"}
                      value={pivotState.dataLimit}
                      data={[
                        {
                          id: 10,
                          name: 10,
                          disabled: false,
                        },
                        {
                          id: 25,
                          name: 25,
                          disabled: false,
                        },
                        {
                          id: 50,
                          name: 50,
                          disabled: false,
                        },
                        {
                          id: 100,
                          name: 100,
                          disabled: false,
                        },
                      ]}
                      onChange={(newValue) => handleApplyDataLimit(newValue)}
                      margin={{ left: "5px" }}
                    /> */}

                    {/* <SigviewButton
                      variant="outlined"
                      onClick={handleClear}
                      title="Clear"
                      disabled={!clearButtonEnabledFlag}
                      style={{
                        width: "50px",
                        margin: { right: "3px" },
                      }}
                    /> */}
                    <SigviewButton
                      variant="containedPrimary"
                      onClick={handleApply}
                      customClassName="PivotApplyChange-GA"
                      title="Apply"
                      disabled={applyButtonDisabledFlag}
                      style={{
                        width: "auto",
                        margin: { left: "3px" },
                      }}
                    />
                  </section>
                </div>
                {pivotState.dimensionsList.length > 5 && (
                  <div
                    style={{
                      color: "red",
                      textAlign: "right",
                      padding: "3px 6px",
                      fontFamily: "Fira Sans",
                      fontSize: "10px",
                      fontWeight: "400 !important",
                    }}
                  >
                    Upto 5 Dimensions Only
                  </div>
                )}
              </article>
            </section>
          </AccordionDetails>
        </Accordion>

        <Box
          className={standaloneChartOuterContainerClassName}
          style={{
            padding: "3px",
          }}
        >
          {!pivotState.renderFlag && (
            <div className="chart-placeholder-container">
              <div className="chart-placeholder-image"></div>
              <p className="chart-placeholder-title">
                {t("Pivot Table will appear here after you click apply")}
              </p>
            </div>
          )}
          {pivotState.renderFlag && (
            <>
              {isTableNormalPivot && (
                <PivotTable
                  selections={selections}
                  user={user}
                  allData={allData}
                  onDataChange={handlePivotDataChange}
                  dispatchPivotState={dispatchPivotState}
                />
              )}
              {isTableCompare && (
                <PivotComparisonTable
                  selections={selections}
                  user={user}
                  allData={allData}
                  onDataChange={handlePivotDataChange}
                  dispatchPivotState={dispatchPivotState}
                />
              )}
              {isTableTranspose && (
                <PivotTransposeTable
                  selections={selections}
                  user={user}
                  allData={allData}
                  dispatchPivotState={dispatchPivotState}
                />
              )}
            </>
          )}
        </Box>
      </div>
    </div>
  );
}

StandalonePivot.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(StandalonePivot);
