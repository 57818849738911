//Import required libraies
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// Import Custom Component

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import action creators

// Import utils

// Import styles

function SigviewNavType2(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    value = "",
    data = [],
    onChange = () => {},
    style = {},
    customClassName,
  } = props;

  //Styling

  const useStyles = makeStyles(() => ({
    root: { padding: "0px !important", height: "100%" },
    navItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "auto",
      backgroundColor: themeColors["secondaryColorLightest"],
      padding: "6px 30px",
      borderRadius: "3px",
      cursor: "pointer",
      color: themeColors["secondaryColor"],
      boxSizing: "content-box",
      "&:not(:first-child)": {
        marginLeft: "-5px",
      },
      "&.active": {
        backgroundColor: themeColors["primaryColor"],
        color: themeColors["buttonColor"],
        zIndex: 1,
      },
      "&.disabled": {
        pointerEvents: "none",
        opacity: 0.5,
      },
    },
  }));
  const classes = useStyles();

  const NavItem = (props) => {
    const { id, name, disabled } = props;

    const navItemClassName = `${classes.navItem} ${
      id === value ? "active" : ""
    } ${disabled ? "disabled" : ""}`;

    const handleClick = (event) => onChange(event, id);

    return (
      <div
        key={id}
        className={`${navItemClassName}${customClassName}${name}-GA`}
        onClick={handleClick}
      >
        <p className={`${customClassName}${name}-GA`}>{t(name)}</p>
      </div>
    );
  };

  return <nav className={classes.root}>{data.map(NavItem)}</nav>;
}

export default SigviewNavType2;
