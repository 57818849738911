// * Import required libraries
import React, { useEffect } from "react";
import axios from "axios";

// Import Custom Component

// Import custom hooks
import useUpdateEffect from "../../../hooks/useUpdateEffect";

// Import action creators

// Import utils
import { config } from "../../../config/config";
import { makeMetricsDataConsistent } from "../../../utils/dsUtils";

// Import API functions
import { getData } from "../../../services/api";

function CounterData(props) {
  // * Destructure Props
  const {
    id = "",
    payload = {},
    data = {},
    onChange = () => {},
    selections = {},
    reloadEpoch = 0,
    allData = {},
  } = props;

  // Defining required event handlers/util functions
  const handleError = (message) => {
    onChange(null, id, {
      status: "error",
      message: message || config.hardCoded.uiErrorMessage,
      result: { dataFromQE: [], extraData: {} },
    });
  };

  // getData Chart Data
  useEffect(() => {
    // Define required variables

    //Make fetch call using axios
    const source = axios.CancelToken.source();

    if (data.status === "loading") {
      const fetchProps = {
        payload,
        cancelToken: source.token,
      };
      const getDataPromise = getData(fetchProps);
      getDataPromise
        .then((responseData) => {
          const { response, status } = makeMetricsDataConsistent(
            responseData?.result?.data || [],
            allData.plotlyMetrics
          );
          onChange(null, id, {
            status,
            message: "",
            result: response[id],
          });
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            handleError(json.error);
          }
        });
    }

    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
    };
  }, [data.status]);

  useUpdateEffect(() => {
    if (["success", "error"].includes(data.status)) {
      // Reset parent chart data state to loading anytime selections or reloadEpoch change
      onChange(null, id, {
        result: { dataFromQE: [], extraData: {} },
        status: "loading",
        message: "",
      });
    }
    // ! Don't replace this with selections object. It doesn't work. Perhaps because of a
    // ! new reference that gets created on every change in props or state
  }, [
    // ! Comment out selections.chartType below to achieve smooth transition of charts (no reload); can be done only when all charts have the same call
    selections.chartType,
    selections.dimensionFilters,
    selections.timeFilters,
    selections.dimensionsList,
    selections.metricsList,
    selections.orderById,
    selections.orderBy,
    selections.timeFiltersAppliedAt,
    selections.dimensionFiltersAppliedAt,
    reloadEpoch,
  ]);

  return <></>;
}

// const mapStateToProps = (state) => ({
//   user: state.user,
//   allData: state.data,
//   timeFilters: state.user.timeFilters,
//   dimensionFilters: state.user.dimensionFilters, //Can't use transformFiltersUiToBackend(state.user.dimensionFilters) here because it re-renders useEffects even when there's no change in dimensionFilters
// });

// const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(SigviewCounterData);
export default CounterData;
