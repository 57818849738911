import { Box } from "@material-ui/core";
import React from "react";

function LayoutRegisterBucket(props) {
  const { type, children } = props;
  console.log("porps", props);
  return (
    <Box css={{ padding: "0px 50px" }}>
      <Box
        css={{
          display: "flex",
          paddingBottom: "30px",
          justifyContent: "space-between",
        }}
      >
        <Box css={{ width: "45%" }}>{children[0]}</Box>
        <Box css={{ width: "45%" }}>{children[1]}</Box>
      </Box>
      <Box
        css={{
          display: "flex",
          paddingBottom: "30px",
          justifyContent: "space-between",
        }}
      >
        <Box css={{ width: "45%" }}>{children[2].props?.children[0]}</Box>
        <Box css={{ width: "45%" }}>{children[2].props?.children[1]}</Box>
      </Box>

      {type === "s3" && (
        <>
          <Box
            css={{
              display: "flex",
              paddingBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <Box css={{ width: "45%" }}>{children[3].props?.children[0]}</Box>
            <Box css={{ width: "45%" }}>{children[3].props?.children[1]}</Box>
          </Box>
          <Box
            css={{
              display: "flex",
              paddingBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <Box css={{ width: "45%" }}>{children[3].props.children[2]}</Box>
            <Box css={{ width: "45%" }}>{children[3].props.children[3]}</Box>
          </Box>

          <Box css={{ position: "absolute", bottom: "300px", right: "250px" }}>
            {children[4]}
          </Box>
        </>
      )}
      {type === "gcs" && (
        <>
          <Box
            css={{
              display: "flex",
              paddingBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <Box css={{ width: "45%" }}>{children[3].props?.children[0]}</Box>
            <Box css={{ width: "45%" }}>{children[3].props?.children[1]}</Box>
          </Box>
          <Box
            css={{
              display: "flex",
              paddingBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <Box css={{ width: "45%" }}>{children[3].props.children[2]}</Box>
            <Box css={{ width: "45%" }}>{children[3].props.children[3]}</Box>
          </Box>

          <Box css={{ position: "absolute", bottom: "300px", right: "250px" }}>
            {children[4]}
          </Box>
        </>
      )}
      <Box css={{ position: "absolute", bottom: "100px", right: "275px" }}>
        {children[5]}
      </Box>
    </Box>
  );
}

export default LayoutRegisterBucket;
