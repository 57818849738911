// ! IMPORTANT NOTES
// Caution:
// 1. This is a fast fix to solve website performance
// 2. This may not adhere to our kit guidelines

//Import required libraies
import React, { useEffect, useState } from "react";
import Popover from "@material-ui/core/Popover";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { DateRangePicker } from "react-date-range";

// Import Custom Component
import SigviewButton from "./SigviewButton";
import SigviewIcon from "./SigviewIcon";

// Import action creators

// Import utils
import { config } from "../../config/config";

// Import styles
const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "999999999 !important",
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.misc["popoverBgColor"],
    },
  },
  dateRangeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  formattedValue: {
    width: "max-content",
    fontSize: "12px",
  },
  arrowDropDown: {
    marginLeft: "3px",
    fontSize: "24px !important",
  },
}));

function SigviewDateSingleSelect(props) {
  const {
    user,
    onChange,
    value,
    format,
    minMaxDates,
    daysLimit = Number.MAX_SAFE_INTEGER,
    isMinMaxDateVisible = false,
    timezone = "UTC (+00:00)",
    disabled = false,
  } = props;
  const theme = useTheme();
  const [dateSelection, setDateSelection] = useState(value);
  const [anchorEl, setAnchorEl] = useState(null);

  //Update date if props change
  useEffect(() => {
    setDateSelection(value);
  }, [value]);

  const handleClick = (event) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const pathname = window.location.pathname;
  const rootPage = pathname.split("/")[1];
  //Implementing days limit as a feature in calendar
  //Restrict the user from selecting a large date range
  //Example:
  //Day Limit: 30
  //User selects: Jan 1st
  //End date will automatically be set to Jan 30th if the endDate-startDate not less than 30
  const handleDateChange = (ranges) => {
    let finalStartDate = ranges[config.hardCoded.reactDatePickerKey].startDate;
    let finalEndDate = ranges[config.hardCoded.reactDatePickerKey].endDate;

    const newSelectedStartDate =
      ranges[config.hardCoded.reactDatePickerKey].startDate;
    const newSelectedEndDate =
      ranges[config.hardCoded.reactDatePickerKey].endDate;
    const diffMilliseconds =
      newSelectedEndDate.valueOf() - newSelectedStartDate.valueOf(); //Milliseconds
    const diffDays = parseInt(diffMilliseconds / (1000 * 60 * 60 * 24));

    //Check what changed: StartDate or EndDate, if both changed, startDate will be preferred
    const endDateChangedFlag = newSelectedEndDate !== dateSelection.endDate;

    //If difference between start and end date is less than days limit
    if (diffDays > daysLimit) {
      //If endDate changed, startDate needs to be changed
      if (endDateChangedFlag) {
        finalStartDate = new Date(
          newSelectedEndDate.valueOf() - daysLimit * 24 * 60 * 60 * 1000
        );
      } else {
        //If endDate didn't changed, means startDate changed, so endDate needs to be changed
        finalEndDate = new Date(
          newSelectedStartDate.valueOf() + daysLimit * 24 * 60 * 60 * 1000
        );
      }
    }

    setDateSelection({
      startDate: finalStartDate,
      endDate: finalEndDate,
    });
  };

  const handleSubmit = () => {
    // * Trigger change only when the user has actually made changes in the date selection
    // Otherwise it is creating a bug where the selection increases by a day
    if (JSON.stringify(dateSelection) !== JSON.stringify(value)) {
      onChange(dateSelection);
    }
    handleClose();
  };

  const handleCancel = () => {
    //Reset values to the original values
    setDateSelection(value);
    handleClose();
  };

  //Define required variables
  const open = Boolean(anchorEl);
  const id = open ? "sigviewDateRangeSelect" : undefined;
  const formattedValue = `${moment(value.startDate).format(format)} - ${moment(
    value.endDate
  ).format(format)}`;
  const minDate = minMaxDates?.minDate || new Date(946688400000);
  const maxDate = minMaxDates?.maxDate || new Date(1893459600000);
  const minMaxFormat = config.hardCoded.minMaxDateFormat;
  const clientSystemOffset = new Date().getTimezoneOffset();
  const formattedMinMaxValue = `${moment(minDate).format(
    minMaxFormat
  )} - ${moment(maxDate).format(minMaxFormat)}`;
  const classes = useStyles();

  return (
    <>
      <div onClick={handleClick} className={classes.dateRangeContainer}>
        <p className={classes.formattedValue}>{formattedValue}</p>
        {/* <span className={`${classes.arrowDropDown} material-icons`}>
          arrow_drop_down
        </span> */}
        <SigviewIcon
          iconName="expand_more"
          style={{
            padding: "0 0 0 6px !important",
            color: theme.palette.misc["secondaryColor"],
            hoverColor: theme.palette.misc["secondaryColor"],
            cursor: "pointer",
          }}
        />
      </div>
      {open && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          className={classes.root}
        >
          <div className="date-time-range-picker-container">
            <DateRangePicker
              ranges={[
                {
                  startDate: dateSelection.startDate,
                  endDate: dateSelection.endDate,
                  key: config.hardCoded.reactDatePickerKey,
                },
              ]}
              onChange={handleDateChange}
              className="sigview-date-range-picker"
              months={2}
              direction="horizontal"
              inputRanges={[]}
              weekStartsOn={1}
              staticRanges={[]}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
          <div className="date-time-range-picker-apply-cancel-row-container">
            <span
              className="date-time-range-picker-min-max"
              style={{ visibility: isMinMaxDateVisible ? "visible" : "hidden" }}
            >
              Data present for: {formattedMinMaxValue} in {timezone}
            </span>
            <div
              className="date-time-range-picker-apply-cancel-row"
              style={{
                width:
                  user?.reqMetadata?.userPreferredLanguage === "ja"
                    ? "21%"
                    : "18%",
              }}
            >
              <SigviewButton
                variant="outlined"
                onClick={handleCancel}
                title="Cancel"
                style={{ width: "auto", margin: { left: "3px", right: "3px" } }}
              />
              <SigviewButton
                variant="contained"
                onClick={handleSubmit}
                title="Submit"
                customClassName={`${rootPage}submitCalendarDates-GA`}
                style={{ width: "50px", margin: { left: "3px", right: "3px" } }}
              />
            </div>
          </div>
        </Popover>
      )}
    </>
  );
}

export default SigviewDateSingleSelect;
