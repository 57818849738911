// * Import required libraries
import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

// * Import custom components
// Screens/Pages
import DatastoryHome from "../screens/Datastory/DatastoryHome";
import DatastoryCat from "../screens/Datastory/DatastoryCat";
import DatastoryCreate from "../screens/Datastory/DatastoryCreate";
import DatastoryDashboard from "../screens/Datastory/DatastoryDashboard";
import ChartsHome from "../screens/Charts/ChartsHome";
import ChartsCreate from "../screens/Charts/ChartsCreate";
import PivotHome from "../screens/Pivot/PivotHome";
import PivotCreate from "../screens/Pivot/PivotCreate";
import AnalyzeDashboard from "../screens/Analyze/AnalyzeDashboard";
import AnalyzeDashboardAngularShareCompatible from "../screens/Analyze/AnalyzeDashboard/AnalyzeDashboardAngularShareCompatible";
// import AnalyzeCreate from "../screens/Analyze/AnalyzeCreate";
// import AnalyzeHome from "../screens/Analyze/AnalyzeHome";
import AdminAwsHome from "../screens/AdminAws/Home";
import AdminAwsUsers from "../screens/AdminAws/Users";
import AdminAwsUser from "../screens/AdminAws/User";
import AdminAwsAttributes from "../screens/AdminAws/Attributes";
import AnalyzeCreateContainer from "../screens/Analyze/AnalyzeDashboard/AnalyzeCreateContainer";

import Home from "../screens/Admin/Home";
import Groups from "../screens/Admin/Groups";
import Group from "../screens/Admin/Group";
import Users from "../screens/Admin/Users";
import User from "../screens/Admin/User";
import Attributes from "../screens/Admin/Attributes";
import BucketContainer from "../screens/Bucket/BucketContainer";
import AdminReport from "../screens/Admin/AdminReport";
// import Login from "../screens/Login/Login";
// import Error from "../screens/Error/Error";
import PageNotFound from "../screens/PageNotFound/PageNotFound";

function Routes() {
  const reqMetadata = useSelector((state) => state.user.reqMetadata);
  const history = useHistory();
  const location = history.location;
  return (
    <Switch>
      {/* REDIRECT */}
      <Redirect from="/" to="/analyze/dashboard" exact />
      {/* This is if user save the bookmark in the browser eg:
      https://openx.sigview.io/openxSignIn */}
      <Redirect from="/openxSignIn" to="/analyze/dashboard" exact />
      <Redirect from="/analyze" to="/analyze/dashboard" exact />
      {/* <Redirect from="/analyze/create" to="/analyze/dashboard" exact /> */}

      {/* ANALYZE */}
      <Route path="/analyze/dashboard" component={AnalyzeDashboard} exact />
      <Route
        path="/analyze/dashboard/create"
        component={AnalyzeCreateContainer}
        exact
      />
      {/* BUCKET */}
      <Route path="/manageBucket" component={BucketContainer} exact />
      {/* THE BELOW ROUTE is to cater to share URLs created in AngularJs */}
      <Route
        path="/home/dash"
        component={AnalyzeDashboardAngularShareCompatible}
        exact
      />
      {/* <Route path="/analyze" component={AnalyzeHome} exact />
      <Route path="/analyze/create" component={AnalyzeCreate} exact /> */}
      {/* //This will be replaced with React Router Switch when the application is completely moved to React
  const getRequiredComponent = () => {
    let Component;
    switch (activeNav) {
      case "datastory":
        switch (activeTab) {
          case "home":
            Component = <DatastoryHome />;
            break;
          case "category":
            Component = <DatastoryCat />;
            break;
          case "create":
            Component = <DatastoryCreate />;
            break;
          case "dashboard":
            Component = <DatastoryDashboard />;
            break;
          case "chartsHome":
            Component = <ChartsHome />;
            break;
          case "createChart":
            Component = <ChartsCreate />;
            break;
          default:
            Component = <DatastoryHome />;
        }
        break;
      case "admin":
        switch (activeTab) {
          case "home":
            Component = <Home />;
            break;
          case "groups":
            Component = <Groups />;
            break;
          case "group":
            Component = <Group />;
            break;
          case "users":
            Component = <Users />;
            break;
          case "user":
            Component = <User />;
            break;
          case "attributes":
            Component = <Attributes />;
            break;
          default:
            Component = <DatastoryHome />;
        }
        break;

      case "manageBucket":
        Component = <BucketContainer />;
        break;

      // switch (activeTab) {

      // }
      // case "charts":
      //   switch (activeTab) {
      //     case "home":
      //       Component = <ChartsHome />;
      //       break;
      //     case "createChart":
      //       Component = <ChartsCreate />;
      //       break;
      //     default:
      //       Component = <ChartsHome />;
      //   }
      //   break;
      default:
        Component = <DatastoryHome />;
    }
    return Component;
  }; */}

      {/* DATASTORY */}
      <Route path="/datastory" component={DatastoryHome} exact />
      <Route path="/datastory/sample" component={DatastoryCat} exact />
      <Route path="/datastory/saved" component={DatastoryCat} exact />
      <Route
        path="/datastory/dashboard/create"
        component={DatastoryCreate}
        exact
      />
      <Route
        path="/datastory/dashboard/bulk-edit"
        component={DatastoryCreate}
        exact
      />
      <Route path="/datastory/dashboard" component={DatastoryDashboard} exact />

      {/* DATASTORY CHARTS */}
      <Route path="/datastory/chart" component={ChartsHome} exact />
      <Route path="/datastory/chart/create" component={ChartsCreate} exact />
      <Route path="/datastory/chart/edit" component={ChartsCreate} exact />

      {/* PIVOT */}
      <Route path="/pivotx" component={PivotHome} exact />
      <Route path="/pivotx/create" component={PivotCreate} exact />
      <Route path="/pivotx/edit" component={PivotCreate} exact />

      {/* ADMIN */}
      {reqMetadata.organization !== "OpenX" && (
        <>
          <Route component={PageNotFound} />
          <Route path="/admin" component={AdminAwsHome} exact />
          <Route path="/admin/users" component={AdminAwsUsers} exact />
          <Route path="/admin/user" component={AdminAwsUser} exact />
          <Route
            path="/admin/attributes"
            component={AdminAwsAttributes}
            exact
          />
        </>
      )}
      {reqMetadata.organization === "OpenX" && (
        <>
          {/* This use case is for only realm instance if there is new realm then we have to add into this array */}
          {[
            "?realm=33across",
            "?realm=befoperator",
            "?realm=jpbefoperator",
            "?realm=justpremium",
            "?realm=meredith",
            "?realm=openx_corporate",
          ].includes(location.search) && (
            <>
              <Redirect from="/" to="/analyze/dashboard" exact />
            </>
          )}
          <Route component={PageNotFound} />
          <Route path="/admin" component={Home} exact />
          <Route path="/admin/users" component={Users} exact />
          <Route path="/admin/groups" component={Groups} exact />
          <Route path="/admin/group" component={Group} exact />
          <Route path="/admin/user" component={User} exact />
          <Route path="/admin/attributes" component={Attributes} exact />
          <Route path="/admin/adminReport" component={AdminReport} exact />
        </>
      )}
      {/* MISC */}
      <Route component={PageNotFound} />
    </Switch>
  );
}

export default Routes;
