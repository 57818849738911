// * Import required libraies
import React, { useState, useReducer, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

// * Import lib components
import { Box } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";

// * Import custom components
import SigviewButton from "../../../components/Common/SigviewButton";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SigviewRadioGroup from "../../../components/Common/SigviewRadioGroup";
import SigviewSingleSelect from "../../../components/Common/SigviewSingleSelect";

// * Import data/utils
import useReducerLogger from "../../../utils/useReducerLogger";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// Import hooks
import useUpdateEffect from "../../../hooks/useUpdateEffect";

// * Import API functions

// * Define style functions
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles({
    root: {
      "& .MuiPaper-root ": {
        // backgroundColor: "transparent !important",
        padding: "7px 15px 10px 15px",
      },
    },
    advanceSortRowHeader: { display: "flex", marginBottom: "7px" },
    advanceSortRowContainer: {},
    advanceSortRow: {
      display: "flex",
      marginBottom: "5px",
    },
    advanceSortRowTitle: { display: "flex", alignItems: "center" },
    advanceSortRowFooter: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
    },
  });
  return useStyles;
};

// * Redux Utilities
// Constants
const C = {
  UPDATE_ORDER_ON: "UPDATE_ORDER_ON",
  UPDATE_ORDER_TYPE: "UPDATE_ORDER_TYPE",
  REPLACE_FORM: "REPLACE_FORM",
};

// Reducer
function advanceSort(state = {}, action = {}) {
  const { type = "", payload = {} } = action;
  const { key = "", value = {} } = payload;
  let newState = {};
  switch (type) {
    case C.UPDATE_ORDER_ON:
      return {
        ...state,
        [key]: { ...state[key], orderOn: value },
      };
    case C.UPDATE_ORDER_TYPE:
      return {
        ...state,
        [key]: { ...state[key], orderType: value },
      };
    case C.REPLACE_FORM:
      newState = { ...value };
      break;
    default:
      console.error("UI ERROR");
      console.groupCollapsed("DETAILS");
      console.log("UNIDENTIFIED TYPE");
      console.log("REDUCER -> ", "advanceSort.js");
      console.log("state -> ", state);
      console.log("action -> ", action);
      console.groupEnd();
      newState = { ...state };
      break;
  }
  return newState;
}

// Action
export const replaceForm = (payload) => ({
  type: C.REPLACE_FORM,
  payload,
});
export const updateOrderOn = (payload) => ({
  type: C.UPDATE_ORDER_ON,
  payload,
});
export const updateOrderType = (payload) => ({
  type: C.UPDATE_ORDER_TYPE,
  payload,
});

// * Define static variables
const ascDescArr = [
  { id: "asc", name: "Ascending", disabled: false },
  { id: "desc", name: "Descending", disabled: false },
];

// * Define required components
function SigviewAdvanceSort(props = {}) {
  // * Destructure props
  const {
    initialSelections = {},
    data = {},
    onChange = () => {},
    customStyle = {},
    disabledAdvanceSort = false,
    customClassName,
  } = props;

  // * Define required hooks/contexts
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define required states
  const [anchorEl, setAnchorEl] = useState(null);
  const [selections, dispatchSelections] = useReducer(
    // useReducerLogger(advanceSort),
    advanceSort,
    initialSelections
  );
  const originalSelections = useRef(initialSelections);

  useUpdateEffect(() => {
    const payload = { value: initialSelections };
    const action = replaceForm(payload);
    dispatchSelections(action);
    originalSelections.current = initialSelections;
  }, [initialSelections]);

  // * Define required event handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleReset = () => {
    for (const iterator in originalSelections.current) {
      originalSelections.current[iterator].orderOn = data.metrics[0].id;
      originalSelections.current[iterator].orderType = "desc";
    }

    const payload = { value: originalSelections.current };
    const action = replaceForm(payload);
    dispatchSelections(action);
  };
  const handleOriginalSelection = () => {
    const payload = { value: originalSelections.current };
    const action = replaceForm(payload);
    dispatchSelections(action);
  };
  const handleClose = () => {
    // Reset selections on Closing it rest the selections
    handleOriginalSelection();

    // Close popover
    setAnchorEl(null);
  };
  const handleSave = () => {
    // Update original selections
    originalSelections.current = selections;

    // Trigger onChange
    onChange(selections);

    // Close popover
    setAnchorEl(null);
  };

  // * Define required variables
  const open = Boolean(anchorEl);
  const id = open ? "advanceSortPopover" : undefined;
  const useSigviewStyles = makeSigviewStyles(themeColors, customStyle);
  const classes = useSigviewStyles();

  // * Define required
  const AdvanceSortRow = (props = {}) => {
    const { id = "", name = "" } = props;
    const rowSelections = selections[id];

    const handleOrderOnChange = (value) => {
      const payload = { key: id, value };
      const action = updateOrderOn(payload);
      dispatchSelections(action);
    };

    const handleOrderByChange = (value) => {
      const payload = { key: id, value };
      const action = updateOrderType(payload);
      dispatchSelections(action);
    };

    return (
      <Box className={classes.advanceSortRow}>
        <Box className={classes.advanceSortRowTitle}>
          <SigviewTypography
            variant="pSmallMedium"
            style={{ width: "150px", height: "max-content" }}
          >
            {name}
          </SigviewTypography>
        </Box>

        <SigviewSingleSelect
          value={rowSelections.orderOn}
          data={data.orderOnList[id]}
          onChange={handleOrderOnChange}
          minWidth="120px"
          maxWidth="120px"
          margin={{ right: "20px" }}
        />
        <SigviewRadioGroup
          data={ascDescArr}
          value={rowSelections.orderType}
          onChange={handleOrderByChange}
          style={{ rootFlexDirection: "row" }}
        />
      </Box>
    );
  };
  const AdvanceSortHeader = (props = {}) => {
    return (
      <Box className={classes.advanceSortRowHeader}>
        <SigviewTypography
          variant="pMedium"
          style={{
            width: "140px",
            color: themeColors["primaryColor"],
          }}
        >
          Sort Dimension
        </SigviewTypography>
        <SigviewTypography
          variant="pMedium"
          style={{
            width: "45px",
            color: themeColors["secondaryColorLighter"],
          }}
        >
          by
        </SigviewTypography>
        <SigviewTypography
          variant="pMedium"
          style={{ width: "70px", color: themeColors["primaryColor"] }}
        >
          Attribute
        </SigviewTypography>
        <SigviewTypography
          variant="pMedium"
          style={{
            width: "75px",
            color: themeColors["secondaryColorLighter"],
          }}
        >
          in
        </SigviewTypography>
        <SigviewTypography
          variant="pMedium"
          style={{ color: themeColors["primaryColor"] }}
        >
          Order
        </SigviewTypography>
      </Box>
    );
  };
  const AdvanceSortFooter = (props = {}) => {
    return (
      <Box className={classes.advanceSortRowFooter}>
        <SigviewButton
          variant="outlined"
          onClick={handleReset}
          title="Reset"
          style={{ width: "auto" }}
        />
        <SigviewButton
          variant="outlined"
          onClick={handleClose}
          title="Cancel"
          style={{ margin: { left: "10px" }, width: "auto" }}
        />
        <SigviewButton
          variant="contained"
          onClick={handleSave}
          title="Done"
          style={{ margin: { left: "10px" }, width: "50px" }}
        />
      </Box>
    );
  };

  return (
    <>
      <SigviewButton
        variant="containedLighter"
        onClick={handleClick}
        title="Advance Sort"
        disabled={disabledAdvanceSort}
        customClassName="PivotOpenAdvanceSort-GA"
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.root}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <AdvanceSortHeader />
        <Box className={classes.advanceSortRowContainer}>
          {data.dimensions.map((row) => (
            <AdvanceSortRow key={row.id} {...row} />
          ))}
        </Box>
        <AdvanceSortFooter />
      </Popover>
    </>
  );
}

export default SigviewAdvanceSort;
