import { makeStyles } from "@material-ui/core";

//Util functions
function getCurrentStepIndex(state) {
  const steps = state.steps;
  const activeStepId = state.activeStep;
  const currStepObject = steps.find((row) => row.id === activeStepId);
  const currStepIndex = steps.indexOf(currStepObject);
  return currStepIndex;
}

// Defining static variables/functions
function defaultFormValidator(state, payload) {
  return { flag: true, message: "" };
}

// Making styles
//1- Stepper styling
const makeSigviewStepperStyles = (themeColors) => {
  const useStyles = makeStyles(() => ({
    stepperRootContainer: {
      // height: "70px",
      height: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "20px",
      boxSizing: "content-box !important",
    },
    stepperRoot: {
      "& *": {
        lineHeight: "1 !important",
        fontFamily: "Fira Sans !important",
      },
      "& .MuiStepButton-root": {
        margin: "0 !important",
        padding: "0 !important",
      },
      padding: "0 !important",
      width: "296px",
      backgroundColor: "#f8f9fd !important",
    },

    stepButtonBase: {
      "& .MuiSvgIcon-root": {
        height: "25px !important",
        width: "25px !important",
        "& circle": {
          color: `${themeColors["secondaryColorLighter"]} !important`,
          // color: `${themeColors["secondaryColorLight"]} !important`,
        },
      },
      "& .MuiStepLabel-label": { fontSize: "0.75rem !important" },
      "& .MuiStepLabel-alternativeLabel": {
        color: `${themeColors["secondaryColorLight"]} !important`,
      },
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        marginTop: "7px !important",
      },
    },

    stepConnectorBase: {
      "& .MuiStepConnector-root": {
        top: "11px !important",
        left: "calc(-50% + 13px) !important",
        right: "calc(50% + 12px) !important",
        position: "absolute !important",
        "& .MuiStepConnector-line": {
          display: "block",
          borderColor: "#e6e9ee",
        },
      },
      "& .MuiStepConnector-lineHorizontal": {
        borderTopStyle: "solid !important",
        borderTopWidth: "3px !important",
      },
    },
    stepConnectorVisited: {
      "& .MuiStepConnector-root": {
        "& .MuiStepConnector-line": {
          borderColor: "#46596a",
        },
      },
    },

    stepButtonVisited: {
      "& .MuiStepIcon-root": {
        "& circle": {
          color: "#46596a !important",
        },
      },
      "& .MuiStepLabel-alternativeLabel": {
        color: "#46596a !important",
      },
    },
    stepButtonActive: {
      "& .MuiStepIcon-active": {
        "& circle": {
          color: `${themeColors["primaryColor"]} !important`,
        },
      },
      "& .MuiStepLabel-alternativeLabel": {
        "&.MuiStepLabel-active": {
          color: `${themeColors["primaryColor"]} !important`,
        },
      },
    },
  }));
  return useStyles;
};
//2- Main content styling
const makeSigviewStepperMainContainerStyles = (themeColors) => {
  const useStyles = makeStyles(() => ({
    mainContainer: { height: "100%", padding: "0px" },
    mainContentRootContainer: {
      height: "calc(100% - 130px)",
      display: "flex",
      flexDirection: "column",
      // alignItems: "center",
      // justifyContent: "space-between",
      overflow: "auto",
    },
  }));
  return useStyles;
};
//3- Bottom container styling
const makeSigviewStepperBottomStyles = (themeColors) => {
  const useStyles = makeStyles(() => ({
    bottomRootContainer: {
      height: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0px 25px",
    },
    bottomRightContainer: {
      display: "flex",
      "&>:not(:last-child)": {
        marginRight: "15px !important",
      },
    },
  }));
  return useStyles;
};

const stepperStyles = {
  makeSigviewStepperStyles,
  makeSigviewStepperMainContainerStyles,
  makeSigviewStepperBottomStyles,
};

export { getCurrentStepIndex, defaultFormValidator, stepperStyles };
