//Import required libraies
import React, { useEffect, useState, useContext } from "react";
import { Calendar } from "react-date-range";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

// Import Custom Component
import SigviewButton from "./SigviewButton";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import action creators

// Import utils

// Import styles

function SigviewDateSingleSelect(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const { onChange, value, format, minMaxDates, showEndDate = false } = props;

  const [date, setDate] = useState(value);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "sigviewDateSingleSelect" : undefined;
  const formattedValue = showEndDate
    ? `${moment(value.startDate).format(format)} - ${moment(
        value.endDate
      ).format(format)}`
    : moment(value.startDate).format(format);
  const minDate = minMaxDates?.minDate;
  const maxDate = minMaxDates?.maxDate;

  // Define styles
  const useStyles = makeStyles((theme) => ({
    root: {
      zIndex: "999999999 !important",
      "& .MuiPaper-root": {
        backgroundColor: themeColors["popoverBgColor"],
      },
    },
    displayDate: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    formattedValue: {
      width: "max-content",
    },
    dateSingleSelectContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "6px",
    },
    dateSingleSelectMenuBar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "6px",
    },
    arrowDropDown: {
      marginLeft: "3px",
      fontSize: "24px !important",
    },
  }));

  //Update date if props change
  useEffect(() => {
    setDate(value.startDate);
  }, [value]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const handleSubmit = () => {
    onChange(date);
    handleClose();
  };

  const handleCancel = () => {
    //Reset values to the original values
    setDate(value.startDate);
    handleClose();
  };

  const classes = useStyles();

  return (
    <>
      <div className={classes.displayDate} onClick={handleClick}>
        <p className={classes.formattedValue}>{formattedValue}</p>
        <span className={`material-icons ${classes.arrowDropDown}`}>
          arrow_drop_down
        </span>
      </div>
      {open && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          className={classes.root}
        >
          <div className={classes.dateSingleSelectContainer}>
            <Calendar
              date={date}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handleDateChange}
              className="sigview-date-single-select"
            />
            <div className={classes.dateSingleSelectMenuBar}>
              <SigviewButton
                variant="contained"
                onClick={handleSubmit}
                title="Submit"
                style={{ width: "50px", margin: { left: "3px", right: "3px" } }}
              />
              <SigviewButton
                variant="outlined"
                onClick={handleCancel}
                title="Cancel"
                style={{ width: "50px", margin: { left: "3px", right: "3px" } }}
              />
            </div>
          </div>
        </Popover>
      )}
    </>
  );
}

export default SigviewDateSingleSelect;
