// * Import required libraries
import React, { memo, useCallback, useContext, useMemo } from "react";
import { connect } from "react-redux";
import isEqual from "lodash.isequal";

// * Import lib components
import { Checkbox, Box, Hidden } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

// * Import custom components
import SigviewIcon from "../Common/SigviewIcon";
import SigviewTooltip from "../Common/SigviewTooltip";
import SigviewDndChecklist from "../Common/SigviewDndChecklist";
import SigviewTypography from "../Common/SigviewTypography";
import ChecklistRowDim from "./ChecklistRowDim";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Import utils
import { getCommonMetricsFromAllDimensions } from "../../utils/analyzeUtils";

// * Define required variables
const makeSigviewStyles = (...args) => {
  const [themeColors, style = {}] = args;
  const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
      flexDirection: "column",
      color: themeColors["sidenavItemColor"],
      // backgroundColor: themeColors["sidenavBgColor"],
      height: "100%",
      // padding: "10px 12px",
      boxSizing: "border-box",
    },
    itemBase: { display: "flex", alignItems: "center", width: "100%" },
    disabledCursorRemove: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      cursor: "default",
    },
    checkboxItemLabel: {
      fontSize: "12px",
      color: themeColors["secondaryColor"],
      paddingLeft: "0px",
      paddingRight: "2px",
      boxSizing: "border-box",
      width: "calc(100% - 18px - 16px)",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "inline-block",
      overflow: "hidden",
      cursor: "pointer",
    },
    checkboxItemLabelSelected: { color: themeColors["primaryColor"] },
    disabledItem: {
      opacity: 1,
      pointerEvents: "none",
      fontSize: "14px",
    },
    disabledItemHidden: {
      visibility: "hidden",
      alignItems: "center",
      marginLeft: "-10px",
    },
    accordionRoot: {
      "&.MuiAccordion-rounded": {
        borderBottom: `1px solid ${themeColors["secondaryColorLightest"]}`,
      },
      "&.Mui-expanded": { marginBottom: "5px !important" },
      "&.Mui-expanded.MuiAccordion-rounded": {
        borderBottom: `0px solid transparent !important`,
      },
      "&.MuiAccordion-rounded:last-child": {
        borderBottomLeftRadius: "0px !important",
        borderBottomRightRadius: "0px !important",
        borderTopRightRadius: "0px !important",
        borderTopLeftRadius: "0px !important",
      },
      "&.MuiAccordion-rounded:first-child": {
        borderBottomLeftRadius: "0px !important",
        borderBottomRightRadius: "0px !important",
        borderTopRightRadius: "0px !important",
        borderTopLeftRadius: "0px !important",
      },
      "& .MuiAccordionSummary-root": {
        padding: "0px 7px !important",
      },
      "& .MuiButtonBase-root": {
        minHeight: "25px !important",
      },
      "& .MuiAccordionSummary-content": {
        margin: "5px 0px !important",
      },
    },
  }));
  return useStyles;
};
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const getItemStyle = ({ isDragging, draggableStyle, customStyle }) => {
  const backgroundColor = "transparent";
  const backgroundColorDuringDragging =
    customStyle?.backgroundColorDuringDragging || "lightblue";
  const marginRight = "0px";
  const marginBottom = "0px";
  const borderRadius = "0px";
  const border = "0px solid transparent";
  const boxSizing = "border-box";

  return {
    // change background colour if dragging
    background: isDragging ? backgroundColorDuringDragging : backgroundColor,

    // add custom styles
    marginRight,
    marginBottom,
    borderRadius,
    border,
    boxSizing,

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};
const getListStyle = (props) => {
  const { isDraggingOver, direction, customStyle } = props;

  const flexDirectionMapping = {
    vertical: "column",
    horizontal: "row",
  };

  const backgroundColor = customStyle?.backgroundColor || "lightgrey";
  const backgroundColorDuringDragging =
    customStyle?.backgroundColorDuringDragging || "lightblue";
  // const overflow = customStyle?.overflow || "auto";

  return {
    background: isDraggingOver
      ? backgroundColorDuringDragging
      : backgroundColor,
    display: "flex",
    flexDirection: flexDirectionMapping[direction] || "column",
    padding: customStyle?.padding || 8,
    width: "100%",
  };
};

function DimensionDndChecklist(props) {
  const { state: themeState } = useContext(ThemeContext);

  const themeColors = themeState.themes[themeState.activeTheme];

  // * Destructure props
  const {
    data = [],
    // metricData,
    selections = [],
    setSelections = () => {},
    settings = { atLeastOneItem: true },
    openAccordions = [],
    setOpenAccordions = () => {},
    style = {},
  } = props;

  const { metricData } = useMemo(() => props, [props.metricData]);

  // * Define required event handlers
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    setSelections((selections) => {
      const reorderedSelectedItems = reorder(
        selections,
        result.source.index,
        result.destination.index
      );
      return reorderedSelectedItems;
    });
  };
  const onCheckboxChange = (event, itemId, flag) => {
    if (flag) {
      // Add New Entry
      setSelections((selections) => {
        const commonMetrics = getCommonMetricsFromAllDimensions(selections);
        // Corner Case: IF there is no CommonMetrics then get the first form metriData
        const finalCommonMetrics =
          commonMetrics.length === 0 ? [metricData[0].id] : commonMetrics;
        const newEntry = {
          id: itemId,
          // ! TODO: later to remove the below commented if no bug after testing
          // metrics: getCommonMetricsFromAllDimensions(selections),
          metrics: finalCommonMetrics,
        };
        const newSelections = [...selections, newEntry];
        return newSelections;
      });
    } else {
      // Remove Old Entry
      setSelections((selections) =>
        selections.filter((row) => itemId !== row.id)
      );
    }
  };

  // * Define required static variables
  var selectionsIds = selections.map((row) => row.id);
  var selectedDataItems = selections.map((selectedRow) =>
    data.find((row) => row.id === selectedRow.id)
  );
  // ! OLD LOGIC WHICH DIDN'T WORK
  // // Accordion to be controlled only when the drawer opens, otherwise on every change of the metric, it reinitializes based on default value
  // const shouldConsiderDimOpenByDefault = isEqual(
  //   originalSelections.current,
  //   selections
  // );
  // selectedDataItems = selectedDataItems.map((row, index) => ({
  //   ...row,
  //   ...(shouldConsiderDimOpenByDefault
  //     ? { showAccordian: dimOpenByDefault.includes(row.id) } // For opeaning the According form the dimension table
  //     : {}),
  // }));
  // Remove undefined elements
  // Bug occurs when data is empty (when user quickly types in the search box)
  selectedDataItems = selectedDataItems.filter((row) => row !== undefined);
  const isRemainingDataItemOnlyOne = selections.length === 1;
  if (isRemainingDataItemOnlyOne && settings.atLeastOneItem) {
    selectedDataItems = selectedDataItems.map((row, index) => ({
      ...row,
      disabled: true,
    }));
  }
  const remainingDataItems = data.filter(
    (item) => !selectionsIds.includes(item.id)
  );

  // Styling
  const SigviewCheckbox = withStyles(() => ({
    root: {
      opacity: 1,
      color: `${themeColors["primaryColor"]} !important`,
      padding: "0px !important",
      marginRight: "5px",
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18,
      },
      "&.MuiCheckbox-colorSecondary.Mui-checked:hover": {
        backgroundColor: "transparent",
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  }))(Checkbox);
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const droppableStyle = {
    padding: "0px",
    backgroundColorDuringDragging: "transparent",
    backgroundColor: "transparent",
  };
  const draggableStyle = {
    backgroundColorDuringDragging: themeColors["secondaryColorLightest"],
    backgroundColor: themeColors["secondaryColorLightest"],
    marginRight: "0px",
    marginBottom: "0px",
    borderRadius: "3px",
    border: `1px solid ${themeColors["secondaryColorLighter"]}`,
  };
  const ChecklistRow = (props = {}) => {
    // * Destructure props
    const {
      item = {},
      variant = "selected",
      openAccordions = [],
      setOpenAccordions = () => {},
      provided = {},
      selectedMetrics = [],
    } = props;

    // * Define required event handlers
    const handleCheckboxChange = (event) => {
      if (!item.disabled)
        onCheckboxChange(event, item.id, variant === "remaining");
    };

    const handleAccChange = () => {
      setOpenAccordions((oldOpenAccordions) => {
        const isAccExpanded = openAccordions[item.id] === true;
        if (isAccExpanded) {
          let { [item.id]: keyToBeRemoved, ...newOpenAccordions } =
            oldOpenAccordions;
          return newOpenAccordions;
        } else {
          let newOpenAccordions = { ...oldOpenAccordions, [item.id]: true };
          return newOpenAccordions;
        }
      });
    };

    const handleDimMetricsChange = (value) => {
      const newSelections = selections.map((row) =>
        row.id === item.id ? { ...row, metrics: value } : row
      );
      const areEqual = isEqual(selections, newSelections);
      if (!areEqual) {
        setSelections(newSelections);
      }
    };

    // * Define required variables
    var labelClassName = classes.checkboxItemLabel;
    if (variant === "selected")
      labelClassName += ` ${classes.checkboxItemLabelSelected}`;
    if (variant === "remaining")
      labelClassName += ` ${classes.checkboxItemLabelRemaining}`;
    if (item.disabled) labelClassName += ` ${classes.disabledItem}`;
    const checkboxClassName = item.disabled ? classes.disabledItemHidden : "";
    const accExpanded = openAccordions[item.id] === true;
    const accordionsHover = item.disabled
      ? classes.disabledCursorRemove
      : classes.itemBase;

    return (
      <Box>
        <Accordion expanded={accExpanded} className={classes.accordionRoot}>
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className={accordionsHover}>
              <SigviewCheckbox
                checked={variant === "selected"}
                value="checkedG"
                inputProps={{ "aria-label": "secondary checkbox" }}
                disabled={item.disabled}
                className={checkboxClassName}
                onClick={handleCheckboxChange}
              />
              <Box
                component="label"
                title={item.name}
                className={labelClassName}
                onClick={handleCheckboxChange}
              >
                {item.name}
              </Box>

              {variant === "selected" && (
                <SigviewTooltip
                  title={
                    accExpanded
                      ? "Click To Hide Metrics"
                      : "Click To Show Metrics"
                  }
                  placement="bottom"
                >
                  <Box>
                    <SigviewIcon
                      iconName={accExpanded ? "expand_less" : "expand_more"}
                      onClick={handleAccChange}
                      style={{
                        fontSize: "25px !important",
                        padding: "0 0 0 10px !important",
                        color: item.disabled
                          ? "#fff"
                          : themeColors["secondaryColorLight"],
                        hoverColor: item.disabled
                          ? "#fff"
                          : themeColors["secondaryColorLight"],
                        cursor: item.disabled ? "default" : "pointer",
                      }}
                    />
                  </Box>
                </SigviewTooltip>
              )}
              {variant === "selected" && (
                <span {...provided.dragHandleProps}>
                  <SigviewIcon
                    className="material-icons-outlined"
                    iconName="menu"
                    style={{
                      fontSize: "16px !important",
                      display: "inline-flex",
                      padding: "0px 0px 0px 0px",
                      color: item.disabled
                        ? "#fff"
                        : themeColors["secondaryColorLight"],
                      hoverColor: item.disabled
                        ? "#fff"
                        : themeColors["secondaryColorLight"],
                      cursor: item.disabled ? "default" : "grab",
                    }}
                  />
                </span>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {variant === "selected" && accExpanded && (
              <Box css={{ width: "100%" }}>
                <SigviewTypography
                  variant="pMedium"
                  style={{
                    height: "max-content",
                    color: themeColors["secondaryColor"],
                    fontWeight: 500,
                    padding: "0px 0px 5px 0px",
                  }}
                >
                  Select Metrics
                </SigviewTypography>
                <SigviewDndChecklist
                  data={metricData}
                  initialSelections={selectedMetrics}
                  onChange={handleDimMetricsChange}
                />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  // * DEBUGGER
  // console.groupCollapsed("DimensionDndChecklist.js");
  // console.log("data", data);
  // console.log("selections", selections);
  // console.log("selectedDataItems", selectedDataItems);
  // console.log("remainingDataItems", remainingDataItems);
  // console.log("metricData",metricData)
  // console.groupEnd();

  return (
    <Box className={classes.root}>
      <Box className={classes.selectedItemsContainer}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle({
                  isDraggingOver: snapshot.isDraggingOver,
                  direction: "vertical",
                  customStyle: droppableStyle,
                })}
                {...provided.droppableProps}
              >
                {selectedDataItems.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle({
                          isDragging: snapshot.isDragging,
                          draggableStyle: provided.draggableProps.style,
                          customStyle: draggableStyle,
                        })}
                      >
                        <ChecklistRowDim
                          item={item}
                          variant="selected"
                          accordianOpen={item.showAccordian}
                          provided={provided}
                          selectedMetrics={
                            selections.find((row) => row.id === item.id).metrics
                          }
                          openAccordions={openAccordions}
                          setOpenAccordions={setOpenAccordions}
                          selections={selections}
                          setSelections={setSelections}
                          metricData={metricData}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box className={classes.remainingItemsContainer}>
        {remainingDataItems.map((item, index) => (
          <ChecklistRowDim
            key={item.id}
            item={item}
            variant="remaining"
            selections={selections}
            setSelections={setSelections}
            metricData={metricData}
          />
        ))}
      </Box>
    </Box>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(memo(DimensionDndChecklist));
