// Import required libraries
import React, { useContext } from "react";

// Import custom components
import SigviewTypography from "../../Common/SigviewTypography";
import SelectedFilters from "../../GlobalFilters/SelectedFilters";
import { useTranslation } from "react-i18next";

// Import utils/data
import {
  unwrapperSummary,
  alertCriteriaFormula,
  getCriteriaFormulae,
  getMetricTitleFormId,
} from "../../../utils/alertUtils";

// Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// Import styles
import "./AlertManagerSummary.scss";

function AlertManagerSummary(props) {
  const { t } = useTranslation();
  // Destructure props
  const { state, allData } = props;
  // console.log(" STATE OF SUMMARY", props);

  // Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  //Defining required functions
  const allplotlyDimension = allData.plotlyDimensions.map((row) => ({
    id: row._id,
    name: row._title,
    disabled: false,
  }));
  const allplotlyMetric = allData.plotlyMetrics.map((row) => ({
    id: row._id,
    name: row._title,
    dataUnitValue: row.dataUnit.value,
    disabled: false,
  }));

  // returning the name after giving id of metric and dimension
  // TODO: remove this code is not use here
  // function giveName(id, arr) {
  //   const row = arr.find((el) => el.id === id);
  //   return row;
  // }

  // function getMetricTitleFormId(id, arr) {
  //   const row = arr.find((el) => el.id === id);
  //   return row;
  // }

  const finalObj = unwrapperSummary(state);
  // console.log("finalObj", finalObj);

  // Destructure finalObj
  const {
    name,
    alertMeWhen,
    alertDuration,
    alertCriteria,
    criteriaFormula,
    alertFilters,
    emailRecipients,
  } = finalObj;
  const { comparedWith, basis, timeZone } = alertMeWhen;

  // Defining required Components
  const AlertCriteria = () => {
    const { t } = useTranslation();
    const alertCompared = comparedWith ? ` ${comparedWith} ` : "";

    var result =
      state.alertType.value === "metric" &&
      alertCriteria.metric.map((obj) => {
        return obj.changeCriteria;
      });
    // console.log("result->", result);
    return (
      <>
        {state.alertType.value === "metric" && (
          <div className="alert-me">
            <p>
              {t("On")} <span className="highlight">{t(basis)}</span>{" "}
              {t("basis")}{" "}
              {state.alertType.value === "metric"
                ? result.includes("Percentage Change") && (
                    <>
                      {t("compared with")}
                      <span className="highlight">{alertCompared}</span>{" "}
                    </>
                  )
                : ""}
              {t("in")}
              <span className="highlight">{timeZone}</span>
            </p>

            <p>{t("When")}</p>

            {state.alertType.value === "metric" &&
              alertCriteria.metric.map((obj, i) => {
                const {
                  changeCriteria,
                  metric,
                  condition,
                  conditionValue,
                  logicalCondition,
                } = obj;
                return (
                  <p>
                    {changeCriteria === "Percentage Change" && (
                      <>
                        <span className="highlight">
                          {t("Percentage Change")}
                        </span>
                        <span> {t("of")}</span>
                      </>
                    )}
                    {changeCriteria === "Total" && (
                      <span className="highlight">{t("Total")}</span>
                    )}
                    <span className="highlight">
                      {getMetricTitleFormId(metric, allplotlyMetric).name}
                    </span>
                    <span className="highlight">{t(condition)}</span>
                    <span className="highlight"> {conditionValue}</span>{" "}
                    {changeCriteria === "Percentage Change" && <>%</>}
                    {i != alertCriteria.metric.length - 1 && (
                      <span className="logic">
                        {t(logicalCondition.toUpperCase())}
                      </span>
                    )}
                  </p>
                );
              })}
          </div>
        )}
        {state.alertType.value === "dimension" && (
          <div className="alert-me">
            <Dimension />
          </div>
        )}
      </>
    );
  };
  const Dimension = () => {
    // changeCriteria: "Total"
    // condition: "Is Equal to"
    // conditionValue: ""
    // dimension: "selectDimension"
    // dimensionRow: "change"
    // group: "Top 10"
    // metric: "selectMetric"

    const {
      changeCriteria,
      condition,
      conditionValue,
      dimension,
      dimensionRow,
      group,
      metric,
    } = alertCriteria.dimension;
    return (
      <>
        {dimensionRow === "change" && (
          <p className="alert-me-dimension">
            {t("If")}
            <span className="highlight">{t(changeCriteria)}</span>
            <span className="highlight">
              {getMetricTitleFormId(metric, allplotlyMetric).name}
            </span>
            {t("for")}
            <span className="highlight">{t(group)}</span>
            <span className="highlight">
              {getMetricTitleFormId(dimension, allplotlyDimension).name}
            </span>
            {t("is")}
            <span className="highlight">{t(condition)}</span>
            <span className="highlight">{conditionValue}</span>
            {changeCriteria === "Percentage Change" && <>{" % "}</>}
            {changeCriteria === "Percentage Change" && (
              <>
                {t("Compared With")}
                <span className="highlight">{t(comparedWith)}</span>
              </>
            )}
            {t("For Timezone")}
            <span className="highlight"> {timeZone}</span>
            {t("On")} <span className="highlight">{t(basis)}</span> {t("basis")}
          </p>
        )}
        {dimensionRow === "add" && (
          <p className="alert-me-dimension">
            {t("If There is a new value in")}{" "}
            <span className="highlight">
              {getMetricTitleFormId(dimension, allplotlyDimension).name}
            </span>
            <>
              {t("Compared With")}
              <span className="highlight">{comparedWith}</span>
            </>
            {t("For Timezone")}
            <span className="highlight"> {timeZone}</span>
            {t("On")} <span className="highlight">{t(basis)}</span> {t("basis")}
          </p>
        )}
        {dimensionRow === "delete" && (
          <p className="alert-me-dimension">
            {" "}
            {t("If Any value for")}{" "}
            <span className="highlight">
              {getMetricTitleFormId(dimension, allplotlyDimension).name}
            </span>{" "}
            {t("value was dropped ")}
            <>
              {t("Compared With")}
              <span className="highlight">{t(comparedWith)}</span>
            </>
            {t("For Timezone")}
            <span className="highlight"> {timeZone}</span>
            {t("On")} <span className="highlight">{t(basis)}</span> {t("basis")}
          </p>
        )}
      </>
    );
  };
  const AlertCriteriaFormula = () => {
    // console.log("row criteriaFormula", criteriaFormula);
    const finalFormulaeFormUtils = alertCriteriaFormula({ state, allData });

    // ! TODO: Remove the below code because same thing is working on utils
    // "[ Total(CM019)<25 ] AND [ Percentage Change(M002)~25 OR Total(M002)<25 ]"
    const exArr = [];
    for (const el of criteriaFormula) {
      // console.log(el);
      const inArr = [];
      for (const el2 of el) {
        const { formula } = el2;
        const { changeCriteria, metric, condition, conditionValue } = formula;
        // ${changeCriteria}(${metric})${condition}${conditionValue}
        const dataType = getMetricTitleFormId(
          metric,
          allplotlyMetric
        ).dataUnitValue;
        // console.log(dataType);
        const criteria =
          changeCriteria === "Absolute Change" ? "" : changeCriteria;
        const str = `${criteria}(${
          getMetricTitleFormId(metric, allplotlyMetric).name
        })${condition}${dataType === "$" ? "$" : ""}${conditionValue}${
          dataType === "%" ? "%" : ""
        }`;
        // console.log(str);
        inArr.push(str);
      }
      // console.log(inArr.join(" OR "));
      exArr.push(`[ ${inArr.join(" OR ")} ]`);
    }
    // console.log(exArr.join(" AND "));
    const formulaString = exArr.join(" AND ");
    return (
      <>
        <div className="criteria-formula">
          <p>{finalFormulaeFormUtils}</p>
        </div>
      </>
    );
  };
  // console.log("Alert summary->", state);

  //! to be improved
  const summarystyle = {
    // pointerEvents: "none",
    height: "max-content",
    width: "98%",
    border: "1px solid ##edf1ff",
    padding: "7px",
    marginTop: "5px",
    borderRadius: "4px",
    minHeight: "24px",
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    backgroundColor: "var(--popoverBgColor)",
    color: "var(--secondaryColor)",
    display: "flex",
  };

  //* Defining required variables
  const showFilterRow = alertFilters.length > 0 ? true : false;
  // console.log("alertfilters", alertFilters);
  return (
    <>
      <div className="summary-container">
        <div className="summary-items">
          <p
            style={{
              color: themeColors["secondaryColor"],
              fontSize: "12px",
              fontWeight: "400",
            }}
            className="label"
          >
            {t("Name:")}
          </p>
          <div style={summarystyle}>
            <SigviewTypography variant="AlertSummaryTitle">
              {name}
            </SigviewTypography>
          </div>
        </div>
        <div className="summary-items">
          <p
            style={{
              color: themeColors["secondaryColor"],
              fontSize: "12px",
              fontWeight: "400",
            }}
            className="label"
          >
            {t("Alert Me:")}
          </p>
          <div className="alert-me-container" style={summarystyle}>
            {/* <SigviewTypography
              variant="AlertSummaryTitle"
              style={{
                height: "auto",
              }}
            >
              {" "}
            </SigviewTypography> */}
            <span
              style={{
                fontSize: "12px",
              }}
            >
              <AlertCriteria />
            </span>{" "}
          </div>
        </div>
        {state.alertType.value === "metric" && (
          <div className="summary-items">
            <p
              style={{
                color: themeColors["secondaryColor"],
                fontSize: "12px",
                fontWeight: "400",
              }}
              className="label"
            >
              {t("Criteria Formula:")}
            </p>

            <div style={summarystyle}>
              {/* <SigviewTypography variant="AlertSummaryTitle"></SigviewTypography> */}
              <span
                style={{
                  fontSize: "12px",
                }}
              >
                <AlertCriteriaFormula />
              </span>{" "}
            </div>
          </div>
        )}
        {showFilterRow && (
          <div className="summary-items">
            <p
              style={{
                color: themeColors["secondaryColor"],
                fontSize: "12px",
                fontWeight: "400",
              }}
              className="label"
            >
              {t("Filter:")}
            </p>

            <div style={summarystyle}>
              <SelectedFilters
                style={{ maxHeight: "62px" }}
                selectedDimensionFilters={[...alertFilters]}
                allData={allData}
                disableClick={true}
                isReadOnly={true}
              />
            </div>
          </div>
        )}
        {/* <div className="summary-items">
          <p className="label">Filter:</p>

          <div style={summarystyle}>
            <SigviewTypography variant="AlertSummaryTitle">
              {includeFilters}
            </SigviewTypography>
          </div>
        </div> */}
        {/* <div className="summary-items">
          <p className="label">Exclude Filter:</p>

          <div style={summarystyle}>
            <SigviewTypography variant="AlertSummaryTitle">
              {excludeFilters}
            </SigviewTypography>
          </div>
        </div> */}
        <div className="summary-items">
          <p
            style={{
              color: themeColors["secondaryColor"],
              fontSize: "12px",
              fontWeight: "400",
            }}
            className="label"
          >
            {t("Alert Ends in:")}
          </p>

          <div style={summarystyle}>
            <SigviewTypography variant="AlertSummaryTitle">
              {alertDuration}
            </SigviewTypography>
          </div>
        </div>

        <div className="summary-items">
          <p
            style={{
              color: themeColors["secondaryColor"],
              fontSize: "12px",
              fontWeight: "400",
            }}
            className="label"
          >
            {t("Email Recipients:")}
          </p>
          <div style={summarystyle}>
            <SigviewTypography variant="AlertSummaryTitle">
              {emailRecipients.map((el) => el).join(", ")}
            </SigviewTypography>
          </div>
        </div>
      </div>{" "}
    </>
  );
}

export default AlertManagerSummary;
