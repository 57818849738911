import C from "../constants";
import { removeDupFromArray } from "../../utils/utils";
import { validateFilterRow } from "../../utils/filtersUtils";

export default function dimensionFilters(state = {}, payload) {
  let {
    type,
    newDimensionFilters,
    filterId,
    filterMetadata,
    filterType,
    filterValues,
    filterValidity,
    newAdvancedFilters,
  } = payload;
  let newState, newFilterObject, filterExistsFlag;

  switch (type) {
    case C.REPLACE_ALL_DIMENSION_FILTERS:
      return newDimensionFilters;

    case C.ADD_FILTER:
      newFilterObject = {
        id: filterId,
        metadata: filterMetadata,
        values: filterValues,
        filterType,
        advancedFilters: [],
        valid: filterValidity,
      };
      newState = [...state, newFilterObject];
      return newState;

    case C.REMOVE_FILTER:
      newState = state.filter((filterRow) => filterRow.id !== filterId);
      return newState;

    case C.CHANGE_FILTER_TYPE:
      //check if the filter object exists
      filterExistsFlag = state.find((filterRow) => filterRow.id === filterId);
      if (filterExistsFlag) {
        newState = state.map((filterRow) =>
          filterRow.id !== filterId ? filterRow : { ...filterRow, filterType }
        );
      } else {
        newFilterObject = {
          id: filterId,
          metadata: filterMetadata,
          values: [],
          filterType,
          advancedFilters: [],
          valid: false,
        };
        newState = [...state, newFilterObject];
      }

      //Validate filters
      newState = newState.map((filterRow) => ({
        ...filterRow,
        valid: validateFilterRow(filterRow),
      }));
      return newState;

    case C.ADD_FILTER_VALUES:
      //check if the filter object exists
      filterExistsFlag = state.find((filterRow) => filterRow.id === filterId);
      if (filterExistsFlag) {
        newState = state.map((filterRow) =>
          filterRow.id !== filterId
            ? filterRow
            : {
                ...filterRow,
                values: removeDupFromArray([
                  ...filterRow.values,
                  ...filterValues,
                ]),
              }
        );
      } else {
        newFilterObject = {
          id: filterId,
          metadata: filterMetadata,
          values: removeDupFromArray(filterValues),
          filterType: "include",
          advancedFilters: [],
          valid: true,
        };
        newState = [...state, newFilterObject];
      }

      //Validate filters
      newState = newState.map((filterRow) => ({
        ...filterRow,
        valid: validateFilterRow(filterRow),
      }));

      return newState;

    case C.REMOVE_FILTER_VALUES:
      newState = state.map((filterRow) =>
        filterRow.id !== filterId
          ? filterRow
          : {
              ...filterRow,
              values: removeDupFromArray(
                filterRow.values.filter(
                  (filterValue) => !filterValues.includes(filterValue)
                )
              ),
            }
      );
      //Validate filters
      newState = newState.map((filterRow) => ({
        ...filterRow,
        valid: validateFilterRow(filterRow),
      }));
      return newState;

    case C.REPLACE_ALL_ADVANCED_FILTERS:
      //check if the filter object exists
      filterExistsFlag = state.find((filterRow) => filterRow.id === filterId);
      if (filterExistsFlag) {
        newState = state.map((filterRow) =>
          filterRow.id !== filterId
            ? filterRow
            : {
                ...filterRow,
                advancedFilters: newAdvancedFilters,
              }
        );
      } else {
        newFilterObject = {
          id: filterId,
          metadata: filterMetadata,
          values: [],
          filterType: "include",
          advancedFilters: newAdvancedFilters,
        };
        newState = [...state, newFilterObject];
      }

      //Validate filters
      newState = newState.map((filterRow) => ({
        ...filterRow,
        valid: validateFilterRow(filterRow),
      }));

      return newState;

    default:
      return state;
  }
}
