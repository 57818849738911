//import required libraries
import React, { useContext, useEffect, useState } from "react";

//import library components
import { makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";

//import custom components
import SigviewSearchField from "../Common/SigviewSearchField";
import SigviewButton from "../Common/SigviewButton";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

//making styles for all variations
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
      justifyContent: "space-between",
      padding: customStyle.padding,
      height: "30px",
      boxSizing: "content-box !important",
      backgroundColor: themeColors["mainContentBgColor"],
    },
  }));
  return useStyles;
};

function AppMenuBar(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    onSearchFieldChange = () => {},
    buttonProps = {},
    placeholder = "Search...",
    style = {},
  } = props;
  const {
    handleClick: handleButtonClick = () => {},
    title: buttonTitle = "Click Me",
    flag: buttonFlag = true,
  } = buttonProps;
  const {
    padding = "10px 25px",
    wrapperBgColor = "#fff",
    wrapperBorderColor = "1px solid #fff",
    wrapperBorderHover = "#fff",
    wrapperBorderFocus = "#fff",
  } = style;

  // * Define required states
  const [searchField, setSearchField] = useState("");

  // * Define required event handlers
  const handleSearchFieldChange = (event, value) => setSearchField(value);

  // * Define required side effects
  useEffect(() => {
    onSearchFieldChange(null, searchField);
  }, [searchField]);

  // * Define required static variables
  const createChartStyle = { wrapperHeight: "100%", buttonHeight: "100%" };
  const customStyle = { padding };
  const useSigviewStyles = makeSigviewStyles(themeColors, customStyle);
  const classes = useSigviewStyles();
  var btnText = buttonTitle.replace(/ +/g, "");

  return (
    <section className={classes.root}>
      <Box css={{ width: "300px", position: "relative", top: "0px" }}>
        <SigviewSearchField
          placeholder={placeholder}
          onChange={handleSearchFieldChange}
          customClassName={`${
            buttonTitle === "Create Chart"
              ? "SearchInSavedChart-GA"
                ? buttonTitle === "Create New Group"
                : "CreateNewGroup-GA"
                ? buttonTitle === "Create New User"
                : "CreateNewUser-GA"
              : "SearchInSavedDatastory-GA"
          }`}
          customStyle={{
            wrapperBgColor: wrapperBgColor,
            wrapperBorderHover: wrapperBorderHover,
            wrapperBorderFocus: wrapperBorderFocus,
            wrapperBorderColor: wrapperBorderColor,
          }}
        />
      </Box>
      {buttonFlag && (
        <SigviewButton
          variant="containedPrimary"
          onClick={handleButtonClick}
          title={buttonTitle}
          iconFlag={true}
          customClassName={`${btnText}-GA`}
          iconProps={{
            className: "material-icons-outlined",
            iconName: "add_box",
          }}
          style={createChartStyle}
        />
      )}
    </section>
  );
}

export default AppMenuBar;
