// Import required libraries
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

// Import custom components
import Tour from "../Tour/Tour";
import SigviewProgressBar from "../Common/SigviewProgressBar";
import SigviewSwitch from "../Common/SigviewSwitch";
import DialogChangePassword from "../DialogChangePassword/DialogChangePassword";

// Import styles
import "./UserInfo.scss";

// Import config
import { config } from "../../config/config";

// Import action creators
import { logout } from "../../redux/actions";

// Import utils
import tourData from "../../../assets/data/tourData.json";
import {
  capitalize,
  isLogoutVisible,
  isAdminVisible,
  isChangePasswordVisible,
  isPartnerAdminVisible,
  getSigviewUserType,
} from "../../utils/utils";
import { unwrapperEmail } from "../../utils/reportUtils";
import { updateUserScreen } from "../../redux/actions";

// Import APIs
import { updateAutoSaveStatus, signOut } from "../../services/api";
import { masterTrackGaEvent } from "../../services/ga";

const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root ": {
      backgroundColor: "transparent !important",
    },
  },
});

function UserInfo(props) {
  const { t } = useTranslation();
  const { user = {}, dispatch: ReduxDispatcher } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [tourRun, setTourRun] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [autosave, setAutosave] = useState({ status: "success", message: "" }); // Status is one of success/loading; Error handled by snackbar

  const sigviewUserType = getSigviewUserType();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    if (user.type.newUserType === "nonSigview") {
      localStorage.clear();
      const signOutPromise = signOut({});
      signOutPromise
        .then((responseData) => {
          ReduxDispatcher(logout());
        })
        .catch((error) => {
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log("ERROR ->", error);
          console.groupEnd();

          // Show Snackbar on failure
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: "Logout failed Please Try Again!",
          };
          ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
        });


      // ReduxDispatcher(logout());
      // history.push("/openxSignIn");
      // window.location.reload();
    } else {
      // Make API call to Logout
      const signOutPromise = signOut({});
      signOutPromise
        .then((responseData) => {
          if (responseData.status.statusCode === "200") {
            masterTrackGaEvent({
              category: "SignOut",
              label: "SignOut Successfully",
            });
            ReduxDispatcher(logout());
          }
        })
        .catch((error) => {
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log("ERROR ->", error);
          console.groupEnd();

          // Show Snackbar on failure
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: "Logout failed Please Try Again!",
          };
          ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
        });

      // ReduxDispatcher(logout());
    }
  };

  const handleChangePassword = () => {
    handleClose();
    const payload = {
      open: true,
      children: (
        <DialogChangePassword user={user} ReduxDispatcher={ReduxDispatcher} />
      ),
    };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleNavigateToAdmin = () => {
    // * NEW IMPLEMENTATION
    history.push("/admin");

    // ! OLD IMPLEMENTATION
    // const activeTab = "home";
    // const activeNav = "admin";
    // const value = { activeNav, activeTab };
    // const action = updateUserScreen(null, value);
    // ReduxDispatcher(action);
  };
  const handleNavigateToManageBucket = () => {
    // * NEW IMPLEMENTATION
    history.push("/manageBucket");

    // ! OLD IMPLEMENTATION
    // const activeTab = "home";
    // const activeNav = "admin";
    // const value = { activeNav, activeTab };
    // const action = updateUserScreen(null, value);
    // ReduxDispatcher(action);
  };
  const handleAutosaveChange = (e, value) => {
    // Update local state to show prgress bar
    setAutosave({ status: "loading", message: "" });

    // Make API call to update status
    const fetchProps = {
      payload: {
        autoSaveStatus: value,
        email: user?.reqMetadata?.email,
      },
    };
    const updateAutoSaveStatusPromise = updateAutoSaveStatus(fetchProps);
    updateAutoSaveStatusPromise
      .then(() => {
        // Update local state to hide prgress bar
        setAutosave({ status: "success", message: "" });

        // Update redux state on success
        const action = updateUserScreen("workspaceAutoSaveStatus", value);
        ReduxDispatcher(action);
      })
      .catch((error) => {
        console.error("UI ERROR");
        console.groupCollapsed("DETAILS");
        console.log("ERROR ->", error);
        console.groupEnd();

        // Update local state to hide prgress bar
        setAutosave({ status: "success", message: "" }); // ! Error handled by snackbar

        // Show Snackbar on failure
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Updating autosave failed!",
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const tourStepsData =
    tourData[user.screen.activeTab]?.map((row) => ({
      ...row,
      placement: "auto",
    })) || [];
  const userEmailFirstChar = user?.reqMetadata?.email?.slice(0, 1) || "";
  const userEmailWithInstance = user?.reqMetadata?.email || "";
  const userInstance = user?.auth?.data?.instances || "";
  const userEmail = capitalize(
    unwrapperEmail(userEmailWithInstance, userInstance)
  );
  const visibilityList = {
    admin: isAdminVisible(user),
    partnerAdmin: isPartnerAdminVisible(user),
    logout: isLogoutVisible(user),
    changePassword: isChangePasswordVisible(user),
  };

  return (
    <>
      {/* <div
        className={`user-info disabled`}
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <i
          className={`material-icons person-icon`}
          title={user.reqMetadata.email}
        >
          person
        </i>
        <p className={`user-email`} title={user.reqMetadata.email}>
          {user.reqMetadata.email}
        </p>
        <i className={`material-icons settings-icon`} title="Settings">
          settings
        </i>
        <i className={`material-icons drop-down-icon`} title="Settings">
          arrow_drop_down
        </i>
      </div> */}
      <div
        className="user-info-ball"
        onClick={(e) => {
          handleClick(e);
        }}
      >
        {userEmailFirstChar}
      </div>
      {/* {tourRun && (
        <Tour
          steps={tourStepsData}
          tourRun={tourRun}
          setTourRun={setTourRun}
          autoStart={true}
        />
      )} */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.root}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className={`user-info-pop-up-container`}>
          <div className="user-details-container">
            <div className="user-info-ball large" title={userEmail}>
              {userEmailFirstChar}
            </div>
            <div className="user-email" title={userEmail}>
              {userEmail}
            </div>
          </div>
          {/* <div
            className={`pop-up-item-container`}
            onClick={() => {
              setTourRun(true);
              handleClose();
            }}
          >
            <p className={`pop-up-item-title`}>
              {config.hardCoded.allTitlesMapping[user.screen.activeTab]} Tour
            </p>
            <i className={`pop-up-item-icon material-icons hidden`}>save</i>
          </div> */}
          <div className={`pop-up-item-container`}>
            <p className={`pop-up-item-title`}>{t("Autosave")}</p>
            <i className={`pop-up-item-icon autosave-row`}>
              {autosave.status === "success" && (
                <SigviewSwitch
                  checked={user.screen.workspaceAutoSaveStatus}
                  onChange={handleAutosaveChange}
                  customClassName="workspaceAutoSaveON-OFF-GA"
                />
              )}
              {autosave.status === "loading" && <SigviewProgressBar />}
            </i>
          </div>
          {/* TODO To be visible only when user is admin */}
          {/* {isAdmin && (
            <div
              className={`pop-up-item-container`}
              onClick={handleNavigateToAdmin}
            >
              <p className={`pop-up-item-title`}>Admin</p>
              <i className={`pop-up-item-icon material-icons hidden`}>save</i>
            </div>
          )} */}
          {visibilityList.changePassword && (
            <div
              className={`pop-up-item-container ChangePasswordClicked-GA`}
              onClick={handleChangePassword}
            >
              <p className={`pop-up-item-title ChangePasswordClicked-GA`}>
                {t("Change Password")}
              </p>
            </div>
          )}

          {visibilityList.admin && (
            <div
              className={`pop-up-item-container AdminClick-GA`}
              onClick={handleNavigateToAdmin}
            >
              <p className={`pop-up-item-title  AdminClick-GA`}>{t("Admin")}</p>
              <i className={`pop-up-item-icon material-icons hidden`}>save</i>
            </div>
          )}
          {(visibilityList.partnerAdmin || visibilityList.admin) &&
            sigviewUserType === "nonSigview" && (
              <div
                className={`pop-up-item-container StorageManagerClick-GA`}
                onClick={handleNavigateToManageBucket}
              >
                <p className={`pop-up-item-title StorageManagerClick-GA`}>
                  {t("Storage Manager")}
                </p>
                <i className={`pop-up-item-icon material-icons hidden`}>save</i>
              </div>
            )}

          {visibilityList.logout && (
            <div
              className={`pop-up-item-container LogoutClick-GA`}
              onClick={handleLogout}
            >
              <p className={`pop-up-item-title LogoutClick-GA`}>
                {t("Logout")}
              </p>
              <i className={`pop-up-item-icon material-icons hidden`}>save</i>
            </div>
          )}
        </div>
      </Popover>
    </>
  );
}

UserInfo.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(UserInfo);
