// Import required libraries
import React, { useEffect } from "react";
import axios from "axios";
import { v4 } from "uuid";

// Import custom components
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import Loader from "../Loader/Loader";

// Import styles

// Import config
import { config } from "../../config/config";

// Import action creators
import { removeFilterValues, addFilterValues } from "../../redux/actions";

// Import API functions
import { getDimensionValues, getData } from "../../services/api";

// Import utils & data
import {
  getDimensionObjByOriginalID,
  getMetricTypeForChartObject,
} from "../../utils/plotlyUtils";
import {
  getFinalDimensionValuesData,
  isApiGetDimensionValues,
} from "../../utils/filtersUtils";

function DimensionValues(props) {
  const {
    user,
    globalFilters,
    activeDimension,
    searchKeyWord,
    setSearchKeyWord,
    filtersDimData = [],
    filtersMetricsData = [],
    dimensionData,
    setDimensionData,
    dimensionDataReload,
    setDimensionDataReload,
    initialDimensionData,
    globalFiltersStore,
    dispatchGlobalFiltersStore,
    localTimeFilters,
    isVisible,
    selections = {},
  } = props;
  const {
    activeView = user?.reqMetadata?.view,
    activeOrg = user?.reqMetadata?.organization,
  } = selections;

  //Re-order and format dimensionData values
  const activeFilterObj = globalFiltersStore.find(
    (filterRow) => filterRow.id === activeDimension?._id
  );
  const finalDimensionData = getFinalDimensionValuesData(
    dimensionData,
    activeFilterObj
  );

  //Fetch Dimension values data API call
  useEffect(() => {
    //For cancelling the query if the activeDimension or searchKeyWord Cahnges
    const source = axios.CancelToken.source();
    //Do anything if activeDimension is defined
    if (activeDimension) {
      //Based on business logic, make the necessary calls
      // ! HARD CODED
      // ! Elastic search was too costly for this dimension, hence using getData instead of getDimensionValues
      const isApiGetDimensionValuesFlag = isApiGetDimensionValues({
        activeView: activeView,
        activeDimension,
      });
      if (isApiGetDimensionValuesFlag) {
        //Set Dimension data to default
        setDimensionData(initialDimensionData);

        //Make the required payload for fetching dimension values
        const payload = {
          orgViewReq: {
            organization: activeOrg,
            view: activeView,
          },
          requestParam: {
            searchKeyWord: searchKeyWord,
            dimSearchType: config.hardCoded.dimensionValuesDimSearchType,
            dimensionIdList: [activeDimension._id],
            limit: config.hardCoded.getDimensionValuesDataLimit,
          },
        };

        //Make fetch call using axios
        const fetchProps = {
          payload,
          cancelToken: source.token,
        };
        const getDimensionValuesPromise = getDimensionValues(fetchProps);
        getDimensionValuesPromise
          .then((responseDate) =>
            setDimensionData({
              status: "success",
              message: "",
              data: responseDate?.result?.data?.[0]?.values,
            })
          )
          .catch((json) => {
            if (json.error !== config.hardCoded.queryCancelled) {
              console.groupCollapsed("UI ERROR");
              console.log("ERROR ->", json.error);
              console.groupEnd();
              setDimensionData({
                status: "error",
                message: json.error || config.hardCoded.uiErrorMessage,
                data: [],
              });
            }
          });
      } else {
        //Set Dimension data to default
        setDimensionData(initialDimensionData);

        //Add first available metric in yAxis or specialCalculation
        //Add orderBy as well
        //Doing it for the error in real-time-cpr
        //Should be a backend fix
        let yAxis = [],
          specialCalculation = [],
          approxCountDistinct = [];
        const currPlotlyMetrics = [...filtersMetricsData];
        const firstMetric = currPlotlyMetrics[0];
        const firstMetricType = getMetricTypeForChartObject(firstMetric);
        const firstMetricId = firstMetric._id;
        if (firstMetricType === "base_sum") {
          yAxis.push(firstMetricId);
        } else if (firstMetricType === "custom") {
          specialCalculation.push(firstMetricId);
        } else if (firstMetricType === "base_approxCountDistinct") {
          approxCountDistinct.push(firstMetricId);
        }
        const orderByObject = {
          dimOrdByList: [
            {
              desc: false,
              id: activeDimension?._id,
            },
          ],
        };

        // firstMetricType === "base_sum" ||
        // firstMetricType === "base_approxCountDistinct"
        //   ? { metricOrdByList: [{ id: firstMetricId, desc: true }] }
        //   : { customMetricOrdByList: [{ id: firstMetricId, desc: true }] };

        //If the user has typed a search string, add it as a filter object in the payload
        const filter = searchKeyWord
          ? [
              {
                filterType: "regex",
                values: [searchKeyWord],
                id: activeDimension?._id,
              },
            ]
          : [];
        //Make the required payload for fetching dimension values
        const payload = {
          _id: v4(),
          emailId: user?.reqMetadata?.email,
          orgViewReq: {
            organization: activeOrg,
            view: activeView,
          },
          chartObject: {
            metadata: {
              title: "",
              img_thumbnail: "dimensionClick",
              chartType: "table",
              dataLimit: config.hardCoded.getDimensionValuesDataLimit,
            },
            requestParam: {
              granularity: globalFilters?.metricChartGranularity?.value,
              timeZone: {
                name: localTimeFilters?.selectedTimezone?.name,
                location: localTimeFilters?.selectedTimezone?.location,
              },
              dateRange: {
                startDate:
                  localTimeFilters?.selectedDatesQE?.startDate?.toString(),
                endDate: localTimeFilters?.selectedDatesQE?.endDate?.toString(),
              },
              xAxis: [activeDimension?._id],
              yAxis,
              specialCalculation,
              approxCountDistinct,
              filter: filter,
              orderBy: orderByObject,
              percentCalList: [],
            },
          },
        };

        const fetchProps = {
          payload,
          cancelToken: source.token,
        };
        const getDataPromise = getData(fetchProps);
        getDataPromise
          .then((responseDate) => {
            let data = responseDate?.result?.data;
            let dimObject = getDimensionObjByOriginalID(
              activeDimension?._id,
              filtersDimData
            );
            let accessor = dimObject["dimID"];
            data = data.map((row) => row[accessor]);
            setDimensionData({
              status: "success",
              message: "",
              data,
            });
          })
          .catch((json) => {
            if (json.error !== config.hardCoded.queryCancelled) {
              console.groupCollapsed("UI ERROR");
              console.log("ERROR ->", json.error);
              console.groupEnd();
              setDimensionData({
                status: "error",
                message: json.error || config.hardCoded.uiErrorMessage,
                data: [],
              });
            }
          });
      }
    }

    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
    };
  }, [activeDimension, dimensionDataReload]);

  //DEBUGGING
  // console.groupCollapsed("DIMENSION VALUES.js");
  // console.log("dimensionData", dimensionData);
  // console.groupEnd();

  return (
    <>
      {isVisible && (
        <>
          {dimensionData.status === "error" && (
            <ErrorHandler
              message={dimensionData.message}
              reloadFlag={true}
              onReload={() => {
                setSearchKeyWord("");
                setDimensionDataReload(!dimensionDataReload);
              }}
            />
          )}
          {dimensionData.status === "loading" && <Loader />}
          {dimensionData.status === "success" && (
            <>
              {dimensionData.data.length > 0 ? (
                finalDimensionData.allValues.map((value) => (
                  <DimensionValueRow
                    key={value}
                    selectedValues={finalDimensionData.selectedValues}
                    value={value}
                    activeDimension={activeDimension}
                    dispatchGlobalFiltersStore={dispatchGlobalFiltersStore}
                  />
                ))
              ) : (
                <ErrorHandler
                  message="No data available"
                  reloadFlag={true}
                  onReload={() => {
                    setSearchKeyWord("");
                    setDimensionDataReload(!dimensionDataReload);
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default DimensionValues;

function DimensionValueRow(props) {
  const { selectedValues, value, activeDimension, dispatchGlobalFiltersStore } =
    props;

  const checkedFlag = selectedValues.includes(value);
  const handleClick = () => {
    const payload = {
      filterId: activeDimension._id,
      filterMetadata: activeDimension,
      filterValues: [value],
    };
    if (checkedFlag) {
      dispatchGlobalFiltersStore(removeFilterValues(payload));
    } else {
      dispatchGlobalFiltersStore(addFilterValues(payload));
    }
  };

  return (
    <span
      key={value}
      className={`dimension-value-item-container ${
        checkedFlag ? "selected" : ""
      }`}
      onClick={handleClick}
    >
      <i
        className={`material-icons dimension-value-item-icon dimension-value-selected-GA`}
      >
        {checkedFlag ? "check_box" : "check_box_outline_blank"}
      </i>
      <p className="dimension-value-item-title" title={value}>
        {value}
      </p>
    </span>
  );
}
