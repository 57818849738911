// Import required libraies
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { v4 } from "uuid";
import isEqual from "lodash.isequal";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

// * Import 3rd party lib
import { Box } from "@material-ui/core";

// Import Custom Component
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import AppMenuBar from "../../../components/AppMenuBar";
import AppRow from "../../../components/AppRow";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";

// Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// Import action creators
import {
  updateStandaloneChartForm,
  updateUserScreen,
  replaceTimeFilters,
  replaceAllDimensionFilters,
} from "../../../redux/actions";

// Import utils/data
import { config } from "../../../config/config";
import staticChartJson from "../../../../assets/data/chartTitle.json";
import { unwrapperChartObject } from "../../../utils/chartObjectUtils";
import {
  unwrapperAppRow,
  convertChartResToDsRes,
  getBreadcrumbsDataFromRoute,
} from "../../../utils/utils";
import { masterTrackGaEvent } from "../../../services/ga";

// Import APIs
import { getChartObjectList } from "../../../services/api";

// Import reducers
import standaloneChartReducer from "../../../redux/reducers/standaloneChart";

//Defining static variables
const initialData = { status: "loading", message: "", data: [] };

function ChartsHome(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    dispatch: ReduxDispatcher,
    user,
    allData,
    currTab,
    globalFilters = {},
  } = props;

  // * Define required hooks
  const history = useHistory();

  //Defining required state
  const [chartList, setChartList] = useState(initialData);
  const [reloadData, setReloadData] = useState(false);
  const [searchField, setSearchField] = useState("");

  //Defining required useEffects
  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
    };
    const getChartObjectListPromise = getChartObjectList(fetchProps);
    getChartObjectListPromise
      .then((responseData) => {
        let newData = {
          status: "success",
          message:
            responseData?.result?.data?.length || [].length !== 0
              ? ""
              : "No charts to display",
          data: config.hardCoded.convertAllTablesToMultiTable(
            responseData?.result?.data || []
          ),
        };
        setChartList(newData);
      })
      .catch((json) => {
        if (json.error !== config.hardCoded.queryCancelled) {
          let newData = { status: "error", message: json.error, data: [] };
          setChartList(newData);
          console.groupCollapsed("UI ERROR");
          console.log("ERROR ->", json.error);
          console.groupEnd();
        }
      });

    return () => {
      setChartList(initialData);
      //Cancel all previous fetch calls
      if (source) source.cancel();
    };
  }, [reloadData]);

  //Defining required change handlers
  const handleCreateChartClick = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryCategory",
      action: "CreateNew",
      label: "Chart",
    });
    //Remove charts which are pivotx
    const filteredChartList = chartList.data.filter(
      (row) => row.chartObject.metadata.chartType !== "pivotx"
    );
    const newTitle = `Untitled Chart - ${filteredChartList.length + 1}`;
    // Update activeChart details with defaultState
    let initialStandaloneChartState = {
      id: `chart-object-${v4()}`,
      title: newTitle,
      dimensionsList: [],
      metricsList: [],
      orderById: "",
      orderBy: "desc",
      chartType: "bar",
      metricFilters: [],
      dimensionFilters: globalFilters.dimensionFilters,
      timeFilters: globalFilters.timeFilters,
      chartList: staticChartJson,
      elementType: "create", // create or update to help differentiate in PlotCreate,
      renderFlag: false, // to control rendering of the chart in the component
    };
    // Updating plot by calling the reducer
    // Adding required business logic
    const payload = { key: "chartType", value: "bar" };
    var action = updateStandaloneChartForm(payload);
    initialStandaloneChartState = standaloneChartReducer(
      initialStandaloneChartState,
      action
    );

    // Update activeChart in redux and screen so that user can be redirected to chart
    const value = {
      activeTab: "createChart",
      activeChart: initialStandaloneChartState,
    };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);
    history.push("/datastory/chart/create");
  };
  const handleReload = () => setReloadData((prevState) => !prevState);
  const handleSearchFieldChange = (event, value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryCategory",
      action: "Search",
      label: "SavedCharts",
    });
    setSearchField(value);
  };

  const handleChartItemClick = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryCategory",
      action: "Launch",
      label: "SavedCharts",
    });

    const { actualPayload: uiAppCardActualPayload } = value;
    const { chartItemPayload } = uiAppCardActualPayload;
    const { chartObjectUI } = chartItemPayload;

    // First update the global time filters because PlotCreate takes it from that
    // Dispatch only when they are unequal
    if (!isEqual(user.timeFilters, chartObjectUI.timeFilters)) {
      var action = replaceTimeFilters(chartObjectUI.timeFilters);
      ReduxDispatcher(action);
    }

    //Update dimension filters
    if (!isEqual(user.dimensionFilters, chartObjectUI.dimensionFilters)) {
      var payload = {
        newDimensionFilters: chartObjectUI.dimensionFilters,
      };
      var action = replaceTimeFilters(payload);
      ReduxDispatcher(replaceAllDimensionFilters(payload));
    }

    // Update activeChart in redux and screen so that user can be redirected to chart
    const userScreenValue = {
      activeTab: "createChart",
      activeChart: {
        ...chartObjectUI,
        elementType: "update",
        renderFlag: true,
      },
    };
    var action = updateUserScreen(null, userScreenValue);
    ReduxDispatcher(action);
    history.push("/datastory/chart/edit");
  };
  const handleGoHome = (user) => {
    history.push("/datastory");
  };
  const handleBreadcrumbChange = (event, value) => {
    const data = { activeNav: "datastory", activeTab: value };
    const action = updateUserScreen(null, data);
    ReduxDispatcher(action);
    history.push(value.path);
  };

  // Defining required variables
  // Remove charts which are pivotx
  let filteredSavedCharts = chartList.data.filter(
    (row) => row.chartObject.metadata.chartType !== "pivotx"
  );
  // Filter based on search value
  filteredSavedCharts = filteredSavedCharts.filter((row) =>
    row.chartObject.metadata.title
      .toLowerCase()
      .includes(searchField.toLowerCase())
  );
  // Conversion so that it can be dev to unwrapperChartObjec func
  filteredSavedCharts = filteredSavedCharts.map((row) => ({
    id: row._id,
    name: row.chartObject.metadata.title,
    payload: row,
    chartType: row.chartObject.metadata.chartType,
  }));
  // Adding chartObjectUI using uwrapperChartObject to filter out corrupt objects
  filteredSavedCharts = filteredSavedCharts.map((row) => ({
    ...row,
    chartObjectUI: unwrapperChartObject({
      allData: allData,
      user: user,
      payload: { ...row.payload },
    }),
  }));
  // Filter out corrupt objects
  filteredSavedCharts = filteredSavedCharts.filter(
    (row) => row.chartObjectUI.valid
  );
  // ! HARD CODED: Chart list ordering not present in the backend
  // ! Hence, doing it here
  filteredSavedCharts = [...filteredSavedCharts].reverse();
  filteredSavedCharts = filteredSavedCharts.map(convertChartResToDsRes);
  const savedChartsAppRowProps = {
    title: "Saved Charts",
    payload: {},
    data: filteredSavedCharts,
    noCardProps: {
      title: "No Charts Available",
      flag: true,
    },
    createNewCardFlag: false,
    singleRowFlag: false,
    titleRowFlag: false,
    buttonRowFlag: false,
    appCardTag: "chartWithIcon",
    onAppCardClick: (event, value) => {
      handleChartItemClick(value);
    },
  };
  const uiFriendlySavedCharts = unwrapperAppRow(savedChartsAppRowProps);
  const buttonProps = {
    handleClick: handleCreateChartClick,
    title: "Create Chart",
  };
  const appMenuBarProps = {
    buttonProps,
    onSearchFieldChange: handleSearchFieldChange,
  };
  const stickyStyles = {
    position: "sticky",
    top: "0px",
    zIndex: 1,
  };
  const breadcrumbData = getBreadcrumbsDataFromRoute(user, history);

  // Debugging
  // console.group("ChartsHome.js");
  // console.log("filteredChartList", filteredChartList);
  // console.groupEnd();
  const pivotNoImagePosition = {
    height: "calc(100vh - 123px)",
  };
  const additionalSidenavItems = [
    {
      id: "home",
      name: "Home",
      tooltip: "Go back to Datastory home",
      elementId: "dsBackToHome",
      iconId: "custom-home-icon",
      customClass: "sidenav-home",
      onClick: (user) => handleGoHome(user),
      isVisible: true,
    },
  ];

  const sidenavItemsOrderInfo = {
    home: 1,
    reports: 2,
    alerts: 3,
  };
  const tabName = "charts-home";
  const sidenavProps = { additionalSidenavItems, sidenavItemsOrderInfo, tabName };

  return (
    <>
      <LayoutTopSideBottom sidenavProps={sidenavProps}>
        <SigviewBreadcrumb
          data={breadcrumbData}
          onClick={handleBreadcrumbChange}
        />
        {/* THIS WRAPPER BOX IS FOR STICKY PURPOSES ONLY */}
        <Box>
          <Box css={stickyStyles}>
            <AppMenuBar {...appMenuBarProps} />
          </Box>
          <Box
            className="datastory-cat-content-wrapper"
            css={pivotNoImagePosition}
          >
            {chartList.status === "loading" && <Loader />}
            {chartList.status === "error" && (
              <ErrorHandler
                message={chartList.message}
                reloadFlag={true}
                onReload={handleReload}
              />
            )}
            {chartList.status === "success" &&
              filteredSavedCharts.length === 0 && (
                <div className="no-data-container">
                  <div className="no-data-image"></div>
                  <p className="no-data">{t(config.messages.noCharts)}</p>
                </div>
              )}
            {chartList.status === "success" &&
              filteredSavedCharts.length !== 0 && (
                <AppRow {...uiFriendlySavedCharts} />
              )}
          </Box>
        </Box>
      </LayoutTopSideBottom>
      {/* <SigviewCommon /> */}
    </>
  );
}

ChartsHome.propTypes = {
  location: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  currTab: state.user.screen.activeTab,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(ChartsHome);
