// * Import required libraies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";

// * Import Custom Component
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler";
import Loader from "../../components/Loader/Loader";
import Routes from "../../routes/Routes";

// * Import utils/data
import SIGVIEW_CONTANTS from "../../constants/sigviewConstants";

function AppLoader(props) {
  const { user, allData } = props;

  // * Define required hooks
  const history = useHistory();

  // * Define required states
  const [pageStatus, setPageStatus] = useState(
    SIGVIEW_CONTANTS.initialPageStatus
  );

  useEffect(() => {
    const runFlag =
      user.timeFilters.isLoading === false &&
      allData.plotlyMetrics !== null &&
      allData.plotlyDimensions !== null &&
      user.reqMetadata !== null;
    if (runFlag) {
      setPageStatus({
        status: "success",
        message: "",
      });
    } else {
      setPageStatus({
        status: "loading",
        message: "",
      });
    }
  }, [
    user.timeFilters.isLoading,
    allData.plotlyMetrics,
    allData.plotlyDimensions,
    user.reqMetadata,
  ]);

  return (
    <>
      {pageStatus.status === "loading" && (
        <LayoutTopSideBottom>
          <Loader />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "error" && (
        <LayoutTopSideBottom>
          <ErrorHandler
            message={pageStatus.message}
            reloadFlag={true}
            onReload={() => {
              window.reload();
            }}
          />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "success" && <Routes />}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(AppLoader);
