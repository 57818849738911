const sigviewTheme = {
  palette: {
    type: "light",
    primary: {
      main: "#267ee6", //REFER: https://docusaurus.io/docs/styling-layout
      light: "#6ea9ee", //-30
      lighter: "#b6d4f7", //-60
      lightest: "#e6f0fc", //-80
    },
    secondary: {
      main: "#46596A", //REFER: https://docusaurus.io/docs/styling-layout
      light: "#607282", //-70
      lighter: "#c5cfd9", //-135
      lightest: "#eff2f5", //-175
    },
    misc: {
      //PRIMARY
      primaryColor: "#267EE6", //REFER: https://docusaurus.io/docs/styling-layout
      primaryColorLight: "#6ea9ee", //-30
      primaryColorLighter: "#b6d4f7", //-60
      primaryColorLightest: "#e6f0fc", //-80

      //SECONDARY
      secondaryColor: "#46596A", //REFER: https://docusaurus.io/docs/styling-layout
      secondaryColorLight: "#607282", //-70
      secondaryColorLighter: "#c5cfd9", //-135
      secondaryColorLightest: "#eff2f5", //-175

      //HEADER
      headerBgColor: "#ffffff",

      //USER INFO
      userInfoBgColor: "#ffffff",

      //SIDENAV
      sidenavBgColor: "#ffffff",
      sidenavItemColor: "#46596a",
      sidenavItemHoverColor: "#F8F9FD",

      //MAIN CONTENT
      //mainContentBgColor: "#f8f9fd", Older
      mainContentBgColor: "#f4f7ff",

      //DATASTORY
      dsItemBgColor: "#ffffff",
      dsItemBoxShadow: "0px 0px 7px 0px #eff2ff",

      //TIME FILTERS
      timeFiltersBgColor: "#ffffff",
      timeFiltersBoxShadow: "0px 0px 7px 0px #eff2ff",

      //BUTTONS
      buttonBgColor: "#46596a",
      buttonBgColorLight: "#607282",
      buttonBgColorLighter: "#c5cfd9",
      buttonBgColorLightest: "#eff2f5",
      buttonColor: "#ffffff",
      buttonColorLight: "#46596a",
      buttonColorLighter: "#46596a",
      buttonColorLightest: "#46596a",
      buttonColorDark: "#eff2f5",
      buttonColorDarker: "#c5cfd9",
      buttonColorDarkest: "#607282",
      // buttonDisabledBgColor: "#c5cfd9",
      buttonDisabledBgColor: "#e0e0e0",
      buttonDisabledColor: "#8b8b8b",

      //RADIO
      radioBgColor: "#ffffff",
      radioColor: "#267ee6",
      radioDisabledBgColor: "#ffffff",
      radioDisabledColor: "#e0e0e0",

      //CHECKBOX
      checkboxColor: "#267ee6",

      //METRICS
      negChangeColor: "#ff4b5c",
      posChangeColor: "#1eae98",

      //OTHERS
      popoverBgColor: "#ffffff",
      failureColor: "#f44336",
      overlayBgColor: "rgba(0, 0, 0, 0.5)",
      overlayBgColorLight: "rgba(0, 0, 0, 0.1)",
    },
    text: {
      primary: "#46596A",
      secondary: "#267ee6",
    },
  },
  typography: {
    fontFamily: "Fira Sans",
    caption: { fontSize: "10px", width: "max-content" },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: "small",
    },
    MuiTooltip: {
      arrow: false,
    },
  },
  spacing: 8,
  custom: {
    layouts: {
      mainScreen: {
        advertisementWrapper: { height: "2rem" },
        headerWrapper: { height: "3.75rem" },
        footerWrapper: { height: "1.25rem" },
      },
    },
    components: { sidenav: { width: 240 } },
  },
};

export default sigviewTheme;
