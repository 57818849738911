// * Import required libraies
import React, { useContext } from "react";
import { Box } from "@material-ui/core";

// * Import lib
import { makeStyles } from "@material-ui/core";

// * Import Custom Component
import SigviewIcon from "../../Common/SigviewIcon";
import SigviewTypography from "../../Common/SigviewTypography";
import SigviewTooltip from "../../Common/SigviewTooltip";
import Loader from "../../Loader/Loader";

// * Import utils

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Define required functions
const makeSigviewStyle = (themeColors, style) => {
  //   const {} = style;
  const useStyles = makeStyles({
    headerRoot: {
      // padding: "5px 10px",
      padding: "0px 7px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: "31px",
      boxSizing: "border-box",
    },
    headerLeftContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexGrow: 1,
      flexBasis: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    headerValueContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // flexGrow: 1,
      // flexBasis: 0,
      flexShrink: 0,
    },
    headerTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // flexGrow: 1,
      // flexBasis: 0,
      flexShrink: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    headerInfoContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexShrink: 0,
    },
    headerCompareContainer: {
      display: "flex",
      justifyContent: "space-between",
      justifyContent: "flex-start",
      alignItems: "center",
      // flexShrink: 0,
      flexGrow: 1,
      flexBasis: 0,
    },
    headerRightContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      flexShrink: 0,
      width: "max-content",
    },
    headerCustomIcon: {
      cursor: "pointer",
      padding: "0px 5px",
      color: `${themeColors["secondaryColorLight"]} !important`,
      "&:hover": {
        color: `${themeColors["primaryColor"]} !important`,
      },
    },
  });
  return useStyles;
};

function MetricChartExpandedHeader(props) {
  // * Destructure props
  const {
    user,
    handleDownload = () => {},
    metricChartHeaderDetails = {},
    metricCounterData = {},
  } = props;

  // * Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define required styles
  const useSigviewStyles = makeSigviewStyle(themeColors, {});
  const classes = useSigviewStyles();
  const tickerIconStyle = {
    fontSize: "20px",
    color: metricChartHeaderDetails["tickerColor"] + " !important",
  };
  const tickerStyle = {
    color: metricChartHeaderDetails["tickerColor"],
    height: "max-content",
  };
  const valueStyle = {
    height: "max-content",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: themeColors["primaryColor"],
    textTransform: "uppercase",
  };
  const isDownloadAvailable = user.uiFeatureList.download;
  const {
    value = "72.5M",
    totalValueStatus = "loading",
    tickerChange = "",
    tickerIconName,
    showTickerChange,
    tickerStatus,
    formattedValue,
  } = metricChartHeaderDetails;

  return (
    <>
      <Box className={classes.headerRoot}>
        <Box className={classes.headerLeftContainer}>
          <Box className={classes.headerValueContainer}>
            {totalValueStatus === "loading" && <Loader />}
            {totalValueStatus === "success" && (
              <SigviewTooltip title={formattedValue} placement="bottom-start">
                <Box>
                  <SigviewTypography variant="pLarge" style={valueStyle}>
                    {value}
                  </SigviewTypography>
                </Box>
              </SigviewTooltip>
            )}
          </Box>
          <Box className={classes.headerTitleContainer}></Box>
          <Box className={classes.headerInfoContainer}></Box>
          <Box className={classes.headerCompareContainer}>
            {showTickerChange && tickerStatus && (
              <>
                <SigviewIcon
                  className="material-icons-round"
                  iconName={tickerIconName}
                  style={tickerIconStyle}
                />
                <SigviewTypography style={tickerStyle} variant="pSmall">
                  {tickerChange}
                </SigviewTypography>
              </>
            )}
          </Box>
        </Box>
        <Box className={classes.headerRightContainer}>
          {isDownloadAvailable && (
            <SigviewTooltip title={"Download"} placement="bottom">
              <span>
                <SigviewIcon
                  className={`material-icons-round ${classes.headerCustomIcon} analyzeChartExpandFileDownload-GA`}
                  iconName="file_download"
                  onClick={() => handleDownload("expanded")}
                  disabled={
                    metricCounterData.status === "loading" ? true : false
                  }
                />
              </span>
            </SigviewTooltip>
          )}
        </Box>
      </Box>
    </>
  );
}
export default MetricChartExpandedHeader;
