//Import required libraies
import React, { useState, useRef, useContext } from "react";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

// Import Custom Component

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import action creators

// Import utils

// Import styles

function SigviewHoverPopup(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const { children, title, data, customStyle } = props;
  const popupId = useRef(v4());
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? `sigviewHoverPopup_${popupId.current}` : undefined;

  // Define styles
  const useStyles = makeStyles(() => ({
    root: {
      zIndex: "999999999 !important",
      pointerEvents: "none",
      "& .MuiPaper-root": {
        backgroundColor: themeColors["popoverBgColor"],
      },
    },
    sigviewHoverPopupContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }));

  // Define hooks
  const classes = useStyles();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        className={classes.sigviewHoverPopupContainer}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </div>
      {open && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className={classes.root}
        >
          <div className="sigview-hover-popup-content-container">
            <p className="sigview-hover-popup-title">{t(title)}</p>
            <div className="sigview-hover-popup-content">
              <ol className="sigview-hover-popup-list-container">
                {data.map((sentence) => (
                  <li key={v4()} className="sigview-hover-popup-list-item">
                    {t(sentence)}
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </Popover>
      )}
    </>
  );
}

export default SigviewHoverPopup;
