// Import Constants
import C from "../constants";

import {
  getValidAdvanceSort,
  getValidDimensionSort,
  getValidCompareDimension,
} from "../../utils/pivotUtils";

const pivotForm = (state = {}, action = {}) => {
  const { type = "", payload = {} } = action;
  const { key = "", value = "", sortedOn = "", orderBy = "" } = payload;

  let newState = {};
  let newAdvanceSort = [];
  switch (type) {
    case C.UPDATE_PIVOT_FORM:
      switch (key) {
        case "dimensionsList":
          newState = {
            ...state,
            [key]: value,
          };
          const isCompare = getValidCompareDimension(newState);
          newState = {
            ...newState,
            timeFilters: {
              ...newState.timeFilters,
              isComparisonOn:
                newState.timeFilters.isComparisonOn === true
                  ? !isCompare
                  : newState.timeFilters.isComparisonOn,
            },
            renderFlag: false,
          };
          newAdvanceSort = getValidAdvanceSort(newState);
          newState = { ...newState, advanceSort: newAdvanceSort };
          return newState;
        case "metricsList":
        case "dimensionFilters":
        case "timeFilters":
        case "advanceSort":
        case "dataLimit":
          newState = {
            ...state,
            [key]: value,
            renderFlag: false,
          };
          newAdvanceSort = getValidAdvanceSort(newState);
          newState = { ...newState, advanceSort: newAdvanceSort };
          return newState;
        case "transpose":
          newState = {
            ...state,
            [key]: value,
          };
          if (value) {
            const newDimensionList = getValidDimensionSort(newState);
            newState = { ...newState, dimensionsList: newDimensionList };
            newAdvanceSort = getValidAdvanceSort(newState);
            newState = { ...newState, advanceSort: newAdvanceSort };
            return newState;
          } else return newState;

        case "elementType":
        case "title":
          newState = {
            ...state,
            [key]: value,
          };
          newAdvanceSort = getValidAdvanceSort(newState);
          newState = { ...newState, advanceSort: newAdvanceSort };
          return newState;
        case "renderFlag":
          newState = {
            ...state,
            [key]: value,
          };
          return newState;
        case "replacePivotForm":
          newState = { ...value };
          return newState;
        default:
          console.error("UI ERROR occured in pivotForm.js");
          console.groupCollapsed("Details Below");
          console.log("UNIDENTIFIED TYPE");
          console.log("key", key);
          console.log("REDUCER -> ", "pivotForm.js");
          console.log("state -> ", state);
          console.log("payload -> ", payload);
          console.groupEnd();
          return state;
      }
    case C.UPDATE_PIVOT_ADVANCE_SORT:
      newAdvanceSort = state.advanceSort.map((row) => ({
        ...row,
        sortedOn: sortedOn,
        sortType: "metric",
        desc: orderBy === "desc",
      }));
      newState = {
        ...state,
        advanceSort: newAdvanceSort,
        renderFlag: false,
      };
      return newState;

    case C.UPDATE_PIVOT_RELOADEPOCH:
      newState = {
        ...state,
        renderFlag: false,
        reloadRenderEpoch: Date.now(),
      };
      return newState;

    case C.UPDATE_PIVOT_MULTIPLE_FIELDS:
      newState = { ...state };
      for (const [fieldName, fieldValue] of Object.entries(value)) {
        newState = {
          ...newState,
          [fieldName]: fieldValue,
        };
      }
      // Change render flag only on change of dataLimit and not on progressiveDateFlag
      var newRenderFlag = state.dataLimit !== newState.dataLimit ? false : true;
      // Condition if the mericlist & DimensionList is empty then to prevent ui break renderFlag must be false
      if (
        newState.metricsList.length === 0 ||
        newState.dimensionsList.length === 0
      ) {
        newRenderFlag = false;
      }
      newAdvanceSort = getValidAdvanceSort(newState);
      newState = {
        ...newState,
        advanceSort: newAdvanceSort,
        renderFlag: newRenderFlag,
      };
      return newState;

    default:
      console.groupCollapsed("UI ERROR");
      console.log("UNIDENTIFIED TYPE");
      console.log("REDUCER -> ", "plot.js");
      console.log("state -> ", state);
      console.log("payload -> ", payload);
      console.groupEnd();
  }
};

export default pivotForm;
