// * Import required libraies
import React, { useRef, useContext, useMemo } from "react";
import { v4 } from "uuid";
import { useSelector } from "react-redux";
import { connect } from "react-redux";

// * Import lib components
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// * Import custom components
import MsvMetricChartHeader from "../../components/VizTypes/MsvMetricChart/MsvMetricChartHeader";
import MsvMetricChart from "../../components/VizTypes/MsvMetricChart/MsvMetricChart";
import Loader from "../../components/Loader/Loader";
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler";
import SigviewProgressBar from "../../components/Common/SigviewProgressBar";

// * Import redux utils
// Selectors
import {
  selectSelectedTableItemInChart,
  selectMsvColorMapping,
  selectMetricChartType,
  selecteMetricChartGranularity,
  selectSelectedTimezone,
  selectMetricObjectFromPlotlyMetrics,
  selectMetricChartYaxisRangeType,
  selectLoadingStatusForWsMetricChart,
  selectMetricAccessor,
  selectDataForSelectedTableItemInChart,
  selectErrorMessageForMsvMetricChart,
} from "../../redux/selectors/standaloneMsvSelectors";
// Actions
import { reloadStandaloneMsvAllMetricChartsData } from "../../redux/actions";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Import config
import { config } from "../../config/config";

// * Define required functions
const makeSigviewStyle = (themeColors, style) => {
  //   const {} = style;
  const useStyles = makeStyles({
    root: {
      height: "100%",
      overflow: "hidden",
      backgroundColor: "white",
      //border: "1px solid transparent",
      boxShadow: themeColors["dsItemBoxShadow"],
      "&:hover": {
        //border: `1px solid ${themeColors["secondaryColorLighter"]}`,
        "& $headerRightContainer": { display: "flex" },
      },
    },
    chartContainer: {
      height: "calc(100% - 31px - 5px)",
      width: "100%",
      // This padding is not correct here, if you really want that padding and want child not to overflow use the below width then.
      // padding: "0px 10px",
      // width: "calc(100% - 20px)",
      boxSizing: "border-box",
    },
    headerRoot: {
      // padding: "5px 10px",
      padding: "0px 7px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: "31px",
      boxSizing: "border-box",
    },
    headerLeftContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexGrow: 1,
      flexBasis: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      cursor: "all-scroll",
    },
    headerValueContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // flexGrow: 1,
      // flexBasis: 0,
      flexShrink: 0,
    },
    headerTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // flexGrow: 1,
      // flexBasis: 0,
      flexShrink: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    headerInfoContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexShrink: 0,
    },
    headerCompareContainer: {
      display: "flex",
      justifyContent: "space-between",
      justifyContent: "flex-start",
      alignItems: "center",
      // flexShrink: 0,
      flexGrow: 1,
      flexBasis: 0,
    },
    headerRightContainer: {
      display: "none",
      justifyContent: "flex-end",
      alignItems: "center",
      flexShrink: 0,
      width: "max-content",
    },
    headerCustomIcon: {
      cursor: "pointer",
      padding: "0px 5px",
      color: `${themeColors["secondaryColorLight"]} !important`,
      "&:hover": {
        color: `${themeColors["primaryColor"]} !important`,
      },
    },
    headerDeleteIcon: {
      "&:hover": {
        color: themeColors["negChangeColor"] + " !important",
      },
    },
    progressBarContainer: {
      height: "5px",
      width: "100%",
      padding: "3px 0px 0px 0px !important",
      boxSizing: "border-box",
    },
  });
  return useStyles;
};

function MsvMetricChartItem(props) {
  // * Destructure props
  const {
    // REDUX PROPS
    dispatch: ReduxDispatcher,
    // PARENT PROPS
    id,
  } = props;

  // * Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define required states
  const chartContainerId = useRef(`chartHolder-${v4()}`);

  // * Destructure REDUX props
  const chartType = useSelector(selectMetricChartType);
  const msvMetricChartLoadingStatus = useSelector((state) =>
    selectLoadingStatusForWsMetricChart(state, id)
  );
  const msvMetricChartErrorMessage = useSelector((state) =>
    selectErrorMessageForMsvMetricChart(state, id)
  );
  const accessor = useSelector((state) => selectMetricAccessor(state, id));
  const granularity = useSelector(selecteMetricChartGranularity);
  const selectedTimezone = useSelector(selectSelectedTimezone);
  const metricObj = useSelector((state) =>
    selectMetricObjectFromPlotlyMetrics(state, id)
  );
  const metricChartYaxisRangeType = useSelector(
    selectMetricChartYaxisRangeType
  );
  const msvColorMapping = useSelector(selectMsvColorMapping);
  const selectedTableItemInChart = useSelector(selectSelectedTableItemInChart);
  const dataForSelectedTableItemInChart = useSelector((state) =>
    selectDataForSelectedTableItemInChart(state, id)
  );

  // * Define ONLY ABSOLUTE NECEASSRY MEMOIZED STATES
  const childrenProps = {
    id,
    selectedTableItemInChart: selectedTableItemInChart,
    dataForSelectedTableItemInChart: dataForSelectedTableItemInChart,
    msvColorMapping: msvColorMapping,
    chartContainerId: chartContainerId.current,
    isMetricChartOpen: false,
    chartType: chartType,
    accessor: accessor,
    granularity: granularity,
    metricChartYaxisRangeType: metricChartYaxisRangeType,
    selectedTimezone: selectedTimezone,
    metricObj: metricObj,
    themeColors: themeColors,
    themeState: themeState,
  };
  const memoizedStringedChildrenProps = JSON.stringify(childrenProps);
  // ! COMMENTING OUT THE MEMOIZATION AS THE HOVER CHART TOOLTIP FUNC IS AFFECTING
  // ! FIX THAT BUG IF YOU WANT TO IMPLEMENT MEMOIZATION
  // const memoizedStringedChildrenProps = useMemo(
  //   () => JSON.stringify(childrenProps),
  //   [
  //     selectedTableItemInChart,
  //     dataForSelectedTableItemInChart,
  //     msvColorMapping,
  //     chartType,
  //     granularity,
  //     metricChartYaxisRangeType,
  //     selectedTimezone,
  //   ]
  // );

  // * Define required event handlers
  const handleReloadMetricCharts = () => {
    const action = reloadStandaloneMsvAllMetricChartsData();
    ReduxDispatcher(action);
  };

  // * Define required static variables
  const useSigviewStyles = makeSigviewStyle(themeColors, {});
  const classes = useSigviewStyles();
  const {
    fullLoading,
    fullSuccess,
    partialLoading,
    partialSuccess,
    // anyError, // TODO : Can be thought of how to show on the UI since all metric charts are driven by one API and shown at one place in the UI
    allError,
  } = msvMetricChartLoadingStatus;

  return (
    <Box className={classes.root}>
      <MsvMetricChartHeader
        id={id}
        classes={classes}
        metricChartProps={childrenProps}
      />

      <Box className={classes.chartContainer} id={chartContainerId.current}>
        {/* UI */}
        {!allError && fullLoading && <Loader />}
        {allError && (
          <ErrorHandler
            layout="metricChart"
            message={
              msvMetricChartErrorMessage || config.hardCoded.uiErrorMessage
            }
            reloadFlag={true}
            onReload={handleReloadMetricCharts}
          />
        )}
        {!allError && (fullSuccess || partialSuccess) && (
          <MsvMetricChart
            memoizedStringedChildrenProps={memoizedStringedChildrenProps}
          />
        )}
      </Box>
      <Box className={classes.progressBarContainer}>
        {!allError && partialLoading && !fullLoading && <SigviewProgressBar />}
      </Box>
    </Box>
  );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(MsvMetricChartItem);
