// * Import required libraries
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import axios from "axios";

// * Import custom components
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import AnalyzeDashboardCreate from "./AnalyzeDashboardCreate";

// * Import utils, config & static data
import { wrapperOrgViewReq } from "../../../utils/chartObjectUtils";
import { config } from "../../../config/config";

import { makeNewDefaultCreateWorksheet } from "../../../utils/analyzeServiceUtils";

// * Import redux utilities
// Action creators
import { updateUserScreen } from "../../../redux/actions";
// Initial state
import { initialActiveWorkspace } from "../../../redux/stateData";

// * Import API functions
import { getAllWorkbooks } from "../../../services/api";

// * Import required hooks
import useUpdateEffect from "../../../hooks/useUpdateEffect";

// * Define required static variables
const initialData = {
  status: "loading",
  message: "",
  result: [],
};
const initialPageStatus = {
  status: "loading",
  message: "",
};

function AnalyzeCreateContainer(props) {
  // * Destructure props
  const {
    dispatch: ReduxDispatcher = () => {},
    user = {},
    allData = {},
    changeFlag = {},
  } = props;
  const history = useHistory();
  const location = useLocation();

  // * Define required states
  const [isAnyWorkbookInRedux, setIsAnyworkbookInRedux] = useState(false);
  const [workbooks, setWorkbooks] = useState(initialData);
  const [reloadWorkbooks, setReloadWorkbooks] = useState(Date.now());
  const [reloadWorksheet, setReloadWorksheet] = useState(Date.now());
  const [pageStatus, setPageStatus] = useState(initialPageStatus);
  const [checkFlag, setCheckFlag] = useState(false);

  // * Define required side effects
  // ! Commenting this useEffect because the activeWorkspace become empty into this and create bug into analyze Dashboard
  // useEffect(() => {
  //   return () => {
  //     // Revert to inital state in redux when component unmounts
  //     // This is to cater to case when user switches between tabs
  //     const dashboardPath = location.pathname;
  //     if (dashboardPath !== "/analyze/dashboard") {
  //       console.log("unmount", window.location.pathname);
  //       const action = updateUserScreen(
  //         "activeWorkspace",
  //         initialActiveWorkspace
  //       );
  //       ReduxDispatcher(action);
  //       ReduxDispatcher(updateAnalyzeFiltersReadOnlyFromGlobalFiletrs());
  //       ReduxDispatcher(resetWorkspaceAndMsv());
  //     }
  //   };
  // }, []);

  useEffect(() => {
    return () => {
      // Revert to inital state in redux when component unmounts
      // This is to cater to case when user switches between tabs

      if (history.location.pathname !== "/analyze/dashboard") {
        const action = updateUserScreen(
          "activeWorkspace",
          initialActiveWorkspace
        );
        ReduxDispatcher(action);
      }
    };
  }, []);

  // to call the default api here
  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();

    let runFlag = !user.screen.isDashboardLoading;
    if (runFlag) {
      const fetchProps = {
        payload: {
          orgViewReq: wrapperOrgViewReq(user),
          email: user.reqMetadata?.email,
        },
        cancelToken: source.token,
      };
      const getAllWorkbooksPromise = getAllWorkbooks(fetchProps);
      getAllWorkbooksPromise
        .then((responseData) => {
          const newData = {
            status: "success",
            message: "",
            result: { workbookList: responseData.result.data },
          };
          setWorkbooks(newData);
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            console.groupCollapsed("UI ERROR");
            console.log("JSON -> ", json);
            console.log("ERROR -> ", json.error);
            console.groupEnd();

            const newData = {
              status: "error",
              message: "",
              result: json.error || config.messages.uiErrorMessage,
            };
            setWorkbooks(newData);
          }
        });
    }

    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      runFlag = false;
      if (source) source.cancel();
    };
  }, [user.screen.isDashboardLoading]);

  useEffect(() => {
    let runFlag = workbooks.status === "success";
    if (runFlag) {
      // * There are 2 cases here:

      // 2. Non-Shared Dashboard
      //    2.1 Saved Dashboard Load (from workspace drawer or home page if that's built later)
      //    2.2 Initial Dashboard Load

      // Run this only when there is no workbook in redux
      // Since this useEffect has a dependency on workbooks.status, it will run again when reloading the workbooks
      // In that case we need not run this case
      // This case is ONLY for initial application load

      // This flag will help us decide if we need to load default template
      var defaultTemplateFlag = true;
      var workspaceLoadErrorMessage = "";
      var activeWorkbook = workbooks.result.workbookList[0];

      // LOAD DEFAULT TEMPLATE
      if (defaultTemplateFlag) {
        // Update snackbar
        var snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Loading default profile!",
        };
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);

        // Call Function to make default worksheet
        const makeNewDefaultWorksheetProps = {
          user,
          allData,
          activeWorkbook,
          ReduxDispatcher,
          setIsAnyworkbookInRedux,
          setPageStatus,
          setReloadWorkbooks,
          setReloadWorksheet,
          setCheckFlag,
        };

        // CASE 1
        // 1. getGroupDefaultWorksheetPromise requires workbook
        // Else show an error message on the UI
        const canGetGroupDefaultWorksheetPromiseBeCalled = ![
          "Workbook API failed",
          "No workbook",
        ].includes(workspaceLoadErrorMessage);
        if (canGetGroupDefaultWorksheetPromiseBeCalled) {
          makeNewDefaultCreateWorksheet(makeNewDefaultWorksheetProps);
        } else {
          setPageStatus({
            status: "error",
            message:
              "No workbook could be loaded successfully! Please try again",
          });
        }
      }
    }
    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      runFlag = false;
    };
  }, [
    // user.screen.isDashboardLoading,
    workbooks.status,
    // user.screen.activeWorkspace.wsCategory, // ! INCORRECT : Re-trigger if user changes the active worksheet from AllWorkbooksDrawer
    // Re-trigger if user changes the active worksheet from AllWorkbooksDrawer
  ]);

  useEffect(() => {
    let runFlag =
      user.timeFilters.isLoading === false &&
      allData.plotlyMetrics !== null &&
      allData.plotlyDimensions !== null &&
      checkFlag;
    if (runFlag) {
      setPageStatus({
        status: "success",
        message: "",
      });
    } else {
      setPageStatus({
        status: "loading",
        message: "",
      });
    }

    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      runFlag = false;
    };
  }, [
    user.timeFilters.isLoading,
    allData.plotlyMetrics,
    allData.plotlyDimensions,
    isAnyWorkbookInRedux,
    checkFlag,
  ]);

  // reset to initialState if changeFlag.view changes
  // to cater to view/org change
  useUpdateEffect(() => {
    setIsAnyworkbookInRedux(false);
    setPageStatus(initialPageStatus);
    setWorkbooks(initialData);
    setCheckFlag(false);
  }, [changeFlag.view]);

  // * Define required static variables
  // const isWsReadyToLoad =
  //   user.timeFilters.isLoading === false &&
  //   allData.plotlyMetrics !== null &&
  //   allData.plotlyDimensions !== null &&
  //   isAnyWorkbookInRedux;
  const workbookProps = {
    workbooks,
    setReloadWorkbooks,
    setIsAnyworkbookInRedux,
    setReloadWorksheet,
    setPageStatus,
  };

  // * DEBUGGER
  console.group("AnalyzeDashboardCreate.js");
  console.log("pageStatus", pageStatus);
  console.log("isAnyWorkbookInRedux", isAnyWorkbookInRedux);
  console.groupEnd();

  return (
    <>
      {pageStatus.status === "loading" && (
        <LayoutTopSideBottom>
          <Loader />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "error" && (
        <LayoutTopSideBottom>
          <ErrorHandler
            message={pageStatus.message}
            reloadFlag={true}
            onReload={() => {
              window.reload();
            }}
          />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "success" && (
        <AnalyzeDashboardCreate workbookProps={workbookProps} />
      )}
    </>
  );
}

AnalyzeCreateContainer.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  changeFlag: state.changeFlag,
});

export default connect(mapStateToProps)(AnalyzeCreateContainer);
