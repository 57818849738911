// * Import required libraries
import React, {
  useContext,
  useEffect,
  useState,
  useReducer,
  memo,
  useMemo,
  useCallback,
} from "react";

// * Import library components
import { Box, makeStyles } from "@material-ui/core";
import { Drawer } from "@material-ui/core";

// * Import custom components
import SigviewSearchField from "../Common/SigviewSearchField";
import SigviewButton from "../Common/SigviewButton";
import SigviewTooltip from "../Common/SigviewTooltip";
import SigviewIcon from "../Common/SigviewIcon";
// import useReducerLogger from "../../utils/useReducerLogger";

// * Import action creators
import {
  addMetricChart,
  addMetricToTables,
  removeMetricChart,
  removeMetricFromTables,
  replaceMetricDrawerSelections,
} from "../../redux/actions";
import addMetricDrawerReducer from "../../redux/reducers/addMetricDrawer";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";
import SigviewTypography from "../Common/SigviewTypography";
import AddToDimTableIcon from "../Common/AddToDimTableIcon";
import AddToMetricChartIcon from "../Common/AddToMetricChartIcon";

// * Making styles for all variations
const makeSigviewStyles = (...args) => {
  const [themeColors] = args;
  const useStyles = makeStyles(() => ({
    leftDrawerStyles: {
      height: "100vh",
      // padding: "12px 0px",
      boxSizing: "border-box",
      width: "350px",
      backgroundColor: themeColors["sidenavBgColor"],
    },
    headerStyles: {
      height: "40px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0px 24px",
      boxSizing: "border-box",
      boxShadow: `0px 1px 1px -1px ${themeColors["secondaryColorLight"]}`,
      "&>.drawer-close-icon": {
        cursor: "pointer",
      },
    },
    searchContainer: { padding: "12px 24px 12px 24px" },
    bodyContainerStyles: {
      overflowY: "auto",
      overflowX: "hidden",
      padding: "0px 24px 12px 24px",
      height: "calc(100% - 40px - 40px - 54px)",
    },
    rowContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
    },
    rowTitleContainer: {
      display: "flex",
      alignItems: "center",
      width: "80%",
    },
    footerStyles: {
      height: "40px",
      padding: "0px 24px",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    iconStyles: {
      width: "18px",
      height: "18px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      cursor: "pointer",
      marginLeft: "8px",
      "&.disabled": {
        opacity: "0.5",
      },
    },
    bodyRowStyles: {
      display: "flex",
      padding: "2px 0px 2px 0px",
    },
  }));
  return useStyles;
};

function AddMetricDrawer(props = {}) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    data = [],
    user = {},
    initialSelections = { chartMetrics: [], tableMetrics: [] },
    isOpen = false,
    onCloseHandler = () => {},
    onChange = () => {},
    settings = {},
  } = props;
  const {
    infoFlag = true,
    infoAccessor = (row) => row["displayFormula"],
    showAddToDimTable = true,
  } = settings;

  // * Define required states
  const [metricSearch, setMetricSearch] = useState("");
  const [selections, dispatch] = useReducer(
    // useReducerLogger(addMetricDrawerReducer),
    addMetricDrawerReducer,
    initialSelections
  );

  // * Define utility functions
  const filterAndSortData = (data, metricSearch) => {
    if (data.length === 0) return data;
    const filteredData = data.filter((row) =>
      row.measureTitle.toLowerCase().includes(metricSearch.toLowerCase())
    );
    const sortedData = filteredData.sort((first, second) =>
      first["measureTitle"] < second["measureTitle"] ? -1 : 1
    );
    return sortedData;
  };

  // * Define required side effects
  // ! Commented this as it creates a bug where the banner change resets this state
  // useEffect(() => {
  //   const value = { ...initialSelections };
  //   const payload = { value };
  //   const action = replaceMetricDrawerSelections(payload);
  //   dispatch(action);
  // }, [initialSelections]);
  // Update initialSelections when the drawer opens
  useEffect(() => {
    if (isOpen) {
      const value = { ...initialSelections };
      const payload = { value };
      const action = replaceMetricDrawerSelections(payload);
      dispatch(action);
    }
  }, [isOpen]);

  // * Define required event handlers
  const handleSearchFieldChange = (event, value) => setMetricSearch(value);

  const handleOnClose = useCallback((event) => {
    onCloseHandler(false);
  }, []);

  const handleSubmit = (event) => {
    onChange(event, selections);
    onCloseHandler(false);
  };

  // * Define required static variables
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const {
    leftDrawerStyles,
    headerStyles,
    searchContainer,
    bodyContainerStyles,
    rowContainer,
    rowTitleContainer,
    footerStyles,
    bodyRowStyles,
    iconStyles,
  } = classes;

  let iconStyleProp = {
    width: "18px",
    height: "18px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    cursor: "pointer",
    marginLeft: "8px",
    "&.disabled": {
      opacity: "0.5",
    },
  };
  const leftDrawerClasses = { className: leftDrawerStyles };
  const filteredSortedData = useMemo(
    () => filterAndSortData(data, metricSearch),
    [data, metricSearch]
  );

  // ! Done button is disable when two table have diff metric - no common metric
  // const doneButtonDisableFlag =
  //   selections.chartMetrics.length === 0 ||
  //   selections.tableMetrics.length === 0;

  const doneButtonDisableFlag = selections.chartMetrics.length === 0;

  // Define required event handlers which was before inside  filteredSortedData.map()
  const handleAddChartClick = useCallback((metricId, selections) => {
    const metricValuePresentFlag =
      selections["chartMetrics"].includes(metricId);

    const payload = { value: metricId };
    let action = {};
    if (metricValuePresentFlag) {
      action = removeMetricChart(payload);
      dispatch(action);
    } else {
      action = addMetricChart(payload);
      dispatch(action);
    }
  }, []);

  const handleAddToTablesClick = useCallback((metricId, selections) => {
    const metricValuePresentFlag =
      selections["tableMetrics"].includes(metricId);

    const payload = { value: metricId };
    let action = {};
    if (metricValuePresentFlag) {
      action = removeMetricFromTables(payload);
      dispatch(action);
    } else {
      action = addMetricToTables(payload);
      dispatch(action);
    }
  }, []);

  // * DEBUGGER
  // console.groupCollapsed("AddMetricDrawer.js");
  // console.log("data", data);
  // console.log("selections", selections);
  // console.log("filteredSortedData", filteredSortedData);
  // console.log("isMetricValuePresent", isMetricValuePresent);
  // console.log("doneButtonDisableFlag", doneButtonDisableFlag);
  // console.groupEnd();

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={handleOnClose}
      PaperProps={{ ...leftDrawerClasses }}
    >
      <Box className={headerStyles}>
        <SigviewTypography variant="pLarger" style={{ height: "max-content" }}>
          Add Metrics
        </SigviewTypography>
        <span
          className="material-icons sigview-close-icon"
          onClick={handleOnClose}
        >
          close
        </span>
      </Box>
      <Box className={searchContainer}>
        <SigviewSearchField
          onChange={handleSearchFieldChange}
          placeholder="Search a Metric"
        />
      </Box>
      <Box className={`${bodyContainerStyles} sigview-styled-scroller-thin`}>
        {filteredSortedData.length === 0 && (
          <Box className="no-data-container">
            <Box className="no-data-image"></Box>
            <Box component="p" className="no-data">
              No Metric Available
            </Box>
          </Box>
        )}
        {filteredSortedData.length > 0 &&
          filteredSortedData.map((row) => {
            // Define require static variable
            const infoTooltip = infoAccessor(row);

            let finalInfoFlag = false;
            if (
              user.reqMetadata.organization === "Kayzen" &&
              user.reqMetadata.view === "Percentile" &&
              infoFlag &&
              infoTooltip !== "" &&
              infoTooltip !== undefined
            ) {
              finalInfoFlag =
                infoTooltip.startsWith("(Price Digest") ||
                infoTooltip.startsWith("(price digest");
            } else {
              finalInfoFlag =
                (infoFlag &&
                  infoTooltip !== "" &&
                  infoTooltip !== undefined) === true
                  ? false
                  : true;
            }
            const isMetricInMetricCharts = selections["chartMetrics"].includes(
              row["_id"]
            );
            const isMetricInDimTables = selections["tableMetrics"].includes(
              row["_id"]
            );
            const isMetricInMetricChartsDisabled =
              selections["chartMetrics"].length === 1 && isMetricInMetricCharts;
            const isMetricInDimTablesDisabled =
              selections["tableMetrics"].length === 1 && isMetricInDimTables;
            const isMetricInMetricChartsDisabledClassName =
              isMetricInMetricChartsDisabled ? "disabled" : "";
            const isMetricInDimTablesDisabledClassName =
              isMetricInDimTablesDisabled ? "disabled" : "";
            const isMetricInMetricChartsTooltip =
              isMetricInMetricChartsDisabled && isMetricInMetricCharts
                ? "Atleast 1 required"
                : isMetricInMetricCharts
                ? "Remove Chart"
                : "Add Chart";
            const isMetricInDimTablesTooltip =
              isMetricInDimTablesDisabled && isMetricInDimTables
                ? "Atleast 1 required"
                : isMetricInDimTables
                ? "Remove From Tables"
                : "Add to Tables";
            const isMetricInMetricChartsLogo = isMetricInMetricCharts
              ? "url(https://storage.googleapis.com/sigview-icons/misc/addToMetricHovered.svg)"
              : "url(https://storage.googleapis.com/sigview-icons/misc/addToMetric.svg)";
            const isMetricInDimTablesLogo = isMetricInDimTables
              ? "url(https://storage.googleapis.com/sigview-icons/misc/addToTableHovered.svg)"
              : "url(https://storage.googleapis.com/sigview-icons/misc/addToTable.svg)";

            return (
              <Box key={row._id} className={bodyRowStyles}>
                <Box className={rowContainer}>
                  <Box className={rowTitleContainer}>
                    <SigviewTypography
                      variant="pMedium"
                      style={{
                        height: "max-content",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                      title={row.measureTitle}
                    >
                      {row.measureTitle}
                    </SigviewTypography>
                    {!finalInfoFlag && (
                      <SigviewTooltip title={infoTooltip} placement="bottom">
                        <span>
                          <SigviewIcon
                            className={`material-icons-round`}
                            iconName="info"
                            style={{ padding: "0px 0px 0px 5px" }}
                          />
                        </span>
                      </SigviewTooltip>
                    )}
                  </Box>

                  <Box css={{ display: "flex", width: "20%" }}>
                    <SigviewTooltip
                      title={isMetricInMetricChartsTooltip}
                      placement="top"
                    >
                      <Box>
                        <AddToMetricChartIcon
                          // iconStyles={iconStyles}
                          iconStyleProp={iconStyleProp}
                          isMetricInDimTablesDisabledClassName={
                            isMetricInDimTablesDisabledClassName
                          }
                          id={row._id}
                          backgroundImage={isMetricInMetricChartsLogo}
                          onClick={() =>
                            handleAddChartClick(row._id, selections)
                          }
                        />
                      </Box>
                    </SigviewTooltip>
                    {showAddToDimTable && (
                      <SigviewTooltip
                        title={isMetricInDimTablesTooltip}
                        placement="top"
                      >
                        <Box>
                          <AddToDimTableIcon
                            // iconStyles={iconStyles}
                            iconStyleProp={iconStyleProp}
                            isMetricInDimTablesDisabledClassName={
                              isMetricInDimTablesDisabledClassName
                            }
                            id={row._id}
                            backgroundImage={isMetricInDimTablesLogo}
                            onClick={() =>
                              handleAddToTablesClick(row._id, selections)
                            }
                          />
                        </Box>
                      </SigviewTooltip>
                    )}
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>
      <Box className={footerStyles}>
        <SigviewButton
          variant="contained"
          onClick={handleSubmit}
          disabled={doneButtonDisableFlag}
          title="Done"
        />
      </Box>
    </Drawer>
  );
}

export default memo(AddMetricDrawer);
