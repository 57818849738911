const constants = {
  //COMMON
  CHANGE_THEME: "CHANGE_THEME",
  UPDATE_CHANGE_FLAG: "UPDATE_CHANGE_FLAG",
  UPDATE_THEME_COLORS: "UPDATE_THEME_COLORS",
  REDIRECT_TO_ANGULAR: "REDIRECT_TO_ANGULAR",

  //DATA
  UPDATE_DATA: "UPDATE_DATA",
  UPDATE_PLOTLY_METRICS_DIMENSIONS_LIST:
    "UPDATE_PLOTLY_METRICS_DIMENSIONS_LIST",
  EMPTY_ALL_DATA: "EMPTY_ALL_DATA",
  LOGOUT: "LOGOUT",
  SSO_LOGOUT: "SSO_LOGOUT",
  UPDATE_ALL_DATE_RANGE: "UPDATE_ALL_DATE_RANGE",

  //USER
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
  UPDATE_AUTH: "UPDATE_AUTH",
  UPDATE_USER_METADATA: "UPDATE_USER_METADATA",
  UPDATE_USER_SCREEN: "UPDATE_USER_SCREEN",
  UPDATE_DIALOG_INFO: "UPDATE_DIALOG_INFO",
  UPDATE_USER_API_ENDPOINTS: "UPDATE_USER_API_ENDPOINTS",
  UPDATE_USER_TYPE: "UPDATE_USER_TYPE",
  UPDATE_USER_FEATURE_ACCESS_LIST: "UPDATE_USER_FEATURE_ACCESS_LIST",
  UPDATE_VERSION: "UPDATE_VERSION",
  UPDATE_USER_BANNER_STATUS: "UPDATE_USER_BANNER_STATUS",
  UPDATE_USER_LANGUAGE: "UPDATE_USER_LANGUAGE",

  //TIME FILTERS
  UPDATE_TIME_FILTERS: "UPDATE_TIME_FILTERS",
  UPDATE_TIME_FILTERS_WITH_DEFAULT_DATES:
    "UPDATE_TIME_FILTERS_WITH_DEFAULT_DATES",
  TIMEZONE_CHANGED: "TIMEZONE_CHANGED",
  DATE_PRESET_CHANGED: "DATE_PRESET_CHANGED",
  DATE_SELECTED_CHANGED: "DATE_SELECTED_CHANGED",
  REPLACE_TIME_FILTERS: "REPLACE_TIME_FILTERS",
  COMPARE_DATE_SELECTED_CHANGED: "COMPARE_DATE_SELECTED_CHANGED",
  TOGGLE_COMPARE: "TOGGLE_COMPARE",
  UPDATE_CALENDAR_COMPARISON_TYPE: "UPDATE_CALENDAR_COMPARISON_TYPE",
  UPDATE_ACTIVE_COMPARE_DATE_ROW: "UPDATE_ACTIVE_COMPARE_DATE_ROW",
  ADD_COMPARE_ROW: "ADD_COMPARE_ROW",
  REMOVE_COMPARE_ROW: "REMOVE_COMPARE_ROW",
  COMPARE_DATE_PRESET_CHANGE: "COMPARE_DATE_PRESET_CHANGE",

  //FILTERS
  REPLACE_ALL_DIMENSION_FILTERS: "REPLACE_ALL_DIMENSION_FILTERS",
  ADD_FILTER: "ADD_FILTER",
  REMOVE_FILTER: "REMOVE_FILTER",
  CHANGE_FILTER_TYPE: "CHANGE_FILTER_TYPE",
  ADD_FILTER_VALUES: "ADD_FILTER_VALUES",
  REMOVE_FILTER_VALUES: "REMOVE_FILTER_VALUE",

  //ADVANCED FILTERS
  ADD_ADVANCED_FILTER: "ADD_ADVANCED_FILTER", //filterId, advancedFilterId, advancedFilterType, type, value, extraData
  REMOVE_ADVANCED_FILTER: "REMOVE_ADVANCED_FILTER", //filterId, advancedFilterId
  CHANGE_ADVANCED_FILTER_TYPE: "CHANGE_ADVANCED_FILTER_TYPE", //filterId, advancedFilterId, advancedFilterType
  CHANGE_ADVANCED_FILTER_VALUE: "CHANGE_ADVANCED_FILTER_VALUE", //filterId, advancedFilterId, value
  CHANGE_ADVANCED_FILTER_EXTRA_DATA: "CHANGE_ADVANCED_FILTER_EXTRA_DATA", //filterId, advancedFilterId, extraData
  REPLACE_ALL_ADVANCED_FILTERS: "REPLACE_ALL_ADVANCED_FILTERS", //new array of advanced filters[]

  //METRIC FILTERS
  ADD_METRIC_FILTER: "ADD_METRIC_FILTER",
  REMOVE_METRIC_FILTER: "REMOVE_METRIC_FILTER",
  CHANGE_METRIC_FILTER_TYPE: "CHANGE_METRIC_FILTER_TYPE",
  CHANGE_METRIC_FILTER_VALUE: "CHANGE_METRIC_FILTER_VALUE",
  REPLACE_ALL_METRIC_FILTERS: "REPLACE_ALL_METRIC_FILTERS",
  CHANGE_METRIC_FILTER_METRIC_ID: "CHANGE_METRIC_FILTER_METRIC_ID",
  CHANGE_ABSOLUTE_CHANGE: "CHANGE_ABSOLUTE_CHANGE",

  //STANDALONE_CHART
  UPDATE_STANDALONE_CHART_FORM: "UPDATE_STANDALONE_CHART_FORM",
  REPLACE_STANDALONE_CHART_FORM: "REPLACE_STANDALONE_CHART_FORM",

  //STANDALONE_DS
  UPDATE_STANDALONE_DS_FORM: "UPDATE_STANDALONE_DS_FORM",
  REPLACE_STANDALONE_DS_FORM: "REPLACE_STANDALONE_DS_FORM",
  UPDATE_STANDALONE_DS_FORM_CHART_DATA: "UPDATE_STANDALONE_DS_FORM_CHART_DATA",
  UPDATE_STANDALONE_DS_FORM_METRICS_DATA:
    "UPDATE_STANDALONE_DS_FORM_METRICS_DATA",
  RELOAD_DS_CHART_DATA: "RELOAD_DS_CHART_DATA",
  RELOAD_DS_METRICS_DATA: "RELOAD_DS_METRICS_DATA",
  REMOVE_DS_WIDGET: "REMOVE_DS_WIDGET",
  UPDATE_STANDALONE_DS_FORM_CHART_OBJECT:
    "UPDATE_STANDALONE_DS_FORM_CHART_OBJECT",
  UPDATE_STANDALONE_DS_FORM_CHART_NAME: "UPDATE_STANDALONE_DS_FORM_CHART_NAME",
  UPDATE_STANDALONE_DS_FORM_WIDGET: "UPDATE_STANDALONE_DS_FORM_WIDGET",
  REPLACE_STANDALONE_DS_FORM_WIDGET: "REPLACE_STANDALONE_DS_FORM_WIDGET",
  ADD_STANDALONE_DS_FORM_WIDGET_CHART: "ADD_STANDALONE_DS_FORM_WIDGET_CHART",
  UPDATE_STANDALONE_DS_FORM_MULTIPLE_KPIS:
    "UPDATE_STANDALONE_DS_FORM_MULTIPLE_KPIS",
  UPDATE_STANDALONE_DS_FORM_MULTIPLE_CHARTS:
    "UPDATE_STANDALONE_DS_FORM_MULTIPLE_CHARTS",
  UPDATE_STANDALONE_DS_FORM_MULTIPLE_FIELDS:
    "UPDATE_STANDALONE_DS_FORM_MULTIPLE_FIELDS",
  RELOAD_WS_ALL_METRIC_CHARTS_DATA: "RELOAD_WS_ALL_METRIC_CHARTS_DATA",
  RESET_WORKSPACE_AND_MSV: "RESET_WORKSPACE_AND_MSV",
  UPDATE_ANALYZE_FILTERS_READONLY_FROM_GLOBALFILTERS:
    "UPDATE_ANALYZE_FILTERS_READONLY_FROM_GLOBALFILTERS",
  UPDATE_GLOBALFILTERS_TIMEFILTERS_WITH_DEFAULT_VALUE:
    "UPDATE_GLOBALFILTERS_TIMEFILTERS_WITH_DEFAULT_VALUE",
  UPDATE_GLOBALFILTERS_FORM_ANALYZEFILTERSREADONLY:
    "UPDATE_GLOBALFILTERS_FORM_ANALYZEFILTERSREADONLY",

  //STEPPER
  CHANGE_STEP: "CHANGE_STEP",
  REPLACE_STATE: "REPLACE_STATE",
  UPDATE_STEP_VALIDITY: "UPDATE_STEP_VALIDITY",

  // ADMIN
  UPDATE_ADMIN_FORM: "UPDATE_ADMIN_FORM",
  REPLACE_ADMIN_FORM: "REPLACE_ADMIN_FORM",
  VALIDATE_ADMIN_FORM: "VALIDATE_ADMIN_FORM",

  // ADMIN ATTRIBUTE DIMENSION FORM
  UPDATE_ATTRIBUTE_DIMENSION_FORM: "UPDATE_ATTRIBUTE_DIMENSION_FORM",
  UPDATE_ATTRIBUTE_DIMENSION_FORM_WHOLE_KEY:
    "UPDATE_ATTRIBUTE_DIMENSION_FORM_WHOLE_KEY",
  REPLACE_ATTRIBUTE_DIMENSION_FORM: "REPLACE_ATTRIBUTE_DIMENSION_FORM",

  // ADMIN ATTRIBUTE METRIC FORM
  UPDATE_ATTRIBUTE_METRIC_FORM: "UPDATE_ATTRIBUTE_METRIC_FORM",
  REPLACE_ATTRIBUTE_METRIC_FORM: "REPLACE_ATTRIBUTE_METRIC_FORM",
  UPDATE_ATTRIBUTE_METRIC_FORM_WHOLE_KEY:
    "UPDATE_ATTRIBUTE_METRIC_FORM_WHOLE_KEY",

  // ADMIN ATTRIBUTE CUSTOM METRIC FORM
  UPDATE_ATTRIBUTE_CUSTOM_METRIC_FORM: "UPDATE_ATTRIBUTE_CUSTOM_METRIC_FORM",
  REPLACE_ATTRIBUTE_CUSTOM_METRIC_FORM: "REPLACE_ATTRIBUTE_CUSTOM_METRIC_FORM",
  UPDATE_ATTRIBUTE_CUSTOM_METRIC_FORM_WHOLE_KEY:
    "UPDATE_ATTRIBUTE_CUSTOM_METRIC_FORM_WHOLE_KEY",

  // Alert Manager Form
  UPDATE_ALERT_FORM: "UPDATE_ALERT_FORM",
  REPLACE_ALERT_FORM: "REPLACE_ALERT_FORM",
  REPLACE_ALERT: "REPLACE_ALERT",
  CHANGE_ACTIVE_DIMENSION_ROW: "CHANGE_ACTIVE_DIMENSION_ROW",

  //PIVOT
  UPDATE_PIVOT_FORM: "UPDATE_PIVOT_FORM",
  UPDATE_PIVOT_ADVANCE_SORT: "UPDATE_PIVOT_ADVANCE_SORT",
  UPDATE_PIVOT_MULTIPLE_FIELDS: "UPDATE_PIVOT_MULTIPLE_FIELDS",
  UPDATE_PIVOT_RELOADEPOCH: "UPDATE_PIVOT_RELOADEPOCH",

  // RESET PASSWORD
  UPDATE_RESET_PASSWORD_FORM: "UPDATE_RESET_PASSWORD_FORM",
  SUBMIT_RESET_PASSWORD_INITIAL_ERROR: "SUBMIT_RESET_PASSWORD_INITIAL_ERROR",

  //CHANGE PASSWORD
  UPDATE_CHANGED_PASSWORD: "UPDATE_CHANGED_PASSWORD",
  SUBMIT_CHANGED_PASSWORD_INITIAL_ERROR:
    "SUBMIT_CHANGED_PASSWORD_INITIAL_ERROR",

  // SIGNIN FORM
  UPDATE_SIGNIN_FORM: "UPDATE_SIGNIN_FORM",
  REPLACE_SIGNIN_FORM: "REPLACE_SIGNIN_FORM",
  UPDATE_SIGNIN_FORM_WHOLE_KEY: "UPDATE_SIGNIN_FORM_WHOLE_KEY",

  // ADD METRCIC DRAWER
  ADD_METRIC_CHART: "ADD_METRIC_CHART",
  ADD_METRIC_TO_TABLES: "ADD_METRIC_TO_TABLES",
  REMOVE_METRIC_CHART: "REMOVE_METRIC_CHART",
  REMOVE_METRIC_FROM_TABLES: "REMOVE_METRIC_FROM_TABLES",
  REPLACE_METRIC_DRAWER_SELECTIONS: "REPLACE_METRIC_DRAWER_SELECTIONS",

  // WORKSPACE
  UPDATE_STANDALONE_WS_FORM: "UPDATE_STANDALONE_WS_FORM",
  REPLACE_STANDALONE_WS_FORM: "REPLACE_STANDALONE_WS_FORM",
  UPDATE_WS_FORM_DIMENSION_TABLE_DATA: "UPDATE_WS_FORM_DIMENSION_TABLE_DATA",
  RELOAD_WS_DIMENSION_TABLE_DATA: "RELOAD_WS_DIMENSION_TABLE_DATA",
  UPDATE_STANDALONE_WS_FORM_METRICS_DATA:
    "UPDATE_STANDALONE_WS_FORM_METRICS_DATA",
  DELETE_STANDALONE_WS_FORM_METRIC_CHART:
    "DELETE_STANDALONE_WS_FORM_METRIC_CHART",
  DELETE_STANDALONE_WS_FORM_DIMENSION_TABLE:
    "DELETE_STANDALONE_WS_FORM_DIMENSION_TABLE",
  UPDATE_STANDALONE_WS_FORM_METRICS_CHARTS_DATA:
    "UPDATE_STANDALONE_WS_FORM_METRICS_CHARTS_DATA",
  UPDATE_STANDALONE_WS_FORM_METRICS_CHARTS_DATA_WITH_FILTER:
    "C.UPDATE_STANDALONE_WS_FORM_METRICS_CHARTS_DATA_WITH_FILTER",
  UPDATE_STANDALONE_WS_MULTIPLE_DIMENSIONS:
    "UPDATE_STANDALONE_WS_MULTIPLE_DIMENSIONS",
  UPDATE_STANDALONE_WS_MULTIPLE_DIMENSIONS_AND_METRICS:
    "UPDATE_STANDALONE_WS_MULTIPLE_DIMENSIONS_AND_METRICS",
  UPDATE_STANDALONE_WS_MULTIPLE_METRICS:
    "UPDATE_STANDALONE_WS_MULTIPLE_METRICS",
  UPDATE_STANDALONE_WS_MULTIPLE_METRICS_TABLE:
    "UPDATE_STANDALONE_WS_MULTIPLE_METRICS_TABLE",
  UPDATE_STANDALONE_WS_DATA_SORT: "UPDATE_STANDALONE_WS_DATA_SORT",
  UPDATE_STANDALONE_WS_TOGGLE_PERCENT_CAL_LIST:
    "UPDATE_STANDALONE_WS_TOGGLE_PERCENT_CAL_LIST",
  UPDATE_STANDALONE_WS_FILTERS_FROM_DIM_TABLE:
    "UPDATE_STANDALONE_WS_FILTERS_FROM_DIM_TABLE",
  UPDATE_STANDALONE_WS_DIMENSION_TABLE_METRICS:
    "UPDATE_STANDALONE_WS_DIMENSION_TABLE_METRICS",
  UPDATE_STANDALONE_WS_MULTIPLE_DIMENSIONS_FROM_DIMENSION_DRAWER:
    "UPDATE_STANDALONE_WS_MULTIPLE_DIMENSIONS_FROM_DIMENSION_DRAWER",
  NO_ACTION: "NO_ACTION",
  UPDATE_STANDALONE_WS_MSV_TABLE_ROW_CLICKED:
    "UPDATE_STANDALONE_WS_MSV_TABLE_ROW_CLICKED",
  UPDATE_STANDALONE_WS_METRIC_FILTERS_FOR_MSV_TABLE:
    "UPDATE_STANDALONE_WS_METRIC_FILTERS_FOR_MSV_TABLE",
  UPDATE_STANDALONE_WS_MSV_CANCEL: "UPDATE_STANDALONE_WS_MSV_CANCEL",
  UPDATE_STANDALONE_WS_MSV_APPLY_FILTERS:
    "UPDATE_STANDALONE_WS_MSV_APPLY_FILTERS",
  UPDATE_STANDALONE_WS_FORM_CHECK_ALL: "UPDATE_STANDALONE_WS_FORM_CHECK_ALL",
  UPDATE_STANDALONE_WS_AND_MSV_BY_OPENING_MSV:
    "UPDATE_STANDALONE_WS_AND_MSV_BY_OPENING_MSV",
  UPDATE_STANDALONE_WS_FORM_METRICS_CHARTS_HOVER:
    "UPDATE_STANDALONE_WS_FORM_METRICS_CHARTS_HOVER",

  // MSV
  UPDATE_STANDALONE_MSV_FORM: "UPDATE_STANDALONE_MSV_FORM",
  REPLACE_STANDALONE_MSV_FORM: "REPLACE_STANDALONE_MSV_FORM",
  UPDATE_STANDALONE_MSV_FORM_DIMENSION_TABLE_DATA:
    "UPDATE_STANDALONE_MSV_FORM_DIMENSION_TABLE_DATA",
  RELOAD_STANDALONE_MSV_DIMENSION_TABLE_DATA:
    "RELOAD_STANDALONE_MSV_DIMENSION_TABLE_DATA",
  UPDATE_STANDALONE_MSV_FORM_METRICS_DATA:
    "UPDATE_STANDALONE_MSV_FORM_METRICS_DATA",
  DELETE_STANDALONE_MSV_FORM_METRIC_CHART:
    "DELETE_STANDALONE_MSV_FORM_METRIC_CHART",
  DELETE_STANDALONE_MSV_FORM_DIMENSION_TABLE:
    "DELETE_STANDALONE_MSV_FORM_DIMENSION_TABLE",
  UPDATE_STANDALONE_MSV_FORM_METRICS_CHARTS_DATA:
    "UPDATE_STANDALONE_MSV_FORM_METRICS_CHARTS_DATA",
  UPDATE_STANDALONE_MSV_FORM_METRICS_CHARTS_DATA_WITH_FILTER:
    "UPDATE_STANDALONE_MSV_FORM_METRICS_CHARTS_DATA_WITH_FILTER",
  UPDATE_STANDALONE_MSV_MULTIPLE_DIMENSIONS:
    "UPDATE_STANDALONE_MSV_MULTIPLE_DIMENSIONS",
  UPDATE_STANDALONE_MSV_MULTIPLE_DIMENSIONS_AND_METRICS:
    "UPDATE_STANDALONE_MSV_MULTIPLE_DIMENSIONS_AND_METRICS",
  UPDATE_STANDALONE_MSV_MULTIPLE_METRICS:
    "UPDATE_STANDALONE_MSV_MULTIPLE_METRICS",
  UPDATE_STANDALONE_MSV_MULTIPLE_METRICS_TABLE:
    "UPDATE_STANDALONE_MSV_MULTIPLE_METRICS_TABLE",
  UPDATE_STANDALONE_MSV_DATA_SORT: "UPDATE_STANDALONE_MSV_DATA_SORT",
  UPDATE_STANDALONE_MSV_TOGGLE_PERCENT_CAL_LIST:
    "UPDATE_STANDALONE_MSV_TOGGLE_PERCENT_CAL_LIST",
  UPDATE_STANDALONE_MSV_FILTERS_FROM_DIM_TABLE:
    "UPDATE_STANDALONE_MSV_FILTERS_FROM_DIM_TABLE",
  UPDATE_STANDALONE_MSV_DIMENSION_TABLE_METRICS:
    "UPDATE_STANDALONE_MSV_DIMENSION_TABLE_METRICS",
  UPDATE_STANDALONE_MSV_MULTIPLE_DIMENSIONS_FROM_DIMENSION_DRAWER:
    "UPDATE_STANDALONE_MSV_MULTIPLE_DIMENSIONS_FROM_DIMENSION_DRAWER",
  NO_ACTION: "NO_ACTION",
  UPDATE_STANDALONE_MSV_MSV_TABLE_ROW_CLICKED:
    "UPDATE_STANDALONE_MSV_MSV_TABLE_ROW_CLICKED",
  UPDATE_STANDALONE_MSV_METRIC_FILTERS_FOR_MSV_TABLE:
    "UPDATE_STANDALONE_MSV_METRIC_FILTERS_FOR_MSV_TABLE",
  UPDATE_STANDALONE_MSV_MSV_CANCEL: "UPDATE_STANDALONE_MSV_MSV_CANCEL",
  UPDATE_STANDALONE_MSV_MSV_APPLY_FILTERS:
    "UPDATE_STANDALONE_MSV_MSV_APPLY_FILTERS",
  UPDATE_STANDALONE_MSV_FORM_CHECK_ALL: "UPDATE_STANDALONE_MSV_FORM_CHECK_ALL",
  RELOAD_STANDALONE_MSV_ALL_METRIC_CHARTS_DATA:
    "RELOAD_STANDALONE_MSV_ALL_METRIC_CHARTS_DATA",

  // SIGNIN FORM
  UPDATE_SIGNIN_FORM: "UPDATE_SIGNIN_FORM",
  REPLACE_SIGNIN_FORM: "REPLACE_SIGNIN_FORM",
  UPDATE_SIGNIN_FORM_WHOLE_KEY: "UPDATE_SIGNIN_FORM_WHOLE_KEY",

  // ADD METRCIC DRAWER
  ADD_METRIC_CHART: "ADD_METRIC_CHART",
  ADD_METRIC_TO_TABLES: "ADD_METRIC_TO_TABLES",
  REMOVE_METRIC_CHART: "REMOVE_METRIC_CHART",
  REMOVE_METRIC_FROM_TABLES: "REMOVE_METRIC_FROM_TABLES",
  REPLACE_METRIC_DRAWER_SELECTIONS: "REPLACE_METRIC_DRAWER_SELECTIONS",

  // AUTH
  UPDATE_AUTH_LOGIN: "UPDATE_AUTH_LOGIN",
  UPDATE_AUTH_PUBLIC_URL: "UPDATE_AUTH_PUBLIC_URL",
  UPDATE_AUTH_ERROR: "UPDATE_AUTH_ERROR",
  UPDATE_AUTH_LOADING: "UPDATE_AUTH_LOADING",
  UPDATE_AUTH_SUCCESS: "UPDATE_AUTH_SUCCESS",

  // GlobalFilters
  UPDATE_GLOBALFILTERS_TIMEFILTERS: "UPDATE_GLOBALFILTERS_TIMEFILTERS",
  UPDATE_GLOBALFILTERS_DIMENSIONFILTERS:
    "UPDATE_GLOBALFILTERS_DIMENSIONFILTERS",
  UPDATE_GLOBALFILTERS_ROLLINGDATETYPE: "UPDATE_GLOBALFILTERS_ROLLINGDATETYPE",
  UPDATE_MERTRIC_CHART_GRANULARITY: "UPDATE_MERTRIC_CHART_GRANULARITY",
  // LANGUAGE
  UPDATE_USER_LANGUAGE: "UPDATE_USER_LANGUAGE",

  // BUCKET
  UPDATE_REGISTER_BUCKET_FORM: "UPDATE_REGISTER_BUCKET_FORM",
  REPLACE_REGISTER_BUCKET_FORM: "REPLACE_REGISTER_BUCKET_FORM",
  VALIDATE_REGISTER_BUCKET_FORM: "VALIDATE_REGISTER_BUCKET_FORM",
};

export default constants;
