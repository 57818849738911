// ! IMPORTANT NOTES
// Caution:
// 1. This is a fast fix to solve website performance
// 2. This may not adhere to our kit guidelines

// Import library components
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import isEqual from "lodash.isequal";

const useStyles = makeStyles((theme) => {
  const commonStyles = {
    height: (props) => props?.height || "25px",
    padding: (props) => props?.padding || "0px",
    cursor: (props) => props?.cursor || "default",
    color: (props) => props?.color || theme.palette.misc["secondaryColor"],
    width: (props) => props?.width || "initial",
    whiteSpace: (props) => props?.whiteSpace || "initial",
    overflow: (props) => props?.overflow || "initial",
    textOverflow: (props) => props?.textOverflow || "initial",
    boxSizing: (props) => props?.boxSizing || "initial",
    textAlign: (props) => props?.textAlign || "initial",
    lineHeight: (props) => props?.lineHeight || 1,
    wordBreak: (props) => props?.wordBreak || "normal",
    textTransform: (props) => props?.textTransform || "initial",
    fontWeight: (props) => props?.fontWeight || 400,
    margin: (props) => props?.margin || "0px !important",
    fontSize: (props) => props?.fontSize || "18px",
    letterSpacing: (props) => props?.letterSpacing || "0.0125em",
    fontFamily: (props) => props?.fontFamily || "Fira Sans !important",
    hoverColor: (props) =>
      props?.hoverColor || theme.palette.misc["primaryColor"],
    pointerEvents: (props) => props?.pointerEvents || "auto",
    border: (props) => props?.border || "0px solid #ccc",
    borderRadius: (props) => props?.borderRadius || "3px",
  };
  const finalStyles = {
    heading: { ...commonStyles, fontWeight: 500 },
    pSmallest: { ...commonStyles, fontSize: "8px" },
    pSmaller: { ...commonStyles, fontSize: "9px" },
    pSmall: { ...commonStyles, fontSize: "10px" },
    pSmallMedium: { ...commonStyles, fontSize: "11px" },
    pMedium: { ...commonStyles, fontSize: "12px" },
    pMediumLarge: {
      ...commonStyles,
      fontSize: "13px",
      "&:hover": { color: commonStyles.hoverColor },
    },
    pLarge: { ...commonStyles, fontSize: "14px" },
    pLargeMiddle: { ...commonStyles, fontSize: "15px" },
    pLarger: { ...commonStyles, fontSize: "16px" },
    pLargest: {
      ...commonStyles,
      fontSize: "23px",
      fontWeight: 500,
      letterSpacing: "0em",
    },
    pLargerCounter: {
      ...commonStyles,
      fontSize: "17px",
      fontWeight: 400,
      letterSpacing: "0em",
    },
    AlertSummaryTitle: {
      ...commonStyles,
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
    },
  };
  return finalStyles;
});

function areEqual(prevProps, nextProps) {
  let prev = prevProps;
  let next = nextProps;

  return isEqual(prev, next);
}

function SigviewTypography(props) {
  const { t } = useTranslation();
  const {
    children = "Saved Charts",
    variant = "pMedium",
    title = "",
    onClick = () => {},
    style = {},
    customClassName,
    customID,
  } = props;

  // Making styles
  const classes = useStyles(style);

  // ! this is a fallback if there is any typography which is not a string it wont break the UI, rather it will show but translation will not work
  return !Array.isArray(children) ? (
    <Typography
      className={`${classes[variant]} ${customClassName}`}
      onClick={onClick}
      title={t(title)}
      id={customID}
    >
      <div className={customClassName} id={customID}>
        {t(children)}
      </div>
    </Typography>
  ) : (
    <Typography
      className={`${classes[variant]} ${customClassName}`}
      onClick={onClick}
      title={t(title)}
      id={customID}
    >
      <div className={customClassName} id={customID}>
        {children}
      </div>
    </Typography>
  );
}

export default memo(SigviewTypography, areEqual);
