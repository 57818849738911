// * Import required libraries
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";

// * Import custom components

// * Import utils, config & static data
import { getBookmarkDetails } from "../../../utils/analyzeUtils";

// * Import redux utilities
// Action creators
import { updateUserScreen } from "../../../redux/actions";

// * Import API functions

// * Import contexts

// * Import API functions

// * Define static variables

function AnalyzeDashboardAngularShareCompatible(props) {
  // * Destructure props
  const { user } = props;
  const history = useHistory();
  const location = useLocation();

  // * Define required states

  // * Define required side effects
  // Redirect Angular shared URLs to react version (/analyze/dashboard?id=<bookmark_id>)
  useEffect(() => {
    // * Running this useEffect only after mandatory APIs has been completed
    // Otherwise it resets the screen state
    const runFlag = !user.screen.isDashboardLoading;
    if (runFlag) {
      const { isSharedUrlFlag, bookmarkId } = getBookmarkDetails(location);
      // * Redirect to shared URL (react version: /analyze/dashboard?id=<bookmark_id>)
      var newUrl = `/analyze/dashboard`;
      if (isSharedUrlFlag) {
        newUrl = `/analyze/dashboard?id=${bookmarkId}`;
      }
      history.push(newUrl);
    }
  }, [user.screen.isDashboardLoading]);

  // * Define required static variables

  return <></>;
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(AnalyzeDashboardAngularShareCompatible);
