// * Import required libraies
import {
  React,
  useState,
  useReducer,
  useRef,
  useContext,
  useEffect,
  memo,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import isEqual from "lodash.isequal";

// * Import lib components
import { Box } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";

// * Import custom components
import SigviewButton from "./SigviewButton";
import SigviewTypography from "./SigviewTypography";
import SigviewRadioGroup from "./SigviewRadioGroup";
import SigviewSingleSelect from "./SigviewSingleSelect";
import SigviewTooltip from "./SigviewTooltip";
import SigviewIcon from "./SigviewIcon";

// * Import data/utils
import useReducerLogger from "../../utils/useReducerLogger";
import { masterTrackGaEvent } from "../../services/ga";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Import API functions

// * Define style functions
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles({
    root: {
      "& .MuiPaper-root ": {
        backgroundColor: "transparent !important",
        padding: "7px 15px 10px 15px",
      },
      width: "300px",
      padding: "10px",
    },
    dataSortFooter: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
    },
    box1: {
      // border: `1px solid ${themeColors["secondaryColorLighter"]}`,
      // display: "flex",
      // alignItems: "center",
      // width: "200px",
      // height: "max-content",
      // padding: "5px",
      // borderRadius: "3px",
      // backgroundColor: themeColors["secondaryColorLightest"],
      // color: themeColors["secondaryColor"],
      // cursor: "pointer",
      // fontWeight: 400,
      // fontSize: "12px",
      // letterSpacing: "0.0125em",
      // fontFamily: "Fira Sans !important",
    },
  });
  return useStyles;
};

// * Redux Utilities
// Constants
const C = {
  REPLACE_FORM: "REPLACE_FORM",
  UPDATE_FORM: "UPDATE_FORM",
};

// Reducer
function dataSort(state = {}, action = {}) {
  const { type = "", payload = {} } = action;
  const { key = "", value = {} } = payload;
  let newState = {};
  switch (type) {
    case C.REPLACE_FORM:
      newState = { ...value };
      break;
    case C.UPDATE_FORM:
      if (value.id !== "_dimension") {
        newState = {
          ...state,
          [key]: value,
        };
      } else if (value.id === "_dimension") {
        newState = {
          ...state,
          [key]: value,
          sortTypeId: {
            id: "id_only",
          },
        };
      }
      break;
    default:
      console.error("UI ERROR");
      console.groupCollapsed("DETAILS");
      console.log("UNIDENTIFIED TYPE");
      console.log("REDUCER -> ", "dataSort.js");
      console.log("state -> ", state);
      console.log("action -> ", action);
      console.groupEnd();
      newState = { ...state };
      break;
  }
  return newState;
}

// Action
export const replaceForm = (payload) => ({
  type: C.REPLACE_FORM,
  payload,
});
export const updateForm = (payload) => ({
  type: C.UPDATE_FORM,
  payload,
});

// * Define static variables
const ascDescArr = [
  { id: "asc", name: "Ascending", disabled: false },
  { id: "desc", name: "Descending", disabled: false },
];

// ! This component is memoized because it runs on every click on analyze dashboard
function areEqual(prevProps, nextProps) {
  let prev = {
    sortType: { ...prevProps.initialSelections.sortType.id },
    sortTypeId: { ...prevProps.initialSelections.sortTypeId.id },
    sortTypeValue: { ...prevProps.initialSelections.sortTypeValue },
  };
  let next = {
    sortType: { ...nextProps.initialSelections.sortType.id },
    sortTypeId: { ...nextProps.initialSelections.sortTypeId.id },
    sortTypeValue: { ...nextProps.initialSelections.sortTypeValue },
  };

  return isEqual(prev, next);
}

function SigviewDataSort(props = {}) {
  // * Destructure props
  const {
    initialSelections = {},
    data = {},
    onChange = () => {},
    customStyle = {},
    handleSave = () => {},
    isComparisonOn = false,
  } = props;

  // * Define required hooks/contexts
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define required states
  const [anchorEl, setAnchorEl] = useState(null);

  const [selections, dispatchSelections] = useReducer(
    useReducerLogger(dataSort),
    initialSelections
  );

  // * Define side effects
  useEffect(() => {
    const payload = { value: initialSelections };
    const action = replaceForm(payload);
    dispatchSelections(action);
  }, [initialSelections]);

  let buttonSelections = initialSelections;

  const originalSelections = useRef(initialSelections);

  // * Define required event handlers

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReset = () => {
    const payload = { value: originalSelections.current };
    const action = replaceForm(payload);
    dispatchSelections(action);
  };

  const handleSortTypeChange = (val) => {
    const key = "sortType";
    const value = data.sortType.find((row) => row.id === val);
    const action = updateForm({ key, value });
    dispatchSelections(action);
  };

  const handleSortTypeIdChange = (val, data) => {
    console.log("val", val);
    const key = "sortTypeId";
    const value = data.find((row) => row.id === val);
    const action = updateForm({ key, value });
    dispatchSelections(action);
  };

  const handleSortTypeValueChange = (val) => {
    const key = "sortTypeValue";
    const value = val;
    const action = updateForm({ key, value });
    dispatchSelections(action);
  };

  const handleCancel = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "TableGlobalSortOpen",
      label: "Cancel",
    });
    // Reset selections
    handleReset();
    // Close popover
    setAnchorEl(null);
  };

  const handleDone = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "TableGlobalSortOpen",
      label: "Done",
    });
    handleSave(selections);
    setAnchorEl(null);
  };

  // * Define required variables
  const open = Boolean(anchorEl);
  const id = open ? "dataSortPopover" : undefined;
  const useSigviewStyles = makeSigviewStyles(themeColors, customStyle);
  const classes = useSigviewStyles();

  const DataSortFooter = (props = {}) => {
    return (
      <Box className={classes.dataSortFooter}>
        <SigviewButton
          variant="outlined"
          onClick={handleCancel}
          title="Cancel"
          style={{ margin: { left: "10px" }, width: "auto" }}
        />
        <SigviewButton
          variant="contained"
          title="Done"
          style={{ margin: { left: "10px" }, width: "50px" }}
          onClick={handleDone}
        />
      </Box>
    );
  };

  const modifyData = (selections, data, isComparisonOn) => {
    let modifiedData = [...data.sortTypeId];
    if (selections.sortType.id !== "_dimension") {
      if (isComparisonOn) {
        modifiedData = modifiedData.concat([
          {
            id: "id_trueDelta",
            name: "Absolute Change",
            disabled: false,
          },
          {
            id: "id_deltaPercentage",
            name: "Percentage Change",
            disabled: false,
          },
        ]);
      }
      return [...modifiedData];
    } else {
      return [...modifiedData];
    }
  };

  return (
    <>
      <Box className={classes.box1} onClick={handleClick}>
        {/* OLD IMPLEMENTATION */}
        {/* Data Sorted On:
        <span
          style={{
            fontWeight: 800,
            display: "inline-block",
            maxWidth: "69px",
            whiteSpace: "pre",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {customButtonTitle}
        </span>
        <span
          style={{
            whiteSpace: "pre",
          }}
        >
          {customButtonTitle2}
        </span> */}
        <SigviewTooltip title="Open Data Sort" placement="bottom">
          <span>
            <SigviewIcon
              className={`material-icons-round GlobalSortOpenDataSort-GA`}
              iconName="sort"
              style={{
                padding: "0px 0px 0px 5px",
                color: themeColors["secondaryColor"],
                hoverColor: themeColors["primaryColor"],
                cursor: "pointer",
              }}
            />
          </span>
        </SigviewTooltip>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCancel}
        // className={classes.root}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box className={classes.root}>
          <Box>
            <SigviewTypography style={{ height: "20px" }}>
              {"Date Sorted On:"}
            </SigviewTypography>
            <Box css={{ paddingRight: "5px", display: "inline-block" }}>
              <SigviewSingleSelect
                data={data.sortType}
                value={selections.sortType.id}
                onChange={handleSortTypeChange}
                minWidth="145px"
                maxWidth="145px"
              />
            </Box>
            {selections.sortType.id !== "_dimension" && (
              <Box css={{ paddingLeft: "5px", display: "inline-block" }}>
                <SigviewSingleSelect
                  data={modifyData(selections, data, isComparisonOn)}
                  value={selections.sortTypeId.id}
                  onChange={(val) => {
                    handleSortTypeIdChange(
                      val,
                      modifyData(selections, data, isComparisonOn)
                    );
                  }}
                  minWidth="145px"
                  maxWidth="145px"
                  // disabled={selections.sortType.id === "_dimension"}
                />
              </Box>
            )}
          </Box>

          <Box
            css={{ paddingTop: "10px", display: "flex", alignItems: "center" }}
          >
            {/* <SigviewTypography> */}
            <Box
              style={{
                height: "15px",
                fontSize: "12px",
                paddingRight: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SigviewTypography style={{ height: "12px" }}>
                {"Date Sorted By:"}
              </SigviewTypography>
            </Box>
            {/* </SigviewTypography> */}
            <SigviewRadioGroup
              value={selections.sortTypeValue}
              data={ascDescArr}
              onChange={handleSortTypeValueChange}
            />
          </Box>
          <DataSortFooter />
        </Box>
      </Popover>
    </>
  );
}

export default memo(SigviewDataSort, areEqual);
