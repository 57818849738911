//Import required libraies
import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

// Import Custom Component
import SigviewIcon from "../../components/Common/SigviewIcon";
import SigviewTypography from "../../components/Common/SigviewTypography";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";
import { Box } from "@material-ui/core";

// Import action creators

// Import utils

// Import styles

function SigviewIconButton(props) {
  const {
    onClick = {},
    title = "",
    iconName = "",
    customClassName,
    style = "",
  } = props;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const outerBox = {
    display: "flex",
    justifyContent: "space-evenly",
    width: "140px",
    height: "22px",
    borderLeft: "1px solid #F5F5F5",
    cursor: "pointer",
    alignItems: "center",
  };
  const innerBoxIcon = {
    fontSize: "13.5px !important",
    paddingRight: "10px !important",
    color: style.color || themeColors["secondaryColor"],
    hoverColor: style.color || themeColors["secondaryColor"],
    cursor: "pointer",
  };
  const innerBoxText = {
    fontSize: "12px",
    height: "max-content",
    color: style.color || themeColors["secondaryColor"],
    cursor: "pointer",
    textTransform: "uppercase",
  };

  return (
    <div className="SigviewIconButton">
      <Box className={customClassName} onClick={onClick} style={outerBox}>
        <Box>
          <SigviewIcon
            className={`material-icons-outlined ${customClassName}`}
            iconName={iconName}
            style={innerBoxIcon}
          />
        </Box>
        <Box>
          <SigviewTypography
            customClassName={customClassName}
            variant="pSmallMedium"
            style={innerBoxText}
          >
            {title}
          </SigviewTypography>
        </Box>
      </Box>
    </div>
  );
}

export default SigviewIconButton;
