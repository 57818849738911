// * Import required libraries
import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

// * Import hooks

// * Import utils
import { isEmbeddedUser } from "../../utils/authUtils";
import { getSigviewUserType } from "../../utils/utils";

function SignInRedirectHandler(props = {}) {
  const history = useHistory();
  const location = useLocation();
  const isEmbeddedUserFlag = isEmbeddedUser();
  const pathname = location.pathname;
  const sigviewUserType = getSigviewUserType();

  useEffect(() => {
    // * Catering to 3 different users
    // 1. AWS
    // 2. SSO
    // 3. Default

    if (pathname !== "/sign-in" && !isEmbeddedUserFlag) {
      if (sigviewUserType === "sigview") history.push("/sign-in");
      else if (sigviewUserType === "nonSigview") history.push("/openxSignIn");
    } else if (pathname !== "/sso/v1/login" && isEmbeddedUserFlag) {
      history.push("/sso/v1/login");
    } else {
      history.push("/sign-in");
    }
  }, []);

  return <></>;
}

export default SignInRedirectHandler;
