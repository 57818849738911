// * Import required libraies
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// * Import Custom Component
import UserInfoAdmin from "../UserInfoAdmin/UserInfoAdmin";
import ClientLogo from "../ClientLogo/ClientLogo";
import SigviewNav from "../Common/SigviewNav";

// * Import styles
import "./HeaderAdmin.scss";

function HeaderAdmin(props = {}) {
  const adminPageArr = [{ id: "admin", name: "Admin", disabled: false }];

  return (
    <>
      <header>
        <div className={`left-panel`}>
          <ClientLogo />
          <SigviewNav data={adminPageArr} value={adminPageArr[0].id} />
        </div>
        <div className={`right-panel`}>
          <UserInfoAdmin />
        </div>
      </header>
    </>
  );
}

HeaderAdmin.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAdmin);
