// Import Constants
import C from "../constants";

//import { validateAlertFormData } from "../../../../utils/alertUtils";

const alertFormReducer = (state, action) => {
  // console.log("checking", state, action);
  const { type = "", payload = {} } = action;
  const { key = "", value = "" } = payload;

  let newState = {};
  switch (type) {
    case C.UPDATE_ALERT_FORM:
      switch (key) {
        case "alertCriteriaMetric":
        case "duration":
        case "timeZone":
        case "alertType":
        case "group":
        case "comparedWith":
        case "activeDimensionRow":
        case "emailRecipient":
        case "duration":
        case "dimensionFilters":
        case "emailRecipients":
        case "userPreferredLanguage":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          // console.log("DEBUG", newState);
          break;
        case "name":
          let newName = value;
          newName = newName.replace(/\s/g, "_");
          // console.log("newName", newName);

          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
            // id: {
            //   ...state.id,
            //   value: `${newName}_${Date.now().toString()}`,
            // },
          };
          break;

        case "active":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          break;
        case "basis":
          let newCompareWith = value === "daily" ? "prevDay" : "prevHour"; //! hard coded
          // console.log("newCompareWith", newCompareWith);
          // console.log("key", key);
          // console.log("value", value);
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
            comparedWith: { ...state.comparedWith, value: newCompareWith },
          };
          // console.log("DEBUG", newState);
          break;
        default:
          console.log("Something Went Wrong");
      }
      break;

    // case C.CHANGE_DURATION:
    //   newState = {
    //     ...state,
    //     [key]: { ...state[key], value },
    //   };
    //   break;
    case C.REPLACE_ALERT_FORM: //TODO! replacealert
      newState = { ...value };
      break;

    case C.CHANGE_ACTIVE_DIMENSION_ROW:
      switch (key) {
        case "dimensionRow":
        case "changeCriteria":
        case "metric":
        case "group":
        case "dimensionChange":
        case "dimensionAdd":
        case "dimensionDelete":
        case "conditionValue":
          newState = {
            ...state,
            ["alertCriteriaDimension"]: {
              ...state["alertCriteriaDimension"],
              value: {
                ...state["alertCriteriaDimension"].value,
                [key]: { ...state["alertCriteriaDimension"].value[key], value },
              },
            },
            basis:
              value === "add" || "delete"
                ? { ...state.basis, value: "daily" }
                : { ...state.basis },
            comparedWith:
              value === "add" || "delete"
                ? { ...state.comparedWith, value: "prevDay" }
                : { ...state.comparedWith },
          };
          break;
        case "relativeComparison":
        case "absoluteComparison":
          newState = {
            ...state,
            ["alertCriteriaDimension"]: {
              ...state["alertCriteriaDimension"],
              value: {
                ...state["alertCriteriaDimension"].value,
                ["condition"]: {
                  ...state["alertCriteriaDimension"].value["condition"],
                  [key]: {
                    ...state["alertCriteriaDimension"].value["condition"][key],
                    value,
                  },
                },
              },
            },
          };
          break;
        default:
          console.log("Something Went Wrong");
      }
      break;
  }

  // const validatedState = validateAlertFormData(newState);

  return newState;
};

export default alertFormReducer;
