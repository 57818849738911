// * Import required libraries
import { useState, useEffect, useReducer, useContext, useRef } from "react";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * import lib component
import { Box } from "@material-ui/core";

// * Import custom components
import SigviewTextField from "../../../components/Common/SigviewTextField";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SigviewMultiSelectDnD from "../../../components/Common/SigviewMultiSelectDnD";
import SigviewSingleSelect from "../../../components/Common/SigviewSingleSelect";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import SigviewButton from "../../../components/Common/SigviewButton";
import SigviewCheckboxGroup from "../../../components/Common/SigviewCheckboxGroup";
import SigviewProgressBar from "../../../components/Common/SigviewProgressBar";
import SigviewTextFieldAsync from "../../../components/Common/SigviewTextFieldAsync";
import SigviewHoverPopup from "../../../components/Common/SigviewHoverPopup";
import SigviewIcon from "../../../components/Common/SigviewIcon";

// * Import utils, config & static data
import {
  defaultCustomMetricState as defaultSelections,
  unwrapperGroupDataDnd,
  isSelectionsInvalid,
  wrapperCustomMetricForm,
  wrapperGroup,
  unWrapperFormula,
  validateAttributeName,
  validateBackendName,
} from "../../../utils/attributeUtils";
import { config } from "../../../config/config";

// * Import redux utils
import useReducerLogger from "../../../utils/useReducerLogger";
import {
  updateAdminForm,
  updateUserScreen,
  replaceAttributeCustomMetricForm,
  updateAttributeCustomMetricForm,
  updateData,
  updateAttributeCustomMetricFormWholeKey,
} from "../../../redux/actions";
import attributeCustomMetricReducer from "../../../redux/reducers/attributeCustomMetric";
import data from "../../../redux/reducers/data";

//  * Import APIs
import {
  readAttributeGroupsByOrgViewId,
  createAttribute,
  readAdminByOrg,
  updateAttributeGroup,
  updateAttribute,
  readAttributeFormula,
  nameCheckAttribute,
} from "../../../services/api";

// TODO Same code changes as DimensionForm.js

function CustomMetricForm(props = {}) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  //  * Destructure props
  const {
    initialSelections = defaultSelections,
    groupsData = [],
    usableAttributes = [],
    viewsData,
    ReduxDispatcher,
    setShowForm = () => {},
    formulaData = [],
    availableGroupData = [],
  } = props;

  let usableAttributeNames = [{ id: "numeric", name: "Numeric Value" }].concat(
    usableAttributes.map((row) => {
      return { ...row, id: row._id, name: row.title };
    })
  );

  // * Define required states
  const [selections, dispatch] = useReducer(
    useReducerLogger(attributeCustomMetricReducer),
    initialSelections
  );

  const [groupData, setGroupData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });

  // const [formulaData, setFormulaData] = useState({
  //   result: [],
  //   status: "loading",
  //   messsage: "",
  // });

  const originalSelections = useRef({});

  const [name, setName] = useState({
    status: "success",
    value: selections.name.value,
    originalValue: selections.name.value,
    message: "",
  });

  // * Define required side effects

  // useEffect(() => {
  //   const readAttributeFormulaPromise = readAttributeFormula();

  //   readAttributeFormulaPromise
  //     .then((data) => {
  //       setFormulaData({
  //         result: data?.result?.data,
  //         status: "success",
  //         messsage: "",
  //       });
  //     })
  //     .catch((json) => {
  //       setFormulaData({
  //         result: [],
  //         status: "error",
  //         messsage: "API failed",
  //       });
  //     });
  // }, []);

  useEffect(() => {
    const payload = { ...initialSelections };
    const action = replaceAttributeCustomMetricForm(payload);

    setName((prevState) => ({
      ...prevState,
      value: initialSelections.name.value,
      message: "",
    }));

    originalSelections.current = {
      ...initialSelections,
      enteredFormula: {
        ...initialSelections.enteredFormula,
        value: initialSelections.enteredFormula.value.toLowerCase(),
      },
    };

    dispatch(action); // to update selections based on new clicked attribute
  }, [initialSelections.id.value]);

  useEffect(() => {
    if (selections.crudType === "create") {
      let formulaEntity = {};

      let formula = selections.formulaType.value.match(/[A-Z]/g);

      console.log("formula", formula, usableAttributeNames[0]);
      for (const i of formula) {
        formulaEntity[i] = {
          message: "",
          value: { id: usableAttributeNames[0].id, num: NaN },
          status: "valid",
        };
      }

      const payload = {
        key: "formulaType",
        value: { formulaType: selections.formulaType.value, formulaEntity },
      };
      const action = updateAttributeCustomMetricForm(payload);
      dispatch(action);
    }
    if (selections.crudType === "update") {
      console.log("UPDATE");
    }
  }, [selections.crudType, initialSelections]);

  const getString = (arr, id) => {
    for (const element of arr) {
      if (element.id === id) {
        return `${
          (element?.measureType?.toLowerCase() &&
            `${element.measureType.toLowerCase()}(${element.name.toLowerCase()})`) ||
          element.name.toLowerCase()
        }`;
      }
    }
  };
  useEffect(() => {
    let formula = selections.formulaType.value.match(/[A-Z]/g);
    let formulaEntity = selections.formulaEntity;

    let formulaType = selections.formulaType.value;
    for (const id of formula) {
      // APPROACH1
      formulaType = formulaType.replace(
        id,
        formulaEntity[id].value.id === "numeric"
          ? formulaEntity[id].value.num || 0
          : getString(usableAttributeNames, formulaEntity[id].value.id)

        // APPROACH2
        // if (formulaEntity[id].value.id === "numeric") {
        //   formulaType = formulaType.replace(id, formulaEntity[id].value.num || 0);
        // } else {
        //   let elem = usableAttributeNames.find(
        //     (row) => row.id === formulaEntity[id].value.id
        //   );

        //   let replacedString = `${
        //     (elem?.measureType?.toLowerCase() &&
        //       `${elem.measureType.toLowerCase()}(${elem.name.toLowerCase()})`) ||
        //     elem.name.toLowerCase()
        //   }`;

        //   //   getString(
        //   //   usableAttributeNames,
        //   //   formulaEntity[id].value.id
        //   // );
        //   formulaType = formulaType.replace(id, replacedString);
        // }

        // APPROACH3
        // usableAttributeNames.forEach((item) => {
        //     if (item.id === formulaEntity[id].value.id) {
        //       // item.id === formulaEntity[id].value.id;

        //       console.log("item", item.id);

        //       return item.id;
        //     }
        //   })
      );
    }
    const payload = {
      key: "enteredFormula",
      value: formulaType,
    };
    const action = updateAttributeCustomMetricForm(payload);
    dispatch(action);
  }, [selections.formulaEntity]);

  // Fetch  groupData and set groupData
  useEffect(() => {
    if (selections.crudType === "update") {
      setGroupData({
        result: [],
        status: "loading",
        messsage: "",
      });
      // Make fetch call using axios
      const readAllGroupsViews = readAttributeGroupsByOrgViewId({
        view: selections.activeView.value,
        id: selections.id.value,
      });

      readAllGroupsViews
        .then((data) => {
          setGroupData({
            result: data?.result?.data,
            status: "success",
            messsage: "",
          });
        })
        .catch((json) => {
          setGroupData({
            result: [],
            status: "error",
            messsage: "API failed",
          });
        });
    }
  }, [selections.id.value]);

  useEffect(() => {
    if (groupData.status === "success") {
      const payload = {
        key: "groups",
        value: unwrapperGroupDataDnd(groupData.result),
      };

      originalSelections.current = {
        ...originalSelections.current,
        groups: {
          message: "",
          value: payload.value,
          status: "valid",
        },
      };

      const action = updateAttributeCustomMetricForm(payload);
      dispatch(action);
    }
  }, [groupData]);

  // console.log("groupData", groupData);

  // * Define requried event handlers

  // const handleNameChange = (value) => {
  //   const payload = {
  //     key: "name",
  //     value,
  //   };
  //   const action = updateAttributeCustomMetricForm(payload);
  //   dispatch(action);
  // };

  const handleNameChange = (event, value) => {
    setName((prevState) => ({
      ...prevState,
      value: value,
      message: "",
    }));
  };

  const handleGroupsChange = (value) => {
    const payload = {
      key: "groups",
      value,
    };
    const action = updateAttributeCustomMetricForm(payload);
    dispatch(action);
  };
  const handelFormulaChange = (value) => {
    let formulaEntity = {};

    let formula = value.match(/[A-Z]/g);

    for (const i of formula) {
      formulaEntity[i] = selections?.formulaEntity[i] || {
        message: "",
        value: { id: usableAttributeNames[0].id, num: NaN },
        status: "",
      };
    }

    console.log("formulaArray", formula, formulaEntity);

    console.log("handelFormulaChange", value);
    const payload = {
      key: "formulaType",
      value: { formulaType: value, formulaEntity },
    };
    const action = updateAttributeCustomMetricForm(payload);
    dispatch(action);
  };
  const handleFormEntryChange = (id, entityValue, entityValueType = "") => {
    console.log("handleFormEntryChange", id, entityValue, entityValueType);

    // if (
    //   entityValueType !== "numeric" ||
    //   (entityValueType === "numeric" && !isNaN(entityValue))
    // ) {
    let value = {
      id,
      entityValue,
      entityValueType,
    };

    const payload = {
      key: "formulaEntity",
      value,
    };

    const action = updateAttributeCustomMetricForm(payload);
    dispatch(action);
    // }
  };
  const handleDataTypeChange = (value) => {
    if (value === "String") {
      let payload = {
        key: "dataUnitType",
        value,
      };
      let action = updateAttributeCustomMetricForm(payload);
      dispatch(action);

      payload = {
        key: "dataUnitSymbol",
        value,
      };

      action = updateAttributeCustomMetricForm(payload);
      dispatch(action);
    }
    if (value === "percent") {
      let payload = {
        key: "dataUnitType",
        value,
      };
      let action = updateAttributeCustomMetricForm(payload);
      dispatch(action);

      payload = {
        key: "dataUnitSymbol",
        value: config.hardCoded.attributes.metricDataSymbol[value][0].id,
      };

      action = updateAttributeCustomMetricForm(payload);
      dispatch(action);
    }
    if (value === "currency") {
      let payload = {
        key: "dataUnitType",
        value,
      };
      let action = updateAttributeCustomMetricForm(payload);
      dispatch(action);

      payload = {
        key: "dataUnitSymbol",
        value: config.hardCoded.attributes.metricDataSymbol[value][0].id,
      };

      action = updateAttributeCustomMetricForm(payload);
      dispatch(action);
    }
  };
  const handleDataSymbolChange = (value) => {
    const payload = {
      key: "dataUnitSymbol",
      value,
    };
    const action = updateAttributeCustomMetricForm(payload);
    dispatch(action);
  };
  const handleAdditiveChange = (value) => {
    const payload = {
      key: "isAdditive",
      value,
    };
    const action = updateAttributeCustomMetricForm(payload);
    dispatch(action);
  };

  const handleSave = () => {
    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    // TODO: Make API call to save attribute
    let payload = wrapperCustomMetricForm({ ...selections });
    let fetchProps = {
      payload,
      type: "cm",
    };
    console.log("payload", payload);
    const updateAttributePromise = createAttribute(fetchProps);
    updateAttributePromise
      .then(() => {
        const readAllAdminViewsPromise = readAdminByOrg({});
        readAllAdminViewsPromise
          .then((data) => {
            let newData = {
              result: data.result.data,
              status: "success",
              messsage: "",
            };
            ReduxDispatcher(updateData("views", newData));

            ReduxDispatcher(updateUserScreen("isDashboardLoading", false));

            setShowForm(false);
          })
          .catch((json) => {
            let newData = {
              result: [],
              status: "error",
              messsage: "Views API failed",
            };
            ReduxDispatcher(updateData("views", newData));
            ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
          });
      })
      .catch((json) => {
        ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
      });
  };
  const handleUpdate = () => {
    let isGroupChanged = !isEqual(
      originalSelections.current.groups.value,
      selections.groups.value
    );

    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    // // TODO: Make API call to save attribute
    let payload = wrapperCustomMetricForm({ ...selections });
    let fetchProps = {
      payload,
      type: "cm",
    };
    console.log("payload", payload);
    const updateAttributePromise = updateAttribute(fetchProps);
    updateAttributePromise
      .then(() => {
        if (isGroupChanged) {
          let payload = wrapperGroup({ ...selections });
          let fetchProps = {
            payload,
          };
          const updateAttributeGroupPromise = updateAttributeGroup(fetchProps);
          updateAttributeGroupPromise
            .then(() => {
              const readAllAdminViewsPromise = readAdminByOrg({});
              readAllAdminViewsPromise
                .then((data) => {
                  let newData = {
                    result: data.result.data,
                    status: "success",
                    messsage: "",
                  };
                  ReduxDispatcher(updateData("views", newData));
                  ReduxDispatcher(
                    updateUserScreen("isDashboardLoading", false)
                  );

                  setShowForm(false);
                })
                .catch((json) => {
                  let newData = {
                    result: [],
                    status: "error",
                    messsage: "Views API failed",
                  };
                  ReduxDispatcher(updateData("views", newData));
                  ReduxDispatcher(
                    updateUserScreen("isDashboardLoading", false)
                  );
                });
            })
            .catch((json) => {
              ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
            });
        } else {
          const readAllAdminViewsPromise = readAdminByOrg({});
          readAllAdminViewsPromise
            .then((data) => {
              let newData = {
                result: data.result.data,
                status: "success",
                messsage: "",
              };

              ReduxDispatcher(updateData("views", newData));

              // update screen
              const activeTab = "attributes";
              const value = { activeNav: "admin", activeTab };
              var action = updateUserScreen(null, value);
              ReduxDispatcher(action);

              ReduxDispatcher(updateUserScreen("isDashboardLoading", false));

              setShowForm(false);
            })
            .catch((json) => {
              let newData = {
                result: [],
                status: "error",
                messsage: "Views API failed",
              };
              ReduxDispatcher(updateData("views", newData));
              ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
            });
        }
      })
      .catch((json) => {
        ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
      });
  };

  const handleClickAway1 = () => {
    const { status: nameStatus, message: nameMessage } = validateAttributeName(
      name.value
    );

    // 1. If name is same as before, don't call the API
    if (originalSelections.current.name.value === name.value) {
      setName((prevState) => ({
        ...prevState,
        value: originalSelections.current.name.value,
        originalValue: originalSelections.current.name.value,
      }));
      const payload = {
        key: "name",
        value: { ...originalSelections.current.name },
      };
      const action = updateAttributeCustomMetricFormWholeKey(payload);
      dispatch(action);
    } else {
      if (nameStatus === "invalid") {
        setName((prevState) => ({
          ...prevState,
          status: "error",
          message: nameMessage,
        }));
        var payload = {
          key: "name",
          value: {
            message: nameMessage,
            status: "invalid",
            value: name.value,
          },
        };
        var action = updateAttributeCustomMetricFormWholeKey(payload);
        dispatch(action);
        // var snackbarPayload = {
        //   open: true,
        //   message: nameMessage,
        // };
        // var action = updateUserScreen("snackbar", snackbarPayload);
        // ReduxDispatcher(action);
      } else {
        // 3. If the group name is valid, call the API and reset state based on response
        setName((prevState) => ({
          ...prevState,
          status: "loading",
        }));
        console.log("name", name);

        // API to check group name availability
        const fetchProps = {
          orgViewReq: {
            organization: "OpenX",
            view: selections.activeView.value,
          },
          title: name.value,
          attributeType: "customMetric",
        };
        const nameCheckAttributePromise = nameCheckAttribute(fetchProps);
        nameCheckAttributePromise
          .then((reponseData) => {
            if (reponseData.status.statusCode === "200") {
              setName((prevState) => ({
                ...prevState,
                status: "success",
                originalValue: prevState.value,
              }));
              var payload = {
                key: "name",
                value: {
                  message: nameMessage,
                  status: "valid",
                  value: name.value,
                },
              };
              var action = updateAttributeCustomMetricFormWholeKey(payload);
              dispatch(action);
            } else {
              // let snackbarPayload = {
              //   open: true,
              //   message: "Requested group name failed",
              // };
              // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
            }
          })
          .catch((json) => {
            setName((prevState) => ({
              ...prevState,
              status: "error",
              message: json.error,
            }));

            var payload = {
              key: "name",
              value: {
                message: json.error,
                status: "invalid",
                value: name.value,
              },
            };
            var action = updateAttributeCustomMetricFormWholeKey(payload);
            dispatch(action);

            // let snackbarPayload = {
            //   open: true,
            //   message: json.error,
            // };
            // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
          });
      }
    }
  };

  const isValid =
    isSelectionsInvalid({
      name: selections.name,
      // backendName: selections.backendName,
      // dataUnitType: selections.dataUnitType,
    }).status === "valid";

  const areSelectionsEqual = isEqual(originalSelections.current, selections);

  const isUpdateDisabled = areSelectionsEqual ? true : !isValid ? true : false;

  // Styles
  const box1 = {
    padding: "0px 0px 0px 20px",
    height: "50px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
  };
  const box2 = {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // padding: "50px 0px 0px 0px",
    // width: "500px",
    // paddingBottom: "150px",
    height: "calc(100% - 100px)",
    boxSizing: "border-box",
    width: "100%",
    overflow: "auto",
  };
  const fieldBox = {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    width: "100%",
    boxSizing: "border-box",
    // justifyContent: "space-between",
  };
  const fieldBox2 = {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    width: "520px",
    boxSizing: "border-box",
    justifyContent: "space-between",
  };

  const formTitleStyling = {
    height: "max-content",
    padding: "0px 10px 0px 0px",
  };
  const buttonContainer = {
    height: "50px",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px 10px 0px",
    justifyContent: "flex-end",
    boxSizing: "border-box",
  };

  const typographyStyle = {
    padding: "5px 10px 0px 0px",
  };
  const buttonStyle = { width: "auto", margin: { left: "3px", right: "3px" } };

  const textFieldCustomStyle = {
    textFieldFontSize: "12px",
    wrapperBorder: `1px solid ${themeColors["secondaryColorLighter"]}`,
    wrapperWidth: "300px",
    wrapperHeight: "25px",
    margin: "0px 5px 0px 0px",
    textFieldFontSize: "10px",
    placeholderFontSize: "10px",
  };
  const jpStyle = {
    fontSize: "16px",
    color: "#46596a",
    height: "25px",
    fontWeight: "400",
    letterSpacing: "0.0125em",
    lineHeight: "1.5",
  };
  const enteredFormulaBox = {
    border: `1px solid ${themeColors["secondaryColorLighter"]}`,
    padding: "5px",
    fontSize: "10px",
    borderRadius: "3px",
    color: themeColors["secondaryColor"],
    width: "300px",
    boxSizing: "border-box",
    background: themeColors["primaryColorLightest"],
    cursor: "not-allowed",
    wordWrap: "break-word",
  };

  return (
    <>
      <Box css={box1}>
        {(selections.crudType === "create" && (
          <p style={jpStyle}>
            {t("Add New Custom Metric")} {t("for")}{" "}
            {selections.activeView?.value}
          </p>
        )) || (
          <p style={jpStyle}>
            {t("Update Custom Metric:")} {selections.name?.value} {t("for")}{" "}
            {selections.activeView?.value}
            {/* </SigviewTypography> */}
          </p>
        )}
      </Box>

      <Box css={box2} className="sigview-styled-scroller">
        {selections.crudType === "update" && (
          <Box css={fieldBox}>
            <SigviewTypography style={typographyStyle}>
              {t("Groups:")}
            </SigviewTypography>

            {groupData.status === "loading" &&
              selections.crudType === "update" && (
                <Box
                  css={{
                    width: "400px",
                    paddingLeft: "110px",
                  }}
                >
                  <SigviewProgressBar />
                </Box>
              )}
            {groupData.status === "error" &&
              selections.crudType === "update" && (
                <ErrorHandler
                  message="Error in fetching group data"
                  reloadFlag={true}
                  onReload={() => {}}
                />
              )}
            {groupData.status === "success" && (
              <Box
                css={{
                  paddingLeft: "93px",
                  width: "calc(100% - 140px)",
                }}
              >
                <SigviewMultiSelectDnD
                  title=""
                  data={groupsData.filter((row) =>
                    availableGroupData.includes(row._id)
                  )}
                  value={selections.groups?.value}
                  onChange={handleGroupsChange}
                />
              </Box>
            )}
          </Box>
        )}

        <Box css={{ ...fieldBox2, width: "518px" }}>
          <SigviewTypography style={typographyStyle}>
            {t("Name:")}
          </SigviewTypography>

          <Box
            css={{
              display: "flex",
            }}
          >
            <SigviewTextFieldAsync
              value={name.value}
              onChange={handleNameChange}
              status={name.status}
              width="300px"
              onClickAway={handleClickAway1}
              customStyle={textFieldCustomStyle}
              tooltipTitle={t("Type and press enter to check if name is valid")}
              errorFlag={selections.name.status === "invalid"}
              errorMessage={selections.name.message}
            />
            <Box
              css={{ height: "25px", display: "flex", alignItems: "center" }}
            >
              <SigviewHoverPopup
                title={t("Name Rules")}
                data={config.hardCoded.attributeNameRules}
              >
                <SigviewIcon className="material-icons-round" iconName="info" />
              </SigviewHoverPopup>
            </Box>
          </Box>
        </Box>

        {/* {formulaData.status === "loading" && (
          <Box
            css={{
              width: "300px",
              paddingLeft: "180px",
            }}
          >
            <SigviewProgressBar />
          </Box>
        )}
        {formulaData.status === "error" && (
          <ErrorHandler
            message="Error in fetching group data"
            reloadFlag={true}
            onReload={() => {}}
          />
        )}
        {formulaData.status === "success" && ( */}
        <Box css={{ ...fieldBox2, width: "496px" }}>
          <SigviewTypography style={typographyStyle}>
            {t("Formula Type:")}
          </SigviewTypography>
          <SigviewSingleSelect
            value={selections.formulaType.value}
            data={formulaData}
            onChange={handelFormulaChange}
            minWidth="300px"
          />
        </Box>
        {/* )} */}

        <Box css={{ ...fieldBox2, width: "496px" }}>
          <SigviewTypography style={typographyStyle}>
            {t("Data Unit Type:")}
          </SigviewTypography>
          <SigviewSingleSelect
            value={selections?.dataUnitType?.value}
            data={config.hardCoded.attributes.metricDatatype}
            onChange={handleDataTypeChange}
            disabled={selections.crudType === "update"}
            minWidth="300px"
          />
        </Box>

        {(selections?.dataUnitType?.value === "percent" ||
          selections?.dataUnitType?.value === "currency") && (
          <Box css={{ ...fieldBox2, width: "496px" }}>
            <SigviewTypography style={typographyStyle}>
              {t("Data Unit Symbol:")}
            </SigviewTypography>
            <SigviewSingleSelect
              value={selections?.dataUnitSymbol?.value}
              data={
                config.hardCoded.attributes.metricDataSymbol[
                  selections?.dataUnitType?.value
                ]
              }
              onChange={handleDataSymbolChange}
              minWidth="300px"
            />
          </Box>
        )}

        {Object.keys(selections.formulaEntity).map((row) => {
          return (
            <Box key={row} css={{ ...fieldBox2, width: "496px" }}>
              <SigviewTypography style={typographyStyle}>
                t({row}):
              </SigviewTypography>

              <Box>
                <SigviewSingleSelect
                  data={usableAttributeNames}
                  value={selections.formulaEntity[row].value.id}
                  onChange={(value) => {
                    handleFormEntryChange(row, value);
                  }}
                  minWidth={
                    selections.formulaEntity[row].value.id === "numeric"
                      ? "150px"
                      : "300px"
                  }
                />

                {selections.formulaEntity[row].value.id === "numeric" && (
                  <SigviewTextField
                    placeholder={t("Enter Value")}
                    width="50px"
                    onChange={(value) => {
                      console.log("value", value);
                      let valueType = "numeric";
                      handleFormEntryChange(row, value, valueType);
                    }}
                    value={selections.formulaEntity[row].value.num || 0}
                    minWidth="150px"
                  />
                )}
              </Box>
            </Box>
          );
        })}

        <Box css={{ ...fieldBox2, width: "496px" }}>
          <SigviewTypography style={typographyStyle}>
            {t("Entered Formula:")}
          </SigviewTypography>
          {/* <SigviewTextField
            readOnly={true}
            value={selections.enteredFormula.value}
            width="300px"
          /> */}

          <Box css={enteredFormulaBox}>{selections.enteredFormula.value}</Box>
        </Box>
        <Box css={{ ...fieldBox2, justifyContent: "flexStart" }}>
          <SigviewCheckboxGroup
            data={[{ id: "", name: "", checked: selections.isAdditive.value }]}
            customID="AdminIsAdditive-GA"
            onChange={() => {
              handleAdditiveChange(!selections.isAdditive.value);
            }}
          />
          <SigviewTypography style={{ padding: "7px 10px 0px 0px" }}>
            {t("Is Additive?")}
          </SigviewTypography>
        </Box>
      </Box>

      <Box css={buttonContainer}>
        <SigviewButton
          title={selections.crudType === "create" ? "Save" : "Update"}
          variant="contained"
          onClick={selections.crudType === "create" ? handleSave : handleUpdate}
          style={buttonStyle}
          disabled={isUpdateDisabled}
          customClassName={`${
            selections.crudType === "create"
              ? `Add-${name.value}-GA  add-new-customMetric-GA`
              : `Edit-${name.value}-GA  update-customMetric-GA`
          }`}
        />
      </Box>
    </>
  );
}

export default CustomMetricForm;
