// * Import required libraies
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// * Import Custom Component
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Sidenav from "../../components/Sidenav/Sidenav";
import Loader from "../../components/Loader/Loader";
import SigviewTypography from "../../components/Common/SigviewTypography";

const PageTitle = (props) => {
  const { title = "Sample Page Title", variant = "pLarger", children } = props;
  return (
    <article className="main-content-page-title">
      <SigviewTypography variant={variant}>{title}</SigviewTypography>
      {children}
    </article>
  );
};

function LayoutTopSideBottom(props = {}) {
  const { user = {}, children = <></>, sidenavProps = {} } = props;
  return (
    <>
      {user.screen.isDashboardLoading && (
        <section className={`dashboard-loader-container`}>
          <Loader />
        </section>
      )}
      <div className={`page layout-top-side-bottom`}>
        <Header />
        <main>
          <section className={`sidenav`}>
            <Sidenav {...sidenavProps} />
          </section>
          <section className={`content`}>
            <section className={`main-content-data`}>{children}</section>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
}

LayoutTopSideBottom.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({ user: state.user });

export { PageTitle };

export default connect(mapStateToProps)(LayoutTopSideBottom);
