// * Import required lib
import moment from "moment";
import { v4 } from "uuid";

// * Import required utils
import staticChartJson from "../../assets/data/chartTitle.json";
import { config } from "../config/config";

const initialState = {
  status: "loading", // loading/success/error/initial/accessError
  message: "",
  result: {},
};
const defaultQeData = {
  status: "loading",
  message: "",
  result: { dataFromQE: [], extraData: {} },
};
const defaultKpiData = {
  status: "loading",
  message: "",
  result: { value: 0, displayValue: "0" },
};
const initialPageStatus = {
  status: "loading",
  message: "",
};
// ! PLEASE NOTE THAT THESE ARE JUST A VALID TIME FILTERS STRUCTURE
// ! DO NOT USE THIS ANYWHERE IN THE APP BECAUSE DATE RANGE WILL CHANGE THIS
// ! USING THIS JUST FOR MAKING DEFAULT VALID STANDALONE WS
const sampleValidTimeFilters = {
  isLoading: false,
  selectedTimezone: {
    name: "UTC (+00:00)",
    location: "UTC",
    value: {
      hours: 9,
      minutes: 0,
    },
    minutesOffset: 0,
  },
  validDates: {
    startDate: {
      epoch: 1491004800000,
      timezone: "UTC (+00:00)",
      nativeDateObj: "2017-03-31T18:30:00.000Z",
      formattedDate: "Sat April 01, 2017 00:00:00 +0000",
    },
    endDate: {
      epoch: 1493593200000,
      timezone: "UTC (+00:00)",
      nativeDateObj: "2017-04-30T17:30:00.000Z",
      formattedDate: "Sun April 30, 2017 23:00:00 +0000",
    },
  },
  selectedDates: {
    startDate: {
      timezone: {
        name: "UTC (+00:00)",
        location: "UTC",
        value: {
          hours: 9,
          minutes: 0,
        },
        minutesOffset: 0,
      },
      epoch: 1493424000000,
      nativeDateObj: "2017-04-28T18:30:00.000Z",
      formattedDate: "Sat April 29, 2017 00:00:00 +0000",
    },
    endDate: {
      timezone: {
        name: "UTC (+00:00)",
        location: "UTC",
        value: {
          hours: 9,
          minutes: 0,
        },
        minutesOffset: 0,
      },
      epoch: 1493593200000,
      nativeDateObj: "2017-04-30T17:30:00.000Z",
      formattedDate: "Sun April 30, 2017 23:00:00 +0000",
    },
    valid: true,
  },
  validDatePresets: [
    {
      id: "custom",
      name: "Custom",
      available: true,
      priority: 13,
    },
  ],
  selectedDatePreset: "custom",
  selectedDatesQE: {
    startDate: 1493424000000,
    endDate: 1493596800000,
  },
  compareDates: [
    {
      id: "d009fe3f-ad26-41c8-998b-b2bddf8fab76",
      compareSelectedDates: {
        startDate: {
          epoch: 1493251200000,
          timezone: {
            name: "UTC (+00:00)",
            location: "UTC",
            value: {
              hours: 9,
              minutes: 0,
            },
            minutesOffset: 0,
          },
          nativeDateObj: "2017-04-26T18:30:00.000Z",
          formattedDate: "Thu April 27, 2017 00:00:00 +0000",
        },
        endDate: {
          epoch: 1493423999999,
          timezone: {
            name: "UTC (+00:00)",
            location: "UTC",
            value: {
              hours: 9,
              minutes: 0,
            },
            minutesOffset: 0,
          },
          nativeDateObj: "2017-04-28T18:29:59.999Z",
          formattedDate: "Fri April 28, 2017 23:59:59 +0000",
        },
        valid: true,
      },
      compareSelectedDatesSameDuration: {
        startDate: {
          epoch: 1493251200000,
          timezone: {
            name: "UTC (+00:00)",
            location: "UTC",
            value: {
              hours: 9,
              minutes: 0,
            },
            minutesOffset: 0,
          },
          nativeDateObj: "2017-04-26T18:30:00.000Z",
          formattedDate: "Thu April 27, 2017 00:00:00 +0000",
        },
        endDate: {
          epoch: 1493420400000,
          timezone: {
            name: "UTC (+00:00)",
            location: "UTC",
            value: {
              hours: 9,
              minutes: 0,
            },
            minutesOffset: 0,
          },
          nativeDateObj: "2017-04-28T17:30:00.000Z",
          formattedDate: "Fri April 28, 2017 23:00:00 +0000",
        },
        valid: true,
      },
      compareValidDates: {
        startDate: {
          epoch: 1491004800000,
          timezone: "UTC (+00:00)",
          nativeDateObj: "2017-03-31T18:30:00.000Z",
          formattedDate: "Sat April 01, 2017 00:00:00 +0000",
        },
        endDate: {
          epoch: 1493510399999,
          timezone: "UTC (+00:00)",
          nativeDateObj: "2017-04-29T18:29:59.999Z",
          formattedDate: "Sat April 29, 2017 23:59:59 +0000",
        },
      },
      compareSelectedDatesQE: {
        startDate: 1493251200000,
        endDate: 1493424000000,
      },
      compareSelectedDatesQESameDuration: {
        startDate: 1493251200000,
        endDate: 1493424000000,
      },
      compareValidDatePresets: [
        {
          id: "custom",
          name: "Custom",
          available: true,
          priority: 1,
        },
        {
          id: "previous_week",
          name: "Previous Week",
          available: true,
          priority: 3,
        },
      ],
      compareSelectedDatePreset: "custom",
      isSelected: false,
    },
  ],
  isComparisonOn: false,
  format: "ddd MMMM DD, YYYY HH:mm:ss ZZ",
};

const analyze = {};

// ! This won't work correctly as timeFilters are incorrect here
const defaultStandaloneChartData = {
  id: `chart-object-${v4()}`,
  title: `Untitled Chart - ${moment(Date.now()).format(
    config.hardCoded.defaultStandaloneChartTimestampFormat
  )}`,
  dimensionsList: [],
  metricsList: [],
  orderById: "",
  orderBy: "asc",
  chartType: "bar",
  metricFilters: [],
  dimensionFilters: [],
  timeFilters: {}, // ! Invalid
  chartList: staticChartJson,
  elementType: "create", // create or update to help differentiate in PlotCreate,
  renderFlag: false, // to control rendering of the chart in the component
};

const SIGVIEW_CONTANTS = {
  initialState,
  defaultQeData,
  initialPageStatus,
  analyze,
  initialPageStatus,
  defaultStandaloneChartData,
  defaultKpiData,
  sampleValidTimeFilters,
};

export default SIGVIEW_CONTANTS;
