// Import required libraries
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Import custom components
import Nav from "../Nav/Nav";
import UserInfo from "../UserInfo/UserInfo";
import ClientLogo from "../ClientLogo/ClientLogo";
// import ThemeSelector from "../ThemeSelector/ThemeSelector";
import SigviewVR from "../Common/SigviewVR";
import ViewSelection from "../ViewSelection/ViewSelection";
import OrgSelection from "../OrgSelection/OrgSelection";
import Overlay from "../Overlay/Overlay";
import DataLagReload from "../DataLagReload/DataLagReload";
import DataLag from "../DalaLag/DataLag";
import BannerDisplay from "../BannerDisplay/BannerDisplay";
import DashboardLastSaved from "../Common/DashboardLastSaved/DashboardLastSaved";
import LanguageSelection from "../LanguageSelection/LanguageSelection.js";

// Import styles
import "./Header.scss";

// Import config

// Import action creators

// Import utils

function Header(props = {}) {
  const { user } = props;
  const isOverlayAvailable = user.uiFeatureList.overlayUpdates;
  const isDataLagReloadAvailable = user.uiFeatureList.forcedRefreshToast;
  const isAutosaveOn = user.screen.workspaceAutoSaveStatus;
  const isi18nAvailable = user.uiFeatureList.i18n;

  return (
    <>
      <header>
        <div className={`left-panel`}>
          <ClientLogo />
          <Nav />
        </div>
        <BannerDisplay />
        <div className={`right-panel`}>
          {isAutosaveOn && (
            <>
              <DashboardLastSaved />
              <SigviewVR />
            </>
          )}
          <DataLag />
          {isDataLagReloadAvailable && <DataLagReload />}
          {/* <SigviewVR />
          <ThemeSelector /> */}
          {isOverlayAvailable && (
            <>
              <SigviewVR />
              <Overlay />
            </>
          )}
          {isi18nAvailable && (
            <>
              <SigviewVR /> <LanguageSelection />
            </>
          )}
          <SigviewVR />
          <OrgSelection />
          <SigviewVR />
          <ViewSelection />
          <SigviewVR />
          {user.reqMetadata && <UserInfo />}
        </div>
      </header>
    </>
  );
}

Header.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  changeFlag: state.changeFlag,
});

export default connect(mapStateToProps)(Header);
