// * Import required libraries
import React, { useState, useContext, useEffect } from "react";

// * Import lib components
import { Checkbox, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// * Import custom components
import ChecklistRowMetric from "../AddDimensionDrawer/ChecklistRowMetric";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Define required variables
const makeSigviewStyles = (...args) => {
  const [themeColors, style = {}] = args;
  const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
      flexDirection: "column",
      color: themeColors["sidenavItemColor"],
      // backgroundColor: themeColors["sidenavBgColor"],
      height: "100%",
      // padding: "10px 12px",
      boxSizing: "border-box",
      width: "100%",
    },
    itemBase: { display: "flex", alignItems: "center", width: "100%" },
    checkboxItemLabel: {
      fontSize: "12px",
      color: themeColors["secondaryColor"],
      paddingLeft: "0px",
      paddingRight: "2px",
      boxSizing: "border-box",
      width: "calc(100% - 18px - 16px)",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "inline-block",
      overflow: "hidden",
      cursor: "pointer",
    },
    checkboxItemLabelSelected: { color: themeColors["primaryColor"] },
    disabledItem: {
      opacity: 0.5,
      pointerEvents: "none",
    },
  }));
  return useStyles;
};
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const getItemStyle = ({ isDragging, draggableStyle, customStyle }) => {
  const backgroundColor = "transparent";
  const backgroundColorDuringDragging =
    customStyle?.backgroundColorDuringDragging || "lightblue";
  const marginRight = "0px";
  const marginBottom = "0px";
  const borderRadius = "0px";
  const border = "0px solid transparent";
  const boxSizing = "border-box";

  return {
    // change background colour if dragging
    background: isDragging ? backgroundColorDuringDragging : backgroundColor,

    // add custom styles
    marginRight,
    marginBottom,
    borderRadius,
    border,
    boxSizing,

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};
const getListStyle = (props) => {
  const { isDraggingOver, direction, customStyle } = props;

  const flexDirectionMapping = {
    vertical: "column",
    horizontal: "row",
  };

  const backgroundColor = customStyle?.backgroundColor || "lightgrey";
  const backgroundColorDuringDragging =
    customStyle?.backgroundColorDuringDragging || "lightblue";
  // const overflow = customStyle?.overflow || "auto";

  return {
    background: isDraggingOver
      ? backgroundColorDuringDragging
      : backgroundColor,
    display: "flex",
    flexDirection: flexDirectionMapping[direction] || "column",
    padding: customStyle?.padding || 8,
    width: "100%",
  };
};
const defaultData = [
  { id: "D001", name: "Short Name", disabled: false },
  { id: "D002", name: "Looooooooong Name", disabled: false },
  {
    id: "D003",
    name: "Verrrrrrrryyyyyyyy Loooooooooooonnnnnnngggggggggg Nammmmmmmmmmmmmmme",
    disabled: false,
  },
  { id: "D004", name: "D004", disabled: false },
];
const defatulSelections = ["D001", "D002"];

function SigviewDndChecklist(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Destructure props
  const {
    data = defaultData,
    initialSelections = defatulSelections,
    onChange = () => {},
    settings = { atLeastOneItem: true },
    style = {},
  } = props;

  // * Define requried states
  const [selections, setSelections] = useState(initialSelections);

  // * Define required side effects
  useEffect(() => {
    onChange(selections);
  }, [selections]);

  // * Define required event handlers
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const reorderedSelectedItems = reorder(
      selections,
      result.source.index,
      result.destination.index
    );
    setSelections(reorderedSelectedItems);
  };
  const onCheckboxChange = (event, itemId, flag) => {
    if (flag) {
      setSelections((selections) => [...selections, itemId]);
    } else {
      setSelections((selections) => selections.filter((id) => itemId !== id));
    }
  };

  // * Define required static variables
  var selectedDataItems = selections.map((id) =>
    data.find((row) => row.id === id)
  );
  // Remove undefined elements
  // Bug occurs when data is empty (when user quickly types in the search box)
  selectedDataItems = selectedDataItems.filter((row) => row !== undefined);
  const isRemainingDataItemOnlyOne = selections.length === 1;
  if (isRemainingDataItemOnlyOne && settings.atLeastOneItem) {
    selectedDataItems = selectedDataItems.map((row) => ({
      ...row,
      disabled: true,
    }));
  }

  const remainingDataItems = data.filter(
    (item) => !selections.includes(item.id)
  );
  // Styling
  const SigviewCheckbox = withStyles(() => ({
    root: {
      opacity: 1,
      color: `${themeColors["primaryColor"]} !important`,
      padding: "0px !important",
      marginRight: "5px",
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18,
      },
      "&.MuiCheckbox-colorSecondary.Mui-checked:hover": {
        backgroundColor: "transparent",
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  }))(Checkbox);
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const droppableStyle = {
    padding: "0px",
    backgroundColorDuringDragging: "transparent",
    backgroundColor: "transparent",
  };
  const draggableStyle = {
    backgroundColorDuringDragging: themeColors["secondaryColorLightest"],
    backgroundColor: themeColors["secondaryColorLightest"],
    marginRight: "0px",
    marginBottom: "0px",
    borderRadius: "3px",
    border: `1px solid ${themeColors["secondaryColorLighter"]}`,
  };

  // // * DEBUGGER
  // console.groupCollapsed("SigviewDndChecklist.js");
  // console.log("data", data);
  // console.log("selections", selections);
  // console.log("selectedDataItems", selectedDataItems);
  // console.log("remainingDataItems", remainingDataItems);
  // console.groupEnd();

  return (
    <Box className={classes.root}>
      <Box className={classes.selectedItemsContainer}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle({
                  isDraggingOver: snapshot.isDraggingOver,
                  direction: "vertical",
                  customStyle: droppableStyle,
                })}
                {...provided.droppableProps}
              >
                {selectedDataItems.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle({
                          isDragging: snapshot.isDragging,
                          draggableStyle: provided.draggableProps.style,
                          customStyle: draggableStyle,
                        })}
                      >
                        <ChecklistRowMetric
                          item={item}
                          variant="selected"
                          provided={provided}
                          setSelections={setSelections}
                          selections={selections}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box className={classes.remainingItemsContainer}>
        {remainingDataItems.map((item, index) => (
          <ChecklistRowMetric
            key={item.id}
            item={item}
            variant="remaining"
            setSelections={setSelections}
            selections={selections}
          />
        ))}
      </Box>
    </Box>
  );
}

export default SigviewDndChecklist;
