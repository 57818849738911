// *  Import required libraies
import React, { useState, useContext } from "react";
import { v4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

// * Import lib components
import { Box } from "@material-ui/core";
import { Popover } from "@material-ui/core";

// * Import Custom Component
import Loader from "../../../components/Loader/Loader";
import SigviewTextField from "../../../components/Common/SigviewTextField";
import SigviewButton from "../../../components/Common/SigviewButton";
import SigviewSimpleText from "../../../components/Common/SigviewSimpleText";
import SigviewTooltip from "../../../components/Common/SigviewTooltip";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SigviewSingleSelect from "../../../components/Common/SigviewSingleSelect";
import SigviewRadioGroup from "../../../components/Common/SigviewRadioGroup";
import SigviewIcon from "../../../components/Common/SigviewIcon";

// * Import action creators
import { updateUserScreen } from "../../../redux/actions";

// * Import utils/data
import {
  validateChartName,
  wrapperOrgViewReq,
} from "../../../utils/chartObjectUtils";
import { config } from "../../../config/config";
import { masterTrackGaEvent } from "../../../services/ga";
import { getCustomColorPallete } from "../../../utils/plotlyUtils";
import { wrapperWs } from "../../../utils/analyzeUtils";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Import API functions
import {
  checkWorksheetName,
  getGroupDefaultWorksheet,
  renameWorksheet,
  saveWs,
} from "../../../services/api";

// * Define style functions
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles({
    renameRoot: {
      padding: "20px",
      maxWidth: "445px",
    },
    renameMenuBar: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "15px",
    },
    postRenameRow: {
      padding: "10px",
      width: "350px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
  });
  return useStyles;
};

const SigviewDialogClone = (props) => {
  // * Destructure props
  const {
    user = {},
    ReduxDispatcher = () => {},
    initialName = "",
    cloneProps = {},
    onSuccess = () => {},
    onCloneLaunch = () => {},
    allData = {},
    wsForm = {},
  } = props;

  // * Define required state
  const [name, setName] = useState({
    value: initialName,
    status: "success", // success/error/loading
    message: "",
  });

  // * Define required event handlers
  const handleNameChange = (newValue) => {
    setName(() => {
      const { status, message } = validateChartName(newValue);
      let finalStatus = "success";
      if (status === "invalid") finalStatus = "error";
      const newState = { status: finalStatus, message, value: newValue };
      return newState;
    });
  };

  const handleCancel = () => {
    const payload = { open: false, children: null };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleError = (message) => {
    setName((prevState) => ({
      ...prevState,
      status: "error",
      message: message,
    }));
  };

  const handleClone = (type = "clone") => {
    // Google Analytics Event - Master
    // masterTrackGaEvent({
    //   category: "DatastoryDashboard",
    //   action: "CloneMenu",
    //   label: type,
    // });

    // Set status to loading to show a loader in the rename button
    setName((prevState) => ({
      ...prevState,
      status: "loading",
    }));

    const fetchPayload = {
      clickedFromMenubar: false,
      workbookId: cloneProps.workbook._id,
      worksheetName: name.value,
    };
    const fetchProps = {
      payload: fetchPayload,
    };
    const checkWoksheetNamePromise = checkWorksheetName(fetchProps);
    checkWoksheetNamePromise
      .then((responseData) => {
        // Worksheet name exits
        if (!responseData.result.data.status) {
          const workbookId = cloneProps.workbook._id;
          var backendWs = wrapperWs({ user, allData, wsForm, workbookId });

          // Update required fields in clone
          backendWs = {
            ...backendWs,
            worksheet: {
              ...backendWs.worksheet,
              isActive: false,
              sheetName: name.value,
              sheetId: v4(),
            },
          };
          const fetchProps = {
            payload: { ...backendWs },
          };
          const saveWsPromise = saveWs(fetchProps);
          saveWsPromise
            .then((responseData2) => {
              if (responseData2.status?.statusCode === "200") {
                // Update local status to success
                setName((prevState) => ({
                  ...prevState,
                  status: "success",
                }));
                // Close the dialog
                handleCancel();
                // Update snackbar
                const snackbarPayload = {
                  ...user.screen.snackbar,
                  open: true,
                  message: "Workspace created successfully!",
                };
                var action = updateUserScreen("snackbar", snackbarPayload);
                ReduxDispatcher(action);

                // If type is cloneLaunch, open the workspace
                if (type === "cloneLaunch") {
                  onCloneLaunch(cloneProps.workbook, backendWs.worksheet);
                } else {
                  // Reload workbook reload
                  onSuccess();
                }
              } else {
                handleError(
                  responseData2?.status?.statusMessage ||
                    config.hardCoded.uiErrorMessage
                );
              }
            })
            .catch((json) => {
              console.error("UI ERROR - API FAILED");
              console.groupCollapsed("DETAILS");
              console.log("Error JSON -> ", json);
              console.groupEnd();
              handleError(json.error || config.hardCoded.uiErrorMessage);
            });
        } else {
          // ! INCORRECT RESPONSE STRUCTURE (HENCE COMMENTING)
          handleError("Workspace name already exists");
          // handleError(
          //   responseData?.status?.statusMessage ||
          //     "Workspace name already exists"
          // );
        }
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        // ! INCORRECT RESPONSE STRUCTURE (HENCE COMMENTING)
        handleError("Workspace name check failed");
        // handleError(json.error || "Workspace name check failed");
      });
  };

  // * Define required variables
  const errorFlag = name.status === "error";
  const helperText = name.message;
  const cloneDisabled = ["error", "loading"].includes(name.status);
  const cloneTitle = name.status === "loading" ? <Loader /> : "Clone";
  const cloneLaunchTitle =
    name.status === "loading" ? <Loader /> : "Clone & Launch";

  return (
    <section className="sigview-dialog-chart-clone">
      <SigviewSimpleText
        value="Enter workspace name"
        style={{
          border: "none",
          fontSize: "16px",
          margin: "0px 0px 15px 0px",
          padding: "0px",
        }}
      />
      <SigviewTextField
        placeholder="Please enter a valid workspace name"
        value={name.value}
        onChange={(newValue) => handleNameChange(newValue)}
        error={errorFlag}
        helperText={helperText}
        minWidth="400px"
        maxWidth="400px"
        height="40px"
        fontSize="12px"
        margin={{ right: "5px", bottom: "10px" }}
      />
      <article className="sigview-dialog-chart-clone-menu-bar">
        <SigviewButton
          variant="outlined"
          onClick={handleCancel}
          title="Cancel"
          style={{ width: "auto", margin: { left: "3px", right: "3px" } }}
        />
        <SigviewButton
          variant="contained"
          onClick={() => handleClone("clone")}
          disabled={cloneDisabled}
          customClassName="WorkspaceClone-GA"
          title={cloneTitle}
          style={{ width: "auto", margin: { left: "3px", right: "3px" } }}
        />
        <SigviewButton
          variant="contained"
          onClick={() => handleClone("cloneLaunch")}
          disabled={cloneDisabled}
          customClassName="WorkspaceCloneLaunch-GA"
          title={cloneLaunchTitle}
          style={{
            width: "max-content",
            margin: { left: "3px", right: "3px" },
          }}
        />
      </article>
    </section>
  );
};

const SigviewDialogCreateNew = (props) => {
  // * Destructure props
  const {
    user = {},
    ReduxDispatcher = () => {},
    initialName = "",
    cloneProps = {},
    onSuccess = () => {},
    onCloneLaunch = () => {},
    allData = {},
    wsForm = {},
  } = props;

  // * Define required state
  const [name, setName] = useState({
    value: initialName,
    status: "success", // success/error/loading
    message: "",
  });

  // * Define required event handlers
  const handleNameChange = (newValue) => {
    setName(() => {
      const { status, message } = validateChartName(newValue);
      let finalStatus = "success";
      if (status === "invalid") finalStatus = "error";
      const newState = { status: finalStatus, message, value: newValue };
      return newState;
    });
  };

  const handleCancel = () => {
    const payload = { open: false, children: null };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleError = (message) => {
    setName((prevState) => ({
      ...prevState,
      status: "error",
      message: message,
    }));
  };

  const handleCreateNew = (type = "save") => {
    // Google Analytics Event - Master
    // masterTrackGaEvent({
    //   category: "DatastoryDashboard",
    //   action: "CloneMenu",
    //   label: type,
    // });

    // Set status to loading to show a loader in the rename button
    setName((prevState) => ({
      ...prevState,
      status: "loading",
    }));

    var fetchPayload = {
      clickedFromMenubar: false,
      workbookId: cloneProps.workbook._id,
      worksheetName: name.value,
    };
    var fetchProps = {
      payload: fetchPayload,
    };
    const checkWoksheetNamePromise = checkWorksheetName(fetchProps);
    checkWoksheetNamePromise
      .then((responseData) => {
        // Worksheet name exits
        if (!responseData.result.data.status) {
          var payload = {
            orgViewReq: wrapperOrgViewReq(user),
            email: user?.reqMetadata?.email,
            workbookId: cloneProps.workbook._id,
            worksheetName: name.value,
          };
          var fetchProps = {
            payload: payload,
          };
          const getGroupDefaultWorksheetPromise =
            getGroupDefaultWorksheet(fetchProps);
          getGroupDefaultWorksheetPromise
            .then((responseData) => {
              // TODO : Check if the worksheet is valid
              // TODO : If not, make one from dimension and metrics list
              let newDefaultWorksheet = responseData?.result.data[0];
              const isDefaultWorksheetValidFlag = true;
              console.log("newDefaultWorksheet", newDefaultWorksheet);
              if (isDefaultWorksheetValidFlag) {
                const fetchProps = {
                  payload: {
                    clickedsaveAs: false,
                    orgViewReq: wrapperOrgViewReq(user),
                    email: user?.reqMetadata?.email,
                    workbookId: cloneProps.workbook._id,
                    worksheet: newDefaultWorksheet,
                  },
                };
                const saveWsPromise = saveWs(fetchProps);
                saveWsPromise
                  .then((responseData2) => {
                    if (responseData2.status?.statusCode === "200") {
                      // Update local status to success
                      setName((prevState) => ({
                        ...prevState,
                        status: "success",
                      }));
                      // Close the dialog
                      handleCancel();
                      // Update snackbar
                      const snackbarPayload = {
                        ...user.screen.snackbar,
                        open: true,
                        message: "Workspace created successfully!",
                      };
                      var action = updateUserScreen(
                        "snackbar",
                        snackbarPayload
                      );
                      ReduxDispatcher(action);

                      // If type is cloneLaunch, open the workspace
                      if (type === "saveLaunch") {
                        // TODO : Can be done as an enhancement
                        // onCloneLaunch(cloneProps.workbook, backendWs.worksheet);
                      } else {
                        // Reload workbook reload
                        onSuccess();
                      }
                    } else {
                      handleError(
                        responseData2?.status?.statusMessage ||
                          config.hardCoded.uiErrorMessage
                      );
                    }
                  })
                  .catch((json) => {
                    console.error("UI ERROR - API FAILED");
                    console.groupCollapsed("DETAILS");
                    console.log("Error JSON -> ", json);
                    console.groupEnd();
                    handleError(json.error || config.hardCoded.uiErrorMessage);
                  });
              } else {
                // TODO
              }
            })
            .catch((json) => {
              if (json.error !== config.hardCoded.queryCancelled) {
                // TODO : Check if the worksheet is valid
                // TODO : If not, make one from dimension and metrics list

                console.groupCollapsed("UI ERROR");
                console.log("JSON", json);
                console.groupEnd();
              }
            });
        } else {
          // ! INCORRECT RESPONSE STRUCTURE (HENCE COMMENTING)
          handleError("Workspace name already exists");
          // handleError(
          //   responseData?.status?.statusMessage ||
          //     "Workspace name already exists"
          // );
        }
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        // ! INCORRECT RESPONSE STRUCTURE (HENCE COMMENTING)
        handleError("Workspace name check failed");
        // handleError(json.error || "Workspace name check failed");
      });
  };

  // * Define required variables
  const errorFlag = name.status === "error";
  const helperText = name.message;
  const cloneDisabled = ["error", "loading"].includes(name.status);
  const cloneTitle = name.status === "loading" ? <Loader /> : "Save";
  const cloneLaunchTitle =
    name.status === "loading" ? <Loader /> : "Save & Launch";

  return (
    <section className="sigview-dialog-chart-clone">
      <SigviewSimpleText
        value="Enter workspace name"
        style={{
          border: "none",
          fontSize: "16px",
          margin: "0px 0px 15px 0px",
          padding: "0px",
        }}
      />
      <SigviewTextField
        placeholder="Please enter a valid workspace name"
        value={name.value}
        onChange={(newValue) => handleNameChange(newValue)}
        error={errorFlag}
        helperText={helperText}
        minWidth="400px"
        maxWidth="400px"
        height="40px"
        fontSize="12px"
        margin={{ right: "5px", bottom: "10px" }}
      />
      <article className="sigview-dialog-chart-clone-menu-bar">
        <SigviewButton
          variant="outlined"
          onClick={handleCancel}
          title="Cancel"
          style={{ width: "auto", margin: { left: "3px", right: "3px" } }}
        />
        <SigviewButton
          variant="contained"
          onClick={() => handleCreateNew("save")}
          disabled={cloneDisabled}
          title={cloneTitle}
          style={{ width: "auto", margin: { left: "3px", right: "3px" } }}
        />
        {/* <SigviewButton
          variant="contained"
          onClick={() => handleClone("saveLaunch")}
          disabled={cloneDisabled}
          title={cloneLaunchTitle}
          style={{
            width: "max-content",
            margin: { left: "3px", right: "3px" },
          }}
        /> */}
      </article>
    </section>
  );
};

const SigviewDialogRename = (props) => {
  const {
    user = {},
    ReduxDispatcher = () => {},
    initialName = "",
    renameProps = {},
    themeColors = {},
    onSuccess = () => {},
  } = props;
  // * Define required state
  const [name, setName] = useState({
    value: initialName,
    status: "success", // success/error/loading
    message: "",
  });
  const [nameChanged, setNameChangedStatus] = useState(false);

  // * Define required event handlers
  const handleNameChange = (newValue) => {
    setName(() => {
      const { status, message } = validateChartName(newValue);
      let finalStatus = "success";
      if (status === "invalid") finalStatus = "error";
      const newState = { status: finalStatus, message, value: newValue };
      return newState;
    });
  };

  const handleCancel = () => {
    const payload = { open: false, children: null };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleError = (message) => {
    setName((prevState) => ({
      ...prevState,
      status: "error",
      message: message,
    }));
  };

  const handleRename = () => {
    // // Google Analytics Event - Master
    // masterTrackGaEvent({
    //   category: "DatastoryDashboard",
    //   action: "CloneMenu",
    //   label: type,
    // });

    // Set status to loading to show a loader in the rename button
    setName((prevState) => ({
      ...prevState,
      status: "loading",
    }));

    const fetchPayload = {
      clickedFromMenubar: false,
      workbookId: renameProps.workbook._id,
      worksheetName: name.value,
    };
    const fetchProps = {
      payload: fetchPayload,
    };
    const checkWoksheetNamePromise = checkWorksheetName(fetchProps);
    checkWoksheetNamePromise
      .then((responseData) => {
        // Worksheet name exits
        if (!responseData.result.data.status) {
          const fetchPayload = {
            emailId: user?.reqMetadata?.email,
            workbookId: renameProps.workbook._id,
            worksheetId: renameProps.worksheet.sheetId,
            clickedFromMenubar: false,
            worksheetName: name.value,
          };
          const fetchProps = {
            payload: fetchPayload,
          };
          const renameWorksheetPromise = renameWorksheet(fetchProps);
          renameWorksheetPromise
            .then((responseData2) => {
              if (responseData2.status?.statusCode === "200") {
                setNameChangedStatus(true);
                setName((prevState) => ({
                  ...prevState,
                  status: "success",
                }));
                onSuccess(renameProps.worksheet, name.value);
              } else {
                handleError(
                  responseData2?.status?.statusMessage ||
                    config.hardCoded.uiErrorMessage
                );
              }
            })
            .catch((json) => {
              console.error("UI ERROR - API FAILED");
              console.groupCollapsed("DETAILS");
              console.log("Error JSON -> ", json);
              console.groupEnd();
              handleError(json.error || config.hardCoded.uiErrorMessage);
            });
        } else {
          // ! INCORRECT RESPONSE STRUCTURE (HENCE COMMENTING)
          handleError("Workspace name already exists");
          // handleError(
          //   responseData?.status?.statusMessage ||
          //     "Workspace name already exists"
          // );
        }
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        handleError(json.error || "Rename failed");
      });
  };

  // * Define required variables
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const errorFlag = name.status === "error";
  const helperText = name.message;
  const renameDisabled = ["error", "loading"].includes(name.status);
  const renameTitle = name.status === "loading" ? <Loader /> : "Rename";
  const heading = `Rename Workspace "${renameProps.worksheet.sheetName}"`;

  return (
    <>
      {!nameChanged && (
        <Box component="section" className={classes.renameRoot}>
          <SigviewSimpleText
            value={heading}
            style={{
              border: "none",
              fontSize: "16px",
              margin: "0px 0px 15px 0px",
              padding: "0px",
              maxWidth: "400px",
            }}
          />
          <SigviewTextField
            placeholder="Please enter a valid workspace name"
            value={name.value}
            onChange={handleNameChange}
            error={errorFlag}
            helperText={helperText}
            minWidth="400px"
            maxWidth="400px"
            height="40px"
            margin={{ right: "5px", bottom: "10px" }}
            fontSize="12px"
          />
          <Box component="article" className={classes.renameMenuBar}>
            <SigviewButton
              customClassName="WorkspaceRenameCancel-GA"
              variant="outlined"
              onClick={handleCancel}
              title="Cancel"
              style={{ width: "auto", margin: { left: "3px", right: "3px" } }}
            />
            <SigviewButton
              customClassName="WorkspaceRenameSuccess-GA"
              variant="contained"
              onClick={handleRename}
              disabled={renameDisabled}
              title={renameTitle}
              style={{ width: "60px", margin: { left: "3px", right: "3px" } }}
            />
          </Box>
        </Box>
      )}

      {nameChanged && (
        <Box>
          <Box>
            <SigviewIcon
              iconName="check_circle"
              style={{
                fontSize: "50px !important",
                padding: "30px 20px 15px 20px",
                color: themeColors["primaryColor"],
                hoverColor: themeColors["primaryColor"],
                cursor: "default",
              }}
            />
          </Box>
          <Box className={classes.postRenameRow}>
            <SigviewTypography
              variant="pLarger"
              style={{ padding: "10px 50px 20px 50px" }}
            >
              Workbook renamed successfully!
            </SigviewTypography>
          </Box>
          <Box className={classes.postRenameRow}>
            <SigviewButton
              onClick={handleCancel}
              style={{
                btnWidth: "auto",
                btnHeight: "30px",
                fontSize: "14px !important",
                margin: { bottom: "20px" },
              }}
              title="OK"
              type="OK"
            />
          </Box>
        </Box>
      )}
    </>
  );
};

const WsSettingsIcon = (props = {}) => {
  // * Destructure props
  const { initialSelections = {}, onChange = () => {} } = props;

  // * Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define required states
  const [anchorEl, setAnchorEl] = useState(null);
  const [settingsSelections, setSettingsSelections] =
    useState(initialSelections);

  // * Define required event handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSettingsSelections(initialSelections);
  };
  const handleDsSettingsChange = (key, value) => {
    setSettingsSelections((prevState) => ({ ...prevState, [key]: value }));
  };
  const handleCancel = () => handleClose();
  const handleSave = () => {
    onChange(settingsSelections);
    setAnchorEl(null);
  };

  // * Define required variables
  const open = Boolean(anchorEl);
  const id = open ? "wsSettingsPopover" : undefined;
  const useStyles = makeStyles({
    root: {
      "& .MuiPaper-root ": {
        backgroundColor: "transparent !important",
      },
    },
  });
  const classes = useStyles();
  const containerCss = {
    backgroundColor: themeColors["mainContentBgColor"],
    padding: "10px 15px",
  };
  const footerCss = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  };
  const formRowCss = {
    marginBottom: "5px",
    display: "flex",
    alignItems: "center",
  };
  const dataLimitData = config.hardCoded.defaultDataLimitData; // Can be configured from backend
  const rollingDatesData = config.hardCoded.defaultRollingDatesData; // Can be configured from backend

  return (
    <>
      <SigviewTooltip title="Edit Settings" placement="bottom">
        <Box>
          <SigviewIcon
            className="material-icons-round"
            iconName="settings"
            style={{
              fontSize: "22px !important",
              padding: "0px 10px 0px 10px",
              color: themeColors["secondaryColorLight"],
              hoverColor: themeColors["primaryColor"],
              cursor: "pointer",
            }}
            onClick={handleClick}
          />
        </Box>
      </SigviewTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.root}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box css={containerCss}>
          <Box>
            <SigviewTypography
              variant="pLarge"
              style={{ color: themeColors["primaryColor"] }}
            >
              Settings
            </SigviewTypography>
          </Box>
          <Box>
            <Box css={formRowCss}>
              <SigviewTypography
                variant="pSmallMedium"
                style={{ height: "max-content", width: "100px" }}
              >
                Row Count:
              </SigviewTypography>
              <SigviewSingleSelect
                minWidth={"50px"}
                maxWidth={"50px"}
                value={settingsSelections.dataLimit}
                data={dataLimitData}
                onChange={(newValue) =>
                  handleDsSettingsChange("dataLimit", newValue)
                }
                margin={{ left: "5px" }}
                customClassName="AnalyzeTableRowCount-GA"
              />
            </Box>
            <Box css={formRowCss}>
              <SigviewTypography
                variant="pSmallMedium"
                style={{ height: "max-content", width: "100px" }}
              >
                Rolling Dates:
              </SigviewTypography>
              <SigviewRadioGroup
                data={rollingDatesData}
                value={settingsSelections.rollingDateType}
                onChange={(newValue) =>
                  handleDsSettingsChange("rollingDateType", newValue)
                }
              />
            </Box>
          </Box>
          <Box css={footerCss}>
            <SigviewButton
              variant="outlined"
              onClick={handleCancel}
              title="Discard"
              style={{ width: "auto" }}
            />
            <SigviewButton
              variant="contained"
              onClick={handleSave}
              title="Save"
              style={{ width: "auto", margin: { left: "10px" } }}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

const MetricChartsLegend = () => {
  // * Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  const colors = getCustomColorPallete(themeState);

  return (
    <Box style={{ display: "flex" }}>
      <Box style={{ margin: "0px 5px" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              width: "5px",
              height: "5px",
              borderRadius: "50%",
              backgroundColor: colors[0],
              marginRight: "5px",
            }}
          ></Box>
          {/* <Box
            style={{
              width: "10px",
              height: "1.5px",
              backgroundColor: colors[0],
              transform: "translate(-2px,0px)",
            }}
          ></Box> */}

          <SigviewTypography
            variant="pSmallMedium"
            style={{
              color: themeColors["secondaryColor"],
              height: "max-content",
              padding: "0px",
            }}
          >
            Current Period
          </SigviewTypography>
        </Box>
      </Box>

      <Box style={{ margin: "0px 5px" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box
            style={{
              width: "5px",
              height: "5px",
              borderRadius: "50%",
              backgroundColor: colors[1],
              marginRight: "5px",
            }}
          ></Box>
          {/* <Box
            style={{
              width: "10px",
              height: "1.5px",
              backgroundColor: colors[1],
              transform: "translate(-2px,0px)",
            }}
          ></Box> */}

          <SigviewTypography
            variant="pSmallMedium"
            style={{
              color: themeColors["secondaryColor"],
              height: "max-content",
              padding: "0px",
            }}
          >
            Comparison Period
          </SigviewTypography>
        </Box>
      </Box>
    </Box>
  );
};

const AddAttributeCard = (props = {}) => {
  const {
    themeColors = {},
    attributeType = "Metric",
    onClick = () => {},
  } = props;

  const title = `Add New ${attributeType}`;

  const rootCss = {
    display: "flex",
    flexDirection: "column",
    background: "white",
    height: "100%",
    width: "100%",
    cursor: "pointer",
  };
  const headerCss = {
    padding: "10px 10px 0px 10px",
    boxSizing: "border-box",
    flexShrink: 0,
  };
  const bodyCss = {
    flexGrow: 1,
    flexBasis: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Box className="ws-react-dnd-grid-item" onClick={onClick}>
      <Box css={rootCss}>
        <Box css={headerCss}>
          <SigviewTypography
            variant="pMediumLarge"
            style={{
              height: "max-content",
              fontWeight: "500",
              color: themeColors["secondaryColor"],
              hoverColor: themeColors["secondaryColor"],
            }}
          >
            {title}
          </SigviewTypography>
        </Box>

        <Box css={bodyCss}>
          <SigviewIcon
            className="material-icons-round"
            iconName="add_box"
            style={{
              fontSize: "18px",
              color: `${themeColors["primaryColor"]}`,
              hoverColor: `${themeColors["primaryColor"]}`,
              padding: "0px 0px 10px 0px",
              cursor: "pointer",
            }}
          />
          <SigviewTypography
            variant="pMedium"
            style={{
              height: "max-content",
              color: `${themeColors["primaryColor"]}`,
              cursor: "pointer",
            }}
          >
            {title}
          </SigviewTypography>
        </Box>
      </Box>
    </Box>
  );
};

export {
  SigviewDialogClone,
  WsSettingsIcon,
  MetricChartsLegend,
  SigviewDialogRename,
  SigviewDialogCreateNew,
  AddAttributeCard,
};
