// * Import required libraries
import React, { useReducer, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEqual from "lodash.isequal";

// * Import custom components
import SelectedFilters from "../GlobalFilters/SelectedFilters";
import SigviewButton from "../Common/SigviewButton";

// * Import styles
import "./DimensionFilters.scss";

// * Import utils, data & config
import { config } from "../../config/config";
import { masterTrackGaEvent } from "../../services/ga";
import useReducerLogger from "../../utils/useReducerLogger";

// * Import redux utils
import metricFiltersReducer from "../../redux/reducers/metricFilters";
import dimensionFiltersReducer from "../../redux/reducers/dimensionFilters";
import {
  removeFilter,
  updateUserScreen,
  replaceAllDimensionFilters,
  replaceAllMetricFilters,
  removeMetricFilter,
} from "../../redux/actions";
import SigviewTooltip from "../Common/SigviewTooltip";

// * Define required static variables

function DimensionFilters(props) {
  // * Destructure props
  const {
    // REDUX PROPS
    dispatch: ReduxDispatcher,
    user,
    allData,
    // PARENT PROPS
    initialDimensionFilters = [],
    initialMetricFilters = [],
    initialTimeFilters = {}, // needed so populate global filters if user clicks on any dim filter
    settings = {},
    onDimensionFiltersChange = () => {},
    onMetricFiltersChange = () => {},
    onTimeFiltersChange = () => {},
    filtersData = {},
    selections = {},
  } = props;
  const {
    isReadOnly = false,
    showTimeFiltersInGlobalFilters = false,
    showMetricFiltersInGlobalFilters = false,
    isAdvancedFilterVisible = true,
    isExcludeAllowed = true,
    addFilterTitle = "Click to add filters",
    rowTitle = false,
    disabledItemsList = [], // ["D044"] --> This needs to be a array of ids
  } = settings;
  const {
    filtersDimData = allData.plotlyDimensions,
    filtersMetricsData = allData.plotlyMetrics,
  } = filtersData;

  // * Define required state
  const [dimensionFilters, dispatchDimensionFilters] = useReducer(
    useReducerLogger(dimensionFiltersReducer),
    initialDimensionFilters
  );
  const [metricFilters, dispatchMetricFilters] = useReducer(
    useReducerLogger(metricFiltersReducer),
    initialMetricFilters
  );

  // * Define required static variables
  const commonGlobalFilterProps = {
    isOpen: true, //global filters dialog open close
    showTimeFilters: showTimeFiltersInGlobalFilters,
    showMetricFilters: showMetricFiltersInGlobalFilters,
    activeFilterType: "dimensions",
    isAdFiltersOpen: false, //advanced filters dialog open close
    timeFilters: initialTimeFilters, //for initializing filters
    dimensionFilters: dimensionFilters, //for initializing filters
    metricFilters: metricFilters, //for initializing filters
    settings: { isAdvancedFilterVisible, isExcludeAllowed, disabledItemsList },
    filtersDimData,
    filtersMetricsData,
    selections,
  };

  // * Define required event handlers
  const handleApplyFilters = (payload, filterType) => {
    switch (filterType) {
      case "dimensions":
        var action = replaceAllDimensionFilters(payload);
        dispatchDimensionFilters(action);
        break;
      case "metrics":
        var action = replaceAllMetricFilters(payload);
        dispatchMetricFilters(action);
        break;
      case "time":
        onTimeFiltersChange(payload);
        break;
    }
  };

  const handleDimFilterClick = (filterRow) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Open Filters",
      label: "FilterBarAppliedDimension",
    });

    // Open Global Filters
    const newGlobalFiltersProps = {
      ...commonGlobalFilterProps,
      activeDimensionFilter: filterRow.metadata,
      handleApplyFilters,
    };
    ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
  };

  const handleDimFilterDelete = (filterRow) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Clear Filters",
      label: "FilterBarAppliedDimension",
    });
    const payload = {
      filterId: filterRow.id,
    };
    const action = removeFilter(payload);
    dispatchDimensionFilters(action);
  };

  const handleMetricFilterClick = (metricRow) => {
    //Open Global Filters
    //Update activeDimensionFilter in redux
    const newGlobalFiltersProps = {
      ...commonGlobalFilterProps,
      activeDimensionFilter: metricRow.metadata,
      handleApplyFilters,
    };
    ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
  };

  const handleMetricFilterDelete = (metricRow) => {
    //Remove filter from redux
    const payload = { metricFilterId: metricRow.id };
    const action = removeMetricFilter(payload);
    dispatchMetricFilters(action);
  };

  const handleAddFilters = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Open Filters",
      label: "FilterBarAddIcon",
    });
    //Open Global Filters
    const newGlobalFiltersProps = {
      ...commonGlobalFilterProps,
      activeDimensionFilter: {},
      handleApplyFilters, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };

    const action = updateUserScreen("globalFilters", newGlobalFiltersProps);
    ReduxDispatcher(action);
  };

  const handleClearAll = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Clear Filters",
      label: "All",
    });

    // ! OLD IMPLEMENTATION
    // //Update dimension filters
    // var payload = {
    //   newDimensionFilters: [],
    // };
    // var action = replaceAllDimensionFilters(payload);
    // dispatchDimensionFilters(action);

    // //Update metric filters
    // var payload = {
    //   newMetricFilters: [],
    // };
    // var action = replaceAllMetricFilters(payload);
    // dispatchMetricFilters(action);

    // ! NEW IMPLEMENTATION
    // * The below code is to cater to settings disabledItemsList
    const disabledDimensionFilters = dimensionFilters.filter((row) =>
      disabledItemsList.includes(row.id)
    );

    //Update dimension filters
    var payload = {
      newDimensionFilters: [...disabledDimensionFilters],
    };
    var action = replaceAllDimensionFilters(payload);
    dispatchDimensionFilters(action);

    //Update metric filters
    var payload = {
      newMetricFilters: [],
    };
    var action = replaceAllMetricFilters(payload);
    dispatchMetricFilters(action);
  };

  // * Define required side effects
  // DIMENSION FILTERS
  // Update local dimension filters if props change
  useEffect(() => {
    // Perform it only when they are not equal to avoid infinite loop
    if (!isEqual(initialDimensionFilters, dimensionFilters)) {
      const payload = {
        newDimensionFilters: [...initialDimensionFilters],
      };
      const action = replaceAllDimensionFilters(payload);
      dispatchDimensionFilters(action);
    }
  }, [initialDimensionFilters]);
  // OnChange handler for timeFilters (update parent state everytime state changes)
  useEffect(() => {
    // Perform it only when they are not equal to avoid infinite loop
    if (!isEqual(initialDimensionFilters, dimensionFilters)) {
      onDimensionFiltersChange(dimensionFilters);
    }
  }, [dimensionFilters]);

  // METRIC FILTERS
  useEffect(() => {
    // Perform it only when they are not equal to avoid infinite loop
    if (!isEqual(initialMetricFilters, metricFilters)) {
      const payload = {
        newMetricFilters: [...metricFilters],
      };
      const action = replaceAllMetricFilters(payload);
      dispatchMetricFilters(action);
    }
  }, [initialDimensionFilters]);
  // OnChange handler for timeFilters (update parent state everytime state changes)
  useEffect(() => {
    // Perform it only when they are not equal to avoid infinite loop
    if (!isEqual(initialMetricFilters, metricFilters)) {
      onMetricFiltersChange(metricFilters);
    }
  }, [metricFilters]);
  // // Use effect to check filters menu row height and updateUserScreen for CSS
  // // Add resize event listener
  // useEffect(() => {
  //   handleFiltersRowHeight();
  //   function handleFiltersRowHeight() {
  //     const filtersMenuBarRow = document.getElementById("filtersMenuBarRow");
  //     const filtersMenuBarRowHeight = filtersMenuBarRow.offsetHeight;
  //     if (filtersMenuBarRowHeight > 35) {
  //       ReduxDispatcher(updateUserScreen("filtersMenuRowCount", "double"));
  //     } else {
  //       ReduxDispatcher(updateUserScreen("filtersMenuRowCount", "single"));
  //     }
  //   }
  //   window.addEventListener("resize", handleFiltersRowHeight);

  //   return function cleanupListener() {
  //     window.removeEventListener("resize", handleFiltersRowHeight);
  //   };
  // }, [dimensionFilters]);

  // * Define required variables
  const selectedFiltersCount = dimensionFilters.length + metricFilters.length; // TODO LATER: user.metricFilters.length

  return (
    <div className="filters-menu-bar-container" id="filtersInMenuBar">
      <div className="left-panel">
        <div className="applied-filters-row">
          {/* <p className="applied-filters-row-title">{"Applied Filters: "}</p> */}
          <SelectedFilters
            onClick={(row, type) => {
              switch (type) {
                case "dimension":
                  handleDimFilterClick(row);
                  break;
                case "metric":
                  handleMetricFilterClick(row);
                  break;
              }
            }}
            onDelete={(row, type) => {
              switch (type) {
                case "dimension":
                  handleDimFilterDelete(row);
                  break;
                case "metric":
                  handleMetricFilterDelete(row);
                  break;
              }
            }}
            // rowHeader="Applied Filters:"
            selectedDimensionFilters={dimensionFilters}
            selectedMetricFilters={metricFilters}
            style={{ maxHeight: "62px" }}
            divId="filtersMenuBarRow"
            allData={allData}
            childrenPlacement="end"
            showClearAllButton={true}
            onClearAll={handleClearAll}
            isReadOnly={isReadOnly}
            rowHeader={rowTitle}
            disabledItemsList={disabledItemsList}
          >
            {selectedFiltersCount > 0 && (
              <SigviewTooltip title="Click to add Filters" placement="bottom">
                <div
                  className={`selected-filter-item-container child-container WorkspaceAddFilters-GA`}
                >
                  <i
                    className="material-icons add-filters-icon child-element WorkspaceAddFilters-GA"
                    onClick={handleAddFilters}
                  >
                    add
                  </i>
                </div>
              </SigviewTooltip>
            )}
            {selectedFiltersCount === 0 && (
              <div
                className={`selected-filter-item-container child-container no-padding`}
              >
                <SigviewButton
                  variant="containedLighter"
                  onClick={handleAddFilters}
                  title={addFilterTitle}
                  iconFlag={false}
                />
              </div>
            )}
          </SelectedFilters>
        </div>
      </div>
      {/* <div className="right-panel">
        <div className="clear-all-filters-container">
          <p className="clear-all-filters-title" onClick={handleClearAll}>
            Clear All
          </p>
        </div>
      </div> */}
    </div>
  );
}

DimensionFilters.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  dimensionFilters: state.user.dimensionFilters,
  metricFilters: state.user.metricFilters,
  allData: state.data,
});

export default connect(mapStateToProps)(DimensionFilters);
