// * Import required libraries
import { useState, useEffect, useReducer, useContext, useRef } from "react";
import isEqual from "lodash.isequal";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * import lib component
import { Box } from "@material-ui/core";

// * Import custom components
import SigviewTextField from "../../../components/Common/SigviewTextField";
import SigviewTypography from "../../../components/Common/SigviewTypography";

import SigviewSingleSelect from "../../../components/Common/SigviewSingleSelect";

import SigviewButton from "../../../components/Common/SigviewButton";
import SigviewCheckboxGroup from "../../../components/Common/SigviewCheckboxGroup";

import SigviewTextFieldAsync from "../../../components/Common/SigviewTextFieldAsync";
import SigviewHoverPopup from "../../../components/Common/SigviewHoverPopup";
import SigviewIcon from "../../../components/Common/SigviewIcon";

// * Import utils, config & static data
import {
  defaultCustomMetricState as defaultSelections,
  unwrapperGroupDataDnd,
  isSelectionsInvalid,
  validateAttributeName,
} from "../../../utils/attributeUtils";

import { config } from "../../../config/config";

// * Import redux utils
import useReducerLogger from "../../../utils/useReducerLogger";
import {
  updateUserScreen,
  replaceAttributeCustomMetricForm,
  updateAttributeCustomMetricForm,
  updateData,
  updateAttributeCustomMetricFormWholeKey,
} from "../../../redux/actions";
import attributeCustomMetricReducer from "../../../redux/reducers/attributeCustomMetric";

//  * Import APIs
import {
  createAttributeAws,
  readAdminAwsByOrg,
  updateAttributeAws,
  nameCheckAttributeAws,
} from "../../../services/api";
import { connect } from "react-redux";
import { wrapperCustomMetricFormAws } from "../../../utils/adminAwsUtils";

// TODO Same code changes as DimensionForm.js

function CustomMetricForm(props = {}) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  //  * Destructure props
  const {
    initialSelections = defaultSelections,
    usableAttributes = [],
    ReduxDispatcher,
    setShowForm = () => {},
    formulaData = [],
    user = {},
    activeView = "",
  } = props;

  let usableAttributeNames = [{ id: "numeric", name: "Numeric Value" }]
    .concat(
      usableAttributes.map((row) => {
        return { ...row, id: row._id, name: row.title };
      })
    )
    .filter((row) => row.measureType !== "approxPercentile");

  let usableAttributeApproxPercentileNames = usableAttributes
    .map((row) => {
      return { ...row, id: row._id, name: row.title };
    })
    .filter((row) => row.measureType === "approxPercentile");

  const formulaDataList = formulaData.filter((row) => row.id !== "(A,B]");
  const percentileFormulaList = formulaData.filter((row) => row.id === "(A,B]");

  // * Define required states
  const [selections, dispatch] = useReducer(
    useReducerLogger(attributeCustomMetricReducer),
    initialSelections
  );

  const originalSelections = useRef({});

  const [name, setName] = useState({
    status: "success",
    value: selections.name.value,
    originalValue: selections.name.value,
    message: "",
  });

  let dataUnitTypeList;
  if (usableAttributeApproxPercentileNames.length === 0) {
    dataUnitTypeList = config.hardCoded.attributes.metricDatatype.filter(
      (row) => row.id !== "percentile"
    );
  } else {
    dataUnitTypeList = config.hardCoded.attributes.metricDatatype;
  }
  // * Define required side effects
  useEffect(() => {
    if (
      selections?.dataUnitType?.value === "percentile" &&
      selections.crudType === "create"
    ) {
      let valueType = "numeric";
      handelFormulaChange("(A,B]");
      handleFormEntryChange("A", usableAttributeApproxPercentileNames[0]?.id);
      handleFormEntryChange("B", "numeric", valueType);
    } else if (
      selections?.dataUnitType?.value !== "percentile" &&
      selections.crudType === "create" &&
      selections.formulaType.value !== "A" &&
      selections.formulaEntity["A"].value.id !== "numeric"
    ) {
      handleFormEntryChange("A", usableAttributeNames[1].id);
      handleFormEntryChange("B", "numeric", 0);
    }
  }, [selections?.dataUnitType?.value]);

  useEffect(() => {
    const payload = { ...initialSelections };
    const action = replaceAttributeCustomMetricForm(payload);

    setName((prevState) => ({
      ...prevState,
      value: initialSelections.name.value,
      message: "",
    }));

    originalSelections.current = {
      ...initialSelections,
      enteredFormula: {
        ...initialSelections.enteredFormula,
        value: initialSelections.enteredFormula.value.toLowerCase(),
      },
    };

    dispatch(action); // to update selections based on new clicked attribute
  }, [initialSelections.id.value]);

  useEffect(() => {
    if (selections.crudType === "create") {
      let formulaEntity = {};

      let formula = selections.formulaType.value.match(/[A-Z]/g);
      if (selections?.dataUnitType?.value === "percentile") {
        for (const i of formula) {
          if (i === "A") {
            formulaEntity[i] = {
              message: "",
              value: {
                id: usableAttributeApproxPercentileNames[0]?.id,
                num: NaN,
              },
              status: "valid",
            };
          } else if (i === "B") {
            formulaEntity[i] = {
              message: "",
              value: {
                id: "numeric",
                num: 0.1,
              },
              status: "valid",
            };
          }
        }
      } else {
        for (const i of formula) {
          formulaEntity[i] = {
            message: "",
            value: { id: usableAttributeNames[1].id, num: NaN },
            status: "valid",
          };
        }
      }

      const payload = {
        key: "formulaType",
        value: { formulaType: selections.formulaType.value, formulaEntity },
      };
      const action = updateAttributeCustomMetricForm(payload);
      dispatch(action);
    }
    if (selections.crudType === "update") {
    }
  }, [selections.crudType]);

  const getString = (arr, id) => {
    for (const element of arr) {
      if (element.id === id) {
        return `${
          (element?.measureType?.toLowerCase() &&
            `${element.measureType.toLowerCase()}(${element.name.toLowerCase()})`) ||
          element.name.toLowerCase()
        }`;
      }
    }
  };
  useEffect(() => {
    let formula = selections.formulaType.value.match(/[A-Z]/g);
    let formulaEntity = selections.formulaEntity;

    let formulaType = selections.formulaType.value;
    if (selections?.dataUnitType?.value === "percentile") {
      for (const id of formula) {
        // APPROACH1
        formulaType = formulaType.replace(
          id,
          formulaEntity[id].value.id === "numeric"
            ? formulaEntity[id].value.num || 0
            : getString(
                usableAttributeApproxPercentileNames,
                formulaEntity[id].value.id
              )
        );
      }
    } else {
      for (const id of formula) {
        // APPROACH1
        formulaType = formulaType.replace(
          id,
          formulaEntity[id].value.id === "numeric"
            ? formulaEntity[id].value.num || 0
            : getString(usableAttributeNames, formulaEntity[id].value.id)
        );
      }
    }

    const payload = {
      key: "enteredFormula",
      value: formulaType,
    };
    // Update only when state is not equal
    const isEqualFlag = selections.enteredFormula.value === formulaType;
    if (!isEqualFlag) {
      const action = updateAttributeCustomMetricForm(payload);
      dispatch(action);
    }
  }, [selections.formulaEntity]);

  // * Define requried event handlers

  const handleNameChange = (event, value) => {
    setName((prevState) => ({
      ...prevState,
      value: value,
      message: "",
    }));
  };

  const handelFormulaChange = (value) => {
    let formulaEntity = {};

    let formula = value.match(/[A-Z]/g);

    for (const i of formula) {
      formulaEntity[i] = selections?.formulaEntity[i] || {
        message: "",
        value: { id: usableAttributeNames[1].id, num: NaN },
        status: "",
      };
    }

    const payload = {
      key: "formulaType",
      value: { formulaType: value, formulaEntity },
    };
    const action = updateAttributeCustomMetricForm(payload);
    dispatch(action);
  };
  const handleFormEntryChange = (id, entityValue, entityValueType = "") => {
    let value = {
      id,
      entityValue,
      entityValueType,
    };

    const payload = {
      key: "formulaEntity",
      value,
    };

    const action = updateAttributeCustomMetricForm(payload);
    dispatch(action);
  };
  const handleDataTypeChange = (value) => {
    if (value === "String") {
      let payload = {
        key: "dataUnitType",
        value,
      };
      let action = updateAttributeCustomMetricForm(payload);
      dispatch(action);

      payload = {
        key: "dataUnitSymbol",
        value,
      };

      action = updateAttributeCustomMetricForm(payload);
      dispatch(action);
    }
    if (value === "percent") {
      let payload = {
        key: "dataUnitType",
        value,
      };
      let action = updateAttributeCustomMetricForm(payload);
      dispatch(action);

      payload = {
        key: "dataUnitSymbol",
        value: config.hardCoded.attributes.metricDataSymbol[value][0].id,
      };

      action = updateAttributeCustomMetricForm(payload);
      dispatch(action);
    }
    if (value === "percentile") {
      let payload = {
        key: "dataUnitType",
        value,
      };
      let action = updateAttributeCustomMetricForm(payload);
      dispatch(action);
      payload = {
        key: "dataUnitSymbol",
        value: config.hardCoded.attributes.metricDataSymbol[value][0].id,
      };

      action = updateAttributeCustomMetricForm(payload);
      dispatch(action);
    }
    if (value === "currency") {
      let payload = {
        key: "dataUnitType",
        value,
      };
      let action = updateAttributeCustomMetricForm(payload);
      dispatch(action);

      payload = {
        key: "dataUnitSymbol",
        value: config.hardCoded.attributes.metricDataSymbol[value][0].id,
      };

      action = updateAttributeCustomMetricForm(payload);
      dispatch(action);
    }
  };
  const handleDataSymbolChange = (value) => {
    const payload = {
      key: "dataUnitSymbol",
      value,
    };
    const action = updateAttributeCustomMetricForm(payload);
    dispatch(action);
  };
  const handleAdditiveChange = (value) => {
    const payload = {
      key: "isAdditive",
      value,
    };
    const action = updateAttributeCustomMetricForm(payload);
    dispatch(action);
  };

  const handleSave = () => {
    let snackbarPayload = {
      open: true,
      message: "Requesting to save attribute...",
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    // TODO: Make API call to save attribute
    let payload = wrapperCustomMetricFormAws(
      { ...selections },
      user.reqMetadata
    );
    let fetchProps = {
      payload,
      type: "cm",
    };

    const updateAttributePromise = createAttributeAws(fetchProps);
    updateAttributePromise
      .then(() => {
        const readAllAdminViewsPromise = readAdminAwsByOrg({});
        readAllAdminViewsPromise
          .then((data) => {
            let snackbarPayload = {
              open: true,
              message: "Attribute saved successfully",
            };
            ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

            let newData = {
              result: data.result.data,
              status: "success",
              messsage: "",
            };
            ReduxDispatcher(updateData("views", newData));

            ReduxDispatcher(updateUserScreen("isDashboardLoading", false));

            setShowForm(false);
          })
          .catch((json) => {
            if (json.error !== config.hardCoded.queryCancelled) {
              const message = json.error || config.messages.uiErrorMessage;
              let newData = {
                result: [],
                status: "error",
                messsage: "Views API failed",
              };
              ReduxDispatcher(updateData("views", newData));
              ReduxDispatcher(updateUserScreen("isDashboardLoading", false));

              console.groupCollapsed("UI ERROR");
              console.log("JSON", json);
              console.log("ERROR ->", message);
              console.groupEnd();
            }
          });
      })
      .catch((json) => {
        if (json.error !== config.hardCoded.queryCancelled) {
          const message = json.error || config.messages.uiErrorMessage;
          ReduxDispatcher(updateUserScreen("isDashboardLoading", false));

          console.groupCollapsed("UI ERROR");
          console.log("JSON", json);
          console.log("ERROR ->", message);
          console.groupEnd();
        }
      });
  };
  const handleUpdate = () => {
    let snackbarPayload = {
      open: true,
      message: "Requesting to update attribute...",
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));

    let payload = wrapperCustomMetricFormAws(
      { ...selections },
      user.reqMetadata
    );
    let fetchProps = {
      payload,
      type: "cm",
    };

    const updateAttributePromise = updateAttributeAws(fetchProps);
    updateAttributePromise
      .then(() => {
        const readAllAdminViewsPromise = readAdminAwsByOrg({});
        readAllAdminViewsPromise
          .then((data) => {
            let snackbarPayload = {
              open: true,
              message: "Attribute updated successfully",
            };
            ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

            let newData = {
              result: data.result.data,
              status: "success",
              messsage: "",
            };
            ReduxDispatcher(updateData("views", newData));
            ReduxDispatcher(updateUserScreen("isDashboardLoading", false));

            setShowForm(false);
          })
          .catch((json) => {
            if (json.error !== config.hardCoded.queryCancelled) {
              const message = json.error || config.messages.uiErrorMessage;
              let newData = {
                result: [],
                status: "error",
                messsage: "Views API failed",
              };
              ReduxDispatcher(updateData("views", newData));
              ReduxDispatcher(updateUserScreen("isDashboardLoading", false));

              console.groupCollapsed("UI ERROR");
              console.log("JSON", json);
              console.log("ERROR ->", message);
              console.groupEnd();
            }
          });
      })
      .catch((json) => {
        if (json.error !== config.hardCoded.queryCancelled) {
          const message = json.error || config.messages.uiErrorMessage;
          ReduxDispatcher(updateUserScreen("isDashboardLoading", false));

          console.groupCollapsed("UI ERROR");
          console.log("JSON", json);
          console.log("ERROR ->", message);
          console.groupEnd();
        }
      });
  };

  const handleClickAway1 = () => {
    const { status: nameStatus, message: nameMessage } = validateAttributeName(
      name.value
    );

    // 1. If name is same as before, don't call the API
    if (originalSelections.current.name.value === name.value) {
      setName((prevState) => ({
        ...prevState,
        value: originalSelections.current.name.value,
        originalValue: originalSelections.current.name.value,
      }));
      const payload = {
        key: "name",
        value: { ...originalSelections.current.name },
      };
      const action = updateAttributeCustomMetricFormWholeKey(payload);
      dispatch(action);
    } else {
      if (nameStatus === "invalid") {
        setName((prevState) => ({
          ...prevState,
          status: "error",
          message: nameMessage,
        }));
        var payload = {
          key: "name",
          value: {
            message: nameMessage,
            status: "invalid",
            value: name.value,
          },
        };
        var action = updateAttributeCustomMetricFormWholeKey(payload);
        dispatch(action);
        // var snackbarPayload = {
        //   open: true,
        //   message: nameMessage,
        // };
        // var action = updateUserScreen("snackbar", snackbarPayload);
        // ReduxDispatcher(action);
      } else {
        // 3. If the group name is valid, call the API and reset state based on response
        setName((prevState) => ({
          ...prevState,
          status: "loading",
        }));

        // API to check group name availability
        const fetchProps = {
          payload: {
            orgViewReq: {
              organization: user.reqMetadata?.organization,
              view: activeView,
            },
            attributeType: "CM",
            title: name.value,
          },
        };
        const nameCheckAttributeAwsPromise = nameCheckAttributeAws(fetchProps);
        nameCheckAttributeAwsPromise
          .then((reponseData) => {
            if (reponseData.status.statusCode === "200") {
              setName((prevState) => ({
                ...prevState,
                status: "success",
                originalValue: prevState.value,
              }));
              var payload = {
                key: "name",
                value: {
                  message: nameMessage,
                  status: "valid",
                  value: name.value,
                },
              };
              var action = updateAttributeCustomMetricFormWholeKey(payload);
              dispatch(action);
            } else {
              // let snackbarPayload = {
              //   open: true,
              //   message: "Requested group name failed",
              // };
              // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
            }
          })
          .catch((json) => {
            if (json.error !== config.hardCoded.queryCancelled) {
              const message = json.error || config.messages.uiErrorMessage;
              setName((prevState) => ({
                ...prevState,
                status: "error",
                message: json.error,
              }));

              var payload = {
                key: "name",
                value: {
                  message: json.error,
                  status: "invalid",
                  value: name.value,
                },
              };
              var action = updateAttributeCustomMetricFormWholeKey(payload);
              dispatch(action);

              // let snackbarPayload = {
              //   open: true,
              //   message: json.error,
              // };
              // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

              console.groupCollapsed("UI ERROR");
              console.log("JSON", json);
              console.log("ERROR ->", message);
              console.groupEnd();
            }
          });
      }
    }
  };

  const isValid =
    isSelectionsInvalid({
      name: selections.name,
      // backendName: selections.backendName,
      // dataUnitType: selections.dataUnitType,
    }).status === "valid";

  const areSelectionsEqual = isEqual(originalSelections.current, selections);

  const isUpdateDisabled = areSelectionsEqual
    ? true
    : !isValid
    ? true
    : false || selections?.formulaEntity["B"]?.status === "invalid";

  // Styles
  const box1 = {
    padding: "0px 0px 0px 20px",
    height: "50px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
  };
  const box2 = {
    height: "calc(100% - 100px)",
    boxSizing: "border-box",
    width: "100%",
    overflow: "auto",
  };
  const fieldBox = {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    width: "100%",
    boxSizing: "border-box",
  };
  const fieldBox2 = {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    width: "520px",
    boxSizing: "border-box",
    justifyContent: "space-between",
  };
  const fieldBox4 = {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    width: "525px",
    boxSizing: "border-box",
    justifyContent: "space-between",
  };
  const fieldBox3 = {
    display: "flex",
    alignItems: "end",
    padding: "0px",
    width: "480px",
    height: "0px",
    boxSizing: "border-box",
    justifyContent: "end",
    position: "relative",
    bottom: "16px",
  };

  const formTitleStyling = {
    height: "max-content",
    padding: "0px 10px 0px 0px",
  };
  const buttonContainer = {
    height: "50px",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px 10px 0px",
    justifyContent: "flex-end",
    boxSizing: "border-box",
  };

  const typographyStyle = {
    padding: "5px 10px 0px 0px",
  };
  const typographyStyleNumbericField = {
    border: "1px solid #ccc",
    width: "138px",
    borderRadius: "3px",
    padding: "5px",
    height: "11px",
    fontSize: "10px",
  };
  const buttonStyle = { width: "60px", margin: { left: "3px", right: "3px" } };

  const textFieldCustomStyle = {
    textFieldFontSize: "12px",
    wrapperBorder: `1px solid ${themeColors["secondaryColorLighter"]}`,
    wrapperWidth: "300px",
    wrapperHeight: "25px",
    margin: "0px 5px 0px 0px",
    textFieldFontSize: "10px",
    placeholderFontSize: "10px",
  };

  const enteredFormulaBox = {
    border: `1px solid ${themeColors["secondaryColorLighter"]}`,
    padding: "5px",
    fontSize: "10px",
    borderRadius: "3px",
    color: themeColors["secondaryColor"],
    width: "300px",
    boxSizing: "border-box",
    background: themeColors["primaryColorLightest"],
    cursor: "not-allowed",
    wordWrap: "break-word",
  };

  return (
    <>
      <Box css={box1}>
        <SigviewTypography variant="pLarger">
          {(selections.crudType === "create" && "Add New Custom Metric") ||
            `Update Custom Metric: ${selections.name?.value}`}{" "}
          for {selections.activeView?.value}
        </SigviewTypography>
      </Box>

      <Box css={box2} className="sigview-styled-scroller">
        <Box css={fieldBox4}>
          <SigviewTypography style={typographyStyle}>Name:</SigviewTypography>

          <Box
            css={{
              display: "flex",
            }}
          >
            <SigviewTextFieldAsync
              value={name.value}
              onChange={handleNameChange}
              status={name.status}
              width="300px"
              onClickAway={handleClickAway1}
              customStyle={textFieldCustomStyle}
              tooltipTitle="Type and press enter to check if name is valid"
              errorFlag={selections.name.status === "invalid"}
              errorMessage={selections.name.message}
            />
            <Box
              css={{ height: "25px", display: "flex", alignItems: "center" }}
            >
              <SigviewHoverPopup
                title="Name Rules"
                data={config.hardCoded.attributeNameRules}
              >
                <SigviewIcon className="material-icons-round" iconName="info" />
              </SigviewHoverPopup>
            </Box>
          </Box>
        </Box>
        {/* {formulaData.status === "loading" && (
          <Box
            css={{
              width: "300px",
              paddingLeft: "180px",
            }}
          >
            <SigviewProgressBar />
          </Box>
        )}
        {formulaData.status === "error" && (
          <ErrorHandler
            message="Error in fetching group data"
            reloadFlag={true}
            onReload={() => {}}
          />
        )}
        {formulaData.status === "success" && ( */}
        {selections?.dataUnitType?.value !== "percentile" && (
          <Box css={{ ...fieldBox2, width: "501px" }}>
            <SigviewTypography style={typographyStyle}>
              Formula Type:
            </SigviewTypography>
            <SigviewSingleSelect
              value={selections.formulaType.value}
              data={formulaDataList}
              onChange={handelFormulaChange}
              minWidth="300px"
            />
          </Box>
        )}
        {/* )} */}
        {selections?.dataUnitType?.value === "percentile" && (
          <Box css={{ ...fieldBox2, width: "501px" }}>
            <SigviewTypography style={typographyStyle}>
              Formula Type:
            </SigviewTypography>
            <SigviewSingleSelect
              value={selections.formulaType.value}
              data={percentileFormulaList}
              onChange={handelFormulaChange}
              minWidth="300px"
            />
          </Box>
        )}
        <Box css={{ ...fieldBox2, width: "501px" }}>
          <SigviewTypography style={typographyStyle}>
            Data Unit Type:
          </SigviewTypography>
          <SigviewSingleSelect
            value={selections?.dataUnitType?.value}
            data={dataUnitTypeList}
            onChange={handleDataTypeChange}
            disabled={selections.crudType === "update"}
            minWidth="300px"
          />
        </Box>
        {(selections?.dataUnitType?.value === "percent" ||
          selections?.dataUnitType?.value === "percentile" ||
          selections?.dataUnitType?.value === "currency") && (
          <Box css={{ ...fieldBox2, width: "501px" }}>
            <SigviewTypography style={typographyStyle}>
              Data Unit Symbol:
            </SigviewTypography>
            <SigviewSingleSelect
              value={selections?.dataUnitSymbol?.value}
              data={
                config.hardCoded.attributes.metricDataSymbol[
                  selections?.dataUnitType?.value
                ]
              }
              onChange={handleDataSymbolChange}
              minWidth="300px"
            />
          </Box>
        )}
        {selections?.dataUnitType?.value !== "percentile" &&
          Object.keys(selections.formulaEntity).map((row) => {
            return (
              <Box key={row} css={{ ...fieldBox2, width: "501px" }}>
                <SigviewTypography style={typographyStyle}>
                  {row}:
                </SigviewTypography>

                <Box>
                  <SigviewSingleSelect
                    data={usableAttributeNames}
                    value={selections.formulaEntity[row].value.id}
                    onChange={(value) => {
                      handleFormEntryChange(row, value);
                    }}
                    minWidth={
                      selections.formulaEntity[row].value.id === "numeric"
                        ? "150px"
                        : "300px"
                    }
                  />

                  {selections.formulaEntity[row].value.id === "numeric" && (
                    <SigviewTextField
                      placeholder="Enter Value"
                      width="50px"
                      onChange={(value) => {
                        let valueType = "numeric";
                        handleFormEntryChange(row, value, valueType);
                      }}
                      value={selections.formulaEntity[row].value.num || 0}
                      minWidth="150px"
                    />
                  )}
                </Box>
              </Box>
            );
          })}
        {selections?.dataUnitType?.value === "percentile" &&
          Object.keys(selections.formulaEntity).map((row) => {
            return (
              <Box key={row} css={{ ...fieldBox2, width: "501px" }}>
                <SigviewTypography style={typographyStyle}>
                  {row}:
                </SigviewTypography>

                {row === "A" && (
                  <Box>
                    <SigviewSingleSelect
                      data={usableAttributeApproxPercentileNames}
                      value={selections.formulaEntity[row].value.id}
                      onChange={(value) => {
                        handleFormEntryChange(row, value);
                      }}
                      minWidth="300px"
                    />
                  </Box>
                )}
                {row === "B" && (
                  <Box css={{ display: "flex", marginLeft: "140px" }}>
                    <SigviewTypography
                      variant="pSmall"
                      style={{
                        border: "1px solid #ccc",
                        width: "138px",
                        borderRadius: "3px",
                        padding: "5px",
                        height: "11px",
                        fontSize: "10px",
                      }}
                    >
                      Numeric Value
                    </SigviewTypography>
                    <SigviewTextField
                      placeholder="Enter Value"
                      width="50px"
                      onChange={(value) => {
                        let valueType = "numeric";
                        handleFormEntryChange(row, value, valueType);
                      }}
                      value={selections.formulaEntity[row].value.num}
                      minWidth="150px"
                    />
                    <Box
                      css={{
                        height: "25px",
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        left: "10px",
                      }}
                    >
                      <SigviewHoverPopup
                        title="Formula Entity Rules"
                        data={config.hardCoded.attributeNumbersRules}
                      >
                        <SigviewIcon
                          className="material-icons-round"
                          iconName="info"
                        />
                      </SigviewHoverPopup>
                    </Box>
                  </Box>
                )}
              </Box>
            );
          })}
        {selections?.dataUnitType?.value === "percentile" && (
          <Box css={{ ...fieldBox3 }}>
            {selections.formulaEntity["B"].status === "invalid" && (
              <Box>
                <SigviewTypography
                  variant="pSmall"
                  style={{
                    color: "#f44336",
                    lineHeight: "16px",
                    textAlign: "right",
                    width: "220px",
                    padding: "5px 0px 0px 0px",
                    height: "0px",
                  }}
                >
                  {selections.formulaEntity["B"].message}
                </SigviewTypography>
              </Box>
            )}
          </Box>
        )}

        <Box css={{ ...fieldBox2, width: "501px" }}>
          <SigviewTypography style={typographyStyle}>
            Entered Formula:
          </SigviewTypography>
          {/* <SigviewTextField
            readOnly={true}
            value={selections.enteredFormula.value}
            width="300px"
          /> */}

          <Box css={enteredFormulaBox}>{selections.enteredFormula.value}</Box>
        </Box>
        {selections?.dataUnitType?.value !== "percentile" && (
          <Box css={{ ...fieldBox2, justifyContent: "flexStart" }}>
            <SigviewCheckboxGroup
              data={[
                { id: "", name: "", checked: selections.isAdditive.value },
              ]}
              customID="AWSAdminIsAdditive-GA"
              onChange={() => {
                handleAdditiveChange(!selections.isAdditive.value);
              }}
            />
            <SigviewTypography style={{ padding: "13px 10px 0px 0px" }}>
              Is Additive?
            </SigviewTypography>
          </Box>
        )}
      </Box>

      <Box css={buttonContainer}>
        <SigviewButton
          title={selections.crudType === "create" ? "Save" : "Update"}
          variant="contained"
          onClick={selections.crudType === "create" ? handleSave : handleUpdate}
          style={buttonStyle}
          disabled={isUpdateDisabled}
          customClassName={`${
            selections.crudType === "create"
              ? `Add-${name.value}-GA  add-new-customMetric-GA`
              : `Edit-${name.value}-GA  update-customMetric-GA`
          }`}
        />
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(CustomMetricForm);
