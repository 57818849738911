// * Import required libraies
import React, { useState } from "react";
import { v4 } from "uuid";

// * Import lib comp
import { Box } from "@material-ui/core";

// * Import Custom Component
import Loader from "../../../components/Loader/Loader";
import SigviewTextField from "../../../components/Common/SigviewTextField";
import SigviewButton from "../../../components/Common/SigviewButton";
import SigviewSimpleText from "../../../components/Common/SigviewSimpleText";
import SigviewHoverPopup from "../../../components/Common/SigviewHoverPopup";
import SigviewIcon from "../../../components/Common/SigviewIcon";

// * Import action creators
import { updateUserScreen } from "../../../redux/actions";

// * Import utils/data
import { config } from "../../../config/config";

// * Import APIs
import {
  addSavedReport,
  addScheduledReport,
  createGroup,
} from "../../../services/api";
import {
  validateReporCloneName,
  validateReportCloneName,
  validateReportName,
  wrapperSavedReport,
  wrapperScheduledReport,
} from "../../../utils/reportUtils";

//Defining required variables
const reportMessages = config.messages.reports;

const AdminReportClone = (props = {}) => {
  // * Destructure props
  const {
    user = {},
    ReduxDispatcher,
    initialName = "",
    reportFormData = {},
    reportType = "",
    globalFilters = {},
    allData = {},
    wsForm = {},
    activeReport = {},
  } = props;

  // * Define requried state
  const [name, setName] = useState({
    value: initialName,
    status: "success", // success/error/loading
    message: "",
  });

  // * Define required event handlers
  const handleNameChange = (newValue) => {
    setName(() => {
      const { flag, message } = validateReportName({ reportName: newValue });
      let finalStatus = "success";
      if (flag) finalStatus = "error";
      const newState = { status: finalStatus, message, value: newValue };
      return newState;
    });
  };

  const handleCancel = () => {
    const payload = { open: false, children: null };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleClone = (type = "clone") => {
    if (reportType === "scheduled") {
      //Update snackbar
      let snackbarPayload = {
        ...user.screen.snackbar,
        open: true,
        message: reportMessages.scheduledRequest,
      };
      ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

      //Making fetch call
      //hard coded
      //If the saved report had five_thoudsand_records selected, API will fail, change it to fifty_thousand_records
      const reportRowCount = ["fifty_thousand_records", "all_records"].includes(
        reportFormData.reportRowCount
      )
        ? reportFormData.reportRowCount
        : "fifty_thousand_records";
      const wrapperProps = {
        activeReportFormData: {
          reportFormData: {
            ...reportFormData,
            reportId: `report-${v4()}`,
            reportName: name.value,
            reportRowCount,
          }, //Updating Id
          reportPayload: {
            orgViewReq:
              user.screen.adminReportData.activeAdminReport.metadata.orgViewReq,
            loginInfo:
              user.screen.adminReportData.activeAdminReport.metadata.loginInfo,
          }, //Adding download/saved report to scheduled reports
        },
        user: user,
        allData: allData,
        wsForm: wsForm,
        activeReport,
        globalFilters: globalFilters,
        variant: "adminReport",
      };
      const fetchPayload = wrapperScheduledReport(wrapperProps);
      const fetchProps = {
        payload: fetchPayload,
      };
      const addScheduledReportPromise = addScheduledReport(fetchProps);
      addScheduledReportPromise
        .then((responseDate) => {
          //Update snackbar
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: reportMessages.scheduledSuccess,
          };
          ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

          const payload = { open: false, children: null };
          const action = updateUserScreen("sigviewDialog", payload);
          ReduxDispatcher(action);
          ReduxDispatcher(updateUserScreen("adminReportHardReload", true));

          ReduxDispatcher(updateUserScreen("adminReport", false));
        })
        .catch((json) => {
          console.log("API FAILED", json);
          // setIsLoading(false);
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: json.error || reportMessages.scheduledFailure,
          };
          ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
        });
    }

    if (reportType === "saved") {
      //Update snackbar
      let snackbarPayload = {
        ...user.screen.snackbar,
        open: true,
        message: reportMessages.savedRequest,
      };
      ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

      //Making fetch call
      const wrapperProps = {
        activeReportFormData: {
          reportFormData: {
            ...reportFormData,
            reportId: `report-${v4()}`,
            reportName: name.value,
          }, //Updating Id

          reportPayload: {
            orgViewReq:
              user.screen.adminReportData.activeAdminReport.metadata.orgViewReq,
          }, //Adding download/scheduled report to saved reports
        },
        user: user,
        allData: allData,
        activeReport,
        globalFilters: globalFilters,
        variant: "adminReport",
      };
      const fetchPayload = wrapperSavedReport(wrapperProps);
      const fetchProps = {
        payload: fetchPayload,
      };
      const addSavedReportPromise = addSavedReport(fetchProps);
      addSavedReportPromise
        .then((responseDate) => {
          //Update snackbar
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: reportMessages.savedSuccess,
          };
          ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

          const payload = { open: false, children: null };
          const action = updateUserScreen("sigviewDialog", payload);
          ReduxDispatcher(action);

          ReduxDispatcher(updateUserScreen("adminReport", false));
        })
        .catch((json) => {
          console.log("API FAILED", json);

          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: json.error || reportMessages.savedFailure,
          };
          ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
        });
    }
  };

  // * Define required variables
  const errorFlag = name.status === "error";
  const helperText = name.message;
  const cloneDisabled = ["error", "loading"].includes(name.status);
  const cloneTitle = name.status === "loading" ? <Loader /> : "Clone";
  const box1Style = {
    display: "flex",
    margin: "0px 0px 8px 0px",
    alignItems: "center",
  };

  return (
    <section className="sigview-dialog-chart-clone">
      <Box css={box1Style}>
        <SigviewSimpleText
          value="Report Name"
          style={{
            border: "none",
            fontSize: "12px",
            padding: "0px",
            margin: "0px 5px 0px 0px",
          }}
        />
        <SigviewHoverPopup
          title="Report Name Rules"
          data={config.hardCoded.reportNameRules}
        >
          <SigviewIcon className="material-icons-round" iconName="info" />
        </SigviewHoverPopup>
      </Box>

      <SigviewTextField
        placeholder="Please enter a valid report name..."
        value={name.value}
        onChange={(newValue) => handleNameChange(newValue)}
        error={errorFlag}
        helperText={helperText}
        minWidth="400px"
        maxWidth="400px"
        height="40px"
        margin={{ right: "5px", bottom: "10px" }}
      />

      <article className="sigview-dialog-chart-clone-menu-bar">
        <SigviewButton
          variant="outlined"
          onClick={handleCancel}
          title="Cancel"
          style={{
            width: "max-content",
            margin: { left: "3px", right: "3px" },
          }}
        />
        <SigviewButton
          variant="contained"
          onClick={() => handleClone("clone")}
          disabled={cloneDisabled}
          title={cloneTitle}
          style={{
            width: "max-content",
            margin: { left: "3px", right: "3px" },
          }}
          customClassName="adminConfirmCloneReport-GA"
        />
      </article>
    </section>
  );
};

export { AdminReportClone };
