function validateEmail(email) {
  var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let isValid = re.test(email);

  if (isValid) {
    return {
      status: "valid",
      message: "",
    };
  } else {
    return {
      status: "invalid",
      message: "Please enter a valid email address",
    };
  }
}

function validatePassword(password) {
  //   var re = /\S+@\S+\.\S+/;
  //   let isValid = re.test(password);

  if (password.length <= 0) {
    return {
      status: "invalid",
      message: "Please enter a password",
    };
  } else {
    return {
      status: "valid",
      message: "",
    };
  }
}

function validateSignInForm(selections) {
  const validatedSelections = {
    ...selections,
    email: {
      value: selections.email.value,
      ...validateEmail(selections.email.value),
    },
    password: {
      value: selections.password.value,
      ...validatePassword(selections.password.value),
    },
  };

  return validatedSelections;
}

const isSelectionsInvalid = (selections) => {
  if (
    selections.email.status === "invalid" ||
    selections.password.status === "invalid"
  ) {
    return true;
  } else {
    return false;
  }
};

export { validateSignInForm, isSelectionsInvalid };
