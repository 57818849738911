// Import required libraries
import React, {
  useState,
  useRef,
  useReducer,
  useEffect,
  useContext,
} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import orderBy from "lodash.orderby";
import { v4 } from "uuid";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";

// Import lib components
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

// Import custom components
import SigviewMultiSelectDnD from "../../components/Common/SigviewMultiSelectDnD";
import SigviewTooltip from "../../components/Common/SigviewTooltip";
import SigviewSingleSelect from "../../components/Common/SigviewSingleSelect";
import SigviewRadioGroup from "../../components/Common/SigviewRadioGroup";
import SigviewButton from "../../components/Common/SigviewButton";
import VizItem from "../../components/VizItem/VizItem";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import styles
import "./StandaloneChart.scss";

// Import config
import { config } from "../../config/config";

// Import action creators
import {
  replaceStandaloneChartForm,
  updateStandaloneChartForm,
} from "../../redux/actions";

// Import utils
import useReducerLogger from "../../utils/useReducerLogger";
import staticChartJson from "../../../assets/data/chartTitle.json";
import { masterMakeChartObject } from "../../utils/utils";
import { masterTrackGaEvent } from "../../services/ga";

// Import hooks
import useUpdateEffect from "../../hooks/useUpdateEffect";

// Import reducers
import standaloneChartReducer from "../../redux/reducers/standaloneChart";
import SigviewChartTypes from "../Common/SigviewChartTypes";
import {
  getAscDecBasesOnBusinessLogic,
  getOrderByDropdownBasedOnBusinessLogic,
} from "../../utils/standaloneChartUtils";

// Defining required variables
const defaultState = {
  id: `chart-object-${v4()}`,
  title: "Untitled Chart",
  dimensionsList: [],
  metricsList: [],
  orderById: "",
  orderBy: "desc",
  chartType: "bar",
  metricFilters: [],
  dimensionFilters: [],
  timeFilters: {}, // Invalid
  chartList: staticChartJson,
  elementType: "create", // create or update to help differentiate in PlotCreate,
  renderFlag: false, // to control rendering of the chart in the component
};

// * Define requried styles
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& .MuiPaper-root": {
        boxShadow: themeColors["timeFiltersBoxShadow"],
        backgroundColor: themeColors["popoverBgColor"],
      },
      "& .MuiAccordion-root .MuiCollapse-container": {
        transitionDuration: "300ms !important",
      },
      "& .MuiAccordion-root .MuiCollapse-root": {
        transitionDuration: "300ms !important",
      },
      "& .MuiAccordionSummary-root": {
        padding: "0px 21px",
        cursor: "auto",
        "&.Mui-expanded": { minHeight: "40px !important" },
      },
      "& .MuiAccordionSummary-content": {
        color: themeColors["secondaryColor"],
        "&.Mui-expanded": {
          margin: "10px 0px !important",
        },
        "&>span": { fontSize: "14px !important" },
      },
      "& .MuiIconButton-label": { color: themeColors["secondaryColor"] },
      "& .MuiAccordionDetails-root": { paddingTop: "0px !important" },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));
  return useStyles;
};

// * Define required static variables
const defaultChartData = {
  result: { dataFromQE: [], extraData: {} },
  status: "loading",
  message: "",
};

function StandaloneChart(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    user,
    allData,
    initialState = defaultState,
    initialChartData = defaultChartData,
    updateParentState = false,
    onChange = () => {},
    onChartDataChange = () => {},
  } = props;

  // * Define required states
  const [state, dispatch] = useReducer(
    useReducerLogger(standaloneChartReducer),
    initialState
  );
  const chartContainerId = useRef(`chartHolder-${v4()}`);
  const [accExpanded, setAccExpanded] = useState(true);
  const [chartData, setChartData] = useState(initialChartData);
  const [reloadChartEpoch, setReloadChartEpoch] = useState(
    new Date().valueOf()
  );

  // * Define required event handlers
  const handleDimensionsListChange = (newValue) => {
    const payload = { key: "dimensionsList", value: newValue };
    const action = updateStandaloneChartForm(payload);
    dispatch(action);
  };
  const handleMetricsListChange = (newValue) => {
    const payload = { key: "metricsList", value: newValue };
    const action = updateStandaloneChartForm(payload);
    dispatch(action);
  };
  const handleOrderByIdChange = (newValue) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "CreateChart",
      action: "Order By",
      label: newValue,
    });
    const payload = { key: "orderById", value: newValue };
    const action = updateStandaloneChartForm(payload);
    dispatch(action);
  };
  const handleOrderByChange = (newValue) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "CreateChart",
      action: "Order Type",
      label: newValue,
    });
    const payload = { key: "orderBy", value: newValue };
    const action = updateStandaloneChartForm(payload);
    dispatch(action);
  };
  const handleChartTypeChange = (newValue) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "CreateChart",
      action: "Select Chart",
      label: newValue,
    });
    // Dispatch action only when chart type is different
    if (state.chartType !== newValue) {
      const payload = { key: "chartType", value: newValue };
      const action = updateStandaloneChartForm(payload);
      dispatch(action);
    }
  };
  const handleApply = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "CreateChart",
      action: "Apply",
      label: "Apply",
    });

    //Remove child node of chartContainer
    const chartContainerEl = document.getElementById(chartContainerId.current);
    chartContainerEl.style.height = "0%";
    while (chartContainerEl.firstChild) {
      chartContainerEl.removeChild(chartContainerEl.lastChild);
    }
    const payload = { key: "renderFlag", value: true };
    const action = updateStandaloneChartForm(payload);
    dispatch(action);
  };
  const handleClear = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "CreateChart",
      action: "RemoveAllValues",
      label: "Dimensions&Metrics",
    });

    //Remove child node of chartContainer
    const chartContainerEl = document.getElementById(chartContainerId.current);
    chartContainerEl.style.height = "0%";
    while (chartContainerEl.firstChild) {
      chartContainerEl.removeChild(chartContainerEl.lastChild);
    }
    const clearAllState = {
      ...state,
      dimensionsList: [],
      metricsList: [],
      orderById: "",
      orderBy: "asc",
      chartType: "bar",
      chartList: staticChartJson,
      renderFlag: false,
    };
    const action = replaceStandaloneChartForm(clearAllState);
    dispatch(action);
  };
  const handleAccChange = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "CreateChart",
      action: "DataSelections",
      label: accExpanded ? "Collapse" : "Expand",
    });
    setAccExpanded(!accExpanded);
  };
  const handleChartDataReload = () => {
    setReloadChartEpoch(new Date().valueOf());
  };
  const handleChartDataChange = (event, id, value) => {
    setChartData(value);
  };

  // * Define required side effects
  //Update local state if props change
  useEffect(() => {
    //Perform it only when they are not equal to avoid infinite loop
    if (!isEqual(initialState, state)) {
      const action = replaceStandaloneChartForm(initialState);
      dispatch(action);
    }
  }, [initialState]);
  //OnChange handler for timeFilters (update parent state everytime state changes)
  useEffect(() => {
    //Perform it only when they are not equal to avoid infinite loop
    //Update only when updateParentTimeFilters is true
    //It's to cater to the case when user is making changes in compare calendar
    if (!isEqual(initialState, state) && updateParentState) {
      onChange(state);
    }
  }, [state]);
  //Empty chartContainer child nodes everytime renderChart updates
  useUpdateEffect(() => {
    //Remove child node of chartContainer
    const chartContainerEl = document.getElementById(chartContainerId.current);
    chartContainerEl.style.height = "0%";
    while (chartContainerEl.firstChild) {
      chartContainerEl.removeChild(chartContainerEl.lastChild);
    }
  }, [state.renderFlag]);
  // Reset data whenever selections (state changes)
  useUpdateEffect(() => {
    //Do it only when they are unequal to prevent unnecessary state updates
    if (!isEqual(defaultChartData, chartData)) {
      setChartData(defaultChartData);
    }
  }, [
    state.chartType,
    state.dimensionFilters,
    state.timeFilters,
    state.dimensionsList,
    state.metricsList,
    state.orderById,
    state.orderBy,
  ]);
  // Pass data back to parent chartData changes
  useEffect(() => {
    onChartDataChange(chartData);
  }, [chartData]);

  //Defining required variables
  // Required for DnD component
  // Dimensions
  const dimensionIdAccessor = "_id";
  const dimensionLabelAccessor = "dimTitle";
  const selectedDimensionsIds = state.dimensionsList.map(
    (row) => row[dimensionIdAccessor]
  );
  const unselectedDimensions = allData.plotlyDimensions
    .filter((row) => !selectedDimensionsIds.includes(row[dimensionIdAccessor]))
    .filter((row) => row[dimensionLabelAccessor].toLowerCase() !== "week"); //hard coded: remove week from the list
  const dimensionsDndData = [
    ...state.dimensionsList,
    //HARD CODED: Placing timestamp dimensions first
    ...orderBy(
      unselectedDimensions.filter(
        (row) => row.dataType.toLowerCase() === "datetime"
      ),
      [dimensionLabelAccessor],
      ["asc"]
    ),
    //HARD CODED: Placing non-timestamp dimensions later
    ...orderBy(
      unselectedDimensions.filter(
        (row) => row.dataType.toLowerCase() === "string"
      ),
      [dimensionLabelAccessor],
      ["asc"]
    ),
  ];
  // Metrics
  const metricIdAccessor = "_id";
  const metricLabelAccessor = "measureTitle";
  const selectedMetricsIds = state.metricsList.map(
    (row) => row[metricIdAccessor]
  );
  const unselectedMetrics = allData.plotlyMetrics.filter(
    (row) => !selectedMetricsIds.includes(row[metricIdAccessor])
  );
  const metricsDndData = [
    // NOT ORDERDERING THE SELECTED DIMENSIONS; They will in the order they are selected
    ...state.metricsList,
    //HARD CODED: Placing timestamp dimensions first
    ...orderBy(unselectedMetrics, [metricLabelAccessor], ["asc"]),
  ];
  const renderOptionAutoCompleteMetric = (props) => {
    const { option, allSelectedIds, idAccessor, labelAccessor } = props;
    const selectedFlag = allSelectedIds.includes(option[idAccessor]);
    const metricType = option._id.startsWith("M") ? "metric" : "customMetric";
    let infoTooltipStatus = false;
    if (
      user.reqMetadata.organization === "Kayzen" &&
      user.reqMetadata.view === "Percentile"
    ) {
      infoTooltipStatus =
        (option.displayFormula !== undefined &&
          option.displayFormula.startsWith("(Price Digest")) ||
        (option.displayFormula !== undefined &&
          option.displayFormula.startsWith("(price digest"));
    }
    return (
      <>
        <i
          className={`material-icons auto-complete-option-icon ${
            selectedFlag ? "" : "invisible"
          } `}
        >
          check_circle
        </i>
        <span
          className="quantity-selector-option-title"
          title={option[labelAccessor]}
        >
          {option[labelAccessor]}
        </span>
        {metricType === "customMetric" && !infoTooltipStatus && (
          <SigviewTooltip title={option.displayFormula} top="-12px">
            <i className="material-icons-round info-icon">info</i>
          </SigviewTooltip>
        )}
      </>
    );
  };
  const allSelectedMetricsDimensions =
    getOrderByDropdownBasedOnBusinessLogic(state);
  const ascDescArr = getAscDecBasesOnBusinessLogic(state);
  const orderByShowFlag = allSelectedMetricsDimensions.length ? true : false;
  const chartObject = masterMakeChartObject({
    metadataParams: {
      title: v4(),
      chartType: state.chartType,
    },
    filters: {
      timeFilters: user.timeFilters,
      metricFilters: state.metricFilters,
      dimensionFilters: state.dimensionFilters,
    },
    orderByDetails: {
      orderById: state.orderById,
      orderBy: state.orderBy,
      orderByType: "id_only",
    },
    dimensionsList: state.dimensionsList,
    metricsList: state.metricsList,
  });
  const payload = {
    _id: chartObject.metadata.title,
    emailId: user?.reqMetadata?.email,
    orgViewReq: {
      organization: user?.reqMetadata?.organization,
      view: user?.reqMetadata?.view,
    },
    chartObject,
  };
  const applyButtonDisabledFlag =
    state.dimensionsList.length === 0 ||
    state.metricsList.length === 0 ||
    state.renderFlag;
  const clearButtonEnabledFlag =
    state.dimensionsList.length > 0 || state.metricsList.length > 0;
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  let standaloneChartOuterContainerClassName =
    "standalone-chart-outer-container";
  if (accExpanded) standaloneChartOuterContainerClassName += " expanded";
  if (!accExpanded) standaloneChartOuterContainerClassName += " collapsed";
  let chartContainerClass = "standalone-chart-container";
  chartContainerClass += " no-height";
  const selections = {
    chartType: state.chartType,
    dimensionFilters: state.dimensionFilters,
    timeFilters: state.timeFilters,
    dimensionsList: state.dimensionsList,
    metricsList: state.metricsList,
    orderById: state.orderById,
    orderBy: state.orderBy,
    timeFiltersAppliedAt: "dashboard", //hard coded so that it can cater to both charts and datastory
    dimensionFiltersAppliedAt: "dashboard", //hard coded so that it can can cater to both charts and datastory
  };

  //DEBUGGING
  // console.groupCollapsed("STANDALONE CHART");
  // console.log("state", state);
  // console.log("chartObject", chartObject);
  // console.log("payload", payload);
  // console.log("state.renderFlag", state.renderFlag);
  // console.groupEnd();

  return (
    <div className="standalone-chart-wrapper-non-pivot">
      <div className={classes.root}>
        <Accordion expanded={accExpanded} onChange={handleAccChange}>
          <AccordionSummary
            expandIcon={
              <SigviewTooltip
                title={accExpanded ? "Expand" : "Collapase"}
                placement="bottom"
              >
                <span className="material-icons react-material-icons ChartExpandColapse-GA">
                  expand_more
                </span>
              </SigviewTooltip>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span>{t("Data Selections")}</span>
          </AccordionSummary>
          <AccordionDetails>
            <section className="standalone-chart-data-selections-wrapper-non-pivot">
              <article className="left-panel">
                <div className="left-panel-row">
                  <SigviewMultiSelectDnD
                    data={dimensionsDndData}
                    value={state.dimensionsList}
                    onChange={handleDimensionsListChange}
                    title="Dimensions"
                    idAccessor={dimensionIdAccessor}
                    labelAccessor={dimensionLabelAccessor}
                    variant="horizontal"
                    googleAnalytics={{ category: "CreateChart" }}
                    user={user}
                  />
                </div>

                <div className="left-panel-row">
                  <SigviewMultiSelectDnD
                    data={metricsDndData}
                    value={state.metricsList}
                    onChange={handleMetricsListChange}
                    title="Metrics"
                    idAccessor={metricIdAccessor}
                    labelAccessor={metricLabelAccessor}
                    variant="horizontal"
                    // googleAnalytics={{ category: "ReportManager" }}
                    user={user}
                    renderOptionAutoComplete={renderOptionAutoCompleteMetric}
                  />
                </div>

                <div className="left-panel-row sort-menu-bar">
                  <section className="standalone-menu-bar">
                    {!orderByShowFlag && (
                      <p className="info-title">
                        {t("Please select an attribute")}
                      </p>
                    )}
                    {orderByShowFlag && (
                      <label className="row-title">
                        {t("Data Sorted By:")}
                      </label>
                    )}
                    {orderByShowFlag && (
                      <SigviewSingleSelect
                        value={state.orderById}
                        data={allSelectedMetricsDimensions}
                        onChange={handleOrderByIdChange}
                        minWidth="80px"
                        margin={{ right: "10px" }}
                        customClassName="chartOrderBy-GA"
                      />
                    )}
                    {orderByShowFlag && (
                      <SigviewRadioGroup
                        data={ascDescArr}
                        value={state.orderBy}
                        onChange={handleOrderByChange}
                        style={{ rootFlexDirection: "row" }}
                      />
                    )}
                  </section>
                  <section className="standalone-menu-bar">
                    <SigviewButton
                      variant="outlined"
                      onClick={handleClear}
                      title="Clear"
                      disabled={!clearButtonEnabledFlag}
                      style={{
                        width: "auto",
                        margin: { right: "3px" },
                      }}
                    />
                    <SigviewButton
                      variant="contained"
                      onClick={handleApply}
                      title="Apply"
                      customClassName="ChartApply-GA"
                      disabled={applyButtonDisabledFlag}
                      style={{
                        width: "auto",
                        // margin: { left: "3px", right: "3px" },
                      }}
                    />
                  </section>
                </div>

                {/* <div className="left-panel-row right-aligned padded-right">
                  <SigviewButton
                    variant="containedLighter"
                    onClick={handleClear}
                    title="Clear"
                    disabled={!clearButtonEnabledFlag}
                    style={{
                      width: "50px",
                      margin: { right: "3px" },
                    }}
                  />
                  <SigviewButton
                    variant="containedLighter"
                    onClick={handleApply}
                    title="Apply"
                    disabled={applyButtonDisabledFlag}
                    style={{
                      width: "50px",
                      // margin: { left: "3px", right: "3px" },
                    }}
                  />
                </div> */}
              </article>

              <article className="right-panel">
                <section className="standalone-chart-types-wrapper">
                  <SigviewChartTypes
                    value={state.chartType}
                    data={state.chartList}
                    onChange={handleChartTypeChange}
                    format="grid"
                  />
                </section>
              </article>
            </section>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className={standaloneChartOuterContainerClassName}>
        <div
          id={chartContainerId.current}
          className={chartContainerClass}
        ></div>
        {!state.renderFlag && (
          <div className="chart-placeholder-container">
            <div className="chart-placeholder-image"></div>
            <p className="chart-placeholder-title">
              {t("Chart will appear here after you click apply")}
            </p>
          </div>
        )}
        {state.renderFlag && (
          <VizItem
            id={state.id}
            selections={selections}
            data={chartData}
            payload={payload}
            chartContainerId={chartContainerId.current}
            resizeFlag={accExpanded}
            reloadEpoch={reloadChartEpoch}
            onChartDataReload={handleChartDataReload}
            onChange={handleChartDataChange}
          />
        )}
      </div>
    </div>
  );
}

StandaloneChart.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(StandaloneChart);
