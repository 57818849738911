// * Import required libraries
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";

// * Import lib components
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// * Import custom components
import AuthLoader from "../AuthLoader";

// * Import utils
import { config } from "../../config/config";

// * Import required contexts

// * Import hooks

// * Import APIs
import { getToken, sigviewSignInSso } from "../../services/api";
import signInImage from "../../../assets/images/openxLoginFlowImg.svg";
import openxLogo from "../../../assets/images/openxLogo.svg";
import ApigeeLogo from "../../../assets/images/ApigeeLogo.svg";

// * Import action creators
import { updateAuthLoading, updateChangeFlag } from "../../redux/actions";
import { ThemeContext } from "../../contexts/ThemeContext";
import SigviewTypography from "../../components/Common/SigviewTypography";
import { store } from "../../redux/storeInitializer";
import { getBaseUrlEndpoints } from "../../utils/utils";
import SigviewButton from "../../components/Common/SigviewButton";
import SignInButton from "../../components/Common/SignInButton";

// * Define required static variables
const initialIframeDetails = {
  status: "loading",
  result: { iframeUrl: "" },
  message: "",
};
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent:"space-between"
    },
    leftPanel: {
      width: "50%",
      height: "100%",
      display: "inline-block",
    },
    rightPanel: {
      width: "50%",
      height: "100%",
      display: "inline-block",
    },
    signInSignUpImageContainer: {
      width: "100%",
      height: "calc(100vh - 1px)",
      background: "url(" + signInImage + ")",
      backgroundRepeat: "no-repeat"
    },
    
    mainSignInFormRow: {
      width: "calc(40vw - 50px)",
      height: "65vh",
      marginTop: "100px",
      marginLeft: "5vw",
      backgroundColor: "#f5f5f5",
    },
    mainSignInFormRowLeft: {
      marginLeft: "30px",
      padding: "10px",
      paddingTop:"0px"
    },
    mainSignInFormRowLeftBottom: {
      padding: "10px 10px 15px 10px",
      marginLeft: "20px",
    },
  });
  return useStyles;
};
function SiginInOpenX(props = {}) {
  const { dispatch: ReduxDispatcher = () => {}, authForm = {}, user={} } = props;
  const history = useHistory();
  const location = useLocation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  // * Define required states
  const [iframeDetails, setIframeDetails] = useState(initialIframeDetails);
  const [isoldSSO, setIsOldSSO] = useState(false);
  const [userType , setUserType] = useState("");
  const [isApigeeLogin,setIsApigeeLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // * Define required side effects
  // Function to set valid iframeUrl based on window.location.pathname
  useEffect(() => {
    // * Make iframeUrl based on realm and instance_uid
    var search = location.search;
    var iframeUrl = "";
    var pathnameId = "";
    const openxIframeBaseUrl = config.hardCoded.openxIframeBaseUrl;
    if (search.indexOf("realm") > -1) {
      pathnameId = search.split("realm=").pop();
      iframeUrl = `${openxIframeBaseUrl}?realm=${pathnameId}`;
    } else if (search.indexOf("instance_uid") > -1) {
      pathnameId = search.split("instance_uid=").pop();
      iframeUrl = `${openxIframeBaseUrl}?instance_uid=${pathnameId}`;
    } else {
      iframeUrl = `${openxIframeBaseUrl}`;
    }
    setIframeDetails({ status: "success", message: "", result: { iframeUrl } });
  }, []);

  // Function to add event listener to
  useEffect(() => {
    function handleSignIn(token) {
      var decodedJwt = jwt_decode(token);
      console.log("decodedJwt", decodedJwt);
      const email = decodedJwt.email.toLowerCase();
      const clientId = decodedJwt.clientId;
      const cryptoKey = decodedJwt.cryptokey;
      const userType = decodedJwt.userType;
      const name = decodedJwt.name;
      const instances = decodedJwt.instances;
      const payload = {
        email,
        clientId,
        cryptoKey,
        userType,
        token: token,
      };
      const headers = {
        Authorization: cryptoKey,
        clientid: decodedJwt.clientId,
        useremail: email,
        userType,
      };
      const fetchProps = {
        payload,
        headers,
      };
      const sigviewSignInSsoPromise = sigviewSignInSso(fetchProps);
      sigviewSignInSsoPromise
        .then((response) => {
          if (response.status === 401) throw new Error();
          return response.json();
        })
        .then((responseData) => {
          console.log("responseData1", responseData);
          if (responseData.statusCode === "200") {
            // ! Currently, embeddedInfo.isEmbeddedUser is true only for non-openx embedded users (like kayzen
            // Update embeddedInfo in redux
            // var data = {
            //   embeddedInfo: {
            //     isEmbeddedUser: true,
            //     email: params.get("email"),
            //     clientId: params.get("clientId"),
            //     cryptoKey: params.get("cryptoKey"),
            //     status: "success",
            //     message: "",
            //   },
            // };
            // handleUpdateUserInfo(data);

            // * For OpenX users, we do:
            // 1. Set values to local storage
            // 2. Re-trigger authForm
            // 3. Redirect to home page

            // 1. Set to values to local storage
            const satellizer_token = responseData.token;
            const openxUserDataInfo = [
              {
                token: decodedJwt.token,
                name: name,
                instances: instances[0],
                userType: userType,
              },
            ];
            localStorage.setItem(
              "openxUserData",
              JSON.stringify(openxUserDataInfo)
            );
            localStorage.setItem("satellizer_token", satellizer_token);
            localStorage.setItem("user_email", email);
            const payload = {
              status: "success",

              xAuthToken: {
                "X-Auth-Token": satellizer_token,
                instances: "",
              },
              isTokenPresent: true,
              isTokenValid: true,
            };
            const action = updateAuthLoading(payload);
            ReduxDispatcher(action);

            // // 2. Re-trigger authForm
            // setTimeout(() => {
            //   ReduxDispatcher(updateChangeFlag("authFormEpoch", Date.now()));
            // }, 0);

            // 3. Redirect to home page
            history.push(config.hardCoded.rootNonSigviewUrlOnLogin);
          } else if (responseData.statusCode === "401") {
            throw new Error();
          }
        })
        .catch((json) => {
          console.error("UI ERROR");
          console.groupCollapsed("DETAIL");
          console.log("Component ---> SignInOpenX.js");
          console.log("Function --> handleSignIn");
          console.log("json", json);
          console.groupEnd();

          // ! Currently, embeddedInfo.isEmbeddedUser is true only for non-openx embedded users (like kayzen)
          // Update embeddedInfo in redux
          //   var data = {
          //     embeddedInfo: {
          //       isEmbeddedUser: true,
          //       status: "error",
          //       message: "",
          //     },
          //   };
          //   handleUpdateUserInfo(data);

          // Redirect to sso error page
          history.push("/openxLoginErrorPage");
        });
    }

    function receiveMessageFromOpenxSSO(event) {
      const runFlag =
        event.data !== "" &&
        event.data !== undefined &&
        event.data.value !== undefined;
      if (runFlag) {
        if (event.data.value !== "Internal Server Error") {
          var token = event.data.value;
          handleSignIn(token);
        } else {
          history.push("/openxLoginErrorPage");
        }
      }
    }

    // Add event listener to capture message from OpenX SSO SignIn
    window.addEventListener("message", receiveMessageFromOpenxSSO, true);

    return () => {
      // Remove event listener on unmount
      window.removeEventListener("message", receiveMessageFromOpenxSSO, true);
    };
  }, []);

  // * Define required static variables
  const openxIframeContainerStyle = { width: "100vw", height: "100vh" };

  // * DEBUGGER
  // console.groupCollapsed("SignInOpenX.js");
  // console.log("iframeDetails", iframeDetails);
  // console.groupEnd();

useEffect(()=>{
    setUserType(localStorage.getItem('userType'));
},[])

  const handleOldSSOLogin = () => {
    setIsOldSSO(true);
  };

  const handleApigeeLogin = () => {
    localStorage.setItem("Login Method","Apigee");
    const apiEndpoints = getBaseUrlEndpoints();
    window.location.href = `${apiEndpoints.baseUrlOld}/api/oauth2`;
  };

  useEffect(()=>{
    setTimeout(() => {
    const isapigeeLogin = localStorage.getItem('isapigeeLogin');
    if(isapigeeLogin)
    {
      setIsApigeeLogin(true);
    }
    setIsLoading(false);
  }, 1000);
  },[])
  
  return (
    <>
      {iframeDetails.status === "loading" && <AuthLoader />}
      {iframeDetails.status === "success" && (
        <>
          {isoldSSO && (
            <Box css={openxIframeContainerStyle}>
              <iframe
                id="iFrameLink"
                title="Login Openx"
                src={iframeDetails.result.iframeUrl}
                width="100%"
                height="100%"
              />
            </Box>
          )}
        </>
      )}
      {userType !== "OAA" && !isApigeeLogin && !isLoading &&(
          <Box
            className={classes.root}
          >
            <Box className={classes.leftPanel}>
              <Box className={classes.mainSignInFormRow} >
                <Box marginLeft={3}>
                  <img src={openxLogo} width="20%" height="30%" alt="OpenX Logo" />
                </Box>

                <Box className={classes.mainSignInFormRowLeft}>
                  <SigviewTypography
                    variant="pLarge"
                    style={{ padding: "0px 0px 40px 0px" ,lineHeight:"1.5" }}
                  >
                    Welcome to OpenX, where you can manage media buys to reach<br/>
                    your target audience and maximize your ROI.
                  </SigviewTypography>
                  <SigviewTypography
                    variant="pLargest"
                    style={{ padding: "80px 0px 0px 0px" }}
                  >
                    Log in to your Account
                  </SigviewTypography>
                </Box>
                <Box className={classes.mainSignInFormRowLeftBottom}>
                  <SigviewTypography
                    variant="pSmallMedium"
                    style={{ padding: "0px 0px 0px 10px" }}
                  >
                    Welcome back! Select method to log in:
                  </SigviewTypography>
                </Box>
                <Box marginLeft="40px">
                  <SignInButton
                    onClick={handleOldSSOLogin}
                    title="Log in OpenX SSO"
                    imgUrl={openxLogo}
                    Imgwidth =  "100px" 
                    Imgheight =  "70px"
                  />
                </Box>
                <Box css={{ paddingTop: "13px", marginLeft: "40px" }}>
                  <SignInButton
                    onClick={handleApigeeLogin}
                    title="Log in with APIGEE"
                    imgUrl={ApigeeLogo}
                    Imgwidth =  "100px" 
                    Imgheight =  "30px"
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classes.rightPanel}>
              <Box className={classes.signInSignUpImageContainer}>

              </Box>
              {/* <img src={signInImage} width="100%" height="100%" alt="Sign In"  /> */}
            </Box>
          </Box>
          )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
  allData: state.data,
  authForm: state.auth,
  signInFormStatus: state.signInFormStatus,
});

export default connect(mapStateToProps)(SiginInOpenX);
