// Import required libraies
import React, { useState, useEffect } from "react";
import { format as d3Format } from "d3";

// Import Custom Component
import SigviewTooltip from "../../Common/SigviewTooltip";

// Import custom hooks

// Import action creators

// Import utils
import { getCounterContainerType } from "../../../utils/utils";

// Import styles

// Import data

// Import API functions

function CounterUI(props) {
  const { data = {}, chartContainerId = "", displayLayout, resizeFlag } = props;
  const { value, displayValue } = data.result;
  const [windowResize, setWindowResized] = useState(false);
  const [counterCountainerType, setCounterContainerType] = useState("medium");

  //Add resize event listener
  useEffect(() => {
    function handleCounterResize() {
      setWindowResized(new Date().valueOf());
    }
    window.addEventListener("resize", handleCounterResize);

    return function cleanupListener() {
      window.removeEventListener("resize", handleCounterResize);
    };
  }, []);

  //On change of display layout, replot the chart
  useEffect(() => {
    setWindowResized(new Date().valueOf());
  }, [displayLayout]);

  //Update counter container type based on window resize
  useEffect(() => {
    const counterContainer = document.getElementById(chartContainerId);
    if (counterContainer) {
      const width = counterContainer.offsetWidth;
      const height = counterContainer.offsetHeight;
      const containerType = getCounterContainerType({ width, height });
      setCounterContainerType(containerType);
    }
  }, [windowResize, resizeFlag]); //Need to add layout change as well when the feature is included

  return (
    <SigviewTooltip title={d3Format(",")(value)} placement="bottom-start">
      <p className={`datastory-counter-title ${counterCountainerType}`}>
        {displayValue}
      </p>
    </SigviewTooltip>
  );
}

export default CounterUI;
