//Import required libraies
import React, { useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { v4 } from "uuid";

// Import Custom Component

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import action creators

// Import utils

// Import styles

function SigviewFileInput(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const { accept, style, label, onChange } = props;
  const fileId = useRef(v4());

  const useStyles = makeStyles(() => ({
    root: {
      marginRight: style?.margin?.right || "0px",
      marginLeft: style?.margin?.left || "0px",
    },
    inputFile: {
      opacity: "0",
      width: "0.1px",
      height: "0.1px",
      position: "absolute",
    },
    label: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "24px",
      boxSizing: "border-box",
      fontFamily: "inherit",
      fontSize: "10px",
      border: `1px solid ${themeColors["primaryColor"]}`,
      borderRadius: "3px",
      color: themeColors["primaryColor"],
      padding: "0px 4px",
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  }));
  const classes = useStyles();

  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <div className={classes.root}>
      <input
        type="file"
        id={fileId.current}
        className={classes.inputFile}
        accept={accept}
        onChange={(e) => handleChange(e)}
      />
      <label htmlFor={fileId.current} className={classes.label}>
        {label}
      </label>
    </div>
  );
}

export default SigviewFileInput;
