// Import required libraies
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import _ from "underscore";
import axios from "axios";
// import { useDidUpdate } from "rooks";

// Import Custom Component

// Import Context

// Import hooks
import useUpdateEffect from "../../../hooks/useUpdateEffect";

// Import utils/data
import { config } from "../../../config/config";

// Import plotly utils
import {
  buildXvalFromChartObject,
  buildYvalFromChartObject,
  buildSortbyFromChartObject,
  extractChartTypeFromChartObject,
  supportsStackedBarChart,
  formatFiltersFromVals,
  isTimeDimension,
  getParamsFromChartObj,
} from "../../../utils/plotlyUtils";
import { transformFiltersUiToBackend } from "../../../utils/filtersUtils";

// Import API functions
import { getData } from "../../../services/api";

function ChartData(props) {
  const {
    allData = {},
    payload = {},
    globalFilters = {},
    id = "",
    data = {},
    onChange = () => {},
    onChartDataReload = () => {},
    selections = {},
    reloadEpoch = 0,
  } = props;

  // Defining required event handlers/util functions
  const handleError = (message) => {
    onChange(null, id, {
      result: { dataFromQE: [], extraData: {} },
      status: "error",
      message: message || config.hardCoded.uiErrorMessage,
    });
  };
  // * Define required event handlers
  const handleChartDataReload = (event) => onChartDataReload(event, id);

  // getData Chart Data
  useEffect(() => {
    // Define required variables
    const { timeFilters, dimensionFilters } = selections;

    //Making required source objects for cancellation in clean-up function
    const sourceSingleCall = axios.CancelToken.source();
    const sourceMultiCall1 = axios.CancelToken.source();
    const sourceMultiCall2 = axios.CancelToken.source();
    const sourceMultiCall3 = axios.CancelToken.source();

    // console.log("I CAME HERE 4");
    // console.log("data.status", data.status);
    if (data.status === "loading") {
      // Initializing required variables
      let dataFromQEFinal = [];
      let extraDataFinal = [];

      try {
        // Make a copy of the payload
        let payloadCopy = cloneDeep(payload);

        // Update time filters based on user selections
        payloadCopy = {
          ...payloadCopy,
          chartObject: {
            ...payloadCopy.chartObject,
            requestParam: {
              ...payloadCopy.chartObject.requestParam,
              filter: transformFiltersUiToBackend([...dimensionFilters]), //don't give dimensionFilters directly; it creates a difficult to catch bug; this was before we used transformFiltersUiToBackend
              timeZone: {
                name: timeFilters.selectedTimezone.name,
                location: timeFilters.selectedTimezone.location,
                value: timeFilters.selectedTimezone.value,
              },
              dateRange: {
                startDate: timeFilters.selectedDatesQE.startDate.toString(),
                endDate: timeFilters.selectedDatesQE.endDate.toString(),
              },
              granularity: globalFilters?.metricChartGranularity?.value,
            },
          },
        };
        var chartObject = payloadCopy.chartObject;
        var xval = buildXvalFromChartObject({
          chartObject,
          plotlyDimensions: allData.plotlyDimensions,
        });
        var yval = buildYvalFromChartObject({
          chartObject,
          plotlyMetrics: allData.plotlyMetrics,
        });
        var chartType = extractChartTypeFromChartObject(chartObject);
        var sortBy = buildSortbyFromChartObject({
          chartObject,
          plotlyMetrics: allData.plotlyMetrics,
          plotlyDimensions: allData.plotlyDimensions,
        });
        var supportsStackedBarFlag = supportsStackedBarChart({
          xval,
          yval,
          sortBy,
          chartType,
        });
        var crosstabFlag = chartType === "crosstabheatmap";

        // console.log("supportsStackedBarFlag", supportsStackedBarFlag);

        if (supportsStackedBarFlag) {
          var extraData = {};

          //update extraData
          // console.log("I SHOULD BE HERE");

          //Make a copy of the chartObject
          var newChartObj = cloneDeep(payloadCopy.chartObject);
          // console.log("FILTER ME LATEST newChartObj", newChartObj);

          //keep only one element in xAxis
          newChartObj.requestParam.xAxis.splice(1, 1);

          //Get filters data
          var obj = formatFiltersFromVals(
            newChartObj.requestParam.filter,
            xval[0],
            [],
            1
          );
          // console.log("FILTER ME LATEST obj", obj);

          //If first dimension is time dimension, add filters to chartObject, else don't
          if (!isTimeDimension(xval[0])) {
            newChartObj.requestParam.filter = obj.filters;
          } else {
            newChartObj.requestParam.filter = [];
          }

          //Change data limit if first dimension is time dimension
          newChartObj.metadata.dataLimit = isTimeDimension(xval[0]) ? 500 : 20;

          //Fetch to get data
          const fetchProps = {
            payload: {
              ...payloadCopy,
              chartObject: newChartObj,
            },
            cancelToken: sourceMultiCall1.token,
          };
          const getDataPromise = getData(fetchProps);
          getDataPromise
            .then((responseDate) => {
              if (responseDate.result.data.length > 0) {
                var customFilterVals = _.pluck(
                  responseDate.result.data,
                  xval[0].elementID
                );
                // console.log(
                //   "FILTER ME LATEST customFilterVals",
                //   customFilterVals
                // );
                extraData["xAxisData"] = responseDate.result.data;

                // getAdditionalStackBarData(chartObj, topDimValues);
                //chartObj is chartObject
                //customFilterVals is topDimValues

                //Make a copy of the original chart object
                var newChartObj = cloneDeep(payloadCopy.chartObject);
                // console.log("LATEST MATCH 1", newChartObj);

                //If the filters have length and the xAxis[0] is not a time dim
                if (customFilterVals.length > 0 && !isTimeDimension(xval[0])) {
                  var obj = formatFiltersFromVals(
                    getParamsFromChartObj(newChartObj, "filter"),
                    xval[0],
                    customFilterVals,
                    2
                  );
                  // console.log("LATEST MATCH 2", obj);
                  if (obj.sendQuery) {
                    // console.log("HERE");
                    // console.log("obj", obj);
                    newChartObj.requestParam.filter = obj.filters;
                  }
                }
                // console.log("LATEST MATCH 3", newChartObj);

                //Change flag isSortedByTime based on some logic
                var isSortedByTime = false;
                _.each(xval, function (dim) {
                  if (dim.elementID === sortBy[0].id && isTimeDimension(dim)) {
                    isSortedByTime = true;
                    return false;
                  }
                });

                // console.log("LATEST MATCH 4", isSortedByTime);

                //set data limit
                if (
                  isSortedByTime &&
                  sortBy[0].id === xval[0].elementID &&
                  !sortBy[0].desc
                ) {
                  newChartObj.metadata.dataLimit = 5000;
                }

                // console.log("LATEST MATCH 5", newChartObj);

                //change order by
                if (
                  yval.length > 0 &&
                  sortBy[0].id !== yval[0].elementID &&
                  sortBy[0].desc
                ) {
                  newChartObj.requestParam.orderBy = {};
                  newChartObj.requestParam.orderBy = {
                    metricOrdByList: [
                      {
                        id: yval[0]._id,
                        desc: true,
                      },
                    ],
                  };
                }

                // console.log("LATEST MATCH 6", newChartObj);

                //change order by
                if (
                  getParamsFromChartObj(newChartObj, "specialCalculation")
                    .length > 0 &&
                  sortBy[0].id !==
                    getParamsFromChartObj(
                      newChartObj,
                      "specialCalculation"
                    )[0] &&
                  sortBy[0].desc
                ) {
                  newChartObj.requestParam.orderBy = {};
                  newChartObj.requestParam.orderBy = {
                    customMetricOrdByList: [
                      {
                        id: getParamsFromChartObj(
                          newChartObj,
                          "specialCalculation"
                        )[0],
                        desc: true,
                      },
                    ],
                  };
                }

                // console.log("LATEST MATCH 7", newChartObj);

                //Making secChartObject
                var secChartObject = cloneDeep(newChartObj);
                secChartObject.metadata.dataLimit = 50;
                secChartObject.requestParam.xAxis.splice(0, 1);
                secChartObject.requestParam.orderBy = {};
                if (newChartObj.requestParam.yAxis.length > 0) {
                  secChartObject.requestParam.orderBy = {
                    metricOrdByList: [
                      {
                        id: secChartObject.requestParam.yAxis[0],
                        desc: true,
                      },
                    ],
                  };
                }
                if (newChartObj.requestParam.specialCalculation.length > 0) {
                  secChartObject.requestParam.orderBy = {
                    customMetricOrdByList: [
                      {
                        id: getParamsFromChartObj(
                          secChartObject,
                          "specialCalculation"
                        )[0],
                        desc: true,
                      },
                    ],
                  };
                }

                // console.log("newChartObj", newChartObj);
                // console.log("secChartObject", secChartObject);

                var primaryChartObject = cloneDeep(newChartObj);
                // ! Initially it was set to 500 to match angular which was probably as mistake since it gave an incorrect chart data
                // ! which didn't match the then prod version
                // * Hence, commenting it out
                // Changing data limit to 500 to match angular
                // primaryChartObject.metadata.dataLimit = 500;

                //Fetch to get data
                const fetchPropsPrimaryQuery = {
                  payload: {
                    ...payloadCopy,
                    chartObject: primaryChartObject,
                  },
                  cancelToken: sourceMultiCall2.token,
                };
                const fetchPropsSecQuery = {
                  payload: {
                    ...payloadCopy,
                    chartObject: secChartObject,
                  },
                  cancelToken: sourceMultiCall3.token,
                };
                //get chart data
                var primaryQueryPromise = getData(fetchPropsPrimaryQuery);
                var secQueryPromise = getData(fetchPropsSecQuery);

                Promise.all([primaryQueryPromise, secQueryPromise]).then(
                  function (res) {
                    if (
                      !res[0].result.data.length ||
                      !res[1].result.data.length
                    ) {
                      // console.log("error", "Couldn't plot chart, No Data!");
                    } else {
                      if (sortBy[0].id === xval[0].elementID) {
                        // console.log("I SHOULD COME HERE");
                        // console.log("I SHOULD COME HERE", xval[0].elementID);
                        // console.log("I SHOULD COME HERE", res[0].result.data);
                        res[0].result.data = _.sortBy(
                          res[0].result.data,
                          xval[0].elementID
                        );
                        // console.log("I SHOULD COME HERE", res[0].result.data);
                        //if(chartObj.requestParam.orderBy[0].desc){
                        if (sortBy[0].desc) {
                          res[0].result.data = res[0].result.data.reverse();
                          // console.log("I SHOULD COME HERE", res[0].result.data);
                        }
                      }
                      extraData["legendsData"] = res[1].result.data;
                      dataFromQEFinal = res[0].result.data;
                      extraDataFinal = cloneDeep(extraData);
                      // Update parent state
                      onChange(null, id, {
                        status: "success",
                        message: "",
                        result: {
                          dataFromQE: dataFromQEFinal,
                          extraData: extraDataFinal,
                        },
                        reload: data.reload,
                      });
                      // console.log(res[0].result.data);
                      // console.log(extraData);
                    }
                  },
                  function (error) {
                    if (error.error !== config.hardCoded.queryCancelled) {
                      handleError(error.error);
                    }
                  }
                );
              }
            })
            .catch((error) => {
              if (error.error !== config.hardCoded.queryCancelled) {
                handleError(error.error);
              }
            });
        } else if (crosstabFlag) {
          // var chartObject = cloneDeep(payloadCopy.chartObject);
          var chartObj1 = cloneDeep(payloadCopy.chartObject);
          var chartObj2 = cloneDeep(payloadCopy.chartObject);

          function addFiltersToChartObj(chartObj, id, values) {
            chartObj.requestParam.filter.push({
              filterType: "sum",
              values: values,
              id,
            });
            return chartObj;
          }

          //NEED TO UNDERSTAND AND SORT THIS (sort by dim is not supported in sigview)
          //if orderBy is on the basis of dim
          // if (sortBy._id == xval[0]) {
          //   var metricId =
          //     chartObj1.requestParam.yAxis.length > 0
          //       ? chartObj1.requestParam.yAxis[0].elementID
          //       : chartObj1.requestParam.specialCalculation[0].alias;
          //   chartObj1.requestParam.orderBy = [
          //     {
          //       id: metricId,
          //       desc: true,
          //     },
          //   ];
          // }
          // if (
          //   chartObj2.requestParam.orderBy[0].id ==
          //   chartObj2.requestParam.xAxis[0].elementID
          // ) {
          //   var metricId =
          //     chartObj1.requestParam.yAxis.length > 0
          //       ? chartObj1.requestParam.yAxis[0].elementID
          //       : chartObj1.requestParam.specialCalculation[0].alias;
          //   chartObj2.requestParam.orderBy = [
          //     {
          //       id: metricId,
          //       desc: true,
          //     },
          //   ];
          // }
          chartObj1.requestParam.xAxis.splice(1, 1);
          chartObj2.requestParam.xAxis.splice(0, 1);

          //Fetch to get data
          const fetchPropsReq1 = {
            payload: {
              ...payloadCopy,
              chartObject: chartObj1,
            },
            cancelToken: sourceMultiCall1.token,
          };
          const fetchPropsReq2 = {
            payload: {
              ...payloadCopy,
              chartObject: chartObj2,
            },
            cancelToken: sourceMultiCall2.token,
          };

          var req1 = getData(fetchPropsReq1);
          var req2 = getData(fetchPropsReq2);
          Promise.all([req1, req2]).then(
            function (response) {
              if (
                response[0].result.data.length === 0 ||
                response[1].result.data.length === 0
              ) {
                // console.log("error", "Couldn't plot chart, No Data!");
              } else {
                //Adding filter values in the chart object
                var filterVals = response[0].result.data.splice(0, 15);
                var dim1Vals = _.pluck(filterVals, xval[0].elementID);
                chartObject = addFiltersToChartObj(
                  chartObject,
                  xval[0]._id,
                  dim1Vals
                );

                filterVals = response[1].result.data.splice(0, 15);
                var dim2Vals = _.pluck(filterVals, xval[1].elementID);
                chartObject = addFiltersToChartObj(
                  chartObject,
                  xval[1]._id,
                  dim2Vals
                );

                //Fetch to get data
                const fetchProps = {
                  payload: {
                    ...payloadCopy,
                    chartObject: chartObject,
                  },

                  cancelToken: sourceMultiCall3.token,
                };

                const getDataPromise = getData(fetchProps);
                getDataPromise
                  .then((responseDate) => {
                    var extraData = {};
                    extraData["legendsData"] = {};
                    extraData["legendsData"][xval[0].elementID] = dim1Vals;
                    extraData["legendsData"][xval[1].elementID] = dim2Vals;
                    dataFromQEFinal = responseDate.result.data;
                    extraDataFinal = cloneDeep(extraData);
                    // Update parent state
                    onChange(null, id, {
                      status: "success",
                      message: "",
                      result: {
                        dataFromQE: dataFromQEFinal,
                        extraData: extraDataFinal,
                      },
                      reload: data.reload,
                    });
                  })
                  .catch((error) => {
                    if (error.error !== config.hardCoded.queryCancelled) {
                      handleError(error.error);
                    }
                  });
              }
            },
            function (error) {
              if (error.error !== config.hardCoded.queryCancelled) {
                handleError(error.error);
              }
            }
          );
        } else {
          const fetchProps = {
            payload: payloadCopy,
            cancelToken: sourceSingleCall.token,
          };
          const getDataPromise = getData(fetchProps);
          getDataPromise
            .then((responseDate) => {
              dataFromQEFinal = responseDate.result.data;
              extraDataFinal = {};
              // Update parent state
              onChange(null, id, {
                status: "success",
                message: "",
                result: {
                  dataFromQE: dataFromQEFinal,
                  extraData: extraDataFinal,
                },
                reload: data.reload,
              });
            })
            .catch((error) => {
              if (error.error !== config.hardCoded.queryCancelled) {
                handleError(error.error);
              }
            });
        }
      } catch (err) {
        console.error("UI ERROR");
        console.groupCollapsed("DETAILS");
        console.error(err);
        console.groupEnd();
        handleError(config.hardCoded.uiErrorMessage);
      }
    }

    // Clean-up function to cancel all pending fetch calls
    return () => {
      // Cancel all previous fetch calls
      if (sourceSingleCall) sourceSingleCall.cancel();
      if (sourceMultiCall1) sourceMultiCall1.cancel();
      if (sourceMultiCall2) sourceMultiCall2.cancel();
      if (sourceMultiCall3) sourceMultiCall3.cancel();
    };
  }, [data.status, reloadEpoch]);

  // useEffect(() => {
  //   return () => {
  //     console.error("CHARTDATA UNMOUNTED");
  //   };
  // }, []);

  useUpdateEffect(() => {
    // console.error("I CAME HERE");
    // console.error("MOUNTED");
    // console.log("selections", selections);
    // console.log("data.status", data.status);
    // ! Creates a bug where if the chart data is loading, it won't cancel or refetch the request
    // if (["success", "error"].includes(data.status)) {
    //   // console.error("I CAME HERE 2");
    //   // Reset parent chart data state to loading anytime selections or reloadEpoch change
    //   onChange(null, id, {
    //     result: { dataFromQE: [], extraData: {} },
    //     status: "loading",
    //     message: "",
    //   });
    // }
    // Change state to loading
    onChange(null, id, {
      result: { dataFromQE: [], extraData: {} },
      status: "loading",
      message: "",
    });
    // Change reload epoch to retrigger fetch
    handleChartDataReload();
    return () => {
      // console.error("CLEANUP");
    };
    // ! Don't replace this with selections object. It doesn't work. Perhaps because of a
    // ! new reference that gets created on every change in props or state
  }, [
    // ! Comment out selections.chartType below to achieve smooth transition of charts (no reload); can be done only when all charts have the same call
    selections.chartType,
    selections.dimensionFilters,
    selections.timeFilters,
    selections.dimensionsList,
    selections.metricsList,
    selections.orderById,
    selections.orderBy,
    selections.timeFiltersAppliedAt,
    selections.dimensionFiltersAppliedAt,
  ]);

  // Debugger
  // useEffect(() => {
  //   console.groupCollapsed("ChartData.js");
  //   console.log("props", props);
  //   console.groupEnd();
  // });

  return <></>;
}

ChartData.propTypes = {};

const mapStateToProps = (state) => ({
  globalFilters: state.globalFilters,
});

// const mapDispatchToProps = {};

export default connect(mapStateToProps)(ChartData);
//export default ChartData;
