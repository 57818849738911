//Import required libraies
import React from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";

// Import action creators
import { updateUserScreen } from "../../redux/actions";

function SigviewDialog(props) {
  const { dispatch: ReduxDispatcher, user } = props;

  // Defining required event handlers
  const handleClose = () => {
    const payload = { open: false, children: null };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  // Defining required variables
  const open = user.screen.sigviewDialog.open;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      {user.screen.sigviewDialog.children}
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(SigviewDialog);
