// Import required libraries
import React, { useState, useEffect } from "react";
import axios from "axios";
import isEqual from "lodash.isequal";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// Import custom components
import SigviewRadioGroup from "../../components/Common/SigviewRadioGroup";
import SigviewTextField from "../../components/Common/SigviewTextField";
import SigviewSingleSelect from "../../components/Common/SigviewSingleSelect";
import SigviewTypography from "../../components/Common/SigviewTypography";
import SigviewButton from "../../components/Common/SigviewButton";
import SigviewInputTags from "../../components/Common/SigviewInputTags";
import SigviewSwitch from "../../components/Common/SigviewSwitch";
import SigviewNav from "../../components/Common/SigviewNav";
import AlertManagerFormRow from "./AlertManagerComponents/AlertManagerFormRow";
import AlertManagerFormRowItem from "./AlertManagerComponents/AlertManagerFormRowItem";
import AlertManagerSummary from "./AlertManagerComponents/AlertManagerSummary";
import AlertCriteriaComponent from "./AlertManagerComponents/AlertCriteriaComponent";
import SelectedFilters from "../../components/GlobalFilters/SelectedFilters";

// Import utils/data
import { masterTrackGaEvent } from "../../services/ga";
import { validateEmailAddress, getSigviewUserType } from "../../utils/utils";
import {
  updateAlertForm,
  updateAlertFormDimensionRow,
  updateUserScreen,
  updateDialogInfo,
  updateChangeFlag,
  replaceAllDimensionFilters,
} from "../../redux/actions";
import dimensionFiltersReducer from "../../redux/reducers/dimensionFilters";
import {
  validateAlertFormData,
  wrapper,
  alertFormData,
  getAllPlotlyMetrics,
  getAllPlotlyDimensions,
} from "../../utils/alertUtils";
import { config } from "../../config/config";

// Import Api
import {
  createAlert,
  updateAlert,
  deleteAlert,
} from "../../services/api/index";

// Defining required constants

const AlertDimension = (props = {}) => {
  const { t } = useTranslation();
  const {
    dispatch,
    state,
    allData,
    validMetric,
    showError,
    validDimension,
    validConditionValue,
  } = props;

  const sigviewUserType = getSigviewUserType();

  const handleAlertMeDimension = (newValue) => {
    // reducer dispatch action with value
    const payload = { key: "dimensionRow", value: newValue };
    const action = updateAlertFormDimensionRow(payload);
    dispatch(action);
  };
  const optionOneSelected =
    state.alertCriteriaDimension.value.dimensionRow.value === "change";
  const optionTwoSelected =
    state.alertCriteriaDimension.value.dimensionRow.value === "add";
  const optionThreeSelected =
    state.alertCriteriaDimension.value.dimensionRow.value === "delete";
  return (
    <>
      <AlertManagerFormRowItem
        style={{ height: "2px", alertMe: true }}
        title="Alert Me:"
      >
        <Box css={{ height: "38px" }}>
          <SigviewRadioGroup
            data={alertFormData.alertCriteriaDimension.data.dimensionRow.change}
            onChange={(newValue) => handleAlertMeDimension(newValue)}
            value={state.alertCriteriaDimension.value.dimensionRow.value}
            customClassName={`AlertMeClick-${state.alertCriteriaDimension.value.dimensionRow.value}-GA`}
          />
        </Box>

        <Box css={{ height: "25px" }}>
          <SigviewTypography
            style={{
              height: "max-content",
            }}
            variant="pMedium"
          >
            If
          </SigviewTypography>
        </Box>
        <Box css={{ height: "40px !important" }}>
          <SigviewSingleSelect
            value={state.alertCriteriaDimension.value.changeCriteria.value}
            data={alertFormData.alertCriteriaDimension.data.changeCriteria}
            onChange={(newValue) => {
              // reducer dispatch action with value
              const payload = { key: "changeCriteria", value: newValue };
              const action = updateAlertFormDimensionRow(payload);
              dispatch(action);
            }}
            disabled={!optionOneSelected}
            margin={{ right: "5px", left: "5px" }}
            minWidth="110px"
            height="28px"
            fontSize="11px"
            backgroundColor="#fff"
            border="1px solid #edf1ff"
            customClassName={`AlertDimension${state.alertCriteriaDimension.value.changeCriteria.value}-GA`}
          />
        </Box>
        <Box css={{ height: "40px !important" }}>
          <SigviewSingleSelect
            value={state.alertCriteriaDimension.value.metric.value}
            data={getAllPlotlyMetrics(allData)}
            onChange={(newValue) => {
              // reducer dispatch action with value
              const payload = { key: "metric", value: newValue };
              const action = updateAlertFormDimensionRow(payload);
              dispatch(action);
            }}
            helperText="Select Metric cannot be empty"
            disabled={!optionOneSelected}
            error={validMetric && optionOneSelected && showError.flag}
            margin={{ right: "5px" }}
            minWidth="140px"
            height="28px"
            fontSize="11px"
            border="1px solid #edf1ff"
            backgroundColor="#fff"
          />
        </Box>

        <Box css={{ height: "25px" }}>
          <SigviewTypography
            style={{
              height: "max-content",
            }}
            variant="pSmallMedium"
          >
            for
          </SigviewTypography>
        </Box>

        <Box css={{ height: "40px !important" }}>
          <SigviewSingleSelect
            value={state.alertCriteriaDimension.value.group.value}
            data={alertFormData.alertCriteriaDimension.data.groupComparison}
            onChange={(newValue) => {
              // reducer dispatch action with value
              const payload = { key: "group", value: newValue };
              const action = updateAlertFormDimensionRow(payload);
              dispatch(action);
            }}
            disabled={!optionOneSelected}
            margin={{ right: "5px", left: "5px" }}
            minWidth="90px"
            maxWidth="90px"
            height="28px"
            fontSize="11px"
            border="1px solid #edf1ff"
            backgroundColor="#fff"
          />
        </Box>
        <Box css={{ height: "40px !important" }}>
          <SigviewSingleSelect
            value={state.alertCriteriaDimension.value.dimensionChange.value}
            data={getAllPlotlyDimensions(allData)}
            onChange={(newValue) => {
              // reducer dispatch action with value
              const payload = { key: "dimensionChange", value: newValue };
              const action = updateAlertFormDimensionRow(payload);
              dispatch(action);
            }}
            helperText="Select Dimension cannot be empty"
            disabled={!optionOneSelected}
            error={validDimension && optionOneSelected && showError.flag}
            margin={{ right: "5px" }}
            minWidth="140px"
            height="28px"
            fontSize="11px"
            border="1px solid #edf1ff"
            backgroundColor="#fff"
          />
        </Box>

        {
          <>
            {(state.alertCriteriaDimension.value.changeCriteria.value ===
              "percentageChange" && (
              <Box css={{ height: "40px !important" }}>
                <SigviewSingleSelect
                  value={
                    state.alertCriteriaDimension.value.condition
                      .relativeComparison.value
                  }
                  data={
                    alertFormData.alertCriteriaDimension.data.condition
                      .relativeComparison
                  }
                  //TODO:
                  onChange={(newValue) => {
                    // reducer dispatch action with value
                    const payload = {
                      key: "relativeComparison",
                      value: newValue,
                    };
                    const action = updateAlertFormDimensionRow(payload);
                    dispatch(action);
                  }}
                  disabled={!optionOneSelected}
                  margin={{ right: "5px" }}
                  minWidth="110px"
                  height="28px"
                  fontSize="11px"
                  border="1px solid #edf1ff"
                  backgroundColor="#fff"
                />
              </Box>
            )) || (
              <Box css={{ height: "40px !important" }}>
                <SigviewSingleSelect
                  value={
                    state.alertCriteriaDimension.value.condition
                      .absoluteComparison.value
                  }
                  data={
                    alertFormData.alertCriteriaDimension.data.condition
                      .absoluteComparison
                  }
                  //TODO:
                  onChange={(newValue) => {
                    // reducer dispatch action with value
                    const payload = {
                      key: "absoluteComparison",
                      value: newValue,
                    };
                    const action = updateAlertFormDimensionRow(payload);
                    dispatch(action);
                  }}
                  disabled={!optionOneSelected}
                  margin={{ right: "5px" }}
                  minWidth="100px"
                  //maxWidth="100px"
                  height="28px"
                  fontSize="11px"
                  border="1px solid #edf1ff"
                  backgroundColor="#fff"
                />
              </Box>
            )}
          </>
        }

        <Box css={{ height: "40px !important" }}>
          <SigviewTextField
            placeholder="Enter a Number"
            type="number"
            border="1px solid #edf1ff"
            backgroundColor="#fff"
            onChange={(newValue) => {
              // reducer dispatch action with value

              const payload = {
                key: "conditionValue",
                value: newValue,
              };
              const action = updateAlertFormDimensionRow(payload);
              dispatch(action);
            }}
            value={state.alertCriteriaDimension.value.conditionValue.value}
            maxWidth="110px"
            helperText="Threshold field cannot be empty"
            disabled={!optionOneSelected}
            readOnly={!optionOneSelected}
            error={validConditionValue && optionOneSelected && showError.flag}
            helperText={validConditionValue}
            margin={{ right: "5px" }}
            minWidth="110px"
            height="28px"
            fontSize="11px"
            border="1px solid #edf1ff"
            backgroundColor="#fff"
          />
        </Box>

        {state.alertCriteriaDimension.value.changeCriteria.value ===
          "percentageChange" && (
          <Box
            css={{
              height: "25px",
            }}
          >
            <SigviewTypography
              style={{
                height: "max-content",
              }}
              variant="pSmallMedium"
            >
              %
            </SigviewTypography>
          </Box>
        )}
      </AlertManagerFormRowItem>

      <AlertManagerFormRowItem>
        <Box css={{ height: "38px" }}>
          <SigviewRadioGroup
            data={alertFormData.alertCriteriaDimension.data.dimensionRow.add}
            onChange={(newValue) => {
              // reducer dispatch action with value
              const payload = {
                key: "dimensionRow",
                value: newValue,
              };
              const action = updateAlertFormDimensionRow(payload);
              dispatch(action);
            }}
            value={state.alertCriteriaDimension.value.dimensionRow.value}
          />
        </Box>
        <Box css={{ height: "25px" }}>
          <SigviewTypography
            style={{
              height: "max-content",
            }}
            variant="pMedium"
          >
            If there is a new value in
          </SigviewTypography>
        </Box>
        <Box css={{ height: "40px !important" }}>
          <SigviewSingleSelect
            value={state.alertCriteriaDimension.value.dimensionAdd.value}
            data={getAllPlotlyDimensions(allData)}
            onChange={(newValue) => {
              // reducer dispatch action with value
              const payload = {
                key: "dimensionAdd",
                value: newValue,
              };
              const action = updateAlertFormDimensionRow(payload);
              dispatch(action);
            }}
            helperText="Select Dimension cannot be empty"
            disabled={!optionTwoSelected}
            error={validDimension && optionTwoSelected && showError.flag}
            margin={{ left: "5px" }}
            minWidth="110px"
            height="28px"
            fontSize="11px"
            border="1px solid #edf1ff"
            backgroundColor="#fff"
          />
        </Box>
      </AlertManagerFormRowItem>

      <AlertManagerFormRowItem>
        <Box css={{ height: "38px" }}>
          <SigviewRadioGroup
            data={alertFormData.alertCriteriaDimension.data.dimensionRow.delete}
            onChange={(newValue) => {
              // reducer dispatch action with value
              const payload = {
                key: "dimensionRow",
                value: newValue,
              };
              const action = updateAlertFormDimensionRow(payload);
              dispatch(action);
            }}
            value={state.alertCriteriaDimension.value.dimensionRow.value}
          />
        </Box>

        <Box css={{ height: "25px" }}>
          <SigviewTypography
            style={{
              height: "max-content",
            }}
            variant="pMedium"
          >
            If there was a dropped value dropped in
          </SigviewTypography>
        </Box>

        <Box css={{ height: "40px !important" }}>
          <SigviewSingleSelect
            value={state.alertCriteriaDimension.value.dimensionDelete.value}
            data={getAllPlotlyDimensions(allData)}
            onChange={(newValue) => {
              // reducer dispatch action with value
              const payload = {
                key: "dimensionDelete",
                value: newValue,
              };
              const action = updateAlertFormDimensionRow(payload);
              dispatch(action);
            }}
            helperText="Select Dimension cannot be empty"
            disabled={!optionThreeSelected}
            error={validDimension && optionThreeSelected && showError.flag}
            margin={{ left: "5px" }}
            minWidth="110px"
            height="28px"
            fontSize="11px"
            border="1px solid #edf1ff"
            backgroundColor="#fff"
          />
        </Box>
      </AlertManagerFormRowItem>
    </>
  );
};

function AlertManagerForm(props) {
  const { t } = useTranslation();
  // Destructure props
  const {
    state,
    dispatch,
    ReduxDispatcher,
    user,
    globalFilters,
    allData,
    setShowAlertManager,
    reloadData,
    setReloadData,
    alertFormDataOriginal,
    activeAlertNav,
    setActiveAlertNav,
    setSelectedAlertId,
    alertsName,
  } = props;

  // * Defining required states
  const [showError, setShowError] = useState({
    flag: false,
  });

  const pathname = window.location.pathname;
  const rootPage = pathname.split("/")[1];

  // Validate the alert form state
  const validate = validateAlertFormData(state);

  // * Define required variables
  const dimensionFiltersSettings = {
    isAdvancedFilterVisible: true,
    isExcludeAllowed: true,
    disabledItemsList: [], // ["D044"] --> This needs to be a array of ids
  };

  //Name field  validation flag
  const sigviewUserType = getSigviewUserType();

  const checkSpecialChar = (value) => {
    if (sigviewUserType === "sigview") return !/^[A-z0-9-_]*$/.test(value);
    else
      return !/^[a-zA-Z0-9-_!@#$%^&*()+=~|:;',./<>? \u3040-\u309F\u30A0-\u30FF-\u31F0-\u31FF-\u2E80-\u2EFF-\u2F00-\u2FDF-\u3000-\u303F-\u31C0-\u31EF-\u3200-\u32FF-\u3300-\u33FF-\u3400-\u3FFF-\u4000-\u4DBF-\u4E00-\u4FFF]*$/.test(
        state.name.value
      );
  };

  const validateName =
    state.name.value.trim() === "" ||
    state.name.value.length === 0 ||
    state.name.value.length > 100 ||
    checkSpecialChar(state.name.value);

  const validateNameMessage = (value) => {
    const runFlagCheck =
      sigviewUserType === "sigview"
        ? !/^[A-z0-9-_ ]*$/.test(value)
        : !/^[a-zA-Z0-9-_!@#$%^&*()+=~|:;',./<>? \u3040-\u309F\u30A0-\u30FF-\u31F0-\u31FF-\u2E80-\u2EFF-\u2F00-\u2FDF-\u3000-\u303F-\u31C0-\u31EF-\u3200-\u32FF-\u3300-\u33FF-\u3400-\u3FFF-\u4000-\u4DBF-\u4E00-\u4FFF]*$/.test(
            value
          );

    if (runFlagCheck) {
      return "Alert name cannot have special characters";
    } else if (state.name.value.trim() === "") {
      return "Name must have at least 1 character";
    } else if (state.name.value.length > 100) {
      return "Name cannot be more than 100 character";
    }
  };

  //validation in dimension alert on change dimension input feild is empty or not
  const validConditionValue =
    !state.alertCriteriaDimension.value.conditionValue.value;
  // console.error("validConditionValue", validConditionValue);
  //In dimension alert to select the options (change/add/delete) valid metric and dimension flag
  const optionOneSelected =
    state.alertCriteriaDimension.value.dimensionRow.value === "change";
  const optionTwoSelected =
    state.alertCriteriaDimension.value.dimensionRow.value === "add";
  const optionThreeSelected =
    state.alertCriteriaDimension.value.dimensionRow.value === "delete";

  // Validation for in dimesion alert any of the dimension row is selected or not
  const validDimension = optionOneSelected
    ? state.alertCriteriaDimension.value.dimensionChange.value ===
      "selectDimension"
    : optionTwoSelected
    ? state.alertCriteriaDimension.value.dimensionAdd.value ===
      "selectDimension"
    : optionThreeSelected
    ? state.alertCriteriaDimension.value.dimensionDelete.value ===
      "selectDimension"
    : console.log("Wrong Input");

  //validation in dimension alert on change dimension metric feild is empty or not
  const validMetric =
    state.alertCriteriaDimension.value.metric.value === "selectMetric";

  //Email validation function
  const validateEmail = (text) => {
    const recipientsList = state.emailRecipients.value.map((row) => row.name);
    if (!validateEmailAddress(text))
      return { isValid: false, message: "Invalid email address" };
    if (recipientsList.includes(text))
      return { isValid: false, message: "Duplicate email address" };
    return { isValid: true, message: "" };
  };
  // Run on clicking add filters
  // Global Filters Event Handlers

  const handleDimensionFiltersChange = (value) => {
    var payload = { key: "dimensionFilters", value };
    const action = updateAlertForm(payload);
    dispatch(action);
  };

  const handleAddFilters = () => {
    const handleApplyFilters = (payload, filterType) => {
      switch (filterType) {
        case "dimensions":
          var action = replaceAllDimensionFilters(payload);
          var newDimensionFilters = dimensionFiltersReducer(
            payload.newDimensionFilters,
            action
          );
          handleDimensionFiltersChange(newDimensionFilters);
          break;
        // case "metrics":
        //   var action = replaceAllMetricFilters(payload);
        //   dispatchMetricFilters(action);
        //   break;
        // case "time":
        //   onTimeFiltersChange(payload);
        //   break;
      }
    };

    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Open Filters",
      label: "Sidenav",
    });
    const commonGlobalFilterProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: false,
      showMetricFilters: false,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      timeFilters: user.timeFilters, //for initializing filters
      dimensionFilters: state.dimensionFilters.value, //for initializing filters
      metricFilters: [], //for initializing filters
      settings: dimensionFiltersSettings,
      filtersDimData: allData.plotlyDimensions,
      filtersMetricsData: allData.plotlyMetrics,
      selections: {},
    };
    //Open Global Filters
    const newGlobalFiltersProps = {
      ...commonGlobalFilterProps,
      activeDimensionFilter: {},
      handleApplyFilters, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };

    const action = updateUserScreen("globalFilters", newGlobalFiltersProps);
    ReduxDispatcher(action);
  };

  const handleDimFilterClick = (filterRow) => {
    //Open Global Filters
    const newGlobalFiltersProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: false,
      showMetricFilters: false,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      activeDimensionFilter: filterRow.metadata,
      timeFilters: user.timeFilters, //for initializing filters
      dimensionFilters: state.dimensionFilters.value, //for initializing filters
      metricFilters: [], //for initializing filters
      handleApplyFilters: (payload, filterType) => {
        switch (filterType) {
          case "dimensions":
            const actionPayload = {
              key: "dimensionFilters",
              value: payload.newDimensionFilters,
            };
            const action = updateAlertForm(actionPayload);
            dispatch(action);
            break;
          case "metrics":
            break;
          case "time":
            break;
        }
      }, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };
    ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
  };

  const handleDimFilterDelete = (filterRow) => {
    const newDimFilters = state.dimensionFilters.value.filter(
      (filter) => filter.id !== filterRow.id
    );
    const actionPayload = {
      key: "dimensionFilters",
      value: newDimFilters,
    };
    const action = updateAlertForm(actionPayload);
    dispatch(action);
  };

  const handleClearAllFilters = () => {
    const actionPayload = {
      key: "dimensionFilters",
      value: [],
    };
    const action = updateAlertForm(actionPayload);
    dispatch(action);
  };

  // Cancel event listener
  // Run on clicking the cancel button form alert manager form
  const handleCancel = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "AlertManager",
      action: "AlertCURD",
      label: "cancel",
    });

    //Check if there's a change in alert form data and state
    //If yes, show the dialog, else close the active report
    const flag = !isEqual(state, alertFormDataOriginal.current);

    if (flag) {
      const message = {
        title: "Discard Changes?",
        subtitle: "This action cannot be undone",
      };
      const handleNo = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
          })
        );
      };
      const handleYes = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
            message: { title: "", subtitle: "" },
          })
        );

        //Close the active alert
        setShowAlertManager(false);
      };
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: true,
          message,
          handleYes,
          handleNo,
        })
      );
    } else {
      //Close the active alert
      setShowAlertManager(false);
      // resetting the selected alert id
      setSelectedAlertId(null);
    }
  };

  // Update event listener
  // Run on clicking  update button
  const handleUpdate = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "AlertManager",
      action: "AlertCURD",
      label: "update",
    });

    const isAlertFormDataValidFlag = validateAlertFormData(state);
    // if validate is true then disable the save button
    if (isAlertFormDataValidFlag) {
      setShowError({ ...showError, flag: true });
    } else {
      //Update snackbar
      let snackbarPayload = {
        ...user.screen.snackbar,
        open: true,
        message: "Requesting update Alert",
      };
      ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

      const payload = wrapper(
        { ...state },
        user?.reqMetadata?.email,
        allData,
        globalFilters
      );
      const source = axios.CancelToken.source();
      const fetchProps = {
        cancelToken: source.token,
        payload,
      };
      const updateAlertPromise = updateAlert(fetchProps);
      updateAlertPromise
        .then(() => {
          //Update snackbar
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: "Alert updated sucessfully",
          };
          ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
          setShowAlertManager(false);
          // resetting the selected alert id
          setSelectedAlertId(null);
          setReloadData(!reloadData);
        })
        .catch((json) => {
          console.log("API FAILED", json);

          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: json.error || "Requesting Updating Alert",
          };
          ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
        });
    }
  };
  // Delete event listener
  // Run on clicking  delete button
  const handleDelete = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "AlertManager",
      action: "AlertCURD",
      label: "delete",
    });

    const message = {
      title: `Are you sure you want to delete?`,
      subtitle: "",
    };

    const handleNo = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
        })
      );
    };

    const handleYes = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
          message: { title: "", subtitle: "" },
        })
      );

      //Update snackbar
      let snackbarPayload = {
        ...user.screen.snackbar,
        open: true,
        message: "Requesting deleting Alert",
      };
      ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

      // Alert id to be deleted
      const alert_id = state.id.value;
      const fetchProps = {
        alert_id,
      };

      const deleteSavedAlertPromise = deleteAlert(fetchProps);
      deleteSavedAlertPromise
        .then(() => {
          //Update snackbar
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: "Alert deleted sucessfully",
          };
          ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
          setShowAlertManager(false);
          setReloadData(!reloadData);
        })
        .catch((json) => {
          console.log("API FAILED", json);

          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: json.error || "Requesting deleting Alert",
          };
          ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
        });
    };
    ReduxDispatcher(
      updateDialogInfo({
        ...user.dialogInfo,
        open: true,
        message,
        handleYes,
        handleNo,
      })
    );
  };

  // Save event listener
  // Run on clicking  save button
  const handleSave = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "AlertManager",
      action: "AlertCURD",
      label: "save",
    });

    const isAlertFormDataValidFlag = validateAlertFormData(state);
    // if validate is true then disable the save button

    if (isAlertFormDataValidFlag) {
      setShowError({ ...showError, flag: true });
    } else {
      //Update snackbar
      let snackbarPayload = {
        ...user.screen.snackbar,
        open: true,
        message: "Requesting to saved alert...",
      };
      ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

      // if isAlertFormDataValidFlag is false then the form is correctly filled and call the api
      //! Add patterns
      //Make fetch call using axios
      const payload = wrapper(
        { ...state },
        user?.reqMetadata?.email,
        allData,
        globalFilters
      );
      const source = axios.CancelToken.source();
      const fetchProps = {
        cancelToken: source.token,
        payload,
      };
      const createAlertPromise = createAlert(fetchProps);
      createAlertPromise
        .then((responseData) => {
          // TODO: Change when backend DS is corrected
          if (responseData.status.statusCode === "200") {
            //Update snackbar
            let snackbarPayload = {
              ...user.screen.snackbar,
              open: true,
              message: "Alert has been saved sucessfully",
            };
            ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
            setShowAlertManager(false);
            setReloadData(!reloadData);
          } else {
            console.groupCollapsed("API ERROR");
            console.groupEnd();

            let snackbarPayload = {
              ...user.screen.snackbar,
              open: true,
              message: responseData.status.statusMessage,
            };
            ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
          }
        })
        .catch((json) => {
          console.log("handleSave", json);
          if (json.error !== config.hardCoded.queryCancelled) {
            console.groupCollapsed("API ERROR");
            console.error("JSON ->", json);
            console.groupEnd();

            let snackbarPayload = {
              ...user.screen.snackbar,
              open: true,
              message: json.error || config.hardCoded.uiErrorMessage,
            };
            ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
          }
        });
    }
  };

  const handleAlertEnds = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "AlertManager",
      action: "AlertEndsIn",
      label: value,
    });

    // reducer dispatch action with value
    const payload = { key: "duration", value };
    const action = updateAlertForm(payload);
    dispatch(action);
  };

  const handleAlertType = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "AlertManager",
      action: "AlertType",
      label: value,
    });
    // reducer dispatch action with value
    const payload = { key: "alertType", value };
    const action = updateAlertForm(payload);
    dispatch(action);
  };

  const handlelanguageChange = (value) => {
    // reducer dispatch action with value
    const payload = { key: "userPreferredLanguage", value };
    const action = updateAlertForm(payload);
    dispatch(action);
  };

  const handleAlertStatus = (e, value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "AlertManager",
      action: "AlertStatus",
      label: "DetailPage",
    });

    // console.log("value of sigview switch", value);
    const payload = { key: "active", value };
    const action = updateAlertForm(payload);
    dispatch(action);
  };

  const handleFrequencyBasis = (newValue) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "AlertManager",
      action: "AlertFrequency",
      label: newValue,
    });

    // reducer dispatch action with value
    const payload = {
      key: "basis",
      value: newValue,
    };
    const action = updateAlertForm(payload);
    dispatch(action);
  };

  const handleAlertNavChange = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "AlertManager",
      action: "AlertNav",
      label: value,
    });

    setActiveAlertNav(value);
  };

  // Defining required variables for the AlertCriteria row props
  const AlertCriteriaRowProps = {
    changeCriteria: alertFormData.alertCriteriaMetric.data.changeCriteria,
    allMetrics: getAllPlotlyMetrics(allData),
    logicalConditionData:
      alertFormData.alertCriteriaMetric.data.logicalCondition.data,
    condition: alertFormData.alertCriteriaMetric.data.condition,
    state,
    showError,
  };

  // conditional rendering variable for Alert type
  const activeMetric = state.alertType.value;
  const activeDimension = state.alertType.value;

  // console.log(
  //   "AlertManagerForm",
  //   state,
  //   state.alertCriteriaDimension.value.conditionValue.validConditionValue
  // );
  // conditional rendering for alert dimension row
  // Defining required components
  const AlertFrequencyRow = () => {
    return (
      <>
        <AlertManagerFormRowItem
          title={t("Frequency:")}
          style={{
            inputContainer: true,
            borderLess: true,
            commonSet: true,
          }}
        >
          <SigviewSingleSelect
            value={state.timeZone.value}
            data={alertFormData.timeZone.data}
            onChange={(newValue) => {
              // reducer dispatch action with value
              const payload = {
                key: "timeZone",
                value: newValue,
              };
              const action = updateAlertForm(payload);
              dispatch(action);
            }}
            margin={{ right: "5px" }}
            minWidth="110px"
            height="28px"
            fontSize="11px"
            border="1px solid #edf1ff"
            backgroundColor="#fff"
          />
        </AlertManagerFormRowItem>

        <AlertManagerFormRowItem
          title={t("On")}
          style={{ inputContainer: true, borderLess: true, widthSet: true }}
        >
          <SigviewSingleSelect
            value={state.basis.value}
            data={
              state.alertCriteriaDimension.value.dimensionRow.value !== "change"
                ? alertFormData.basis.data.filter((el) => el.id === "daily")
                : alertFormData.basis.data
            }
            onChange={(newValue) => handleFrequencyBasis(newValue)}
            margin={{ right: "5px", left: "5px" }}
            minWidth="110px"
            height="28px"
            fontSize="11px"
            border="1px solid #edf1ff"
            backgroundColor="#fff"
          />

          <Box
            css={{
              height: "25px",
              display: "flex",
              alignItems: "center",
              //marginTop: "11px",
            }}
          >
            <SigviewTypography
              style={{
                height: "max-content",
                padding: "4px 0px 0px 0px",
              }}
              variant="pSmallMedium"
            >
              Basis
            </SigviewTypography>
          </Box>
        </AlertManagerFormRowItem>
        {/* <AlertManagerFormRowItem
          style={{ inputContainer: true, borderLess: true }}
        >
          <Box
            css={{
              height: "25px",
              display: "flex",
              alignItems: "center",
              marginTop: "11px",
            }}
          >
            <SigviewSingleSelect
              value={state.basis.value}
              data={
                state.alertCriteriaDimension.value.dimensionRow.value !==
                "change"
                  ? alertFormData.basis.data.filter((el) => el.id === "daily")
                  : alertFormData.basis.data
              }
              onChange={(newValue) => handleFrequencyBasis(newValue)}
              margin={{ right: "5px", left: "5px" }}
              minWidth="140px"
              height="28px"
              fontSize="11px"
              border="1px solid #edf1ff"
              backgroundColor="#fff"
              customClassName={`Alert${state.basis.value}-GA`}
            />
          </Box>
        </AlertManagerFormRowItem>

        <AlertManagerFormRowItem style={{ borderLess: true }}>
          <Box
            css={{
              height: "25px",
              display: "flex",
              alignItems: "center",
              marginTop: "11px",
            }}
          >
            <SigviewTypography
              style={{
                height: "max-content",
                padding: "2px",
              }}
              variant="pSmallMedium"
            >
              Basis
            </SigviewTypography>
          </Box>
        </AlertManagerFormRowItem> */}

        {(state.alertType.value === "dimension" &&
          state.alertCriteriaDimension.value.changeCriteria.value ===
            "percentageChange" && (
            <>
              <AlertManagerFormRowItem
                title={t("Compared with")}
                style={{
                  inputContainer: true,
                  borderLess: true,
                  comparedWith: true,
                }}
              >
                {(state.basis.value === "daily" && (
                  <Box
                    css={{
                      height: "25px",
                      display: "flex",
                      alignItems: "center",
                      //marginTop: "11px",
                    }}
                  >
                    <SigviewSingleSelect
                      value={state.comparedWith.value}
                      data={alertFormData.comparedWith.onDailyBasis}
                      onChange={(newValue) => {
                        // reducer dispatch action with value
                        const payload = {
                          key: "comparedWith",
                          value: newValue,
                        };
                        const action = updateAlertForm(payload);
                        dispatch(action);
                      }}
                      margin={{ left: "5px" }}
                      minWidth="110px"
                      height="28px"
                      fontSize="11px"
                      border="1px solid #edf1ff"
                      backgroundColor="#fff"
                    />
                  </Box>
                )) ||
                  (state.basis.value === "hourly" && (
                    <Box
                      css={{
                        height: "25px",
                        display: "flex",
                        alignItems: "center",
                        //marginTop: "11px",
                      }}
                    >
                      <SigviewSingleSelect
                        value={state.comparedWith.value}
                        data={alertFormData.comparedWith.onHourlyBasis}
                        onChange={(newValue) => {
                          // reducer dispatch action with value
                          const payload = {
                            key: "comparedWith",
                            value: newValue,
                          };
                          const action = updateAlertForm(payload);
                          dispatch(action);
                        }}
                        margin={{ left: "5px" }}
                        minWidth="110px"
                        height="28px"
                        fontSize="11px"
                        border="1px solid #edf1ff"
                        backgroundColor="#fff"
                      />
                    </Box>
                  ))}
              </AlertManagerFormRowItem>
            </>
          )) ||
          (state.alertType.value === "dimension" &&
            state.alertCriteriaDimension.value.dimensionRow.value === "add" && (
              <AlertManagerFormRowItem
                title={t("Compared with")}
                style={{
                  inputContainer: true,
                  borderLess: true,
                  comparedWith: true,
                }}
              >
                <Box
                  css={{
                    height: "25px",
                    display: "flex",
                    alignItems: "center",
                    //marginTop: "11px",
                  }}
                >
                  <SigviewSingleSelect
                    value={state.comparedWith.value}
                    data={alertFormData.comparedWith.onDailyBasis}
                    onChange={(newValue) => {
                      // reducer dispatch action with value
                      const payload = {
                        key: "comparedWith",
                        value: newValue,
                      };
                      const action = updateAlertForm(payload);
                      dispatch(action);
                    }}
                    margin={{ left: "5px" }}
                    minWidth="110px"
                    height="28px"
                    fontSize="11px"
                    border="1px solid #edf1ff"
                    backgroundColor="#fff"
                  />
                </Box>
              </AlertManagerFormRowItem>
            )) ||
          (state.alertType.value === "dimension" &&
            state.alertCriteriaDimension.value.dimensionRow.value ===
              "delete" && (
              <AlertManagerFormRowItem
                title={t("Compared with")}
                style={{
                  inputContainer: true,
                  borderLess: true,
                  comparedWith: true,
                }}
              >
                <Box
                  css={{
                    height: "25px",
                    display: "flex",
                    alignItems: "center",
                    //marginTop: "11px",
                  }}
                >
                  <SigviewSingleSelect
                    value={state.comparedWith.value}
                    data={alertFormData.comparedWith.onDailyBasis}
                    onChange={(newValue) => {
                      // reducer dispatch action with value
                      const payload = {
                        key: "comparedWith",
                        value: newValue,
                      };
                      const action = updateAlertForm(payload);
                      dispatch(action);
                    }}
                    margin={{ left: "5px" }}
                    minWidth="110px"
                    height="28px"
                    fontSize="11px"
                    border="1px solid #edf1ff"
                    backgroundColor="#fff"
                  />
                </Box>
              </AlertManagerFormRowItem>
            ))}

        {/* {state.alertType.value === "dimension" &&
          state.alertCriteriaDimension.value.dimensionRow === "add" && (
            <AlertManagerFormRowItem
              title={t("Compared with")}
              style={{
                inputContainer: true,
                borderLess: true,
                comparedWith: true,
              }}
            ></AlertManagerFormRowItem>
          )} */}
        {state.alertType.value === "metric" &&
          state.alertCriteriaMetric.value.find(
            (el) => el.changeCriteria?.value === "percentageChange"
          ) && (
            <>
              <AlertManagerFormRowItem
                title={t("Compared with:")}
                style={{
                  inputContainer: true,
                  borderLess: true,
                  comparedWith: true,
                }}
              >
                {(state.basis.value === "daily" && (
                  <Box
                    css={{
                      height: "25px",
                      display: "flex",
                      alignItems: "center",
                      // marginTop: "11px",
                    }}
                  >
                    <SigviewSingleSelect
                      value={state.comparedWith.value}
                      data={alertFormData.comparedWith.onDailyBasis}
                      onChange={(newValue) => {
                        // reducer dispatch action with value
                        const payload = {
                          key: "comparedWith",
                          value: newValue,
                        };
                        const action = updateAlertForm(payload);
                        dispatch(action);
                      }}
                      margin={{ left: "5px" }}
                      minWidth="110px"
                      height="28px"
                      fontSize="11px"
                      border="1px solid #edf1ff"
                      backgroundColor="#fff"
                    />
                  </Box>
                )) ||
                  (state.basis.value === "hourly" && (
                    <Box
                      css={{
                        height: "25px",
                        display: "flex",
                        alignItems: "center",
                        // marginTop: "11px",
                      }}
                    >
                      <SigviewSingleSelect
                        value={state.comparedWith.value}
                        data={alertFormData.comparedWith.onHourlyBasis}
                        onChange={(newValue) => {
                          // reducer dispatch action with value
                          const payload = {
                            key: "comparedWith",
                            value: newValue,
                          };
                          const action = updateAlertForm(payload);
                          dispatch(action);
                        }}
                        margin={{ left: "5px" }}
                        minWidth="110px"
                        height="28px"
                        fontSize="11px"
                        border="1px solid #edf1ff"
                        backgroundColor="#fff"
                      />
                    </Box>
                  ))}
              </AlertManagerFormRowItem>
            </>
          )}
      </>
    );
  };

  //* Defining required variables
  const navDataFinal = [
    { id: "details", name: "Details", disabled: false },
    { id: "summary", name: "Summary", disabled: validate },
  ];
  // save button disable logic
  const isFormInvalidFlag = validateAlertFormData(state); //not state
  const isShowErrorVisible = showError.flag; //state ->

  const isSaveDisabled = !isFormInvalidFlag
    ? false
    : isShowErrorVisible
    ? true
    : false; //not state
  const updateAlertFlag = isEqual(state, alertFormDataOriginal.current);

  // update button disable logic - commenting because of bug need to be always enabled
  // const isUpdateDisabled = updateAlertFlag
  //   ? true
  //   : isFormInvalidFlag
  //   ? true
  //   : false;

  const isUpdateDisabled = false;

  const atleastOneFilterSelected = state.dimensionFilters.value.length > 0;

  // Defining required variables for the alert manager summary props
  const alertManagerSummaryProps = {
    state,
    allData,
  };
  return (
    <>
      <div className="form">
        <div className="content-nav-bar-container" id="reportFormNavBar">
          <SigviewNav
            data={navDataFinal}
            value={activeAlertNav}
            onChange={(e, value) => {
              handleAlertNavChange(value);
            }}
            style={{
              fontSize: "12px",
              textTransform: "uppercase",
              padding: "0px 11px",
            }}
            customClassName={`Alert`}
          />
        </div>
        <div className="content-main">
          {activeAlertNav === "details" && (
            <>
              {/* Name row */}
              <AlertManagerFormRow>
                <AlertManagerFormRowItem
                  title={t("Name:")}
                  style={{
                    full: true,
                    inputContainer: true,
                    borderLessName: true,
                    commonSet: true,
                  }}
                >
                  <SigviewTextField
                    onChange={(value) => {
                      if (value !== " ") {
                        // reducer dispatch action with value
                        const payload = { key: "name", value };
                        const action = updateAlertForm(payload);
                        dispatch(action);
                      }
                    }}
                    border="1px solid #edf1ff"
                    height="28px"
                    backgroundColor="#fff"
                    value={state.name.value}
                    fontSize="12px"
                    maxWidth="92%"
                    width="92%"
                    helperText={validateNameMessage(state.name.value)}
                    error={validateName}
                  />
                </AlertManagerFormRowItem>
              </AlertManagerFormRow>
              {/* Alert type row */}
              <AlertManagerFormRow style={{ frequencyItemContainer: true }}>
                <AlertManagerFormRowItem
                  title={t("Alert Type:")}
                  style={{
                    inputContainer: true,
                    borderLess: true,
                    commonSet: true,
                  }}
                >
                  <SigviewSingleSelect
                    value={state.alertType.value}
                    data={alertFormData.alertType.data}
                    margin={{ right: "5px" }}
                    minWidth="110px"
                    height="28px"
                    fontSize="11px"
                    border="1px solid #edf1ff"
                    backgroundColor="#fff"
                    onChange={(value) => handleAlertType(value)}
                    customClassName={`Alert${state.alertType.value}-GA`}
                  />
                </AlertManagerFormRowItem>
                <AlertManagerFormRowItem
                  title={t("Alert Ends in:")}
                  style={{ alertEndsWidth: true, radioBtnGroup: true }}
                >
                  <SigviewRadioGroup
                    data={alertFormData.duration.data}
                    onChange={(value) => handleAlertEnds(value)}
                    value={state.duration.value}
                    fontSize="12px"
                    padding="5px 0px 0px 0px !important"
                  />
                </AlertManagerFormRowItem>

                <AlertManagerFormRowItem
                  title={t("Alert Status:")}
                  style={{
                    alertStatus: true,
                    radioBtnGroup: true,
                    commonSet: true,
                  }}
                >
                  {/*  TODO: */}
                  <SigviewSwitch
                    name={"Alert Status:"}
                    customClassName="AlertON-OFF-GA"
                    checked={state.active.value}
                    margin={{ left: "5px" }}
                    fontSize="12px"
                    onChange={(e, value = !state.active.value) => {
                      handleAlertStatus(e, value);
                    }}
                  />
                </AlertManagerFormRowItem>
              </AlertManagerFormRow>
              {/* Alert Me Metric Row */}
              {activeMetric === "metric" && (
                <AlertManagerFormRow>
                  <AlertManagerFormRowItem title={t("Alert Me:")}>
                    <AlertCriteriaComponent
                      {...AlertCriteriaRowProps}
                      onChange={(value) => {
                        // reducer dispatch action with value
                        const payload = {
                          key: "alertCriteriaMetric",
                          value,
                        };
                        const action = updateAlertForm(payload);

                        dispatch(action);
                      }}
                    />
                  </AlertManagerFormRowItem>
                </AlertManagerFormRow>
              )}
              {/* Alert Me Dimension Row */}
              {activeDimension === "dimension" && (
                <AlertManagerFormRow>
                  <AlertDimension
                    dispatch={dispatch}
                    state={state}
                    allData={allData}
                    validMetric={validMetric}
                    showError={showError}
                    validDimension={validDimension}
                    validConditionValue={validConditionValue}
                    // validConditionValue={validConditionValue}
                  />
                </AlertManagerFormRow>
              )}
              {/* Frequency row */}
              <AlertManagerFormRow
                style={{ frequencyItemContainer: true, commonSet: true }}
              >
                <AlertFrequencyRow />
              </AlertManagerFormRow>
              {/* Filter row */}
              <AlertManagerFormRow>
                <AlertManagerFormRowItem title={t("Filters:")}>
                  {/* <SigviewButton
                    variant="contained"
                    title="Add Filters"
                    onClick={handleAddFilters}
                  /> */}
                  {atleastOneFilterSelected && (
                    <div className="input-item-container transparent filterBox">
                      <div
                        className={`selected-filter-item-container child-container selectedFilter AlertOpenFilterSelectedDimension-GA`}
                        id="reportManagerInstance"
                        title="Add filters"
                      >
                        <i
                          className="material-icons add-filters-icon selected-filter-item-title child-element add-Alertfilters-icon"
                          onClick={handleAddFilters}
                        >
                          add
                        </i>
                      </div>
                      <SelectedFilters
                        onClick={(row, type) => {
                          switch (type) {
                            case "dimension":
                              handleDimFilterClick(row);
                              break;
                            case "metric":
                              break;
                          }
                        }}
                        onDelete={(row, type) => {
                          switch (type) {
                            case "dimension":
                              handleDimFilterDelete(row);
                              break;
                            case "metric":
                              break;
                          }
                        }}
                        showClearAllButton={true}
                        onClearAll={handleClearAllFilters}
                        // rowHeader="Applied Filters:"
                        selectedDimensionFilters={state.dimensionFilters.value}
                        selectedMetricFilters={[]}
                        style={{ maxHeight: "62px", margin: "3px" }}
                        divId="filtersMenuBarRow"
                        allData={allData}
                        childrenPlacement="start"
                      ></SelectedFilters>
                    </div>
                  )}
                  {!atleastOneFilterSelected && (
                    <div
                      className={`input-item-container  ${rootPage}AlertsAddFilters-GA`}
                    >
                      <p
                        className={`info-title add-filters-text  ${rootPage}AlertsAddFilters-GA`}
                        onClick={handleAddFilters}
                      >
                        {t("Click to add filters")}
                      </p>
                    </div>
                  )}
                </AlertManagerFormRowItem>
              </AlertManagerFormRow>

              {/* User LanguagePreference row */}
              <AlertManagerFormRow>
                {sigviewUserType === "nonSigview" && (
                  <AlertManagerFormRowItem title={t("Preferred Language:")}>
                    <SigviewSingleSelect
                      value={state.userPreferredLanguage.value}
                      data={
                        user.reqMetadata.organization === "OpenX"
                          ? alertFormData.languageOptionsArr.NonSigview
                          : alertFormData.languageOptionsArr.Sigview
                      }
                      onChange={(value) => {
                        handlelanguageChange(value);
                      }}
                      minWidth="80px"
                      //minWidth="140px"
                      height="28px"
                      fontSize="11px"
                      border="1px solid #edf1ff"
                      backgroundColor="#fff"
                      disabled={false}
                    />
                  </AlertManagerFormRowItem>
                )}
              </AlertManagerFormRow>
              {/* Email rec row */}
              <AlertManagerFormRow>
                <AlertManagerFormRowItem title={t("Email Recipients:")}>
                  <SigviewInputTags
                    inputValidationFunction={validateEmail}
                    data={state.emailRecipients.value}
                    border="none"
                    onChange={(value) => {
                      // reducer dispatch action with value
                      const payload = { key: "emailRecipients", value };
                      const action = updateAlertForm(payload);
                      dispatch(action);
                    }}
                  />
                </AlertManagerFormRowItem>
              </AlertManagerFormRow>
            </>
          )}
          {activeAlertNav === "summary" && (
            <AlertManagerSummary {...alertManagerSummaryProps} />
          )}
        </div>
        <div className="content-menu-bar" id="reportFormMenuBar">
          <div className="left-panel-footer"></div>
          <div className="right-panel-footer">
            <SigviewButton
              variant="outlined"
              onClick={handleCancel}
              title="Cancel"
              style={{ margin: { right: "5px" } }}
              customClassName="AlertCancel-GA"
            />
            {state.crudOperation.value === "update" && (
              <SigviewButton
                variant="contained"
                onClick={handleDelete}
                title="Delete"
                style={{ margin: { right: "5px" } }}
                customClassName="AlertDelete-GA"
              />
            )}
            {state.crudOperation.value === "update" && (
              <SigviewButton
                variant="contained"
                onClick={handleUpdate}
                title="Update"
                style={{ margin: { right: "5px" } }}
                disabled={isUpdateDisabled}
                customClassName="AlertUpdate-GA"
              />
            )}
            {state.crudOperation.value === "create" && (
              <SigviewButton
                variant="contained"
                // onClick={handleAddSaveAlert}
                onClick={handleSave}
                title="Save"
                style={{ margin: { right: "5px" } }}
                disabled={isSaveDisabled}
                customClassName="AlertSave-GA"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AlertManagerForm;

// const isFormValid = false //not state
// const isShowErrorVisible = false //state ->
// const isSaveDisabled = isFormValid ? false : isShowErrorVisible ? true : false //not state
