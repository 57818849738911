import C from "../constants";

import globalFiltersTypeUiCompTypeMapping from "../../../assets/data/globalFiltersTypeUiCompTypeMapping.json";

export default function advancedFilters(state = {}, payload) {
  let {
    type,
    advancedFilterId,
    advancedFilterType,
    advancedFilterValue,
    advancedFilterExtraData,
    advancedFiltersUiCompType,
    advancedFiltersTypeError,
    advancedFiltersValueError,
    newAdvancedFilters,
  } = payload;
  let newState, newAdvancedFilterObject;

  switch (type) {
    case C.ADD_ADVANCED_FILTER:
      newAdvancedFilterObject = {
        id: advancedFilterId,
        type: advancedFilterType,
        uiCompType: advancedFiltersUiCompType,
        value: advancedFilterValue,
        extraData: {},
        typeError: advancedFiltersTypeError,
        valueError: advancedFiltersValueError,
      };
      newState = [...state, newAdvancedFilterObject];
      return newState;

    case C.REMOVE_ADVANCED_FILTER:
      newState = state.filter(
        (adFilterRow) => adFilterRow.id !== advancedFilterId
      );
      return newState;

    case C.CHANGE_ADVANCED_FILTER_TYPE:
      newState = state.map((adFilterRow) =>
        adFilterRow.id !== advancedFilterId
          ? adFilterRow
          : {
              ...adFilterRow,
              type: advancedFilterType,
              uiCompType:
                globalFiltersTypeUiCompTypeMapping[advancedFilterType],
              value: advancedFilterValue,
              extraData: advancedFilterExtraData,
            }
      );
      return newState;

    case C.CHANGE_ADVANCED_FILTER_VALUE:
      newState = state.map((adFilterRow) =>
        adFilterRow.id !== advancedFilterId
          ? adFilterRow
          : {
              ...adFilterRow,
              value: advancedFilterValue,
            }
      );
      return newState;

    case C.CHANGE_ADVANCED_FILTER_EXTRA_DATA:
      newState = state.map((adFilterRow) =>
        adFilterRow.id !== advancedFilterId
          ? adFilterRow
          : {
              ...adFilterRow,
              extraData: advancedFilterExtraData,
            }
      );
      return newState;

    case C.REPLACE_ALL_ADVANCED_FILTERS:
      return newAdvancedFilters;

    default:
      return state;
  }
}
