// * Import required libraries
import { useReducer, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useState } from "react";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * import lib component
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// * Import custom components
import SigviewTextField from "../../components/Common/SigviewTextField";
import SigviewButton from "../../components/Common/SigviewButton";
import SigviewTypography from "../../components/Common/SigviewTypography";
import Loader from "../../components/Loader/Loader";
import LayoutBottom from "../../layouts/LayoutBottom/LayoutBottom";

// * Import redux utils
import { masterTrackGaEvent } from "../../services/ga";
import useReducerLogger from "../../utils/useReducerLogger";
import {
  updateSignInForm,
  updateSignInFormWholeKey,
  updateAuthLogin,
} from "../../redux/actions";

import { isSelectionsInvalid } from "../../utils/signInUtils";
import { config } from "../../config/config";

//  * Import APIs
import { sigviewSignIn, sendResetPasswordLink } from "../../services/api";

// * Import styles
import signInImage from "../../../assets/images/loginFlowImg.svg";
import googleLogo from "../../../assets/images/GoogleLogo.svg";
import GoogleSignInButton from "../../components/Common/GoogleSignInButton";

// * Define style functions
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
    },
    leftPanel: {
      width: "50%",
      height: "100%",
      display: "inline-block",
    },
    rightPanel: {
      width: "50%",
      height: "100%",
      display: "inline-block",
      background: "#045ad9",
      borderRadius: "50px 0px 0px 50px",
    },
    signInSignUpImageContainer: {
      width: "100%",
      height: "calc(100vh - 20px)",
      background: "url(" + signInImage + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center",
      borderRadius: "50px 0px 0px 50px",
    },
    signInImageContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    sigmoidLogoContainer: {
      height: "50px",
      width: "100px",
      padding: "50px",
      cursor: "pointer",
    },
    mainSignInContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100vh - 50px)",
    },
    mainSignInFormRowBottom: {
      padding: "10px 10px 15px 10px",
      width: "350px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    mainSignInFormRow: {
      padding: "10px",
      width: "350px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    mainSignInFormRowLeft: {
      padding: "10px",
      width: "350px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    mainSignInFormRowLeftBottom: {
      padding: "10px 10px 15px 10px",
      width: "350px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    horizontalLineWithWord: {
      width: "350px",
      textAlign: "center",
      borderBottom: "1px solid #ddd",
      lineHeight: "0.1em",
      margin: "30px 0 20px",
    },
    horizontalLineWithWordInner: {
      background: "#fff",
      padding: "0 10px",
      fontSize: "11px",
      color: "#46596A",
    },
    link: {
      color: themeColors["primaryColor"],
      padding: "0 0px",
      fontSize: "11px",
      background: "#fff",
      cursor: "pointer",
      textDecoration: "none",
    },
  });
  return useStyles;
};

const initialSelections = {
  email: {
    message: "",
    value: "",
    status: "",
  },

  password: {
    message: "",
    value: "",
    status: "",
  },

  tab: {
    message: "",
    value: "signIn",
    status: "",
  },

  signInStatus: {
    message: "",
    value: "initial", // loading/error/success/initial
    status: "",
  },

  resetStatus: {
    message: "",
    value: "initial", // loading/error/success/initial
    status: "",
  },

  postResetStatus: {
    message: "",
    value: "initial", // loading/error/success/initial
    status: "",
  },

  showSignInUiError: {
    message: "",
    value: false,
    status: "",
  },
};

// * Define required utility components
// ! It may be temptive to define utility components inside the main component because it will reduce sending and destructuring props
// ! But it should NOT be defined inside the main component
// * BECAUSE everytime state changes those functional components get redefined and are unmounted/mounted on every state change
// * This creates a bug where the input loses focus on each key stroke as the component has mounted and unmounted

const SignInImage = (props = {}) => {
  const { classes } = props;
  return (
    <Box className={classes.signInSignUpImageContainer}>
      {/* <img src={signInImage} width="80%" height="80%" /> */}
    </Box>
  );
};
const SigmoidLogo = (props = {}) => {
  const { eventHandlers, classes } = props;
  const { handleBackToHome } = eventHandlers;
  return (
    // <Box className={classes.sigmoidLogoContainer} onClick={handleBackToHome}>
    <Box>
      <Box className={classes.mainSignInFormRowLeft} onClick={handleBackToHome}>
        <img
          src="https://logo.sigmoid.io/sigmoid.png"
          alt="Sigmoid logo"
          height="auto"
          width="70px"
        />
      </Box>
    </Box>
  );
};
const PostReset = (props = {}) => {
  const { signInFormStatus, classes, eventHandlers } = props;
  const { handleBackToHome } = eventHandlers;
  return (
    <Box className={classes.mainSignInContainer}>
      <SigmoidLogo eventHandlers={eventHandlers} classes={classes} />
      <Box className={classes.mainSignInFormRowLeft}>
        <SigviewTypography
          variant="pLargest"
          style={{ padding: "10px 0px 20px 0px" }}
        >
          Mail sent
        </SigviewTypography>
        {/* <Box className={classes.mainSignInFormRow}> */}
        <SigviewTypography
          variant="pMedium"
          style={{
            lineHeight: "18px",
            textAlign: "left",
            width: "350px",
            height: "auto",
            fontSize: "12px",
            padding: "0px 0px 10px 0px",
          }}
        >
          If email {signInFormStatus.email.value} is associated with Sigview,
          you should receive an email containing instructions on how to create a
          new password
        </SigviewTypography>
        {/* </Box> */}
      </Box>

      <Box className={classes.mainSignInFormRow}>
        <SigviewButton
          variant="containedLighterNewBlue"
          style={{
            btnWidth: "350px",
            btnHeight: "40px",
            fontSize: "12px !important",
            bgColor: "#045ad9",
            color: "#fff",
            bgHoverColor: "#fff",
            backgroundColor: "#045ad9",
          }}
          onClick={handleBackToHome}
          title="Back to home"
        />
      </Box>
    </Box>
  );
};
const SignIn = (props = {}) => {
  const {
    signInFormStatus,
    classes,
    eventHandlers,
    staticVariables,
    themeColors,
    getLoader,
  } = props;
  const {
    handleEmailChange,
    handleForgotPassword,
    handlePasswordChange,
    handleLogin,
    handleGoogleLogin,
  } = eventHandlers;
  const { LoginTitle } = staticVariables;

  return (
    <Box className={classes.mainSignInContainer}>
      <SigmoidLogo eventHandlers={eventHandlers} classes={classes} />
      <Box className={classes.mainSignInFormRowLeft}>
        <SigviewTypography
          variant="pLargest"
          style={{ padding: "10px 0px 0px 0px" }}
        >
          Log in to your Account
        </SigviewTypography>
      </Box>
      <Box className={classes.mainSignInFormRowLeftBottom}>
        <SigviewTypography
          variant="pSmallMedium"
          style={{ padding: "0px 0px 0px 0px" }}
        >
          Welcome back! Select method to log in:
        </SigviewTypography>
      </Box>

      {/* ERROR */}
      {signInFormStatus.signInStatus.value === "error" && (
        <Box>
          <SigviewTypography
            style={{
              color: themeColors["failureColor"],
              lineHeight: "16px",
              textAlign: "left",
              height: "auto",
              width: "350px",
              padding: "0px 0px 10px 0px",
            }}
            variant="pMedium"
          >
            {signInFormStatus.signInStatus.message}
          </SigviewTypography>
        </Box>
      )}

      {/* FORM */}
      <form onSubmit={handleLogin}>
        <Box className={classes.mainSignInFormRow}>
          <SigviewTextField
            width="350px"
            height="40px"
            fontSize="12px"
            placeholder="Email"
            border="1px solid #ddd"
            type="email"
            value={signInFormStatus.email.value}
            onChange={handleEmailChange}
            error={
              signInFormStatus.email.status === "invalid" &&
              signInFormStatus.showSignInUiError.value
            }
            helperText={signInFormStatus.email.message}
          />
        </Box>
        <Box className={classes.mainSignInFormRow}>
          <SigviewTextField
            width="350px"
            height="40px"
            fontSize="12px"
            border="1px solid #ddd"
            placeholder="Password"
            type="password"
            value={signInFormStatus.password.value}
            onChange={handlePasswordChange}
            error={
              signInFormStatus.password.status === "invalid" &&
              signInFormStatus.showSignInUiError.value
            }
            helperText={signInFormStatus.password.message}
          />
        </Box>
        <Box className={classes.mainSignInFormRowLeft}>
          <SigviewTypography
            style={{
              height: "15px",
              cursor: "pointer",
              color: themeColors["primaryColor"],
            }}
            variant="pSmallMedium"
            onClick={handleForgotPassword}
            customClassName="ForgotPassword-GA"
          >
            Forgot Password?
          </SigviewTypography>
          {/* <SigviewButton
            onClick={handleGoogleLogin}
            style={{
              btnWidth: "350px",
              btnHeight: "40px",
              fontSize: "14px !important",
              iconName: "https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg",
            }}
            title="Login with Google"
          /> */}
        </Box>
        <Box className={classes.mainSignInFormRow}>
          <SigviewButton
            onClick={handleLogin}
            variant="containedLighterNewBlue"
            style={{
              btnWidth: "350px",
              btnHeight: "40px",
              fontSize: "12px !important",
              bgColor: "#045ad9",
              color: "#fff",
              bgHoverColor: "#fff",
              backgroundColor: "#045ad9",
            }}
            title={LoginTitle}
            customClassName={`${
              (isSelectionsInvalid(signInFormStatus) &&
                signInFormStatus.showSignInUiError.value) ||
              getLoader
                ? ""
                : "SignInLoginButton-GA"
            }`}
            disabled={
              (isSelectionsInvalid(signInFormStatus) &&
                signInFormStatus.showSignInUiError.value) ||
              getLoader
            }
            type="submit"
          />
        </Box>
        <Box className={classes.mainSignInFormRowBottom}>
          <Box
            css={{
              color: "#46596A",
              padding: "0 3px",
              fontSize: "11px",
              background: "#fff",
            }}
          >
            New to Sigview?
          </Box>
          <Box
            css={{
              color: "#045ad9",
              padding: "0 0px",
              fontSize: "11px",
              background: "#fff",
              cursor: "pointer",
            }}
          >
            <a
              className={classes.link}
              href="https://www.sigmoid.com/data-analytics/"
              target="_blank"
            >
              Know more about us
            </a>
          </Box>
        </Box>
      </form>
      <Box className={classes.horizontalLineWithWord}>
        <span className={classes.horizontalLineWithWordInner}>
          or continue with email
        </span>
      </Box>
      <Box css={{ paddingTop: "13px" }}>
        <GoogleSignInButton
          onClick={handleGoogleLogin}
          title="Log in with Google"
          imgUrl={googleLogo}
        />
      </Box>
    </Box>
  );
};
const Reset = (props = {}) => {
  const {
    signInFormStatus,
    classes,
    eventHandlers,
    staticVariables,
    themeColors,
    sentEmailLoader,
  } = props;
  const { handleEmailChange, handleReset, handleBackToHome } = eventHandlers;
  const { NextTitle } = staticVariables;
  return (
    <Box className={classes.mainSignInContainer}>
      <SigmoidLogo eventHandlers={eventHandlers} classes={classes} />
      <Box className={classes.mainSignInFormRowLeft}>
        <SigviewTypography
          variant="pLargest"
          style={{ textAlign: "center", padding: "10px 0px 0px 0px" }}
        >
          Reset your Password
        </SigviewTypography>
      </Box>
      <Box className={classes.mainSignInFormRowLeft}>
        <SigviewTypography
          variant="pSmallMedium"
          style={{ padding: "0px 0px 0px 0px" }}
        >
          No worries, we'll send you reset instructions
        </SigviewTypography>
      </Box>
      <Box>
        {signInFormStatus.resetStatus.value === "error" && (
          <SigviewTypography
            style={{
              color: "#f44336",
              lineHeight: "16px",
              textAlign: "left",
              width: "350px",
              height: "20px",
            }}
            variant="pMedium"
          >
            {signInFormStatus.resetStatus.message}
          </SigviewTypography>
        )}
      </Box>

      <Box className={classes.mainSignInFormRow}>
        <SigviewTextField
          width="350px"
          height="40px"
          fontSize="12px"
          border="1px solid #ddd"
          placeholder="Enter email id"
          type="email"
          value={signInFormStatus.email.value}
          onChange={handleEmailChange}
          error={
            (signInFormStatus.email.status === "invalid" &&
              signInFormStatus.showSignInUiError.value) ||
            signInFormStatus.resetStatus.value === "error"
          }
          helperText={signInFormStatus.email.message}
        />
      </Box>

      <Box className={classes.mainSignInFormRow}>
        <SigviewButton
          variant="containedLighterNewBlue"
          onClick={handleReset}
          style={{
            btnWidth: "350px",
            btnHeight: "40px",
            fontSize: "12px !important",
            bgColor: "#045ad9",
            color: "#fff",
            bgHoverColor: "#fff",
            backgroundColor: "#045ad9",
          }}
          customClassName="ClickSendResetLink-GA"
          title={NextTitle}
          disabled={
            (signInFormStatus.showSignInUiError.value &&
              signInFormStatus.email.status === "invalid") ||
            sentEmailLoader
          }
        />
      </Box>
      <Box className={classes.mainSignInFormRowLeft}>
        <SigviewTypography
          style={{
            height: "15px",
            cursor: "pointer",
            color: themeColors["primaryColor"],
          }}
          variant="pMedium"
          onClick={handleBackToHome}
        >
          Back to login
        </SigviewTypography>
      </Box>
    </Box>
  );
};

function SignInForm(props = {}) {
  // * Destructure props
  const { dispatch: ReduxDispatcher, signInFormStatus = {} } = props;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  console.log("sonu", signInFormStatus);

  // * Define contexts
  const history = useHistory();

  // * Define required states
  // const [signInFormStatus, ReduxDispatcher] = useReducer(
  //   useReducerLogger(signInFormStatus),
  //   initialSelections
  // );
  const [getLoader, setLoader] = useState(false);
  const [sentEmailLoader, setSentEmailLoader] = useState(false);

  useEffect(() => {
    return () => {
      setLoader(false);
      setSentEmailLoader(false);
    };
  }, []);

  // * Define requried event handlers
  const handleEmailChange = (value) => {
    const payload = {
      key: "email",
      value,
    };
    const action = updateSignInForm(payload);
    ReduxDispatcher(action);
  };
  const handlePasswordChange = (value) => {
    const payload = {
      key: "password",
      value,
    };
    const action = updateSignInForm(payload);
    ReduxDispatcher(action);
  };
  const cleanPasswordField = () => {
    const payload = {
      key: "password",
      value: "",
    };
    const action = updateSignInForm(payload);
    ReduxDispatcher(action);
  };
  const handleBackToHome = () => {
    cleanPasswordField();
    const payload = {
      key: "tab",
      value: "signIn",
    };
    const action = updateSignInForm(payload);
    ReduxDispatcher(action);
  };
  const handleReset = () => {
    // TO BE REPLACED IN LINE NO.192
    // console.log("window.location.hostname -> ", window.location.hostname);
    setSentEmailLoader(true);
    if (signInFormStatus.email.value !== "") {
      let payload = {
        email: signInFormStatus.email.value,
        domain: window.location.hostname,
      };
      let fetchProps = {
        payload,
      };

      const sendResetPasswordLinkPromise = sendResetPasswordLink(fetchProps);
      sendResetPasswordLinkPromise
        .then((response) => response.json())
        .then((data) => {
          console.log("data -> ", data);
          if (data.status === "1") {
            payload = {
              key: "tab",
              value: "postReset",
            };
            const action = updateSignInForm(payload);
            ReduxDispatcher(action);
            setSentEmailLoader(false);
            masterTrackGaEvent({
              category: "ForgotPassword",
              action: "ForgotPassEmail",
              label: "EmailSentToUser",
            });
          }

          if (data.status === "0") {
            const payload = {
              key: "resetStatus",
              value: {
                ...signInFormStatus.resetStatus,
                message: data.message,
                value: "error",
              },
            };
            const action = updateSignInFormWholeKey(payload);
            ReduxDispatcher(action);
            setSentEmailLoader(false);
          }
        })
        .catch((json) => {
          console.log("json -> ", json);
        });
    } else {
      setSentEmailLoader(false);
    }

    const payload = {
      key: "showSignInUiError",
      value: true,
    };
    const action = updateSignInForm(payload);
    ReduxDispatcher(action);
  };
  const handleLogin = (e) => {
    e.preventDefault();
    setLoader(true);
    if (
      signInFormStatus.email.value !== "" &&
      signInFormStatus.password.value !== ""
    ) {
      const sigviewSignInPromise = sigviewSignIn({
        payload: {
          email: signInFormStatus.email.value,
          password: signInFormStatus.password.value,
          rememberMe: true,
        },
      });
      sigviewSignInPromise
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === "200") {
            localStorage.setItem("satellizer_token", data.token);
            localStorage.setItem("sigview_email", signInFormStatus.email.value);
            masterTrackGaEvent({
              category: "SignIn",
              action: "Login",
              label: "Logged-In",
            });
            const payload = {
              status: "success",
              xAuthToken: { "X-Auth-Token": data.token, instances: "" },
              isTokenPresent: true,
              isTokenValid: true,
            };

            const action = updateAuthLogin(payload);
            ReduxDispatcher(action);

            history.push(config.hardCoded.rootUrlOnLogin);
            const payload1 = {
              key: "signInStatus",
              value: {
                ...signInFormStatus.signInStatus,
                message: "",
                value: "initial",
              },
            };
            const action1 = updateSignInFormWholeKey(payload1);
            ReduxDispatcher(action1);
            setLoader(false);
          } else {
            setLoader(false);
            const payload = {
              key: "signInStatus",
              value: {
                ...signInFormStatus.signInStatus,
                message: data.statusMessage,
                value: "error",
              },
            };
            const action = updateSignInFormWholeKey(payload);
            ReduxDispatcher(action);
          }
        })

        .catch((json) => {
          console.log("json -> ", json);

          const payload = {
            key: "signInStatus",
            value: {
              ...signInFormStatus.signInStatus,
              message: json.error || config.messages.uiErrorMessage,
              value: "error",
            },
          };
          const action = updateSignInFormWholeKey(payload);
          ReduxDispatcher(action);
        });
    } else {
      setLoader(false);
    }

    const payload = {
      key: "showSignInUiError",
      value: true,
    };
    const action = updateSignInForm(payload);
    ReduxDispatcher(action);
  };
  const handleForgotPassword = () => {
    const payload = {
      key: "tab",
      value: "reset",
    };
    const action = updateSignInForm(payload);
    ReduxDispatcher(action);
  };

  // Local Test Google Login Url -  "https://accounts.google.com/o/oauth2/auth?client_id=628022011733-53mt3n31rvtd70neu2ut9kqn2v1eb6iq.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocal.sigview.io%3A3000&scope=profile%20email&response_type=code&response_mode=query&state=hzhs7raagup&nonce=981vzvoy87";
  const handleGoogleLogin = () => {
    window.location.href =
      "https://accounts.google.com/o/oauth2/auth?client_id=810584185860-p12fcgdjj7b0217hjjf2cnoqf7m6n4lt.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fsigview.sigmoid.io&scope=profile%20email&response_type=code&response_mode=query&state=c3f938881ln&nonce=2k0f739900l";
  };

  // * Define required static variables
  const LoginTitle = getLoader === true ? <Loader /> : "Log in to Sigview";
  const NextTitle = sentEmailLoader === true ? <Loader /> : "Send Reset Link";
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const eventHandlers = {
    handleEmailChange,
    handleReset,
    handlePasswordChange,
    handleBackToHome,
    handleLogin,
    handleForgotPassword,
    handleGoogleLogin,
  };
  const staticVariables = { NextTitle, LoginTitle };
  const commonChildrenProps = {
    signInFormStatus,
    eventHandlers,
    classes,
    staticVariables,
    themeColors,
    sentEmailLoader,
    getLoader,
  };

  return (
    <LayoutBottom>
      <Box className={classes.root}>
        <Box className={classes.leftPanel}>
          {signInFormStatus.tab.value === "signIn" && (
            <SignIn {...commonChildrenProps} />
          )}
          {signInFormStatus.tab.value === "reset" && (
            <Reset {...commonChildrenProps} />
          )}
          {signInFormStatus.tab.value === "postReset" && (
            <PostReset {...commonChildrenProps} />
          )}
        </Box>
        <Box className={classes.rightPanel}>
          <SignInImage {...commonChildrenProps} />
        </Box>
      </Box>
    </LayoutBottom>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
  allData: state.data,
  signInFormStatus: state.signInFormStatus,
});

export default connect(mapStateToProps)(SignInForm);
