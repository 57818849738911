// * Import required libraies
import React, { useContext } from "react";

// * Import 3rd party lib comp
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Define required functions
const useStyles = makeStyles((theme) => {
  // const { rootHeight = "100%" } = style;
  const styles = {
    root: (props) => ({
      height: !props.rootHeight ? "100%" : props.rootHeight,
      display: "flex",
      flexDirection: "column",
      "@media (max-width:1277px)": {
        width: "max-content",
      },
      // padding: "0px 0px 10px 0px",
      // position: "relative",
    }),
    stickyBox: {
      position: "sticky",
      top: "0",
      zIndex: 11,
      // position: "absolute",
      // top: 0,
      // left: 22,
      // width: "100%",
      // boxSizing: "border-box",
      backgroundColor: theme.palette.misc["mainContentBgColor"],
      // padding: "12px 0px 12px 0px",
      padding: "12px 22px 12px 22px",
      flexShrink: 0,
    },
    droppableZoneContainer: {
      padding: "0px 7px 10px 22px",
      flexGrow: 1,
      flexBasis: 0,
    },
    menuBar: {},
    dimensionFilters: { paddingBottom: "10px" },
    metricChartsMenu: {},
    dimensionTablesMenu: {},
    metricChartsContainer: {},
    dimensionTablesContainer: {},
  };
  return styles;
});

function LayoutAnalyze(props = {}) {
  // * Destructure props
  const { children = <></>, style = {} } = props;

  // * Define requires static variables
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const classes = useStyles(style);
  const {
    root,
    stickyBox,
    menuBar,
    dimensionFilters,
    metricChartsMenu,
    dimensionTablesMenu,
    metricChartsContainer,
    dimensionTablesContainer,
    droppableZoneContainer,
  } = classes;

  // ---LAYOUT HIERARCHY---
  // STICKY AT THE TOP
  // Menu Bar
  //    Name
  //    Time Filters
  //    Button
  // Dimension Filters
  // Dashboard Menu Bar
  //    Metric Charts Menu
  //    Dimension Table Menu
  // SCROLLABLE CONTENT
  // Dashboard Content
  //    Metric Charts
  //    Dimension Tables

  return (
    <Grid container className={root}>
      <Grid container className={stickyBox}>
        <Grid item xs={12} className={menuBar}>
          {children[0]}
        </Grid>
        <Grid item xs={12} className={dimensionFilters}>
          {children[1]}
        </Grid>
        <Grid item xs={4} className={metricChartsMenu}>
          {children[2]}
        </Grid>
        <Grid item xs={8} className={dimensionTablesMenu}>
          {children[3]}
        </Grid>
      </Grid>

      <Grid container className={droppableZoneContainer}>
        <Grid item xs={4} className={metricChartsContainer}>
          {children[4]}
        </Grid>
        <Grid item xs={8} className={dimensionTablesContainer}>
          {children[5]}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LayoutAnalyze;
