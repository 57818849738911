// Import required lib
import ReactGA from "react-ga";

// Import store
import { store } from "../../redux/storeInitializer";

// Import config
import { config } from "../../config/config";

// ! PLEASE NOTE
// GA not to be triggered for these email
// 1. email id contains @sigmoid.com or @sigmoidanalytics.com or @sigmoid.in
// 2. email id one of the below list

function isEmailWhiteListed(email = "") {
  const whiteListEmailsForGa = config.hardCoded.whiteListEmailsForGa;
  // 1. email id contains @sigmoid.com or @sigmoidanalytics.com or @sigmoid.in
  if (
    email.includes("@sigmoid.com") ||
    email.includes("@sigmoidanalytics.com") ||
    email.includes("@sigmoid.io")
  )
    return true;
  // 2. email id one of the below list
  if (whiteListEmailsForGa.includes(email)) return true;
  return false;
}

function getGaCustomDimensions(props = {}) {
  const { reqMetadata = {}, currTab = "DatastoryBeta" } = props;
  const {
    organization = "",
    view = "",
    groupId = "",
    userId = "",
    userType = "",
  } = reqMetadata;
  return {
    dimension1: organization, //organization
    dimension2: view, //view
    dimension3: groupId, //group
    dimension4: userId, //userId
    dimension5: userType, //userType
    dimension6: currTab, //tab
  };
}

function masterTrackGaEvent(props = {}) {
  const { category = "Datastory", action = "", label = "" } = props;

  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const reqMetadata = globalReduxUser?.reqMetadata || {};
  const userEmail = reqMetadata?.email || "";

  // Making required variables
  const pathName = window.location.pathname;
  const currentTabName = pathName.split("/");
  const currTab = config.hardCoded.gacurrTabMapping[currentTabName[1]];
  const customDimesionsProps = { reqMetadata, currTab };
  const gaCustomDimensions = getGaCustomDimensions(customDimesionsProps);

  // Trigger GA Event only if email is not white-listed
  const isEmailWhiteListedFlag = isEmailWhiteListed(userEmail);
  if (!isEmailWhiteListedFlag) {
    ReactGA.set(gaCustomDimensions);
    ReactGA.event({
      category,
      action,
      label,
    });
  }
}

function masterTrackGaPageView(locationPath) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const reqMetadata = globalReduxUser?.reqMetadata || {};
  const userEmail = reqMetadata?.email || "";

  // Making required variables
  const pathName = window.location.pathname;
  const currentTabName = pathName.split("/");
  const currTab = config.hardCoded.gacurrTabMapping[currentTabName[1]];
  const customDimesionsProps = { reqMetadata, currTab };
  const gaCustomDimensions = getGaCustomDimensions(customDimesionsProps);

  // Trigger GA Event only if email is not white-listed
  const isEmailWhiteListedFlag = isEmailWhiteListed(userEmail);
  if (!isEmailWhiteListedFlag) {
    ReactGA.set(gaCustomDimensions);
    ReactGA.pageview({ locationPath });
  }
}

export { masterTrackGaEvent, masterTrackGaPageView };
