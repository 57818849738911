//Import required libraies
import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = ({ isDragging, draggableStyle, customStyle }) => {
  const backgroundColor = customStyle?.backgroundColor || "lightgreen";
  const backgroundColorDuringDragging =
    customStyle?.backgroundColorDuringDragging || "lightblue";
  const marginRight = customStyle?.marginRight || "0px";
  const marginBottom = customStyle?.marginBottom || "0px";
  const borderRadius = customStyle?.borderRadius || "0px";
  const border = customStyle?.border || "0px solid transparent";
  const boxSizing = "border-box";

  return {
    // change background colour if dragging
    background: isDragging ? backgroundColorDuringDragging : backgroundColor,

    // add custom styles
    marginRight,
    marginBottom,
    borderRadius,
    border,
    boxSizing,

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

const getListStyle = (props) => {
  const { isDraggingOver, direction, customStyle } = props;

  const flexDirectionMapping = {
    vertical: "column",
    horizontal: "row",
  };

  const backgroundColor = customStyle?.backgroundColor || "lightgrey";
  const backgroundColorDuringDragging =
    customStyle?.backgroundColorDuringDragging || "lightblue";
  // const overflow = customStyle?.overflow || "auto";

  return {
    background: isDraggingOver
      ? backgroundColorDuringDragging
      : backgroundColor,
    display: "flex",
    flexDirection: flexDirectionMapping[direction] || "column",
    padding: customStyle?.padding || 8,
    width: "100%",
  };
};

function SigviewDnD(props) {
  const {
    data,
    onChange,
    direction = "vertical",
    render,
    customStyle = {},
    idAccessor = "id",
    disabled = false,
  } = props;

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newData = reorder(
      data,
      result.source.index,
      result.destination.index
    );

    onChange(newData);
  };

  //Debugging
  // console.groupCollapsed("SigviewDnD");
  // console.log("Props", props);
  // console.groupEnd();

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction={direction}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle({
              isDraggingOver: snapshot.isDraggingOver,
              direction,
              customStyle: customStyle.droppable,
            })}
            {...provided.droppableProps}
          >
            {data.map((item, index) => (
              <Draggable
                key={item[idAccessor]}
                draggableId={item[idAccessor]}
                index={index}
                isDragDisabled={disabled}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle({
                      isDragging: snapshot.isDragging,
                      draggableStyle: provided.draggableProps.style,
                      customStyle: customStyle.draggable,
                    })}
                  >
                    {render(item)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default SigviewDnD;
