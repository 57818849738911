import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from "@material-ui/core";
import { format as d3Format } from "d3";

// Import custom components
import SigviewProgressBar from "../../../components/Common/SigviewProgressBar";
import SigviewButton from "../../../components/Common/SigviewButton";
import Loader from "../../../components/Loader/Loader";
import SigviewIcon from "../../../components/Common/SigviewIcon";

// * Import utils/data
import { wrapperChartObject } from "../../../utils/chartObjectUtils";
import {
  updatePivotAdvanceSort,
  updatePivotReloadEpoch,
} from "../../../redux/actions";
import {
  getSortDetails,
  rowUiDataFriendly,
  totalRowUiDataFriendly,
  getColumn,
  updateRowData,
  makeSigviewStyles,
  makeUiFriendlyDimensionFilters,
  updateChildrenShowMoreLoading,
  updateChildrenData,
  updateChildrenDataRecursively,
  updateChildrenLoading,
} from "../../../utils/pivotUtils";

// Import API functions
import { getData } from "../../../services/api";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";
import { appendMetricSym } from "../../../utils/plotlyUtils";
import {
  formatDimValue,
  extractOrderFromAdvanceSort,
  makeAdditionalTimeFilters,
} from "../../../utils/pivotUtils";

// Defining required constants
const initialPivotData = { result: [], status: "loading", message: "" };

function PivotTableRow(props = {}) {
  const {
    row,
    columns,
    pivotData,
    setPivotData,
    selections,
    user,
    allData = {},
    globalFilters = {},
    totalLength = 0,
    rowIndex = 0,
  } = props;
  // console.log("PivotTableRow props", props);
  const level = row.level;
  const actualColumns = columns[level];

  // * Function run after clicking the Error button form the table
  const handleError = (event, { row, columns }) => {
    // * DEBUGGER
    // console.groupCollapsed("handleError");
    // console.log("row", row);
    // console.log("columns", columns);
    // console.groupEnd();
    if (row.level === 0) {
      const extractOrderFromAdvanceSortProps = {
        advanceSort: selections.advanceSort,
        dimension: selections.dimensionsList[row.level],
        allData,
      };
      const orderDetails = extractOrderFromAdvanceSort(
        extractOrderFromAdvanceSortProps
      );
      var source = axios.CancelToken.source();
      var newSelections = {
        ...selections,
        dimensionsList: [selections.dimensionsList[row.level]],
        dataLimit: selections.dataLimit,
        granularity: globalFilters?.metricChartGranularity?.value,
        ...orderDetails,
      };
      var props = { selections: newSelections, user };
      var getDataPayload = wrapperChartObject(props);
      var fetchProps = {
        payload: getDataPayload,
        cancelToken: source.token,
      };
      var getDataPromise = getData(fetchProps);
      getDataPromise
        .then((data) => {
          const rowUiDataProps = {
            getDataResponse: data.result.data,
            columnData: columns,
            columnIndex: row.level,
            dataLimit: selections.dataLimit,
            apiResponseLength: data.result.data.length,
          };
          const finalData = rowUiDataFriendly(rowUiDataProps);
          setPivotData({
            result: finalData,
            status: "success",
            message: "",
          });
        })
        .catch((json) => {
          const rowUiDataProps = {
            getDataResponse: [],
            columnData: columns,
            columnIndex: 0,
            dataLimit: selections.dataLimit,
            apiResponseLength: 0,
            errMessage: json.error,
          };
          const finalData = rowUiDataFriendly(rowUiDataProps);
          setPivotData({
            result: finalData,
            status: "success",
            message: "",
          });
          console.groupCollapsed("UI ERROR USE-EFFECT");
          console.log(json);
          console.groupEnd();
        });
    } else {
      // Get all the parent row Filters Function
      const { actualParent } = row;
      const additionalDimensionFilters = makeUiFriendlyDimensionFilters(
        columns,
        actualParent
      );
      const clickedRowDimensionFilters = [
        {
          id: columns[actualParent.level][0].id,
          values: [actualParent[columns[actualParent.level][0]["accessor"]]],
          metadata: {
            ...columns[actualParent.level][0].actualPayload,
          },
          filterType: "include",
          advancedFilters: [],
          valid: true,
        },
      ];
      const extractOrderFromAdvanceSortProps = {
        advanceSort: selections.advanceSort,
        dimension: selections.dimensionsList[actualParent.level + 1],
        allData,
      };
      const orderDetails = extractOrderFromAdvanceSort(
        extractOrderFromAdvanceSortProps
      );
      const source = axios.CancelToken.source();
      const newSelections = {
        ...selections,
        // * If there is dateTime dimension Don't send filter in payload
        dimensionFilters:
          columns[actualParent.level][0].actualPayload.dataType !== "dateTime"
            ? [
                ...selections.dimensionFilters,
                ...additionalDimensionFilters,
                ...clickedRowDimensionFilters,
              ]
            : [...selections.dimensionFilters, ...additionalDimensionFilters],
        dimensionsList: [selections.dimensionsList[actualParent.level + 1]],
        dataLimit: 11,
        granularity: globalFilters?.metricChartGranularity?.value,
        ...orderDetails,
      };
      const props = { selections: newSelections, user };
      const getDataPayload = wrapperChartObject(props);
      // console.log("payload for +click", getDataPayload);
      const fetchProps = {
        payload: getDataPayload,
        cancelToken: source.token,
      };
      // Api calling and updating the children array with api response
      const getDataPromise = getData(fetchProps);
      getDataPromise
        .then((data) => {
          if (data.status.statusCode === "200") {
            // console.log("ERROR DATA", data);
            setPivotData((latestState) => {
              const updateChildrenDataProps = {
                apiResponse: data?.result.data,
                columns,
                data: latestState.result,
                row: actualParent,
                dataLimit: newSelections.dataLimit,
                isExpandedValue: true,
              };
              const newPivotData = updateChildrenData(updateChildrenDataProps);
              return {
                result: newPivotData,
                status: "success",
                message: "",
              };
            });
          } else return data;
        })
        .catch((json) => {
          setPivotData((latestState) => {
            // console.log("ROW LEVEL", actualParent.level);
            const rowLevel = actualParent.level;
            const id = actualParent[columns[rowLevel][0].accessor];
            const key = columns[rowLevel][0].accessor;
            const updateChildrenDataRecursivelyProps = {
              id,
              key,
              children: latestState.result,
              value: [],
              rowLevel,
              dataLimit: newSelections.dataLimit,
              isExpandedValue: true,
              errMessage: json.error,
              actualParent,
            };
            let newPivotData = updateChildrenDataRecursively(
              updateChildrenDataRecursivelyProps
            );
            return {
              result: newPivotData,
              status: "success",
              message: "",
            };
          });
          console.groupCollapsed("UI ERROR +ICON CLICK");
          console.log(json);
          console.groupEnd();
        });
    }
  };
  // * Function run after clicking the Show More button form the table
  const handleShowMore = (event, { row, columns, selections = {} }) => {
    const rowLevel = row.level;
    //  For showing Loading to ui and adding +10 to datalimit feild
    setPivotData((latestState) => {
      const updateChildrenLoadingProps = {
        columns,
        data: latestState.result,
        row,
        rowLevel: row.level,
        value: true,
        selections,
      };
      var updatedPivotData = updateChildrenShowMoreLoading(
        updateChildrenLoadingProps
      );
      return {
        result: updatedPivotData,
        status: "success",
        message: "",
      };
    });
    const extractOrderFromAdvanceSortProps = {
      advanceSort: selections.advanceSort,
      dimension: selections.dimensionsList[rowLevel],
      allData,
    };
    const orderDetails = extractOrderFromAdvanceSort(
      extractOrderFromAdvanceSortProps
    );
    //For cancelling the query if the activeDimension or
    const source = axios.CancelToken.source();
    const additionalDimensionFilters = makeUiFriendlyDimensionFilters(
      columns,
      row
    );
    const { timeFilterFlag, dateRange } = makeAdditionalTimeFilters({
      columns,
      row,
      timeFilters: selections.timeFilters,
      considerRowFlag: false,
    });
    let dimensionFiltersFinal = [
      ...selections.dimensionFilters,
      ...additionalDimensionFilters,
    ];
    // * If there is dateTime dimension Don't send filter in payload
    dimensionFiltersFinal = dimensionFiltersFinal.filter(
      (row) => row.metadata.dataType !== "dateTime"
    );
    const newSelections = {
      ...selections,
      dimensionsList: [selections.dimensionsList[rowLevel]],
      dataLimit: row.dataLimit + selections.dataLimit,
      granularity: globalFilters?.metricChartGranularity?.value,
      dimensionFilters: dimensionFiltersFinal,
      ...orderDetails,
    };
    const props = { selections: newSelections, user };
    let getDataPayload = wrapperChartObject(props);
    if (timeFilterFlag) {
      getDataPayload = {
        ...getDataPayload,
        chartObject: {
          ...getDataPayload.chartObject,
          requestParam: {
            ...getDataPayload.chartObject.requestParam,
            dateRange: dateRange,
          },
        },
      };
    }
    const fetchProps = {
      payload: getDataPayload,
      cancelToken: source.token,
    };
    // console.log("getData payload for show More", getDataPayload);
    const getDataPromise = getData(fetchProps);
    getDataPromise
      .then((data) => {
        if (data.status.statusCode === "200") {
          // * First update the loader to normal
          setPivotData((latestState) => {
            const updateChildrenLoadingProps = {
              columns,
              data: latestState.result,
              row,
              rowLevel: row.level,
              value: false,
              selections,
            };
            var updatedPivotData = updateChildrenShowMoreLoading(
              updateChildrenLoadingProps
            );
            return {
              result: updatedPivotData,
              status: "success",
              message: "",
            };
          });
          // * Second update the pivotData state with the new Data
          setPivotData((latestState) => {
            const newPivotData = updateRowData({
              apiResponse: data?.result.data,
              columns,
              data: latestState.result,
              row,
              dataLimit: newSelections.dataLimit,
              previousDataLimit: row.dataLimit,
            });

            return {
              result: newPivotData,
              status: "success",
              message: "",
            };
          });
        } else return data;
      })
      .catch((json) => {
        console.groupCollapsed("UI ERROR HANDLESHOWMORE");
        console.log(json);
        console.groupEnd();
      });
  };

  // * Func to clear children - when click -Icon to clear children
  const handleClearChildren = (event, clearChildrenProps = {}) => {
    const { row = {}, columns = [] } = clearChildrenProps;
    // * DEBUGGER
    // console.groupCollapsed("handleClearChildren");
    // console.log("row", row);
    // console.groupEnd();

    setPivotData((latestState) => {
      const newPivotData = updateChildrenData({
        apiResponse: [],
        columns,
        data: latestState.result,
        row,
        isExpandedValue: false,
        clearChilren: "true",
      });

      return {
        result: newPivotData,
        status: "success",
        message: "",
      };
    });
  };

  // * Function run after clicking the + icon form the table
  const handleUpdateIsLoading = (event, handleUpdateIsLoadingProps = {}) => {
    const {
      row = {},
      columns = [],
      selections = {},
    } = handleUpdateIsLoadingProps;

    // step1-  For updating to the loader form where it is clicked
    setPivotData((latestState) => {
      const updateChildrenLoadingProps = {
        columns,
        data: latestState.result,
        row,
        rowLevel: row.level,
        value: true,
      };
      const updatedPivotData = updateChildrenLoading(
        updateChildrenLoadingProps
      );
      return {
        result: updatedPivotData,
        status: "success",
        message: "",
      };
    });

    // Get all the parent row Filters Function
    const additionalDimensionFilters = makeUiFriendlyDimensionFilters(
      columns,
      row
    );
    const clickedRowDimensionFilters = [
      {
        id: columns[row.level][0].id,
        values: [row[columns[row.level][0]["accessor"]]],
        metadata: {
          ...columns[row.level][0].actualPayload,
        },
        filterType: "include",
        advancedFilters: [],
        valid: true,
      },
    ];
    const extractOrderFromAdvanceSortProps = {
      advanceSort: selections.advanceSort,
      dimension: selections.dimensionsList[row.level + 1],
      allData,
    };
    const orderDetails = extractOrderFromAdvanceSort(
      extractOrderFromAdvanceSortProps
    );
    const source = axios.CancelToken.source();
    const { timeFilterFlag, dateRange } = makeAdditionalTimeFilters({
      columns,
      row,
      timeFilters: selections.timeFilters,
      considerRowFlag: true,
    });
    let dimensionFiltersFinal = [
      ...selections.dimensionFilters,
      ...additionalDimensionFilters,
      ...clickedRowDimensionFilters,
    ];
    // * If there is dateTime dimension Don't send filter in payload
    dimensionFiltersFinal = dimensionFiltersFinal.filter(
      (row) => row.metadata.dataType !== "dateTime"
    );
    const newSelections = {
      ...selections,
      // * If there is dateTime dimension Don't send filter in payload
      dimensionFilters: dimensionFiltersFinal,
      dimensionsList: [selections.dimensionsList[row.level + 1]],
      granularity: selections.globalFilters,
      ...orderDetails,
    };
    const props = { selections: newSelections, user };
    let getDataPayload = wrapperChartObject(props);
    if (timeFilterFlag) {
      getDataPayload = {
        ...getDataPayload,
        chartObject: {
          ...getDataPayload.chartObject,
          requestParam: {
            ...getDataPayload.chartObject.requestParam,
            dateRange: dateRange,
          },
        },
      };
    }
    // console.log("getDataPayload", getDataPayload);
    const fetchProps = {
      payload: getDataPayload,
      cancelToken: source.token,
    };
    // Api calling and updating the children array with api response
    const getDataPromise = getData(fetchProps);
    getDataPromise
      .then((data) => {
        if (data.status.statusCode === "200") {
          setPivotData((latestState) => {
            const updateChildrenDataProps = {
              apiResponse: data?.result.data,
              columns,
              data: latestState.result,
              row,
              dataLimit: newSelections.dataLimit,
              isExpandedValue: true,
            };
            const newPivotData = updateChildrenData(updateChildrenDataProps);
            return {
              result: newPivotData,
              status: "success",
              message: "",
            };
          });
        } else return data;
      })
      .catch((json) => {
        setPivotData((latestState) => {
          const rowLevel = row.level;
          const id = row[columns[rowLevel][0].accessor];
          const key = columns[rowLevel][0].accessor;
          const updateChildrenDataRecursivelyProps = {
            id,
            key,
            children: latestState.result,
            value: [],
            rowLevel,
            dataLimit: newSelections.dataLimit,
            isExpandedValue: true,
            errMessage: json.error,
            actualParent: row,
          };
          let newPivotData = updateChildrenDataRecursively(
            updateChildrenDataRecursivelyProps
          );
          return {
            result: newPivotData,
            status: "success",
            message: "",
          };
        });
        console.groupCollapsed("UI ERROR +ICON CLICK");
        console.log(json);
        console.groupEnd();
      });
  };

  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();

  return (
    <>
      <>
        {!row.isError && (
          <TableRow>
            {actualColumns.map((col, index) => {
              const value = row[col.accessor];
              const formatDimValueProps = {
                value: row[col.accessor],
                accessor: col.accessor,
                selections,
              };

              const isTotalRow = value?.toLowerCase() === "total";
              let displayValue = formatDimValue(formatDimValueProps);
              let title = value;
              if (isTotalRow) displayValue = value;
              // Only format if it's a metric (which is non 0 index in our case)
              if (index !== 0) {
                let metricObj = col.actualPayload;
                displayValue =
                  value === "NA"
                    ? value
                    : appendMetricSym(
                        value,
                        metricObj.dataUnit,
                        true
                      ).toUpperCase();
                title = value === "NA" ? value : d3Format(",")(value);
              } else {
                title = displayValue;
              }
              const style1 = {
                paddingLeft: `${row.level * 30}px`,
                alignItems: "center",
                backgroundColor: "white",
                textAlign: "left",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              };
              const style2 = {
                paddingLeft: `${row.level * 30}px`,
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                textAlign: "left",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              };
              const finalStyle = displayValue === "" ? style1 : style2;

              if (index === 0) {
                return (
                  <TableCell
                    key={index}
                    style={finalStyle}
                    className={`${classes.tableCell} ${classes.sticky}`}
                    title={title}
                  >
                    {row.isClickButton && row.isExpanded === false && (
                      <span
                        onClick={(event) =>
                          handleUpdateIsLoading(event, {
                            row,
                            columns,
                            selections: selections,
                          })
                        }
                        className="material-icons-outlined"
                        style={{
                          cursor: "pointer",
                          fontSize: "14px",
                          marginRight: "2px",
                          color: themeColors["primaryColor"],
                        }}
                      >
                        add_circle
                      </span>
                    )}
                    {row.isClickButton && row.isExpanded === true && (
                      <span
                        onClick={(event) =>
                          handleClearChildren(event, {
                            row,
                            columns,
                          })
                        }
                        className="material-icons-outlined"
                        style={{
                          cursor: "pointer",
                          fontSize: "14px",
                          marginRight: "2px",
                          color: themeColors["secondaryColorLight"],
                        }}
                      >
                        remove_circle
                      </span>
                    )}
                    {displayValue}
                  </TableCell>
                );
              } else {
                return (
                  <TableCell
                    className={classes.tableCell}
                    title={title}
                    key={index}
                  >
                    {displayValue}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        )}
        {/* show error button code */}
        {row.isError && row.isChildrenLoading === false && (
          <SigviewButton
            variant="containedPrimary"
            onClick={(event) =>
              handleError(event, {
                row,
                columns,
              })
            }
            title={row.errMessage}
            iconFlag={true}
            iconProps={{
              className: "material-icons-outlined",
            }}
          />
        )}
        {/* For showing the loader  */}
        {row.isChildrenLoading && (
          <TableRow>
            <TableCell className={classes.tableCell}>
              <SigviewProgressBar />
            </TableCell>
          </TableRow>
        )}
        {/* show more code */}
        {/* ORDER of SHOW MORE AND CHILDREN DEPENDS ON IF THE ROW IS THE LAST INDEX */}
        {/* ROW IS NOT THE LAST INDEX */}
        {row.isShowMore &&
          row.isChildrenLoading === false &&
          totalLength - 1 !== rowIndex && (
            <Box css={{ padding: `5px 0px 5px ${row.level * 30}px` }}>
              <SigviewButton
                variant="containedPrimary"
                onClick={(event) =>
                  handleShowMore(event, {
                    row,
                    columns,
                    selections: selections,
                  })
                }
                title="Show More"
                iconFlag={true}
                iconProps={{
                  className: "material-icons-outlined",
                }}
              />
            </Box>
          )}
        {row.children.length > 0 &&
          totalLength - 1 !== rowIndex &&
          row.children.map((childRow, index) => (
            <PivotTableRow
              key={index}
              row={childRow}
              columns={columns}
              pivotData={pivotData}
              setPivotData={setPivotData}
              selections={selections}
              user={user}
              allData={allData}
              totalLength={row.children.length}
              rowIndex={index}
            />
          ))}

        {/* ROW IS THE LAST INDEX */}
        {row.children.length > 0 &&
          totalLength - 1 === rowIndex &&
          row.children.map((childRow, index) => (
            <PivotTableRow
              key={index}
              row={childRow}
              columns={columns}
              pivotData={pivotData}
              setPivotData={setPivotData}
              selections={selections}
              user={user}
              allData={allData}
              totalLength={row.children.length}
              rowIndex={index}
            />
          ))}
        {row.isShowMore &&
          row.isChildrenLoading === false &&
          totalLength - 1 === rowIndex && (
            <Box
              className={classes.sticky}
              css={{ padding: `5px 0px 5px ${row.level * 30}px` }}
            >
              <SigviewButton
                variant="containedPrimary"
                onClick={(event) =>
                  handleShowMore(event, {
                    row,
                    columns,
                    selections,
                  })
                }
                title="Show More"
                iconFlag={true}
                iconProps={{
                  className: "material-icons-outlined",
                }}
              />
            </Box>
          )}
      </>
    </>
  );
}

function PivotTableTotalRow(props = {}) {
  const {
    row,
    columns,
    selections,
    user,
    allData = {},
    globalFilters = {},
    setPivotDataTotal,
  } = props;
  // * DEBUGGER
  // console.groupCollapsed("PivotTableTotalRow");
  // console.log("row", row);
  // console.log("columns", columns);
  // console.groupEnd();

  // * Function run after clicking the Error button form the table
  const handleError = (event, { row, columns }) => {
    // * DEBUGGER
    // console.groupCollapsed("handleError");
    // console.log("row", row);
    // console.log("columns", columns);
    // console.groupEnd();

    var newSelections = {
      ...selections,
      dimensionsList: [],
      dataLimit: 11,
      orderBy: "desc", //HARD CODED
      orderById: selections.metricsList[0]._id, //HARD CODED
      granularity: globalFilters?.metricChartGranularity?.value,
    };
    var props = { selections: newSelections, user };
    var getDataPayload = wrapperChartObject(props);
    // console.log("total payload", getDataPayload);
    var fetchProps = {
      payload: getDataPayload,
    };
    var getDataPromise = getData(fetchProps);
    getDataPromise
      .then((data) => {
        setPivotDataTotal((latestState) => {
          // console.log("total data", data.result.data);
          const rowUiDataProps = {
            getDataResponse: data.result.data,
            columnData: columns,
            columnIndex: 0,
          };
          const totalRowData = totalRowUiDataFriendly(rowUiDataProps);
          return {
            result: totalRowData,
            status: "success",
            message: "",
          };
        });
      })
      .catch((json) => {
        const rowUiDataProps = {
          getDataResponse: [],
          columnData: columns,
          columnIndex: 0,
          dataLimit: selections.dataLimit,
          apiResponseLength: 0,
          errMessage: json.error,
        };
        const finalData = rowUiDataFriendly(rowUiDataProps);
        setPivotDataTotal({
          result: finalData,
          status: "success",
          message: "",
        });
        console.groupCollapsed("UI ERROR USE-EFFECT FOR TOTAL API CALL");
        console.log(json);
        console.groupEnd();
      });
  };

  const actualColumns = columns[0];
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();

  return (
    <>
      <>
        {!row.isError && (
          <TableRow className={classes.totalRow}>
            {actualColumns.map((col, index) => {
              const value = row[col.accessor];
              const formatDimValueProps = {
                value: row[col.accessor],
                accessor: col.accessor,
                selections,
              };

              const isTotalRow = value?.toLowerCase() === "total";
              let displayValue = formatDimValue(formatDimValueProps);
              let title = value;
              if (isTotalRow) displayValue = value;
              // Only format if it's a metric (which is non 0 index in our case)
              if (index !== 0) {
                let metricObj = col.actualPayload;
                displayValue =
                  value === "NA"
                    ? value
                    : appendMetricSym(
                        value,
                        metricObj.dataUnit,
                        true
                      ).toUpperCase();
                title = value === "NA" ? value : d3Format(",")(value);
              } else {
                title = displayValue;
              }
              let className = classes.totaltableCellHeader;
              if (index === 0) className += ` ${classes.sticky}`;
              const style = {
                zIndex: `${row[col.accessor] === "Total" ? 0 : 0}`,
              };

              if (index === 0) {
                return (
                  <TableCell
                    key={index}
                    style={style}
                    className={className}
                    title={title}
                  >
                    {displayValue}
                  </TableCell>
                );
              } else {
                return (
                  <TableCell key={index} className={className} title={title}>
                    {displayValue}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        )}

        {/* show error button code */}
        {row.isError && row.isChildrenLoading === false && (
          <SigviewButton
            variant="containedPrimary"
            onClick={(event) =>
              handleError(event, {
                row,
                columns,
              })
            }
            title={row.errMessage}
            iconFlag={true}
            iconProps={{
              className: "material-icons-outlined",
            }}
          />
        )}
      </>
    </>
  );
}

function PivotTable(props) {
  const {
    selections = {},
    user = {},
    allData = {},
    onDataChange = () => {},
    dispatchPivotState = () => {},
    globalFilters = {},
  } = props;
  // console.log("pivottable selections", selections);
  const {
    dimensionsList,
    metricsList,
    dimensionFilters,
    advanceSort,
    timeFilters,
  } = selections;
  const [pivotData, setPivotData] = useState(initialPivotData);
  const [pivotDataTotal, setPivotDataTotal] = useState(initialPivotData);
  console.log("initial pivotData", pivotData);
  // console.log("pivotDataTotal", pivotDataTotal);

  // * Define required side effects
  useEffect(() => {
    const extractOrderFromAdvanceSortProps = {
      advanceSort: selections.advanceSort,
      dimension: dimensionsList[0],
      allData,
    };
    const orderDetails = extractOrderFromAdvanceSort(
      extractOrderFromAdvanceSortProps
    );
    var source = axios.CancelToken.source();
    var newSelections = {
      ...selections,
      dimensionsList: [dimensionsList[0]],
      granularity: globalFilters?.metricChartGranularity?.value,
      // dataLimit: selections.dataLimit,
      ...orderDetails,
    };
    console.log("datalimit", newSelections.dataLimit);
    var props = { selections: newSelections, user };
    var getDataPayload = wrapperChartObject(props);
    var fetchProps = {
      payload: getDataPayload,
      cancelToken: source.token,
    };
    var getDataPromise = getData(fetchProps);
    getDataPromise
      .then((data) => {
        const rowUiDataProps = {
          getDataResponse: data.result.data,
          columnData: columns,
          columnIndex: 0,
          dataLimit: selections.dataLimit,
          apiResponseLength: data.result.data.length,
        };
        const finalData = rowUiDataFriendly(rowUiDataProps);
        setPivotData({
          result: finalData,
          status: "success",
          message: "",
        });
      })
      .catch((json) => {
        const rowUiDataProps = {
          getDataResponse: [],
          columnData: columns,
          columnIndex: 0,
          dataLimit: selections.dataLimit,
          apiResponseLength: 0,
          errMessage: json.error,
        };
        const finalData = rowUiDataFriendly(rowUiDataProps);
        setPivotData({
          result: finalData,
          status: "success",
          message: "",
        });
        console.groupCollapsed("UI ERROR USE-EFFECT");
        console.log(json);
        console.groupEnd();
      });

    // ! Total api calling
    var source1 = axios.CancelToken.source();
    var newSelections = {
      ...selections,
      dimensionsList: [],
      orderBy: "desc", //HARD CODED
      orderById: selections.metricsList[0]._id, //HARD CODED
      granularity: globalFilters?.metricChartGranularity?.value,
    };
    var props = { selections: newSelections, user };
    var getDataPayload = wrapperChartObject(props);
    // console.log("total payload", getDataPayload);
    var fetchProps = {
      payload: getDataPayload,
      cancelToken: source.token,
    };
    var getDataPromise = getData(fetchProps);
    getDataPromise
      .then((data) => {
        setPivotDataTotal((latestState) => {
          // console.log("total data", data.result.data);
          const rowUiDataProps = {
            getDataResponse: data.result.data,
            columnData: columns,
            columnIndex: 0,
          };
          const totalRowData = totalRowUiDataFriendly(rowUiDataProps);
          return {
            result: totalRowData,
            status: "success",
            message: "",
          };
        });
      })
      .catch((json) => {
        const rowUiDataProps = {
          getDataResponse: [],
          columnData: columns,
          columnIndex: 0,
          dataLimit: selections.dataLimit,
          apiResponseLength: 0,
          errMessage: json.error,
        };
        const finalData = rowUiDataFriendly(rowUiDataProps);
        setPivotDataTotal({
          result: finalData,
          status: "success",
          message: "",
        });
        console.groupCollapsed("UI ERROR USE-EFFECT FOR TOTAL API CALL");
        console.log(json);
        console.groupEnd();
      });
    return () => {
      //Cancel all previous fetch calls
      if (source1) source1.cancel();
      if (source) source.cancel();
    };
  }, []);

  // Pass data to parent whenever data changes
  useEffect(() => {
    onDataChange(pivotData);
  }, [pivotData]);

  const columns = getColumn(dimensionsList, metricsList);
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const actualColumns = columns[0];

  const TableHeader = (props = {}) => {
    // * Destructure props
    const { head = {}, index = 0 } = props;

    // * Define required states
    const [mouseIn, setMouseIn] = useState(false);

    // * Define event handlers
    const handleMouseEnter = () => setMouseIn(true);
    const handleMouseLeave = () => setMouseIn(false);
    const handleMetricRowClick = () => {
      const payload = {
        sortedOn: head.id,
        orderBy: orderBy === "asc" ? "desc" : "asc",
      };
      let action = updatePivotAdvanceSort(payload);
      dispatchPivotState(action);

      action = updatePivotReloadEpoch();
      dispatchPivotState(action);
    };

    // * Define required variables
    const { isAllDimSortedOnThisMetric, orderBy } = getSortDetails(
      head,
      advanceSort
    );
    let className = classes.tableCellHeader;
    if (index === 0) className += ` ${classes.sticky}`;
    const style = {
      zIndex: `${head.name === "Dimension" ? 10 : 0}`,
    };
    const arrowType = orderBy === "asc" ? "arrow_drop_up" : "arrow_drop_down";

    return (
      <>
        <TableCell
          className={className}
          style={style}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              style={{
                maxWidth: "20px",
                minWidth: "20px",
              }}
            ></span>
            <span
              // className={classes.tableHeadTitle}
              title={head.name}
              style={{
                maxWidth: "70px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                marginRight: "3px",
              }}
            >
              {head.name}
            </span>
            <span
              style={{
                maxWidth: "20px",
                minWidth: "20px",
              }}
            >
              {isAllDimSortedOnThisMetric && index !== 0 && (
                <SigviewIcon
                  iconName={arrowType}
                  style={{
                    fontSize: "20px !important",
                    // padding: "0px 5px 0px 0px",
                    color: themeColors["primaryColor"],
                    hoverColor: themeColors["primaryColor"],
                    cursor: "pointer",
                  }}
                  onClick={handleMetricRowClick}
                />
              )}
              {!isAllDimSortedOnThisMetric && index !== 0 && mouseIn && (
                <SigviewIcon
                  iconName={arrowType}
                  style={{
                    fontSize: "20px !important",
                    // padding: "0px 5px 0px 0px",
                    color: themeColors["primaryColor"],
                    hoverColor: themeColors["primaryColor"],
                    cursor: "pointer",
                  }}
                  onClick={handleMetricRowClick}
                />
              )}
            </span>

            {/* <Box
              css={{
                visibility: mouseIn ? "visible" : "hidden",
                display: "flex",
                alignItems: "center",
                width: mouseIn ? "max-content" : "0px",
              }}
            >
              <SigviewIcon
                // className={filterIconMuiClassName}
                iconName={arrowType}
                style={{
                  fontSize: "16px !important",
                  // padding: "0px 5px 0px 0px",
                  color: themeColors["primaryColor"],
                  hoverColor: themeColors["primaryColor"],
                  cursor: "pointer",
                }}
                onClick={handleMetricRowClick}
              />
            </Box> */}
          </span>
        </TableCell>
      </>
    );
  };

  return (
    <Box css={{ height: "100%", overflowX: "auto", boxSizing: "border-box" }}>
      {pivotData.status === "loading" && <Loader />}
      {pivotData.status === "success" && (
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableRowHeader}>
              {actualColumns.map((head, index) => (
                <TableHeader key={index} head={head} index={index} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pivotDataTotal.status === "loading" && <SigviewProgressBar />}
            {pivotDataTotal.status === "success" && (
              <PivotTableTotalRow
                row={pivotDataTotal.result[0]}
                columns={columns}
                setPivotDataTotal={setPivotDataTotal}
                selections={selections}
                user={user}
                allData={allData}
                globalFilters={globalFilters}
              />
            )}
            {pivotData.result.map((row, index) => (
              <PivotTableRow
                key={index}
                row={row}
                columns={columns}
                pivotData={pivotData}
                setPivotData={setPivotData}
                selections={selections}
                user={user}
                allData={allData}
                globalFilters={globalFilters}
                totalLength={pivotData.result.length}
                rowIndex={index}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(PivotTable);
