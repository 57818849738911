// Import required libraries
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Import custom components

// Import styles

// Import config

// Import action creators

// Import utils

function ClientLogo(props) {
  const { user } = props;

  const status = user?.reqMetadata ? "success" : "loading";
  const logoUrl =
    status === "success"
      ? "https://logo.sigmoid.io/" +
        user?.reqMetadata?.organization?.toLowerCase() +
        "/" +
        user?.reqMetadata?.organization?.toLowerCase() +
        "-" +
        user?.reqMetadata?.view?.toLowerCase() +
        ".png"
      : "";

  const logoStyle = {
    backgroundImage: `url("${logoUrl}")`,
    backgroundSize: "90%",
  };

  return (
    <div className="client-logo-container">
      <div className="client-logo" style={logoStyle}></div>
    </div>
  );
}

ClientLogo.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ClientLogo);
