// * Import required libraries
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";
import Dialog from "@material-ui/core/Dialog";

import { useContext } from "react";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Import custom components
import Loader from "../Loader/Loader";
import SigviewSingleSelect from "../Common/SigviewSingleSelect";
import SigviewTooltip from "../Common/SigviewTooltip";
import SigviewIcon from "../Common/SigviewIcon";
import OverlayContainer from "./OverlayContainer";

// * Import action creators
import { updateUserScreen, updateChangeFlag } from "../../redux/actions";
import {
  getActiveViewObjFromViewsArr,
  getActiveOrgObject,
} from "../../utils/utils";

// * Import API functions
import { updateUserView } from "../../services/api";
import { masterTrackGaEvent } from "../../services/ga";

// Import styles
import "./Overlay.scss";
import SigviewTypography from "../Common/SigviewTypography";

function Overlay(props) {
  const { dispatch: ReduxDispatcher, user, changeFlag } = props;
  const [error, setError] = useState(false);
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  const handleOpenOverlay = () => {
    masterTrackGaEvent({
      category: "Menubar",
      action: "FeatureOverlay",
      label: "1",
    });
    const payload = {
      isOpen: true,
      children: <OverlayContainer />,
    };
    const action = updateUserScreen("overlay", payload);
    ReduxDispatcher(action);
  };

  return (
    <section className={`view-container`} style={{ width: "60px" }}>
      {/* <span class="material-symbols-outlined">star</span> */}

      <SigviewTooltip title={"Click to view latest updates"} placement="bottom">
        <SigviewTypography
          customClassName="OverlayClick-GA"
          variant="pSmallMedium"
          onClick={handleOpenOverlay}
          style={{
            color: themeColors["primaryColor"],
            hoverColor: themeColors["primaryColor"],
            cursor: "pointer",
            height: "11px",
          }}
        >
          What's New
        </SigviewTypography>
        {/* <span>
          <SigviewIcon
            className={`material-icons-round`}
            iconName="star"
            onClick={handleOpenOverlay}
            style={{
              fontSize: "16px !important",
              color: themeColors["primaryColor"],
              hoverColor: themeColors["primaryColor"],
              cursor: "pointer",
            }}
          />
        </span> */}
      </SigviewTooltip>
    </section>
  );
}

Overlay.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
});

export default connect(mapStateToProps)(Overlay);
