// * Import required libraries
import React, { useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Import custom components
import SigviewIcon from "../../components/Common/SigviewIcon";
import SigviewTooltip from "../../components/Common/SigviewTooltip";
import { masterTrackGaEvent } from "../../services/ga";

// * Import utils
import { isDateRangeChange } from "../../utils/utils";

// * Import action creators
import { updateChangeFlag, updateUserScreen } from "../../redux/actions";

function DataLagReload(props) {
  const { dispatch: ReduxDispatcher, user, allData, changeFlag } = props;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const pathname = window.location.pathname;
  const rootPage = pathname.split("/")[1]; // 0th element is empty, 1st element is the root page

  // * Define required event handlers
  const handleDataLagReload = () => {
    if (isDateRangeChange(allData)) {
      //Show loader on the entire page
      ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
      ReduxDispatcher(updateChangeFlag("view", !changeFlag.view));
      masterTrackGaEvent({
        category: "Menubar",
        action: "DashboardReload",
        label: "1",
      });
    }
  };

  // * Define required static variable
  const tooltipTitle = isDateRangeChange(allData)
    ? "Refresh to get the latest data"
    : "Dashboard is upto date";
  const iconStyle = {
    fontSize: "15px !important",
    padding: "1px 2px",
    color: isDateRangeChange(allData)
      ? themeColors["primaryColor"]
      : themeColors["secondaryColorLighter"],
    hoverColor: isDateRangeChange(allData)
      ? themeColors["primaryColor"]
      : themeColors["secondaryColorLighter"],
    cursor: isDateRangeChange(allData) ? "pointer" : "not-allowed",
    border: isDateRangeChange(allData)
      ? "1px solid" + themeColors["primaryColor"]
      : "1px solid" + themeColors["secondaryColorLighter"],
    borderRadius: "3px",
    fontWeight: "bold",
  };

  return (
    <>
      <SigviewTooltip title={tooltipTitle} placement="bottom">
        <Box>
          <SigviewIcon
            className={`${
              isDateRangeChange(allData)
                ? `material-icons-round ${rootPage}DataLagReloadClick-GA`
                : "material-icons-round"
            }`}
            onClick={handleDataLagReload}
            iconName="sync"
            style={iconStyle}
          />
        </Box>
      </SigviewTooltip>
    </>
  );
}

DataLagReload.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  changeFlag: state.changeFlag,
});

export default connect(mapStateToProps)(DataLagReload);
