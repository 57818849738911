// * Import required libraries
import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef,
} from "react";
import axios from "axios";
import { connect } from "react-redux";
import isEqual from "lodash.isequal";
import orderBy from "lodash/orderBy";
import { useHistory, useLocation } from "react-router";

// * Import lib components
import { Box } from "@material-ui/core";

// Import Components
import LayoutTopSideBottomAdmin from "../../../layouts/LayoutTopSideBottomAdmin/LayoutTopSideBottomAdmin";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import SigviewTabAws from "../../../components/SigviewTabAws";

import SigviewCheckList from "../../../components/SigviewChecklistAws";
import SigviewTextField from "../../../components/Common/SigviewTextField";
import SigviewNav from "../../../components/Common/SigviewNav";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SigviewButton from "../../../components/Common/SigviewButton";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import { config } from "../../../config/config";
import DimensionFilters from "../../../components/DimensionFilters";

// Import Layouts
import LayoutAdminContent from "../../../layouts/LayoutAdminContent";
import LayoutRow from "../../../layouts/LayoutRow";

// Import Context
import { ThemeContext } from "../../../contexts/ThemeContext";
import { UserProvider } from "../../../contexts/AdminContext";

// Reducers
import timeFiltersReducer from "../../../../js/redux/reducers/timeFilters";

// Import utils/data
import {
  updateDialogInfo,
  updateTimeFiltersWithDefaultDates,
  updateUserScreen,
} from "../../../redux/actions";
import {
  transformDimensionsForPlotly,
  transformMetricsForPlotly,
  capitalize,
  getBreadcrumbsDataFromRoute,
} from "../../../utils/utils";
import {
  validateUserAws,
  wrapperUserAws,
  wrapperUserAwsCreate,
  unwrapperTabViews,
  unwrapperUserAws,
  initialUserSelection,
  transformToChecklistData,
} from "../../../utils/adminAwsUtils";
import { transformFiltersBackendToUi } from "../../../utils/filtersUtils";

import useReducerLogger from "../../../utils/useReducerLogger";
import { updateAdminForm, replaceAdminForm } from "../../../redux/actions";
import { adminUser } from "../../../redux/reducers/adminAws";
import { unWrapperviews, isSelectionsInvalid } from "../../../utils/adminUtils";
import { masterTrackGaEvent } from "../../../../js/services/ga/index";
import allDatePresets from "../../../../assets/data/allDatePresets.json";
import allTimezones from "../../../../assets/data/allTimezones.json";

// * Import APIs
import {
  readUserAwsById,
  updateUserAws,
  createUserAws,
  sendEmailVerificationLink,
} from "../../../services/api";

import SigviewTextFieldAsync2 from "../../../components/Common/SigviewTextFieldAsync2";

const views = (view, id) => {
  view.map((row) => {
    if ((row.id = id)) {
      return row.dimensions.value;
    }
  });
};

const sigviewNavData = [
  { id: "accessList", name: "Access List", disabled: false },
];
const defaultActiveView = {
  id: "view1",
  name: "View 1",
  subTitle: "",
  disabled: false,
  metricData: [],
  DimensionData: [],
  customMetricData: [],
};

function User(props) {
  // * Destructure props
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const { dispatch: ReduxDispatcher, user, allData = {} } = props;
  const { views: viewsData } = allData;

  const originalSelections = useRef({});

  // ! Fuc on the basics of curd type we will make the selection state
  // * Define required states
  const [selections, dispatchSelections] = useReducer(
    useReducerLogger(adminUser),
    initialUserSelection
  );

  // * Define contexts
  const history = useHistory();
  const location = useLocation();

  const [userData, setUserData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });

  // Define Validity of form
  const [emailvalidationResponse, setEmailvalidationResponse] = useState("");
  const [tabViewsData, setTabViewsData] = useState(
    unwrapperTabViews(allData.viewGroupList) || []
  );

  // * Define required side effects
  // ! Fetch user Data
  useEffect(() => {
    if (user.screen?.activeAdminUser?.metadata?.crudType === "edit") {
      // Make fetch call using axios
      const source = axios.CancelToken.source();
      const fetchProps = {
        payload: {
          email: user.screen.activeAdminUser.payload.emailId,
          orgViewList: [
            {
              organization: user.reqMetadata.organization,
              view: user.screen.activeAdminUser.metadata.view,
            },
          ],
        },
        cancelToken: source.token,
      };
      const readUserId = readUserAwsById(fetchProps);
      readUserId
        .then((data) => {
          setUserData({
            result: data?.result?.data,
            status: "success",
            messsage: "",
          });
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            const message = json.error || config.messages.uiErrorMessage;
            setUserData({
              result: [],
              status: "error",
              messsage: "API failed",
            });

            console.groupCollapsed("UI ERROR");
            console.log("JSON", json);
            console.log("ERROR ->", message);
            console.groupEnd();
          }
        });
    }
    if (user.screen?.activeAdminUser?.metadata?.crudType === "create") {
      setUserData({
        // result: viewsDataFromApiSample,
        result: selections,
        status: "success",
        messsage: "",
      });
    }
  }, []);

  // ! Replace selectiom when user data is success
  useEffect(() => {
    if (
      userData.status === "success" &&
      user.screen?.activeAdminUser?.metadata?.crudType === "edit"
    ) {
      const payload = {
        value: unwrapperUserAws(userData.result, allData),
      };
      // setDefaultEmail(payload?.value?.email?.value);
      const action = replaceAdminForm(payload);
      // console.log("action", action);
      dispatchSelections(action);
    }
    if (
      userData.status === "success" &&
      user.screen?.activeAdminUser?.metadata?.crudType === "create"
    ) {
      let defaultView = allData.viewGroupList.result[0].view;
      let defaultGroup = {};

      for (let i = 0; i < allData.viewGroupList.result.length; i++) {
        defaultGroup[allData.viewGroupList.result[i].view] =
          allData.viewGroupList.result[i].groupInfo[0].groupId;
      }

      const payload = {
        value: {
          ...userData.result,
          activeView: {
            message: "",
            value: {
              id: defaultView,
            },
            status: "valid",
          },
          selectedGroup: {
            message: "",
            value: defaultGroup,

            status: "valid",
          },
        },
      };

      const action = replaceAdminForm(payload);
      dispatchSelections(action);
      // }
    }
  }, [userData]);

  // UseEffect for selection in checklist Changes
  useEffect(() => {
    const getCustomSelection = (activeView, activeGroup) => {
      let activeViewInfo = allData.viewGroupList.result.find(
        (row) => row.view === activeView
      );
      let activeGroupInfo = activeViewInfo.groupInfo.find(
        (row) => row.groupId === activeGroup
      );

      return {
        visibleDimensionList: activeGroupInfo.dimensionList
          .map((row) => {
            if (row.status === true) {
              return row._id;
            }
          })
          .filter((el) => el !== undefined),
        visibleCustomMetricList: activeGroupInfo.customMetricList
          .map((row) => {
            if (row.status === true) {
              return row._id;
            }
          })
          .filter((el) => el !== undefined),
        visibleMetricList: activeGroupInfo.metricList
          .map((row) => {
            if (row.status === true) {
              return row._id;
            }
          })
          .filter((el) => el !== undefined),
      };
    };

    if (
      user.screen.activeAdminUser.metadata.crudType === "edit" &&
      userData.status === "success"
    ) {
      let activeViewInfo = userData.result.orgInfoList[0].viewInfoList.find(
        (row) => row.name === selections.activeView.value.id
      );

      let activeGroupInfo = activeViewInfo.groupInfoList.find(
        (row) =>
          row.groupId ===
          selections.selectedGroup.value[selections.activeView.value.id]
      );

      if (activeGroupInfo === undefined) {
        activeGroupInfo = getCustomSelection(
          selections.activeView.value.id,
          selections.selectedGroup.value[selections.activeView.value.id]
        );
      }

      let value = {};

      for (const key in selections.views.value) {
        value[key] = {
          groupId: selections.views.value[key].groupId,

          dimensions: selections.views.value[key].dimensions.map((row) => {
            if (row.id === undefined) return { id: row, status: true };
            else {
              return row;
            }
          }),
          metrics: selections.views.value[key].metrics.map((row) => {
            if (row.id === undefined) return { id: row, status: true };
            else {
              return row;
            }
          }),
          customMetrics: selections.views.value[key].customMetrics.map(
            (row) => {
              if (row.id === undefined) return { id: row, status: true };
              else {
                return row;
              }
            }
          ),
          userFilters: selections.views.value[key].userFilters,
        };
      }

      const payload = {
        key: "views",
        value,
      };
      let action = updateAdminForm(payload);
      dispatchSelections(action);

      originalSelections.current = {
        ...selections,
        views: { ...selections.views, value },
      };

      let newActiveAdminUser = {
        payload: {},
        metadata: {
          crudType: "existing",
          name: user.screen.activeAdminUser?.metadata?.name,
        },
      };
      action = updateUserScreen("activeAdminUser", newActiveAdminUser);
      ReduxDispatcher(action);
    }
    if (
      user.screen.activeAdminUser.metadata.crudType === "create" &&
      userData.status === "success"
    ) {
      let value1 = {};

      for (const key in selections.selectedGroup.value) {
        let activeGroupInfo = getCustomSelection(
          key,
          selections.selectedGroup.value[key]
        );

        value1[key] = {
          groupId: selections.selectedGroup.value[key],
          dimensions: activeGroupInfo.visibleDimensionList.map((row) => {
            return {
              id: row,
              status: true,
            };
          }),
          metrics: activeGroupInfo.visibleMetricList.map((row) => {
            return {
              id: row,
              status: true,
            };
          }),
          customMetrics: activeGroupInfo.visibleCustomMetricList.map((row) => {
            return {
              id: row,
              status: true,
            };
          }),

          userFilters: [],
        };
      }

      const payload = {
        key: "views",
        value: value1,
      };
      let action = updateAdminForm(payload);
      dispatchSelections(action);

      originalSelections.current = {
        ...selections,
        views: { ...selections.views, value: value1 },
      };

      let newActiveAdminUser = {
        payload: {},
        metadata: {
          crudType: "new",
          name: "Create New User",
        },
      };
      action = updateUserScreen("activeAdminUser", newActiveAdminUser);
      ReduxDispatcher(action);
    }
  }, [selections.selectedGroup.value]);

  const handleDimensionChange = (newValue) => {
    // reducer dispatch action with value

    let newdata;
    const newSelections = { ...selections };
    const allViewsValue = newSelections.views.value;
    const activeViewValue = newSelections.activeView.value.id;
    // Is clicked metric already present
    const presentFlag = allViewsValue[activeViewValue].dimensions.find(
      (row) => newValue.id === row.id
    );
    if (presentFlag) {
      newdata = allViewsValue[activeViewValue].dimensions.filter(
        (row) => newValue.id !== row.id
      );
    } else {
      newdata = [
        ...allViewsValue[activeViewValue].dimensions,
        { id: newValue.id, status: true },
      ];

      newdata = orderBy(newdata, ["id"], ["asc"]);
    }

    // let newViews = { ...allViewsValue, dimensions: newdata };

    let newViews = {
      ...allViewsValue,
      [activeViewValue]: {
        ...allViewsValue[activeViewValue],
        dimensions: newdata,
      },
    };

    const payload = {
      key: "views",
      value: newViews,
    };

    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };

  const handleMetricChange = (newValue) => {
    // reducer dispatch action with value

    let newdata;
    const newSelections = { ...selections };
    const allViewsValue = newSelections.views.value;
    const activeViewValue = newSelections.activeView.value.id;
    // Is clicked metric already present
    const presentFlag = allViewsValue[activeViewValue].metrics.find(
      (row) => newValue.id === row.id
    );
    if (presentFlag) {
      newdata = allViewsValue[activeViewValue].metrics.filter(
        (row) => newValue.id !== row.id
      );
    } else {
      newdata = [
        ...allViewsValue[activeViewValue].metrics,
        { id: newValue.id, status: true },
      ];

      newdata = orderBy(newdata, ["id"], ["asc"]);
    }

    // let newViews = { ...allViewsValue, metrics: newdata };

    let newViews = {
      ...allViewsValue,
      [activeViewValue]: {
        ...allViewsValue[activeViewValue],
        metrics: newdata,
      },
    };

    const payload = {
      key: "views",
      value: newViews,
    };

    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };

  const handleCustomMetricChange = (newValue) => {
    // reducer dispatch action with value

    let newdata;
    const newSelections = { ...selections };
    const allViewsValue = newSelections.views.value;
    const activeViewValue = newSelections.activeView.value.id;
    // Is clicked metric already present
    const presentFlag = allViewsValue[activeViewValue].customMetrics.find(
      (row) => newValue.id === row.id
    );
    if (presentFlag) {
      newdata = allViewsValue[activeViewValue].customMetrics.filter(
        (row) => newValue.id !== row.id
      );
    } else {
      newdata = [
        ...allViewsValue[activeViewValue].customMetrics,
        { id: newValue.id, status: true },
      ];

      newdata = orderBy(newdata, ["id"], ["asc"]);
    }

    let newViews = {
      ...allViewsValue,
      [activeViewValue]: {
        ...allViewsValue[activeViewValue],
        customMetrics: newdata,
      },
    };
    const payload = {
      key: "views",
      value: newViews,
    };

    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleCancel = () => {
    // update screen
    history.push("/admin/users");
  };
  const handleSave = () => {
    // wrapper user will come here
    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: "Requesting to save user...",
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    let payload = wrapperUserAwsCreate(
      { ...selections },
      user.reqMetadata,
      viewsData
    );

    let source = axios.CancelToken.source();
    let fetchProps = {
      cancelToken: source.token,
      payload,
    };
    const createUserAwsPromise = createUserAws(fetchProps);
    createUserAwsPromise
      .then(() => {
        source = axios.CancelToken.source();
        fetchProps = {
          cancelToken: source.token,
          payload: {
            domain: window.location.hostname,
            email: selections.email.value,
          },
        };
        const sendEmailVerificationLinkPromise =
          sendEmailVerificationLink(fetchProps);
        sendEmailVerificationLinkPromise
          .then((data) => {
            //Update snackbar

            let snackbarPayload = {
              ...user.screen.snackbar,
              open: false,
              message: `User saved successfully and Verification Link has been sent to ${selections.email.value}`,
            };
            ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

            history.push("/admin/users");
          })
          .catch((json) => {
            if (json.error !== config.hardCoded.queryCancelled) {
              const message = json.error || config.messages.uiErrorMessage;

              console.groupCollapsed("UI ERROR");
              console.log("JSON", json);
              console.log("ERROR ->", message);
              console.groupEnd();
            }
          });
      })
      .catch((json) => {
        if (json.error !== config.hardCoded.queryCancelled) {
          const message = json.error || config.messages.uiErrorMessage;
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: json.error || "Updating User Failed",
          };
          ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

          console.groupCollapsed("UI ERROR");
          console.log("JSON", json);
          console.log("ERROR ->", message);
          console.groupEnd();
        }
      });
  };
  const handleUpdate = () => {
    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: "Requesting to update user...",
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    const payload = wrapperUserAws(
      { ...selections },
      user.reqMetadata,
      viewsData
    );
    // console.log("payload(user)", payload);
    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
      payload,
    };

    const updateUserAwsPromise = updateUserAws(fetchProps);
    updateUserAwsPromise
      .then(() => {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: false,
          message: "",
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        history.push("/admin/users");
      })
      .catch((json) => {
        if (json.error !== config.hardCoded.queryCancelled) {
          const message = json.error || config.messages.uiErrorMessage;
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: json.error || "Updating User Failed",
          };
          ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

          console.groupCollapsed("UI ERROR");
          console.log("JSON", json);
          console.log("ERROR ->", message);
          console.groupEnd();
        }
      });
  };

  const handleActiveTab = (e, id) => {
    //Return to home (will be controlled by router later)

    // Google Analytics Event - Master Admin
    masterTrackGaEvent({
      category: "Admin",
      action: `${capitalize(id)}Open`,
      label: "Breadcrumb",
    });

    if (id === "home") {
      history.push("/admin");
    }

    // console.log("handleActiveTab", e, id);
    // const activeTab = id;
    // const value = { activeNav: "admin", activeTab };
    // const action = updateUserScreen(null, value);
    // ReduxDispatcher(action);
  };
  // * Define required static variables for props
  const activeTab = user.screen.activeTab;

  const breadcrumbsData = getBreadcrumbsDataFromRoute(user, history);
  const layoutTopSideBottomAdminProps = {
    activeTab,
    handleActiveTab,
    variant: "AdminAws",
  };

  const handleBreadcrumbChange = (event, value) => {
    history.push(value.path);
  };

  const handleFirstNameChange = (value) => {
    const payload = {
      key: "firstName",
      value,
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleLastNameChange = (value) => {
    const payload = {
      key: "lastName",
      value,
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleEmailChange = (value) => {
    const payload = {
      key: "email",
      value,
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };

  const handleViewChange = (e, value) => {
    const payload = {
      key: "activeView",
      value,
      value: { id: value.id },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleSelectedView = (e, newValue) => {
    let newdata;

    const newSelections = { ...selections };
    const newSelectedValue = newSelections.selectedView.value;

    const presentFlag = newSelectedValue.find((row) => newValue.id === row.id);

    if (presentFlag) {
      newdata = newSelectedValue.filter((row) => newValue.id !== row.id);
    } else {
      newdata = [
        ...newSelectedValue,
        {
          id: newValue.id,
          title: newValue.id,
        },
      ];

      newdata = orderBy(newdata, ["id"], ["asc"]);
    }

    // reducer dispatch action with value
    const payload = {
      key: "selectedView",
      value: newdata,
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };

  const updateStateForGroupChange = (item, value) => {
    // reducer dispatch action with value

    let payload = {
      key: "selectedGroup",
      value: { item, value },
    };
    let action = updateAdminForm(payload);
    dispatchSelections(action);

    let customViews = (allData, item, value) => {
      allData.viewGroupList.result.forEach((row) => {
        if (row.view === item) {
          row.groupInfo.forEach((group) => {
            if (group.groupId === value) {
              modifiedView[item] = {
                groupId: group.groupId,

                dimensions: group.dimensionList
                  .map((item1) => {
                    if (item1.status === true) {
                      return {
                        id: item1._id,
                        status: true,
                      };
                    }
                  })
                  .filter((element) => element !== undefined),

                metrics: group.metricList
                  .map((item1) => {
                    if (item1.status === true) {
                      return {
                        id: item1._id,
                        status: true,
                      };
                    }
                  })
                  .filter((element) => element !== undefined),

                customMetrics: group.customMetricList
                  .map((item1) => {
                    if (item1.status === true) {
                      return {
                        id: item1._id,
                        status: true,
                      };
                    }
                  })
                  .filter((element) => element !== undefined),

                userFilters: [],
              };
            }
          });
        }
      });
    };

    var modifiedView = {};

    if (user.screen.activeAdminUser.metadata.crudType === "existing") {
      let userSelectedViewIds = userData.result.orgInfoList[0].viewInfoList.map(
        (row) => row.name
      );

      if (userSelectedViewIds.includes(item)) {
        let userSelectedGroup = {};
        userData.result.orgInfoList[0].viewInfoList.forEach((row) => {
          if (item === row.name) {
            userSelectedGroup = row.groupInfoList[0];
          }
        });

        if (userSelectedGroup.groupId === value) {
          modifiedView[item] = {
            groupId: userSelectedGroup.groupId,
            dimensions: userSelectedGroup.visibleDimensionList
              .map((row) => {
                return {
                  id: row,
                  status: true,
                };
              })
              .filter((element) => element !== undefined),
            metrics: userSelectedGroup.visibleMetricList
              .map((row) => {
                return {
                  id: row,
                  status: true,
                };
              })
              .filter((element) => element !== undefined),

            customMetrics: userSelectedGroup.visibleCustomMetricList
              .map((row) => {
                return {
                  id: row,
                  status: true,
                };
              })
              .filter((element) => element !== undefined),

            userFilters: [],
          };
        } else {
          customViews(allData, item, value);
        }
      } else {
        customViews(allData, item, value);
      }
    }

    if (user.screen.activeAdminUser.metadata.crudType === "new") {
      customViews(allData, item, value);
    }

    let dispatchableView = { ...selections.views.value };

    dispatchableView[item] = { ...modifiedView[item] };

    payload = {
      key: "views",
      value: dispatchableView,
    };
    action = updateAdminForm(payload);
    dispatchSelections(action);
  };

  const handleSelectedGroup = (item, value) => {
    const message = {
      title: `Your selected security filters for '${item}' view will not be saved. Do you wish to continue?`,
      subtitle: "",
    };

    const handleNo = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
        })
      );
    };
    const handleYes = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
          message: { title: "", subtitle: "" },
        })
      );
      updateStateForGroupChange(item, value);
    };

    if (selections.views.value[item].userFilters.length > 0) {
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: true,
          message,
          handleYes,
          handleNo,
        })
      );
    } else {
      updateStateForGroupChange(item, value);
    }
  };
  const handleDimensionFiltersChange = (value) => {
    // console.log("NEW DIMENSION FILTERS", value);
    // TODO
    const payload = {
      key: "userFilters",
      value: {
        newdata: value,
        activeViewId: selections.activeView.value.id,
      },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };

  const handleClearDimension = () => {
    const newSelections = { ...selections };
    const allViewsValue = newSelections.views.value;
    const activeViewValue = newSelections.activeView.value.id;

    let activeDefaultView = allData.viewGroupList.result.find(
      (el) => el.view === activeViewValue
    );

    let activeDeafaultGroup = activeDefaultView.groupInfo.find(
      (el) => el.groupId === selections.selectedGroup.value[activeViewValue]
    );

    let newdata = activeDeafaultGroup.dimensionList
      .map((row) => {
        if (["D001", "D002", "D003", "D150"].includes(row._id)) {
          return {
            id: row._id,
            status: true,
          };
        }
      })
      .filter((el) => el !== undefined);

    let newViews = {
      ...allViewsValue,
      [activeViewValue]: {
        ...allViewsValue[activeViewValue],
        dimensions: newdata,
      },
    };

    const payload = {
      key: "views",
      value: newViews,
    };

    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleClearMetric = () => {
    const newSelections = { ...selections };
    const allViewsValue = newSelections.views.value;
    const activeViewValue = newSelections.activeView.value.id;

    // let newViews = { ...allViewsValue, metrics: [] };

    let newViews = {
      ...allViewsValue,
      [activeViewValue]: {
        ...allViewsValue[activeViewValue],
        metrics: [],
      },
    };

    const payload = {
      key: "views",
      value: newViews,
    };

    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleClearCustomMetric = () => {
    const newSelections = { ...selections };
    const allViewsValue = newSelections.views.value;
    const activeViewValue = newSelections.activeView.value.id;

    // let newViews = { ...allViewsValue, customMetrics: [] };

    let newViews = {
      ...allViewsValue,
      [activeViewValue]: {
        ...allViewsValue[activeViewValue],
        customMetrics: [],
      },
    };

    const payload = {
      key: "views",
      value: newViews,
    };

    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };

  const viewsDataUi = unWrapperviews(viewsData.result);

  const handleSelectAllDimension = () => {
    const newSelections = { ...selections };
    const allViewsValue = newSelections.views.value;
    const activeViewValue = newSelections.activeView.value.id;

    let activeDefaultView = allData.viewGroupList.result.find(
      (el) => el.view === activeViewValue
    );

    let activeDeafaultGroup = activeDefaultView.groupInfo.find(
      (el) => el.groupId === selections.selectedGroup.value[activeViewValue]
    );

    let newdata = activeDeafaultGroup.dimensionList.map((row) => {
      return {
        id: row._id,
        status: true,
      };
    });

    let newViews = {
      ...allViewsValue,
      [activeViewValue]: {
        ...allViewsValue[activeViewValue],
        dimensions: newdata.filter((row) => !["D003"].includes(row.id)),
      },
    };

    const payload = {
      key: "views",
      value: newViews,
    };

    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleSelectAllMetric = () => {
    const newSelections = { ...selections };
    const allViewsValue = newSelections.views.value;
    const activeViewValue = newSelections.activeView.value.id;

    let activeDefaultView = allData.viewGroupList.result.find(
      (el) => el.view === activeViewValue
    );

    let activeDeafaultGroup = activeDefaultView.groupInfo.find(
      (el) => el.groupId === selections.selectedGroup.value[activeViewValue]
    );

    let newdata = activeDeafaultGroup.metricList
      .map((row) => {
        return {
          id: row._id,
          status: true,
        };
      })
      .filter((el) => el !== undefined);

    let newViews = {
      ...allViewsValue,
      [activeViewValue]: {
        ...allViewsValue[activeViewValue],
        metrics: newdata,
      },
    };

    const payload = {
      key: "views",
      value: newViews,
    };

    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleSelectAllCustomMetric = () => {
    const newSelections = { ...selections };
    const allViewsValue = newSelections.views.value;
    const activeViewValue = newSelections.activeView.value.id;

    let activeDefaultView = allData.viewGroupList.result.find(
      (el) => el.view === activeViewValue
    );

    let activeDeafaultGroup = activeDefaultView.groupInfo.find(
      (el) => el.groupId === selections.selectedGroup.value[activeViewValue]
    );

    let newdata = activeDeafaultGroup.customMetricList
      .map((row) => {
        return {
          id: row._id,
          status: true,
        };
      })
      .filter((el) => el !== undefined);

    let newViews = {
      ...allViewsValue,
      [activeViewValue]: {
        ...allViewsValue[activeViewValue],
        customMetrics: newdata,
      },
    };

    const payload = {
      key: "views",
      value: newViews,
    };

    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };

  const activeViewId = selections.activeView.value.id;
  const activeView =
    viewsDataUi.find((row) => row.id === activeViewId) || defaultActiveView;

  const getAllDataStatus = (allData = []) => {
    const areAllSuccess = allData.every((data) => data.status === "success");
    const isAnyError = allData.some((data) => data.status === "error");
    let allVendorDataStatus = "loading";
    if (areAllSuccess) allVendorDataStatus = "success";
    if (isAnyError) allVendorDataStatus = "error";
    return allVendorDataStatus; //loading, success, error
  };

  const finalStatus = getAllDataStatus([
    userData,

    viewsData,
    allData.viewGroupList,
    allData.allDateRange,
  ]);
  // console.groupCollapsed("UI ERROR");
  // console.log(finalStatus);
  // console.log("userData", userData.status);
  // console.log("groupData", groupData.status);
  // console.log("groupsData", groupsData.status);
  // console.groupEnd();

  // console.log("emailValidation", emailValidation, isValid);

  // Making Dimension Filters Data
  const activeGroupInfo =
    allData.viewGroupList.result.filter(
      (row) => row.view === selections.activeView.value.id
    )[0] || [];

  const activeViewData =
    activeGroupInfo?.groupInfo?.filter(
      (el) =>
        el.groupId ===
        selections.selectedGroup.value[selections.activeView.value.id]
    )[0] || [];

  var filtersDimData = [...allData.plotlyDimensions];
  if (activeViewData)
    filtersDimData = transformDimensionsForPlotly(activeViewData.dimensionList);
  var filtersMetricsData = [...allData.plotlyMetrics];
  if (activeViewData)
    filtersMetricsData = transformMetricsForPlotly(
      activeViewData.metricList,
      activeViewData.customMetricList
    );

  let validatedSelections = validateUserAws(selections);
  const isValid = isSelectionsInvalid(validatedSelections).status === "valid";
  const areSelectionsEqual = isEqual(originalSelections.current, selections);
  const isUpdateDisabled = areSelectionsEqual ? true : !isValid ? true : false;

  const isFirstNameEqual = isEqual(
    originalSelections.current?.firstName?.value,
    selections.firstName.value
  );

  // Styles
  const formTitleStyling = {
    // height: "max-content",
    padding: "0px 10px 0px 0px",
  };
  const attributeTitleStyling = {
    height: "max-content",
    padding: "7px 0px 7px 15px",
  };
  const box1Style = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  };

  const userFiltersSettings = {
    isReadOnly: false,
    isAdvancedFilterVisible: false,
    isExcludeAllowed: false,
    addFilterTitle: "Click to Add User Filters",
    rowTitle:
      selections.views.value[selections.activeView.value.id]?.userFilters
        ?.length === 0
        ? "User Security Filters:"
        : "User Security Filters:",
  };

  let viewDataInChecklist =
    allData.viewGroupList.result.find(
      (row) => row.view === selections?.activeView?.value?.id
    ) || [];

  let groupDataInChecklist =
    viewDataInChecklist?.groupInfo?.find(
      (row) =>
        row.groupId ===
        selections.selectedGroup.value[selections.activeView.value.id]
    ) ||
    // viewDataInChecklist?.groupInfo[0] ||
    [];

  let groupFilters =
    transformFiltersBackendToUi(
      groupDataInChecklist?.groupFilter || [],
      allData.plotlyDimensions
    ) || [];

  const groupFiltersSettings = {
    isReadOnly: true,
    isAdvancedFilterVisible: false,
    isExcludeAllowed: false,
    rowTitle:
      groupFilters.length === 0
        ? "No Group Security Filters Applied"
        : "Group Security Filters:",
  };

  const timeFiltersAction = updateTimeFiltersWithDefaultDates({
    selectedTimezone: allTimezones[1],

    startDateEpoch:
      allData.allDateRange.data[selections.activeView.value.id]?.value
        .startDate || "0",
    endDateEpoch:
      allData.allDateRange.data[selections.activeView.value.id]?.value
        .endDate || "0",
    allDatePresets,
    format: config.hardCoded.datetimeFormat,
    selectedDatePreset: "last_2_days",
    daysLimit: 90,
    isComparisonOn: false,
  });
  const newTimeFilters = timeFiltersReducer({}, timeFiltersAction);

  const emailStatusValue = (value) => {
    setEmailvalidationResponse(value);
  };

  return (
    <LayoutTopSideBottomAdmin {...layoutTopSideBottomAdminProps}>
      <SigviewBreadcrumb
        data={breadcrumbsData} //data
        onClick={handleBreadcrumbChange} //onClick
      />

      {finalStatus === "loading" && <Loader />}

      {finalStatus === "error" && (
        <ErrorHandler
          message={config.hardCoded.uiErrorMessage}
          reloadFlag={true}
          onReload={() => {}}
        />
      )}
      {finalStatus === "success" && (
        <>
          <LayoutRow
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "50px",
              padding: "0px 25px",
              width: "100%",
              boxSizing: "border-box",
              alignItems: "flex-start",
              paddingTop: "10px",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                alignItems: "baseline",
                width: "300px",
                paddingRight: "10px",
              }}
            >
              <div
                style={{
                  width: "80px",
                  display: "flex",
                  height: "max-content",
                  paddingTop: "5px",
                }}
              >
                <SigviewTypography
                  style={{
                    // ...formTitleStyling,
                    height: "max-content",
                    // width: "30%",
                  }}
                >
                  First Name:
                </SigviewTypography>
                <span
                  style={{
                    color: "red",
                    // transform: "translateX(-10px)",
                    paddingLeft: "3px",
                  }}
                >
                  *
                </span>
              </div>
              <SigviewTextField
                placeholder="First Name"
                value={selections.firstName.value}
                onChange={handleFirstNameChange}
                // maxWidth="200px"
                // minWidth="20px"
                width="200px"
                error={
                  !isFirstNameEqual &&
                  validatedSelections.firstName.status === "invalid"
                }
                helperText={validatedSelections.firstName.message}
                fontSizeHelperText="9px"
              />
            </div>
            <>
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "baseline",
                  width: "300px",
                  paddingRight: "10px",
                }}
              >
                <div
                  style={{
                    width: "80px",
                    display: "inline-block",
                    height: "max-content",
                    paddingTop: "5px",
                  }}
                >
                  <SigviewTypography
                    style={{
                      // ...formTitleStyling,
                      height: "max-content",
                      // width: "30%",
                    }}
                  >
                    Last Name:
                  </SigviewTypography>
                </div>
                <SigviewTextField
                  placeholder="Last Name"
                  value={selections.lastName.value}
                  onChange={handleLastNameChange}
                  width="200px"
                  // maxWidth="200px"
                  // minWidth="20px"
                />
              </div>
            </>
            <>
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "baseline",
                  width: "450px",
                  paddingRight: "10px",
                }}
              >
                <div
                  style={{
                    width: "50px",
                    display: "flex",
                    height: "max-content",
                    paddingTop: "5px",
                  }}
                >
                  <SigviewTypography
                    style={{
                      // ...formTitleStyling,
                      height: "max-content",
                    }}
                  >
                    Email:
                  </SigviewTypography>
                  <span
                    style={{
                      color: "red",
                      // transform: "translateX(-10px)",
                      paddingLeft: "3px",
                    }}
                  >
                    *
                  </span>
                </div>
                <SigviewTextFieldAsync2
                  placeholder="Email"
                  value={selections.email.value}
                  org={user.reqMetadata.organization}
                  readOnly={
                    ["existing", "edit"].includes(
                      user.screen?.activeAdminUser?.metadata?.crudType
                    ) || false
                  }
                  onChange={handleEmailChange}
                  tooltipTitle="Type you email to check if exists"
                  width="200px"
                  // maxWidth="200px"
                  // minWidth="200px"
                  emailStatusValue={emailStatusValue}
                  ReduxDispatcher={ReduxDispatcher}
                  user={user}
                />
              </div>
            </>
          </LayoutRow>
          <UserProvider value={"calc(100% - 85px)"}>
            <LayoutAdminContent
              fullHeight={"calc(100% - 134px)"}
              padding="0px 25px"
            >
              <Box css={box1Style}>
                <Box
                  css={{
                    display: "flex",
                    alignItems: "center",
                    height: "45px !important",
                  }}
                >
                  <SigviewTypography
                    style={{
                      padding: "0px 10px ",
                      height: "max-content",
                      // boxSizing: "border-box !important",
                      color: themeColors["primaryColor"],
                    }}
                  >
                    Views
                  </SigviewTypography>
                </Box>

                <SigviewTabAws
                  disabled={false}
                  data={tabViewsData}
                  selectedView={selections.selectedView.value}
                  selectedGroup={selections.selectedGroup.value}
                  activeView={selections.activeView.value}
                  handleActiveView={handleViewChange}
                  handleSelectedView={handleSelectedView}
                  handleSelectedGroup={handleSelectedGroup}
                />
              </Box>

              <SigviewNav
                data={sigviewNavData}
                value={selections.activeTab.value.id}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    backgroundColor: `${themeColors["secondaryColorLightest"]} `,
                    borderRadius: "3px 3px 0px 0px",
                    // height: "100%",
                    width: "100%",
                  }}
                >
                  <SigviewTypography style={attributeTitleStyling}>
                    {`Dimensions (
                      ${
                        selections.views?.value[
                          selections.activeView.value.id
                        ]?.dimensions.filter(
                          (row) => !["D003"].includes(row.id)
                        )?.length || 0
                      }
                    /
                    ${
                      transformToChecklistData(
                        groupDataInChecklist?.dimensionList || []
                      ).length
                    }
                    )`}
                  </SigviewTypography>
                </div>
                <SigviewCheckList
                  disabled={false}
                  data={transformToChecklistData(
                    groupDataInChecklist?.dimensionList || []
                  )}
                  selections={{
                    userSelection:
                      selections.views.value[selections.activeView.value.id]
                        ?.dimensions || [],

                    filter:
                      selections.views.value[
                        selections.activeView.value.id
                      ]?.userFilters?.map((row) => row.id) || [],
                  }}
                  onChange={handleDimensionChange}
                  handleClearAll={handleClearDimension}
                  handleSelectAll={handleSelectAllDimension}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    backgroundColor: `${themeColors["secondaryColorLightest"]} `,
                    borderRadius: "3px 3px 0px 0px",
                    // height: "100%",
                    width: "100%",
                  }}
                >
                  <SigviewTypography style={attributeTitleStyling}>
                    {`Metrics (
                    ${
                      selections.views.value[selections.activeView.value.id]
                        ?.metrics?.length || 0
                    }
                    /
                    ${
                      transformToChecklistData(
                        groupDataInChecklist?.metricList || []
                      )?.length
                    }
                    )`}
                  </SigviewTypography>
                </div>
                <SigviewCheckList
                  data={transformToChecklistData(
                    groupDataInChecklist?.metricList || []
                  )}
                  selections={{
                    userSelection:
                      selections.views.value[selections.activeView.value.id]
                        ?.metrics || [],
                  }}
                  onChange={handleMetricChange}
                  handleClearAll={handleClearMetric}
                  handleSelectAll={handleSelectAllMetric}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    backgroundColor: `${themeColors["secondaryColorLightest"]} `,
                    borderRadius: "3px 3px 0px 0px",
                    // height: "100%",
                    width: "100%",
                  }}
                >
                  <SigviewTypography style={attributeTitleStyling}>
                    {`Custom Metrics (
                    ${
                      selections.views.value[selections.activeView.value.id]
                        ?.customMetrics?.length || 0
                    }
                    /
                    ${
                      transformToChecklistData(
                        groupDataInChecklist?.customMetricList || []
                      )?.length
                    }
                    )`}
                  </SigviewTypography>
                </div>
                <SigviewCheckList
                  data={transformToChecklistData(
                    groupDataInChecklist?.customMetricList || []
                  )}
                  selections={{
                    userSelection:
                      selections.views.value[selections.activeView.value.id]
                        ?.customMetrics || [],
                  }}
                  onChange={handleCustomMetricChange}
                  handleClearAll={handleClearCustomMetric}
                  handleSelectAll={handleSelectAllCustomMetric}
                />
              </div>
              <div>
                <div
                  className="sigview-styled-scroller-thin"
                  style={{
                    // padding: "10px 0px 0px 10px",
                    paddingLeft: "10px",
                    paddingTop: "5px",
                    height: "45px",
                    overflow: "auto",
                    boxSizing: "border-box !important",
                  }}
                >
                  <DimensionFilters
                    initialDimensionFilters={
                      selections.views.value[selections.activeView.value.id]
                        ?.userFilters || []
                    }
                    initialTimeFilters={newTimeFilters}
                    onDimensionFiltersChange={handleDimensionFiltersChange}
                    settings={userFiltersSettings}
                    filtersData={{
                      filtersDimData: filtersDimData,
                      filtersMetricsData: filtersMetricsData,
                    }}
                    selections={{
                      activeView: selections.activeView.value.id,
                      activeOrg: user?.reqMetadata?.organization,
                    }}
                  />
                </div>
              </div>
            </LayoutAdminContent>
          </UserProvider>

          <LayoutRow
            style={{
              display: "flex",
              height: "50px",

              padding: "0px 25px",
              justifyContent: "flex-end",
              alignItems: "center",
              " & > div:first-child": { paddng: "100px" },
            }}
          >
            <SigviewButton
              title="Cancel"
              variant="outlined"
              onClick={handleCancel}
              style={{ margin: { left: "10px" } }}
            />

            {(user.screen?.activeAdminUser?.metadata?.crudType === "create" ||
              user.screen?.activeAdminUser?.metadata?.crudType === "new") && (
              <SigviewButton
                title="Save"
                variant="contained"
                onClick={handleSave}
                disabled={
                  isUpdateDisabled ||
                  ["104", "303", "200"].includes(
                    emailvalidationResponse?.result?.statusCode
                  )
                }
                style={{ margin: { left: "10px" } }}
              />
            )}
            {(user.screen?.activeAdminUser?.metadata?.crudType === "edit" ||
              user.screen?.activeAdminUser?.metadata?.crudType ===
                "existing") && (
              <SigviewButton
                title="Update"
                variant="contained"
                onClick={handleUpdate}
                disabled={isUpdateDisabled}
                style={{ margin: { left: "10px" } }}
              />
            )}
          </LayoutRow>
        </>
      )}
    </LayoutTopSideBottomAdmin>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(User);
