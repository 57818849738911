import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import isEqual from "lodash.isequal";
import moment from "moment";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from "@material-ui/core";
import { format as d3Format } from "d3";

// Import custom components
import SigviewProgressBar from "./../../../components/Common/SigviewProgressBar";
import SigviewButton from "../../../components/Common/SigviewButton";
import Loader from "../../../components/Loader/Loader";
import SigviewIcon from "../../../components/Common/SigviewIcon";

// * Import utils/data
import { wrapperChartObject } from "../../../utils/chartObjectUtils";

// Import API functions
import { getData } from "../../../services/api";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";
import { appendMetricSym } from "../../../utils/plotlyUtils";
import {
  formatDimValue,
  extractOrderFromAdvanceSort,
  getTransposeTableHeaderColumns,
  getTransposeColumn,
  getSortDetails,
  rowUiDataFriendly,
  totalRowUiDataFriendly,
  getColumn,
  updateRowData,
  makeRowLevelFilterForTranspose,
  transposeTotalRowUiDataFriendly,
  transposeRowUiDataFriendly,
  updateChildrenDataTranpose,
  makeSigviewStyles,
  makeUiFriendlyDimensionFilters,
  updateChildrenShowMoreLoading,
  updateChildrenDataRecursively,
  updateChildrenData,
  updateChildrenLoading,
} from "../../../utils/pivotUtils";
import {
  updatePivotAdvanceSort,
  updatePivotReloadEpoch,
} from "../../../redux/actions";

// Defining required constants
const initialPivotData = { result: [], status: "loading", message: "" };

function PivotTableRow(props = {}) {
  const {
    row,
    columns,
    pivotData,
    setPivotData,
    selections,
    user,
    allData = {},
    globalFilters = {},
    setReloadTableEpoch,
    totalLength = 0,
    rowIndex = 0,
  } = props;

  const level = row.level;
  const actualColumns = columns[level];

  // * Function run after clicking the Error button form the table
  const handleError = (event, { row, columns }) => {
    // * DEBUGGER
    // console.groupCollapsed("handleError");
    // console.log("row", row);
    // console.log("columns", columns);
    // console.groupEnd();
    if (row.level === 0) {
      setReloadTableEpoch(Date.now());
    } else {
      // Get all the parent row Filters Function
      const { actualParent } = row;
      const additionalDimensionFilters = makeUiFriendlyDimensionFilters(
        columns,
        actualParent
      );
      const clickedRowDimensionFilters = [
        {
          id: columns[actualParent.level][0].id,
          values: [actualParent[columns[actualParent.level][0]["accessor"]]],
          metadata: {
            ...columns[actualParent.level][0].actualPayload,
          },
          filterType: "include",
          advancedFilters: [],
          valid: true,
        },
      ];
      const extractOrderFromAdvanceSortProps = {
        advanceSort: selections.advanceSort,
        dimension: selections.dimensionsList[actualParent.level + 1],
        allData,
      };
      const orderDetails = extractOrderFromAdvanceSort(
        extractOrderFromAdvanceSortProps
      );
      const source = axios.CancelToken.source();
      const newSelections = {
        ...selections,
        // * If there is dateTime dimension Don't send filter in payload
        dimensionFilters:
          columns[actualParent.level][0].actualPayload.dataType !== "dateTime"
            ? [
                ...selections.dimensionFilters,
                ...additionalDimensionFilters,
                ...clickedRowDimensionFilters,
              ]
            : [...selections.dimensionFilters, ...additionalDimensionFilters],
        dimensionsList: [selections.dimensionsList[actualParent.level + 1]],
        granularity: globalFilters?.metricChartGranularity?.value,
        dataLimit: 11,
        ...orderDetails,
      };
      const props = { selections: newSelections, user };
      const getDataPayload = wrapperChartObject(props);
      // console.log("payload for +click", getDataPayload);
      const fetchProps = {
        payload: getDataPayload,
        cancelToken: source.token,
      };
      // Api calling and updating the children array with api response
      const getDataPromise = getData(fetchProps);
      getDataPromise
        .then((data) => {
          if (data.status.statusCode === "200") {
            // console.log("ERROR DATA", data);
            setPivotData((latestState) => {
              const updateChildrenDataProps = {
                apiResponse: data?.result.data,
                columns,
                data: latestState.result,
                row: actualParent,
                dataLimit: newSelections.dataLimit,
                isExpandedValue: true,
              };
              const newPivotData = updateChildrenData(updateChildrenDataProps);
              return {
                result: newPivotData,
                status: "success",
                message: "",
              };
            });
          } else return data;
        })
        .catch((json) => {
          setPivotData((latestState) => {
            // console.log("ROW LEVEL", actualParent.level);
            const rowLevel = actualParent.level;
            const id = actualParent[columns[rowLevel][0].accessor];
            const key = columns[rowLevel][0].accessor;
            const updateChildrenDataRecursivelyProps = {
              id,
              key,
              children: latestState.result,
              value: [],
              rowLevel,
              dataLimit: newSelections.dataLimit,
              isExpandedValue: true,
              errMessage: json.error,
              actualParent,
            };
            let newPivotData = updateChildrenDataRecursively(
              updateChildrenDataRecursivelyProps
            );
            return {
              result: newPivotData,
              status: "success",
              message: "",
            };
          });
          console.groupCollapsed("UI ERROR +ICON CLICK");
          console.log(json);
          console.groupEnd();
        });
    }
  };
  const updateShowMoreNthLvel = (updateshowMoreNthLvelProps = {}) => {
    const {
      key,
      id,
      data,
      dataLimit,
      previousDataLimit,
      currentRow,
      finalUpdatedChildren,
    } = updateshowMoreNthLvelProps;
    // * DEBUGGER
    console.groupCollapsed("updateShowMoreNthLvel");
    console.log("key", key);
    console.log("id", id);
    console.log("data", data);
    console.log("previousDataLimit", previousDataLimit);
    console.log("dataLimit", dataLimit);
    console.log("currentRow", currentRow);
    console.groupEnd();

    const updatedChildren = data.map((el) => {
      const elMod = {
        ...el,
        children:
          el[key] === id && isEqual(el.parentInfo, currentRow.parentInfo)
            ? [...el.children, ...finalUpdatedChildren.slice(previousDataLimit)]
            : updateShowMoreNthLvel({
                key,
                id,
                data: el.children,
                dataLimit,
                previousDataLimit,
                currentRow,
                finalUpdatedChildren,
              }),
      };
      return elMod;
    });
    return updatedChildren;
  };
  // * Function run after clicking the Show More button form the table
  const handleShowMore = (event, { row, columns }) => {
    const rowLevel = row.level;
    //  For showing Loading to ui and adding +10 to datalimit feild
    setPivotData((latestState) => {
      const updateChildrenLoadingProps = {
        columns,
        data: latestState.result,
        row,
        rowLevel: row.level,
        value: true,
        selections,
      };
      var updatedPivotData = updateChildrenShowMoreLoading(
        updateChildrenLoadingProps
      );
      return {
        result: updatedPivotData,
        status: "success",
        message: "",
      };
    });

    // Get all the parent row Filters Function
    const additionalDimensionFilters = makeUiFriendlyDimensionFilters(
      columns,
      row
    );

    const extractOrderFromAdvanceSortProps = {
      advanceSort: selections.advanceSort,
      dimension: selections.dimensionsList[rowLevel],
      allData,
    };
    const orderDetails = extractOrderFromAdvanceSort(
      extractOrderFromAdvanceSortProps
    );
    //For cancelling the query if the activeDimension or
    const source = axios.CancelToken.source();
    const newSelections = {
      ...selections,
      dimensionFilters:
        rowLevel === 0
          ? []
          : [...additionalDimensionFilters, ...selections.dimensionFilters],
      dimensionsList: [selections.dimensionsList[rowLevel]],
      dataLimit: row.dataLimit + selections.dataLimit,
      granularity: globalFilters?.metricChartGranularity?.value,
      ...orderDetails,
    };
    // console.log("newSelections", newSelections);

    const props = { selections: newSelections, user };
    const getDataPayload = wrapperChartObject(props);
    const fetchProps = {
      payload: getDataPayload,
      cancelToken: source.token,
    };
    // console.log("payload", fetchProps.payload);

    const getDataPromise = getData(fetchProps);
    getDataPromise
      .then((data) => {
        if (data.status.statusCode === "200") {
          setPivotData((latestState) => {
            const updateChildrenLoadingProps = {
              columns,
              data: latestState.result,
              row,
              rowLevel: row.level,
              value: false,
              selections,
            };
            var updatedPivotData = updateChildrenShowMoreLoading(
              updateChildrenLoadingProps
            );
            return {
              result: updatedPivotData,
              status: "success",
              message: "",
            };
          });
          setPivotData((latestState) => {
            const newPivotData = updateRowData({
              apiResponse: data?.result.data,
              columns,
              data: latestState.result,
              row,
              dataLimit: newSelections.dataLimit,
              previousDataLimit: row.dataLimit,
            });

            return {
              result: newPivotData,
              status: "success",
              message: "",
            };
          });

          // ! For the transpose row Data api call except the total api call
          // ! This is row level filter to get data of the row level
          // ! useful for the get data call
          let currentRowFilter = data?.result.data.map((el) => {
            return el[selections.dimensionsList[rowLevel].dimID];
          });
          // if rowleve is not 0 then take the last currentRowFilter

          currentRowFilter = currentRowFilter.slice(-selections.dataLimit);

          const withTimeDimension = selections.dimensionsList.find(
            (dimList) => {
              if (dimList.dataType === "dateTime") {
                return dimList;
              }
            }
          );
          // ! Ui friendly filter making function
          const rowLevelFilterForTranspose = makeRowLevelFilterForTranspose(
            columns,
            currentRowFilter,
            rowLevel
          );

          const rowLevelForClickedRowDimensionFilters =
            rowLevel === 0 ? rowLevel : rowLevel - 1;
          const clickedRowDimensionFilters = [
            {
              id: columns[rowLevelForClickedRowDimensionFilters][0].id,
              values: [row.parentInfo[row.level]],

              filterType: "include",
              advancedFilters: [],
              valid: true,
            },
          ];

          const source = axios.CancelToken.source();
          var newSelections1 = {
            ...selections,
            dimensionsList: [
              selections.dimensionsList[rowLevel],
              withTimeDimension,
            ],
            ...orderDetails,
            granularity: globalFilters?.metricChartGranularity?.value,
            dimensionFilters:
              rowLevel === 0
                ? [
                    ...selections.dimensionFilters,
                    ...rowLevelFilterForTranspose,
                  ]
                : [
                    ...selections.dimensionFilters,
                    ...additionalDimensionFilters,
                    ...rowLevelFilterForTranspose,
                  ],
            dataLimit: 5000,
          };
          var props = { selections: newSelections1, user };
          var getDataPayload = wrapperChartObject(props);

          var fetchProps = {
            payload: getDataPayload,
            cancelToken: source.token,
          };
          var getDataPromise = getData(fetchProps);
          getDataPromise
            .then((data) => {
              if (rowLevel === 0) {
                setPivotData((latestState) => {
                  const transposeRowUiDataFriendlyProps = {
                    getDataResponse: data.result.data,
                    columnData: columns,
                    columnIndex: rowLevel,
                    dataLimit: selections.dataLimit,
                    pivotData: latestState,
                    selections,
                  };
                  const finalData = transposeRowUiDataFriendly(
                    transposeRowUiDataFriendlyProps
                  );
                  // setShowTranposeTable(true);
                  return {
                    result: finalData,
                    status: "success",
                    message: "",
                  };
                });
              } else {
                setPivotData((latestState) => {
                  const getDataResponse = data.result.data;
                  const columnData = columns;
                  const columnIndex = row.level;
                  const dataLimit = selections.dataLimit;
                  const currentcoldata = columnData[columnIndex][0].accessor;
                  const key = currentcoldata;
                  // * DEBUGGER
                  // console.groupCollapsed("transposeRowUiData");
                  // console.log("columnIndex", columnIndex);
                  // console.log("getDataResponse", getDataResponse);
                  // console.log("columnData", columnData);
                  // console.log("dataLimit", dataLimit);
                  // console.log("latestState", latestState);
                  // console.log("currentcoldata", currentcoldata);
                  // console.log("key", key);
                  // console.log("row", row);
                  // console.log("row.level", row.level);
                  // console.log(
                  //   "row ka key",
                  //   columnData[row.level - 1][0].accessor
                  // );
                  // console.log("check", row[columnData[row.level][0].accessor]);
                  // console.log("+", row.parentInfo[row.level]);
                  // console.groupEnd();
                  // Recursively find the row on the basics of key and id
                  const currentRow = findRowRecursively(
                    row.parentInfo[row.level],
                    latestState.result,
                    columnData[row.level - 1][0].accessor
                  );
                  // console.log("currentRow", currentRow);
                  let finalUpdatedChildren;
                  // After getting the row update the children data with response
                  const updatedRow = getDataResponse.map(
                    (getResponseEl, index) => {
                      const id = getResponseEl[key];
                      const children = pivotData.result;

                      const newRowData = selections.metricsList.map((el) => {
                        let key;
                        if (getResponseEl.hour) {
                          key = `${el.measureTitle}_${moment(
                            Number(getResponseEl.hour)
                          )
                            .utcOffset(
                              selections.timeFilters.selectedTimezone
                                .minutesOffset
                            )
                            .format("MM-DD HH:mm")}`;
                        } else if (getResponseEl.day) {
                          key = `${el.measureTitle}_${moment(
                            Number(getResponseEl.day)
                          )
                            .utcOffset(
                              selections.timeFilters.selectedTimezone
                                .minutesOffset
                            )
                            .format("YYYY-MM-DD")}`;
                        } else if (getResponseEl.month) {
                        }
                        const obj = {
                          [key]: getResponseEl[el.measureID],
                        };
                        return obj;
                      });
                      const updatedChildren = updateChildrenDataTranpose(
                        id,
                        currentRow.children,
                        newRowData,
                        currentcoldata
                      );
                      // console.log("updatedChildren", updatedChildren);
                      finalUpdatedChildren = updatedChildren;
                    }
                  );
                  const updateshowMoreNthLvelProps = {
                    key: columnData[row.level - 1][0].accessor,
                    id: row.parentInfo[row.level],
                    data: latestState.result,
                    dataLimit: newSelections.dataLimit,
                    previousDataLimit: row.dataLimit,
                    currentRow,
                    finalUpdatedChildren,
                  };

                  const finalData = updateShowMoreNthLvel(
                    updateshowMoreNthLvelProps
                  );

                  return {
                    result: latestState.result,
                    status: "success",
                    message: "",
                  };
                });
              }
            })
            .catch((json) => {
              const rowUiDataProps = {
                getDataResponse: [],
                columnData: columns,
                columnIndex: 0,
                dataLimit: selections.dataLimit,
                apiResponseLength: 0,
                errMessage: json.error,
              };
              const finalData = rowUiDataFriendly(rowUiDataProps);
              setPivotData({
                result: finalData,
                status: "success",
                message: "",
              });
              console.groupCollapsed("UI ERROR USE-EFFECT");
              console.log(json);
              console.groupEnd();
            });
        } else return data;
      })
      .catch((json) => {
        console.groupCollapsed("UI ERROR HANDLESHOWMORE");
        console.log(json);
        console.groupEnd();
      });
  };

  // * Func to clear children - when click -Icon to clear children
  const handleClearChildren = (event, clearChildrenProps = {}) => {
    const { row = {}, columns = [] } = clearChildrenProps;
    // * DEBUGGER
    // console.groupCollapsed("handleClearChildren");
    // console.log("row", row);
    // console.groupEnd();

    setPivotData((latestState) => {
      const newPivotData = updateChildrenData({
        apiResponse: [],
        columns,
        data: latestState.result,
        row,
        isExpandedValue: false,
        clearChilren: "true",
      });

      return {
        result: newPivotData,
        status: "success",
        message: "",
      };
    });
  };

  // Function to find the row using finding a node in n-ary tree
  const findRowRecursively = (key, pivotData, id) => {
    // console.groupCollapsed("findRowRecursively");
    // console.log("key", key);
    // console.log("pivotData", pivotData);
    // console.log("id", id);
    // console.groupEnd();
    let currentRow = undefined;
    for (let item of pivotData) {
      if (item[id] === key) {
        currentRow = item;
        return currentRow;
      }
      currentRow = findRowRecursively(key, item.children, id);
      if (currentRow !== undefined) return currentRow;
    }
    return currentRow;
  };
  const updateChildrenTransposeDataRecursively = (
    id,
    key,
    children,
    updatedChildren
  ) => {
    // console.groupCollapsed("updateChildrenTransposeDataRecursively");
    // console.log("children", children);
    // console.log("updatedChildren", updatedChildren);
    // console.log("id", id);
    // console.log("key", key);
    // console.groupEnd();
    const finalUpdatedChildren = children.map((el) => {
      const elMod = {
        ...el,
        children:
          el[key] === id
            ? updatedChildren
            : updateChildrenTransposeDataRecursively(
                id,
                key,
                el.children,
                updatedChildren
              ),
      };
      return elMod;
    });
    // console.log("check final", finalUpdatedChildren);
    return finalUpdatedChildren;
  };

  // * Function run after clicking the + icon form the table
  const handleUpdateIsLoading = (event, handleUpdateIsLoadingProps = {}) => {
    const {
      row = {},
      columns = [],
      selections = {},
    } = handleUpdateIsLoadingProps;

    // Step - 1  updating to the loading state of that rowData form where it is clicked
    setPivotData((latestState) => {
      const updateChildrenLoadingProps = {
        columns,
        data: latestState.result,
        row,
        rowLevel: row.level,
        value: true,
      };
      const updatedPivotData = updateChildrenLoading(
        updateChildrenLoadingProps
      );
      return {
        result: updatedPivotData,
        status: "success",
        message: "",
      };
    });

    // Get all the parent row Filters Function
    const additionalDimensionFilters = makeUiFriendlyDimensionFilters(
      columns,
      row
    );
    const clickedRowDimensionFilters = [
      {
        id: columns[row.level][0].id,
        values: [row[columns[row.level][0]["accessor"]]],
        metadata: {
          ...columns[row.level][0].actualPayload,
        },
        filterType: "include",
        advancedFilters: [],
        valid: true,
      },
    ];
    const extractOrderFromAdvanceSortProps = {
      advanceSort: selections.advanceSort,
      dimension: selections.dimensionsList[row.level + 1],
      allData,
    };
    const orderDetails = extractOrderFromAdvanceSort(
      extractOrderFromAdvanceSortProps
    );
    const source = axios.CancelToken.source();
    const newSelections = {
      ...selections,
      dimensionFilters: [
        ...selections.dimensionFilters,
        ...additionalDimensionFilters,
        ...clickedRowDimensionFilters,
      ],
      dimensionsList: [selections.dimensionsList[row.level + 1]],
      granularity: globalFilters?.metricChartGranularity?.value,
      ...orderDetails,
    };
    var props = { selections: newSelections, user };
    var getDataPayload = wrapperChartObject(props);
    var fetchProps = {
      payload: getDataPayload,
      cancelToken: source.token,
    };
    // ! Api calling and updating the children array with api response
    var getDataPromise = getData(fetchProps);
    getDataPromise
      .then((data) => {
        setPivotData((latestState) => {
          const updateChildrenDataProps = {
            apiResponse: data?.result.data,
            columns,
            data: latestState.result,
            row,
            dataLimit: selections.dataLimit,
            isExpandedValue: true,
          };
          const newPivotData = updateChildrenData(updateChildrenDataProps);

          // * For the row Data api call except the total api call
          // * This is for sending the row level information as a filter to get data of the row level
          // * useful for the get data call
          var currentRowFilter = data.result.data.map((el) => {
            return el[selections.dimensionsList[row.level + 1].dimID];
          });
          //  Ui friendly filter making function
          var rowLevelFilterForTranspose = makeRowLevelFilterForTranspose(
            columns,
            currentRowFilter,
            row.level + 1
          );

          // * To find the time dimension
          const withTimeDimension = selections.dimensionsList.find(
            (dimList) => {
              if (dimList.dataType === "dateTime") {
                return dimList;
              }
            }
          );
          var newSelections = {
            ...selections,
            dimensionsList: [
              selections.dimensionsList[row.level + 1],
              withTimeDimension,
            ],
            dataLimit: 50000,
            granularity: globalFilters?.metricChartGranularity?.value,
            ...orderDetails,
            dimensionFilters: [
              ...selections.dimensionFilters,
              ...clickedRowDimensionFilters,
              ...additionalDimensionFilters,
              ...rowLevelFilterForTranspose,
            ],
          };
          var props = { selections: newSelections, user };
          var getDataPayload = wrapperChartObject(props);
          var fetchProps = {
            payload: getDataPayload,
            cancelToken: source.token,
          };
          var getDataPromise = getData(fetchProps);
          getDataPromise
            .then((data) => {
              setPivotData((latestState) => {
                const getDataResponse = data.result.data;
                const columnData = columns;
                const columnIndex = row.level + 1;
                const dataLimit = selections.dataLimit;
                const currentcoldata = columnData[columnIndex][0].accessor;
                const key = currentcoldata;
                // * DEBUGGER
                // console.groupCollapsed("transposeRowUiData");
                // console.log("columnIndex", columnIndex);
                // console.log("getDataResponse", getDataResponse);
                // console.log("columnData", columnData);
                // console.log("dataLimit", dataLimit);
                // console.log("latestState", latestState);
                // console.log("currentcoldata", currentcoldata);
                // console.log("key", key);
                // console.log("row", row);
                // console.log("row.level", row.level);
                // console.log("row ka key", columnData[row.level][0].accessor);
                // console.log("check", row[columnData[row.level][0].accessor]);
                // console.groupEnd();

                // Recursively find the row on the basics of key and id
                const currentRow = findRowRecursively(
                  row[columnData[row.level][0].accessor],
                  latestState.result,
                  columnData[row.level][0].accessor
                );
                var finalUpdatedChildren = [];
                // After getting the row update the children data with response
                const updatedRow = getDataResponse.map(
                  (getResponseEl, index) => {
                    const id = getResponseEl[key];
                    const children = pivotData.result;
                    // ! month is in pending
                    const newRowData = selections.metricsList.map((el) => {
                      let key;

                      if (getResponseEl.hour) {
                        key = `${el.measureTitle}_${moment(
                          Number(getResponseEl.hour)
                        )
                          .utcOffset(
                            selections.timeFilters.selectedTimezone
                              .minutesOffset
                          )
                          .format("MM-DD HH:mm")}`;
                      } else if (getResponseEl.day) {
                        key = `${el.measureTitle}_${moment(
                          Number(getResponseEl.day)
                        )
                          .utcOffset(
                            selections.timeFilters.selectedTimezone
                              .minutesOffset
                          )
                          .format("YYYY-MM-DD")}`;
                      } else if (getResponseEl.month) {
                      }
                      const obj = {
                        [key]: getResponseEl[el.measureID],
                      };
                      return obj;
                    });
                    const updatedChildren = updateChildrenDataTranpose(
                      id,
                      currentRow.children,
                      newRowData,
                      currentcoldata
                    );

                    finalUpdatedChildren =
                      updateChildrenTransposeDataRecursively(
                        row[columnData[row.level][0].accessor],
                        columnData[row.level][0].accessor,
                        latestState.result,
                        updatedChildren
                      );
                  }
                );

                return {
                  result: finalUpdatedChildren,
                  status: "success",
                  message: "",
                };
              });
            })
            .catch((json) => {
              console.groupCollapsed("UI ERROR USE-EFFECT");
              console.log(json);
              console.groupEnd();
            });
          return {
            result: newPivotData,
            status: "success",
            message: "",
          };
        });
      })
      .catch((json) => {
        console.groupCollapsed("UI ERROR +ICON CLICK");
        console.log(json);
        console.groupEnd();
      });
  };

  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const showPlusButton = selections.dimensionsList.length > 2;

  return (
    <>
      <>
        {!row.isError && (
          <TableRow>
            {actualColumns.map((col, index) => {
              const value = row[col.accessor];
              const formatDimValueProps = {
                value: row[col.accessor],
                accessor: col.accessor,
                selections,
              };
              const isTotalRow = value?.toLowerCase() === "total";
              let displayValue = formatDimValue(formatDimValueProps);
              let title = value;
              if (isTotalRow) displayValue = value;
              // Only format if it's a metric (which is non 0 index in our case)
              if (index !== 0) {
                let metricObj = col.actualPayload;
                displayValue =
                  value === "NA"
                    ? value
                    : appendMetricSym(
                        value,
                        metricObj.dataUnit,
                        true
                      ).toUpperCase();
                title = value === "NA" ? value : d3Format(",")(value);
              } else {
                title = displayValue;
              }

              const style1 = {
                paddingLeft: `${row.level * 30}px`,
                alignItems: "center",
                backgroundColor: "white",
                textAlign: "left",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              };
              const style2 = {
                paddingLeft: `${row.level * 30}px`,
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                textAlign: "left",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              };
              const finalStyle = displayValue === "" ? style1 : style2;

              if (index === 0) {
                return (
                  <TableCell
                    key={index}
                    style={finalStyle}
                    className={`${classes.tableCell} ${classes.sticky}`}
                    title={title}
                  >
                    {showPlusButton &&
                      row.isClickButton &&
                      row.isExpanded === false && (
                        <span
                          onClick={(event) =>
                            handleUpdateIsLoading(event, {
                              row,
                              columns,
                              selections: selections,
                            })
                          }
                          className="material-icons-outlined"
                          style={{
                            cursor: "pointer",
                            fontSize: "14px",
                            marginRight: "2px",
                            color: themeColors["primaryColor"],
                          }}
                        >
                          add_circle
                        </span>
                      )}

                    {row.isClickButton && row.isExpanded === true && (
                      <span
                        onClick={(event) =>
                          handleClearChildren(event, {
                            row,
                            columns,
                          })
                        }
                        className="material-icons-outlined"
                        style={{
                          cursor: "pointer",
                          fontSize: "14px",
                          marginRight: "2px",
                          color: themeColors["secondaryColorLight"],
                        }}
                      >
                        remove_circle
                      </span>
                    )}
                    {displayValue}
                  </TableCell>
                );
              } else {
                return (
                  <TableCell
                    key={index}
                    className={classes.tableCell}
                    title={title}
                  >
                    {displayValue}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        )}
        {/* show error button code */}
        {row.isError && row.isChildrenLoading === false && (
          <SigviewButton
            variant="containedPrimary"
            onClick={(event) =>
              handleError(event, {
                row,
                columns,
              })
            }
            title={row.errMessage}
            iconFlag={true}
            iconProps={{
              className: "material-icons-outlined",
            }}
          />
        )}
        {/* For showing the loader  */}
        {row.isChildrenLoading && (
          <TableRow>
            <TableCell className={classes.tableCell}>
              <SigviewProgressBar />
            </TableCell>
          </TableRow>
        )}
        {/* show more code */}
        {/* ORDER of SHOW MORE AND CHILDREN DEPENDS ON IF THE ROW IS THE LAST INDEX */}
        {/* ROW IS NOT THE LAST INDEX */}
        {row.isShowMore &&
          row.isChildrenLoading === false &&
          totalLength - 1 !== rowIndex && (
            <Box css={{ padding: `5px 0px 5px ${row.level * 30}px` }}>
              <SigviewButton
                variant="containedPrimary"
                onClick={(event) =>
                  handleShowMore(event, {
                    row,
                    columns,
                    selections: selections,
                  })
                }
                title="Show More"
                iconFlag={true}
                iconProps={{
                  className: "material-icons-outlined",
                }}
              />
            </Box>
          )}
        {row.children.length > 0 &&
          totalLength - 1 !== rowIndex &&
          row.children.map((childRow, index) => (
            <PivotTableRow
              key={index}
              row={childRow}
              columns={columns}
              pivotData={pivotData}
              setPivotData={setPivotData}
              selections={selections}
              user={user}
              allData={allData}
              totalLength={row.children.length}
              rowIndex={index}
            />
          ))}

        {/* ROW IS THE LAST INDEX */}
        {row.children.length > 0 &&
          totalLength - 1 === rowIndex &&
          row.children.map((childRow, index) => (
            <PivotTableRow
              key={index}
              row={childRow}
              columns={columns}
              pivotData={pivotData}
              setPivotData={setPivotData}
              selections={selections}
              user={user}
              allData={allData}
              totalLength={row.children.length}
              rowIndex={index}
            />
          ))}
        {row.isShowMore &&
          row.isChildrenLoading === false &&
          totalLength - 1 === rowIndex && (
            <Box
              className={classes.sticky}
              css={{ padding: `5px 0px 5px ${row.level * 30}px` }}
            >
              <SigviewButton
                variant="containedPrimary"
                onClick={(event) =>
                  handleShowMore(event, {
                    row,
                    columns,
                    selections: selections,
                  })
                }
                title="Show More"
                iconFlag={true}
                iconProps={{
                  className: "material-icons-outlined",
                }}
              />
            </Box>
          )}
      </>
    </>
  );
}

function PivotTableTotalRow(props = {}) {
  const {
    row,
    columns,
    selections,
    user,
    allData = {},
    setPivotDataTotal,
    globalFilters = {},
  } = props;
  // * DEBUGGER
  // console.groupCollapsed("PivotTableTotalRow");
  // console.log("row", row);
  // console.log("columns", columns);
  // console.groupEnd();

  // * Function run after clicking the Error button form the table
  const handleError = (event, { row, columns }) => {
    // * DEBUGGER
    // console.groupCollapsed("handleError");
    // console.log("row", row);
    // console.log("columns", columns);
    // console.groupEnd();

    var newSelections = {
      ...selections,
      dimensionsList: [],
      dataLimit: 11,
      orderBy: "desc", //HARD CODED
      orderById: selections.metricsList[0]._id, //HARD CODED
      granularity: globalFilters?.metricChartGranularity?.value,
    };
    var props = { selections: newSelections, user };
    var getDataPayload = wrapperChartObject(props);
    // console.log("total payload", getDataPayload);
    var fetchProps = {
      payload: getDataPayload,
    };
    var getDataPromise = getData(fetchProps);
    getDataPromise
      .then((data) => {
        setPivotDataTotal((latestState) => {
          const rowUiDataProps = {
            getDataResponse: data.result.data,
            columnData: columns,
            columnIndex: 0,
          };
          const totalRowData = totalRowUiDataFriendly(rowUiDataProps);
          return {
            result: totalRowData,
            status: "success",
            message: "",
          };
        });
      })
      .catch((json) => {
        const rowUiDataProps = {
          getDataResponse: [],
          columnData: columns,
          columnIndex: 0,
          dataLimit: selections.dataLimit,
          apiResponseLength: 0,
          errMessage: json.error,
        };
        const finalData = rowUiDataFriendly(rowUiDataProps);
        setPivotDataTotal({
          result: finalData,
          status: "success",
          message: "",
        });
        console.groupCollapsed("UI ERROR USE-EFFECT FOR TOTAL API CALL");
        console.log(json);
        console.groupEnd();
      });
  };

  const actualColumns = columns[0];
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();

  return (
    <>
      <>
        {!row.isError && (
          <TableRow className={classes.totalRow}>
            {actualColumns.map((col, index) => {
              const value = row[col.accessor];
              const formatDimValueProps = {
                value: row[col.accessor],
                accessor: col.accessor,
                selections,
              };

              const isTotalRow = value?.toLowerCase() === "total";
              let displayValue = formatDimValue(formatDimValueProps);
              let title = value;
              if (isTotalRow) displayValue = value;
              // Only format if it's a metric (which is non 0 index in our case)
              if (index !== 0) {
                let metricObj = col.actualPayload;
                displayValue =
                  value === "NA"
                    ? value
                    : appendMetricSym(
                        value,
                        metricObj.dataUnit,
                        true
                      ).toUpperCase();
                title = value === "NA" ? value : d3Format(",")(value);
              } else {
                title = displayValue;
              }

              let className = classes.totaltableCellHeader;
              if (index === 0) className += ` ${classes.sticky}`;
              const style = {
                zIndex: `${row[col.accessor] === "Total" ? 0 : 0}`,
              };

              if (index === 0) {
                return (
                  <TableCell className={className} title={title}>
                    {displayValue}
                  </TableCell>
                );
              } else {
                return (
                  <TableCell className={className} title={title}>
                    {displayValue}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        )}

        {/* show error button code */}
        {row.isError && row.isChildrenLoading === false && (
          <SigviewButton
            variant="containedPrimary"
            onClick={(event) =>
              handleError(event, {
                row,
                columns,
              })
            }
            title={row.errMessage}
            iconFlag={true}
            iconProps={{
              className: "material-icons-outlined",
            }}
          />
        )}
      </>
    </>
  );
}

function PivotTransposeTable(props) {
  const {
    selections = {},
    user = {},
    allData = {},
    onDataChange = () => {},
    dispatchPivotState = () => {},
    globalFilters = {},
  } = props;
  // console.log("Pivot Transpose table", selections);
  const {
    dimensionsList,
    metricsList,
    dimensionFilters,
    advanceSort,
    timeFilters,
  } = selections;
  const [pivotData, setPivotData] = useState(initialPivotData);
  const [pivotDataTotal, setPivotDataTotal] = useState(initialPivotData);
  const [reloadTableEpoch, setReloadTableEpoch] = useState(Date.now());
  const [showTranposeTable, setShowTranposeTable] = useState(false);
  const [showTotalTable, setShowTotalTable] = useState({ status: "loading" });

  const [columns, setColumns] = useState(
    getColumn(dimensionsList, metricsList)
  );
  // * DEBUGGER
  // console.groupCollapsed("PivotTransposeTable");
  // console.log("pivotData", pivotData);
  // console.log("pivotDataTotal", pivotDataTotal);
  // console.groupEnd();

  // * Define required side effects for Normal table
  useEffect(() => {
    const extractOrderFromAdvanceSortProps = {
      advanceSort: selections.advanceSort,
      dimension: dimensionsList[0],
      allData,
    };
    const orderDetails = extractOrderFromAdvanceSort(
      extractOrderFromAdvanceSortProps
    );

    const source = axios.CancelToken.source();
    var newSelections = {
      ...selections,
      dimensionsList: [dimensionsList[0]],
      granularity: globalFilters?.metricChartGranularity?.value,
      ...orderDetails,
    };

    var props = { selections: newSelections, user };
    var getDataPayload = wrapperChartObject(props);
    var fetchProps = {
      payload: getDataPayload,
      cancelToken: source.token,
    };
    var getDataPromise = getData(fetchProps);
    getDataPromise
      .then((data) => {
        // console.log("tranpose 1", data.result.data);
        const rowUiDataProps = {
          getDataResponse: data.result.data,
          columnData: columns,
          columnIndex: 0,
          dataLimit: selections.dataLimit,
          apiResponseLength: data.result.data.length,
        };
        const finalData = rowUiDataFriendly(rowUiDataProps);
        // console.log("finalData", finalData);
        setPivotData({
          result: finalData,
          status: "success",
          message: "",
        });
      })
      .catch((json) => {
        const rowUiDataProps = {
          getDataResponse: [],
          columnData: columns,
          columnIndex: 0,
          dataLimit: selections.dataLimit,
          apiResponseLength: 0,
          errMessage: json.error,
        };
        const finalData = rowUiDataFriendly(rowUiDataProps);
        setPivotData({
          result: finalData,
          status: "success",
          message: "",
        });
        console.groupCollapsed("UI ERROR USE-EFFECT");
        console.log(json);
        console.groupEnd();
      });
    // ! Total api calling
    var source1 = axios.CancelToken.source();
    var newSelections = {
      ...selections,
      dimensionsList: [],
      orderBy: "desc", //HARD CODED
      orderById: selections.metricsList[0]._id, //HARD CODED
      granularity: globalFilters?.metricChartGranularity?.value,
    };
    var props = { selections: newSelections, user };
    var getDataPayload = wrapperChartObject(props);
    var fetchProps = {
      payload: getDataPayload,
      cancelToken: source.token,
    };
    var getDataPromise = getData(fetchProps);
    getDataPromise
      .then((data) => {
        setPivotDataTotal((latestState) => {
          // console.log("total data", data.result.data);
          const rowUiDataProps = {
            getDataResponse: data.result.data,
            columnData: columns,
            columnIndex: 0,
          };
          const totalRowData = totalRowUiDataFriendly(rowUiDataProps);
          return {
            result: totalRowData,
            status: "success",
            message: "",
          };
        });
      })
      .catch((json) => {
        const rowUiDataProps = {
          getDataResponse: [],
          columnData: columns,
          columnIndex: 0,
          dataLimit: selections.dataLimit,
          apiResponseLength: 0,
          errMessage: json.error,
        };
        const finalData = rowUiDataFriendly(rowUiDataProps);
        setPivotDataTotal({
          result: finalData,
          status: "success",
          message: "",
        });
        // TODO: Hide the lower table
        setShowTranposeTable(false);
        // setPivotDataTotal(initialPivotData);
        console.groupCollapsed("UI ERROR USE-EFFECT");
        console.log(json);
        console.groupEnd();
      });
    // ! Table api call for making the UI Table
    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
      if (source1) source1.cancel();
      setPivotData(initialPivotData);
    };
  }, [reloadTableEpoch]);

  // ! useEffect for the transpose
  useEffect(() => {
    if (pivotData.status === "success") {
      // ! For the transpose getdata api call for the table making except the total
      const extractOrderFromAdvanceSortProps = {
        advanceSort: selections.advanceSort,
        dimension: dimensionsList[0],
        allData,
      };
      var orderDetails = extractOrderFromAdvanceSort(
        extractOrderFromAdvanceSortProps
      );
      // * To find the time dimension
      const withTimeDimension = dimensionsList.find((dimList) => {
        if (dimList.dataType === "dateTime") {
          return dimList;
        }
      });
      //! For the total api calling
      var source2 = axios.CancelToken.source();
      var newSelections = {
        ...selections,
        dimensionsList: [withTimeDimension],
        // ...orderDetails,
        orderBy: "desc", //HARD CODED
        orderById: selections.metricsList[0]._id, //HARD CODED
        dimensionFilters: [],
        dataLimit: 5000,
        granularity: globalFilters?.metricChartGranularity?.value,
      };
      var props = { selections: newSelections, user };
      var getDataPayload = wrapperChartObject(props);
      // console.log("useEffect payload for total", getDataPayload);
      var fetchProps = {
        payload: getDataPayload,
        cancelToken: source2.token,
      };
      var getDataPromise = getData(fetchProps);
      getDataPromise
        .then((data) => {
          // console.log("transpose 2", data);
          // ! For column making functions
          // * To find the time dimension
          const withoutTimeDimension = dimensionsList.filter((dimList) => {
            if (dimList.dataType !== "dateTime") {
              return dimList;
            }
          });

          const transposeColumns = getTransposeColumn(
            withoutTimeDimension,
            metricsList,
            data.result.data,
            selections
          );
          // console.log("transposeColumns", transposeColumns);
          setColumns(transposeColumns);

          setShowTotalTable({ status: "success" });
          setPivotDataTotal((latestState) => {
            const transposeRowUiDataFriendlyProps = {
              getDataResponse: data.result.data,
              pivotData: latestState,
              selections,
            };

            const finalData = transposeTotalRowUiDataFriendly(
              transposeRowUiDataFriendlyProps
            );
            // console.log("final output", finalData);
            return {
              result: finalData,
              status: "success",
              message: "",
            };
          });

          // ! For the row Data api call except the total api call
          // ! This is row level filter to get data of the row level
          // ! useful for the get data call
          let currentRowFilter = pivotData.result.map((el) => {
            return el[dimensionsList[0].dimID];
          });
          if (currentRowFilter[0] === undefined) {
            currentRowFilter = [];
          }
          // console.log("currentRowFilter", currentRowFilter);
          // ! Ui friendly filter making function
          const rowLevelFilterForTranspose = makeRowLevelFilterForTranspose(
            columns,
            currentRowFilter,
            0
          );
          // console.log("rowLevelFilterForTranspose", rowLevelFilterForTranspose);

          const source = axios.CancelToken.source();
          var newSelections = {
            ...selections,
            dimensionsList: [dimensionsList[0], withTimeDimension],
            ...orderDetails,
            dimensionFilters: [
              ...selections.dimensionFilters,
              ...rowLevelFilterForTranspose,
            ],
            dataLimit: 5000,
            granularity: globalFilters?.metricChartGranularity?.value,
          };
          var props = { selections: newSelections, user };
          var getDataPayload = wrapperChartObject(props);
          // console.log("useEffect payload for table cell", getDataPayload);
          var fetchProps = {
            payload: getDataPayload,
            cancelToken: source.token,
          };
          var getDataPromise = getData(fetchProps);
          getDataPromise
            .then((data) => {
              // console.log("transpose 2", data);
              setPivotData((latestState) => {
                const transposeRowUiDataFriendlyProps = {
                  getDataResponse: data.result.data,
                  columnData: transposeColumns,
                  columnIndex: 0,
                  dataLimit: selections.dataLimit,
                  pivotData: latestState,
                  selections,
                };
                const finalData = transposeRowUiDataFriendly(
                  transposeRowUiDataFriendlyProps
                );
                setShowTranposeTable(true);
                return {
                  result: finalData,
                  status: "success",
                  message: "",
                };
              });
            })
            .catch((json) => {
              const rowUiDataProps = {
                getDataResponse: [],
                columnData: columns,
                columnIndex: 0,
                dataLimit: selections.dataLimit,
                apiResponseLength: 0,
                errMessage: json.error,
              };
              const finalData = rowUiDataFriendly(rowUiDataProps);
              setPivotData({
                result: finalData,
                status: "success",
                message: "",
              });
              console.groupCollapsed("UI ERROR USE-EFFECT");
              console.log(json);
              console.groupEnd();
            });
        })
        .catch((json) => {
          const rowUiDataProps = {
            getDataResponse: [],
            columnData: columns,
            columnIndex: 0,
            dataLimit: selections.dataLimit,
            apiResponseLength: 0,
            errMessage: json.error,
          };
          const finalData = rowUiDataFriendly(rowUiDataProps);
          setPivotData({
            result: finalData,
            status: "success",
            message: "",
          });
          setPivotDataTotal(initialPivotData);
          console.groupCollapsed("UI ERROR USE-EFFECT");
          console.log(json);
          console.groupEnd();
        });
    }
  }, [pivotData.status]);

  // Pass data to parent whenever data changes
  useEffect(() => {
    onDataChange(pivotData);
  }, [pivotData]);

  const TableHeader = (props = {}) => {
    // * Destructure props
    const { head = {}, index = 0 } = props;

    // * Define required states
    const [mouseIn, setMouseIn] = useState(false);

    // * Define event handlers
    const handleMouseEnter = () => setMouseIn(true);
    const handleMouseLeave = () => setMouseIn(false);
    const handleMetricRowClick = () => {
      const payload = {
        sortedOn: head.id,
        orderBy: orderBy === "asc" ? "desc" : "asc",
      };
      let action = updatePivotAdvanceSort(payload);
      dispatchPivotState(action);

      action = updatePivotReloadEpoch();
      dispatchPivotState(action);
    };

    // * Define required variables
    const { isAllDimSortedOnThisMetric, orderBy } = getSortDetails(
      head,
      advanceSort
    );
    const isColSpanOne = head.colSpan === 1;
    let className = classes.tableCellHeader;
    if (index === 0) className += ` ${classes.sticky}`;
    const style = {
      zIndex: `${head.name === "Dimension" ? 10 : 0}`,
      // ...(isColSpanOne ? {} : { position: "initial" }),
    };
    const arrowType = orderBy === "asc" ? "arrow_drop_up" : "arrow_drop_down";

    return (
      <>
        <TableCell
          className={className}
          style={style}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          rowSpan={head.rowSpan}
          colSpan={head.colSpan}
        >
          <span
            style={{
              display: "flex",
              justifyContent: !isColSpanOne ? "flex-start" : "space-between",
              alignItems: "center",
            }}
          >
            {isColSpanOne && (
              <span
                style={{
                  maxWidth: "20px",
                  minWidth: "20px",
                }}
              ></span>
            )}
            <span
              // className={classes.tableHeadTitle}
              title={head.name}
              style={{
                maxWidth: "70px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                marginRight: "3px",
              }}
            >
              {head.name}
            </span>
            <span
              style={{
                maxWidth: "20px",
                minWidth: "20px",
              }}
            >
              {isAllDimSortedOnThisMetric && index !== 0 && isColSpanOne && (
                <SigviewIcon
                  iconName={arrowType}
                  style={{
                    fontSize: "20px !important",
                    // padding: "0px 5px 0px 0px",
                    color: themeColors["primaryColor"],
                    hoverColor: themeColors["primaryColor"],
                    cursor: "pointer",
                  }}
                  onClick={handleMetricRowClick}
                />
              )}
              {!isAllDimSortedOnThisMetric &&
                index !== 0 &&
                isColSpanOne &&
                mouseIn && (
                  <SigviewIcon
                    iconName={arrowType}
                    style={{
                      fontSize: "20px !important",
                      // padding: "0px 5px 0px 0px",
                      color: themeColors["primaryColor"],
                      hoverColor: themeColors["primaryColor"],
                      cursor: "pointer",
                    }}
                    onClick={handleMetricRowClick}
                  />
                )}
            </span>
          </span>
        </TableCell>
      </>
    );
  };

  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const actualColumns = columns[0];
  const transposeTableColumns = getTransposeTableHeaderColumns(actualColumns);
  const { row1Columns, row2Columns } = transposeTableColumns;

  return (
    <Box css={{ height: "100%", overflowX: "auto", boxSizing: "border-box" }}>
      {(pivotData.status === "loading" ||
        showTotalTable.status === "loading") && <Loader />}
      {pivotData.status === "success" && (
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableRowHeader}>
              {row1Columns.map((head, index) => (
                <TableHeader
                  head={head}
                  index={index}
                  key={`${head.id}_${index}`}
                />
              ))}
            </TableRow>
            <TableRow className={classes.tableRowHeader}>
              {row2Columns.map((head, index) => (
                <TableCell
                  key={index}
                  className={`${classes.tableCellHeaderRow2} ${classes.sticky}`}
                  style={{
                    top: "35px", // ! TODO: THIS IS HARD CODED. CHANGE THIS WHILE CHANGING THE STYLE
                  }}
                  rowSpan={head.rowSpan}
                  colSpan={head.colSpan}
                >
                  {head.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {showTotalTable.status === "loading" && <SigviewProgressBar />} */}
            {showTotalTable.status === "success" && (
              <PivotTableTotalRow
                row={pivotDataTotal.result[0]}
                columns={columns}
                setPivotDataTotal={setPivotDataTotal}
                selections={selections}
                user={user}
                allData={allData}
                globalFilters={globalFilters}
              />
            )}
            {showTotalTable.status === "success" && !showTranposeTable && (
              <SigviewProgressBar />
            )}
            {showTranposeTable &&
              pivotData.result.map((row, index) => (
                <PivotTableRow
                  row={row}
                  key={index}
                  columns={columns}
                  pivotData={pivotData}
                  setPivotData={setPivotData}
                  selections={selections}
                  user={user}
                  allData={allData}
                  globalFilters={globalFilters}
                  reloadTableEpoch={reloadTableEpoch}
                  setReloadTableEpoch={setReloadTableEpoch}
                  totalLength={pivotData.result.length}
                  rowIndex={index}
                />
              ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(PivotTransposeTable);
