// * Import required libraries
import React, { useState, useEffect, useReducer, useContext } from "react";
import axios from "axios";
import { connect } from "react-redux";
import LayoutTopSideBottomAdmin from "../../../layouts/LayoutTopSideBottomAdmin/LayoutTopSideBottomAdmin";
import orderBy from "lodash/orderBy";
import { isEmpty } from "lodash";

// * import lib component
import { Box } from "@material-ui/core";

// * Import custom components
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import LayoutAdminAttributes from "../../../layouts/LayoutAdminAttributes";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import SigviewSingleSelect from "../../../components/Common/SigviewSingleSelect";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SigviewTab from "../../../components/SigviewTab";
import SigviewSearchField from "../../../components/Common/SigviewSearchField";
import SigviewButton from "../../../components/Common/SigviewButton";
import DimensionForm from "./DimensionForm";
import MetricForm from "./MetricForm";
import CustomMetricForm from "./CustomMetricForm";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Import utils, config & static data
import { unWrapperviews } from "../../../utils/adminUtils";
import {
  unwrapperDimForm,
  unwrapperMetricForm,
  unwrapperCustomMetricForm,
  defaultDimensionState,
  defaultMetricState,
  defaultCustomMetricState,
  unwrapperGroupDnd,
  unwrapperGroupDataDnd,
  unWrapperFormula,
} from "../../../utils/attributeUtils";
import { getBreadcrumbsDataFromRoute } from "../../../utils/utils";
import { config } from "../../../config/config";
import { masterTrackGaEvent } from "../../../../js/services/ga/index";

// * Import redux utils
import {
  updateUserScreen,
  updateDialogInfo,
  updateChangeFlag,
} from "../../../redux/actions";

//  * Import APIs
import {
  readUserById,
  readGroupsByOrg,
  readGroupById,
  updateUser,
  createUser,
  emailCheckUser,
  readAttributeGroupsByOrgViewId,
  readAttributeFormula,
  readAvailableGroupData,
} from "../../../services/api";
import { useHistory } from "react-router";

// TODO Move the below to adminAttributesUtils.js
const attributeTypeData = [
  {
    id: "dimensions",
    name: "Dimensions",
  },
  { id: "metrics", name: "Metrics", disabled: false },
  {
    id: "customMetrics",
    name: "Custom Metrics",
    disabled: false,
  },
];
const getActiveAttributeData = (activeViewData, activeAttributeType) => {
  if (activeAttributeType === "dimensions") {
    return orderBy(activeViewData.dimensions, ["title"], ["asc"]);
  } else if (activeAttributeType === "metrics") {
    return orderBy(activeViewData.metrics, ["title"], ["asc"]);
  } else if (activeAttributeType === "customMetrics") {
    return orderBy(activeViewData.customMetrics, ["title"], ["asc"]);
  }
  return [];
};

const getObject = (value, data) => {
  console.log("getObject", value, data);

  let obj = data.find((item) => item.id === value);
  return obj;
};

const getFirstAttribute = (activeView, activeAttributeType, viewsData) => {
  const activeViewData = viewsData.result.find((row) => row.id === activeView);

  const activeAttributeData = getActiveAttributeData(
    activeViewData,
    activeAttributeType
  );
  const activeAttributeDataModified = activeAttributeData.map((item) => {
    return { ...item, id: item._id, name: item.title };
  });
  return activeAttributeDataModified[0];
};

function Attributes(props) {
  // * Destructure props
  const { ReduxDispatcher, user, allData = {} } = props;
  const { views: viewsData } = allData;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define contexts
  const history = useHistory();

  // REDUX FLAG

  let reduxFlag =
    user.screen?.newActiveAttribute?.metadata?.crudType !== "update";

  // * Define static variables
  const viewsDataUi = unWrapperviews(viewsData.result);

  // * Define required states
  const [activeView, setActiveView] = useState(viewsDataUi[0]?.id);
  const [activeAttributeType, setActiveAttributeType] = useState("dimensions");
  const [activeAttribute, setActiveAttribute] = useState({
    crudType: "create", // create or update
    metadata: defaultDimensionState,
  });
  const [search, setSearch] = useState("");
  const [groupsData, setGroupsData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });

  const [formulaData, setFormulaData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });

  const [availableGroupData, setAvailableGroupData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });

  const [showForm, setShowForm] = useState(true);

  // * Define required side effects

  useEffect(() => {
    if (showForm === false) setShowForm(true);

    console.log("useEffect", activeAttribute);
    setActiveAttribute({
      crudType: "update",
      metadata: getFirstAttribute(activeView, activeAttributeType, viewsData),
    });
  }, [showForm]);

  console.log("showForm", showForm);

  useEffect(() => {
    const readAttributeFormulaPromise = readAttributeFormula();

    readAttributeFormulaPromise
      .then((data) => {
        setFormulaData({
          result: data?.result?.data,
          status: "success",
          messsage: "",
        });
      })
      .catch((json) => {
        setFormulaData({
          result: [],
          status: "error",
          messsage: "API failed",
        });
      });

    if (user.screen?.newActiveAttribute?.metadata?.crudType === "update") {
      setActiveView(user.screen?.newActiveAttribute?.metadata?.activeView);

      setActiveAttributeType(
        user.screen?.newActiveAttribute?.metadata?.activeAttributeType
      );

      setActiveAttribute({
        crudType: "update",
        metadata: user.screen?.newActiveAttribute?.payload,
      });
    }

    const newActiveAttribute = {
      payload: {},
      metadata: {},
    };
    var action = updateUserScreen("newActiveAttribute", newActiveAttribute);
    ReduxDispatcher(action);
  }, []);

  // Fetch  groupsData and set groupsData
  useEffect(() => {
    // Make fetch call using axios
    const readAllGroupsViews = readGroupsByOrg();
    readAllGroupsViews
      .then((data) => {
        setGroupsData({
          result: data?.result?.data,
          status: "success",
          messsage: "",
        });
      })
      .catch((json) => {
        setGroupsData({
          result: [],
          status: "error",
          messsage: "API failed",
        });
      });
  }, []);

  useEffect(() => {
    setAvailableGroupData({
      result: [],
      status: "loading",
      messsage: "",
    });
    // Make fetch call using axios
    const readAvailableGroupDataPromise = readAvailableGroupData({
      org: "OpenX",
      view: activeView,
    });

    readAvailableGroupDataPromise
      .then((data) => {
        setAvailableGroupData({
          result: data?.result?.data.map((el) => el._id),
          status: "success",
          messsage: "",
        });
      })
      .catch((json) => {
        setAvailableGroupData({
          result: [],
          status: "error",
          messsage: "API failed",
        });
      });
  }, [activeView]);

  useEffect(() => {
    if (reduxFlag) {
      setActiveAttribute({
        crudType: "update",
        metadata: getFirstAttribute(activeView, activeAttributeType, viewsData),
      });
    }
  }, [activeView, activeAttributeType]);

  // useEffect(() => {
  //   // handleActiveAttribute()

  //   // console.log("activeAttributeDataModified", activeAttributeData[0]);

  //   const obj = {
  //     crudType: "update",
  //     metadata: activeAttribute,
  //   };

  //   setActiveAttribute({ ...obj });
  // }, [activeView]);

  // * Define requried event handlers
  const handleActiveTab = (e, id) => {
    const activeTab = id;
    const value = { activeNav: "admin", activeTab };
    const action = updateUserScreen(null, value);
    ReduxDispatcher(action);
  };

  const handleBreadcrumbChange = (event, value) => {
    history.push(value.path);
  };
  const handleActiveView = (value, id) => {
    setActiveView(value);

    setActiveAttribute({});
  };
  const handleActiveAttributeType = (value, id) => {
    setActiveAttributeType(value);
    setActiveAttribute({});
  };
  const handleActiveAttribute = (e, value) => {
    masterTrackGaEvent({
      category: "Attribute",
      action: `Edit ${value._id}`,
      label: value._id,
    });
    const obj = {
      crudType: "update",
      metadata: value,
    };
    if (value._id !== activeAttribute.metadata._id) {
      setActiveAttribute({ ...obj });
    }
  };

  const handleSearch = (e, value) => {
    setSearch(value);
  };

  const handleAddNew = () => {
    masterTrackGaEvent({
      category: "Attribute",
      action: `Add New ${activeAttributeType}`,
      label: activeAttributeType,
    });

    // TODO Convert to switch case with a default case same as attributeDimension.js
    if (activeAttributeType === "dimensions") {
      const obj = {
        crudType: "create",
        metadata: {
          ...defaultDimensionState,
          crudType: "create",
          activeView: {
            ...defaultDimensionState.activeView,
            value: activeView,
          },
          name: {
            ...defaultDimensionState.name,
            value: `SampleDimension${filteredData.length + 1}`,
            status: "valid",
          },
          backendName: {
            ...defaultDimensionState.name,
            value: `SAMPLEDIMENSION${filteredData.length + 1}`,
            status: "valid",
          },
        },
      };

      setActiveAttribute({ ...obj });
    }
    if (activeAttributeType === "metrics") {
      const obj = {
        crudType: "create",
        metadata: {
          ...defaultMetricState,
          crudType: "create",
          activeView: {
            ...defaultMetricState.activeView,
            value: activeView,
          },
          name: {
            ...defaultDimensionState.name,
            value: `SampleMetric${filteredData.length + 1}`,
            status: "valid",
          },
          backendName: {
            ...defaultDimensionState.name,
            value: `SAMPLEMETRIC${filteredData.length + 1}`,
            status: "valid",
          },
        },
      };

      setActiveAttribute({ ...obj });
    }
    if (activeAttributeType === "customMetrics") {
      const obj = {
        crudType: "create",
        metadata: {
          ...defaultCustomMetricState,
          crudType: "create",
          activeView: {
            ...defaultCustomMetricState.activeView,
            value: activeView,
          },

          name: {
            ...defaultDimensionState.name,
            value: `SampleCustomMetric${filteredData.length + 1}`,
            status: "valid",
          },
        },
      };

      setActiveAttribute({ ...obj });
    }
  };

  // * Define required static variables
  const activeTab = user.screen.activeTab;
  const breadcrumbsData = getBreadcrumbsDataFromRoute(user, history);
  const layoutTopSideBottomAdminProps = {
    activeTab,
    handleActiveTab,
  };

  const activeViewData = viewsData.result.find((row) => row.id === activeView);

  const activeAttributeData = getActiveAttributeData(
    activeViewData,
    activeAttributeType
  );
  const activeAttributeDataModified = activeAttributeData
    .map((item) => {
      return { ...item, id: item._id, name: item.title };
    })
    .filter(
      (item) =>
        !["day", "hour", "month", "week"].includes(item.title.toLowerCase())
    );
  const filteredData = activeAttributeDataModified.filter((item) => {
    if (item.title.toLowerCase().includes(search.toLowerCase())) {
      return item;
    }
  });
  const usableAttributes = activeViewData.metrics.concat(
    activeViewData.customMetrics
  );

  // Styles
  const box1Container = {
    padding: "0px 10px",
  };
  const box1 = {
    padding: "5px 0px",
    width: "100%",
    margin: "0px",
    width: "50%",
    boxSizing: "border-box",
    display: "inline-block",
  };
  const searchFieldContainerBox = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "7px",
    padding: "5px 10px",
  };
  const box2 = { width: "130px", display: "inline-block" };
  const box3 = {
    // width: "100px",
    display: "inline-block",
    display: "flex",
    justifyContent: "flex-end",
  };
  const tabContainerBox = { height: "calc(100% - 89px)", overflow: "auto" };
  const sigviewSearchFieldStyle = {
    wrapperHeight: "20px",
    iconSize: "12px",
    textFieldFontSize: "10px",
    placeholderFontSize: "9px",
  };

  const noAttributeMessageBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  };

  // * Define required JSX helpers
  // TODO: Can be later converted into a function which caters all the states
  const initialDimensionFormSelection =
    activeAttribute.crudType === "update" &&
    activeAttributeType === "dimensions"
      ? unwrapperDimForm(activeAttribute.metadata, activeView)
      : activeAttribute.crudType === "create"
      ? activeAttribute.metadata
      : defaultDimensionState;

  const initialMetricFormSelection =
    activeAttribute.crudType === "update" && activeAttributeType === "metrics"
      ? unwrapperMetricForm(activeAttribute.metadata, activeView)
      : activeAttribute.crudType === "create"
      ? activeAttribute.metadata
      : defaultMetricState;

  const initialCustomMetricFormSelection =
    activeAttribute.crudType === "update" &&
    activeAttributeType === "customMetrics"
      ? unwrapperCustomMetricForm(activeAttribute.metadata, activeView)
      : activeAttribute.crudType === "create"
      ? activeAttribute.metadata
      : defaultCustomMetricState;

  const noAttributeMessage =
    " No attribute selected. Please select an attribute or add a new one.";

  return (
    <LayoutTopSideBottomAdmin {...layoutTopSideBottomAdminProps}>
      <SigviewBreadcrumb
        data={breadcrumbsData}
        onClick={handleBreadcrumbChange}
      />
      <LayoutAdminAttributes>
        <>
          <Box css={box1Container}>
            <Box css={{ ...box1, paddingRight: "10px" }}>
              <SigviewTypography
                variant="pSmall"
                style={{
                  height: "max-content",
                }}
              >
                Select View:
              </SigviewTypography>
              <SigviewSingleSelect
                data={viewsDataUi}
                value={activeView}
                maxWidth="100px"
                width="100%"
                minWidth="100%"
                onChange={handleActiveView}
              />
            </Box>
            <Box css={box1}>
              <SigviewTypography
                variant="pSmall"
                style={{ height: "max-content" }}
              >
                Select Attribute Type:
              </SigviewTypography>
              <SigviewSingleSelect
                data={attributeTypeData}
                value={activeAttributeType}
                maxWidth="100%"
                width="100%"
                minWidth="100%"
                onChange={handleActiveAttributeType}
              />
            </Box>
          </Box>

          <Box css={searchFieldContainerBox}>
            <Box css={box2}>
              <SigviewSearchField
                onChange={handleSearch}
                customStyle={sigviewSearchFieldStyle}
              />
            </Box>
            <Box css={box3}>
              <SigviewButton
                title="Add New"
                variant="containedPrimary"
                style={{
                  buttonHeight: "20px",
                  fontSize: "11px !important",
                }}
                onClick={handleAddNew}
                customClassName={activeAttributeType + "AddNew-GA"}
              />
            </Box>
          </Box>

          <Box css={tabContainerBox} className="sigview-styled-scroller">
            {filteredData.length === 0 && (
              <div className="no-data-container">
                <div className="no-data-image"></div>
                <p className="no-data">No Data available</p>
              </div>
            )}
            <SigviewTab
              data={filteredData}
              type="dataOnly"
              isEditable={true}
              handleActiveView={handleActiveAttribute}
              activeView={activeAttribute.metadata}
            />
          </Box>
        </>

        <>
          {(groupsData.status === "loading" ||
            availableGroupData.status === "loading") && <Loader />}
          {(groupsData.status === "error" ||
            availableGroupData.status === "error") && (
            <ErrorHandler
              message="Error in fetching groups data"
              reloadFlag={true}
              onReload={() => {}}
            />
          )}
          {groupsData.status === "success" &&
            availableGroupData.status === "success" &&
            showForm && (
              <>
                {!isEmpty(activeAttribute) && (
                  <>
                    {activeAttributeType === "dimensions" && (
                      <DimensionForm
                        initialSelections={initialDimensionFormSelection}
                        groupsData={unwrapperGroupDnd(groupsData.result)}
                        ReduxDispatcher={ReduxDispatcher}
                        viewsData={viewsData}
                        setShowForm={setShowForm}
                        availableGroupData={availableGroupData.result}
                        activeAttributeType={activeAttributeType}
                      />
                    )}
                    {activeAttributeType === "metrics" && (
                      <MetricForm
                        initialSelections={initialMetricFormSelection}
                        groupsData={unwrapperGroupDnd(groupsData.result)}
                        ReduxDispatcher={ReduxDispatcher}
                        viewsData={viewsData}
                        setShowForm={setShowForm}
                        availableGroupData={availableGroupData.result}
                        activeAttributeType={activeAttributeType}
                      />
                    )}
                    {formulaData.status === "loading" && <Loader />}
                    {formulaData.status === "error" && (
                      <ErrorHandler
                        message="Error in fetching group data"
                        reloadFlag={true}
                        onReload={() => {}}
                      />
                    )}
                    {formulaData.status === "success" &&
                      activeAttributeType === "customMetrics" && (
                        <CustomMetricForm
                          initialSelections={initialCustomMetricFormSelection}
                          groupsData={unwrapperGroupDnd(groupsData.result)}
                          ReduxDispatcher={ReduxDispatcher}
                          viewsData={viewsData}
                          usableAttributes={orderBy(
                            usableAttributes,
                            ["title"],
                            ["asc"]
                          )}
                          setShowForm={setShowForm}
                          formulaData={unWrapperFormula(formulaData.result)}
                          availableGroupData={availableGroupData.result}
                        />
                      )}
                  </>
                )}
                {isEmpty(activeAttribute) && (
                  <Box css={noAttributeMessageBox}>
                    <div className="no-data-container">
                      <div className="no-data-image"></div>
                      <p className="no-data">{noAttributeMessage}</p>
                    </div>
                  </Box>
                )}
              </>
            )}
        </>
      </LayoutAdminAttributes>
    </LayoutTopSideBottomAdmin>
  );
}

export default Attributes;
