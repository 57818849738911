// * Import required libraries
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// * Import custom components
import SigviewSimpleText from "../Common/SigviewSimpleText";

// * Import utils
import { getTimeLag } from "../../utils/utils";

function DataLag(props) {
  const { t } = useTranslation();
  const { allData } = props;

  return (
    <>
      {allData.dateRange.status === "success" && (
        <SigviewSimpleText
          value={`${t("Data Lag:")} ${getTimeLag(allData)}`}
          style={{ border: "none", padding: "0px 10px" }}
        />
      )}
    </>
  );
}

DataLag.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  changeFlag: state.changeFlag,
});

export default connect(mapStateToProps)(DataLag);
