// * Import required libraries
import React, { useState, useReducer, useEffect, useContext } from "react";
import { connect } from "react-redux";
import orderBy from "lodash.orderby";
import axios from "axios";
import { useHistory } from "react-router";

// * Import components
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import SigviewStepper from "../../../components/SigviewStepper";
import SigviewButton from "../../../components/Common/SigviewButton";
import { Details, KpisPopover, ChartsPopover } from "./DatastoryCreateHelpers";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Import utils/data
import useReducerLogger from "../../../utils/useReducerLogger";
// * Import hooks
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import {
  wrapperDatastory,
  getInitialDsForm,
  getInitialDsStepper,
  validateDsForm,
  unwrapperChartsPopoverData,
  unwrapperChartsPopoverSelections,
} from "../../../utils/dsUtils";
import { getBreadcrumbsDataFromRoute } from "../../../utils/utils";
import standaloneDsReducer from "../../../redux/reducers/standaloneDs";
import { masterTrackGaEvent } from "../../../services/ga";
import {
  unwrapperChartObject,
  wrapperChartObject,
} from "../../../utils/chartObjectUtils";
import allDatePresets from "../../../../assets/data/allDatePresets.json";
import allTimezones from "../../../../assets/data/allTimezones.json";

// * Import config
import { config } from "../../../config/config";

// * Import action creators
import {
  updateStandaloneDsForm,
  updateUserScreen,
  updateStandaloneDsFormMultipleKpis,
  updateStandaloneDsFormMultipleCharts,
  updateTimeFiltersWithDefaultDates,
  updateGlobalFiltersDimensionFilters,
  updateGlobalFiltersTimeFilters,
  updateGlobalFiltersFormAnalyzeFiltersReadOnly,
} from "../../../redux/actions";
import timeFiltersReducer from "../../../redux/reducers/timeFilters";

// * Import APIs
import { getChartObjectList, saveDs, updateDs } from "../../../services/api";
import globalFilters from "../../../redux/reducers/globalFilters";

// * Define static variables
const initialData = { status: "loading", message: "", data: [] };

function DatastoryCreate(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    dispatch: ReduxDispatcher,
    user,
    allData,
    globalFilters = {},
    // initialDsForm = {},
  } = props;
  //Defining the main reducer
  const getInitialDsFormProps = {
    user,
    allData,
    title: user.screen.activeDatastory.title,
  };
  const initialDsForm = getInitialDsForm(getInitialDsFormProps);

  const pathname = window.location.pathname;
  const rootPage = pathname.split("/")[1];

  console.log("initialDsForm", initialDsForm, "globalFilters", globalFilters);

  initialDsForm.dimensionFilters = {
    ...initialDsForm.dimensionFilters,
    value: globalFilters.dimensionFilters,
  };

  initialDsForm.timeFilters = {
    ...initialDsForm.timeFilters,
    value: globalFilters.timeFilters,
  };

  initialDsForm.rollingDateType = {
    ...initialDsForm.rollingDateType,
    value: globalFilters.analyzeFiltersReadOnly.rollingDateType.value,
  };

  const [dsForm, dispatchDsForm] = useReducer(
    useReducerLogger(standaloneDsReducer),
    initialDsForm
  );
  const initialDsStepper = getInitialDsStepper(
    user.screen.activeDatastory.elementType
  );
  const [stepperState, setStepperState] = useState(initialDsStepper);
  const [savedCharts, setSavedCharts] = useState(initialData);
  const [reloadSavedCharts, setReloadSavedCharts] = useState(false);

  // * Define required hooks
  const history = useHistory();

  useEffect(() => {
    return () => {
      // Revert to inital state in redux when component unmounts
      // This is to cater to case when user switches between tabs
      ReduxDispatcher(updateGlobalFiltersFormAnalyzeFiltersReadOnly());
    };
  }, []);

  useUpdateEffect(() => {
    if (dsForm.dimensionFilterType.value === "widget") {
      let payload = { value: [] };
      let action = updateGlobalFiltersDimensionFilters(payload);
      ReduxDispatcher(action);
    } else if (dsForm.dimensionFilterType.value === "dashboard") {
      let payload = { value: dsForm.dimensionFilters.value };
      let action = updateGlobalFiltersDimensionFilters(payload);
      ReduxDispatcher(action);
    }
  }, [dsForm.dimensionFilterType.value, savedCharts]);

  useUpdateEffect(() => {
    if (dsForm.timeFilterType.value === "widget") {
      const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarDashboard;
      const timeFiltersAction = updateTimeFiltersWithDefaultDates({
        selectedTimezone: allTimezones[1],
        startDateEpoch: allData.dateRange.data.startDate,
        endDateEpoch: allData.dateRange.data.endDate,
        allDatePresets,
        format: config.hardCoded.datetimeFormat,
        selectedDatePreset: "last_2_days",
        daysLimit: calendarDaysLimits,
        isComparisonOn: false,
      });
      const defaultTimeFilters = timeFiltersReducer({}, timeFiltersAction);
      let payload = { value: defaultTimeFilters };
      var action = updateGlobalFiltersTimeFilters(payload);
      ReduxDispatcher(action);
    } else if (dsForm.timeFilterType.value === "dashboard") {
      let payload = { value: dsForm.timeFilters.value };
      var action = updateGlobalFiltersTimeFilters(payload);
      ReduxDispatcher(action);
    }
  }, [dsForm.timeFilterType.value, savedCharts]);

  //Defining required useEffects
  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
    };
    const getChartObjectListPromise = getChartObjectList(fetchProps);
    getChartObjectListPromise
      .then((responseData) => {
        let newData = {
          status: "success",
          message:
            responseData?.result?.data?.length || [].length !== 0
              ? ""
              : "No charts to display",
          data: config.hardCoded.convertAllTablesToMultiTable(
            responseData?.result?.data || []
          ),
        };
        setSavedCharts(newData);
      })
      .catch((json) => {
        if (json.error !== config.hardCoded.queryCancelled) {
          let newData = { status: "error", message: json.error, data: [] };
          setSavedCharts(newData);
          console.groupCollapsed("UI ERROR");
          console.log("ERROR ->", json.error);
          console.groupEnd();
        }
      });

    return () => {
      setSavedCharts(initialData);
      //Cancel all previous fetch calls
      if (source) source.cancel();
    };
  }, [reloadSavedCharts]);

  // Defining required event handlers
  const handleStepperChange = (stepperState) => {
    setStepperState(stepperState);
  };
  // This function will help us add validation at each step: if needed
  function validateCurrentStep(state, payload) {
    const { activeStep } = state;

    const { form } = payload;
    return { flag: true, message: "" };
  }
  const handleDsFormChange = (key, value) => {
    const payload = { key, value };
    const action = updateStandaloneDsForm(payload);
    dispatchDsForm(action);
    var payloadData = { value: value };
    if (key === "dimensionFilters") {
      var actionDimFilter = updateGlobalFiltersDimensionFilters(payloadData);
      ReduxDispatcher(actionDimFilter);
    } else if (key === "timeFilters") {
      var actionTimeFilter = updateGlobalFiltersTimeFilters(payloadData);
      ReduxDispatcher(actionTimeFilter);
    }
  };
  const handleSavedChartsReload = () =>
    setReloadSavedCharts(!reloadSavedCharts);

  const handleSaveAndLaunch = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "CreateDatastory",
      action: "Launch",
      label: "Save",
    });
    let backendDs = wrapperDatastory({ uiDs: dsForm, user });
    backendDs = { ...backendDs };
    const fetchProps = {
      payload: { ...backendDs },
    };
    // Add Dashboard Loader
    var action = updateUserScreen("isDashboardLoading", true);
    ReduxDispatcher(action);
    const saveDsPromise = saveDs(fetchProps);
    saveDsPromise
      .then(() => {
        // // Remove Dashboard Loader
        // var action = updateUserScreen("isDashboardLoading", false);
        // ReduxDispatcher(action);

        // // Update snackbar
        const snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Datastory saved successfully!",
        };
        // var action = updateUserScreen("snackbar", snackbarPayload);
        // ReduxDispatcher(action);

        // Remove Dashboard Loader
        const value = {
          isDashboardLoading: false,
          snackbar: snackbarPayload,
          activeNav: "datastory",
          activeTab: "dashboard",
          activeDsCategory: "saved",
          activeDatastory: {
            ...backendDs,
            dsCategory: "Saved",
            elementType: "edit",
          },
        };
        const action = updateUserScreen(null, value);
        ReduxDispatcher(action);
        history.push("/datastory/dashboard");
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        const message = json.error || "Datastory saved failed";

        // Remove Dashboard Loader
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);

        // Update snackbar
        const snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: message,
        };
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
      });
  };

  const handleUpdateAndLaunch = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "EditDatastory",
      action: "Launch",
      label: "Update",
    });
    let backendDs = wrapperDatastory({ uiDs: dsForm, user });
    backendDs = { ...backendDs };
    const fetchProps = {
      payload: { ...backendDs },
    };
    // Add Dashboard Loader
    var action = updateUserScreen("isDashboardLoading", true);
    ReduxDispatcher(action);
    const updateDsPromise = updateDs(fetchProps);
    updateDsPromise
      .then(() => {
        const snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Datastory updated successfully!",
        };
        const value = {
          isDashboardLoading: false,
          snackbar: snackbarPayload,
          activeNav: "datastory",
          activeTab: "dashboard",
          activeDsCategory: "saved",
          activeDatastory: {
            ...backendDs,
            dsCategory: "Saved",
            elementType: "edit",
          },
        };
        const action = updateUserScreen(null, value);
        ReduxDispatcher(action);
        history.push("/datastory/dashboard");
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        const message = json.error || "Datastory update failed";

        // Remove Dashboard Loader
        var action = updateUserScreen("isDashboardLoading", false);
        ReduxDispatcher(action);

        // Update snackbar
        const snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: message,
        };
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
      });
  };

  const handleKpisChange = (event, modifiedKpis) => {
    // * This reducer case is using allData and utility functions which might throw an error
    // * Hence this catch block
    try {
      const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarDashboard;
      const timeFiltersAction = updateTimeFiltersWithDefaultDates({
        selectedTimezone: allTimezones[1],
        startDateEpoch: allData.dateRange.data.startDate,
        endDateEpoch: allData.dateRange.data.endDate,
        allDatePresets,
        format: config.hardCoded.datetimeFormat,
        selectedDatePreset: "last_2_days",
        daysLimit: calendarDaysLimits,
        isComparisonOn: false,
      });
      const defaultTimeFilters = timeFiltersReducer({}, timeFiltersAction);
      const payload = { modifiedKpis, allData, defaultTimeFilters };
      const action = updateStandaloneDsFormMultipleKpis(payload);
      dispatchDsForm(action);
    } catch (error) {
      console.error("UI ERROR");
      console.groupCollapsed("DETAILS");
      console.log(error);
      console.groupEnd();
    }
  };

  const handleChartsChange = (event, modifiedChartsIds) => {
    // Convert chartIds to proper ui selection objects
    let modifiedCharts = modifiedChartsIds.map((chartId) => {
      // console.log("chartId", chartId);
      // console.log("savedCharts.data", savedCharts.data);
      const backendChartObj = savedCharts.data.find(
        (savedChart) => savedChart._id === chartId
      );
      // console.log("backendChartObj", backendChartObj);
      if (backendChartObj) {
        var chartForUi = unwrapperChartObject({
          allData: allData,
          user: user,
          payload: { ...backendChartObj },
        });
        return chartForUi;
      } else if (crudType === "edit") {
        // In case of edit, savedCharts.data will never have that information,
        // That information will be present in dsForm.selectedCharts
        // So pick from there
        var chartForUi = dsForm.selectedCharts.value[chartId];
        if (chartForUi) return chartForUi;
      }
    });
    modifiedCharts = modifiedCharts.filter((row) => row !== undefined);

    // * This reducer case is using allData and utility functions which might throw an error
    // * Hence this catch block
    try {
      const payload = { modifiedCharts, allData };
      const action = updateStandaloneDsFormMultipleCharts(payload);
      dispatchDsForm(action);
    } catch (error) {
      console.error("UI ERROR");
      console.groupCollapsed("DETAILS");
      console.log(error);
      console.groupEnd();
    }
  };

  const handleBreadcrumbChange = (event, value) => {
    const data = {
      activeNav: "datastory",
      activeTab: value,
      activeDsCategory: "saved",
    };
    const action = updateUserScreen(null, data);
    ReduxDispatcher(action);
    history.push(value.path);
  };

  // Defining required static variables
  const crudType = user.screen.activeDatastory.elementType;
  const isPageCreate = crudType !== "edit";
  // const pageTitle =
  //   crudType === "edit"
  //     ? `Edit Datastory - ${dsForm.datastoryName.value}`
  //     : "Create Datastory";
  const pageTitle = `${crudType === "edit" ? "Edit" : "Create"} Datastory - ${
    dsForm.datastoryName.value
  }`;
  const plotlyMetricsMod = allData.plotlyMetrics.map((row) => ({
    id: row._id,
    name: row._title,
    disabled: false,
    checked: false,
  }));
  // Order By Data
  const orderedArray = orderBy(plotlyMetricsMod, "name");
  const { status: dsFormStatus, message: dsFormMessage } =
    validateDsForm(dsForm);
  const isSaveDisabled = dsFormStatus === "invalid";
  const actionButtonTitle = isPageCreate
    ? "Save and Launch"
    : "Update and Launch";
  const actionButtonOnClick = isPageCreate
    ? handleSaveAndLaunch
    : handleUpdateAndLaunch;

  // * Making data for charts popover
  // A. chartsPopoverData
  const chartsPopoverDataProps = {
    dsForm,
    savedCharts,
    allData,
    user,
  };
  const chartsPopoverData = unwrapperChartsPopoverData(chartsPopoverDataProps);
  // B. chartsPopoverSelections
  const chartsPopoverSelectionsProps = {
    dsForm,
  };

  const handleGoHome = (user) => {
    history.push("/datastory");
  };

  const chartsPopoverSelections = unwrapperChartsPopoverSelections(
    chartsPopoverSelectionsProps
  );
  const breadcrumbData = getBreadcrumbsDataFromRoute(user, history);

  const handleAddFilters = () => {
    const handleApplyFilters = (payload, filterType) => {
      // switch (filterType) {
      //   case "dimensions":
      //     var action = replaceAllDimensionFilters(payload);
      //     var newDimensionFilters = dimensionFiltersReducer(
      //       wsForm.dimensionFilters.value,
      //       action
      //     );
      //     handleDimensionFiltersChange(newDimensionFilters);
      //     break;
      //   // case "metrics":
      //   //   var action = replaceAllMetricFilters(payload);
      //   //   dispatchMetricFilters(action);
      //   //   break;
      //   // case "time":
      //   //   onTimeFiltersChange(payload);
      //   //   break;
      // }
    };

    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Open Filters",
      label: "Sidenav",
    });
    const commonGlobalFilterProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: false,
      showMetricFilters: false,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      timeFilters: user.timeFilters, //for initializing filters
      dimensionFilters: [], //for initializing filters
      metricFilters: [], //for initializing filters
      // settings: dimensionFiltersSettings,
      filtersDimData: allData.plotlyDimensions,
      filtersMetricsData: allData.plotlyMetrics,
      selections: {},
    };
    //Open Global Filters
    const newGlobalFiltersProps = {
      ...commonGlobalFilterProps,
      activeDimensionFilter: {},
      handleApplyFilters, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };

    const action = updateUserScreen("globalFilters", newGlobalFiltersProps);
    ReduxDispatcher(action);
  };

  const additionalSidenavItems = [
    {
      id: "home",
      name: "Home",
      tooltip: "Go back to Datastory home",
      elementId: "dsBackToHome",
      iconId: "custom-home-icon",
      customClass: "sidenav-home",
      onClick: (user) => handleGoHome(user),
      isVisible: true,
    },
    {
      id: "filters",
      name: "Filters",
      tooltip: "Open filters",
      elementId: "filtersInSidenav",
      iconId: "custom-filter-icon",
      customClass: "sidenav-filters",
      onClick: (user) => handleAddFilters(),
      isVisible: true,
    },
  ];
  const sidenavItemsOrderInfo = {
    home: 1,
    filters: 2,
    reports: 3,
    alerts: 4,
  };
  const tabName = 'datastory-create';
  const sidenavProps = { additionalSidenavItems, sidenavItemsOrderInfo, tabName };

  return (
    <>
      <LayoutTopSideBottom sidenavProps={sidenavProps}>
        <SigviewBreadcrumb
          data={breadcrumbData}
          onClick={handleBreadcrumbChange}
        />
        <section className="main-content-with-title">
          <SigviewStepper
            initialState={stepperState}
            form={dsForm}
            formValidator={validateCurrentStep}
            onChange={handleStepperChange}
            user={user}
            renderButton={() => (
              <SigviewButton
                disabled={isSaveDisabled}
                onClick={actionButtonOnClick}
                title={actionButtonTitle}
                variant="contained"
                customClassName={`datastory-${actionButtonTitle.replace(
                  / +/g,
                  ""
                )}-GA`}
              />
            )}
          >
            <Details
              dsForm={dsForm}
              handleDsFormChange={handleDsFormChange}
              allData={allData}
              globalFilters={globalFilters}
              user={user}
              ReduxDispatcher={ReduxDispatcher}
              isEdit={crudType === "edit"}
              className={`${rootPage}AddFilters-GA`}
            />
            {/* <Charts
              data={savedCharts}
              allData={allData}
              user={user}
              handleReload={handleSavedChartsReload}
              themeColors={themeColors}
              value={dsForm.selectedCharts.value}
              onChange={handleDsFormChange}
              ReduxDispatcher={ReduxDispatcher}
              dsForm={dsForm}
              crudType={crudType}
            /> */}
            <ChartsPopover
              allData={allData}
              user={user}
              ReduxDispatcher={ReduxDispatcher}
              themeColors={themeColors}
              initialData={chartsPopoverData}
              initialSelections={chartsPopoverSelections}
              onChange={handleChartsChange}
              handleReload={handleSavedChartsReload}
              dsForm={dsForm}
              crudType={crudType}
            />
            {/* <Kpis
              data={orderedArray}
              onChange={handleKpisChange}
              value={dsForm.selectedKpis.value}
              allData={allData}
              crudType={crudType}
              user={user}
              ReduxDispatcher={ReduxDispatcher}
            /> */}
            <KpisPopover
              user={user}
              allData={allData}
              ReduxDispatcher={ReduxDispatcher}
              initialData={orderedArray}
              initialSelections={Object.keys(dsForm.selectedKpis.value)}
              onChange={handleKpisChange}
              settings={{ maxLimit: config.hardCoded.kpisMaxLimitInDs }}
            />
          </SigviewStepper>
        </section>
      </LayoutTopSideBottom>
      {/* <SigviewCommon /> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(DatastoryCreate);
