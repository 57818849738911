// Import required libraries
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";

// Import custom components
import ErrorHandler from "../ErrorHandler/ErrorHandler";

// Import styles
import "./Nav.scss";

// Import config
import { config } from "../../config/config";

// Import action creators

// Import utils
import navDataJson from "../../../assets/data/navData.json";
import { masterTrackGaEvent } from "../../services/ga";

const initialNavData = {
  status: "loading",
  message: "",
  data: [],
};

function Nav(props) {
  const { dispatch: ReduxDisaptcher, user, allData } = props;

  //Making required state
  const [navData, setNavData] = useState(initialNavData);
  const { t } = useTranslation();

  // * Define required hooks
  // * Define contexts
  const history = useHistory();

  //Making required useEffects
  //To update navData on first mount and everytime featureAccessList changes
  useEffect(() => {
    let runFlag =
      history.location.pathname === "/analyze/dashboard/create" &&
      navData.data.length > 0 &&
      navData.data[0].id === "analyze" &&
      navData.data[0].to !== "/analyze/dashboard/create";

    if (runFlag) {
      let newAnalyzeDataArr = { ...navData.data[0] };
      navData.data.shift();
      newAnalyzeDataArr = {
        ...newAnalyzeDataArr,
        to: "/analyze/dashboard/create",
      };
      navData.data.unshift(newAnalyzeDataArr);
      setNavData({ ...navData, data: navData.data });
    }
    return () => {
      runFlag = false;
    };
  }, [navData]);

  useEffect(() => {
    if (allData.featureAccessList.status === "loading") {
      setNavData(initialNavData);
    } else if (allData.featureAccessList.status === "error") {
      setNavData({
        status: "error",
        message: "Something went wrong",
        data: [],
      });
    } else {
      const navDataFinal = navDataJson.filter((navRow) => {
        const navItemFeatureList = navRow.featureList;
        const condition = navRow.featureCondition;
        var finalFlagArr = [];
        for (const requiredFeature of navItemFeatureList) {
          const isRequiredFeatureAvailable =
            user.uiFeatureList[requiredFeature];
          finalFlagArr.push(isRequiredFeatureAvailable);
        }
        if (condition === "or") {
          return finalFlagArr.some((flag) => flag === true);
        }
        if (condition === "and") {
          return finalFlagArr.every((flag) => flag === true);
        }
        return false;
      });
      if (navDataFinal.length > 0) {
        setNavData({
          status: "success",
          message: "Something went wrong",
          data: navDataFinal,
        });
      } else {
        setNavData({
          status: "error",
          message: "Something went wrong",
          data: [],
        });
      }
    }
  }, [allData.featureAccessList]);

  const handleClick = (navItem) => {
    // Google Analytics Event - Master (Page Switch)
    masterTrackGaEvent({
      category: "PageSwitch",
      action: "PageSwitch",
      label: navItem.name,
    });
  };

  return (
    <nav>
      {/* {navData.status === "loading" && <Loader />} */}
      {navData.status === "error" && (
        <ErrorHandler
          message="Something went wrong."
          reloadFlag={false}
          onReload={() => {}}
        />
      )}
      {navData.status === "success" &&
        navData.data.map((navItem) => (
          <NavLink
            key={navItem.id}
            className={"nav-item"}
            onClick={() => {
              handleClick(navItem);
            }}
            to={navItem.to}
            activeClassName="active"
          >
            <div className="nav-item-name-container">
              <div className="nav-item-name-holder">
                <div className="nav-item-name-top-bar"></div>
                <p className={`nav-item-name ${navItem.name}Click-GA`}>
                  {t(navItem.name)}
                </p>
                <div className="nav-item-name-bottom-bar"></div>
              </div>
              {navItem.postName && (
                <p className="nav-item-post-name">{t(navItem.postName)}</p>
              )}
            </div>
          </NavLink>
        ))}
    </nav>
  );
}

Nav.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(Nav);
