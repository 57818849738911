import { combineReducers } from "redux";
import user from "./user";
import data from "./data";
import changeFlag from "./changeFlag";
import auth from "./auth";
import standaloneWs from "./standaloneWs";
import standaloneMsv from "./standaloneMsv";
import globalFilters from "./globalFilters";
import signInFormStatus from "./signInFormStatus";

export default combineReducers({
  user,
  data,
  changeFlag,
  auth,
  standaloneWs,
  standaloneMsv,
  globalFilters,
  signInFormStatus,
});
