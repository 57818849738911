//Import required libraies
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// Import styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: (style) => style?.margin || "0px",
    padding: (style) => style?.padding || "0px 5px",
    fontSize: (style) => style?.fontSize || "10px",
    height: (style) => style?.height || "24px",
    borderRadius: "3px",
    backgroundColor: "inherit",
    fontFamily: "inherit",
    border: (style) =>
      style?.border ||
      `1px solid ${theme.palette.misc["secondaryColorLighter"]}`,
    boxSizing: "border-box",
    color: theme.palette.misc["secondaryColor"],
    maxWidth: (style) => style?.maxWidth || "",
    width: "max-content",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    backgroundColor: (style) => style?.backgroundColor || "transparent",
  },
  value: {
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    margin: "0px !important",
  },
}));

function SigviewSimpleText(props) {
  const { t } = useTranslation();
  const { value, children, style = {} } = props;
  const classes = useStyles(style);

  return (
    <div className={classes.root} title={value}>
      <p className={classes.value}>{t(value)}</p>
      {children ? children : null}
    </div>
  );
}

export default SigviewSimpleText;
