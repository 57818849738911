// * Import required libraries
import { useReducer, useContext, useState, useEffect } from "react";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Import lib component
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// * Import custom components
import SigviewIcon from "../../components/Common/SigviewIcon";
import SigviewTextField from "../../components/Common/SigviewTextField";
import SigviewButton from "../../components/Common/SigviewButton";
import SigviewTypography from "../../components/Common/SigviewTypography";
import Loader from "../../components/Loader/Loader";
import SigviewHoverPopup from "../../components/Common/SigviewHoverPopup";

// * Import redux utils
import useReducerLogger from "../../utils/useReducerLogger";
import { config } from "../../config/config";
import { updateUserScreen } from "../../redux/actions";
import {
  updateChangedPassword,
  submitChangePasswordInitialError,
} from "../../redux/actions";
import changePasswordReducer from "../../redux/reducers/changePassword";
import { isSelectionsInvalid } from "../../utils/changePasswordUtils";

//  * Import APIs
import { masterTrackGaEvent } from "../../services/ga";
import { changePasswordAPIcall } from "../../services/api";

// * Define required static variables
const initialSelections = {
  oldPassword: {
    message: "",
    value: "",
    status: "invalid",
  },
  newPassword: {
    message: "",
    value: "",
    status: "invalid",
  },
  confirmPassword: {
    message: "",
    value: "",
    status: "invalid",
  },
  changePasswordInitialError: {
    message: "",
    value: "",
    status: "",
  },
  showChangePasswordError: {
    message: "",
    value: false,
    status: "",
  },
  oldPasswordResponseMessage: {
    message: "",
    value: false,
    status: "valid",
  },
};

// * Define style functions
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      padding: "25px",
      alignItems: "center",
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "5px 5px 15px 10px",
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    mainSignInFormRow: {
      padding: "10px",
      width: "350px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
  });
  return useStyles;
};

const DialogChangePassword = (props = {}) => {
  // * Destructure props
  const { ReduxDispatcher, user } = props;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const [postResetPsw, setPostResetPsw] = useState(false);
  const [getLoader, setLoader] = useState(false);
  const [count, setCount] = useState(10);

  // * Define required states
  const [selections, dispatch] = useReducer(
    useReducerLogger(changePasswordReducer),
    initialSelections
  );

  // * Define required event handlers
  const handleOldPassword = (value) => {
    const payload = {
      key: "oldPassword",
      value,
    };
    const action = updateChangedPassword(payload);
    dispatch(action);
  };

  const handleNewPassword = (value) => {
    const payload = {
      key: "newPassword",
      value,
    };
    const action = updateChangedPassword(payload);
    dispatch(action);
  };

  const handleConfirmPassword = (value) => {
    const payload = {
      key: "confirmPassword",
      value,
    };
    const action = updateChangedPassword(payload);
    dispatch(action);
  };

  const handleClose = () => {
    const payload = { open: false, children: null };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  // Automatic Redirection on home Link count
  useEffect(() => {
    if (postResetPsw) {
      var intervalId = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount === 0) {
            handleClose();
          }
          return prevCount - 1;
        });
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [postResetPsw]);

  const submitChangePassword = (e) => {
    e.preventDefault();
    setLoader(true);
    if (!isSelectionsInvalid(selections)) {
      const changePasswordPromise = changePasswordAPIcall({
        payload: {
          email: user?.reqMetadata?.email,
          currentPassword: selections.oldPassword.value,
          newPassword: selections.newPassword.value,
        },
      });
      changePasswordPromise
        .then((result) => {
          setLoader(false);
          if (result.status === "1") {
            masterTrackGaEvent({
              category: "SignIn",
              action: "Login",
              label: "Password Successfully Changed",
            });
            setPostResetPsw(true);
          } else if (result.status === "409") {
            setPostResetPsw(false);

            const payload = {
              key: "oldPasswordResponseMessage",
              value: {
                ...selections.oldPasswordResponseMessage,
                message: result.message,
                value: "error",
                status: "invalid",
              },
            };
            const action = submitChangePasswordInitialError(payload);
            dispatch(action);
            // blocked button clicked
            const payload1 = {
              key: "showChangePasswordError",
              value: true,
            };
            const action1 = updateChangedPassword(payload1);
            dispatch(action1);
          }
        })
        .catch((result) => {
          setPostResetPsw(false);
          setLoader(false);
          console.error("CATCH ERROR -> ");
        });
    } else {
      setLoader(false);
      const payload = {
        key: "showChangePasswordError",
        value: true,
      };
      const action = updateChangedPassword(payload);
      dispatch(action);
    }
  };

  // * Define required static variables
  const changePasswordTitle = getLoader === true ? <Loader /> : "Save";
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const passwordValidationRules = config.hardCoded["passwordValidationRules"];

  return (
    <Box className={classes.root}>
      {!postResetPsw && (
        <Box>
          <Box className={classes.titleContainer}>
            <Box className={classes.title}>
              <SigviewTypography variant="pLargest">
                Change Password
              </SigviewTypography>
              <SigviewHoverPopup
                title="Password Validation Rules"
                data={passwordValidationRules}
              >
                <SigviewIcon
                  iconName="help"
                  style={{
                    fontSize: "16px !important",
                    padding: "0px 0px 0px 10px",
                    color: themeColors["secondaryColorLighter"],
                    hoverColor: themeColors["primaryColor"],
                    cursor: "default",
                  }}
                />
              </SigviewHoverPopup>
            </Box>

            <i
              className="material-icons-round sigview-close-icon"
              onClick={handleClose}
            >
              close
            </i>
          </Box>
          <form onSubmit={submitChangePassword}>
            <Box className={classes.mainSignInFormRow}>
              <SigviewTextField
                width="350px"
                height="40px"
                fontSize="14px"
                placeholder="Old Password"
                type="Password"
                value={selections.oldPassword?.value || ""}
                onChange={handleOldPassword}
                error={
                  (selections.oldPassword.status === "invalid" &&
                    selections.showChangePasswordError.value) ||
                  selections.oldPasswordResponseMessage.status === "invalid"
                }
                helperText={
                  selections.oldPassword.message ||
                  selections.oldPasswordResponseMessage.message
                }
              />
            </Box>
            <Box className={classes.mainSignInFormRow}>
              <SigviewTextField
                width="350px"
                height="40px"
                fontSize="14px"
                placeholder="New Password"
                type="password"
                value={selections.newPassword.value || ""}
                onChange={handleNewPassword}
                error={
                  selections.newPassword.status === "invalid" &&
                  selections.showChangePasswordError.value === true
                }
                helperText={selections.newPassword.message}
              />
            </Box>
            <Box className={classes.mainSignInFormRow}>
              <SigviewTextField
                width="350px"
                height="40px"
                fontSize="14px"
                placeholder="Confirm Password"
                type="password"
                value={selections.confirmPassword.value || ""}
                onChange={handleConfirmPassword}
                error={
                  selections.confirmPassword.status === "invalid" &&
                  selections.showChangePasswordError.value === true
                }
                helperText={selections.confirmPassword.message}
              />
            </Box>
            <Box className={classes.mainSignInFormRow}>
              <SigviewButton
                onClick={submitChangePassword}
                style={{
                  btnWidth: "350px",
                  btnHeight: "40px",
                  fontSize: "14px !important",
                }}
                customClassName="ChangePasswordClick-GA"
                title={changePasswordTitle}
                disabled={
                  (selections.showChangePasswordError.value
                    ? isSelectionsInvalid(selections)
                    : false) ||
                  selections.oldPasswordResponseMessage.status === "invalid" ||
                  getLoader
                }
              />
            </Box>
          </form>
        </Box>
      )}
      {postResetPsw && (
        <Box>
          <i
            style={{ position: "absolute", right: "3px", top: "3px" }}
            className="material-icons-round sigview-close-icon"
            onClick={handleClose}
          >
            close
          </i>
          <Box>
            <SigviewIcon
              iconName="check_circle"
              style={{
                fontSize: "50px !important",
                padding: "10px 20px 15px 20px",
                color: themeColors["primaryColor"],
                hoverColor: themeColors["primaryColor"],
                cursor: "default",
              }}
            />
          </Box>
          <Box className={classes.mainSignInFormRow}>
            <SigviewTypography
              variant="pLarger"
              style={{ padding: "10px 50px 20px 50px" }}
            >
              Password Successfully Changed!
            </SigviewTypography>
          </Box>
          <Box>
            {count > 0 && (
              <SigviewTypography
                variant="pMedium"
                style={{
                  lineHeight: "16px",
                  textAlign: "center",
                  width: "350px",
                  height: "auto",
                  padding: "10px",
                }}
              >
                Dialog Box will be automatically close in {count} seconds
              </SigviewTypography>
            )}
          </Box>
          {/* <Box className={classes.mainSignInFormRow}>
            <SigviewButton
              onClick={handleClose}
              style={{
                btnWidth: "60px",
                btnHeight: "30px",
                fontSize: "14px !important",
              }}
              title="OK"
              type="OK"
            />
          </Box> */}
        </Box>
      )}
    </Box>
  );
};

export default DialogChangePassword;
