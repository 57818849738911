// * Import required libraies
import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";

// * Import config
import { config } from "../../config/config";

// * Define required static variables (outside main component)
const chartTypesBaseUrl = config.hardCoded.chartTypesBaseUrl;

// * Define style functions
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: (props) => props.style?.minWidth || "max-content",
    margin: (props) => props.style?.margin || "0px",
  },
  multiSelect: {
    "& .MuiSelect-icon": {
      color: theme.palette.misc["secondaryColor"],
    },
    color: theme.palette.misc["secondaryColor"],
    fontSize: (props) => props.style?.fontSize || "10px",
    fontFamily: "inherit",
    minWidth: (props) => props.style?.minWidth || "max-content",
    maxWidth: (props) => props.style?.maxWidth || "100px",
    height: "24px",
    borderRadius: "3px",
    backgroundColor: "transparent",
    border: (props) =>
      props.style?.border
        ? props.style.border
        : `1px solid ${
            props.error
              ? theme.palette.misc["failureColor"]
              : theme.palette.misc["secondaryColorLighter"]
          }`,
    "& .MuiSvgIcon-root": {
      color: (props) =>
        props.disabled
          ? theme.palette.misc["secondaryColorLighter"]
          : theme.palette.misc["secondaryColor"],
    },
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "6px",
      paddingTop: "11px",
      color: (props) =>
        props.disabled
          ? theme.palette.misc["secondaryColorLighter"]
          : theme.palette.misc["secondaryColor"],
      fontSize: (props) => props?.style?.fontSize || "10px",
      paddingRight: "0px",
      "& span": {
        display: "none",
      },
      marginLeft: "12px",
    },
  },
  singleMenuItem: {
    fontSize: (props) => props?.style?.fontSize || "10px",
    fontFamily: "inherit",
    padding: "3px 6px",
    margin: "0px 0px",
    width: "auto",
    color: theme.palette.misc["secondaryColor"],
    "&:hover": {
      backgroundColor: theme.palette.misc["secondaryColorLightest"],
    },
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: theme.palette.misc["secondaryColorLightest"],
    },
  },
  formHelperText: {
    fontSize: "9px",
    fontFamily: "inherit",
    minWidth: (props) => props?.style?.minWidth || "120px",
    maxWidth: "350px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: `${theme.palette.misc["failureColor"]} !important`,
  },
  popoverRoot: {
    zIndex: "9999999999 !important",
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.misc["popoverBgColor"],
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
        backgroundColor: `transparent !important`,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: `${theme.palette.misc["secondaryColorLighter"]} !important`,
        borderRadius: "5px",
      },
      "& .MuiList-root": {},
      // "& .MuiList-padding": {
      //   padding: "0px",
      // },
    },
  },
  selectIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px !important",
    color: theme.palette.misc["secondaryColor"],
    "&:hover": { color: theme.palette.misc["secondaryColor"] },
    cursor: "pointer",
  },
}));

const CustomExpandMore = (props) => {
  const { className } = props;
  let finalClassname = `${className} material-icons`;
  const customStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
  };
  const classes = useStyles(props);
  return (
    <span className={finalClassname} style={customStyle}>
      <span className={classes.selectIcon}>expand_more</span>
    </span>
  );
};

const menuItemStyle = {
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "contain",
  width: "18px",
  height: "18px",
  paddingRight: "5px",
};

function SigviewChartTypeSingleSelect(props = {}) {
  const { t } = useTranslation();
  // * Destructure props
  const {
    data = [],
    value = {},
    onChange = () => {},
    error = false,
    disabled = false, //if the entire dropdown is disabled or not; default is false
    helperText = "",
  } = props;

  // * Define required event handlers
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  // * Define required static variables
  const classes = useStyles(props);
  const MenuProps = {
    PopoverClasses: {
      root: classes.popoverRoot,
    },
    PaperProps: {
      style: {
        maxHeight: "280px",
        width: "auto",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
    // disablePortal: true, //to let dropdown menu render in the same zIndex as the parent
    // popperDisablePortal: true,
  };
  const selectStyle = {
    background: `url(${chartTypesBaseUrl}/${value}Hovered.svg) no-repeat left center`,

    // backgroundImage: `url(${chartTypesBaseUrl}/${value}Hovered.svg)`,
    // backgroundRepeat: "no-repeat",
    // textIndent: "-9999px",
    // backgroundPosition: "center",
    // backgroundOrigin: "left",
    // backgroundClip: "center",
    // backgroundSize: "contain",
    // width: "20px",
    // height: "20px",
    // paddingLeft: "5px",
  };

  return (
    <FormControl
      className={classes.formControl}
      error={error}
      disabled={disabled}
    >
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        className={classes.multiSelect}
        style={selectStyle}
        MenuProps={MenuProps}
        title={t("Chart Type")}
        // IconComponent={() => (
        //   <span className={`${classes.dropdownIcon} material-icons`}>
        //     expand_more
        //   </span>
        // )}
        IconComponent={CustomExpandMore}
      >
        {data.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
            className={`${classes.singleMenuItem} chartType-GA`}
            disabled={item.disabled}
            id={item.id}
          >
            <span
              id={item.id}
              className="chartType-GA"
              style={{
                marginRight: "7px",
                backgroundImage: `url(${chartTypesBaseUrl}/${
                  item.id !== value ? item.id : item.id + "Hovered"
                }.svg)`,
                ...menuItemStyle,
              }}
            ></span>
            <span
              className="chartType-GA"
              id={item.id}
              style={{ paddingRight: "20px" }}
            >
              {t(item.name)}
            </span>
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText className={classes.formHelperText} title={helperText}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default SigviewChartTypeSingleSelect;
