// Import required libraries
import React, { createContext, useReducer } from "react";

// Import utils
import themes from "../../styles/theme/themeColors";
import useReducerLogger from "../utils/useReducerLogger";

export const ThemeContext = createContext();

const initialState = {
  activeTheme: "light",
  themes: themes,
};

const themeReducer = (state, action) => {
  switch (action.theme) {
    case "light":
      return { ...state, activeTheme: "light" };
    case "dark":
      return { ...state, activeTheme: "dark" };
    default:
      return state;
  }
};

export function ThemeProvider(props) {
  const [state, dispatch] = useReducer(
    useReducerLogger(themeReducer),
    initialState
  );

  return (
    <ThemeContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ThemeContext.Provider>
  );
}
