//import required libraries
import React, { useContext } from "react";

//import library components
import { Box, makeStyles } from "@material-ui/core";

//import custom components
import SigviewTooltip from "./SigviewTooltip";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Making styles for all variations
// Defining required functions
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "60px",
    height: "24px",
    background: "white",
    borderRadius: "3px",
    boxSizing: "border-box",
    cursor: "pointer",
    boxShadow: theme.palette.misc["dsItemBoxShadow"],
  },

  singleGridViewBox: (prop) => ({
    background:
      prop.value === "double" ? theme.palette.misc["secondaryColor"] : "white",
    border: `7px solid ${
      prop.value === "double" ? `white` : theme.palette.misc["secondaryColor"]
    }`,
    borderRadius: "3px",
    height: "100%",
    width: "50%",
    boxSizing: "border-box",
  }),

  doubleGridViewBox: (prop) => ({
    background:
      prop.value === "double" ? "white" : theme.palette.misc["secondaryColor"],
    border: `7px solid ${
      prop.value === "double" ? theme.palette.misc["secondaryColor"] : `white`
    }`,
    borderRadius: "3px",
    height: "100%",
    width: "50%",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),

  seperatorBox: (prop) => ({
    height: "100%",
    width: "2px",
    background:
      prop.value === "double" ? theme.palette.misc["secondaryColor"] : `white`,
  }),
}));

function TableGridView(props) {
  const { value = "double", data = [], onChange = () => {} } = props;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  const classes = useStyles(props);

  return (
    <Box className={classes.root}>
      <SigviewTooltip title="Single Grid View" placement="bottom">
        <Box
          className={`${classes.singleGridViewBox} singleGridViewBoxClicked-GA`}
          onClick={() => onChange("single")}
        ></Box>
      </SigviewTooltip>
      <SigviewTooltip title="Double Grid View" placement="bottom">
        <Box
          className={`${classes.doubleGridViewBox} doubleGridViewBoxClicked-GA`}
          onClick={() => onChange("double")}
        >
          <Box className={classes.seperatorBox} />
        </Box>
      </SigviewTooltip>
    </Box>
  );
}

export default TableGridView;
