// Import required libraries
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Import styles
import "./Footer.scss";

// Import config
import { config } from "../../config/config";
import moment from "moment";

function Footer(props) {
  const {} = props;
  const footerYear = new Date().getFullYear();

  return (
    <footer id="sigview-footer">
      <p className={`footer-text`}>
        Copyright © {footerYear}. Powered by{" "}
        <a
          target="_blank"
          href="https://www.sigmoid.com/"
          className={`sigmoid-redirect`}
        >
          Sigmoid
        </a>
      </p>
    </footer>
  );
}

Footer.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(Footer);
