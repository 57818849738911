//Import required libraies
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//Import Custom Component
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import Footer from "../../components/Footer/Footer";
import SidenavAdmin from "../../components/SidenavAdmin/SidenavAdmin";
import Loader from "../../components/Loader/Loader";

//Import Custom Hooks

//Import utils/data

//Import styles

function LayoutTopSideBottomAdmin(props) {
  // * Destructure props
  const {
    user,
    children,
    handleActiveTab = () => {},
    activeTab,
    isSidenavVisible = true,
    variant = "AdminGcp",
  } = props;

  // * Define required static variables for props
  const sidenavAdminProps = {
    handleActiveTab,
    variant,
  };
  var sectionClass = "content";
  if (!isSidenavVisible) sectionClass += " without-sidenav";

  return (
    <div className={`page layout-top-side-bottom`}>
      {user.screen.isDashboardLoading && (
        <section className={`dashboard-loader-container`}>
          <Loader />
        </section>
      )}
      <HeaderAdmin />
      <main>
        {isSidenavVisible && (
          <section className={`sidenav`}>
            <SidenavAdmin {...sidenavAdminProps} />
          </section>
        )}
        <section className={sectionClass}>
          <section className={`main-content-data`}>{children}</section>
        </section>
      </main>

      <Footer />
    </div>
  );
}

LayoutTopSideBottomAdmin.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(LayoutTopSideBottomAdmin);
