import C from "../constants";

import metricFiltersTypeUiCompTypeMapping from "../../../assets/data/metricFiltersTypeUiCompTypeMapping.json";

export default function metricFilters(state = {}, payload) {
  let {
    type,
    newMetricFilterRow,
    metricFilterId,
    metricFilterType,
    metricFilterKey,
    metricFilterValue,
    newMetricFilters,
    metricFilterMetricId,
    absoluteChange,
  } = payload;
  let newState;

  switch (type) {
    case C.ADD_METRIC_FILTER:
      newState = [...state, newMetricFilterRow];
      return newState;

    case C.REMOVE_METRIC_FILTER:
      newState = state.filter(
        (metricFilterRow) => metricFilterRow.id !== metricFilterId
      );
      return newState;

    case C.CHANGE_METRIC_FILTER_TYPE:
      newState = state.map((metricFilterRow) =>
        metricFilterRow.id !== metricFilterId
          ? metricFilterRow
          : {
              ...metricFilterRow,
              type: metricFilterType,
              uiCompType: metricFiltersTypeUiCompTypeMapping[metricFilterType],
            }
      );
      return newState;

    case C.CHANGE_METRIC_FILTER_METRIC_ID:
      newState = state.map((metricFilterRow) =>
        metricFilterRow.id !== metricFilterId
          ? metricFilterRow
          : {
              ...metricFilterRow,
              metricId: metricFilterMetricId,
            }
      );
      return newState;

    case C.CHANGE_METRIC_FILTER_VALUE:
      newState = state.map((metricFilterRow) =>
        metricFilterRow.id !== metricFilterId
          ? metricFilterRow
          : {
              ...metricFilterRow,
              [metricFilterKey]: metricFilterValue,
            }
      );
      return newState;

    case C.CHANGE_ABSOLUTE_CHANGE:
      newState = state.map((metricFilterRow) =>
        metricFilterRow.id !== metricFilterId
          ? metricFilterRow
          : {
              ...metricFilterRow,
              absoluteChange,
            }
      );
      return newState;

    case C.REPLACE_ALL_METRIC_FILTERS:
      return newMetricFilters;

    default:
      return state;
  }
}
