// Import required libraries
import { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

// Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// Making styles
const makeSigviewStyles = (themeColors) => {
  const useStyles = makeStyles(() => ({
    alertManagerFormItemContainer: {
      // display: "flex",
      // flexDirection: "column",
      // alignItems: "flex-start",
      // boxSizing: "border-box",
      // paddingRight: "10px",
      display: "flex",
      justifyContent: "flex-start",
      //marginBottom: "14px",
      alignItems: "center",
      paddingRight: "5px",
    },
    labelContainer: {
      // height: "16px",
      "&.commonSet": {
        paddingTop: "7px",
      },

      "&.widthSet": {
        width: "20px !important",
        paddingTop: "6px",
      },
      "&.alertEndsWidth": {
        width: "80px !important",
        paddingTop: "6px",
        paddingLeft: "30px",
      },
      "&.alertStatus": {
        width: "70px !important",
        paddingTop: "7px",
        paddingLeft: "30px",
      },
      "&.comparedWith": {
        width: "90px !important",
        paddingTop: "4px",
        paddingLeft: "30px",
      },
      "&.alertMe": {
        paddingBottom: "14px",
      },
    },
    label: {
      color: themeColors["secondaryColor"],
      fontSize: "12px",
      fontWeight: "400",
      //paddingTop: "5px",
      width: "110px",
    },
    inputItemContainer: {
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      alignItems: "center",
      paddingTop: "1px",

      "&.full": { width: "100%" },
      "&.inputContainer": {
        // backgroundColor: themeColors["secondaryColorLightest"],
        border: `1px solid ${themeColors["secondaryColorLighter"]}`,
        borderRadius: "3px",
        boxSizing: "border-box",
      },
      "&.borderLess": {
        border: "none !important",
        height: "24px",
        display: "flex",
        flexDirection: "column",
      },
      "&.borderLessName": {
        border: "none !important",
        height: "24px",
      },
      "&.radioBtnGroup": {
        paddingTop: "7px",
      },
    },
  }));
  return useStyles;
};

function AlertManagerFormRowItem(props) {
  // Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // Destructure props
  const { title = "", children = <></>, style = {} } = props;

  // Destructuring variables from useStyles()
  const useSigviewStyles = makeSigviewStyles(themeColors);
  let {
    alertManagerFormItemContainer,
    labelContainer,
    label,
    inputItemContainer,
  } = useSigviewStyles();

  // Adding required classes based on props
  if (style.full) inputItemContainer += " full";
  if (style.inputContainer) inputItemContainer += " inputContainer";
  if (style.borderLess) inputItemContainer += " borderLess";
  if (style.widthSet) labelContainer += " widthSet";
  if (style.alertEndsWidth) labelContainer += " alertEndsWidth";
  if (style.alertStatus) labelContainer += " alertStatus";
  if (style.comparedWith) labelContainer += " comparedWith";
  if (style.radioBtnGroup) inputItemContainer += " radioBtnGroup";
  if (style.alertMe) labelContainer += " alertMe";
  if (style.commonSet) labelContainer += " commonSet";
  if (style.borderLessName) inputItemContainer += " borderLessName";

  return (
    <div className={alertManagerFormItemContainer}>
      <div className={labelContainer}>
        <p className={label}>{title}</p>
      </div>
      <div className={inputItemContainer}> {children}</div>
    </div>
  );
}

export default AlertManagerFormRowItem;
