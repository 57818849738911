// ----------------------------------------COMPONENT HIERARCHY----------------------------------------
// GlobalFilters
//     DimensionValues
//     AdvancedFiltersContainer
//         AdvancedFilters
//             AdvancedFiltersRow
//                  AdvancedFiltersFileUpload
//                  AdvancedFiltersStringMatch
//                  AdvancedFiltersDateSelect

import GlobalFilters from "./GlobalFilters.js";

export default GlobalFilters;
