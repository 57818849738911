// move all unwrapper and wrapper functions here
// move assets/userSelection.js here
// import group from "../../assets/data/TEMP/group.json";
// import GroupsTable from "../../assets/data/TEMP/groupsTable.json";
// import views from "../../assets/data/TEMP/views.json";
import { common } from "@material-ui/core/colors";
import { selection } from "d3";
import { v4 } from "uuid";
import { validateChartName } from "./chartObjectUtils";
import { log } from "./utils";
import orderBy from "lodash.orderby";
import _ from "underscore";
import {
  transformFiltersUiToBackend,
  transformFiltersBackendToUi,
} from "../utils/filtersUtils";
import { transformDimensionsForPlotly } from "./utils";

let commonCredentials = {
  dimensionValue: [
    {
      id: "D001",
      status: true,
    },
    {
      id: "D002",
      status: true,
    },
    {
      id: "D150",
      status: true,
    },
  ],
  metricValue: [],

  customMetricValue: [],
  groupLevelCustomMetrics: [],
  groupLevelCustomMetrics: [],
  groupSecurityFilter: [],
};

function unWrapperGroup(group, viewsDataui, allData = {}) {
  let idValue = group._id;
  let nameValue = group.name;
  let selectedView = group.views.map((row) => {
    return {
      id: row.viewId,
      title: row.viewId,
    };
  });

  selectedView = orderBy(selectedView, ["id"], ["asc"]);

  let activeViewValue = { id: group.views[0].viewId };
  // let metricValue;
  // let dimensionValue;
  // let customMetricValue;

  let selectedViewIdArray = group.views.map((row) => row.viewId);

  let views = viewsDataui.map((view) => {
    if (selectedViewIdArray.includes(view.id)) {
      let currentView = group.views.find((row) => row.viewId === view.id);

      const activeViewData = allData.views.result.find(
        (row) => row.id === view.id
      );

      var plotlyDimensions = [...allData.plotlyDimensions];

      if (activeViewData)
        plotlyDimensions = transformDimensionsForPlotly(
          activeViewData.dimensions
        );

      let viewValue = currentView.viewId;

      let metricValue = currentView.visibleMetricList.filter(
        (row) => row.status === true
      );

      let customMetricValue = currentView.visibleCustomMetricList.filter(
        (row) => row.status === true
      );

      let dimensionValue = currentView.visibleDimensionList.filter(
        (row) =>
          (row.id !== "D003" && row.status === true) ||
          (row.id === "D002" && row.status === true) ||
          (row.id === "D001" && row.status === true) ||
          (row.id === "D150" && row.status === true)
      );

      let sortedMetricValue = orderBy(metricValue, ["id"], ["asc"]);
      let sortedCustomMetricValue = orderBy(customMetricValue, ["id"], ["asc"]);
      let sortedDimensionValue = orderBy(dimensionValue, ["id"], ["asc"]);

      return {
        viewValue: currentView.viewId,

        metricValue: sortedMetricValue,

        customMetricValue: sortedCustomMetricValue,

        dimensionValue: sortedDimensionValue,

        groupLevelCustomMetrics: currentView?.groupLevelCustomMetrics || [],
        // groupSecurityFilter: [],
        groupSecurityFilter: transformFiltersBackendToUi(
          currentView?.groupLevelfilters || [],
          plotlyDimensions
        ),
        // transformFiltersBackendToUi(
        //   view?.groupLevelfilters,
        //   plotlyDimensions
        // ),
      };
    } else {
      return {
        viewValue: view.id,
        metricValue: view.metricData.map((row) => {
          return { id: row.id, status: true };
        }),

        customMetricValue: view.customMetricData.map((row) => {
          return { id: row.id, status: true };
        }),

        dimensionValue: view.DimensionData.map((row) => {
          return { id: row.id, status: true };
        }),

        groupLevelCustomMetrics: view?.groupLevelCustomMetrics || [],
        groupSecurityFilter: [],

        // transformFiltersBackendToUi(
        //   view?.groupLevelfilters,
        //   plotlyDimensions
        // ),
      };
    }
  });

  let viewDataAllviewsArray = viewsDataui.map((row) => row.id);

  let viewDataSelectedviewsArray = views.map((row) => row.viewValue);

  viewDataAllviewsArray.map((row) => {
    if (!viewDataSelectedviewsArray.includes(row)) {
      views.push({
        viewValue: row,
        ...commonCredentials,
      });
    }
  });

  let viewsObj = views.reduce((acc, v) => ({ ...acc, [v.viewValue]: v }), {});

  let unWrappedData = {
    id: {
      message: "",
      value: idValue,
      status: "",
    },
    name: {
      message: "",
      value: nameValue,
      status: "",
    },
    selectedView: {
      message: "",
      value: selectedView,
      status: "",
    },
    activeView: {
      message: "",
      value: activeViewValue,
      status: "",
    },
    views: {
      message: "",
      value: viewsObj,
      status: "",
    },
    activeTab: {
      message: "",
      value: {
        id: "accessList",
        title: "accessList",
      },
      status: "valid",
    },
    actualPayload: group,
  };

  return unWrappedData;
}
function unWrapperUserGroup(group, viewsData, allData = {}) {
  // let idValue = group._id;
  // let nameValue = group.name;
  let selectedView = group.views.map((row) => {
    return {
      id: row.viewId,
      title: row.name,
    };
  });
  let activeViewValue = { id: group.views[0].viewId };
  // let metricValue;
  // let dimensionValue;
  // let customMetricValue;

  let selectedViewIdArray = group.views.map((row) => row.viewId);

  let views = viewsData.result.map((view) => {
    if (selectedViewIdArray.includes(view.id)) {
      let currentView = group.views.find((row) => row.viewId === view.id);
      const activeViewData = allData.views.result.find(
        (row) => row.id === view.id
      );
      var plotlyDimensions = [...allData.plotlyDimensions];
      if (activeViewData)
        plotlyDimensions = transformDimensionsForPlotly(
          activeViewData.dimensions
        );

      return {
        _id: currentView._id,
        viewValue: currentView.viewId,

        metricValue: currentView.visibleMetricList.filter(
          (row) => row.status === true
        ),

        customMetricValue: currentView.visibleCustomMetricList.filter(
          (row) => row.status === true
        ),

        dimensionValue: currentView.visibleDimensionList.filter(
          (row) =>
            (row.id !== "D003" && row.status === true) ||
            (row.id === "D002" && row.status === true) ||
            (row.id === "D001" && row.status === true) ||
            (row.id === "D150" && row.status === true)
        ),

        groupLevelCustomMetrics: currentView?.groupLevelCustomMetrics || [],
        groupSecurityFilter: transformFiltersBackendToUi(
          currentView?.groupLevelfilters || [],
          plotlyDimensions
        ),

        // transformFiltersBackendToUi(
        //   view?.groupLevelfilters,
        //   plotlyDimensions
        // ),
      };
    } else {
      return {
        _id: view.id,
        viewValue: view.id,
        metricValue: [],

        customMetricValue: [],

        dimensionValue: [
          {
            id: "D001",
            status: true,
          },
          {
            id: "D002",
            status: true,
          },
          {
            id: "D150",
            status: true,
          },
        ],

        groupLevelCustomMetrics: view?.groupLevelCustomMetrics || [],
        groupSecurityFilter: [],

        // transformFiltersBackendToUi(
        //   view?.groupLevelfilters,
        //   plotlyDimensions
        // ),
      };
    }
  });

  let viewDataAllviewsArray = viewsData.result.map((row) => row.id);

  let viewDataSelectedviewsArray = views.map((row) => row.viewValue);

  viewDataAllviewsArray.map((row) => {
    if (!viewDataSelectedviewsArray.includes(row)) {
      views.push({
        viewValue: row,
        ...commonCredentials,
      });
    }
  });

  let viewsObj = views.reduce((acc, v) => ({ ...acc, [v.viewValue]: v }), {});

  let unWrappedData = {
    // id: {
    //   message: "",
    //   value: idValue,
    //   status: "",
    // },
    // name: {
    //   message: "",
    //   value: nameValue,
    //   status: "",
    // },
    selectedView: {
      message: "",
      value: selectedView,
      status: "",
    },
    activeView: {
      message: "",
      value: activeViewValue,
      status: "",
    },
    views: {
      message: "",
      value: viewsObj,
      status: "",
    },
    activeTab: {
      message: "",
      value: {
        id: "accessList",
        title: "accessList",
      },
      status: "valid",
    },
  };

  return unWrappedData;
}

const mapping = {
  id: "id",
  name: "Name",
  lastModifiedOn: "Last Modified On",
  lastSuccessLoginTime: "Last Logged On",
  createdOn: "Created On",
  organization: "Organization",
  usersCount: "Number of Users",
  createdBy: "Created By",
  lastModifiedBy: "Last Modified By",
  firstName: "First Name",
  lastName: "Last Name",
  userGroupName: "Group",
  role: "Role",
  userName: "Name",
  email: "Email",
};

function unWrapperGroups(groupsUsersData) {
  let groups = groupsUsersData;
  let groupKey = Object.keys(groups[0]);

  let groupKeyObj = groupKey.map((row) => {
    if (row !== "_id" && row !== "organization") {
      return { id: row, name: row, label: mapping[row] };
    }
  });

  let groupKeyObjFiltered = groupKeyObj.filter((row) => row !== undefined);

  return groupKeyObjFiltered;
}

function unWrapperUsers(groupsUsersData) {
  let groups = groupsUsersData;
  let groupKey = Object.keys(groups[0]);

  let groupKeyObj = groupKey.map((row) => {
    if (row !== "_id") {
      if (row !== "userId") {
        if (row !== "organization") {
          if (row !== "firstName") {
            if (row !== "lastName") {
              if (row !== "role") {
                if (row !== "lastModifiedOn") {
                  if (row !== "lastModifiedBy") {
                    if (row !== "createdBy") {
                      if (row !== "organization") {
                        if (row !== "userGroup") {
                          return { id: row, name: row, label: mapping[row] };
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  });

  let groupKeyObjFiltered = groupKeyObj.filter((row) => row !== undefined);

  return groupKeyObjFiltered;
}

function unWrapperviews(viewData) {
  const sideNavData = viewData.map((row) => {
    let id = row.id ? row.id : "";
    let name = row.name ? row.name : "";
    let subTitle = row.subTitle ? row.subTitle : "";
    let disabled = false;

    // let metricData = row.metrics;
    let metricData = row.metrics.map((metricRow) => {
      return {
        id: metricRow._id,
        title: metricRow.title,
        disabled: false,
        hoverInfo: "info",
        backendName: metricRow.metricId,

        // actualPayload: metricRow,
      };
    });
    let DimensionData = row.dimensions
      .map((dimensionRow) => {
        if (dimensionRow._id == "D001") {
          return {
            id: dimensionRow._id,
            title: dimensionRow.title,
            disabled: true,
            hoverInfo: "info",
            backendName: dimensionRow.dimId,
            // actualPayload: dimensionRow,
          };
        }
        if (dimensionRow._id == "D002") {
          return {
            id: dimensionRow._id,
            title: dimensionRow.title,
            disabled: true,
            hoverInfo: "info",
            backendName: dimensionRow.dimId,
            // actualPayload: dimensionRow,
          };
        }
        if (dimensionRow._id == "D150") {
          return {
            id: dimensionRow._id,
            title: dimensionRow.title,
            disabled: true,
            hoverInfo: "info",
            backendName: dimensionRow.dimId,
            // actualPayload: dimensionRow,
          };
        } else {
          return {
            id: dimensionRow._id,
            title: dimensionRow.title,
            disabled: false,
            hoverInfo: "info",
            backendName: dimensionRow.dimId,
            // actualPayload: dimensionRow,
          };
        }
      })
      .filter((row) => row.title !== "Week");

    let customMetricData = row.customMetrics.map((customMetricRow) => {
      return {
        id: customMetricRow._id,
        title: customMetricRow.title,
        disabled: false,
        hoverInfo: "info",
        // backendName:
        actualPayload: customMetricRow,
      };
    });

    let filteredDimData = DimensionData.filter(
      (row) => row.id !== "D001" && row.id !== "D002" && row.id !== "D150"
    );
    let specficDimData = DimensionData.filter(
      (row) => row.id === "D001" || row.id === "D002" || row.id === "D150"
    );

    filteredDimData = _.sortBy(filteredDimData, ["title"], ["asc"]);
    specficDimData = _.sortBy(specficDimData, ["title"], ["asc"]);

    DimensionData = specficDimData.concat(filteredDimData);
    metricData = _.sortBy(metricData, ["title"], ["asc"]);
    customMetricData = _.sortBy(customMetricData, ["title"], ["asc"]);

    return {
      id,
      name,
      subTitle,
      disabled,
      metricData,
      DimensionData,
      customMetricData,
    };
  });

  return sideNavData;
}

const makeViewIdForNewViewInGroup = (groupName, viewName) => {
  let name = groupName.split("_");
  name.shift();

  let finalname = name.join("_") + "-" + viewName;

  return finalname.toLowerCase();
};

function wrapperGroup(
  userSelections,
  reqMetadata,
  viewsDataUi = [],
  groupdata,
  crudType
) {
  let _id =
    crudType === "edit"
      ? userSelections?.actualPayload?._id
      : crudType === "create"
      ? ""
      : "";
  // * COMMENTING AS IT MIGHT BE USED LATER
  // reqMetadata.organization.toLowerCase().replace(/\s/g, "") +
  // "_" +
  // userSelections.name.value.toLowerCase().replace(/\s/g, "");

  let name = userSelections.name.value;
  let createdOn =
    crudType === "create"
      ? Date.now().toString()
      : userSelections.actualPayload?.createdOn || Date.now().toString();
  let lastModifiedOn = Date.now().toString();
  let organization =
    userSelections?.actualPayload?.organization || reqMetadata.organization;
  let createdBy = userSelections?.actualPayload?.createdBy || reqMetadata.email;
  let lastModifiedBy = reqMetadata.email;
  let views = userSelections.views;

  let selectedViewIdArray = userSelections.selectedView.value.map(
    (row) => row.id
  );

  let viewsArray = [];
  let viewsArrayNew = [];

  Object.keys(views.value).forEach((key) => {
    viewsArray.push({
      ...views.value[key],
      _id: key,
      viewId: key,
      name: userSelections.name.value,
    });
  });

  if (crudType === "edit") {
    let existingViewsIds = groupdata.views.map((row) => row.viewId);

    for (const view of viewsArray) {
      var element;
      if (existingViewsIds.includes(view.viewId)) {
        const oldViewObj = groupdata.views.find(
          (row) => row.viewId === view.viewId
        );
        element = {
          ...view,
          _id: oldViewObj._id,
        };
      } else {
        element = {
          ...view,
          _id: makeViewIdForNewViewInGroup(groupdata._id, view.viewId),
        };
      }
      viewsArrayNew.push(element);
    }
  } else if (crudType === "create") {
    viewsArrayNew = viewsArray.map((row) => ({
      ...row,
      _id: "",
    }));
  }

  let newArray = viewsArrayNew
    .map((item) => {
      // Visible Dimension List
      const currViewId = item.viewId;
      const currViewObj = viewsDataUi.find((row) => row.id === currViewId);
      const currViewAllDimList = currViewObj?.DimensionData || [];
      const allVisibleDimensionIds = currViewAllDimList.map((row) => row.id);
      const selectedVisibleDimensionIds = item.dimensionValue.map(
        (row) => row.id
      );
      const nonSelectedVisibleDimensionIds = allVisibleDimensionIds.filter(
        (x) => !selectedVisibleDimensionIds.includes(x)
      );
      const visibleDimensionList = [
        ...item.dimensionValue.map((row) => ({
          ...row,
          status: true,
        })),
        ...nonSelectedVisibleDimensionIds.map((id) => ({
          id,
          status: false,
        })),
      ];

      // Visible Metric List
      const currViewAllMetricList = currViewObj?.metricData || [];
      const allVisibleMetricIds = currViewAllMetricList.map((row) => row.id);
      const selectedVisibleMetricIds = item.metricValue.map((row) => row.id);
      const nonSelectedVisibleMetricIds = allVisibleMetricIds.filter(
        (x) => !selectedVisibleMetricIds.includes(x)
      );
      const visibleMetricList = [
        ...item.metricValue.map((row) => ({
          ...row,
          status: true,
        })),
        ...nonSelectedVisibleMetricIds.map((id) => ({
          id,
          status: false,
        })),
      ];

      // Visible Custom Metric List
      const currViewAllCustomMetricList = currViewObj?.customMetricData || [];
      const allVisibleCustomMetricIds = currViewAllCustomMetricList.map(
        (row) => row.id
      );
      const selectedVisibleCustomMetricIds = item.customMetricValue.map(
        (row) => row.id
      );
      const nonSelectedVisibleCustomMetricIds =
        allVisibleCustomMetricIds.filter(
          (x) => !selectedVisibleCustomMetricIds.includes(x)
        );
      const visibleCustomMetricList = [
        ...item.customMetricValue.map((row) => ({
          ...row,
          status: true,
        })),
        ...nonSelectedVisibleCustomMetricIds.map((id) => ({
          id,
          status: false,
        })),
      ];

      let crudTypeView = (crudType, item) => {
        if (crudType === "edit") {
          return item.viewValue;
        }
        if (crudType === "create") {
          return item.viewId;
        }
      };

      let activeViewsDataUi = viewsDataUi.find(
        (el) => el.id === crudTypeView(crudType, item)
      );

      let visibleDimensionListIds = item.dimensionValue.map((el) => el.id);
      let visibleMetricListIds = item.metricValue.map((el) => el.id);
      let visibleCustomMetricListIds = item.customMetricValue.map(
        (el) => el.id
      );

      return {
        _id: item._id,
        viewId: item.viewId,
        name: item.name,

        visibleDimensionList: activeViewsDataUi.DimensionData.map((el) => {
          if (visibleDimensionListIds.includes(el.id)) {
            return {
              id: el.id,
              status: true,
            };
          } else {
            return {
              id: el.id,
              status: false,
            };
          }
        }).filter((elm) => elm.id !== undefined),
        visibleMetricList: activeViewsDataUi.metricData
          .map((el) => {
            if (visibleMetricListIds.includes(el.id)) {
              return {
                id: el.id,
                status: true,
              };
            } else {
              return {
                id: el.id,
                status: false,
              };
            }
          })
          .filter((elm) => elm.id !== undefined),
        visibleCustomMetricList: activeViewsDataUi.customMetricData
          .map((el) => {
            if (visibleCustomMetricListIds.includes(el.id)) {
              return {
                id: el.id,
                status: true,
              };
            } else {
              return {
                id: el.id,
                status: false,
              };
            }
          })
          .filter((elm) => elm.id !== undefined),
        groupLevelfilters: transformFiltersUiToBackend(
          item.groupSecurityFilter
        ),
      };
    })
    .filter((row) => selectedViewIdArray.includes(row.viewId));

  const finalResponse = {
    _id,
    name,
    lastModifiedOn,
    createdOn,
    organization,
    createdBy,
    lastModifiedBy,
    // viewsArray,
    views: newArray,
  };
  return finalResponse;
}

function wrapperUser(userSelections, reqMetadata) {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  let userId =
    userSelections.actualPayload?.userId ||
    userSelections.groupName.value.toLowerCase().replace(/\s/g, "") +
      "_" +
      userSelections.email.value.toLowerCase().replace(/\s/g, "");
  let firstName = capitalizeFirstLetter(userSelections.firstName.value);
  let lastName = capitalizeFirstLetter(userSelections.lastName.value);
  let role = userSelections.role.value;
  let userGroup = userSelections.groupName.value;
  let email = userSelections.email.value;
  let organization =
    userSelections?.actualPayload?.organization || reqMetadata.organization;
  let createdBy = userSelections?.actualPayload?.createdBy || reqMetadata.email;
  let createdOn =
    userSelections.actualPayload?.lastModifiedOn || Date.now().toString();
  let lastModifiedBy = reqMetadata.email;
  let lastModifiedOn = Date.now().toString();

  let views = userSelections.views;

  let selectedViewIdArray = userSelections.selectedView.value.map(
    (row) => row.id
  );

  let viewsArray = [];

  Object.keys(views.value).forEach((key) => {
    viewsArray.push({
      ...views.value[key],
      _id: userSelections.views.value[key]._id,
      viewId: key,
      // name: userSelections.name.value,
    });
  });

  let newArray = viewsArray
    .map((item) => {
      return {
        id: item._id,
        viewId: item.viewId,
        // name: item.name,

        userLevelFilters: item.userSecurityFilter
          ? transformFiltersUiToBackend(item.userSecurityFilter)
          : [],
      };
    })
    .filter((row) => selectedViewIdArray.includes(row.viewId));

  let finalResponse = {
    userId,
    firstName,
    lastName,
    role,
    userGroup,
    email,
    organization,
    createdBy,
    createdOn,
    lastModifiedBy,
    lastModifiedOn,
    views: newArray,
  };

  return finalResponse;
}

function unWrapperUser(user, allData = {}) {
  let idValue = user._id;
  let groupNameValue = user.userGroup;
  let firstNameValue = user.firstName;
  let lastNameValue = user.lastName;
  let roleValue = user.role;
  let emailValue = user.email;
  let organizationValue = user.organization;
  let createdOnValue = user.createdOn;
  let selectedView = user.views.map((row) => {
    return {
      id: row.viewId,
      title: row.name,
    };
  });
  let activeViewValue = { id: user?.views[0]?.viewId };
  let metricValue;
  let dimensionValue;
  let customMetricValue;

  let views = user.views.map((view) => {
    const activeViewData = allData.views.result.find(
      (row) => row.id === view.viewId
    );

    var plotlyDimensions = [...allData.plotlyDimensions];

    if (activeViewData)
      plotlyDimensions = transformDimensionsForPlotly(
        activeViewData.dimensions
      );

    return {
      viewValue: view.viewId,
      // metricValue: view.visibleMetricList.filter((row) => row.status === true),
      // dimensionValue: view.visibleDimensionList.filter(
      //   (row) => row.status === true
      // ),
      // customMetricValue: view.visibleCustomMetricList.filter(
      //   (row) => row.status === true
      // ),
      // groupLevelCustomMetrics: view.groupLevelCustomMetrics,

      userSecurityFilter: transformFiltersBackendToUi(
        view?.userLevelFilters || [],
        plotlyDimensions
      ),
    };
  });

  let viewsObj = views.reduce((acc, v) => ({ ...acc, [v.viewValue]: v }), {});

  let unWrappedData = {
    id: {
      message: "",
      value: idValue,
      status: "",
    },
    groupName: {
      message: "",
      value: groupNameValue,
      status: "",
    },
    firstName: {
      message: "",
      value: firstNameValue,
      status: "",
    },
    lastName: {
      message: "",
      value: lastNameValue,
      status: "",
    },

    email: {
      message: "",
      value: emailValue,
      status: "",
    },
    role: {
      message: "",
      value: roleValue,
      status: "",
    },

    selectedView: {
      message: "",
      value: selectedView,
      status: "",
    },
    activeView: {
      message: "",
      value: activeViewValue,
      status: "",
    },
    views: {
      message: "",
      value: viewsObj,
      status: "",
    },
    activeTab: {
      message: "",
      value: {
        id: "accessList",
        title: "accessList",
      },
      status: "valid",
    },

    actualPayload: user,
  };

  return unWrappedData;
}

function validateName(name) {
  if (name === "") {
    return {
      status: "invalid",
      message: "name can not be an empty",
    };
  }

  return {
    status: "valid",
    message: "",
  };
}
function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  let isValid = re.test(email);

  if (isValid) {
    return {
      status: "valid",
      message: "",
    };
  } else {
    return {
      status: "invalid",
      message: "email is not valid",
    };
  }
}

function validateViews(props) {
  if (props.length === 0) {
    return {
      status: "invalid",
      message: "There should be atleast one value selected",
    };
  }
  return {
    status: "valid",
  };
}

function validatedDimension(selectedDimension, allDimensionData) {
  let constantDimId = allDimensionData
    .filter(
      (row) =>
        row.dimId === "day" || row.dimId === "hour" || row.dimId === "month"
    )
    .map((row) => row._id);

  let validateDim = constantDimId.map((row) => selectedDimension.includes(row));

  let flag =
    validateDim.every((row) => !row) ||
    selectedDimension.length <= constantDimId.length;
  let finalValidateDim = {
    status: flag ? "invalid" : "valid",
    message: flag ? "select required Dimension" : "",
  };

  return finalValidateDim;
}

// function groupValidation(selections, viewsData) {
//   // return selections;

//   // console.log("valid", selections);

//   const validatedName = validateName(selections.name.value);
//   const validatedViews = validateViews(selections.selectedView.value);

//   const validatedSelectedViews = selections.selectedView.value.map((row) => {
//     const validatedDimensionList = validatedDimension(
//       selections.views.value[row.id].dimensionValue.map(
//         (selectedDimId) => selectedDimId.id
//       ),
//       viewsData.find((allDimnesion) => allDimnesion.id === row.id).dimensions
//     );

//     const validatedMetricList = validateViews(
//       selections.views.value[row.id].metricValue
//     );

//     const validatedCustomMetricList = validateViews(
//       selections.views.value[row.id].customMetricValue
//     );

//     return (
//       validatedDimensionList.flag ||
//       (validatedMetricList.flag && validatedCustomMetricList.flag)
//     );
//   });
//   // const validatedDimensionList = validateViews(selections.views)
//   // const validatedMetricList = validateViews(selections.views)
//   // const validatedCustomMetricList = validateViews(selections.views)

//   const flag =
//     validatedName.flag ||
//     validatedViews.flag ||
//     validatedSelectedViews.some((row) => row);

//   // console.log("validatedPayload", flag);

//   // || validatedDimensionList.flag || validatedMetricList.flag || validatedCustomMetricList.flag

//   // console.log(
//     "every",
//     validatedSelectedViews,
//     validatedSelectedViews.some((row) => row)
//   );

//   const response = {
//     flag,
//     message: {},
//   };
//   return response;
// }

const validateAllViews = (selections, viewsData) => {
  let validatedDimensionList;
  let validatedSelectedViews;

  if (selections.selectedView.value.length !== 0) {
    validatedSelectedViews = selections.selectedView.value.map((row) => {
      if (viewsData !== undefined) {
        validatedDimensionList = validatedDimension(
          selections.views.value[row.id].dimensionValue.map(
            (selectedDimId) => selectedDimId.id
          ),
          viewsData.find((allDimnesion) => allDimnesion.id === row.id)
            .dimensions
        );
      }
      const validatedMetricList = validateViews(
        selections.views.value[row.id].metricValue
      );

      const validatedCustomMetricList = validateViews(
        selections.views.value[row.id].customMetricValue
      );

      return validatedDimensionList?.status === "valid" &&
        (validatedMetricList.status === "valid" ||
          validatedCustomMetricList.status === "valid")
        ? { status: "valid" }
        : { status: "invalid" };
    });

    if (validatedSelectedViews.every((row) => row.status === "valid")) {
      return { message: "", status: "valid" };
    }

    return { message: "please select a value", status: "invalid" };
  }

  return { message: "no views selected", status: "invalid" };
};

const validateGroup = (groupSelections, viewsData) => {
  const validatedUserSelection = {
    ...groupSelections,
    name: {
      value: groupSelections.name.value,
      ...validateName(groupSelections.name.value),
    },

    selectedView: {
      value: groupSelections.selectedView.value,
      ...validateViews(groupSelections.selectedView.value),
    },

    views: {
      value: groupSelections.views.value,
      ...validateAllViews(groupSelections, viewsData),
    },
  };

  return validatedUserSelection;
};

const validateUser = (userSelections) => {
  const validatedUserSelection = {
    ...userSelections,
    firstName: {
      value: userSelections.firstName.value,
      ...validateName(userSelections.firstName.value),
    },

    email: {
      value: userSelections.email.value,
      ...validateEmail(userSelections.email.value),
    },
    role: {
      value: userSelections.role.value,
      ...validateName(userSelections.role.value),
    },
    groupNname: {
      value: userSelections.groupName.value,
      ...validateName(userSelections.groupName.value),
    },
  };

  return validatedUserSelection;
};

const isSelectionsInvalid = (selections) => {
  for (const [key, value] of Object.entries(selections)) {
    if (value.status === "invalid") {
      return {
        status: value.status,
        message: value.message,
      };
    }
  }
  return {
    status: "valid",
    message: "",
  };
};

const userSelection = {
  id: {
    message: "",
    value: "",
    status: "valid",
  },
  name: {
    message: "",
    value: "",
    status: "valid",
  },
  selectedView: {
    message: "",
    value: [],
    status: "valid",
  },
  activeView: {
    message: "",
    value: {
      id: "",
      title: "",
    },
    status: "valid",
  },
  activeTab: {
    message: "",
    value: {
      id: "accessList",
      title: "accessList",
    },
    status: "valid",
  },
  views: {
    message: "",
    value: {},
    status: "valid",
  },
  features: {
    message: "",
    value: {},
    status: "valid",
  },
  limits: {
    message: "",
    value: {},
    status: "valid",
  },
  actualPayload: {},
};

const userData = {
  id: {
    message: "",
    value: "",
    status: "valid",
  },
  groupName: {
    message: "",
    value: "",
    status: "valid",
  },
  firstName: {
    message: "",
    value: "abc",
    status: "valid",
  },
  lastName: {
    message: "",
    value: "def",
    status: "valid",
  },
  email: {
    message: "",
    value: "",
    status: "valid",
  },
  role: {
    message: "",
    value: {
      id: "admin",
      name: "Admin",
    },
    status: "valid",
  },
  selectedView: {
    message: "",
    value: [
      {
        id: "view1",
        title: "View 1",
      },
    ],
    status: "valid",
  },
  activeView: {
    message: "",
    value: {
      id: "view1",
      title: "View 1",
    },
    status: "valid",
  },
  activeTab: {
    message: "",
    value: {
      id: "accessList",
      title: "accessList",
    },
    status: "valid",
  },
  views: [],
  features: {
    message: "",
    value: {},
    status: "valid",
  },
  limits: {
    message: "",
    value: {},
    status: "valid",
  },
  actualPayload: {},
};

const sigviewNavData = [
  { id: "accessList", name: "Access List", disabled: false },
  { id: "featureList", name: "Feature List", disabled: true },
  { id: "limitList", name: "Limit List", disabled: true },
];

const defaultActiveView = {
  id: "view1",
  name: "View 1",
  subTitle: "",
  disabled: false,
  metricData: [],
  DimensionData: [],
  customMetricData: [],
};

const validateGroupName = (name) => {
  //Common Validations
  if (!/^[A-Za-z0-9_]*$/.test(name))
    return {
      status: "invalid",
      message: "Name can only have alphabets, numbers and underscore",
    };

  if (name.length < 3)
    return {
      status: "invalid",
      message: "Name must have at least 3 character",
    };

  if (name.length > 20)
    return {
      status: "invalid",
      message: "Name cannot exceed 20 characters",
    };

  return { status: "valid", message: "" };
};

const getHardcodedValueDisabled = (data, harcode) => {
  let newArray = data.map((i) =>
    harcode.includes(i.id) ? { ...i, disabled: true } : { ...i }
  );
  return newArray;
};

let initialAdminreportState = {
  activeUser: {
    message: "",
    value: "",
    status: "valid",
  },
  view: {
    message: "",
    value: "",
    status: "valid",
  },
  group: {
    message: "",
    value: "",
    status: "valid",
  },
  sortBy: {
    message: "",
    value: "asc",
    status: "valid",
  },
  sortOn: {
    message: "",
    value: "email",
    status: "valid",
  },
  pageNumber: {
    message: "",
    value: 1,
    status: "valid",
  },
  rowsPerPage: {
    message: "",
    value: 15,
    status: "valid",
  },
  tab: {
    message: "",
    value: "allReports",
    status: "valid",
  },
};

const unwrapperScheduledReports = (data) => {
  if (data.length === 0) return [];
  return data.map((el) => {
    return {
      name: el.reportName,
      // triggerDay: el.triggerDay,
      recipients: el.recipients.length,
      createdOn: el.createdOn,
      type: "scheduled",
      reportType: "Scheduled Report",
      metadata: el,
      id: el._id,
    };
  });
};

const unwrapperSavedReports = (data) => {
  if (data.length === 0) return [];
  return data.map((el) => {
    return {
      name: el.recentDownloadRecord.name,
      recipients: el.recentDownloadRecord.recipientsList.length,
      createdOn: el.recentDownloadRecord.createdOn,
      type: "saved",
      reportType: "Saved Report",
      metadata: el,
      id: el.recentDownloadRecord.id,
    };
  });
};

const unwrapperAllReports = (data) => {
  let tableScheduleReports = unwrapperScheduledReports(data.scheduledReports);
  let tableSavedReports = unwrapperSavedReports(data.savedReports);

  return [...tableScheduleReports, ...tableSavedReports];
};

function unWrapperMetricViewsDetails(viewData, activeView, idValue) {
  const viewsDetails = viewData?.[activeView]?.[idValue]
    ? {
        id: viewData[activeView][idValue].id,
        sigviewName: viewData[activeView][idValue].sigviewName,
        physicalName: viewData[activeView][idValue].physicalName,
        formula: viewData[activeView][idValue].formula,
      }
    : false;
  return viewsDetails;
}

function unWrapperDimViewsDetails(viewData, activeView, idValue) {
  const viewsDetails = viewData?.[activeView]?.[idValue]
    ? {
        id: viewData[activeView][idValue].id,
        sigviewName: viewData[activeView][idValue].sigviewName,
        physicalName: viewData[activeView][idValue].physicalName,
        mapper:
          viewData[activeView][idValue].mapper === true ? "true" : "false",
        mapperType: viewData[activeView][idValue].mapperType,
        formula: viewData[activeView][idValue].formula,
      }
    : false;
  return viewsDetails;
}

export {
  unWrapperGroups,
  unWrapperUsers,
  unWrapperGroup,
  unWrapperUser,
  // groupValidation,
  validateGroup,
  validateUser,
  isSelectionsInvalid,
  unWrapperUserGroup,
  validateEmail,
  wrapperUser,
  wrapperGroup,
  unWrapperviews,
  validateGroupName,
  userSelection,
  userData,
  sigviewNavData,
  defaultActiveView,
  getHardcodedValueDisabled,
  initialAdminreportState,
  unwrapperAllReports,
  unwrapperScheduledReports,
  unwrapperSavedReports,
  unWrapperDimViewsDetails,
  unWrapperMetricViewsDetails,
};
