//Import required libraies
import React, { useEffect } from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";

import OverlayContainer from "../../components/Overlay/OverlayContainer";

// Import action creators
import { updateUserScreen } from "../../redux/actions";

// * Import API functions
import { updateVersion } from "../../services/api/index";

// * Define style functions
const makeSigviewStyles = (...args) => {
  const useStyles = makeStyles({
    root: {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "max-content",
        overflowY: "inherit",
      },
    },
  });
  return useStyles;
};

function OverlayDialog(props) {
  const { dispatch: ReduxDispatcher, user } = props;

  useEffect(() => {
    const runFlag =
      user.version.status === "success" &&
      user.metadata.status === "success" &&
      !user.screen.overlay.isOpen;
    if (runFlag && user.screen.overlay.children === null) {
      const getVersion = user.version.data.result.data;
      const currentUserVersion = user.metadata.data.dsbVersion;
      if (getVersion !== currentUserVersion) {
        const payload = {
          isOpen: true,
          children: <OverlayContainer />,
        };
        const action = updateUserScreen("overlay", payload);
        ReduxDispatcher(action);
        const payload1 = {
          email: user.metadata.data.email,
          version: user.version.data.result.data,
        };
        const fetchProps = {
          payload: payload1,
        };
        const updateVersionPromise = updateVersion(fetchProps);
        updateVersionPromise
          .then((responseData) => {
            console.log(responseData);
          })
          .catch((err) => {
            console.error("err", err);
          });
      }
    }
  }, [user]);

  // Defining required variables
  const open = user.screen.overlay.isOpen;

  const useSigviewStyles = makeSigviewStyles();
  const classes = useSigviewStyles();

  return (
    <Dialog
      open={open}
      //   onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.root}
    >
      {user.screen.overlay.children}
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(OverlayDialog);
