// * Import required libraies
import React, { useContext, useMemo } from "react";
import { connect, useSelector } from "react-redux";

// * Import lib components
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// * Import custom components
import MsvDimTableData from "../../components/VizTypes/MsvDimTable/MsvDimTableData";
import MsvDimTableUI from "../../components/VizTypes/MsvDimTableNew/MsvDimTableUI";
import MsvDimHeader from "../../components/VizTypes/MsvDimTable/MsvDimHeader";
import Loader from "../../components/Loader/Loader";
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Import redux utils
// Selectors
import {
  selectDimTableData,
  selectMsvTableSelections,
  selectMsvColorMapping,
  selectSelectedTableItemInChart,
  selectAllSelectedTableItem,
} from "../../redux/selectors/standaloneMsvSelectors";
// Actions
import { reloadStandaloneMsvDimensionTableData } from "../../redux/actions";

// * Define required functions

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 200px)",
    overflow: "hidden",
    backgroundColor: "white",
    border: "1px solid transparent",
    // "&:hover": { border: `1px solid ${themeColors["primaryColor"]}` },
    display: "flex",
    flexDirection: "column",
    marginRight: "15px",
  },
  dimTableContainer: {
    height: "calc(100% - 40px)", // TODO : Need to find a way to make it's height auto-adjust
    backgroundColor: "white !important",
    // flexGrow: 1,
    // flexBasis: 0,
  },
}));

function MsvDimensionTableItem(props) {
  // * Destructure props
  const {
    // REDUX PROPS
    dispatch: ReduxDispatcher,
    // PARENT PROPS
    id,
  } = props;

  // * Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Destructure REDUX props
  const dimensionTableDate = useSelector((state) =>
    selectDimTableData(state, id)
  );
  const selections = useSelector((state) =>
    selectMsvTableSelections(state, id)
  );
  const allSelectedTableItem = useSelector(selectAllSelectedTableItem);
  const selectedTableItemInChart = useSelector(selectSelectedTableItemInChart);
  const msvColorMapping = useSelector(selectMsvColorMapping);

  // * Define required static variables
  const classes = useStyles();

  // * Define ONLY ABSOLUTE NECEASSRY MEMOIZED STATES
  const childrenProps = {
    id: id,
    data: dimensionTableDate,
    selections,
    themeColors,
    allSelectedTableItem,
    selectedTableItemInChart,
    msvColorMapping,
  };
  const memoizedStringedChildrenProps = useMemo(
    () => JSON.stringify(childrenProps),
    [dimensionTableDate, selections]
  );

  // * Define required event handlers
  const handleWsDimensionTableDataReload = (event) => {
    const payload = { dimId: id };
    const action = reloadStandaloneMsvDimensionTableData(payload);
    ReduxDispatcher(action);
  };

  return (
    <Box className={classes.root}>
      <MsvDimHeader
        id={id}
        classes={classes}
        dimensionTableDate={dimensionTableDate}
      />

      <Box className={classes.dimTableContainer}>
        {/* DATA */}
        <MsvDimTableData id={id} />

        {/* UI */}
        {dimensionTableDate.status === "loading" && <Loader />}
        {dimensionTableDate.status === "error" && (
          <ErrorHandler
            message={dimensionTableDate.message}
            reloadFlag={true}
            onReload={handleWsDimensionTableDataReload}
          />
        )}
        {dimensionTableDate.status === "success" && (
          <>
            <MsvDimTableUI
              memoizedStringedChildrenProps={memoizedStringedChildrenProps}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
  allData: state.data,
});

export default connect(mapStateToProps)(MsvDimensionTableItem);
