// * Import required libraies
import React, { useRef, useContext, useMemo, useEffect, useState } from "react";
import { v4 } from "uuid";
import { useSelector } from "react-redux";
import { connect } from "react-redux";

// * Import lib components
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// * Import custom components
import WsMetricChart from "../../../components/VizTypes/WsMetricChart/WsMetricChart";
import WsMetricChartHoverAll from "../../../components/VizTypes/WsMetricChart/WsMetricChartHoverAll";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import WsMetricChartHeader from "../../../components/VizTypes/WsMetricChart/WsMetricChartHeader";
import SigviewProgressBar from "../../../components/Common/SigviewProgressBar";

// * Import redux utils
// Selectors
import {
  selectPrimaryCalendarData,
  selectSecondaryCalendarData,
  selectMetricChartType,
  selecteMetricChartGranularity,
  selectSelectedTimezone,
  selectMetricObjectFromPlotlyMetrics,
  selectMetricChartYaxisRangeType,
  selectLoadingStatusForWsMetricChart,
  selectMetricAccessor,
} from "../../../redux/selectors/standaloneWsSelectors";
// Actions
import { reloadWsAllMetricChartsData } from "../../../redux/actions";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Define required functions
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflow: "hidden",
    backgroundColor: "white",
    border: "1px solid transparent",
    "&:hover": {
      // border: `1px solid ${theme.palette.misc["secondaryColorLighter"]}`,
      boxShadow: "0px 0px 7px 0px #d7d9e2",
      "& $headerRightContainer": { display: "flex" },
    },
  },
  chartContainer: {
    height: "calc(100% - 31px - 5px)",
    width: "100%",
    // This padding is not correct here, if you really want that padding and want child not to overflow use the below width then.
    // padding: "0px 10px",
    // width: "calc(100% - 20px)",
    cursor: "grab",
    boxSizing: "border-box",
  },
  headerRoot: {
    // padding: "5px 10px",
    padding: "0px 7px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "31px",
    boxSizing: "border-box",
  },
  headerLeftContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
    flexBasis: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    cursor: "all-scroll",
  },
  headerValueContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // flexGrow: 1,
    // flexBasis: 0,
    flexShrink: 0,
  },
  headerTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // flexGrow: 1,
    // flexBasis: 0,
    flexShrink: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  headerInfoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexShrink: 0,
  },
  headerCompareContainer: {
    display: "flex",
    justifyContent: "space-between",
    justifyContent: "flex-start",
    alignItems: "center",
    // flexShrink: 0,
    flexGrow: 1,
    flexBasis: 0,
  },
  headerRightContainer: {
    display: "none",
    justifyContent: "flex-end",
    alignItems: "center",
    flexShrink: 0,
    width: "max-content",
  },
  headerCustomIcon: {
    cursor: "pointer",
    padding: "0px 5px",
    color: `${theme.palette.misc["secondaryColorLight"]} !important`,
    "&:hover": {
      color: `${theme.palette.misc["primaryColor"]} !important`,
    },
  },
  headerDeleteIcon: {
    "&:hover": {
      color: theme.palette.misc["negChangeColor"] + " !important",
    },
  },
  progressBarContainer: {
    height: "5px",
    width: "100%",
    padding: "3px 0px 0px 0px !important",
    boxSizing: "border-box",
  },
}));

function WsMetricChartItem(props) {
  // * Destructure props
  const {
    // REDUX PROPS
    dispatch: ReduxDispatcher,
    // PARENT PROPS
    id,
    user,
    standaloneWs,
  } = props;

  const [hover, setHover] = useState(false);

  // * Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define required states
  const chartContainerId = useRef(`chartHolder-${v4()}`);

  // * Destructure REDUX props
  const { metricChartHover } = standaloneWs;
  const chartType = useSelector(selectMetricChartType);
  const primaryCalendarData = useSelector((state) =>
    selectPrimaryCalendarData(state, id)
  );
  const secondaryCalendarData = useSelector((state) =>
    selectSecondaryCalendarData(state, id)
  );
  const wsMetricChartLoadingStatus = useSelector((state) =>
    selectLoadingStatusForWsMetricChart(state, id)
  );
  const accessor = useSelector((state) => selectMetricAccessor(state, id));
  const granularity = useSelector(selecteMetricChartGranularity);
  const selectedTimezone = useSelector(selectSelectedTimezone);
  const metricObj = useSelector((state) =>
    selectMetricObjectFromPlotlyMetrics(state, id)
  );
  const metricChartYaxisRangeType = useSelector(
    selectMetricChartYaxisRangeType
  );

  // * Define ONLY ABSOLUTE NECEASSRY MEMOIZED STATES
  const childrenProps = {
    id,
    primaryCalendarData: primaryCalendarData,
    secondaryCalendarData: secondaryCalendarData,
    chartContainerId: chartContainerId.current,
    isMetricChartOpen: false,
    chartType: chartType,
    accessor: accessor,
    granularity: granularity,
    metricChartYaxisRangeType: metricChartYaxisRangeType,
    selectedTimezone: selectedTimezone,
    metricObj: metricObj,
    themeColors: themeColors,
    themeState: themeState,
    metricChartHover: metricChartHover,
    hover: hover,
  };
  const memoizedStringedChildrenProps = JSON.stringify(childrenProps);
  // ! COMMENTING OUT THE MEMOIZATION AS THE HOVER CHART TOOLTIP FUNC IS AFFECTING
  // ! FIX THAT BUG IF YOU WANT TO IMPLEMENT MEMOIZATION
  // const memoizedStringedChildrenProps = useMemo(
  //   () => JSON.stringify(childrenProps),
  //   [
  //     primaryCalendarData,
  //     secondaryCalendarData,
  //     chartType,
  //     granularity,
  //     metricChartYaxisRangeType,
  //     selectedTimezone,
  //   ]
  // );

  // * Define required event handlers
  const handleReloadMetricCharts = () => {
    const action = reloadWsAllMetricChartsData();
    ReduxDispatcher(action);
  };

  // * Define required static variables
  const classes = useStyles();
  const { fullLoading, fullSuccess, partialLoading, partialSuccess, anyError } =
    wsMetricChartLoadingStatus;

  // ! This is to check when create workspace flow
  const createCrudType =
    user.screen.activeWorkspace.wsCategory !== "shared" &&
    user.screen.activeWorkspace.crudType === "create";
  const isComparisonOn = standaloneWs.timeFilters.value.isComparisonOn;

  useEffect(() => {
    const runFlag = user.screen.activeWorkspace.crudType;
    if (runFlag === "update") {
      let options = {
        root: document.querySelector("#scrollArea"),
        rootMargin: "0px",
        threshold: 1.0,
      };
      let observer = new IntersectionObserver(function (entries, observer) {
        // console.log("sonu entries", entries[0]);
        if (entries[0].isIntersecting) {
          setHover(true);
        } else {
          setHover(false);
        }
      }, options);
      let target = document.getElementById(`${id}`);
      // console.log("sonu target", id, target);
      observer.observe(target);
    }
  }, []);

  return (
    <Box className={classes.root}>
      <WsMetricChartHeader
        id={id}
        classes={classes}
        metricChartProps={childrenProps}
        anyError={anyError}
      />

      {createCrudType ? (
        <Box className={classes.chartContainer} id={chartContainerId.current}>
          <Box
            css={{
              height: "100%",
              width: "100%",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {user?.reqMetadata?.userPreferredLanguage === "ja" ? (
              <img
                height="80%"
                width="70%"
                src="https://storage.googleapis.com/sigview-icons/illustrations/chart-JP.svg"
              ></img>
            ) : (
              <img
                height="80%"
                width="70%"
                src="https://storage.googleapis.com/sigview-icons/illustrations/Chart.svg"
              ></img>
            )}
          </Box>
        </Box>
      ) : (
        <Box className={classes.chartContainer} id={chartContainerId.current}>
          {!anyError && fullLoading && <Loader />}
          {anyError && (
            <ErrorHandler
              message={primaryCalendarData.message}
              reloadFlag={true}
              onReload={handleReloadMetricCharts}
              layout="metricChart"
            />
          )}
          {!anyError && (fullSuccess || partialSuccess) && !isComparisonOn ? (
            <Box id={id}>
              <WsMetricChartHoverAll
                memoizedStringedChildrenProps={memoizedStringedChildrenProps}
              />
            </Box>
          ) : (
            <Box id={id}>
              <WsMetricChart
                memoizedStringedChildrenProps={memoizedStringedChildrenProps}
              />
            </Box>
          )}
        </Box>
      )}
      {user.screen.activeWorkspace.crudType === "update" && (
        <Box className={classes.progressBarContainer}>
          {!anyError && partialLoading && !fullLoading && (
            <SigviewProgressBar />
          )}
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  standaloneWs: state.standaloneWs,
});

export default connect(mapStateToProps)(WsMetricChartItem);
