// * Import required libraries
import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { v4 } from "uuid";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

// * Import 3rd party lib
import { Box } from "@material-ui/core";

// * Import custom components
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import AppMenuBar from "../../../components/AppMenuBar";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import AppRow from "../../../components/AppRow";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// *  Import action creators
import {
  updateUserScreen,
  updateGlobalFiltersDimensionFilters,
  updateGlobalFiltersTimeFilters,
} from "../../../redux/actions";

// * Import utils/data
import { config } from "../../../config/config";
import { masterTrackGaEvent } from "../../../services/ga";
import {
  unwrapperAppRow,
  getBreadcrumbsData,
  getBreadcrumbsDataFromRoute,
  convertChartResToDsRes,
} from "../../../utils/utils";

// * Import APIs
import { getChartObjectList } from "../../../services/api";
import { unwrapperPivots } from "../../../utils/pivotUtils";

// * Define static variables
const initialData = { status: "loading", message: "", result: [] };

function PivotHome(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    dispatch: ReduxDispatcher,
    user,
    allData,
    globalFilters = {},
  } = props;

  // * Define required hooks
  const history = useHistory();

  //Defining required state
  const [pivotList, setPivotList] = useState(initialData);
  const [reloadData, setReloadData] = useState(false);
  const [searchField, setSearchField] = useState("");

  // Defining required useEffects
  useEffect(() => {
    const source = axios.CancelToken.source();
    const runFlag =
      user.reqMetadata !== null &&
      allData.plotlyDimensions !== null &&
      allData.plotlyMetrics !== null;
    if (runFlag) {
      //Make fetch call using axios
      const fetchProps = {
        cancelToken: source.token,
      };
      const getChartObjectListPromise = getChartObjectList(fetchProps);
      getChartObjectListPromise
        .then((responseData) => {
          let newData = {
            status: "success",
            message:
              responseData?.result?.data?.length || [].length !== 0
                ? ""
                : "No charts to display",
            result: config.hardCoded.convertAllTablesToMultiTable(
              responseData?.result?.data || []
            ),
          };
          setPivotList(newData);
          const filteredPivotList = newData.result.filter(
            (row) => row.chartObject.metadata.chartType === "pivotx"
          );
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            let newData = { status: "error", message: json.error, result: [] };
            setPivotList(newData);
            console.groupCollapsed("UI ERROR");
            console.log("ERROR ->", json.error);
            console.groupEnd();
          }
        });
    }

    return () => {
      setPivotList(initialData);
      //Cancel all previous fetch calls
      if (source) source.cancel();
    };
  }, [
    user.reqMetadata,
    allData.plotlyDimensions,
    allData.plotlyDimensions,
    reloadData,
  ]);

  // * Define required change handlers
  const handleCreatePivotClick = () => {
    let id = v4();
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "CreatePivot",
      action: "PivotOpen",
      label: id,
    });
    const filteredPivotList = pivotList.result.filter(
      (row) => row.chartObject.metadata.chartType === "pivotx"
    );
    const newTitle = `Untitled Pivot - ${filteredPivotList.length + 1}`;
    const defaultPivotSelections = {
      id: id,
      title: newTitle,
      dimensionsList: [],
      metricsList: [],
      advanceSort: [],
      timeFilters: globalFilters.timeFilters,
      elementType: "create",
      renderFlag: false,
      transpose: false,
      dimensionFilters: globalFilters.dimensionFilters,
      metricFilters: [],
      dataLimit: 10,
      progressiveDateFlag:
        globalFilters.analyzeFiltersReadOnly.rollingDateType.value === "yes"
          ? true
          : false,
      feature: "pivotx",
      reloadRenderEpoch: Date.now(),
    };

    // Update activeChart in redux and screen so that user can be redirected to create pivot screen
    const value = {
      activeTab: "createPivot",
      activePivot: { ...defaultPivotSelections, type: "createPivotOnly" },
    };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);
    history.push("pivotx/create");
  };
  const handleReload = () => setReloadData((prevState) => !prevState);
  const handleSearchFieldChange = (event, value) => {
    setSearchField(value);
  };
  const handlePivotItemClick = (value) => {
    const { actualPayload: uiAppCardActualPayload } = value;
    const { chartItemPayload } = uiAppCardActualPayload;
    const { chartObjectUI, payload } = chartItemPayload;
    const { chartObject } = payload;
    const { metadata } = chartObject;

    const dataLimit = [10, 25, 50, 100].includes(metadata.dataLimit)
      ? metadata.dataLimit
      : 10;

    // Update activeChart in redux and screen so that user can be redirected to chart
    const userScreenValue = {
      activeTab: "createPivot",
      activePivot: {
        ...chartObjectUI,
        elementType: "update",
        renderFlag: true,
        transpose: false,
        // dataLimit Hardcoded
        dataLimit,
        feature: "pivotx",
        reloadRenderEpoch: Date.now(),
      },
    };
    var action = updateUserScreen(null, userScreenValue);
    ReduxDispatcher(action);
    var payloadDimFilter = {
      value: userScreenValue.activePivot.dimensionFilters,
    };
    var action_globalDimFilters =
      updateGlobalFiltersDimensionFilters(payloadDimFilter);
    ReduxDispatcher(action_globalDimFilters);
    var payloadTimeFilter = {
      value: userScreenValue.activePivot.timeFilters,
    };
    var action_globalTimeFilters =
      updateGlobalFiltersTimeFilters(payloadTimeFilter);
    ReduxDispatcher(action_globalTimeFilters);
    history.push("pivotx/create");
  };
  // ! Allready to the Pivot Home Route so nothing todo
  const handleGoHome = (user) => {};
  const handleBreadcrumbChange = (event, value) => {
    const data = { activeNav: "pivot", activeTab: value };
    const action = updateUserScreen(null, data);
    ReduxDispatcher(action);
    history.push(value.path);
  };

  const breadcrumbData = getBreadcrumbsDataFromRoute(user, history);
  const buttonProps = {
    handleClick: handleCreatePivotClick,
    title: "Create Pivot",
  };
  const appMenuBarProps = {
    buttonProps,
    onSearchFieldChange: handleSearchFieldChange,
  };
  const stickyStyles = {
    position: "sticky",
    top: "0px",
    zIndex: 1,
  };
  const pivotNoImagePosition = {
    height: "calc(100vh - 123px)",
  };
  let filteredPivots = unwrapperPivots({
    allData,
    user,
    searchField,
    data: pivotList.result,
  });
  filteredPivots = filteredPivots.map(convertChartResToDsRes);
  const savedChartsAppRowProps = {
    title: "Saved Pivots",
    payload: {},
    data: filteredPivots,
    noCardProps: {
      title: "No Pivots Available",
      flag: true,
    },
    createNewCardFlag: false,
    singleRowFlag: false,
    titleRowFlag: false,
    buttonRowFlag: false,
    appCardTag: "chartWithIcon",
    onAppCardClick: (event, value) => {
      handlePivotItemClick(value);
    },
  };
  const uiFriendlyPivots = unwrapperAppRow(savedChartsAppRowProps);
  // console.log("uiFriendlyPivots", uiFriendlyPivots);

  const additionalSidenavItems = [
    {
      id: "home",
      name: "Home",
      tooltip: "Go back to home",
      elementId: "dsBackToHome",
      iconId: "custom-home-icon",
      customClass: "sidenav-home",
      onClick: (user) => handleGoHome(user),
      isVisible: true,
    },
  ];

  const sidenavItemsOrderInfo = {
    home: 1,
    reports: 2,
    alerts: 3,
  };
  const tabName = 'pivot';
  const sidenavProps = { additionalSidenavItems, sidenavItemsOrderInfo, tabName };

  return (
    <LayoutTopSideBottom sidenavProps={sidenavProps}>
      <SigviewBreadcrumb
        data={breadcrumbData}
        onClick={handleBreadcrumbChange}
      />

      {/* THIS WRAPPER BOX IS FOR STICKY PURPOSES ONLY */}
      <Box>
        <Box css={stickyStyles}>
          <AppMenuBar {...appMenuBarProps} />
        </Box>
        <Box
          className="datastory-cat-content-wrapper"
          css={pivotNoImagePosition}
        >
          {pivotList.status === "loading" && <Loader />}
          {pivotList.status === "error" && (
            <ErrorHandler
              message={pivotList.message}
              reloadFlag={true}
              onReload={handleReload}
            />
          )}
          {pivotList.status === "success" && filteredPivots.length === 0 && (
            <div className="no-data-container">
              <div className="no-data-image"></div>
              <p className="no-data">{t(config.messages.pivot.noPivot)}</p>
            </div>
          )}
          {pivotList.status === "success" && filteredPivots.length !== 0 && (
            <AppRow {...uiFriendlyPivots} />
          )}
        </Box>
      </Box>
    </LayoutTopSideBottom>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(PivotHome);
