// Import required libraies
import React from "react";
import { connect } from "react-redux";

// Import Custom Component
import VizTypeCounterData from "../VizTypes/Counter/CounterData";
import VizTypeCounterUI from "../VizTypes/Counter/CounterUI";
import VizTypeChartUI from "../VizTypes/Chart/ChartUI";
import VizTypeChartData from "../VizTypes/Chart/ChartData";
import VizTypeTableUI from "../VizTypes/Table/TableUI";
// import VizTypeTableData from "../VizTypes/Table/TableData";
import Loader from "../Loader/Loader";
import ErrorHandler from "../ErrorHandler/ErrorHandler";

// Import styles

function VizItem(props) {
  const {
    // REDUX PROPS
    user,
    allData,
    // PARENT PROPS
    id,
    data = {},
    selections = {},
    payload = {},
    reloadEpoch = Date.now(),
    chartContainerId = "",
    displayLayout = false,
    resizeFlag,
    onChange = () => {},
    onMetricsDataReload = () => {},
    onChartDataReload = () => {},
    isCounterAtWidgetLevel = false,
  } = props;
  const { status = "loading", message = "" } = data;

  // * Define required event handlers
  const handleChartDataReload = (event) => {
    // Reset state to loading as /getData for fetch gets triggered only when status is loading
    onChange(null, id, {
      result: { dataFromQE: [], extraData: {} },
      status: "loading",
      message: "",
    });
    // Push to call back queue the reloadEpoch for this chart so that the useEffect to gets triggered (in which the above /getData call is present)

    onChartDataReload(event, id);
  };

  const handleMetricsDataReload = (event) =>
    onChartDataReload(event, "metrics");

  // * Define required static variables
  let Component;
  const chartType = selections.chartType;
  const childrenProps = {
    id,
    data,
    selections,
    reloadEpoch,
    onChange,
    payload,
    user,
    allData,
    chartContainerId,
    displayLayout,
    resizeFlag,
    onChartDataReload,
  };

  // * DEBUGGER
  // console.log("selections", selections);
  // console.log("reloadEpoch", reloadEpoch);
  // console.log("reloadEpochs", reloadEpochs);
  // console.log("id", id);
  // console.log("data", data);

  switch (chartType) {
    case "counter":
      Component = (
        <>
          {/* DATA */}
          {/* NOT NEEDED FOR COUNTER AS IT'S HAPPENING AT THE PARENT */}
          {isCounterAtWidgetLevel && <VizTypeCounterData {...childrenProps} />}

          {/* UI */}
          {status === "loading" && <Loader />}
          {!isCounterAtWidgetLevel && status === "error" && (
            <ErrorHandler
              type="noImg"
              message={message}
              reloadFlag={true}
              onReload={handleMetricsDataReload}
            />
          )}
          {isCounterAtWidgetLevel && status === "error" && (
            <ErrorHandler
              type="noImg"
              message={message}
              reloadFlag={true}
              onReload={handleChartDataReload}
            />
          )}
          {status === "success" && (
            <VizTypeCounterUI
              id={id}
              data={data}
              chartContainerId={chartContainerId}
              displayLayout={displayLayout}
              resizeFlag={resizeFlag}
            />
          )}
        </>
      );
      break;

    case "line":
    case "bar":
    case "pie":
    case "horizontalbar":
    case "multiline":
    case "multiaxisline":
    case "groupedbar":
    case "groupedbar":
    case "groupedhorizontalbar":
    case "horizontalstackedbar":
    case "verticalstackedbar":
    case "percentagestackedbar":
    case "crosstabheatmap":
    case "barline":
    case "multiaxisgroupedbar":
    case "table":
    case "multitable":
      Component = (
        <>
          {/* DATA */}
          <VizTypeChartData {...childrenProps} />

          {/* UI */}
          {status === "loading" && <Loader />}
          {status === "error" && (
            <ErrorHandler
              type="noImg"
              message={message}
              reloadFlag={true}
              onReload={handleChartDataReload}
            />
          )}
          {status === "success" && (
            <>
              {["table", "multitable"].includes(chartType) && (
                <VizTypeTableUI {...childrenProps} />
              )}
              {!["table", "multitable"].includes(chartType) && (
                <VizTypeChartUI {...childrenProps} />
              )}
            </>
          )}
        </>
      );
      break;
    default:
      Component = <p>{`${chartType} is currently not supported in Sigview`}</p>;
  }

  return Component;
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(VizItem);
