// * Import required libraries
import React, { useContext } from "react";
import { connect } from "react-redux";
import LayoutTopSideBottomAdmin from "../../../layouts/LayoutTopSideBottomAdmin/LayoutTopSideBottomAdmin";

// * import lib component
import { Box } from "@material-ui/core";

// * Import custom components
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import LayoutAdminAttributes from "../../../layouts/LayoutAdminAttributes";
import Loader from "../../../components/Loader/Loader";

import Attributes from "./Attributes";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Import utils, config & static data
import { getBreadcrumbsDataFromRoute } from "../../../utils/utils";

// * Import redux utils
import { updateUserScreen } from "../../../redux/actions";
import { useHistory } from "react-router";

//  * Import APIs

function AttributesContainer(props) {
  // * Destructure props
  const { dispatch: ReduxDispatcher, user, allData = {} } = props;
  const { views: viewsData } = allData;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define contexts
  const history = useHistory();

  if (viewsData.status !== "success") {
    history.push("/admin");
  }

  // * Define static variables

  // * Define required states

  // * Define required side effects

  // * Define requried event handlers
  const handleActiveTab = (e, id) => {
    const activeTab = id;
    const value = { activeNav: "admin", activeTab };
    const action = updateUserScreen(null, value);
    ReduxDispatcher(action);
  };

  const handleBreadcrumbChange = (event, value) => {
    history.push(value.path);
  };

  // * Define required static variables
  const activeTab = user.screen.activeTab;
  const breadcrumbsData = getBreadcrumbsDataFromRoute(user, history);
  const layoutTopSideBottomAdminProps = {
    activeTab,
    handleActiveTab,
  };
  const noAttributeMessageBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  };

  // * Define required JSX helpers
  const noAttributeMessage =
    "Reloading views data to reflect the updated changes! Please wait.";

  return (
    <>
      {viewsData.status === "loading" && (
        <LayoutTopSideBottomAdmin {...layoutTopSideBottomAdminProps}>
          <SigviewBreadcrumb
            data={breadcrumbsData}
            onClick={handleBreadcrumbChange}
          />
          <Box css={noAttributeMessageBox}>
            <div className="no-data-container">
              <div className="no-data-image"></div>
              <p className="no-data">{noAttributeMessage}</p>
            </div>
          </Box>
        </LayoutTopSideBottomAdmin>
      )}
      {viewsData.status === "success" && (
        <Attributes
          user={user}
          allData={allData}
          ReduxDispatcher={ReduxDispatcher}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(AttributesContainer);
