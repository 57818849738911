// Import required libraries
import { makeStyles } from "@material-ui/core/styles";

// Making styles
const makeSigviewStyles = (themeColors) => {
  const useStyles = makeStyles(() => ({
    alertManagerFormRow: {
      marginBottom: "24px",
      "&.frequencyItemContainer": { display: "flex !important" },
    },
  }));
  return useStyles;
};

function AlertManagerFormRow(props) {
  // Destructure props
  const { children = {}, style = {} } = props;

  // Destructuring variables from useStyles()
  const useSigviewStyles = makeSigviewStyles();
  let { alertManagerFormRow } = useSigviewStyles();
  if (style.frequencyItemContainer)
    alertManagerFormRow += " frequencyItemContainer";

  return <div className={alertManagerFormRow}>{children}</div>;
}

export default AlertManagerFormRow;
