// Import required libraries
import React from "react";
import orderBy from "lodash/orderBy";

// Import custom components
import SigviewSingleSelect from "../Common/SigviewSingleSelect";
import SigviewTextField from "../Common/SigviewTextField";

// Import styles

// Import config
import { config } from "../../config/config";

// Import action creators
import {
  addMetricFilter,
  removeMetricFilter,
  changeMetricFilterType,
  changeMetricFilterValue,
  changeMetricFilterMetricId,
  changeAbsoluteChange,
} from "../../redux/actions";

// Import utils & data
import { getNewMetricFilterRow } from "../../utils/filtersUtils";
import { getObjectByKey } from "../../utils/timeFiltersUtils";
import { masterTrackGaEvent } from "../../services/ga";

function MetricFiltersRowMsv(props) {
  const {
    user,
    allFilterTypes,
    rowIndex,
    totalLength,
    maxLimit,
    dispatchMetricFiltersStore,
    showError,
    data,
    metricFilterRow,
    showAbsoluteChange,
  } = props;

  const { id, uiCompType } = metricFilterRow;

  let Component;

  //Defining required variables
  const showDeleteIconFlag = totalLength > 1;
  const showAddIconFlag =
    maxLimit !== rowIndex + 1 && rowIndex + 1 === totalLength;
  const itemTitle = `Select all rows having`; //hard coded
  const allMetrics = [
    {
      id: "selectMetric",
      name: "Select Metric",
      disabled: true,
    },
    ...orderBy(
      data.map((row) => ({
        id: row._id,
        name: row.measureTitle,
        disabled: false,
      })),
      ["name"],
      ["asc"]
    ),
  ];

  const handleMetricIdChange = (newValue) => {
    const payload = {
      metricFilterId: id,
      metricFilterMetricId: newValue,
    };
    dispatchMetricFiltersStore(changeMetricFilterMetricId(payload));
  };
  const handleAbsoluteChange = (newValue) => {
    const payload = {
      metricFilterId: id,
      absoluteChange: newValue,
    };
    dispatchMetricFiltersStore(changeAbsoluteChange(payload));
  };

  const handleTypeChange = (newValue) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "MetricFiltersCondition",
      label: getObjectByKey({ value: newValue, data: allFilterTypes }).title,
    });

    const payload = {
      metricFilterId: id,
      metricFilterType: newValue,
    };
    dispatchMetricFiltersStore(changeMetricFilterType(payload));
    //Hard Coded
    //If user changes from between to some other type, reset 2nd value to 0
    if (newValue !== config.hardCoded.metricFilterTypesBetweenId) {
      const payload2 = {
        metricFilterId: id,
        metricFilterKey: "value2",
        metricFilterValue: "0",
      };
      dispatchMetricFiltersStore(changeMetricFilterValue(payload2));
    }
  };

  const handleRowDelete = () => {
    const payload = { metricFilterId: id };
    dispatchMetricFiltersStore(removeMetricFilter(payload));
  };

  const handleValueChange = (key, newValue) => {
    const payload = {
      metricFilterId: id,
      metricFilterKey: key,
      metricFilterValue: newValue,
    };
    dispatchMetricFiltersStore(changeMetricFilterValue(payload));
  };

  const handleAddRow = () => {
    const newMetricFilterRow = getNewMetricFilterRow();
    const payload = { newMetricFilterRow };
    dispatchMetricFiltersStore(addMetricFilter(payload));
  };

  const propsList = {
    itemTitle,
    metricFilterRow,
    allFilterTypes,
    handleTypeChange,
    handleValueChange,
    handleRowDelete,
    handleAddRow,
    handleMetricIdChange,
    handleAbsoluteChange,
    showAddIconFlag,
    showDeleteIconFlag,
    allMetrics,
    showError,
    showAbsoluteChange,
    ...metricFilterRow,
  };

  switch (uiCompType) {
    case "singleValueInput":
      Component = <MetricFilterInputRow {...propsList} />;
      break;

    case "multiValueInput":
      Component = <MetricFilterInputRow {...propsList} />;
      break;

    default:
      Component = <p>Undefined component type</p>;
  }

  return Component;
}

export default MetricFiltersRowMsv;

function MetricFilterInputRow(props) {
  const {
    itemTitle,
    uiCompType,
    allMetrics,
    allFilterTypes,
    showError,
    handleValueChange,
    handleMetricIdChange,
    handleAbsoluteChange,
    handleTypeChange,
    showDeleteIconFlag,
    handleRowDelete,
    handleAddRow,
    showAddIconFlag,
    absoluteChange,
    type,
    typeError,
    value1,
    value2,
    metricId,
    metricIdError,
    value1Error,
    value2Error,
    showAbsoluteChange,
  } = props;

  return (
    <div className="advanced-filters-item-container">
      <div className="advanced-filters-item-title-container">
        <p className="advanced-filters-item-title">{itemTitle}</p>
      </div>
      <SigviewSingleSelect
        value={metricId}
        data={allMetrics}
        onChange={handleMetricIdChange}
        error={showError && metricIdError.flag}
        helperText={metricIdError.message}
        minWidth="130px"
        maxWidth="130px"
        margin={{ right: "5px" }}
        customClassName={`MetricFilterDropdownValue${type}`}
      />

      {showAbsoluteChange && (
        <SigviewSingleSelect
          value={absoluteChange}
          data={[
            { id: "id_only", name: "Value", disabled: false },
            {
              id: "id_trueDelta",
              name: "Absolute Change",
              disabled: false,
            },
            {
              id: "id_deltaPercentage",
              name: "Percentage Change",
              disabled: false,
            },
          ]}
          onChange={handleAbsoluteChange}
          error={showError && metricIdError.flag}
          helperText={metricIdError.message}
          minWidth="130px"
          maxWidth="130px"
          margin={{ right: "5px" }}
        />
      )}

      <SigviewSingleSelect
        value={type}
        data={allFilterTypes}
        onChange={handleTypeChange}
        error={showError && typeError.flag}
        helperText={typeError.message}
        minWidth="150px"
        margin={{ right: "5px" }}
        customClassName="MetricFilterSelectedValues-GA"
      />
      <SigviewTextField
        value={value1}
        onChange={(newValue) => handleValueChange("value1", newValue)}
        placeholder="Enter Number"
        error={showError && value1Error.flag}
        helperText={value1Error.message}
        minWidth="100px"
        maxWidth="100px"
        margin={{ right: "5px" }}
        type="number"
      />
      {uiCompType === "multiValueInput" && (
        <>
          <div className="advanced-filters-item-title-container">
            <p className="advanced-filters-item-title">and</p>
          </div>

          <SigviewTextField
            value={value2}
            onChange={(newValue) => {
              console.log(newValue);
              handleValueChange("value2", newValue);
            }}
            placeholder="Enter Number"
            error={showError && value2Error.flag}
            helperText={value2Error.message}
            minWidth="100px"
            maxWidth="100px"
            margin={{ right: "5px" }}
            type="number"
          />
        </>
      )}
      <div
        className={`advanced-filters-item-icon-container ${
          showDeleteIconFlag ? "visible" : "hidden"
        }`}
      >
        <i
          className="material-icons-outlined advanced-filters-item-icon af-delete-icon"
          onClick={handleRowDelete}
        >
          cancel
        </i>
      </div>
      {showAddIconFlag && (
        <div className="advanced-filters-item-icon-container">
          <i
            className="material-icons advanced-filters-item-icon af-add-icon"
            onClick={handleAddRow}
          >
            add
          </i>
        </div>
      )}
    </div>
  );
}
