import { id } from "date-fns/locale";
import orderBy from "lodash.orderby";
import {
  transformFiltersBackendToUi,
  transformFiltersUiToBackend,
} from "./filtersUtils";
import { transformDimensionsForPlotly } from "./utils";

const mapping = {
  id: "id",
  name: "Name",
  lastModifiedOn: "Last Modified On",
  lastSuccessLoginTime: "Last Logged On",
  createdOn: "Created On",
  organization: "Organization",
  usersCount: "Number of Users",
  createdBy: "Created By",
  lastModifiedBy: "Last Modified By",
  firstName: "First Name",
  lastName: "Last Name",
  userGroupName: "Group",
  role: "Role",
  userName: "Name",
  emailId: "Email",
};

function unWrapperUsers(groupsUsersData) {
  let groups = groupsUsersData;
  let groupKey = Object.keys(groups[0]);

  let groupKeyObj = groupKey.map((row) => {
    if (row !== "_id") {
      if (row !== "userId") {
        if (row !== "organization") {
          if (row !== "firstName") {
            if (row !== "lastName") {
              if (row !== "role") {
                if (row !== "lastModifiedOn") {
                  if (row !== "lastModifiedBy") {
                    if (row !== "organization") {
                      if (row !== "userGroup") {
                        if (row !== "views") {
                          if (row !== "groups") {
                            if (row !== "activeStatus") {
                              return {
                                id: row,
                                name: row,
                                label: mapping[row],
                              };
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  });

  let groupKeyObjFiltered = groupKeyObj.filter((row) => row !== undefined);

  return groupKeyObjFiltered;
}

const unwrapperViewsFromAllData = (allData) => {
  let views = allData.views.result.map((row) => {
    return {
      id: row.id,
      name: row.name,
      label: row.name,
    };
  });

  return views;
};
const unwrapperGroupsInViews = (data, view) => {
  let filteredView = data.filter((row) => row.view === view);

  return filteredView[0].groupInfo.map((row) => {
    return {
      id: row.groupId,
      name: row.groupName,
    };
  });
};

function validateName(name) {
  if (name === "" || name.length < 3) {
    return {
      status: "invalid",
      message: "Name must have atleast 3 characters",
    };
  }

  if (name[0] === " ") {
    return {
      status: "invalid",
      message: "Name can not start with an empty string",
    };
  }
  let regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (regex.test(name)) {
    return {
      status: "invalid",
      message: "Name should not contain special characters",
    };
  }

  return {
    status: "valid",
    message: "",
  };
}
function validateEmail(email) {
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let isValid = re.test(email);

  if (isValid) {
    return {
      status: "valid",
      message: "",
    };
  } else {
    return {
      status: "invalid",
      message: "email is not valid",
    };
  }
}

function validateViews(props) {
  if (props.length === 0) {
    return {
      status: "invalid",
      message: "There should be atleast one value selected",
    };
  }
  return {
    status: "valid",
  };
}
const validateAllViews = (selections, viewsData) => {
  let validatedDimensionList;
  let validatedSelectedViews;

  if (selections.selectedView.value.length !== 0) {
    validatedSelectedViews = selections.selectedView.value.map((row) => {
      if (viewsData !== undefined) {
        validatedDimensionList = validatedDimension(
          selections.views.value[row.id].dimensionValue.map(
            (selectedDimId) => selectedDimId.id
          ),
          viewsData.find((allDimnesion) => allDimnesion.id === row.id)
            .dimensions
        );
      }
      const validatedMetricList = validateViews(
        selections.views.value[row.id].metricValue
      );

      const validatedCustomMetricList = validateViews(
        selections.views.value[row.id].customMetricValue
      );

      return validatedDimensionList?.status === "valid" &&
        (validatedMetricList.status === "valid" ||
          validatedCustomMetricList.status === "valid")
        ? { status: "valid" }
        : { status: "invalid" };
    });

    if (validatedSelectedViews.every((row) => row.status === "valid")) {
      return { message: "", status: "valid" };
    }

    return { message: "please select a value", status: "invalid" };
  }

  return { message: "no views selected", status: "invalid" };
};

function validatedDimension(selectedDimension, allDimensionData) {
  let constantDimId = allDimensionData
    .filter(
      (row) =>
        row.dimId === "day" || row.dimId === "hour" || row.dimId === "month"
    )
    .map((row) => row._id);

  let validateDim = constantDimId.map((row) => selectedDimension.includes(row));

  let flag =
    validateDim.every((row) => !row) ||
    selectedDimension.length <= constantDimId.length;
  let finalValidateDim = {
    status: flag ? "invalid" : "valid",
    message: flag ? "select required Dimension" : "",
  };

  return finalValidateDim;
}

const validateViewsAws = (viewsValue, selectedView) => {
  let selectedViewArray = selectedView.map((el) => el.id);
  for (const key in viewsValue) {
    if (selectedViewArray.includes(key)) {
      if (
        viewsValue[key].metrics?.length === 0 &&
        viewsValue[key].customMetrics?.length === 0
      ) {
        return {
          status: "invalid",
          message: "please select a view",
        };
      }

      let filteredDim = viewsValue[key].dimensions?.filter(
        (row) => !["D001", "D002", "D003", "D150"].includes(row.id)
      );

      if (filteredDim?.length === 0) {
        return {
          status: "invalid",
          message: "please select a view",
        };
      }
    }
  }

  return { status: "valid", message: "" };
};

const validateselectedViewAws = (value) => {
  if (value.length === 0) {
    return {
      status: "invalid",
      message: "please select a view",
    };
  }

  return { status: "valid", message: "" };
};

const validateUserAws = (userSelections) => {
  const validatedUserSelection = {
    ...userSelections,
    firstName: {
      value: userSelections.firstName.value,
      ...validateName(userSelections.firstName.value),
    },

    email: {
      value: userSelections.email.value,
      ...validateEmail(userSelections.email.value),
    },

    views: {
      value: userSelections.views.value,
      ...validateViewsAws(
        userSelections.views.value,
        userSelections.selectedView.value
      ),
    },
    selectedView: {
      value: userSelections.selectedView.value,
      ...validateselectedViewAws(userSelections.selectedView.value),
    },
  };

  return validatedUserSelection;
};

const isSelectionsInvalid = (selections) => {
  for (const [key, value] of Object.entries(selections)) {
    if (value.status === "invalid") {
      return {
        status: value.status,
        message: value.message,
      };
    }
  }
  return {
    status: "valid",
    message: "",
  };
};

const initialUserSelection = {
  id: {
    message: "",
    value: "",
    status: "valid",
  },
  // groupName: {
  //   message: "",
  //   value: "",
  //   status: "valid",
  // },
  firstName: {
    message: "",
    value: "",
    status: "valid",
  },
  lastName: {
    message: "",
    value: "",
    status: "valid",
  },
  email: {
    message: "",
    value: "",
    status: "valid",
  },
  selectedView: {
    message: "",
    value: [],
    status: "valid",
  },
  selectedGroup: {
    message: "",
    value: {},
    status: "valid",
  },
  activeView: {
    message: "",
    value: {},
    status: "valid",
  },
  activeTab: {
    message: "",
    value: {
      id: "accessList",
      title: "accessList",
    },
    status: "valid",
  },
  views: {
    message: "",
    value: {
      dimensions: [],
      metrics: [],
      customMetrics: [],
    },
    status: "valid",
  },
  features: {
    message: "",
    value: {},
    status: "valid",
  },
  limits: {
    message: "",
    value: {},
    status: "valid",
  },
  actualPayload: {},
};

const unwrapperUserAws = (data, allData) => {
  let selectedViewValue = data.orgInfoList[0].viewInfoList.map((row) => {
    return { id: row.name };
  });

  let selectedGroupValue = {};
  data.orgInfoList[0].viewInfoList.forEach((row) => {
    selectedGroupValue[row.name] = row.groupInfoList[0].groupId;
  });

  allData.viewGroupList.result.forEach((row) => {
    if (selectedGroupValue[row.view] === undefined) {
      selectedGroupValue[row.view] = row.groupInfo[0].groupId;
    }
  });

  let activeViewValue = selectedViewValue[0];

  let activeViewInfo = data.orgInfoList[0].viewInfoList.find(
    (row) => row.name === activeViewValue.id
  );

  let activeGroupInfo = activeViewInfo.groupInfoList.find(
    (row) => row.groupId === selectedGroupValue[activeViewValue.id]
  );

  let viewValue = {};

  data.orgInfoList[0].viewInfoList.forEach((row) => {
    const activeGroupInfo1 =
      allData.viewGroupList.result.filter((el) => el.view === row.name)[0] ||
      [];

    const activeViewData1 =
      activeGroupInfo1?.groupInfo?.filter(
        (el) => el.groupId === row.groupInfoList[0].groupId
      )[0] || [];

    viewValue[row.name] = {
      groupId: row.groupInfoList[0].groupId,

      dimensions: row.groupInfoList[0].visibleDimensionList,

      metrics: row.groupInfoList[0].visibleMetricList,
      customMetrics: row.groupInfoList[0].visibleCustomMetricList,
      userFilters: transformFiltersBackendToUi(
        row.filters || [],
        transformDimensionsForPlotly(activeViewData1.dimensionList)
      ),
    };
  });

  allData.viewGroupList.result.forEach((row) => {
    if (viewValue[row.view] === undefined) {
      viewValue[row.view] = {
        groupId: row.groupInfo[0].groupId,

        dimensions: row.groupInfo[0].dimensionList.map((row) =>
          row.status === true ? row._id : ""
        ),

        metrics: row.groupInfo[0].metricList.map((row) =>
          row.status === true ? row._id : ""
        ),
        customMetrics: row.groupInfo[0].customMetricList.map((row) =>
          row.status === true ? row._id : ""
        ),
        userFilters: [],
      };
    }
  });

  // let viewValue = {
  //   dimensions: activeGroupInfo.visibleDimensionList,
  //   metrics: activeGroupInfo.visibleMetricList,
  //   customMetrics: activeGroupInfo.visibleCustomMetricList,
  // };

  let user = {
    id: {
      message: "",
      value: data.userId,
      status: "valid",
    },
    // groupName: {
    //   message: "",
    //   value: "",
    //   status: "valid",
    // },
    firstName: {
      message: "",
      value: data.firstName,
      status: "valid",
    },
    lastName: {
      message: "",
      value: data.lastName,
      status: "valid",
    },
    email: {
      message: "",
      value: data.email,
      status: "valid",
    },

    selectedView: {
      message: "",
      value: selectedViewValue,

      status: "valid",
    },
    selectedGroup: {
      message: "",
      value: selectedGroupValue,
      status: "valid",
    },
    activeView: {
      message: "",
      value: activeViewValue,
      status: "valid",
    },
    activeTab: {
      message: "",
      value: {
        id: "accessList",
        title: "accessList",
      },
      status: "valid",
    },
    views: {
      message: "",
      value: viewValue,
      status: "valid",
    },
    features: {
      message: "",
      value: {},
      status: "valid",
    },
    limits: {
      message: "",
      value: {},
      status: "valid",
    },
    actualPayload: { message: "", value: { ...data }, status: "valid" },
  };

  return user;
};

const unwrapperTabViews = (data) => {
  if (data.status === "success") {
    return data.result.map((row) => {
      return {
        id: row.view,
        name: row.view,
        views: row.groupInfo.map((row) => {
          return {
            id: row.groupId,
            name: row.groupName,

            metricList: row.metricList,
            customMetricList: row.customMetricList,
            dimensionList: row.dimensionList,
          };
        }),
      };
    });
  } else {
    return [];
  }
};

const transformToChecklistData = (data) => {
  const disabledValuesArray = ["hour", "day", "week", "month"];

  let timeValueAttributes = [];
  let nonTimeValueAttributes = [];

  data.forEach((row) => {
    if (disabledValuesArray.includes(row.title.toLowerCase())) {
      timeValueAttributes.push({
        id: row._id,
        title: row.title,
        disabled: true,
        hoverInfo: "info",
        backendName: row.dimId,
        actualPayload: row,
      });
    } else {
      nonTimeValueAttributes.push({
        id: row._id,
        title: row.title,
        disabled: false,
        hoverInfo: "info",
        backendName: row.dimId,
        actualPayload: row,
      });
    }
  });

  let sortedTimeValueAttributes = orderBy(
    timeValueAttributes,
    ["title"],
    ["asc"]
  );
  let sortedNonTimeValueAttributes = orderBy(
    nonTimeValueAttributes,
    ["title"],
    ["asc"]
  );

  return [...sortedTimeValueAttributes, ...sortedNonTimeValueAttributes].filter(
    (row) => row.title.toLowerCase() !== "week"
  );
};

const wrapperUserAwsCreate = (userSelections, reqMetadata, viewsData) => {
  const { result: allViews } = viewsData;

  let viewInfoListValue = [];

  viewInfoListValue = userSelections.selectedView.value.map((row) => {
    let activeAllView = allViews.find((item) => item.name === row.id);

    // let activeDimIds = activeAllView.dimensions.map((el) => el._id);
    // let activeMetricIds = activeAllView.metrics.map((el) => el._id);
    // let activeCustomMetricIds = activeAllView.customMetrics.map((el) => el._id);

    let visibleMetricListIds = userSelections.views.value[row.id].metrics
      .filter((elm) => elm.id !== undefined)
      .map((el) => el.id);
    let visibleDimensionListIds = userSelections.views.value[row.id].dimensions
      .filter((elm) => elm.id !== undefined)
      .map((el) => el.id);
    let visibleCustomMetricListIds = userSelections.views.value[
      row.id
    ].customMetrics
      .filter((elm) => elm.id !== undefined)
      .map((el) => el.id);

    return {
      name: row.id,
      isActive: true,
      jobRole: "user",
      groupInfoList: [
        {
          groupId: userSelections.views.value[row.id].groupId,
          jobRole: "user",
          visibleMetricList: activeAllView.metrics.map((el) => {
            if (visibleMetricListIds.includes(el._id)) {
              return {
                id: el._id,
                status: true,
              };
            } else {
              return {
                id: el._id,
                status: false,
              };
            }
          }),
          visibleDimensionList: activeAllView.dimensions.map((el) => {
            if (visibleDimensionListIds.includes(el._id)) {
              return {
                id: el._id,
                status: true,
              };
            } else {
              return {
                id: el._id,
                status: false,
              };
            }
          }),
          visibleCustomMetricList: activeAllView.customMetrics.map((el) => {
            if (visibleCustomMetricListIds.includes(el._id)) {
              return {
                id: el._id,
                status: true,
              };
            } else {
              return {
                id: el._id,
                status: false,
              };
            }
          }),
        },
      ],
      filters: transformFiltersUiToBackend(
        userSelections.views.value[row.id].userFilters
      ),
    };
  });

  let finalResponse = {
    email: userSelections.email.value,
    firstName: userSelections.firstName.value,
    lastName: userSelections.lastName.value,
    createdBy: reqMetadata.email,
    activeOrg: reqMetadata.organization,
    password: "",
    orgInfoList: [
      {
        name: reqMetadata.organization,
        viewInfoList: viewInfoListValue,
      },
    ],
  };

  return finalResponse;
};
const wrapperUserAws = (userSelections, reqMetadata, viewsData) => {
  const { result: allViews } = viewsData;

  const isAnAdmin =
    userSelections.actualPayload.value.orgInfoList.length > 0 &&
    userSelections.actualPayload.value.orgInfoList[0].viewInfoList.some(
      (el) => el.jobRole === "admin"
    )
      ? "admin"
      : "user";

  let viewInfoListValue = [];

  viewInfoListValue = userSelections.selectedView.value.map((row) => {
    let activeAllView = allViews.find((item) => item.name === row.id);

    // let activeDimIds = activeAllView.dimensions.map((el) => el._id);
    // let activeMetricIds = activeAllView.metrics.map((el) => el._id);
    // let activeCustomMetricIds = activeAllView.customMetrics.map((el) => el._id);

    let visibleMetricListIds = userSelections.views.value[row.id].metrics.map(
      (el) => el.id
    );
    let visibleDimensionListIds = userSelections.views.value[
      row.id
    ].dimensions.map((el) => el.id);
    let visibleCustomMetricListIds = userSelections.views.value[
      row.id
    ].customMetrics.map((el) => el.id);

    return {
      name: row.id,
      isActive:
        userSelections.actualPayload.value.orgInfoList?.[0]?.viewInfoList?.filter(
          (el) => el.name === row.id
        )?.[0]?.isActive || false,
      jobRole:
        userSelections.actualPayload.value.orgInfoList?.[0]?.viewInfoList?.filter(
          (el) => el.name === row.id
        )?.[0]?.jobRole || isAnAdmin,
      groupInfoList: [
        {
          groupId: userSelections.views.value[row.id].groupId,
          jobRole: isAnAdmin,
          visibleMetricList: activeAllView.metrics.map((el) => {
            if (visibleMetricListIds.includes(el._id)) {
              return {
                id: el._id,
                status: true,
              };
            } else {
              return {
                id: el._id,
                status: false,
              };
            }
          }),
          visibleDimensionList: activeAllView.dimensions.map((el) => {
            if (visibleDimensionListIds.includes(el._id)) {
              return {
                id: el._id,
                status: true,
              };
            } else {
              return {
                id: el._id,
                status: false,
              };
            }
          }),
          visibleCustomMetricList: activeAllView.customMetrics.map((el) => {
            if (visibleCustomMetricListIds.includes(el._id)) {
              return {
                id: el._id,
                status: true,
              };
            } else {
              return {
                id: el._id,
                status: false,
              };
            }
          }),
          // visibleMetricList: userSelections.views.value[row.id].metrics,
          // visibleDimensionList: userSelections.views.value[row.id].dimensions,
          // visibleCustomMetricList:
          //   userSelections.views.value[row.id].customMetrics,
        },
      ],
      filters: transformFiltersUiToBackend(
        userSelections.views.value[row.id].userFilters
      ),
    };
  });

  let finalResponse = {
    email: userSelections.email.value,
    firstName: userSelections.firstName.value,
    lastName: userSelections.lastName.value,
    activeOrg: reqMetadata.organization,
    orgInfoList: [
      {
        name: reqMetadata.organization,
        viewInfoList: viewInfoListValue,
      },
    ],
    loginInfo: userSelections.actualPayload.value.loginInfo,
    userId: userSelections.actualPayload.value.userId,
  };

  return finalResponse;
};

const wrapperCustomMetricFormAws = (data, reqMetadata) => {
  let idsMap = {};

  let metricList = [];
  if (data.dataUnitType.value === "percentile") {
    for (const i in data.formulaEntity) {
      if (data.formulaEntity[i].value.id !== "numeric") {
        metricList.push(data.formulaEntity[i].value.id);
      }

      idsMap[i] =
        data.formulaEntity[i].value.id === "numeric"
          ? {
              id: "const",
              function: `${data.formulaEntity[i].value.num}`,
            }
          : {
              id: data.formulaEntity[i].value.id,
              function: "approxPercentile",
            };
    }
  } else {
    for (const i in data.formulaEntity) {
      if (data.formulaEntity[i].value.id !== "numeric") {
        metricList.push(data.formulaEntity[i].value.id);
      }

      idsMap[i] =
        data.formulaEntity[i].value.id === "numeric"
          ? {
              id: "const",
              function: `${data.formulaEntity[i].value.num}`,
            }
          : {
              id: data.formulaEntity[i].value.id,
              function: "sum",
            };
    }
  }
  let wrappedData = {
    orgViewReq: {
      organization: reqMetadata.organization,
      view: data.activeView.value,
    },

    customMetric: {
      title: data.name.value,
      metricType: "custom",
      dummyFormula: data.formulaType.value,
      idsMap: idsMap,
      metricLevel: "view",
      referenceId: "view",
      status: true,
      metricList: metricList,
      dataUnit: {
        dType: data.dataUnitType.value,
        value: data.dataUnitSymbol.value,
      },
      isPercentageOn: data.isAdditive.value,
      _id: data.id.value,
    },

    groupList: [],
  };

  return { ...wrappedData };
};

export {
  unWrapperUsers,
  unwrapperViewsFromAllData,
  unwrapperGroupsInViews,
  unwrapperUserAws,
  unwrapperTabViews,
  initialUserSelection,
  transformToChecklistData,
  wrapperUserAws,
  wrapperCustomMetricFormAws,
  validateUserAws,
  wrapperUserAwsCreate,
  validateEmail,
};
