import { Box, Checkbox, makeStyles, withStyles } from "@material-ui/core";
import isEqual from "lodash.isequal";
import React, { memo, useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import SigviewIcon from "../Common/SigviewIcon";

function areEqual(prevProps, nextProps) {
  let prev = prevProps;
  let next = nextProps;
  //   prev = delete prev.children;
  //   next = delete next.children;

  var prev2 = {};
  var next2 = {};

  if (
    (prev?.selections?.find((el) => el === prev.item.id) &&
      next?.selections?.find((el) => el === next.item.id)) ||
    (prev?.selections?.find((el) => el !== prev.item.id) &&
      next?.selections?.find((el) => el !== next.item.id))
  ) {
    prev = { ...prev, selections: [] };
    next = { ...next, selections: [] };
  }
  return isEqual(prev, next);
}

// * Define required variables
const makeSigviewStyles = (...args) => {
  const [themeColors, style = {}] = args;
  const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
      flexDirection: "column",
      color: themeColors["sidenavItemColor"],
      // backgroundColor: themeColors["sidenavBgColor"],
      height: "100%",
      // padding: "10px 12px",
      boxSizing: "border-box",
      width: "100%",
    },
    itemBase: { display: "flex", alignItems: "center", width: "100%" },
    checkboxItemLabel: {
      fontSize: "12px",
      color: themeColors["secondaryColor"],
      paddingLeft: "0px",
      paddingRight: "2px",
      boxSizing: "border-box",
      width: "calc(100% - 18px - 16px)",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "inline-block",
      overflow: "hidden",
      cursor: "pointer",
    },
    checkboxItemLabelSelected: { color: themeColors["primaryColor"] },
    disabledItem: {
      opacity: 0.5,
      pointerEvents: "none",
    },
  }));
  return useStyles;
};

function ChecklistRowMetric(props = {}) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  // * Destructure props
  const {
    item = {},
    variant = "selected",
    provided = {},
    setSelections = () => {},
    selections = [],
  } = props;

  const onCheckboxChange = (event, itemId, flag) => {
    if (flag) {
      setSelections((selections) => [...selections, itemId]);
    } else {
      setSelections((selections) => selections.filter((id) => itemId !== id));
    }
  };

  const SigviewCheckbox = withStyles(() => ({
    root: {
      opacity: 1,
      color: `${themeColors["primaryColor"]} !important`,
      padding: "0px !important",
      marginRight: "5px",
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18,
      },
      "&.MuiCheckbox-colorSecondary.Mui-checked:hover": {
        backgroundColor: "transparent",
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  }))(Checkbox);

  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const droppableStyle = {
    padding: "0px",
    backgroundColorDuringDragging: "transparent",
    backgroundColor: "transparent",
  };
  const draggableStyle = {
    backgroundColorDuringDragging: themeColors["secondaryColorLightest"],
    backgroundColor: themeColors["secondaryColorLightest"],
    marginRight: "0px",
    marginBottom: "0px",
    borderRadius: "3px",
    border: `1px solid ${themeColors["secondaryColorLighter"]}`,
  };

  // * Define required event handlers
  const handleCheckboxChange = (event) => {
    if (!item.disabled)
      onCheckboxChange(event, item.id, variant === "remaining");
  };

  // * Define required variables
  var labelClassName = classes.checkboxItemLabel;
  if (variant === "selected")
    labelClassName += ` ${classes.checkboxItemLabelSelected}`;
  if (variant === "remaining")
    labelClassName += ` ${classes.checkboxItemLabelRemaining}`;
  if (item.disabled) labelClassName += ` ${classes.disabledItem}`;
  const checkboxClassName = item.disabled ? classes.disabledItem : "";

  console.log("dndmetric");

  return (
    <Box className={classes.itemBase}>
      <SigviewCheckbox
        checked={variant === "selected"}
        value="checkedG"
        inputProps={{ "aria-label": "secondary checkbox" }}
        disabled={item.disabled}
        className={checkboxClassName}
        onClick={handleCheckboxChange}
      />
      <Box
        component="label"
        title={item.name}
        className={labelClassName}
        onClick={handleCheckboxChange}
      >
        {item.name}
      </Box>

      {variant === "selected" && (
        <span {...provided.dragHandleProps}>
          <SigviewIcon
            className="material-icons-outlined"
            iconName="menu"
            style={{
              fontSize: "16px !important",
              display: "inline-flex",
              padding: "0px 0px 0px 0px",
              color: themeColors["secondaryColorLight"],
              hoverColor: themeColors["secondaryColorLight"],
              cursor: "grab",
            }}
          />
        </span>
      )}
    </Box>
  );
}

export default memo(ChecklistRowMetric, areEqual);
