function CardCreate(props) {
  const { payload, classes } = props;
  const { bgIMG, icon, tag, title, onClick = () => {} } = payload;
  const { flag, className, value } = icon;

  // Defining required event handlers
  const handleClick = (e) => onClick(e, props);

  //declaring utils variables
  let cardClassName = classes.card + " card-create-and-nochart";
  // let heightWidth = { height: 56, width: 72 };
  // * Different Variation
  let heightWidth = { height: 90, width: 90 };
  if (tag === "createNew") heightWidth = { height: 56, width: 72 };
  let extraStyles = {
    cardImageContainerStyles: heightWidth,
    cardBackgroundIconStyles: { fontSize: "40px" },
  };

  switch (tag) {
    case "noCharts":
      extraStyles = {
        cardImageContainerStyles: {
          ...extraStyles.cardImageContainerStyles,
          backgroundImage: `url(${bgIMG})`,
        },
      };
      cardClassName += " card-nochart";
      break;
    case "createNew":
      cardClassName += " card-create";
      break;
    default:
      break;
  }

  const { cardImageContainerStyles, cardBackgroundIconStyles } = extraStyles;
  var datastoryTitleClass = title.replace(/ +/g, "");

  return (
    <>
      <article
        className={`${cardClassName} ${datastoryTitleClass}-GA`}
        onClick={handleClick}
      >
        <div className="card-image-container" style={cardImageContainerStyles}>
          {flag && (
            <span
              className={`${className} ${datastoryTitleClass}-GA`}
              style={cardBackgroundIconStyles}
            >
              {value}
            </span>
          )}
        </div>
        <p className={`card-title ${datastoryTitleClass}-GA`} title={title}>
          {title}
        </p>
      </article>
    </>
  );
}

export default CardCreate;
