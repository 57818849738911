import C from "../constants";
import {
  addValidationToDsForm,
  populateContainerInfoV2,
} from "../../utils/dsUtils";
import { config } from "../../config/config";
import { v4 } from "uuid";
import omit from "lodash.omit";

import { getMeasureObjByOriginalID } from "../../utils/plotlyUtils";

// TODO Move to utils
const defaultQeData = {
  status: "loading",
  message: "",
  result: { dataFromQE: [], extraData: {} },
};
const defaultKpiData = {
  status: "loading",
  message: "",
  result: { value: 0, displayValue: "0" },
};

function standaloneDs(state, action) {
  const { type = "", payload = {} } = action;
  const {
    key = "",
    value = "",
    widgetItemId = "",
    widgetItemValue = {},
    widgetItemType = "chart", //chart or counter
    metricsDataFromQE = {},
    metricsDataFromQEStatus = "success",
    chartObjectPayload = {},
    widgetItemTitle = "",
    modifiedKpis = [],
    modifiedCharts = [],
    allData = {},
    defaultTimeFilters = {},
  } = payload;

  let newState = {};
  let newDataFromQEVal = [];
  let newReloadEpochsValue = {};
  let newContainerInfoValue = {};
  let newRollingDateTypeValue = "yes";
  switch (type) {
    case C.UPDATE_STANDALONE_DS_FORM:
      switch (key) {
        case "datastoryName":
        case "dimensionFilterType":
        case "timeFilterType":
        case "rollingDateType":
        case "dimensionFilters":
        case "selectedCharts":
        case "selectedKpis":
        case "containerInfo":
        case "type":
        case "actualPayload":
          newState = {
            ...state,
            [key]: { ...state[key], value },
          };
          break;
        // * BUSINESS LOGIC
        // If dateRange is custom, reset rolling dates to no; else yes
        case "timeFilters":
          if (value.selectedDatePreset === "custom")
            newRollingDateTypeValue = "no";
          newState = {
            ...state,
            [key]: { ...state[key], value },
            rollingDateType: {
              ...state.rollingDateType,
              value: newRollingDateTypeValue,
            },
          };
          break;
        case "layoutEditable":
          // update gridlayout static flag to false
          let newGridLayout = {
            ...state.gridLayout,
            value: state.gridLayout.value.map((row) => ({
              ...row,
              static: !value,
            })),
          };
          newState = {
            ...state,
            [key]: { ...state[key], value },
            gridLayout: newGridLayout,
          };
          break;
        case "gridLayout":
          // Update container info as well since it will be used in wrapperDatastory
          for (const [containerInfoKey, containerInfoValue] of Object.entries(
            state.containerInfo.value
          )) {
            const gridLayoutRow = value.find(
              (row) => row.widgetItemId === containerInfoKey
            );
            newContainerInfoValue[containerInfoKey] = {
              ...containerInfoValue,
              metadata: gridLayoutRow?.container?.metadata || {
                i: containerInfoKey,
                x: 0,
                y: 0,
                w: 3,
                h: 4,
              },
            };
          }
          newState = {
            ...state,
            [key]: { ...state[key], value },
            containerInfo: {
              ...state.containerInfo,
              value: newContainerInfoValue,
            },
          };
          break;
        default:
          console.groupCollapsed("UI ERROR");
          console.log("UNIDENTIFIED TYPE");
          console.log("REDUCER -> ", "Datastory.js");
          console.log("state -> ", state);
          console.log("payload -> ", payload);
          console.groupEnd();
          newState = { ...state };
      }
      break;

    case C.UPDATE_STANDALONE_DS_FORM_MULTIPLE_FIELDS:
      newState = { ...state };
      console.log("value", value);
      for (const [fieldName, fieldValue] of Object.entries(value)) {
        console.log("fieldName", fieldName);
        console.log("fieldValue", fieldValue);
        newState = {
          ...newState,
          [fieldName]: { ...newState[fieldName], value: fieldValue },
        };
      }
      break;

    case C.REPLACE_STANDALONE_DS_FORM:
      newState = { ...value };
      break;

    case C.UPDATE_STANDALONE_DS_FORM_CHART_DATA:
      // Do this only when the widget exists
      // In case it's deleted do nothing
      if (state.dataQE.value[widgetItemId]) {
        newDataFromQEVal = {
          ...state.dataQE.value,
          [widgetItemId]: {
            ...widgetItemValue,
          },
        };
        newState = {
          ...state,
          dataQE: {
            value: newDataFromQEVal,
            status: "valid",
            message: "",
            stepTag: "",
          },
        };
      } else {
        newState = { ...state };
      }
      break;

    case C.UPDATE_STANDALONE_DS_FORM_METRICS_DATA:
      newDataFromQEVal = { ...state.dataQE.value };
      if (metricsDataFromQEStatus === "success") {
        Object.keys(metricsDataFromQE).forEach((metricRow) => {
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (state.dataQE.value[metricRow]) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricRow]: {
                status: "success",
                message: "",
                result: metricsDataFromQE[metricRow],
              },
            };
          }
        });
      } else if (metricsDataFromQEStatus === "error") {
        Object.keys(state.selectedKpis.value).forEach((metricRow) => {
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (state.dataQE.value[metricRow]) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricRow]: {
                status: "error",
                message: config.hardCoded.uiErrorMessage,
                result: metricsDataFromQE[metricRow],
              },
            };
          }
        });
      } else if (metricsDataFromQEStatus === "loading") {
        Object.keys(state.selectedKpis.value).forEach((metricRow) => {
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (state.dataQE.value[metricRow]) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricRow]: {
                status: "loading",
                message: "",
                result: { value: 0, displayValue: "0" },
              },
            };
          }
        });
      }
      newState = {
        ...state,
        dataQE: {
          value: newDataFromQEVal,
          status: "valid",
          message: "",
          stepTag: "",
        },
      };
      break;

    case C.RELOAD_DS_CHART_DATA:
      newReloadEpochsValue = {
        ...state.reloadEpochs.value,
        [widgetItemId]: Date.now(),
      };

      newState = {
        ...state,
        reloadEpochs: {
          value: newReloadEpochsValue,
          status: "valid",
          message: "",
          stepTag: "",
        },
      };
      break;

    case C.RELOAD_DS_METRICS_DATA:
      newDataFromQEVal = { ...state.dataQE.value };
      Object.keys(metricsDataFromQE).forEach((metricRow) => {
        newDataFromQEVal = {
          ...newDataFromQEVal,
          [metricRow]: {
            ...state.dataQE.value[metricRow],
            status: "loading",
            message: "",
          },
        };
      });

      newState = {
        ...state,
        chartDataFromQE: {
          value: newDataFromQEVal,
          status: "valid",
          message: "",
          stepTag: "",
        },
      };
      break;

    case C.REMOVE_DS_WIDGET:
      // Update newChartDataFromQE
      var { [widgetItemId]: widgetItemIdRemoved, ...dataQEValueRem } =
        state.dataQE.value;

      // Update containerInfo
      var { [widgetItemId]: widgetItemIdRemoved, ...containerInfoValueRem } =
        state.containerInfo.value;

      // Update gridLayout
      var gridLayoutValueRem = state.gridLayout.value.filter(
        (row) => row.widgetItemId !== widgetItemId
      );

      // Update kpis
      var { [widgetItemId]: widgetItemIdRemoved, ...kpisValueRem } =
        state.selectedKpis.value;

      // Update selectedCharts
      var { [widgetItemId]: widgetItemIdRemoved, ...selectedChartsValueRem } =
        state.selectedCharts.value;

      // Update reloadEpochs
      var { [widgetItemId]: widgetItemIdRemoved, ...reloadEpochsValueRem } =
        state.reloadEpochs.value;

      newState = {
        ...state,
        dataQE: {
          ...state.dataQE,
          value: dataQEValueRem,
        },
        containerInfo: {
          ...state.containerInfo,
          value: containerInfoValueRem,
        },
        gridLayout: { ...state.gridLayout, value: gridLayoutValueRem },
        selectedKpis: { ...state.gridLayout, value: kpisValueRem },
        selectedCharts: {
          ...state.selectedCharts,
          value: selectedChartsValueRem,
        },
        reloadEpochs: {
          ...state.reloadEpochs,
          value: reloadEpochsValueRem,
        },
      };
      break;

    case C.UPDATE_STANDALONE_DS_FORM_CHART_OBJECT:
      // Update gridLayout
      var gridLayoutValueRem = state.gridLayout.value.map((row) =>
        row.widgetItemId !== widgetItemId
          ? row
          : {
              ...row,
              payload: chartObjectPayload,
              widgetTitle: chartObjectPayload.chartObject.metadata.title,
              chartType: chartObjectPayload.chartObject.metadata.chartType,
            }
      );

      // Update selectedCharts
      var selectedChartsValueRem = state.selectedCharts.value.map((row) =>
        row._id !== widgetItemId ? row : { ...chartObjectPayload }
      );

      newState = {
        ...state,
        gridLayout: { ...state.gridLayout, value: gridLayoutValueRem },
        selectedCharts: {
          ...state.selectedCharts,
          value: selectedChartsValueRem,
        },
      };
      break;

    case C.UPDATE_STANDALONE_DS_FORM_WIDGET:
      switch (widgetItemType) {
        case "chart":
          newState = {
            ...state,
            selectedCharts: {
              ...state.selectedCharts,
              value: {
                ...state.selectedCharts.value,
                [widgetItemId]: {
                  ...state.selectedCharts.value[widgetItemId],
                  [key]: value,
                },
              },
            },
          };
          break;
        case "counter":
          newState = {
            ...state,
            selectedKpis: {
              ...state.selectedKpis,
              value: {
                ...state.selectedKpis.value,
                [widgetItemId]: {
                  ...state.selectedKpis.value[widgetItemId],
                  [key]: value,
                },
              },
            },
          };
          break;
        default:
          newState = { ...state };
      }
      break;

    case C.REPLACE_STANDALONE_DS_FORM_WIDGET:
      switch (widgetItemType) {
        case "chart":
          newState = {
            ...state,
            selectedCharts: {
              ...state.selectedCharts,
              value: { ...state.selectedCharts.value, [widgetItemId]: value },
            },
          };
          break;
        case "kpi":
          newState = newState = {
            ...state,
            selectedKpis: {
              ...state.selectedKpis,
              value: { ...state.selectedKpis.value, [widgetItemId]: value },
            },
          };
          break;
        default:
          newState = { ...state };
      }
      break;

    case C.UPDATE_STANDALONE_DS_FORM_CHART_NAME:
      // Update gridLayout
      var gridLayoutValueRem = state.gridLayout.value.map((row) =>
        row.widgetItemId !== widgetItemId
          ? row
          : {
              ...row,
              payload: {
                ...row.payload,
                chartObject: {
                  ...row.payload.chartObject,
                  metadata: {
                    ...row.payload.chartObject.metadata,
                    title: widgetItemTitle,
                  },
                },
              },
              widgetTitle: widgetItemTitle,
            }
      );

      // Update selectedCharts
      var selectedChartsValueRem = state.selectedCharts.value.map((row) =>
        row._id !== widgetItemId
          ? row
          : {
              ...row,
              chartObject: {
                ...row.chartObject,
                metadata: {
                  ...row.chartObject.metadata,
                  title: widgetItemTitle,
                },
              },
            }
      );

      newState = {
        ...state,
        gridLayout: { ...state.gridLayout, value: gridLayoutValueRem },
        selectedCharts: {
          ...state.selectedCharts,
          value: selectedChartsValueRem,
        },
      };
      break;

    case C.ADD_STANDALONE_DS_FORM_WIDGET_CHART:
      var uniqueId = v4();

      var newChartContainerInfo = {
        id: uniqueId,
        size: "medium1",
        metadata: { i: uniqueId, x: 0, y: 0, w: 6, h: 4 }, //TODO Need to find the right coordinates
      };
      var newChartGridLayoutItem = {
        ...newChartContainerInfo.metadata,
        container: newChartContainerInfo,
        static: false,
        widgetItemId: uniqueId,
        chartType: value.chartType,
      };

      newState = {
        ...state,
        dataQE: {
          ...state.dataQE,
          value: { ...state.dataQE.value, [uniqueId]: defaultQeData },
        },
        selectedCharts: {
          ...state.selectedCharts,
          value: { ...state.selectedCharts.value, [uniqueId]: value },
        },
        containerInfo: {
          ...state.containerInfo,
          value: {
            ...state.containerInfo.value,
            [uniqueId]: newChartContainerInfo,
          },
        },
        gridLayout: {
          ...state.gridLayout,
          value: [...state.gridLayout.value, newChartGridLayoutItem],
        },
        reloadEpochs: {
          ...state.reloadEpochs,
          value: { ...state.reloadEpochs.value, [uniqueId]: Date.now() },
        },
      };
      break;

    case C.UPDATE_STANDALONE_DS_FORM_MULTIPLE_KPIS:
      // * Remove Kpis which are no longer in the selections
      var currentKpisList = Object.keys(state.selectedKpis.value);
      const kpisNoLongerPresent = currentKpisList.filter(
        (kpi) => !modifiedKpis.includes(kpi)
      );
      const newKpisAdded = modifiedKpis.filter(
        (kpi) => !currentKpisList.includes(kpi)
      );

      // Update newChartDataFromQE
      var dataQEValueRem = omit(state.dataQE.value, kpisNoLongerPresent);

      // Update containerInfo
      var containerInfoValueRem = omit(
        state.containerInfo.value,
        kpisNoLongerPresent
      );

      // Update gridLayout
      var gridLayoutValueRem = state.gridLayout.value.filter(
        (row) => !kpisNoLongerPresent.includes(row.widgetItemId)
      );

      // Update kpis
      var kpisValueRem = omit(state.selectedKpis.value, kpisNoLongerPresent);

      // Update reloadEpochs
      var reloadEpochsValueRem = omit(
        state.reloadEpochs.value,
        kpisNoLongerPresent
      );

      // * ADD Kpis which are in the selection
      for (const newKpi of newKpisAdded) {
        // Update newChartDataFromQE
        dataQEValueRem = {
          ...dataQEValueRem,
          [newKpi]: defaultKpiData,
        };

        // let newKpiContainerInfo = {
        //   id: newKpi,
        //   size: "small1",
        //   metadata: { i: newKpi, x: 0, y: 0, w: 2, h: 3 }, //TODO Need to find the right coordinates
        // };
        // let newKpiGridLayoutItem = {
        //   ...newKpiContainerInfo.metadata,
        //   container: newKpiContainerInfo,
        //   static: false,
        //   widgetItemId: newKpi,
        //   chartType: "counter",
        // };

        // // Update containerInfo
        // containerInfoValueRem = {
        //   ...containerInfoValueRem,
        //   [newKpi]: newKpiContainerInfo,
        // };

        // // Update gridLayout
        // gridLayoutValueRem = [...gridLayoutValueRem, newKpiGridLayoutItem];

        // console.log("newKpi", newKpi);
        // console.log("allData.plotlyMetrics", allData.plotlyMetrics);
        // Update kpis
        const kpiMetadata = getMeasureObjByOriginalID(
          newKpi,
          allData.plotlyMetrics
        );
        const newKpiValue = {
          id: newKpi,
          title: kpiMetadata._title,
          chartType: "counter",
          metricFilters: [],
          dimensionFilters: [],
          // timeFilters: state.timeFilters.value,
          timeFilters: defaultTimeFilters,
          metadata: kpiMetadata,
          metricsList: [kpiMetadata],
          dimensionsList: [],
          orderById: newKpi,
          orderBy: "desc",
        };
        kpisValueRem = {
          ...kpisValueRem,
          [newKpi]: newKpiValue,
        };

        // Update reloadEpochs
        reloadEpochsValueRem = {
          ...reloadEpochsValueRem,
          [newKpi]: Date.now(),
        };
      }

      newState = {
        ...state,
        dataQE: {
          ...state.dataQE,
          value: dataQEValueRem,
        },
        containerInfo: {
          ...state.containerInfo,
          value: containerInfoValueRem,
        },
        gridLayout: { ...state.gridLayout, value: gridLayoutValueRem },
        selectedKpis: { ...state.gridLayout, value: kpisValueRem },
        reloadEpochs: {
          ...state.reloadEpochs,
          value: reloadEpochsValueRem,
        },
      };
      newState = populateContainerInfoV2(newState);
      break;

    case C.UPDATE_STANDALONE_DS_FORM_MULTIPLE_CHARTS:
      // * Remove Charts which are no longer in the selections
      var currentChartsList = Object.keys(state.selectedCharts.value);
      const modifiedChartsIds = modifiedCharts.map((row) => row.id);
      const chartsNoLongerPresent = currentChartsList.filter(
        (chartId) => !modifiedChartsIds.includes(chartId)
      );
      const newChartsAdded = modifiedChartsIds.filter(
        (chartId) => !currentChartsList.includes(chartId)
      );
      console.log("currentChartsList", currentChartsList);
      console.log("modifiedChartsIds", modifiedChartsIds);
      console.log("newChartsAdded", newChartsAdded);

      // Update newChartDataFromQE
      var dataQEValueRem = omit(state.dataQE.value, chartsNoLongerPresent);

      // Update containerInfo
      var containerInfoValueRem = omit(
        state.containerInfo.value,
        chartsNoLongerPresent
      );

      // Update gridLayout
      var gridLayoutValueRem = state.gridLayout.value.filter(
        (row) => !chartsNoLongerPresent.includes(row.widgetItemId)
      );

      // Update kpis
      var selectedChartsValueRem = omit(
        state.selectedCharts.value,
        chartsNoLongerPresent
      );

      // Update reloadEpochs
      var reloadEpochsValueRem = omit(
        state.reloadEpochs.value,
        chartsNoLongerPresent
      );

      // * ADD Kpis which are in the selection
      for (const newChartId of newChartsAdded) {
        // Update newChartDataFromQE
        dataQEValueRem = {
          ...dataQEValueRem,
          [newChartId]: defaultQeData,
        };

        // Update kpis
        const newChartValue = modifiedCharts.find(
          (row) => row.id === newChartId
        );
        selectedChartsValueRem = {
          ...selectedChartsValueRem,
          [newChartId]: newChartValue,
        };

        // Update reloadEpochs
        reloadEpochsValueRem = {
          ...reloadEpochsValueRem,
          [newChartId]: Date.now(),
        };
      }

      newState = {
        ...state,
        dataQE: {
          ...state.dataQE,
          value: dataQEValueRem,
        },
        containerInfo: {
          ...state.containerInfo,
          value: containerInfoValueRem,
        },
        gridLayout: { ...state.gridLayout, value: gridLayoutValueRem },
        selectedCharts: { ...state.gridLayout, value: selectedChartsValueRem },
        reloadEpochs: {
          ...state.reloadEpochs,
          value: reloadEpochsValueRem,
        },
      };
      newState = populateContainerInfoV2(newState);
      break;

    default:
      console.error("UI ERROR");
      console.groupCollapsed("DETAILS");
      console.log("UNIDENTIFIED TYPE");
      console.log("REDUCER -> ", "Datastory.js");
      console.log("state -> ", state);
      console.log("payload -> ", payload);
      console.groupEnd();
      newState = { ...state };
  }
  const validatedState = addValidationToDsForm({ ...newState });
  return validatedState;
}

export default standaloneDs;
