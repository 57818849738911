//Import required libraies
import React, { useContext } from "react";
import { Box, makeStyles } from "@material-ui/core";

// Import Custom Component

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import action creators

// Import utils

// Import styles

// ! TODO: This component is incomplete make to dynamic
//Styling
const useStyles = makeStyles(() => ({
  root: {
    width: "350px",
    height: "40px",
    backgroundColor: "#fff",
    borderRadius: "2px",
    border: "1px solid #045ad9",
    //boxShadow: "0 3px 4px 0 rgba(0,0,0,.25)",
    //boxShadow: "0 0 6px #4285f4",
    display: "flex !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    // position: "absolute",
    marginTop: "1px",
    marginLeft: "1px",
    width: "35%",
    height: "33px",
    borderRadius: "3px",
    backgroundColor: "#fff",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  imgIcon: {
    // position: "absolute",
    marginLeft: "9px",
    width: "20px",
    height: "20px",
  },
  btnTexts: {
    // width: "100% !important",
    width: "39%",
    // float: "right",
    // margin: " 11px 11px 0 0",
    color: "#045ad9",
    fontSize: "12px",
    letterSpacing: "0.2px",
    fontFamily: "Fira Sans",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //paddingTop: "14px",
    // paddingRight: "10px",
  },
  textStyle: {
    fontWeight: "300 !important",
  },
}));

function GoogleSignInButton(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const {
    title = "",
    imgUrl = "",
    onClick = () => {},
    style = {
      padding: "6px 16px",
      fontSize: "12px !important",
    },
    disabled = false,
    iconProps = {
      className: "material-icons",
      iconName: "add",
    },
    iconFlag = false,
    iconStyle = { fontSize: "18px !important" },
    type = "button",
  } = props;

  //Defining styling variables
  let buttonHeight = "24px";
  if (style.buttonHeight) buttonHeight = style.buttonHeight;
  let wrapperHeight = "max-content";
  if (style.wrapperHeight) wrapperHeight = style.wrapperHeight;

  const classes = useStyles();

  return (
    <Box className={classes.root} onClick={onClick}>
      <Box className={classes.icon}>
        <img className={classes.imgIcon} src={imgUrl} />
      </Box>
      <Box className={classes.btnTexts}>
        <b className={classes.textStyle}>{title}</b>
      </Box>
    </Box>
  );
}

export default GoogleSignInButton;
