// Import required libraries
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";

// Import custom components
import Loader from "../Loader/Loader";
import SigviewSingleSelect from "../Common/SigviewSingleSelect";

// Import styles
import "./ViewSelection.scss";

// Import config
import { config } from "../../config/config";

// Import action creators
import {
  updateUserScreen,
  updateChangeFlag,
  replaceAllDimensionFilters,
} from "../../redux/actions";
import {
  getActiveViewObjFromViewsArr,
  getActiveOrgObject,
  getSigviewUserType,
} from "../../utils/utils";

// Import API functions
import { updateUserView } from "../../services/api";

function ViewSelection(props) {
  const { dispatch: ReduxDispatcher, user, changeFlag } = props;

  const [error, setError] = useState(false);

  //Making required variables
  const activeOrgObject = getActiveOrgObject(user.metadata.data);
  const activeView = getActiveViewObjFromViewsArr(
    activeOrgObject?.viewInfoList
  );
  //Get active view from active org object
  let allView = activeOrgObject?.viewInfoList || [];
  //sort allView alphabetically
  allView = orderBy(allView, ["name"], ["asc"]);
  //Modifying allViews for SigviewSingleSelect
  const allViewModified = allView.map((row) => ({
    ...row,
    id: row.name,
    name: row.name,
    disabled: false,
  }));

  const handleViewChange = (newValue) => {
    //Empty the filters
    ReduxDispatcher(replaceAllDimensionFilters({ newDimensionFilters: [] }));

    //Return to home (will be controlled by router later)
    ReduxDispatcher(updateUserScreen("activeTab", "home"));

    //Show loader on the entire page
    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));

    //Make fetch call using axios
    const fetchProps = {
      payload: {
        loginInfo: {
          providerID: "credentials",
          providerKey: user.reqMetadata.email,
        },
        currentActiveOrgViewReq: {
          organization: user.reqMetadata.organization,
          view: activeView.name,
        },
        newActiveOrgViewReq: {
          organization: user.reqMetadata.organization,
          view: newValue,
        },
        isEmbedded: false,
      },
    };
    const updateUserViewPromise = updateUserView(fetchProps);
    updateUserViewPromise
      .then(() => {
        //Change viewFlag to trigger useEffect to refetch all data
        ReduxDispatcher(updateChangeFlag("view", !changeFlag.view));
      })
      .catch((error) => setError(error.error));
  };

  return (
    <section className={`view-container`}>
      {error ? (
        <p>{error}</p>
      ) : activeView ? (
        <>
          <SigviewSingleSelect
            value={activeView.name}
            data={allViewModified}
            onChange={(newValue) => {
              handleViewChange(newValue);
            }}
            minWidth="10px"
            // margin={{ left: "5px", right: "5px" }}
            border="none"
            theme={user.theme}
            themeColors={user.themeColors}
          />
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
}

ViewSelection.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
});

export default connect(mapStateToProps)(ViewSelection);
