// Import required libraries
import React, { useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// Import custom components
import SigviewButton from "../Common/SigviewButton";

// Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Import styles
import "./DialogAlert.scss";

// Import config
import { config } from "../../config/config";

// Import action creators

// Import utils

function DialogAlert(props) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const { user } = props;

  //Styling
  const useStyles = makeStyles({
    root: {
      "&.MuiDialog-root": {
        zIndex: "9999999 !important",
        "& .MuiPaper-root": {
          backgroundColor: themeColors["popoverBgColor"],
        },
      },
    },
  });
  const classes = useStyles();

  //Defining required variables
  const actionBarClassname = `dialog-alert-actions-bar ${
    user.dialogInfo.message.subtitle.length ? "with-border" : ""
  }`;

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={user.dialogInfo.open}
      className={classes.root}
    >
      <div className="dialog-alert-container">
        <p className="dialog-alert-title">{t(user.dialogInfo.message.title)}</p>
        <p className="dialog-alert-subtitle">
          {t(user.dialogInfo.message.subtitle)}
        </p>
        <div className={actionBarClassname}>
          <SigviewButton
            variant="outlined"
            onClick={() => {
              user.dialogInfo.handleNo();
            }}
            title="No"
            style={{ width: "50px" }}
          />
          <SigviewButton
            variant="contained"
            onClick={() => {
              user.dialogInfo.handleYes();
            }}
            title="Yes"
            style={{ margin: { left: "10px" }, width: "50px" }}
          />
        </div>
      </div>
    </Dialog>
  );
}

DialogAlert.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DialogAlert);
