// ! IMPORTANT NOTES
// Caution:
// 1. This is a fast fix to solve website performance
// 2. This may not adhere to our kit guidelines

//Import required libraies
import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";

//Styling
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: (props) => props.minWidth || "120px",
    marginLeft: (props) => props.margin?.left || "0px",
    marginRight: (props) => props.margin?.right || "0px",
    marginTop: (props) => props.margin?.top || "0px",
  },
  multiSelect: {
    "& .MuiSelect-icon": {
      color: (props) =>
        props.disabled
          ? theme.palette.misc["secondaryColorLighter"]
          : theme.palette.misc["secondaryColor"],
      opacity: (props) => (props.disabled ? "0.5" : "1"),
    },
    color: theme.palette.misc["secondaryColor"],
    fontSize: (props) => props.fontSize || "10px",
    fontFamily: "inherit",
    minWidth: (props) => props.minWidth || "120px",
    maxWidth: (props) => props.maxWidth || "350px",
    height: (props) => props.height || "24px",
    borderRadius: "3px",
    backgroundColor: (props) => props.backgroundColor || "transparent",
    border: (props) =>
      props.border
        ? props.border
        : `1px solid ${
            props.error
              ? theme.palette.misc["failureColor"]
              : theme.palette.misc["secondaryColorLighter"]
          }`,
    "& .MuiSvgIcon-root": {
      color: (props) =>
        props.disabled
          ? theme.palette.misc["secondaryColorLighter"]
          : theme.palette.misc["secondaryColor"],
    },
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "6px",
      textTransform: (props) => props.textTransform || "initial",
      // paddingTop: "11px",
      lineHeight: 1.5, // TODO make it dynamic based on font-size
      // paddingRight: "5px",
      paddingBottom: "6px",
      color: (props) =>
        props.disabled
          ? theme.palette.misc["secondaryColorLighter"]
          : theme.palette.misc["secondaryColor"],
      fontSize: (props) => props.fontSize || "10px",
      textTransform: (props) => props.textTransform || "capitalize",
    },
  },
  singleMenuItem: {
    fontSize: (props) => props.fontSize || "10px",
    fontFamily: "inherit",
    padding: "3px 6px",
    margin: "0px 0px",
    width: "auto",
    textTransform: (props) => props.textTransform || "capitalize",
    color: theme.palette.misc["secondaryColor"],
    "&:hover": {
      backgroundColor: theme.palette.misc["secondaryColorLightest"],
    },
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: theme.palette.misc["secondaryColorLightest"],
      textTransform: (props) => props.textTransform || "capitalize",
    },
  },
  formHelperText: {
    fontSize: "9px",
    fontFamily: "inherit",
    minWidth: (props) => props.minWidth || "120px",
    maxWidth: "350px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: `${theme.palette.misc["failureColor"]} !important`,
  },
  popoverRoot: {
    zIndex: "9999999999 !important",
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.misc["popoverBgColor"],
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
        backgroundColor: `transparent !important`,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: `${theme.palette.misc["secondaryColorLighter"]} !important`,
        borderRadius: "5px",
      },
      "& .MuiList-root": {},
    },
  },
  selectIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px !important",
    color: theme.palette.misc["secondaryColor"],
    "&:hover": { color: theme.palette.misc["secondaryColor"] },
    cursor: "pointer",
  },
}));

const CustomExpandMore = (props) => {
  const { className } = props;
  let finalClassname = `${className} material-icons`;
  const customStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
  };
  const classes = useStyles(props);
  return (
    <span className={finalClassname} style={customStyle}>
      <span className={classes.selectIcon}>expand_more</span>
    </span>
  );
};

function SigviewSingleSelect(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    customId,
    customClassName,
    value,
    data,
    onChange,
    error,
    helperText,
    disabled = false, //if the entire dropdown is disabled or not; default is false
  } = props;

  //Required Functions
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const classes = useStyles(props);
  const MenuProps = {
    PopoverClasses: {
      root: classes.popoverRoot,
    },
    PaperProps: {
      style: {
        maxHeight: "280px",
        width: "auto",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
    // disablePortal: true, //to let dropdown menu render in the same zIndex as the parent
    // popperDisablePortal: true,
  };

  return (
    <FormControl
      className={classes.formControl}
      error={error}
      disabled={disabled}
    >
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        className={`${classes.multiSelect} ${customClassName}`}
        MenuProps={MenuProps}
        // IconComponent={() => (
        //   <span className={`${classes.dropdownIcon} material-icons`}>
        //     expand_more
        //   </span>
        // )}
        // {}
        IconComponent={CustomExpandMore}
      >
        {data.map((item) => (
          <MenuItem
            key={item.id}
            id={item.name}
            value={item.id}
            disabled={item.disabled}
            className={`${classes.singleMenuItem} ${customClassName}`}
          >
            <div
              id={item.name}
              style={{
                paddingRight: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
              }}
              className={customClassName}
            >
              {t(item.name)}
            </div>
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText className={classes.formHelperText} title={helperText}>
          {t(helperText)}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default SigviewSingleSelect;
