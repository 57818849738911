// Import Constants
import C from "../constants";

// Import validation function
import { validateResetPassword } from "../../utils/resetPasswordUtils";

const resetPassword = (state = {}, action = {}) => {
  const { type = "", payload = {} } = action;
  const { key = "", value = "" } = payload;

  let newState = {};
  switch (type) {
    case C.UPDATE_RESET_PASSWORD_FORM:
      switch (key) {
        case "email":
        case "newPassword":
        case "confirmPassword":
        case "tab":
        case "showResetPasswordError":
        case "resetPasswordResponseMessage":
        case "resetLoader":
          newState = {
            ...state,
            [key]: {
              ...state[key],
              value,
            },
          };
          break;
        default:
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log("UNIDENTIFIED KEY in UPDATE_CHANGED_PASSWORD");
          console.log("REDUCER -> ", "resetPassword reducer");
          console.log("state -> ", state);
          console.log("action -> ", action);
          console.groupEnd();
          newState = { ...state };
      }

      break;

    case C.SUBMIT_RESET_PASSWORD_INITIAL_ERROR:
      switch (key) {
        case "email":
        case "newPassword":
        case "confirmPassword":
        case "tab":
        case "showResetPasswordError":
        case "resetPasswordResponseMessage":
        case "resetLoader":
          newState = {
            ...state,
            [key]: { ...value },
          };
          break;
        default:
          console.error("UI ERROR");
          console.groupCollapsed("DETAILS");
          console.log(
            "UNIDENTIFIED KEY in SUBMIT_RESET_PASSWORD_INITIAL_ERROR"
          );
          console.log("REDUCER -> ", "resetPassword reducer");
          console.log("state -> ", state);
          console.log("action -> ", action);
          console.groupEnd();
          newState = { ...state };
      }

      break;
    default:
      console.error("UI ERROR");
      console.groupCollapsed("DETAILS");
      console.log("UNIDENTIFIED TYPE");
      console.log("REDUCER -> ", "resetPassword reducer");
      console.log("state -> ", state);
      console.log("action -> ", action);
      console.groupEnd();
      newState = { ...state };
      break;
  }

  return validateResetPassword({ ...newState });
};

export default resetPassword;
