// * Import required libraries
import React, { useContext } from "react";
import { connect } from "react-redux";
import LayoutTopSideBottomAdmin from "../../../layouts/LayoutTopSideBottomAdmin/LayoutTopSideBottomAdmin";
import { useHistory } from "react-router";

// * import lib component
import { Box } from "@material-ui/core";

// * Import custom components
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";

import Attributes from "./Attributes";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Import utils, config & static data
import { getBreadcrumbsData } from "../../../utils/utils";

// * Import redux utils
import { updateUserScreen } from "../../../redux/actions";

//  * Import APIs

function AttributesContainer(props) {
  // * Destructure props
  const { dispatch: ReduxDispatcher, user, allData = {} } = props;
  const { views: viewsData } = allData;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define static variables

  // * Define required states

  // * Define required side effects

  // * Define requried event handlers
  const handleActiveTab = (e, id) => {
    const activeTab = id;
    const value = { activeNav: "admin", activeTab };
    const action = updateUserScreen(null, value);
    ReduxDispatcher(action);
  };

  // * Define required static variables
  const activeTab = user.screen.activeTab;
  const breadcrumbsData = getBreadcrumbsData(user);
  const layoutTopSideBottomAdminProps = {
    activeTab,
    handleActiveTab,
    variant: "AdminAws",
  };
  const noAttributeMessageBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  };

  // * Define required JSX helpers
  const noAttributeMessage =
    "Reloading views data to reflect the updated changes! Please wait.";

  // new Code for handling page refreshing when viewsData=loading
  const getAllDataStatus = (allData = []) => {
    const areAllSuccess = allData.every((data) => data.status === "success");
    const isAnyError = allData.some((data) => data.status === "error");
    let allVendorDataStatus = "loading";
    if (areAllSuccess) allVendorDataStatus = "success";
    if (isAnyError) allVendorDataStatus = "error";
    return allVendorDataStatus; //loading, success, error
  };

  const finalStatus = getAllDataStatus([viewsData]);

  const history = useHistory();

  if (finalStatus === "loading") {
    history.push("/admin");
  }

  return (
    <>
      {viewsData.status === "loading" && (
        <LayoutTopSideBottomAdmin {...layoutTopSideBottomAdminProps}>
          <SigviewBreadcrumb data={breadcrumbsData} onClick={handleActiveTab} />
          <Box css={noAttributeMessageBox}>
            <div className="no-data-container">
              <div className="no-data-image"></div>
              <p className="no-data">{noAttributeMessage}</p>
            </div>
          </Box>
        </LayoutTopSideBottomAdmin>
      )}
      {viewsData.status === "success" && (
        <Attributes
          user={user}
          allData={allData}
          ReduxDispatcher={ReduxDispatcher}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(AttributesContainer);
