// * Import required libraries
import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";

// * Import required lib

// * Import custom components
import App from "./App";
import SignInForm from "./screens/SignIn/SignInForm";
import SignInOpenX from "./screens/SignIn/SignInOpenX";
import SignInOpenXLoginError from "./screens/SignIn/SignInOpenXLoginError";
import SignInOpenXErrorMessageRetry from "./screens/SignIn/SignInOpenXErrorMessageRetry";
import SignInOpenXErrorMessagePage from "./screens/SignIn/SignInOpenXErrorMessagePage";
import SignInSSO from "./screens/SignIn/SignInSSO";
import SignInSSOError from "./screens/SignIn/SignInSSOError";
import ResetPassword from "./screens/ResetPassword/ResetPasswordForm";
import PageNotFound from "./screens/PageNotFound/PageNotFound";
import AuthLoader from "./screens/AuthLoader";
import Logout from "./screens/Logout/Logout";
import SignInRedirectHandler from "./screens/SignInRedirectHandler/SignInRedirectHandler";

// * Import required contexts

// * Import hooks

// * Import utils
import { getSigviewUserType, getEmbedInfo ,getBaseUrlEndpoints} from "./utils/utils";
import { masterTrackGaPageView } from "../../src/js/services/ga";
import {
  redirectToSignInPage,
  isUrlPublic,
  removeAngularHashFromUrl,
} from "./utils/authUtils";
import { config } from "./config/config";
import { oAuthSignIn, sigviewSignInSso,getToken } from "./services/api/index";

// * Import action creators
import {
  updateAuthLoading,
  updateAuthError,
  updateAuthPublicUrl,
  updateAuthSuccess,
  logout,
  updateAuthLogin,
  updateSignInFormWholeKey,
  ssoLogout,
} from "./redux/actions";

function AppAuth(props = {}) {
  // * Destructure props
  const { user = {}, dispatch: ReduxDispatcher, authForm = {} } = props;
  // ! Hack - To Remove Please verify with Atmanand
  // !  This useState is used for the login with OIDC(google)
  // ! main purpose of this useState is to prevent the login Screen when login with google
  const [authMethod, setAuthMethod] = useState(null);

  // * Define contexts
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("openxUserData"))?.[0]?.userType ===
        "OAA" &&
      ["/", "/openxSignIn"].includes(location.pathname)
    ) {
      localStorage.clear();
    } else if (
      JSON.parse(localStorage.getItem("openxUserData"))?.[0]?.userType !==
        "OAA" &&
      location.hash.includes("sso")
    ) {
      localStorage.clear();
      // window.location.reload();
    }
  }, []);

  // * Define Fucntions
  const sigviewUserType = getSigviewUserType();

  // Update authForm

  // ! This useEffect is for login with google
  useEffect(() => {
    let runFlag =
      window.location.href.includes("state") && authForm.status === "initial";
    if (runFlag) {
      // 1 Update to loding state
      const payload = {
        status: "loading",
      };
      const action = updateAuthLoading(payload);
      ReduxDispatcher(action);
      setAuthMethod("google");

      // ! TODO: Do check the token direclty hardcode by array use the string matching algo
      const URL = window.location.href;
      const arr = URL.split("&");
      const tokenId = arr[1].split("=");
      const decodedToken = decodeURIComponent(tokenId[1]);

      const finalPayload = {
        idProvider: "google",
        domain: window.location.origin,
        token: decodedToken,
      };

      const updateoAuthSignInPromise = oAuthSignIn(finalPayload);
      updateoAuthSignInPromise
        .then((response) => response.json())
        .then((responseData) => {
          console.log("responseData", responseData);
          if (responseData.status.statusCode === "200") {
            localStorage.setItem("satellizer_token", responseData.result.data);
            const payload = {
              status: "success",
              xAuthToken: {
                "X-Auth-Token": responseData.result.data,
                instances: "",
              },
              isTokenPresent: true,
              isTokenValid: true,
            };

            let action = updateAuthLogin(payload);
            ReduxDispatcher(action);

            history.push(config.hardCoded.rootUrlOnLogin);
            // setAuthMethod(null);
          } else {
            const payload = {
              key: "signInStatus",
              value: {
                message: responseData.status.statusMessage,
                value: "error",
              },
            };
            // setAuthMethod(null);
            const action = updateSignInFormWholeKey(payload);
            ReduxDispatcher(action);

            payload = {
              status: "error",
              isTokenValid: false,
            };
            // setAuthMethod(null);
            action = updateAuthError(payload);
            ReduxDispatcher(action);
          }
        })
        .catch((err) => {
          console.error("err", err);
          const payload = {
            status: "error",
            isTokenValid: false,
          };
          // setAuthMethod(null);
          const action = updateAuthError(payload);
          ReduxDispatcher(action);
          // Redirect to login page
          redirectToSignInPage({ history, location });
        });
    }
    return () => {
      runFlag = false;
    };
  }, []);

  // ! This useEffect handles OAA users in openx

  useEffect(() => {
    const URL = window.location.href;
    const arr = URL.split("&");

    if (
      URL.includes("sso") &&
      URL.includes("openx") &&
      !URL.includes("loginErrorMsg")
    ) {
      const finalPayload = {
        email: decodeURIComponent(arr[0].split("=")[1]),
        clientId: arr[1].split("=")[1],
        cryptoKey: arr[4].split("=")[1],
        userType: arr[2].split("=")[1],
        token: arr[3].split("=")[1],
      };

      const payload = finalPayload;
      const headers = {
        Authorization: finalPayload.cryptoKey,
        clientid: finalPayload.clientId,
        useremail: finalPayload.email,
        userType: finalPayload.userType,
      };
      const fetchProps = {
        payload,
        headers,
      };

      const sigviewSignInSsoPromise = sigviewSignInSso(fetchProps);
      sigviewSignInSsoPromise
        .then((response) => {
          if (response.status === 401) throw new Error();
          return response.json();
        })
        .then((responseData) => {
          console.log("responseData1", responseData);
          if (responseData.statusCode === "200") {
            // * For OpenX users, we do:
            // 1. Set values to local storage
            // 2. Re-trigger authForm
            // 3. Redirect to home page

            // 1. Set to values to local storage
            const satellizer_token = responseData.token;
            const openxUserDataInfo = [
              {
                userType: "OAA",
              },
            ];
            localStorage.setItem(
              "openxUserData",
              JSON.stringify(openxUserDataInfo)
            );
            localStorage.setItem("satellizer_token", satellizer_token);
            localStorage.setItem("user_email", finalPayload.email);
            const payload = {
              status: "success",

              xAuthToken: {
                "X-Auth-Token": satellizer_token,
                instances: "",
              },
              isTokenPresent: true,
              isTokenValid: true,
            };
            const action = updateAuthLoading(payload);
            ReduxDispatcher(action);

            // // 2. Re-trigger authForm
            // setTimeout(() => {
            //   ReduxDispatcher(updateChangeFlag("authFormEpoch", Date.now()));
            // }, 0);

            // 3. Redirect to home page
            history.push(config.hardCoded.rootNonSigviewUrlOnLogin);
          } else if (responseData.statusCode === "401") {
            throw new Error();
          }
        })
        .catch((json) => {
          console.error("UI ERROR");
          console.groupCollapsed("DETAIL");
          console.log("Component ---> SignInOpenX.js");
          console.log("Function --> handleSignIn");
          console.log("json", json);
          console.groupEnd();

          // ! Currently, embeddedInfo.isEmbeddedUser is true only for non-openx embedded users (like kayzen)

          history.push("/openxLoginErrorPage");
        });
    }
  }, []);

  useEffect(() => {
    let runFlag = authForm.status === "initial";
    if (runFlag) {
      const isUrlPublicFlag = isUrlPublic(location);
      if (isUrlPublicFlag) {
        var pathname = removeAngularHashFromUrl(location);
        history.push(pathname);

        const payload = {
          status: "public",
        };
        const action = updateAuthPublicUrl(payload);
        ReduxDispatcher(action);
      } else {
        const sigviewUserType = getSigviewUserType();
        let authData = {};
        if (sigviewUserType === "sigview") {
          const { xAuthToken } = getEmbedInfo(user);
          authData = {
            "X-Auth-Token": xAuthToken,
            instances: "",
          };
        } else {
          const openxTokenLocalObject = JSON.parse(
            localStorage.getItem("openxUserData")
          );
          const openxToken = openxTokenLocalObject?.[0]?.token;
          const openxUserType = openxTokenLocalObject?.[0]?.userType;
          const instances = openxTokenLocalObject?.[0]?.instances;
          const xAuthToken = localStorage.getItem("satellizer_token");
          const openxEmail = localStorage.getItem("user_email");
          authData = {
            "openx-email": openxEmail,
            "openx-token": openxToken,
            "X-Auth-Token": xAuthToken,
            "openx-userType": openxUserType,
            instances: instances,
          };
        }

        let isTokenPresent = true;
        // If any of the values in authData is undefined, it means the some/all required tokens are not present in the local storage
        for (const [key, value] of Object.entries(authData)) {
          if (value === undefined || value === null) isTokenPresent = false;
        }
        const payload = {
          status: "loading",
          authData,
          isTokenPresent,
        };

        // !If user is alrady openxaudiance(OAA) type, we validate their token

        if (
          JSON.parse(localStorage.getItem("openxUserData"))?.[0]?.userType ===
            "OAA" &&
          localStorage.getItem("satellizer_token") &&
          localStorage.getItem("user_email")
        ) {
          //! Validates if satellizer_token is expired or not
          const headers = {
            "X-Auth-Token": localStorage.getItem("satellizer_token"),
            usertype: localStorage.getItem("openxUserData"),
            useremail: localStorage.getItem("user_email"),
          };

          const url = `${authForm.apiEndpoints.baseUrlOld}${config.apiEndpoints.get}`;
          var options = {
            headers,
          };
          fetch(url, options)
            .then((response) => {
              if (response.status === 401) {
                ReduxDispatcher(ssoLogout());
              } else {
                const payload = {
                  ...authForm,
                  status: "success",
                  isTokenPresent: true,
                  sigviewUserType: "nonSigview",
                };

                const action = updateAuthLoading(payload);
                ReduxDispatcher(action);
              }
            })
            .catch((err) => {
              ReduxDispatcher(ssoLogout());
            });
        } else {
          const action = updateAuthLoading(payload);
          ReduxDispatcher(action);
        }
      }
    }
    return () => {
      runFlag = false;
    };
  }, [authForm.status]);

  useEffect(() => {
    masterTrackGaPageView(location.pathname);
  }, [location.pathname]);

  // Update token validity
  useEffect(() => {
    // If the authMethod is null to prevent the authForm status to error
    // and to prevent the user to see the login screen When login with OIDC
    let runFlag = authForm.status === "loading" && authMethod === null;
    if (runFlag) {
      if (!authForm.isTokenPresent) {
        const payload = {
          status: "error",
          isTokenValid: false,
        };
        const action = updateAuthError(payload);
        ReduxDispatcher(action);
        // Redirect to login page
        redirectToSignInPage({ history, location });
      } else {
        // Check token validity
        // if token is invalid, redirect to login page
        // if token is valid, enter app
        var headers = {};
        if (authForm.sigviewUserType === "sigview") {
          headers = { "X-Auth-Token": authForm.authData["X-Auth-Token"] };
        } else {
          headers = {
            "X-Auth-Token": authForm.authData["X-Auth-Token"],
            // ! COMMENTED DUE TO "400 Request Header Or Cookie Too Large" THIS WARNING
            // token: authForm.authData["openx-token"],
            usertype: authForm.authData["openx-userType"],
            useremail: authForm.authData["openx-email"],
          };
        }
        const url = `${authForm.apiEndpoints.baseUrlOld}${config.apiEndpoints.get}`;
        var options = {
          headers,
        };
        fetch(url, options).then((response) => {
          if (response.status === 401) {
            // Logout user
            ReduxDispatcher(logout());

            const payload = {
              status: "error",
              isTokenValid: false,
            };
            const action = updateAuthError(payload);
            ReduxDispatcher(action);

            redirectToSignInPage({ history, location });
          } else {
            const payload1 = {
              status: "success",
              isTokenValid: true,
            };
            const action = updateAuthSuccess(payload1);
            ReduxDispatcher(action);
            var pathname = removeAngularHashFromUrl(location);
            history.push(pathname);
          }
        });
      }
    }
    return () => {
      runFlag = false;
    };
  }, [authForm.status]);

  useEffect(() => {
    const URL = window.location.href;
    const isApigeelogin = localStorage.getItem('Login Method');
    const arr = URL.split("&");
    let userType;
    if (URL.includes("sso") && URL.includes("openx") && !URL.includes("loginErrorMsg"))
    {
      userType = arr[2].split("=")[1]
    }
    if(userType !== "OAA" && isApigeelogin === "Apigee")
    {
      if (!localStorage.getItem("satellizer_token")) {
        getToken()
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            } else {
              return res;
            }
          })
          .then((response) => {
            if (response.status === 412) {
              history.push("/openxSignIn");
            } else {
              localStorage.setItem("isapigeeLogin",true);
              const satellizer_token = response.result.data.token;
              const user_email = response.result.data.email;
              const openxUserDataInfo = [
                {
                  token: "",
                  name: response.result.data.name,
                  instances: response.result.data.instance,
                  userType: response.result.data.userType,
                },
              ];
              localStorage.setItem(
                "openxUserData",
                JSON.stringify(openxUserDataInfo)
              );
              localStorage.setItem("satellizer_token", satellizer_token);
              localStorage.setItem("user_email", user_email);
              const payload = {
                status: "success",

                xAuthToken: {
                  "X-Auth-Token": satellizer_token,
                  instances: "",
                },
                isTokenPresent: true,
                isTokenValid: true,
              };
              const action = updateAuthLoading(payload);
              ReduxDispatcher(action);
              history.push(config.hardCoded.rootNonSigviewUrlOnLogin);
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
        const payload = {
          status: "success",
          xAuthToken: {
            "X-Auth-Token": "",
            instances: "",
          },
          isTokenPresent: true,
          isTokenValid: true,
        };
        const action = updateAuthLoading(payload);
        ReduxDispatcher(action);
      }
    }

  }, [user.reqMetadata]);

  console.log("authForm", authForm);

  return (
    <>
      {authForm.status === "initial" && <AuthLoader />}
      {authForm.status === "loading" && <AuthLoader />}
      {/* THESE URLS ARE PUBLIC AND CAN BE VIEWED BY ANYONE WITHOUT AUTHENTICATION */}
      {authForm.status === "public" && (
        <>
          <Switch>
            <Route path="/reset/token" component={ResetPassword} exact />
            <PageNotFound />
          </Switch>
        </>
      )}
      {authForm.status === "error" && (
        <>
          <Switch>
            {sigviewUserType !== "sigview" && (
              <Route path="/openxSignIn" component={SignInOpenX} exact />
            )}
            <Route path="/sign-in" component={SignInForm} exact />
            <Route path="/sso/v1/login" component={SignInSSO} exact />
            {/* redirecting to signin page for now, later we have to make a error page for sigview embedded users */}
            <Route path="/sso/loginErrorMsg" component={SignInForm} exact />
            <Route path="/openxSignIn" component={SignInOpenX} exact />
            <Route
              path="/openxLoginErrorPage"
              component={SignInOpenXLoginError}
              exact
            />
            <Route
              path="/sso/loginOpenxErrorMsg"
              component={SignInOpenXErrorMessagePage}
              exact
            />
            <Route
              path="/sso/loginOpenxErrorRetryMsg"
              component={SignInOpenXErrorMessageRetry}
              exact
            />
            <SignInRedirectHandler />
          </Switch>
        </>
      )}
      {authForm.status === "success" && <App />}
      {authForm.status === "logout" && (
        <Switch>
          <Logout path="/logout" exact />
          <Logout />
        </Switch>
      )}
      {authForm.status === "logoutOaa" && (
        <Switch>
          <SignInSSOError path="/sso/loginErrorMsg" />
          <SignInSSOError />
        </Switch>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
  allData: state.data,
  authForm: state.auth,
  signInFormStatus: state.signInFormStatus,
});

export default connect(mapStateToProps)(AppAuth);
