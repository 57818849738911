// * Import constants
import C from "../constants";

// * Import utils if required

export default function addMetricDrawer(
  state = {},
  action = { type: "", payload: { key: "", value: "" } }
) {
  let { type = "", payload = { value: "" } } = action;
  let { value = "" } = payload;

  let newState = {};
  switch (type) {
    case C.ADD_METRIC_CHART:
      var newArr = [...state["chartMetrics"], value];
      newState = {
        ...state,
        chartMetrics: newArr,
      };
      return newState;

    case C.ADD_METRIC_TO_TABLES:
      var newArr = [...state["tableMetrics"], value];
      newState = {
        ...state,
        tableMetrics: newArr,
      };
      return newState;

    case C.REMOVE_METRIC_CHART:
      var newArr = state["chartMetrics"];
      var filteredArr = [];
      // Logic to disable removal of last selected metric
      if (newArr.length > 1) {
        filteredArr = newArr.filter((el) => el !== value);
      } else {
        filteredArr = newArr;
      }

      newState = {
        ...state,
        chartMetrics: filteredArr,
      };
      return newState;

    case C.REMOVE_METRIC_FROM_TABLES:
      var newArr = state["tableMetrics"];
      var filteredArr = [];
      // Logic to disable removal of last selected metric
      if (newArr.length > 1) {
        filteredArr = newArr.filter((el) => el !== value);
      } else {
        filteredArr = newArr;
      }
      newState = {
        ...state,
        tableMetrics: filteredArr,
      };
      return newState;

    case C.REPLACE_METRIC_DRAWER_SELECTIONS:
      return value;

    default:
      console.error("UI ERROR occured in addMetricDrawer.js");
      console.groupCollapsed("Details Below");
      console.log("UNIDENTIFIED TYPE");
      console.log("REDUCER -> ", "addMetricDrawer.js");
      console.log("state -> ", state);
      console.log("payload -> ", payload);
      console.groupEnd();
      return state;
  }
}
