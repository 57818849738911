// * Import required libraies
import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import orderBy from "lodash.orderby";
import isEqual from "lodash.isequal";

// * Import 3rd party lib comp
import { Box } from "@material-ui/core";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";

// * Import Custom Component
// Layouts
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import LayoutAnalyzeDashboard from "../../../layouts/LayoutAnalyzeDashboard";
import LayoutMenuBar from "../../../layouts/LayoutMenuBar/LayoutMenuBar";
import LayoutMetricChartsMenu from "../../../layouts/LayoutMetricChartsMenu";
import LayoutDimTablesMenu from "../../../layouts/LayoutDimTablesMenu";
// Common Kit
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import SigviewButton from "../../../components/Common/SigviewButton";
import SigviewButtonSplit from "../../../components/Common/SigviewButtonSplit";
import SigviewTextFieldAsync from "../../../components/Common/SigviewTextFieldAsync";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SigviewIcon from "../../../components/Common/SigviewIcon";
import SigviewChartTypeSingleSelect from "../../../components/Common/SigviewChartTypeSingleSelect";
import SigviewSingleSelect from "../../../components/Common/SigviewSingleSelect";
import SigviewDataSort from "../../../components/Common/SigviewDataSort";
import SigviewSwitch from "../../../components/Common/SigviewSwitch";
import SigviewTooltip from "../../../components/Common/SigviewTooltip";
import TableGridView from "../../../components/Common/TableGridView";
// Sigview Specific
import DimensionFilters from "../../../components/DimensionFilters";
import TimeFilters from "../../../components/TimeFilters/TimeFilters";
// Analyze Specific
import {
  SigviewDialogClone,
  MetricChartsLegend,
  SigviewDialogRename,
  SigviewDialogCreateNew,
  AddAttributeCard,
} from "./AnalyzeDashboardHelpers";
import AllWorkbooksDrawer from "./AllWorkbooksDrawer";
import AnalyzeShareDialog from "./AnalyzeShareDialog";
import WsDimensionTableItem from "./WsDimensionTableItem";
import WsMetricChartItem from "./WsMetricChartItem";
import AddMetricDrawer from "../../../components/AddMetricDrawer/AddMetricDrawer";
import AddDimensionDrawer from "../../../components/AddDimensionDrawer";
import MSV from "../../MSV";

// * Import actions
import {
  updateUserScreen,
  updateDialogInfo,
  replaceAllDimensionFilters,
  replaceStandaloneWsForm,
  updateStandaloneWsForm,
  updateStandaloneWsFormMetricsChartsData,
  updateStandaloneWsFormMetricsData,
  updateStandaloneWsMultipleMetrics,
  updateStandaloneWsFormDataSort,
  updateStandaloneWsDimensionTableMetrics,
  updateStandaloneWsMultipleDimensionsFromDimensionDrawer,
  updateStandaloneWsMsvCancel,
  updateStandaloneWsMsvApplyFilters,
  updateGlobalFiltersDimensionFilters,
  updateGlobalFiltersTimeFilters,
  updateGlobalFiltersRollingDateType,
  updateMetricChartGranularity,
} from "../../../redux/actions";

// * Import data/utils
import { config } from "../../../config/config";
import {
  isWsFormSameAsOriginal,
  wrapperWs,
  makeMetricsDataConsistent,
  makeMetricsTotalDataConsistent,
  makeMetricsChartDataConsistent,
  getAddMetricDrawerInitialSelections,
  getUnionOfAllMetricsInDimensionTables,
  dispatchErrorForMetricChartsOnApiFailure,
  dispatchErrorForMetricChartsOnNoData,
  dispatchErrorForMetricsOnApiFailure,
  dispatchErrorForMetricsOnNoData,
} from "../../../utils/analyzeUtils";
import { makeNewDefaultWorksheet } from "../../../utils/analyzeServiceUtils";
import {
  validateChartName,
  wrapperOrgViewReq,
  wrapperChartObject,
} from "../../../utils/chartObjectUtils";
import dimensionFiltersReducer from "../../../redux/reducers/dimensionFilters";
import { masterTrackGaEvent } from "../../../services/ga";
import {
  unwrapperWs,
  plotlyDimensionsToDimensionDwawer,
  plotlyMetricsToDimensionDwawer,
  initialWsFormScreenValue,
  wrapperUpdateWorksheetStatus,
  isWorkspaceValidBasedOnUserAccess,
  makeAttributeMissingErrorMessage,
  isWorkspaceCorrupt,
  makeCorruptWorkspaceErrorMessage,
} from "../../../utils/analyzeUtils";
import {
  getDimensionObjByOriginalID,
  getMeasureObjByOriginalID,
} from "../../../utils/plotlyUtils";
import { initialActiveWorkspace } from "../../../redux/stateData";
import SIGVIEW_CONTANTS from "../../../constants/sigviewConstants";
import { getSigviewUserType } from "../../../utils/utils";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Import API functions
import {
  getWorksheetById,
  getData,
  updateWs,
  deleteWorksheet,
  checkWorksheetName,
  renameWorksheet,
  getBookmark,
  updateWorksheetStatus,
} from "../../../services/api";

function AnalyzeDashboard(props) {
  // * Destructure props
  const {
    dispatch: ReduxDispatcher = () => {},
    user = {},
    standaloneWs: wsForm,
    globalFilters: {},
    allData = {},
    workbookProps = {},
  } = props;
  const {
    workbooks = {},
    setReloadWorkbooks = () => {},
    setIsAnyworkbookInRedux = () => {},
    setReloadWorksheet = () => {},
    setPageStatus = () => {},
  } = workbookProps;

  // * Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const history = useHistory();

  // * Define required state
  // WS (Backend)
  const [ws, setWs] = useState(SIGVIEW_CONTANTS.initialState); // ws ---> Workspace
  const [wsReload, setWsReload] = useState(new Date().valueOf());
  const wsFormOriginal = useRef(wsForm);
  // WS Name (UI)
  const [wsName, setWsName] = useState({
    status: "success",
    value: user.screen.activeWorkspace.payload.worksheet.sheetName,
    originalValue: user.screen.activeWorkspace.payload.worksheet.sheetName,
    message: "",
  });
  // Dragablity State
  const [drag, setDrag] = useState(false);

  // * Define required variables
  const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarDashboard;
  const activeWorkspace = user.screen.activeWorkspace;
  const isSharedWs = activeWorkspace.wsCategory.toLowerCase() === "shared";
  const granularityIdMappingInverse =
    config.hardCoded.granularityIdMappingInverse;
  const dimensionFiltersSettings = {
    showTimeFiltersInGlobalFilters: true,
    isAdvancedFilterVisible: true,
    isExcludeAllowed: true,
    disabledItemsList: [], // ["D044"] --> This needs to be a array of ids
  };

  // * Define required side effects
  // Fetch ws data from API
  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();

    // * This useEffect is to handle shared and update workspace
    // TODO: create workspace pending (openx case)
    if (activeWorkspace.wsCategory === "shared") {
      const payload = {
        bookmarkId: activeWorkspace.payload.bookmarkId,
        isShare: "true",
      };
      const fetchProps = {
        payload: payload,
        cancelToken: source.token,
      };
      const getBookmarkPromise = getBookmark(fetchProps);
      getBookmarkPromise
        .then((responseData) => {
          let profile = responseData.result.data.profile;
          // ! Modifying result structure to match getWorksheetById so that the same unwrapper can be used
          let result = {
            sheetId: profile._id,
            sheetName: activeWorkspace.payload.worksheet.sheetName,
            isActive: false,
            worksheetObject: {
              ...profile,
              // ! HARD CODED TO match same structure as getWorksheetById
              // Removed this hardcoding
              globalSort: {
                ...profile.globalSort,
              },
              isTableExpanded: {
                ...profile.isTableExpanded,
              },
            },
          };

          // * CHECKS
          // 1. Is it a corrupt object?
          // 2. Are all the metrics and dimensions in accessible list?

          // * Validating workspace for corrupt structure
          const isWorkspaceCorruptProps = { backendWs: result };
          const isWorkspaceCorruptResponse = isWorkspaceCorrupt(
            isWorkspaceCorruptProps
          );

          if (isWorkspaceCorruptResponse.flag) {
            let message = makeCorruptWorkspaceErrorMessage(
              isWorkspaceCorruptResponse
            );
            let newData = {
              status: "corruptError",
              message,
              result: {},
            };
            setWs(newData);
          } else {
            // * Validating workspace for missing attributes (access change)
            const isWorkspaceValidBasedOnUserAccessProps = {
              backendWs: result,
              user,
              allData,
            };
            const isWorkspaceValidBasedOnUserAccessResponse =
              isWorkspaceValidBasedOnUserAccess(
                isWorkspaceValidBasedOnUserAccessProps
              );

            if (isWorkspaceValidBasedOnUserAccessResponse.status !== "valid") {
              let message = makeAttributeMissingErrorMessage(
                isWorkspaceValidBasedOnUserAccessResponse
              );
              let newData = {
                status: "accessError",
                message,
                result: {},
              };
              setWs(newData);
            } else {
              let newData = { status: "success", message: "", result };
              // * IMPORTANT
              // 1) First update the wsForm
              // 2) Update original ref
              // 3) Async update ws so that it happens at the last (call stack | callback queue)
              const newWsForm = unwrapperWs({
                backendWs: result,
                user,
                allData,
              });
              var payload = { value: newWsForm };
              var action = replaceStandaloneWsForm(payload);
              ReduxDispatcher(action);

              // 2) Update original ref
              wsFormOriginal.current = { ...newWsForm };
              // 3) Async update ws so that it happens at the last (call stack | callback queue)

              setWs(newData);
            }
          }
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            const message = json.error || config.messages.uiErrorMessage;
            let newData = {
              status: "error",
              message,
              result: {},
            };
            setWs(newData);

            console.groupCollapsed("UI ERROR");
            console.log("JSON", json);
            console.log("ERROR ->", message);
            console.groupEnd();
          }
        });
    } else if (activeWorkspace.crudType === "update") {
      const payload = {
        orgViewReq: wrapperOrgViewReq(user),
        email: user?.reqMetadata?.email,
        workbookId: activeWorkspace.payload.workbook._id,
        worksheetId: activeWorkspace.payload.worksheet.sheetId,
      };

      const fetchProps = {
        payload: payload,
        cancelToken: source.token,
      };
      const getWorksheetByIdPromise = getWorksheetById(fetchProps);
      getWorksheetByIdPromise
        .then((responseData) => {
          let result = responseData?.result?.data?.worksheetList[0];

          // * CHECKS
          // 1. Is it a corrupt object?
          // 2. Are all the metrics and dimensions in accessible list?

          // * Validating workspace for corrupt structure
          const isWorkspaceCorruptProps = { backendWs: result };
          const isWorkspaceCorruptResponse = isWorkspaceCorrupt(
            isWorkspaceCorruptProps
          );

          if (isWorkspaceCorruptResponse.flag) {
            let message = makeCorruptWorkspaceErrorMessage(
              isWorkspaceCorruptResponse
            );
            let newData = {
              status: "corruptError",
              message,
              result: {},
            };
            setWs(newData);
          } else {
            // * Validating workspace for missing attributes (access change)
            const isWorkspaceValidBasedOnUserAccessProps = {
              backendWs: result,
              user,
              allData,
            };
            const isWorkspaceValidBasedOnUserAccessResponse =
              isWorkspaceValidBasedOnUserAccess(
                isWorkspaceValidBasedOnUserAccessProps
              );

            if (isWorkspaceValidBasedOnUserAccessResponse.status !== "valid") {
              let message = makeAttributeMissingErrorMessage(
                isWorkspaceValidBasedOnUserAccessResponse
              );
              let newData = {
                status: "accessError",
                message,
                result: {},
              };
              setWs(newData);
            } else {
              let newData = { status: "success", message: "", result };
              // * IMPORTANT
              // 1) First update the wsForm
              // 2) Update original ref
              // 3) Async update ws so that it happens at the last (call stack | callback queue)
              const newWsForm = unwrapperWs({
                backendWs: result,
                user,
                allData,
              });
              var payload = { value: newWsForm };
              var action = replaceStandaloneWsForm(payload);
              ReduxDispatcher(action);
              var action_globalDimFilters = updateGlobalFiltersDimensionFilters(
                payload.value.dimensionFilters
              );
              ReduxDispatcher(action_globalDimFilters);
              var action_globalTimeFilters = updateGlobalFiltersTimeFilters(
                payload.value.timeFilters
              );
              ReduxDispatcher(action_globalTimeFilters);
              var action_rollingDateType = updateGlobalFiltersRollingDateType(
                payload.value.rollingDateType
              );
              ReduxDispatcher(action_rollingDateType);
              var action_metricChartGranularity = updateMetricChartGranularity(
                payload.value.metricChartGranularity
              );
              ReduxDispatcher(action_metricChartGranularity);

              // 2) Update original ref
              wsFormOriginal.current = { ...newWsForm };
              // 3) Async update ws so that it happens at the last (call stack | callback queue)

              setWs(newData);
            }
          }
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            const message = json.error || config.messages.uiErrorMessage;
            let newData = {
              status: "error",
              message,
              result: {},
            };
            setWs(newData);

            console.groupCollapsed("UI ERROR");
            console.log("JSON", json);
            console.log("ERROR ->", message);
            console.groupEnd();
          }
        });
    }
    return () => {
      //Cancel prev fetch
      if (source) source.cancel();
      // Revert to loading state
      setWs(SIGVIEW_CONTANTS.initialState);
    };
  }, [wsReload]);

  // Fetch metrics charts data from API (all at once to optimize the number of fetch calls)
  useEffect(() => {
    // Make fetch call using axios
    const source = axios.CancelToken.source();
    const runFlag =
      ws.status === "success" &&
      Object.keys(wsForm.selectedKpis.value).length > 0;
    if (runFlag) {
      const metricsIds = Object.keys(wsForm.selectedKpis.value);
      const metricsList = metricsIds.map((metricId) =>
        getMeasureObjByOriginalID(metricId, allData.plotlyMetrics)
      );

      try {
        const granularity = wsForm.metricChartGranularity.value;
        const granularityId = granularityIdMappingInverse[granularity];
        const granDimObject = getDimensionObjByOriginalID(
          granularityId,
          allData.plotlyDimensions
        );
        let selections = {
          chartType: "chart",
          dimensionFilters: wsForm.dimensionFilters.value || [],
          timeFilters: wsForm.timeFilters.value || {},
          metricFilters: [], // HARD CODED
          dimensionsList: [granDimObject],
          metricsList: metricsList,
          orderById: granDimObject._id,
          orderBy: "asc",
          orderByType: "abs",
          percentCalList: [],
          granularity: granularity,
        };
        var payload = wrapperChartObject({ selections, user });
        // ! Remove compareDate
        delete payload["chartObject"]["requestParam"]["compareDate"];
        //Make fetch call using axios
        const fetchProps = {
          payload,
          cancelToken: source.token,
        };
        const getDataPromise = getData(fetchProps);
        getDataPromise
          .then((responseData) => {
            // * Update metrics chart data
            const makeMetricsChartDataConsistentProps = {
              data: responseData?.result?.data,
              dateRange: {
                startDate: wsForm.timeFilters.value.selectedDatesQE.startDate,
                endDate: wsForm.timeFilters.value.selectedDatesQE.endDate,
              },
              allData,
              metricsList,
              granDimObject,
            };
            const { response, status } = makeMetricsChartDataConsistent(
              makeMetricsChartDataConsistentProps
            );
            // Update wsForm
            let payload = {
              metricsChartsDataFromQE: response,
              metricsChartsDataFromQEStatus: status,
            };
            let action = updateStandaloneWsFormMetricsChartsData(payload);
            ReduxDispatcher(action);
          })
          .catch((json) => {
            if (json.error !== config.hardCoded.queryCancelled) {
              console.error("API FAILED");
              console.groupCollapsed("DETAILS");
              console.log(json);
              console.groupEnd();

              const sigviewUserType = getSigviewUserType();
              const errorMessage =
                sigviewUserType === "sigview"
                  ? config.hardCoded.noDataAvailableMessageSigview
                  : config.hardCoded.noDataAvailableMessageNonSigview;

              const isNoDataAvailableError = json.error === errorMessage; // TODO : To be changed based on sigview user type (aws vs openx)
              if (isNoDataAvailableError) {
                // Update wsForm.dataQE to show no data
                const dispatchErrorForMetricChartsOnNoDataProps = {
                  metricsList,
                  wsForm,
                  updateStandaloneWsFormMetricsChartsData,
                  ReduxDispatcher: ReduxDispatcher,
                };
                dispatchErrorForMetricChartsOnNoData(
                  dispatchErrorForMetricChartsOnNoDataProps
                );
              } else {
                // Update wsForm.dataQE to show error
                const dispatchErrorForMetricChartsOnApiFailureProps = {
                  metricsList,
                  wsForm,
                  updateStandaloneWsFormMetricsChartsData,
                  ReduxDispatcher: ReduxDispatcher,
                };
                dispatchErrorForMetricChartsOnApiFailure(
                  dispatchErrorForMetricChartsOnApiFailureProps
                );
              }
            }
          });

        // * Make calls for compare dates
        const compareDatesList = wsForm.timeFilters.value.compareDates;
        if (wsForm.timeFilters.value.isComparisonOn) {
          for (const compareRow of compareDatesList) {
            const granularity = wsForm.metricChartGranularity.value;
            const granularityId = granularityIdMappingInverse[granularity];
            const granDimObject = getDimensionObjByOriginalID(
              granularityId,
              allData.plotlyDimensions
            );
            let selections = {
              chartType: "chart",
              dimensionFilters: wsForm.dimensionFilters.value || [],
              timeFilters: wsForm.timeFilters.value || {},
              metricFilters: [], // HARD CODED
              dimensionsList: [granDimObject],
              metricsList: metricsList,
              orderById: granDimObject._id,
              orderBy: "asc",
              orderByType: "abs",
              percentCalList: [],
              granularity: granularity,
            };
            var payloadTemp = wrapperChartObject({ selections, user });

            // Update dates based compare dates
            const startDate =
              compareRow.compareSelectedDatesQE.startDate.toString();
            const endDate =
              compareRow.compareSelectedDatesQE.endDate.toString();
            payloadTemp = {
              ...payloadTemp,
              chartObject: {
                ...payloadTemp.chartObject,
                requestParam: {
                  ...payloadTemp.chartObject.requestParam,
                  dateRange: { startDate, endDate },
                },
              },
            };

            //Make fetch call using axios
            const fetchProps = {
              payload: payloadTemp,
              cancelToken: source.token,
            };
            const getDataPromise = getData(fetchProps);
            getDataPromise
              .then((responseData) => {
                // * Update metrics chart data
                const makeMetricsChartDataConsistentProps = {
                  data: responseData?.result?.data,
                  dateRange: {
                    startDate:
                      wsForm.timeFilters.value.selectedDatesQE.startDate,
                    endDate: wsForm.timeFilters.value.selectedDatesQE.endDate,
                  },
                  metricsList,
                  granDimObject,
                  dateRange: { startDate: startDate, endDate: endDate },
                };
                const { response, status } = makeMetricsChartDataConsistent(
                  makeMetricsChartDataConsistentProps
                );
                // Update wsForm
                let payload = {
                  metricsChartsDataFromQE: response,
                  metricsChartsDataFromQEStatus: status,
                };
                let action = updateStandaloneWsFormMetricsChartsData(payload);
                ReduxDispatcher(action);
              })
              .catch((json) => {
                if (json.error !== config.hardCoded.queryCancelled) {
                  console.error("API FAILED");
                  console.groupCollapsed("DETAILS");
                  console.log(json);
                  console.groupEnd();

                  const isNoDataAvailableError =
                    json.error === config.hardCoded.noDataAvailableMessage; // TODO : To be changed based on sigview user type (aws vs openx)
                  if (isNoDataAvailableError) {
                    // Update wsForm.dataQE to show no data
                    const dispatchErrorForMetricChartsOnNoDataProps = {
                      metricsList,
                      wsForm,
                      updateStandaloneWsFormMetricsChartsData,
                      ReduxDispatcher: ReduxDispatcher,
                    };
                    dispatchErrorForMetricChartsOnNoData(
                      dispatchErrorForMetricChartsOnNoDataProps
                    );
                  } else {
                    // Update wsForm.dataQE to show error
                    const dispatchErrorForMetricChartsOnApiFailureProps = {
                      metricsList,
                      wsForm,
                      updateStandaloneWsFormMetricsChartsData,
                      ReduxDispatcher: ReduxDispatcher,
                    };
                    dispatchErrorForMetricChartsOnApiFailure(
                      dispatchErrorForMetricChartsOnApiFailureProps
                    );
                  }
                }
              });
          }
        }
      } catch (err) {
        console.error("UI ERROR");
        console.groupCollapsed("DETAILS");
        console.log(err);
        console.groupEnd();
        // Update wsForm.dataQE to show error
        const dispatchErrorForMetricChartsOnApiFailureProps = {
          metricsList,
          wsForm,
          updateStandaloneWsFormMetricsChartsData,
          ReduxDispatcher: ReduxDispatcher,
        };
        dispatchErrorForMetricChartsOnApiFailure(
          dispatchErrorForMetricChartsOnApiFailureProps
        );
      }
    }
    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
    };
  }, [
    ws,
    wsForm.reloadEpochs.value["allMetricCharts"],
    // ! Logic moved to standaloneWs using manipulateStateBasedOnTimeFiltersChange
    // wsForm.timeFilters.value,
    // wsForm.dimensionFilters.value,
    // wsForm.metricChartGranularity.value,
    // wsForm.reloadEpochs.value["metrics"],
    // wsForm.selectedKpis.value,
  ]);

  // Fetch metrics data from API (all at once to optimize the number of fetch calls)
  useEffect(() => {
    // Make fetch call using axios
    const source = axios.CancelToken.source();
    const runFlag =
      ws.status === "success" &&
      Object.keys(wsForm.selectedKpis.value).length > 0;
    const granularity = wsForm.metricChartGranularity.value;
    if (runFlag) {
      const metricsIds = Object.keys(wsForm.selectedKpis.value);
      const metricsList = metricsIds.map((metricId) =>
        getMeasureObjByOriginalID(metricId, allData.plotlyMetrics)
      );

      try {
        let selections = {
          chartType: "chart",
          dimensionFilters: wsForm.dimensionFilters.value || [],
          timeFilters: wsForm.timeFilters.value || {},
          metricFilters: [], // HARD CODED
          dimensionsList: [], // HARD CODED
          metricsList: metricsList,
          orderById: metricsList[0]._id,
          orderBy: "asc",
          orderByType: "abs",
          percentCalList: [],
          granularity: granularity,
        };
        const payload = wrapperChartObject({ selections, user });
        //Make fetch call using axios
        const fetchProps = {
          payload,
          cancelToken: source.token,
        };
        const getDataPromise = getData(fetchProps);
        getDataPromise
          .then((responseData) => {
            // * Update metrics chart data
            const makeMetricsDataConsistentProps = {
              data: responseData?.result?.data,
              allData,
              isComparisonOn: wsForm.timeFilters.value.isComparisonOn,
            };
            const { response, status } = makeMetricsDataConsistent(
              makeMetricsDataConsistentProps
            );
            // Update wsForm
            let payload = {
              metricsDataFromQE: response,
              metricsDataFromQEStatus: status,
            };
            let action = updateStandaloneWsFormMetricsData(payload);
            ReduxDispatcher(action);
          })
          .catch((json) => {
            if (json.error !== config.hardCoded.queryCancelled) {
              console.error("API FAILED");
              console.groupCollapsed("DETAILS");
              console.log(json);
              console.groupEnd();

              const isNoDataAvailableError =
                json.error === config.hardCoded.noDataAvailableMessage; // TODO : To be changed based on sigview user type (aws vs openx)
              if (isNoDataAvailableError) {
                // Update wsForm.dataQE to show error
                const dispatchErrorForMetricsOnNoDataProps = {
                  metricsList,
                  updateStandaloneWsFormMetricsData,
                  ReduxDispatcher: ReduxDispatcher,
                };
                dispatchErrorForMetricsOnNoData(
                  dispatchErrorForMetricsOnNoDataProps
                );
              } else {
                // Update wsForm.dataQE to show error
                const dispatchErrorForMetricsOnApiFailureProps = {
                  metricsList,
                  updateStandaloneWsFormMetricsData,
                  ReduxDispatcher: ReduxDispatcher,
                };
                dispatchErrorForMetricsOnApiFailure(
                  dispatchErrorForMetricsOnApiFailureProps
                );
              }
            }
          });
        // * Make calls for compare calender Total value
        const compareDatesList = wsForm.timeFilters.value.compareDates;
        if (wsForm.timeFilters.value.isComparisonOn) {
          const granularity = wsForm.metricChartGranularity.value;
          for (const compareRow of compareDatesList) {
            // const granularity = wsForm.metricChartGranularity.value;
            // const granularityId = granularityIdMappingInverse[granularity];
            // const granDimObject = getDimensionObjByOriginalID(
            //   granularityId,
            //   allData.plotlyDimensions
            // );
            let selections = {
              chartType: "chart",
              dimensionFilters: wsForm.dimensionFilters.value || [],
              timeFilters: wsForm.timeFilters.value || {},
              metricFilters: [], // HARD CODED
              dimensionsList: [],
              metricsList: metricsList,
              orderById: metricsList[0]._id,
              orderBy: "asc",
              orderByType: "abs",
              percentCalList: [],
              granularity: granularity,
            };
            var payloadTemp = wrapperChartObject({ selections, user });

            // Update dates based compare dates
            const startDate =
              compareRow.compareSelectedDatesQE.startDate.toString();
            const endDate =
              compareRow.compareSelectedDatesQE.endDate.toString();
            payloadTemp = {
              ...payloadTemp,
              chartObject: {
                ...payloadTemp.chartObject,
                requestParam: {
                  ...payloadTemp.chartObject.requestParam,
                  dateRange: { startDate, endDate },
                },
              },
            };
            delete payloadTemp.chartObject.requestParam.compareDate;

            //Make fetch call using axios
            const fetchProps = {
              payload: payloadTemp,
              cancelToken: source.token,
            };
            const getDataPromise = getData(fetchProps);
            getDataPromise
              .then((responseData) => {
                // * Update metrics chart data
                const makeMetricsDataConsistentProps = {
                  data: responseData?.result?.data,
                  allData,
                  comparisionTotalValue: true,
                };
                const { response, status } = makeMetricsTotalDataConsistent(
                  makeMetricsDataConsistentProps
                );
                // Update wsForm
                let payload = {
                  metricsDataFromQE: response,
                  metricsDataFromQEStatus: status,
                };
                let action = updateStandaloneWsFormMetricsData(payload);
                ReduxDispatcher(action);
              })
              .catch((json) => {
                if (json.error !== config.hardCoded.queryCancelled) {
                  console.error("API FAILED");
                  console.groupCollapsed("DETAILS");
                  console.log(json);
                  console.groupEnd();

                  const isNoDataAvailableError =
                    json.error === config.hardCoded.noDataAvailableMessage; // TODO : To be changed based on sigview user type (aws vs openx)
                  if (isNoDataAvailableError) {
                    // Update wsForm.dataQE to show error
                    const dispatchErrorForMetricsOnNoDataProps = {
                      metricsList,
                      updateStandaloneWsFormMetricsData,
                      ReduxDispatcher: ReduxDispatcher,
                    };
                    dispatchErrorForMetricsOnNoData(
                      dispatchErrorForMetricsOnNoDataProps
                    );
                  } else {
                    // Update wsForm.dataQE to show error
                    const dispatchErrorForMetricsOnApiFailureProps = {
                      metricsList,
                      updateStandaloneWsFormMetricsData,
                      ReduxDispatcher: ReduxDispatcher,
                    };
                    dispatchErrorForMetricsOnApiFailure(
                      dispatchErrorForMetricsOnApiFailureProps
                    );
                  }
                }
              });
          }
        }
      } catch (err) {
        console.error("UI ERROR");
        console.groupCollapsed("DETAILS");
        console.log(err);
        console.groupEnd();
        // Update wsForm.dataQE to show error
        const dispatchErrorForMetricsOnApiFailureProps = {
          metricsList,
          updateStandaloneWsFormMetricsData,
          ReduxDispatcher: ReduxDispatcher,
        };
        dispatchErrorForMetricsOnApiFailure(
          dispatchErrorForMetricsOnApiFailureProps
        );
      }
    }
    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
    };
  }, [
    ws,
    wsForm.reloadEpochs.value["allMetrics"],
    // ! Logic moved to standaloneWs using manipulateStateBasedOnTimeFiltersChange
    // wsForm.timeFilters.value,
    // wsForm.dimensionFilters.value,
    // wsForm.selectedKpis.value,
  ]);

  // Call updateWorksheetStatus on every change of activeWorksheet
  // So that there is ONLY 1 worksheet that remains active always in mongo
  useEffect(() => {
    // This to be done only when it's not a shared workspace
    const runFlag = !isSharedWs;
    if (runFlag) {
      const payload = wrapperUpdateWorksheetStatus({
        workbook: user.screen.activeWorkspace.payload?.workbook,
        worksheet: user.screen.activeWorkspace.payload?.worksheet,
      });
      const fetchProps = { payload };
      const updateWorksheetStatusPromise = updateWorksheetStatus(fetchProps);
      updateWorksheetStatusPromise
        .then((responseData) => {
          // Post success, reload the workbook data so that it has the correct active worksheet
          setReloadWorkbooks(Date.now());
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            console.groupCollapsed("UI ERROR");
            console.log("ERROR -> ", json.error);
            console.log("updateWorksheetStatus API failed");
            console.groupEnd();

            const message = "Updating worksheet status failed";
            handleSnackbarMessageShow(json.error || message);
          }
        });
    }
  }, [user.screen.activeWorkspace.payload.worksheet]);

  //Update wsForm.name in the state everytime wsName (parent state) changes
  //Do this only when wsName.originalValue changes because wsName.value is
  //real-time state and wsName.originalValue refers to the renamed state
  useEffect(() => {
    // Update state only when they are different
    if (wsForm.name.value !== wsName.originalValue) {
      handleWsFormChange("name", wsName.originalValue);
      // Update ref so that update button doesn't get enabled
      wsForm.current = {
        ...wsFormOriginal.current,
        name: {
          ...wsFormOriginal.current.name,
          value: wsName.originalValue,
        },
      };
    }
  }, [wsName.originalValue]);

  // Autosave API
  useEffect(() => {
    // TODO : Check why does this useEffect gets triggered even when there is no change in depedency array
    // 1. Is it because we reassign a new state object on validation just before returning from the reducer?
    const isWsFormSameAsOriginalFlag = isWsFormSameAsOriginal(
      wsForm,
      wsFormOriginal.current
    );
    const runFlag =
      user.screen.workspaceAutoSaveStatus &&
      !isWsFormSameAsOriginalFlag &&
      wsForm.autosaveApiStatus.value !== "loading"; // ! Added this to prevent triggering of updateWorkspace if last it's already in progress
    if (runFlag) {
      handleUpdate("autosave");
    }
  }, [
    wsForm.dataLimit.value,
    wsForm.dimTablesGridViewType.value,
    wsForm.dimensionFilters.value,
    wsForm.globalSort.value,
    wsForm.layout.value,
    wsForm.metricChartGranularity.value,
    wsForm.metricChartType.value,
    wsForm.rollingDateType.value,
    wsForm.selectedDimensions.value,
    wsForm.selectedKpis.value,
    wsForm.timeFilters.value.selectedDatesQE,
    wsForm.timeFilters.value.compareDates,
  ]);

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });

    document.addEventListener("mouseover", (e) => {
      if (
        typeof e?.target?.className === "string" &&
        e?.target?.className?.includes("dndClass") === true
      ) {
        setDrag(true);
      } else {
        setDrag(false);
      }
    });

    return () => {
      document.removeEventListener("contextmenu", null);
      document.removeEventListener("mouseover", null);
    };
  }, []);

  // * Define required event handlers
  const handleWsFormChange = (key, value) => {
    const payload = { key, value };
    const action = updateStandaloneWsForm(payload);
    ReduxDispatcher(action);
  };

  const handleSaveDataSort = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "TableSort",
      label: value.sortTypeValue,
    });

    const payload = { value };
    const action = updateStandaloneWsFormDataSort(payload);
    ReduxDispatcher(action);
  };

  const handleShowAddMetricDrawer = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "TableAddMetrics",
      label: "AddMetrics",
    });

    const newScreen = { ...initialWsFormScreenValue, metricDrawer: value };
    handleWsFormChange("screen", newScreen);
  };

  const handleShowAddDimensionDrawer = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "TableAddDimensions",
      label: "AddDimensions",
    });

    const newScreen = { ...initialWsFormScreenValue, dimensionDrawer: value };
    handleWsFormChange("screen", newScreen);
  };

  const handleShowWorkbooksDrawer = (value) => {
    const newScreen = { ...initialWsFormScreenValue, allWorkbooks: value };
    handleWsFormChange("screen", newScreen);
  };

  const handleAccessCorruptError = () => {
    // In case of shared workspace, we don't have any workbook in user.screen.activeWorkspace.payload.workbook
    // Hence, pushing to /analyze/dashboard
    if (isSharedWs) {
      history.push("/");
    } else {
      setPageStatus({ status: "loading", message: "" });
      const props = {
        ...workbookProps,
        user,
        allData,
        ReduxDispatcher,
        activeWorkbook: user.screen.activeWorkspace.payload.workbook,
      };
      makeNewDefaultWorksheet(props);
    }
  };

  const handleMetricDrawerSubmit = (event, selections = "") => {
    // * NEW IMPLEMENTATION
    // * WHERE ALL DIMENSION TABLES HAVE DIFFERENT METRICS
    // CHART METRICS
    const oldSelectedChartMetrics = Object.keys(wsForm.selectedKpis.value);
    const newSelectedChartMetrics = selections.chartMetrics;
    const areChartMetricsEqual = isEqual(
      oldSelectedChartMetrics,
      newSelectedChartMetrics
    );
    if (!areChartMetricsEqual) {
      let payload = {
        selections,
        plotlyMetrics: allData.plotlyMetrics,
      };
      let action = updateStandaloneWsMultipleMetrics(payload);
      ReduxDispatcher(action);
    }

    // DIMENSION METRICS
    const oldSelectedTableMetrics =
      getAddMetricDrawerInitialSelections(wsForm).tableMetrics;
    const newSelectedTableMetrics = selections["tableMetrics"];
    const tableMetricsEqual = isEqual(
      oldSelectedTableMetrics,
      newSelectedTableMetrics
    );
    if (!tableMetricsEqual) {
      let payload = {
        oldSelectedTableMetrics,
        newSelectedTableMetrics,
        plotlyMetrics: allData.plotlyMetrics,
      };
      let action = updateStandaloneWsDimensionTableMetrics(payload);
      ReduxDispatcher(action);
    }

    // ! OLD IMPLEMENTATION
    // ! WHERE ALL DIMENSION TABLES HAD COMMON METRICS
    // const oldSelectedTableMetrics = wsForm.selectedDimTableMetrics.value.map(
    //   (row) => row._id
    // );
    // const oldSelectedChartMetrics = [];

    // for (const key in wsForm.selectedKpis.value) {
    //   oldSelectedChartMetrics.push(key);
    // }
    // const newSelectedChartMetrics = selections.chartMetrics;
    // const newSelectedTableMetrics = selections.tableMetrics;
    // const areChartMetricsEqual = isEqual(
    //   oldSelectedChartMetrics,
    //   newSelectedChartMetrics
    // );
    // const areTableMetricsEqual = isEqual(
    //   oldSelectedTableMetrics,
    //   newSelectedTableMetrics
    // );

    // if (!areChartMetricsEqual) {
    //   let payload = {
    //     selections,
    //     plotlyMetrics: allData.plotlyMetrics,
    //   };
    //   let action = updateStandaloneWsMultipleMetrics(payload);
    //   ReduxDispatcher(action);
    // }

    // if (!areTableMetricsEqual) {
    //   let payload = {
    //     selections,
    //     plotlyMetrics: allData.plotlyMetrics,
    //   };
    //   let action = updateStandaloneWsMultipleMetricsTable(payload);
    //   ReduxDispatcher(action);
    // }
  };

  const handleDimensionDrawerSubmit = (selections = {}) => {
    // * NEW IMPLEMENTATION
    // SAMPLE SELECTIONS OBJECT
    // selections = [{ id: "D001", metrics: ["M001", "M002"] }];
    let payload = {
      selections,
      plotlyDimensions: allData.plotlyDimensions,
      plotlyMetrics: allData.plotlyMetrics,
    };
    let action =
      updateStandaloneWsMultipleDimensionsFromDimensionDrawer(payload);
    ReduxDispatcher(action);

    // ! OLD IMPLEMENTATION
    // const oldSelectedDimTableMetrics = wsForm.selectedDimTableMetrics.value.map(
    //   (row) => row._id
    // );
    // const newSelectedDimTableMetrics = selections.selectedMetric;
    // const areMetricsEqual = isEqual(
    //   oldSelectedDimTableMetrics,
    //   newSelectedDimTableMetrics
    // );
    // if (areMetricsEqual) {
    //   let payload = {
    //     selections,
    //     plotlyDimensions: allData.plotlyDimensions,
    //   };
    //   let action = updateStandaloneWsMultipleDimensions(payload);
    //   ReduxDispatcher(action);
    // } else {
    //   let payload = {
    //     selections,
    //     plotlyDimensions: allData.plotlyDimensions,
    //     plotlyMetrics: allData.plotlyMetrics,
    //   };
    //   let action = updateStandaloneWsMultipleDimensionsAndMetrics(payload);
    //   ReduxDispatcher(action);
    // }
  };

  const handleUpdate = (variant = "nonAutosave") => {
    // This if condition to hanlde from UI breaks because for shared workbook we have different structure under user.screen.activeWorkspace
    if (!isSharedWs) {
      const workbookId = user.screen.activeWorkspace.payload.workbook._id;
      const backendWs = wrapperWs({ user, allData, wsForm, workbookId });
      const fetchProps = {
        payload: { ...backendWs },
      };
      if (variant !== "autosave") {
        ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
        // Google Analytics Event - Master
        masterTrackGaEvent({
          category: "Workspace",
          action: "UpdateWorkspace",
          label: "Update",
        });
      }

      if (variant === "autosave")
        handleWsFormChange("autosaveApiStatus", "loading");

      const updateWsPromise = updateWs(fetchProps);
      updateWsPromise
        .then(() => {
          if (variant !== "autosave") {
            // Update snackbar
            const snackbarPayload = {
              ...user.screen.snackbar,
              open: true,
              message: "Worksheet updated successfully!",
            };
            var payload = {
              isDashboardLoading: false,
              snackbar: snackbarPayload,
            };
            var action = updateUserScreen(null, payload);
            ReduxDispatcher(action);
          } else {
            if (variant === "autosave")
              handleWsFormChange("autosaveApiStatus", "success");
          }

          // Update ref
          wsFormOriginal.current = wsForm;
        })
        .catch((json) => {
          console.groupCollapsed("API FAILED");
          console.log("Error JSON -> ", json);
          console.groupEnd();
          const message = json.error || "Updating worksheet failed!";
          // Update snackbar
          const snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: message,
          };
          var payload = {
            isDashboardLoading: false,
            snackbar: snackbarPayload,
          };
          var action = updateUserScreen(null, payload);
          ReduxDispatcher(action);
          handleWsFormChange("autosaveApiStatus", "success");
        });
    }
  };

  const handleSuccessCloneLaunch = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "CloneWorkspace",
      label: "CloneSuccess",
    });

    setReloadWorkbooks(Date.now());
  };

  const handleCloneDialog = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "CloneWorkspace",
      label: "Clone",
    });
    const initialName = `${config.hardCoded.cloneOfPreText} ${wsForm.name.value}`;
    var cloneProps = {
      workbook: user.screen.activeWorkspace.payload.workbook,
      worksheet: wsForm.actualPayload.value,
    };
    if (isSharedWs) {
      var activeWorkbook = workbooks.result.workbookList.find(
        (row) => row.isActive
      );
      if (!activeWorkbook) activeWorkbook = workbooks.result[0];
      cloneProps = {
        workbook: activeWorkbook,
        worksheet: wsForm.actualPayload.value,
      };
    }

    const payload = {
      open: true,
      children: (
        <SigviewDialogClone
          ReduxDispatcher={ReduxDispatcher}
          initialName={initialName}
          user={user}
          wsForm={wsForm}
          allData={allData}
          cloneProps={cloneProps}
          onCloneLaunch={handleWorkspaceOpen}
          onSuccess={handleSuccessCloneLaunch}
        />
      ),
      // children: null,
    };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleCreateNewDialog = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "CreateWorkspace",
      label: "Save",
    });

    const sigviewUserType = getSigviewUserType();
    if (sigviewUserType === "sigview") {
      var activeWorkbook = workbooks.result.workbookList.find(
        (row) => row.isActive
      );
      if (!activeWorkbook) {
        activeWorkbook = workbooks.result.workbookList[0];
      }
      const initialName = `Untitled Workspace - ${
        activeWorkbook.worksheetList.length + 1
      }`;
      const cloneProps = {
        workbook: activeWorkbook,
        worksheet: wsForm.actualPayload.value,
      };
      const payload = {
        open: true,
        children: (
          <SigviewDialogCreateNew
            ReduxDispatcher={ReduxDispatcher}
            initialName={initialName}
            user={user}
            wsForm={wsForm}
            allData={allData}
            cloneProps={cloneProps}
            onCloneLaunch={handleWorkspaceOpen}
            onSuccess={handleSuccessCloneLaunch}
          />
        ),
        // children: null,
      };
      const action = updateUserScreen("sigviewDialog", payload);
      ReduxDispatcher(action);
    } else {
      //! This logic must be seperate for GCP and AWS
      handleShowWorkbooksDrawer(false);
      history.push("/analyze/dashboard/create");
      const newValue = { ...user.screen.activeWorkspace, crudType: "create" };
      var action = updateUserScreen("activeWorkspace", newValue);
      ReduxDispatcher(action);
    }
  };

  const handleDeleteWorksheet = (...args) => {
    const [workbook, worksheet, variant = "mainPage"] = args;
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "DeleteWorkspace",
      label: "Delete",
    });

    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    const fetchProps = {
      payload: {
        email: user?.reqMetadata?.email,
        workbookId: workbook._id,
        worksheetId: worksheet.sheetId,
        orgViewReq: {
          organization: user?.reqMetadata?.organization,
          view: user?.reqMetadata?.view,
        },
      },
    };

    const deleteChartPromise = deleteWorksheet(fetchProps);
    deleteChartPromise
      .then((responseData) => {
        if (variant === "mainPage") {
          // ! THIS SHOULD BE DONE IN THE APPSERVER in the deleteWorksheet API call
          // 1. Delete the workspace in mongo
          // 2. Get the list of remaining workspaces
          // 3. Set the first one as active

          // ! HANDLING THIS IN THE UI RIGHT NOW
          // 1. Trigger updateWorksheetStatus to update active worksheet in mongo
          const remainingWorksheetsList = workbook.worksheetList.filter(
            (row) => row.sheetId !== worksheet.sheetId
          );
          const firstWorksheet = remainingWorksheetsList[0]; // to make the first one active
          const modifiedWorkbook = {
            ...workbook,
            worksheetList: remainingWorksheetsList,
          };
          const payload = wrapperUpdateWorksheetStatus({
            workbook: modifiedWorkbook,
            worksheet: firstWorksheet,
          });
          const fetchProps = { payload };
          const updateWorksheetStatusPromise =
            updateWorksheetStatus(fetchProps);
          updateWorksheetStatusPromise
            .then((responseData) => {
              // 1. Revert setIsAnyworkbookInRedux to unmound AnalyzeDashboard

              setIsAnyworkbookInRedux(false);

              // 2. Set new active workspace in redux

              // Set new active workspace in redux
              const activeWorkspacePayload = {
                workbook: modifiedWorkbook,
                worksheet: firstWorksheet,
              };
              const actionValue = {
                activeWorkspace: {
                  wsCategory: "saved",
                  crudType: "update",
                  payload: activeWorkspacePayload,
                },
                isDashboardLoading: false,
              };
              var action = updateUserScreen(null, actionValue);
              ReduxDispatcher(action);

              // 3. Reload Worksheet to trigger useEffect

              setReloadWorksheet(Date.now());

              // 3. Reload Worksheet to trigger useEffect

              setReloadWorkbooks(Date.now());
            })
            .catch((json) => {
              if (json.error !== config.hardCoded.queryCancelled) {
                console.error("UI ERROR - API FAILED");
                console.groupCollapsed("DETAILS");
                console.log("JSON -> ", json);
                console.log("ERROR -> ", json.error);
                console.log("updateWorksheetStatus API failed");
                console.groupEnd();

                // ! If the API fails, there old active workspace will be deleted and there will be no new one that will be active
                // ! Hence, such logics should be done in the App Server
                // setIsAnyworkbookInRedux to false so that the component unmounts
                setIsAnyworkbookInRedux(false);

                // Update User Screen to initial workspace category so that getAllWorkbooks is called
                const message = "Updating worksheet status failed";
                let snackbarPayload = {
                  ...user.screen.snackbar,
                  open: true,
                  message: json.error || message,
                };
                var actionPayload = {
                  snackbar: snackbarPayload,
                  activeWorkspace: initialActiveWorkspace, // This will also reload the workbook data so that it has the correct active worksheet (AnalyzeDashboard.js - useEffect)
                  isDashboardLoading: false,
                };
                var action = updateUserScreen(null, actionPayload);
                ReduxDispatcher(action);
              }
            });
        } else if (variant === "sidenav") {
          let snackbarPayload = {
            ...user.screen.snackbar,
            open: true,
            message: "Worksheet deleted successfully!",
          };
          var actionPayload = {
            snackbar: snackbarPayload,
            isDashboardLoading: false,
          };
          // Update User Screen
          var action = updateUserScreen(null, actionPayload);
          ReduxDispatcher(action);

          setReloadWorkbooks(Date.now());
        }
      })
      .catch((json) => {
        console.error("UI ERROR - API FAILED");
        console.groupCollapsed("DETAILS");
        console.log("Error JSON -> ", json);
        console.groupEnd();

        var snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Deleting workspace failed",
        };
        var actionPayload = {
          snackbar: snackbarPayload,
          isDashboardLoading: false,
        };
        // Update User Screen
        var action = updateUserScreen(null, actionPayload);
        ReduxDispatcher(action);
      });
  };

  const handleDeleteDialog = (...args) => {
    const message = {
      title: "Are you sure you want to delete?",
      subtitle: "This action cannot be undone",
    };
    const handleNo = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
        })
      );
      // trackCrudGA("DeleteNo");
    };
    const handleYes = () => {
      //Close the dialog box
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: false,
          message: { title: "", subtitle: "" },
        })
      );
      handleDeleteWorksheet.apply(null, args);
      // trackCrudGA("DeleteYes");
    };
    ReduxDispatcher(
      updateDialogInfo({
        ...user.dialogInfo,
        open: true,
        message,
        handleYes,
        handleNo,
      })
    );
  };

  const handleDimensionFiltersChange = (value) => {
    handleWsFormChange("dimensionFilters", value);
    const payload = { key: "dimensionFilters", value };
    const action = updateGlobalFiltersDimensionFilters(payload);
    ReduxDispatcher(action);
  };

  const handleTimeFiltersChange = (value) => {
    handleWsFormChange("timeFilters", value);
    const payload = { value };
    const action = updateGlobalFiltersTimeFilters(payload);
    ReduxDispatcher(action);
  };

  const handleChartNameChange = (event, value) => {
    setWsName((prevState) => ({ ...prevState, value }));
  };

  const handleSnackbarMessageShow = (message) => {
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: message,
    };
    const action = updateUserScreen("snackbar", snackbarPayload);
    ReduxDispatcher(action);
  };

  const handleNameChangeClickAway = () => {
    // Google Analytics Event - Master
    // masterTrackGaEvent({
    //   category: "DatastoryDashboard",
    //   action: "Rename",
    //   label: "DS",
    // });

    // Making required active variables
    //If entered wsName is invalid
    const { status, message } = validateChartName(wsName.value);
    if (status === "invalid") {
      setWsName((prevState) => ({
        ...prevState,
        status: "success",
        value: prevState.originalValue,
      }));
      handleSnackbarMessageShow(message);
    } else {
      // Do this only when the names are different otherwise
      // it will run for every click on the screen
      if (wsName.originalValue !== wsName.value) {
        // Make an API call only when the element type is update
        if (wsForm.type.value === "update") {
          setWsName((prevState) => ({ ...prevState, status: "loading" }));
          const fetchPayload = {
            clickedFromMenubar: true,
            workbookId: user.screen.activeWorkspace.payload.workbook._id,
            worksheetName: wsName.value,
          };
          const fetchProps = {
            payload: fetchPayload,
          };
          const checkWoksheetNamePromise = checkWorksheetName(fetchProps);
          checkWoksheetNamePromise
            .then((responseData) => {
              // Worksheet name exits
              if (!responseData.result.data.status) {
                const fetchPayload = {
                  emailId: user?.reqMetadata?.email,
                  workbookId: user.screen.activeWorkspace.payload.workbook._id,
                  worksheetId: wsForm.id.value,
                  clickedFromMenubar: true,
                  worksheetName: wsName.value,
                };
                const fetchProps = {
                  payload: fetchPayload,
                };
                const renameWorksheetPromise = renameWorksheet(fetchProps);
                renameWorksheetPromise
                  .then((responseData) => {
                    setWsName((prevState) => ({
                      ...prevState,
                      status: "success",
                      originalValue: prevState.value,
                    }));
                    handleSnackbarMessageShow("Name updated successfully!");
                    setReloadWorkbooks(Date.now());
                  })
                  .catch((json) => {
                    console.groupCollapsed("API FAILED");
                    console.log("Error JSON -> ", json);
                    console.groupEnd();
                    setWsName((prevState) => ({
                      ...prevState,
                      status: "success",
                      value: prevState.originalValue,
                    }));
                    handleSnackbarMessageShow(json.error || "Rename Failed");
                  });
              } else {
                setWsName((prevState) => ({
                  ...prevState,
                  status: "success",
                  value: prevState.originalValue,
                }));
                handleSnackbarMessageShow("Workspace name already exists!");
              }
            })
            .catch((json) => {
              console.groupCollapsed("API FAILED");
              console.log("Error JSON -> ", json);
              console.groupEnd();
              setWsName((prevState) => ({
                ...prevState,
                status: "success",
                value: prevState.originalValue,
              }));
              handleSnackbarMessageShow(json.error || "Rename Failed");
            });
        } else {
          // Don't make the API call if the element type is not edit
          setWsName((prevState) => ({
            ...prevState,
            status: "success",
            originalValue: prevState.value,
          }));
        }
      }
    }
  };

  const handleDimensionsLayoutChange = (...args) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "TableRearrange",
      label: "1",
    });

    const [sourceId, sourceIndex, targetIndex, targetId] = args;
    const items = wsForm.layout.value.dimensions;
    var dimensionsValue = swap(items, sourceIndex, targetIndex);
    dimensionsValue = dimensionsValue.filter((row) => row !== undefined);
    const value = {
      kpis: wsForm.layout.value.kpis,
      dimensions: dimensionsValue,
    };
    handleWsFormChange("layout", value);
  };

  const handleKpisLayoutChange = (...args) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "ChartRearrange",
      label: "1",
    });

    const [sourceId, sourceIndex, targetIndex, targetId] = args;
    const items = wsForm.layout.value.kpis;
    var kpisValue = swap(items, sourceIndex, targetIndex);
    kpisValue = kpisValue.filter((row) => row !== undefined);
    const value = {
      dimensions: wsForm.layout.value.dimensions,
      kpis: kpisValue,
    };
    handleWsFormChange("layout", value);
  };

  const handleAddFilters = () => {
    const handleApplyFilters = (payload, filterType) => {
      switch (filterType) {
        case "dimensions":
          var action = replaceAllDimensionFilters(payload);
          var newDimensionFilters = dimensionFiltersReducer(
            wsForm.dimensionFilters.value,
            action
          );
          handleDimensionFiltersChange(newDimensionFilters);
          break;
        // case "metrics":
        //   var action = replaceAllMetricFilters(payload);
        //   dispatchMetricFilters(action);
        //   break;
        case "time":
          handleTimeFiltersChange(payload);
          break;
      }
    };

    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Open Filters",
      label: "Sidenav",
    });
    const commonGlobalFilterProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: true,
      showMetricFilters: false,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      timeFilters: wsForm.timeFilters.value, //for initializing filters
      dimensionFilters: wsForm.dimensionFilters.value, //for initializing filters
      metricFilters: [], //for initializing filters
      settings: dimensionFiltersSettings,
      filtersDimData: allData.plotlyDimensions,
      filtersMetricsData: allData.plotlyMetrics,
      selections: {},
    };
    //Open Global Filters
    const newGlobalFiltersProps = {
      ...commonGlobalFilterProps,
      activeDimensionFilter: {},
      handleApplyFilters, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };

    const action = updateUserScreen("globalFilters", newGlobalFiltersProps);
    ReduxDispatcher(action);
  };

  const handleGlobalSortChange = (event, value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "TableGlobalSort",
      label: value ? "ON" : "OFF",
    });
    handleWsFormChange("globalSort", value);
  };

  // const handleSettingsChange = (newSettingsSelections) => {
  //   const allEntriesNewSettingsSelections = Object.entries(
  //     newSettingsSelections
  //   );
  //   for (const [key, value] of allEntriesNewSettingsSelections) {
  //     handleWsFormChange(key, value);
  //   }
  // };
  const handleShare = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Share",
      action: "ShareURLOpenInSidenav",
      label: "OpenShare",
    });

    const workbookId = user.screen.activeWorkspace.payload.workbook?._id;
    const backendWs = wrapperWs({ user, allData, wsForm, workbookId });
    const payload = {
      ...backendWs.worksheet.worksheetObject,
      orgViewReq: backendWs.orgViewReq,
    };
    const actionPayload = {
      open: true,
      children: (
        <AnalyzeShareDialog
          user={user}
          ReduxDispatcher={ReduxDispatcher}
          payload={payload}
        />
      ),
    };
    const action = updateUserScreen("sigviewDialog", actionPayload);
    ReduxDispatcher(action);
  };

  const handleWorkspaceOpen = (workbook, worksheet) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "OpenWorkspace",
      label: "Open",
    });

    // * ASYNC
    // 0. Redirect to /analyze/dashboard so that the shared URL is removed
    // 1. Close the drawer (Since AnalyzeDashboard will umount, it will anyways close; still putting it if in case logic changes later)
    // 2. Revert setIsAnyworkbookInRedux to unmound AnalyzeDashboard
    // 3. Set new active workspace in redux
    // 4. Reload Worksheet to trigger useEffect
    // 5. Trigger updateWorksheetStatus to update active worksheet in mongo

    // 0. Redirect to /analyze/dashboard so that the shared URL is removed
    history.push("/analyze/dashboard");

    // 1. Close the drawer (Since AnalyzeDashboard will umount, it will anyways close; still putting it if in case logic changes later)

    // Close the drawer
    handleShowWorkbooksDrawer(false);

    // 2. Revert setIsAnyworkbookInRedux to unmound AnalyzeDashboard

    setIsAnyworkbookInRedux(false);

    // 3. Set new active workspace in redux

    // Set new active workspace in redux
    const activeWorkspacePayload = {
      workbook,
      worksheet,
    };
    const actionValue = {
      activeWorkspace: {
        wsCategory: "saved",
        crudType: "update",
        payload: activeWorkspacePayload,
      },
    };
    var action = updateUserScreen(null, actionValue);
    ReduxDispatcher(action);

    // 4. Reload Worksheet to trigger useEffect

    setReloadWorksheet(Date.now());

    // 5. Trigger updateWorksheetStatus to update active worksheet in mongo
    const modWorksheet = { ...worksheet, isActive: true };
    const payload = wrapperUpdateWorksheetStatus({
      workbook,
      worksheet: modWorksheet,
    });
    const fetchProps = { payload };
    const updateWorksheetStatusPromise = updateWorksheetStatus(fetchProps);
    updateWorksheetStatusPromise
      .then((responseData) => {
        // Post success, reload the workbook data so that it has the correct active worksheet
        setReloadWorkbooks(Date.now());
      })
      .catch((json) => {
        if (json.error !== config.hardCoded.queryCancelled) {
          console.groupCollapsed("UI ERROR");
          console.log("ERROR -> ", json.error);
          console.log("updateWorksheetStatus API failed");
          console.groupEnd();

          const message = "Updating worksheet status failed";
          handleSnackbarMessageShow(json.error || message);
        }
      });
  };

  const handleRenameSuccessFromDrawer = (worksheet, newName) => {
    // Do this only if the name changed is for the active workspace
    if (wsForm.id.value === worksheet.sheetId) {
      setWsName((prevState) => ({
        ...prevState,
        status: "success",
        value: newName,
        originalValue: newName,
      }));
    }

    // Do this for all
    setReloadWorkbooks(Date.now());
  };

  const handleWorkspaceRenameFromWorkbookDrawer = (workbook, worksheet) => {
    masterTrackGaEvent({
      category: "EditWorkspace",
      action: "WorkspaceRename",
      label: "Rename",
    });

    const payload = {
      open: true,
      children: (
        <SigviewDialogRename
          user={user}
          ReduxDispatcher={ReduxDispatcher}
          initialName={worksheet.sheetName}
          renameProps={{ workbook, worksheet }}
          themeColors={themeColors}
          setReloadWorkbooks={setReloadWorkbooks}
          onSuccess={handleRenameSuccessFromDrawer} // Update wsName and wsOriginal
        />
      ),
    };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const onMsvCancel = (msvForm) => {
    const payload = { msvForm };
    const action = updateStandaloneWsMsvCancel(payload);
    ReduxDispatcher(action);
  };

  const onMsvApplyFilters = (msvForm) => {
    const payload = { msvForm };
    const action = updateStandaloneWsMsvApplyFilters(payload);
    ReduxDispatcher(action);
  };

  const handleWorkspaceOpenFromSidenav = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "OpenWorkspaceFromSidenav",
      label: "Open",
    });
    handleShowWorkbooksDrawer(true);
  };

  const handleRollingDatesChange = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "RollingDates",
      label: value === "yes" ? "ON" : "OFF",
    });
    handleWsFormChange("rollingDateType", value);
    const payload = ("rollingDateType", { value: value });
    const action = updateGlobalFiltersRollingDateType(payload);
    ReduxDispatcher(action);
  };

  const handleChartTypeChange = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "ChangeChart",
      label: value,
    });
    handleWsFormChange("metricChartType", value);
  };

  const handleChartGranChange = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "ChartGranularity",
      label: value,
    });
    handleWsFormChange("metricChartGranularity", value);
    const payload = ("metricChartGranularity", { value: value });
    const action = updateMetricChartGranularity(payload);
    ReduxDispatcher(action);
  };

  const handleDataLimitChange = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "TableRowCount",
      label: `${value}`,
    });
    handleWsFormChange("dataLimit", value);
  };

  const handleGridTypeChange = (value) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "TableView",
      label: `${value}`,
    });
    handleWsFormChange("dimTablesGridViewType", value);
  };

  // * Define required static variables
  // TODO : Try and move all these to utils and useMemo for performance optimization
  const isAnyDimFiltersApplied = wsForm.dimensionFilters.value.length > 0;
  const isAutosaveOn = user.screen.workspaceAutoSaveStatus;
  const autosaveApiStatus = wsForm.autosaveApiStatus.value;
  const autosaveStatusTitle =
    autosaveApiStatus === "loading" ? "Saving..." : "Saved to Cloud";
  const isCloneDisabled = ws.status !== "success";
  // Delete won't be available (will be disabled) if it's a shared workspace or it's the only remaining workspace
  const activeWorkbookId = user.screen.activeWorkspace?.payload?.workbook?._id;
  const workbookObjectInWorkbooks = workbooks?.result?.workbookList?.find(
    (row) => row._id === activeWorkbookId
  );
  const isDeleteAvailable =
    workbookObjectInWorkbooks?.worksheetList?.length > 1 || false;
  const isDeleteDisabled = ws.status !== "success" || !isDeleteAvailable;
  const isWsFormSameAsOriginalFlag = isWsFormSameAsOriginal(
    wsForm,
    wsFormOriginal.current
  );
  const isUpdateDisabled =
    ws.status !== "success" || isWsFormSameAsOriginalFlag;
  let menuBarSplitButtonOptions = [];

  if (isSharedWs)
    menuBarSplitButtonOptions = [
      {
        id: "addToSaved",
        label: "Clone",
        onClick: handleCloneDialog,
        disabled: isCloneDisabled,
      },
    ];
  if (!isSharedWs && !isAutosaveOn)
    menuBarSplitButtonOptions = [
      {
        id: "update",
        label: "Update",
        onClick: handleUpdate,
        disabled: isUpdateDisabled,
      },
      {
        id: "clone",
        label: "Clone",
        onClick: handleCloneDialog,
        disabled: false,
      },
      // ...(isDeleteAvailable
      //   ? [
      //       {
      //         id: "delete",
      //         label: "Delete",
      //         onClick: () =>
      //           handleDeleteDialog(
      //             user.screen.activeWorkspace.payload.workbook,
      //             wsForm.actualPayload.value,
      //             "mainPage"
      //           ),
      //         disabled: isDeleteDisabled,
      //       },
      //     ]
      //   : []),
      {
        id: "delete",
        label: "Delete",
        onClick: () =>
          handleDeleteDialog(
            user.screen.activeWorkspace.payload.workbook,
            wsForm.actualPayload.value,
            "mainPage"
          ),
        disabled: isDeleteDisabled,
        tooltipProps: {
          flag: true,
          title: "User Must Have At Least 1 Workspace",
        },
      },
    ];
  if (!isSharedWs && isAutosaveOn)
    menuBarSplitButtonOptions = [
      {
        id: "clone",
        label: "Clone",
        onClick: handleCloneDialog,
        disabled: false,
      },
      {
        id: "delete",
        label: "Delete",
        onClick: () =>
          handleDeleteDialog(
            user.screen.activeWorkspace.payload.workbook,
            wsForm.actualPayload.value,
            "mainPage"
          ),
        disabled: isDeleteDisabled,
      },
    ];
  const renameTooltip = "Type and press ENTER to rename";
  const isMultiCompareRowAllowed = user.uiFeatureList.multiCompareCalendar;
  // If multi compare row is not allowed, hard code to compareCalendarRowLimit to 1
  const compareCalendarRowLimit = isMultiCompareRowAllowed
    ? user.uiLimitsList.maxCompareCalendarInDashboard
    : 1;
  const timeFiltersSettings = {
    compareCalendarRowLimit: compareCalendarRowLimit, //it comes from /getUserAccessList
    isMultiCompareRowAllowed: isMultiCompareRowAllowed, //it comes from /getUserAccessList
    isComparisonAvailable: true, //hard coded; it should come from where this component is being called
    isChangeTypeDropdownAvailable: true,
    isRollingDatesAvailable: true,
  };
  const rollingDatesProps = {
    value: wsForm.rollingDateType.value,
    onChange: (value) => handleRollingDatesChange(value),
  };
  var chartTypeData = [
    { id: "line", name: "Line Chart", disabled: false },
    { id: "bar", name: "Bar Chart", disabled: false },
    { id: "area", name: "Area Chart", disabled: false },
  ];
  if (wsForm.timeFilters.value.isComparisonOn) {
    var chartTypeData = [
      { id: "multiline", name: "Multi line Chart", disabled: false },
      { id: "groupedbar", name: "Grouped bar Chart", disabled: false },
    ];
  }
  const textFieldCustomStyle = {
    wrapperWidth: "100%",
    wrapperBorder: "1px solid transparent",
    wrapperFontSize: "16px",
    wrapperfontWeight: "500",
    wrapperBackgroundColor: "transparent",
    wrapperMinWidth: "100px",
  };
  // const onTimeFiltersChange = handleTimeFiltersChange;
  const renameDisabled = isSharedWs;
  const layoutVal = wsForm.layout.value;
  // const wsSettingsInitialSelections = {
  //   dataLimit: wsForm.dataLimit.value,
  //   rollingDateType: wsForm.rollingDateType.value,
  // };
  // ! OLD IMPLEMENTATION
  // ! Common Metrics for all Dimension Tables
  // const dataForDataSort = {
  //   sortType: [
  //     { id: "_dimension", name: "Dimension Values", disabled: false },
  //     ...wsForm.selectedDimTableMetrics.value.map((row) => {
  //       return {
  //         id: row._id,
  //         name: row._title,
  //         disabled: false,
  //       };
  //     }),
  //   ],
  //   sortTypeId: [{ id: "id_only", name: "Value", disabled: false }],
  // };
  // * NEW IMPLEMENTATION
  // * Different Metrics for all Dimension Tables
  var sortTypeData = useMemo(
    () => getUnionOfAllMetricsInDimensionTables(wsForm),
    [wsForm.selectedDimensions.value]
  );
  sortTypeData = [
    { id: "_dimension", name: "Dimension Values", disabled: false },
    ...sortTypeData,
  ];
  const dataForDataSort = {
    sortType: sortTypeData,
    sortTypeId: [{ id: "id_only", name: "Value", disabled: false }],
  };
  const initialSelectionsDataSort = {
    sortType: { id: wsForm.orderById.value },
    sortTypeId: { id: wsForm.orderByType.value },
    sortTypeValue: wsForm.orderBy.value,
  };
  const isGlobalSortOn = wsForm.globalSort.value;
  const gridTypeData = [
    { id: "single", name: "Single", disabled: false },
    { id: "double", name: "Double", disabled: false },
  ];
  const isShareInFeatureList = user.uiFeatureList.workspaceShare;
  const isShareVisible = user.embeddedInfo.isEmbeddedUser
    ? false
    : isShareInFeatureList;
  const additionalSidenavItems = [
    {
      id: "allWorkbooks",
      name: "Workspaces",
      tooltip: "Open Workspaces",
      elementId: "allWorkbooksInSidenav",
      iconId: "custom-workspaces-icon",
      customClass: "sidenav-workspaces",
      onClick: (user) => handleWorkspaceOpenFromSidenav(),
      isVisible: true,
    },
    {
      id: "filters",
      name: "Filters",
      tooltip: "Open filters",
      elementId: "filtersInSidenav",
      iconId: "custom-filter-icon",
      customClass: "sidenav-filters",
      onClick: (user) => handleAddFilters(),
      isVisible: true,
    },
    {
      id: "metricCharts",
      name: "Metrics",
      tooltip: "Add Metric Charts",
      elementId: "metricChartsInSidenav",
      iconId: "custom-metricCharts-icon",
      customClass: "sidenav-metrics",
      onClick: (user) => handleShowAddMetricDrawer(true),
      isVisible: true,
    },
    {
      id: "dimensionTable",
      name: "Dimensions",
      tooltip: "Add Dimension Tables",
      elementId: "dimensionTablesInSidenav",
      iconId: "custom-dimensionTables-icon",
      customClass: "sidenav-dimensions",
      onClick: (user) => handleShowAddDimensionDrawer(true),
      isVisible: true,
    },
    {
      id: "wsShare",
      name: "Share",
      tooltip: "Share",
      elementId: "wsShareInSidenav",
      customClass: "sidenav-share",
      iconId: "custom-share-icon",
      onClick: (user) => {
        handleShare();
      },
      isVisible: isShareVisible,
    },
  ];
  const sidenavItemsOrderInfo = {
    allWorkbooks: 1,
    filters: 2,
    metricCharts: 3,
    dimensionTable: 4,
    reports: 5,
    alerts: 6,
    wsShare: 7,
  };
  const tabName='analyze';
  const sidenavProps = { additionalSidenavItems, sidenavItemsOrderInfo, tabName };
  const isMsvOpen = wsForm.msv.value.isOpen;
  const boxesPerRowDimTables =
    wsForm.dimTablesGridViewType.value === "single" ? 1 : 2;
  const addMetricDrawerInitialSelections =
    getAddMetricDrawerInitialSelections(wsForm);
  const addDimensionDrawerData = {
    dimData: plotlyDimensionsToDimensionDwawer(allData.plotlyDimensions),
    metricData: plotlyMetricsToDimensionDwawer(allData.plotlyMetrics),
  };
  const dimensionTableOrder = wsForm.layout.value.dimensions.reduce(
    (finalObj, currRow, index) => ({ ...finalObj, [currRow.id]: index }),
    {}
  );
  const addDimensionDrawerInitialSelections = orderBy(
    Object.values(wsForm.selectedDimensions.value),
    (item) => dimensionTableOrder[item.id],
    ["asc"]
  );
  const layoutDimensionsMemoized = useMemo(
    () => wsForm.layout.value.dimensions,
    [wsForm.layout.value.dimensions]
  );
  const layoutKpisMemoized = useMemo(
    () => wsForm.layout.value.kpis,
    [wsForm.layout.value.kpis]
  );
  const showLegend =
    wsForm.timeFilters.value.isComparisonOn &&
    wsForm.timeFilters.value.compareDates.length === 1;
  const dataLimitData = config.hardCoded.defaultDataLimitData; // Can be configured from backend
  const allWorkbooksEventHandlers = {
    handleWorkspaceOpen: handleWorkspaceOpen,
    handleWorkspaceRename: handleWorkspaceRenameFromWorkbookDrawer,
    handleWorkspaceDelete: handleDeleteDialog,
    handleCreateNew: handleCreateNewDialog,
  };
  const isChartTypeAvailable = user.uiFeatureList.chartType;

  // * DEBUBGGER
  console.groupCollapsed("AnalyzeDashboard.js");
  console.log("wsName", wsName);
  console.log("ws", ws);
  console.groupEnd();

  //* hide add new dimension/metric card from dashboard when all dimensions are selected */
  const mapSelectedDimenstions = wsForm.selectedDimensions.value;
  const selectedDimensionArray = Object.values(mapSelectedDimenstions);

  //* hide add new dimension/metric card from dashboard when all dimensions are selected */
  const mapSelectedMetrics = wsForm.selectedKpis.value;
  const selectedMetricsArray = Object.values(mapSelectedMetrics);

  return (
    <>
      {!isMsvOpen && (
        <LayoutTopSideBottom sidenavProps={sidenavProps}>
          {/* <SigviewBreadcrumb
            data={breadcrumbData}
            onClick={handleBreadcrumbChange}
          /> */}
          <AllWorkbooksDrawer
            workbooks={workbooks}
            setReloadWorkbooks={setReloadWorkbooks}
            onCloseHandler={handleShowWorkbooksDrawer}
            isOpen={wsForm.screen.value.allWorkbooks}
            eventHandlers={allWorkbooksEventHandlers}
            activeWorkspace={activeWorkspace}
          />
          {ws.status === "error" && (
            <ErrorHandler
              message={ws.message}
              reloadFlag={true}
              onReload={() => {
                setWsReload(Date.now().valueOf());
              }}
            />
          )}
          {ws.status === "accessError" && (
            <ErrorHandler
              message={ws.message}
              reloadFlag={true}
              onReload={handleAccessCorruptError}
              reloadButtonProps={{
                flag: true,
                // variant: "contained",
                title: "Load Default Profile",
              }}
            />
          )}
          {ws.status === "corruptError" && (
            <ErrorHandler
              message={ws.message}
              reloadFlag={true}
              onReload={handleAccessCorruptError}
              reloadButtonProps={{
                flag: true,
                // variant: "contained",
                title: "Load Default Profile",
              }}
            />
          )}
          {ws.status === "loading" && <Loader />}
          {ws.status === "success" && (
            <>
              <LayoutAnalyzeDashboard>
                {/* Menu Bar */}
                <LayoutMenuBar>
                  <SigviewTextFieldAsync
                    placeholder="Please enter a valid worksheet name"
                    value={wsName.value}
                    onChange={handleChartNameChange}
                    status={wsName.status}
                    onClickAway={handleNameChangeClickAway}
                    customStyle={textFieldCustomStyle}
                    tooltipTitle={renameTooltip}
                    readOnly={renameDisabled}
                    inputClassName = "rename-workspace-GA"
                  />
                  <TimeFilters
                    user={user}
                    allData={allData}
                    initialTimeFilters={wsForm.timeFilters.value}
                    onChange={handleTimeFiltersChange}
                    googleAnalytics={{
                      category: "DatastoryDashboardPage",
                    }}
                    calendarDaysLimits={calendarDaysLimits}
                    settings={timeFiltersSettings}
                    rollingDatesProps={rollingDatesProps}
                  />
                  <>
                    {isAutosaveOn && (
                      <SigviewTooltip title={autosaveStatusTitle}>
                        <Box>
                          {autosaveApiStatus === "loading" && (
                            <Box className="sigview-rotate">
                              <SigviewIcon
                                className="material-icons-outlined"
                                iconName="sync"
                                style={{
                                  fontSize: "22px !important",
                                  padding: "0px 0px 0px 0px",
                                  color: themeColors["secondaryColorLight"],
                                  hoverColor: themeColors["primaryColor"],
                                  cursor: "initial",
                                }}
                              />
                            </Box>
                          )}
                          {autosaveApiStatus === "success" && (
                            <SigviewIcon
                              className="material-icons-outlined"
                              iconName="cloud_done"
                              style={{
                                fontSize: "22px !important",
                                padding: "0px px 0px 0px",
                                color: themeColors["primaryColor"],
                                hoverColor: themeColors["primaryColor"],
                                cursor: "initial",
                              }}
                            />
                          )}
                        </Box>
                      </SigviewTooltip>
                    )}

                    {/* <WsSettingsIcon
                      initialSelections={wsSettingsInitialSelections}
                      onChange={handleSettingsChange}
                    /> */}
                    {menuBarSplitButtonOptions.length > 1 && (
                      <SigviewButtonSplit
                        options={menuBarSplitButtonOptions}
                        customStyle={{ margin: "0px 0px 0px 10px" }}
                        customClassName="Workspace-GA"
                      />
                    )}
                    {menuBarSplitButtonOptions.length === 1 && (
                      <SigviewButton
                        id={menuBarSplitButtonOptions[0]["id"]}
                        title={menuBarSplitButtonOptions[0]["label"]}
                        disabled={menuBarSplitButtonOptions[0]["disabled"]}
                        onClick={menuBarSplitButtonOptions[0]["onClick"]}
                        customStyle={{ margin: "0px 0px 0px 10px" }}
                        customClassName="Workspace-GA"
                      />
                    )}
                  </>
                </LayoutMenuBar>

                {/* Dimension Filters */}
                {isAnyDimFiltersApplied ? (
                  <DimensionFilters
                    initialDimensionFilters={wsForm.dimensionFilters.value}
                    initialTimeFilters={wsForm.timeFilters.value}
                    onTimeFiltersChange={handleTimeFiltersChange}
                    onDimensionFiltersChange={handleDimensionFiltersChange}
                    settings={dimensionFiltersSettings}
                  />
                ) : (
                  <></> // ADDING THIS BECAUSE LAYOUT NEEDS SOMETHING IN THAT INDEX
                )}

                {/* METRIC CHARTS MENU */}
                <LayoutMetricChartsMenu showTitle={false}>
                  {/* CHART TYPE */}
                  {/* WITH TOOLTIP - THIS CREATES A BUG */}
                  {/* <Box>
                    <SigviewTooltip title="Chart Type">
                      <Box>
                        <SigviewChartTypeSingleSelect
                          data={chartTypeData}
                          value={wsForm.metricChartType.value}
                          onChange={(value) =>
                            handleWsFormChange("metricChartType", value)
                          }
                        />
                      </Box>
                    </SigviewTooltip>
                  </Box> */}

                  {/* WITHOUT TOOLTIP - IF TITLE IS PRESENT */}
                  <Box>
                    {isChartTypeAvailable && (
                      <SigviewChartTypeSingleSelect
                        data={chartTypeData}
                        value={wsForm.metricChartType.value}
                        onChange={handleChartTypeChange}
                        style={{ border: "none", fontSize: "11px" }}
                      />
                    )}
                  </Box>

                  {/* COMPARE LEGEND */}
                  {showLegend ? <MetricChartsLegend /> : <></>}

                  {/* WITH TOOLTIP - THIS CREATES A BUG */}
                  {/* GRANULARITY DROPDOWN */}
                  {/* <Box>
                    <SigviewTooltip title="Granularity">
                      <Box>
                        <SigviewSingleSelect
                          value={wsForm.metricChartGranularity.value}
                          data={wsForm.metricGranData.value}
                          onChange={(value) => {
                            handleWsFormChange("metricChartGranularity", value);
                          }}
                          minWidth="60px"
                          // margin={{ right: "15px" }}
                          border="none"
                        />
                      </Box>
                    </SigviewTooltip>
                  </Box> */}

                  {/* WITHOUT TOOLTIP - IF TITLE IS PRESENT */}
                  <SigviewTooltip title="Granularity">
                    <Box>
                      <SigviewSingleSelect
                        value={wsForm.metricChartGranularity.value}
                        data={wsForm.metricGranData.value}
                        onChange={handleChartGranChange}
                        minWidth="60px"
                        fontSize="11px"
                        textTransform="capitalize"
                        customId={wsForm.metricChartGranularity.value}
                        customClassName="Granularity-GA"
                        // margin={{ right: "15px" }}
                        border="none"
                      />
                    </Box>
                  </SigviewTooltip>
                </LayoutMetricChartsMenu>

                {/* DIMENSION TABLES MENU */}
                <LayoutDimTablesMenu showTitle={false}>
                  <>
                    <SigviewTypography
                      variant="pSmallMedium"
                      style={{
                        height: "max-content",
                        margin: "0px 3px 0px 3px !important",
                      }}
                    >
                      Global Sort
                    </SigviewTypography>
                    <SigviewSwitch
                      checked={wsForm.globalSort.value}
                      onChange={handleGlobalSortChange}
                      customClassName="GlobalSortON-OFF-GA"
                    />
                    {isGlobalSortOn && (
                      <SigviewDataSort
                        data={dataForDataSort}
                        initialSelections={initialSelectionsDataSort}
                        // onChange={handleChange}
                        handleSave={handleSaveDataSort}
                        isComparisonOn={wsForm.timeFilters.value.isComparisonOn}
                      />
                    )}
                  </>
                  <>
                    {/* WITHOUT TOOLTIP */}
                    {/* <SigviewTypography
                      variant="pSmall"
                      style={{
                        height: "max-content",
                        margin: "0px 3px 0px 3px !important",
                      }}
                    >
                      Row Count:
                    </SigviewTypography>
                    <SigviewSingleSelect
                      minWidth={"50px"}
                      maxWidth={"50px"}
                      value={wsForm.dataLimit.value}
                      data={dataLimitData}
                      onChange={(newValue) =>
                        handleWsFormChange("dataLimit", newValue)
                      }
                      border="none"
                      // margin={{ left: "10px", right: "10px" }}
                    /> */}

                    {/* WITH TOOLTIP - WIHTOUT TITLE */}
                    <SigviewTypography
                      variant="pSmallMedium"
                      style={{
                        height: "max-content",
                        margin: "0px 3px 0px 3px !important",
                      }}
                    >
                      Row Count:
                    </SigviewTypography>
                    <SigviewTooltip title="Row Count">
                      <Box>
                        <SigviewSingleSelect
                          minWidth={"50px"}
                          maxWidth={"50px"}
                          value={wsForm.dataLimit.value}
                          data={dataLimitData}
                          onChange={handleDataLimitChange}
                          border="none"
                          customId={wsForm.dataLimit.value}
                          customClassName="AnalyzeTableRowCount-GA"
                        />
                      </Box>
                    </SigviewTooltip>
                  </>
                  <TableGridView
                    data={gridTypeData}
                    value={wsForm.dimTablesGridViewType.value}
                    onChange={handleGridTypeChange}
                  />
                </LayoutDimTablesMenu>

                {/* OLD IMPLEMENTATION */}
                {/* <Box css={menuChildrenCss}>
                  <SigviewTypography
                    variant="pSmall"
                    style={{ height: "max-content" }}
                  >
                    Row Count:
                  </SigviewTypography>
                  <SigviewSingleSelect
                    minWidth={"50px"}
                    maxWidth={"50px"}
                    value={wsForm.dataLimit.value}
                    data={dataLimitData}
                    onChange={(newValue) =>
                      handleWsFormChange("dataLimit", newValue)
                    }
                    margin={{ left: "10px", right: "10px" }}
                  />
                  <SigviewVR />

                  <SigviewTypography
                    variant="pSmall"
                    style={{ height: "max-content" }}
                  >
                    Global Sort
                  </SigviewTypography>
                  <SigviewSwitch
                    checked={wsForm.globalSort.value}
                    onChange={handleGlobalSortChange}
                  />
                  {isGlobalSortOn && (
                    <SigviewDataSort
                      data={dataForDataSort}
                      initialSelections={initialSelectionsDataSort}
                      // onChange={handleChange}
                      handleSave={handleSaveDataSort}
                    />
                  )}
                  <SigviewVR />
                  <TableGridView
                    data={gridTypeData}
                    value={wsForm.dimTablesGridViewType.value}
                    onChange={(value) => {
                      handleWsFormChange("dimTablesGridViewType", value);
                    }}
                  />
                </Box> */}

                {/* METRIC CHARTS */}
                <GridContextProvider onChange={handleKpisLayoutChange}>
                  <Box className="ws-react-dnd-container">
                    <GridDropZone
                      className="ws-react-dnd-dropzone workspace-kpis"
                      id="kpis"
                      boxesPerRow={1}
                      rowHeight={180}
                    >
                      {layoutKpisMemoized.map((item) => {
                        return (
                          <GridItem key={item.id}>
                            <Box className="ws-react-dnd-grid-item">
                              <WsMetricChartItem id={item.id} />
                              {/* <Box className="ws-react-dnd-grid-item-content">
                                {item.id.toUpperCase()}
                              </Box> */}
                            </Box>
                          </GridItem>
                        );
                      })}
                      {addDimensionDrawerData.metricData.length !==
                        selectedMetricsArray.length && (
                        <GridItem key="Add Metric Card">
                          <AddAttributeCard
                            themeColors={themeColors}
                            onClick={() => handleShowAddMetricDrawer(true)}
                          />
                        </GridItem>
                      )}
                    </GridDropZone>
                  </Box>
                </GridContextProvider>

                {/* DIMENSION TABLES */}
                <GridContextProvider onChange={handleDimensionsLayoutChange}>
                  <Box className="ws-react-dnd-container">
                    <GridDropZone
                      disableDrag={!drag}
                      className="ws-react-dnd-dropzone workspace-dimensions"
                      id="dimensions"
                      boxesPerRow={boxesPerRowDimTables}
                      rowHeight={220}
                    >
                      {layoutDimensionsMemoized.slice(0, 6).map((item) => {
                        return (
                          <GridItem key={item.id}>
                            <Box className="ws-react-dnd-grid-item">
                              <WsDimensionTableItem
                                id={item.id}
                                wsForm={wsForm}
                              />
                              {/* <Tester name={memoizedName}></Tester> */}
                              {/* <Box className="ws-react-dnd-grid-item-content">
                                {item.id.toUpperCase()}
                              </Box> */}
                            </Box>
                          </GridItem>
                        );
                      })}
                      {layoutDimensionsMemoized.slice(6).map((item) => {
                        return (
                          <GridItem key={item.id}>
                            <Box className="ws-react-dnd-grid-item">
                              <WsDimensionTableItem
                                id={item.id}
                                wsForm={wsForm}
                              />
                              {/* <Tester name={memoizedName}></Tester> */}
                              {/* <Box className="ws-react-dnd-grid-item-content">
                                {item.id.toUpperCase()}
                              </Box> */}
                            </Box>
                          </GridItem>
                        );
                      })}
                      {addDimensionDrawerData.dimData.length !==
                        selectedDimensionArray.length && (
                        <GridItem key="Add Dimension Card">
                          <AddAttributeCard
                            themeColors={themeColors}
                            attributeType="Dimension"
                            onClick={() => handleShowAddDimensionDrawer(true)}
                          />
                        </GridItem>
                      )}
                    </GridDropZone>
                  </Box>
                </GridContextProvider>
              </LayoutAnalyzeDashboard>

              {wsForm.screen.value.metricDrawer && (
                <AddMetricDrawer
                  data={allData.plotlyMetrics}
                  user={user}
                  onCloseHandler={handleShowAddMetricDrawer}
                  initialSelections={addMetricDrawerInitialSelections}
                  isOpen={wsForm.screen.value.metricDrawer}
                  onChange={handleMetricDrawerSubmit}
                />
              )}
              {wsForm.screen.value.dimensionDrawer && (
                <AddDimensionDrawer
                  data={addDimensionDrawerData}
                  initialSelections={addDimensionDrawerInitialSelections}
                  onChange={handleDimensionDrawerSubmit}
                  isOpen={wsForm.screen.value.dimensionDrawer}
                  onCloseHandler={handleShowAddDimensionDrawer}
                  // TODO: This has to be set in state by dimension table and it should hence come from state
                  dimOpenByDefault={wsForm.screen.value.dimensionDrawerList} // Should be object like this ---> { D017: true }
                  // OLD IMPLEMENTATION (ALL METRICS COMMON IN ALL DIMENSION TABLES)
                  // initialSelections={{
                  //   selectedDim: Object.keys(wsForm.selectedDimensions.value),
                  //   selectedMetric: wsForm.selectedDimTableMetrics.value.map(
                  //     (row) => row._id
                  //   ),
                  // }}
                />
              )}
            </>
          )}
        </LayoutTopSideBottom>
      )}
      {isMsvOpen && (
        <MSV workspaceEventHandlers={{ onMsvCancel, onMsvApplyFilters }}></MSV>
      )}
    </>
  );
}

AnalyzeDashboard.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  standaloneWs: state.standaloneWs,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(AnalyzeDashboard);
