// Import required libraies
import React, { useState } from "react";
import { v4 } from "uuid";

// Import lib
import { Popover, Box, makeStyles } from "@material-ui/core";

// Import Custom Component
import Loader from "../../../components/Loader/Loader";
import SigviewTextField from "../../../components/Common/SigviewTextField";
import SigviewButton from "../../../components/Common/SigviewButton";
import SigviewSimpleText from "../../../components/Common/SigviewSimpleText";
import SigviewRadioGroup from "../../../components/Common/SigviewRadioGroup";
import SigviewIcon from "../../../components/Common/SigviewIcon";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SigviewTooltip from "../../../components/Common/SigviewTooltip";

// Import actions
import {
  updateUserScreen,
  updateStandaloneDsFormMultipleFields,
} from "../../../redux/actions";

// * Import data/utils
import { config } from "../../../config/config";
import { validateChartName } from "../../../utils/chartObjectUtils";
import { wrapperDatastory, dsFormData } from "../../../utils/dsUtils";
import { masterTrackGaEvent } from "../../../services/ga";

// Import API functions
import { saveDs } from "../../../services/api";

// const Icons = (props) => {
//   const { data } = props;
//   console.log("data", data);
//   const classes = {
//     root: {
//       display: "flex",
//     },
//     item: {
//       fontSize: "16px",
//       marginLeft: "5px",
//       cursor: "pointer",
//       color: themeColors.light["secondaryColor"],
//       "&:hover": { color: themeColors.light["primaryColor"] },
//     },
//   };
//   const mapping = {
//     update: "cloud_upload",
//     edit: "edit",
//     rearrange: "sort",
//     clone: "content_copy",
//     delete: "delete",
//   };
//   const muiClassMapping = {
//     update: "material-icons",
//     edit: "material-icons",
//     rearrange: "material-icons-round",
//     clone: "material-icons",
//     delete: "material-icons",
//   };
//   const tooltipMapping = {
//     update: "Update to Cloud",
//     edit: "Edit",
//     rearrange: "Rearrange",
//     clone: "Clone",
//     delete: "Delete",
//   };
//   return (
//     <div style={classes.root}>
//       {data.map((row) => (
//         <SigviewTooltip title={tooltipMapping[row.id]} placement="bottom">
//           <span
//             className={muiClassMapping[row.id]}
//             style={classes.item}
//             onClick={row.onClick}
//           >
//             {mapping[row.id]}
//           </span>
//         </SigviewTooltip>
//       ))}
//     </div>
//   );
// };

const SigviewDialogClone = (props) => {
  const { user, ReduxDispatcher, initialName, dsForm, isEdit = false } = props;
  // Defining required state
  const [name, setName] = useState({
    value: initialName,
    status: "success", // success/error/loading
    message: "",
  });

  // Making required variables

  // Defining required event handlers
  const handleNameChange = (newValue) => {
    setName(() => {
      const { status, message } = validateChartName(newValue);
      let finalStatus = "success";
      if (status === "invalid") finalStatus = "error";
      const newState = { status: finalStatus, message, value: newValue };
      return newState;
    });
  };

  const handleCancel = () => {
    const payload = { open: false, children: null };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };

  const handleClone = (type = "clone") => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "DatastoryDashboard",
      action: "CloneMenu",
      label: type,
    });
    let backendDs = wrapperDatastory({ uiDs: dsForm, user });
    backendDs = {
      ...backendDs,
      _id: v4(),
      title: name.value,
      createdOn: Date.now().toString(),
      modifiedOn: Date.now().toString(),
      dsMetaData: {
        ...backendDs.dsMetaData,
        thumbnail: {
          ...backendDs.dsMetaData.thumbnail,
          url: config.hardCoded.sampleDashboardUrl,
        },
      },
    };
    const fetchProps = {
      payload: { ...backendDs },
    };
    setName((prevState) => ({ ...prevState, status: "loading" }));
    const saveDsPromise = saveDs(fetchProps);
    saveDsPromise
      .then(() => {
        // Update local status to success
        setName((prevState) => ({ ...prevState, status: "success" }));
        // Close the dialog
        handleCancel();
        // Update snackbar
        const snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: "Datastory saved successfully!",
        };
        var action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);

        // If type is cloneLaunch, update activeChart
        if (type === "cloneLaunch") {
          const value = {
            isDashboardLoading: false,
            activeNav: "datastory",
            activeTab: "dashboard",
            activeDsCategory: "saved",
            activeDatastory: {
              ...backendDs,
              dsCategory: "Saved",
              elementType: "edit",
            },
          };
          var action = updateUserScreen(null, value);
          ReduxDispatcher(action);
          // ! NOT NEEDED AS THE USER IS ALREADY ON THE SAME ROUTE
          // history.push("/datastory/dashboard")
        }
      })
      .catch((json) => {
        console.groupCollapsed("API FAILED");
        console.log("Error JSON -> ", json);
        console.groupEnd();
        const message = json.error || "Datastory saved failed";
        // Update local status to error
        setName((prevState) => ({ ...prevState, status: "error", message }));
      });
  };

  // Defining required variables
  const errorFlag = name.status === "error";
  const helperText = name.message;
  const cloneDisabled = ["error", "loading"].includes(name.status);
  const cloneTitle = name.status === "loading" ? <Loader /> : "Clone";
  const cloneLaunchTitle =
    name.status === "loading" ? <Loader /> : "Clone & Launch";

  return (
    <section className="sigview-dialog-chart-clone">
      <SigviewSimpleText
        value="Enter datastory name"
        style={{
          border: "none",
          fontSize: "16px",
          margin: "0px 0px 15px 0px",
          padding: "0px",
        }}
      />
      <SigviewTextField
        placeholder="Please enter a valid datastory name"
        value={name.value}
        onChange={(newValue) => handleNameChange(newValue)}
        error={errorFlag}
        helperText={helperText}
        minWidth="400px"
        maxWidth="400px"
        height="40px"
        fontSize="12px"
        margin={{ right: "5px", bottom: "10px" }}
      />
      <article className="sigview-dialog-chart-clone-menu-bar">
        <SigviewButton
          variant="outlined"
          onClick={handleCancel}
          title="Cancel"
          style={{ width: "auto", margin: { left: "3px", right: "3px" } }}
        />
        <SigviewButton
          variant="contained"
          onClick={() => handleClone("clone")}
          disabled={cloneDisabled}
          customClassName="DatastoryClone-GA"
          title={cloneTitle}
          style={{ width: "auto", margin: { left: "3px", right: "3px" } }}
        />
        <SigviewButton
          variant="contained"
          onClick={() => handleClone("cloneLaunch")}
          disabled={cloneDisabled}
          customClassName="DatastoryCloneLaunch-GA"
          title={cloneLaunchTitle}
          style={{
            width: "max-content",
            margin: { left: "3px", right: "3px" },
          }}
        />
      </article>
    </section>
  );
};

// * Define required components
const DsSettingsIcon = (props = {}) => {
  const { dsForm, dispatchDsForm, themeColors } = props;
  // * Define required states
  const initialSelections = {
    dimensionFilterType: dsForm.dimensionFilterType.value,
    timeFilterType: dsForm.timeFilterType.value,
    rollingDateType: dsForm.rollingDateType.value,
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [settingsSelections, setSettingsSelections] =
    useState(initialSelections);

  // * Define required event handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    const initialSelections = {
      dimensionFilterType: dsForm.dimensionFilterType.value,
      timeFilterType: dsForm.timeFilterType.value,
      rollingDateType: dsForm.rollingDateType.value,
    };
    setSettingsSelections(initialSelections);
  };
  const handleDsSettingsChange = (key, value) => {
    setSettingsSelections((prevState) => ({ ...prevState, [key]: value }));
  };
  const handleCancel = () => handleClose();
  const handleSave = () => {
    const payload = { value: settingsSelections };
    const action = updateStandaloneDsFormMultipleFields(payload);
    dispatchDsForm(action);
    // To close the pop-up for setting
    setAnchorEl(null);
  };

  // * Define required variables
  const open = Boolean(anchorEl);
  const id = open ? "dsSettingsPopover" : undefined;
  const useStyles = makeStyles({
    root: {
      "& .MuiPaper-root ": {
        backgroundColor: "transparent !important",
      },
    },
  });
  const classes = useStyles();
  const containerCss = {
    backgroundColor: themeColors["mainContentBgColor"],
    padding: "10px 15px",
  };
  const footerCss = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  };
  const formRowCss = {
    marginBottom: "5px",
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <SigviewTooltip title="Edit Settings" placement="bottom">
        <Box>
          <SigviewIcon
            className="material-icons-round datastory-setting-icon-Clicked-GA"
            iconName="settings"
            style={{
              fontSize: "22px !important",
              padding: "0px 10px 0px 0px",
              color: themeColors["secondaryColorLight"],
              hoverColor: themeColors["primaryColor"],
              cursor: "pointer",
            }}
            onClick={handleClick}
          />
        </Box>
      </SigviewTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.root}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box css={containerCss}>
          <Box>
            <SigviewTypography
              variant="pLarge"
              style={{ color: themeColors["primaryColor"] }}
            >
              Settings
            </SigviewTypography>
          </Box>
          <Box>
            <Box css={formRowCss}>
              <SigviewTypography
                variant="pSmallMedium"
                style={{ height: "max-content", width: "150px" }}
              >
                Apply Dimension Filters On:
              </SigviewTypography>
              <SigviewRadioGroup
                data={dsFormData.dimensionData}
                value={settingsSelections.dimensionFilterType}
                customClassName={`datastory-setting-${settingsSelections.dimensionFilterType}-GA`}
                onChange={(newValue) =>
                  handleDsSettingsChange("dimensionFilterType", newValue)
                }
              />
            </Box>
            <Box css={formRowCss}>
              <SigviewTypography
                variant="pSmallMedium"
                style={{ height: "max-content", width: "150px" }}
              >
                Apply Time Filters On:
              </SigviewTypography>
              <SigviewRadioGroup
                data={dsFormData.dimensionData}
                value={settingsSelections.timeFilterType}
                customClassName={`datastory-setting-timefilter-${settingsSelections.timeFilterType}-GA`}
                onChange={(newValue) =>
                  handleDsSettingsChange("timeFilterType", newValue)
                }
              />
            </Box>
            <Box css={formRowCss}>
              <SigviewTypography
                variant="pSmallMedium"
                style={{ height: "max-content", width: "150px" }}
              >
                Rolling Dates:
              </SigviewTypography>
              <SigviewRadioGroup
                data={dsFormData.rollingData}
                value={settingsSelections.rollingDateType}
                customClassName={`datastory-setting-rollingDates-${settingsSelections.rollingDateType}-GA`}
                onChange={(newValue) =>
                  handleDsSettingsChange("rollingDateType", newValue)
                }
              />
            </Box>
          </Box>
          <Box css={footerCss}>
            <SigviewButton
              variant="outlined"
              onClick={handleCancel}
              title="Discard"
              style={{ width: "80px" }}
            />
            <SigviewButton
              variant="contained"
              onClick={handleSave}
              title="Save"
              customClassName="datastory-Setting-Save-GA"
              style={{ width: "80px", margin: { left: "10px" } }}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export { SigviewDialogClone, DsSettingsIcon };
