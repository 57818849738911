// Import required lib
import { v4 } from "uuid";
import moment from "moment";

// Import utils
import { config } from "../config/config";
import allDatePresets from "../../assets/data/allDatePresets.json";
import {
  transformFiltersBackendToUi,
  transformFiltersUiToBackend,
  transformMetricFiltersBackendToUi,
} from "./filtersUtils";
import {
  unwrapperTimeFilters,
  validateChartName,
  unwrapperChartObject,
  wrapperChartObject,
} from "./chartObjectUtils";
import { getDatePresetObj } from "./timeFiltersUtils";
import {
  getMeasureObjByOriginalID,
  getMetricTypeForChartObject,
  appendMetricSym,
  getOriginalIdFromMeasureId,
} from "./plotlyUtils";

// SAMPLE OBJECTS
// const sampleSavedUi = https://run.mocky.io/v3/df08a8f6-2e2e-4032-993e-44ebdef956f2
// const sampleSavedBackend = https://run.mocky.io/v3/02b0cccb-d9fb-428a-aabf-46205b5af757

const unwrapperDsKpiObject = (props = {}) => {
  const { user = {}, allData = {}, payload = {} } = props;
  const { chartObject = {} } = payload;
  const { requestParam = {} } = chartObject;
  try {
    const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarReports;
    const dimensionFilters = transformFiltersBackendToUi(
      requestParam.filter || [],
      allData.plotlyDimensions
    );
    const metricFilters = transformMetricFiltersBackendToUi(
      requestParam.metricFilter || [],
      allData.plotlyMetrics
    );
    const timeFilters = unwrapperTimeFilters({
      chartObject,
      calendarDaysLimits,
      user,
      allData,
    });
    let kpiObject = {
      metricFilters,
      dimensionFilters,
      timeFilters,
    };
    return kpiObject;
  } catch (error) {
    console.error("UI ERROR -> CORRUPT KPI OBJECT");
    console.groupCollapsed("Details Below");
    console.log("ERROR ->", error);
    console.log("PROPS", props);
    console.groupEnd();
    let defaultKpiObject = {
      metricFilters: [],
      dimensionFilters: [],
      timeFilters: {},
    };
    return defaultKpiObject;
  }
};

// Layout utility functions

const generateContainerInfo = (props) => {
  const { x = 0, y = 0, metrics = [], charts = [], counterStart = 0 } = props;
  // let currX = x;
  // let currY = y;
  // let xOffset = x;
  let yOffset = y;
  let maxY = y;
  // let metricsLen = metrics.length;
  // let chartsLen = charts.length;
  // let metricsRows = Math.ceil(metricsLen / 6);
  // let chartsRows = Math.ceil(chartsLen / 2);
  let newMetrics = [];
  let newCharts = [];
  let counter = counterStart;

  // Updating metrics
  for (const index in metrics) {
    const metric = metrics[index];
    const newX = (index % 6) * 2;
    const newY = yOffset + Math.floor(index / 6) * 3;

    // Updating maxY
    if (newY > maxY) maxY = newY;

    const uniqueId = v4();

    const container = {
      id: uniqueId,
      size: "small1",
      metadata: {
        i: uniqueId,
        x: newX,
        y: newY,
        w: 2,
        h: 3,
      },
    };
    // Increment the coutner
    counter += 1;

    // Make a new row
    const newRow = { ...metric, container: container };
    newMetrics.push(newRow);
  }

  // Reinitialize yOffset as maxY + height of metric
  // * Do this only when there are is atleast 1 metric in the metrics list
  if (metrics.length > 0) yOffset = maxY + 3;

  // Updating charts
  for (const index in charts) {
    const chart = charts[index];
    const newX = (index % 2) * 6;
    const newY = yOffset + Math.floor(index / 2) * 8;

    // Updating maxY
    if (newY > maxY) maxY = newY;

    const uniqueId = v4();

    const container = {
      id: uniqueId,
      size: "medium1",
      metadata: {
        i: uniqueId,
        x: newX,
        y: newY,
        w: 6,
        h: 8,
      },
    };
    // Increment the coutner
    counter += 1;

    // Make a new row
    const newRow = { ...chart, container: container };
    newCharts.push(newRow);
  }

  const res = { metrics: newMetrics, charts: newCharts };
  return res;
};

const generateContainerInfoV2 = (props) => {
  const { x = 0, y = 0, metrics = [], charts = [], counterStart = 0 } = props;
  // let currX = x;
  // let currY = y;
  let xOffset = x;
  let yOffset = y;
  let maxY = y;
  // let metricsLen = metrics.length;
  // let chartsLen = charts.length;
  // let metricsRows = Math.ceil(metricsLen / 6);
  // let chartsRows = Math.ceil(chartsLen / 2);
  let newMetrics = {};
  let newCharts = {};
  let counter = counterStart;

  // Updating metrics
  for (const index in metrics) {
    const metric = metrics[index];
    const newX = (index % 6) * 2;
    const newY = yOffset + Math.floor(index / 6) * 3;

    // Updating maxY
    if (newY > maxY) maxY = newY;

    const uniqueId = v4();

    const container = {
      id: uniqueId,
      size: "small1",
      metadata: {
        i: uniqueId,
        x: newX,
        y: newY,
        w: 2,
        h: 3,
      },
    };
    // Increment the coutner
    counter += 1;

    // Make a new row
    newMetrics[metric] = container;
  }

  // Reinitialize yOffset as maxY + height of metric
  // * Do this only when there are is atleast 1 metric in the metrics list
  if (metrics.length > 0) yOffset = maxY + 3;

  // Updating charts
  for (const index in charts) {
    const chart = charts[index];
    const newX = (index % 2) * 6;
    const newY = yOffset + Math.floor(index / 2) * 8;

    // Updating maxY
    if (newY > maxY) maxY = newY;

    const uniqueId = v4();

    const container = {
      id: uniqueId,
      size: "medium1",
      metadata: {
        i: uniqueId,
        x: newX,
        y: newY,
        w: 6,
        h: 8,
      },
    };
    // Increment the coutner
    counter += 1;

    // Make a new row
    newCharts[chart] = container;
  }

  const res = { metrics: newMetrics, charts: newCharts };
  return res;
};

const populateContainerInfoV2 = (dsForm) => {
  const gridLayout = dsForm.gridLayout.value;
  const allContainerInfo = dsForm.containerInfo.value;
  const metricList = Object.keys(dsForm.selectedKpis.value);
  const chartList = Object.keys(dsForm.selectedCharts.value);
  let newMetricList = [...metricList];
  let newChartList = [...chartList];

  // Find max of y because we will start placing the new metrics and charts from (0, y)
  // Max of y = max(y+h) for all charts and metrics
  let maxY = 0;

  let leftOverMetrics = [];
  let leftOverCharts = [];

  // Update from gridlayout
  // Do something else
  newMetricList = metricList.map((kpiId, index) => {
    const containerInfo = allContainerInfo[kpiId];
    if (containerInfo) {
      const containerId = containerInfo.id;
      const gridLayoutRow = gridLayout.find(
        (gridRow) => gridRow.i === containerId
      );
      // Checking maxY
      let currMaxY = gridLayoutRow.y + gridLayoutRow.h;
      if (currMaxY > maxY) maxY = currMaxY;
      return `metric_${index + 1}`;
    } else {
      leftOverMetrics.push(kpiId);
    }
  });
  newChartList = chartList.map((chartId, index) => {
    const containerInfo = allContainerInfo[chartId];
    if (containerInfo) {
      const containerId = containerInfo.id;
      const gridLayoutRow = gridLayout.find(
        (gridRow) => gridRow.i === containerId
      );
      // Checking maxY
      let currMaxY = gridLayoutRow.y + gridLayoutRow.h;
      if (currMaxY > maxY) maxY = currMaxY;
      return `chart_${index + 1}`;
    } else {
      leftOverCharts.push(chartId);
    }
  });

  // Remove undefined values
  newMetricList = newMetricList.filter((row) => row !== undefined);
  newChartList = newChartList.filter((row) => row !== undefined);
  const totalWidgets = newMetricList.length + newChartList.length;

  let { metrics: newMetricsContainerInfo, charts: newChartsContainerInfo } =
    generateContainerInfoV2({
      x: 0,
      y: maxY,
      metrics: leftOverMetrics,
      charts: leftOverCharts,
      counterStart: totalWidgets + 1,
    });

  // Adding metrics and charts with new container info
  // newMetricList = [...newMetricList, ...newMetrics];
  // newChartList = [...newChartList, ...newCharts];

  // console.log("chartList", chartList);
  // console.log("newChartsContainerInfo", newChartsContainerInfo);
  //
  let additionalContainerInfo = {
    ...newMetricsContainerInfo,
    ...newChartsContainerInfo,
  };
  let additionalGridLayoutMetrics = Object.keys(newMetricsContainerInfo).map(
    (row) => ({
      container: newMetricsContainerInfo[row],
      chartType: "counter",
      static: false,
      widgetItemId: row,
      ...newMetricsContainerInfo[row].metadata,
    })
  );
  let additionalGridLayoutCharts = Object.keys(newChartsContainerInfo).map(
    (row) => ({
      container: newChartsContainerInfo[row],
      chartType: dsForm.selectedCharts.value[row].chartType,
      static: false,
      widgetItemId: row,
      ...newChartsContainerInfo[row].metadata,
    })
  );
  let additionalGridLayout = [
    ...additionalGridLayoutMetrics,
    ...additionalGridLayoutCharts,
  ];

  // update dsForm
  const updDsForm = {
    ...dsForm,
    gridLayout: {
      ...dsForm.gridLayout,
      value: [...dsForm.gridLayout.value, ...additionalGridLayout],
    },
    containerInfo: {
      ...dsForm.containerInfo,
      value: {
        ...dsForm.containerInfo.value,
        ...additionalContainerInfo,
      },
    },
  };

  return updDsForm;
};

//WRAPPER -> UI to Backend
const wrapperDatastory = (props) => {
  // For a new DS or an old DS with new charts/kpis, container positions need to be added
  // This function takes in a metrics array, a charts array and the starting x and y coordinate and
  // adds containers' coordinates
  // For metrics:
  //    width: 2 units
  //    height: 4 units
  // For charts:
  //    width: 6 units
  //    height: 8 units
  // MAX WIDTH = 12 units
  // Hence, max metrics count in one row = 6
  // Hence, max charts count in one row = 2
  // Total metric rows needed = Math.ceil(total metrics/6)
  // Total charts rows needed = Math.ceil(total metrics/2)

  // Making required functions
  const populateContainerInfo = (props) => {
    const {
      metricList = [],
      chartList = [],
      containerInfo: allContainerInfo = {},
      gridLayout,
      type = "create",
    } = props;
    let newMetricList = [...metricList];
    let newChartList = [...chartList];
    if (type === "create") {
      let { metrics: newMetrics, charts: newCharts } = generateContainerInfo({
        x: 0,
        y: 0,
        metrics: metricList,
        charts: chartList,
        counterStart: 0,
      });
      newMetricList = [...newMetrics];
      newChartList = [...newCharts];
    } else {
      // Find max of y because we will start placing the new metrics and charts from (0, y)
      // Max of y = max(y+h) for all charts and metrics
      let maxY = 0;

      let leftOverMetrics = [];
      let leftOverCharts = [];

      // Update from gridlayout
      // Do something else
      newMetricList = metricList.map((row) => {
        const containerInfo = allContainerInfo[row.id];
        if (containerInfo) {
          const containerId = containerInfo.id;
          const gridLayoutRow = gridLayout.find(
            (gridRow) => gridRow.i === containerId
          );

          // Checking maxY
          let currMaxY = gridLayoutRow.y + gridLayoutRow.h;
          if (currMaxY > maxY) maxY = currMaxY;

          return {
            ...row,
            container: {
              id: containerId,
              metadata: {
                i: containerId,
                x: gridLayoutRow.x,
                y: gridLayoutRow.y,
                w: gridLayoutRow.w,
                h: gridLayoutRow.h,
              },
              size: gridLayoutRow.container.size,
            },
          };
        } else {
          leftOverMetrics.push(row);
        }
      });
      newChartList = chartList.map((row) => {
        const containerInfo = allContainerInfo[row.id];
        if (containerInfo) {
          const containerId = containerInfo.id;
          const gridLayoutRow = gridLayout.find(
            (gridRow) => gridRow.i === containerId
          );
          let { id, ...newRow } = row;

          // Checking maxY
          let currMaxY = gridLayoutRow.y + gridLayoutRow.h;
          if (currMaxY > maxY) maxY = currMaxY;

          return {
            ...newRow,
            container: {
              id: containerId,
              metadata: {
                i: containerId,
                x: gridLayoutRow.x,
                y: gridLayoutRow.y,
                w: gridLayoutRow.w,
                h: gridLayoutRow.h,
              },
              size: gridLayoutRow.container.size,
            },
          };
        } else {
          leftOverCharts.push(row);
        }
      });

      // Remove undefined values
      newMetricList = newMetricList.filter((row) => row !== undefined);
      newChartList = newChartList.filter((row) => row !== undefined);
      const totalWidgets = newMetricList.length + newChartList.length;

      let { metrics: newMetrics, charts: newCharts } = generateContainerInfo({
        x: 0,
        y: maxY,
        metrics: leftOverMetrics,
        charts: leftOverCharts,
        counterStart: totalWidgets + 1,
      });

      // Adding metrics and charts with new container info
      newMetricList = [...newMetricList, ...newMetrics];
      newChartList = [...newChartList, ...newCharts];
    }

    return { metricList: newMetricList, chartList: newChartList };
  };

  // Destructure props
  const { uiDs = {}, user = {} } = props;
  const {
    id = {},
    datastoryName = {},
    rollingDateType = {},
    timeFilters = {},
    dimensionFilters = {},
    selectedKpis = {},
    selectedCharts = {},
    containerInfo = {},
    type = {},
    actualPayload = {},
    gridLayout = {},
    dimensionFilterType = {},
    timeFilterType = {},
  } = uiDs;

  // Defining requried variables
  const isEdit = type.value === "edit";
  const actualPayloadVal = actualPayload.value;

  // SIMPLER FIELDS
  const _id = id.value;
  const level = "group"; //TODO: HARD CODED
  const userType = "internal"; //TODO: HARD CODED
  const orgViewReq = {
    organization: user?.reqMetadata?.organization,
    view: user?.reqMetadata?.view,
  };
  const title = datastoryName.value;

  // COMPLEX FIELDS
  const progressiveDate = rollingDateType.value === "yes" ? "true" : "false";
  const timeZone = {
    name: timeFilters.value.selectedTimezone.name,
    location: timeFilters.value.selectedTimezone.location,
  };
  const startDate = timeFilters.value.selectedDatesQE.startDate.toString();
  const endDate = timeFilters.value.selectedDatesQE.endDate.toString();
  const dateRange = { startDate, endDate };
  const dateRangeType = getDatePresetObj(
    timeFilters.value.selectedDatePreset,
    allDatePresets,
    "id"
  ).name;
  const filter = transformFiltersUiToBackend(dimensionFilters.value);
  const url = isEdit
    ? actualPayloadVal.dsMetaData.thumbnail.url
    : config.hardCoded.sampleDashboardUrl;
  const layoutId = isEdit
    ? actualPayloadVal.dsMetaData.layoutMetadata.layoutId
    : "Layout1";
  const dataLimit = isEdit
    ? actualPayloadVal.dsMetaData.requestParam.dataLimit
    : config.hardCoded.dataLimit;
  const chartLimit = isEdit ? actualPayloadVal.dsMetaData.chartLimit : 5;
  const metricLimit = isEdit ? actualPayloadVal.dsMetaData.metricLimit : 6;
  const downloadLimit = isEdit
    ? actualPayloadVal.dsMetaData.downloadLimit
    : 500;
  const dashboardRequestParam = {
    timeZone: timeZone,
    dateRange: dateRange,
    compareDate: dateRange,
    dateRangeType: dateRangeType,
    compareDateRangeType: "Custom",
    progressiveDate: progressiveDate,
    dataLimit: dataLimit,
    filter: filter,
    comparisonMode: false, //TODO: HARD CODED
  };
  const dsMetaData = {
    dimensionFiltersAppliedOn: dimensionFilterType.value,
    timeFiltersAppliedOn: timeFilterType.value,
    layoutMetadata: {
      layoutId: layoutId, //TODO: HARD CODED
      staticFlag: true,
    },
    requestParam: dashboardRequestParam,
    isActive: true, //TODO: HARD CODED
    chartLimit: chartLimit, //TODO: HARD CODED
    metricLimit: metricLimit, //TODO: HARD CODED
    downloadLimit: downloadLimit, //TODO: HARD CODED
    thumbnail: {
      url: url,
    },
  };
  const metricList = Object.entries(selectedKpis.value).map(
    ([metricId, metricValue]) => {
      const progressiveDateFlag = rollingDateType.value === "yes";
      const selections = { ...metricValue, progressiveDateFlag };
      const payload = wrapperChartObject({ selections, user: user });
      const { comparisonMode, dataLimit, ...reqMetadata } =
        payload.chartObject.metadata;
      const reqRequestParam = {
        ...payload.chartObject.requestParam,
        dataLimit,
        comparisonMode,
        compareDateRangeType: "Custom", // TODO : Hard coded; to be changed when compare is enabled
        compareDate: payload.chartObject.requestParam.dateRange,
      };
      // console.log("metricId", metricId);
      // console.log("metricValue", metricValue);
      // console.log("payload", payload);
      return {
        id: metricId,
        requestParam: reqRequestParam,
      };
    }
  );
  const chartList = Object.entries(selectedCharts.value).map(
    ([chartId, chartValue]) => {
      const progressiveDateFlag = rollingDateType.value === "yes";
      const selections = { ...chartValue, progressiveDateFlag };
      const payload = wrapperChartObject({ selections, user: user });
      const { comparisonMode, dataLimit, ...reqMetadata } =
        payload.chartObject.metadata;
      const reqRequestParam = {
        ...payload.chartObject.requestParam,
        dataLimit,
        comparisonMode,
        compareDateRangeType: "Custom", // TODO : Hard coded; to be changed when compare is enabled
        compareDate: payload.chartObject.requestParam.dateRange,
      };
      // console.log("chartId", chartId);
      // console.log("chartValue", chartValue);
      // console.log("payload", payload);
      return {
        requestParam: reqRequestParam,
        metadata: reqMetadata,
        id: chartId,
      };
    }
  );
  var datastoryParam = populateContainerInfo({
    metricList: metricList,
    chartList: chartList,
    containerInfo: containerInfo.value,
    type: type.value,
    gridLayout: gridLayout.value,
  });

  // Removing id from chartList objects
  datastoryParam = {
    ...datastoryParam,
    chartList: datastoryParam.chartList.map(({ id, ...rest }) => rest),
  };

  const createdBy = user?.reqMetadata?.email;
  const createdOn = isEdit
    ? actualPayloadVal.createdOn || Date.now().toString()
    : Date.now().toString();
  const isShared = true; //TODO: HARD CODED
  const recipientsList = []; //TODO: HARD CODED
  const modifiedOn = Date.now().toString(); //TODO: HARD CODED

  const backendDs = {
    _id,
    level,
    userType,
    orgViewReq,
    title,
    dsMetaData,
    datastoryParam,
    createdBy,
    createdOn,
    isShared,
    recipientsList,
    modifiedOn,
  };
  return backendDs;
};

//UNWRAPPER -> Backend to UI
const unwrapperDatastory = (props) => {
  // Making required functions
  const getReactGridLayout = (props) => {
    const {
      datastoryParam = {},
      staticFlag = true,
      containerInfoValueReverse = {},
    } = props;
    const { metricList = [], chartList = [] } = datastoryParam;
    let layoutMetrics = metricList.map((metric) => ({
      ...metric.container.metadata,
      chartType: "counter",
      container: metric.container,
    }));
    let layoutCharts = chartList.map((chart) => ({
      ...chart.container.metadata,
      chartType: chart.metadata.chartType,
      container: chart.container,
    }));
    let layoutFinal = [...layoutMetrics, ...layoutCharts];
    //Adding static flag to all elements in layout
    layoutFinal = layoutFinal.map((ele) => ({
      ...ele,
      static: staticFlag,
      widgetItemId: containerInfoValueReverse[ele.i],
    }));
    return layoutFinal;
  };

  // Destructure props
  const {
    backendDs = {},
    user = {},
    allData = {},
    isLayoutEditableFlag = true, // ! if you don't want to open on unpublished state, revert to true
  } = props;
  const {
    _id = "",
    title = "",
    dsMetaData = {},
    datastoryParam = {},
  } = backendDs;
  const { requestParam: dashboardRequestParam = {} } = dsMetaData;
  const {
    timeZone = {
      name: "UTC (+00:00)",
      location: "UTC",
    },
    dateRange = {
      startDate: "1633305600000",
      endDate: "1633910400000",
    },
    compareDate = {
      startDate: "1633305600000",
      endDate: "1633910400000",
    },
    dateRangeType = "Last Week",
    compareDateRangeType = "Last Week",
    progressiveDate = "true",
    dataLimit = config.hardCoded.dataLimit,
    filter = [],
    comparisonMode = false,
  } = dashboardRequestParam;
  const {
    dimensionFiltersAppliedOn: dimensionFilterTypeVal = "dashboard",
    timeFiltersAppliedOn: timeFilterTypeVal = "dashboard",
  } = dsMetaData;
  // const dimensionFilterTypeVal = "dashboard"; // TODO Change to dynamic
  // const timeFilterTypeVal = "dashboard"; // TODO Change to dynamic
  // const dimensionFilterTypeVal = "widget"; // TODO Change to dynamic
  // const timeFilterTypeVal = "widget"; // TODO Change to dynamic

  // Defining required variables
  let containerInfoValue = {};
  let reloadEpochsValue = {};
  const rollingDateTypeVal = progressiveDate === "true" ? "yes" : "no";
  const startDate = dateRange.startDate.toString();
  const endDate = dateRange.endDate.toString();

  // SIMPLER FIELDS
  const id = {
    value: _id,
    status: "valid",
    message: "",
    stepTag: "",
  };
  const datastoryName = {
    value: title,
    status: "valid",
    message: "",
    stepTag: "details",
  };
  const dimensionFilterType = {
    value: dimensionFilterTypeVal,
    status: "valid",
    message: "",
    stepTag: "details",
  };
  const timeFilterType = {
    value: timeFilterTypeVal,
    status: "valid",
    message: "",
    stepTag: "details",
  };
  const rollingDateType = {
    value: rollingDateTypeVal,
    status: "valid",
    message: "",
    stepTag: "details",
  };
  const chartObjectForTimeFilters = {
    requestParam: {
      dateRangeType,
      dateRange,
      timeZone,
      progressiveDate,
    },
  };
  const unwrapperTimeFiltersProps = {
    allData: allData,
    chartObject: chartObjectForTimeFilters,
    calendarDaysLimits: user.uiLimitsList.daysLimitCalendarReports,
    user: user,
  };
  const timeFiltersVal = unwrapperTimeFilters(unwrapperTimeFiltersProps);
  const timeFilters = {
    value: timeFiltersVal,
    status: "valid",
    message: "",
    stepTag: "details",
  };
  const dimensionFilters = {
    value: transformFiltersBackendToUi(filter, allData.plotlyDimensions),
    status: "valid",
    message: "",
    stepTag: "details",
  };
  const orgViewReq = {
    organization: user?.reqMetadata?.organization,
    view: user?.reqMetadata?.view,
  };
  let selectedChartsValue = {};
  datastoryParam.chartList.forEach((row) => {
    const uniqueId = v4();
    const payload = {
      _id: uniqueId,
      emailId: user?.reqMetadata?.email,
      orgViewReq: orgViewReq,
      chartObject: {
        metadata: row.metadata,
        requestParam: row.requestParam,
      },
    };
    const unwrapperChartObjectProps = { payload, user, allData };
    const selectedChart = unwrapperChartObject(unwrapperChartObjectProps);
    containerInfoValue[uniqueId] = row.container;
    selectedChartsValue[uniqueId] = selectedChart;
    reloadEpochsValue[uniqueId] = Date.now();
  });
  const selectedCharts = {
    value: selectedChartsValue,
    status: "valid",
    message: "",
    stepTag: "charts",
  };
  let selectedKpisValue = {};
  datastoryParam.metricList.forEach((row) => {
    console.log("row", row);
    const payload = {
      chartObject: {
        requestParam: row.requestParam,
      },
    };
    const unwrapperDsKpiObjectProps = { payload, user, allData };
    const selectedKpiObject = unwrapperDsKpiObject(unwrapperDsKpiObjectProps);
    const kpiMetadata = getMeasureObjByOriginalID(
      row.id,
      allData.plotlyMetrics
    );
    const selectedKpi = {
      id: row.id,
      title: kpiMetadata._title,
      chartType: "counter",
      metricFilters: selectedKpiObject.metricFilters,
      dimensionFilters: selectedKpiObject.dimensionFilters,
      timeFilters: selectedKpiObject.timeFilters,
      metadata: kpiMetadata,
      metricsList: [kpiMetadata],
      dimensionsList: [],
      orderById: row.id,
      orderBy: "desc",
    };
    containerInfoValue[row.id] = row.container;
    selectedKpisValue[row.id] = selectedKpi;
    // * Adding the below line is useless because all metric api calls are wrapped in 1
    // * But keeping this for consistency and future change
    reloadEpochsValue[row.id] = Date.now();
  });
  const selectedKpis = {
    value: selectedKpisValue,
    status: "valid",
    message: "",
    stepTag: "kpis",
  };
  let containerInfoValueReverse = {};
  for (const [key, value] of Object.entries(containerInfoValue)) {
    containerInfoValueReverse[value.id] = key;
  }
  const containerInfo = {
    value: containerInfoValue,
    status: "valid",
    message: "",
    stepTag: "",
  };
  const type = {
    value: "edit",
    status: "valid",
    message: "",
    stepTag: "",
  };
  const actualPayload = {
    value: { ...backendDs },
    status: "valid",
    message: "",
    stepTag: "",
  };
  const reactGridLayoutProps = {
    dsMetaData,
    datastoryParam,
    allData,
    user,
    timeFilters: timeFiltersVal,
    staticFlag: !isLayoutEditableFlag,
    containerInfoValueReverse,
  };
  const gridLayoutVal = getReactGridLayout(reactGridLayoutProps);
  const gridLayout = {
    value: gridLayoutVal,
    status: "valid",
    message: "",
    stepTag: "",
  };
  const layoutEditable = {
    value: isLayoutEditableFlag,
    status: "valid",
    message: "",
    stepTag: "",
  };
  let dataQEValue = {};
  Object.keys(selectedKpis.value).forEach((key) => {
    dataQEValue[key] = {
      status: "loading",
      message: "",
      result: { value: 0, displayValue: "0" },
    };
  });
  Object.keys(selectedCharts.value).forEach((key) => {
    dataQEValue[key] = {
      status: "loading",
      message: "",
      result: { dataFromQE: [], extraData: {} },
    };
  });
  const dataQE = {
    value: dataQEValue,
    status: "valid",
    message: "",
    stepTag: "",
  };
  // * ALL METRICS HAVE ONE RELOAD EPOCH SINCE ALL METRICS ARE WRAPPED IN 1 API CALL
  reloadEpochsValue["metrics"] = Date.now();
  const reloadEpochs = {
    value: reloadEpochsValue,
    status: "valid",
    message: "",
    stepTag: "",
  };

  const uiDs = {
    id,
    datastoryName,
    dimensionFilterType,
    timeFilterType,
    rollingDateType,
    timeFilters,
    dimensionFilters,
    selectedCharts,
    selectedKpis,
    containerInfo,
    type,
    actualPayload,
    gridLayout,
    layoutEditable,
    dataQE,
    reloadEpochs,
  };
  return uiDs;
};

const isDatastoryValid = (props) => {
  // * Destructure props
  const { backendDs = {}, user = {}, allData = {} } = props;
  const { dsMetaData = {}, datastoryParam = {} } = backendDs;
  const { requestParam: dashboardRequestParam = {} } = dsMetaData;
  const { filter = [] } = dashboardRequestParam;

  // * Response initializations
  let dimensionsList = [];
  let metricsList = [];
  let customMetricsList = [];
  let response = {
    status: "valid",
    message: "",
    metadata: { missingMetrics: [], missingDimensions: [] },
  };

  // * Dimension Filters
  for (const filterRow of filter) {
    dimensionsList.push(filterRow.id);
  }

  // * Chart List
  datastoryParam.chartList.forEach((row) => {
    metricsList = [
      ...metricsList,
      ...row.requestParam.yAxis,
      ...row.requestParam.specialCalculation,
      ...row.requestParam.approxCountDistinct,
    ];
    dimensionsList = [...dimensionsList, ...row.requestParam.xAxis];
  });

  // * Metric List
  datastoryParam.metricList.forEach((row) => {
    metricsList = [...metricsList, row.id];
  });

  // * Take unique values
  dimensionsList = [...new Set(dimensionsList)];
  metricsList = [...new Set(metricsList)];

  // * Checking if all attributes are present in the access list
  const allMetricIds = allData.plotlyMetrics.map((row) => row._id);
  const allDimensionIds = allData.plotlyDimensions.map((row) => row._id);
  const missingDimensionList = dimensionsList.filter(
    (id) => !allDimensionIds.includes(id)
  );
  const missingMetricsList = metricsList.filter(
    (id) => !allMetricIds.includes(id)
  );

  // * Final valid check
  const isInvalidFlag =
    missingDimensionList.length > 0 || missingMetricsList.length > 0;
  if (isInvalidFlag) {
    let missingMetrics = missingMetricsList.filter((id) => id.startsWith("M"));
    let missingCustomMetrics = missingMetricsList.filter((id) =>
      id.startsWith("C")
    );
    response = {
      status: "invalid",
      message: "",
      metadata: {
        missingMetrics: missingMetrics,
        missingCustomMetrics: missingCustomMetrics,
        missingDimensions: missingDimensionList,
      },
    };
  }

  // * DEBUGGER
  console.groupCollapsed("isDatastoryValid");
  console.log("dimensionsList", dimensionsList);
  console.log("metricsList", metricsList);
  console.log("customMetricsList", customMetricsList);
  console.log("missingDimensionList", missingDimensionList);
  console.log("missingMetricsList", missingMetricsList);
  console.log("response", response);
  console.groupEnd();

  return response;
};

const updateGridLayout = (gridLayout, layout) => {
  const newGridLayout = gridLayout.map((gridRow) => {
    const currLayoutRow = layout.find((layoutRow) => layoutRow.i === gridRow.i);
    const newGridRow = {
      ...gridRow,
      i: currLayoutRow.i,
      x: currLayoutRow.x,
      y: currLayoutRow.y,
      w: currLayoutRow.w,
      h: currLayoutRow.h,
      container: {
        ...gridRow.container,
        metadata: {
          i: currLayoutRow.i,
          x: currLayoutRow.x,
          y: currLayoutRow.y,
          w: currLayoutRow.w,
          h: currLayoutRow.h,
        },
      },
    };
    return newGridRow;
  });
  return newGridLayout;
};

const getInitialDsForm = (props) => {
  const {
    user,
    title = `Untitled Datastory - ${moment(Date.now()).format(
      config.hardCoded.defaultDsTimestampFormat
    )}`,
    allData,
    defaultFlag = false,
  } = props;
  const activeDatastory = user.screen.activeDatastory;
  let initialDsForm = {};
  if (activeDatastory.elementType === "edit" && !defaultFlag) {
    initialDsForm = unwrapperDatastory({
      backendDs: activeDatastory,
      user,
      allData,
    });
  } else {
    initialDsForm = {
      id: {
        value: v4(),
        status: "valid",
        message: "",
        stepTag: "",
      },
      datastoryName: {
        value: title,
        status: "valid", //> C.valid
        message: "",
        stepTag: "details", //> C.stepTagTypes.details
      },
      dimensionFilterType: {
        value: "dashboard",
        status: "valid", //> C.valid
        message: "",
        stepTag: "details", //> C.stepTagTypes.details
      },
      timeFilterType: {
        value: "dashboard",
        status: "valid", //> C.valid
        message: "",
        stepTag: "details", //> C.stepTagTypes.details
      },
      rollingDateType: {
        value: "yes",
        status: "valid", //> C.valid,
        message: "",
        stepTag: "details", //> C.stepTagTypes.details
      },
      timeFilters: {
        // value: {},
        value: user.timeFilters,
        message: "",
        status: "valid", //> C.valid
        stepTag: "details", //> C.stepTagTypes.details
      },
      dimensionFilters: {
        value: [],
        message: "",
        status: "valid", //> C.valid
        stepTag: "details", //> C.stepTagTypes.details
      },
      selectedCharts: {
        value: {},
        status: "valid", //> C.valid
        message: "",
        stepTag: "charts", //> C.stepTagTypes.charts
      },
      selectedKpis: {
        value: {},
        status: "valid", //> C.valid
        message: "",
        stepTag: "kpis", //> C.stepTagTypes.charts
      },
      containerInfo: {
        value: {},
        status: "valid",
        message: "",
        stepTag: "",
      },
      type: { value: "create", status: "valid", message: "", stepTag: "" },
      actualPayload: { value: {}, status: "valid", message: "", stepTag: "" },
      gridLayout: {
        value: [],
        status: "valid",
        message: "",
        stepTag: "",
      },
      layoutEditable: {
        value: false,
        status: "valid",
        message: "",
        stepTag: "",
      },
      dataQE: {
        value: {},
        status: "valid",
        message: "",
        stepTag: "",
      },
      reloadEpochs: { value: {}, status: "valid", message: "", stepTag: "" },
    };
  }
  return initialDsForm;
};

const getMetricPayloadFromDs = (props) => {
  const { ds, user, allData, timeFilters, dimensionFilters } = props;
  const { dsMetaData, datastoryParam } = ds;
  const { dataLimit = config.hardCoded.dataLimit } = dsMetaData;
  let yAxis = [],
    specialCalculation = [],
    approxCountDistinct = [];
  datastoryParam.metricList.forEach((metric) => {
    const metricObj = getMeasureObjByOriginalID(
      metric.id,
      allData.plotlyMetrics
    );
    const metricType = getMetricTypeForChartObject(metricObj);
    if (metricType === "base_sum") {
      yAxis.push(metric.id);
    } else if (metricType === "custom") {
      specialCalculation.push(metric.id);
    } else if (metricType === "base_approxCountDistinct") {
      approxCountDistinct.push(metric.id);
    }
  });
  const payload = {
    _id: v4(),
    emailId: user?.reqMetadata?.email,
    orgViewReq: {
      organization: user?.reqMetadata?.organization,
      view: user?.reqMetadata?.view,
    },
    chartObject: {
      metadata: {
        ...config.hardCoded.payload.getDataMetricMetadata,
        dataLimit,
      },
      requestParam: {
        xAxis: [],
        yAxis,
        specialCalculation,
        approxCountDistinct,
        filter: transformFiltersUiToBackend([...dimensionFilters]),
        timeZone: {
          name: timeFilters.selectedTimezone.name,
          location: timeFilters.selectedTimezone.location,
          value: timeFilters.selectedTimezone.value,
        },
        dateRange: {
          startDate: timeFilters.selectedDatesQE.startDate.toString(),
          endDate: timeFilters.selectedDatesQE.endDate.toString(),
        },
        orderBy: {},
      },
    },
  };
  return payload;
};

const getInitialDsStepper = (elementType = "create") => {
  const initialVisitedFlag = elementType === "edit";
  const initialValidFlag = elementType === "edit";
  const defaultSteps = [
    {
      id: "details",
      name: "Details",
      visited: { flag: true, message: "" },
      valid: { flag: initialValidFlag, message: "" },
    },
    {
      id: "charts",
      name: "Charts",
      visited: { flag: initialVisitedFlag, message: "" },
      valid: { flag: initialValidFlag, message: "" },
    },
    {
      id: "kpis",
      name: "KPIs",
      visited: { flag: initialVisitedFlag, message: "" },
      valid: { flag: initialValidFlag, message: "" },
    },
  ];

  const initialStepperState = {
    steps: defaultSteps,
    showError: false,
    activeStep: "details",
  };
  return initialStepperState;
};

const addValidationToDsForm = (dsForm) => {
  let validatedDsForm = {};
  for (const [key, value] of Object.entries(dsForm)) {
    switch (key) {
      case "datastoryName":
        var { status, message } = validateChartName(value.value);
        validatedDsForm[key] = { ...value, status, message };
        break;
      default:
        validatedDsForm[key] = { ...value };
    }
  }
  return { ...validatedDsForm };
};

const validateDsForm = (dsForm) => {
  for (const [key, value] of Object.entries(dsForm)) {
    if (value.status === "invalid")
      return { status: value.status, message: value.message };
  }
  return { status: "valid", message: "" };
};

const getDefaultDsForm = (user) => {
  return {
    id: {
      value: v4(),
      status: "valid",
      message: "",
      stepTag: "",
    },
    datastoryName: {
      value: "Untitled",
      status: "valid", //> C.valid
      message: "",
      stepTag: "details", //> C.stepTagTypes.details
    },
    dimensionFilterType: {
      value: "dashboard",
      status: "valid", //> C.valid
      message: "",
      stepTag: "details", //> C.stepTagTypes.details
    },
    timeFilterType: {
      value: "dashboard",
      status: "valid", //> C.valid
      message: "",
      stepTag: "details", //> C.stepTagTypes.details
    },
    rollingDateType: {
      value: "yes",
      status: "valid", //> C.valid,
      message: "",
      stepTag: "details", //> C.stepTagTypes.details
    },
    timeFilters: {
      // value: {},
      value: user.timeFilters,
      message: "",
      status: "valid", //> C.valid
      stepTag: "details", //> C.stepTagTypes.details
    },
    dimensionFilters: {
      value: [],
      message: "",
      status: "valid", //> C.valid
      stepTag: "details", //> C.stepTagTypes.details
    },
    selectedCharts: {
      value: [],
      status: "valid", //> C.valid
      message: "",
      stepTag: "charts", //> C.stepTagTypes.charts
    },
    kpis: {
      value: [],
      status: "valid", //> C.valid
      message: "",
      stepTag: "kpis", //> C.stepTagTypes.charts
    },
    containerInfo: {
      value: {},
      status: "valid",
      message: "",
      stepTag: "",
    },
    type: { value: "create", status: "valid", message: "", stepTag: "" },
    actualPayload: { value: {}, status: "valid", message: "", stepTag: "" },
    gridLayout: {
      value: [],
      status: "valid",
      message: "",
      stepTag: "",
    },
    layoutEditable: {
      value: false,
      status: "valid",
      message: "",
      stepTag: "",
    },
  };
};

const makeMetricsDataConsistent = (response = [{}], plotlyMetrics = []) => {
  // * Function Usage and Need
  // getData call for metrics returns a structure like below
  // const sampleRes = {
  //   status: {
  //     statusCode: "200",
  //     statusMessage: "Success",
  //   },
  //   result: {
  //     data: [
  //       {
  //         AllRequests: "1483736374959",
  //         MarketImpressions: "7900944021",
  //         CM012: "29191910.766606",
  //         CM016: "1.796313293036303",
  //       },
  //     ],
  //   },
  // };
  // It has a combination of _id and measureID which makes it difficult in the UI
  // This functions makes the response uniform and converts array to object for easier access to values
  // Input:
  // response = [
  //   {
  //     AllRequests: "1483736374959",
  //     MarketImpressions: "7900944021",
  //     CM012: "29191910.766606",
  //     CM016: "1.796313293036303",
  //   },
  // ];
  // Output:
  // finalRes = {
  //   M001: {
  //     value: "1496709770448",
  //     displayValue: "1.5 t",
  //   },
  //   M004: {
  //     value: "7967963255",
  //     displayValue: "7.97 b",
  //   },
  //   CM012: {
  //     value: "29350469.43151",
  //     displayValue: "29.35 m",
  //   },
  //   CM016: {
  //     value: "1.7970708331606129",
  //     displayValue: "$1.8 ",
  //   },
  // };
  let finalRes = {};
  const responseFirstEle = response[0];
  let status = "success";
  Object.keys(responseFirstEle).forEach((key) => {
    try {
      if (key.startsWith("CM")) {
        let metricObj = getMeasureObjByOriginalID(key, plotlyMetrics);
        let value = responseFirstEle[key];
        let displayValue = appendMetricSym(value, metricObj.dataUnit, true);
        finalRes[key] = { value, displayValue };
      } else {
        // get original id from measureId
        // get M001 from "AllRequests"
        let reqKey = getOriginalIdFromMeasureId(key, plotlyMetrics);
        let metricObj = getMeasureObjByOriginalID(reqKey, plotlyMetrics);
        let value = responseFirstEle[key];
        let displayValue = appendMetricSym(value, metricObj.dataUnit, true);
        finalRes[reqKey] = { value, displayValue };
      }
    } catch (error) {
      console.error("UI ERROR");
      console.groupCollapsed("DETAILS");
      console.log(error);
      console.groupEnd();
      status = "error";
      finalRes[key] = { value: 0, displayValue: "0" };
    }
  });
  return { response: finalRes, status };
};

const unwrapperChartsPopoverData = (props = {}) => {
  const { dsForm = {}, savedCharts = {}, allData = {}, user = {} } = props;
  let selectedChartsIds = Object.keys(dsForm.selectedCharts.value);
  // 1. Remove pivotx entries
  let chartsDataResult = savedCharts.data.filter(
    (row) => row.chartObject.metadata.chartType !== "pivotx"
  );
  // 2. Filter out corrupt objects
  chartsDataResult = chartsDataResult.map((row) => ({
    ...row,
    chartObjectUI: unwrapperChartObject({
      allData: allData,
      user: user,
      payload: row,
    }),
  }));
  chartsDataResult = chartsDataResult.filter((row) => row.chartObjectUI.valid);
  // 3. Convert into standard data structure (id, name, disabled, metadata)
  chartsDataResult = chartsDataResult.map((row) => ({
    id: row._id,
    name: row.chartObject.metadata.title,
    metadata: { chartType: row.chartObject.metadata.chartType },
    disabled: false,
  }));
  // 4. Filter those charts which are already present in selected charts list (if page is create)
  chartsDataResult = chartsDataResult.filter(
    (row) => !selectedChartsIds.includes(row.id)
  );
  // 5. Chart list ordering not present in the backend // ! HARD CODED
  chartsDataResult = [...chartsDataResult].reverse();
  const chartsPopoverData = {
    result: chartsDataResult,
    status: savedCharts.status,
    message: savedCharts.message,
  };
  return chartsPopoverData;
};

const unwrapperChartsPopoverSelections = (props = {}) => {
  const { dsForm = {} } = props;
  // 1. Get selectedCharts in the form of an array
  let chartsPopoverSelections = Object.values(dsForm.selectedCharts.value);
  // 2. Convert into standard data structure (id, name, disabled, metadata)
  chartsPopoverSelections = chartsPopoverSelections.map((row) => ({
    id: row.id,
    name: row.title,
    metadata: { chartType: row.chartType },
    disabled: false,
  }));
  return chartsPopoverSelections;
};

const isAnyWidgetPresent = (ds = {}, dsForm = {}) => {
  let isAnyWidgetPresentFlag = true;
  if (ds.status === "success") {
    isAnyWidgetPresentFlag =
      [
        ...(Object.keys(dsForm.selectedKpis.value) || []),
        ...(Object.keys(dsForm.selectedCharts.value) || []),
      ].length > 0;
  }
  return isAnyWidgetPresentFlag;
};

const formatTimeFilters = (
  timeFilters,
  format = config.hardCoded.dateFormatFilterMenu
) => {
  const dateRangeFormatted = `${moment(
    timeFilters.selectedDates.startDate.nativeDateObj
  ).format(format)} - ${moment(
    timeFilters.selectedDates.endDate.nativeDateObj
  ).format(format)}`;
  const timezone = timeFilters.selectedTimezone.name;
  const finalText = `${dateRangeFormatted} in  ${timezone}`;
  return finalText;
};

const formatCompareTimeFilters = (
  timeFilters,
  format = config.hardCoded.dateFormatFilterMenu
) => {
  const dateRangeFormatted = `${moment(
    timeFilters.compareDates[0].compareSelectedDates.startDate.nativeDateObj
  ).format(format)} - ${moment(
    timeFilters.compareDates[0].compareSelectedDates.endDate.nativeDateObj
  ).format(format)}`;
  const timezone = timeFilters.selectedTimezone.name;
  const finalText = `${dateRangeFormatted} in  ${timezone}`;
  return finalText;
};

const dsFormData = {
  dimensionData: [
    {
      id: "dashboard",
      name: "Entire Dashboard",
      disabled: false,
    },
    {
      id: "widget",
      name: "Individual Widgets",
      disabled: false,
    },
  ],
  rollingData: [
    {
      id: "yes",
      name: "Yes",
      disabled: false,
    },
    {
      id: "no",
      name: "No",
      disabled: false,
    },
  ],
};

const getAllDataStatus = (allData = []) => {
  const areAllSuccess = allData.every((data) => data.status === "success");
  const isAnyError = allData.some((data) => data.status === "error");
  let allVendorDataStatus = "loading";
  if (areAllSuccess) allVendorDataStatus = "success";
  if (isAnyError) allVendorDataStatus = "error";
  return allVendorDataStatus;
};

export {
  unwrapperDatastory,
  wrapperDatastory,
  updateGridLayout,
  getInitialDsForm,
  getMetricPayloadFromDs,
  getInitialDsStepper,
  validateDsForm,
  addValidationToDsForm,
  getDefaultDsForm,
  makeMetricsDataConsistent,
  populateContainerInfoV2,
  unwrapperChartsPopoverData,
  unwrapperChartsPopoverSelections,
  isAnyWidgetPresent,
  formatTimeFilters,
  formatCompareTimeFilters,
  dsFormData,
  getAllDataStatus,
  isDatastoryValid,
};
