// Import required libraries
import React, { useEffect, useReducer } from "react";
import isEqual from "lodash.isequal";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// Import custom components
import SigviewTextField from "../../Common/SigviewTextField";
import SigviewSingleSelect from "../../Common/SigviewSingleSelect";
import SigviewTypography from "../../Common/SigviewTypography";
import SigviewRadioGroup from "../../Common/SigviewRadioGroup";
import useReducerLogger from "../../../utils/useReducerLogger";

// Import utils/data
import {
  alertFormData,
  getNewAlertCriteriaRow,
} from "../../../utils/alertUtils";

// Import styles

// Initial State of the Alert criteria component

const AlertCriteriaComponent = (props) => {
  // Destructure props
  const {
    changeCriteria,
    allMetrics,
    logicalConditionData,
    condition,
    state,
    showError,
    onChange = () => {},
  } = props;

  const { alertCriteriaMetric } = state;

  let initialState = alertCriteriaMetric.value;
  // Defining the AlertCriteria reducer
  const [alertrowState, dispatch] = useReducer(
    useReducerLogger(reducer),
    initialState
  );
  //
  useEffect(() => {
    const payload = {
      value: alertCriteriaMetric.value,
    };
    const action = {
      type: "replace",
      payload,
    };
    let flag = !isEqual(alertrowState, alertCriteriaMetric.value);
    if (flag) {
      dispatch(action);
    }
  }, [alertCriteriaMetric.value]);

  // SideEffect for new change into alertrowState
  useEffect(() => {
    // console.log("sideEffects of componet change state", alertrowState);
    let flag = !isEqual(alertrowState, alertCriteriaMetric.value);
    if (flag) {
      onChange(alertrowState);
    }
  }, [alertrowState]);

  // This funcion cannot work without  state
  // function getCondition(value, id, alertrowState) {
  //   let data = alertrowState?.find((elm) => elm.id === id);

  //   if (value === "total") {
  //     return condition.absoluteComparison[0].id;
  //   } else if (value === "percentageChange") {
  //     return condition.relativeComparison[0].id;
  //   }
  //   return condition.relativeComparison[0].id;
  // }

  // Reducer function of AlertCriteriaComponent
  function reducer(alertrowState, action) {
    const { type, payload = {} } = action;
    const { key, value, id } = payload;

    let newData;

    switch (type) {
      case "UPDATE_METRIC_ALERT":
        switch (key) {
          case "changeCriteria":
          case "metric":
          case "conditionValue":
          case "logicalCondition":
            newData = alertrowState.map((el) => {
              if (el.id === id) {
                let newEl = el;
                if (value === "percentageChange") {
                  newEl = {
                    ...el,
                    condition: {
                      ...el.condition,
                      relativeComparison: {
                        message: "",
                        value: "increasesBy",
                        status: "valid",
                      },
                    },
                  };
                } else if (value === "total") {
                  newEl = {
                    ...el,
                    condition: {
                      ...el.condition,
                      absoluteComparison: {
                        message: "",
                        value: "isLessThan",
                        status: "valid",
                      },
                    },
                  };
                }

                return {
                  ...newEl,
                  [key]: { ...newEl[key], value },
                };
              } else return el;
            });
            break;
          case "absoluteComparison":
          case "relativeComparison":
            newData = alertrowState.map((el) =>
              el.id === id
                ? {
                    ...el,
                    ["condition"]: {
                      ...el["condition"],
                      [key]: {
                        ...el.condition[key],
                        value,
                      },
                    },
                  }
                : el
            );
            break;
        }
        break;

      case "deleteRow":
        if (alertrowState.length > 1) {
          newData = alertrowState.filter((el) => el.id !== id);
        }
        break;
      case "replace":
        newData = [...value];
        break;
      case "addRow":
        newData = [...alertrowState, getNewAlertCriteriaRow()];
        break;
      default:
        newData = [...alertrowState];
        break;
    }
    return newData;
  }

  // 2. ACTION CREATORS
  const handleChangeMetric = (action) => {
    dispatch(action);
  };

  // Making styles
  const makeSigviewStyles = () => {
    const useStyles = makeStyles(() => ({
      alertManagerFormRow: {
        // marginBottom: 25,
      },
      alertManagerFormItemContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        minHeight: "40px !important",
        boxSizing: "border-box",
      },
      inputItemContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        alignItems: "center",
        flexDirection: " column",
        width: "100%",
      },
      metricCriteriaRowContainer: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        width: "100%",
      },
      metricCriteriaRow: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        flexDirection: "column",
        marginRight: "10px",
      },
      alertMeInputContainer: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        backgroundColor: "#fff",
        padding: "13px 11px 0px 11px !important",
        borderRadius: "3px",
        boxSizing: "border-box",
        border: "1px solid #edf1ff",
      },
    }));
    return useStyles;
  };

  // Destructuring variables from useStyles()
  const useSigviewStyles = makeSigviewStyles();
  let {
    alertManagerFormRow,
    alertManagerFormItemContainer,
    inputItemContainer,
    metricCriteriaRowContainer,
    metricCriteriaRow,
    alertMeInputContainer,
  } = useSigviewStyles();

  return (
    <div className={alertManagerFormRow}>
      <div className={alertManagerFormItemContainer}>
        <div className={inputItemContainer}>
          {alertrowState.map((el, index) => {
            const { id } = el;

            return (
              <div key={id} className={metricCriteriaRowContainer}>
                <div className={metricCriteriaRow}>
                  <div className={alertMeInputContainer}>
                    <Box css={{ height: "40px !important" }}>
                      <SigviewSingleSelect
                        margin={{ right: "5px", left: "5px" }}
                        value={el.changeCriteria.value}
                        data={changeCriteria}
                        maxWidth="130px"
                        minWidth="130px"
                        height="28px"
                        fontSize="11px"
                        backgroundColor="#fff"
                        border="1px solid #edf1ff"
                        customClassName={`AlertMetric${el.changeCriteria.value}-GA`}
                        onChange={(newValue) => {
                          const payload = {
                            key: "changeCriteria",
                            value: newValue,
                            id,
                          };
                          const action = {
                            type: "UPDATE_METRIC_ALERT",
                            payload,
                          };

                          handleChangeMetric(action);
                        }}
                      />
                    </Box>
                    <Box css={{ height: "40px !important" }}>
                      <SigviewSingleSelect
                        margin={{ right: "5px", left: "5px" }}
                        value={el.metric.value}
                        data={allMetrics}
                        maxWidth="120px"
                        //minWidth="140px"
                        height="28px"
                        fontSize="11px"
                        backgroundColor="#fff"
                        border="1px solid #edf1ff"
                        onChange={(newValue) => {
                          const payload = {
                            key: "metric",
                            value: newValue,
                            id,
                          };
                          const action = {
                            type: "UPDATE_METRIC_ALERT",
                            payload,
                          };
                          handleChangeMetric(action);
                        }}
                        helperText="Select Metric cannot be empty"
                        error={
                          el.metric.value === "selectMetric" && showError.flag
                        }
                      />
                    </Box>
                    {(el.changeCriteria.value === "percentageChange" && (
                      <Box css={{ height: "40px !important" }}>
                        <SigviewSingleSelect
                          margin={{ right: "5px", left: "5px" }}
                          minWidth="140px"
                          height="28px"
                          fontSize="11px"
                          backgroundColor="#fff"
                          border="1px solid #edf1ff"
                          value={el.condition.relativeComparison.value}
                          data={
                            alertFormData.alertCriteriaMetric.data.condition
                              .relativeComparison
                          }
                          maxWidth="120px"
                          onChange={(newValue) => {
                            const payload = {
                              key: "relativeComparison",
                              value: newValue,
                              id,
                            };
                            const action = {
                              type: "UPDATE_METRIC_ALERT",
                              payload,
                            };
                            handleChangeMetric(action);
                          }}
                        />
                      </Box>
                    )) || (
                      <Box css={{ height: "40px !important" }}>
                        <SigviewSingleSelect
                          margin={{ right: "5px", left: "5px" }}
                          minWidth="140px"
                          height="28px"
                          fontSize="11px"
                          backgroundColor="#fff"
                          border="1px solid #edf1ff"
                          value={el.condition.absoluteComparison.value}
                          data={
                            alertFormData.alertCriteriaMetric.data.condition
                              .absoluteComparison
                          }
                          maxWidth="120px"
                          onChange={(newValue) => {
                            const payload = {
                              key: "absoluteComparison",
                              value: newValue,
                              id,
                            };
                            const action = {
                              type: "UPDATE_METRIC_ALERT",
                              payload,
                            };
                            handleChangeMetric(action);
                          }}
                        />
                      </Box>
                    )}

                    <Box css={{ height: "40px !important" }}>
                      <SigviewTextField
                        margin={{ right: "5px", left: "5px", bottom: "5px" }}
                        fontSize="11px"
                        height="28px"
                        border="1px solid #edf1ff"
                        backgroundColor="#fff"
                        placeholder="Enter a Number"
                        type="number"
                        onChange={(newValue) => {
                          const payload = {
                            key: "conditionValue",
                            value: newValue,
                            id,
                          };
                          const action = {
                            type: "UPDATE_METRIC_ALERT",
                            payload,
                          };
                          handleChangeMetric(action);
                        }}
                        value={el.conditionValue.value}
                        maxWidth="120px"
                        minWidth="100px"
                        helperText="Threshold field cannot be empty"
                        fontSizeHelperText="10px"
                        error={!el.conditionValue.value && showError.flag}
                      />
                    </Box>

                    {/* <SigviewTextField
                      value={value}
                      onChange={(newValue) => handleValueChange(newValue)}
                      placeholder="Enter String"
                      error={showError && valueError.flag}
                      helperText={valueError.message}
                      minWidth="210px"
                      maxWidth="210px"
                      margin={{ right: "5px" }}
                    /> */}
                    <div
                      className={
                        el.changeCriteria.value === "percentageChange"
                          ? "percent-sign percentageChangeON-GA"
                          : "no-percent-sign"
                      }
                    >
                      <SigviewTypography variant="pSmallMedium">
                        %
                      </SigviewTypography>
                    </div>
                    {/* {el.triggerCriteria === "percentageChange" && (
                    <SigviewTypography variant="MultiSelectTitle">
                      %
                    </SigviewTypography>
                  )} */}

                    {alertrowState.length > 1 && (
                      <Box
                        css={{ position: "relative", top: "-7px" }}
                        onClick={(newValue) => {
                          const payload = { value: newValue, id };
                          const action = {
                            type: "deleteRow",
                            payload,
                          };
                          handleChangeMetric(action);
                        }}
                        className="material-icons-outlined delete-btn"
                      >
                        cancel
                      </Box>
                    )}
                  </div>
                  {alertrowState.length - 1 !== index && (
                    <div
                      className={`and-or-container ${
                        el.logicalCondition.value === "or" ? "or-container" : ""
                      }`}
                    >
                      <SigviewRadioGroup
                        value={el.logicalCondition.value}
                        data={logicalConditionData}
                        onChange={(newValue) => {
                          const payload = {
                            key: "logicalCondition",
                            value: newValue,
                            id,
                          };
                          const action = {
                            type: "UPDATE_METRIC_ALERT",
                            payload,
                          };
                          handleChangeMetric(action);
                        }}
                      />
                    </div>
                  )}
                </div>
                {alertrowState.length - 1 === index && (
                  <i
                    className="material-icons add-new-row-btn"
                    onClick={() => {
                      const payload = {};
                      const action = {
                        type: "addRow",
                        payload,
                      };
                      handleChangeMetric(action);
                    }}
                  >
                    add
                  </i>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AlertCriteriaComponent;
