// * Import required libraries
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import axios from "axios";

// * Import custom components
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import AnalyzeDashboard from "./AnalyzeDashboard";

// * Import utils, config & static data
import { wrapperOrgViewReq } from "../../../utils/chartObjectUtils";
import { config } from "../../../config/config";
import { getBookmarkDetails } from "../../../utils/analyzeUtils";
import { makeNewDefaultWorksheet } from "../../../utils/analyzeServiceUtils";

// * Import redux utilities
// Action creators
import {
  updateUserScreen,
  resetWorkspaceAndMsv,
  updateAnalyzeFiltersReadOnlyFromGlobalFiletrs,
} from "../../../redux/actions";
// Initial state
import { initialActiveWorkspace } from "../../../redux/stateData";

// * Import API functions
import { getAllWorkbooks } from "../../../services/api";

// * Import required hooks
import useUpdateEffect from "../../../hooks/useUpdateEffect";

// * Define required static variables
const initialData = {
  status: "loading",
  message: "",
  result: [],
};
const initialPageStatus = {
  status: "loading",
  message: "",
};

function AnalyzeDashboardContainer(props) {
  // * Destructure props
  const {
    dispatch: ReduxDispatcher = () => {},
    user = {},
    allData = {},
    changeFlag = {},
  } = props;
  const history = useHistory();
  const location = useLocation();

  // * Define required states
  const [isAnyWorkbookInRedux, setIsAnyworkbookInRedux] = useState(false);
  const [workbooks, setWorkbooks] = useState(initialData);
  const [reloadWorkbooks, setReloadWorkbooks] = useState(Date.now());
  const [reloadWorksheet, setReloadWorksheet] = useState(Date.now());
  const [pageStatus, setPageStatus] = useState(initialPageStatus);

  // * Define required side effects
  useEffect(() => {
    return () => {
      // Revert to inital state in redux when component unmounts
      // This is to cater to case when user switches between tabs

      const action = updateUserScreen(
        "activeWorkspace",
        initialActiveWorkspace
      );
      ReduxDispatcher(action);
      ReduxDispatcher(updateAnalyzeFiltersReadOnlyFromGlobalFiletrs());
      ReduxDispatcher(resetWorkspaceAndMsv());
    };
  }, []);

  useEffect(() => {
    //Make fetch call using axios
    const source = axios.CancelToken.source();

    let runFlag = !user.screen.isDashboardLoading;
    if (runFlag) {
      const fetchProps = {
        payload: {
          orgViewReq: wrapperOrgViewReq(user),
          email: user.reqMetadata?.email,
        },
        cancelToken: source.token,
      };
      const getAllWorkbooksPromise = getAllWorkbooks(fetchProps);
      getAllWorkbooksPromise
        .then((responseData) => {
          const newData = {
            status: "success",
            message: "",
            result: { workbookList: responseData.result.data },
          };
          setWorkbooks(newData);
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            console.groupCollapsed("UI ERROR");
            console.log("JSON -> ", json);
            console.log("ERROR -> ", json.error);
            console.groupEnd();

            const newData = {
              status: "error",
              message: "",
              result: json.error || config.messages.uiErrorMessage,
            };
            setWorkbooks(newData);
          }
        });
    }

    //Clean-up function to cancel all pending fetch calls
    return () => {
      //Cancel all previous fetch calls
      if (source) source.cancel();
      runFlag = false;
    };
  }, [user.screen.isDashboardLoading, reloadWorkbooks]);

  useEffect(() => {
    let runFlag = !user.screen.isDashboardLoading;
    if (runFlag) {
      // * Catering to the case where dashboard is a shared one
      // 1. isWsCategoryShared comes from AnalyzeDashboardAngularShareCompatible.js
      // 2. isSharedUrlFlag is for react shared URL
      const isWsCategoryShared =
        user.screen.activeWorkspace.wsCategory === "shared";
      const { isSharedUrlFlag, bookmarkId } = getBookmarkDetails(location);
      const isDashboardShared = isWsCategoryShared || isSharedUrlFlag;

      // * There are 2 cases here:
      // 1. Shared Dashboard
      // 2. Non-Shared Dashboard
      //    2.1 Saved Dashboard Load (from workspace drawer or home page if that's built later)
      //    2.2 Initial Dashboard Load

      if (isDashboardShared) {
        // * Do 2 things here
        // * These to be done one after the other, hence using callback queue (Async JS)
        // 1. Set user.screen.activeWorkspace.wsCategory to shared
        // 2. Set setIsAnyworkbookInRedux to true

        // 1. Set user.screen.activeWorkspace.wsCategory to shared
        const actionValue = {
          activeWorkspace: {
            wsCategory: "shared",
            crudType: "create",
            payload: {
              worksheet: { sheetName: "Untitled Workspace" },
              bookmarkId: bookmarkId,
            },
          },
        };
        var action = updateUserScreen(null, actionValue);
        ReduxDispatcher(action);

        // 2. Set setIsAnyworkbookInRedux to true

        setIsAnyworkbookInRedux(true);
      } else {
        // Run this only when there is no workbook in redux
        // Since this useEffect has a dependency on workbooks.status, it will run again when reloading the workbooks
        // In that case we need not run this case
        // This case is ONLY for initial application load
        const initialLoadRunFlag =
          user.screen.activeWorkspace.wsCategory === "initial" &&
          !isAnyWorkbookInRedux &&
          !user.screen.isDashboardLoading;

        // Catering to the case where wsCategory is saved (coming from analyze home page)
        if (user.screen.activeWorkspace.wsCategory === "saved") {
          setIsAnyworkbookInRedux(true);
        } else if (initialLoadRunFlag) {
          // This flag will help us decide if we need to load default template
          var defaultTemplateFlag = false;
          var workspaceLoadErrorMessage = "";

          if (workbooks.status === "success") {
            var activeWorkbook = workbooks.result.workbookList.find(
              (row) => row.isActive
            );
            if (!activeWorkbook) {
              // NO ACTIVE WORKBOOK
              // So take first element as the active one
              activeWorkbook = workbooks.result.workbookList[0];
            }
            // If no elements in the activeWorkbook
            if (activeWorkbook) {
              var activeSheet = activeWorkbook.worksheetList.find(
                (row) => row.isActive
              );
              // NO ACTIVE WORKSHEET
              // So take first element as the active one
              if (!activeSheet) {
                activeSheet = activeWorkbook.worksheetList[0];
              }
              // If no elements in the activeWorkbook
              if (activeSheet) {
                const activeWorkspacePayload = {
                  workbook: { ...activeWorkbook },
                  worksheet: { ...activeSheet },
                };
                const actionValue = {
                  activeWorkspace: {
                    wsCategory: "saved",
                    crudType: "update",
                    payload: activeWorkspacePayload,
                  },
                };
                var action = updateUserScreen(null, actionValue);
                ReduxDispatcher(action);

                setIsAnyworkbookInRedux(true);

                const bookmarkIdQueryString = location.search;
                const newUrl = `/analyze/dashboard${bookmarkIdQueryString}`;
                history.push(newUrl);
              } else {
                defaultTemplateFlag = true;
                workspaceLoadErrorMessage = "No worksheet";
              }
            } else {
              defaultTemplateFlag = true;
              workspaceLoadErrorMessage = "No workbook";
            }
          } else if (workbooks.status === "error") {
            defaultTemplateFlag = true;
            workspaceLoadErrorMessage = "Workbook API failed";
          }

          // LOAD DEFAULT TEMPLATE
          if (defaultTemplateFlag) {
            // Update snackbar
            var snackbarPayload = {
              ...user.screen.snackbar,
              open: true,
              message: "Loading default profile!",
            };
            var action = updateUserScreen("snackbar", snackbarPayload);
            ReduxDispatcher(action);

            // Call Function to make default worksheet
            const makeNewDefaultWorksheetProps = {
              user,
              allData,
              activeWorkbook,
              ReduxDispatcher,
              setIsAnyworkbookInRedux,
              setPageStatus,
              setReloadWorkbooks,
            };

            // CASE 1
            // 1. getGroupDefaultWorksheetPromise requires workbook
            // Else show an error message on the UI
            const canGetGroupDefaultWorksheetPromiseBeCalled = ![
              "Workbook API failed",
              "No workbook",
            ].includes(workspaceLoadErrorMessage);
            if (canGetGroupDefaultWorksheetPromiseBeCalled) {
              makeNewDefaultWorksheet(makeNewDefaultWorksheetProps);
            } else {
              setPageStatus({
                status: "error",
                message:
                  "No workbook could be loaded successfully! Please try again",
              });
            }
          }
        }
      }
    }
    //Clean-up function to cancel all pending fetch calls
    return () => {
      runFlag = false;
    };
  }, [
    // user.screen.isDashboardLoading,
    workbooks.status,
    // user.screen.activeWorkspace.wsCategory, // ! INCORRECT : Re-trigger if user changes the active worksheet from AllWorkbooksDrawer
    reloadWorksheet, // Re-trigger if user changes the active worksheet from AllWorkbooksDrawer
  ]);

  useEffect(() => {
    let runFlag =
      user.timeFilters.isLoading === false &&
      allData.plotlyMetrics !== null &&
      allData.plotlyDimensions !== null &&
      isAnyWorkbookInRedux;
    if (runFlag) {
      setPageStatus({
        status: "success",
        message: "",
      });
    } else {
      setPageStatus({
        status: "loading",
        message: "",
      });
    }
    //Clean-up function to cancel all pending fetch calls
    return () => {
      runFlag = false;
    };
  }, [
    user.timeFilters.isLoading,
    allData.plotlyMetrics,
    allData.plotlyDimensions,
    isAnyWorkbookInRedux,
  ]);

  // reset to initialState if changeFlag.view changes
  // to cater to view/org change
  useUpdateEffect(() => {
    setIsAnyworkbookInRedux(false);
    setPageStatus(initialPageStatus);
    setWorkbooks(initialData);
  }, [changeFlag.view]);

  // * Define required static variables
  // const isWsReadyToLoad =
  //   user.timeFilters.isLoading === false &&
  //   allData.plotlyMetrics !== null &&
  //   allData.plotlyDimensions !== null &&
  //   isAnyWorkbookInRedux;
  const workbookProps = {
    workbooks,
    setReloadWorkbooks,
    setIsAnyworkbookInRedux,
    setReloadWorksheet,
    setPageStatus,
  };

  // * DEBUGGER
  // console.group("AnalyzeDashboardContainer.js");
  // console.log("pageStatus", pageStatus);
  // console.groupEnd();

  return (
    <>
      {pageStatus.status === "loading" && (
        <LayoutTopSideBottom>
          <Loader />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "error" && (
        <LayoutTopSideBottom>
          <ErrorHandler
            message={pageStatus.message}
            reloadFlag={true}
            onReload={() => {
              window.reload();
            }}
          />
        </LayoutTopSideBottom>
      )}
      {pageStatus.status === "success" && (
        <AnalyzeDashboard workbookProps={workbookProps} />
      )}
    </>
  );
}

AnalyzeDashboardContainer.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  changeFlag: state.changeFlag,
});

export default connect(mapStateToProps)(AnalyzeDashboardContainer);
