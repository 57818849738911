import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import AppMenuBar from "../../components/AppMenuBar";
import SigviewEnhancedTable from "../../components/SigviewEnhancedTable";
import { updateUserScreen } from "../../redux/actions";

import {
  unWrapperBucketBody,
  unWrapperBucketHeader,
} from "../../utils/bucketUtils";

function ManageBuckets(props) {
  const {
    dispatch: ReduxDispatcher,
    bucketList = [],
    handleClickRow = () => {},
  } = props;

  const [searchField, setSearchField] = useState("");

  let modifiedbucketlist = bucketList.result || [];

  const handleregisterBucket = () => {
    var action = updateUserScreen("bucketScreen", {
      type: "registerBucket",
      payload: {},
    });
    ReduxDispatcher(action);
  };

  const buttonProps = {
    handleClick: handleregisterBucket,
    title: "Register Storage",
  };
  const handleSearchFieldChange = (event, value) => setSearchField(value);

  const appMenuBarProps = {
    buttonProps,
    onSearchFieldChange: handleSearchFieldChange,
  };

  const filteredData = modifiedbucketlist.filter((el) =>
    el.bucketName.toLowerCase().includes(searchField.toLowerCase())
  );
  return (
    <>
      <AppMenuBar {...appMenuBarProps} />
      {modifiedbucketlist.length > 0 && (
        <Box css={{ padding: "0px 25px" }}>
          <SigviewEnhancedTable
            handleClickRow={handleClickRow}
            headCells={unWrapperBucketHeader(modifiedbucketlist)}
            rows={filteredData}
          />
        </Box>
      )}
      {modifiedbucketlist.length === 0 && (
        <Box css={{ height: "calc(100% - 30px)" }}>
          <div className="no-data-container">
            <div className="no-data-image"></div>
            <p className="no-data">No Storage available</p>
          </div>
        </Box>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(ManageBuckets);
