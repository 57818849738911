// * Import lib
import omit from "lodash.omit";
import isEqual from "lodash.isequal";

// * Import utils
import C from "../constants";
import { config } from "../../config/config";
import {
  getDimensionObjByOriginalID,
  getMeasureObjByOriginalID,
} from "../../utils/plotlyUtils";
import { checkChartTypeValidity } from "../../utils/standaloneChartUtils";
import { getChartObjectUiPlaceHolder } from "../../utils/chartObjectUtils";
import {
  getValidMetricGranDataBasedOnDimListAndAccessList,
  manipulateAvailableMetricGranDataBasedOnSelectedDatesAndLimitList,
  getValidMetricChartGranularityBasedOnValidMetricGranData,
} from "../../utils/granularityUtils";
import { wrapperMsvFilters } from "../../utils/msvUtils";
import {
  manipulateOrderByDetailsForDimensionTables,
  makeAllValidMetricCalendarKeys,
  manipulateDataQEBasedOnTimeFilters,
  manipulateDataQEBasedOnMsvTableRowClick,
  manipulateMetricChartTypeBasedOnBusinessLogic,
  resetAllDataQE,
  resetAllMetricChartsDataQE,
  resetAllMetricsOnlyDataQE,
  manipulateStateBasedOnTimeFiltersChange,
  manipulateMetricFiltersBasedOnTimeFiltersChange,
  manipulateMsvColorMapping,
  manipulateRegexCheckAllBasedOnDataQe,
} from "../../utils/standaloneWsUtils";
import SIGVIEW_CONTANTS from "../../constants/sigviewConstants";
import { initialStandaloneWs } from "../stateData";

// * Define required variables
const defaultQeData = SIGVIEW_CONTANTS.defaultQeData;
const defaultKpiData = SIGVIEW_CONTANTS.defaultKpiData;

function standaloneWs(state, action) {
  const { type = "", payload = {} } = action;
  const {
    key = "",
    value = "",
    dimId = "",
    dimData = {},
    metricsChartsDataFromQE = {},
    metricsChartsDataFromQEStatus = "success",
    metricsDataFromQE = {},
    metricsDataFromQEStatus = "success",
    selections = [],
    plotlyDimensions = [],
    plotlyMetrics = [],
    metricId = "",
    flag = false,
    deleteItemId = "",
    newSelectedTableMetrics = [],
    oldSelectedTableMetrics = [],
    msvForm = {},
    wsFormMsvValue = {},
  } = payload;

  let newState = {};
  let newDataFromQEVal = {};
  switch (type) {
    case C.UPDATE_STANDALONE_WS_FORM:
      switch (key) {
        case "name":
        case "rollingDateType":
        case "selectedDimensions":
        case "orderById":
        case "orderBy":
        case "orderByType":
        case "dimTablesGridViewType":
        case "selectedKpis":
        case "metricChartType":
        case "containerInfo":
        case "layout":
        case "dataQE":
        case "reloadEpochs":
        case "type":
        case "actualPayload":
        case "globalSort":
        case "msv":
        case "selectedTableItemInChart":
        case "screen":
        case "dataLimit":
        case "autosaveApiStatus":
        case "regexSearchCheckAll":
          newState = {
            ...state,
            [key]: { ...state[key], value },
          };
          break;
        case "regexSearchField":
          newState = {
            ...state,
            [key]: { ...state[key], value },
            regexSearchCheckAll: {
              ...state["regexSearchCheckAll"],
              value:
                value.length === 0 ? false : state["regexSearchCheckAll"].value,
            },
          };
          break;
        case "allSelectedTableItem":
          let allCurrentVal = state[key].value;
          let allSelectedTableItemVal = allCurrentVal.some(
            (el) => el === value
          );

          if (allSelectedTableItemVal) {
            newState = {
              ...state,
              [key]: {
                ...state[key],
                value: allCurrentVal.filter((el) => el !== value),
              },
            };
          } else {
            newState = {
              ...state,
              [key]: { ...state[key], value: allCurrentVal.concat(value) },
            };
          }
          break;
          // case "allSelectedTableItemValue":
          //   newState = {
          //     ...state,
          //     allSelectedTableItem: { ...state.allSelectedTableItem, value },
          //   };
          //   break;
          // case "selectedTableItemValue":
          //   newState = {
          //     ...state,
          //     selectedTableItem: { ...state.selectedTableItem, value },
          //   };
          break;
        case "selectedTableItemInChart":
          let currentVal = state[key].value;
          let selectedTableItemVal = currentVal.some((el) => el === value);

          if (selectedTableItemVal) {
            newState = {
              ...state,
              [key]: {
                ...state[key],
                value: currentVal.filter((el) => el !== value),
              },
            };
          } else {
            if (state.allSelectedTableItem.value.includes(value)) {
              newState = { ...state };
            } else if (state[key].value.length < 5) {
              newState = {
                ...state,
                [key]: { ...state[key], value: currentVal.concat(value) },
              };
            } else {
              currentVal.shift();

              newState = {
                ...state,
                [key]: { ...state[key], value: currentVal.concat(value) },
              };
            }
          }
          break;
        // * BUSINESS LOGIC
        case "timeFilters":
          // 1. Change TimeFilters value
          newState = {
            ...state,
            [key]: { ...state[key], value },
          };

          // 2. If dateRange is custom, reset rolling dates to no; else yes
          // if (value.selectedDatePreset === "custom") {
          //   newState = {
          //     ...newState,
          //     rollingDateType: {
          //       ...newState.rollingDateType,
          //       value: "no",
          //     },
          //   };
          // } else {
          //   newState = {
          //     ...newState,
          //     rollingDateType: {
          //       ...newState.rollingDateType,
          //       value: "yes",
          //     },
          //   };
          // }
          // 3. Manipulate DataQE
          newState = manipulateStateBasedOnTimeFiltersChange(newState, state);

          // ! OLD IMPLEMENTATION (BEFORE getData calls optimization in MSV)
          // if (state.isItMsvForm.value !== true) {
          //   // 3. Manipulate DataQE
          //   newState = manipulateStateBasedOnTimeFiltersChange(newState, state);
          // } else {
          //   // TODO : Change
          // }

          // 4. Manipulate metricChartGranularity
          var metricGranDataVal =
            getValidMetricGranDataBasedOnDimListAndAccessList({
              plotlyDimensions: newState.utils.value.plotlyDimensions,
              uiFeatureList: newState.utils.value.uiFeatureList,
            });
          var selectedDatesProps = {
            startDate: value.selectedDates.startDate.epoch,
            endDate: value.selectedDates.endDate.epoch,
          };
          var selectedTimezone = {
            location:
              newState.timeFilters.value.selectedDates.startDate.timezone
                .location,
            name: newState.timeFilters.value.selectedDates.startDate.timezone
              .name,
            value:
              newState.timeFilters.value.selectedDates.startDate.timezone.value,
          };
          metricGranDataVal =
            manipulateAvailableMetricGranDataBasedOnSelectedDatesAndLimitList({
              metricGranData: metricGranDataVal,
              uiLimitsList: newState.utils.uiLimitsList,
              selectedDates: selectedDatesProps,
              selectedTimezone: selectedTimezone,
            });
          var metricChartGranularityVal =
            getValidMetricChartGranularityBasedOnValidMetricGranData({
              metricGranData: metricGranDataVal,
              metricChartGranularity: newState.metricChartGranularity.value,
            });
          newState = {
            ...newState,
            metricGranData: {
              ...newState.metricGranData,
              value: metricGranDataVal,
            },
            metricChartGranularity: {
              ...newState.metricChartGranularity,
              value: metricChartGranularityVal,
            },
          };

          // 5. Manipulate metric filters based on comparison on off state
          newState = manipulateMetricFiltersBasedOnTimeFiltersChange(newState);

          break;
        case "dimensionFilters":
          newState = {
            ...state,
            [key]: { ...state[key], value },
          };
          newState = resetAllDataQE(newState);
          break;
        case "metricChartGranularity":
          newState = {
            ...state,
            [key]: { ...state[key], value },
          };
          newState = resetAllMetricChartsDataQE(newState);
          break;
        default:
          console.groupCollapsed("UI ERROR");
          console.log("UNIDENTIFIED TYPE");
          console.log("REDUCER -> ", "standaloneWs.js");
          console.log("state -> ", state);
          console.log("payload -> ", payload);
          console.groupEnd();
          newState = { ...state };
      }
      break;

    case C.REPLACE_STANDALONE_WS_FORM:
      newState = { ...value };
      break;
    case C.UPDATE_STANDALONE_WS_FORM_METRICS_CHARTS_HOVER:
      newState = {
        ...state,
        metricChartHover: { ...payload },
      };
      break;

    case C.UPDATE_STANDALONE_WS_MSV_TABLE_ROW_CLICKED:
      newState = { ...state };

      // 1. Update "selectedTableItemInChart"
      let currentVal = newState["selectedTableItemInChart"].value;
      let selectedTableItemVal = currentVal.some((el) => el === value);

      if (selectedTableItemVal) {
        newState = {
          ...newState,
          selectedTableItemInChart: {
            ...newState["selectedTableItemInChart"],
            value: currentVal.filter((el) => el !== value),
          },
        };
        let allSelectedTableItemWithoutSelectedTableItemInChart = newState[
          "allSelectedTableItem"
        ].value.filter(
          (row) => !newState["selectedTableItemInChart"].value.includes(row)
        );
        if (
          newState["selectedTableItemInChart"].value.length < 5 &&
          allSelectedTableItemWithoutSelectedTableItemInChart.length > 1
        ) {
          newState = {
            ...newState,
            selectedTableItemInChart: {
              ...newState["selectedTableItemInChart"],
              value: [
                ...newState["selectedTableItemInChart"].value,
                allSelectedTableItemWithoutSelectedTableItemInChart[0],
              ],
            },
          };
        }
      } else {
        if (newState.allSelectedTableItem.value.includes(value)) {
          newState = { ...newState };
        } else if (newState["selectedTableItemInChart"].value.length < 5) {
          newState = {
            ...newState,
            selectedTableItemInChart: {
              ...newState["selectedTableItemInChart"],
              value: currentVal.concat(value),
            },
          };
        } else {
          currentVal.shift();

          newState = {
            ...newState,
            selectedTableItemInChart: {
              ...newState["selectedTableItemInChart"],
              value: currentVal.concat(value),
            },
          };
        }
      }

      // 2. Update "allSelectedTableItem"
      let allCurrentVal = newState["allSelectedTableItem"].value;
      let allSelectedTableItemVal = allCurrentVal.some((el) => el === value);

      if (allSelectedTableItemVal) {
        newState = {
          ...newState,
          allSelectedTableItem: {
            ...newState["allSelectedTableItem"],
            value: allCurrentVal.filter((el) => el !== value),
          },
        };
      } else {
        newState = {
          ...newState,
          allSelectedTableItem: {
            ...newState["allSelectedTableItem"],
            value: allCurrentVal.concat(value),
          },
        };
      }

      // 3. Update dimensionFilters
      var newDimensionFiltersVal = [...newState.dimensionFilters.value];
      let currentMsvFilter = newDimensionFiltersVal.filter(
        (el) => el.id === newState.activeMsvTableId.value
      );
      let remainingMsvFilter = newDimensionFiltersVal.filter(
        (el) => el.id !== newState.activeMsvTableId.value
      );
      let modifiedCurrentMsvFilter = { ...currentMsvFilter[0] };
      let finalMsvfilter;

      // Case 1: Filter Row Available in dimensionFilters
      //    Sub-Case 1: Remove Filter Value
      //    Sub-Case 2: Add Filter Value
      if (currentMsvFilter.length > 0) {
        if (modifiedCurrentMsvFilter.values.includes(value)) {
          modifiedCurrentMsvFilter.values =
            modifiedCurrentMsvFilter.values.filter((el) => el !== value);

          if (modifiedCurrentMsvFilter.values.length === 0) {
            finalMsvfilter = remainingMsvFilter;

            newState = {
              ...newState,
              dimensionFilters: {
                ...newState.dimensionFilters,
                value: finalMsvfilter,
              },
            };

            newState = resetAllDataQE(newState);
            break;
          } else {
            finalMsvfilter = remainingMsvFilter.concat(
              modifiedCurrentMsvFilter
            );
          }
        } else {
          modifiedCurrentMsvFilter.values.push(value);
          finalMsvfilter = remainingMsvFilter.concat(modifiedCurrentMsvFilter);
        }
      } else if (currentMsvFilter.length === 0 && value.length > 0) {
        // Case 2: Filter Row NOT Available in dimensionFilters
        //    Sub-Case 1: Remove Filter Value
        //    Sub-Case 2: Add Filter Value
        // Checks
        // 1. Add a check if the values array is empty for any dimRow in newDimensionFiltersVal, remove it
        let newReqFilterInfo =
          newState.selectedDimensions.value[newState.activeMsvTableId.value];

        let dimObj = newReqFilterInfo.dimensionsList[0];

        let newFilter = {
          id: dimObj._id,
          values: [value],
          metadata: dimObj,
          filterType: "include",
          advancedFilters: [],
          valid: true,
        };

        finalMsvfilter = remainingMsvFilter.concat(newFilter);
      }

      // 4. Reset all metrics data
      newState = resetAllMetricsOnlyDataQE(newState);

      // 5. Keep only valid metric keys
      newState = manipulateDataQEBasedOnMsvTableRowClick(newState);

      // 6. Update reloadEpoch for allMetricCharts to trigger API call
      var reloadEpochsVal = {
        ...newState.reloadEpochs.value,
        allMetricCharts: Date.now(),
      };

      // 7. Update msvColorMapping
      newState = manipulateMsvColorMapping(newState);

      newState = {
        ...newState,
        dimensionFilters: {
          ...newState.dimensionFilters,
          value: finalMsvfilter,
        },
        reloadEpochs: {
          ...newState.reloadEpochs,
          value: reloadEpochsVal,
        },
      };

      break;

    case C.UPDATE_STANDALONE_WS_FORM_CHECK_ALL:
      newState = { ...state };

      // NOTE : Value represents if the it checked or unchecked

      // If checked
      if (value) {
        // Define required variables
        var activeMsvTableId = state.activeMsvTableId.value;
        var activeMsvTableIdObj =
          state.selectedDimensions.value[activeMsvTableId].dimensionsList[0];
        var accessor = activeMsvTableIdObj["dimID"]; // ! hard coded
        var allDataFromQE =
          newState.dataQE.value[activeMsvTableId].result.dataFromQE;
        var allDimValues = allDataFromQE.map((row) => row[accessor]);
        var lastFiveValues = allDimValues.slice(
          Math.max(allDimValues.length - 5, 0)
        );

        // 1. Update selectedTableItemInChart & allSelectedTableItem
        newState = {
          ...newState,
          selectedTableItemInChart: {
            ...newState.selectedTableItemInChart,
            value: lastFiveValues,
          },
          allSelectedTableItem: {
            ...newState.allSelectedTableItem,
            value: allDimValues,
          },
        };

        // 2. Update dimensionFilters
        var newDimensionFiltersVal = [...newState.dimensionFilters.value];
        let remainingMsvFilter = newDimensionFiltersVal.filter(
          (el) => el.id !== activeMsvTableId
        );
        var filterDimObject =
          state.selectedDimensions.value[activeMsvTableId].dimensionsList[0];
        var newMsvFilterItem = {
          id: activeMsvTableId,
          values: allDimValues,
          metadata: filterDimObject,
          filterType: "include",
          advancedFilters: [],
          valid: true,
        };
        let finalMsvfilter = [...remainingMsvFilter, newMsvFilterItem];

        // 4. Reset all metrics data
        newState = resetAllMetricsOnlyDataQE(newState);

        // 5. Keep only valid metric keys
        newState = manipulateDataQEBasedOnMsvTableRowClick(newState);

        // 6. Update reloadEpoch for allMetricCharts to trigger API call
        var reloadEpochsVal = {
          ...newState.reloadEpochs.value,
          allMetricCharts: Date.now(),
        };

        // 7. Update msvColorMapping
        newState = manipulateMsvColorMapping(newState);

        newState = {
          ...newState,
          dimensionFilters: {
            ...newState.dimensionFilters,
            value: finalMsvfilter,
          },
          reloadEpochs: {
            ...newState.reloadEpochs,
            value: reloadEpochsVal,
          },
          regexSearchCheckAll: {
            ...newState.regexSearchCheckAll,
            value: true,
          },
        };
      } else {
        // If unchecked
        // 1. Update the below fields:
        //    a. selectedTableItemInChart to []
        //    b. allSelectedTableItem to []
        //    c. msvColorMapping to {}
        //    d. dimensionFilters to all values except the msv table id
        var newDimensionFiltersVal = [...newState.dimensionFilters.value];
        let remainingMsvFilter = newDimensionFiltersVal.filter(
          (el) => el.id !== newState.activeMsvTableId.value
        );

        newState = {
          ...newState,
          selectedTableItemInChart: {
            ...newState.selectedTableItemInChart,
            value: [],
          },
          allSelectedTableItem: {
            ...newState.allSelectedTableItem,
            value: [],
          },
          msvColorMapping: {
            ...newState.msvColorMapping,
            value: {},
          },
          dimensionFilters: {
            ...newState.dimensionFilters,
            value: remainingMsvFilter,
          },
          regexSearchCheckAll: {
            ...newState.regexSearchCheckAll,
            value: false,
          },
        };

        // 2. Reset all metrics data
        newState = resetAllMetricsOnlyDataQE(newState);

        // 3. Keep only valid metric keys
        newState = manipulateDataQEBasedOnMsvTableRowClick(newState);

        // 4. Update reloadEpoch for allMetricCharts to trigger API call
        var reloadEpochsVal = {
          ...newState.reloadEpochs.value,
          allMetricCharts: Date.now(),
        };

        newState = {
          ...newState,
          reloadEpochs: {
            ...newState.reloadEpochs,
            value: reloadEpochsVal,
          },
        };
      }

      break;

    case C.UPDATE_STANDALONE_WS_DATA_SORT:
      // Data Sort Change is based on Global Sort
      const currGlobalSort = state?.globalSort?.value; // either true or false
      var newSelectedDimensionsVal = {};
      var sortType = value.sortType;
      var sortTypeId = value.sortTypeId;
      var sortTypeValue = value.sortTypeValue;
      var sortDimId = value.dimId;
      if (currGlobalSort) {
        // If true, traverse inside each selectedDimensions
        // check if the id is _dimension, if it is _dimension, it we need to change order by to that for all
        // if it is not _dimension, we need to check if the metric exists in the metrics list, if it does, change order by
        // else let it be
        // orderBy (asc/desc) to be changed only when the sorting is on dimension or when the sorting is on a metric which is in it's metrics list
        Object.values(state.selectedDimensions.value).forEach(
          (dimensionRow) => {
            const currDimId = dimensionRow.id;
            const metricsListIds = dimensionRow.metricsList.map(
              (row) => row._id
            );
            const isSortByMetricIdAvailableInMetricsList =
              metricsListIds.includes(sortType.id);
            const isSortByOnDimension = sortType.id === "_dimension";
            const orderById = isSortByOnDimension
              ? "_dimension"
              : isSortByMetricIdAvailableInMetricsList
              ? sortType.id
              : dimensionRow.orderById;
            const orderBy =
              isSortByMetricIdAvailableInMetricsList || isSortByOnDimension
                ? sortTypeValue
                : dimensionRow.orderBy;
            const newDimensionRow = {
              ...dimensionRow,
              orderBy,
              orderById,
              orderByType: sortTypeId.id, // TODO: Compare on case to be handled
            };
            newSelectedDimensionsVal[currDimId] = newDimensionRow;
          }
        );
      } else {
        // If global sort is false, it means we need to update only THAT selectedDimension in our selectedDimensions
        newSelectedDimensionsVal = { ...state.selectedDimensions.value };
        var currDimensionRow = state.selectedDimensions.value[sortDimId];
        newSelectedDimensionsVal[sortDimId] = {
          ...currDimensionRow,
          orderBy: sortTypeValue,
          orderById: sortType.id,
          orderByType: sortTypeId.id, // TODO: Compare on case to be handled
        };
      }
      newState = {
        ...state,
        orderBy: { ...state.orderBy, value: value.sortTypeValue },
        orderById: {
          ...state.orderById,
          value: value.sortType.id,
        },
        orderByType: { ...state.orderByType, value: value.sortTypeId.id },
        selectedDimensions: {
          ...state.selectedDimensions,
          value: newSelectedDimensionsVal,
        },
      };
      break;

    case C.UPDATE_WS_FORM_DIMENSION_TABLE_DATA:
      // Do this only when the widget exists
      // In case it's deleted do nothing
      if (state.dataQE.value[dimId]) {
        newDataFromQEVal = {
          ...state.dataQE.value,
          [dimId]: {
            ...dimData,
          },
        };
        newState = {
          ...state,
          dataQE: {
            ...state.dataQE,
            value: newDataFromQEVal,
          },
        };
      } else {
        newState = { ...state };
      }
      break;

    case C.UPDATE_STANDALONE_WS_FORM_METRICS_CHARTS_DATA:
      newDataFromQEVal = { ...state.dataQE.value };
      var allMetricIds = Object.keys(state.selectedKpis.value);
      if (metricsChartsDataFromQEStatus === "success") {
        Object.keys(metricsChartsDataFromQE).forEach((metricId) => {
          let metricIdOnly = metricId.match(/[CM]{1,2}[0-9]{3}/)[0];
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (allMetricIds.includes(metricIdOnly)) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricId]: {
                status: "success",
                message: "",
                result: metricsChartsDataFromQE[metricId],
              },
            };
          }
        });
      } else if (metricsChartsDataFromQEStatus === "error") {
        Object.keys(metricsChartsDataFromQE).forEach((metricId) => {
          let metricIdOnly = metricId.match(/[CM]{1,2}[0-9]{3}/)[0];
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (allMetricIds.includes(metricIdOnly)) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricId]: {
                status: "error",
                message: config.hardCoded.internalServerErrorSigview,
                result: {
                  dataFromQE: [],
                  extraData: {},
                },
              },
            };
          }
        });
      } else if (metricsChartsDataFromQEStatus === "noData") {
        Object.keys(metricsChartsDataFromQE).forEach((metricId) => {
          let metricIdOnly = metricId.match(/[CM]{1,2}[0-9]{3}/)[0];
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (allMetricIds.includes(metricIdOnly)) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricId]: {
                status: "error",
                message: config.hardCoded.noDataAvailableMessage, // TODO : To be changed based on sigview user type (aws vs openx)
                result: {
                  dataFromQE: [],
                  extraData: {},
                },
              },
            };
          }
        });
      } else if (metricsChartsDataFromQEStatus === "loading") {
        Object.keys(metricsChartsDataFromQE).forEach((metricId) => {
          let metricIdOnly = metricId.match(/[CM]{1,2}[0-9]{3}/)[0];
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (allMetricIds.includes(metricIdOnly)) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricId]: {
                status: "loading",
                message: "",
                result: {
                  dataFromQE: [],
                  extraData: {},
                },
              },
            };
          }
        });
      }
      newState = {
        ...state,
        dataQE: {
          ...state.dataQE,
          value: newDataFromQEVal,
          status: "valid",
          message: "",
          stepTag: "",
        },
      };
      break;

    case C.UPDATE_STANDALONE_WS_FORM_METRICS_CHARTS_DATA_WITH_FILTER:
      newDataFromQEVal = { ...state.dataQE.value };
      var allMetricIds = Object.keys(state.selectedKpis.value);
      if (metricsChartsDataFromQEStatus === "success") {
        Object.keys(metricsChartsDataFromQE).forEach((metricId) => {
          let metricIdOnly = metricId.match(/[CM]{1,2}[0-9]{3}/)[0];
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (allMetricIds.includes(metricIdOnly)) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricId]: {
                status: "success",
                message: "",
                result: metricsChartsDataFromQE[metricId],
              },
            };
          }
        });
      } else if (metricsChartsDataFromQEStatus === "error") {
        Object.keys(metricsChartsDataFromQE).forEach((metricId) => {
          let metricIdOnly = metricId.match(/[CM]{1,2}[0-9]{3}/)[0];
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (allMetricIds.includes(metricIdOnly)) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricId]: {
                status: "error",
                message: config.hardCoded.internalServerErrorSigview,
                result: {
                  dataFromQE: [],
                  extraData: {},
                },
              },
            };
          }
        });
      } else if (metricsChartsDataFromQEStatus === "noData") {
        Object.keys(metricsChartsDataFromQE).forEach((metricId) => {
          let metricIdOnly = metricId.match(/[CM]{1,2}[0-9]{3}/)[0];
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (allMetricIds.includes(metricIdOnly)) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricId]: {
                status: "error",
                message: config.hardCoded.noDataAvailableMessage, // TODO : To be changed based on sigview user type (aws vs openx)
                result: {
                  dataFromQE: [],
                  extraData: {},
                },
              },
            };
          }
        });
      } else if (metricsChartsDataFromQEStatus === "loading") {
        Object.keys(metricsChartsDataFromQE).forEach((metricId) => {
          let metricIdOnly = metricId.match(/[CM]{1,2}[0-9]{3}/)[0];
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (allMetricIds.includes(metricIdOnly)) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricId]: {
                status: "loading",
                message: "",
                result: {
                  dataFromQE: [],
                  extraData: {},
                },
              },
            };
          }
        });
      }
      newState = {
        ...state,
        dataQE: {
          ...state.dataQE,
          value: newDataFromQEVal,
          status: "valid",
          message: "",
          stepTag: "",
        },
      };

      break;

    case C.UPDATE_STANDALONE_WS_FORM_METRICS_DATA:
      newDataFromQEVal = { ...state.dataQE.value };
      if (metricsDataFromQEStatus === "success") {
        Object.keys(metricsDataFromQE).forEach((metricId) => {
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (state.dataQE.value[metricId]) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricId]: {
                status: "success",
                message: "",
                result: metricsDataFromQE[metricId],
              },
            };
          }
        });
      } else if (metricsDataFromQEStatus === "error") {
        Object.keys(metricsDataFromQE).forEach((metricId) => {
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (state.dataQE.value[metricId]) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricId]: {
                status: "error",
                message: config.hardCoded.internalServerErrorSigview,
                result: { value: 0, displayValue: "0" },
              },
            };
          }
        });
      } else if (metricsDataFromQEStatus === "noData") {
        Object.keys(metricsDataFromQE).forEach((metricId) => {
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (state.dataQE.value[metricId]) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricId]: {
                status: "error",
                message: config.hardCoded.uiErrorMessage,
                result: { value: 0, displayValue: "No Data" },
              },
            };
          }
        });
      } else if (metricsDataFromQEStatus === "loading") {
        Object.keys(metricsDataFromQE).forEach((metricId) => {
          // Do this only when the widget exists
          // In case it's deleted do nothing
          if (state.dataQE.value[metricId]) {
            newDataFromQEVal = {
              ...newDataFromQEVal,
              [metricId]: {
                status: "loading",
                message: "",
                result: { value: 0, displayValue: "0" },
              },
            };
          }
        });
      }
      newState = {
        ...state,
        dataQE: {
          ...state.dataQE,
          value: newDataFromQEVal,
          status: "valid",
          message: "",
          stepTag: "",
        },
      };
      break;

    case C.RELOAD_WS_DIMENSION_TABLE_DATA:
      // var newReloadEpochsValue = {
      //   ...state.reloadEpochs.value,
      //   [dimId]: Date.now(),
      // };
      var newDataQEVal = {
        ...state.dataQE.value,
        [dimId]: defaultQeData,
      };

      newState = {
        ...state,
        // reloadEpochs: {
        //   ...state.reloadEpochs,
        //   value: newReloadEpochsValue,
        // },
        dataQE: { ...state.dataQE, value: newDataQEVal },
      };

      break;

    case C.RELOAD_WS_ALL_METRIC_CHARTS_DATA:
      newState = resetAllMetricChartsDataQE(state);
      break;

    case C.UPDATE_STANDALONE_WS_MULTIPLE_DIMENSIONS:
      // * Define required variables
      var oldDimIds = Object.keys(state.selectedDimensions.value);
      var newDimIds = [...selections.selectedDim];
      var dimIdsToBeRemoved = oldDimIds.filter(
        (oldId) => !newDimIds.includes(oldId)
      );
      var dimIdsToBeAdded = newDimIds.filter(
        (newId) => !oldDimIds.includes(newId)
      );
      var newSelectedDimensions = { ...state.selectedDimensions.value };
      var newReloadEpochs = { ...state.reloadEpochs.value };
      var newDataQE = { ...state.dataQE.value };
      var newDimensionLayout = [];

      // * Update newLayout
      // newDimensionLayout = newDimIds.map((id, index) => ({ id, order: index }));

      // * Update newDataQE, newReloadEpochs, newSelectedDimensions
      // Remove old dim Ids
      for (const oldId of dimIdsToBeRemoved) {
        delete newSelectedDimensions[oldId];
        delete newReloadEpochs[oldId];
        delete newDataQE[oldId];
      }

      // Add new dim Ids
      for (const newId of dimIdsToBeAdded) {
        const newDimObj = getDimensionObjByOriginalID(newId, plotlyDimensions);
        newSelectedDimensions[newId] = {
          id: newDimObj._id,
          title: newDimObj.dimTitle,
          dimensionsList: [newDimObj],
          metricsList: state.selectedDimTableMetrics.value,
          metricFilters: [],
          dimensionFilters: state.dimensionFilters.value,
          timeFilters: state.timeFilters.value,
          orderById: newDimObj._id,
          orderBy: state.orderBy.value,
          orderByType: "id_only",
          chartType: "table",
          chartList: "",
          valid: true,
          progressiveDateFlag: true,
          percentCalList: [],
          renderFlag: false,
        };

        newReloadEpochs[newId] = Date.now();
        newDataQE[newId] = {
          status: "loading",
          message: "",
          result: {
            dataFromQE: [],
            extraData: {},
          },
        };
      }
      // Update layout
      var newMetricLayoutInDimension = selections.selectedMetric.map(
        (row, index) => {
          return {
            metricId: row,
            order: index + 1,
            isPercentCalOn: false,
            compareCol: "_Both",
          };
        }
      );

      newDimIds.map((newId, index) => {
        const newDimensionLayoutObj = {
          id: newId,
          order: index,
          chartType: "table",
          size: {
            width: 50,
            height: 0,
          },
          metrics: newMetricLayoutInDimension,
          sortList: [
            {
              id: state.orderById.value,
              desc: state.orderBy.value === "desc" ? true : false,
            },
          ],
        };
        newDimensionLayout.push(newDimensionLayoutObj);
      });

      newState = {
        ...state,
        layout: {
          ...state.layout,
          value: {
            kpis: [...state.layout.value.kpis],
            dimensions: newDimensionLayout,
          },
        },
        dataQE: { ...state.dataQE, value: newDataQE },
        selectedDimensions: {
          ...state.selectedDimensions,
          value: newSelectedDimensions,
        },
        reloadEpochs: { ...state.reloadEpochs, value: newReloadEpochs },
      };

      break;

    case C.UPDATE_STANDALONE_WS_MULTIPLE_DIMENSIONS_AND_METRICS:
      // * Define required variables
      var newDimIds = [...selections.selectedDim];
      var newMetricIds = [...selections.selectedMetric];
      var newSelectedDimTableMetrics = newMetricIds.map((id) =>
        getMeasureObjByOriginalID(id, plotlyMetrics)
      );

      var newSelectedDimensions = { ...state.selectedDimensions.value };
      var newReloadEpochs = { ...state.reloadEpochs.value };
      var newDataQE = { ...state.dataQE.value };
      var newDimensionLayout = [];

      // * Update newLayout
      // newDimensionLayout = newDimIds.map((id, index) => ({ id, order: index }));

      // * Update newDataQE, newReloadEpochs, newSelectedDimensions
      for (const newId of newDimIds) {
        const newDimObj = getDimensionObjByOriginalID(newId, plotlyDimensions);
        newSelectedDimensions[newId] = {
          id: newDimObj._id,
          title: newDimObj.dimTitle,
          dimensionsList: [newDimObj],
          metricsList: [...newSelectedDimTableMetrics],
          metricFilters: [],
          dimensionFilters: state.dimensionFilters.value,
          timeFilters: state.timeFilters.value,
          orderById: newDimObj._id,
          orderBy: state.orderBy.value,
          orderByType: "id_only",
          chartType: "table",
          chartList: "",
          valid: true,
          progressiveDateFlag: true,
          percentCalList: [],
          renderFlag: false,
        };
        newReloadEpochs[newId] = Date.now();
        newDataQE[newId] = {
          status: "loading",
          message: "",
          result: {
            dataFromQE: [],
            extraData: {},
          },
        };
      }
      // Update layout
      var newMetricLayoutInDimension = selections.selectedMetric.map(
        (row, index) => {
          return {
            metricId: row,
            order: index + 1,
            isPercentCalOn: false,
            compareCol: "_Both",
          };
        }
      );

      newDimIds.map((newId, index) => {
        const newDimensionLayoutObj = {
          id: newId,
          order: index,
          chartType: "table",
          size: {
            width: 50,
            height: 0,
          },
          metrics: newMetricLayoutInDimension,
          sortList: [
            {
              id: state.orderById.value,
              desc: state.orderBy.value === "desc" ? true : false,
            },
          ],
        };
        newDimensionLayout.push(newDimensionLayoutObj);
      });

      newState = {
        ...state,
        layout: {
          ...state.layout,
          value: {
            kpis: [...state.layout.value.kpis],
            dimensions: newDimensionLayout,
          },
        },
        dataQE: { ...state.dataQE, value: newDataQE },
        selectedDimTableMetrics: {
          ...state.selectedDimTableMetrics,
          value: newSelectedDimTableMetrics,
        },
        selectedDimensions: {
          ...state.selectedDimensions,
          value: newSelectedDimensions,
        },
        reloadEpochs: { ...state.reloadEpochs, value: newReloadEpochs },
      };

      break;

    case C.UPDATE_STANDALONE_WS_MULTIPLE_METRICS:
      // * Define required variables
      // var newDataQEValue = { ...state.dataQE.value };
      var newReloadEpochsVal = { ...state.reloadEpochs.value };
      const oldMetricsList = state.layout.value.kpis.map((row) => row.id);
      var newMetricsList = selections.chartMetrics;
      const newMetricsToBeAdded = newMetricsList.filter(
        (id) => !oldMetricsList.includes(id)
      );
      const obsoluteMetricsToBeRemoved = oldMetricsList.filter(
        (id) => !newMetricsList.includes(id)
      );
      var finalMetricsList = oldMetricsList.filter(
        (id) => !obsoluteMetricsToBeRemoved.includes(id)
      );
      finalMetricsList = [...finalMetricsList, ...newMetricsToBeAdded];

      // * 1. Layout
      let newSelectedKpisLayout = finalMetricsList.map((metricId, index) => ({
        id: metricId,
        order: index + 1,
        chartType: state.metricChartType.value,
      }));

      // * 2. Reload Epochs
      newReloadEpochsVal["allMetrics"] = Date.now();
      newReloadEpochsVal["allMetricCharts"] = Date.now();
      // Remove old ones
      for (const key of Object.keys(newReloadEpochsVal)) {
        for (const metricId of obsoluteMetricsToBeRemoved) {
          if (key.includes(metricId)) {
            delete newReloadEpochsVal[metricId];
          }
        }
      }
      // Add new ones
      for (const metricId of newMetricsToBeAdded) {
        const newKey1 = `${metricId}_trueDelta`;
        const newKey2 = `${metricId}_deltaPercentage`;
        const newKey3 = `${metricId}_percentageTotalValue`;
        newReloadEpochsVal[metricId] = Date.now();
        newReloadEpochsVal[newKey2] = Date.now();
        newReloadEpochsVal[newKey1] = Date.now();
        newReloadEpochsVal[newKey3] = Date.now();
        const { allValidMetricKeys } = makeAllValidMetricCalendarKeys(state);
        for (const validKey of allValidMetricKeys) {
          if (validKey.includes(metricId)) {
            newReloadEpochsVal[validKey] = Date.now();
          }
        }
      }

      // * 3. Selected KPIs
      var newSelectedKpisVal = {};
      finalMetricsList.forEach((row) => {
        let metric = getMeasureObjByOriginalID(row, plotlyMetrics);
        const newRow = {
          id: metric._id,
          title: metric._title,
          chartType: state.metricChartType.value,
          metricFilters: [],
          dimensionFilters: state.dimensionFilters.value,
          timeFilters: state.timeFilters.value,
          metadata: { ...metric },
          metricsList: [metric],
          dimensionsList: [],
          orderById: metric._id,
          orderBy: "asc",
        };
        newSelectedKpisVal[metric._id] = newRow;
      });

      // ! Before the above code the newState needs have the updated selectedKpis
      newState = {
        ...state,
        layout: {
          ...state.layout,
          value: { ...state.layout.value, kpis: newSelectedKpisLayout },
        },
        selectedKpis: {
          ...state.selectedKpis,
          value: newSelectedKpisVal,
        },
        reloadEpochs: { ...state.reloadEpochs, value: newReloadEpochsVal },
      };
      var newDataQEValue = { ...newState.dataQE.value };

      // * 4. dataQE
      // Remove old ones
      for (const key of Object.keys(newDataQEValue)) {
        for (const metricId of obsoluteMetricsToBeRemoved) {
          if (key.includes(metricId)) {
            delete newDataQEValue[metricId];
          }
        }
      }
      // Add new ones
      for (const metricId of newMetricsToBeAdded) {
        const newKey1 = `${metricId}_trueDelta`;
        const newKey2 = `${metricId}_deltaPercentage`;
        const newKey3 = `${metricId}_percentageTotalValue`;
        newDataQEValue[metricId] = defaultKpiData;
        newDataQEValue[newKey2] = defaultKpiData;
        newDataQEValue[newKey1] = defaultKpiData;
        newDataQEValue[newKey3] = defaultKpiData;
        const { allValidMetricKeys } = makeAllValidMetricCalendarKeys(newState);
        for (const validKey of allValidMetricKeys) {
          if (validKey.includes(metricId))
            newDataQEValue[validKey] = defaultQeData;
        }
      }
      newState = {
        ...newState,
        dataQE: { ...newState.dataQE, value: newDataQEValue },
      };

      break;

    case C.UPDATE_STANDALONE_WS_MULTIPLE_METRICS_TABLE:
      // case 1.1 Metric Chart
      // layout
      // dataQE
      // selectedkpis
      // reloadEpochs

      // case 1.2 metric table
      // layout
      // dataQE
      // selectedDimTableMetrics
      // reloadEpochs

      // let newSelectedKpisLayout = [];

      var layoutOrder = 1;

      // var newSelectedKpisLayout = selections.chartMetrics.map((row) => {
      //   return {
      //     id: getMeasureObjByOriginalID(row, plotlyMetrics)._id,
      //     order: layoutOrder++,
      //     chartType: "line",
      //   };
      // });

      // console.log("newSelectedKpisLayout", newSelectedKpisLayout);

      // var newSelectedkpis = {};

      // selections.chartMetrics.forEach((row) => {
      //   let metric = getMeasureObjByOriginalID(row, plotlyMetrics);

      //   console.log("metricId", metric);
      //   newSelectedkpis[metric._id] = {
      //     id: metric._id,
      //     title: metric._title,
      //     chartType: "line",
      //     metricFilters: [],
      //     dimensionFilters: [],
      //     timeFilters: state.timeFilters.value,
      //     metadata: { ...metric },
      //     metricsList: [
      //       {
      //         ...metric,
      //       },
      //     ],
      //     dimensionsList: [],
      //     orderById: metric._id,
      //     orderBy: "asc",
      //   };
      // });

      // console.log("newSelectedkpis", newSelectedkpis);

      var newFilteredDataQE = {};
      var newReloadEpochs = {};

      selections.tableMetrics.forEach((row) => {
        for (const key in state.dataQE.value) {
          if (key.split("_")[0].includes("D")) {
            newFilteredDataQE[key] = {
              status: "loading",
              message: "",
              result: {},
            };

            newReloadEpochs[key] = Date.now();
          } else {
            newFilteredDataQE[key] = state.dataQE.value[key];

            // newReloadEpochs[key] = state.reloadEpochs.value[key];
          }
        }
      });

      newSelectedDimTableMetrics = selections.tableMetrics.map((row) => {
        return getMeasureObjByOriginalID(row, plotlyMetrics);
      });

      var newMetricLayoutInDimension = selections.tableMetrics.map((row) => {
        let metric = getMeasureObjByOriginalID(row, plotlyMetrics);

        return {
          metricId: metric._id,
          order: layoutOrder++,
          isPercentCalOn: false,
          compareCol: "_deltaPercentage",
        };
      });

      var newDimensionLayout = state.layout.value.dimensions.map((row) => {
        return { ...row, metrics: newMetricLayoutInDimension };
      });
      // console.log("newDimensionLayout", newDimensionLayout);

      // var newFilteredDataQEKeys = Object.keys(newFilteredDataQE);

      // selections.chartMetrics.forEach((row) => {
      //   if (!newFilteredDataQEKeys.includes(row)) {
      //     newFilteredDataQE[row] = {
      //       status: "loading",
      //       message: "",
      //       result: {
      //         value: 0,
      //         displayValue: "0",
      //       },
      //     };
      //     newFilteredDataQE[
      //       row +
      //         "_chart_" +
      //         state.timeFilters.value.selectedDatesQE.startDate +
      //         "_" +
      //         state.timeFilters.value.selectedDatesQE.endDate
      //     ] = {
      //       status: "loading",
      //       message: "",
      //       result: {
      //         value: 0,
      //         displayValue: "0",
      //       },
      //     };
      //     newFilteredDataQE[row + "_deltaPercentage"] = {
      //       status: "loading",
      //       message: "",
      //       result: {
      //         value: 0,
      //         displayValue: "0",
      //       },
      //     };
      //     newFilteredDataQE[row + "_trueDelta"] = {
      //       status: "loading",
      //       message: "",
      //       result: {
      //         value: 0,
      //         displayValue: "0",
      //       },
      //     };
      //   }
      // });

      // console.log("newFilteredDataQE", newFilteredDataQE, newReloadEpochs);

      let newSelectedDimensionsmetricList = {};

      for (const key in state.selectedDimensions.value) {
        newSelectedDimensionsmetricList[key] = {
          ...state.selectedDimensions.value[key],
          metricsList: newSelectedDimTableMetrics,
        };
      }

      newState = {
        ...state,

        selectedDimensions: {
          ...state.selectedDimensions,
          value: newSelectedDimensionsmetricList,
        },
        layout: {
          ...state.layout,
          value: {
            ...state.layout.value,
            dimensions: newDimensionLayout,
          },
        },
        dataQE: { ...state.dataQE, value: newFilteredDataQE },
        selectedDimTableMetrics: {
          ...state.selectedDimTableMetrics,
          value: newSelectedDimTableMetrics,
        },
        reloadEpochs: {
          ...state.reloadEpochs,
          value: { ...state.reloadEpochs.value, ...newReloadEpochs },
        },
      };

      break;

    case C.DELETE_STANDALONE_WS_FORM_METRIC_CHART:
      var newDataQEValue = {};

      for (const key in state.dataQE.value) {
        if (key.split("_")[0] !== deleteItemId.split("_")[0]) {
          newDataQEValue[key] = state.dataQE.value[key];
        }
      }

      // state.dataQE.value.forEach((row) => {

      //   if (row.id.split("_")[0] !== deleteItemId.split("_")[0]) {
      //     newDataQE[row.id] = row;
      //   }
      // });
      var newReloadEpochsValue = {};
      for (const key in state.reloadEpochs.value) {
        if (key.split("_")[0] !== deleteItemId.split("_")[0]) {
          newReloadEpochsValue[key] = state.reloadEpochs.value[key];
        }
      }

      // state.dataQE.value.forEach((row) => {
      //   if (row.id.split("_")[0] !== deleteItemId.split("_")[0]) {
      //     newReloadEpochsValue[row.id] = row;
      //   }
      // });
      let newSelectedKpisValue = {};

      for (const key in state.selectedKpis.value) {
        if (key.split("_")[0] !== deleteItemId.split("_")[0]) {
          newSelectedKpisValue[key] = state.selectedKpis.value[key];
        }
      }
      // state.dataQE.value.forEach((row) => {
      //   if (row.id.split("_")[0] !== deleteItemId.split("_")[0]) {
      //     newSelectedKpisValue[row.id] = row;
      //   }
      // });

      newState = {
        ...state,

        layout: {
          ...state.layout,
          value: {
            ...state.layout.value,
            kpis: state.layout.value.kpis.filter(
              (row) => row.id !== deleteItemId
            ),
          },
        },
        dataQE: {
          ...state.dataQE,
          value: newDataQEValue,
        },

        reloadEpochs: {
          ...state.reloadEpochs,
          value: newReloadEpochsValue,
        },
        selectedKpis: {
          ...state.selectedKpis,
          value: newSelectedKpisValue,
        },
      };

      break;

    case C.DELETE_STANDALONE_WS_FORM_DIMENSION_TABLE:
      var newDataQEValueAfterDelete = { ...state.dataQE.value };
      let newReloadEpochsValueAfterDelete = { ...state.reloadEpochs.value };
      let newSelectedDimensionsAfterDelete = {
        ...state.selectedDimensions.value,
      };
      let newLayoutAfterDelete = [...state.layout.value.dimensions];

      // 1. DataQE
      delete newDataQEValueAfterDelete[deleteItemId];

      // 2. Reload Epochs
      delete newReloadEpochsValueAfterDelete[deleteItemId];

      // 3. Selected Dimensions
      delete newSelectedDimensionsAfterDelete[deleteItemId];

      // 4. Layout
      newLayoutAfterDelete = newLayoutAfterDelete.filter(
        (row) => row.id !== deleteItemId
      );

      newState = {
        ...state,
        layout: {
          ...state.layout,
          value: {
            ...state.layout.value,
            dimensions: newLayoutAfterDelete,
          },
        },
        dataQE: {
          ...state.dataQE,
          value: newDataQEValueAfterDelete,
        },
        reloadEpochs: {
          ...state.reloadEpochs,
          value: newReloadEpochsValueAfterDelete,
        },
        selectedDimensions: {
          ...state.selectedDimensions,
          value: newSelectedDimensionsAfterDelete,
        },
      };

      break;

    case C.UPDATE_STANDALONE_WS_TOGGLE_PERCENT_CAL_LIST:
      // PAYLOAD SAMPLE
      // metricId -> "M001"
      // flag -> false
      // dimId -> "D004"

      const newMetricObj = getMeasureObjByOriginalID(
        metricId,
        state.utils.value.plotlyMetrics
      );

      // Updating in global level (although it's not used as of now)
      let newSelectedDimTablePercentCalListVal =
        state.selectedDimTablePercentCalList.value;
      if (flag) {
        newSelectedDimTablePercentCalListVal = [
          ...newSelectedDimTablePercentCalListVal,
          newMetricObj,
        ];
      } else {
        newSelectedDimTablePercentCalListVal =
          newSelectedDimTablePercentCalListVal.filter(
            (row) => row._id !== newMetricObj._id
          );
      }

      // Updating only in that dimension
      var newSelectedDimensionsVal = { ...state.selectedDimensions.value };
      var currDimensionRow = newSelectedDimensionsVal[dimId];
      var newPercentCalList = currDimensionRow["percentCalList"];
      if (flag) {
        newPercentCalList = [...newPercentCalList, newMetricObj];
      } else {
        newPercentCalList = newPercentCalList.filter(
          (row) => row._id !== newMetricObj._id
        );
      }
      newSelectedDimensionsVal[dimId] = {
        ...currDimensionRow,
        percentCalList: newPercentCalList,
      };

      newState = {
        ...state,
        selectedDimTablePercentCalList: {
          ...state.selectedDimTablePercentCalList,
          value: newSelectedDimTablePercentCalListVal,
        },
        selectedDimensions: {
          ...state.selectedDimensions,
          value: newSelectedDimensionsVal,
        },
      };
      break;

    case C.UPDATE_STANDALONE_WS_FILTERS_FROM_DIM_TABLE:
      // payload -> { dimId, value }

      const newDimObj = getDimensionObjByOriginalID(
        dimId,
        state.utils.value.plotlyDimensions
      );

      // Sample dimensionFilters row
      const newFilterRow = {
        id: newDimObj._id,
        values: [value],
        metadata: newDimObj,
        filterType: "include",
        advancedFilters: [],
        valid: true,
      };
      // Remove row if already present which means removing already applied filters on that dimension
      // Eg. 3 values are filtered for publisher name dimension
      // If user clicks on a row, remove all exisitng filter values and apply only on that new value
      let newDimFiltersVal = state.dimensionFilters.value.filter(
        (row) => row.id !== newDimObj._id
      );
      newDimFiltersVal = [...newDimFiltersVal, newFilterRow];

      newState = {
        ...state,
        dimensionFilters: {
          ...state.dimensionFilters,
          value: newDimFiltersVal,
        },
      };
      newState = resetAllDataQE(newState);
      break;

    case C.UPDATE_STANDALONE_WS_DIMENSION_TABLE_METRICS:
      // LOGIC
      // Remove ONLY those metrics which were present in the common list BEFORE but are not present now
      // Add ONLY those metrics which were not present in the common list BEFORE

      // Do the same in percentCalList
      var newSelectedDimensionsVal = {};
      const selectedDimensionsArr = Object.values(
        state.selectedDimensions.value
      );
      for (const selectedDimension of selectedDimensionsArr) {
        const dimId = selectedDimension.id;
        const currMetricsList = selectedDimension.metricsList.map(
          (row) => row._id
        );
        const kpisToBeRemoved = oldSelectedTableMetrics.filter(
          (kpi) => !newSelectedTableMetrics.includes(kpi)
        );
        const newKpisAdded = newSelectedTableMetrics.filter(
          (kpi) => !oldSelectedTableMetrics.includes(kpi)
        );
        var newMetricsList = currMetricsList.filter(
          (kpi) => !kpisToBeRemoved.includes(kpi)
        );
        newMetricsList = [...newMetricsList, ...newKpisAdded];
        // Remove duplicates
        newMetricsList = [...new Set(newMetricsList)];
        // Add metrics metadata
        newMetricsList = newMetricsList.map((kpi) =>
          getMeasureObjByOriginalID(kpi, plotlyMetrics)
        );
        var newMetricsListIds = newMetricsList.map((row) => row._id);
        const newPercentCalList = selectedDimension.percentCalList.filter(
          (row) => newMetricsListIds.includes(row._id)
        );
        const newSelectedDimension = {
          ...selectedDimension,
          metricsList: newMetricsList,
          percentCalList: newPercentCalList,
        };
        newSelectedDimensionsVal[dimId] = newSelectedDimension;
      }

      newState = {
        ...state,
        selectedDimensions: {
          ...state.selectedDimensions,
          value: newSelectedDimensionsVal,
        },
      };
      break;

    case C.UPDATE_STANDALONE_WS_MULTIPLE_DIMENSIONS_FROM_DIMENSION_DRAWER:
      // SAMPLE SELECTIONS OBJECT
      // selections = [{ id: "D001", metrics: ["M001", "M002"] }];

      // * 4 keys need to be updated
      // 1. layout - DONE
      // 2. dataQE - DONE
      // 3. reloadEpochs - DONE
      // 4. selectedDimensions

      // Making dim to be added and dim to be removed
      var newDimList = selections.map((row) => row.id);
      var oldDimList = Object.keys(state.selectedDimensions.value);
      var dimToBeRemoved = oldDimList.filter(
        (dim) => !newDimList.includes(dim)
      );
      var dimToBeAdded = newDimList.filter((dim) => !oldDimList.includes(dim));

      // * REMOVE
      // 1. layout
      var newLayoutDimVal = selections.map((row, index) => ({
        id: row.id,
        chartType: "table",
        order: index + 1,
      }));

      // 2. dataQE
      var newDataQEVal = omit(state.dataQE.value, dimToBeRemoved);

      // 3. reloadEpochs
      var newReloadEpochsVal = omit(state.reloadEpochs.value, dimToBeRemoved);

      // * ADD
      for (const newDim of dimToBeAdded) {
        // 2. dataQE
        newDataQEVal = {
          ...newDataQEVal,
          [newDim]: defaultQeData,
        };

        // 3. reloadEpochs
        newReloadEpochsVal = {
          ...newReloadEpochsVal,
          [newDim]: Date.now(),
        };
      }

      // * 4. selectedDimensions
      var oldSelectedDimensionsValArr = Object.values(
        state.selectedDimensions.value
      );
      // ! REMOVE OLD ONES
      var modSelectedDimensionsValArr = oldSelectedDimensionsValArr.filter(
        (row) => !dimToBeRemoved.includes(row.id)
      );
      // ! MODIFY REMAINING ONES ONLY IF THE METRICS CHANGED OTHERWISE selections.metricsList gets changed and API gets retriggered
      // Now this list contains only those which are still present in the selections
      // We will pick the new metrics list from these selections for each dimension
      modSelectedDimensionsValArr = modSelectedDimensionsValArr.map((row) => {
        const currDimObjFromSelections = selections.find(
          (sRow) => sRow.id === row.id
        );
        var newMetricsList = currDimObjFromSelections.metrics;
        const oldMetricsList = row.metricsList.map((kpiRow) => kpiRow._id);
        const areMetricsListEqual = isEqual(newMetricsList, oldMetricsList);
        if (areMetricsListEqual) {
          return row;
        } else {
          var newMetricsListFinal = newMetricsList.map((kpiId) =>
            getMeasureObjByOriginalID(kpiId, plotlyMetrics)
          );
          const newRow = { ...row, metricsList: newMetricsListFinal };
          return newRow;
        }
      });

      // ! ADD NEW ONES
      for (const uniqueId of dimToBeAdded) {
        // * This logic is a copy from analyzeUtils unwrapperWs
        const currDimObjFromSelections = selections.find(
          (sRow) => sRow.id === uniqueId
        );
        const metricsList = currDimObjFromSelections.metrics.map((kpiId) =>
          getMeasureObjByOriginalID(kpiId, plotlyMetrics)
        );
        const dimObject = getDimensionObjByOriginalID(
          uniqueId,
          plotlyDimensions
        );
        var selectedDimension = getChartObjectUiPlaceHolder();
        selectedDimension["id"] = uniqueId;
        selectedDimension["title"] = dimObject.title;
        selectedDimension["dimensionsList"] = [dimObject];
        selectedDimension["metricsList"] = metricsList;
        selectedDimension["dimensionFilters"] = state.dimensionFilters.value;
        selectedDimension["timeFilters"] = state.timeFilters.value;
        selectedDimension["orderBy"] = "desc";
        selectedDimension["orderById"] = currDimObjFromSelections.metrics[0];
        selectedDimension["orderByType"] = "id_only";
        const validChartList = checkChartTypeValidity({
          dimensionsList: selectedDimension.dimensionsList,
          metricsList: selectedDimension.metricsList,
          chartList: selectedDimension.chartList,
        });
        selectedDimension["chartList"] = validChartList;
        // Add the new dimension table item
        modSelectedDimensionsValArr = [
          ...modSelectedDimensionsValArr,
          selectedDimension,
        ];
      }

      var newSelectedDimensionsVal = modSelectedDimensionsValArr.reduce(
        (newObj, currRow) => ({ ...newObj, [currRow.id]: currRow }),
        {}
      );

      newState = {
        ...state,
        layout: {
          ...state.layout,
          value: { ...state.layout.value, dimensions: newLayoutDimVal },
        },
        reloadEpochs: { ...state.reloadEpochs, value: newReloadEpochsVal },
        dataQE: { ...state.dataQE, value: newDataQEVal },
        selectedDimensions: {
          ...state.selectedDimensions,
          value: newSelectedDimensionsVal,
        },
      };
      break;

    case C.UPDATE_STANDALONE_WS_METRIC_FILTERS_FOR_MSV_TABLE:
      var activeMsvTableId = state.activeMsvTableId.value;
      var newSelectedDimensionsVal = {
        ...state.selectedDimensions.value,
        [activeMsvTableId]: {
          ...state.selectedDimensions.value[activeMsvTableId],
          metricFilters: wrapperMsvFilters(value), //todo wrapper/unwrapper
        },
      };

      newState = {
        ...state,
        selectedDimensions: {
          ...state.selectedDimensions,
          value: newSelectedDimensionsVal,
        },
      };
      break;

    case C.UPDATE_STANDALONE_WS_MSV_CANCEL:
      newState = { ...state };

      // Make required variables
      var allowedMsvKey = [
        "rollingDateType",
        "timeFilters",
        "metricChartGranularity",
        "metricGranData",
        "metricChartType",
        "dataLimit",
        "selectedKpis",
      ];
      var filteredMsvform = Object.keys(msvForm)
        .filter((key) => allowedMsvKey.includes(key))
        .reduce((obj, key) => {
          obj[key] = msvForm[key];
          return obj;
        }, {});

      // 1. Keep MSV Keys as they have the updated data
      newState = {
        ...newState,
        ...filteredMsvform,
      };

      // 2. Update layout
      var newLayoutVal = {
        dimensions: newState.layout.value.dimensions,
        kpis: msvForm.layout.value.kpis,
      };
      newState = {
        ...newState,
        layout: {
          ...newState.layout,
          value: newLayoutVal,
        },
      };

      // 3. Update selectedDimensions (keep data if msv form for activeMsvTableId)
      var newSelectedDimensionsVal = { ...newState.selectedDimensions.value };
      var activeMsvTableId = msvForm.activeMsvTableId.value;
      newSelectedDimensionsVal = {
        ...newSelectedDimensionsVal,
        [activeMsvTableId]: {
          ...msvForm.selectedDimensions.value[activeMsvTableId],
          metricFilters: [],
        },
      };
      newState = {
        ...newState,
        selectedDimensions: {
          ...newState.selectedDimensions,
          value: newSelectedDimensionsVal,
        },
      };

      // 4. Reset all data
      // TODO : Devise a way to reduce getData calls
      newState = resetAllDataQE(newState);

      // 5. Keep all dimension filters except the msv table one
      // For the MSV table one, keep retain the old Ws filter
      var newDimensionFiltersVal = [...msvForm.dimensionFilters.value];
      var activeMsvTableId = msvForm.activeMsvTableId.value;
      newDimensionFiltersVal = newDimensionFiltersVal.filter(
        (row) => row.id !== activeMsvTableId
      );
      var activeMsvTableFilterInOldWs = state.dimensionFilters.value.filter(
        (row) => row.id === activeMsvTableId
      );
      newDimensionFiltersVal = [
        ...newDimensionFiltersVal,
        ...activeMsvTableFilterInOldWs,
      ];
      newState = {
        ...newState,
        dimensionFilters: {
          ...newState.dimensionFilters,
          value: newDimensionFiltersVal,
        },
      };

      // 6. Close MSV
      newState = {
        ...newState,
        msv: {
          ...newState.msv,
          value: {
            isOpen: false,
            metadata: { workspaceSelections: newState, msvTableId: "" },
          },
        },
      };

      break;

    case C.UPDATE_STANDALONE_WS_MSV_APPLY_FILTERS:
      newState = { ...state };

      // Make required variables
      var allowedMsvKey = [
        "rollingDateType",
        "timeFilters",
        "dimensionFilters",
        "metricChartGranularity",
        "metricGranData",
        "metricChartType",
        "dataLimit",
        "selectedKpis",
      ];
      var filteredMsvform = Object.keys(msvForm)
        .filter((key) => allowedMsvKey.includes(key))
        .reduce((obj, key) => {
          obj[key] = msvForm[key];
          return obj;
        }, {});

      // 1. Keep MSV Keys as they have the updated data
      newState = {
        ...newState,
        ...filteredMsvform,
      };

      // 2. Update layout
      var newLayoutVal = {
        dimensions: newState.layout.value.dimensions,
        kpis: msvForm.layout.value.kpis,
      };
      newState = {
        ...newState,
        layout: {
          ...newState.layout,
          value: newLayoutVal,
        },
      };

      // 3. Update selectedDimensions (keep data if msv form for activeMsvTableId)
      var newSelectedDimensionsVal = { ...newState.selectedDimensions.value };
      var activeMsvTableId = msvForm.activeMsvTableId.value;
      newSelectedDimensionsVal = {
        ...newSelectedDimensionsVal,
        [activeMsvTableId]: {
          ...msvForm.selectedDimensions.value[activeMsvTableId],
          metricFilters: [],
        },
      };
      newState = {
        ...newState,
        selectedDimensions: {
          ...newState.selectedDimensions,
          value: newSelectedDimensionsVal,
        },
      };

      // 4. Update dimension filters
      var newDimensionFiltersVal = newState.dimensionFilters.value;
      var activeMsvTableId = msvForm.activeMsvTableId.value;
      // remove activeMsvTableId row (if it exists)
      newDimensionFiltersVal = newDimensionFiltersVal.filter(
        (row) => row.id !== activeMsvTableId
      );
      // add new filters coming from msv
      var filterDimObject =
        msvForm.selectedDimensions.value[activeMsvTableId].dimensionsList[0];
      var newMsvFilterItem = {
        id: activeMsvTableId,
        values: msvForm.allSelectedTableItem.value,
        metadata: filterDimObject,
        filterType: "include",
        advancedFilters: [],
        valid: true,
      };
      // Add filter only when at least one row is selected in MSV
      if (msvForm.allSelectedTableItem.value.length > 0) {
        newDimensionFiltersVal = [...newDimensionFiltersVal, newMsvFilterItem];
      }
      newSelectedDimensionsVal = {
        ...newSelectedDimensionsVal,
        [activeMsvTableId]: msvForm.selectedDimensions.value[activeMsvTableId],
      };
      newState = {
        ...newState,
        dimensionFilters: {
          ...newState.dimensionFilters,
          value: newDimensionFiltersVal,
        },
      };

      // 5. Reset all data
      newState = resetAllDataQE(newState);

      // 6. Close MSV
      newState = {
        ...newState,
        msv: {
          ...newState.msv,
          value: {
            isOpen: false,
            metadata: { workspaceSelections: newState, msvTableId: "" },
          },
        },
      };

      break;

    case C.UPDATE_STANDALONE_WS_AND_MSV_BY_OPENING_MSV:
      newState = { ...state, msv: { ...state.msv, value: wsFormMsvValue } };
      break;

    case C.NO_ACTION:
      newState = { ...state };
      break;

    case C.RESET_WORKSPACE_AND_MSV:
      return initialStandaloneWs;
      break;

    default:
      // console.error("UI ERROR");
      // console.groupCollapsed("DETAILS");
      // console.log("UNIDENTIFIED TYPE");
      // console.log("REDUCER -> ", "standaloneWs.js");
      // console.log("state -> ", state);
      // console.log("action -> ", action);
      // console.groupEnd();
      newState = { ...state };
  }

  // * To avoid failure when redux gets initiated on app load
  if (
    !type.startsWith("@@redux/INIT") &&
    !type.startsWith("@@redux/PROBE_UNKNOWN_ACTION") &&
    // ! DO NOT TRIGGER VALIDATIONS IF THE ACTION IS NON STANDALONE WS
    // ! IT CREATES A BUG WHERE ANY CHANGE IN THE STORE UNNECESSARILY UPDATES THE WS
    (type.includes("STANDALONE_WS") ||
      type.includes("RELOAD_WS") ||
      type.includes("WS_FORM") ||
      type.includes("NO_ACTION") ||
      type.includes("RESET_WORKSPACE_AND_MSV"))
  ) {
    // FINAL
    let validatedState = manipulateDataQEBasedOnTimeFilters(newState);
    validatedState =
      manipulateMetricChartTypeBasedOnBusinessLogic(validatedState);
    validatedState = manipulateOrderByDetailsForDimensionTables(validatedState);
    validatedState = manipulateRegexCheckAllBasedOnDataQe(validatedState);

    return validatedState;
  } else {
    return newState;
  }
}

export default standaloneWs;
