const themeLight = {
  //PRIMARY
  primaryColor: "#267EE6", //REFER: https://docusaurus.io/docs/styling-layout
  primaryColorLight: "#6ea9ee", //-30
  primaryColorLighter: "#b6d4f7", //-60
  primaryColorLightest: "#eaf3ff", //-80

  //SECONDARY
  secondaryColor: "#46596A", //REFER: https://docusaurus.io/docs/styling-layout
  // secondaryColorLight: "#758ea4", //-60
  // secondaryColorLighter: "#aabac7", //-110
  // secondaryColorLightest: "#d5dce3", //-150
  secondaryColorLight: "#607282", //-70
  secondaryColorLighter: "#c5cfd9", //-135
  secondaryColorLightest: "#eff2f5", //-175

  //HEADER
  headerBgColor: "#ffffff",

  //USER INFO
  userInfoBgColor: "#ffffff",

  //SIDENAV
  sidenavBgColor: "#ffffff",
  sidenavItemColor: "#46596a",
  sidenavItemHoverColor: "#F8F9FD",

  //MAIN CONTENT
  mainContentBgColor: "#f4f7ff",

  //DATASTORY
  dsItemBgColor: "#ffffff",
  dsItemBoxShadow: "0px 0px 7px 0px #d7d9e2",

  //TIME FILTERS
  timeFiltersBgColor: "#ffffff",
  timeFiltersBoxShadow: "0px 0px 7px 0px #eff2ff",

  //BUTTONS
  buttonBgColor: "#46596a",
  buttonBgColorLight: "#607282",
  buttonBgColorLighter: "#c5cfd9",
  buttonBgColorLightest: "#eff2f5",
  buttonColor: "#ffffff",
  buttonColorLight: "#46596a",
  buttonColorLighter: "#46596a",
  buttonColorLightest: "#46596a",
  buttonColorDark: "#eff2f5",
  buttonColorDarker: "#c5cfd9",
  buttonColorDarkest: "#607282",
  // buttonDisabledBgColor: "#c5cfd9",
  buttonDisabledBgColor: "#e0e0e0",
  buttonDisabledColor: "#8b8b8b",

  //RADIO
  radioBgColor: "#ffffff",
  radioColor: "#267ee6",
  radioDisabledBgColor: "#ffffff",
  radioDisabledColor: "#e0e0e0",

  //CHECKBOX
  checkboxColor: "#267ee6",

  //METRICS
  negChangeColor: "#ff4b5c",
  posChangeColor: "#1eae98",

  //OTHERS
  popoverBgColor: "#ffffff",
  failureColor: "#f44336",
  overlayBgColor: "rgba(0, 0, 0, 0.5)",
  overlayBgColorLight: "rgba(0, 0, 0, 0.1)",
};

const themeDark = {
  //PRIMARY
  // primaryColor: "#FDE725",
  // primaryColor: "#C0EE3D",
  // primaryColor: "#C3B56D",
  // primaryColorLight: "#97883e", //30
  // primaryColorLighter: "#6c612c", //50
  // primaryColorLightest: "#2b2712", //80
  //PRIMARY
  primaryColor: "#267EE6", //REFER: https://docusaurus.io/docs/styling-layout
  primaryColorLight: "#1664c0", //20
  primaryColorLighter: "#114b90", //40
  primaryColorLightest: "#0b3260", //60

  //SECONDARY
  secondaryColor: "#E2E2E2", //REFER: https://docusaurus.io/docs/styling-layout
  secondaryColorLight: "#9e9e9e", //30
  secondaryColorLighter: "#5a5a5a", //60
  secondaryColorLightest: "#444444", //90
  tableRowBGColor: "#f8f9fc",

  //HEADER
  headerBgColor: "#393939",

  //USER INFO
  userInfoBgColor: "#212121",

  //SIDENAV
  sidenavBgColor: "#393939",
  sidenavItemColor: "#46596a",
  sidenavItemHoverColor: "#F8F9FD",

  //MAIN CONTENT
  mainContentBgColor: "#212121",

  //DATASTORY
  dsItemBgColor: "#393939",
  dsItemBoxShadow: "0px 0px 7px 0px #161616",

  //TIME FILTERS
  timeFiltersBgColor: "#393939",
  timeFiltersBoxShadow: "0px 0px 7px 0px #161616",

  //BUTTONS
  // buttonBgColor: "#C3B56D",
  // buttonBgColorLight: "#97883e",
  // buttonBgColorLighter: "#6c612c",
  // buttonBgColorLightest: "#2b2712",
  // buttonColor: "#ffffff",
  // buttonColorLight: "#ffffff",
  // buttonColorLighter: "#ffffff",
  // buttonColorLightest: "#ffffff",
  // buttonColorDark: "#2b2712",
  // buttonColorDarker: "#6c612c",
  // buttonColorDarkest: "#97883e",
  // // buttonDisabledBgColor: "#6c612c",
  // // buttonDisabledColor: "#8b8b8b",
  // buttonDisabledBgColor: "#515151",
  // buttonDisabledColor: "#9A9A9A",
  //Experiment
  buttonBgColor: "#46596a",
  buttonBgColorLight: "#3f505f",
  buttonBgColorLighter: "#384755",
  buttonBgColorLightest: "#313e4a",
  buttonColor: "#ffffff",
  buttonColorLight: "#ffffff",
  buttonColorLighter: "#ffffff",
  buttonColorLightest: "#ffffff",
  buttonColorDark: "#eff2f5",
  buttonColorDarker: "#c5cfd9",
  buttonColorDarkest: "#607282",
  buttonDisabledBgColor: "#2f2f2f",
  buttonDisabledColor: "#6d6d6d",

  //RADIO
  radioBgColor: "transparent",
  radioColor: "#267ee6",
  radioDisabledBgColor: "transparent",
  radioDisabledColor: "#9A9A9A",

  //CHECKBOX
  checkboxColor: "#267ee6",

  //METRICS
  negChangeColor: "#ff4b5c",
  posChangeColor: "#1eae98",

  //OTHERS
  popoverBgColor: "#212121",
  failureColor: "#f77970",
  overlayBgColor: "rgba(0, 0, 0, 0.8)",
  overlayBgColorLight: "rgba(0, 0, 0, 0.4)",
};

const themeColors = {
  light: themeLight,
  dark: themeDark,
};

export default themeColors;
