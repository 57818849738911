// Import required libraries
import React from "react";
import { useTranslation } from "react-i18next";

// Import custom components
import SigviewSingleSelect from "../Common/SigviewSingleSelect";
import SigviewDateSingleSelect from "../Common/SigviewDateSingleSelect";
import SigviewDateRangeSelect from "../Common/SigviewDateRangeSelect";
import AdvancedFiltersFileUpload from "./AdvancedFiltersFileUpload";
import AdvancedFiltersFileUploadSigview from "./AdvancedFiltersFileUploadSigview";
import SigviewTextField from "../Common/SigviewTextField";

// Import styles

// Import config
import { config } from "../../config/config";

// Import action creators
import {
  removeAdvancedFilter,
  changeAdvancedFilterType,
  changeAdvancedFilterValue,
  addAdvancedFilter,
  changeAdvancedFilterExtraData,
} from "../../redux/actions";

// Import utils & data
import { getNewAdFilterRow } from "../../utils/filtersUtils";
import {
  getDateAllVariations,
  addOneDayMinus1SecondMilliseconds,
} from "../../utils/timeFiltersUtils";
import { getSigviewUserType } from "../../utils/utils";
import { masterTrackGaEvent } from "../../services/ga";

function AdvancedFiltersRow(props) {
  const { t } = useTranslation();
  const {
    user,
    id,
    uiCompType,
    adFilterTypes,
    allFilterTypes,
    value,
    type,
    rowIndex,
    totalLength,
    maxLimit,
    filterType,
    dispatchAdFiltersStore,
    showError,
    activeDimDatatype,
    extraData,
    typeError,
    valueError,
    allData,
    activeDimension,
  } = props;
  let Component;

  //Defining required variables
  //add the object to dropdown list based on the type
  //eg: if the type is exactlyMatches, check if it exists, if doesn't add it to the list
  //CONFIGURABLE FEATURE
  const sigviewUserType = getSigviewUserType();
  const dropDownExistsInList = adFilterTypes.find(
    (adFilterStringRow) => adFilterStringRow.id === type
  );
  let newDropDownData = [...adFilterTypes];
  if (!dropDownExistsInList) {
    //get the object from static list
    const dropDownObjectForSelectedType = allFilterTypes.find(
      (row) => row.id === type
    );
    newDropDownData = [...adFilterTypes, dropDownObjectForSelectedType];
  }

  //Add partnerId object in the dropdown list if they don't exist
  const currSelectedDropdownObj = allFilterTypes.find(
    (adFilterTypeRow) => adFilterTypeRow.id === type
  );
  let toBeAddedPartnerDropdowns = [];
  currSelectedDropdownObj.partnerIds.forEach((partnerId) => {
    const dropdownObj = allFilterTypes.find(
      (filterTypeRow) => filterTypeRow.id === partnerId
    );
    const currDropdownIds = newDropDownData.map((row) => row.id);
    if (!currDropdownIds.includes(dropdownObj.id)) {
      toBeAddedPartnerDropdowns.push(dropdownObj);
    }
  });
  newDropDownData = [...newDropDownData, ...toBeAddedPartnerDropdowns];

  const exactlyMatchesObject = config.hardCoded.exactlyMatchesObject;
  const showDeleteIconFlag = totalLength > 1;
  const showAddIconFlag =
    maxLimit !== rowIndex + 1 && rowIndex + 1 === totalLength;
  const itemTitle = `${
    config.hardCoded.advancedFiltersIncExcMapping[filterType || "include"]
  } all ${config.hardCoded.advancedFiltersDatatypeMapping[activeDimDatatype]}`;

  const handleTypeChange = (newValue) => {
    //Grabbing the new value name
    const newValueName = allFilterTypes.find(
      (row) => row.id === newValue
    )?.name;

    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "AdvancedFilterCondition",
      label: newValueName,
    });

    //Hard coded
    //If user moves from exactlyMatches to some other type, empty the value and emptyData
    //Else keep it as it is
    const payload = {
      advancedFilterId: id,
      advancedFilterType: newValue,
      advancedFilterValue: type === exactlyMatchesObject.id ? "" : value,
      advancedFilterExtraData:
        type === exactlyMatchesObject.id ? {} : extraData,
    };
    dispatchAdFiltersStore(changeAdvancedFilterType(payload));
  };

  const handleRowDelete = () => {
    const payload = { advancedFilterId: id };
    dispatchAdFiltersStore(removeAdvancedFilter(payload));
  };

  const handleValueChange = (newValue) => {
    const payload = {
      advancedFilterId: id,
      advancedFilterValue: newValue,
    };
    dispatchAdFiltersStore(changeAdvancedFilterValue(payload));
  };

  const handleAddRow = () => {
    const newAdFilterRow = getNewAdFilterRow(activeDimDatatype);
    const payload = {
      advancedFilterId: newAdFilterRow.id,
      advancedFilterType: newAdFilterRow.type,
      advancedFilterValue: newAdFilterRow.value,
      advancedFiltersUiCompType: newAdFilterRow.uiCompType,
      advancedFiltersTypeError: newAdFilterRow.typeError,
      advancedFiltersValueError: newAdFilterRow.valueError,
    };
    dispatchAdFiltersStore(addAdvancedFilter(payload));
  };

  const handleExtraDataChange = (newExtraData) => {
    const payload = {
      advancedFilterId: id,
      advancedFilterExtraData: newExtraData,
    };
    dispatchAdFiltersStore(changeAdvancedFilterExtraData(payload));
  };

  const propsList = {
    itemTitle,
    type,
    newDropDownData,
    handleTypeChange,
    showError,
    value,
    handleValueChange,
    showDeleteIconFlag,
    handleRowDelete,
    handleAddRow,
    showAddIconFlag,
    handleExtraDataChange,
    extraData,
    typeError,
    valueError,
    activeDimension,
  };

  switch (uiCompType) {
    case "stringMatch":
      Component = <AdFilterStringMatch {...propsList} />;
      break;

    case "fileUpload":
      Component =
        sigviewUserType === "sigview" ? (
          <AdvancedFiltersFileUploadSigview {...propsList} />
        ) : (
          <AdvancedFiltersFileUpload {...propsList} />
        );
      break;

    case "dateSingleSelect":
      Component = (
        <AdFilterDateSelect
          {...propsList}
          calendarType={uiCompType}
          allData={allData}
          user={user}
        />
      );
      break;

    case "dateRangeSelect":
      Component = (
        <AdFilterDateSelect
          {...propsList}
          calendarType={uiCompType}
          allData={allData}
          user={user}
        />
      );
      break;

    default:
      Component = <p>Undefined component type</p>;
  }

  return Component;
}

export default AdvancedFiltersRow;

function AdFilterStringMatch(props) {
  const { t } = useTranslation();
  const {
    itemTitle,
    type,
    newDropDownData,
    handleTypeChange,
    showError,
    value,
    handleValueChange,
    showDeleteIconFlag,
    handleRowDelete,
    handleAddRow,
    showAddIconFlag,
    typeError,
    valueError,
  } = props;

  return (
    <div className="advanced-filters-item-container">
      <div className="advanced-filters-item-title-container">
        <p className="advanced-filters-item-title">{t(itemTitle)}</p>
      </div>
      <SigviewSingleSelect
        value={type}
        data={newDropDownData}
        onChange={handleTypeChange}
        error={showError && typeError.flag}
        helperText={typeError.message}
        minWidth="170px"
        margin={{ right: "5px" }}
        customClassName={`AdvanceFilterDropdownValue${type}`}
      />
      <SigviewTextField
        value={value}
        onChange={(newValue) => handleValueChange(newValue)}
        placeholder="Enter String"
        error={showError && valueError.flag}
        helperText={valueError.message}
        minWidth="210px"
        maxWidth="210px"
        margin={{ right: "5px" }}
      />
      <div
        className={`advanced-filters-item-icon-container ${
          showDeleteIconFlag ? "visible" : "hidden"
        }`}
      >
        <i
          className="material-icons-outlined advanced-filters-item-icon af-delete-icon"
          onClick={handleRowDelete}
        >
          cancel
        </i>
      </div>
      {showAddIconFlag && (
        <div className="advanced-filters-item-icon-container">
          <i
            className="material-icons advanced-filters-item-icon af-add-icon"
            onClick={handleAddRow}
          >
            add
          </i>
        </div>
      )}
    </div>
  );
}

function AdFilterDateSelect(props) {
  const { t } = useTranslation();
  const {
    user,
    itemTitle,
    type,
    newDropDownData,
    handleTypeChange,
    showError,
    value,
    handleValueChange,
    showDeleteIconFlag,
    handleRowDelete,
    handleAddRow,
    showAddIconFlag,
    calendarType,
    typeError,
    valueError,
    allData,
  } = props;

  const handleDateSingleChange = (newDate) => {
    const selectedTimezone = {
      name: "UTC (+00:00)",
      location: "UTC",
      value: {
        hours: 9,
        minutes: 0,
      },
      minutesOffset: 0,
    };
    const format = "ddd MMMM DD, YYYY HH:mm:ss ZZ";
    const startDateProps = {
      selectedDate: newDate,
      selectedTimezone,
      format,
    };
    const startDate = getDateAllVariations(startDateProps);
    const endDateProps = {
      selectedDate: new Date(newDate.valueOf() + 24 * 60 * 60 * 1000), //adding one day to start date to match angular structure
      selectedTimezone,
      format,
    };
    const endDate = getDateAllVariations(endDateProps);
    const newValue = {
      startDate,
      endDate,
    };
    handleValueChange(newValue);
  };

  const handleDateMultiChange = (newDateRange) => {
    const selectedTimezone = {
      name: "UTC (+00:00)",
      location: "UTC",
      value: {
        hours: 9,
        minutes: 0,
      },
      minutesOffset: 0,
    };
    const format = "ddd MMMM DD, YYYY HH:mm:ss ZZ";
    const startDateProps = {
      selectedDate: newDateRange.startDate,
      selectedTimezone,
      format,
    };
    const startDate = getDateAllVariations(startDateProps);
    const endDateProps = {
      selectedDate: new Date(
        addOneDayMinus1SecondMilliseconds(newDateRange.endDate.valueOf())
      ), //adding one day minus 1 milliseconds to start date to match angular structure
      selectedTimezone,
      format,
    };
    const endDate = getDateAllVariations(endDateProps);
    const newValue = {
      startDate,
      endDate,
    };
    handleValueChange(newValue);
  };

  const errorFlag = showError && valueError.flag;
  const minMaxDates = {
    minDate: new Date(config.hardCoded.adFiltersCalendarMinDateEpoch), //need to convert to user system
    maxDate: new Date(parseInt(allData.dateRange.data.endDate)), //need to convert to user system
  };

  return (
    <div className="advanced-filters-item-container">
      <div className="advanced-filters-item-title-container">
        <p className="advanced-filters-item-title">{t(itemTitle)}</p>
      </div>
      <SigviewSingleSelect
        value={type}
        data={newDropDownData}
        onChange={handleTypeChange}
        error={showError && typeError.flag}
        helperText={typeError.message}
        minWidth="170px"
        margin={{ right: "5px" }}
      />
      <div className="advanced-filters-item-date-container">
        <div
          className={`advanced-filters-item-date ${errorFlag ? "error" : ""}`}
        >
          {calendarType === "dateSingleSelect" ? (
            <SigviewDateSingleSelect
              value={{
                startDate: value.startDate.nativeDateObj,
                endDate: value.startDate.nativeDateObj,
              }}
              onChange={handleDateSingleChange}
              format={config.hardCoded.dateFormatFilterMenu}
              minMaxDates={minMaxDates}
              user={user}
            />
          ) : calendarType === "dateRangeSelect" ? (
            <SigviewDateRangeSelect
              value={{
                startDate: value.startDate.nativeDateObj,
                endDate: value.endDate.nativeDateObj,
              }}
              onChange={handleDateMultiChange}
              format={config.hardCoded.dateFormatFilterMenu}
              minMaxDates={minMaxDates}
            />
          ) : null}
        </div>
        {errorFlag && (
          <div className="advanced-filters-item-date-error">
            {valueError.message}
          </div>
        )}
      </div>

      <div
        className={`advanced-filters-item-icon-container ${
          showDeleteIconFlag ? "visible" : "hidden"
        }`}
      >
        <i
          className="material-icons-outlined advanced-filters-item-icon af-delete-icon"
          onClick={handleRowDelete}
        >
          cancel
        </i>
      </div>
      {showAddIconFlag && (
        <div className="advanced-filters-item-icon-container">
          <i
            className="material-icons advanced-filters-item-icon af-add-icon"
            onClick={handleAddRow}
          >
            add
          </i>
        </div>
      )}
    </div>
  );
}
