// * Import required libraies
import React, { useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

// * Import Custom Component
import SigviewIcon from "../../Common/SigviewIcon";
import SigviewTypography from "../../Common/SigviewTypography";
import SigviewTooltip from "../../Common/SigviewTooltip";
import Loader from "../../Loader/Loader";
import WsMetricChart from "./WsMetricChart";
import MetricChartExpandedHeader from "./MetricChartExpandedHeader";

// * Import redux utils
// Selectors
import {
  selectMetricChartHeaderDetails,
  selectMetricCounterData,
  selectMetricSelections,
} from "../../../redux/selectors/standaloneWsSelectors";
// Actions
import {
  updateUserScreen,
  deleteStandaloneWsMetricChart,
} from "../../../redux/actions";

// * Import utils
import { wrapperMetricDownloadRequest } from "../../../utils/analyzeUtils";
import {
  downloadReportFile,
  metricDownloadRequest,
} from "../../../services/api";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Import services
import { masterTrackGaEvent } from "../../../services/ga";

function WsMetricChartHeader(props) {
  const { t } = useTranslation();
  // * Destructure props
  const {
    // REDUX PROPS
    dispatch: ReduxDispatcher,
    user,
    // PARENT PROPS
    id,
    settings = {},
    classes,
    anyError,
    variant = "mainPage",
    metricChartProps = {},
  } = props;
  const {
    showTitle = true,
    showExpandIcon = true,
    showDeleteIcon = true,
  } = settings;

  // * Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const toastId = useRef(null);

  // * Destructure REDUX props
  const metricChartHeaderDetails = useSelector((state) =>
    selectMetricChartHeaderDetails(state, id, themeColors, user)
  );
  const metricCounterData = useSelector((state) =>
    selectMetricCounterData(state, id)
  );
  const metricSelections = useSelector((state) =>
    selectMetricSelections(state, id)
  );

  // * Define required event handlers
  const handleDelete = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "ChartRemove",
      label: "Remove",
    });
    let payload = {
      deleteItemId: id,
    };
    let action = deleteStandaloneWsMetricChart(payload);
    ReduxDispatcher(action);
  };
  const handleChartExpand = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: "ChartExpand",
      label: "Expand",
    });
    const uniqueChartId = `metric_chart_${v4()}`;
    const handleClose = () => {
      // Close the dialog
      const value = {
        open: false,
        title: metricChartHeaderDetails.title,
        onClose: () => {},
        children: null,
      };
      var action = updateUserScreen("sigviewBigDialog", value);
      ReduxDispatcher(action);
    };

    const childComponent = () => {
      // * Define required styles
      const rootCss = {
        padding: "10px 10px 10px 15px",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
      };
      const metricChartFinalProps = {
        ...metricChartProps,
        chartContainerId: uniqueChartId,
        isMetricChartOpen: true,
      };
      // ! Memoization not needed here
      const metricChartStringedProps = JSON.stringify(metricChartFinalProps);

      return (
        <Box css={rootCss}>
          <MetricChartExpandedHeader
            id={id}
            user={user}
            handleDownload={handleDownload}
            metricChartHeaderDetails={metricChartHeaderDetails}
            metricCounterData={metricCounterData}
          />
          <Box
            id={uniqueChartId}
            style={{
              height: "calc(100% - 31px)",
              width: "100%",
              boxSizing: "border-box",
            }}
          ></Box>
          <WsMetricChart
            memoizedStringedChildrenProps={metricChartStringedProps}
          />
        </Box>
      );
    };
    const titleComponent = () => {
      // * Define required styles
      const rootCss = { display: "flex", alignItems: "center" };

      return (
        <Box css={rootCss}>
          <SigviewTypography
            variant="heading"
            style={{ padding: "0px 10px 0px 0px", height: "max-content" }}
          >
            {metricChartHeaderDetails.title}
          </SigviewTypography>
          {metricChartHeaderDetails.isCmFlag && (
            <SigviewTooltip
              title={metricChartHeaderDetails.infoTooltip}
              placement="bottom"
            >
              <Box component="span">
                <SigviewIcon
                  className={`material-icons-round`}
                  iconName="info"
                />
              </Box>
            </SigviewTooltip>
          )}
        </Box>
      );
    };
    // Update activeChart in redux
    const sigviewBigDialogVal = {
      open: true,
      props: {
        title: titleComponent(),
        onClose: handleClose,
        style: { overlayPadding: "100px" },
        children: childComponent(),
      },
    };
    var value = {
      sigviewBigDialog: sigviewBigDialogVal,
    };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);
  };
  const handleDownload = (variant) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "Workspace",
      action: variant === "expanded" ? "ChartExpandDownload" : "ChartDownload",
      label: "Download",
    });

    // Show info toaster
    toastId.current = toast.info(
      <p>
        {t("Download initiated for")} {metricChartHeaderDetails.title}
      </p>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );
    const fetchPayload = wrapperMetricDownloadRequest({
      selections: metricSelections,
      user,
    });
    const fetchProps = {
      payload: fetchPayload,
    };
    const metricDownloadRequestPromise = metricDownloadRequest(fetchProps);
    metricDownloadRequestPromise
      .then((responseDate) => {
        //Initiate download file
        const fileName = responseDate.result.data.downloadFileName;
        const fileID = responseDate.result.data.file;
        const downloadUrl = `${user.apiEndpoints.baseUrl}/downloadFile?fid=${fileID}&fname=${fileName}`;
        // * Old implementation
        // This stopped working in new app server as the opened URL required authentication token
        // window.open doesn't allow sending headers (https://stackoverflow.com/questions/4325968/window-open-with-headers)
        // window.open(downloadUrl);
        // * New implementation
        // Fetching the required data from the download file url and then downloading via blob
        const fetchProps = {
          url: downloadUrl,
        };
        const downloadReportFilePromise = downloadReportFile(fetchProps);
        downloadReportFilePromise
          .then((blob) => {
            // Show success toaster
            toast.dismiss(toastId.current);
            toastId.current = toast.success(
              <p>
                {t("Download completed for")} {metricChartHeaderDetails.title}
              </p>,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            setTimeout(() => {
              toast.dismiss(toastId.current);
            }, 5000);
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          })
          .catch((json) => {
            console.log("API FAILED", json);
            // Show failure toaster
            toast.dismiss(toastId.current);
            toastId.current = toast.error(
              <p>
                {t("Download failed for")} {metricChartHeaderDetails.title}!{" "}
                {json.error}
              </p>,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            setTimeout(() => {
              toast.dismiss(toastId.current);
            }, 5000);
          });
      })
      .catch((json) => {
        // Show failure toaster
        toast.dismiss(toastId.current);
        toastId.current = toast.error(
          <p>
            {t("Download failed for")} {metricChartHeaderDetails.title}!{" "}
            {json.error}
          </p>,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 5000);
        console.log("API FAILED", json);
      });
  };

  // * Define required styles
  const tickerIconStyle = {
    fontSize: "20px",
    color: metricChartHeaderDetails["tickerColor"] + " !important",
  };
  const tickerStyle = {
    color: metricChartHeaderDetails["tickerColor"],
    height: "max-content",
    textTransform: "uppercase",
  };
  const valueStyle = {
    height: "max-content",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: themeColors["primaryColor"],
    textTransform: "uppercase",
  };
  const isDownloadAvailable = user.uiFeatureList.download;
  const {
    title = "",
    value = "72.5M",
    totalValueStatus = "loading",
    tickerChange = "",
    isDeleteVisible = false,
    infoFlag = false,
    infoTooltip = "",
    tickerIconName,
    showTickerChange,
    tickerStatus,
    formattedValue,
    tickerTotalCompareValue,
  } = metricChartHeaderDetails;

  const createCrudType =
    user.screen.activeWorkspace.wsCategory === "shared"
      ? true
      : user.screen.activeWorkspace.wsCategory !== "shared"
      ? user.screen.activeWorkspace.crudType !== "create"
      : false;

  return (
    <>
      <Box className={classes.headerRoot}>
        <Box className={classes.headerLeftContainer}>
          <Box className={classes.headerValueContainer}>
            {totalValueStatus === "loading" && createCrudType && (
              <Loader size="15px" />
            )}
            {totalValueStatus === "success" && (
              <SigviewTooltip title={formattedValue} placement="bottom-start">
                <Box>
                  <SigviewTypography
                    variant="pLargerCounter"
                    style={valueStyle}
                  >
                    {value}
                  </SigviewTypography>
                </Box>
              </SigviewTooltip>
            )}
          </Box>
          <Box className={classes.headerTitleContainer}>
            {showTitle && (
              <SigviewTypography
                style={{
                  height: "max-content",
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  padding: "0px 5px",
                  color: themeColors["secondaryColor"],
                  cursor: "pointer",
                  fontWeight: "500",
                  pointerEvents:
                    metricCounterData.status === "loading" ? "none" : "auto",
                }}
                onClick={handleChartExpand}
                variant="pMediumLarge"
                title={title}
              >
                {title}
              </SigviewTypography>
            )}
          </Box>
          <Box className={classes.headerInfoContainer}>
            {infoFlag && (
              <SigviewTooltip title={infoTooltip} placement="bottom">
                <span>
                  <SigviewIcon
                    className={`material-icons-round ${classes.headerCustomIcon}`}
                    iconName="info"
                  />
                </span>
              </SigviewTooltip>
            )}
          </Box>
          <Box className={classes.headerCompareContainer}>
            {showTickerChange && tickerStatus && (
              <>
                <SigviewIcon
                  className="material-icons-round"
                  iconName={tickerIconName}
                  style={tickerIconStyle}
                />

                <SigviewTooltip
                  title={`Compared With ${tickerTotalCompareValue}`}
                  placement="bottom"
                >
                  <Box>
                    <SigviewTypography style={tickerStyle} variant="pSmall">
                      {tickerChange}
                    </SigviewTypography>
                  </Box>
                </SigviewTooltip>
              </>
            )}
          </Box>
        </Box>
        <Box className={classes.headerRightContainer}>
          {showExpandIcon && createCrudType && (
            <SigviewTooltip title={"Expand Chart"} placement="bottom">
              <span>
                <SigviewIcon
                  className={`${metricCounterData.status} === "loading" ? material-icons-round ${classes.headerCustomIcon} : material-icons-round ${classes.headerCustomIcon} analyzeChartFullView-GA `}
                  iconName="open_in_full"
                  style={{
                    fontSize: "13px !important",
                  }}
                  onClick={handleChartExpand}
                  disabled={
                    metricCounterData.status === "loading" || anyError
                      ? true
                      : false
                  }
                />
              </span>
            </SigviewTooltip>
          )}

          {isDownloadAvailable && createCrudType && (
            <SigviewTooltip title={"Download"} placement="bottom">
              <span>
                <SigviewIcon
                  className={`material-icons-round ${classes.headerCustomIcon} analyzeChartFileDownload-GA`}
                  iconName="file_download"
                  onClick={() => handleDownload(variant)}
                  disabled={
                    metricCounterData.status === "loading" || anyError
                      ? true
                      : false
                  }
                  style={{ fontSize: "16px", padding: "1px 0px 0px 0px" }}
                />
              </span>
            </SigviewTooltip>
          )}

          {isDeleteVisible && showDeleteIcon && (
            <SigviewTooltip title={"Remove"} placement="bottom">
              <span>
                <SigviewIcon
                  className={`material-icons-round ${classes.headerCustomIcon} ${classes.headerDeleteIcon} analyzeChartDelete-GA`}
                  iconName="close"
                  onClick={handleDelete}
                  style={{ fontSize: "17px", padding: "0px 0px 0px 0px" }}
                />
              </span>
            </SigviewTooltip>
          )}
        </Box>
      </Box>
    </>
  );
}
const mapStateToProps = (state) => ({
  allData: state.data,
  user: state.user,
});

export default connect(mapStateToProps)(WsMetricChartHeader);
