// * Import required libraries
import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef,
} from "react";
import axios from "axios";
import { connect } from "react-redux";
import isEqual from "lodash.isequal";
import orderBy from "lodash/orderBy";
import { useTranslation } from "react-i18next";

// * Import lib components
import { Box } from "@material-ui/core";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";
import { UserProvider } from "../../../contexts/AdminContext";

// * Import layouts
import LayoutAdminContent from "../../../layouts/LayoutAdminContent/LayoutAdminContent";
import LayoutRow from "../../../layouts/LayoutRow/LayoutRow";

// * Import custom components
import SigviewTab from "../../../components/SigviewTab";
import SigviewTextFieldAsync from "../../../components/Common/SigviewTextFieldAsync";
import LayoutTopSideBottomAdmin from "../../../layouts/LayoutTopSideBottomAdmin/LayoutTopSideBottomAdmin";
import SigviewBreadcrumb from "../../../components/Common/SigviewBreadcrumb";
import SigviewCheckList from "../../../components/SigviewChecklist/SigviewChecklist";
import SigviewNav from "../../../components/Common/SigviewNav";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SigviewButton from "../../../components/Common/SigviewButton";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import { config } from "../../../config/config";
import DimensionFilters from "../../../components/DimensionFilters";
import { GroupClone } from "./GroupHelpers";
import SigviewHoverPopup from "../../../components/Common/SigviewHoverPopup";
import SigviewIcon from "../../../components/Common/SigviewIcon";

// * Import utils, config & static data
import {
  unWrapperGroup,
  wrapperGroup,
  unWrapperviews,
  userSelection as initialUserSelection,
  validateGroup,
  isSelectionsInvalid,
  sigviewNavData,
  defaultActiveView,
  validateGroupName,
  getHardcodedValueDisabled,
} from "../../../utils/adminUtils";
import {
  getBreadcrumbsData,
  transformDimensionsForPlotly,
  transformMetricsForPlotly,
  capitalize,
  getBreadcrumbsDataFromRoute,
} from "../../../utils/utils";
import { masterTrackGaEvent } from "../../../../js/services/ga/index";

// * Import redux utils
import useReducerLogger from "../../../utils/useReducerLogger";
import {
  updateAdminForm,
  replaceAdminForm,
  updateUserScreen,
} from "../../../redux/actions";
import { adminGroup } from "../../../redux/reducers/admin";

// * Import APIs
import {
  createGroup,
  readGroupById,
  updateGroup,
  nameCheckGroup,
} from "../../../services/api";
import { useHistory } from "react-router";

function Group(props) {
  const { t } = useTranslation();
  //  * Destructure props
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const { dispatch: ReduxDispatcher, user, allData = {} } = props;
  const { views: viewsData } = allData;

  // * Define contexts
  const history = useHistory();

  if (viewsData.status !== "success") {
    history.push("/admin");
  }
  // * Define required states
  const [selections, dispatchSelections] = useReducer(
    useReducerLogger(adminGroup),
    initialUserSelection
  );
  const originalSelections = useRef({});
  const [groupData, setGroupData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });
  const [groupName, setGroupName] = useState({
    status: "success",
    value: user.screen.activeAdminGroup.metadata?.name,
    originalValue: user.screen.activeAdminGroup.metadata?.name,
    message: "",
  });

  // * Define required variables
  const viewsDataUi = unWrapperviews(viewsData.result);
  const activeViewId = selections.activeView.value.id;
  const activeView =
    viewsDataUi.find((row) => row.id === activeViewId) || defaultActiveView;

  // * Define required side effects
  // Define groupData for creating or editing
  useEffect(() => {
    if (user.screen.activeAdminGroup.metadata?.crudType === "create") {
      setGroupData({
        result: {},
        status: "success",
        messsage: "",
      });
    }

    if (user.screen.activeAdminGroup.metadata?.crudType === "edit") {
      const source = axios.CancelToken.source();
      const fetchProps = {
        id: user.screen.activeAdminGroup.payload._id,
        cancelToken: source.token,
      };
      const readGroupId = readGroupById(fetchProps);
      readGroupId
        .then((data) => {
          setGroupData({
            result: data?.result?.data,
            status: "success",
            messsage: "",
          });
        })
        .catch((json) => {
          setGroupData({
            result: [],
            status: "error",
            messsage: "API failed",
          });
          console.log(json);
        });

      return () => {
        setGroupData({
          result: [],
          status: "loading",
          messsage: "",
        });
      };
    }
  }, []);

  // Replacing state wrt initial/api Group Data
  useEffect(() => {
    if (groupData.status === "success") {
      if (user.screen.activeAdminGroup.metadata.crudType === "create") {
        let defaultViews = viewsDataUi.map((row) => {
          let customMetricValue = row.customMetricData.map((row) => {
            return {
              id: row.id,
              status: true,
            };
          });
          if (row.id === "CPR") {
            customMetricValue = customMetricValue.filter(
              (row) =>
                !config.hardCoded.admin.cprCustomMetricIdList.includes(row.id)
            );
          }
          if (row.id === "REAL-TIME-CPR") {
            customMetricValue = customMetricValue.filter(
              (row) =>
                !config.hardCoded.admin.realTimeCprCustomMetricIdList.includes(
                  row.id
                )
            );
          }
          return {
            id: row.id,
            name: row.name,
            subTitle: row.subTitle,
            disabled: false,
            metricValue: row.metricData.map((row) => {
              return {
                id: row.id,
                status: true,
              };
            }),
            dimensionValue: row.DimensionData.map((row) => {
              return {
                id: row.id,
                status: true,
              };
            }),
            customMetricValue: customMetricValue,
            groupSecurityFilter: [],
            groupLevelCustomMetrics: [],
          };
        });
        let defaultviewsObj = defaultViews.reduce(
          (acc, v) => ({ ...acc, [v.id]: v }),
          {}
        );
        const payload = {
          value: {
            ...initialUserSelection,
            name: {
              value: user.screen.activeAdminGroup.metadata.name,
              status: "valid",
              message: "",
            },
            activeView: {
              message: "",
              value: viewsDataUi[0],
              status: "valid",
            },
            selectedView: {
              message: "",
              value: [],
              status: "valid",
            },
            views: {
              ...initialUserSelection.views,
              value: defaultviewsObj,
            },
          },
        };
        // Update original selections
        originalSelections.current = { ...payload.value };
        const action = replaceAdminForm(payload);
        dispatchSelections(action);
      }
      if (user.screen.activeAdminGroup.metadata.crudType === "edit") {
        const newGroupSelections = unWrapperGroup(
          groupData.result,
          viewsDataUi,
          allData
        );
        const validatedGroupSelections = validateGroup(
          newGroupSelections,
          viewsData.result
        );
        const payload = { value: validatedGroupSelections };
        // Update original selections
        originalSelections.current = { ...payload.value };
        const action = replaceAdminForm(payload);
        dispatchSelections(action);
      }
    }
  }, [groupData]);

  // Add validation to selections if selections changes
  useEffect(() => {
    let validatedSelections = validateGroup(selections, viewsData.result);
    if (!isEqual(selections, validatedSelections)) {
      const payload = { value: validatedSelections };
      const action = replaceAdminForm(payload);
      dispatchSelections(action);
    }
  }, [selections]);

  // * Define requried event handlers
  const handleUpdate = () => {
    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: "Requesting to updating group...",
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    const payload = wrapperGroup(
      { ...selections },
      user.reqMetadata,
      viewsDataUi,
      groupData.result,
      user.screen.activeAdminGroup.metadata.crudType
    );

    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
      payload,
    };

    const updateGroupPromise = updateGroup(fetchProps);
    updateGroupPromise
      .then(() => {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: false,
          message: "",
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
        // update screen
        const activeTab = "groups";
        const value = { activeNav: "admin", activeTab };
        var action = updateUserScreen(null, value);
        ReduxDispatcher(action);
        const pathname = "/admin/groups";
        history.push(pathname);
      })
      .catch((json) => {
        console.log("API FAILED", json);
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Requesting Updating Group",
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };
  const handleSave = () => {
    //Update snackbar
    let snackbarPayload = {
      ...user.screen.snackbar,
      open: true,
      message: "Requesting to saved group...",
    };
    ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

    const payload = wrapperGroup(
      { ...selections },
      user.reqMetadata,
      viewsDataUi,
      groupData.result,
      user.screen.activeAdminGroup.metadata.crudType
    );

    const fetchProps = {
      payload,
    };
    const createGroupPromise = createGroup(fetchProps);
    createGroupPromise
      .then(() => {
        //Update snackbar
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: false,
          message: "",
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));

        // update screen
        const activeTab = "groups";
        const value = { activeNav: "admin", activeTab };
        var action = updateUserScreen(null, value);
        ReduxDispatcher(action);

        const pathname = "/admin/groups";
        history.push(pathname);
      })
      .catch((json) => {
        // console.log("api FAILED");
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: json.error || "Requesting Creating Group",
        };
        ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
      });
  };
  const handleClone = () => {
    // Google Analytics Event - Master Admin
    masterTrackGaEvent({
      category: "Admin",
      action: "GroupClone",
      label: selections.id.value,
    });

    const payload = {
      open: true,
      children: (
        <GroupClone
          ReduxDispatcher={ReduxDispatcher}
          initialName={`CloneOf${selections.name.value}`}
          user={user}
          selections={selections}
          viewsDataUi={viewsDataUi}
          groupData={groupData}
        />
      ),
    };
    const action = updateUserScreen("sigviewDialog", payload);
    ReduxDispatcher(action);
  };
  const handleCancel = () => {
    // update screen
    const activeTab = "groups";
    const value = { activeNav: "admin", activeTab };
    var action = updateUserScreen(null, value);
    ReduxDispatcher(action);
    const pathname = "/admin/groups";
    history.push(pathname);
  };
  const handleNameChange = (event, value) => {
    setGroupName((prevState) => ({
      ...prevState,
      value: value,
      message: "",
    }));
  };
  const handleClickAway = () => {
    // 1. If group name is same as before, don't call the API
    // 2. If the group name is invalid, revert the name to original and no need to call API
    // 3. If the group name is valid, call the API and reset state based on response
    const { status: groupNameStatus, message: groupNameMessage } =
      validateGroupName(groupName.value);
    // 1. If group name is same as before, don't call the API
    if (originalSelections.current.name.value === groupName.value) {
      setGroupName((prevState) => ({
        ...prevState,
        value: originalSelections.current.name.value,
        originalValue: originalSelections.current.name.value,
      }));
      const payload = {
        key: "name",
        value: originalSelections.current.name.value,
      };
      const action = updateAdminForm(payload);
      dispatchSelections(action);
    } else {
      if (groupNameStatus === "invalid") {
        // 2. If the group name is invalid, revert the name to original and no need to call API
        setGroupName((prevState) => ({
          ...prevState,
          status: "success",
          value: prevState.originalValue,
        }));
        let snackbarPayload = {
          ...user.screen.snackbar,
          open: true,
          message: groupNameMessage,
        };
        const action = updateUserScreen("snackbar", snackbarPayload);
        ReduxDispatcher(action);
      } else {
        // 3. If the group name is valid, call the API and reset state based on response
        setGroupName((prevState) => ({
          ...prevState,
          status: "loading",
        }));

        // API to check group name availability
        const fetchProps = {
          title: groupName.value,
          organization: user?.reqMetadata?.organization,
        };
        const nameCheckGroupPromise = nameCheckGroup(fetchProps);
        nameCheckGroupPromise
          .then((reponseData) => {
            if (reponseData.status.statusCode === "200") {
              setGroupName((prevState) => ({
                ...prevState,
                status: "success",
                originalValue: prevState.value,
              }));
              const payload = {
                key: "name",
                value: groupName.value,
              };
              const action = updateAdminForm(payload);
              dispatchSelections(action);
            } else {
              let snackbarPayload = {
                ...user.screen.snackbar,
                open: true,
                message: "Requested group name failed",
              };
              ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
            }
          })
          .catch((json) => {
            setGroupName((prevState) => ({
              ...prevState,
              status: "success",
              value: prevState.originalValue,
            }));
            let snackbarPayload = {
              ...user.screen.snackbar,
              open: true,
              message: json.error,
            };
            ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
          });
      }
    }
  };
  const handleViewChange = (e, value) => {
    const payload = {
      key: "activeView",
      value: { id: value.id },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleViewsDataReload = () => {
    // setViewsDataReload(new Date().valueOf());
    // TODO Update action to store so that views data can reload in App.js
    // ! HACK
    window.location.reload();
  };
  const handleDimensionChange = (newValue) => {
    // reducer dispatch action with value

    let newdata;
    const newSelections = { ...selections };
    const allViewsValue = newSelections.views.value;
    const activeViewId = newSelections.activeView.value.id;
    // Is clicked metric already present
    const presentFlag = allViewsValue[activeViewId].dimensionValue.find(
      (row) => newValue.id === row.id
    );
    if (presentFlag) {
      newdata = allViewsValue[activeViewId].dimensionValue.filter(
        (row) => newValue.id !== row.id
      );
    } else {
      newdata = [
        ...allViewsValue[activeViewId].dimensionValue,
        { id: newValue.id, status: true },
      ];

      newdata = orderBy(newdata, ["id"], ["asc"]);
    }

    const payload = {
      key: "dimensionValue",
      value: {
        newdata,
        activeViewId: newSelections.activeView.value.id,
      },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleMetricChange = (newValue) => {
    // reducer dispatch action with value

    let newdata;
    const newSelections = { ...selections };
    const allViewsValue = newSelections.views.value;
    const activeViewId = newSelections.activeView.value.id;
    // Is clicked metric already present
    const presentFlag = allViewsValue[activeViewId].metricValue.find(
      (row) => newValue.id === row.id
    );
    if (presentFlag) {
      newdata = allViewsValue[activeViewId].metricValue.filter(
        (row) => newValue.id !== row.id
      );
    } else {
      newdata = [
        ...allViewsValue[activeViewId].metricValue,
        { id: newValue.id, status: true },
      ];

      newdata = orderBy(newdata, ["id"], ["asc"]);
    }

    const payload = {
      key: "metricValue",
      value: {
        newdata,
        activeViewId: newSelections.activeView.value.id,
      },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleCustomMetricChange = (newValue) => {
    // reducer dispatch action with value

    let newdata;
    const newSelections = { ...selections };
    const allViewsValue = newSelections.views.value;
    const activeViewId = newSelections.activeView.value.id;
    // Is clicked metric already present
    const presentFlag = allViewsValue[activeViewId].customMetricValue.find(
      (row) => newValue.id === row.id
    );
    if (presentFlag) {
      newdata = allViewsValue[activeViewId].customMetricValue.filter(
        (row) => newValue.id !== row.id
      );
    } else {
      newdata = [
        ...allViewsValue[activeViewId].customMetricValue,
        { id: newValue.id, status: true },
      ];

      newdata = orderBy(newdata, ["id"], ["asc"]);
    }

    const payload = {
      key: "customMetricValue",
      value: {
        newdata,
        activeViewId: newSelections.activeView.value.id,
      },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleClearDimension = () => {
    let newdata = [
      {
        id: "D001",
        status: true,
      },
      {
        id: "D002",
        status: true,
      },
      {
        id: "D150",
        status: true,
      },
    ];

    if (selections.views.value[selections.activeView.value.id] === undefined) {
      selections.views.value[selections.activeView.value.id] = {};
    }
    if (
      selections.views.value[selections.activeView.value.id].dimensionValue ===
      undefined
    ) {
      selections.views.value[selections.activeView.value.id].dimensionValue =
        [];
      selections.views.value[selections.activeView.value.id].metricValue = [];
      selections.views.value[selections.activeView.value.id].customMetricValue =
        [];
    }

    const payload = {
      key: "dimensionValue",
      value: {
        newdata,
        activeViewId: selections.activeView.value.id,
      },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleClearMetric = () => {
    let newdata = [];

    if (selections.views.value[selections.activeView.value.id] === undefined) {
      selections.views.value[selections.activeView.value.id] = {};
    }
    if (
      selections.views.value[selections.activeView.value.id].dimensionValue ===
      undefined
    ) {
      selections.views.value[selections.activeView.value.id].dimensionValue =
        [];
      selections.views.value[selections.activeView.value.id].metricValue = [];
      selections.views.value[selections.activeView.value.id].customMetricValue =
        [];
    }

    const payload = {
      key: "metricValue",
      value: {
        newdata,
        activeViewId: selections.activeView.value.id,
      },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleClearCustomMetric = () => {
    let newdata = [];

    if (selections.views.value[selections.activeView.value.id] === undefined) {
      selections.views.value[selections.activeView.value.id] = {};
    }
    if (
      selections.views.value[selections.activeView.value.id].dimensionValue ===
      undefined
    ) {
      selections.views.value[selections.activeView.value.id].dimensionValue =
        [];
      selections.views.value[selections.activeView.value.id].metricValue = [];
      selections.views.value[selections.activeView.value.id].customMetricValue =
        [];
    }

    const payload = {
      key: "customMetricValue",
      value: {
        newdata,
        activeViewId: selections.activeView.value.id,
      },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleSelectedView = (e, newValue) => {
    let newdata;

    const newSelections = { ...selections };
    const newSelectedValue = newSelections.selectedView.value;
    // const activeViewId = newSelections.activeView.value.id;

    const presentFlag = newSelectedValue.find((row) => newValue.id === row.id);

    // let presentFlag = selections.selectedView.value.find(
    //   (row) => newValue.id === row.id
    // );

    if (presentFlag) {
      newdata = newSelectedValue.filter((row) => newValue.id !== row.id);
    } else {
      newdata = [
        ...newSelectedValue,
        {
          id: newValue.id,
          title: newValue.id,
        },
      ];

      newdata = orderBy(newdata, ["id"], ["asc"]);

      //   selections.selectedView.value.push({
      //   id: newValue.id,
      //   title: newValue.id,
      // });
      // newdata = newSelections.newSelectedValue;
      // selections.selectedView.value;
    }

    // setData({ ...data, activeView: { ...data.activeView, value: newdata } });

    // reducer dispatch action with value
    const payload = {
      key: "selectedView",
      value: newdata,
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleSelectAllDimension = () => {
    if (selections.views.value[selections.activeView.value.id] === undefined) {
      selections.views.value[selections.activeView.value.id] = {};
    }
    if (
      selections.views.value[selections.activeView.value.id].dimensionValue ===
      undefined
    ) {
      selections.views.value[selections.activeView.value.id].dimensionValue =
        [];
      selections.views.value[selections.activeView.value.id].metricValue = [];
      selections.views.value[selections.activeView.value.id].customMetricValue =
        [];
    }

    let newdata = viewsDataUi
      .find((row) => row.id === selections.activeView.value.id)
      .DimensionData.map((row) => {
        return {
          id: row.id,
          status: true,
        };
      });

    const payload = {
      key: "dimensionValue",
      value: {
        newdata,
        activeViewId: selections.activeView.value.id,
      },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleSelectAllMetric = () => {
    if (selections.views.value[selections.activeView.value.id] === undefined) {
      selections.views.value[selections.activeView.value.id] = {};
    }
    if (
      selections.views.value[selections.activeView.value.id].dimensionValue ===
      undefined
    ) {
      selections.views.value[selections.activeView.value.id].dimensionValue =
        [];
      selections.views.value[selections.activeView.value.id].metricValue = [];
      selections.views.value[selections.activeView.value.id].customMetricValue =
        [];
    }

    let newdata = viewsDataUi
      .find((row) => row.id === selections.activeView.value.id)
      .metricData.map((row) => {
        return {
          id: row.id,
          status: true,
        };
      });

    const payload = {
      key: "metricValue",
      value: {
        newdata,
        activeViewId: selections.activeView.value.id,
      },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleSelectAllCustomMetric = () => {
    if (selections.views.value[selections.activeView.value.id] === undefined) {
      selections.views.value[selections.activeView.value.id] = {};
    }
    if (
      selections.views.value[selections.activeView.value.id].dimensionValue ===
      undefined
    ) {
      selections.views.value[selections.activeView.value.id].dimensionValue =
        [];
      selections.views.value[selections.activeView.value.id].metricValue = [];
      selections.views.value[selections.activeView.value.id].customMetricValue =
        [];
    }

    let newdata = viewsDataUi
      .find((row) => row.id === selections.activeView.value.id)
      .customMetricData.map((row) => {
        return {
          id: row.id,
          status: true,
        };
      });

    const payload = {
      key: "customMetricValue",
      value: {
        newdata,
        activeViewId: selections.activeView.value.id,
      },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleDimensionFiltersChange = (value) => {
    const payload = {
      key: "groupSecurityFilter",
      value: {
        newdata: value,
        activeViewId: selections.activeView.value.id,
      },
    };
    const action = updateAdminForm(payload);
    dispatchSelections(action);
  };
  const handleActiveTab = (e, id) => {
    //Return to home (will be controlled by router later)

    // Google Analytics Event - Master Admin
    masterTrackGaEvent({
      category: "Admin",
      action: `${capitalize(id)}Open`,
      label: "Breadcrumb",
    });

    const activeTab = id;
    const value = { activeNav: "admin", activeTab };
    const action = updateUserScreen(null, value);
    ReduxDispatcher(action);
  };

  const handleBreadcrumbChange = (event, value) => {
    history.push(value.path);
  };

  // * Define required static variables for props
  const activeTab = user.screen.activeTab;
  const breadcrumbsData = getBreadcrumbsDataFromRoute(user, history);
  const layoutTopSideBottomAdminProps = {
    activeTab,
    handleActiveTab,
  };
  const isValid = isSelectionsInvalid(selections).status === "valid";
  const areSelectionsEqual = isEqual(originalSelections.current, selections);
  const isUpdateDisabled = areSelectionsEqual ? true : !isValid ? true : false;
  // Making Dimension Filters Data
  const activeViewData = allData.views.result.find(
    (row) => row.id === selections.activeView.value.id
  );
  var filtersDimData = [...allData.plotlyDimensions];
  if (activeViewData)
    filtersDimData = transformDimensionsForPlotly(activeViewData.dimensions);
  var filtersMetricsData = [...allData.plotlyMetrics];
  if (activeViewData)
    filtersMetricsData = transformMetricsForPlotly(
      activeViewData.metrics,
      activeViewData.customMetrics
    );
  // Styles
  const layoutRowStyle = {
    display: "flex",
    alignItems: "center",
    height: "50px",
    padding: "0px 25px",
  };
  const layoutRowStyle2 = {
    display: "flex",
    height: "50px",
    padding: "0px 25px",
    justifyContent: "flex-end",
    alignItems: "center",
    " & > div:firstChild": { paddng: "100px" },
  };
  const jpStyle1 = {
    fontSize: "12px",
    color: "#46596a",
    height: "25px",
    fontWeight: "400",
    letterSpacing: "0.0125em",
    lineHeight: "2",
    padding: "7px 0px 7px 15px",
    height: "max-content",
  };
  const jpStyle2 = {
    fontSize: "12px",
    color: "#46596a",
    height: "25px",
    fontWeight: "400",
    letterSpacing: "0.0125em",
    lineHeight: "2",
    padding: "7px 0px 7px 7px",
    height: "max-content",
  };
  const formTitleStyling = {
    height: "max-content",
    padding: "0px 10px 0px 5px",
  };
  const attributeTitleStyling = {
    height: "max-content",
    padding: "7px 0px 7px 15px",
  };
  const box1Style = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  };
  const textFieldCustomStyle = {
    textFieldFontSize: "12px",
    wrapperBorder: `1px solid ${themeColors["secondaryColorLighter"]}`,
  };

  // * HARDCODED
  const internalGroupIds = ["openx_internal", "openx_internal_prebid"];
  let isInternalGroup = internalGroupIds.includes(selections.id.value);

  const modifiedCustomMetricData =
    !isInternalGroup && selections.activeView.value.id === "CPR"
      ? getHardcodedValueDisabled(
          activeView.customMetricData,
          config.hardCoded.admin.cprCustomMetricIdList
        )
      : !isInternalGroup && selections.activeView.value.id === "REAL-TIME-CPR"
      ? getHardcodedValueDisabled(
          activeView.customMetricData,
          config.hardCoded.admin.realTimeCprCustomMetricIdList
        )
      : activeView.customMetricData;
  const initialDimensionFilters =
    selections.views.value[selections.activeView.value.id]
      ?.groupSecurityFilter || [];
  const filtersSettings = {
    isReadOnly: false,
    isAdvancedFilterVisible: false,
    isExcludeAllowed: false,
    addFilterTitle: "Click to Add Group Security Filters",
    rowTitle:
      initialDimensionFilters.length === 0 ? false : "Group Security Filters:",
  };

  return (
    <>
      <LayoutTopSideBottomAdmin {...layoutTopSideBottomAdminProps}>
        <SigviewBreadcrumb
          data={breadcrumbsData} //data
          onClick={handleBreadcrumbChange} //onClick
        />
        {(viewsData.status === "loading" || groupData.status === "loading") && (
          <Loader />
        )}
        {(viewsData.status === "error" || groupData.status === "error") && (
          <ErrorHandler
            message={viewsData.message || config.hardCoded.uiErrorMessage}
            reloadFlag={true}
            onReload={handleViewsDataReload}
          />
        )}
        {viewsData.status && groupData.status === "success" && (
          <>
            <LayoutRow style={layoutRowStyle}>
              <>
                <SigviewTypography style={formTitleStyling}>
                  Name:
                </SigviewTypography>
                <span style={{ color: "red", transform: "translateX(-10px)" }}>
                  *
                </span>
                <Box css={{ width: "200px", marginRight: "5px" }}>
                  <SigviewTextFieldAsync
                    value={groupName.value}
                    onChange={handleNameChange}
                    status={groupName.status}
                    onClickAway={handleClickAway}
                    customStyle={textFieldCustomStyle}
                    tooltipTitle="Type and press enter to check if group name is valid"
                  />
                </Box>
                <SigviewHoverPopup
                  title="Group Name Rules"
                  data={config.hardCoded.adminGroupNameRules}
                >
                  <SigviewIcon
                    className="material-icons-round"
                    iconName="info"
                  />
                </SigviewHoverPopup>
              </>
            </LayoutRow>
            <UserProvider value={"calc(100% - 85px)"}>
              <LayoutAdminContent
                fullHeight={"calc(100% - 134px)"}
                padding="0px 25px"
              >
                <Box css={box1Style}>
                  <SigviewTypography
                    style={{
                      padding: "10px",
                      height: "45px !important",
                      boxSizing: "border-box !important",
                      color: themeColors["primaryColor"],
                    }}
                  >
                    Views
                  </SigviewTypography>
                  <SigviewTab
                    disabled={false}
                    data={viewsDataUi}
                    selectedView={selections.selectedView.value}
                    activeView={selections.activeView.value}
                    handleActiveView={handleViewChange}
                    handleSelectedView={handleSelectedView}
                  />
                </Box>

                <SigviewNav
                  data={sigviewNavData}
                  value={selections.activeTab.value.id}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: `${themeColors["secondaryColorLightest"]} `,
                      borderRadius: "3px 3px 0px 0px",
                      // height: "100%",
                      width: "100%",
                    }}
                  >
                    <span style={jpStyle1}>{t("Dimensions")}</span>
                    <span style={jpStyle2}>
                      {selections.views.value[selections.activeView.value.id]
                        ?.dimensionValue.length || 0}
                      /{activeView.DimensionData.length}
                    </span>
                  </div>
                  <SigviewCheckList
                    disabled={false}
                    data={activeView.DimensionData}
                    selections={{
                      userSelection:
                        selections.views.value[selections.activeView.value.id]
                          ?.dimensionValue || [],
                      activeItem: selections.activeView.value,
                      filter:
                        selections.views.value[
                          selections.activeView.value.id
                        ]?.groupSecurityFilter?.map((row) => row.id) || [],
                    }}
                    onChange={handleDimensionChange}
                    handleClearAll={handleClearDimension}
                    handleSelectAll={handleSelectAllDimension}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: `${themeColors["secondaryColorLightest"]} `,
                      borderRadius: "3px 3px 0px 0px",
                      // height: "100%",
                      width: "100%",
                    }}
                  >
                    <span style={jpStyle1}>{t("Metrics")}</span>
                    <span style={jpStyle2}>
                      {selections.views.value[selections.activeView.value.id]
                        ?.metricValue.length || 0}
                      /{activeView.metricData.length}
                    </span>
                  </div>
                  <SigviewCheckList
                    disabled={false}
                    data={activeView.metricData}
                    selections={{
                      userSelection:
                        selections.views.value[selections.activeView.value.id]
                          ?.metricValue || [],
                      activeItem: selections.activeView.value,
                    }}
                    onChange={handleMetricChange}
                    handleClearAll={handleClearMetric}
                    handleSelectAll={handleSelectAllMetric}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: `${themeColors["secondaryColorLightest"]} `,
                      borderRadius: "3px 3px 0px 0px",
                      // height: "100%",
                      width: "100%",
                    }}
                  >
                    <span style={jpStyle1}>{t("Custom Metrics")}</span>
                    <span style={jpStyle2}>
                      {selections.views.value[selections.activeView.value.id]
                        ?.customMetricValue.length || 0}
                      /{activeView.customMetricData.length}
                    </span>
                  </div>
                  <SigviewCheckList
                    disabled={false}
                    data={modifiedCustomMetricData}
                    selections={{
                      userSelection:
                        selections.views.value[selections.activeView.value.id]
                          ?.customMetricValue || [],
                      activeItem: selections.activeView.value,
                    }}
                    onChange={handleCustomMetricChange}
                    handleClearAll={handleClearCustomMetric}
                    handleSelectAll={handleSelectAllCustomMetric}
                  />
                </div>

                <div
                  className="sigview-styled-scroller-thin"
                  style={{
                    paddingTop: "10px",
                    height: "100%",
                    overflow: "auto",
                    boxSizing: "border-box",
                  }}
                >
                  <DimensionFilters
                    initialDimensionFilters={initialDimensionFilters}
                    initialTimeFilters={user.timeFilters}
                    onDimensionFiltersChange={handleDimensionFiltersChange}
                    settings={filtersSettings}
                    filtersData={{
                      filtersDimData: filtersDimData,
                      filtersMetricsData: filtersMetricsData,
                    }}
                    selections={{
                      activeView: selections.activeView.value.id,
                      activeOrg: user?.reqMetadata?.organization,
                    }}
                  />
                </div>
              </LayoutAdminContent>
            </UserProvider>
            <LayoutRow style={layoutRowStyle2}>
              <SigviewButton
                title="Cancel"
                variant="outlined"
                onClick={handleCancel}
                style={{ margin: { left: "10px" } }}
              />

              {user.screen.activeAdminGroup.metadata.crudType === "edit" && (
                <SigviewButton
                  title="Clone"
                  variant="contained"
                  onClick={handleClone}
                  disabled={!isValid}
                  style={{ margin: { left: "10px" } }}
                  customClassName="adminGroupClone-GA"
                />
              )}
              {user.screen.activeAdminGroup.metadata.crudType === "create" && (
                <SigviewButton
                  title="Save"
                  variant="contained"
                  onClick={handleSave}
                  disabled={!isValid}
                  style={{ margin: { left: "10px" } }}
                  customClassName="SaveNewGroup-GA"
                />
              )}
              {user.screen.activeAdminGroup.metadata.crudType === "edit" && (
                <SigviewButton
                  title="Update"
                  variant="contained"
                  onClick={handleUpdate}
                  disabled={isUpdateDisabled}
                  style={{ margin: { left: "10px" } }}
                  customClassName="updateGroup-GA"
                />
              )}
            </LayoutRow>
          </>
        )}
      </LayoutTopSideBottomAdmin>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(Group);
