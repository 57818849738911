import C from "../constants";
import { getSigviewUserType, getBaseUrlEndpoints } from "../../utils/utils";
import { initialAuthData } from "../stateData";

export default function auth(state = {}, action) {
  const { type = "", payload = {} } = action;
  let newState = {};

  switch (type) {
    case C.UPDATE_AUTH_LOGIN:
      newState = {
        ...state,
        authData: payload.xAuthToken,
        status: payload.status,
        isTokenPresent: payload.isTokenPresent,
        isTokenValid: payload.isTokenValid,
      };
      break;

    case C.UPDATE_AUTH_LOADING:
      newState = {
        ...state,
        authData: payload.authData,
        status: payload.status,
        isTokenPresent: payload.isTokenPresent,
      };
      break;

    case C.UPDATE_AUTH_PUBLIC_URL:
      newState = {
        ...state,
        status: payload.status,
      };
      break;

    case C.UPDATE_AUTH_ERROR:
      newState = {
        ...state,
        isTokenValid: payload.isTokenValid,
        status: payload.status,
      };
      break;

    case C.UPDATE_AUTH_SUCCESS:
      newState = {
        ...state,
        isTokenValid: payload.isTokenValid,
        status: payload.status,
      };
      break;

    case C.LOGOUT:
      newState = {
        ...initialAuthData,
        status: "logout",
      };
      break;

    case C.SSO_LOGOUT:
      newState = {
        ...initialAuthData,
        status: "logoutOaa",
        ssoType: "oaa",
      };
      break;

    default:
      newState = { ...state };
  }
  const apiEndpoints = getBaseUrlEndpoints();
  const userType = getSigviewUserType();
  newState = {
    ...newState,
    sigviewUserType: userType,
    apiEndpoints: apiEndpoints,
  };

  return newState;
}
