import C from "./constants";

export const changeTheme = (data) => ({
  type: C.CHANGE_THEME,
  data,
});

export const updateData = (key, data) => ({
  type: C.UPDATE_DATA,
  key,
  data,
});

export const updateAllDateRange = (viewId, viewData, viewsLength) => ({
  type: C.UPDATE_ALL_DATE_RANGE,
  viewId,
  viewData,
  viewsLength,
});

export const updateUserInfo = (data) => ({
  type: C.UPDATE_USER_INFO,
  data,
});

export const updateUserData = (key, data) => ({
  type: C.UPDATE_USER_DATA,
  key,
  data,
});

export const updateAuth = (data) => ({
  type: C.UPDATE_AUTH,
  data,
});

export const updateUserBannerStatus = (data) => ({
  type: C.UPDATE_USER_BANNER_STATUS,
  data,
});

export const updateUserMetadata = (data) => ({
  type: C.UPDATE_USER_METADATA,
  data,
});

export const updatePlotlyMetricsDimensionsList = () => ({
  type: C.UPDATE_PLOTLY_METRICS_DIMENSIONS_LIST,
});

export const updateUserScreen = (key, data) => ({
  type: C.UPDATE_USER_SCREEN,
  key,
  data,
});

export const resetWorkspaceAndMsv = () => ({
  type: C.RESET_WORKSPACE_AND_MSV,
});

export const updateChangeFlag = (key, data) => ({
  type: C.UPDATE_CHANGE_FLAG,
  key,
  data,
});

export const updateVersion = (key, data) => ({
  type: C.UPDATE_VERSION,
  key,
  data,
});

export const emptyAllData = () => ({
  type: C.EMPTY_ALL_DATA,
});

export const updateTimeFilters = (payload) => ({
  type: C.UPDATE_TIME_FILTERS,
  ...payload,
});

export const updateTimeFiltersWithDefaultDates = (payload) => ({
  type: C.UPDATE_TIME_FILTERS_WITH_DEFAULT_DATES,
  ...payload,
});

export const timezoneChanged = (payload) => ({
  type: C.TIMEZONE_CHANGED,
  ...payload,
});

export const datePresetChanged = (payload) => ({
  type: C.DATE_PRESET_CHANGED,
  ...payload,
});

export const dateSelectedChanged = (payload) => ({
  type: C.DATE_SELECTED_CHANGED,
  ...payload,
});

export const replaceTimeFilters = (newTimeFilters) => ({
  type: C.REPLACE_TIME_FILTERS,
  newTimeFilters,
});

export const logout = () => ({
  type: C.LOGOUT,
});

export const ssoLogout = () => ({
  type: C.SSO_LOGOUT,
});

export const updateThemeColors = (data) => ({
  type: C.UPDATE_THEME_COLORS,
  data,
});

export const updateDialogInfo = (data) => ({
  type: C.UPDATE_DIALOG_INFO,
  data,
});

export const replaceAllDimensionFilters = (payload) => ({
  type: C.REPLACE_ALL_DIMENSION_FILTERS,
  ...payload,
});

export const addFilter = (payload) => ({
  type: C.ADD_FILTER,
  ...payload,
});

export const removeFilter = (payload) => ({
  type: C.REMOVE_FILTER,
  ...payload,
});

export const changeFilterType = (payload) => ({
  type: C.CHANGE_FILTER_TYPE,
  ...payload,
});

export const addFilterValues = (payload) => ({
  type: C.ADD_FILTER_VALUES,
  ...payload,
});

export const removeFilterValues = (payload) => ({
  type: C.REMOVE_FILTER_VALUES,
  ...payload,
});

export const removeAdvancedFilter = (payload) => ({
  type: C.REMOVE_ADVANCED_FILTER,
  ...payload,
});

export const changeAdvancedFilterType = (payload) => ({
  type: C.CHANGE_ADVANCED_FILTER_TYPE,
  ...payload,
});

export const changeAdvancedFilterValue = (payload) => ({
  type: C.CHANGE_ADVANCED_FILTER_VALUE,
  ...payload,
});

export const changeAdvancedFilterExtraData = (payload) => ({
  type: C.CHANGE_ADVANCED_FILTER_EXTRA_DATA,
  ...payload,
});

export const addAdvancedFilter = (payload) => ({
  type: C.ADD_ADVANCED_FILTER,
  ...payload,
});

export const replaceAllAdvancedFilters = (payload) => ({
  type: C.REPLACE_ALL_ADVANCED_FILTERS,
  ...payload,
});

export const updateUserApiEndpoints = (payload) => ({
  type: C.UPDATE_USER_API_ENDPOINTS,
  ...payload,
});

export const updateUserType = (payload) => ({
  type: C.UPDATE_USER_TYPE,
  ...payload,
});

export const updateUserFeatureAccessList = (payload) => ({
  type: C.UPDATE_USER_FEATURE_ACCESS_LIST,
  ...payload,
});

export const redirectToAngular = () => ({
  type: C.REDIRECT_TO_ANGULAR,
});

export const compareDateSelectedChanged = (payload) => ({
  type: C.COMPARE_DATE_SELECTED_CHANGED,
  ...payload,
});

export const toggleCompare = (payload) => ({
  type: C.TOGGLE_COMPARE,
  ...payload,
});

export const updateCalendarComparisonType = (payload) => ({
  type: C.UPDATE_CALENDAR_COMPARISON_TYPE,
  ...payload,
});

export const updateActiveCompareDateRow = (payload) => ({
  type: C.UPDATE_ACTIVE_COMPARE_DATE_ROW,
  ...payload,
});

export const addCompareRow = (payload) => ({
  type: C.ADD_COMPARE_ROW,
  ...payload,
});

export const removeCompareRow = (payload) => ({
  type: C.REMOVE_COMPARE_ROW,
  ...payload,
});

export const compareDatePresetChanged = (payload) => ({
  type: C.COMPARE_DATE_PRESET_CHANGE,
  ...payload,
});

export const addMetricFilter = (payload) => ({
  type: C.ADD_METRIC_FILTER,
  ...payload,
});

export const removeMetricFilter = (payload) => ({
  type: C.REMOVE_METRIC_FILTER,
  ...payload,
});

export const changeMetricFilterType = (payload) => ({
  type: C.CHANGE_METRIC_FILTER_TYPE,
  ...payload,
});

export const changeMetricFilterValue = (payload) => ({
  type: C.CHANGE_METRIC_FILTER_VALUE,
  ...payload,
});

export const replaceAllMetricFilters = (payload) => ({
  type: C.REPLACE_ALL_METRIC_FILTERS,
  ...payload,
});

export const changeMetricFilterMetricId = (payload) => ({
  type: C.CHANGE_METRIC_FILTER_METRIC_ID,
  ...payload,
});

export const changeAbsoluteChange = (payload) => ({
  type: C.CHANGE_ABSOLUTE_CHANGE,
  ...payload,
});

export const updateStandaloneChartForm = (payload) => ({
  type: C.UPDATE_STANDALONE_CHART_FORM,
  ...payload,
});

export const replaceStandaloneChartForm = (value) => ({
  type: C.REPLACE_STANDALONE_CHART_FORM,
  value,
});

export const updateStandaloneDsForm = (payload) => ({
  type: C.UPDATE_STANDALONE_DS_FORM,
  payload,
});

export const replaceStandaloneDsForm = (payload) => ({
  type: C.REPLACE_STANDALONE_DS_FORM,
  payload,
});

export const updateStandaloneDsFormMetricsData = (payload) => ({
  type: C.UPDATE_STANDALONE_DS_FORM_METRICS_DATA,
  payload,
});

export const updateStandaloneDsFormChartData = (payload) => ({
  type: C.UPDATE_STANDALONE_DS_FORM_CHART_DATA,
  payload,
});

export const addStandaloneDsFormWidgetChart = (payload) => ({
  type: C.ADD_STANDALONE_DS_FORM_WIDGET_CHART,
  payload,
});

export const updateStandaloneDsFormMultipleKpis = (payload) => ({
  type: C.UPDATE_STANDALONE_DS_FORM_MULTIPLE_KPIS,
  payload,
});

export const updateStandaloneDsFormMultipleCharts = (payload) => ({
  type: C.UPDATE_STANDALONE_DS_FORM_MULTIPLE_CHARTS,
  payload,
});

export const updateStandaloneDsFormMultipleFields = (payload) => ({
  type: C.UPDATE_STANDALONE_DS_FORM_MULTIPLE_FIELDS,
  payload,
});

export const reloadDsChartData = (payload) => ({
  type: C.RELOAD_DS_CHART_DATA,
  payload,
});

export const reloadDsMetricsData = (payload) => ({
  type: C.RELOAD_DS_METRICS_DATA,
  payload,
});

export const removeDsWidget = (payload) => ({
  type: C.REMOVE_DS_WIDGET,
  payload,
});

export const updateStandaloneDsFormChartObject = (payload) => ({
  type: C.UPDATE_STANDALONE_DS_FORM_CHART_OBJECT,
  payload,
});

export const updateStandaloneDsFormChartName = (payload) => ({
  type: C.UPDATE_STANDALONE_DS_FORM_CHART_NAME,
  payload,
});

export const updateStandaloneDsFormWidget = (payload) => ({
  type: C.UPDATE_STANDALONE_DS_FORM_WIDGET,
  payload,
});

export const replaceStandaloneDsFormWidget = (payload) => ({
  type: C.REPLACE_STANDALONE_DS_FORM_WIDGET,
  payload,
});

export const changeStep = (payload) => ({
  type: C.CHANGE_STEP,
  payload,
});

export const updateAdminForm = (payload) => ({
  type: C.UPDATE_ADMIN_FORM,
  payload,
});

export const updateAdminAwsForm = (payload) => ({
  type: C.UPDATE_ADMIN_AWS_FORM,
  payload,
});

export const replaceAdminForm = (payload) => ({
  type: C.REPLACE_ADMIN_FORM,
  payload,
});

export const replaceAdminAwsForm = (payload) => ({
  type: C.REPLACE_ADMIN_AWS_FORM,
  payload,
});

export const updateAttributeDimensionForm = (payload) => ({
  type: C.UPDATE_ATTRIBUTE_DIMENSION_FORM,
  payload,
});

export const updateAttributeDimensionFormWholeKey = (payload) => ({
  type: C.UPDATE_ATTRIBUTE_DIMENSION_FORM_WHOLE_KEY,
  payload,
});

export const updateAttributeMetricFormWholeKey = (payload) => ({
  type: C.UPDATE_ATTRIBUTE_METRIC_FORM_WHOLE_KEY,
  payload,
});

export const updateAttributeCustomMetricFormWholeKey = (payload) => ({
  type: C.UPDATE_ATTRIBUTE_CUSTOM_METRIC_FORM_WHOLE_KEY,
  payload,
});

export const replaceAttributeDimensionForm = (payload) => ({
  type: C.REPLACE_ATTRIBUTE_DIMENSION_FORM,
  payload,
});

export const updateAttributeMetricForm = (payload) => ({
  type: C.UPDATE_ATTRIBUTE_METRIC_FORM,
  payload,
});

export const replaceAttributeMetricForm = (payload) => ({
  type: C.REPLACE_ATTRIBUTE_METRIC_FORM,
  payload,
});

export const updateAttributeCustomMetricForm = (payload) => ({
  type: C.UPDATE_ATTRIBUTE_CUSTOM_METRIC_FORM,
  payload,
});

export const replaceAttributeCustomMetricForm = (payload) => ({
  type: C.REPLACE_ATTRIBUTE_CUSTOM_METRIC_FORM,
  payload,
});

//* Global Filters Update from all the places
export const updateGlobalFiltersDimensionFilters = (payload) => ({
  type: C.UPDATE_GLOBALFILTERS_DIMENSIONFILTERS,
  payload,
});

//* Global Filters Update from all the places
export const updateGlobalFiltersTimeFilters = (payload) => ({
  type: C.UPDATE_GLOBALFILTERS_TIMEFILTERS,
  payload,
});

export const updateGlobalFiltersRollingDateType = (payload) => ({
  type: C.UPDATE_GLOBALFILTERS_ROLLINGDATETYPE,
  payload,
});

export const updateMetricChartGranularity = (payload) => ({
  type: C.UPDATE_MERTRIC_CHART_GRANULARITY,
  payload,
});

export const updateGlobalFiltersTimeFiltersWithDefaultValue = (payload) => ({
  type: C.UPDATE_GLOBALFILTERS_TIMEFILTERS_WITH_DEFAULT_VALUE,
  payload,
});

export const updateAnalyzeFiltersReadOnlyFromGlobalFiletrs = () => ({
  type: C.UPDATE_ANALYZE_FILTERS_READONLY_FROM_GLOBALFILTERS,
});

export const updateGlobalFiltersFormAnalyzeFiltersReadOnly = () => ({
  type: C.UPDATE_GLOBALFILTERS_FORM_ANALYZEFILTERSREADONLY,
});

// * Workspace
export const updateStandaloneWsForm = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_FORM,
  payload,
});

export const updateStandaloneWsFormCheckAll = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_FORM_CHECK_ALL,
  payload,
});

export const updateStandaloneWsMultipleDimensions = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_MULTIPLE_DIMENSIONS,
  payload,
});

export const updateStandaloneWsMultipleDimensionsFromDimensionDrawer = (
  payload
) => ({
  type: C.UPDATE_STANDALONE_WS_MULTIPLE_DIMENSIONS_FROM_DIMENSION_DRAWER,
  payload,
});

export const updateStandaloneWsMultipleDimensionsAndMetrics = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_MULTIPLE_DIMENSIONS_AND_METRICS,
  payload,
});

export const updateStandaloneWsMsvCancel = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_MSV_CANCEL,
  payload,
});

export const updateStandaloneWsMsvApplyFilters = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_MSV_APPLY_FILTERS,
  payload,
});

export const updateStandaloneWsMultipleMetrics = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_MULTIPLE_METRICS,
  payload,
});
export const updateStandaloneWsMultipleMetricsTable = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_MULTIPLE_METRICS_TABLE,
  payload,
});
export const updateStandaloneWsDimensionTableMetrics = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_DIMENSION_TABLE_METRICS,
  payload,
});
export const DeleteStandaloneWsMetricChart = (payload) => ({
  type: C.DELETE_STANDALONE_WS_FORM_METRIC_CHART,
  payload,
});
export const deleteStandaloneWsMetricChart = (payload) => ({
  type: C.DELETE_STANDALONE_WS_FORM_METRIC_CHART,
  payload,
});
export const deleteStandaloneWsDimensionTable = (payload) => ({
  type: C.DELETE_STANDALONE_WS_FORM_DIMENSION_TABLE,
  payload,
});
export const updateStandaloneWsMetricFiltersForMsvTable = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_METRIC_FILTERS_FOR_MSV_TABLE,
  payload,
});

export const DeleteStandaloneWsDimensiontable = (payload) => ({
  type: C.DELETE_STANDALONE_WS_FORM_DIMENSION_TABLE,
  payload,
});

export const replaceStandaloneWsForm = (payload) => ({
  type: C.REPLACE_STANDALONE_WS_FORM,
  payload,
});

export const updateWsFormDimensionTableData = (payload) => ({
  type: C.UPDATE_WS_FORM_DIMENSION_TABLE_DATA,
  payload,
});

export const reloadWsDimensionTableData = (payload) => ({
  type: C.RELOAD_WS_DIMENSION_TABLE_DATA,
  payload,
});
export const reloadWsAllMetricChartsData = (payload) => ({
  type: C.RELOAD_WS_ALL_METRIC_CHARTS_DATA,
  payload,
});

export const updateStandaloneWsFormMetricsData = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_FORM_METRICS_DATA,
  payload,
});

export const updateStandaloneWsFormDataSort = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_DATA_SORT,
  payload,
});

export const updateStandaloneWsTogglePercentCalList = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_TOGGLE_PERCENT_CAL_LIST,
  payload,
});

export const updateStandaloneWsFiltersFromDimTable = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_FILTERS_FROM_DIM_TABLE,
  payload,
});

export const updateStandaloneWsFormMetricsChartsData = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_FORM_METRICS_CHARTS_DATA,
  payload,
});

export const updateStandaloneWsFormMetricsChartHover = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_FORM_METRICS_CHARTS_HOVER,
  payload,
});

export const updateStandaloneWsFormMetricsChartsDataWithFilter = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_FORM_METRICS_CHARTS_DATA_WITH_FILTER,
  payload,
});

export const updateStandaloneWsMsvTableRowClicked = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_MSV_TABLE_ROW_CLICKED,
  payload,
});

export const updateStandaloneWsAndMsvByOpeningMsv = (payload) => ({
  type: C.UPDATE_STANDALONE_WS_AND_MSV_BY_OPENING_MSV,
  payload,
});

export const addMetricChart = (payload) => ({
  type: C.ADD_METRIC_CHART,
  payload,
});

export const addMetricToTables = (payload) => ({
  type: C.ADD_METRIC_TO_TABLES,
  payload,
});

export const removeMetricChart = (payload) => ({
  type: C.REMOVE_METRIC_CHART,
  payload,
});

export const removeMetricFromTables = (payload) => ({
  type: C.REMOVE_METRIC_FROM_TABLES,
  payload,
});

export const replaceMetricDrawerSelections = (payload) => ({
  type: C.REPLACE_METRIC_DRAWER_SELECTIONS,
  payload,
});

// Reset Password
export const updateResetPassword = (payload) => ({
  type: C.UPDATE_RESET_PASSWORD_FORM,
  payload,
});

export const submitResetPasswordInitialError = (payload) => ({
  type: C.SUBMIT_RESET_PASSWORD_INITIAL_ERROR,
  payload,
});

// Change Password
export const updateChangedPassword = (payload) => ({
  type: C.UPDATE_CHANGED_PASSWORD,
  payload,
});

export const submitChangePasswordInitialError = (payload) => ({
  type: C.SUBMIT_CHANGED_PASSWORD_INITIAL_ERROR,
  payload,
});

// * Sign-In
export const updateSignInForm = (payload) => ({
  type: C.UPDATE_SIGNIN_FORM,
  payload,
});

export const replaceSignInForm = (payload) => ({
  type: C.REPLACE_SIGNIN_FORM,
  payload,
});

export const updateSignInFormWholeKey = (payload) => ({
  type: C.UPDATE_SIGNIN_FORM_WHOLE_KEY,
  payload,
});

export const updatePivotForm = (payload) => ({
  type: C.UPDATE_PIVOT_FORM,
  payload,
});

export const updatePivotAdvanceSort = (payload) => ({
  type: C.UPDATE_PIVOT_ADVANCE_SORT,
  payload,
});

export const updatePivotReloadEpoch = (payload) => ({
  type: C.UPDATE_PIVOT_RELOADEPOCH,
  payload,
});

export const updatePivotMultipleFields = (payload) => ({
  type: C.UPDATE_PIVOT_MULTIPLE_FIELDS,
  payload,
});

//Alert Manager actions
export const updateAlertForm = (payload) => ({
  type: C.UPDATE_ALERT_FORM,
  payload,
});

export const updateAlertFormDimensionRow = (payload) => ({
  type: C.CHANGE_ACTIVE_DIMENSION_ROW,
  payload,
});

export const replaceAlertForm = (payload) => ({
  type: C.REPLACE_ALERT_FORM,
  payload,
});

export const updateAuthLogin = (payload) => ({
  type: C.UPDATE_AUTH_LOGIN,
  payload,
});

export const updateAuthPublicUrl = (payload) => ({
  type: C.UPDATE_AUTH_PUBLIC_URL,
  payload,
});

export const updateAuthError = (payload) => ({
  type: C.UPDATE_AUTH_ERROR,
  payload,
});

export const updateAuthLoading = (payload) => ({
  type: C.UPDATE_AUTH_LOADING,
  payload,
});

export const updateAuthSuccess = (payload) => ({
  type: C.UPDATE_AUTH_SUCCESS,
  payload,
});

// * MSV
export const updateStandaloneMsvForm = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_FORM,
  payload,
});
export const updateStandaloneMsvFormCheckAll = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_FORM_CHECK_ALL,
  payload,
});
export const updateStandaloneMsvMultipleDimensions = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_MULTIPLE_DIMENSIONS,
  payload,
});
export const updateStandaloneMsvMultipleDimensionsFromDimensionDrawer = (
  payload
) => ({
  type: C.UPDATE_STANDALONE_MSV_MULTIPLE_DIMENSIONS_FROM_DIMENSION_DRAWER,
  payload,
});
export const updateStandaloneMsvMultipleDimensionsAndMetrics = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_MULTIPLE_DIMENSIONS_AND_METRICS,
  payload,
});
export const updateStandaloneMsvMsvCancel = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_MSV_CANCEL,
  payload,
});
export const updateStandaloneMsvMsvApplyFilters = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_MSV_APPLY_FILTERS,
  payload,
});
export const updateStandaloneMsvMultipleMetrics = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_MULTIPLE_METRICS,
  payload,
});
export const updateStandaloneMsvMultipleMetricsTable = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_MULTIPLE_METRICS_TABLE,
  payload,
});
export const updateStandaloneMsvDimensionTableMetrics = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_DIMENSION_TABLE_METRICS,
  payload,
});
export const deleteStandaloneMsvMetricChart = (payload) => ({
  type: C.DELETE_STANDALONE_MSV_FORM_METRIC_CHART,
  payload,
});
export const deleteStandaloneMsvDimensionTable = (payload) => ({
  type: C.DELETE_STANDALONE_MSV_FORM_DIMENSION_TABLE,
  payload,
});
export const updateStandaloneMsvMetricFiltersForMsvTable = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_METRIC_FILTERS_FOR_MSV_TABLE,
  payload,
});
export const replaceStandaloneMsvForm = (payload) => ({
  type: C.REPLACE_STANDALONE_MSV_FORM,
  payload,
});
export const updateStandaloneMsvFormDimensionTableData = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_FORM_DIMENSION_TABLE_DATA,
  payload,
});
export const reloadStandaloneMsvDimensionTableData = (payload) => ({
  type: C.RELOAD_STANDALONE_MSV_DIMENSION_TABLE_DATA,
  payload,
});
export const reloadStandaloneMsvAllMetricChartsData = (payload) => ({
  type: C.RELOAD_STANDALONE_MSV_ALL_METRIC_CHARTS_DATA,
  payload,
});
export const updateStandaloneMsvFormMetricsData = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_FORM_METRICS_DATA,
  payload,
});
export const updateStandaloneMsvFormDataSort = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_DATA_SORT,
  payload,
});
export const updateStandaloneMsvTogglePercentCalList = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_TOGGLE_PERCENT_CAL_LIST,
  payload,
});
export const updateStandaloneMsvFiltersFromDimTable = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_FILTERS_FROM_DIM_TABLE,
  payload,
});
export const updateStandaloneMsvFormMetricsChartsData = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_FORM_METRICS_CHARTS_DATA,
  payload,
});
export const updateStandaloneMsvFormMetricsChartsDataWithFilter = (
  payload
) => ({
  type: C.UPDATE_STANDALONE_MSV_FORM_METRICS_CHARTS_DATA_WITH_FILTER,
  payload,
});
export const updateStandaloneMsvMsvTableRowClicked = (payload) => ({
  type: C.UPDATE_STANDALONE_MSV_MSV_TABLE_ROW_CLICKED,
  payload,
});

export const updateRegisterBucketForm = (payload) => ({
  type: C.UPDATE_REGISTER_BUCKET_FORM,
  payload,
});

export const replaceRegisterBucketForm = (payload) => ({
  type: C.REPLACE_REGISTER_BUCKET_FORM,
  payload,
});

export const updateUserLanguage = (payload) => ({
  type: C.UPDATE_USER_LANGUAGE,
  payload,
});
