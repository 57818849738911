// * Import required libraries
import React, { useState, useContext } from "react";

// * Import lib components
import { Checkbox, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

// * Import custom components
import SigviewButton from "../Common/SigviewButton";
import SigviewSearchField from "../Common/SigviewSearchField";
import SigviewIcon from "../Common/SigviewIcon";
import SigviewTooltip from "../Common/SigviewTooltip";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// Making styles for all variations
// Defining required functions
const makeSigviewStyle = (themeColors, style) => {
  const {
    border = `1px solid ${themeColors["secondaryColorLightest"]}`,
    height = "24px",
  } = style;
  const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
      flexDirection: "column",
      color: themeColors["sidenavItemColor"],
      backgroundColor: themeColors["sidenavBgColor"],
      height: "calc(100% - 50px)",
      padding: "10px 12px",
      border: border,
      borderBottomLeftRadius: "3px",
      borderBottomRightRadius: "3px",
    },
    checkboxItemContainer: {
      height: style?.height ? style?.height : "24px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",

      alignItems: "center",
      // padding: "10px !important",
      boxSizing: "border-box",
      "&:hover ": {
        cursor: "pointer",
        backgroundColor: themeColors["secondaryColorLightest"],
        borderRadius: "3px",
      },
    },
    itemText: {},

    materialIconsOnHover: {
      color: themeColors["primaryColor"],
      fontSize: "small",
      // display: editIcon ? "inline-block" : "none",
      cursor: "pointer",
    },

    checkboxLabelContainer: {
      width: "80%",
      display: "flex",
      alignItems: "center",
    },
    checkboxContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    listItemContainer: {
      height: "100%",
      width: "100%",

      overflowY: "auto",
    },

    checkboxHeadContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    checkboxItemLabel: {
      fontSize: "10px",
      color: themeColors["secondaryColor"],
      paddingLeft: "7px",
      whiteSpace: "nowrap",
      width: "calc(100% - 7px)",
      textOverflow: "ellipsis",
      // border: "1px solid black",
      display: "inline-block",
      overflow: "hidden",
    },
    materialIcons: {
      color: themeColors["primaryColor"],
    },
    materialIconsEdit: {
      color: themeColors["secondaryColor"],
      cursor: "pointer",
    },
    materialIconsFilter: {
      color: themeColors["primaryColor"],
      fontSize: "small",
    },
    "& .listItemContainer .checkboxItemContainer .iconContainer": {
      // display: editIcon.display,
      fontSize: "small",
      color: themeColors["primaryColor"],
      cursor: "pointer",
    },

    checklistItem: {
      color: themeColors["secondaryColor"],
    },
    selectedItem: {
      color: themeColors["primaryColor"],
    },
    buttonContainer: {
      display: "flex",
    },
    iconContainer: {
      display: "flex",
      width: "max-content",
      overflowX: "hidden",
      padding: "5px",
      boxSizing: "border-box",
    },
    disabledItem: {
      color: `${themeColors["primaryColorLighter"]} !important`,
    },
  }));
  return useStyles;
};

function SigviewCheckList(props) {
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Destructure props
  const {
    onChange = () => {},
    selections = {},
    data = [],
    handleSelectAll = () => {},
    handleClearAll = () => {},
    disabled = false,
    style = "",
    diabledItemData = [],
    type = "",
    handelAttributeEdit = () => {},
    variant = "",
  } = props;

  const { userSelection = [], activeItem = {}, filter = [] } = selections;

  // * Define requried states
  const [search, setSearch] = useState("");

  // * Define required event handlers
  const handleSearchFieldChange = (event, value) => setSearch(value);

  const handleClick = (item) => {
    if (!item.disabled) onChange(item);
  };

  // * Define required static variables
  const filteredData = data.filter((item) => {
    if (item.title.toLowerCase().includes(search.toLowerCase())) {
      return item;
    }
  });
  // Styling
  const SigviewCheckbox = withStyles(() => ({
    root: {
      opacity: disabled ? 0.5 : 1,
      color: `${themeColors["primaryColor"]} !important`,
      padding: "0px !important",
      marginRight: "5px",
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18,
      },
      "&.MuiCheckbox-colorSecondary.Mui-checked:hover": {
        backgroundColor: "transparent",
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  }))(Checkbox);

  const useSigviewStyles = makeSigviewStyle(themeColors, style);
  const classes = useSigviewStyles();

  const ChecklistRow = (props = {}) => {
    const { item = {} } = props;
    // const [showIcon, setShowIcon] = useState(false);
    const [editIcon, setEditIcon] = useState(false);
    const checked =
      userSelection.find((row) => row.id === item.id) !== undefined;

    return (
      <div
        className={classes.checkboxItemContainer}
        key={item.id}
        id={item.id}
        onMouseEnter={(e) => {
          // console.log("mouse enter", e);
          setEditIcon(true);
        }}
        onMouseLeave={(e) => {
          setEditIcon(false);
        }}
      >
        <div className={classes.checkboxLabelContainer}>
          {type !== "dataOnly" && (
            <div
              className={classes.checkboxContainer}
              onClick={() => handleClick(item)}
            >
              <SigviewCheckbox
                // checked={userSelection.find((row) => row.id === item.id)}
                checked={checked}
                // onChange={() => {
                //   console.log("item", item);
                //   onChange(item);
                // }}
                value="checkedG"
                inputProps={{ "aria-label": "secondary checkbox" }}
                disabled={disabled || item.disabled}
                className={item.disabled ? classes.disabledItem : ""}
              />
              <label
                title={item.title}
                style={{ cursor: "pointer" }}
                className={`${classes.checkboxItemLabel} ${
                  userSelection.find((row) => row.id === item.id)
                    ? classes.selectedItem
                    : ""
                }`}
              >
                {item.title}
              </label>
            </div>
          )}
          {type === "dataOnly" && (
            <label
              title={item.title}
              className={`${classes.checkboxItemLabel} ${
                userSelection.find((row) => row.id === item.id)
                  ? classes.selectedItem
                  : ""
              }`}
            >
              {item.title}
            </label>
          )}
        </div>

        <div className={classes.iconContainer}>
          {variant === "editable" && !item.disabled && editIcon && (
            <SigviewIcon
              className={`material-icons-outlined ${classes.materialIconsEdit}`}
              iconName="edit"
              onClick={() => {
                handelAttributeEdit(item);
              }}
            />
          )}
          {type !== "dataOnly" &&
            filter.length !== 0 &&
            filter.includes(item.id) && (
              <span
                className={`material-icons-outlined ${classes.materialIconsFilter}`}
              >
                filter_alt
              </span>
            )}

          {item.id.includes("CM") && (
            <SigviewTooltip
              title={item.actualPayload.displayFormula}
              placement="bottom"
            >
              <span>
                <SigviewIcon
                  className="material-icons"
                  iconName="info"
                  // style={{ padding: "0px 10px" }}
                />
              </span>
            </SigviewTooltip>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.checkboxHeadContainer}>
        <Box css={{ width: "130px" }}>
          <SigviewSearchField
            onChange={handleSearchFieldChange}
            customStyle={{
              wrapperHeight: "20px",
              iconSize: "12px",
              textFieldFontSize: "10px",
              placeholderFontSize: "9px",
            }}
          />
        </Box>

        {type !== "dataOnly" && !disabled && type !== "dataSelect" && (
          <div className={classes.buttonContainer}>
            <SigviewButton
              title="Select All"
              variant="text"
              onClick={() => {
                handleSelectAll();
              }}
              style={{ fontSize: "10px", padding: "0px 10px !important" }}
            />
            <SigviewButton
              title="Clear All"
              variant="text"
              onClick={() => {
                handleClearAll();
              }}
              style={{ fontSize: "10px", padding: "0px 10px !important" }}
            />
          </div>
        )}
      </div>
      <div
        className={`sigview-styled-scroller-thin ${classes.listItemContainer}`}
      >
        {filteredData.length === 0 && (
          <div className="no-data-container">
            <div className="no-data-image"></div>
            <p className="no-data">No Data available</p>
          </div>
        )}

        {filteredData.map((item, index) => (
          <ChecklistRow key={item.id} item={item} index={index} />
        ))}
      </div>
    </div>
  );
}

export default SigviewCheckList;
