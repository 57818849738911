// Import required libraries
import React, { memo, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import { v4 } from "uuid";
import orderBy from "lodash/orderBy";
import { useTranslation } from "react-i18next";

// Import custom components
import ReportManager from "./ReportManager";
import SigviewButton from "../Common/SigviewButton";
import Loader from "../Loader/Loader";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import SigviewSwitch from "../Common/SigviewSwitch";
import SigviewProgressBar from "../Common/SigviewProgressBar";
import SigviewNav from "../Common/SigviewNav";
import SigviewNavType2 from "../Common/SigviewNavType2";

// Import styles
import "./ReportManager.scss";

// Import config
import { config } from "../../config/config";

// Import action creators and reducers
import { updateUserScreen, updateDialogInfo } from "../../redux/actions";

// Import utils
import {
  timeSinceCreation,
  capitalize,
  getSigviewUserType,
} from "../../utils/utils";
import {
  makeSubtitleForScheduledReport,
  getSelectedReportPayload,
  unwrapperBucketListInReport,
} from "../../utils/reportUtils";
import { masterTrackGaEvent } from "../../services/ga";

// Import data
import triggerFrequencyArr from "../../../assets/data/triggerFrequencyArr.json";

// Import api functions
import {
  getAllScheduledReports,
  getAllSavedReports,
  getReportsDownloadHistory,
  updateScheduledReport,
  readUsersByOrg,
  fetchBucketList,
} from "../../services/api";
import { isEqual } from "underscore";

const reportTypesArr = [
  { id: "saved", name: "Saved", disabled: false },
  { id: "scheduled", name: "Scheduled", disabled: false },
  // { id: "download", name: "Download History" },
];

const downloadsNavArr = [
  { id: "downloads", name: "Download History", disabled: false },
];

const initialData = { status: "loading", message: "", data: [] };

function areEqual(prevProps, nextProps) {
  let prev = prevProps.backgroundImage;
  let next = nextProps.backgroundImage;

  return isEqual(prev, next);
}

function ReportManagerContainer(props) {
  const { t } = useTranslation();
  const {
    dispatch: ReduxDispatcher,
    user,
    allData,
    standalone: wsForm,
    globalFilters,
  } = props;

  //Defining required states
  const [scheduledReports, setScheduledReports] = useState({
    status: "success",
    message: "",
    data: user.screen.adminReportData.scheduledAdminReports.data,
  });
  const [savedReports, setSavedReports] = useState({
    status: "success",
    message: "",
    data: user.screen.adminReportData.savedAdminReports.data,
  });

  const [tourRun, setTourRun] = useState(false);

  const [activeReport, setActiveReport] = useState({
    type: user.screen.adminReportData.activeAdminReport.type,
    id: user.screen.adminReportData.activeAdminReport.id,
  });
  const [showReportManager, setShowReportManager] = useState(true); //mount and unmount report manager everytime user changes active report

  const [usersData, setUsersData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });

  const [bucketList, setBucketList] = useState({
    result: [],
    status: "initial",
    message: "",
  });

  const sigviewUserType = getSigviewUserType();

  // Fetch users Data
  useEffect(() => {
    // Make fetch call using axios
    const source = axios.CancelToken.source();
    const fetchProps = {
      cancelToken: source.token,
    };
    const readUsersByOrgPromise = readUsersByOrg(fetchProps);
    readUsersByOrgPromise
      .then((data) => {
        setUsersData({
          result: data?.result?.data,
          status: "success",
          messsage: "",
        });
      })
      .catch((json) => {
        setUsersData({
          result: [],
          status: "error",
          messsage: "API failed",
        });
        // console.log(json);
      });

    if (sigviewUserType === "nonSigview") {
      const fetchBucketListPromise = fetchBucketList({});
      fetchBucketListPromise
        .then((data) => {
          let newData = {
            result: data.result.data,
            status: "success",
            messsage: "",
          };
          setBucketList(newData);
        })
        .catch((json) => {
          let newData = {
            result: [],
            status: "error",
            messsage: "Fetch bucketList API failed",
          };
          setBucketList(newData);
        });
    }
    return () => {
      setUsersData({
        result: [],
        status: "loading",
        messsage: "",
      });
    };
  }, []);

  const activeReportFormData = getSelectedReportPayload({
    activeReport,
    allReports: {
      scheduledReports: scheduledReports.data,
      savedReports: savedReports.data,
      reportsDownloadHistory: [],
    },
    allData,
    user,
    variant: "adminReport",
  });
  const isDownloadAvailableClassName = user.uiFeatureList.download
    ? ""
    : "download-unavailable";

  //Defining utility functions
  const handleReportManagerClose = () => {
    //Check if there's a change in report manager values
    //If yes, show the dialog, else close report manager
    //TODO
    // const flag = !isEqual(
    //   globalFiltersStoreFiltered,
    //   globalFiltersOriginal.current
    // );
    const flag = activeReport.type !== "none";
    if (flag) {
      const message = {
        title: t(config.dialogInfo.filtersClose.title),
        subtitle: t(config.dialogInfo.filtersClose.subtitle),
      };
      const handleNo = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
          })
        );
      };
      const handleYes = () => {
        //Close the dialog box
        ReduxDispatcher(
          updateDialogInfo({
            ...user.dialogInfo,
            open: false,
            message: { title: "", subtitle: "" },
          })
        );
        //Close the report manager
        ReduxDispatcher(updateUserScreen("adminReport", false));
      };
      ReduxDispatcher(
        updateDialogInfo({
          ...user.dialogInfo,
          open: true,
          message,
          handleYes,
          handleNo,
        })
      );
    } else {
      //Close the report manager
      ReduxDispatcher(updateUserScreen("adminReport", false));
    }
  };

  //Change showReportManager state so that the component can mount and unmount for resetting formData
  useEffect(() => {
    setShowReportManager(false);
  }, [activeReport]);

  //Change showReportManager state so that the component can mount and unmount for resetting formData
  useEffect(() => {
    if (showReportManager === false) setShowReportManager(true);
  }, [showReportManager]);

  //DEBUGGING
  // console.group("REPORT MANAGER CONTAINER STATE");
  // console.log("scheduledReports", scheduledReports);
  // console.log("activeReport", activeReport);
  // console.log("savedReports", savedReports);
  // console.log("activeReportFormData", activeReportFormData);
  // console.groupEnd();

  const getCorruptReportErrorMessage = (message) => {
    if (message.toString().includes("is not available")) {
      let splitMessage = message.toString().split(" ");
      let targetedId = splitMessage[1];
      let targetedTitle = "";
      allData.views.result.forEach((row) => {
        row.dimensions.forEach((el) => {
          if (el._id === targetedId) targetedTitle = `Dimension (${el.title})`;
        });
        row.metrics.forEach((el) => {
          if (el._id === targetedId) targetedTitle = `Metric (${el.title})`;
        });
        row.customMetrics.forEach((el) => {
          if (el._id === targetedId)
            targetedTitle = `Custom Metric (${el.title})`;
        });
      });
      splitMessage[1] = targetedTitle;
      let joinedMessage = splitMessage.join(" ");

      joinedMessage = joinedMessage + " in your access list";
      return joinedMessage;
    } else return "Something went wrong!";
  };

  let ModifiedBucketList = useMemo(
    () =>
      bucketList.result.length > 0
        ? [{ id: "initial", name: "Select Storage", disabled: true }].concat(
            unwrapperBucketListInReport(bucketList.result)
          )
        : [
            {
              id: "NA",
              name: "No storage available for your realm",
              disabled: true,
            },
          ],
    [bucketList.result]
  );

  return (
    <>
      {user.screen.adminReport && (
        <div className="report-manager-container-overlay">
          <div className="report-manager-container">
            <section className="title-row-container">
              <div className="left-panel">
                <p className="title">{t("Reports")}</p>
              </div>
              <div className="right-panel-admin-report">
                <i
                  className="material-icons-round close-icon"
                  onClick={handleReportManagerClose}
                >
                  close
                </i>
              </div>
            </section>
            <section className="content-container">
              {activeReport.type === "none" ? (
                <div className="right-panel-admin-report no-report-selected-container">
                  <section className="add-or-open-report-container">
                    <SigviewButton
                      variant="containedLighter"
                      onClick={() => {
                        // handleAddNewReport("Form");
                      }}
                      title="Add New Report"
                      iconFlag={true}
                      iconProps={{
                        className: "material-icons",
                        iconName: "add",
                      }}
                    />
                    {/* <p
                    className="no-report-selected-text add-report-text"
                    onClick={() => {
                      handleAddNewReport("Form");
                    }}
                  >
                    <span className="material-icons add-report-icon">add</span>
                    <span>Add New Report</span>
                  </p> */}
                    <p className="no-report-selected-text">{t("Or")}</p>
                    <p className="no-report-selected-text">
                      {t("Select a saved Report")}
                    </p>
                  </section>
                </div>
              ) : (
                <>
                  {showReportManager && (
                    <>
                      {activeReportFormData.valid ? (
                        <ReportManager
                          activeReportFormData={activeReportFormData}
                          activeReport={activeReport}
                          setActiveReport={setActiveReport}
                          ReduxDispatcher={ReduxDispatcher}
                          user={user}
                          allData={allData}
                          wsForm={wsForm}
                          tourRun={tourRun}
                          setTourRun={setTourRun}
                          uiFeatureList={user.uiFeatureList}
                          variant="adminReport"
                          usersData={usersData}
                          globalFilters={globalFilters}
                          bucketList={ModifiedBucketList}
                        />
                      ) : (
                        <>
                          <div className="right-panel-admin-report no-report-selected-container">
                            <p
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {t(`CORRUPT REPORT`)}
                            </p>
                            <br />

                            {`${getCorruptReportErrorMessage(
                              activeReportFormData.error
                            )}`}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </section>
          </div>
        </div>
      )}
    </>
  );
}

ReportManagerContainer.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  standaloneWs: state.standalone,
  globalFilters: state.globalFilters,
});

export default connect(mapStateToProps)(memo(ReportManagerContainer, areEqual));

// export default memo(AddToMetricChartIcon, areEqual);
