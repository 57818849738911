// * Import required libraries
import React, { useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// * Import custom components
import SigviewTooltip from "../Common/SigviewTooltip";

// * Import utils, config & static data
// import { masterTrackGaEventAdmin } from "../../services/ga/index";

// * Import styles
import "./SidenavBucket.scss";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

function SidenavBucket(props) {
  const {
    dispatch: ReduxDispatcher,
    user,
    allData,
    handleActiveTab = () => {},
  } = props;
  const activeTab = user.screen.activeTab;
  const { state: themeState } = useContext(ThemeContext);

  const handleTab = (e, id) => {
    handleActiveTab(e, id);
  };

  //* Define Event Handlers
  const handleGroupsOpen = (e) => {
    // Google Analytics Event - Master Admin
    // masterTrackGaEventAdmin({
    //   category: "Admin",
    //   action: "GroupsOpen",
    //   label: "Sidenav",
    // });
    handleTab(e, "groups");
  };
  const handleUsersOpen = (e) => {
    // Google Analytics Event - Master Admin
    // masterTrackGaEventAdmin({
    //   category: "Admin",
    //   action: "UsersOpen",
    //   label: "Sidenav",
    // });
    handleTab(e, "users");
  };

  let activeTabHome = activeTab === "home";
  let activeTabGroups = activeTab === "groups";
  let activeTabUsers = activeTab === "users";
  let activeTabAttributes = activeTab === "attributes";

  return (
    <>
      <aside className={`sidenav-container `}></aside>
    </>
  );
}

SidenavBucket.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(SidenavBucket);
