const checkChartTypeValidity = (props) => {
  const getSelectedChartAttributes = (props) => {
    // A function that takes in dim and metric list and returns:
    // const res = {
    //   dim: 0,
    //   timeDim: 0,
    //   nonTimeDim: 0,
    //   metric: 0,
    //   additive: 0,
    //   nonAdditive: 0,
    // };
    const { dimensionsList, metricsList } = props;
    const dim = dimensionsList.length;
    const timeDim = dimensionsList.filter(
      (row) => row.dataType === "dateTime"
    ).length;
    const nonTimeDim = dimensionsList.filter(
      (row) => row.dataType !== "dateTime"
    ).length;
    const metric = metricsList.length;
    const additive = metricsList.filter((row) => row.isPercentCalOn).length;
    const nonAdditive = metricsList.filter((row) => !row.isPercentCalOn).length;
    return {
      dim,
      timeDim,
      nonTimeDim,
      metric,
      additive,
      nonAdditive,
    };
  };

  const isSelectedAttributeValid = (
    selectedChartAttribute,
    maxValidAttributes
  ) => {
    const strictEqualityList = ["dim", "metric"]; //hard coded
    const lessThanList = ["timeDim", "nonTimeDim", "additive", "nonAdditive"]; //hard coded
    for (let key of strictEqualityList) {
      //Do this check only when the count is not equal to 1000
      //1000 refers to less than equal to (multi-table)
      if (maxValidAttributes[key] !== 1000) {
        if (selectedChartAttribute[key] !== maxValidAttributes[key])
          return false;
      }
    }
    for (let key of lessThanList) {
      if (selectedChartAttribute[key] > maxValidAttributes[key]) return false;
    }
    return true;
  };

  const isChartTypeValid = (validityArr, selectedChartAttributes) => {
    const validityFlags = validityArr.map((row) => {
      return isSelectedAttributeValid(selectedChartAttributes, row.attributes);
    });
    const valid = validityFlags.some((flag) => flag);
    return valid;
  };

  const { dimensionsList, metricsList, chartList } = props;
  const selectedChartAttributes = getSelectedChartAttributes(props);
  const newChartList = chartList.map((row) => {
    let disabled = true;
    //User has to select at least one metric & 1 dimension, otherwise all charts should be disabled
    if (dimensionsList.length > 0 && metricsList.length > 0) {
      disabled = !isChartTypeValid(row.validity, selectedChartAttributes);
    }
    const newRow = { ...row, disabled };
    return newRow;
  });
  return newChartList;
};

// Utility Functions
const areSelectionsTimeDimBased = (state) => {
  let allSelectedMetricsDimensions = [
    ...state.dimensionsList.map((row) => ({
      id: row._id,
      dataType: row.dataType,
    })),
    ...state.metricsList.map((row) => ({
      id: row._id,
      dataType: row.dataType,
    })),
  ];
  const orderByObj = allSelectedMetricsDimensions.find(
    (row) => row.id === state.orderById
  );
  const isChartSelectedTable = ["multitable", "table"].includes(
    state.chartType
  );

  // CASE 1
  const condition1 =
    orderByObj?.dataType === "dateTime" && !isChartSelectedTable;
  // CASE 2
  // If there is only 1 dimension which is time and chart type is not table/multi-table
  // default sort is time-dim with asc as fixed (and others disabled)
  const condition2 =
    state.dimensionsList[0]?.dataType === "dateTime" && !isChartSelectedTable;
  // in case, there are no values in the list or there no value matches orderById; it will give undefined
  if (condition1 || condition2) return true;
  return false;
};

// A time dim will be disabled in the order by drop-down in case
// 1. chart is not table
// 2. time dim is not the first element in the dim list
const shouldTimeDimBeDisabled = (state) => {
  let allSelectedMetricsDimensions = [
    ...state.dimensionsList.map((row) => ({
      id: row._id,
      dataType: row.dataType,
    })),
  ];
  const allSelectedMetricsDimensionsLen = allSelectedMetricsDimensions.length;
  const isFirstEleTimeDim =
    allSelectedMetricsDimensionsLen === 0
      ? false
      : allSelectedMetricsDimensions[0].dataType === "dateTime"
      ? true
      : false;
  const isChartSelectedTable = ["multitable", "table"].includes(
    state.chartType
  );
  if (!isChartSelectedTable && !isFirstEleTimeDim) return true;
  return false;
};

const manipulatePlotStateBasedOnBusinessLogic = (state, payload) => {
  const { key } = payload;
  // There will 2 separate checks here for better UX:
  // If the user changes metrics or dimensions list, we will change the orderBy details
  // If the user changes orderBy or chartType, we will re-order the metrics/dimensions list

  const multiSelectionsOrderByLogic = (state, payload) => {
    const { orderById, chartType, dimensionsList, metricsList } = state;
    //User cannot order by on the second selected dim/metric
    //It has to be the first one
    const runFlag =
      chartType !== "multitable" &&
      (dimensionsList.length > 1 || metricsList.length > 1) &&
      orderById !== "";
    if (!runFlag) return state;
    const orderByCategory = state.orderById.startsWith("D")
      ? "dimension"
      : "metric";
    const requiredList =
      orderByCategory === "dimension" ? [...dimensionsList] : [...metricsList];
    const requiredKey =
      orderByCategory === "dimension" ? "dimensionsList" : "metricsList";
    //check if orderById === dimList.first element
    if (orderById === requiredList[0]._id) return state;
    const selectedElement = requiredList.find((row) => row._id === orderById);
    const remainingElements = requiredList.filter(
      (row) => row._id !== orderById
    );
    const newRequiredList = [selectedElement, ...remainingElements];
    return { ...state, [requiredKey]: newRequiredList };
  };
  const orderByValidationForMultiSelections = (state, payload) => {
    const { key } = payload;
    const { chartType, orderById, dimensionsList, metricsList } = state;
    const runFlag =
      chartType !== "multitable" &&
      (dimensionsList.length > 1 || metricsList.length > 1) &&
      orderById !== "";
    if (!runFlag) return state;
    if (key === "dimensionsList") {
      //Run only if orderById is included in dimensionsList
      let dimensionsListIds = dimensionsList.map((row) => row._id);
      if (!dimensionsListIds.includes(orderById)) return state;
      let firstDimElementId = dimensionsList[0]._id;
      if (orderById !== firstDimElementId)
        return { ...state, orderById: firstDimElementId };
    } else if (key === "metricsList") {
      let metricsListIds = metricsList.map((row) => row._id);
      if (!metricsListIds.includes(orderById)) return state;
      let firstDimElementId = metricsList[0]._id;
      if (orderById !== firstDimElementId)
        return { ...state, orderById: firstDimElementId };
    } else {
      return state;
    }
    return state;
  };
  const orderByValidation = (state, payload) => {
    const { orderById, dimensionsList, metricsList, chartType } = state;

    // Defining required variables
    let runFlag = false;

    //If the user changes any selection, check if the orderById field is valid
    if (dimensionsList.length === 0 && metricsList.length === 0)
      return { ...state, orderById: "" };
    runFlag = dimensionsList.length > 0 || metricsList.length > 0;
    if (!runFlag) return state;

    // HARD CODED
    // CRITERIA 1
    // If user has order by on a time-dim, it has to be asc ALWAYS
    // in case, there are no values in the list or there no value matches orderById; it will give undefined
    runFlag = areSelectionsTimeDimBased(state);
    if (runFlag) {
      return { ...state, orderById: dimensionsList[0]._id, orderBy: "asc" };
    }

    // HARD CODED
    // CRITERIA 2
    // If chartType is crosstabheatmap, orderBy can only be on the metric and it has to be descending
    if (chartType === "crosstabheatmap")
      return { ...state, orderById: metricsList[0]._id, orderBy: "desc" };

    // HARD CODED
    // Copy of the CRITERIA 1
    // // If chartType is multi-line or multiaxisline or barline, orderBy can only be on the dimension and it has to be ascending
    // const tempHardCodedList = ["barline", "multiline", "multiaxisline"];
    // if (tempHardCodedList.includes(chartType))
    //   return { ...state, orderById: dimensionsList[0]._id, orderBy: "asc" };

    const validIds = [...dimensionsList, ...metricsList].map((row) => row._id);
    if (orderById === "" || !validIds.includes(orderById)) {
      return { ...state, orderById: validIds[0] };
    } else {
      return state;
    }
  };
  const validateChartType = (state, payload) => {
    //If user selects Line and then changes selections which makes Line chart invalid,
    //change chartType to first available
    //Else, take default value as bar
    // const {  } = payload;
    const { chartList, chartType } = state;
    const validChartTypesIds = chartList
      .filter((row) => !row.disabled)
      .map((row) => row.id);
    if (validChartTypesIds.includes(chartType)) return state;
    if (validChartTypesIds.length > 0)
      return { ...state, chartType: validChartTypesIds[0] };
    return { ...state, chartType: "line" }; //hard coded
  };

  //Different validations will run for different cases
  let newState = { ...state };
  if (["orderById", "chartType"].includes(key)) {
    newState = orderByValidation(newState, payload);
    newState = multiSelectionsOrderByLogic(newState, payload);
    newState = validateChartType(newState, payload);
  } else {
    newState = orderByValidation(newState, payload);
    newState = orderByValidationForMultiSelections(newState, payload);
    newState = validateChartType(newState, payload);
  }

  return newState;
};

//A function that converts CSV to required JSON
//https://docs.google.com/spreadsheets/d/1wHioVq-wJH5O4QlWdZdj4ett3S5zJLGhh8sVsilTVoU/edit#gid=1911869798
// const chartTypeCsvToJson = arr.map((row) => {
//   const list = [
//     "dim",
//     "timeDim",
//     "nonTimeDim",
//     "metric",
//     "additive",
//     "nonAdditive",
//   ];
//   const attributes = {};
//   list.forEach((key) => {
//     attributes[key] = row[key];
//   });
//   const validity = {
//     labels: {
//       dim: { label: row.dimLabel, classNames: row.dimLabelClassNames },
//       metric: { label: row.metricLabel, classNames: row.metricLabelClassNames },
//     },
//     attributes,
//   };
//   const obj = {
//     id: row.id,
//     order: row.order,
//     name: row.name,
//     validity: [validity],
//   };
//   return obj;
// });

const getAscDecBasesOnBusinessLogic = (state) => {
  let ascDescArr = [
    { id: "asc", name: "Ascending", disabled: false },
    { id: "desc", name: "Descending", disabled: false },
  ];
  if (areSelectionsTimeDimBased(state)) {
    ascDescArr = [
      { id: "asc", name: "Ascending", disabled: false },
      { id: "desc", name: "Descending", disabled: true },
    ];
  }
  // If chartType is crosstabheatmap, orderBy can only be on the metric and it has to be descending
  if (state.chartType === "crosstabheatmap") {
    ascDescArr = [
      { id: "asc", name: "Ascending", disabled: true },
      { id: "desc", name: "Descending", disabled: false },
    ];
  }
  return ascDescArr;
};

const getOrderByDropdownBasedOnBusinessLogic = (state) => {
  let allSelectedMetricsDimensions = [
    ...state.dimensionsList.map((row) => ({
      id: row._id,
      name: row.dimTitle,
      dataType: row.dataType,
      disabled: false,
    })),
    ...state.metricsList.map((row) => ({
      id: row._id,
      name: row.measureTitle,
      dataType: row.dataType,
      disabled: false,
    })),
  ];

  let runFlag = areSelectionsTimeDimBased(state);
  if (runFlag) {
    allSelectedMetricsDimensions = allSelectedMetricsDimensions.map((row) =>
      row.id === state.orderById
        ? { ...row, disabled: false }
        : { ...row, disabled: true }
    );
    return allSelectedMetricsDimensions;
  }

  // Disable all time dimensions in order by list if chart is table and time dim is not first dim
  runFlag = shouldTimeDimBeDisabled(state);
  if (runFlag) {
    allSelectedMetricsDimensions = allSelectedMetricsDimensions.map((row) =>
      row.dataType === "dateTime"
        ? { ...row, disabled: true }
        : { ...row, disabled: false }
    );
    return allSelectedMetricsDimensions;
  }

  // If chartType is crosstabheatmap, orderBy can only be on the metric and it has to be descending
  if (state.chartType === "crosstabheatmap") {
    allSelectedMetricsDimensions = allSelectedMetricsDimensions.map((row) =>
      row.id === state.orderById
        ? { ...row, disabled: false }
        : { ...row, disabled: true }
    );
  }
  return allSelectedMetricsDimensions;
};

export {
  getAscDecBasesOnBusinessLogic,
  getOrderByDropdownBasedOnBusinessLogic,
  checkChartTypeValidity,
  areSelectionsTimeDimBased,
  manipulatePlotStateBasedOnBusinessLogic,
};
