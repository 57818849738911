// * Import required libraies
import React, { useReducer, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import isEqual from "lodash.isequal";
import { v4 } from "uuid";

// * Import 3rd party lib
import { Box } from "@material-ui/core";

// * Import Custom Component
import StandaloneChart from "../StandaloneChart";
import DimensionFilters from "../DimensionFilters";
import { PlotMenuBar } from "../../screens/Charts/ChartsCreate/ChartsCreateHelpers";

// Import Context

// Import action creators
import {
  replaceStandaloneChartForm,
  updateStandaloneChartForm,
} from "../../redux/actions";
import standaloneChartReducer from "../../redux/reducers/standaloneChart";

// * Import utils/data
import { config } from "../../config/config";
import { wrapperChart } from "../../utils/chartObjectUtils";
import { masterTrackGaEvent } from "../../services/ga";
import useReducerLogger from "../../utils/useReducerLogger";
import SIGVIEW_CONTANTS from "../../constants/sigviewConstants";

// * Import API

// * Define required static variables
const defaultChartData = {
  result: { dataFromQE: [], extraData: {} },
  status: "loading",
  message: "",
};

function ChartPopover(props) {
  // * Destructure Props
  const {
    // REDUX PROPS
    dispatch: ReduxDispatcher,
    user,
    allData,
    // PARENT PROPS
    initialStandaloneChartData = SIGVIEW_CONTANTS.defaultStandaloneChartData,
    initialChartData = defaultChartData,
    eventHandlers = {},
    settings = {},
    onChartDataChange = () => {},
  } = props;
  const { onYes = () => {}, onNo = () => {} } = eventHandlers;
  const { titles = {} } = settings;
  const { yesTitle = "Save", noTitle = "Discard" } = settings;

  // * Define required states
  const [chartName, setChartName] = useState({
    status: "success",
    value: initialStandaloneChartData.title,
    originalValue: initialStandaloneChartData.title,
    message: "",
  });
  const [standaloneChart, dispatchStandaloneChart] = useReducer(
    useReducerLogger(standaloneChartReducer),
    initialStandaloneChartData
  );
  const [chartData, setChartData] = useState(initialChartData);
  const standaloneChartOriginal = useRef(initialStandaloneChartData);

  // * Define required variables
  const isEdit = false;
  // const gaCategory = isEdit ? "EditChart" : "CreateChart";

  // * Define required side effects
  //Update chartTitle in the object everytime chartName (parent state) changes
  //Do this only when chartName.originalValue changes because chartName.value is
  //real-time state and chartName.originalValue refers to the renamed state
  useEffect(() => {
    // Update state only when they are different
    if (standaloneChart.title !== chartName.originalValue) {
      const payload = { key: "title", value: chartName.originalValue };
      const action = updateStandaloneChartForm(payload);
      dispatchStandaloneChart(action);
      // Rename chart is a separate API
      standaloneChartOriginal.current = {
        ...standaloneChartOriginal.current,
        title: chartName.originalValue,
      };
    }
  }, [chartName.originalValue]);

  //Update local state of standaloneChart if initialStandaloneChartData changes
  //Update local chartName if initialStandaloneChartData changes
  useEffect(() => {
    //Perform it only when they are not equal to avoid infinite loop
    if (!isEqual(initialStandaloneChartData, standaloneChart)) {
      const value = { ...initialStandaloneChartData };
      const action = replaceStandaloneChartForm(value);
      dispatchStandaloneChart(action);
      // Can come here (Not needed)
      // standaloneChartOriginal.current = { ...initialStandaloneChartData };
      setChartName(() => ({
        status: "success",
        value: initialStandaloneChartData.title,
        originalValue: initialStandaloneChartData.title,
        message: "",
      }));
    }
  }, [initialStandaloneChartData]);

  // * Define required event handlers
  const trackCrudGA = (label) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "CreateChartPopover",
      action: "CRUD",
      label,
    });
  };

  const handleStandaloneChartChange = (value) => {
    const action = replaceStandaloneChartForm(value);
    dispatchStandaloneChart(action);
  };

  const handleChartDataChange = (value) => setChartData(value);

  const handleYes = () => {
    trackCrudGA("Save");
    const wrapperChartProps = { user, standaloneChart, chartName };
    const backendPayload = wrapperChart(wrapperChartProps);
    const uiPayload = { standaloneChart, chartName, chartData };
    onYes({ backendPayload, uiPayload });
  };

  const handleNo = () => {
    trackCrudGA("Cancel");
    onNo();
  };

  const handleTimeFiltersChange = (value) => {
    var payload = { key: "renderFlag", value: false }; // so that getData call is not made until user clicks apply
    var action = updateStandaloneChartForm(payload);
    dispatchStandaloneChart(action);
    var payload = { key: "timeFilters", value };
    var action = updateStandaloneChartForm(payload);
    dispatchStandaloneChart(action);
  };

  const handleDimensionFiltersChange = (value) => {
    var payload = { key: "renderFlag", value: false }; // so that getData call is not made until user clicks apply
    var action = updateStandaloneChartForm(payload);
    dispatchStandaloneChart(action);
    var payload = { key: "dimensionFilters", value };
    var action = updateStandaloneChartForm(payload);
    dispatchStandaloneChart(action);
  };

  // * Define required variables
  const isYesDisabled =
    standaloneChart.dimensionsList.length === 0 ||
    standaloneChart.metricsList.length === 0;
  // chartData.status === "loading"; // in case we doesn't want the user to update if the data hasn't been fetched yet
  let menuBarSplitButtonOptions = [
    {
      id: "yes",
      label: yesTitle,
      onClick: handleYes,
      disabled: isYesDisabled,
    },
    {
      id: "no",
      label: noTitle,
      onClick: handleNo,
      disabled: false,
    },
  ];

  const renderMenuBarProps = {
    ReduxDispatcher,
    user,
    allData,
    chartName,
    setChartName,
    menuBarSplitButtonOptions,
    standaloneChart,
    isEdit,
    onTimeFiltersChange: handleTimeFiltersChange,
  };

  // * DEBUGGING
  // console.groupCollapsed("PlotCreate.js");
  // console.log("standaloneChart", standaloneChart);
  // console.groupEnd();

  // Styles
  const filtersMenuRowCount = "single"; // single/double
  const filterContainerRowHeight = filtersMenuRowCount === "single" ? 35 : 70;
  const TEMP_STYLE = {
    root: { height: "100%", width: "100%", boxSizing: "border-box" },
    menuBarContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "48px",
      width: "100%",
      padding: "0px 22px",
      boxSizing: "border-box",
    },
    filtersBarContainer: {
      display: "flex",
      // justifyContent: "center",
      alignItems: "flex-start",
      height: `${filterContainerRowHeight}px`,
      width: "100%",
      overflow: "auto",
      padding: "0px 22px 5px 22px",
      // boxSizing: "border-box",
    },
    standaloneChartContainer: {
      height: `calc(100% - 46px - ${filterContainerRowHeight}px - 5px)`,
      boxSizing: "border-box",
      width: "100%",
    },
  };
  //   const TEMP_CSS = { height: "35px" };
  //   const standaloneChartWrapperCss = { height: "calc(100% - 100px)" };

  return (
    <Box css={TEMP_STYLE.root}>
      <Box css={TEMP_STYLE.menuBarContainer}>
        <PlotMenuBar {...renderMenuBarProps} />
      </Box>
      <Box css={TEMP_STYLE.filtersBarContainer}>
        <DimensionFilters
          initialDimensionFilters={standaloneChart.dimensionFilters}
          initialTimeFilters={standaloneChart.timeFilters}
          onDimensionFiltersChange={handleDimensionFiltersChange}
        />
      </Box>
      <Box css={TEMP_STYLE.standaloneChartContainer}>
        <StandaloneChart
          initialState={standaloneChart}
          onChange={handleStandaloneChartChange}
          updateParentState={true}
          initialChartData={initialChartData}
          onChartDataChange={handleChartDataChange}
        />
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(ChartPopover);
